import { SdohSingleAnswer } from './SdohSingleAnswer.model';
import { ICD10 } from '../Shared/ICD10.model';

export default interface ISdohSingleAnswerAdvanced extends SdohSingleAnswer {
    OtherAnswer: string;
    NoAnswerToQuestion: boolean;
}

export class SdohSingleAnswerAdvanced implements ISdohSingleAnswerAdvanced {
    OtherAnswer: string;
    NoAnswerToQuestion: boolean;
    Answer: string;
    ICD10: ICD10 | null;
    LoincCode: string;
    Diagnosis: string;

    constructor() {
        this.OtherAnswer = '';
        this.NoAnswerToQuestion = false;
        this.Answer = '';
        this.ICD10 = null;
        this.LoincCode = '';
        this.Diagnosis = '';
    }
}
