import { Box, Stack, FormControl, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';

import { dateFormatter } from '../../../../utils/timeFormat';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';

import { patchUnmatchMember, setEndDate, setStartDate, getAlertsByMatchedDates, setRows, getAlertHistory } from '../../../../store/hie.slice';
import Button from '@mui/material/Button';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Authorization from '../../../../utils/Authorization';
import MatchedHistoryModal from './MatchedHistoryModal';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { isDefaultDate } from '../../../../utils/common';

const History = () => {
    const dispatch = useDispatch();
    const auth = new Authorization();

    const { alertHistory, rows, startDate, endDate } = useSelector((state: RootState) => state.hie);

    const [openHistory, setOpenHistory] = useState(false);
    const [matchedHistory, setMatchedHistory] = useState([]);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalTitle, setConfirmationModalTitle] = useState('');
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [alertId, setAlertId] = useState('');

    const openVeiwHistoryHandler = (params) => {
        setMatchedHistory(params);
        setOpenHistory(true);
    };

    const closeViewHistoryHandler = () => {
        setMatchedHistory([]);
        setOpenHistory(false);
    };

    const openConfirmationModalHandler = (params) => {
        setConfirmationModalTitle('Confirm Un-Match');
        setConfirmationModalMessage(`Are you sure you wish to Un-Match ${params.row.Patient}?`);
        setAlertId(params.row.Id);
        setConfirmationModalOpen(true);
    };

    const closeConfirmationModalHandler = () => {
        setConfirmationModalOpen(false);
        setAlertId('');
    };

    const unMatchSelectedMemberHandler = () => {
        dispatch(patchUnmatchMember(alertId, auth.UserName));
        closeConfirmationModalHandler();
    };

    const getAlertsByMatchedDatesHandler = () => {
        dispatch(getAlertsByMatchedDates(dateFormatter(startDate), dateFormatter(endDate)));
    };

    useEffect(() => {
        dispatch(getAlertHistory());
    }, [dispatch]);

    useEffect(() => {
        if (alertHistory.length > 0) {
            dispatch(setRows(alertHistory));
        }
    }, [dispatch, alertHistory]);

    const columns: GridColDef[] = [
        {
            field: 'MemberId',
            headerName: 'Un-Match Alert',
            minWidth: 140,
            renderCell: (params: GridRenderCellParams) => {
                return params.row.MemberId ? (
                    <Button variant="contained" onClick={(e) => openConfirmationModalHandler(params)}>
                        Unmatch
                    </Button>
                ) : (
                    'Not Matched'
                );
            },
        },
        {
            field: 'MatchedDate',
            headerName: 'Matched Date',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.MatchedDate) ? 'N/A' : dateFormatter(new Date(params.row.MatchedDate))}`,
        },
        {
            field: 'History',
            headerName: 'Alert History',
            minWidth: 140,
            renderCell: (params: GridRenderCellParams) => {
                return params.row.History ? (
                    <Tooltip title="View History">
                        <Button startIcon={<WorkHistoryIcon />} onClick={() => openVeiwHistoryHandler(params.row.History)}></Button>
                    </Tooltip>
                ) : null;
            },
        },
        {
            field: 'Patient',
            headerName: 'Name',
            minWidth: 220,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? 'N/A' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'CHPDownloadDate',
            headerName: 'Download Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CHPDownloadDate) ? 'N/A' : dateFormatter(new Date(params.row.CHPDownloadDate))}`,
        },
        {
            field: 'AlertType',
            headerName: 'Alert Type',
            minWidth: 120,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'SendingFacility',
            headerName: 'Sending Facility',
            minWidth: 280,
        },
        {
            field: 'AdmitDate',
            headerName: 'Admission Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? 'N/A' : dateFormatter(new Date(params.row.AdmitDate))}`,
        },
        {
            field: 'AdmitTime',
            headerName: 'Admission Time',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${new Date(params.row.AdmitTime).toLocaleTimeString()}`,
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeDate) ? 'N/A' : dateFormatter(new Date(params.row.DischargeDate))}`,
        },
        {
            field: 'DischargeTime',
            headerName: 'Discharge Time',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${new Date(params.row.DischargeTime).toLocaleTimeString()}`,
        },
    ];

    return (
        <>
            <ConfirmationModal
                confirmationModalMessage={confirmationModalMessage}
                handleClose={closeConfirmationModalHandler}
                open={confirmationModalOpen}
                handleAction={unMatchSelectedMemberHandler}
                title={confirmationModalTitle}
                handleCancel={() => dispatch(setConfirmationModalOpen(false))}
            />
            <MatchedHistoryModal open={openHistory} handleClose={closeViewHistoryHandler} matchedHistory={matchedHistory} />
            <Box>
                <Grid display={'flex'} container p={5}>
                    <Grid item xs={2} p={2}>
                        <Typography variant="body1" marginBottom={2} fontWeight={'bold'}>
                            Search by Alert Download Date
                        </Typography>
                        <Stack>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <FormControl fullWidth>
                                    <DesktopDatePicker
                                        label="Start Date:"
                                        value={startDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            dispatch(setStartDate(newValue));
                                        }}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ marginY: 2 }}>
                                    <DesktopDatePicker
                                        label="End Date:"
                                        value={endDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            dispatch(setEndDate(newValue));
                                        }}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                            <Button
                                sx={{ marginY: 2 }}
                                className="button-120"
                                disabled={!startDate || !endDate}
                                variant="contained"
                                onClick={getAlertsByMatchedDatesHandler}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item p={2} xs={10}>
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                autoHeight
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.Id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default History;
