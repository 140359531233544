import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';
import { HraReferral } from './HraReferral.model';

export default interface IHraEducation extends BaseEntity {
    MemberId: string;
    Referrals: HraReferral[];
    EducatedTheMember: boolean;
    Notes: boolean;
    Status: StatusEnum;
}

export class HraEducation implements IHraEducation {
    Id: string;
    MemberId: string;
    Referrals: HraReferral[];
    EducatedTheMember: boolean;
    Notes: boolean;
    Status: StatusEnum;
    CanCloneMember: boolean;
    IsCompleted: boolean;

    constructor() {
        this.Referrals = [] as HraReferral[];
    }
}
