import {
    Avatar,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    FormLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AlertDialog from '../../AlertDialog/AlertDialog';
import {
    fetchInsuranceTypes,
    fetchMemberDemoGraphicInfo,
    saveMemberDemographicInfo,
    setSelectedMember,
    setShowLoginSetup,
} from '../../../store/memberDetail.slice';
import { fetchAllLanguages, fetchGenders, fetchMaritalStatuses } from '../../../store/shared.slice';
import React, { useEffect, useState } from 'react';
import MessageDisplay from '../../Shared/MessageDisplay';
import { fetchMemberStatuses, fetchRiskLevels, setShowMemberStatusChange } from '../../../store/members.slice';
import { getTCCRoleProviders } from '../../../store/provider.slice';
import { cloneMember as fuhfumClone } from '../../../store/fuhfum.slice';
import { cloneMember as hraClone } from '../../../store/hra.slice';
import { setShowMessage } from '../../../store/shared.slice';
import MemberStatusChange from '../MemberStatusChange';
import SSNMaskCustom from '../../Shared/SSNMask';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ERROR, SUCCESS } from '../../../utils/constants';
import LoginSetup from './LoginSetup';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { fetchEthnicities, fetchPreferredPronouns, fetchRaces, setShowEditMemberDemographics } from '../../../store/memberDemographicsDetails.slice';
import { isValidFirstName, isValidName } from '../../../utils/common';
import AddressDetail from '../../Shared/AddressDetail';
import Authorization from '../../../utils/Authorization';

const MemberDemographic: React.FC<{ isAssessment?: boolean; setSaveClickEvent?: Function }> = ({ isAssessment = false, setSaveClickEvent = null }) => {
    const dispatch = useDispatch();
    const auth = new Authorization();
    const { insuranceTypes, selectedMember, teams } = useSelector((state: RootState) => state.memberDetail);
    const { allLanguages, genders, maritalStatuses } = useSelector((state: RootState) => state.shared);
    const { memberStatuses, riskLevels } = useSelector((state: RootState) => state.members);
    const { tccProviders } = useSelector((state: RootState) => state.provider);
    const { isCloning: isCloningFuhFum } = useSelector((state: RootState) => state.fuhfum);
    const { isCloning: isCloningHra } = useSelector((state: RootState) => state.hra);
    const { ethnicities, preferredPronouns, races } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const [error, setError] = useState({ FirstName: '', LastName: '', MiddleName: '', DOB: '' });
    const [selectedFile, setSelectedFile] = useState();
    const [showSSN, setShowSSN] = useState(!selectedMember?.SSN);
    const [showClonePopUp, setShowClonePopUp] = useState<boolean>(false);
    const [agreesToFollowUp, setAgreesToFollowUp] = useState<boolean>(false);
    let selectedMemberStatusList = memberStatuses.filter((m) => m.value !== MemberStatusEnum.HRAUnd.toString());
    const isCloning = isCloningFuhFum || isCloningHra;
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [memberCopy] = useState(selectedMember);

    const racesOptions = selectedMember.Ethnicity === '1' ? races.filter((el) => el.value === '4') : races.filter((el) => el.value !== '4');

    const errorStyles = {
        [`& .MuiOutlinedInput-notchedOutline, & .Mui-focused`]: {
            borderColor: '#d32f2f!important',
        },
        '& .MuiInputLabel-root': {
            color: '#d32f2f',
        },
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    };

    const changePrimaryLanguage = (value) => {
        var update = { ...selectedMember };
        update.PrimaryLanguage = allLanguages.find((x) => x.Id === value);

        dispatch(setSelectedMember(update));
    };

    const ssnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let update = { ...selectedMember };
        update.SSN = event.target.value;

        dispatch(setSelectedMember(update));
    };

    const changeTeam = (value) => {
        var update = { ...selectedMember };
        update.Team = teams.find((x) => x.Id === value);

        dispatch(setSelectedMember(update));
    };

    const changeTccProvider = (value) => {
        var update = { ...selectedMember };
        if (value !== 'All') {
            update.TCCProvider = tccProviders.find((x) => x.Id === value);
        } else {
            update.TCCProvider = null;
        }
        dispatch(setSelectedMember(update));
    };

    const profileImageHandler = (event) => {
        if (event.target.files[0]) {
            setSelectedFile(event.target.files[0]);

            const url = window.URL.createObjectURL(event.target.files[0]);
            dispatch(setSelectedMember({ ...selectedMember, ProfileImageUrl: url }));
        }
    };

    const closeEditHandler = () => {
        dispatch(setShowEditMemberDemographics(false));
    };

    const cancel = () => {
        closeEditHandler();
    };

    const save = () => {
        if (valid()) {
            dispatch(saveMemberDemographicInfo(selectedMember, selectedFile));
            closeEditHandler();
        }
    };

    const valid = () => {
        if (error.FirstName || error.LastName || error.MiddleName || error.DOB) {
            setIsSubmitted(true);
            return false;
        } else {
            return true;
        }
    };

    const changeMemberStatus = (newStatus) => {
        dispatch(setShowMemberStatusChange(parseInt(newStatus), true));
    };

    useEffect(() => {
        if (isAssessment) {
            dispatch(fetchMemberDemoGraphicInfo(selectedMember.Id));
            dispatch(fetchInsuranceTypes());
            dispatch(fetchRiskLevels());
            dispatch(fetchGenders());
            dispatch(fetchMaritalStatuses());
            dispatch(fetchAllLanguages());
            dispatch(fetchMemberStatuses());
            dispatch(fetchPreferredPronouns());
            dispatch(fetchEthnicities());
            dispatch(fetchRaces());
            dispatch(getTCCRoleProviders(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAssessment]);

    useEffect(() => {
        if (setSaveClickEvent) {
            setSaveClickEvent(() => save);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMember, error]);

    useEffect(() => {
        setError({
            ...error,
            FirstName: !selectedMember.FirstName
                ? 'Required'
                : auth.Role !== 'CEO' && memberCopy.FirstName !== selectedMember.FirstName && !isValidFirstName(selectedMember.FirstName)
                ? 'First Name must be one word or hyphenated, with no spaces.'
                : auth.Role === 'CEO' && !isValidName(selectedMember.FirstName)
                ? 'First Name must have no leading/trailing spaces or special characters, except hyphen.'
                : '',
            LastName: !selectedMember.LastName
                ? 'Required'
                : !isValidName(selectedMember.LastName)
                ? 'Last Name must have no leading/trailing spaces or special characters, except hyphen.'
                : '',
            MiddleName:
                selectedMember.MiddleName && !isValidName(selectedMember.MiddleName)
                    ? 'Middle Name must have no leading/trailing spaces or special characters, except hyphen.'
                    : '',
            DOB: !selectedMember.DOB ? 'Required' : '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMember]);

    const handleOnAgreeToMember = () => {
        if (agreesToFollowUp) {
            if (selectedMember.IsInFuhFum) {
                dispatch(
                    fuhfumClone(selectedMember.Id, (isCloned: boolean) => {
                        if (isCloned) {
                            dispatch(fetchMemberDemoGraphicInfo(selectedMember.Id));
                            setShowClonePopUp(false);
                            dispatch(setShowMessage(true, 'The member has been cloned successfully!', SUCCESS));
                        } else {
                            setShowClonePopUp(false);
                            dispatch(setShowMessage(true, 'Error occured while cloning the member!', ERROR));
                        }
                    })
                );
            }
            if (selectedMember.IsInHra) {
                dispatch(
                    hraClone(selectedMember.Id, (isCloned: boolean) => {
                        if (isCloned) {
                            dispatch(fetchMemberDemoGraphicInfo(selectedMember.Id));
                            setShowClonePopUp(false);
                            dispatch(setShowMessage(true, 'The member has been cloned successfully!', SUCCESS));
                        } else {
                            setShowClonePopUp(false);
                            dispatch(setShowMessage(true, 'Error occured while cloning the member!', ERROR));
                        }
                    })
                );
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <MessageDisplay />
            <MemberStatusChange />
            <LoginSetup />
            <Grid item xs>
                <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                    <Divider style={{ marginBottom: '16px' }}>
                        <Chip label="Identifying Information" />
                    </Divider>
                    <Stack direction="row" spacing={2}>
                        <Stack spacing={2}>
                            <Avatar
                                alt={selectedMember?.FirstName + ' ' + selectedMember?.LastName}
                                src={selectedMember?.ProfileImageUrl}
                                sx={{ width: 144, height: 144 }}
                                className="profile-image"
                            />
                            <Button variant="contained" component="label">
                                Upload <input type="file" hidden onChange={profileImageHandler} />
                            </Button>
                        </Stack>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        error={isSubmitted && Boolean(error.FirstName)}
                                        helperText={isSubmitted && error.FirstName}
                                        fullWidth
                                        label="First Name"
                                        variant="outlined"
                                        value={selectedMember?.FirstName}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, FirstName: e.target.value }));
                                        }}
                                    />
                                    <TextField
                                        error={isSubmitted && Boolean(error.MiddleName)}
                                        helperText={isSubmitted && error.MiddleName}
                                        fullWidth
                                        label="Middle Name"
                                        variant="outlined"
                                        value={selectedMember?.MiddleName ? selectedMember?.MiddleName : ''}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, MiddleName: e.target.value }));
                                        }}
                                    />
                                    <TextField
                                        error={isSubmitted && Boolean(error.LastName)}
                                        helperText={isSubmitted && error.LastName}
                                        fullWidth
                                        label="Last Name"
                                        variant="outlined"
                                        value={selectedMember?.LastName}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, LastName: e.target.value }));
                                        }}
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Preferred Name"
                                        variant="outlined"
                                        value={selectedMember?.PreferredName ? selectedMember?.PreferredName : ''}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, PreferredName: e.target.value }));
                                        }}
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <FormControl fullWidth sx={error.DOB ? errorStyles : {}}>
                                            <DatePicker
                                                label="DOB"
                                                renderInput={(params) => (
                                                    <TextField
                                                        error={isSubmitted && Boolean(error.DOB)}
                                                        helperText={isSubmitted && error.DOB}
                                                        sx={{ width: '100%' }}
                                                        {...params}
                                                    />
                                                )}
                                                onChange={(e: any) => {
                                                    dispatch(setSelectedMember({ ...selectedMember, DOB: e }));
                                                }}
                                                value={selectedMember.DOB}
                                            />
                                        </FormControl>
                                    </LocalizationProvider>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Primary Language</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Primary Language"
                                            value={selectedMember.PrimaryLanguage?.Id || ''}
                                            onChange={(e: any) => {
                                                changePrimaryLanguage(e.target.value);
                                            }}
                                        >
                                            {allLanguages.map(function (language) {
                                                return (
                                                    <MenuItem key={language.Id} value={language.Id}>
                                                        {language.Name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Gender"
                                            value={selectedMember.Gender ? selectedMember.Gender : ''}
                                            onChange={(e: any) => {
                                                dispatch(setSelectedMember({ ...selectedMember, Gender: e.target.value }));
                                            }}
                                        >
                                            {genders.map(function (option) {
                                                return (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    {showSSN ? (
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel htmlFor="formatted-text-mask-input">SSN</InputLabel>
                                            <Input
                                                value={selectedMember.SSN}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    ssnChange(e);
                                                }}
                                                inputComponent={SSNMaskCustom as any}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                setShowSSN(false);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <Visibility />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    ) : (
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel htmlFor="formatted-text-mask-input">SSN</InputLabel>
                                            <Input
                                                disabled={true}
                                                value={selectedMember?.SSN ? 'XXX-XX-' + selectedMember?.SSN.substring(selectedMember.SSN.length - 4) : ''}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                setShowSSN(true);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <VisibilityOff />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    )}
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Marital Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedMember.MaritalStatus || ''}
                                            label="Marital Status"
                                            onChange={(e: any) => {
                                                dispatch(setSelectedMember({ ...selectedMember, MaritalStatus: e.target.value }));
                                            }}
                                        >
                                            {maritalStatuses.map(function (option) {
                                                return (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </Stack>
                    <FormGroup>
                        <Stack direction={'row'} spacing={2} marginY={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Preferred Pronouns</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Primary Language"
                                    value={selectedMember.PreferredPronouns || ''}
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, PreferredPronouns: parseInt(e.target.value) }));
                                    }}
                                >
                                    {preferredPronouns.map(function (pronoun) {
                                        return (
                                            <MenuItem key={pronoun.value} value={pronoun.value}>
                                                {pronoun.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {selectedMember?.PreferredPronouns === 4 ? (
                                <FormControl fullWidth>
                                    <TextField
                                        fullWidth
                                        label="Preferred Pronouns Other"
                                        variant="outlined"
                                        value={selectedMember?.PreferredPronounsOther || ''}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, PreferredPronounsOther: e.target.value }));
                                        }}
                                    />
                                </FormControl>
                            ) : null}

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Ethnicity</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedMember.Ethnicity}
                                    label="Ethnicity"
                                    onChange={(e: any) => {
                                        if (e.target.value === '1') {
                                            dispatch(setSelectedMember({ ...selectedMember, Ethnicity: e.target.value }));
                                        } else {
                                            dispatch(setSelectedMember({ ...selectedMember, Ethnicity: e.target.value }));
                                        }
                                    }}
                                >
                                    {ethnicities.map(function (option) {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Race</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedMember.Race}
                                    label="Race"
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, Race: e.target.value }));
                                    }}
                                >
                                    {racesOptions.map(function (option) {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </FormGroup>
                </Paper>
                <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                    <Divider style={{ marginBottom: '16px' }}>
                        <Chip label="Address Information" />
                    </Divider>
                    <Stack style={{ width: '100%' }} spacing={2}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={selectedMember.IsHomeless} />}
                                onChange={(e: any) => {
                                    dispatch(setSelectedMember({ ...selectedMember, IsHomeless: e.target.checked }));
                                }}
                                label="Is Currently Homeless"
                            />
                        </FormGroup>
                        <AddressDetail address={selectedMember} onChange={(data) => dispatch(setSelectedMember(data))} />
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={selectedMember?.Email ? selectedMember?.Email : ''}
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, Email: e.target.value }));
                                    }}
                                />
                            </Stack>
                        </FormGroup>
                    </Stack>
                    <Stack style={{ width: '100%' }} spacing={2}>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={selectedMember.IsDifferMeetingAddress} />}
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, IsDifferMeetingAddress: e.target.checked }));
                                    }}
                                    label="Different Meeting Address"
                                />
                            </Stack>
                        </FormGroup>
                        {selectedMember.IsDifferMeetingAddress ? (
                            <>
                                <FormGroup>
                                    <Divider style={{ marginBottom: '16px' }}>
                                        <Chip label="Different Meeting Address Information" />
                                    </Divider>
                                </FormGroup>
                                <AddressDetail
                                    address={selectedMember.MeetingAddress}
                                    onChange={(data) => dispatch(setSelectedMember({ ...selectedMember, MeetingAddress: data }))}
                                />
                            </>
                        ) : null}
                    </Stack>
                    {selectedMember.IsInFuhFum ? (
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedMember.IsDifferDischargingAdress} />}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, IsDifferDischargingAdress: e.target.checked }));
                                        }}
                                        label="Different Discharging Address"
                                    />
                                </Stack>
                            </FormGroup>
                            {selectedMember.IsDifferDischargingAdress ? (
                                <AddressDetail
                                    address={selectedMember.DischargingAddress}
                                    onChange={(data) => dispatch(setSelectedMember({ ...selectedMember, DischargingAddress: data }))}
                                />
                            ) : null}
                        </Stack>
                    ) : null}
                </Paper>
                <Paper elevation={3} style={{ padding: '8px', margin: '16px', marginBottom: '50px', textAlign: 'left' }}>
                    <Divider style={{ marginBottom: '16px' }}>
                        <Chip label="Other Information" />
                    </Divider>
                    <Stack style={{ width: '100%' }} spacing={2}>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Risk Level</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedMember.RiskLevel ? selectedMember.RiskLevel : ''}
                                        label="Risk Level"
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, RiskLevel: e.target.value }));
                                        }}
                                    >
                                        {riskLevels.map(function (option) {
                                            return (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Risk Score"
                                    variant="outlined"
                                    value={selectedMember?.RiskScore ? selectedMember?.RiskScore : ''}
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, RiskScore: e.target.value }));
                                    }}
                                />
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedMember.TwoStaff} />}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, TwoStaff: e.target.checked }));
                                        }}
                                        label="Two Staff"
                                    />
                                </FormControl>
                            </Stack>
                        </FormGroup>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Career"
                                    variant="outlined"
                                    value={selectedMember?.Career ? selectedMember?.Career : ''}
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, Career: e.target.value }));
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Hobbies"
                                    variant="outlined"
                                    value={selectedMember?.Hobbies ? selectedMember?.Hobbies : ''}
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, Hobbies: e.target.value }));
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="My Favorites"
                                    variant="outlined"
                                    value={selectedMember?.MyFavorites ? selectedMember?.MyFavorites : ''}
                                    onChange={(e: any) => {
                                        dispatch(setSelectedMember({ ...selectedMember, MyFavorites: e.target.value }));
                                    }}
                                />
                            </Stack>
                        </FormGroup>
                    </Stack>
                </Paper>
            </Grid>
            {isAssessment ? null : (
                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Dates & Insurance" />
                        </Divider>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Insurance Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedMember?.InsuranceType}
                                            label="Insurance Type"
                                            onChange={(e: any) => {
                                                dispatch(setSelectedMember({ ...selectedMember, InsuranceType: e.target.value }));
                                            }}
                                        >
                                            {insuranceTypes.map(function (option) {
                                                return (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedMember.Status}
                                            label="Member Status"
                                            onChange={(e: any) => {
                                                changeMemberStatus(e.target.value);
                                            }}
                                        >
                                            {selectedMemberStatusList.map(function (option) {
                                                return (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date of Onboard"
                                            renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                            onChange={(e: any) => {
                                                dispatch(setSelectedMember({ ...selectedMember, DateOfOnboard: e }));
                                            }}
                                            value={selectedMember.DateOfOnboard}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="End of Date of Service"
                                            renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                            onChange={(e: any) => {
                                                dispatch(setSelectedMember({ ...selectedMember, EndOfDateOfService: e }));
                                            }}
                                            value={selectedMember.EndOfDateOfService}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Last PCP Visit"
                                            renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                            onChange={(e: any) => {
                                                dispatch(setSelectedMember({ ...selectedMember, LastPCPVisit: e }));
                                            }}
                                            value={selectedMember.LastPCPVisit}
                                        />
                                    </LocalizationProvider>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Primary Team</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedMember.Team?.Id}
                                            label="Primary Team"
                                            onChange={(e: any) => {
                                                changeTeam(e.target.value);
                                            }}
                                        >
                                            {teams.map(function (team) {
                                                return (
                                                    <MenuItem key={team.Id} value={team.Id}>
                                                        {team.Name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </Paper>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Tablet Information" />
                        </Divider>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Tablet ID"
                                        variant="outlined"
                                        value={selectedMember?.TabletId ? selectedMember?.TabletId : ''}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, TabletId: e.target.value }));
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="SIM"
                                        variant="outlined"
                                        value={selectedMember?.Sim ? selectedMember?.Sim : ''}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, Sim: e.target.value }));
                                        }}
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date of Delivery"
                                            renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                            onChange={(e: any) => {
                                                dispatch(setSelectedMember({ ...selectedMember, DateOfDelivery: e }));
                                            }}
                                            value={selectedMember.DateOfDelivery}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        fullWidth
                                        label="Internet Connectivity"
                                        variant="outlined"
                                        value={selectedMember?.InternetConnectivity ? selectedMember?.InternetConnectivity : ''}
                                        onChange={(e: any) => {
                                            dispatch(setSelectedMember({ ...selectedMember, InternetConnectivity: e.target.value }));
                                        }}
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Button
                                    className="button-120"
                                    variant="contained"
                                    onClick={() => {
                                        dispatch(setShowLoginSetup(true));
                                    }}
                                >
                                    Login Setup
                                </Button>
                            </FormGroup>
                        </Stack>
                    </Paper>
                    {selectedMember.IsInFuhFum ? (
                        <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                            <Divider style={{ marginBottom: '16px' }}>
                                <Chip label="FUHFUM Information" />
                            </Divider>
                            <Stack style={{ width: '100%' }} spacing={2}>
                                <FormGroup>
                                    <Stack direction="row" spacing={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">TCC Provider</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedMember.TCCProvider ? selectedMember.TCCProvider.Id : 'All'}
                                                label="TCC Provider"
                                                onChange={(e: any) => {
                                                    changeTccProvider(e.target.value);
                                                }}
                                            >
                                                <MenuItem key={'All'} value={'All'}>
                                                    Select
                                                </MenuItem>
                                                {tccProviders.map(function (Pro) {
                                                    return (
                                                        <MenuItem key={Pro.Id} value={Pro.Id}>
                                                            {Pro.LastName}, {Pro.FirstName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </FormGroup>
                                <FormGroup>
                                    <Button
                                        disabled={!selectedMember.CanClone}
                                        className="button-120"
                                        variant="contained"
                                        onClick={() => {
                                            setShowClonePopUp(true);
                                        }}
                                    >
                                        Clone Member
                                    </Button>
                                </FormGroup>
                            </Stack>
                        </Paper>
                    ) : null}
                    {selectedMember.IsInHra ? (
                        <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                            <Divider style={{ marginBottom: '16px' }}>
                                <Chip label="HRA Information" />
                            </Divider>
                            <Stack style={{ width: '100%' }} spacing={2}>
                                <FormGroup>
                                    <Button
                                        disabled={!selectedMember.CanClone}
                                        className="button-120"
                                        variant="contained"
                                        onClick={() => {
                                            setShowClonePopUp(true);
                                        }}
                                    >
                                        Clone Member
                                    </Button>
                                </FormGroup>
                            </Stack>
                        </Paper>
                    ) : null}
                    <Stack direction="row" spacing={2} style={{ float: 'right', margin: '16px' }}>
                        <Button className="button-120" variant="contained" onClick={save}>
                            Save Member
                        </Button>
                        <Button className="button-120" variant="outlined" onClick={cancel}>
                            Cancel
                        </Button>
                    </Stack>
                </Grid>
            )}
            <AlertDialog
                open={showClonePopUp}
                title="CHP Full Program"
                cancelText="Go Back"
                okText="Confirm"
                isLoading={isCloning}
                onConfirm={() => handleOnAgreeToMember()}
                onClose={() => {
                    setShowClonePopUp(false);
                }}
                disabledSubmit={!agreesToFollowUp || isCloning}
                renderBody={() => (
                    <Stack>
                        <FormLabel>Do you want to proceed with enrolling the member in the CHP full program?</FormLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreesToFollowUp}
                                    onChange={(e) => {
                                        setAgreesToFollowUp(e.target.checked);
                                    }}
                                />
                            }
                            label="Member agrees to follow-up"
                        />
                    </Stack>
                )}
            />
        </Grid>
    );
};

export default MemberDemographic;
