import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Grid,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useSelector } from 'react-redux';

import { useWidgetContext } from '../../../hooks/widgetContext';
import { MenuProps, exportEngagementSummary, fetchEngagementSummaryChart } from '../../Widgets/WidgetService';
import { parentUrl } from '../../../utils/constants';
import { RootState } from '../../../reducers';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import EngagementSummary from '../../../Models/Widget/EngagementSummary.model';
import b64toBlob from 'b64-to-blob';
import '../Widgets.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const dateFilterList = [
    { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
    { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
    { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
    { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
];

const EngagementSummaryDetail = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(true);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const [engagementSummary, setEngagementSummary] = useState<EngagementSummary>();
    const { engagementSummaryFilter, setEngagementSummaryFilter } = useWidgetContext();
    const filteredTeams = (engagementSummaryFilter.showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders =
        (engagementSummaryFilter.showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    useEffect(() => {
        if (
            engagementSummaryFilter.dateFilter !== TimePeriodFilterEnum.Custom ||
            (Boolean(engagementSummaryFilter.fromDate) && Boolean(engagementSummaryFilter.toDate))
        ) {
            const changeFilter = async () => {
                setIsMembersLoading(true);
                let start = engagementSummaryFilter.fromDate !== null ? new Date(engagementSummaryFilter.fromDate).toDateString() : null;
                let end = engagementSummaryFilter.toDate !== null ? new Date(engagementSummaryFilter.toDate).toDateString() : null;

                const data = await fetchEngagementSummaryChart(
                    engagementSummaryFilter.provider,
                    engagementSummaryFilter.team,
                    engagementSummaryFilter.dateFilter,
                    start,
                    end,
                    true,
                    engagementSummaryFilter.showInActiveTeams,
                    engagementSummaryFilter.showInActiveProviders
                );
                setEngagementSummary(data);
                setIsMembersLoading(false);
            };
            changeFilter();
        }
    }, [engagementSummaryFilter, setEngagementSummaryFilter]);

    const changeFromDate = (date: any) => {
        let filter = engagementSummaryFilter;
        filter.fromDate = date;
        let dateInfo = new Date(date);
        if (!Boolean(engagementSummaryFilter.toDate) || new Date(engagementSummaryFilter.toDate) <= dateInfo) {
            filter.toDate = dateInfo.setDate(dateInfo.getDate() + 30);
        }
        filter.dateFilter = TimePeriodFilterEnum.Custom;
        setEngagementSummaryFilter({ ...filter });
    };

    const changeToDate = (date: any) => {
        let filter = engagementSummaryFilter;
        filter.toDate = date;
        setEngagementSummaryFilter({ ...engagementSummaryFilter, toDate: date });
        let dateInfo = new Date(date);
        if (!Boolean(engagementSummaryFilter.fromDate) || new Date(engagementSummaryFilter.fromDate) >= dateInfo) {
            filter.fromDate = dateInfo.setDate(dateInfo.getDate() - 30);
        }
        filter.dateFilter = TimePeriodFilterEnum.Custom;
        setEngagementSummaryFilter({ ...filter });
    };

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let start = engagementSummaryFilter.fromDate !== null ? new Date(engagementSummaryFilter.fromDate).toDateString() : null;
        let end = engagementSummaryFilter.toDate !== null ? new Date(engagementSummaryFilter.toDate).toDateString() : null;
        const data = await exportEngagementSummary(
            engagementSummaryFilter.provider,
            engagementSummaryFilter.team,
            engagementSummaryFilter.dateFilter,
            start,
            end,
            engagementSummaryFilter.showInActiveTeams,
            engagementSummaryFilter.showInActiveProviders
        );

        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `EnagagementSummary - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        let filter = engagementSummaryFilter;
        filter.showInActiveTeams = isChecked;
        filter.team = 'All';
        setEngagementSummaryFilter({ ...filter });
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        let filter = engagementSummaryFilter;
        filter.showInActiveProviders = isChecked;
        filter.provider = 'All';
        setEngagementSummaryFilter({ ...filter });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Engagement Visits Summary
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={engagementSummaryFilter.provider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setEngagementSummaryFilter({ ...engagementSummaryFilter, provider: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={
                                        <Checkbox
                                            checked={engagementSummaryFilter.showInActiveProviders}
                                            onChange={(e) => toggleProviderHandler(e.target.checked)}
                                        />
                                    }
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select
                                label="Team"
                                name="Team"
                                onChange={(e) => setEngagementSummaryFilter({ ...engagementSummaryFilter, team: e.target.value })}
                                value={engagementSummaryFilter.team}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={
                                        <Checkbox checked={engagementSummaryFilter.showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />
                                    }
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={engagementSummaryFilter.dateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setEngagementSummaryFilter({ ...engagementSummaryFilter, dateFilter: parseInt(e.target.value as any) })}
                            >
                                {dateFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {engagementSummaryFilter.dateFilter === TimePeriodFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={engagementSummaryFilter.fromDate || null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={engagementSummaryFilter.toDate || null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs />
                    <Grid item xs={1}>
                        <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                            {downLoading ? 'Exporting...' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {isMembersLoading ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            InPerson Visit Members ({engagementSummary.InPersonVisitMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Visit Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {engagementSummary.InPersonVisitMembers.length ? (
                                                    engagementSummary.InPersonVisitMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Telemedicine Visit Members ({engagementSummary.TelemedicineVisitMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Visit Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {engagementSummary.TelemedicineVisitMembers.length ? (
                                                    engagementSummary.TelemedicineVisitMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Telephonic Visit Members ({engagementSummary.TelephonicVisitMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Visit Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {engagementSummary.TelephonicVisitMembers.length ? (
                                                    engagementSummary.TelephonicVisitMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default EngagementSummaryDetail;
