import { Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import Population from '../../../Models/Population/Population.model';

import { useEffect, useState } from 'react';
import { setSelectedPopulation, setShowEditPopulation, setShowInactivePopulations } from '../../../store/adminOnboarding.slice';

import { dateFormatter } from '../../../utils/timeFormat';
import { isDefaultDate } from '../../../utils/common';
import EditPopulationModal from './EditPopulationModal';
import moment from 'moment';

export const PopulationGoals = (props: any) => {
    const dispatch = useDispatch();
    const { fetchingReports, populations, showInactivePopulations } = useSelector((state: RootState) => state.adminOnboardingSlice);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setShowInactivePopulations(event.target.checked));
    };

    const checkIfActive = (start: Date, end: Date) => {
        const momentToday = moment();
        const startDate = new Date(start);
        const endDate = new Date(end);

        if (momentToday.isBetween(startDate, endDate, 'days', '[]')) {
            return true;
        }
    };

    const activePopulations = populations.filter((p) => checkIfActive(p.OnboardingStartDate, p.OnboardingDeadlineDate));

    const openEditPopulation = (population: Population) => {
        dispatch(setSelectedPopulation(population));
        dispatch(setShowEditPopulation(true));
    };

    const columns: GridColDef[] = [
        {
            field: 'PopulationName',
            headerName: 'Population Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'PopulationSize',
            headerName: 'Population Size',
            minWidth: 160,
        },
        {
            field: 'UndeterminedMembersCount',
            headerName: 'Undetermined Members',
            minWidth: 180,
        },
        {
            field: 'ScheduledMembersCount',
            headerName: 'Currently Scheduled',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        <Typography>{params.row.ScheduledMembersCount ?? 0}</Typography>
                    </Stack>
                );
            },
        },
        {
            field: 'OnboardedIndividualsGoal',
            headerName: 'Members Onboarded Goal',
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        <Typography fontWeight={'bold'}>{params.row.OnboardedIndividualsGoal ?? 0}</Typography>
                    </Stack>
                );
            },
        },
        {
            field: 'OnboardingStartDate',
            headerName: 'Onboarding Start',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.OnboardingStartDate) ? '-' : dateFormatter(new Date(params.row.OnboardingStartDate))}`,
        },
        {
            field: 'OnboardingDeadlineDate',
            headerName: 'Onboarding End',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.OnboardingDeadlineDate) ? '-' : dateFormatter(new Date(params.row.OnboardingDeadlineDate))}`,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Stack direction={'row'} spacing={2} sx={{ cursor: 'pointer' }}>
                        <Tooltip title={`Edit ${params.row.PopulationName}`} placement="bottom">
                            <EditIcon color="primary" onClick={() => openEditPopulation(params.row)} />
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 78 : 82) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <EditPopulationModal />
            <Grid container justifyContent={'flex-end'} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Population Goals and Dates</Typography>
                </Grid>
                <Grid item xs={3} textAlign={'right'}>
                    <FormControlLabel
                        control={<Checkbox disabled={fetchingReports} checked={showInactivePopulations} onChange={handleChange} />}
                        sx={{ mr: 5 }}
                        label="Show Inactive Populations"
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ width: '100%', height: '62vh' }}>
                        {fetchingReports ? (
                            <CircularProgress style={{ marginTop: '50px' }} size={30} color="primary" />
                        ) : (
                            <DataGrid
                                rows={showInactivePopulations ? populations : activePopulations}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[5]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                getRowId={(row) => row.Id}
                                onRowClick={(params) => openEditPopulation(params.row)}
                                getRowHeight={() => 'auto'}
                                getRowClassName={(params) => {
                                    const isActive = activePopulations.find((el) => el.Id === params.row.Id);
                                    return isActive ? 'active' : 'inactive';
                                }}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                    '.inactive': {
                                        color: 'rgba(0,0,0,0.27)',
                                    },
                                }}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </>
    );
};
