import { Dialog, DialogTitle, Grid, Stack, Tabs, Tab, TextField, Button } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

const RegionsDialog = (props) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
            <Grid container p={3}>
                <Grid xs={12} item>
                    <Stack marginBottom={2} direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        {!props.isNewRegion && <DialogTitle>Edit Region - {props.regionData.Name}</DialogTitle>}
                        {props.isNewRegion && (
                            <TextField
                                variant="outlined"
                                required
                                autoFocus
                                error={props.helperText.length !== 0}
                                helperText={props.helperText}
                                label="Enter Region Name"
                                value={props.regionData.Name}
                                onChange={props.nameOnChange}
                            />
                        )}
                        {props.isNewRegion && (
                            <Button
                                disabled={props.regionData.Name.length === 0}
                                sx={{ midWidth: '120px', marginLeft: 'auto', marginRight: '8px' }}
                                variant={'contained'}
                                onClick={props.addRegion}
                            >
                                Save
                            </Button>
                        )}
                        <Button sx={{ midWidth: '120px' }} variant={!props.isNewRegion ? 'contained' : 'outlined'} onClick={props.onClose}>
                            {!props.isNewRegion ? 'Close' : 'Cancel'}
                        </Button>
                    </Stack>
                </Grid>

                <Grid xs={12} item>
                    <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                        <Tabs value={props.currentTab} onChange={props.tabsHandler}>
                            <Tab disabled={props.isNewRegion} label="Providers" value="Provider" />
                            <Tab disabled={props.isNewRegion} label="Facilities" value="Facility" />
                        </Tabs>
                        {!props.isNewRegion && (
                            <Button
                                disabled={props.isNewRegion}
                                sx={{ midWidth: '120px', marginBottom: 2 }}
                                variant={'contained'}
                                onClick={props.providerFacilityOpen}
                            >
                                Add {props.currentTab}
                            </Button>
                        )}
                    </Stack>
                </Grid>
                <Grid xs={12} item>
                    <div style={{ height: '65vh', width: '100%' }}>
                        <DataGrid
                            rows={props.rows}
                            columns={props.columns}
                            pageSize={props.pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={props.handleWindowResize}
                            getRowId={(row) => row.Id || row.ProviderId}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default RegionsDialog;
