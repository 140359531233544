import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import Loading from '../../Shared/Loading';
import ConfirmDelete from '../../Shared/ConfirmDelete';
import BasicContactInfoDialog from '../BasicContactInfoDialog';
import SFTPDialog from './SFTPDialog';
import { useState } from 'react';
import {
    closeDeleteContactHandler,
    deleteContactHandler,
    openDeleteContactHandler,
    saveContactInfoHandler,
    setContactInfoTitle,
    setContactToDelete,
    setCurrentContact,
    setCurrentContactList,
    setOpenContactInfo,
    setOpenDeleteContact,
    setSftpAddress,
    setSftpAlert,
} from '../../../store/adminHealthPlan.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

const DataFeed = (props) => {
    const { contactInfoTitle, contactToDelete, currentContact, currentContactList, healthPlanForm, openContactInfo, openDeleteContact, sftpAddress } =
        useSelector((state: RootState) => state.adminHealthPlanSlice);

    const dispatch = useDispatch();

    const getDefaultContact = () => {
        return {
            Address: '',
            Email: '',
            Id: '',
            Name: '',
            PhoneNumber: '',
        };
    };

    const getDefaultSFTPAddress = () => {
        return {
            AlertContacts: [],
            AlertFrequency: [],
            FilePath: '',
            FileType: 1,
            HostName: '',
            Id: '',
            Password: '',
            PortNumber: 22,
            TransferMode: 0,
            UserName: '',
        };
    };

    const [openSFTPDialog, setOpenSFTPDialog] = useState(false);

    const addSFTPAddressHandler = () => {
        dispatch(setSftpAddress(getDefaultSFTPAddress()));
        dispatch(setSftpAlert(false));
        dispatch(setContactInfoTitle('SFTP - SSH File Transfer Protocol'));
        dispatch(setCurrentContactList('SftpAddress'));
        setOpenSFTPDialog(true);
    };

    const editSFTPDialogHandler = (params) => {
        dispatch(setSftpAlert(false));
        dispatch(setSftpAddress(params.row));
        dispatch(setContactInfoTitle(params.row.HostName));
        setOpenSFTPDialog(true);
    };

    const closeSFTPDialogHandler = () => {
        dispatch(setSftpAlert(false));
        setOpenSFTPDialog(false);
    };

    const inputOnChange = (e) => {
        dispatch(setSftpAddress({ ...sftpAddress, [e.target.name]: e.target.value }));
    };

    const editContactInfoHandler = (params) => {
        dispatch(setCurrentContact(params.row));
        dispatch(setContactInfoTitle(params.row.Name));
        dispatch(setOpenContactInfo(true));
    };

    const addCPContactHandler = () => {
        dispatch(setContactInfoTitle('Contact Person'));
        dispatch(setCurrentContact(getDefaultContact()));
        dispatch(setCurrentContactList('ContactPerson'));
        dispatch(setOpenContactInfo(true));
    };

    const openDeleteSFTPHandler = (params) => {
        dispatch(setContactToDelete({ Name: params.HostName, Id: params.Id }));
        dispatch(setOpenDeleteContact(true));
    };

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 1,
        },
        {
            field: 'PhoneNumber',
            headerName: 'Phone Number',
            flex: 1,
            // valueGetter: (params: GridValueGetterParams) => `${phoneFormat(params.row.PhoneNumber)}`,
        },
        {
            field: 'Email',
            headerName: 'Email',
            flex: 1.5,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit Contact" placement="bottom">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => editContactInfoHandler(params)} />
                        </Tooltip>
                        <Tooltip title="Delete Contact" placement="top">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(openDeleteContactHandler(params.row))}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const sftpColumns: GridColDef[] = [
        {
            field: 'HostName',
            headerName: 'Host Name',
            flex: 1,
        },
        {
            field: 'TransferMode',
            headerName: 'Transfer Mode',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.TransferMode === 0 ? 'Default' : params.row.TransferMode === 1 ? 'Active' : params.row.TransferMode === 2 ? 'Passive' : ''}`,
        },
        {
            field: 'PortNumber',
            headerName: 'Port Number',
            flex: 1,
        },
        {
            field: 'UserName',
            headerName: 'User Name',
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit SFTP Address" placement="bottom">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => editSFTPDialogHandler(params)} />
                        </Tooltip>
                        <Tooltip title="Delete SFTP Address" placement="top">
                            <CancelIcon color="error" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => openDeleteSFTPHandler(params.row)} />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {<Loading message={'Loading...'} />}

            <ConfirmDelete
                open={openDeleteContact}
                onClose={() => dispatch(closeDeleteContactHandler())}
                name={contactToDelete.Name}
                delete={() => dispatch(deleteContactHandler(healthPlanForm, contactToDelete))}
                cancel={() => dispatch(closeDeleteContactHandler())}
            />

            <BasicContactInfoDialog
                open={openContactInfo}
                onClose={() => dispatch(setOpenContactInfo(false))}
                healthPlanForm={healthPlanForm}
                contactInfoTitle={contactInfoTitle}
                inputOnChange={inputOnChange}
                currentContact={currentContact}
                onSave={() => dispatch(saveContactInfoHandler(healthPlanForm, currentContact, currentContactList))}
            />

            <SFTPDialog
                open={openSFTPDialog}
                onClose={closeSFTPDialogHandler}
                healthPlanForm={healthPlanForm}
                contactInfoTitle={contactInfoTitle}
                addCPContactHandler={addCPContactHandler}
            />

            <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                <Typography display={'inline-flex'} variant="h5">
                    Contact Persons
                </Typography>
                <Button variant="contained" onClick={() => addCPContactHandler()}>
                    Add Contact Person
                </Button>
            </Grid>

            <Grid item xs={12}>
                {healthPlanForm.ContactPerson?.length > 0 && (
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={healthPlanForm.ContactPerson || []}
                            columns={columns}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            autoHeight
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                )}
            </Grid>

            <Grid item xs={12} marginTop={5} display="flex" alignItems="center" justifyContent="space-between">
                <Typography display={'inline-flex'} variant="h5">
                    SFTP
                </Typography>
                <Button sx={{ minWidth: '120px' }} variant="contained" onClick={() => addSFTPAddressHandler()}>
                    Add SFTP
                </Button>
            </Grid>
            <Grid item xs={12}>
                {healthPlanForm.SftpAddress?.length > 0 && (
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={healthPlanForm?.SftpAddress || []}
                            columns={sftpColumns}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            autoHeight
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                )}
            </Grid>
        </>
    );
};

export default DataFeed;
