import { Eventcalendar, MbscResource } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from '../../Models/Provider/Provider.model';
import { RootState } from '../../reducers';
import {
    fetchSwappableLists,
    setEndDateBoundary,
    setProviderOne,
    setProviderOneSwapSelectionList,
    setProviderTwo,
    setProviderTwoSwapSelectionList,
    setStartDateBoundary,
} from '../../store/providerRotation.slice';
import { ROLE_NAMES, apiString } from '../../utils/constants';
import Loading from '../Shared/Loading';
import MessageDisplay from '../Shared/MessageDisplay';
import AppointmentSwap from './AppointmentSwap/AppointmentSwap';
import { IProviderRotationOption } from './interfaces/IProviderRotationOption';

const ProviderRotation = () => {
    const dispatch = useDispatch();
    const calendarRef = React.useRef(null);
    const { events, providerOne, providerTwo, startDateBoundary, endDateBoundary } = useSelector((state: RootState) => state.providerRotationSlice);
    const [providerOneInputValue, setProviderOneInputValue] = React.useState('');
    const [providerTwoInputValue, setProviderTwoInputValue] = React.useState('');
    const [myResources, setMyResources] = React.useState<MbscResource[]>([]);
    const [providerOptions, setProviderOptions] = React.useState<IProviderRotationOption[]>([]);
    const providerTwoOptions = useMemo(() => {
        if (providerOptions?.length > 0 && providerOne?.ProviderId?.length > 0) {
            let filteredOptions = providerOptions.filter((option) => option.ProviderId !== providerOne?.ProviderId);
            filteredOptions = filteredOptions.filter((option) => option.Role !== providerOne?.Role);
            return filteredOptions;
        } else return [];
    }, [providerOne?.ProviderId, providerOne?.Role, providerOptions]);

    useEffect(() => {
        dispatch(setProviderOne(null));
        dispatch(setProviderTwo(null));
        axios.get(`${apiString}/Provider/getactiveproviders`).then((response) => {
            if (response.status === 200) {
                let responseOptions = response.data.map((provider: Provider) => ({
                    ProviderId: provider.Id,
                    ProviderNameWithRole: `${provider.LastName}, ${provider.FirstName} (${provider.Role.RoleName})`,
                    ProviderName: `${provider.LastName}, ${provider.FirstName}`,
                    Role: provider.Role.RoleName,
                }));
                responseOptions = responseOptions.filter(
                    (option) => option.Role === ROLE_NAMES.NURSE_PRACTITIONER || option.Role === ROLE_NAMES.BEHAVIOURAL_HEALTH_SPECIALIST
                );
                setProviderOptions(responseOptions);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        let resources: MbscResource[] = [];
        if (providerOne) {
            resources.push({ id: providerOne.ProviderId, name: providerOne.ProviderNameWithRole, color: '#f4511e', eventCreation: false });
        }
        if (providerTwo) {
            resources.push({ id: providerTwo.ProviderId, name: providerTwo.ProviderNameWithRole, color: '#f4512e', eventCreation: false });
        }
        setMyResources(resources);
    }, [providerOne, providerTwo]);

    const loadSelectedSchedules = useCallback(async () => {
        dispatch(fetchSwappableLists());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerOne, providerTwo, dispatch]);

    useEffect(() => {
        dispatch(setProviderOneSwapSelectionList([]));
        dispatch(setProviderTwoSwapSelectionList([]));
        loadSelectedSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSelectedSchedules, startDateBoundary, endDateBoundary]);

    return (
        <Box p={2}>
            <MessageDisplay />
            <Loading message={'Loading schedules'} />
            <Stack spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    <Autocomplete
                        fullWidth
                        id="calendar-internal-provider-selection"
                        multiple={false}
                        options={providerOptions}
                        isOptionEqualToValue={(option, value) => {
                            return option.ProviderId === value.ProviderId;
                        }}
                        renderInput={(params) => <TextField {...params} label="Select NP or BH" />}
                        value={providerOne}
                        onChange={async (event: any, newValue: any) => {
                            dispatch(setProviderOne(newValue));
                            dispatch(setProviderTwo(null));
                        }}
                        inputValue={providerOneInputValue}
                        onInputChange={(event, value) => {
                            setProviderOneInputValue(value);
                        }}
                        getOptionLabel={(option: any) => option.ProviderNameWithRole}
                    />
                    <Autocomplete
                        fullWidth
                        id="provider-two-selection"
                        multiple={false}
                        disabled={providerOne === null}
                        options={providerTwoOptions}
                        isOptionEqualToValue={(option, value) => {
                            return option.ProviderId === value.ProviderId;
                        }}
                        renderInput={(params) => <TextField {...params} label="Compare With" />}
                        value={providerTwo}
                        onChange={async (event: any, newValue: any) => {
                            dispatch(setProviderTwo(newValue));
                        }}
                        inputValue={providerTwoInputValue}
                        onInputChange={(event, value) => {
                            setProviderTwoInputValue(value);
                        }}
                        getOptionLabel={(option: any) => option.ProviderNameWithRole}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <AppointmentSwap />
                    <Eventcalendar
                        ref={calendarRef}
                        theme="material"
                        themeVariant="light"
                        width={'100%'}
                        view={{
                            schedule: {
                                type: 'week',
                            },
                        }}
                        data={events}
                        onPageLoading={({ firstDay, lastDay }, inst) => {
                            dispatch(setStartDateBoundary(new Date(firstDay)));
                            dispatch(setEndDateBoundary(new Date(lastDay)));
                        }}
                        resources={myResources}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};

export default ProviderRotation;
