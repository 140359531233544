import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';

const encounterBillingHistoryReport = createSlice({
    name: 'encounterBillingHistoryReport',
    initialState: {
        billingHistory: [],
        modalVisibilty: false,
        encounterServices: [],
        encounterName: '',
    },
    reducers: {
        _setBillingHistory(state, action) {
            state.billingHistory = action.payload;
        },
        _setModalVisibility(state, action) {
            state.modalVisibilty = action.payload;
        },
        _setEncounterServices(state, action) {
            state.encounterServices = action.payload;
        },
        _setEncounterName(state, action) {
            state.encounterName = action.payload;
        },
    },
});

const { _setBillingHistory, _setModalVisibility, _setEncounterServices, _setEncounterName } = encounterBillingHistoryReport.actions;

export const setBillingHistory =
    (value: []): AppThunk =>
    (dispatch) => {
        dispatch(_setBillingHistory(value));
    };

export const getEncounterBillingReportHistory = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/encounterbillingreport/getallencounterbillinghistory`);
    console.log('getEncounterBillingReportHistory: ', response);
    dispatch(setBillingHistory(response.data));
    dispatch(setIsLoading(false));
};

export const setModalVisibility =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setModalVisibility(value));
    };

export const setEncounterServices =
    (value: []): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterServices(value));
    };

export const setEncounterName =
    (value: `${any} ${any}`): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterName(value));
    };

export default encounterBillingHistoryReport.reducer;
