const sharedModalStyles = {
    '& .mui-modal-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #dee2e6',
        minHeight: '56px',
        padding: '0 15px',

        '& h6': {
            fontSize: '16px',
        },
    },

    '& .mui-modal-body': {
        padding: '15px 15px 25px',
        maxHeight: 'calc(100vh - 115px)',
        overflow: 'auto',

        '& .MuiFormLabel-root:not(.MuiInputLabel-root)': {
            color: 'black',
            fontSize: '14px',
            fontWeight: 700,
        },
    },

    '& .mui-modal-footer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: '1px solid #dee2e6',
        minHeight: '56px',
        padding: '0 15px',

        '& button': {
            marginLeft: '15px',
        },
    },
};

const useStyles = {
    careCircleTab: {
        '& .MuiBox-root:first-child': {
            padding: 0,
        },
        '& .MuiPaper-root:first-child': {
            margin: '0 !important',
        },
    },
    loadingContainer: {
        minHeight: '250px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        '& .MuiFormControl-root': {
            marginBottom: '15px',
        },
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    rowFormControl: {
        minHeight: '40px',
        marginBottom: '20px',
        paddingTop: '10px',

        '& .MuiFormLabel-root': {
            marginBottom: 0,
        },
    },
    tableContainer: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        marginTop: '20px',

        '& tbody tr td': {
            color: '#999',
        },

        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
    customCheckbox: {
        '&.MuiFormControlLabel-root': {
            margin: 0,

            '& .MuiTypography-root': {
                fontSize: '14px',
                fontWeight: 500,
                marginRight: '5px',
            },
        },
    },
    modalPopupForm: {
        top: '15vh',
        left: '50%',
        width: '900px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',
        ...sharedModalStyles,
    },
    mdModalPopupForm: {
        top: '10%',
        left: '50%',
        width: '450px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',
        ...sharedModalStyles,
    },
    smModalPopupForm: {
        top: '10%',
        left: '50%',
        width: '350px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',
        ...sharedModalStyles,
    },
    richTextBox: {
        '& .ql-editor': {
            minHeight: '250px',
        },
    },
    sortingButton: {
        '&.MuiButton-root': {
            textTransform: 'none',
            justifyContent: 'space-between',
        },
    },
    sortingMenu: {
        '& .MuiListItemText-root': {
            paddingLeft: '25px',
        },
        '& .MuiListSubheader-root': {
            lineHeight: '30px',
            color: '#999',
        },
    },
    selectedMenuListName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    requestListItem: {
        '& h6': {
            color: '#0063a7',
            marginTop: '20px',
            marginBottom: '10px',
        },
        '& .MuiDivider-root': {
            marginTop: '10px',
            marginBottom: '15px',
            borderTop: '1px solid #0063a7',
        },
        '& .MuiPaper-root': {
            height: '150px',
            width: '100%',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiChip-root': {
            fontWeight: 700,
            fontSize: '11px',
            height: '20px',
            marginBottom: '5px',
            borderRadius: '3px',
        },
        '& .request-message': {
            fontSize: '13px',
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    moreMenuBtn: {
        '&.MuiButtonBase-root.MuiIconButton-root': {
            marginTop: '-10px',
            marginRight: '-10px',
        },
    },
    footerCard: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    avatar: {
        '&.MuiAvatar-root': {
            fontSize: '15px',
            color: 'black',
        },
    },
    commentModal: {
        '& .content-body': {
            padding: '20px',
        },
        '& .lite-title': {
            color: '#969696',
        },
        '& .message': {
            marginBottom: '10px',
        },
        '& .comment-item': {
            display: 'flex',

            '& .MuiAvatar-root': {
                marginRight: '15px',
            },

            '& h6': {
                fontWeight: 600,
                color: '#0063a7',
                marginBottom: 0,
            },

            '& .comment-date': {
                color: '#999',
                fontSize: '12px',
                fontWeight: 'normal',
            },
        },

        '& .MuiChip-root': {
            fontWeight: 700,
            fontSize: '11px',
            height: '20px',
            marginBottom: '5px',
            borderRadius: '3px',
        },
    },
    chatContainer: {
        '&.MuiPaper-root': {
            minHeight: '300px',
            marginRight: '20px',
            padding: '15px',
            backgroundColor: '#ddefff',

            '& .MuiListItem-root': {
                backgroundColor: 'white',
                padding: '10px 15px',
            },

            '& h6': {
                marginBottom: '0',
                fontWeight: '600',
                fontSize: '14px',
            },
        },
    },
    commentInput: {
        '&.MuiFormControl-root': {
            width: '100%',
            marginTop: '25px',
            backgroundColor: 'white',

            '& .MuiFormLabel-root': {
                display: 'none',
            },
            '& fieldset': {
                top: 0,
                '& legend': {
                    display: 'none',
                },
            },
        },
    },
    budgetFormTable: {
        '&.MuiPaper-root': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },

        '& thead tr': {
            backgroundColor: '#0063a7',

            '& th': {
                color: 'white',
            },
        },
        '& tbody tr': {
            '& th, td': {
                '& .MuiFormControlLabel-root': {
                    marginBottom: 0,

                    '& .MuiButtonBase-root': {
                        padding: '5px 9px',
                    },
                },
                '& .MuiFormControl-root': {
                    backgroundColor: 'white',
                },
            },
        },
    },
    topScrollPaper: {
        '&.MuiDialog-container.MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
        },
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    historyList: {
        height: '350px',
        overflow: 'auto',
        width: '100%',
        padding: '10px 20px',
    },
    historyTabMenu: {
        '&.MuiButtonBase-root': {
            textTransform: 'none',
            paddingRight: '25px',
        },
    },
    historyItem: {
        marginBottom: '10px',
        '& .MuiTypography-root': {
            fontWeight: 600,
        },
    },
    borderedTable: {
        '& .MuiTableCell-root': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    borderedBodyTable: {
        '& tbody .MuiTableCell-root': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    procedureOffTable: {
        '&.MuiPaper-root': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },

        '& thead tr': {
            backgroundColor: '#0063a7',

            '& th': {
                color: 'white',
                padding: '8px 15px',
            },
        },
        '& tbody tr': {
            '& th, td': {
                padding: '10px 8px',
                fontWeight: 500,

                '& .MuiRadio-root': {
                    padding: 0,
                },

                '&.bottom-border': {
                    borderBottom: '1px solid #777',
                    paddingBottom: 0,
                },
            },
            '& th': {
                paddingTop: '20px',
                borderBottom: 0,
            },
            '& td': {
                // paddingTop: '0'
            },
        },
        '& .MuiInputBase-input': {
            fontSize: '14px',
        },
        '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-sizeSmall': {
            paddingTop: '8px',
            paddingBottom: '8px',
        },
    },
    actionButton: {
        '&.MuiButton-root.MuiButton-contained': {
            textTransform: 'none',
            borderRadius: 0,
            marginLeft: '12px',
        },
        '&.MuiButton-containedSecondary': {
            backgroundColor: '#909090',
            '&:hover': {
                backgroundColor: '#676767',
            },
        },
    },
    warningList: {
        listStyleType: 'disc',
        color: 'orange',
        paddingLeft: '25px',
        fontSize: '14px',
    },
    selectInputLinkItem: {
        padding: '5px 20px',
        '& button': {
            cursor: 'pointer',
        },
    },
};

export default useStyles;
