import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Radio, RadioGroup, FormControlLabel, Button, Box, CircularProgress, Stack, Typography, Collapse, TextField, FormLabel } from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { HealthEvaluationResponses, YesNoAnswers } from '../../../utils/assessments';
import { copyObject, preventTextInput } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchAdditionalCHPCriteriaById, saveAdditionalCHPCriteria } from '../../../store/hra.slice';
import { AdditionalCHPCriteria } from '../../../Models/HRA/AdditionalCHPCriteria.model';
import { LastSixMonthHealthEvaluation } from '../../../Models/HRA/AdditionalCHPCriteria.model';
import { EDVisitsInLastFourMonths } from '../../../Models/HRA/AdditionalCHPCriteria.model';
import { HospitalizedInLastFourMonths } from '../../../Models/HRA/AdditionalCHPCriteria.model';
import { HealthEvaluationResponseEnum } from '../../../Enum/HealthEvaluationResponseEnum';
import { AnswerOptionsEnum } from '../../../Enum/AnswerOptionsEnum';

const AdditionalCHPCriteriaAssessment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { additionalCHPCriteria, isFetching, isSaving: isSavingHra } = useSelector((state: RootState) => state.hra);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingHra || isSavingEngagementDraft;

    useEffect(() => {
        if (Boolean(engagementDraft.AdditionalCHPCriteriaId)) {
            dispatch(fetchAdditionalCHPCriteriaById(engagementDraft.AdditionalCHPCriteriaId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveAdditionalCHPCriteria({ ...additionalCHPCriteria, Status: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChange = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(additionalCHPCriteria) as AdditionalCHPCriteria;
        data.MemberId = memberId;
        data[propName] = val;

        dispatch(saveAdditionalCHPCriteria(data, false, saveOnlyLocal));
    };

    const handleOnChangeHealthEvaluation = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(additionalCHPCriteria) as AdditionalCHPCriteria;
        data.MemberId = memberId;
        data.LastSixMonthHealthEvaluation = data.LastSixMonthHealthEvaluation ?? new LastSixMonthHealthEvaluation();
        data.LastSixMonthHealthEvaluation[propName] = val;

        dispatch(saveAdditionalCHPCriteria(data, false, saveOnlyLocal));
    };

    const handleOnChangeEDVisits = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(additionalCHPCriteria) as AdditionalCHPCriteria;
        data.MemberId = memberId;
        data.EDVisitsInLastFourMonths = data.EDVisitsInLastFourMonths ?? new EDVisitsInLastFourMonths();
        data.EDVisitsInLastFourMonths[propName] = val;

        dispatch(saveAdditionalCHPCriteria(data, false, saveOnlyLocal));
    };

    const handleOnChangeHospitalize = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(additionalCHPCriteria) as AdditionalCHPCriteria;
        data.MemberId = memberId;
        data.HospitalizedInLastFourMonths = data.HospitalizedInLastFourMonths ?? new HospitalizedInLastFourMonths();
        data.HospitalizedInLastFourMonths[propName] = val;

        dispatch(saveAdditionalCHPCriteria(data, false, saveOnlyLocal));
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" paddingTop={2}>
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form">
                        <Typography variant="h6" component="label">
                            Additional CHP Criteria
                        </Typography>
                        <hr />
                        <Stack paddingY={2}>
                            <Typography variant="subtitle1" component="label" fontWeight={500} paddingBottom={1}>
                                How would you evaluate your health-over the last 6 months?
                            </Typography>
                            <Stack paddingLeft={2}>
                                <RadioGroup
                                    row
                                    name="Response"
                                    value={additionalCHPCriteria.LastSixMonthHealthEvaluation?.Response}
                                    onChange={(e) => handleOnChangeHealthEvaluation(parseInt(e.target.value), e.target.name)}
                                >
                                    {HealthEvaluationResponses.map((type, index) => (
                                        <FormControlLabel key={`health-evaluation-${index}`} value={type.Id} control={<Radio />} label={type.Name} />
                                    ))}
                                </RadioGroup>
                                <Collapse
                                    in={
                                        additionalCHPCriteria.LastSixMonthHealthEvaluation?.Response === HealthEvaluationResponseEnum.Worse ||
                                        additionalCHPCriteria.LastSixMonthHealthEvaluation?.Response === HealthEvaluationResponseEnum.SomewhatWorse
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <TextField
                                        fullWidth
                                        sx={{ maxWidth: 450, marginTop: 2 }}
                                        label="Why It Feels Worse"
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={additionalCHPCriteria.LastSixMonthHealthEvaluation?.Reason}
                                        name="Reason"
                                        onChange={(e) => handleOnChangeHealthEvaluation(e.target.value, e.target.name, true)}
                                        onBlur={(e) => handleOnChangeHealthEvaluation(e.target.value, e.target.name)}
                                    />
                                </Collapse>
                            </Stack>
                        </Stack>
                        <Stack paddingY={2}>
                            <Typography variant="subtitle1" component="label" fontWeight={500} paddingBottom={1}>
                                Have you gone to the ED in the last 4 months?
                            </Typography>
                            <Stack paddingLeft={2}>
                                <RadioGroup
                                    row
                                    name="Response"
                                    value={additionalCHPCriteria.EDVisitsInLastFourMonths?.Response}
                                    onChange={(e) => handleOnChangeEDVisits(parseInt(e.target.value), e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`answer-bath-body-${index}`} value={type.Value} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                                <Collapse in={additionalCHPCriteria.EDVisitsInLastFourMonths?.Response === AnswerOptionsEnum.Yes} timeout="auto" unmountOnExit>
                                    <TextField
                                        label="How Many Times"
                                        variant="outlined"
                                        name="NumberOfVisits"
                                        type="number"
                                        onKeyPress={preventTextInput}
                                        defaultValue={additionalCHPCriteria.EDVisitsInLastFourMonths?.NumberOfVisits}
                                        placeholder="Enter quantity here"
                                        inputProps={{ min: 0, max: 100 }}
                                        style={{ width: '200px', marginTop: 2 }}
                                        onChange={(e) => handleOnChangeEDVisits(e.target.value, e.target.name, true)}
                                        onBlur={(e) => handleOnChangeEDVisits(e.target.value, e.target.name)}
                                    />
                                </Collapse>
                            </Stack>
                        </Stack>
                        <Stack paddingY={2}>
                            <Typography variant="subtitle1" component="label" fontWeight={500} paddingBottom={1}>
                                Have you been hospitalized in the last 4 months? (beside maternity or elective surgeries)
                            </Typography>
                            <Stack paddingLeft={2}>
                                <RadioGroup
                                    row
                                    name="Response"
                                    value={additionalCHPCriteria.HospitalizedInLastFourMonths?.Response}
                                    onChange={(e) => handleOnChangeHospitalize(e.target.value, e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`answer-bath-body-${index}`} value={type.Value} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                                <Collapse
                                    in={additionalCHPCriteria.HospitalizedInLastFourMonths?.Response === AnswerOptionsEnum.Yes}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <TextField
                                        label="How Many Times"
                                        variant="outlined"
                                        name="NumberOfVisits"
                                        type="number"
                                        onKeyPress={preventTextInput}
                                        defaultValue={additionalCHPCriteria.HospitalizedInLastFourMonths?.NumberOfVisits}
                                        placeholder="Enter quantity here"
                                        inputProps={{ min: 0, max: 100 }}
                                        style={{ width: '200px', marginTop: 2 }}
                                        onChange={(e) => handleOnChangeHospitalize(e.target.value, e.target.name, true)}
                                        onBlur={(e) => handleOnChangeHospitalize(e.target.value, e.target.name)}
                                    />
                                </Collapse>
                            </Stack>
                        </Stack>

                        <Stack>
                            <FormLabel className="mt-15">General Notes:</FormLabel>
                            <TextField
                                fullWidth
                                multiline
                                className="hide-textinput-label"
                                style={{ maxWidth: '750px' }}
                                placeholder="Enter here"
                                margin="none"
                                variant="outlined"
                                rows={6}
                                value={additionalCHPCriteria.Notes}
                                name="Notes"
                                onChange={(e) => handleOnChange(e.target.value, e.target.name, true)}
                                onBlur={(e) => handleOnChange(e.target.value, e.target.name)}
                            />
                        </Stack>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ marginRight: 2 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {Boolean(engagementDraft.AdditionalCHPCriteriaId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Additional CHP criteria"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Additional CHP criteria is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Additional CHP criteria"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Additional CHP criteria?"
                />
            )}
        </Grid>
    );
};

export default AdditionalCHPCriteriaAssessment;
