import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Snackbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Shared/Loading';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import phoneFormat from '../../utils/phoneFormat';
import ProviderDialog from './ProviderDialog';
import { RootState } from '../../reducers';
import { fetchAllProviders } from '../../store/shared.slice';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
    callHandler,
    deleteProvider,
    getPreferredContactOptions,
    getProviderRoles,
    handleClickOpen,
    openConfirm,
    openEdit,
    resetPasswordHandler,
    setConfirmVisibility,
    setOpenAlert,
    setProviderData,
    setRowData,
    setTitle,
} from '../../store/adminProvider.slice';
import { states } from '../../utils/constants';
import { Search } from '@mui/icons-material';
import { Provider } from '../../Models/Provider/Provider.model';
import { roleEnum } from '../../utils/assessments';

export const formValidation = (providerData: Provider) => {
    if (
        providerData.LastName === '' ||
        providerData.Role === undefined ||
        providerData.FirstName === '' ||
        providerData.Email === undefined ||
        providerData.PreferredContact === undefined ||
        providerData.ProviderType === undefined ||
        providerData.MeansOfEncounter === undefined ||
        (providerData.StateIssuingLicense?.length > 0 && !states.includes(providerData.StateIssuingLicense)) ||
        (isNPorMD(providerData) && (providerData.StateLicenseNumber === undefined || !providerData.StateLicenseNumber)) ||
        (isNPorMD(providerData) && (new Date(providerData.StateLicenseExpiration).toString() === 'Invalid Date' || !providerData.StateLicenseExpiration)) ||
        (isNPorMD(providerData) && (providerData.StateIssuingLicense === undefined || providerData.StateIssuingLicense?.length !== 2)) ||
        (providerData.MeansOfEncounter === 'Personal NPI' && providerData.NPINumber?.length < 10) ||
        (providerData.MeansOfEncounter === 'Group NPI' && providerData.GroupNPINumber?.length < 10)
    ) {
        return false;
    } else {
        return true;
    }
};

export const isNPorMD = (providerData: Provider) => {
    return providerData.Role?.RoleName === roleEnum.NURSE_PRACTITIONER || providerData.Role?.RoleName === roleEnum.MD;
};

const AdminProviders = (props) => {
    const dispatch = useDispatch();
    const { allProviders } = useSelector((state: RootState) => state.shared);
    const { alertMessage, confirmVisibility, openAlert, providerData, rowData, severity } = useSelector((state: RootState) => state.adminProvider);

    const [providerSearchValue, setProviderSearchValue] = useState('');
    const [inactiveChecked, setInactiveChecked] = useState(false);
    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 54 : 63) + ''));
    };

    const toggleInactiveHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInactiveChecked(event.target.checked);
    };

    const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setOpenAlert(false));
    };

    const addNewProviderHandler = () => {
        dispatch(setTitle('Provider Detail'));
        dispatch(handleClickOpen());
    };

    function executeSearch(event: any) {
        if (!event.key || event.key === 'Enter') {
            var tmp = [];
            const searchValue = providerSearchValue.toLowerCase();
            for (const provider of allProviders) {
                let searchValueOnlyDigits = searchValue.replace(/\D/g, '');

                if (
                    (provider.LastName && provider.LastName.toLowerCase().indexOf(searchValue) > -1) ||
                    (provider.FirstName && provider.FirstName.toLowerCase().indexOf(searchValue) > -1) ||
                    (provider.Role.RoleName && provider.Role.RoleName.toString().toLowerCase().indexOf(searchValue) > -1) ||
                    (provider.NPINumber && provider.NPINumber.toLowerCase().indexOf(searchValue) > -1) ||
                    (provider.MobilePhone && searchValueOnlyDigits && provider.MobilePhone.replace(/\D/g, '').indexOf(searchValueOnlyDigits) > -1) ||
                    (provider.MainAddress.Address1 && provider.MainAddress.Address1.toLowerCase().indexOf(searchValue) > -1) ||
                    (provider.Email && provider.Email.toLowerCase().indexOf(searchValue) > -1) ||
                    (provider.FaxNumber && searchValueOnlyDigits && provider.FaxNumber.replace(/\D/g, '').indexOf(searchValueOnlyDigits) > -1)
                ) {
                    tmp.push(provider);
                }
            }
            dispatch(setRowData(tmp));
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'Role',
            headerName: 'Role',
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.IsAdmin ? (
                            <Typography color={'error'}>{params.row.Role.RoleName + ' (Admin)'}</Typography>
                        ) : (
                            <Typography>{params.row.Role.RoleName}</Typography>
                        )}
                    </>
                );
            },
        },
        {
            field: 'NPINumber',
            headerName: 'NPINumber',
            minWidth: 160,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 240,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.MainAddress.Address1}`,
        },
        {
            field: 'MobilePhone',
            headerName: 'MobilePhone',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.MobilePhone === null || phoneFormat(params.row.MobilePhone) === null ? 'n/a' : phoneFormat(params.row.MobilePhone)}`,
        },
        {
            field: 'Email',
            headerName: 'Email',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'FaxNumber',
            headerName: 'FaxNumber',
            minWidth: 160,
        },
        {
            field: 'IsOnline',
            headerName: 'Phone/Video',
            minWidth: 80,
            renderCell: (params) => {
                return params.row.Email && params.row.Email.indexOf('catalytichealthpartners') > 0 ? (
                    <>
                        <Tooltip title="Call" placement="left">
                            <PhoneIphoneIcon
                                color="primary"
                                style={{
                                    marginLeft: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => dispatch(callHandler(params.row.Email))}
                            />
                        </Tooltip>
                    </>
                ) : (
                    ''
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit" placement="left">
                            <EditIcon
                                color="primary"
                                style={{
                                    marginLeft: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    dispatch(openEdit(params));
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon
                                color="error"
                                style={{
                                    marginLeft: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    dispatch(openConfirm(params));
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Reset Password" placement="bottom">
                            <LockResetIcon
                                color="error"
                                style={{
                                    marginLeft: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => dispatch(resetPasswordHandler(params.row.Id))}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(getProviderRoles());
        dispatch(getPreferredContactOptions());
        dispatch(fetchAllProviders());
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!inactiveChecked) {
            dispatch(setRowData(allProviders.filter((item) => item.Role.RoleName !== 'Inactive')));
        } else {
            dispatch(setRowData(allProviders));
        }
    }, [allProviders, inactiveChecked, dispatch]);

    return (
        <Box>
            {<Loading message={'Loading...'} />}
            <Snackbar open={openAlert} autoHideDuration={2500} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseAlert} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <ProviderDialog providerData={providerData} setProviderData={setProviderData} />
            <Dialog
                open={confirmVisibility}
                onClose={() => dispatch(setConfirmVisibility(false))}
                disableEnforceFocus
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <Paper
                    sx={{
                        padding: '16px',
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h6" component={'p'} color={'error'}>
                            Are You Sure You Want To Delete <span style={{ color: '#333', fontWeight: 'bold' }}>{providerData.FullName}</span> ?
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                dispatch(deleteProvider(providerData.Id));
                            }}
                            autoFocus
                        >
                            Delete
                        </Button>
                        <Button variant="outlined" onClick={() => dispatch(setConfirmVisibility(false))}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>

            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid display={'flex'} item xs={6} alignItems="center" textAlign={'right'}>
                    <FormControl style={{ minWidth: '300px', margin: '0px' }}>
                        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            value={providerSearchValue}
                            onChange={(e) => {
                                setProviderSearchValue(e.target.value as string);
                            }}
                            onKeyUp={(event: any) => {
                                executeSearch(event);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Search" onClick={executeSearch} edge="end">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                </Grid>

                <Grid display={'flex'} item xs={6} alignItems="center" justifyContent={'flex-end'}>
                    <FormControlLabel control={<Checkbox checked={inactiveChecked} onChange={toggleInactiveHandler} />} label="Show Inactive Providers" />
                    <Button className="btn-120" onClick={addNewProviderHandler} variant="contained">
                        Add New Provider
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            rows={rowData}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[]}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminProviders;
