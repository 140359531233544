import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { parentUrl } from '../../../utils/constants';

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
};

const classes = {
    wrapText: {
        '& .MuiInputBase-input': {
            wordWrap: 'break-word',
            maxHeight: '100px', // You can set a maximum height
            overflow: 'auto',
        },
    },
};

type CopiableDetailFieldProps = {
    title: string;
    details: string;
    helperText?: string;
};

const CopiableDetailField = (props: CopiableDetailFieldProps) => {
    const { title, details, helperText } = props;
    const handleCopyClick = () => {
        copyToClipboard(details);
    };

    let helperTextComponent = null;
    if (helperText) {
        helperTextComponent = <span style={{ color: 'red', fontWeight: 'bold' }}>{helperText}</span>;
    }

    return (
        <TextField
            label={title}
            variant="outlined"
            value={details}
            size="small"
            fullWidth
            onMouseDown={(e) => e.preventDefault()}
            helperText={helperTextComponent}
            sx={classes.wrapText}
            InputProps={{
                readOnly: true,
                style: {
                    userSelect: 'none',
                },
                endAdornment: (
                    <Tooltip title={'Copy to Clipboard'} arrow PopperProps={{ style: { zIndex: 99999999 } }} placement="top">
                        <IconButton onClick={handleCopyClick} edge="end">
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                ),
            }}
        />
    );
};

const MemberInfoPanel = () => {
    const { member, associatedEvent } = useSelector((state: RootState) => state.officeSchedulerSlice);
    if (member === null || associatedEvent === null) return null;
    return (
        <Stack spacing={3} style={{ marginTop: 20 }}>
            <Button
                startIcon={<AssignmentIndIcon />}
                variant="outlined"
                fullWidth
                style={{ height: '50px' }}
                onClick={() => {
                    window.open(`${parentUrl}provider/members-list/member-chart/${member.Id}/member-profile`, '_blank');
                }}
            >
                OPEN MEMBER CHART
            </Button>
            <CopiableDetailField
                title={`Last Name, First Name`}
                helperText={member.IsInTransition ? '(In Transitional Care)' : null}
                details={`${member.LastName}, ${member.FirstName} ${member.MiddleName ? member.MiddleName : ''}`}
            />
            <CopiableDetailField title="Date of Birth" details={`${format(new Date(member.DOB), 'MM/dd/yyyy')} (Age: ${member.Age})`} />
            <CopiableDetailField
                title="Appointment Date and Time"
                details={format(new Date(associatedEvent?.AppointmentDateTime ?? new Date()), 'MM/dd/yyyy h:mm aa')}
            />
            <CopiableDetailField
                title="Appointment Address"
                details={associatedEvent.IsCustomAddress ? associatedEvent.CustomAddress : associatedEvent.address}
            />
            <CopiableDetailField title="Primary Language" details={member.PrimaryLanguage ? member.PrimaryLanguage.Name : 'Not Set'} />
            <CopiableDetailField title="Provider" details={associatedEvent.providerNames.join(', ')} />
        </Stack>
    );
};

export default MemberInfoPanel;
