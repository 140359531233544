import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL, SELECTED_PROVIDER_ID } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';

const optimizeMap = { AppointmentsNoAddress: [], Appointments: [], MemberHomeLocation: {}, OptimizeRows: [] };

const appointmentSchedulerSlice = createSlice({
    name: 'appointmentScheduler',
    initialState: {
        appointmentDuration: 90,
        appointmentMaps: optimizeMap,
        hasPermission: false,
        selectedProviderId: '',
        selectedMemberId: '',
        scheduleFrom: new Date(),
        scheduleTo: new Date(),
        showModal: false,
    },
    reducers: {
        _setAppointmentDuration(state, action) {
            state.appointmentDuration = action.payload;
        },
        _setAppointmentMaps(state, action) {
            state.appointmentMaps = action.payload;
        },
        _setHasPermission(state, action) {
            state.hasPermission = action.payload;
        },
        _setSelectedMemberId(state, action) {
            state.selectedMemberId = action.payload;
        },
        _setSelectedProviderId(state, action) {
            state.selectedProviderId = action.payload;
        },
        _setScheduleFrom(state, action) {
            state.scheduleFrom = action.payload;
        },
        _setScheduleTo(state, action) {
            state.scheduleTo = action.payload;
        },
        _setShowModal(state, action) {
            state.showModal = action.payload;
        },
    },
});

const {
    _setAppointmentDuration,
    _setAppointmentMaps,
    _setHasPermission,
    _setSelectedMemberId,
    _setSelectedProviderId,
    _setScheduleFrom,
    _setScheduleTo,
    _setShowModal,
} = appointmentSchedulerSlice.actions;

export const fetchAppointmentOptimizedAppointments = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const providerId = getState().appointmentScheduler.selectedProviderId;
    const memberId = getState().appointmentScheduler.selectedMemberId;
    const scheduleFrom = getState().appointmentScheduler.scheduleFrom.toLocaleDateString().replaceAll('/', '-');
    const scheduleTo = getState().appointmentScheduler.scheduleTo.toLocaleDateString().replaceAll('/', '-');
    const appointmentDuration = getState().appointmentScheduler.appointmentDuration;

    if (!providerId || !memberId) {
        dispatch(_setAppointmentMaps([]));
    } else {
        let url = `${API_URL}/scheduling/GetOptimalAppointments/${providerId}/${memberId}/${scheduleFrom}/${scheduleTo}/${appointmentDuration}`;
        const response = await axios.get(url);
        dispatch(_setAppointmentMaps(response.data));
    }

    dispatch(setIsLoading(false));
};

export const setAppointmentDuration =
    (value: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setAppointmentDuration(value));
    };

export const setHasPermission =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setHasPermission(value));
        if (!value) {
        }
    };

export const setIsLaoding =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(setIsLoading(value));
    };

export const setAppointmentMaps =
    (apptMaps: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setAppointmentMaps(apptMaps));
    };

export const setScheduleFrom =
    (date: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setScheduleFrom(date));
    };

export const setScheduleTo =
    (date: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setScheduleTo(date));
    };

export const setSelectedMemberId =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedMemberId(memberId));
    };

export const setSelectedProviderId =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedProviderId(providerId));
        if (providerId) {
            dispatch(_setSelectedProviderId(providerId));
            localStorage.setItem(SELECTED_PROVIDER_ID, providerId);
        }
    };

export const setShowModal =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setShowModal(value));
    };

export default appointmentSchedulerSlice.reducer;
