import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Dialog, DialogContent, DialogActions, DialogTitle, Divider, Typography, TextField, FormControl, CircularProgress } from '@mui/material';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../reducers';
import { addMedication, toggleIsShowMedicationsForm } from '../../store/transitionalCare.slice';
import { copyObject } from '../../utils/common';
import { Medication } from '../../Models/TransitionalCare/Medication.model';
import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';

const medicationsSchema = Yup.object().shape({
    Medications: Yup.string().nullable().required('Medication is required.'),
});

const Medications: React.FC<{ transitionStep: TransitionStep; isAssessment: boolean }> = ({ transitionStep, isAssessment }) => {
    const dispatch = useDispatch();

    const { isShowMedicationsForm } = useSelector((state: RootState) => state.transitionalCare);
    const { engagementDraft, isSaving } = useSelector((state: RootState) => state.documentEngagement);
    const medications = copyObject(transitionStep?.Medications || []).reverse();

    const handleOnSubmit = (values) => {
        if (isAssessment) {
            let data = copyObject(engagementDraft);
            data['MedicalReconcilation'] = true;
            dispatch(saveEngagementDraft(data));
        }
        dispatch(addMedication(values.Medications));
    };

    const handleOpen = () => dispatch(toggleIsShowMedicationsForm(true));
    const handleClose = () => dispatch(toggleIsShowMedicationsForm(false));

    const RenderModalForm = () => {
        return (
            <Dialog open={isShowMedicationsForm} onClose={handleClose} fullWidth maxWidth={'sm'}>
                <DialogTitle>Add Medication</DialogTitle>
                <DialogContent>
                    <Formik initialValues={{ Medications: '' }} onSubmit={handleOnSubmit} validationSchema={medicationsSchema}>
                        {({ values, errors, handleChange }) => (
                            <Form id="medications-form">
                                <FormControl fullWidth={true} error={Boolean(errors?.Medications)}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Description"
                                        multiline
                                        sx={{ marginY: 2 }}
                                        variant="outlined"
                                        rows={6}
                                        value={values.Medications}
                                        name="Medications"
                                        onChange={handleChange}
                                        helperText={errors?.Medications ? errors?.Medications : 'Describe your medications.'}
                                        error={Boolean(errors?.Medications)}
                                    />
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button type="button" onClick={handleClose} disabled={isSaving}>
                        Cancel
                    </Button>
                    <Button
                        form="medications-form"
                        type="submit"
                        disabled={isSaving}
                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                    >
                        Save & Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            <RenderModalForm />
            <Button disabled={!isAssessment} variant="outlined" color="primary" fullWidth sx={{ p: 2, marginBottom: 2 }} onClick={handleOpen}>
                Add Medication
            </Button>
            {!isAssessment && (
                <>
                    <Typography textAlign={'center'} variant="body2" color={'red'} sx={{ marginBottom: 2 }}>
                        You must enter Medications in the Assessment section.
                    </Typography>
                </>
            )}
            {medications?.map((medication: Medication, index: number) => {
                const { CreatedBy, CreatedDate, Medications } = medication;
                const lastItem = medications.length - 1 === index;
                return (
                    <Stack key={index} paddingY={2}>
                        <Typography variant="body1" fontWeight={500}>
                            {Medications}
                        </Typography>
                        <Typography variant="body2" color={'text.secondary'}>
                            {CreatedBy?.Name} - {moment(CreatedDate).format('DD MMM YYYY')}
                        </Typography>
                        {!lastItem && <Divider sx={{ marginTop: 3 }} />}
                    </Stack>
                );
            })}
        </>
    );
};

export default Medications;
