import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DocumentEngagement } from '../Models/DocumentEngagements/DocumentEngagement.model';
import { Member } from '../Models/Member/Member.model';
import { Provider } from '../Models/Provider/Provider.model';
import Appointment from '../Models/Scheduling/Appointment.model';
import { SchedulingCallList } from '../Models/Scheduling/SchedulingCallList.model';
import { SchedulingCallItem } from '../Models/Scheduling/SchedulingCallitem.model';
import { getAppointmentById } from '../components/Calendar/Services/CommonCalendarServices';
import { fetchMemberDocumentation, fetchMemberInfo } from '../components/OfficeScheduling/OfficeSchedulingService';
import { ERROR } from '../utils/constants';
import { setIsLoading, setShowMessage } from './shared.slice';
import { AppThunk } from './store';

export interface IOfficeSchedulerSlice {
    schedulingList: SchedulingCallList | null;
    selectedCallItem: SchedulingCallItem | null;
    member: Member | null;
    memberDocs: DocumentEngagement[];
    isRescheduleModalOpen: boolean;
    associatedEvent: Appointment | null;
    schedulingProvider: Provider | null;
}

const officeSchedulerSliceInitialState: IOfficeSchedulerSlice = {
    schedulingList: null,
    selectedCallItem: null,
    member: null,
    memberDocs: [],
    isRescheduleModalOpen: false,
    associatedEvent: null,
    schedulingProvider: null,
};

const officeSchedulerSlice = createSlice({
    name: 'officeScheduler',
    initialState: officeSchedulerSliceInitialState,
    reducers: {
        _setSchedulingList(state, action: PayloadAction<SchedulingCallList | null>) {
            state.schedulingList = action.payload;
        },
        _setSchedulingProvider(state, action: PayloadAction<Provider | null>) {
            state.schedulingProvider = action.payload;
        },
        _setSelectedCallItem(state, action: PayloadAction<SchedulingCallItem | null>) {
            state.selectedCallItem = action.payload;
        },
        _setMember(state, action: PayloadAction<Member | null>) {
            state.member = action.payload;
        },
        _setMemberDocs(state, action: PayloadAction<DocumentEngagement[]>) {
            state.memberDocs = action.payload;
        },
        _setIsRescheduleModalOpen(state, action: PayloadAction<boolean>) {
            state.isRescheduleModalOpen = action.payload;
        },
        _setAssociatedEvent(state, action: PayloadAction<Appointment | null>) {
            state.associatedEvent = action.payload;
        },
    },
});

const { _setSelectedCallItem, _setIsRescheduleModalOpen, _setAssociatedEvent, _setSchedulingList, _setSchedulingProvider, _setMember, _setMemberDocs } =
    officeSchedulerSlice.actions;

export const setPropertiesOnSelectedCallItem =
    (updatedProperties: Partial<SchedulingCallItem>): AppThunk =>
    async (dispatch, getState) => {
        const { selectedCallItem, schedulingList } = getState().officeSchedulerSlice;
        if (selectedCallItem === null) return;
        const updatedCallItem = { ...structuredClone(selectedCallItem), ...updatedProperties };
        dispatch(_setSelectedCallItem(updatedCallItem));
        // Update call item within the list
        const updatedCallItemsList = structuredClone(schedulingList.CallItems).map((item) => (item.ApptId === updatedCallItem.ApptId ? updatedCallItem : item));
        const updatedSchedulingList = { ...structuredClone(schedulingList), CallItems: updatedCallItemsList };
        dispatch(setSchedulingList(updatedSchedulingList));
    };
    
export const appendToCallItemNotes =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        const { selectedCallItem } = getState().officeSchedulerSlice;
        if (selectedCallItem === null) return;
        const existingNote =
            selectedCallItem.Note === null || selectedCallItem.Note === undefined || selectedCallItem.Note === '' ? '' : `${selectedCallItem.Note}\n`;
        dispatch(setPropertiesOnSelectedCallItem({ Note: `${existingNote}${note}` }));
    };

export const selectCallItem =
    (newSelectedItem: SchedulingCallItem | null): AppThunk =>
    async (dispatch, getState) => {
        const { selectedCallItem: previouslySelectedCallItem } = getState().officeSchedulerSlice;
        const shouldUpdateAllData =
            (newSelectedItem !== null && previouslySelectedCallItem === null) ||
            (previouslySelectedCallItem !== null && newSelectedItem.Id !== previouslySelectedCallItem.Id);
        dispatch(_setSelectedCallItem(newSelectedItem));
        if (shouldUpdateAllData) {
            try {
                dispatch(setIsLoading(true));
                const appointmentResponse = await getAppointmentById(newSelectedItem.ApptId);
                const event: Appointment = appointmentResponse.data;
                const memberInfoResponse = await fetchMemberInfo(event.MemberObjectId);
                const memberDocsResponse = await fetchMemberDocumentation(event.MemberObjectId);
                dispatch(_setMember(memberInfoResponse.data));
                dispatch(_setMemberDocs(memberDocsResponse.data));
                // In case of recurrence, the call item will have the instance's datetime so we replace it here
                event.AppointmentDateTime = newSelectedItem.AppointmentDateTime;
                event.AppointmentEndDateTime = new Date(new Date(newSelectedItem.AppointmentDateTime).getTime() + 30 * 60000);
                dispatch(_setAssociatedEvent(event));
                dispatch(setIsLoading(false));
            } catch (error) {
                console.error(error);
                dispatch(setIsLoading(false));
                dispatch(setShowMessage(true, 'Error while changing the selected call item.', ERROR));
            }
        } else if (newSelectedItem === null) {
            dispatch(_setMember(null));
            dispatch(_setMemberDocs([]));
            dispatch(_setAssociatedEvent(null));
        }
    };

export const setIsRescheduleModalOpen =
    (isRescheduleModalOpen: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsRescheduleModalOpen(isRescheduleModalOpen));
    };

export const setSchedulingList =
    (schedulingList: SchedulingCallList | null): AppThunk =>
    async (dispatch) => {
        dispatch(_setSchedulingList(schedulingList));
    };

export const setSchedulingProvider =
    (schedulingProvider: Provider | null): AppThunk =>
    async (dispatch) => {
        dispatch(_setSchedulingProvider(schedulingProvider));
    };

export default officeSchedulerSlice.reducer;