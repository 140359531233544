export enum PreferredPronounsEnum {
    HeHimDescription = 'He/Him',
    HeHim = 1,
    SheHerDescription = 'She/Her',
    SheHer = 2,
    TheyThemDescription = 'They/Them',
    TheyThem = 3,
    OtherDescription = 'Other',
    Other = 4,
}
