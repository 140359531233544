import { Timeline } from '@mui/lab';
import React, { useMemo } from 'react';
import { IRequestTimelineProps } from '../interfaces/IRequestTimelineProps';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InventoryIcon from '@mui/icons-material/Inventory';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import RequestTimelineItem from './RequestTimelineItem';
import { Typography } from '@mui/material';

const RequestTimeline: React.FC<IRequestTimelineProps> = (props) => {
    const { TimelineEvents } = props;

    const timelineIconMapping: { [key: string]: React.ReactNode } = useMemo(() => {
        return {
            Created: <NewReleasesIcon />,
            InProgress: <PendingIcon />,
            Completed: <CheckCircleIcon />,
            Archived: <InventoryIcon />,
            Forwarded: <KeyboardDoubleArrowRightIcon />,
        };
    }, []);

    const timelineEvents = useMemo(() => {
        if (!TimelineEvents) return [];
        return TimelineEvents.map((event) => {
            console.log({ type: event.EventType });
            return <RequestTimelineItem {...event} icon={timelineIconMapping[event.EventType]} />;
        });
    }, [TimelineEvents, timelineIconMapping]);

    return (
        <Timeline nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
            {timelineEvents?.length > 0 ? timelineEvents : <Typography>There's currently no timeline events to display.</Typography>}
        </Timeline>
    );
};

export default RequestTimeline;
