import { Card, CardContent, CardMedia, Checkbox, FormControl, Grid, Typography } from '@mui/material';

const AssignEducationCheckboxComponent = (props) => {
    const { categoryId, materialItems, selectedMaterialsList, selectedMaterialsListOnChangeHandler } = props;
    return materialItems.map((item) => {
        return (
            <Grid textAlign="center" key={item.Id} item xs={3}>
                <FormControl id={item.Id} fullWidth>
                    <Checkbox
                        checked={
                            selectedMaterialsList
                                .map((el) => el['MaterialItems'])
                                .flat(1)
                                .map((el) => el['Id'])
                                .includes(item.Id) &&
                            selectedMaterialsList
                                .map((el) => el['MaterialItems'])
                                .flat(1)
                                .map((el) => el['Name'])
                                .includes(item.Name)
                                ? true
                                : false
                        }
                        onChange={(e) => selectedMaterialsListOnChangeHandler({ item, categoryId })}
                        icon={
                            <Card sx={{ width: '100%' }}>
                                <CardContent>
                                    <CardMedia
                                        sx={{ objectFit: 'cover', objectPosition: '50% 0' }}
                                        component="img"
                                        width="100"
                                        height="120"
                                        alt={item.Name}
                                        image={item.ThumbnailUrl}
                                    />
                                </CardContent>
                                <Typography p={1} variant="body2">
                                    {item.Name}
                                </Typography>
                            </Card>
                        }
                        checkedIcon={
                            <Card sx={{ width: '100%', boxShadow: '0px 0px 20px 5px rgba(25, 118, 210, 0.8)' }}>
                                <CardContent>
                                    <CardMedia
                                        sx={{ objectFit: 'cover', objectPosition: '50% 0' }}
                                        component="img"
                                        width="100"
                                        height="120"
                                        alt={item.Name}
                                        image={item.ThumbnailUrl}
                                    />
                                </CardContent>
                                <Typography p={1} fontWeight={'bold'} variant="body2">
                                    {item.Name}
                                </Typography>
                            </Card>
                        }
                    />
                </FormControl>
            </Grid>
        );
    });
};

export default AssignEducationCheckboxComponent;
