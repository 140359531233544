import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    closeEditPopulationHandler,
    deletePopulationContactHandler,
    savePopulationContactHandler,
    setAlertContactVisibility,
    setContactInfoTitle,
    setCurrentContact,
    setCurrentPopulation,
    updatePopulation,
} from '../../../store/adminHealthPlan.slice';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import phoneFormat from '../../../utils/phoneFormat';
import BasicContactInfoDialog from '../BasicContactInfoDialog';
import ConfirmDelete from '../../Shared/ConfirmDelete';
import { useState } from 'react';

const EditPopulationDialog = (props) => {
    const dispatch = useDispatch();
    const getDefaultContact = () => {
        return {
            Address: '',
            Email: '',
            Id: '',
            Name: '',
            PhoneNumber: '',
        };
    };
    const { alertContactVisibility, contactInfoTitle, currentContact, currentHealthPlan, currentPopulation } = useSelector(
        (state: RootState) => state.adminHealthPlanSlice
    );

    const [deleteVis, setDeleteVis] = useState(false);
    const [alertContactInfo, setAlertContactInfo] = useState({ Name: '', Id: '' });
    const alertFrequencyList = [5, 10, 15, 30, 60];

    const addNewContactHandler = () => {
        dispatch(setContactInfoTitle('Population Alert Contact'));
        dispatch(setCurrentContact(getDefaultContact()));
        dispatch(setAlertContactVisibility(true));
    };

    const editContactHandler = (params) => {
        dispatch(setCurrentContact(params.row));
        dispatch(setContactInfoTitle(params.row.Name));
        dispatch(setAlertContactVisibility(true));
    };

    const openDeleteAlertHandler = (params) => {
        setAlertContactInfo(params);
        setDeleteVis(true);
    };

    const closeDeleteAlertHandler = () => {
        setDeleteVis(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 1,
        },
        {
            field: 'PhoneNumber',
            headerName: 'Phone Number',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${phoneFormat(params.row.PhoneNumber)}`,
        },
        {
            field: 'Email',
            headerName: 'Email',
            flex: 1.5,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit Contact" placement="bottom">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => editContactHandler(params)} />
                        </Tooltip>
                        <Tooltip title="Delete Contact" placement="top">
                            <CancelIcon color="error" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => openDeleteAlertHandler(params.row)} />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <BasicContactInfoDialog
                currentContact={currentContact}
                open={alertContactVisibility}
                onClose={() => dispatch(setAlertContactVisibility(false))}
                onSave={() => dispatch(savePopulationContactHandler(currentContact, currentPopulation))}
                contactInfoTitle={contactInfoTitle}
            />

            <ConfirmDelete
                open={deleteVis}
                onClose={closeDeleteAlertHandler}
                name={alertContactInfo.Name}
                delete={() => {
                    dispatch(deletePopulationContactHandler(alertContactInfo, currentPopulation, currentHealthPlan));
                    setDeleteVis(false);
                }}
                cancel={closeDeleteAlertHandler}
            />

            <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md" disableEnforceFocus>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography marginBottom={2} display={'inline-flex'} variant="h5">
                                Edit Population
                            </Typography>
                        </Grid>

                        <FormGroup style={{ width: '100%' }}>
                            <Stack direction="row" spacing={2} marginY={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled
                                            value={currentPopulation.HealthPlan?.Name || ''}
                                            id="name"
                                            label="Health Plan"
                                            name="Name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            required
                                            value={currentPopulation.PopulationName || ''}
                                            label="Population Name"
                                            name="PopulationName"
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => dispatch(setCurrentPopulation({ ...currentPopulation, PopulationName: e.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="plan-type-label">Missing Data Alert Frequency</InputLabel>
                                        <Select
                                            labelId="plan-type-label"
                                            id="plan-type"
                                            value={currentPopulation.FileNotReceivedInDays || ''}
                                            margin="dense"
                                            label="Missing Data Alert Frequency"
                                            name="FileNotReceivedInDays"
                                            onChange={(e) => dispatch(setCurrentPopulation({ ...currentPopulation, FileNotReceivedInDays: e.target.value }))}
                                        >
                                            <MenuItem value={0}>Select</MenuItem>
                                            {alertFrequencyList.map((alert, index) => (
                                                <MenuItem key={index} value={index + 1}>
                                                    {alert} days
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Stack>

                            <FormControl fullWidth>
                                <Grid item xs={12} marginY={5} display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography display={'inline-flex'} variant="h5">
                                        Alert Contacts
                                    </Typography>
                                    <Button variant="contained" onClick={addNewContactHandler}>
                                        Add Alert Contact
                                    </Button>
                                </Grid>

                                <Grid item xs={12}>
                                    {currentPopulation.AlertContacts?.length > 0 && (
                                        <div style={{ width: '100%' }}>
                                            <DataGrid
                                                rows={currentPopulation.AlertContacts || []}
                                                columns={columns}
                                                rowsPerPageOptions={[5]}
                                                getRowId={(row) => row.Id}
                                                autoHeight
                                                sx={{
                                                    [`& .${gridClasses.cell}`]: {
                                                        py: 1,
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className="button-120" variant="outlined" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        className="button-120"
                        variant="contained"
                        onClick={() => {
                            dispatch(updatePopulation(currentPopulation, currentPopulation.HealthPlan.Id));
                            dispatch(closeEditPopulationHandler());
                        }}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditPopulationDialog;
