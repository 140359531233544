import { DiagnosisTypeEnum } from '../../Enum/DiagnosisTypeEnum';
import { ICDcodeVersionEnum } from '../../Enum/ICDcodeVersionEnum';
import { ICD10 } from '../Shared/ICD10.model';

export default interface IDiagnosisDraft {
    Diagnosis: string;
}

export class DiagnosisPointer {
    Id: string;
    Name: string;
    Code: string;
    ICDcodeVersion: ICDcodeVersionEnum;
    DiagnosisType: DiagnosisTypeEnum;
}

export class DiagnosisDraft implements IDiagnosisDraft {
    Diagnosis: string;
    DiagnosisPointer: DiagnosisPointer;
    FirstDiagnosis: ICD10;
    SecondDiagnosis: ICD10;
    ThirdDiagnosis: ICD10;
    FourthDiagnosis: ICD10;
    DiagnosisFirstList: ICD10[];
    DiagnosisSecondList: ICD10[];
    DiagnosisThirdList: ICD10[];
    DiagnosisFourthList: ICD10[];

    constructor() {
        this.Diagnosis = '';
    }
}
