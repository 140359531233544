const useStyles = {
    customTable: {
        '&.MuiPaper-root': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },

        '& thead tr': {
            '& th': {
                color: 'black',
                padding: '8px 15px',
            },
            '& td': {
                backgroundColor: '#d2cfcf59',
            },
        },
        '& tbody tr': {
            '& th, td': {
                color: '#999',
                padding: '10px 15px',
                fontWeight: 500,

                '& .MuiRadio-root': {
                    padding: 0,
                },
            },
            '& td': {
                backgroundColor: '#d2cfcf59',
            },
        },

        '& .MuiTableCell-root': {
            borderBottom: '2px solid #191919',

            '&.no-border': {
                borderBottom: '0',
            },
        },
    },
    smLabelRadioBtn: {
        '&.MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,

            '& .MuiTypography-root': {
                fontSize: '14px',
                color: '#666',
                marginLeft: '4px',
            },
        },
    },
    numberLabel: {
        padding: '0px!important',
        minWidth: '22px',
        marginRight: '8px',
        textAlign: 'right',
    },
    totalText: {
        color: 'black',
        marginTop: '10px',
    },
};

export default useStyles;
