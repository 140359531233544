/* eslint-disable react-hooks/exhaustive-deps */
import { AddCircleOutline } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    List,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Member from '../../../Models/Member/Member.model';
import IMemberSelectOption from '../../../Models/Member/MemberSelectOption.model';
import IProviderSelectOption from '../../../Models/Provider/ProviderSelectOption.model';
import { RootState } from '../../../reducers';
import { setIsNewRequestModalOpen } from '../../../store/newRequest.slice';
import {
    fetchActiveMembersRequests,
    fetchLinkedRequest,
    fetchMyActiveRequests,
    fetchProvidersBookmarkedRequests,
    fetchProvidersRecentlyViewedRequests,
    fetchRequestsByProviderId,
    fetchRequestsByProvidersTeams,
    setIsShowingCompletedRequests,
    setLinkedRequestId,
    setOrderByOption,
    setRequests,
    setRequestsSearch,
    setSelectedMemberId,
    setSortByOption,
} from '../../../store/requests.slice';
import { ProviderId, apiString } from '../../../utils/constants';
import { IRequest } from '../interfaces/IRequest';
import { IRequestSearchByOption } from '../interfaces/IRequestSearchByOption';
import { requestStatusMapping } from '../mappings/RequestStatusMapping';
import { requestOrderByOptions } from '../Options/RequestOrderByOptions';
import { requestSortByOptions } from '../Options/RequestSortByOptions';
import RequestListItem from './RequestListItem';
import axios from 'axios';

export interface IRequestsSearchBoxProps {
    preSelectedMember?: Member;
    linkedRequestId?: string;
}

const RequestsListSideBar: React.FC<IRequestsSearchBoxProps> = ({ preSelectedMember, linkedRequestId }) => {
    // Redux
    const dispatch = useDispatch();
    const { sortByOption, orderByOption, requests, requestsSearchString, selectedMemberId, isShowingCompletedRequests, isFetchingRequestsList } = useSelector(
        (state: RootState) => state.requests
    );

    const { isNewRequestModalOpen } = useSelector((state: RootState) => state.newRequest);

    const requestListItems = React.Children.toArray(
        useMemo(() => {
            let filteredRequests = requests;

            if (isFetchingRequestsList) {
                return (
                    <Grid container justifyContent="center" alignContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                            <Typography style={{ paddingTop: 20 }}>
                                Loading... <CircularProgress size={22} />
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }

            if (requests?.length === 0) {
                return (
                    <Grid container justifyContent="center" alignContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                            <Typography style={{ paddingTop: 20 }}>No Requests To Display</Typography>
                        </Grid>
                    </Grid>
                );
            }

            if (requestsSearchString) {
                filteredRequests = requests.filter((item: IRequest) =>
                    `${item.Type} ${item.RequestFor.Name} ${item.CreatedOn}`.toLowerCase().includes(requestsSearchString.toLowerCase())
                );
            }
            if (!isShowingCompletedRequests) {
                filteredRequests = filteredRequests.filter(
                    (request) =>
                        request.Status !== requestStatusMapping.Completed.value &&
                        request.Status !== requestStatusMapping.Archived.value &&
                        request.Status !== requestStatusMapping.ClosedWithNoAction.value
                );
            }

            return filteredRequests.map((request: IRequest, index: number) => {
                return (
                    <>
                        <Divider variant="fullWidth" component="li" />
                        <RequestListItem {...request} index={index} />
                    </>
                );
            });
        }, [requests, requestsSearchString, isShowingCompletedRequests, isFetchingRequestsList])
    );

    // useState

    // TODO: This needs refactored. Magic numbers are bad. Ternary chains are bad. This is double bad.
    // I don't have time right now. It works.
    const [searchByOptionValue, setSearchByOptionValue] = useState<number>(linkedRequestId?.length > 0 ? 70 : preSelectedMember?.Id?.length > 0 ? 20 : 10);

    const [memberOptions, setMemberOptions] = useState<IMemberSelectOption[]>([]);
    const [selectedMemberOption, setSelectedMemberOption] = useState<IMemberSelectOption>();
    const [memberInputValue, setMemberInputValue] = useState<string>('');

    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [selectedProviderOption, setSelectedProviderOption] = useState<IProviderSelectOption>();
    const [providerInputValue, setProviderInputValue] = useState<string>('');

    // Mappings
    const requestSearchByOptionsMapping: {
        [key: string]: IRequestSearchByOption;
    } = {
        MyRequests: {
            value: 10,
            label: 'My Requests',
            fetchRequests: () => dispatch(fetchMyActiveRequests()),
        },
        Member: {
            value: 20,
            label: 'Member',
            fetchRequests: () => {
                dispatch(setRequests([]));
                if (preSelectedMember.Id?.length > 0 || selectedMemberOption?.MemberId?.length > 0) {
                    dispatch(fetchActiveMembersRequests(preSelectedMember.Id?.length > 0 ? preSelectedMember.Id : selectedMemberOption.MemberId));
                }
            },
        },
        Team: {
            value: 30,
            label: 'Team',
            fetchRequests: () => dispatch(fetchRequestsByProvidersTeams(ProviderId)),
        },
        AssignedProvider: {
            value: 40,
            label: 'Assigned Provider',
            fetchRequests: () => {
                dispatch(setRequests([]));
                if (selectedProviderOption?.ProviderId?.length > 0) {
                    dispatch(fetchRequestsByProviderId(selectedProviderOption.ProviderId));
                }
            },
        },
        Bookmarked: {
            value: 50,
            label: 'Bookmarked',
            fetchRequests: () => dispatch(fetchProvidersBookmarkedRequests(ProviderId)),
        },
        MyRecent: {
            value: 60,
            label: 'My Recent',
            fetchRequests: () => dispatch(fetchProvidersRecentlyViewedRequests(ProviderId)),
        },
        Linked: {
            value: 70,
            label: 'Linked Request',
            fetchRequests: () => dispatch(fetchLinkedRequest(linkedRequestId ?? '')),
        },
    };

    const requestSearchByOptions: IRequestSearchByOption[] = Object.values(requestSearchByOptionsMapping);

    const fetchAndSetMemberOptions = useCallback(async (searchString: string) => {
        if (searchString?.length) {
            const response = await axios.get(`${apiString}/member/memberoptions/${searchString.toLowerCase()}`);
            if (response.status === 200) {
                const { data } = response;
                setMemberOptions((data as IMemberSelectOption[]).sort((a, b) => a.MemberName.localeCompare(b.MemberName)));
            }
        }
    }, []);

    const fetchAndSetProviderOptions = useCallback(async (searchString: string) => {
        if (searchString?.length) {
            const response = await axios.get(`${apiString}/provider/provideroptions/${searchString.toLowerCase()}`);
            if (response.status === 200) {
                const { data } = response;
                setProviderOptions((data as { ProviderId: string; ProviderName: string }[]).sort((a, b) => a.ProviderName.localeCompare(b.ProviderName)));
            }
        }
    }, []);

    // useEffects

    useEffect(() => {
        if (linkedRequestId?.length > 0) {
            dispatch(setLinkedRequestId(linkedRequestId));
            setSearchByOptionValue(requestSearchByOptionsMapping.Linked.value);
        }
    }, [linkedRequestId]);

    // If pre-selected member, set member id and set the option automatically.
    useEffect(() => {
        if (preSelectedMember?.Id.length) {
            dispatch(setSelectedMemberId(preSelectedMember.Id));
            setSearchByOptionValue(requestSearchByOptionsMapping.Member.value);
        }
    }, [preSelectedMember?.Id]);

    const toggleModal = () => dispatch(setIsNewRequestModalOpen(!isNewRequestModalOpen));

    // Set the saved member id, and if the search option is for members
    // then refetch the requests with the member id.
    // TODO: refactor to use the value's mapped fetch function
    useEffect(() => {
        const { MemberId = '' } = selectedMemberOption ?? {};
        dispatch(setSelectedMemberId(MemberId));
        if (searchByOptionValue === requestSearchByOptionsMapping.Member.value && MemberId?.length) {
            dispatch(fetchActiveMembersRequests(MemberId));
        }
    }, [selectedMemberOption]);

    // Fetch requests by the provider selected from the dropdown.
    // TODO: refactor to use the value's mapped fetch function
    useEffect(() => {
        if (searchByOptionValue === requestSearchByOptionsMapping.AssignedProvider.value) {
            dispatch(fetchRequestsByProviderId(selectedProviderOption.ProviderId));
        }
    }, [selectedProviderOption]);

    // If tehres a search by option, find the associated fetch
    useEffect(() => {
        if (searchByOptionValue) {
            const { fetchRequests, label }: IRequestSearchByOption = requestSearchByOptions.find((opt) => opt.value === searchByOptionValue);
            console.trace({ label });
            fetchRequests();
        }
    }, [searchByOptionValue, requestsSearchString, selectedMemberId]);

    useEffect(() => {
        if (sortByOption && orderByOption) {
            dispatch(setRequests());
        }
    }, [sortByOption, orderByOption]);

    useEffect(() => {
        fetchAndSetMemberOptions(memberInputValue);
    }, [memberInputValue]);

    useEffect(() => {
        fetchAndSetProviderOptions(providerInputValue);
    }, [providerInputValue]);

    return (
        <Paper style={{ paddingTop: 10, minWidth: 100 }}>
            <Grid container justifyContent="center" alignItems="flex-start" direction="row" style={{ height: 1120 }} spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Container maxWidth={false}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} justifyContent="center" alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    align="center"
                                    style={{
                                        width: '100%',
                                        alignItems: 'center',
                                    }}
                                    variant="h5"
                                >
                                    Requests List
                                </Typography>
                            </Grid>
                            <Grid container item justifyContent={'space-between'} alignItems="center" spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControlLabel
                                        label={
                                            <Box component="div" fontSize={14}>
                                                Show Completed Requests
                                            </Box>
                                        }
                                        control={
                                            <Checkbox
                                                checked={isShowingCompletedRequests}
                                                onChange={() => {
                                                    dispatch(setIsShowingCompletedRequests(!isShowingCompletedRequests));
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                                {preSelectedMember?.Id?.length > 0 ? null : (
                                    <Grid item lg={12}>
                                        <TextField
                                            fullWidth
                                            select
                                            disabled={preSelectedMember?.Id?.length > 0}
                                            label="Search by"
                                            variant="outlined"
                                            value={searchByOptionValue}
                                            onChange={(event) => {
                                                setSearchByOptionValue(Number(event.target?.value));
                                            }}
                                        >
                                            {React.Children.toArray(
                                                requestSearchByOptions.map(({ value, label }) => <MenuItem value={value}>{label}</MenuItem>)
                                            )}
                                        </TextField>
                                    </Grid>
                                )}

                                {searchByOptionValue === 20 && (
                                    <Grid item lg={12} md={12} sm={12} xs={12} hidden={preSelectedMember?.Id?.length > 0}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="request-member-selection"
                                            options={memberOptions}
                                            isOptionEqualToValue={(option, value: any) => {
                                                return option.MemberId === value.MemberId;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Search Members" />}
                                            value={selectedMemberOption as any}
                                            onChange={(event: any, newValue: any) => {
                                                if (newValue?.value) {
                                                    setSelectedMemberOption(newValue.value);
                                                } else {
                                                    setSelectedMemberOption(newValue);
                                                }
                                            }}
                                            inputValue={memberInputValue}
                                            onInputChange={(event, value) => {
                                                setMemberInputValue(value);
                                            }}
                                            getOptionLabel={(option: any) => option.MemberName}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.MemberId}>
                                                        {option.MemberName}
                                                    </li>
                                                );
                                            }}
                                        />
                                    </Grid>
                                )}
                                {searchByOptionValue === 40 && (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            disableClearable
                                            id="request-provider-selection"
                                            options={providerOptions}
                                            isOptionEqualToValue={(option, value: any) => {
                                                return option.ProviderId === value.ProviderId;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Search Providers" />}
                                            value={selectedProviderOption as any}
                                            onChange={(event: any, newValue: any) => {
                                                if (newValue?.value) {
                                                    setSelectedProviderOption(newValue.value);
                                                } else {
                                                    setSelectedProviderOption(newValue);
                                                }
                                            }}
                                            inputValue={providerInputValue}
                                            onInputChange={(event, value) => {
                                                setProviderInputValue(value);
                                            }}
                                            getOptionLabel={(option: any) => option.ProviderName}
                                        />
                                    </Grid>
                                )}
                                <Grid item lg={6}>
                                    <Autocomplete
                                        fullWidth
                                        disableClearable
                                        id="request-sort-by-selection"
                                        options={requestSortByOptions}
                                        isOptionEqualToValue={(option, value: any) => {
                                            return option.value === value.value;
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Sort By" />}
                                        value={sortByOption as any}
                                        onChange={(event: any, newValue: any) => {
                                            if (newValue) {
                                                dispatch(setSortByOption(newValue));
                                            } else {
                                                dispatch(setSortByOption(newValue.value));
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={6}>
                                    <Autocomplete
                                        fullWidth
                                        disableClearable
                                        id="request-order-by-selection"
                                        options={requestOrderByOptions}
                                        isOptionEqualToValue={(option, value: any) => {
                                            return option.value === value.value;
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Order By" />}
                                        value={orderByOption as any}
                                        onChange={(event: any, newValue: any) => {
                                            if (newValue) {
                                                dispatch(setOrderByOption(newValue));
                                            } else {
                                                dispatch(setOrderByOption(newValue.value));
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Text Filter"
                                    variant="outlined"
                                    onChange={(event) => {
                                        dispatch(setRequestsSearch(event.target.value));
                                    }}
                                />
                            </Grid>
                            {preSelectedMember?.Id?.length > 0 && (
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Button startIcon={<AddCircleOutline />} variant="contained" color="primary" fullWidth onClick={toggleModal}>
                                        New Request
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ overflowY: 'auto', maxHeight: 650 }}>
                            <List>{requestListItems}</List>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RequestsListSideBar;
