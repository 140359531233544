import BaseEntity from '../BaseEntity.model';
import { MemberDevice } from './MemberDevice.model';

export default interface IMemberDevices extends BaseEntity {
    MemberId: string;
    BpCuff: MemberDevice;
    Glucometer: MemberDevice;
    PulseOX: MemberDevice;
    Scale: MemberDevice;
    Strips: MemberDevice;
}

export class MemberDevices implements IMemberDevices {
    Id: string;
    MemberId: string;
    BpCuff: MemberDevice;
    Glucometer: MemberDevice;
    PulseOX: MemberDevice;
    Scale: MemberDevice;
    Strips: MemberDevice;
    NoDevices: boolean;

    constructor() {
        this.MemberId = '';
    }
}
