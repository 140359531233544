export enum TransitionalCareStepTypeEnum {
    AdmissionDescription = 'Admission',
    Admission = 1,
    TransferDescription = 'Transfer',
    Transfer = 2,
    DischargeDescription = 'Discharge',
    Discharge = 3,
    ReAdmissionDescription = 'Re-Admission',
    ReAdmission = 4,
}
