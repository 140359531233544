import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setHasLabs, setHasRadiology, setSubMetaTags } from '../../../store/memberDocuments.slice';

const MetaTagDialog = (props) => {
    const dispatch = useDispatch();
    const { documentSubMetaTags, hasLabs, hasRadiology, metaTags, subMetaTags } = useSelector((state: RootState) => state.memberDocuments);
    const { tags, setMetaTags, onClose, open, save } = props;

    const changeTagHandler = (tag) => {
        let copy = [...metaTags];

        if (copy.map((el) => el.Id).includes(tag.Id)) {
            copy = copy.filter((el) => el.Id !== tag.Id);
        } else {
            copy = [...copy, tag];
        }
        let labs = copy.find((el) => el.Name === 'Lab Reports');
        let radiology = copy.find((el) => el.Name === 'Radiology');
        if (labs === undefined) {
            dispatch(setHasLabs(false));
            dispatch(setSubMetaTags([]));
        } else {
            dispatch(setHasLabs(true));
        }
        if (radiology === undefined) {
            dispatch(setHasRadiology(false));
            dispatch(setSubMetaTags([]));
        } else {
            dispatch(setHasRadiology(true));
        }
        dispatch(setMetaTags(copy));
    };

    const changeSubMetaTagHandler = (tag) => {
        let copy = [...subMetaTags];
        if (copy.map((el) => el.Id).includes(tag.Id)) {
            copy = copy.filter((el) => el.Id !== tag.Id);
        } else {
            copy = [...copy, tag];
        }
        dispatch(setSubMetaTags(copy));
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableEnforceFocus fullWidth>
            <Paper
                sx={{
                    padding: '16px',
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h6" component={'p'}>
                        Set Meta Tags
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ marginY: 2 }}>
                    <Grid container spacing={2} alignItems={'center'} justifyContent={'center'} width={'100%'}>
                        {tags.map((tag) => {
                            return (
                                <Grid key={tag.Id} item textAlign={'center'}>
                                    <Button
                                        sx={{ flex: 'none' }}
                                        variant={metaTags?.map((tag2) => tag2.Id).includes(tag.Id) ? 'contained' : 'outlined'}
                                        value={tag}
                                        onClick={() => changeTagHandler(tag)}
                                    >
                                        {tag.Name}
                                    </Button>
                                </Grid>
                            );
                        })}
                        {hasLabs && (
                            <>
                                <Grid item textAlign={'center'}>
                                    <Typography variant="h6" textAlign={'start'} justifySelf="flex-start" marginTop={2} marginBottom={4}>
                                        Lab Reports Sub Meta Tags
                                    </Typography>
                                    <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        {documentSubMetaTags
                                            ?.filter((el) => el.Name !== 'Mammogram')
                                            ?.map((tag) => {
                                                return (
                                                    <Grid key={tag.Id} item textAlign={'center'}>
                                                        <Button
                                                            sx={{ flex: 'none' }}
                                                            variant={subMetaTags?.map((tag2) => tag2.Id).includes(tag.Id) ? 'contained' : 'outlined'}
                                                            value={tag}
                                                            onClick={() => changeSubMetaTagHandler(tag)}
                                                        >
                                                            {tag.Name}
                                                        </Button>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {hasRadiology && (
                            <>
                                <Grid item textAlign={'center'}>
                                    <Typography variant="h6" textAlign={'start'} justifySelf="flex-start" marginTop={2} marginBottom={4}>
                                        Radiology Sub Meta Tags
                                    </Typography>
                                    <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        {documentSubMetaTags
                                            ?.filter((el) => el.Name === 'Mammogram')
                                            ?.map((tag) => {
                                                return (
                                                    <Grid key={tag.Id} item textAlign={'center'}>
                                                        <Button
                                                            sx={{ flex: 'none' }}
                                                            variant={subMetaTags?.map((tag2) => tag2.Id).includes(tag.Id) ? 'contained' : 'outlined'}
                                                            value={tag}
                                                            onClick={() => changeSubMetaTagHandler(tag)}
                                                        >
                                                            {tag.Name}
                                                        </Button>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className="button-120" onClick={save} autoFocus>
                        Save
                    </Button>
                    <Button variant="outlined" className="button-120" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    );
};

export default MetaTagDialog;
