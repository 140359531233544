export default interface IMessageAlert {
    Show: boolean;
    Message: string;
}

export class MessageAlert implements IMessageAlert {
    Show: boolean;
    Message: string;

    constructor() {
        this.Show = false;
        this.Message = '';
    }
}
