import RichTextEditor from '@mantine/rte';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Rating, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { ProviderId } from '../../utils/constants';
import { IResource } from './interfaces/IResource';
import { IResourceReview } from './interfaces/IResourceReview';
import { IResourceReviewEditModalProps } from './interfaces/IResourceReviewEditModalProps';

const ResourceReviewEditModal: React.FC<IResourceReviewEditModalProps> = (props) => {
    const { open, onClose, onSuccess, usersReviewIndex } = props;
    const [reviewValue, setReviewValue] = useState<number>(3);
    const [comment, setComment] = useState<string>('');
    const saveReview = () => {
        if (usersReviewIndex !== undefined && usersReviewIndex > -1) {
            const foundReview = formik.values.Reviews.find((review) => review.ReviewerProviderId === ProviderId);
            console.log({ foundReview, usersReviewIndex });
            const workingReview: IResourceReview = { ...foundReview, Value: reviewValue, Comment: comment, UpdatedOn: new Date() };
            formik.setFieldValue(`Reviews[${usersReviewIndex}]`, workingReview);
        } else {
            const workingReview: IResourceReview = {
                ReviewerProviderId: ProviderId,
                ReviewerName: null,
                CreatedOn: new Date(),
                UpdatedOn: new Date(),
                Value: reviewValue,
                Comment: comment,
            };
            formik.setFieldValue(`Reviews`, [...formik.values.Reviews, workingReview]);
        }
        formik.submitForm();
        if (onSuccess) {
            onSuccess();
        }
        onClose();
    };
    const formik = useFormikContext<IResource>();

    const review = useMemo(() => {
        const foundReview = formik.values.Reviews.find((review) => review.ReviewerProviderId === ProviderId);
        if (usersReviewIndex !== undefined && foundReview?.ReviewerProviderId?.length > 0) {
            return (
                <Stack spacing={2}>
                    <Rating
                        name="simple-controlled-existing-review"
                        precision={1}
                        value={formik.values.Reviews[usersReviewIndex].Value}
                        onChange={(event, newValue) => {
                            formik.setFieldValue(`Reviews[${usersReviewIndex}].Value`, newValue);
                        }}
                    />
                    <Typography variant="h6">Review</Typography>
                    <RichTextEditor
                        value={formik.values.Reviews[usersReviewIndex].Comment}
                        onChange={(value) => formik.setFieldValue(`Reviews[${usersReviewIndex}].Comment`, value)}
                    />
                </Stack>
            );
        } else {
            return (
                <Stack spacing={2}>
                    <Rating
                        name="simple-controlled-new-review"
                        precision={1}
                        value={reviewValue}
                        onChange={(event, newValue) => {
                            setReviewValue(newValue);
                        }}
                    />
                    <Typography variant="h6">Review</Typography>
                    <RichTextEditor
                        value={comment}
                        onChange={(value) => {
                            setComment(value);
                        }}
                    />
                </Stack>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.Reviews, usersReviewIndex, reviewValue, comment]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Your Review</DialogTitle>
            <DialogContent>{review}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={saveReview}>
                    Save
                </Button>
                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ResourceReviewEditModal;
