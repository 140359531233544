import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, Paper, Typography, ToggleButtonGroup, ToggleButton, TableContainer, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { usePopulationContext } from '../../../../hooks/populationContext';
import { useAnalyticContext } from '../../../../hooks/analyticContext';
import { configureBhrtcChart } from '../../../ChartConfiguration/ChartConfiguration';
import TopTenMembers from '../../../../Models/Claims/TopTenMembers.model';
import { TimePeriodTypeEnum } from '../../../../Enum/TimePeriodTypeEnum';
import FilterOptions from '../DetailFilterOption';
import { getBhRfCostGraph, getBfCostGraph, getMonthList, getTop10MembersByBhRtcAndRfCost, quarter, month } from '../AnalyticService';
import { ClaimPeriodEnum } from '../../../../Enum/ClaimPeriodEnum';
import { ClaimAnalyticEnum } from '../../../../Enum/ClaimAnalyticEnum';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const BhRtcCostDetail = () => {
    const history = useNavigate();
    const { analyticPopulations, isPopulationFetched } = usePopulationContext();
    const monthList = getMonthList();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isMemberShow, setIsMemberShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [yearChartOptions, setYearChartOptions] = useState({});
    const [monthChartOptions, setMonthChartOptions] = useState({});
    const [quarterChartOptions, setQuarterChartOptions] = useState({});
    const [yearGraphSummary, setYearGraphSummary] = useState<any>([]);
    const [monthGraphSummary, setMonthGraphSummary] = useState<any>([]);
    const [quarterGraphSummary, setQuarterGraphSummary] = useState<any>([]);
    const [claimPeriod, setClaimPeriod] = useState(ClaimPeriodEnum.BaseLine);
    const [topTenMembers, setTopTenMembers] = useState<TopTenMembers>();

    const {
        bhRtcPopulation,
        setBhRtcPopulation,
        bhRtcMemberStatus,
        setBhRtcMemberStatus,
        bhRtcPrimaryProvider,
        setBhRtcPrimaryProvider,
        bhRtcSecondaryProvider,
        setBhRtcSecondaryProvider,
        showBhRtcInActivePrimaryTeams,
        setShowBhRtcInActivePrimaryTeams,
        showBhRtcInActiveSecondaryTeams,
        setShowBhRtcInActiveSecondaryTeams,
    } = useAnalyticContext();

    const bhRtcName = 'BH RTC';
    const bhRfName = 'BH RF';

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setYearChartOptions(
                configureBhrtcChart('BH RTC/RF Costs', 'Baseline year vs Performance year', 'Population', 'Cost', [{ name: '', data: [0] }], [0], '', history)
            );

            setMonthChartOptions(
                configureBhrtcChart('BH RTC/RF Costs', 'Baseline year vs Performance year', 'Population', 'Cost', [{ name: '', data: [0] }], [0], '', history)
            );

            setQuarterChartOptions(
                configureBhrtcChart('BH RTC/RF Costs', 'Baseline year vs Performance year', 'Population', 'Cost', [{ name: '', data: [0] }], [0], '', history)
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                let yearResponse = [];
                let yearBhrtc, yearBhrf;
                const yearBhrfCost = await getBhRfCostGraph(
                    TimePeriodTypeEnum.YRS,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    0,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                yearBhrtc = yearBhrfCost;
                yearBhrtc.Name = bhRtcName;
                yearResponse.push(yearBhrtc);
                const yearBfCost = await getBfCostGraph(
                    TimePeriodTypeEnum.YRS,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    0,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                yearBhrf = yearBfCost;
                yearBhrf.Name = bhRfName;
                yearResponse.push(yearBhrf);
                setYearGraphSummary(yearResponse);

                let monthResponse = [];
                let monthBhrtc, monthBhrf;
                const monthBhrfCost = await getBhRfCostGraph(
                    TimePeriodTypeEnum.MTH,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    month,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                monthBhrtc = monthBhrfCost;
                monthBhrtc.Name = bhRtcName;
                monthResponse.push(monthBhrtc);
                const monthBfCost = await getBfCostGraph(
                    TimePeriodTypeEnum.MTH,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    month,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                monthBhrf = monthBfCost;
                monthBhrf.Name = bhRfName;
                monthResponse.push(monthBhrf);
                setMonthGraphSummary(monthResponse);

                let quarterResponse = [];
                let quarterBhrtc, quarterBhrf;
                const quarterBhrfCost = await getBhRfCostGraph(
                    TimePeriodTypeEnum.QRT,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    quarter,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                if (quarterBhrfCost.ok) {
                    quarterBhrtc = await quarterBhrfCost.json();
                    quarterBhrtc.Name = bhRtcName;
                    quarterResponse.push(quarterBhrtc);
                }
                const quarterBfCost = await getBfCostGraph(
                    TimePeriodTypeEnum.QRT,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    quarter,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                if (quarterBfCost.status === 200) {
                    quarterBhrf = quarterBfCost;
                    quarterBhrf.Name = bhRfName;
                    quarterResponse.push(quarterBhrf);
                }
                setQuarterGraphSummary(quarterResponse);

                setIsPrepared(true);
            };
            setYearChartOptions(
                configureBhrtcChart(
                    'BH RTC/RF Costs',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            setMonthChartOptions(
                configureBhrtcChart(
                    'BH RTC/RF Costs',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            setQuarterChartOptions(
                configureBhrtcChart(
                    'BH RTC/RF Costs',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            setIsGraphShow(false);
            changeFilter();
        }
    }, [
        bhRtcPopulation,
        bhRtcPrimaryProvider,
        bhRtcMemberStatus,
        showBhRtcInActivePrimaryTeams,
        showBhRtcInActiveSecondaryTeams,
        bhRtcSecondaryProvider,
        isPopulationFetched,
        history,
    ]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                const members = await getTop10MembersByBhRtcAndRfCost(
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    claimPeriod,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                setTopTenMembers(members);
            };
            setIsMemberShow(false);
            changeFilter();
        }
    }, [
        bhRtcPopulation,
        bhRtcPrimaryProvider,
        bhRtcMemberStatus,
        showBhRtcInActivePrimaryTeams,
        showBhRtcInActiveSecondaryTeams,
        bhRtcSecondaryProvider,
        isPopulationFetched,
        claimPeriod,
        history,
    ]);

    useEffect(() => {
        if (isPrepared) {
            if (yearGraphSummary) {
                let xAxisValues = [];
                let yAxisValues = [];
                let isData = false;

                yearGraphSummary.forEach((bhrtc) => {
                    let yValues = [];
                    if (bhrtc.Baseline != null) {
                        let tC = bhrtc.Baseline;
                        if (tC && tC > 0) {
                            isData = true;
                            yValues.push({ y: tC, name: 'BL Yr', title: bhrtc.Name });
                        }
                    }
                    if (bhrtc.PerformanceOne != null) {
                        let tC = bhrtc.PerformanceOne;
                        if (tC && tC > 0) {
                            isData = true;
                            yValues.push({ y: tC, name: 'Perf Yr', title: bhrtc.Name });
                        }
                    }
                    yAxisValues.push({
                        name: bhrtc.Name,
                        data: yValues,
                        pointPadding: 0,
                        color: bhrtc.Name === bhRtcName ? '#B0DBDE' : '#917DFF',
                        showInLegend: true,
                    });
                });

                if (isData) {
                    xAxisValues = ['BL Yr', 'Perf Yr'];
                } else {
                    xAxisValues = [];
                }

                setYearChartOptions(configureBhrtcChart('BH RTC/RF Costs', '', '', 'Cost', yAxisValues, xAxisValues, '', history));
            }

            if (monthGraphSummary) {
                let xAxisValues = [];
                let yAxisValues = [];
                let isData = false;

                monthGraphSummary.forEach((bhrtc) => {
                    let yValues = [];
                    if (bhrtc.Baseline != null) {
                        let tC = bhrtc.Baseline;
                        if (tC && tC > 0) {
                            isData = true;
                            yValues.push({ y: tC, name: 'BL Yr', title: bhrtc.Name });
                        }
                    }
                    if (bhrtc.PerformanceOne != null) {
                        let tC = bhrtc.PerformanceOne;
                        if (tC && tC > 0) {
                            isData = true;
                            yValues.push({ y: tC, name: 'Perf Yr', title: bhrtc.Name });
                        }
                    }
                    yAxisValues.push({
                        name: bhrtc.Name,
                        data: yValues,
                        pointPadding: 0,
                        color: bhrtc.Name === bhRtcName ? '#B0DBDE' : '#917DFF',
                        showInLegend: true,
                    });
                });

                if (isData) {
                    xAxisValues = ['BL Yr', 'Perf Yr'];
                } else {
                    xAxisValues = [];
                }

                setMonthChartOptions(
                    configureBhrtcChart('BH RTC/RF Costs', '', monthList.find((m) => m.Id === month).Value, 'Cost', yAxisValues, xAxisValues, '', history)
                );
            }

            if (quarterGraphSummary) {
                let xAxisValues = [];
                let yAxisValues = [];
                let isData = false;

                quarterGraphSummary.forEach((bhrtc) => {
                    let yValues = [];
                    if (bhrtc.Baseline != null) {
                        let tC = bhrtc.Baseline;
                        if (tC && tC > 0) {
                            isData = true;
                            yValues.push({ y: tC, name: 'BL Yr', title: bhrtc.Name });
                        }
                    }
                    if (bhrtc.PerformanceOne != null) {
                        let tC = bhrtc.PerformanceOne;
                        if (tC && tC > 0) {
                            isData = true;
                            yValues.push({ y: tC, name: 'Perf Yr', title: bhrtc.Name });
                        }
                    }
                    yAxisValues.push({
                        name: bhrtc.Name,
                        data: yValues,
                        pointPadding: 0,
                        color: bhrtc.Name === bhRtcName ? '#B0DBDE' : '#917DFF',
                        showInLegend: true,
                    });
                });

                if (isData) {
                    xAxisValues = ['BL Yr', 'Perf Yr'];
                } else {
                    xAxisValues = [];
                }

                setQuarterChartOptions(configureBhrtcChart('BH RTC/RF Costs', '', `Quarter ${quarter}`, 'Cost', yAxisValues, xAxisValues, '', history));
            }

            setIsGraphShow(true);
            setIsMemberShow(true);
            setIsPrepared(false);
        }
    }, [yearGraphSummary, monthGraphSummary, quarterGraphSummary, bhRtcPopulation, analyticPopulations, monthList, isPrepared, history]);

    const handleChange = async (e: any, val: any) => {
        setIsMemberShow(false);
        setClaimPeriod(val);
        const members = await getTop10MembersByBhRtcAndRfCost(
            bhRtcPopulation,
            bhRtcPrimaryProvider,
            val,
            bhRtcMemberStatus,
            bhRtcSecondaryProvider,
            showBhRtcInActivePrimaryTeams,
            showBhRtcInActiveSecondaryTeams
        );
        setTopTenMembers(members);
        setIsMemberShow(true);
    };

    const navigateToGraph = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    return (
        <Paper elevation={5}>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                <Grid item xs={12} paddingBottom={1}>
                    <span onClick={navigateToGraph}>
                        <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                    </span>
                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                        BH RTC/RF Costs
                    </Typography>
                </Grid>
                <FilterOptions
                    population={bhRtcPopulation}
                    setPopulation={setBhRtcPopulation}
                    memberStatus={bhRtcMemberStatus}
                    setMemberStatus={setBhRtcMemberStatus}
                    primaryProvider={bhRtcPrimaryProvider}
                    setPrimaryProvider={setBhRtcPrimaryProvider}
                    secondaryProvider={bhRtcSecondaryProvider}
                    setSecondaryProvider={setBhRtcSecondaryProvider}
                    claimPeriod={claimPeriod}
                    claimType={ClaimAnalyticEnum.BhRtcCost}
                    showInActivePrimaryTeams={showBhRtcInActivePrimaryTeams}
                    setShowInActivePrimaryTeams={setShowBhRtcInActivePrimaryTeams}
                    showInActiveSecondaryTeams={showBhRtcInActiveSecondaryTeams}
                    setShowInActiveSecondaryTeams={setShowBhRtcInActiveSecondaryTeams}
                />
                <Grid item xs={4} marginTop={1} sx={{ position: 'relative' }}>
                    {!isGraphShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <HighchartsReact highcharts={Highcharts} options={yearChartOptions} />
                </Grid>
                <Grid item xs={4} marginTop={1} sx={{ position: 'relative' }}>
                    {!isGraphShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <HighchartsReact highcharts={Highcharts} options={quarterChartOptions} />
                </Grid>
                <Grid item xs={4} marginTop={1} sx={{ position: 'relative' }}>
                    {!isGraphShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <HighchartsReact highcharts={Highcharts} options={monthChartOptions} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} marginTop={2} style={{ backgroundColor: '#F5F5F5' }} paddingY={1} paddingLeft={2} paddingRight={2}>
                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                        Top 10 Members
                    </Typography>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={claimPeriod}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        className="float-right"
                    >
                        <ToggleButton style={{ textTransform: 'none' }} value={ClaimPeriodEnum.BaseLine}>
                            Baseline
                        </ToggleButton>
                        <ToggleButton style={{ textTransform: 'none' }} value={ClaimPeriodEnum.PerformanceOne}>
                            CHP Performance
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                <Grid item xs={12} marginTop={2} minHeight="40vh" sx={{ position: 'relative' }}>
                    {!isGraphShow || !isMemberShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell align="left">Member Name</StyledTableCell>
                                                <StyledTableCell align="right">Year Cost</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {topTenMembers.ByYear.length ? (
                                                topTenMembers.ByYear.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.MemberName}</StyledTableCell>
                                                        <StyledTableCell align="right">${row.Cost.toFixed(2)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                        No data.
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell align="left">Member Name</StyledTableCell>
                                                <StyledTableCell align="right">Quarter Cost</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {topTenMembers.ByQuarter.length ? (
                                                topTenMembers.ByQuarter.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.MemberName}</StyledTableCell>
                                                        <StyledTableCell align="right">${row.Cost.toFixed(2)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                        No data.
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell align="left">Member Name</StyledTableCell>
                                                <StyledTableCell align="right">Month Cost</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {topTenMembers.ByMonth.length ? (
                                                topTenMembers.ByMonth.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.MemberName}</StyledTableCell>
                                                        <StyledTableCell align="right">${row.Cost.toFixed(2)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                        No data.
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default BhRtcCostDetail;
