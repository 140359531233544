export default interface IDrugAssessment {
    HasUsageInPastYear?: boolean;
    HasUsedOtherThanMedicalReason?: boolean;
    HasAbusedMoreThanOne?: boolean;
    UnableToStop?: boolean;
    HasBlackoutsOrFlashback?: boolean;
    FeltGuiltyAbout?: boolean;
    FamilyComplained?: boolean;
    NeglectedFamily?: boolean;
    EngagedInIllegalActivityToObtainDrug?: boolean;
    IsExperiencedWithdrawalSymptoms?: boolean;
    HasMedicalProblemsDueToDrugUsage?: boolean;
    Score?: number;
}

export class DrugAssessment implements IDrugAssessment {
    HasUsageInPastYear?: boolean;
    HasUsedOtherThanMedicalReason?: boolean;
    HasAbusedMoreThanOne?: boolean;
    UnableToStop?: boolean;
    HasBlackoutsOrFlashback?: boolean;
    FeltGuiltyAbout?: boolean;
    FamilyComplained?: boolean;
    NeglectedFamily?: boolean;
    EngagedInIllegalActivityToObtainDrug?: boolean;
    IsExperiencedWithdrawalSymptoms?: boolean;
    HasMedicalProblemsDueToDrugUsage?: boolean;
    Score?: number;

    constructor() {
        this.HasUsageInPastYear = null;
    }
}
