import { useState } from 'react';
import { Box, Button, Chip, Collapse, List, ListItemText, ListItemButton, ListItemIcon, ListSubheader, Menu, MenuItem, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from 'react';
import { RootState } from '../../reducers';
import {
    admissionChecklist,
    closeTransitionalCare,
    closeTransitionalCareDueToDeathById,
    dischargeChecklist,
    setCurrentChecklistItem,
    setDischargeFacility,
    setExpandedList,
    setIsReAdmitting,
    setIsTransferring,
    setMemberTransitionalCare,
    setPatientAdmissionTitle,
    setTransitionStep,
    toggleIsShowDischargeDetails,
    toggleIsShowFacilityDetails,
} from '../../store/transitionalCare.slice';
import DetailsPage from './DetailsPage';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import { OutsideFacilityLocationEnum } from '../../Enum/OutsideFacilityLocationEnum';
import { ConfirmationDialog } from './ConfirmationDialog';
import { TransitionalCareStepTypeEnum } from '../../Enum/TransitionalCareStepTypeEnum';
import { FacilityTypeEnum } from '../../Enum/FacilityTypeEnum';
import { TransitionalCareVisitTypeEnum } from '../../Enum/TransitionalCareVisitTypeEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import Authorization from '../../utils/Authorization';
import { roleEnum } from '../../utils/assessments';
import { insertTimeline } from '../../store/timeline.slice';
import { TimelineTypeEnum } from '../../Enum/TimelineTypeEnum';
import { PatientDischarge } from '../../Models/TransitionalCare/PatientDischarge.model';
import { NewMemberTransitionalCare } from '../../Models/TransitionalCare/NewMemberTransitionalCareModel';
import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';

interface ITransitionalListMenuProps {
    memberId: string;
    memberTransitionalCares: NewMemberTransitionalCare[];
    isAssessment: boolean;
}

const TransitionsListMenu: React.FC<ITransitionalListMenuProps> = ({ isAssessment, memberId, memberTransitionalCares }) => {
    const dispatch = useDispatch();
    const auth = new Authorization();
    const override = auth.Role === roleEnum.CEO;
    const { currentTransitionTab, memberTransitionalCare, transitionStep, expandedList } = useSelector((state: RootState) => state.transitionalCare);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const engagementEncounters = engagementDraft?.Encounters?.map((el) => el as any);
    const NoStepSelected = () => {
        return (
            <Box display={'flex'} textAlign={'center'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'100%'}>
                <Stack width={300}>
                    <Typography variant="h5" marginBottom={3} fontWeight={700} color={'text.secondary'}>
                        Select transition stage
                    </Typography>
                    <Typography variant="body1" color={'text.secondary'}>
                        You can select a transition stage from the list on the left for more information
                    </Typography>
                </Stack>
            </Box>
        );
    };

    const [hideList, setHideList] = useState(false);
    const [closeAlertDialog, setCloseAlertDialog] = useState<any>({ isShow: false, message: '', canCloseTransitionalCare: false, closeDueToDeath: false });

    const [closeTransitionDialogTitle, setCloseTransitionDialogTitle] = useState('');

    const [transferAnchorEl, setTransferAnchorEl] = React.useState<null | HTMLElement>(null);
    const openTransfer = Boolean(transferAnchorEl);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openClose = Boolean(anchorEl);

    const getReadmissionStatus = (transition: NewMemberTransitionalCare) => {
        const { TransitionSteps } = transition;
        const admission = TransitionSteps.find((el) => el.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
        const dischargeSteps = TransitionSteps.filter((el) => el.TransitionStepType === TransitionalCareStepTypeEnum.Discharge);
        const mostRecentDischarge = dischargeSteps[dischargeSteps.length - 1];
        const dischargeDate = moment(mostRecentDischarge?.AdmissionDate);
        const today = moment();
        const diff = today.diff(dischargeDate, 'days');

        return (
            diff <= 30 &&
            admission.VisitType === TransitionalCareVisitTypeEnum.Inpatient &&
            (admission.AdmissionType === FacilityTypeEnum.BehavioralHealthLevel1 || admission.AdmissionType === FacilityTypeEnum.PhysicalHealth)
        );
    };

    const movedToFacility = () => {
        handleClose();
        dispatch(setPatientAdmissionTitle('Transfer to new facility'));
        dispatch(setIsTransferring(true));
        dispatch(toggleIsShowFacilityDetails(true));
    };

    const movedToHome = () => {
        handleClose();
        dispatch(setDischargeFacility(new PatientDischarge()));
        dispatch(toggleIsShowDischargeDetails(true));
    };

    const handleReAdmit = () => {
        handleClose();
        dispatch(setPatientAdmissionTitle('Re-admission'));
        dispatch(setIsReAdmitting(true));
        dispatch(toggleIsShowFacilityDetails(true));
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTransferAnchorEl(null);
    };

    const getMemberTransitionalCareCompletion = (transition) => {
        const { TransitionSteps } = transition;
        let incompleteTransition = [];
        TransitionSteps.filter((step: TransitionStep) => step.IsDeleted === false).forEach((step) => {
            const isDischarge = Boolean(step.TransitionStepType === TransitionalCareStepTypeEnum.Discharge);
            const checklist = isDischarge ? dischargeChecklist() : admissionChecklist();
            let incompleteChecklist = [];
            checklist.forEach((el) => {
                if (step[el.name] !== undefined && step[el.name] !== null) {
                } else {
                    incompleteChecklist.push(el.label);
                }
            });
            if (incompleteChecklist.length) {
                incompleteTransition.push({
                    step:
                        TransitionalCareStepTypeEnum[step.TransitionStepType] === 'ReAdmission'
                            ? 'Re-Admission'
                            : TransitionalCareStepTypeEnum[step.TransitionStepType],
                    incompleteChecklist,
                });
            }
        });
        return incompleteTransition;
    };

    const handleOnClickClose = (transition: NewMemberTransitionalCare) => {
        handleClose();

        const incompleteTransition = getMemberTransitionalCareCompletion(transition);
        if (incompleteTransition.length > 0) {
            //show alert with incomplete steps
            let message = (
                <Box>
                    <Typography component={'span'} variant="body1">
                        It appears that the following data fields of the transitional care have not been filled in:
                    </Typography>
                    <Box p={2}>
                        {incompleteTransition.map((el, index) => (
                            <Box key={index + el.step}>
                                <Typography component={'span'} variant="body1">
                                    {el.step}:
                                </Typography>
                                <ul>
                                    {el.incompleteChecklist.map((el) => (
                                        <li key={el}>{el}</li>
                                    ))}
                                </ul>
                            </Box>
                        ))}
                    </Box>
                    {override && (
                        <Typography component={'span'} variant="body1" fontWeight={'bold'} color={'error'}>
                            Are you sure you wish to close incomplete transitional care with manager override?
                        </Typography>
                    )}
                </Box>
            );
            override
                ? setCloseAlertDialog({ isShow: true, message: message, canCloseTransitionalCare: true, closeDueToDeath: false })
                : setCloseAlertDialog({ isShow: true, message: message, canCloseTransitionalCare: false });

            setCloseTransitionDialogTitle('Incomplete transitional care');
        } else if (incompleteTransition.length === 0) {
            setCloseTransitionDialogTitle('Success! All steps are complete');
            setCloseAlertDialog({
                isShow: true,
                message: (
                    <>
                        <Typography variant="body1">Are you sure want to close this transitional care?</Typography>
                        {isAssessment && engagementDraft.MedicationReconciliationStatus !== StatusEnum.Completed && (
                            <Typography variant="body1" fontWeight={700} marginY={2} color={'error'}>
                                You must check if Medication Reconciliation 1111F is documented in Procedure Sign Off.
                            </Typography>
                        )}
                    </>
                ),
                canCloseTransitionalCare: true,
            });
        } else {
            setCloseAlertDialog({
                isShow: true,
                message: (
                    <Typography variant="body1">
                        You can not close the transitional care without entering discharge information or date to see NP or BH.
                    </Typography>
                ),
                canCloseTransitionalCare: false,
            });
        }
    };

    const closeDueToDeathHandler = () => {
        setCloseTransitionDialogTitle('Close transitional care due to death');
        setCloseAlertDialog({
            isShow: true,
            message: <Typography variant="body1">Are you sure you wish to close transition due to death?</Typography>,
            canCloseTransitionalCare: true,
            closeDueToDeath: true,
        });
        handleClose();
    };

    const handleOnConfirmClose = () => {
        if (getMemberTransitionalCareCompletion(memberTransitionalCare).length > 0 && override) {
            let message = `Transitional Care closed without completion of all items - ${moment().format('MM/DD/YYYY')}`;
            dispatch(insertTimeline(memberId, message, TimelineTypeEnum.OverrideCloseTransitionalCare));
        }

        if (closeAlertDialog.canCloseTransitionalCare && !closeAlertDialog.closeDueToDeath) {
            dispatch(closeTransitionalCare(memberTransitionalCare.Id, isAssessment));
            let message = `CLOSED Transitional Care - ${moment().format('MM/DD/YYYY')}`;
            dispatch(insertTimeline(memberId, message, TimelineTypeEnum.TransitionalCare));
        }
        if (closeAlertDialog.canCloseTransitionalCare && closeAlertDialog.closeDueToDeath) {
            dispatch(closeTransitionalCareDueToDeathById(memberTransitionalCare.Id, isAssessment));
            let message = `CLOSED Transitional Care DUE TO MEMBER DEATH - ${moment().format('MM/DD/YYYY')}`;
            dispatch(insertTimeline(memberId, message, TimelineTypeEnum.TransitionalCare));
        }
        setCloseAlertDialog({ isShow: false, message: '=', canCloseTransitionalCare: false });
    };

    const getCompletionPercentageHandler = (transition, checklist) => {
        let completed = 0;
        let length = checklist.length;
        checklist.forEach((el) => {
            if (transition && transition[el.name] !== undefined && transition[el.name] !== null) {
                completed++;
            }
        });
        return { completed, length };
    };

    const expandedListHandler = (event, transition: NewMemberTransitionalCare) => {
        if (expandedList.includes(transition.Id)) {
            dispatch(setExpandedList(expandedList.filter((id) => id !== transition.Id)));
        } else {
            dispatch(setExpandedList([...expandedList, transition.Id]));
        }
    };

    const TransferMenu = (transition: NewMemberTransitionalCare) => {
        return (
            <div>
                <Button
                    id="demo-positioned-button"
                    aria-controls={openTransfer ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openTransfer ? 'true' : undefined}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setTransferAnchorEl(event.currentTarget);
                        dispatch(setMemberTransitionalCare(transition));
                    }}
                    variant="outlined"
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{ display: hideList ? 'none' : 'flex', margin: 4 }}
                >
                    Transfer
                </Button>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={transferAnchorEl}
                    open={openTransfer}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => movedToFacility()}>Transfer to another facility</MenuItem>
                    <MenuItem onClick={() => movedToHome()}>Discharge Member</MenuItem>
                    <MenuItem onClick={() => closeDueToDeathHandler()}>Close due to death</MenuItem>
                </Menu>
            </div>
        );
    };

    const CloseMenu = (transition) => {
        return (
            <div>
                <Button
                    id="demo-positioned-button"
                    aria-controls={openClose ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openClose ? 'true' : undefined}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(event.currentTarget);
                        dispatch(setMemberTransitionalCare(transition));
                    }}
                    variant="outlined"
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{ display: hideList ? 'none' : 'flex', margin: 4 }}
                >
                    Close Transition
                </Button>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openClose}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {getReadmissionStatus(transition) && <MenuItem onClick={() => handleReAdmit()}>Re-admit</MenuItem>}
                    <MenuItem onClick={() => handleOnClickClose(transition)}>Close Transition</MenuItem>
                    <MenuItem onClick={() => closeDueToDeathHandler()}>Close due to death</MenuItem>
                </Menu>
            </div>
        );
    };

    const renderListIcon = (completed, length) => {
        let icon = null;
        if (completed !== length) {
            icon = <Chip sx={{ marginLeft: 2 }} label={`${completed} / ${length}`} variant="outlined" color="primary" />;
        }
        if (completed === length) {
            icon = (
                <ListItemIcon style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CheckIcon color="primary" />
                </ListItemIcon>
            );
        }
        return icon;
    };

    const onChangeHandler = (step, transition) => {
        dispatch(setCurrentChecklistItem(1));
        dispatch(setTransitionStep(step));
        dispatch(setMemberTransitionalCare(transition));
    };

    return (
        <>
            <ConfirmationDialog
                open={closeAlertDialog.isShow}
                isLoading={isLoading}
                isOverride={override}
                title={closeTransitionDialogTitle}
                hideCancelButton={!closeAlertDialog.canCloseTransitionalCare}
                onClose={() => setCloseAlertDialog({ isShow: false, message: '=', canCloseTransitionalCare: false })}
                onConfirm={handleOnConfirmClose}
                message={closeAlertDialog.message}
            />
            <Stack direction={'row'} paddingBottom={5} sx={{ bgcolor: 'grey.200', [`.MuiListSubheader-root`]: { bgcolor: 'grey.200' } }}>
                <List
                    sx={{ width: hideList ? 0 : '25vw', display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}
                    subheader={
                        <ListSubheader sx={{ display: hideList ? 'none' : 'flex' }}>
                            {currentTransitionTab === 'open' ? 'OPEN' : 'CLOSED'} TRANSITIONS{' '}
                        </ListSubheader>
                    }
                >
                    {memberTransitionalCares.map((transition, index) => {
                        const canReadmit = getReadmissionStatus(transition);

                        const transitionalCareSteps = transition.TransitionSteps.filter((el) => el.IsDeleted === false);
                        const admission = transitionalCareSteps.find((el) => el.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
                        const dischargeSteps = transitionalCareSteps.filter((el) => el.TransitionStepType === TransitionalCareStepTypeEnum.Discharge);
                        const discharge = dischargeSteps[dischargeSteps.length - 1] || null;
                        const daysSinceDischarge = moment(moment.now()).diff(discharge?.AdmissionDate, 'days');
                        const folowUpCompleted = engagementEncounters?.filter((el) => el.CptCode === '1111F').length > 0;
                        let chipColor = daysSinceDischarge <= 7 ? '#2e7d32' : daysSinceDischarge <= 14 ? '#ff9800' : '#c62828';
                        chipColor = folowUpCompleted ? '#1b5e20' : chipColor;
                        const isDischarged =
                            transitionalCareSteps[transitionalCareSteps.length - 1].TransitionStepType === TransitionalCareStepTypeEnum.Discharge;
                        return (
                            <React.Fragment key={index}>
                                <ListItemButton
                                    sx={{ display: hideList ? 'none' : 'flex' }}
                                    id={transition.Id}
                                    onClick={(e) => expandedListHandler(e, transition)}
                                >
                                    <ListItemText
                                        primary={<Typography variant="h6">Transition {index + 1}</Typography>}
                                        secondary={
                                            <Typography variant="body2">
                                                {discharge
                                                    ? moment(admission?.AdmissionDate).format('MM.DD.YYYY') +
                                                      ' - ' +
                                                      moment(discharge?.AdmissionDate).format('MM.DD.YYYY')
                                                    : moment(admission?.AdmissionDate).format('MM.DD.YYYY') + ' - Open'}
                                            </Typography>
                                        }
                                    />
                                    {expandedList.includes(transition.Id) ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={expandedList.includes(transition.Id)} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding key={index}>
                                        {transitionalCareSteps?.map((step, index) => {
                                            const isDischarge = Boolean(step.TransitionStepType === TransitionalCareStepTypeEnum.Discharge);
                                            const checklist = isDischarge ? dischargeChecklist() : admissionChecklist();
                                            const { completed, length } = getCompletionPercentageHandler(step, checklist);
                                            return (
                                                <ListItemButton
                                                    sx={{ pl: 4, display: hideList ? 'none' : 'flex', '&.active': { bgcolor: 'rgba(33, 150, 243, 0.12)' } }}
                                                    key={step.Id}
                                                    className={step.Id === transitionStep.Id ? 'active' : ''}
                                                    onClick={() => onChangeHandler(step, transition)}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="h6">
                                                                {TransitionalCareStepTypeEnum[step.TransitionStepType] === 'ReAdmission'
                                                                    ? 'Re-Admission'
                                                                    : TransitionalCareStepTypeEnum[step.TransitionStepType]}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography variant="body2" color={'text.secondary'}>
                                                                {step.TransitionStepType === TransitionalCareStepTypeEnum.Discharge
                                                                    ? OutsideFacilityLocationEnum[step.OutsideFacilityLocation]
                                                                    : step.Facility?.Name}
                                                            </Typography>
                                                        }
                                                    />
                                                    {renderListIcon(completed, length)}
                                                </ListItemButton>
                                            );
                                        })}
                                        {dischargeSteps.length > 0 && Boolean(isAssessment) && (
                                            <ListItemButton
                                                sx={{ pl: 4, display: hideList ? 'none' : 'flex', '&.active': { bgcolor: 'rgba(33, 150, 243, 0.12)' } }}
                                                //onClick={() => onChangeHandler(step, transition)}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h6">Follow-up</Typography>}
                                                    secondary={
                                                        folowUpCompleted ? null : (
                                                            <Typography variant="body2" color={chipColor}>
                                                                {daysSinceDischarge} days since discharge
                                                            </Typography>
                                                        )
                                                    }
                                                />
                                                <Chip
                                                    sx={{ marginLeft: 2, color: chipColor, borderColor: chipColor }}
                                                    label={folowUpCompleted ? 'Complete' : `Incomplete`}
                                                    variant="outlined"
                                                />
                                            </ListItemButton>
                                        )}
                                    </List>
                                    {currentTransitionTab === 'open' && isDischarged && CloseMenu(transition)}
                                    {currentTransitionTab === 'open' && !isDischarged && TransferMenu(transition)}
                                    {currentTransitionTab === 'closed' && canReadmit && (
                                        <Button onClick={() => handleReAdmit()} variant="outlined" sx={{ display: hideList ? 'none' : 'flex', margin: 4 }}>
                                            Re-admit
                                        </Button>
                                    )}
                                </Collapse>
                            </React.Fragment>
                        );
                    })}
                </List>
                <Button sx={{ p: 0, m: 0, minWidth: '28px', color: 'text.primary' }} onClick={() => setHideList(!hideList)}>
                    {hideList ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                </Button>
            </Stack>
            <Stack width={'100%'} p={2}>
                {memberTransitionalCare?.Id ? <DetailsPage isAssessment={isAssessment} /> : <NoStepSelected />}
            </Stack>
        </>
    );
};

export default TransitionsListMenu;
