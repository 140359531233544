import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    CallListTabs,
    activityOptions,
    createNote,
    insertMemberStatusChange,
    setChangeStatusFormIsValid,
    setChangeStatusIsOpen,
    setMemberStatusChange,
    setStatusChangeError,
} from '../../../store/onboardingWorkflow.slice';
import Authorization from '../../../utils/Authorization';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';

const ChangeStatusModal = (props) => {
    const { callItem, changeStatusFormIsValid, changeStatusIsOpen, confirmationCallItem, memberStatusChange, selectedCallListTab, statusChangeError } =
        useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const dispatch = useDispatch();
    const auth = new Authorization();
    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    const item = isConfirmCall ? confirmationCallItem : callItem;

    const warningButtonText = [
        { label: MemberStatusEnum.DeceasedDescription, value: MemberStatusEnum.Deceased },
        { label: MemberStatusEnum.DisenrolledFromHealthPlanDescription, value: MemberStatusEnum.DisenrolledFromHealthPlan },
        { label: MemberStatusEnum.RefusedToEnrollDescription, value: MemberStatusEnum.RefusedToEnroll },
        { label: MemberStatusEnum.UnreachableDescription, value: MemberStatusEnum.Unreachable },
    ];

    const closeChangeStatusHandler = () => {
        dispatch(setStatusChangeError(''));
        dispatch(setChangeStatusFormIsValid(true));
        dispatch(setChangeStatusIsOpen(false));
    };

    const submitStatusChangeHandler = () => {
        if (memberStatusChange.RequestedStatus.length === 0 || memberStatusChange.Note.length === 0) {
            dispatch(setChangeStatusFormIsValid(false));
            return;
        } else {
            dispatch(setChangeStatusFormIsValid(true));
            const newValue = { ...memberStatusChange, RequestedStatus: memberStatusChange.RequestedStatus };
            let message = `Status change from Undetermined to ${MemberStatusEnum[newValue.RequestedStatus]} has been sent for approval - ${newValue.Note}`;
            const note = createNote(item.MemberId, activityOptions.MEMBER_STATUS_CHANGE, message, auth);
            dispatch(insertMemberStatusChange(item, newValue, note, isConfirmCall));
        }
    };

    return (
        <Dialog open={changeStatusIsOpen} onClose={closeChangeStatusHandler} fullWidth maxWidth="sm">
            <DialogTitle>Request Status Change</DialogTitle>
            <DialogContent>
                {statusChangeError.length === 0 && (
                    <FormGroup>
                        <Stack spacing={2} paddingY={2}>
                            <FormControl fullWidth>
                                <InputLabel
                                    error={!changeStatusFormIsValid && memberStatusChange.RequestedStatus.length === 0}
                                    required
                                    id="demo-simple-select-label"
                                >
                                    Status
                                </InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    error={!changeStatusFormIsValid && memberStatusChange.RequestedStatus.length === 0}
                                    name="RequestedStatus"
                                    value={memberStatusChange.RequestedStatus || ''}
                                    label="Status"
                                    onChange={(e) => dispatch(setMemberStatusChange({ ...memberStatusChange, RequestedStatus: e.target.value }))}
                                >
                                    {warningButtonText.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                required
                                error={!changeStatusFormIsValid && memberStatusChange.Note.length === 0}
                                helperText={!changeStatusFormIsValid && memberStatusChange.Note.length === 0 && 'Please enter a reason'}
                                label="Reason"
                                name="Note"
                                variant="outlined"
                                value={memberStatusChange.Note || ''}
                                multiline
                                rows={7}
                                onChange={(e) => dispatch(setMemberStatusChange({ ...memberStatusChange, Note: e.target.value }))}
                            />
                        </Stack>
                    </FormGroup>
                )}
                {statusChangeError.length > 0 && <Typography variant="body1">{statusChangeError}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" sx={{ minWidth: '120px' }} onClick={closeChangeStatusHandler}>
                    Cancel
                </Button>
                {statusChangeError.length === 0 && (
                    <Button variant="contained" sx={{ minWidth: '120px' }} color="primary" onClick={submitStatusChangeHandler}>
                        Submit
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ChangeStatusModal;
