export default interface IHeight {
    Feet: number | null;
    Inch: number | null;
}

export default class Height implements IHeight {
    Feet: number | null;
    Inch: number | null;

    constructor() {
        this.Feet = null;
        this.Inch = null;
    }
}
