import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

const getDefaultMemberDevice = () => {
    return { BpCuff: null, Glucometer: null, PulseOX: null, Scale: null, Strips: null };
};

const remoteMonitoring = createSlice({
    name: 'remoteMonitoring',
    initialState: {
        alertMessage: '',
        diastolicBPValue: [],
        glucometerValue: [],
        heartRateValue: [],
        memberDevices: getDefaultMemberDevice(),
        openAlert: false,
        oximeterValue: [],
        scaleValue: [],
        severity: true,
        systolicBPValue: [],
        vitalSignParameters: [],
    },
    reducers: {
        _setAlertMessage(state, action) {
            state.alertMessage = action.payload;
        },
        _setDiastolicBPValue(state, action) {
            state.diastolicBPValue = action.payload;
        },
        _setGlucometerValue(state, action) {
            state.glucometerValue = action.payload;
        },
        _setHeartRateValue(state, action) {
            state.heartRateValue = action.payload;
        },
        _setMemberDevices(state, action) {
            state.memberDevices = action.payload;
        },
        _setOpenAlert(state, action) {
            state.openAlert = action.payload;
        },
        _setOximeterValue(state, action) {
            state.oximeterValue = action.payload;
        },
        _setScaleValue(state, action) {
            state.scaleValue = action.payload;
        },
        _setSeverity(state, action) {
            state.severity = action.payload;
        },
        _setSystolicBPValue(state, action) {
            state.systolicBPValue = action.payload;
        },
        _setVitalSignParameters(state, action) {
            state.vitalSignParameters = action.payload;
        },
    },
});

const {
    _setAlertMessage,
    _setDiastolicBPValue,
    _setGlucometerValue,
    _setHeartRateValue,
    _setMemberDevices,
    _setOpenAlert,
    _setOximeterValue,
    _setScaleValue,
    _setSeverity,
    _setSystolicBPValue,
    _setVitalSignParameters,
} = remoteMonitoring.actions;

export const setAlertMessage =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setAlertMessage(params));
    };

export const setDiastolicBPValue =
    (params: number[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setDiastolicBPValue(params));
    };

export const setGlucometerValue =
    (params: number[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setGlucometerValue(params));
    };

export const setHeartRateValue =
    (params: number[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setHeartRateValue(params));
    };

export const setOpenAlert =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAlert(params));
    };

export const setOximeterValue =
    (params: number[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setOximeterValue(params));
    };

export const setScaleValue =
    (params: number[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setScaleValue(params));
    };

export const setSeverity =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setSeverity(params));
    };

export const setSystolicBPValue =
    (params: number[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSystolicBPValue(params));
    };

export const insertVitalSignParameters =
    (payload: any): AppThunk =>
    async (dispatch) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.put(`${API_URL}/VitalSigns/insertvitalsignparameters`, { VitalSignParameters: payload });
            dispatch(setAlertMessage('Remote Monitoring Successfully Updated.'));
            dispatch(setSeverity(true));
            dispatch(setOpenAlert(true));
        } catch (error) {
            dispatch(setAlertMessage(`An Error Occurred. \n${error}`));
            dispatch(setSeverity(false));
            dispatch(setOpenAlert(true));
            console.log(error);
        }
    };

export const getMemberDevices =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/VitalSigns/getmemberdevices?memberId=${memberId}`);
            dispatch(_setMemberDevices(response.data));
            if (response.status === 204) {
                console.log('NOTHING FOUND?: ', response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

export const getVitalSignParameters =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/VitalSigns/getvitalsignparameters?memberId=${memberId}`);
            dispatch(_setVitalSignParameters(response.data.VitalSignParameters));
            response.data.VitalSignParameters?.forEach((el) => {
                if (el.Type === 1) {
                    dispatch(_setSystolicBPValue([el.Min, el.Max]));
                }
                if (el.Type === 2) {
                    dispatch(_setDiastolicBPValue([el.Min, el.Max]));
                }
                if (el.Type === 3) {
                    dispatch(_setHeartRateValue([el.Min, el.Max]));
                }
                if (el.Type === 4) {
                    dispatch(_setOximeterValue([el.Min, el.Max]));
                }
                if (el.Type === 5) {
                    dispatch(_setScaleValue([el.Min, el.Max]));
                }
                if (el.Type === 6) {
                    dispatch(_setGlucometerValue([el.Min, el.Max]));
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

export default remoteMonitoring.reducer;
