import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, Modal, Typography, TextField, FormControl, CircularProgress, Grid, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { RootState } from '../../reducers';
import classes from '../DocumentEngagements/Styles';
import moment from 'moment';
import Authorization from '../../utils/Authorization';
import { DocumentOnboarding } from '../../Models/DocumentOnboardings/DocumentOnboarding.model';
import { saveDocumentOnboarding, toggleShowDocumentOnboardingModal } from '../../store/documentOnboarding.slice';
import { OnboardingMeansOfEngagements } from '../../utils/mappings';

const docOnboardingFormSchema = Yup.object().shape({
    MeansOfEngagement: Yup.string().nullable().required('Select means of engagement.'),
    VisitedTime: Yup.string().nullable().required('Select Engagement date.'),
    Notes: Yup.string().nullable().required('Enter some notes about engagement.'),
});

const AddOrUpdateDocumentOnboarding: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const { showDocumentOnboardingModal, documentOnboarding, isSaving, isFetching } = useSelector((state: RootState) => state.documentOnboarding);

    const handleClose = () => dispatch(toggleShowDocumentOnboardingModal(false));

    const handleSubmit = (values: DocumentOnboarding) => {
        if (!Boolean(values.EngagedProvider?.Id)) {
            const authData = new Authorization();
            values.EngagedProvider = {
                Id: authData.UserId,
                Name: authData.UserName,
                Role: authData.Role,
            };
        }
        dispatch(saveDocumentOnboarding({ ...values, MemberId: memberId }));
    };

    return (
        <Modal open={showDocumentOnboardingModal} onClose={handleClose}>
            <Box sx={[{ ...classes.modalPopupForm }, { ...classes.form }]}>
                <div className="mui-modal-header">
                    <Typography variant="h6" component="h6">
                        Document Onboarding
                    </Typography>
                </div>
                <Formik initialValues={documentOnboarding} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={docOnboardingFormSchema}>
                    {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<DocumentOnboarding>) => (
                        <Form>
                            <div className="mui-modal-body">
                                <Grid container direction="column" spacing={2} className="content-panel">
                                    <Grid item className="content-form">
                                        {isFetching ? (
                                            <Box display="flex" justifyContent="center" className="loader-container">
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl required style={{ maxWidth: '350px' }}>
                                                        <FormLabel id="moe-radio-buttons-group-label">Means of Engagement</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="moe-radio-buttons-group-label"
                                                            value={values.MeansOfEngagement}
                                                            name="MeansOfEngagement"
                                                            onChange={handleChange}
                                                        >
                                                            {OnboardingMeansOfEngagements.map((item) => (
                                                                <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DateTimePicker
                                                        label="Engagement Date*"
                                                        value={values.VisitedTime ? new Date(values.VisitedTime) : null}
                                                        inputFormat="MM/dd/yyyy hh:mm a"
                                                        minDate={new Date('2017-01-01')}
                                                        onChange={(date) => {
                                                            if (moment(date).isValid() || !Boolean(date)) {
                                                                setFieldValue('VisitedTime', date.toISOString());
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                {...params}
                                                                error={Boolean(errors?.VisitedTime) && Boolean(submitCount)}
                                                                helperText={Boolean(submitCount) ? errors?.VisitedTime : ''}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        required
                                                        placeholder="Enter your note"
                                                        margin="none"
                                                        variant="outlined"
                                                        rows={6}
                                                        value={values.Notes}
                                                        name="Notes"
                                                        label="Notes"
                                                        onChange={handleChange}
                                                        error={Boolean(errors?.Notes) && Boolean(submitCount)}
                                                        helperText={Boolean(submitCount) && errors?.Notes}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mui-modal-footer">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSaving}
                                    endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                                >
                                    {Boolean(values.Id) ? 'Update' : 'Save'}
                                </Button>
                                <Button type="button" onClick={handleClose} variant="outlined" disabled={isSaving}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default AddOrUpdateDocumentOnboarding;
