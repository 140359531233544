import { useCallback, useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { usePopulationContext } from '../../../hooks/populationContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getStatus, getTimePeriodList, getQuarterList, getMonthList, MenuProps } from './AnalyticService';
import { TimePeriodTypeEnum } from '../../../Enum/TimePeriodTypeEnum';

const FilterOptions = (props) => {
    const {
        population,
        setPopulation,
        memberStatus,
        setMemberStatus,
        timePeriod,
        setTimePeriod,
        part,
        setPart,
        primaryProvider,
        setPrimaryProvider,
        secondaryProvider,
        setSecondaryProvider,
        showInActivePrimaryTeams,
        setShowInActivePrimaryTeams,
        showInActiveSecondaryTeams,
        setShowInActiveSecondaryTeams,
    } = props;
    const { teams } = useSelector((state: RootState) => state.teams);
    const { analyticPopulations } = usePopulationContext();
    const statusList = getStatus();
    const timePeroidList = getTimePeriodList();
    const quarterList = getQuarterList();
    const monthList = getMonthList();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const filteredPrimaryTeams = (showInActivePrimaryTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredSecondaryTeams = (showInActiveSecondaryTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    const changeTimePeriod = (filter: any) => {
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        if (parseInt(filter) === TimePeriodTypeEnum.QRT) {
            var m = Math.round(currentMonth / 3);
            var q = m > 4 ? m - 4 : m;
            setPart(q > 0 ? q : 1);
        } else if (parseInt(filter) === TimePeriodTypeEnum.MTH) {
            setPart(currentMonth > 2 ? currentMonth - 2 : currentMonth);
        }
    };

    const togglePrimaryTeamHandler = (isChecked: boolean) => {
        setShowInActivePrimaryTeams(isChecked);
        setPrimaryProvider('All');
    };

    const toggleSecondaryTeamHandler = (isChecked: boolean) => {
        setShowInActiveSecondaryTeams(isChecked);
        setSecondaryProvider('All');
    };

    return (
        <Grid container ml={0} mt={0} spacing={2}>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Population</InputLabel>
                    <Select
                        value={population}
                        label="Population"
                        name="Population"
                        onChange={(e) => setPopulation(parseInt(e.target.value))}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="All" value="0">
                            All
                        </MenuItem>
                        {analyticPopulations.map((pop) => (
                            <MenuItem key={pop.value} value={pop.value}>
                                {pop.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select value={memberStatus} label="Status" name="Status" onChange={(e) => setMemberStatus(parseInt(e.target.value))} MenuProps={MenuProps}>
                        {statusList.map((st) => (
                            <MenuItem key={st.value} value={st.value}>
                                {st.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Time Period</InputLabel>
                    <Select
                        value={timePeriod}
                        label="Time Period"
                        name="Time Period"
                        onChange={(e) => {
                            setTimePeriod(e.target.value);
                            changeTimePeriod(e.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        {timePeroidList.map((team) => (
                            <MenuItem key={team.Id} value={team.Id}>
                                {team.Value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                {parseInt(timePeriod) === TimePeriodTypeEnum.QRT ? (
                    <FormControl size="small" fullWidth>
                        <InputLabel>Quarter</InputLabel>
                        <Select value={part} label="Quarter" name="Quarter" onChange={(e) => setPart(e.target.value)} MenuProps={MenuProps}>
                            {quarterList.map((qrt) => (
                                <MenuItem key={qrt.Id} value={qrt.Id}>
                                    {qrt.Value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}
                {parseInt(timePeriod) === TimePeriodTypeEnum.MTH ? (
                    <FormControl size="small" fullWidth>
                        <InputLabel>Month</InputLabel>
                        <Select value={part} label="Month" name="Month" onChange={(e) => setPart(e.target.value)} MenuProps={MenuProps}>
                            {monthList.map((mth) => (
                                <MenuItem key={mth.Id} value={mth.Id}>
                                    {mth.Value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}
            </Grid>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Primary Provider</InputLabel>
                    <Select
                        value={primaryProvider}
                        label="Primary Provider"
                        name="Primary Provider"
                        onChange={(e) => {
                            setPrimaryProvider(e.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="All" value="All">
                            All
                        </MenuItem>
                        <FormControlLabel
                            sx={{ pl: '12px', mb: '0px' }}
                            control={<Checkbox checked={showInActivePrimaryTeams} onChange={(e) => togglePrimaryTeamHandler(e.target.checked)} />}
                            label="Show Inactive"
                        />
                        {filteredPrimaryTeams.map((team) => (
                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                {team.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Secondary Provider</InputLabel>
                    <Select
                        value={secondaryProvider}
                        label="Secondary Provider"
                        name="Secondary Provider"
                        onChange={(e) => {
                            setSecondaryProvider(e.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="All" value="All">
                            All
                        </MenuItem>
                        <FormControlLabel
                            sx={{ pl: '12px', mb: '0px' }}
                            control={<Checkbox checked={showInActiveSecondaryTeams} onChange={(e) => toggleSecondaryTeamHandler(e.target.checked)} />}
                            label="Show Inactive"
                        />
                        {filteredSecondaryTeams?.map((team) => (
                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                {team.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default FilterOptions;
