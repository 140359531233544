export enum ContactTimeEnum {
    // NoneDescription = 'None',
    None = 0,
    // MorningDescription = 'Morning',
    Morning = 1,
    // AfternoonDescription = 'Afternoon',
    Afternoon = 2,
    // EveningDescription = 'Evening',
    Evening = 3,
}
