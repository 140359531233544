import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import React from 'react';

interface WidgetBoxProps {
    clickRefresh?: Function;
    title?: string;
    maxHeight?: number;
    maxWidth?: number;
    minWidth?: number;
    minHeight?: number;
    variant?: 'outlined' | 'elevation';
    children?: any;
}

const WidgetBox = (props: WidgetBoxProps) => {
    // const [load, setLoad] = useState<boolean>(false);
    const classes = {
        root: {
            margin: '5px',
            maxHeight: props.maxHeight,
            maxWidth: props.maxWidth,
            minWidth: props.minWidth,
        },
    };

    // const doReload = () => {
    //   if (props.clickRefresh && typeof(props.clickRefresh) === "function") {
    //     setLoad(true);
    //     props.clickRefresh();
    //     setTimeout(() => {
    //       setLoad(false);
    //     }, 1000);
    //   }
    // };

    return (
        <Card variant={props.variant} sx={classes.root}>
            <CardHeader
                title={props.title}
                // action={
                //   !load && props.clickRefresh ? <RefreshIcon style={{ cursor: "pointer" }} onClick={doReload} /> : undefined
                // }
            />
            <Divider />
            <CardContent>{React.Children.toArray(props.children)}</CardContent>
        </Card>
    );
};

export default WidgetBox;
