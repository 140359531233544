import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SdohAssessment } from '../../../Models/Sdoh/SdohAssessment.model';
import { SDOHResourceOption } from '../../../Models/Sdoh/SDOHResourceOption.model';
import { RootState } from '../../../reducers';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export interface ISdohNeedsIdentifiedModalProps {
    isOpen: boolean;
    handleClose: () => void;
    handleSubmit: (sdohAssessment: SdohAssessment) => void;
}

const SdohNeedsIdentifiedModal = (props: ISdohNeedsIdentifiedModalProps) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { isOpen, handleClose, handleSubmit } = props;
    const [options, setOptions] = useState<SDOHResourceOption[]>([]);
    const { sdohAssessment } = useSelector((state: RootState) => state.assessment);
    const getOption = (label: string, requestLabel: string): SDOHResourceOption => {
        return {
            Label: label,
            RequestLabel: requestLabel,
            Selected: false,
            RequestId: null,
            Escalate: false,
            Display: false,
        };
    };
    const followupRequest = options.filter((o) => o.Escalate);

    useEffect(() => {
        if (sdohAssessment?.Id) {
            let newOptions = [];
            let option = getOption('Did you provide resources about (Not Losing Housing)?', 'Not Losing Housing');
            if (sdohAssessment.FamilyAndHome.WorriedAboutLosingYourHousing?.Answer === 'Yes') {
                option.Display = true;
                option.Escalate = true;
            }
            newOptions.push(option);

            option = getOption('Did you provide resources about (Housing assistance)?', 'Housing assistance');
            if (sdohAssessment.FamilyAndHome?.HousingSituationToday?.Answer?.indexOf('I do not have housing') > -1) {
                option.Display = true;
                option.Escalate = true;
            }
            newOptions.push(option);

            option = getOption('Did you provide resources about (Domestic Violence)?', 'Domestic Violence');
            if (
                sdohAssessment.OptionalAdditionalQuestions?.DoYouFeelPhysicallyAndEmotionallySafe?.Answer === 'No' ||
                sdohAssessment?.OptionalAdditionalQuestions?.HaveYouBeenAfraidOfYourPartner?.Answer === 'Yes'
            ) {
                option.Display = true;
                option.Escalate = true;
            }
            newOptions.push(option);
            const foodOption = getOption('Did you provide resources about (Food)?', 'Food');
            const clothingOption = getOption('Did you provide resources about (Clothing)?', 'Clothing');
            const utilitiesOption = getOption('Did you provide resources about (Utilities)?', 'Utilities');
            const phoneOption = getOption('Did you provide resources about (Phone)?', 'Phone');

            if (sdohAssessment.MoneyAndResources !== undefined) {
                sdohAssessment.MoneyAndResources?.NeededThingsUnableToGetInPastYear?.Answer?.forEach(({ Answer }) => {
                    if (Answer === 'Food') {
                        foodOption.Display = true;
                        foodOption.Escalate = true;
                    }
                    if (Answer === 'Clothing') {
                        clothingOption.Display = true;
                        clothingOption.Escalate = true;
                    }
                    if (Answer === 'Utilities') {
                        utilitiesOption.Display = true;
                        utilitiesOption.Escalate = true;
                    }
                    if (Answer === 'Phone') {
                        phoneOption.Display = true;
                        phoneOption.Escalate = true;
                    }
                });
            }

            newOptions = [...newOptions, foodOption, clothingOption, utilitiesOption, phoneOption];

            if (sdohAssessment.ResourceOptions) {
                sdohAssessment.ResourceOptions?.forEach((option) => {
                    newOptions?.forEach((newOption) => {
                        if (newOption.Label === option.Label) {
                            if (newOption.Display) {
                                newOption.Selected = option.Selected;
                                newOption.Escalate = option.Escalate;
                            }
                            newOption.RequestId = option.RequestId;
                        }
                    });
                });
            }

            setOptions(newOptions);
        }
    }, [sdohAssessment]);

    const saveSdohNeeds = () => {
        handleSubmit({
            ...sdohAssessment,
            ResourceOptions: options,
        });
    };

    const handleOnSave = () => {
        if (followupRequest?.length > 0) {
            setShowSaveAlert(true);
        } else {
            saveSdohNeeds();
        }
    };

    return (
        <>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                PaperProps={{
                    sx: { alignSelf: 'flex-center' },
                }}
            >
                <DialogTitle textAlign="center">Needs Identified in SDOH</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item lg={12}>
                            <Grid container>
                                <Grid item lg={12}>
                                    {React.Children.toArray(
                                        options.map((option, index) => {
                                            if (option.Display) {
                                                return (
                                                    <div key={`item_${index}`}>
                                                        <Stack direction="row" alignItems="center">
                                                            <Checkbox
                                                                checked={option.Selected}
                                                                onChange={(event) => {
                                                                    const newOptions = [...options];
                                                                    newOptions[index].Selected = event.target.checked;
                                                                    if (!newOptions[index].Selected) {
                                                                        newOptions[index].Escalate = true;
                                                                    }
                                                                    setOptions(newOptions);
                                                                }}
                                                            />
                                                            <Typography variant="body1">{option.Label}</Typography>
                                                        </Stack>
                                                        <Stack direction="row" alignItems="center" paddingLeft={3}>
                                                            <Checkbox
                                                                checked={option.Escalate}
                                                                onChange={(event) => {
                                                                    const newOptions = [...options];
                                                                    newOptions[index].Escalate = event.target.checked;
                                                                    setOptions(newOptions);
                                                                }}
                                                            />
                                                            <Typography variant="body2">Send follow up request to Care Coordinator</Typography>
                                                        </Stack>
                                                    </div>
                                                );
                                            } else return null;
                                        })
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={12}>
                            <TextField
                                style={{
                                    marginTop: 20,
                                }}
                                multiline
                                minRows={3}
                                fullWidth
                                label="Comments"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOptions([]);
                            handleClose();
                        }}
                    >
                        Close
                    </Button>
                    <Button variant="contained" onClick={() => handleOnSave()} color="success">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showSaveAlert}
                maxWidth="sm"
                PaperProps={{
                    sx: { alignSelf: 'flex-center' },
                }}
            >
                <DialogTitle textAlign="center">Follow Up Request Confirmation</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item lg={12}>
                            <Grid container>
                                <Grid item lg={12}>
                                    <Stack direction="row" alignItems="center" style={{ marginBottom: '20px' }}>
                                        <Typography variant="body1">
                                            The Follow-Up requests will be sent to the care coordinator for the below-identified needs.
                                            <b> A total of {followupRequest?.length} requests will be sent. Are you sure you want to continue?</b>
                                        </Typography>
                                    </Stack>
                                    {React.Children.toArray(
                                        followupRequest.map((option, index) => {
                                            if (option.Escalate) {
                                                return (
                                                    <div key={`item_${index}`}>
                                                        <Stack direction="row" alignItems="center" style={{ marginBottom: '10px' }}>
                                                            <CheckCircleOutlineIcon color="primary" style={{ marginRight: '10px' }} />
                                                            <Typography variant="body1">
                                                                Send follow up request to Care Coordinator for {option.RequestLabel}
                                                            </Typography>
                                                        </Stack>
                                                    </div>
                                                );
                                            } else return null;
                                        })
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setShowSaveAlert(false)}>
                        Go Back
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            saveSdohNeeds();
                        }}
                        color="success"
                    >
                        Send {followupRequest?.length} Requests & Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SdohNeedsIdentifiedModal;
