import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';

const memberDetailsReport = createSlice({
    name: 'memberDetailsReport',
    initialState: {
        memberDetailReport: [],
        memberDetailFilter: '-2',
        pageNumber: 0,
        pageSize: 15,
        pageTotal: 0,
    },
    reducers: {
        _setMemberDetailReport(state, action) {
            state.memberDetailReport = action.payload;
        },
        _setMemberDetailFilter(state, action) {
            state.memberDetailFilter = action.payload;
        },
        _setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        _setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        _setPageTotal(state, action) {
            state.pageTotal = action.payload;
        },
    },
});

const { _setMemberDetailReport, _setMemberDetailFilter, _setPageNumber, _setPageSize, _setPageTotal } = memberDetailsReport.actions;

export const setPageNumber =
    (params: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setPageNumber(params));
    };

export const setPageSize =
    (params: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setPageSize(params));
    };

export const setPageTotal =
    (params: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setPageTotal(params));
    };

export const fetchMemberDetailReport =
    (memberDetailFilter: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/MemberDetailReport/getmembers?status=${memberDetailFilter}&page=${getState().memberDetailsReport.pageNumber}&pageSize=${
                getState().memberDetailsReport.pageSize
            }`
        );
        if (response.data.Data.length < response.data.TotalCount) {
            var lastPage = Math.ceil(response.data.TotalCount / getState().members.pageSize);
            if (lastPage < getState().members.pageNumber) {
                dispatch(_setPageNumber(lastPage));
            }
        }
        dispatch(_setPageTotal(response.data.TotalCount));
        dispatch(setMemberDetailReport(response.data.Data));
        dispatch(setIsLoading(false));
    };

export const setMemberDetailReport =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberDetailReport(params));
    };

export const setMemberDetailFilter =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberDetailFilter(params));
    };

export const exportMemberDetailsReport =
    (memberDetailFilter: string): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(`${API_URL}/MemberDetailReport/exportmembers?status=${memberDetailFilter}`);
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default memberDetailsReport.reducer;
