import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Typography } from '@mui/material';
import React from 'react';
import { IRequestTimelineEvent } from '../interfaces/IRequestTimelineEvent';

const RequestTimelineItem: React.FC<IRequestTimelineEvent & { icon: React.ReactNode }> = (props) => {
    const { EventDescription, Title, EventDateTime, icon, InitiatorName } = props;

    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                <Typography variant="subtitle2" component="div">
                    {InitiatorName}
                </Typography>
                {new Date(EventDateTime).toLocaleString()}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">{icon}</TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {Title}
                </Typography>
                <Typography>{EventDescription}</Typography>
            </TimelineContent>
        </TimelineItem>
    );
};

export default RequestTimelineItem;
