import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../../hooks/populationContext';
import { useAnalyticContext } from '../../../../hooks/analyticContext';
import { configureStackedBarChartForCost } from '../../../ChartConfiguration/ChartConfiguration';
import ClaimsPerformance from '../../../../Models/Claims/ClaimsPreview.model';
import FilterOptions from '../FilterOptions';
import { getPhCostGraph } from '../AnalyticService';

const PHCost = () => {
    const history = useNavigate();
    const { analyticPopulations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [graphSummary, setGraphSummary] = useState<ClaimsPerformance>();
    const [chartOptions, setChartOptions] = useState({});

    const {
        phPopulation,
        setPhPopulation,
        phMemberStatus,
        setPhMemberStatus,
        phTimePeriod,
        setPhTimePeriod,
        phPart,
        setPhPart,
        phPrimaryProvider,
        setPhPrimaryProvider,
        phSecondaryProvider,
        setPhSecondaryProvider,
        showPhInActivePrimaryTeams,
        setShowPhInActivePrimaryTeams,
        showPhInActiveSecondaryTeams,
        setShowPhInActiveSecondaryTeams,
    } = useAnalyticContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartForCost(
                    'Pharmaceutical Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [0] }],
                    [0],
                    'PhCost',
                    history
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                const data = await getPhCostGraph(
                    phTimePeriod,
                    phPopulation,
                    phPrimaryProvider,
                    phPart,
                    phMemberStatus,
                    phSecondaryProvider,
                    showPhInActivePrimaryTeams,
                    showPhInActiveSecondaryTeams
                );
                setGraphSummary(data);
                setIsPrepared(true);
            };
            setChartOptions(
                configureStackedBarChartForCost(
                    'Pharmaceutical Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'PhCost',
                    history
                )
            );
            setIsGraphShow(false);
            changeFilter();
        }
    }, [
        phTimePeriod,
        phPopulation,
        phPrimaryProvider,
        phPart,
        phMemberStatus,
        showPhInActivePrimaryTeams,
        showPhInActiveSecondaryTeams,
        phSecondaryProvider,
        isPopulationFetched,
        history,
    ]);

    useEffect(() => {
        if (graphSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];

            if (graphSummary.Baseline !== null) {
                yValues = [];
                let costName = 'BL Yr';
                let d = {
                    y: graphSummary.Baseline,
                    name: costName,
                };
                yValues.push(d);
                yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });
            }

            if (graphSummary.PerformanceOne !== null) {
                yValues = [];
                let costName = 'Perf Yr';
                let d = {
                    y: graphSummary.PerformanceOne,
                    name: costName,
                };
                yValues.push(d);
                yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });
            }

            if (phPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = analyticPopulations.find((p) => p.value === phPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartForCost('Pharmaceutical Cost', '', '', 'Cost', yAxisValues, xAxisValues, 'PhCost', history));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
    }, [graphSummary, phPopulation, analyticPopulations, isPrepared, history]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/phcostdetail`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            Pharmaceutical Costs
                        </Typography>
                    </Grid>
                    <FilterOptions
                        population={phPopulation}
                        setPopulation={setPhPopulation}
                        memberStatus={phMemberStatus}
                        setMemberStatus={setPhMemberStatus}
                        timePeriod={phTimePeriod}
                        setTimePeriod={setPhTimePeriod}
                        part={phPart}
                        setPart={setPhPart}
                        primaryProvider={phPrimaryProvider}
                        setPrimaryProvider={setPhPrimaryProvider}
                        secondaryProvider={phSecondaryProvider}
                        setSecondaryProvider={setPhSecondaryProvider}
                        showInActivePrimaryTeams={showPhInActivePrimaryTeams}
                        setShowInActivePrimaryTeams={setShowPhInActivePrimaryTeams}
                        showInActiveSecondaryTeams={showPhInActiveSecondaryTeams}
                        setShowInActiveSecondaryTeams={setShowPhInActiveSecondaryTeams}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default PHCost;
