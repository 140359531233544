import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import b64toBlob from 'b64-to-blob';

const claimsDateFormatter = (date) => {
    return (
        (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
        '/' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        '/' +
        date.getFullYear()
    );
};

const claimsErrorReport = createSlice({
    name: 'claimsErrorReport',
    initialState: {
        claimsData: [],
        dosStart: new Date(),
        dosEnd: new Date(),
        errorMessage: "",
        selectedProviderId: ""
    },
    reducers: {
        _setClaimsData(state, action) {
            state.claimsData = action.payload;
        },
        _setErrorMessage(state, action) {
            state.errorMessage = action.payload;
        },
        _setDosStart(state, action) {
            state.dosStart = action.payload;
        },
        _setDosEnd(state, action) {
            state.dosEnd = action.payload;
        },
        _setSelectedProviderId(state, action) {
            state.selectedProviderId = action.payload;
        }
    },
});

const { _setClaimsData, _setErrorMessage, _setDosStart, _setDosEnd, _setSelectedProviderId } = claimsErrorReport.actions;

export const fetchClaimsData = (providerId: string, errorMessage: string, dosStart: Date, dosEnd: Date): AppThunk =>
async (dispatch) => {
    dispatch(setIsLoading(true));
    var getURL =  `${API_URL}/encounter/getclaimerrors?providerId=${providerId}&errorMessage=${errorMessage}&startDate=${claimsDateFormatter(dosStart)}&endDate=${claimsDateFormatter(
        dosEnd
    )}`;

    const response = await axios.get(getURL);
    dispatch(_setClaimsData(response.data));
    dispatch(setIsLoading(false));
};

export const fetchClaimResponses =
    (callback: Function): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        await axios.post(`${API_URL}/encounter/processclaims`);
        callback();
    };

export const setErrorMessage =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setErrorMessage(value));
    };

export const setDosStart =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setDosStart(value));
    };

export const setDosEnd =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setDosEnd(value));
    };

export const downloadClaimsHandler =
    (claimsData): AppThunk =>
    async (dispatch) => {
        const downloadList = claimsData.map((item) => item.EncounterId);
        const response = await axios.post(`${API_URL}/encounter/claimsManagementDownload`, downloadList);
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
    };

export const setSelectedProviderId =
    (id: string): AppThunk =>
    async (dispatch) => {
       dispatch(_setSelectedProviderId(id));
    };
    
export default claimsErrorReport.reducer;
