import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../hooks/populationContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { configureStackedBarChartWithoutLegend } from '../../ChartConfiguration/ChartConfiguration';
import { getBpSummary } from '../HedisMetricService';
import BpSummary from '../../../Models/HedisMetrics/BpSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import FilterOptions from '../FilterOptions';
import '../../Widgets/Widgets.css';

const BpSummaries = () => {
    const history = useNavigate();
    const { populations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [bpSummary, setBpSummary] = useState<BpSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const {
        bpPopulation,
        setBpPopulation,
        bpTeam,
        setBpTeam,
        bpDateFilter,
        setBpDateFilter,
        bpFromDate,
        setBpFromDate,
        bpToDate,
        setBpToDate,
        bpIncludePastOpenStatus,
        setBpIncludePastOpenStatus,
        bpShowInActiveTeam,
        setBpShowInActiveTeam,
        setHedisType,
        setPop,
        setTeam,
        setFilter,
        setFDate,
        setTDate,
        setIncludePastOpenStatus,
        setShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartWithoutLegend('BP Summary', 'Controlled vs Not Controlled', 'Population', 'Count', [{ name: '', data: [0] }], [0])
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched && (bpDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(bpFromDate) && Boolean(bpToDate)))) {
            const changeFilter = async () => {
                let start = bpFromDate !== null ? new Date(bpFromDate).toDateString() : null;
                let end = bpToDate !== null ? new Date(bpToDate).toDateString() : null;

                const data = await getBpSummary(bpPopulation, bpTeam, bpDateFilter, start, end, bpIncludePastOpenStatus, bpShowInActiveTeam);
                setBpSummary(data);

                setIsPrepared(true);
            };
            if ((bpFromDate !== null || bpToDate !== null) && bpToDate < bpFromDate) {
            } else {
                setChartOptions(
                    configureStackedBarChartWithoutLegend(
                        'BP Summary',
                        'Controlled vs Not Controlled',
                        'Population',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bpPopulation, bpTeam, bpDateFilter, bpFromDate, bpToDate, bpIncludePastOpenStatus, isPopulationFetched, bpShowInActiveTeam]);

    useEffect(() => {
        if (bpSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            let yVal;

            yValues = [];
            yVal = {
                y: bpSummary.ControlledBp,
                name: 'Controlled Bp',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Controlled Bp', data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });

            yValues = [];
            yVal = {
                y: bpSummary.NotControlledBp,
                name: 'Not Controlled Bp',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Controlled Bp', data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });

            yValues = [];
            yVal = {
                y: bpSummary.NotDocumented,
                name: 'Not Documented',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Documented', data: yValues, pointPadding: 0, color: '#4682B4', showInLegend: true });

            if (bpPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = populations.find((p) => p.value === bpPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartWithoutLegend('BP Summary', '', '', 'Count', yAxisValues, xAxisValues, navigateToMembers));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bpSummary, bpPopulation, populations, isPrepared]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToMembers = () => {
        setPop(bpPopulation);
        setTeam(bpTeam);
        setFilter(bpDateFilter);
        setFDate(bpFromDate);
        setTDate(bpToDate);
        setIncludePastOpenStatus(bpIncludePastOpenStatus);
        setShowInActiveTeam(bpShowInActiveTeam);
        setHedisType(HedisMetricsTypeEnum.BP);
        history(`/provider/metricsummary/hedismemberdetail/BP`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            BP
                        </Typography>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">Member who has an ICD 10 of I10, within a rolling 12 months</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Controlled BP :
                                    </Typography>

                                    <Typography variant="caption">
                                        CPT Codes 3074F or 3075F Systolic (must have one or the other of these) and 3078F or 3079F Diastolic (must have one or
                                        the other of these)
                                    </Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Controlled Bp :
                                    </Typography>
                                    <Typography variant="caption">CPT Codes 3077F and 3080F</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Documented :
                                    </Typography>
                                    <Typography variant="caption">No CPT codes recorded</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FilterOptions
                        selectedTeam={bpTeam}
                        setSelectedTeam={setBpTeam}
                        dateFilter={bpDateFilter}
                        setDateFilter={setBpDateFilter}
                        fromDate={bpFromDate}
                        setFromDate={setBpFromDate}
                        toDate={bpToDate}
                        setToDate={setBpToDate}
                        population={bpPopulation}
                        setPopulation={setBpPopulation}
                        includePastOpenStatusMembers={bpIncludePastOpenStatus}
                        setIncludePastOpenStatusMembers={setBpIncludePastOpenStatus}
                        hedisType={HedisMetricsTypeEnum.BP}
                        showInActiveTeams={bpShowInActiveTeam}
                        setShowInActiveTeams={setBpShowInActiveTeam}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default BpSummaries;
