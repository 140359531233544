import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { states } from '../../../utils/constants';
import { apiString as API_URL } from '../../../utils/constants';
import PlanTypesDialog from './PlanTypesDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {
    addPlanTypeHandler,
    closeDeleteContactHandler,
    closePlanTypesHandler,
    deleteContactHandler,
    editPlanTypeHandler,
    openDeletePlanTypeHandler,
    setHealthPlanForm,
} from '../../../store/adminHealthPlan.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { convertObjectToArray } from '../../../utils/common';
import ConfirmDelete from '../../Shared/ConfirmDelete';

const BasicInformation = (props) => {
    const dispatch = useDispatch();

    const { contactToDelete, healthPlanForm, healthPlanTypes, openDeleteContact, planTypes, planTypesVisibility } = useSelector(
        (state: RootState) => state.adminHealthPlanSlice
    );

    const [healthPlanSubTypes, setHealthPlanSubTypes] = useState([]);

    const getHealthPlanSubTypes = async (typeId: string) => {
        const response = await axios.get(`${API_URL}/managehealthplan/gethealthplansubtypes?id=${typeId}`);
        setHealthPlanSubTypes(response.data);
    };

    const columns: GridColDef[] = [
        {
            field: 'Type',
            headerName: 'Type',
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.HealthPlanType?.Type} ${params.row.HealthPlanSubType ? ' - ' + params.row.HealthPlanSubType?.SubType : ''}`,
        },
        {
            field: 'OrganizationId',
            headerName: 'Organization Id',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'OrganizationName',
            headerName: 'Organization Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit Plan" placement="bottom">
                            <EditIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(editPlanTypeHandler(params.row))}
                            />
                        </Tooltip>
                        <Tooltip title="Delete Plan" placement="top">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(openDeletePlanTypeHandler({ Name: params.row.HealthPlanType.Type, Id: params.row.Id }, params.row))}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        if (healthPlanForm.HealthPlanType) {
            getHealthPlanSubTypes(healthPlanForm.HealthPlanType.Id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthPlanForm.HealthPlanType]);

    return (
        <>
            <PlanTypesDialog open={planTypesVisibility} onClose={() => dispatch(closePlanTypesHandler())} />

            <ConfirmDelete
                open={openDeleteContact}
                onClose={() => dispatch(closeDeleteContactHandler())}
                name={planTypes.HealthPlanType?.Type}
                delete={() => dispatch(deleteContactHandler(healthPlanForm, contactToDelete))}
                cancel={() => dispatch(closeDeleteContactHandler())}
            />

            <Stack width={'100%'} direction={'row'} spacing={2} p={2} marginBottom={2}>
                <FormControl fullWidth>
                    <TextField
                        required
                        value={healthPlanForm.Name || ''}
                        id="name"
                        label="Health Plan Name"
                        helperText="Required"
                        name="Name"
                        fullWidth
                        variant="outlined"
                        onChange={props.inputOnChange}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel id="plan-type-label">Plan Type</InputLabel>
                    <Select
                        labelId="plan-type-label"
                        id="plan-type"
                        value={healthPlanTypes.find((el) => el.Id === healthPlanForm.HealthPlanType?.Id) || ''}
                        margin="dense"
                        label="Plan Type"
                        name="HealthPlanType"
                        onChange={(e) => {
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    HealthPlanType: e.target.value,
                                    HealthPlanSubType: null,
                                })
                            );
                            getHealthPlanSubTypes(e.target.value?.Id);
                        }}
                        renderValue={(value) => value.Type}
                    >
                        <MenuItem value={null}>Select</MenuItem>
                        {healthPlanTypes.map((plan) => {
                            return (
                                <MenuItem value={plan} key={plan.Id}>
                                    {plan.Type}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {healthPlanSubTypes.length > 0 && (
                    <FormControl fullWidth>
                        <InputLabel required id="plan-type-label">
                            {healthPlanForm.HealthPlanType?.Type}
                        </InputLabel>
                        <Select
                            labelId="plan-type-label"
                            required
                            id="plan-type"
                            value={healthPlanSubTypes.find((el) => el.Id === healthPlanForm.HealthPlanSubType?.Id) || ''}
                            margin="dense"
                            label={healthPlanForm.HealthPlanType?.Type}
                            name="HealthPlanSubType"
                            onChange={(e) => {
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        HealthPlanSubType: e.target.value,
                                    })
                                );
                            }}
                        >
                            {healthPlanSubTypes.map((plan) => {
                                return (
                                    <MenuItem value={plan} key={plan.Id}>
                                        {plan.SubType}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                <FormControl fullWidth>
                    <TextField
                        value={healthPlanForm.OrganizationId || ''}
                        id="name"
                        label="Organization Id"
                        name="OrganizationId"
                        fullWidth
                        variant="outlined"
                        onChange={props.inputOnChange}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        value={healthPlanForm.OrganizationName || ''}
                        id="name"
                        label="Organization Name"
                        name="OrganizationName"
                        fullWidth
                        variant="outlined"
                        onChange={props.inputOnChange}
                    />
                </FormControl>
            </Stack>

            <Grid item xs={4}>
                <TextField
                    value={healthPlanForm.Address?.Address1 || ''}
                    label="Address 1"
                    name="Address1"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                        dispatch(
                            setHealthPlanForm({
                                ...healthPlanForm,
                                Address: {
                                    ...healthPlanForm.Address,
                                    Address1: e.target.value,
                                },
                            })
                        )
                    }
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    value={healthPlanForm.Address?.Address2 || ''}
                    label="Address 2"
                    name="Address2"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                        dispatch(
                            setHealthPlanForm({
                                ...healthPlanForm,
                                Address: {
                                    ...healthPlanForm.Address,
                                    Address2: e.target.value,
                                },
                            })
                        )
                    }
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    value={healthPlanForm.Address?.City || ''}
                    label="City"
                    name="City"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                        dispatch(
                            setHealthPlanForm({
                                ...healthPlanForm,
                                Address: {
                                    ...healthPlanForm.Address,
                                    City: e.target.value,
                                },
                            })
                        )
                    }
                />
            </Grid>

            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="plan-state-label">State</InputLabel>
                    <Select
                        labelId="plan-state-label"
                        id="plan-state"
                        value={healthPlanForm.Address?.State || ''}
                        margin="dense"
                        label="State"
                        name="State"
                        onChange={(e) =>
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    Address: {
                                        ...healthPlanForm.Address,
                                        State: e.target.value,
                                    },
                                })
                            )
                        }
                        MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                    >
                        {convertObjectToArray(states).map((state) => {
                            return (
                                <MenuItem value={state.Name} key={state.Id}>
                                    {state.Name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    value={healthPlanForm.Address?.ZipCode || ''}
                    label="ZipCode"
                    name="ZipCode"
                    type="number"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                        dispatch(
                            setHealthPlanForm({
                                ...healthPlanForm,
                                Address: {
                                    ...healthPlanForm.Address,
                                    ZipCode: e.target.value,
                                },
                            })
                        )
                    }
                />
            </Grid>

            <Grid marginTop={2} item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Additional Plan Types</Typography>
                <Button variant="contained" onClick={() => dispatch(addPlanTypeHandler())}>
                    Add Plan Type
                </Button>
            </Grid>

            <Grid item xs={12}>
                {healthPlanForm.PlanTypes?.length > 0 && (
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={healthPlanForm.PlanTypes || []}
                            columns={columns}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.OrganizationId + healthPlanForm.PlanTypes.indexOf(row)}
                            getRowHeight={() => 'auto'}
                            autoHeight
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                )}
            </Grid>
        </>
    );
};

export default BasicInformation;
