import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Radio, RadioGroup, FormControlLabel, Button, Box, CircularProgress, Stack, Divider, Typography } from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { AdlAnswerTypes } from '../../../utils/assessments';
import { copyObject } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchAdlAssessmentById, saveAdlAssessment } from '../../../store/hra.slice';

const ActivitiesOfDailyLiving: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { adlAssessment, isFetching, isSaving: isSavingAdl } = useSelector((state: RootState) => state.hra);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingAdl || isSavingEngagementDraft;

    useEffect(() => {
        if (Boolean(engagementDraft.AdlAssessmentId)) {
            dispatch(fetchAdlAssessmentById(engagementDraft.AdlAssessmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveAdlAssessment({ ...adlAssessment, Status: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeAnswer = (answer: string, propName: string) => {
        let data = copyObject(adlAssessment);
        data[propName] = answer;
        data.MemberId = memberId;

        dispatch(saveAdlAssessment(data));
    };

    const prepareAdlQuestionList = () => {
        return [
            {
                name: 'Bathing',
                questions: [
                    {
                        question: 'Needs help on more than one part of the body?',
                        answer: adlAssessment.BathBody,
                        name: 'BathBody',
                    },
                    {
                        question: 'Needs help getting in or out of tub/shower?',
                        answer: adlAssessment.BathTubOrShower,
                        name: 'BathTubOrShower',
                    },
                ],
            },
            {
                name: 'Dressing',
                questions: [
                    {
                        question: 'Needs help with dressing self or needs to be completely dressed?',
                        answer: adlAssessment.Dressing,
                        name: 'Dressing',
                    },
                ],
            },
            {
                name: 'Toileting',
                questions: [
                    {
                        question: 'Needs help with transferring to the toilet, or using commode?',
                        answer: adlAssessment.Toilet,
                        name: 'Toilet',
                    },
                ],
            },
            {
                name: 'Transferring/Ambulation',
                questions: [
                    {
                        question: 'Needs help with transferring bed to chair?',
                        answer: adlAssessment.TransferringOrAmbulation,
                        name: 'TransferringOrAmbulation',
                    },
                ],
            },
            {
                name: 'Feeding',
                questions: [
                    {
                        question: 'Needs partial or totally help with feeding (outside of food preparation)?',
                        answer: adlAssessment.Feeding,
                        name: 'Feeding',
                    },
                ],
            },
        ];
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" paddingTop={4}>
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form">
                        {prepareAdlQuestionList().map((category, index) => (
                            <Grid container spacing={2} key={`question-category-${index}`}>
                                <Grid item lg={12} md={12} sm={12}>
                                    <Typography variant="h6" component="label">
                                        {category.name}
                                    </Typography>
                                    <Divider />
                                </Grid>
                                {category.questions.map((question, qIndex) => (
                                    <React.Fragment key={`adl-question-${index}-${qIndex}`}>
                                        <Grid item lg={6} md={6} sm={6} display="flex" alignItems="center">
                                            <Typography variant="subtitle1" component="label">
                                                {question.question}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name={question.name}
                                                value={question.answer}
                                                onChange={(e) => handleOnChangeAnswer(e.target.value, e.target.name)}
                                            >
                                                {AdlAnswerTypes.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer-bath-body-${index}-${qIndex}`}
                                                            value={type.Id}
                                                            control={<Radio />}
                                                            label={type.Name}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        ))}
                        <Typography variant="h6" component="label">
                            Score: {adlAssessment.Score || '0'}
                        </Typography>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ marginRight: 2 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {Boolean(engagementDraft.AdlAssessmentId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="ADL Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that ADL assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="ADL Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing ADL assessment?"
                />
            )}
        </Grid>
    );
};

export default ActivitiesOfDailyLiving;
