import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { localDateFormat } from '../../../utils/timeFormat';
import Loading from '../../Shared/Loading';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    setStartDate,
    setEndDate,
    setProviderId,
    setRowData,
    fetchEngagementsVsEncountersReport,
    exportEngagementsVsEncountersReport,
} from '../../../store/engagementsVsEncounters.slice';
import { getActiveProviders } from '../../../store/provider.slice';
import { copyObject } from '../../../utils/common';

const EngagementsVsEncountersReport = () => {
    const dispatch = useDispatch();
    const { startDate, endDate, providerId, rowData } = useSelector((state: RootState) => state.engagementsVsEncounters);
    const { downLoading } = useSelector((state: RootState) => state.shared);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [showInActive, setShowInActive] = useState<boolean>(false);
    const copyProviders = copyObject(activeProviders);
    const filteredProviders = (showInActive ? copyProviders : copyProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    useEffect(() => {
        dispatch(getActiveProviders(true));
        dispatch(setProviderId('-1'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'EngagementDate',
            headerName: 'Date',
            minWidth: 110,
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                const color = parseInt(params.row.NumOfEncounters) < 1 ? 'var(--danger)' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {localDateFormat(new Date(params.value))}
                    </Typography>
                );
            },
        },
        {
            field: 'NumOfEngagements',
            headerName: '# Engagements',
            minWidth: 110,
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                const color = parseInt(params.row.NumOfEncounters) < 1 ? 'var(--danger)' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: 'NumOfEncounters',
            headerName: '# Encounters',
            minWidth: 110,
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                const color = parseInt(params.value) < 1 ? 'var(--danger)' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.value}
                    </Typography>
                );
            },
        },
    ];

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActive(isChecked);
        dispatch(setProviderId('-1'));
        dispatch(setRowData([]));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
                <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                    <Typography fontWeight="fontWeightMedium" component="h3">
                        Engagements vs Encounters Report
                    </Typography>
                </Box>
                {<Loading message={'Loading...'} />}
                <Grid item xs={12}>
                    <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="Start Date:"
                                value={startDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setStartDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="End Date:"
                                value={endDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setEndDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <FormControl className="form-control-200">
                            <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={providerId}
                                value={providerId}
                                label="Provider"
                                onChange={(e) => {
                                    dispatch(setProviderId(e.target.value));
                                }}
                            >
                                <MenuItem value={'-1'}>All</MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActive} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {[...filteredProviders]
                                    .sort((a, b) => a.LastName.localeCompare(b.LastName))
                                    .map((provider) => (
                                        <MenuItem
                                            value={provider.Id}
                                            key={provider.Id}
                                            sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                        >
                                            {provider.LastName}, {provider.FirstName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Button
                            style={{ minWidth: '120px', marginRight: 'auto' }}
                            onClick={() => dispatch(fetchEngagementsVsEncountersReport(providerId, startDate, endDate, showInActive))}
                            variant="contained"
                            color="primary"
                        >
                            Search
                        </Button>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => dispatch(exportEngagementsVsEncountersReport(providerId, startDate, endDate, showInActive))}
                            variant="contained"
                            color="success"
                        >
                            {downLoading ? 'Downloading..' : 'Export'}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item style={{ height: '80vh', width: '100%' }}>
                    <DataGrid
                        rows={rowData}
                        columns={columns}
                        pageSize={15}
                        rowsPerPageOptions={[5]}
                        getRowId={() => Math.random()}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default EngagementsVsEncountersReport;
