/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { IRequest } from '../interfaces';
import { requestStatusMapping } from '../mappings/RequestStatusMapping';
import RequestsQuestionCard from './RequestsQuestionCard';

const RequestsQuestionsBox: React.FC<IRequest> = ({ Id, RequestQuestionAnswers, Status }) => {
    let questionCards: JSX.Element | JSX.Element[] = (
        <Grid container justifyContent="center" alignContent="center">
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
                <Typography style={{ paddingTop: 20 }}>No Questions To Display</Typography>
            </Grid>
        </Grid>
    );

    if (RequestQuestionAnswers?.length > 0) {
        questionCards = RequestQuestionAnswers.map((questionAnswer, index) => {
            return (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RequestsQuestionCard {...questionAnswer} disabled={Status === requestStatusMapping.Archived.value} index={index} requestId={Id} />
                </Grid>
            );
        });
    }

    return (
        <Paper variant="outlined" style={{ minHeight: 900 }}>
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                        Questions
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid style={{ padding: 10 }} container spacing={2}>
                        {React.Children.toArray(questionCards)}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RequestsQuestionsBox;
