import BaseEntity from '../BaseEntity.model';
import { PersonalCharacteristics } from './PersonalCharacteristics.model';
import { FamilyAndHome } from './FamilyAndHome.model';
import { MoneyAndResources } from './MoneyAndResources.model';
import { SocialAndEmotionalHealth } from './SocialAndEmotionalHealth.model';
import { OptionalAdditionalQuestions } from './OptionalAdditionalQuestions.model';
import { StatusEnum } from '../../Enum/StatusEnum';
import { SDOHResourceOption } from './SDOHResourceOption.model';

export default interface ISdohAssessment extends BaseEntity {
    MemberId: string;
    MemberName: string;
    PersonalCharacteristics: PersonalCharacteristics | null;
    FamilyAndHome: FamilyAndHome | null;
    MoneyAndResources: MoneyAndResources | null;
    SocialAndEmotionalHealth: SocialAndEmotionalHealth | null;
    OptionalAdditionalQuestions: OptionalAdditionalQuestions | null;
    Status: StatusEnum;
    RequestIds: string[] | null;
    Comment: string;
}

export class SdohAssessment implements ISdohAssessment {
    Id: string;
    MemberId: string;
    MemberName: string;
    PersonalCharacteristics: PersonalCharacteristics | null;
    FamilyAndHome: FamilyAndHome | null;
    MoneyAndResources: MoneyAndResources | null;
    SocialAndEmotionalHealth: SocialAndEmotionalHealth | null;
    OptionalAdditionalQuestions: OptionalAdditionalQuestions | null;
    Status: StatusEnum;
    RequestIds: string[] | null;
    ResourceRequestIds: string[] | null;
    ResourceOptions: SDOHResourceOption[] | null;
    Comment: string;
    IsLast: boolean;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.MemberName = '';
        this.PersonalCharacteristics = null;
        this.FamilyAndHome = null;
        this.MoneyAndResources = null;
        this.SocialAndEmotionalHealth = null;
        this.OptionalAdditionalQuestions = null;
        this.Status = 0;
        this.RequestIds = null;
        this.Comment = '';
        this.IsLast = false;
    }
}
