import { Button, Box, Divider, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, Grid, Modal } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { deleteLife365, fetchLife365, saveLife365, setLife365 } from '../../../../store/memberDetail.slice';
import { modalStyle } from '../../../../utils/constants';

const TelemonitoringLife365 = () => {
    const dispatch = useDispatch();
    const { life365, selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const [error, setError] = useState({ email: false, password: false, username: false });
    const [showDelete, setShowDelete] = useState(false);

    const createAccount = () => {
        if (valid(true)) {
            dispatch(saveLife365(life365));
        }
    };

    const updateAccount = () => {
        if (valid(false)) {
            dispatch(saveLife365(life365));
        }
    };

    const valid = (userPassword: boolean) => {
        if (!life365.email || (userPassword && !life365.password) || !life365.username) {
            setError({ ...error, email: !life365.email, password: !life365.password, username: !life365.username });
            return false;
        } else {
            return true;
        }
    };

    const cancel = () => {
        dispatch(fetchLife365(selectedMember.Id));
    };

    const renderDeleteBody = () => {
        return (
            <div>
                <Typography sx={{ marginLeft: 3 }} variant="h6">
                    Are you sure do you want to delete the Life 365 Account?
                </Typography>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 margin-top-20">
                        <div className="modal-footer">
                            <Stack direction="row" spacing={2}>
                                <Button className="button-120" variant="contained" color="error" onClick={() => dispatch(deleteLife365(life365.externId))}>
                                    Delete
                                </Button>
                                <Button
                                    className="button-120"
                                    variant="outlined"
                                    onClick={() => {
                                        setShowDelete(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Box>
            <Divider style={{ marginBottom: '16px' }}></Divider>
            {!life365.IsCreateAcount ? (
                <Box>
                    <Stack direction="row" spacing={2} style={{ marginBottom: '16px' }}>
                        <ToggleButtonGroup value={life365.ApiVersion} exclusive onChange={() => {}} aria-label="text alignment">
                            <ToggleButton value={1} aria-label="left aligned">
                                <Typography>V1</Typography>
                            </ToggleButton>
                            <ToggleButton value={2} aria-label="centered">
                                <Typography>V2</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <Grid container spacing={2} style={{ textAlign: 'left', marginBottom: '16px' }}>
                        <Grid item xs={3}>
                            <TextField
                                error={error.email}
                                helperText={error.email ? 'Required' : ''}
                                fullWidth
                                label="Email"
                                variant="outlined"
                                value={life365.email}
                                onChange={(e: any) => {
                                    dispatch(setLife365({ ...life365, email: e.target.value }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                error={error.username}
                                helperText={error.username ? 'Required' : ''}
                                fullWidth
                                label="User Name"
                                variant="outlined"
                                value={life365.username}
                                onChange={(e: any) => {
                                    dispatch(setLife365({ ...life365, username: e.target.value }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" fullWidth label="External Id" variant="outlined" value={life365.externId} />
                        </Grid>
                    </Grid>
                    <Stack direction="row" spacing={2} style={{ float: 'right', margin: '16px' }}>
                        <Button style={{ marginBottom: '16px' }} variant="contained" onClick={updateAccount}>
                            Update Account
                        </Button>
                        <Button
                            style={{ marginBottom: '16px' }}
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setShowDelete(true);
                            }}
                        >
                            Delete Account
                        </Button>
                    </Stack>
                    <Modal
                        open={showDelete}
                        onClose={() => {
                            setShowDelete(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <Stack spacing={2}>
                                <Typography variant="h5">Delete Life 365 Account</Typography>
                                <Divider />
                                {renderDeleteBody()}
                            </Stack>
                        </Box>
                    </Modal>
                </Box>
            ) : (
                <Box>
                    <Grid container spacing={2} style={{ textAlign: 'left', marginBottom: '16px' }}>
                        <Grid item xs={3}>
                            <TextField
                                error={error.email}
                                helperText={error.email ? 'Required' : ''}
                                fullWidth
                                label="Email"
                                variant="outlined"
                                value={life365.email}
                                onChange={(e: any) => {
                                    dispatch(setLife365({ ...life365, email: e.target.value }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                error={error.username}
                                helperText={error.username ? 'Required' : ''}
                                fullWidth
                                label="User Name"
                                variant="outlined"
                                value={life365.username}
                                onChange={(e: any) => {
                                    dispatch(setLife365({ ...life365, username: e.target.value }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                error={error.password}
                                helperText={error.password ? 'Required' : ''}
                                fullWidth
                                label="Password"
                                variant="outlined"
                                value={life365.password}
                                onChange={(e: any) => {
                                    dispatch(setLife365({ ...life365, password: e.target.value }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField id="outlined-basic" fullWidth label="External Id" variant="outlined" value={life365.externId} />
                        </Grid>
                    </Grid>
                    <Stack direction="row" spacing={2} style={{ float: 'right', margin: '16px' }}>
                        <Button style={{ marginBottom: '16px' }} variant="contained" onClick={createAccount}>
                            Create Account
                        </Button>
                        <Button style={{ marginBottom: '16px' }} variant="outlined" onClick={cancel}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            )}
        </Box>
    );
};

export default TelemonitoringLife365;
