import React, { useState } from 'react';
import { apiString as api } from '../utils/constants';
import axios from 'axios';

const ProviderContext = React.createContext();

export const ProviderProvider = ({ children }) => {
    const [providers, setProviders] = useState([]);

    const getProviders = async () => {
        const response = await axios.get(`${api}/provider/getproviderpreview`);
        setProviders(response);
    };

    const value = { providers, getProviders, setProviders };

    return <ProviderContext.Provider value={value}>{children}</ProviderContext.Provider>;
};

export const useProviderContext = () => React.useContext(ProviderContext);
