import { useEffect, useState, useRef } from 'react';
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { isDefaultDate } from '../../../utils/common';
import Authorization from '../../../utils/Authorization';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { fetchGoalSummaryChart, MenuProps } from '../../Widgets/WidgetService';
import MemberPreview from '../../../Models/Member/MemberPreview.model';
import '../Widgets.css';

const GoalSummary = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [members, setMembers] = useState<MemberPreview[]>([]);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        setMembers([]);
        setIsFetched(false);
        const changeFilter = async () => {
            setIsFetched(false);
            const membersInfo = await fetchGoalSummaryChart(selectedTeam, showInActiveTeams);
            setMembers(membersInfo);
            setIsFetched(true);
        };
        changeFilter();
    }, [selectedTeam, showInActiveTeams]);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12}>
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                            Members with Goals Not Met
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">Active members whose current goals due date has been passed</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select value={selectedTeam} label="Team" name="Team" onChange={(e) => setSelectedTeam(e.target.value)} MenuProps={MenuProps}>
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} marginTop={2} height="40vh" sx={{ overflowY: 'scroll', position: 'relative' }}>
                        {!isFetched ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <SimpleBar style={{ maxHeight: '380px' }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={30} size="small">
                                                    Name
                                                </TableCell>
                                                <TableCell width={25} size="small">
                                                    Health Plan
                                                </TableCell>
                                                <TableCell width={25} size="small">
                                                    Population
                                                </TableCell>
                                                <TableCell width={20} align="right" size="small">
                                                    Last CHP Visit
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {members && members.length
                                                ? members.map((mem) => (
                                                      <TableRow key={mem.Id}>
                                                          <TableCell width={30} size="small">
                                                              {mem.FirstName} {mem.LastName}
                                                          </TableCell>
                                                          <TableCell width={25} size="small">
                                                              {mem.HealthPlan}
                                                          </TableCell>
                                                          <TableCell width={25} size="small">
                                                              {mem.Population}
                                                          </TableCell>
                                                          <TableCell width={20} align="right" size="small">
                                                              {isDefaultDate(mem.LastVisit) ? '' : moment(new Date(mem.LastVisit)).format('MM/DD/yyyy')}
                                                          </TableCell>
                                                      </TableRow>
                                                  ))
                                                : null}
                                            {isFetched && members && members.length === 0 ? (
                                                <TableRow>
                                                    <TableCell colSpan={4}>No Member Found</TableCell>
                                                </TableRow>
                                            ) : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </SimpleBar>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default GoalSummary;
