/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, Button, Chip, CircularProgress, Grid, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { apiString } from '../../utils/constants';
import { IResource } from './interfaces/IResource';
import { IResourcesTableProps } from './interfaces/IResourcesTableProps';
import ResourceEditModal from './ResourceEditModal';
import b64toBlob from 'b64-to-blob';

const resourceColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'Id',
        hide: true,
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        flex: 1,
    },
    {
        field: 'categories',
        headerName: 'Categories',
        width: 500,
        flex: 1,
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 350,
        flex: 1,
    },
];

type DataGridResourceType = {
    id: string;
    name: string;
    categories: string;
    address: string;
};

const dataRowToGridRow = ({ Id, Name, Categories, Address }: IResource): DataGridResourceType => {
    return {
        id: Id,
        name: Name,
        categories: Categories.join(', '),
        address: Address,
    };
};

const ResourcesTable: React.FC<IResourcesTableProps> = (props: IResourcesTableProps) => {
    const { selection } = props;
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [addressFilter, setAddressFilter] = useState<string>('');
    const [dataRows, setDataRows] = useState<IResource[]>([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [selectedResource, setSelectedResource] = useState<IResource | null>();

    const isSelectingResource = useMemo(() => {
        if (selection) return true;
        else return false;
    }, [selection]);

    const gridRows: DataGridResourceType[] = useMemo(() => [...dataRows.map(dataRowToGridRow)], [dataRows]);

    const exportResources = useCallback(async () => {
        if (dataRows.length > 0) {
            let exportResourceURL = `${apiString}/resource/download`;
            const resourceIds = dataRows.map((resource) => resource.Id);
            const response = await axios.post(exportResourceURL, JSON.stringify(resourceIds), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                const { data } = response;
                const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                const randomNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
                const fileName = 'Resources_' + randomNumber;
                document.body.appendChild(a);
                a.href = blobUrl;
                a.download = fileName + '.xlsx';
                a.click();
                a.remove();
            }
        }
    }, [dataRows]);

    const load = useCallback(async () => {
        let requestURL = `${apiString}/resource/all/`;
        let requestParams = {};

        if (searchFilter || categoryFilter.length > 0 || addressFilter) {
            requestURL = `${apiString}/resource/search/`;
            requestParams = {
                searchString: searchFilter,
                categoryString: categoryFilter.join(','),
                addressString: addressFilter,
            };
        }

        try {
            const request = await axios.get(requestURL, { params: requestParams });

            if (request.status === 200) {
                setDataRows(request.data);
            }
            if (selectedResource?.Id) {
                const newSelectedResource = request.data.find((r: IResource) => r.Id === selectedResource.Id);
                if (newSelectedResource) {
                    setSelectedResource(newSelectedResource);
                }
            }
        } catch (error) {
            const err = error as Error;
            setDataRows([]);
            window.alert(`Error loading resources: ${err.message}`);
        }

        setIsSearching(false);
    }, [searchFilter, categoryFilter, addressFilter, selectedResource]);

    const saveResource = useCallback(
        async (resource: IResource) => {
            if (Boolean(resource.Id)) {
                const request = await axios.put(`${apiString}/resource/${resource.Id}`, resource);
                if (request.status === 200) {
                    setIsSearching(true);
                    load();
                }
            } else {
                const request = await axios.post(`${apiString}/resource`, resource);
                if (request.status === 201) {
                    setIsSearching(true);
                    setSelectedResource(request.data);
                    load();
                }
            }
        },
        [load]
    );

    const closeResourceEditModal = useCallback(() => {
        setIsEditModalOpen(false);
    }, []);

    const debouncedLoad = debounce(() => {
        load();
    }, 1500);

    useEffect(() => {
        setIsSearching(true);
        debouncedLoad();
        return () => debouncedLoad.cancel();
    }, [searchFilter, categoryFilter, addressFilter]);

    useEffect(() => {
        load();
    }, []);

    const resourceEditModal = useMemo(() => {
        if (!Boolean(selectedResource)) return null;
        return (
            <ResourceEditModal
                open={isEditModalOpen}
                onClickSave={saveResource}
                onClickClose={closeResourceEditModal}
                resource={selectedResource}
                isSelectingResource={isSelectingResource}
                selection={() => {
                    if (selection) selection(selectedResource);
                    closeResourceEditModal();
                }}
            />
        );
    }, [selectedResource, isEditModalOpen, saveResource, closeResourceEditModal]);

    return (
        <Box style={{ maxHeight: '90vh' }}>
            {resourceEditModal}
            <Stack direction="row" spacing={2}>
                <Button
                    hidden={isSelectingResource}
                    variant="contained"
                    onClick={() => {
                        const newResource: IResource = {
                            CreatedByProviderId: '',
                            UpdatedByProviderId: '',
                            CreatedDate: new Date(),
                            UpdatedDate: new Date(),
                            IsActive: true,
                            Name: '',
                            Description: '',
                            Categories: [],
                            Tags: [],
                            ImageLinks: [],
                            Address: '',
                            Latitude: '',
                            Longitude: '',
                            Contacts: [],
                            Website: '',
                            SocialMediaLinks: {},
                            SocialMedia: [],
                            Id: '',
                            HoursOfOperation: {
                                Monday: { OpensAt: '', ClosesAt: '' },
                                Tuesday: { OpensAt: '', ClosesAt: '' },
                                Wednesday: { OpensAt: '', ClosesAt: '' },
                                Thursday: { OpensAt: '', ClosesAt: '' },
                                Friday: { OpensAt: '', ClosesAt: '' },
                                Saturday: { OpensAt: '', ClosesAt: '' },
                                Sunday: { OpensAt: '', ClosesAt: '' },
                            },
                            Reviews: [],
                        };
                        setSelectedResource(newResource);
                        setIsEditModalOpen(true);
                    }}
                >
                    Add New Resource
                </Button>
                <Button color="success" variant="contained" onClick={exportResources}>
                    Export Resources
                </Button>
            </Stack>

            <Grid container spacing={3}>
                <Grid item lg md sm xs>
                    <TextField
                        style={{ marginBottom: 20, marginTop: 10 }}
                        fullWidth
                        label="Search Names"
                        value={searchFilter}
                        onChange={(event) => setSearchFilter(event?.target?.value)}
                        InputProps={{
                            startAdornment: !isSearching ? (
                                <SearchIcon style={{ marginRight: 5 }} />
                            ) : (
                                <CircularProgress size={18} style={{ marginRight: 5 }} />
                            ),
                        }}
                    />
                </Grid>
                <Grid item lg md sm xs>
                    <Autocomplete
                        fullWidth
                        multiple
                        freeSolo
                        options={[] as string[]}
                        onChange={(e, value) => {
                            console.log(value);
                            setCategoryFilter(value);
                        }}
                        value={categoryFilter}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => <Chip variant="filled" label={option} {...getTagProps({ index })} />)
                        }
                        renderInput={(params) => (
                            <TextField
                                inputProps={{ ...params.inputProps }}
                                {...params}
                                fullWidth
                                helperText="Type a category and press enter to add it to the list."
                                label="Search Categories (Any Resource Having Any Entered Category)"
                                style={{ marginBottom: 20, marginTop: 10 }}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg md sm xs>
                    <TextField
                        fullWidth
                        style={{ marginBottom: 20, marginTop: 10 }}
                        label="Search Addresses"
                        value={addressFilter}
                        onChange={(event) => setAddressFilter(event?.target?.value)}
                        InputProps={{
                            startAdornment: !isSearching ? (
                                <SearchIcon style={{ marginRight: 5 }} />
                            ) : (
                                <CircularProgress size={18} style={{ marginRight: 5 }} />
                            ),
                        }}
                    />
                </Grid>
            </Grid>

            <DataGrid
                sx={{ cursor: 'pointer' }}
                autoHeight={true}
                pageSize={10}
                rowsPerPageOptions={[5]}
                rows={gridRows}
                columns={resourceColumns}
                onRowClick={(row) => {
                    const resource = dataRows.find((resource) => resource.Id === row.id);
                    if (Boolean(resource)) {
                        setSelectedResource(resource);
                        setIsEditModalOpen(true);
                    }
                }}
            />
        </Box>
    );
};

export default ResourcesTable;
