export enum SdohAssessmentEnum {
    LanguageOtherThan = 'Language other than English (please write)',
    MoneyAndResources = 'Otherwise unemployed but not seeking work (student, retired, disabled, unpaid primary care giver) Please write',
    LessThan50000 = '$35,001 to $50,000',
    LessThan35000 = 'Less than $35,000',
    LessThan75000 = '$50,001 to $75,000',
    LessThan100000 = '$75,001 to $100,000',
    Food = 'Food',
    Clothing = 'Clothing',
    Utilities = 'Utilities',
    ChildCare = 'ChildCare',
    Phone = 'Phone',
    Medicine = 'Medicine',
    OtherNeed = 'OtherNeed',
    Yes = 'Yes',
    No = 'No',
}
