import { Button, CircularProgress } from '@mui/material';
import React from 'react';

interface GenericSaveButtonProps {
    label?: string;
    disabled?: boolean;
    saving?: boolean;
    fullWidth?: boolean;
    onClick?: Function;
    children?: React.ReactNode;
}

const GenericSaveButton: React.FC<GenericSaveButtonProps> = ({ label, disabled, saving, onClick, fullWidth, children }) => {
    return (
        <Button
            fullWidth={fullWidth}
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={() => {
                onClick();
            }}
        >
            {saving && <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />}
            <span style={{ color: 'white' }}>{children ?? label}</span>
        </Button>
    );
};

export default React.memo(GenericSaveButton);
