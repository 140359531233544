import React from 'react';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { RecurrenceEditor } from '@catalytic-health-partners/common-components';

export interface IRecurrenceEditorModalProps extends IBasicModalProps {
    recurrenceString: string;
    onRecurrenceStringChange: (recurrenceString: string) => void;
    onDisplayTextChange?: (displayText: string) => void;
}

const RecurrenceEditorModal = (props: IRecurrenceEditorModalProps) => {
    const { recurrenceString, open, onClose, onRecurrenceStringChange } = props;
    const [ruleBuffer, setRuleBuffer] = React.useState<string>(recurrenceString);

    const handleSave = () => {
        onRecurrenceStringChange(ruleBuffer.replace('RRULE:', ''));
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Recurrence Editor</DialogTitle>
            <DialogContent>
                <RecurrenceEditor recurrenceString={ruleBuffer} onChange={(newRule: string) => setRuleBuffer(newRule)} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSave} color="success" disabled={recurrenceString === ruleBuffer}>
                    Save
                </Button>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RecurrenceEditorModal;
