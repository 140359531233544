import { SdohSingleAnswerAdvanced } from './SdohSingleAnswerAdvanced.model';

export default interface ISocialAndEmotionalHealth {
    HowOftenDoYouSeeOrTalkToPeople: SdohSingleAnswerAdvanced | null;
    HowStressedAreYou: SdohSingleAnswerAdvanced | null;
}

export class SocialAndEmotionalHealth implements ISocialAndEmotionalHealth {
    HowOftenDoYouSeeOrTalkToPeople: SdohSingleAnswerAdvanced | null;
    HowStressedAreYou: SdohSingleAnswerAdvanced | null;

    constructor() {
        this.HowOftenDoYouSeeOrTalkToPeople = new SdohSingleAnswerAdvanced();
        this.HowStressedAreYou = new SdohSingleAnswerAdvanced();
    }
}
