import React, { useCallback, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';

import { useWidgetContext } from '../../../hooks/widgetContext';
import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchTeamInPersonVisitsSummaryChart, MenuProps } from '../WidgetService';
import { fetchPopulations } from '../../Population/PopulationService';
import EngagementMemberSummary from '../../../Models/Widget/EngagementMemberSummary.model';
import Population from '../../../Models/Population/Population.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import Authorization from '../../../utils/Authorization';
import { getDateFilter } from '../../HedisMetrics/HedisMetricService';
import '../Widgets.css';

const TeamInPersonVisitsSummary = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const history = useNavigate();
    const { healthPlans } = useHealthPlanContext();
    const {
        engagementDateFilter,
        setEngagementDateFilter,
        engagementFromDate,
        setEngagementFromDate,
        engagementToDate,
        setEngagementToDate,
        engagementSelectedPlan,
        setEngagementSelectedPlan,
        engagementSelectedPopulation,
        setEngagementSelectedPopulation,
        engagementSelectedProvider,
        setEngagementSelectedProvider,
        engagementSelectedTeam,
        setEngagementSelectedTeam,
        engagementShowInActiveProviders,
        setEngagementShowInActiveProviders,
        engagementShowInActiveTeams,
        setEngagementShowInActiveTeams,
    } = useWidgetContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [populations, setPopulations] = useState<Population[]>([]);
    const [engagementMemberSummary, setEngagementMemberSummary] = useState<EngagementMemberSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [loadedPage, setLoadedPage] = useState(false);
    const filteredTeams = (engagementShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (engagementShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const dateFilterList = getDateFilter().filter((d) => d.Value !== 'Custom');

    const healthPlanChange = useCallback(
        async (healthPlanId: string, canResetPopulation = true) => {
            setPopulations([]);
            if (canResetPopulation) {
                setEngagementSelectedPopulation(0);
            }
            if (healthPlanId && healthPlanId !== 'All') {
                const popList = await fetchPopulations(healthPlanId, true);
                setPopulations(popList);
            }
        },
        [setEngagementSelectedPopulation]
    );

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        if (engagementSelectedPlan !== 'All') healthPlanChange(engagementSelectedPlan, false);
        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChart(
                    'Engagement Summary',
                    'Members Count',
                    'Month',
                    'Count',
                    [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                    [0],
                    'Engagement',
                    history
                )
            );
        }

        if (Boolean(authData.TeamId)) {
            setEngagementSelectedTeam(authData.TeamId);
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, engagementSelectedPlan, healthPlanChange, history, setEngagementSelectedTeam, authData.TeamId]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (activeProviders?.length && teams?.length) {
            setLoadedPage(true);
        }
    }, [teams, activeProviders]);

    useEffect(() => {
        if (loadedPage && (engagementDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(engagementFromDate) && Boolean(engagementToDate)))) {
            const changeFilter = async () => {
                setIsGraphShow(false);
                const data = await fetchTeamInPersonVisitsSummaryChart(
                    engagementSelectedPlan,
                    engagementSelectedPopulation,
                    engagementSelectedProvider,
                    engagementSelectedTeam,
                    engagementDateFilter,
                    Boolean(engagementFromDate) && engagementDateFilter === TimePeriodFilterEnum.Custom ? new Date(engagementFromDate).toISOString() : '',
                    Boolean(engagementToDate) && engagementDateFilter === TimePeriodFilterEnum.Custom ? new Date(engagementToDate).toISOString() : '',
                    engagementShowInActiveTeams,
                    engagementShowInActiveProviders,
                    false
                );
                setEngagementMemberSummary(data);
            };
            setChartOptions(
                configureStackedBarChart(
                    'Engagement Summary',
                    'Members Count',
                    'Month',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'Engagement',
                    history
                )
            );
            changeFilter();
        }

        if (engagementDateFilter !== TimePeriodFilterEnum.Custom && Boolean(engagementFromDate) && Boolean(engagementToDate)) {
            setEngagementFromDate(null);
            setEngagementToDate(null);
        }
    }, [
        engagementSelectedPlan,
        engagementSelectedPopulation,
        engagementSelectedProvider,
        engagementDateFilter,
        engagementSelectedTeam,
        engagementFromDate,
        engagementToDate,
        setEngagementFromDate,
        setEngagementToDate,
        history,
        loadedPage,
        engagementShowInActiveTeams,
        engagementShowInActiveProviders,
    ]);

    useEffect(() => {
        if (engagementMemberSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let visitedValues = [];
            let notVisitedValues = [];

            let d = {
                y: engagementMemberSummary.VisitedMembersPercentage,
                name: engagementMemberSummary.VisitedMembersPercentage,
            };
            visitedValues.push(d);

            let d1 = {
                y: engagementMemberSummary.NotVisitedMembersPercentage,
                name: engagementMemberSummary.NotVisitedMembersPercentage,
            };
            notVisitedValues.push(d1);
            xAxisValues.push('Members');

            yAxisValues.push({ name: 'Visited', data: visitedValues, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Not Visited', data: notVisitedValues, color: '#ff7d7d', showInLegend: true });

            if (engagementMemberSummary.VisitedMembersPercentage === 0 && engagementMemberSummary.NotVisitedMembersPercentage === 0) {
                setChartOptions(
                    configureStackedBarChart(
                        'Engagement Summary',
                        'Members Count',
                        'Month',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'Engagement',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedBarChart('Engagement Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, 'Engagement', history)
                );
            }

            setIsGraphShow(true);
        }
    }, [engagementMemberSummary, history]);

    const changeFromDate = (date: any) => {
        setEngagementFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(engagementToDate) || new Date(engagementToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setEngagementToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setEngagementToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(engagementFromDate) || new Date(engagementFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setEngagementFromDate(startDate);
        }
    };

    const navigateToMembers = () => {
        history(`/provider/metricsummary/engagementmemberdetail`);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setEngagementShowInActiveTeams(isChecked);
        setEngagementSelectedTeam('All');
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setEngagementShowInActiveProviders(isChecked);
        setEngagementSelectedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    Member Visits By Team
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Visited :
                                            </Typography>
                                            <Typography variant="caption">
                                                Averages selected team's rate of in-person visits with reasons of "Initial", "Routine Follow-Up", and
                                                "Behavioral Health Services" per active member over the selected months. Members will only be counted for months
                                                that they were active
                                            </Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Not Visited :
                                            </Typography>
                                            <Typography variant="caption">
                                                Number of active members who have not been visited via InPerson over the selected months
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Health Plan</InputLabel>
                                <Select
                                    value={engagementSelectedPlan}
                                    label="Health Plan"
                                    name="plan"
                                    onChange={(e) => {
                                        setEngagementSelectedPlan(e.target.value);
                                        healthPlanChange(e.target.value);
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {filteredHealthPlans.map((plan) => (
                                        <MenuItem key={plan.Id} value={plan.Id}>
                                            {plan.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Population</InputLabel>
                                <Select
                                    value={engagementSelectedPopulation}
                                    label="Population"
                                    name="Population"
                                    onChange={(e) => setEngagementSelectedPopulation(parseInt(e.target.value as any))}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="0">
                                        All
                                    </MenuItem>
                                    {populations.map((pop) => (
                                        <MenuItem key={pop.PopulationNumber} value={pop.PopulationNumber}>
                                            {pop.PopulationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={engagementSelectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setEngagementSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox checked={engagementShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={engagementSelectedTeam}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setEngagementSelectedTeam(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={engagementShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={engagementDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setEngagementDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {engagementDateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(engagementFromDate) ? engagementFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(engagementToDate) ? engagementToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default TeamInPersonVisitsSummary;
