import { AllFilterTypeEnum } from '../../Enum/AllFilterTypeEnum';
import { MetricTypeEnum } from '../../Enum/MetricTypeEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IQaMetricsView extends BaseEntity {
    ProviderId: string;
    MetricType: MetricTypeEnum;
    Name: string;
    Percentage: number;
    Goal: number;
    IsMet: boolean;
    AverageDays: number;
    AllFilterType: AllFilterTypeEnum;
}

export default class QaMetricsView implements IQaMetricsView {
    Id: string;
    ProviderId: string;
    MetricType: MetricTypeEnum;
    Name: string;
    Percentage: number;
    Goal: number;
    IsMet: boolean;
    AverageDays: number;
    AllFilterType: AllFilterTypeEnum;

    constructor() {
        this.Id = '';
        this.ProviderId = '';
        this.MetricType = 0;
        this.Name = '';
        this.Percentage = 0;
        this.Goal = 0;
        this.IsMet = false;
        this.AverageDays = 0;
        this.AllFilterType = 0;
    }
}
