import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { useNavigate } from 'react-router';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

import { useWidgetContext } from '../../../hooks/widgetContext';
import { fetchSocialWorkerRequestSummary, MenuProps, exportSocialWorkerRequestSummary } from '../WidgetService';
import SocialWorkerRequestSummary from '../../../Models/Widget/SocialWorkerRequestSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { RequestDayFilterEnum } from '../../../Enum/RequestDayFilterEnum';
import { parentUrl } from '../../../utils/constants';
import '../Widgets.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const dateFilterList = [
    { Id: 1, Value: 'Last 3 Months' },
    { Id: 2, Value: 'Last 6 Months' },
    { Id: 3, Value: 'Last 1 Year' },
    { Id: 4, Value: 'Custom' },
];

const dayFilterList = [
    { Id: 1, Value: RequestDayFilterEnum.Within15DaysDescription },
    { Id: 2, Value: RequestDayFilterEnum.Within30DaysDescription },
];

const SocialWorkerRequestSummaryDetail = () => {
    const socialWorkerRole = 'Social Worker';
    const history = useNavigate();
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const {
        reqSelectedProvider,
        setReqSelectedProvider,
        reqDayFilter,
        setReqDayFilter,
        reqDateFilter,
        setReqDateFilter,
        reqFromDate,
        setReqFromDate,
        reqToDate,
        setReqToDate,
        requestShowInActiveProviders,
        setRequestShowInActiveProviders,
    } = useWidgetContext();
    const [members, setMembers] = useState<SocialWorkerRequestSummary>();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const filteredProviders = (requestShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const socialWorkerProviders = filteredProviders.filter((p) => p.Role.RoleName === socialWorkerRole);

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
    }, [isFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (reqDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(reqFromDate) && Boolean(reqToDate))) {
            const changeFilter = async () => {
                setIsMembersLoading(false);
                const data = await fetchSocialWorkerRequestSummary(
                    reqSelectedProvider,
                    reqDayFilter,
                    reqDateFilter,
                    Boolean(reqFromDate) && reqDateFilter === TimePeriodFilterEnum.Custom ? new Date(reqFromDate).toISOString() : '',
                    Boolean(reqToDate) && reqDateFilter === TimePeriodFilterEnum.Custom ? new Date(reqToDate).toISOString() : '',
                    requestShowInActiveProviders,
                    true
                );
                setMembers(data);
                setIsMembersLoading(true);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (reqDateFilter !== TimePeriodFilterEnum.Custom && Boolean(reqFromDate) && Boolean(reqToDate)) {
            setReqFromDate(null);
            setReqToDate(null);
        }
    }, [reqSelectedProvider, reqDayFilter, reqDateFilter, reqFromDate, reqToDate, setReqFromDate, setReqToDate, requestShowInActiveProviders]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const changeFromDate = (date: any) => {
        setReqFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(reqToDate) || new Date(reqToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setReqToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setReqToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(reqFromDate) || new Date(reqFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setReqFromDate(startDate);
        }
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportSocialWorkerRequestSummary(
            reqSelectedProvider,
            reqDayFilter,
            reqDateFilter,
            Boolean(reqFromDate) && reqDateFilter === TimePeriodFilterEnum.Custom ? new Date(reqFromDate).toISOString() : '',
            Boolean(reqToDate) && reqDateFilter === TimePeriodFilterEnum.Custom ? new Date(reqToDate).toISOString() : '',
            requestShowInActiveProviders
        );
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `SocialWorkerRequestSummary - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleInactiveHandler = (isChecked: boolean) => {
        setRequestShowInActiveProviders(isChecked);
        setReqSelectedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={2}>
                <Grid item xs={12} paddingBottom={1}>
                    <span onClick={backToWidget}>
                        <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                    </span>
                    <Typography display={'inline-block'} variant="h6" textAlign="left">
                        Social Worker Request Member Summary
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Providers</InputLabel>
                        <Select
                            value={reqSelectedProvider}
                            label="Providers"
                            name="Providers"
                            onChange={(e) => setReqSelectedProvider(e.target.value)}
                            MenuProps={MenuProps}
                        >
                            <MenuItem key="All" value="All">
                                All
                            </MenuItem>
                            <FormControlLabel
                                sx={{ pl: '12px', mb: '0px' }}
                                control={<Checkbox checked={requestShowInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                label="Show Inactive"
                            />
                            {socialWorkerProviders.map((pro) => (
                                <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                    {pro.LastName}, {pro.FirstName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Completed Within</InputLabel>
                        <Select
                            value={reqDayFilter}
                            label="Completed Within"
                            name="Completed Within"
                            onChange={(e) => setReqDayFilter(e.target.value)}
                            MenuProps={MenuProps}
                        >
                            {dayFilterList.map((day) => (
                                <MenuItem key={day.Id} value={day.Id}>
                                    {day.Value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Date Filter</InputLabel>
                        <Select value={reqDateFilter} label="Date Filter" name="Date" onChange={(e) => setReqDateFilter(parseInt(e.target.value as any))}>
                            {dateFilterList.map((date) => (
                                <MenuItem key={date.Id} value={date.Id}>
                                    {date.Value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {reqDateFilter === TimePeriodFilterEnum.Custom ? (
                    <React.Fragment>
                        <Grid item md={2}>
                            <DesktopDatePicker
                                label="From Date"
                                value={Boolean(reqFromDate) ? reqFromDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => changeFromDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <DesktopDatePicker
                                label="To Date"
                                value={Boolean(reqToDate) ? reqToDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => changeToDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                    </React.Fragment>
                ) : null}
                <Grid item xs={reqDateFilter === TimePeriodFilterEnum.Custom ? 1 : 5}></Grid>
                <Grid item xs={1}>
                    <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                        {downLoading ? 'Exporting..' : 'Export'}
                    </Button>
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                    <Divider light />
                </Grid>
            </Grid>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={2} position="relative" minHeight={500}>
                {!isMembersLoading ? (
                    <Box className="loader-center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <React.Fragment>
                        <Grid item xs={6}>
                            <Grid item xs={12} textAlign="center">
                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                    Completed Request ({members.CompletedMembers.length})
                                </Typography>
                            </Grid>
                            <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                <Table aria-label="customized table" stickyHeader>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>#</StyledTableCell>
                                            <StyledTableCell>Member Name</StyledTableCell>
                                            <StyledTableCell>Request Type</StyledTableCell>
                                            <StyledTableCell>Created Date</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {members && members.CompletedMembers.length
                                            ? members.CompletedMembers.map((mem, index) => (
                                                  <StyledTableRow key={index} className="pointer" onClick={() => navigateToMemberDashboard(mem.Id)}>
                                                      <StyledTableCell>{index + 1}</StyledTableCell>
                                                      <StyledTableCell>{mem.Name}</StyledTableCell>
                                                      <StyledTableCell>{mem.Description}</StyledTableCell>
                                                      <StyledTableCell>{mem.DateInfo !== null ? mem.DateInfo : ''}</StyledTableCell>
                                                  </StyledTableRow>
                                              ))
                                            : null}
                                        {isFetched && members && members.CompletedMembers.length === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={4}>No Member Found</StyledTableCell>
                                            </StyledTableRow>
                                        ) : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={12} textAlign="center">
                                <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                    Not Completed Request ({members.NotCompletedMembers.length})
                                </Typography>
                            </Grid>
                            <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                <Table aria-label="customized table" stickyHeader>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>#</StyledTableCell>
                                            <StyledTableCell>Member Name</StyledTableCell>
                                            <StyledTableCell>Request Type</StyledTableCell>
                                            <StyledTableCell>Created Date</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {members && members.NotCompletedMembers.length
                                            ? members.NotCompletedMembers.map((mem, index) => (
                                                  <StyledTableRow key={index} className="pointer" onClick={() => navigateToMemberDashboard(mem.Id)}>
                                                      <StyledTableCell>{index + 1}</StyledTableCell>
                                                      <StyledTableCell>{mem.Name}</StyledTableCell>
                                                      <StyledTableCell>{mem.Description}</StyledTableCell>
                                                      <StyledTableCell>{mem.DateInfo !== null ? mem.DateInfo : ''}</StyledTableCell>
                                                  </StyledTableRow>
                                              ))
                                            : null}
                                        {isFetched && members && members.NotCompletedMembers.length === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={4}>No Member Found</StyledTableCell>
                                            </StyledTableRow>
                                        ) : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </LocalizationProvider>
    );
};

export default SocialWorkerRequestSummaryDetail;
