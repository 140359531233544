import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

export interface ITooltipDetailLineProps {
    title: string;
    details: string;
    hide?: boolean;
}

const COPIED_TEXT = 'Copied!';

const classes = {
    wrapText: {
        '& .MuiInputBase-input': {
            wordWrap: 'break-word',
            maxHeight: '100px', // You can set a maximum height
            overflow: 'auto',
        },
    },
};

const TooltipDetailLine = (props: ITooltipDetailLineProps) => {
    const { title, details, hide } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleCopyClick = () => {
        copyToClipboard(details);
        setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    if (hide) return null;

    return (
        <TextField
            label={title}
            variant="outlined"
            value={details}
            size="small"
            fullWidth
            sx={classes.wrapText}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <Tooltip
                        title={COPIED_TEXT}
                        arrow
                        PopperProps={{ style: { zIndex: 99999999 } }}
                        placement="top"
                        open={tooltipOpen}
                        onClose={handleTooltipClose}
                    >
                        <IconButton onClick={handleCopyClick} edge="end">
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                ),
            }}
        />
    );
};

export default TooltipDetailLine;
