import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Autocomplete, Button, CircularProgress, Container, Divider, Grid, Link, Paper, Stack, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StatusEnum } from '../../Enum/StatusEnum';
import Member from '../../Models/Member/Member.model';
import { RootState } from '../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../store/assessment.slice';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';
import { setLinkedRequestId, toggleRequestResolutionModal, updateRequestStatus } from '../../store/requests.slice';
import { getActiveProviders } from '../../store/provider.slice';
import { apiString, parentUrl } from '../../utils/constants';
import AlertDialog from '../AlertDialog/AlertDialog';
import ResourcesPage from '../Resources/ResourcesPage';
import ConfirmCloseRequestModal from './ConfirmCloseRequestModal';
import './css/requests.css';
import ForwardingModal from './Forwarding/ForwardingModal';
import RequestHistoryButtons from './Forwarding/RequestHistoryButtons';
import { IRequestsProps, IRequestStatus } from './interfaces';
import { requestStatusMapping } from './mappings/RequestStatusMapping';
import NewRequestModal from './NewRequestModal/NewRequestModal';
import { requestStatusOptions } from './Options/RequestStatusOptions';
import RequestsListSideBar from './RequestList/RequestsListSideBar';
import RequestResolutionModal from './RequestResolutionModal/RequestResolutionModal';
import RequestsCommentsBox from './RequestsComments/RequestsCommentsBox';
import RequestsQuestionsBox from './RequestsQuestions/RequestsQuestionsBox';
import StatusIcon from './StatusIcon/StatusIcon';

export interface IRequestsExpectedUrlParams {
    memberId: string;
    urlRequestId: string;
    location: string;
    userType: string;
    title: string;
}

const Requests: React.FC<IRequestsProps> = ({ memberId, isAssessment = false, title }) => {
    let params = useParams<any>();
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);

    let urlRequestId = params.urlRequestId || '';

    if (memberId === undefined) {
        memberId = params.memberId;
    }
    const { selectedRequest, linkedRequestId } = useSelector((state: RootState) => state.requests);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const [tabIndex, setTabIndex] = useState<number>(0);
    const [showCloseRequestModal, setShowCloseRequestModal] = useState<boolean>(false);
    const { Message = '', Status = 1 } = selectedRequest ?? {};
    const requestStatus = useMemo(() => {
        if (!Status) return requestStatusOptions[0];
        return requestStatusOptions.find(({ value }) => value === Status);
    }, [Status]);
    const [preSelectedMember, setPreSelectedMember] = useState<any>({
        Id: memberId ?? '',
    });

    const toggleModal = () => dispatch(toggleRequestResolutionModal());

    const handleOnSubmit = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveEngagementDraft({ ...engagementDraft, RequestStatus: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleRequestStatusChange = (newStatus: number) => {
        if (newStatus === requestStatusMapping.Completed.value && (Status ?? 0) !== requestStatusMapping.Completed.value) {
            toggleModal();
        } else if (newStatus === requestStatusMapping.ClosedWithNoAction.value && (Status ?? 0) !== requestStatusMapping.ClosedWithNoAction.value) {
            setShowCloseRequestModal(true);
        } else {
            dispatch(updateRequestStatus(newStatus, selectedRequest.Id));
        }
    };

    useEffect(() => {
        if (urlRequestId?.length > 0 && (linkedRequestId?.length === 0 || linkedRequestId !== urlRequestId)) {
            dispatch(setLinkedRequestId(urlRequestId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlRequestId, linkedRequestId]);

    useEffect(() => {
        if (!memberId) return;
        const response = axios.get(`${apiString}/member/getmemberbyid`, {
            params: { memberId },
        });
        response.then(async ({ data }) => {
            const member: Member = await data;
            setPreSelectedMember(member);
        });
    }, [memberId]);

    useEffect(() => {
        dispatch(getActiveProviders(false));
        document.title = title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    let requestDetailsPane =
        selectedRequest !== undefined ? (
            <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Paper variant="outlined">
                        <Grid container spacing={3} direction="column">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography style={{ textAlign: 'center' }} variant="h6">
                                    Description
                                </Typography>
                                <Divider variant="fullWidth"></Divider>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        whiteSpace: 'pre-line',
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(Message),
                                    }}
                                ></Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {/* <Grid item lg md sm xs>
          <ResourceManagementBox />
        </Grid> */}
                <Grid item lg md sm xs>
                    <RequestsQuestionsBox {...selectedRequest} />
                </Grid>
                <Grid item lg md sm xs>
                    <RequestsCommentsBox {...selectedRequest} isAssessment={isAssessment} />
                </Grid>
            </>
        ) : (
            <Grid item>
                <Paper variant="outlined" style={{ padding: 100 }}>
                    <Typography variant="h2">No Request Selected</Typography>
                </Paper>
            </Grid>
        );

    if (selectedRequest?.Status === requestStatusMapping.ClosedWithNoAction.value) {
        requestDetailsPane = (
            <Grid item lg md sm xs>
                <Typography style={{ textAlign: 'center', paddingTop: 100 }} variant="h2">
                    This Request Has Been Closed
                </Typography>
            </Grid>
        );
    }

    const requests = (
        <Grid container style={{ minHeight: 1000 }} spacing={1} direction="row">
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <RequestsListSideBar preSelectedMember={preSelectedMember} />
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    {selectedRequest?.RequestFor?.Name?.length > 0 && (
                        <>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm
                                xs
                                style={{
                                    paddingLeft: 20,
                                }}
                            >
                                <RequestHistoryButtons selectedRequest={selectedRequest} />
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                <Typography align="center" style={{ alignItems: 'center' }} variant="h5">
                                    <Tooltip title="Open Member Dashboard" placement="left">
                                        <Link
                                            rel="noopener"
                                            target="_blank"
                                            href={`${parentUrl}provider/members-list/member-chart/${selectedRequest.RequestFor.Id}/member-profile`}
                                        >{`${selectedRequest.RequestFor.Name}`}</Link>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item lg={4} md={12} sm={12} xs={12} hidden={!selectedRequest}>
                                <Autocomplete
                                    sx={{
                                        paddingRight: 3,
                                        paddingBottom: 1,
                                        '& .MuiInputBase-root': {
                                            height: '2.6rem',
                                        },
                                    }}
                                    fullWidth
                                    disableClearable
                                    id="request-status-select"
                                    options={requestStatusOptions}
                                    isOptionEqualToValue={(option, value: any) => {
                                        return option.value === value.value;
                                    }}
                                    getOptionDisabled={(option: IRequestStatus) => {
                                        // Return everything when new.
                                        if (Status === requestStatusMapping.New.value) {
                                            if (
                                                option.value === requestStatusMapping.Completed.value ||
                                                option.value === requestStatusMapping.InProgress.value ||
                                                option.value === requestStatusMapping.ClosedWithNoAction.value
                                            )
                                                return false;
                                        }

                                        if (Status === requestStatusMapping.InProgress.value) {
                                            if (
                                                option.value === requestStatusMapping.Completed.value ||
                                                option.value === requestStatusMapping.ClosedWithNoAction.value
                                            )
                                                return false;
                                        }

                                        return true;
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Request Status"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: Status ? <StatusIcon status={Status} /> : undefined,
                                            }}
                                        />
                                    )}
                                    value={requestStatus as any}
                                    onChange={(event: any, newValue: any) => {
                                        if (newValue.value) {
                                            handleRequestStatusChange(newValue.value);
                                        } else {
                                            handleRequestStatusChange(newValue);
                                        }
                                    }}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Container maxWidth={false}>
                            <Grid container justifyContent={selectedRequest ? 'space-around' : 'center'} spacing={1} alignItems="flex-start">
                                {requestDetailsPane}
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <SnackbarProvider maxSnack={2}>
            <div style={{ padding: 10, backgroundColor: 'white' }} className={isAssessment ? 'content-panel' : ''}>
                <NewRequestModal
                    lockedMemberOption={
                        preSelectedMember?.Id?.length
                            ? {
                                  MemberId: preSelectedMember.Id,
                                  MemberName: `${preSelectedMember?.FirstName ?? ''} ${preSelectedMember?.LastName ?? ''}`,
                              }
                            : undefined
                    }
                />
                <ConfirmCloseRequestModal
                    open={showCloseRequestModal}
                    onClose={() => setShowCloseRequestModal(false)}
                    onSuccess={() => {
                        dispatch(updateRequestStatus(requestStatusMapping.ClosedWithNoAction.value, selectedRequest.Id));
                        setShowCloseRequestModal(false);
                    }}
                />
                <ForwardingModal />
                <RequestResolutionModal isAssessment={isAssessment} />
                <Tabs
                    value={tabIndex}
                    onChange={(event, newValue) => {
                        setTabIndex(newValue);
                    }}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                >
                    <Tab label="Requests" />
                    <Tab label="Resources" />
                </Tabs>
                {tabIndex === 0 ? requests : <ResourcesPage />}
                {isAssessment ? (
                    <React.Fragment>
                        <Stack direction="row" justifyContent="flex-end" className="content-footer" style={{ margin: '15px -10px -10px' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ mr: 3 }}
                                disabled={isSavingEngagementDraft}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={handleOnSubmit}
                                disabled={isSavingEngagementDraft}
                                endIcon={isSavingEngagementDraft ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                            >
                                Next
                            </Button>
                        </Stack>
                        <AlertDialog
                            open={showSaveAlert}
                            title="Requests/Referrals"
                            cancelText="Go Back"
                            okText="Confirm"
                            onConfirm={() => handleOnConfirmToNext(true)}
                            onSkip={() => handleOnConfirmToNext()}
                            onClose={() => setShowSaveAlert(false)}
                            isLoading={isSavingEngagementDraft}
                            message="I confirm and acknowledge that Requests/Referrals is completed."
                        />
                    </React.Fragment>
                ) : null}
            </div>
        </SnackbarProvider>
    );
};

export default Requests;
