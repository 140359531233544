import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import axios from 'axios';
import { useEffect, useState, useCallback, useRef } from 'react';
import { parentUrl } from '../../../../utils/constants';
import { apiString as API_URL } from '../../../../utils/constants';
import { localDateFormat } from '../../../../utils/timeFormat';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';
import { fetchTeamsName } from '../../../../store/teams.slice';
import { copyObject } from '../../../../utils/common';

const PHQ9 = () => {
    const dispatch = useDispatch();
    const authData = new Authorization();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { teams } = useSelector((state: RootState) => state.teams);
    const [phq9, setPHQ9] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTeam, setSelectedTeam] = useState('All');
    const [loadedTeam, setLoadedTeam] = useState(false);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const copyTeams = copyObject(teams);
    const [showInActiveTeam, setShowInActiveTeam] = useState<boolean>(false);
    const filteredTeams = (showInActiveTeam ? copyTeams : copyTeams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const initialPage = useCallback(async () => {
        dispatch(fetchTeamsName());

        if (Boolean(authData.TeamId)) {
            setSelectedTeam(authData.TeamId);
        }
    }, [dispatch, authData.TeamId]);

    useEffect(() => {
        if (teams?.length) {
            setLoadedTeam(true);
            setIsLoading(false);
        }
    }, [teams, loadedTeam]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/assessments`, '_blank');
    };

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_URL}/assessment/phq9widget?teamId=${selectedTeam}&includeInActiveTeams=${showInActiveTeam}`);
                setPHQ9(response.data);
                setIsLoading(false);
            } catch (error) {
                setPHQ9([]);
                setIsLoading(false);
                console.log(error);
            }
        };
        if (loadedTeam) {
            changeFilter();
        }
    }, [selectedTeam, showInActiveTeam, loadedTeam]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveTeam(isChecked);
        setLoadedTeam(false);
        setSelectedTeam('All');
        setPHQ9([]);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                            PHQ9
                        </Typography>
                        <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                            &#40;{phq9.length}&#41;
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Members, by NP or BH team, whose PHQ9 assessment score is greater than 14 and PHQ9 CPT code is not present. Red
                                            indicates PHQ9 score which is greater than 14 and last PHQ9 assessment is 15 days old
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    {showProviders && (
                        <Grid item xs={12} marginTop={2}>
                            <FormControl className="form-control-200" sx={{ marginRight: 2 }}>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    disabled={isLoading ? true : false}
                                    size="small"
                                    value={selectedTeam}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => {
                                        setSelectedTeam(e.target.value);
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveTeam} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams
                                        .sort((a, b) => a.Name.localeCompare(b.Name))
                                        .map((team) => (
                                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                                {team.Name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid container spacing={2} marginTop={2} height={showProviders ? '52vh' : '58vh'} sx={{ overflowY: 'scroll', position: 'relative' }}>
                        {isLoading && <WidgetLoading />}
                        {!isLoading ? (
                            phq9.length > 0 ? (
                                phq9.map((member, index) => {
                                    return (
                                        <Grid key={index} item xs={6}>
                                            <Card
                                                sx={{
                                                    ':hover': {
                                                        cursor: 'pointer',
                                                        boxShadow: 10,
                                                        border: 0,
                                                    },
                                                    boxShadow: 0,
                                                }}
                                                onClick={() => openDocumentHandler(member.MemberId)}
                                                variant="outlined"
                                            >
                                                <CardContent
                                                    sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-between' }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Member
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2" color={member.Priority ? 'error.main' : ''}>
                                                                {member.MemberName}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Provider
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2" color={member.Priority ? 'error.main' : ''}>
                                                                {member.Provider}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Assessment Date
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2" color={member.Priority ? 'error.main' : ''}>
                                                                {localDateFormat(new Date(member.AssessmentDate))}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                PHQ9Score
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2" color={member.Priority ? 'error.main' : ''}>
                                                                {member.PHQ9Score}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Message
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2" color={member.Priority ? 'error.main' : ''}>
                                                                Encounter with code G8431 not found.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Request Status
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2" color={member.Priority ? 'error.main' : ''}>
                                                                {member.RequestStatus}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Grid item xs={12} textAlign="center">
                                    <Typography variant="h6">No Matching Records</Typography>
                                </Grid>
                            )
                        ) : null}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default PHQ9;
