import { StatusEnum } from '../../Enum/StatusEnum';
import { Emotion } from './Emotion.model';
import { MiniMentalExamItem } from './MiniMentalExamItem.model';
import { Thought } from './Thought.model';

export default interface IMiniMentalExam {
    Id: string;
    MemberId: string;
    MemberName: string;
    GeneralObservation: GeneralObservation;
    Thought: Thought;
    Emotion: Emotion;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
    Status: StatusEnum;
}

export class GeneralObservation {
    Appearance: MiniMentalExamItem;
    Demeanor: MiniMentalExamItem;
    EyeContact: MiniMentalExamItem;
    Behavior: MiniMentalExamItem;
    Speech: MiniMentalExamItem;
}

export class MiniMentalExam implements IMiniMentalExam {
    Id: string;
    MemberId: string;
    MemberName: string;
    GeneralObservation: GeneralObservation;
    Thought: Thought;
    Emotion: Emotion;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
    Status: StatusEnum;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.MemberName = '';
    }
}
