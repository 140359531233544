import { RequestTypeEnum } from '../../Enum/RequestTypeEnum';
import BaseEntity from '../BaseEntity.model';
import { Team } from '../Member/Team.model';
import RequestTo from './RequestTo.model';
import RequestFrom from './RequestFrom.model';
import RequestFor from './RequestFor.model';
import RequestReviewHistory from './RequestReviewHistory.model';
import { PriorityEnum } from '../../Enum/PriorityEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import { RequestComment } from './Comment.modal';

export default interface IRequestPreview extends BaseEntity {
    Message: string;
}

export class RequestPreview implements IRequestPreview {
    Id: string;
    Message: string;
    RequestTo: RequestTo;
    RequestFrom: RequestFrom;
    RequestFor: RequestFor;
    Type: RequestTypeEnum;
    Priority: PriorityEnum;
    Team: Team;
    CommentsCount: number;
    CreatedOn: string;
    CompletedOn: Date;
    LastUpdatedOn: string;
    Status: StatusEnum;
    ReviewHistory: RequestReviewHistory[];
    Comments: RequestComment[];

    constructor() {
        this.Id = '';
        this.Message = '';
        this.ReviewHistory = [] as RequestReviewHistory[];
        this.Priority = PriorityEnum.Low;
        this.Comments = [] as RequestComment[];
    }
}

export class RequestPreviewsByType {
    Key: string;
    Values: RequestPreview[];

    constructor() {
        this.Key = '';
        this.Values = [];
    }
}
