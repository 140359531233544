import { Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import loginImage from '../../assets/images/catalytic_login.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getForgottenPassword } from '../../store/provider.slice';
import MessageDisplay from '../Shared/MessageDisplay';
import Authorization from '../../utils/Authorization';

const ForgotPassword: React.FC = (props) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [errorText, setErrorText] = useState('');
    const [envText, setEnvText] = useState('');

    const navigate = useNavigate();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (email.includes('@') && email.includes('.')) {
            setErrorText('');
            dispatch(getForgottenPassword(email));
            setEmail('');
        } else {
            setErrorText('Please enter a valid email address');
        }
    };

    const handleCancel = () => {
        setEmail('');
        navigate('/login');
    };

    useEffect(() => {
        var env = new Authorization().getEnv();
        if (env !== 'Production') {
            setEnvText(env);
        }
        document.title = 'CHP - Forgot Password';
    }, []);

    return (
        <>
            <MessageDisplay />
            <Grid container paddingBottom={0} paddingTop={2}>
                <Grid item xs={12}>
                    <Stack spacing={2} alignItems={'center'} paddingY={10}>
                        <Typography className="env-text" align="center">
                            {envText}
                        </Typography>
                        <img src={loginImage} alt="CHP Login" />
                        <form onSubmit={handleSubmit}>
                            <FormControl>
                                <Stack spacing={2} paddingY={2} style={{ minWidth: '400px', paddingTop: '40px' }} alignItems={'center'}>
                                    <Typography variant="h5" fontWeight={'bold'}>
                                        Forgot password?
                                    </Typography>
                                    <Typography variant="body1">Please enter your email address to reset your password</Typography>
                                    <TextField
                                        error={errorText.length > 0}
                                        fullWidth
                                        helperText={errorText}
                                        label="Email"
                                        variant="outlined"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    <Stack spacing={2} direction={'row'}>
                                        <Button type="button" className="button-120" onClick={handleCancel} variant="outlined">
                                            Login
                                        </Button>
                                        <Button type="submit" className="button-120" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </Stack>
                                </Stack>
                            </FormControl>
                        </form>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default ForgotPassword;
