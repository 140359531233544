import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateRequest } from '../../../store/requests.slice';
import { apiString, ProviderId } from '../../../utils/constants';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import { IResource } from '../../Resources/interfaces/IResource';
import { IRequest } from '../interfaces';
import { IResourceServiceItem } from '../interfaces/IResourceServiceItem';
import PayerRow from './PayerRow';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SelectResourceModal from '../../Resources/SelectResourceModal';
import FormikTextField from '../../FormikFields/FormikTextField';

const AddResourceItemModal: React.FC<IBasicModalProps & { request: IRequest }> = (props) => {
    const { request, open, onClose } = props;
    const dispatch = useDispatch();

    const [resourceOptions, setResourceOptions] = useState<{ value: string; label: string }[]>([]);
    const [selectedResourceOption, setSelectedResourceOption] = useState<{ value: string; label: string } | null>(null);
    const [showSelectResourceModal, setShowSelectResourceModal] = useState<boolean>(false);

    const handleSelectResourceModalClose = () => {
        setShowSelectResourceModal(false);
    };

    const handleSelectResourceModalSuccess = (resource: IResource) => {
        setSelectedResourceOption({
            value: resource.Id,
            label: resource.Name,
        });
        handleSelectResourceModalClose();
    };

    const resourceAlreadyExists = useMemo(() => {
        return request.ResourceServiceItems.some((r) => r.ResourceId === selectedResourceOption?.value);
    }, [request.ResourceServiceItems, selectedResourceOption]);

    const fetchResourceOptions = async () => {
        const requestURL = `${apiString}/resource/all`;

        try {
            const response = await axios.get(requestURL);
            if (response?.data) {
                const resourceOptions: {
                    value: string;
                    label: string;
                }[] = (response.data as IResource[]).map(({ Id, Name }) => {
                    return {
                        value: Id,
                        label: Name,
                    };
                });
                setResourceOptions(resourceOptions.sort((a, b) => (a.label > b.label ? 1 : -1)));
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message ?? error?.message;
            window.alert('Error fetching resource options: ' + errorMessage);
            console.log(error);
        }
    };

    useEffect(() => {
        setSelectedResourceOption(null);
        fetchResourceOptions();
    }, []);

    const resourceServiceItem: IResourceServiceItem = useMemo(
        () => ({
            ResourceId: selectedResourceOption?.value ?? '',
            ResourceName: selectedResourceOption?.label ?? '',
            PayerList: [],
            ResourceDidProvideService: false,
            Note: '',
        }),
        [selectedResourceOption]
    );

    return (
        <Formik
            enableReinitialize={true}
            initialValues={resourceServiceItem}
            onSubmit={(values: IResourceServiceItem) => {
                const newRequest = { ...request, ResourceServiceItems: [...request.ResourceServiceItems, values] };
                dispatch(UpdateRequest(newRequest));
                setSelectedResourceOption(null);
                onClose();
            }}
        >
            {({ submitForm, values, setFieldValue, dirty, isValid, resetForm, handleChange }: FormikProps<IResourceServiceItem>) => (
                <Form>
                    <Dialog
                        open={open}
                        maxWidth={false}
                        PaperProps={{
                            sx: { alignSelf: 'flex-start', minWidth: 600 },
                        }}
                    >
                        <DialogTitle>Add Resource to List</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} sx={{ display: 'flex' }}>
                                <Grid item lg={10} md={10} sm={10} xs={10}>
                                    <SelectResourceModal
                                        open={showSelectResourceModal}
                                        onClose={handleSelectResourceModalClose}
                                        onSuccessfulSelection={handleSelectResourceModalSuccess}
                                    />
                                    <Autocomplete
                                        style={{ paddingTop: 5 }}
                                        fullWidth
                                        disableClearable
                                        multiple={false}
                                        id="resource-option-selection"
                                        options={resourceOptions}
                                        isOptionEqualToValue={(option: any, value: any) => {
                                            return option.value === value;
                                        }}
                                        getOptionLabel={(option: any) => option.label}
                                        renderInput={(params) => <TextField {...params} label="Search Resource Names" required />}
                                        value={selectedResourceOption}
                                        onChange={(event: any, newValue: any) => {
                                            setSelectedResourceOption(newValue);
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2} sx={{ display: 'flex' }} justifyContent="center" alignItems="center">
                                    <Tooltip title="Open full resource search window" arrow>
                                        <IconButton color="primary" size="large" onClick={() => setShowSelectResourceModal(true)}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {!selectedResourceOption?.value ? null : (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={values.ResourceDidProvideService} />}
                                            label="Was the resource used to help fulfill the request?"
                                            name="ResourceDidProvideService"
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                )}
                                {!selectedResourceOption?.value ? null : (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormikTextField name="Note" label="Quick Notes" multiline rows={3} />
                                    </Grid>
                                )}
                                <FieldArray name="PayerList">
                                    {({ push, remove }) => (
                                        <>
                                            {!selectedResourceOption?.value ? null : (
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Divider>
                                                        <Chip
                                                            label="Add Contributors"
                                                            clickable
                                                            color="primary"
                                                            icon={<AddCircleOutline />}
                                                            onClick={() =>
                                                                push({
                                                                    WhoPaid: '',
                                                                    Amount: 0,
                                                                    CreatedAt: new Date(),
                                                                    UpdatedAt: new Date(),
                                                                    CreatedBy: ProviderId,
                                                                    UpdatedBy: ProviderId,
                                                                })
                                                            }
                                                        />
                                                    </Divider>
                                                </Grid>
                                            )}
                                            {!selectedResourceOption?.value ? null : (
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    {React.Children.toArray(
                                                        values.PayerList.map((payer, index) => (
                                                            <PayerRow payer={payer} index={index} remove={remove} options={resourceOptions} />
                                                        ))
                                                    )}
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {resourceAlreadyExists && (
                                <Typography variant="caption" color="error" style={{ paddingRight: 10 }}>
                                    Resource already exists in request's resources list.
                                </Typography>
                            )}
                            {!selectedResourceOption?.value && (
                                <Typography variant="caption" color="error" style={{ paddingRight: 10 }}>
                                    Please select a resource.
                                </Typography>
                            )}
                            <Button variant="contained" onClick={submitForm} disabled={!selectedResourceOption?.value || resourceAlreadyExists}>
                                Add Resource
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setSelectedResourceOption(null);
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
};

export default AddResourceItemModal;
