export enum VitalSignEnum {
    AllDescription = 'All',
    All = 0,
    GlucometerDescription = 'Glucometer',
    Glucometer = 1,
    BloodPressureDescription = 'Blood Pressure',
    BloodPressure = 2,
    OximeterDescription = 'Oximeter',
    Oximeter = 3,
    ScaleDescription = 'Scale',
    Scale = 4,
    HeartRateDescription = 'Heart Rate',
    HeartRate = 5
}
