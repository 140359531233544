import { Box, Button, Grid, ToggleButton, ToggleButtonGroup, Stack, Typography, Paper } from '@mui/material';
import axios from 'axios';
import { apiString as API_URL } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../store/shared.slice';
import { RootState } from '../../reducers';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Loading from '../Shared/Loading';
import { setMemberDevices, setMemberVitalSigns, setTimePeriod, setVitalSign } from '../../store/vitalSignsMemberDetail.slice';
import { useNavigate, useParams } from 'react-router-dom';

const MemberVitalSigns = (props: any) => {
    const params = useParams();
    const navigate = useNavigate();
    const memberId = props.memberId || params.memberId;
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { memberDevices, memberVitalSigns, timePeriod, vitalSign } = useSelector((state: RootState) => state.vitalSignsMemberDetail);
    const dispatch = useDispatch();
    const [chartOptions, setChartOptions] = useState({});

    const devicesList = [
        { label: 'Glucometer', value: 1, device: 'Glucometer' },
        { label: 'Blood Pressure', value: 2, device: 'BpCuff' },
        { label: 'Oximeter', value: 3, device: 'PulseOX' },
        { label: 'Scale', value: 4, device: 'Scale' },
    ];

    const dateRanges = [
        { label: 'Week', value: 1 },
        { label: 'Month', value: 2 },
        { label: '3 Months', value: 3 },
    ];

    const vitalSignsComponent = (reading) => {
        const deviceName = devicesList.find((item) => item.value === vitalSign);
        let deviceReading = '';
        switch (vitalSign) {
            case 1:
                deviceReading = `${reading['GlucoseMgDl']} mg/dL`;
                break;
            case 2:
                deviceReading = reading['BpSystolic'] + '/' + reading['BpDiastolic'];
                break;
            case 3:
                deviceReading = `${reading['PulseOxSpO2Last']} %`;
                break;
            case 4:
                deviceReading = `${reading['Weight']} lbs`;
                break;
            case 5:
                deviceReading = `${reading['Strips']} strips`;
                break;
            default:
                deviceReading = '';
                break;
        }
        return (
            <Grid key={reading.Id} item xs={6}>
                <Paper elevation={3} sx={{ p: 2, m: 2, display: 'flex' }}>
                    <Box
                        borderRadius={100}
                        bgcolor={reading.Status === 2 ? 'red' : 'green'}
                        height={'90px'}
                        width={'90px'}
                        marginRight={5}
                        color={'white'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography variant="h6">{deviceReading}</Typography>
                    </Box>
                    <Stack alignItems={'flex-start'}>
                        <Typography>
                            Device: <b>{deviceName ? deviceName.label : ''}</b>
                        </Typography>
                        {vitalSign === 2 && (
                            <Typography>
                                Pulse: <b>{reading['BpPulse']}</b>
                            </Typography>
                        )}
                        <Typography>Reading Date & Time:</Typography>
                        <Typography>
                            <b>{!moment(reading['RecordingDate']).isValid ? 'N/A' : moment.utc(reading['RecordingDate']).format('MM/DD/YYYY @ hh:mm a')}</b>
                        </Typography>
                    </Stack>
                </Paper>
            </Grid>
        );
    };

    const configureChartOptions = (data: any[], deviceName: string, xTitle: string, yTitle: string) => {
        let deviceReading = [];
        switch (vitalSign) {
            case 1:
                deviceReading = [{ name: 'Glucose mg/dl', data: data.map((el) => parseInt(el.GlucoseMgDl)) }];
                break;
            case 2:
                deviceReading = [
                    { name: 'Diastolic', data: data.map((el) => parseInt(el.BpDiastolic)) },
                    { name: 'Systolic', data: data.map((el) => parseInt(el.BpSystolic)) },
                ];
                break;
            case 3:
                deviceReading = [{ name: 'PulseOx %', data: data.map((el) => parseInt(el.PulseOxSpO2Last)) }];
                break;
            case 4:
                deviceReading = [{ name: 'Weight lbs', data: data.map((el) => parseInt(el.Weight)) }];
                break;
            case 5:
                deviceReading = [{ name: 'Strips?', data: [] }];
                break;
            default:
                deviceReading = [{ name: '', data: [] }];
                break;
        }
        const chartOptions = {
            chart: {
                type: 'line',
            },
            title: {
                text: deviceName,
            },
            xAxis: {
                categories: data.map((el) => moment.utc(el.RecordingDate).format('L')),
                title: {
                    text: xTitle,
                },
            },
            yAxis: {
                title: {
                    text: yTitle,
                },
            },
            series: [...deviceReading],
        };
        return chartOptions;
    };

    const getVitalSigns = async (timePeriod: number, vitalSign: number, memberId: string) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(
                `${API_URL}/vitalsigns/getvitalsignsbymember?timePeriod=${timePeriod}&vitalSign=${vitalSign}&memberId=${memberId}`
            );
            const currentDevice = devicesList.find((el) => el.value === vitalSign);
            dispatch(setMemberVitalSigns(response.data));
            const chartOptions = configureChartOptions(response.data, currentDevice?.label, 'Recording Date', '');
            dispatch(setChartOptions(chartOptions));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
        }
    };

    const getMemberDevices = async (memberId: string) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/VitalSigns/getmemberdevices?memberId=${memberId}`);
            dispatch(setMemberDevices(response.data));
            if (response.data) {
                const firstDevice = devicesList.find((item) => response.data[item.device]?.HasDevice === true);
                if (firstDevice) {
                    dispatch(setVitalSign(firstDevice.value));
                }
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
        }
    };

    const changeCurrentDeviceHandler = (event: any, newValue: number) => {
        if (newValue !== null) {
            dispatch(setVitalSign(newValue));
        }
    };

    useEffect(() => {
        if (memberDevices) {
            getVitalSigns(timePeriod, vitalSign, memberId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timePeriod, vitalSign, memberId, memberDevices]);

    useEffect(() => {
        if (memberId) {
            getMemberDevices(memberId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId]);

    return (
        <>
            <Loading open={isLoading} message="Loading..." />
            <Grid container spacing={2} p={2}>
                <Grid item xs={12} marginY={2}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            // window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/member-profile`);
                            navigate(`/provider/members-list/member-chart/${memberId}/member-profile`);
                        }}
                    >
                        Click To Telemonitoring Tab
                    </Button>
                </Grid>

                {memberDevices ? (
                    <>
                        <Grid item xs={12} lg={6} textAlign={'center'} marginY={2}>
                            <Grid item xs={12}>
                                {memberDevices && (
                                    <ToggleButtonGroup exclusive fullWidth value={vitalSign} onChange={changeCurrentDeviceHandler}>
                                        {devicesList.map((item: any, index) => {
                                            return (
                                                <ToggleButton
                                                    key={index}
                                                    color="primary"
                                                    value={item.value}
                                                    sx={{ display: memberDevices[item.device]?.HasDevice ? 'block' : 'none' }}
                                                >
                                                    {item.label}
                                                </ToggleButton>
                                            );
                                        })}
                                    </ToggleButtonGroup>
                                )}
                            </Grid>
                            <Grid item xs={12} textAlign={'center'} marginY={2}>
                                <ToggleButtonGroup fullWidth value={timePeriod} exclusive onChange={(event, newValue) => dispatch(setTimePeriod(newValue))}>
                                    {dateRanges.map((item: any) => (
                                        <ToggleButton color="primary" key={item.value} value={item.value}>
                                            {item.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {memberVitalSigns.length > 0 ? (
                                        memberVitalSigns.map((el) => vitalSignsComponent(el))
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography marginTop={5}>No Data.</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {memberVitalSigns.length > 0 && (
                                <Grid item xs={12} border={2}>
                                    {<HighchartsReact highcharts={Highcharts} options={chartOptions} />}
                                </Grid>
                            )}
                        </Grid>
                    </>
                ) : (
                    <Grid item>
                        <Typography>No Telemonitoring Devices Found.</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default MemberVitalSigns;
