import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { localDateFormat } from '../../../utils/timeFormat';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import { fetchMemberStatusReport, exportMemberStatusReport } from '../../../store/memberStatusReport.slice';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fetchHealthPlans } from '../../../store/memberDetail.slice';
import { isDefaultDate } from '../../../utils/common';

const MemberStatusReport = () => {
    const { memberStatusReport } = useSelector((state: RootState) => state.memberStatusReport);
    const { healthPlans } = useSelector((state: RootState) => state.memberDetail);
    const { downLoading } = useSelector((state: RootState) => state.shared);

    let sDate = new Date();
    const [startDate, setStartDate] = useState(new Date(sDate.getFullYear(), sDate.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [planName, setPlanName] = useState('');

    const dispatch = useDispatch();

    const columns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'MemberId',
            headerName: 'MemberId',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 120,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'MonthsService',
            headerName: 'Months Of Service',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'DateOfOnboard',
            headerName: 'Date Of Onboard',
            minWidth: 120,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DateOfOnboard) ? '-' : localDateFormat(new Date(params.row.DateOfOnboard))}`,
        },
        {
            field: 'Status',
            headerName: 'Current Status',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'StatusChanges',
            headerName: 'Status Changes',
            minWidth: 180,
            flex: 1,
        },
    ];

    const [pageSize, setPageSize] = useState(15);
    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 72 : 74) + ''));
    };
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(fetchHealthPlans());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container>
            <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Member Status Report
                </Typography>
            </Box>
            {<Loading message={'Loading...'} />}
            <Grid item xs={12}>
                <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['month', 'year']}
                            label="Start Month"
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e: any) => {
                                setStartDate(new Date(e.getFullYear(), e.getMonth(), 1));
                            }}
                            value={startDate}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['month', 'year']}
                            label="End Month"
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e: any) => {
                                setEndDate(e);
                            }}
                            value={endDate}
                        />
                    </LocalizationProvider>
                    <FormControl style={{ width: '230px' }}>
                        <InputLabel id="demo-simple-select-label">Plan Name</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={planName}
                            label="Health Plan"
                            onChange={(e: any) => {
                                setPlanName(e.target.value);
                            }}
                        >
                            {healthPlans.map(function (hPlan) {
                                return (
                                    <MenuItem key={hPlan.Id} value={hPlan.Name}>
                                        {hPlan.Name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button style={{ minWidth: '120px' }} onClick={() => dispatch(fetchMemberStatusReport(startDate, endDate, planName))} variant="contained">
                        Search
                    </Button>
                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => dispatch(exportMemberStatusReport(startDate, endDate, planName))}
                        variant="contained"
                        color="success"
                    >
                        {downLoading ? 'Downloading..' : 'Export'}
                    </Button>
                </Stack>
            </Grid>
            <Grid item style={{ height: '82vh', width: '100%' }}>
                <DataGrid
                    rows={memberStatusReport}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.Id}
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MemberStatusReport;
