export default interface IMedicationAssignPeriods {
    MedicationNames: string[];
    Frequency: string;
    IsHrs: boolean;
    TimesGiven: number;
    Periods: IMedicationPeriod[];
}

export interface IMedicationPeriod {
    Hour: string;
    Minute: string;
    Meridiem: string;
}

export class MedicationAssignPeriods implements IMedicationAssignPeriods {
    MedicationNames: string[];
    Frequency: string;
    IsHrs: boolean;
    TimesGiven: number;
    Periods: IMedicationPeriod[];
    Schedules: string[];

    constructor() {
        this.MedicationNames = [] as string[];
        this.Frequency = '';
        this.IsHrs = false;
        this.TimesGiven = 0;
        this.Periods = [] as IMedicationPeriod[];
        this.Schedules = [] as string[];
    }
}
