import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';

interface IConfirmPastEventCreateModalProps extends IBasicModalProps {
    startDate: Date;
}

const ConfirmPastEventCreateModal = (props: IConfirmPastEventCreateModalProps) => {
    const { open, onClose, onSuccess, startDate = new Date() } = props;
    return (
        <Dialog open={open} sx={{ zIndex: 99999 }}>
            <DialogTitle>Past/Today Event Creation Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You are creating an event in the past or for today ({startDate.toLocaleString()}). Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" color="success" onClick={onSuccess}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmPastEventCreateModal;
