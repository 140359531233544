import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const engagementsVsEncounters = createSlice({
    name: 'engagementsVsEncounters',
    initialState: {
        startDate: new Date(),
        endDate: new Date(),
        providerId: '-1',
        rowData: [],
    },
    reducers: {
        _setStartDate(state, action) {
            state.startDate = action.payload;
        },
        _setEndDate(state, action) {
            state.endDate = action.payload;
        },
        _setProviderId(state, action) {
            state.providerId = action.payload;
        },
        _setRowData(state, action) {
            state.rowData = action.payload;
        },
    },
});

const { _setStartDate, _setEndDate, _setProviderId, _setRowData } = engagementsVsEncounters.actions;

export const setStartDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setStartDate(params));
    };

export const setEndDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setEndDate(params));
    };

export const setProviderId =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setProviderId(params));
    };

export const setRowData =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setRowData(params));
    };

export const fetchEngagementsVsEncountersReport =
    (providerId, startDate, endDate, showInActive): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/EngagementVsEncounterReport/getengagementvsencounterReport?providerId=${
                providerId !== '-1' ? providerId : ''
            }&startDate=${dateFormatter(startDate)}&endDate=${dateFormatter(endDate)}&includeInActiveProviders=${showInActive}`
        );
        dispatch(setRowData(response.data));
        dispatch(setIsLoading(false));
    };

export const exportEngagementsVsEncountersReport =
    (providerId, startDate, endDate, showInActive): AppThunk =>
    async (dispatch) => {
        if (providerId === '-1') {
            providerId = '';
        }
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/EngagementVsEncounterReport/exportengagementvsencounterreport?providerId=${
                providerId !== '-1' ? providerId : ''
            }&startDate=${dateFormatter(startDate)}&endDate=${dateFormatter(endDate)}&includeInActiveProviders=${showInActive}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default engagementsVsEncounters.reducer;
