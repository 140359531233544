import { Button, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    setOpenAddPopulation,
    setCurrentPopulation,
    setContactInfoTitle,
    setCurrentContact,
    updatePopulation,
    deletePopulationContactHandler,
    setAlertContactVisibility,
    savePopulationContactHandler,
} from '../../../store/adminHealthPlan.slice';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import Loading from '../../Shared/Loading';
import BasicContactInfoDialog from '../BasicContactInfoDialog';
import phoneFormat from '../../../utils/phoneFormat';
import ConfirmDelete from '../../Shared/ConfirmDelete';

const DataValidation = (props) => {
    const { alertContactVisibility, contactInfoTitle, currentContact, currentPopulation, currentHealthPlan } = useSelector(
        (state: RootState) => state.adminHealthPlanSlice
    );
    const { setFile } = props;
    const [alertContactInfo, setAlertContactInfo] = useState({ Name: '', Id: '' });
    const dispatch = useDispatch();
    const getDefaultContact = () => {
        return {
            Address: '',
            Email: '',
            Id: '',
            Name: '',
            PhoneNumber: '',
        };
    };
    const [deleteVis, setDeleteVis] = useState(false);
    const addNewContactHandler = () => {
        dispatch(setContactInfoTitle('Population Alert Contact'));
        dispatch(setCurrentContact(getDefaultContact()));
        dispatch(setAlertContactVisibility(true));
    };
    const editContactHandler = (params) => {
        dispatch(setCurrentContact(params.row));
        dispatch(setContactInfoTitle(params.row.Name));
        dispatch(setAlertContactVisibility(true));
    };

    const openDeleteAlertHandler = (params) => {
        setAlertContactInfo(params);
        setDeleteVis(true);
    };

    const closeDeleteAlertHandler = () => {
        setDeleteVis(false);
    };

    const alertFrequencyList = [5, 10, 15, 30, 60];
    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 1,
        },
        {
            field: 'PhoneNumber',
            headerName: 'Phone Number',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${phoneFormat(params.row.PhoneNumber)}`,
        },
        {
            field: 'Email',
            headerName: 'Email',
            flex: 1.5,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit Contact" placement="bottom">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => editContactHandler(params)} />
                        </Tooltip>
                        <Tooltip title="Delete Contact" placement="top">
                            <CancelIcon color="error" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => openDeleteAlertHandler(params.row)} />
                        </Tooltip>
                    </>
                );
            },
        },
    ];
    return (
        <>
            <Loading message="Loading..." />
            <BasicContactInfoDialog
                currentContact={currentContact}
                open={alertContactVisibility}
                onClose={() => dispatch(setAlertContactVisibility(false))}
                onSave={() => dispatch(savePopulationContactHandler(currentContact, currentPopulation))}
                contactInfoTitle={contactInfoTitle}
            />

            <ConfirmDelete
                open={deleteVis}
                onClose={closeDeleteAlertHandler}
                name={alertContactInfo.Name}
                delete={() => {
                    dispatch(deletePopulationContactHandler(alertContactInfo, currentPopulation, currentHealthPlan));
                    setDeleteVis(false);
                }}
                cancel={closeDeleteAlertHandler}
            />

            <DialogContent>
                <FormControl fullWidth>
                    <Grid spacing={2} display="flex" alignItems="flex-start" container p={2} sx={{ height: '65vh' }}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="plan-type-label">Missing Data Alert Frequency</InputLabel>
                                <Select
                                    labelId="plan-type-label"
                                    id="plan-type"
                                    value={currentPopulation.FileNotReceivedInDays || ''}
                                    margin="dense"
                                    label="Missing Data Alert Frequency"
                                    name="FileNotReceivedInDays"
                                    onChange={(e) => dispatch(setCurrentPopulation({ ...currentPopulation, FileNotReceivedInDays: e.target.value }))}
                                >
                                    <MenuItem value={0}>Select</MenuItem>
                                    {alertFrequencyList.map((alert, index) => (
                                        <MenuItem key={index} value={index + 1}>
                                            {alert} days
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} textAlign="center" marginX={'auto'}>
                            <Typography variant="body1">
                                When the data first name, last name, dob, gender, ahcccs id, etc... are missing for any members, then the alerts will be send to
                                the below contacts.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                            <Typography display={'inline-flex'} variant="h5">
                                Alert Contacts
                            </Typography>

                            <Button variant="contained" onClick={addNewContactHandler}>
                                Add Alert Contact
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {currentPopulation.AlertContacts?.length > 0 && (
                                <div style={{ width: '100%' }}>
                                    <DataGrid
                                        rows={currentPopulation.AlertContacts || []}
                                        columns={columns}
                                        rowsPerPageOptions={[5]}
                                        getRowId={(row) => row.Id}
                                        autoHeight
                                        sx={{
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1,
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ width: '100%' }}>
                <Button
                    className="button-120"
                    variant="outlined"
                    onClick={() => {
                        dispatch(setOpenAddPopulation(false));
                        setFile(null);
                    }}
                >
                    Close
                </Button>

                <Button
                    className="button-120"
                    variant="contained"
                    onClick={() => {
                        dispatch(updatePopulation(currentPopulation, currentHealthPlan.Id));
                        dispatch(setOpenAddPopulation(false));
                        setFile(null);
                    }}
                >
                    Finish
                </Button>
            </DialogActions>
        </>
    );
};

export default DataValidation;
