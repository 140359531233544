// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { Typography } from '@mui/material';
import blackPin from '../../assets/images/black_pin.png';

const SchedulerMap = () => {
    const API_KEY = 'AIzaSyD0ZVhNqOlGF37mVlyQX2DZUbnvDaLROus';
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [showIndex, setShowIndex] = useState(0);

    const [defaultCenter, setDefaultCenter] = useState({
        lat: 33.302947,
        lng: -111.803276,
    });
    const { appointmentMaps } = useSelector((state: RootState) => state.appointmentScheduler);

    const mapStyles = {
        height: appointmentMaps.AppointmentsNoAddress.length > 0 ? 'calc(100vh - 295px)' : 'calc(100vh - 250px)',
        width: '100%',
    };

    useEffect(() => {
        if (appointmentMaps.Appointments && appointmentMaps.Appointments.length > 0) {
            var first = appointmentMaps.Appointments[0];
            if (first.Location) {
                setDefaultCenter({ lat: first.Location.lat, lng: first.Location.lng });
            }
        }
    }, [appointmentMaps]);

    const mouseOver = (show: boolean, index: number) => {
        setShowIndex(index);
        setShowInfoWindow(show);
    };

    return (
        // @ts-ignore
        <LoadScript googleMapsApiKey={API_KEY}>
            <GoogleMap mapContainerStyle={mapStyles} zoom={11} center={defaultCenter}>
                {appointmentMaps.Appointments?.map((apptMap, index) => {
                    return (
                        // @ts-ignore
                        <Marker
                            onMouseOver={() => {
                                mouseOver(true, index);
                            }}
                            onMouseOut={() => {
                                mouseOver(false, index);
                            }}
                            zIndex={index}
                            opacity={0.8}
                            label={{ text: (index + 1).toString(), color: 'white', fontWeight: 'bold' }}
                            key={apptMap.id}
                            position={apptMap.Location}
                        >
                            {
                                // @ts-ignore
                                showInfoWindow && showIndex === index ? (
                                    <InfoWindow>
                                        <>
                                            <Typography style={{ fontWeight: 'bold' }}>{'(' + (index + 1) + ') ' + apptMap.MemberName}</Typography>
                                            <hr />
                                            <Typography>{apptMap.address}</Typography>
                                            <br />
                                            <Typography>{apptMap.reason}</Typography>
                                        </>
                                    </InfoWindow>
                                ) : null
                            }
                        </Marker>
                    );
                })}
                <Marker
                    onMouseOver={() => {
                        mouseOver(true, -1);
                    }}
                    onMouseOut={() => {
                        mouseOver(false, -1);
                    }}
                    zIndex={-1}
                    opacity={0.8}
                    label={{ text: 'MA', color: 'white', fontWeight: 'bold' }}
                    key={'providerHome'}
                    style={{ color: 'purple' }}
                    position={appointmentMaps.MemberHomeLocation}
                    icon={blackPin}
                >
                    {
                        // @ts-ignore
                        showInfoWindow && showIndex === -1 ? (
                            <InfoWindow>
                                <>
                                    <Typography style={{ fontWeight: 'bold' }}>{'(MA) Member Address'}</Typography>
                                </>
                            </InfoWindow>
                        ) : null
                    }
                </Marker>
            </GoogleMap>
        </LoadScript>
    );
};

export default SchedulerMap;
