import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, CircularProgress, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { RichTextEditor } from '@mantine/rte';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { gotoPreviousMenu } from '../../../store/assessment.slice';
import { copyObject } from '../../../utils/common';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { EngagementDraft } from '../../../Models/DocumentEngagements/EngagementDraft.model';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { SummaryNote } from '../../../Models/DocumentEngagements/SummaryNote.model';

const classes = {
    title: {
        color: '#0063a7',
        marginTop: '10px',
    },
    richTextBox: {
        '& .ql-editor': {
            minHeight: '250px',
        },
    },
};

const ProgressNotes: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const { engagementDraft, isSaving } = useSelector((state: RootState) => state.documentEngagement);
    const [progressNotes, onChangeProgressNotes] = useState(engagementDraft.ProgressNotes || '');
    const [followupRecommendations, onChangeFollowupRecommendations] = useState(engagementDraft.FollowupRecommendations || '');

    const handleOnBlur = () => {
        let engagementDraftData = copyObject(engagementDraft);
        engagementDraftData.ProgressNotes = progressNotes;
        engagementDraftData.FollowupRecommendations = followupRecommendations;
        dispatch(saveEngagementDraft(engagementDraftData));
    };

    const handleOnClickNext = () => {
        let engagementDraftData = copyObject(engagementDraft);
        engagementDraftData.ProgressNoteStatus = StatusEnum.Completed;
        engagementDraftData.SummaryNotes = prepareSummaryNotes(engagementDraftData);
        dispatch(saveEngagementDraft(engagementDraftData, true));
    };

    const prepareSummaryNotes = (data: EngagementDraft) => {
        let summaryNotesData = data.SummaryNotes.filter((s) => s.Type !== SummaryNoteTypeEnum.ProgressNote);
        if (Boolean(progressNotes)) {
            let summaryNotes = [];
            var domParser = new DOMParser();
            var htmlString = domParser.parseFromString(progressNotes, 'text/html');
            var paragraphHtmlTags = htmlString.getElementsByTagName('p');
            var paragraphList = Array.prototype.slice.call(paragraphHtmlTags);
            paragraphList.forEach((html) => {
                if (Boolean(html.children.length)) {
                    var childrens = Array.prototype.slice.call(html.children);
                    childrens.forEach((childHtml) => {
                        if (Boolean(childHtml?.innerText)) {
                            summaryNotes.push(childHtml.innerText);
                        }
                    });
                }
            });

            let notes = '';
            summaryNotes.forEach((note) => {
                if (!Boolean(notes)) notes = `<p> ${note} </p>`;
                else notes += `<p> ${note} </p>`;
            });
            if (notes) {
                const summaryNote = {
                    Id: '',
                    CptCode: '',
                    Zcode: '',
                    Note: notes,
                    Type: SummaryNoteTypeEnum.ProgressNote,
                    IsConfirm: false,
                    OrderBy: 26,
                    DisplayName: 'Progress Note',
                } as SummaryNote;
                summaryNotesData.push(summaryNote);
            }
        }
        return summaryNotesData;
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            <Grid item className="content-form">
                <Typography variant="h6" sx={classes.title}>
                    Progress Notes
                </Typography>
                <RichTextEditor
                    sx={classes.richTextBox}
                    value={progressNotes}
                    onChange={(value) => onChangeProgressNotes(value)}
                    onBlur={() => handleOnBlur()}
                    formats={['bold', 'italic', 'underline']}
                    controls={[['bold', 'italic', 'underline']]}
                />
                <Typography variant="h6" sx={classes.title}>
                    Follow Up / Recommendations
                </Typography>
                <RichTextEditor
                    sx={classes.richTextBox}
                    value={followupRecommendations}
                    onChange={(value) => onChangeFollowupRecommendations(value)}
                    onBlur={() => handleOnBlur()}
                    formats={['bold', 'italic', 'underline']}
                    controls={[['bold', 'italic', 'underline']]}
                />
            </Grid>
            <Grid item justifyContent="flex-end" className="content-footer">
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ mr: 3 }}
                    disabled={isSaving}
                    onClick={() => dispatch(gotoPreviousMenu())}
                    startIcon={<ArrowBackIosIcon />}
                >
                    Previous
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                    onClick={handleOnClickNext}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export default ProgressNotes;
