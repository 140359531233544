import React, { useEffect, useState, useCallback } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Button,
    Box,
    CircularProgress,
    InputAdornment,
    FormHelperText,
    Typography,
    Grid,
    TextField,
    Checkbox,
    ImageList,
    ImageListItem,
    Stack,
} from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { createTheme } from '@mui/material/styles';
import * as Yup from 'yup';

import { RootState } from '../../../reducers';
import { MembersVitalSigns } from '../../../Models/VitalSigns/MembersVitalSigns.model';
import Height from '../../../Models/VitalSigns/Height.model';
import { fetchMemberVitalSign, gotoPreviousMenu, saveMemberVitalsign, updateMemberVitalSign } from '../../../store/assessment.slice';

import bad from './../../../assets/images/1F61F.svg';
import verygood from './../../../assets/images/1F601.svg';
import ok from './../../../assets/images/1F610.svg';
import good from './../../../assets/images/1F642.svg';
import verybad from './../../../assets/images/1F912.svg';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { preventTextInput, preventNumberInputMaxValue } from '../../../utils/common';
import { useParams } from 'react-router-dom';

const emojisList = [
    {
        emoji: verybad,
        title: 'Very Bad',
    },
    {
        emoji: bad,
        title: 'Bad',
    },
    {
        emoji: ok,
        title: 'Ok',
    },
    {
        emoji: good,
        title: 'Good',
    },
    {
        emoji: verygood,
        title: 'Very Good',
    },
];

const theme = createTheme();
const classes = {
    form: {
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    root: {
        backgroundColor: '#f5f5f5',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '15ch',
        },
        '& .MuiInputBase-root.MuiInputBase-formControl': {
            backgroundColor: 'white',
        },
        '& legend.jss4': {
            marginBottom: '0 !important',
            minHeight: 'auto !important',
        },
    },
    imageList: {
        flexWrap: 'nowrap',
        width: '350px',
    },
};

const vitalSignSchema = Yup.object().shape({
    BpPulse: Yup.string().when('RefusedForAssessment', {
        is: false,
        then: Yup.string().nullable().required('HR is required.'),
    }),
    BpSystolic: Yup.string().when('RefusedForAssessment', {
        is: false,
        then: Yup.string().nullable().required('HR is required.'),
    }),
    BpDiastolic: Yup.string().when('RefusedForAssessment', {
        is: false,
        then: Yup.string().nullable().required('HR is required.'),
    }),
});

const VitalSigns: React.FC<{ id?: string; memberId: string }> = ({ id, memberId }) => {
    const params = useParams();
    memberId = memberId || params.memberId;
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { vitalSign, fetchingVitalSign, isSaving } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    useEffect(() => {
        if (engagementDraft?.VitalsignAssessmentId) {
            dispatch(fetchMemberVitalSign(engagementDraft.VitalsignAssessmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [initialInfo, setInitialInfo] = useState<MembersVitalSigns>({
        Id: '',
        MemberId: '',
        Height: {
            Feet: null,
            Inch: null,
        },
        Weight: '',
        BmiValuation: '',
        BMI: '',
        BpPulse: '',
        BpSystolic: '',
        BpDiastolic: '',
        PulseOxSpO2Last: '',
        GlucoseMgDl: '',
        RespirationRate: '',
        RefusedForAssessment: false,
        SpokeWithFamily: '',
        SpokeWithFriends: '',
        HealthEvaluation: '',
        IsAssessmentCompleted: false,
        AssessmentStatus: null,
        VitalSignResultStatus: null,
    });

    const handleSubmit = async () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = async (vitalSignInfo: MembersVitalSigns, isWithoutComplete = false) => {
        let data = new MembersVitalSigns();
        data.MemberId = memberId;
        data.Height = new Height();
        data.Height.Feet = vitalSignInfo.Height?.Feet ?? Number(vitalSignInfo.Height.Feet);
        data.Height.Inch = vitalSignInfo.Height?.Inch ?? Number(vitalSignInfo.Height.Inch);
        data.Weight = vitalSignInfo.Weight;
        data.BmiValuation = vitalSignInfo.BmiValuation;
        data.BpPulse = String(vitalSignInfo.BpPulse);
        data.BpSystolic = String(vitalSignInfo.BpSystolic);
        data.BpDiastolic = String(vitalSignInfo.BpDiastolic);
        data.PulseOxSpO2Last = vitalSignInfo.PulseOxSpO2Last;
        data.GlucoseMgDl = vitalSignInfo.GlucoseMgDl;
        data.RespirationRate = vitalSignInfo.RespirationRate;
        data.RefusedForAssessment = vitalSignInfo.RefusedForAssessment;
        data.SpokeWithFamily = vitalSignInfo.SpokeWithFamily;
        data.SpokeWithFriends = vitalSignInfo.SpokeWithFriends;
        data.HealthEvaluation = vitalSignInfo.HealthEvaluation;
        data.IsAssessmentCompleted = vitalSignInfo.IsAssessmentCompleted;
        data.AssessmentStatus = vitalSignInfo.AssessmentStatus;
        if (isWithoutComplete) {
            data.AssessmentStatus = StatusEnum.Completed;
        }
        if (Boolean(vitalSignInfo.BMI)) {
            data.BMI = vitalSignInfo.BMI;
        } else {
            delete data.BMI;
        }

        if (vitalSignInfo.Id) {
            data.Id = vitalSignInfo.Id;
            dispatch(updateMemberVitalSign(data, isWithoutComplete));
        } else {
            dispatch(saveMemberVitalsign(data, isWithoutComplete));
        }
    };

    const initialPage = useCallback(async () => {
        if (id) {
            dispatch(fetchMemberVitalSign(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (vitalSign && vitalSign.Id) {
            setInitialInfo(vitalSign);
        }
    }, [vitalSign]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    // Calculate bmi
    const calculateBMI = async (feet: number, inch: number, weight: string, setFieldValue: Function) => {
        let bmi = '';
        if (feet && weight) {
            // 1 ft is 12 in
            let totHeightInInch = feet * 12 + inch;
            // Formula: 703 x weight(lbs) / [height(in)]2
            let heightSquare = totHeightInInch * totHeightInInch;
            bmi = ((703 * parseInt(weight)) / heightSquare).toFixed(2);
        } else {
            bmi = '';
        }
        setFieldValue('Height.Feet', feet);
        setFieldValue('Height.Inch', inch);
        setFieldValue('Weight', weight);
        setFieldValue('BMI', bmi);
    };

    return (
        <Formik
            initialValues={initialInfo}
            onSubmit={handleSubmit}
            validationSchema={vitalSignSchema}
            enableReinitialize={true}
            validate={() => setIsChanged(true)}
        >
            {({ values, setFieldValue, errors, handleChange, handleBlur, isValid }: FormikProps<MembersVitalSigns>) => (
                <Form>
                    <Grid container direction="column" spacing={2} sx={classes.form} className="content-panel">
                        <Grid item lg={12} md={12} sm={12}>
                            <Typography variant="h6" component="label">
                                Vital Signs
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item className="content-form" marginTop={2}>
                            {fetchingVitalSign ? (
                                <Box display="flex" justifyContent="center" className="loader-container">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} sx={classes.root}>
                                        <Grid item lg={4} md={4} sm={4}>
                                            <Stack>
                                                <FormLabel sx={{ ml: 2, mb: 0 }}>Height</FormLabel>
                                                <Stack direction="row" spacing={1}>
                                                    <TextField
                                                        type="number"
                                                        label="ft"
                                                        name="Height.Feet"
                                                        variant="outlined"
                                                        size="small"
                                                        value={values.Height?.Feet}
                                                        onChange={(event) =>
                                                            calculateBMI(
                                                                parseFloat(event.currentTarget.value),
                                                                values.Height?.Inch,
                                                                values.Weight,
                                                                setFieldValue
                                                            )
                                                        }
                                                        inputProps={{ min: 0, max: 9 }}
                                                        onKeyPress={(e) => {
                                                            preventTextInput(e);
                                                            preventNumberInputMaxValue(e, 9);
                                                        }}
                                                    />
                                                    <TextField
                                                        type="number"
                                                        label="in"
                                                        name="Height.Inch"
                                                        variant="outlined"
                                                        size="small"
                                                        value={values.Height?.Inch}
                                                        onChange={(event) =>
                                                            calculateBMI(
                                                                values.Height?.Feet,
                                                                parseFloat(event.currentTarget.value),
                                                                values.Weight,
                                                                setFieldValue
                                                            )
                                                        }
                                                        inputProps={{ min: 0, max: 11 }}
                                                        onKeyPress={(e) => {
                                                            preventTextInput(e);
                                                            preventNumberInputMaxValue(e, 11);
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item lg={2} md={2} sm={2}>
                                            <Stack>
                                                <FormLabel sx={{ ml: 2, mb: 0 }}>Weight</FormLabel>
                                                <TextField
                                                    type="number"
                                                    label="lbs"
                                                    name="Weight"
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.Weight}
                                                    onKeyPress={preventTextInput}
                                                    inputProps={{ min: 0 }}
                                                    onChange={(event) =>
                                                        calculateBMI(values.Height?.Feet, values.Height?.Inch, event.currentTarget.value, setFieldValue)
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3}>
                                            <FormControl className="mt-10">
                                                <RadioGroup row name="BmiValuation" value={values.BmiValuation} onChange={handleChange}>
                                                    <FormControlLabel value="Self-reported" control={<Radio />} label="Self-reported" />
                                                    <FormControlLabel value="Actual" control={<Radio />} label="Actual" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    disabled
                                                    type="number"
                                                    fullWidth
                                                    label="BMI"
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.BMI}
                                                    name="BMI"
                                                    sx={{ mb: 0 }}
                                                    onKeyPress={preventTextInput}
                                                    inputProps={{ min: 0 }}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    }}
                                                />
                                                <FormHelperText>auto calculated</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={2} md={4} sm={4}>
                                            <FormLabel sx={{ ml: 2, mb: 0 }} required>
                                                Heart Rate
                                            </FormLabel>
                                            <TextField
                                                type="number"
                                                label="BpPulse"
                                                variant="outlined"
                                                size="small"
                                                name="BpPulse"
                                                value={values.BpPulse}
                                                error={Boolean(errors.BpPulse)}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                onKeyPress={preventTextInput}
                                                inputProps={{ min: 0 }}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={12}>
                                            <Stack>
                                                <FormLabel sx={{ ml: 2, mb: 0 }} required>
                                                    Blood Pressure
                                                </FormLabel>
                                                <Stack direction={'row'}>
                                                    <TextField
                                                        type="number"
                                                        label="BpSystolic"
                                                        variant="outlined"
                                                        size="small"
                                                        name="BpSystolic"
                                                        value={values.BpSystolic}
                                                        error={Boolean(errors.BpSystolic)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        onKeyPress={preventTextInput}
                                                        inputProps={{ min: 0 }}
                                                    />
                                                    <FormLabel className="mt-15">/</FormLabel>
                                                    <TextField
                                                        type="number"
                                                        label="BpDiastolic"
                                                        variant="outlined"
                                                        size="small"
                                                        name="BpDiastolic"
                                                        value={values.BpDiastolic}
                                                        error={Boolean(errors.BpDiastolic)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        onKeyPress={preventTextInput}
                                                        inputProps={{ min: 0 }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={4}>
                                            <Stack>
                                                <FormLabel sx={{ ml: 2, mb: 0 }}>Pulse Oximeter</FormLabel>

                                                <TextField
                                                    label="spo2"
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.PulseOxSpO2Last}
                                                    name="PulseOxSpO2Last"
                                                    onChange={handleChange}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item lg={2} md={4} sm={6}>
                                            <FormLabel sx={{ ml: 2, mb: 0 }}>Glucose</FormLabel>

                                            <TextField
                                                type="number"
                                                label="mg/dl"
                                                variant="outlined"
                                                size="small"
                                                value={values.GlucoseMgDl}
                                                name="GlucoseMgDl"
                                                inputProps={{ min: 0 }}
                                                onKeyPress={preventTextInput}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={6}>
                                            <Stack>
                                                <FormLabel sx={{ ml: 2, mb: 0 }}>Respiration</FormLabel>

                                                <TextField
                                                    type="number"
                                                    label="rate"
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.RespirationRate}
                                                    name="RespirationRate"
                                                    inputProps={{ min: 0 }}
                                                    onKeyPress={preventTextInput}
                                                    onChange={handleChange}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <FormControlLabel
                                                sx={{ pl: 3 }}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={values.RefusedForAssessment}
                                                        onChange={(e) => setFieldValue('RefusedForAssessment', e.currentTarget.checked)}
                                                    />
                                                }
                                                label="Member refuses or is unable to conduct this assessment."
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} className="mt-10">
                                        <Grid item lg={12} md={12} sm={12}>
                                            <FormControl>
                                                <FormLabel>Did you see or speak with any family member this week?</FormLabel>
                                                <RadioGroup row name="SpokeWithFamily" value={values.SpokeWithFamily} onChange={handleChange}>
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                    <FormControlLabel value="Refuses to Answer" control={<Radio />} label="Refuses to Answer" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <FormControl>
                                                <FormLabel>Did you see or speak with any friend this week?</FormLabel>
                                                <RadioGroup row name="SpokeWithFriends" value={values.SpokeWithFriends} onChange={handleChange}>
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                    <FormControlLabel value="Refuses to Answer" control={<Radio />} label="Refuses to Answer" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <FormControl>
                                                <FormLabel>How would you evaluate your health this week?</FormLabel>
                                                <ImageList cols={5} rowHeight={70} sx={classes.imageList}>
                                                    {emojisList.map((item) => (
                                                        <ImageListItem key={item.emoji} onClick={(event) => setFieldValue('HealthEvaluation', item.title)}>
                                                            <img
                                                                src={item.emoji}
                                                                alt={item.title}
                                                                className={values.HealthEvaluation === item.title ? 'emoji' : ''}
                                                            />
                                                        </ImageListItem>
                                                    ))}
                                                </ImageList>
                                                <RadioGroup row name="HealthEvaluation" value={values.HealthEvaluation} onChange={handleChange}>
                                                    <FormControlLabel value="Refuses to Answer" control={<Radio />} label="Refuses to Answer" />
                                                    <FormControlLabel value="Didn’t Ask" control={<Radio />} label="Didn’t Ask" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item className="content-footer" width={'100%'}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ mr: 3 }}
                                disabled={isSaving}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSaving}
                                endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                    {Boolean(engagementDraft.VitalsignAssessmentId) || isChanged ? (
                        <AlertDialog
                            open={showSaveAlert}
                            title="Vital Signs"
                            cancelText="Go Back"
                            okText="Confirm"
                            onConfirm={() => handleOnConfirmToNext(values, true)}
                            onSkip={() => handleOnConfirmToNext(values)}
                            showBottomCenter={true}
                            onClose={() => setShowSaveAlert(false)}
                            isLoading={isSaving}
                            message="I confirm and acknowledge that Vital Signs is completed."
                        />
                    ) : (
                        <AlertDialog
                            open={showSaveAlert}
                            title="Vital Signs"
                            cancelText="No"
                            okText="Yes, Proceed"
                            onConfirm={() => handleOnConfirmToNext(values)}
                            onClose={() => setShowSaveAlert(false)}
                            isLoading={isSaving}
                            showBottomCenter={true}
                            message="Are you sure to proceed without completing Vital Signs?"
                        />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default VitalSigns;
