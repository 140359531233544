import { useState, useEffect } from 'react';
import {  Grid, Stack, Typography, Step, StepLabel, Stepper } from '@mui/material';
import { setActiveStep, setOpenAddMember, setSteps, setUpdatingMember } from '../../store/fuhfum.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import SampleData from './SampleData';
import ChooseData from './ChooseData';
import ImportData from './ImportData';

const FuhFum = () => {
    const dispatch = useDispatch();
    const { activeStep, steps } = useSelector((state: RootState) => state.fuhfum);
    const [file, setFile] = useState<File>();

    const addSteps = [
        { label: 'Sample Data', value: 0 },
        { label: 'Choose Data', value: 1 },
        { label: 'Import Data', value: 2 },
    ];

    useEffect(() => {
        dispatch(setActiveStep(0));
        dispatch(setUpdatingMember(false));
        dispatch(setSteps(addSteps));
        dispatch(setOpenAddMember(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container paddingBottom={0}>
            <Grid item xs={12}>
                <Stack spacing={2} alignItems={'center'} paddingY={2}>
                    <Typography variant="h4" component="h2">
                        Upload FUHFUM Members
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2}>
                    <Stepper alternativeLabel activeStep={activeStep}>
                        {steps.map((step) => {
                            return (
                                <Step key={step.value}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2}>
                    {activeStep === 0 ? (
                        <SampleData />
                    ) : activeStep === 1 ? (
                        <ChooseData file={file} setFile={setFile} />
                    ) : activeStep === 2 ? (
                        <ImportData file={file} setFile={setFile} />
                    ) : (
                        <></>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default FuhFum;
