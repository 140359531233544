import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { IRequestQuestionAnswer } from '../components/Requests/interfaces';
import { IRequest } from '../components/Requests/interfaces/IRequest';
import { IRequestOrderByOption } from '../components/Requests/interfaces/IRequestOrderByOption';
import { IRequestQuestionType } from '../components/Requests/interfaces/IRequestQuestionType';
import { IRequestSortByOption } from '../components/Requests/interfaces/IRequestSortByOption';
import { requestOrderByOptions } from '../components/Requests/Options/RequestOrderByOptions';
import { requestSortByOptions } from '../components/Requests/Options/RequestSortByOptions';
import { apiString } from '../utils/constants';
import { AppThunk } from './store';

interface IRequestSliceState {
    requests: IRequest[];
    selectedRequest: IRequest;
    newRequest: Partial<IRequest>;
    requestTypeOptions: { value: string; label: string }[];
    savingComment: boolean;
    isBackdropOpen: boolean;
    isFetchingRequestsList: boolean;
    selectedRequestId: string;
    selectedMemberId: string;
    linkedRequestId: string;
    requestsSearchString: string;
    sortByOption: IRequestSortByOption;
    orderByOption: IRequestOrderByOption;
    isShowingCompletedRequests: boolean;
    showRequestResolutionModal: boolean;
    selectedRequestTypeId: string;
    showAddEngagementNoteModal: boolean;
    showForwardingModal: boolean;
}

const initialState: IRequestSliceState = {
    requests: [],
    selectedRequest: undefined,
    newRequest: undefined,
    savingComment: false,
    isBackdropOpen: false,
    isFetchingRequestsList: false,
    selectedRequestId: '',
    selectedMemberId: '',
    linkedRequestId: '',
    requestsSearchString: '',
    sortByOption: requestSortByOptions[0],
    orderByOption: requestOrderByOptions[0],
    isShowingCompletedRequests: false,
    requestTypeOptions: [],
    selectedRequestTypeId: '',
    showRequestResolutionModal: false,
    showAddEngagementNoteModal: false,
    showForwardingModal: false,
};

const requestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {
        _setShowForwardingModal(state, action) {
            state.showForwardingModal = action.payload;
        },
        _setShowAddEngagementNoteModal(state, action) {
            state.showAddEngagementNoteModal = action.payload;
        },
        _setIsShowingCompletedRequests(state, action) {
            state.isShowingCompletedRequests = action.payload;
        },
        _setNewRequest(state, action) {
            state.newRequest = action.payload;
        },
        _setSelectedRequest(state, action) {
            state.selectedRequest = action.payload;
        },
        _setRequests(state, action) {
            state.requests = action.payload;
        },
        _setRequestTypeOptions(state, action) {
            state.requestTypeOptions = action.payload;
        },
        _setIsBackdropOpen(state, action) {
            state.isBackdropOpen = action.payload;
        },
        _setSavingComment(state, action) {
            state.savingComment = action.payload;
        },
        _setIsFetchingRequestsList(state, action) {
            state.isFetchingRequestsList = action.payload;
        },
        _setSelectedRequestId(state, action) {
            state.selectedRequestId = action.payload;
        },
        _setLinkedRequestId(state, action) {
            state.linkedRequestId = action.payload;
        },
        _setSelectedMemberId(state, action) {
            state.selectedMemberId = action.payload;
        },
        _setRequestsSearchString(state, action) {
            state.requestsSearchString = action.payload;
        },
        _setSortByOption(state, action) {
            state.sortByOption = action.payload;
        },
        _setOrderByOption(state, action) {
            state.orderByOption = action.payload;
        },
        _setRequestResolutionModalOpen(state, action) {
            state.showRequestResolutionModal = action.payload;
        },

        _setSelectedRequestTypeId(state, action) {
            state.selectedRequestTypeId = action.payload;
        },
    },
});

export const REQUEST_API = `${apiString}/request` as const;

const {
    _setShowForwardingModal,
    _setShowAddEngagementNoteModal,
    _setIsShowingCompletedRequests,
    _setSelectedRequest,
    _setIsBackdropOpen,
    _setIsFetchingRequestsList,
    _setSavingComment,
    _setRequests,
    _setRequestsSearchString,
    _setSelectedRequestId,
    _setSortByOption,
    _setSelectedMemberId,
    _setOrderByOption,
    _setRequestResolutionModalOpen,
    _setRequestTypeOptions,
    _setSelectedRequestTypeId,
    _setNewRequest,
    _setLinkedRequestId,
} = requestsSlice.actions;

export const setShowAddEngagementNoteModal =
    (open: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setShowAddEngagementNoteModal(open));
    };

export const setIsShowingCompletedRequests =
    (isShowing: boolean, unselect: boolean = true): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsShowingCompletedRequests(isShowing));
        if (unselect) {
            dispatch(setSelectedRequestId(null));
        }
    };

export const setSelectedRequest =
    (newRequest: IRequest | null): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedRequest(newRequest));
    };
export const setNewRequest =
    (newRequest: Partial<IRequest>): AppThunk =>
    async (dispatch) => {
        dispatch(_setNewRequest(newRequest));
    };
export const setSelectedRequestTypeId =
    (id: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedRequestTypeId(id));
    };

export const setRequestTypeOptions =
    (requestTypes: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setRequestTypeOptions(requestTypes));
    };
export const setIsBackdropOpen =
    (isBackdropOpen: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsBackdropOpen(isBackdropOpen));
    };
export const setIsFetchingRequestsList =
    (isFetching: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsFetchingRequestsList(isFetching));
    };
export const setSavingComment =
    (saving: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setSavingComment(saving));
    };
export const setRequests =
    (requests?: IRequest[], selected?: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { sortByOption, orderByOption, requests: oldRequests } = getState().requests;
        const newRequests = (requests?.length !== undefined ? requests : [...oldRequests]).sort((a, b) => sortByOption.compareRequests(a, b, orderByOption));

        dispatch(_setRequests(newRequests));

        if (selected) {
            dispatch(setSelectedRequestId(newRequests[0]?.Id));
        }
    };
export const setSelectedIndex =
    (index: number): AppThunk =>
    async (dispatch, getState) => {
        const { requests } = getState().requests;
        dispatch(_setSelectedRequest(requests[index]));
    };
export const setSelectedRequestId =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        const index = getState().requests.requests.findIndex((request) => request.Id === id);
        dispatch(_setSelectedRequestId(id));
        dispatch(setSelectedIndex(index));
    };
export const setSelectedMemberId =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedMemberId(memberId));
    };
export const setRequestsSearch =
    (search: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setRequestsSearchString(search));
    };
export const setSortByOption =
    (sortByOption: IRequestSortByOption): AppThunk =>
    async (dispatch) => {
        dispatch(_setSortByOption(sortByOption));
    };
export const setOrderByOption =
    (sortByOption: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setOrderByOption(sortByOption));
    };
export const setRequestResolutionModalOpen =
    (isOpen: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setRequestResolutionModalOpen(isOpen));
    };

const calculateDaysBetweenDates = (date1: Date, date2: Date) => {
    var oneDay = 24 * 60 * 60 * 1000;
    var date1InMillis = date1.getTime();
    var date2InMillis = date2.getTime();
    var days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
    return days;
};

export const getAgeLabel = (createdDate: Date) => calculateDaysBetweenDates(new Date(), new Date(createdDate)).toString();

export const toggleRequestResolutionModal = (): AppThunk => async (dispatch, getState) => {
    const { showRequestResolutionModal: requestResolutionModalOpen } = getState().requests;
    dispatch(setRequestResolutionModalOpen(!requestResolutionModalOpen));
};
export const fetchRequestTypes = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${REQUEST_API}/GetRequestTypesList`);
    if (response?.data) {
        const requestTypes: { value: string; label: string }[] = (response.data as IRequestQuestionType[]).map((questionType) => {
            return {
                value: questionType.Id,
                label: questionType.RequestTypeLabel,
            };
        });
        dispatch(setRequestTypeOptions(requestTypes));
    }
};
export const fetchMyActiveRequests = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsFetchingRequestsList(true));
    const response = await axios.get(`${REQUEST_API}/GetMyActiveRequests`);
    dispatch(
        setRequests(
            response.data.sort(
                (requestOne: IRequest, requestTwo: IRequest) => new Date(requestTwo.CreatedOn).getTime() - new Date(requestOne.CreatedOn).getTime()
            )
        )
    );
    dispatch(setIsFetchingRequestsList(false));
};

export const fetchActiveMembersRequests =
    (overrideMember?: string): AppThunk =>
    async (dispatch, getState) => {
        const { selectedMemberId } = getState().requests;
        dispatch(setIsFetchingRequestsList(true));
        dispatch(setRequests([]));
        if (selectedMemberId || overrideMember) {
            const response = await axios.get(`${REQUEST_API}/member`, {
                params: {
                    memberId: overrideMember ?? selectedMemberId,
                    isActive: true,
                },
            });

            dispatch(setRequests(response.data));
        }
        dispatch(setIsFetchingRequestsList(false));
    };

export const fetchRequestsByProvidersTeams = (providerId: string) => async (dispatch, getState) => {
    dispatch(setIsFetchingRequestsList(true));

    const response = await axios.get(`${REQUEST_API}/GetRequestsByProvidersTeams/${providerId}`);
    if (response.status === 200) {
        dispatch(
            setRequests(
                response.data.sort(
                    (requestOne: IRequest, requestTwo: IRequest) => new Date(requestTwo.CreatedOn).getTime() - new Date(requestOne.CreatedOn).getTime()
                )
            )
        );
    } else {
        window.alert(response.statusText);
    }
    dispatch(setIsFetchingRequestsList(false));
};

export const saveNewComment =
    (newComment: string, requestId: string, isAssessment?: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setSavingComment(true));
        const { requests } = getState().requests;
        const requestIndex = requests.findIndex((req) => req.Id === requestId);
        if (newComment) {
            const cleanComment = DOMPurify.sanitize(newComment);
            const response = await axios.put(
                `${apiString}/request/addrequestcomment`,
                {
                    Message: cleanComment,
                    CreatedOn: new Date(),
                },
                { params: { requestId } }
            );

            if (response.status === 200 && response.data) {
                const newRequests = [...requests];
                newRequests[requestIndex] = response.data;
                dispatch(setRequests(newRequests));
                if (!isAssessment) {
                    dispatch(setSelectedRequest(newRequests[requestIndex]));
                }
            }
        }
        dispatch(setSavingComment(false));
    };

export const updateQuestionAnswer =
    (questionAnswer: IRequestQuestionAnswer, alert?: (message: string) => void): AppThunk =>
    async (dispatch, getState) => {
        const { QuestionId } = questionAnswer;
        const { requests, selectedRequest } = getState().requests;
        const requestIndex = requests.findIndex((req) => req.Id === selectedRequest.Id);
        const requestCopy = { ...selectedRequest };
        console.log({ answers: requestCopy?.RequestQuestionAnswers });

        const answerIndex = requestCopy.RequestQuestionAnswers.findIndex((answer) => answer.QuestionId === QuestionId);
        if (answerIndex === -1) {
            throw new Error('No matching question ID.');
        }

        requestCopy.RequestQuestionAnswers = [...requestCopy.RequestQuestionAnswers];
        requestCopy.RequestQuestionAnswers[answerIndex] = questionAnswer;
        console.log({ answersAfterSet: requestCopy?.RequestQuestionAnswers });
        const newRequests = [...requests];
        newRequests[requestIndex] = requestCopy;
        // Optimistically update the request
        dispatch(_setRequests(newRequests));
        dispatch(setSelectedRequest(requestCopy));

        const request = await axios.post(`${REQUEST_API}/question/update/${selectedRequest.Id}`, questionAnswer);

        if (request?.status && ![200, 204].includes(request.status) && alert !== undefined) {
            alert(`Status ${request.status}: There was an error updating a question's answer. Please refresh and try again.`);
        }
    };

export const fetchRequestsByProviderId =
    (providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsFetchingRequestsList(true));

        const response = await axios.get(`${REQUEST_API}/ByProvider/${providerId}`);

        if (response.status === 200) {
            dispatch(setRequests([]));
            dispatch(
                setRequests(
                    response.data.sort(
                        (requestOne: IRequest, requestTwo: IRequest) => new Date(requestTwo.CreatedOn).getTime() - new Date(requestOne.CreatedOn).getTime()
                    )
                )
            );
        }
        dispatch(setIsFetchingRequestsList(false));
    };

/**
 *
 * @param newRequestState
 * @returns void
 */
export const setRequest =
    (newRequestState: Partial<IRequest>): AppThunk =>
    async (dispatch, getState) => {
        if (!newRequestState.Id) throw new Error("No ID on request while updating request's state.");

        const { requests, selectedRequest } = getState().requests;
        const oldRequestIndex = requests.findIndex((old) => old.Id === newRequestState.Id);
        const oldRequest = requests[oldRequestIndex];
        const newRequests = [...requests];
        newRequests[oldRequestIndex] = { ...oldRequest, ...newRequestState };
        dispatch(setRequests(newRequests));
        if (selectedRequest?.Id === newRequests[oldRequestIndex].Id) {
            dispatch(setSelectedRequest(newRequests[oldRequestIndex]));
        }
        console.log('Request updated');
    };

export const addBookmarkToRequest =
    (requestId: string, providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setRequest({ Id: requestId, isSettingBookmark: true }));
        const httpRequest = axios.post(`${REQUEST_API}/bookmark/${requestId}/${providerId}/${true}`);

        httpRequest
            .then((response) => {
                dispatch(setRequest(response.data));
            })
            .finally(() => {
                dispatch(setRequest({ Id: requestId, isSettingBookmark: false }));
            });
    };

export const removeBookmarkFromRequest =
    (requestId: string, providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setRequest({ Id: requestId, isSettingBookmark: true }));
        const httpRequest = axios.post(`${REQUEST_API}/bookmark/${requestId}/${providerId}/${false}`);

        httpRequest
            .then((response) => {
                dispatch(setRequest(response.data));
            })
            .finally(() => {
                dispatch(setRequest({ Id: requestId, isSettingBookmark: false }));
            });
    };

export const fetchProvidersBookmarkedRequests =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsFetchingRequestsList(true));

        const axiosRequest = axios.get(`${REQUEST_API}/bookmarks/${providerId}`);
        axiosRequest
            .then((response) => dispatch(setRequests(response.data)))
            .finally(() => {
                dispatch(setIsFetchingRequestsList(false));
            });
    };

export const updateProvidersRecentlyViewedRequests =
    (providerId: string, requestId: string): AppThunk =>
    async () => {
        await axios.post(`${REQUEST_API}/recent/${requestId}/${providerId}`);
    };

export const fetchProvidersRecentlyViewedRequests =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsFetchingRequestsList(true));

        const axiosRequest = axios.get(`${REQUEST_API}/recent/${providerId}`);
        axiosRequest
            .then((response) => dispatch(setRequests(response.data)))
            .finally(() => {
                dispatch(setIsFetchingRequestsList(false));
            });
    };

export const updateRequestStatus =
    (status: number, requestId: string): AppThunk =>
    async (dispatch) => {
        const request: Partial<IRequest> = {
            Id: requestId,
            Status: status,
        };
        if (!Array.isArray(request.TimelineEvents)) {
            request.TimelineEvents = [];
        }
        console.log({ request });
        const axiosRequest = await axios.patch(`${REQUEST_API}/UpdateRequestStatus`, request);
        if (axiosRequest.status === 200) {
            dispatch(setRequest(axiosRequest.data));
        }
    };

export const getRequestZCode = async (requestId: string) => {
    return (await axios.get(`${REQUEST_API}/${requestId}/GetZcode/`)).data;
};

export const fetchRequestTypeOptions = async (): Promise<
    {
        value: string;
        label: string;
        Zcode: string;
    }[]
> => {
    const deprecatedTypes = ['Others'];
    const response = await axios.get(`${REQUEST_API}/GetRequestTypesList`);
    if (response?.data) {
        const requestTypes: {
            value: string;
            label: string;
            Zcode: string;
        }[] = (response.data as IRequestQuestionType[])
            .map(({ Id, RequestTypeLabel, ZCode: Zcode }) => {
                return {
                    value: Id,
                    label: RequestTypeLabel,
                    Zcode: Zcode,
                };
            })
            .filter(({ label }) => !deprecatedTypes.includes(label))
            .sort((a, b) => a.label.localeCompare(b.label));
        return requestTypes;
    }
    return [];
};

export const setShowForwardingModal =
    (showForwardingModal: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setShowForwardingModal(showForwardingModal));
    };

export const setLinkedRequestId =
    (linkedRequestId: string): AppThunk =>
    (dispatch) => {
        dispatch(_setLinkedRequestId(linkedRequestId));
    };

export const fetchLinkedRequest =
    (linkedRequestId: string): AppThunk =>
    async (dispatch, getState) => {
        if (!linkedRequestId) return;
        const response = await axios.get(`${REQUEST_API}?requestId=${linkedRequestId}`);
        console.log({ response });
        if (response.status === 200) {
            dispatch(setRequests([response.data], true));
            dispatch(setIsShowingCompletedRequests(true, false));
        }
    };

export const UpdateRequest =
    (request: IRequest): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.put(`${REQUEST_API}/Update`, request);
            dispatch(setRequest(response.data));
        } catch (error) {
            const err = error as Error;
            console.error(
                `message: ${err.message}\n
                cause: ${err.cause}\n
                stacktrace: ${err.stack}`
            );
            window.alert(`Error updating request: ${err.message}`);
        }
    };

export default requestsSlice.reducer;
