import { useState, useEffect, useCallback } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../reducers';
import {
    fetchAllMemberTransitionalCares,
    fetchFacilityTypes,
    fetchOutsideFacilityLocations,
    fetchPlannedDischargeLocations,
    fetchTransitionalCareNotifications,
    insertNewMemberTransitionalCare,
    saveNewTransferFacility,
    saveReAdmission,
    setIsReAdmitting,
    setIsTransferring,
    toggleIsShowFacilityDetails,
    updateTransitionalCare,
    visitTypesList,
} from '../../store/transitionalCare.slice';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InfoIcon from '@mui/icons-material/Info';
import { PatientAdmission as PatientAdmissionModel } from '../../Models/TransitionalCare/PatientAdmission.model';
import TCModal from '../../assets/documents/TC_Modals.pdf';
import { TransitionalCareStepTypeEnum } from '../../Enum/TransitionalCareStepTypeEnum';

const patientAdmissionsSchema = Yup.object().shape({
    Id: Yup.string().nullable(),
    AdmissionDate: Yup.string().nullable().required('Admission Date is required'),
    AdmissionType: Yup.string().nullable().required('Type of Admission is required'),
    NotificationDate: Yup.string().nullable().required('Notification Date is required'),
    NotificationMethod: Yup.string().nullable().required('Notification Method is required'),
    Facility: Yup.object()
        .nullable()
        .required('Facility is required')
        .shape({
            Name: Yup.string().nullable().required('Facility is required'),
        }),
    Description: Yup.string().nullable().required('Description is required'),
});

const PatientAdmission = (props) => {
    const { memberId } = props;

    const dispatch = useDispatch();
    const {
        facilityTypes,
        tcNotifications,
        isShowFacilityDetails,
        patientAdmissionTitle,
        patientAdmissionFacility,
        memberTransitionalCare,
        isTransferring,
        isReAdmitting,
    } = useSelector((state: RootState) => state.transitionalCare);
    const { allFacilities } = useSelector((state: RootState) => state.shared);

    const [facilityInputValue, setFacilityInputValue] = useState('');

    const initialPage = useCallback(async () => {
        dispatch(fetchFacilityTypes());
        dispatch(fetchTransitionalCareNotifications());
        dispatch(fetchOutsideFacilityLocations());
        dispatch(fetchPlannedDischargeLocations());
        dispatch(fetchAllMemberTransitionalCares(memberId));
    }, [dispatch, memberId]);

    const saveNewTransitionStep = async (values, resetForm) => {
        let data = {} as any;
        if (!memberTransitionalCare.Id) {
            data.IsClosedDueToDeath = false;
            data.IsDeleted = false;
            data.Status = 1;
            data.MemberId = memberId;
            data.TransitionSteps = [{ ...values, TransitionStepType: TransitionalCareStepTypeEnum.Admission }];
            dispatch(insertNewMemberTransitionalCare(data));
        }

        if (memberTransitionalCare.Id && !isTransferring && !isReAdmitting) {
            // Edit existing transitional care
            dispatch(updateTransitionalCare(memberTransitionalCare.Id, values));
        }
        if (memberTransitionalCare.Id && isTransferring) {
            data = { ...values, TransitionStepType: TransitionalCareStepTypeEnum.Transfer };
            dispatch(saveNewTransferFacility(data));
            dispatch(setIsTransferring(false));
        }
        if (memberTransitionalCare.Id && isReAdmitting) {
            data = { ...values, TransitionStepType: TransitionalCareStepTypeEnum.ReAdmission };
            dispatch(saveReAdmission(data));
            dispatch(setIsReAdmitting(false));
        }
        resetForm();
    };

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    return (
        <Formik
            initialValues={patientAdmissionFacility}
            onSubmit={(values, { resetForm }) => {
                saveNewTransitionStep(values, resetForm);
            }}
            validationSchema={patientAdmissionsSchema}
            enableReinitialize={true}
        >
            {({ values, setFieldValue, errors, handleChange, submitCount, resetForm }: FormikProps<PatientAdmissionModel>) => (
                <Form id="patient-admission">
                    <Dialog
                        open={isShowFacilityDetails}
                        onClose={() => {
                            dispatch(toggleIsShowFacilityDetails(false));
                            dispatch(setIsTransferring(false));
                            dispatch(setIsReAdmitting(false));
                            resetForm();
                        }}
                        fullWidth
                        disableEnforceFocus
                        maxWidth="md"
                    >
                        <DialogTitle>
                            {patientAdmissionTitle}
                            <Tooltip title="Transitional Care Help Document">
                                <InfoIcon
                                    color="primary"
                                    sx={{ marginLeft: 1, marginBottom: 1 }}
                                    fontSize="small"
                                    cursor="pointer"
                                    onClick={() => window.open(`${TCModal}#${patientAdmissionTitle}`, '_blank')}
                                />
                            </Tooltip>
                        </DialogTitle>

                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            fullWidth
                                            id="facility-auto-complete"
                                            options={allFacilities}
                                            value={values?.Facility || null}
                                            onChange={(e, value) => {
                                                setFieldValue('Facility', value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.Name === value.Name && option.Id === value.Id}
                                            inputValue={facilityInputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setFacilityInputValue(newInputValue);
                                            }}
                                            sx={{ marginY: 2 }}
                                            getOptionLabel={(option) =>
                                                option
                                                    ? option.Name?.toUpperCase() +
                                                      ' - ' +
                                                      option.Address?.toUpperCase() +
                                                      ' ' +
                                                      option.City?.toUpperCase() +
                                                      ' ' +
                                                      option.State?.toUpperCase() +
                                                      ' ' +
                                                      option.ZipCode
                                                    : ''
                                            }
                                            renderOption={(props, option) => {
                                                return (
                                                    <li
                                                        {...props}
                                                        key={option.Id}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                        }}
                                                    >
                                                        <Typography variant="body1">{option.Name}</Typography>
                                                        <Typography variant="body2" color={'text.secondary'}>
                                                            {option.Address} {option.City}, {option.State} {option.ZipCode}
                                                        </Typography>
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    inputProps={{ height: 600 }}
                                                    {...params}
                                                    helperText="Start typing to select a facility from the list"
                                                    label="Facility"
                                                    error={Boolean(errors?.Facility || errors?.Facility?.Name) && Boolean(submitCount)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth required error={Boolean(errors?.AdmissionType) && Boolean(submitCount)}>
                                        <InputLabel id="admission-type-select">Type of Admission</InputLabel>
                                        <Select
                                            labelId="admission-type-select"
                                            id="admission-type-select"
                                            label="Type of admission"
                                            name="AdmissionType"
                                            value={Boolean(values?.AdmissionType) ? values?.AdmissionType : 0}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value={0}>
                                                Select Type
                                            </MenuItem>
                                            {facilityTypes.map((type) => (
                                                <MenuItem key={type.Id} value={String(type.Id)}>
                                                    {type.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Admission Date"
                                                value={values?.AdmissionDate ? new Date(values?.AdmissionDate) : null}
                                                inputFormat="MM/dd/yyyy"
                                                minDate={new Date('2017-01-01')}
                                                onChange={(date) => {
                                                    if (moment(date).isValid() || !Boolean(date)) {
                                                        const newDate = moment(date).format('YYYY-MM-DD') + 'T12:00:00.000Z';
                                                        setFieldValue('AdmissionDate', newDate);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField fullWidth required {...params} error={Boolean(errors?.AdmissionDate) && Boolean(submitCount)} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth required error={Boolean(errors?.NotificationMethod) && Boolean(submitCount)}>
                                        <InputLabel id="notification-method-select">Notification Method</InputLabel>
                                        <Select
                                            labelId="notification-method-select"
                                            id="notification-method-select"
                                            value={Boolean(values?.NotificationMethod) ? values?.NotificationMethod : '-1'}
                                            label="Notification Method*"
                                            name="NotificationMethod"
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled value="-1">
                                                Select Method
                                            </MenuItem>
                                            {tcNotifications.map((type) => (
                                                <MenuItem key={type.Id} value={type.Id}>
                                                    {type.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Notification Date"
                                                value={values?.NotificationDate ? new Date(values?.NotificationDate) : null}
                                                inputFormat="MM/dd/yyyy"
                                                minDate={new Date('2017-01-01')}
                                                onChange={(date) => {
                                                    if (moment(date).isValid() || !Boolean(date)) {
                                                        const newDate = moment(date).format('YYYY-MM-DD') + 'T12:00:00.000Z';
                                                        setFieldValue('NotificationDate', newDate);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        {...params}
                                                        error={Boolean(errors?.NotificationDate) && Boolean(submitCount)}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="notification-method-select">Type of Visit</InputLabel>
                                        <Select
                                            labelId="notification-method-select"
                                            id="notification-method-select"
                                            value={Boolean(values?.VisitType) ? values?.VisitType : 0}
                                            label="Type of Visit*"
                                            name="VisitType"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={0}>Select Visit Type</MenuItem>
                                            {visitTypesList.map((type) => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel className="mt-15">Description</FormLabel>
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors?.Description) && Boolean(submitCount)}
                                        multiline
                                        className="hide-textinput-label"
                                        helperText="Please describe caller, name, number, room number, and details of reason for why"
                                        margin="none"
                                        variant="outlined"
                                        rows={6}
                                        value={values?.Description}
                                        name="Description"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ p: 2 }}>
                            <Button
                                sx={{ marginRight: 2, minWidth: 120 }}
                                variant="outlined"
                                onClick={() => {
                                    dispatch(toggleIsShowFacilityDetails(false));
                                    resetForm();
                                }}
                                //type="reset"
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit" form="patient-admission">
                                Save & Continue
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
};

export default PatientAdmission;
