import { Grid, Typography } from '@mui/material';
import React from 'react';
import { IOperatingDay } from '../../Resources/interfaces/IOperatingDay';
import FormikTextField from '../FormikTextField';

const FormikOperatingDay: React.FC<IOperatingDay & { DayName: string; isEditing: boolean }> = ({ DayName, OpensAt, ClosesAt, isEditing }) => {
    const displayHours = `${OpensAt} - ${ClosesAt}`;
    let display = (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2">{displayHours}</Typography>
        </Grid>
    );

    if (isEditing) {
        display = (
            <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormikTextField name={`HoursOfOperation.${DayName}.OpensAt`} label="Opens" size="small" fullWidth={false} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormikTextField name={`HoursOfOperation.${DayName}.ClosesAt`} label="Closes" size="small" fullWidth={false} />
                </Grid>
            </>
        );
    }
    return (
        <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography style={{ textAlign: 'center' }} variant="button">
                    {DayName}
                </Typography>
            </Grid>
            {display}
        </Grid>
    );
};

export default React.memo(FormikOperatingDay);
