import { Box, Button, Grid, Tooltip } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Loading from '../Shared/Loading';
import EditIcon from '@mui/icons-material/Edit';
import AddHealthPlanDialog from './HealthPlanComponents/AddHealthPlanDialog';
import GroupIcon from '@mui/icons-material/Group';
import PopulationDialog from './PopulationDialog';
import AddPopulationDialog from './PopulationComponents/AddPopulationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { isDefaultDate } from '../../utils/common';
import {
    addHealthPlanHandler,
    closeAddHealthPlanHandler,
    editHealthPlanHandler,
    fetchHealthPlan,
    setOpenPopulationDialog,
    setActiveStep,
    setCurrentPopulation,
    setHealthPlanForm,
    setNewPopulation,
    setOpenAddPopulation,
    setPopulationActiveStep,
    updateHealthPlan,
    openPopulationDialogHandler,
    setPopulationSteps,
    setUpdatingPopulation,
    closeAddPopulationHandler,
} from '../../store/adminHealthPlan.slice';

const AdminHealthPlan = (props) => {
    const {
        activeStep,
        currentHealthPlan,
        editPopulationVisibility,
        healthPlans,
        healthPlanForm,
        openAddHealthPlan,
        openAddPopulation,
        openPopulationDialog,
        populationActiveStep,
        populationSteps,
    } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const dispatch = useDispatch();

    const steps = [
        { label: 'Basic Information', value: 0 },
        { label: 'Contact', value: 1 },
        { label: 'Contract Terms', value: 2 },
        { label: 'Data Feed', value: 3 },
        { label: 'Outcome Metrics', value: 4 },
    ];

    const addPopulationSteps = [
        { label: 'Sample Data', value: 0 },
        { label: 'Choose Data', value: 1 },
        { label: 'Import Data', value: 2 },
        { label: 'Data Validation', value: 3 },
    ];

    const getDefaultPopulationForm = (currentHealthPlan) => {
        return {
            AlertContacts: null,
            FileNotReceivedInDays: 0,
            HealthPlan: currentHealthPlan,
            Id: '',
            IsActive: true,
            OnboardedIndividualsGoal: null,
            OnboardingActive: false,
            OnboardingDeadlineDate: '',
            OnboardingStartDate: '',
            PopulationName: '',
            PopulationNumber: 0,
        };
    };

    const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setHealthPlanForm({ ...healthPlanForm, [e.target.name]: e.target.value }));
    };

    const nextStepHandler = () => {
        if (activeStep < steps.length - 1) {
            dispatch(setActiveStep(activeStep + 1));
        }
        dispatch(updateHealthPlan(healthPlanForm));
    };

    const prevStepHandler = () => {
        if (activeStep !== 0) {
            dispatch(setActiveStep(activeStep - 1));
        }
        dispatch(updateHealthPlan(healthPlanForm));
    };

    const populationNextStepHandler = () => {
        if (populationActiveStep < populationSteps.length - 1) dispatch(setPopulationActiveStep(populationActiveStep + 1));
    };

    const populationPrevStepHandler = () => {
        if (populationActiveStep !== 0) dispatch(setPopulationActiveStep(populationActiveStep - 1));
    };

    const closePopulationHandler = () => {
        dispatch(setOpenPopulationDialog(false));
    };

    const openAddPopulationHandler = () => {
        dispatch(setPopulationActiveStep(0));
        dispatch(setUpdatingPopulation(false));
        dispatch(setPopulationSteps(addPopulationSteps));
        dispatch(setNewPopulation(true));
        dispatch(setCurrentPopulation(getDefaultPopulationForm(currentHealthPlan)));
        dispatch(setOpenAddPopulation(true));
    };

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'OrganizationId',
            headerName: 'Plan Id',
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 1.5,
        },
        {
            field: 'HealthPlanType',
            headerName: 'Additional Plan Type',
            flex: 1,
        },
        {
            field: 'ContractDate',
            headerName: 'Contract Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ContractDate) ? '' : new Date(params.row.ContractDate).toLocaleDateString()}`,
        },
        {
            field: 'SharedSavings',
            headerName: 'Shared Savings',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => `${params.row.SharedSavings ? 'Yes' : 'No'}`,
        },
        {
            field: 'TargetMembersVolume',
            headerName: 'Target Members',
            flex: 0.75,
        },
        {
            field: 'LoadedMembers',
            headerName: 'Members',
            flex: 0.75,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="View Population" placement="top">
                            <GroupIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(openPopulationDialogHandler(params))}
                            />
                        </Tooltip>
                        <Tooltip title="Edit Plan" placement="bottom">
                            <EditIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(editHealthPlanHandler(params.row.Id))}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 50 : 58) + ''));
    };

    useEffect(() => {
        dispatch(fetchHealthPlan());
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {<Loading message={'Loading...'} />}
            <AddHealthPlanDialog
                open={openAddHealthPlan}
                onClose={() => dispatch(closeAddHealthPlanHandler())}
                steps={steps}
                nextStepHandler={nextStepHandler}
                prevStepHandler={prevStepHandler}
                inputOnChange={inputOnChange}
            />

            <PopulationDialog
                open={openPopulationDialog}
                onClose={closePopulationHandler}
                openAddPopulationHandler={openAddPopulationHandler}
                editPopulationVisibility={editPopulationVisibility}
            />

            <AddPopulationDialog
                open={openAddPopulation}
                onClose={() => dispatch(closeAddPopulationHandler(currentHealthPlan))}
                populationSteps={populationSteps}
                populationNextStepHandler={populationNextStepHandler}
                populationPrevStepHandler={populationPrevStepHandler}
            />

            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={3} textAlign={'right'}>
                    <Button variant="contained" className="button-120" onClick={() => dispatch(addHealthPlanHandler())}>
                        New Health Plan
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            rows={healthPlans}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminHealthPlan;
