import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import {
    setSearchFilter,
    setSearchKey,
    setSearchName,
    setMemberId,
    fetchMemberByName,
    fetchMemberEncounterBillingReport,
    exportMemberEncounterBillingReport,
} from '../../../store/memberEncounterBillingReport.slice';
import { useEffect, useState } from 'react';
import { moneyFormatter } from '../../../utils/moneyFormat';
import { RootState } from '../../../reducers';

const MemberEncounterBillingReport = () => {
    const dispatch = useDispatch();
    const { rows, searchFilter, searchKey, searchName, memberId, membersListByName, memberData, searchLoading } = useSelector(
        (state: RootState) => state.memberEncounterBillingReport
    );
    const { downLoading } = useSelector((state: RootState) => state.shared);

    const columns: GridColDef[] = [
        {
            field: 'ServiceDate',
            headerName: 'ServiceDate',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'PlaceOfService',
            headerName: 'Place Of Service',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'CPTcode',
            headerName: 'CPT',
            minWidth: 250,
            flex: 2,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Typography variant="body2">
                        <strong>
                            {params.row.CPTcode}
                            <br />
                        </strong>
                        {params.row.CPTdescription}
                    </Typography>
                );
            },
        },
        {
            field: 'Charges',
            headerName: 'Charges',
            minWidth: 110,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.Charges)}`,
        },
        {
            field: 'PrimaryDiagnosis',
            headerName: 'Diagnosis',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `Primary: ${params.row.PrimaryDiagnosis}  
                ${params.row.SecondaryDiagnosis.length > 0 ? 'Secondary: ' + params.row.SecondaryDiagnosis : ''}  
                ${params.row.TertiaryDiagnosis.length > 0 ? 'Tertiary: ' + params.row.TertiaryDiagnosis : ''}  
                ${params.row.QuaternaryDiagnosis.length > 0 ? 'Quaternary: ' + params.row.QuaternaryDiagnosis : ''}`,
        },
        {
            field: 'CHStatus',
            headerName: 'CHStatus',
            minWidth: 160,
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                const color = params.value === 'Rejected' ? 'var(--danger)' : params.value === 'Accepted' ? 'var(--success)' : '';
                return (
                    <Typography variant="body2" color={color}>
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: 'PayerStatus',
            headerName: 'Payer Status',
            minWidth: 160,
            flex: 1,
        },
    ];

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 72 : 74) + ''));
    };
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container>
            <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Member Encounter Billing Report
                </Typography>
            </Box>
            {<Loading message={'Loading...'} />}
            <Grid item xs={12}>
                <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                    <FormControl className="form-control-200">
                        <InputLabel id="demo-simple-select-label">Search By</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={searchFilter}
                            value={searchFilter}
                            label="Search By"
                            onChange={(e) => {
                                dispatch(setSearchFilter(e.target.value));
                            }}
                        >
                            <MenuItem value={'1'}>Patient Name</MenuItem>
                            <MenuItem value={'2'}>Patient Account #</MenuItem>
                        </Select>
                    </FormControl>

                    {searchFilter === '1' && (
                        <>
                            <FormControl className="form-control-200">
                                <Autocomplete
                                    id="member_name"
                                    getOptionLabel={(membersListByName) => `${membersListByName.LastName}, ${membersListByName.FirstName}`}
                                    options={membersListByName}
                                    isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                    noOptionsText={'No Members Found'}
                                    filterOptions={(x) => x}
                                    loadingText={'Searching..'}
                                    loading={searchLoading}
                                    inputValue={searchName}
                                    onInputChange={(event, value) => {
                                        dispatch(setSearchName(value));
                                    }}
                                    onChange={(event, value: any) => {
                                        value !== null && dispatch(setMemberId(value.Id));
                                    }}
                                    onKeyUp={() => dispatch(fetchMemberByName(searchName))}
                                    renderInput={(params) => <TextField {...params} label="Member Name" />}
                                    renderOption={(props, membersListByName) => (
                                        <Box component={'li'} {...props} key={membersListByName.Id}>
                                            {membersListByName.LastName}, {membersListByName.FirstName}
                                        </Box>
                                    )}
                                />
                            </FormControl>
                            <Button
                                style={{ minWidth: '120px', marginRight: 'auto' }}
                                onClick={() => {
                                    dispatch(fetchMemberEncounterBillingReport(memberId));
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Search
                            </Button>
                        </>
                    )}
                    {searchFilter === '2' && (
                        <>
                            <FormControl>
                                <TextField label="Search Key" onChange={(e) => dispatch(setSearchKey(e.target.value))} />
                            </FormControl>
                            <Button
                                style={{ minWidth: '120px', marginRight: 'auto' }}
                                onClick={() => {
                                    dispatch(fetchMemberEncounterBillingReport(searchKey));
                                }}
                                variant="contained"
                                disabled={searchKey.length < 1}
                                color="primary"
                            >
                                Search
                            </Button>
                        </>
                    )}
                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => dispatch(exportMemberEncounterBillingReport(memberId))}
                        variant="contained"
                        color="success"
                    >
                        {downLoading ? 'Downloading..' : 'Export'}
                    </Button>
                </Stack>
            </Grid>
            <Grid container marginBottom={2} spacing={2} height={'90px'} sx={{ overflowY: 'scroll' }}>
                <Grid item>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', minWidth: '160px' }}>
                        <strong>Member Name: </strong>
                        {memberData.MemberName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', width: '350px' }}>
                        <strong>Address: </strong>
                        {memberData.Address}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', minWidth: '160px' }}>
                        <strong>DOB: </strong>
                        {memberData.DOB}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', minWidth: '160px' }}>
                        <strong>Gender: </strong>
                        {memberData.Gender}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', minWidth: '160px' }}>
                        <strong>Insurance Type: </strong>
                        {memberData.InsuranceType}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item style={{ height: '70vh', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5]}
                    getRowId={() => Math.random()}
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MemberEncounterBillingReport;
