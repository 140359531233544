import BaseEntity from '../BaseEntity.model';
import Contact from '../Shared/Contact.model';
import NotificationFrequency from '../HealthPlan/NotificationFrequency';
import { TransferModeEnum } from '../../Enum/TransferModeEnum';

export default interface ISftp extends BaseEntity {
    FileType: string;
    HostName: string;
    PortNumber: number;
    UserName: string;
    Password: string;
    TransferMode: TransferModeEnum | string;
    FilePath: string;
    AlertFrequency: NotificationFrequency[] | null;
    AlertContacts: Contact[] | null;

    // Ignore Property
    DaysAfter: number;
    DateOfMonth: number;
}

export default class Sftp implements ISftp {
    Id: string;
    FileType: string;
    HostName: string;
    PortNumber: number;
    UserName: string;
    Password: string;
    TransferMode: TransferModeEnum | string;
    FilePath: string;
    AlertFrequency: NotificationFrequency[] | null;
    AlertContacts: Contact[] | null;

    // Ignore Property
    DaysAfter: number;
    DateOfMonth: number;

    constructor() {
        this.Id = '';
        this.FileType = '';
        this.HostName = '';
        this.PortNumber = 0;
        this.UserName = '';
        this.Password = '';
        this.TransferMode = '';
        this.FilePath = '';
        this.AlertFrequency = null;
        this.AlertContacts = null;

        // Ignore Property
        this.DaysAfter = 0;
        this.DateOfMonth = 0;
    }
}
