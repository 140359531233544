import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { TimePeriodTypeEnum } from '../../../Enum/TimePeriodTypeEnum';
import { ClaimPeriodEnum } from '../../../Enum/ClaimPeriodEnum';
import { apiString as api } from '../../../utils/constants';
import { QuarterEnum } from '../../../Enum/QuarterEnum';
import { MonthEnum } from '../../../Enum/MonthEnum';
import { ClaimAnalyticEnum } from '../../../Enum/ClaimAnalyticEnum';
import axios from 'axios';

export const getStatus = function () {
    let statuses = [];
    statuses.push({ value: 0, name: 'All' });
    statuses.push({ value: 2, name: 'Active' });
    statuses.push({ value: 6, name: 'Deceased' });
    statuses.push({ value: 7, name: 'Disenrolled From CHP' });
    return statuses;
};

export const getAnalyticItems = function () {
    let items = [];
    items.push({ id: 1, name: 'Total Cost', value: 'totalCosts' });
    items.push({ id: 2, name: 'Emergency Department Visits', value: 'edvisits' });
    items.push({ id: 3, name: 'Acute Care Costs', value: 'acCosts' });
    items.push({ id: 4, name: 'Pharmaceutical Costs', value: 'phCosts' });
    items.push({ id: 5, name: 'Acute BH Costs', value: 'ffCosts' });
    items.push({ id: 6, name: 'BH RTC/RF Costs', value: 'bhRtcCosts' });
    return items;
};

export const getTimePeriodList = function () {
    let list = [];
    list.push({ Id: TimePeriodTypeEnum.YRS, Value: TimePeriodTypeEnum.YearDescription });
    list.push({ Id: TimePeriodTypeEnum.QRT, Value: TimePeriodTypeEnum.QuarterDescription });
    list.push({ Id: TimePeriodTypeEnum.MTH, Value: TimePeriodTypeEnum.MonthDescription });
    return list;
};

export const getQuarterList = function () {
    let list = [];
    list.push({ Id: QuarterEnum.Q1, Value: QuarterEnum.Q1Description });
    list.push({ Id: QuarterEnum.Q2, Value: QuarterEnum.Q2Description });
    list.push({ Id: QuarterEnum.Q3, Value: QuarterEnum.Q3Description });
    list.push({ Id: QuarterEnum.Q4, Value: QuarterEnum.Q4Description });
    return list;
};

export const getMonthList = function () {
    let list = [];
    list.push({ Id: MonthEnum.January, Value: MonthEnum.JanuaryDescription });
    list.push({ Id: MonthEnum.February, Value: MonthEnum.FebruaryDescription });
    list.push({ Id: MonthEnum.March, Value: MonthEnum.MarchDescription });
    list.push({ Id: MonthEnum.April, Value: MonthEnum.AprilDescription });
    list.push({ Id: MonthEnum.May, Value: MonthEnum.MayDescription });
    list.push({ Id: MonthEnum.June, Value: MonthEnum.JuneDescription });
    list.push({ Id: MonthEnum.July, Value: MonthEnum.JulyDescription });
    list.push({ Id: MonthEnum.August, Value: MonthEnum.AugustDescription });
    list.push({ Id: MonthEnum.September, Value: MonthEnum.SeptemberDescription });
    list.push({ Id: MonthEnum.October, Value: MonthEnum.OctoberDescription });
    list.push({ Id: MonthEnum.November, Value: MonthEnum.NovemberDescription });
    list.push({ Id: MonthEnum.December, Value: MonthEnum.DecemberDescription });
    return list;
};

let currentMonth = new Date().getMonth() + 1;
let m = Math.round(currentMonth / 3);
let q = m > 4 ? m - 4 : m;
export const quarter = q > 0 ? q : 1;
export const month = currentMonth > 2 ? currentMonth - 2 : currentMonth;

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 50 * 4.5,
            width: 250,
        },
    },
};

export const getTotalCostGraph = async (
    filterBy: TimePeriodTypeEnum,
    population: number,
    primaryProviderId: string,
    part: number,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/gettotalcostgraph?filterBy=${filterBy}&population=${population}&primaryProviderId=${primaryProviderId}&part=${part}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getEdVisitGraph = async (
    filterBy: TimePeriodTypeEnum,
    population: number,
    primaryProviderId: string,
    part: number,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/getedvisitgraph?filterBy=${filterBy}&population=${population}&primaryProviderId=${primaryProviderId}&part=${part}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getAcCostGraph = async (
    filterBy: TimePeriodTypeEnum,
    population: number,
    primaryProviderId: string,
    part: number,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/getaccostgraph?filterBy=${filterBy}&population=${population}&primaryProviderId=${primaryProviderId}&part=${part}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getPhCostGraph = async (
    filterBy: TimePeriodTypeEnum,
    population: number,
    primaryProviderId: string,
    part: number,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/getphcostgraph?filterBy=${filterBy}&population=${population}&primaryProviderId=${primaryProviderId}&part=${part}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getFfCostGraph = async (
    filterBy: TimePeriodTypeEnum,
    population: number,
    primaryProviderId: string,
    part: number,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/getffcostgraph?filterBy=${filterBy}&population=${population}&primaryProviderId=${primaryProviderId}&part=${part}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getBfCostGraph = async (
    filterBy: TimePeriodTypeEnum,
    population: number,
    primaryProviderId: string,
    part: number,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/getbfcostgraph?filterBy=${filterBy}&population=${population}&primaryProviderId=${primaryProviderId}&part=${part}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getBhRfCostGraph = async (
    filterBy: TimePeriodTypeEnum,
    population: number,
    primaryProviderId: string,
    part: number,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/getbhrfcostgraph?filterBy=${filterBy}&population=${population}&primaryProviderId=${primaryProviderId}&part=${part}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};

export const getTop10MembersByTotalCost = async (
    population: number,
    primaryProviderId: string,
    claimPeriod: ClaimPeriodEnum,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/gettoptentotalcostmembers?population=${population}&primaryProviderId=${primaryProviderId}&claimPeriod=${claimPeriod}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getTop10MembersByEDVisit = async (
    population: number,
    primaryProviderId: string,
    claimPeriod: ClaimPeriodEnum,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/gettoptenedvisitmembers?population=${population}&primaryProviderId=${primaryProviderId}&claimPeriod=${claimPeriod}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getTop10MembersByAcCost = async (
    population: number,
    primaryProviderId: string,
    claimPeriod: ClaimPeriodEnum,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/gettoptenaccostmembers?population=${population}&primaryProviderId=${primaryProviderId}&claimPeriod=${claimPeriod}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getTop10MembersByPhCost = async (
    population: number,
    primaryProviderId: string,
    claimPeriod: ClaimPeriodEnum,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/gettoptenphcostmembers?population=${population}&primaryProviderId=${primaryProviderId}&claimPeriod=${claimPeriod}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getTop10MembersByFfCost = async (
    population: number,
    primaryProviderId: string,
    claimPeriod: ClaimPeriodEnum,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/gettoptenffcostmembers?population=${population}&primaryProviderId=${primaryProviderId}&claimPeriod=${claimPeriod}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const getTop10MembersByBhRtcAndRfCost = async (
    population: number,
    primaryProviderId: string,
    claimPeriod: ClaimPeriodEnum,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/gettoptenbhrtcandrfcostmembers?population=${population}&primaryProviderId=${primaryProviderId}&claimPeriod=${claimPeriod}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
export const exportAnalyticMembers = async (
    claimType: ClaimAnalyticEnum,
    population: number,
    primaryProviderId: string,
    claimPeriod: ClaimPeriodEnum,
    status: MemberStatusEnum,
    secondaryProviderId: string,
    showInActivePrimaryTeams: boolean,
    showInActiveSecondaryTeams: boolean
) => {
    const response = await axios.get(
        `${api}/claimanalytic/exportanalyticmembers?claimType=${claimType}&population=${population}&primaryProviderId=${primaryProviderId}&claimPeriod=${claimPeriod}&status=${status}&secondaryProviderId=${secondaryProviderId}&showInActivePrimaryTeams=${showInActivePrimaryTeams}&showInActiveSecondaryTeams=${showInActiveSecondaryTeams}`
    );
    return response.data;
};
