import { Paper, Stack, TextField } from '@mui/material';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { setPropertiesOnSelectedCallItem } from '../../../../store/officeScheduler.slice';

const SchedulingEngagementInput: React.FC = () => {
    const dispatch = useDispatch();
    const { schedulingProvider, selectedCallItem } = useSelector((state: RootState) => state.officeSchedulerSlice);
    const status = useMemo(() => selectedCallItem?.CallStatus ?? '', [selectedCallItem]);
    const contactTime = useMemo(() => {
        if (selectedCallItem?.LastContact === null || selectedCallItem.LastContact === undefined) return '';
        return selectedCallItem.LastContact !== null ? format(new Date(selectedCallItem.LastContact), 'MM/dd/yyyy h:mm aa') : null;
    }, [selectedCallItem]);

    return (
        <Stack spacing={1}>
            <span className="documentation-detail">{contactTime}</span>
            <span className="documentation-detail">Status: {status}</span>
            <span className="documentation-detail">{schedulingProvider.FullName ?? ''}</span>
            <Paper elevation={2} style={{ width: '100%' }}>
                <TextField
                    fullWidth
                    label="Call Documentation"
                    placeholder="Enter Comment..."
                    id="documentation-input"
                    multiline
                    rows={6}
                    inputProps={{
                        style: {
                            padding: '1em',
                        },
                    }}
                    value={selectedCallItem?.Note ?? ''}
                    onChange={(event) => dispatch(setPropertiesOnSelectedCallItem({ Note: event.target.value }))}
                />
            </Paper>
        </Stack>
    );
};

export default SchedulingEngagementInput;
