import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL, MAP_DISTANCE_APPT_STATUS, SELECTED_PROVIDER_ID, USE_HOME_ADDRESS } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';

const apptMap = { Appointments: [], Distance: 0, ProviderHomeLocation: {}, AppointmentMapDownloadDTOs: [] };
const defaultApptStatus = [0, 1, 4];
const storedApptStatus = localStorage.getItem(MAP_DISTANCE_APPT_STATUS) ? JSON.parse(localStorage.getItem(MAP_DISTANCE_APPT_STATUS)) : defaultApptStatus;
const defaultHome = localStorage.getItem(USE_HOME_ADDRESS) ? JSON.parse(localStorage.getItem(USE_HOME_ADDRESS)) : false;

const appointmentMapSlice = createSlice({
    name: 'appointmentMap',
    initialState: {
        appointmentMaps: apptMap,
        hasPermission: false,
        selectedAppointmentStatus: [].concat(storedApptStatus),
        selectedProviderId: '',
        startDate: new Date(),
        endDate: new Date(),
        useHomeAddress: defaultHome,
    },
    reducers: {
        _setAppointmentMaps(state, action) {
            state.appointmentMaps = action.payload;
        },
        _setEndDate(state, action) {
            state.endDate = action.payload;
        },
        _setHasPermission(state, action) {
            state.hasPermission = action.payload;
        },
        _setSelectedAppointmentStatus(state, action) {
            state.selectedAppointmentStatus = action.payload;
        },
        _setSelectedProviderId(state, action) {
            state.selectedProviderId = action.payload;
        },
        _setStartDate(state, action) {
            state.startDate = action.payload;
        },
        _setUseHomeAddress(state, action) {
            state.useHomeAddress = action.payload;
        },
    },
});

const { _setAppointmentMaps, _setEndDate, _setHasPermission, _setSelectedAppointmentStatus, _setSelectedProviderId, _setStartDate, _setUseHomeAddress } =
    appointmentMapSlice.actions;

export const fetchAppointmentMaps = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const providerId = getState().appointmentMap.selectedProviderId;
    const startDate = getState().appointmentMap.startDate.toLocaleDateString().replaceAll('/', '-');
    const endDate = getState().appointmentMap.endDate.toLocaleDateString().replaceAll('/', '-');
    const apptStatusIds = getState().appointmentMap.selectedAppointmentStatus;

    let excludedApptIdsList = getState()
        .appointmentMap.appointmentMaps.Appointments.filter((appt) => appt.Checked)
        .map((appt) => appt.Id);
    const excludedApptIds = excludedApptIdsList.join(',');

    if (apptStatusIds != null && apptStatusIds.length === 0) {
        dispatch(_setAppointmentMaps([]));
    } else {
        let url = `${API_URL}/maps/appointments/${providerId}/${startDate}/${endDate}/${
            getState().appointmentMap.useHomeAddress
        }?apptStatusIds=${apptStatusIds}&excludedApptIds=${excludedApptIds}`;

        const response = await axios.get(url);
        for (let appt of response.data.Appointments) {
            if (excludedApptIds.includes(appt.Id)) {
                appt.Checked = true;
            }
        }
        dispatch(_setAppointmentMaps(response.data));
    }

    dispatch(setIsLoading(false));
};

export const setEndDate =
    (date: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setEndDate(date));
    };

export const setHasPermission =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setHasPermission(value));
        if (!value) {
            dispatch(setUseHomeAddress(false));
            dispatch(setSelectedAppointmentStatus(defaultApptStatus));
        }
    };

export const setIsLaoding =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(setIsLoading(value));
    };

export const setAppointmentMaps =
    (apptMaps: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setAppointmentMaps(apptMaps));
    };

export const setStartDate =
    (date: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setStartDate(date));
    };

export const setSelectedProviderId =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedProviderId(providerId));
        if (providerId) {
            dispatch(_setSelectedProviderId(providerId));
            localStorage.setItem(SELECTED_PROVIDER_ID, providerId);
        }
    };

export const setSelectedAppointmentStatus =
    (status: number[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedAppointmentStatus(status));
        localStorage.setItem(MAP_DISTANCE_APPT_STATUS, JSON.stringify(status));
    };

export const setUseHomeAddress =
    (use: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setUseHomeAddress(use));
        localStorage.setItem(USE_HOME_ADDRESS, JSON.stringify(use));
    };

export default appointmentMapSlice.reducer;
