import {
    Grid,
    Paper,
    Typography,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    List,
    ListItem,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { parentUrl } from '../../../../utils/constants';
import { localDateFormat } from '../../../../utils/timeFormat';
import { apiString as API_URL } from '../../../../utils/constants';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';

const EducationSessionReminder = (props) => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [sessions, setSessions] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const isOlderThan60Days = (row) => {
        const isOlder = new Date(row.CreationTime) < new Date(new Date().setDate(new Date().getDate() - 60));
        const isInProgress = row.Status === 'Plan InProgress';
        return isOlder && isInProgress;
    };

    const returnClassName = (row) => {
        return isOlderThan60Days(row) ? 'is-late' : 'is-in-progress';
    };

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        const changeFilter = async () => {
            setIsFetched(false);
            const response = await axios.get(
                `${API_URL}/membereducation/educationsessionreminder?teamId=${selectedTeam}&includeInActiveTeams=${showInActiveTeams}`
            );
            setSessions(response.data);
            setIsFetched(true);
        };
        changeFilter();
    }, [selectedTeam, showInActiveTeams]);

    const columns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member',
            minWidth: 140,
        },
        {
            field: 'Type',
            headerName: 'Type',
            minWidth: 140,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 140,
        },
        {
            field: 'Audience',
            headerName: 'Audience',
            minWidth: 140,
        },
        {
            field: 'LevelOfTeachback',
            headerName: 'Level of Teach Back',
            minWidth: 165,
        },
        {
            field: 'LevelOfUnderstanding',
            headerName: 'Level of Understanding',
            minWidth: 165,
        },
        {
            field: 'CreationTime',
            headerName: 'Created',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${localDateFormat(new Date(params.row.CreationTime))}`,
        },
        {
            field: 'CreatedBy',
            headerName: 'Provider Name',
            minWidth: 165,
            valueGetter: (params: GridValueGetterParams) => `${params.row.CreatedBy?.Name}`,
        },
    ];

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 100 : 105) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                        Education Session Reminder
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="caption">In Progress Member Educations colored by Status</Typography>
                                    <List>
                                        <ListItem>
                                            <Typography variant="caption" color={'salmon'}>
                                                Red: Older than 60 days
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant="caption" color={'lightgreen'}>
                                                Green: Not older than 60 days
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Team</InputLabel>
                        <Select
                            value={selectedTeam}
                            label="Team"
                            name="Team"
                            onChange={(e) => setSelectedTeam(e.target.value)}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                        >
                            <MenuItem key="All" value="All">
                                All
                            </MenuItem>
                            <FormControlLabel
                                sx={{ pl: '12px', mb: '0px' }}
                                control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                label="Show Inactive"
                            />
                            {filteredTeams.map((team) => (
                                <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                    {team.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <div style={{ width: '100%', height: 'Calc(70vh - 135px)', position: 'relative' }}>
                        {!isFetched && <WidgetLoading />}
                        <DataGrid
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.MemberId}/education`, '_blank');
                            }}
                            rows={sessions}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            getRowClassName={(params) => returnClassName(params.row)}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                '& .is-late': {
                                    color: 'red',
                                },
                                '& .is-in-progress': {
                                    color: 'green',
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default EducationSessionReminder;
