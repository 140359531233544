import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchScheduleNotes, saveScheduleNotes } from '../../store/appointment.slice';

interface SchedulerNotesProps {
    memberId: string;
    notesEdit: boolean;
    setNotesEdit: any;
}

const SchedulerNotes: React.FC<SchedulerNotesProps> = ({ memberId, notesEdit, setNotesEdit }: SchedulerNotesProps) => {
    const [saving, setSaving] = useState<boolean>(false);
    const [newNote, setNewNote] = useState<string>('');
    const { scheduleNote } = useSelector((state: RootState) => state.appointment);
    const dispatch = useDispatch();

    useEffect(() => setNewNote(scheduleNote.Note), [scheduleNote]);

    const saveNote = async () => {
        setSaving(true);
        dispatch(saveScheduleNotes(scheduleNote.Id, memberId, newNote));
        setSaving(false);
    };

    const handleClose = () => {
        setNotesEdit(false);
    };

    useEffect(() => {
        dispatch(fetchScheduleNotes(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId]);

    return (
        <>
            <Paper variant="outlined" style={{ minHeight: 40, maxHeight: 600, overflowY: 'auto' }}>
                <Typography style={{ margin: 5, whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{newNote}</Typography>
            </Paper>

            <Dialog fullWidth open={notesEdit} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <DialogTitle>Scheduling Notes</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{ marginTop: 5 }}
                        id="outlined-multiline-static"
                        label="Edit Scheduling Note"
                        multiline
                        rows={20}
                        fullWidth
                        placeholder="Enter member schedule notes..."
                        variant="outlined"
                        value={newNote}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewNote(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={saving}
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => {
                            saveNote();
                            handleClose();
                        }}
                    >
                        Update Note
                    </Button>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SchedulerNotes;
