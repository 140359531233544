import {
    Box,
    Button,
    Divider,
    FormControl,
    FormGroup,
    IconButton,
    InputAdornment,
    InputLabel,
    Modal,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { saveLoginSetup, setLoginSetup, setShowLoginSetup } from '../../../store/memberDetail.slice';
import { modalStyle } from '../../../utils/constants';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export default function LoginSetup() {
    const dispatch = useDispatch();
    const { loginSetup, showLoginSetup } = useSelector((state: RootState) => state.memberDetail);
    const [error, setError] = useState({ Email: false });
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const save = () => {
        if (valid()) {
            dispatch(saveLoginSetup(loginSetup));
        }
    };

    const valid = () => {
        if (!loginSetup.Email) {
            setError({ ...error, Email: !loginSetup.Email });
            return false;
        } else {
            return true;
        }
    };

    const updateLoginSetup = (property: any, value: any) => {
        let newLoginSetup = { ...loginSetup };
        newLoginSetup[property] = value;
        dispatch(setLoginSetup(newLoginSetup));
    };

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Stack spacing={3}>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                error={error.Email}
                                helperText={error.Email ? 'Required' : ''}
                                fullWidth
                                label="Email/User Name"
                                variant="outlined"
                                value={loginSetup.Email}
                                onChange={(e: any) => {
                                    updateLoginSetup('Email', e.target.value);
                                }}
                            />
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e: any) => {
                                        updateLoginSetup('Password', e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                fullWidth
                                label="Tablet ID"
                                variant="outlined"
                                value={loginSetup.TabletId}
                                onChange={(e: any) => {
                                    updateLoginSetup('TabletId', e.target.value);
                                }}
                            />
                            <TextField
                                fullWidth
                                label="SIM"
                                variant="outlined"
                                value={loginSetup.Sim}
                                onChange={(e: any) => {
                                    updateLoginSetup('Sim', e.target.value);
                                }}
                            />
                        </Stack>
                    </FormGroup>
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={save}>
                            Save
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                dispatch(setShowLoginSetup(false));
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <Modal
            open={showLoginSetup}
            onClose={() => {
                dispatch(setShowLoginSetup(false));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Stack spacing={2}>
                    <Typography variant="h5">Login Setup</Typography>
                    <Divider />
                    {renderBody()}
                </Stack>
            </Box>
        </Modal>
    );
}
