import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Radio, RadioGroup, FormControlLabel, Button, Box, CircularProgress, Stack, Collapse, Typography, Divider } from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { copyObject } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchVisionAndHearingById, saveVisionAndHearing } from '../../../store/hra.slice';
import { YesNoAnswers } from '../../../utils/assessments';

const VisionAndHearingAssessment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { visionAndHearing, isFetching, isSaving: isSavingHra } = useSelector((state: RootState) => state.hra);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingHra || isSavingEngagementDraft;

    useEffect(() => {
        if (Boolean(engagementDraft.VisionAndHearingId)) {
            dispatch(fetchVisionAndHearingById(engagementDraft.VisionAndHearingId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveVisionAndHearing({ ...visionAndHearing, Status: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeAnswer = (answer: any, propName: string) => {
        let data = copyObject(visionAndHearing);
        data[propName] = answer;
        data.MemberId = memberId;

        dispatch(saveVisionAndHearing(data));
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" sx={{ pt: 4 }}>
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form">
                        <div>
                            <Typography variant="h6" component="label">
                                Vision & Hearing
                            </Typography>
                            <Divider sx={{ mb: 3 }} />
                        </div>
                        <Grid container spacing={2}>
                            <Grid item lg={3} md={5} sm={6} display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="label">
                                    Vision impairment
                                </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6}>
                                <RadioGroup
                                    row
                                    name="HasVisionImpairment"
                                    value={String(visionAndHearing.HasVisionImpairment)}
                                    onChange={(e) => handleOnChangeAnswer(e.target.value === 'true', e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Collapse in={visionAndHearing.HasVisionImpairment} timeout="auto" unmountOnExit>
                            <React.Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={0.5} />
                                    <Grid item lg={2.5} md={4.5} sm={5.5} display="flex" alignItems="center">
                                        <Typography variant="subtitle1" component="label">
                                            Glasses used?
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <RadioGroup
                                            row
                                            name="IsGlassesUsed"
                                            value={String(visionAndHearing.IsGlassesUsed)}
                                            onChange={(e) => handleOnChangeAnswer(e.target.value === 'true', e.target.name)}
                                        >
                                            {YesNoAnswers.map((type, index) => {
                                                return <FormControlLabel key={`aids-used-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                            })}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </Collapse>
                        <Grid container spacing={2} sx={{ mt: 3 }}>
                            <Grid item lg={3} md={5} sm={6} display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="label">
                                    Hearing impairment
                                </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6}>
                                <RadioGroup
                                    row
                                    name="HasHearingImpairment"
                                    value={String(visionAndHearing.HasHearingImpairment)}
                                    onChange={(e) => handleOnChangeAnswer(e.target.value === 'true', e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Collapse in={visionAndHearing.HasHearingImpairment} timeout="auto" unmountOnExit>
                            <React.Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={0.5} />
                                    <Grid item lg={2.5} md={4.5} sm={5.5} display="flex" alignItems="center">
                                        <Typography variant="subtitle1" component="label">
                                            Aids used?
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <RadioGroup
                                            row
                                            name="IsAidsUsed"
                                            value={String(visionAndHearing.IsAidsUsed)}
                                            onChange={(e) => handleOnChangeAnswer(e.target.value === 'true', e.target.name)}
                                        >
                                            {YesNoAnswers.map((type, index) => {
                                                return <FormControlLabel key={`aids-used-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                            })}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </Collapse>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {Boolean(engagementDraft.VisionAndHearingId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Vision And Hearing"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Vision and Hearing is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Vision And Hearing"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Vision and Hearing?"
                />
            )}
        </Grid>
    );
};

export default VisionAndHearingAssessment;
