import { Grid, Paper, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import GenericSaveButton from '../../AdminOnboarding/Buttons/GenericSaveButton';
import { IRequest } from '../interfaces';
import { requestStatusMapping } from '../mappings/RequestStatusMapping';
import AddCommentModal from './AddCommentModal';
import RequestsComment from './RequestsComment';

const RequestsCommentsBox: React.FC<IRequest & { isAssessment?: boolean }> = ({ Id, Comments, isAssessment }) => {
    const { savingComment, selectedRequest } = useSelector((state: RootState) => state.requests);
    const comments = useMemo(() => {
        return Comments === undefined ? (
            <Typography variant="h6">No Notes to Display</Typography>
        ) : (
            [...Comments]
                ?.sort((a, b) => new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime())
                .map(({ ProviderName, CreatedOn, Message }) => {
                    return (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <RequestsComment name={ProviderName} date={new Date(CreatedOn)} comment={Message} />
                        </Grid>
                    );
                })
        );
    }, [Comments]);

    const [showCommentModal, setShowCommentModal] = useState<boolean>(false);

    const handleCommentModalCancel = (): void => {
        setShowCommentModal(false);
    };

    const handleCommentSave = () => {
        setShowCommentModal(true);
        // dispatch(saveNewComment(newComment, Id));
        // setNewComment("<p><br></p>");
        // editorRef.current.setEditorContents(
        //   editorRef.current.getEditor(),
        //   "<p><br></p>"
        // );
    };

    return (
        <Paper variant="outlined" style={{ overflowY: 'auto', maxHeight: '61vh', minHeight: 900 }}>
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                        Engagement Notes
                    </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2} style={{ padding: 10 }}>
                        <Grid item lg={12} md={12} sm={12} xs={12} hidden={(selectedRequest?.Status ?? 1) === requestStatusMapping.Archived.value}>
                            <AddCommentModal
                                open={showCommentModal}
                                handleClose={handleCommentModalCancel}
                                handleSave={handleCommentModalCancel}
                                isAssessment={isAssessment}
                            />
                            <GenericSaveButton
                                saving={savingComment}
                                fullWidth
                                onClick={handleCommentSave}
                                disabled={[requestStatusMapping.Completed.value, requestStatusMapping.Archived.value].includes(selectedRequest.Status)}
                            >
                                Add Engagement Note
                            </GenericSaveButton>
                        </Grid>
                        {React.Children.toArray(comments)}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RequestsCommentsBox;
