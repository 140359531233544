import { useCallback, useEffect, useState, useRef } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, IconButton, Tabs, Tab } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'simplebar/dist/simplebar.min.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import '../Widgets.css';

import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { useChpContext } from '../../../hooks/chpContext';
import { fetchPopulations } from '../../Population/PopulationService';
import { fetchRevenueMetrics, fetchRevenueMetricsComparisonCount, MenuProps } from '../../Widgets/WidgetService';
import RevenueMetrics from '../../../Models/Widget/RevenueMetrics.model';
import Population from '../../../Models/Population/Population.model';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';
import { styled } from '@mui/material/styles';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 800,
    },
});

const RevenueMetricsSumamry = () => {
    var priorMonth = new Date();
    priorMonth.setDate(0);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const { cities, getCities } = useChpContext();
    const [populations, setPopulations] = useState<Population[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<string>('All');
    const [selectedPopulation, setSelectedPopulation] = useState<number>(0);
    const [selectedCity, setSelectedCity] = useState<string>('All');
    const [revenueMetrics, setRevenueMetrics] = useState<RevenueMetrics>();
    const [activeComparisonCount, setActiveComparisonCount] = useState<number>(0);
    const [offboardingComparisonCount, setOffboardingComparisonCount] = useState<number>(0);
    const [scheduledComparisonCount, setScheduledComparisonCount] = useState<number>(0);
    const [isDataShow, setIsDataShow] = useState<boolean>(false);
    const [isActiveDataShow, setIsActiveDataShow] = useState<boolean>(false);
    const [isOffboardingDataShow, setIsOffboardingDataShow] = useState<boolean>(false);
    const [isSchedulingDataShow, setIsSchedulingDataShow] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(priorMonth);
    const { healthPlans } = useHealthPlanContext();
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [selectedTab, setSelectedTab] = useState(1);

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        getCities();

        setIsFetched(true);
    }, [isFetched, setIsFetched, getCities]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        const newRequestId = String(Math.random());
        (global as any).renewRequestId = newRequestId;
        const changeFilter = async () => {
            setIsDataShow(false);
            setIsActiveDataShow(false);
            setIsOffboardingDataShow(false);
            setIsSchedulingDataShow(false);
            let start = fromDate !== null ? new Date(fromDate).toDateString() : null;
            let end = toDate !== null ? new Date(toDate).toDateString() : null;

            fetchRevenueMetrics(selectedPlan, selectedPopulation, selectedCity, start, end)
                .then(async (response) => {
                    setRevenueMetrics(await response);
                })
                .finally(() => {
                    setIsDataShow(true);
                });

            fetchRevenueMetricsComparisonCount(selectedPlan, selectedPopulation, selectedCity, end, MemberStatusEnum.Active)
                .then(async (response) => {
                    if (newRequestId === (global as any).renewRequestId) {
                        setActiveComparisonCount(response);
                    }
                })
                .finally(() => {
                    setIsActiveDataShow(true);
                });

            fetchRevenueMetricsComparisonCount(selectedPlan, selectedPopulation, selectedCity, end, MemberStatusEnum.OffBoarding)
                .then(async (response) => {
                    if (newRequestId === (global as any).renewRequestId) {
                        setOffboardingComparisonCount(response);
                    }
                })
                .finally(() => {
                    setIsOffboardingDataShow(true);
                });

            fetchRevenueMetricsComparisonCount(selectedPlan, selectedPopulation, selectedCity, end, MemberStatusEnum.Scheduled)
                .then(async (response) => {
                    if (newRequestId === (global as any).renewRequestId) {
                        setScheduledComparisonCount(response);
                    }
                })
                .finally(() => {
                    setIsSchedulingDataShow(true);
                });
        };
        changeFilter();
    }, [selectedPlan, selectedPopulation, selectedCity, fromDate, toDate]);

    const healthPlanChange = async (healthPlanId: string) => {
        setPopulations([]);
        setSelectedPopulation(0);
        if (healthPlanId && healthPlanId !== 'All') {
            const populations = await fetchPopulations(healthPlanId);
            setPopulations(populations);
        }
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                            Revenue / Volume Metrics
                            <CustomWidthTooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Active Default :
                                        </Typography>
                                        <Typography variant="caption">Count of currently “Active” status members to date</Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Active Comparision :
                                        </Typography>
                                        <Typography variant="caption">Counts “Active” status changes in filtered month</Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Offboarding Default :
                                        </Typography>
                                        <Typography variant="caption">Count of “Offboarding” status members on current</Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Offboarding Comparision :
                                        </Typography>
                                        <Typography variant="caption">Counts “Offboarding” status changes in filtered month</Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Disenrolled from CHP :
                                        </Typography>
                                        <Typography variant="caption">
                                            Default & Comparison: Count of members who changed status to “DisenrolledFromCHP” in filtered month
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Refused to Continue Service :
                                        </Typography>
                                        <Typography variant="caption">
                                            Count of members who changed status to “RefusedToContinueServices” within date range
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Newly Onboarded :
                                        </Typography>
                                        <Typography variant="caption">
                                            Default & Comparison: Count of members who have an onboard date in filtered month
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Scheduled :
                                        </Typography>
                                        <Typography variant="caption">
                                            Default & Comparison: Displays members who have changed status to “Scheduled” on filtered month
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            FUHFUM In Progress :
                                        </Typography>
                                        <Typography variant="caption">
                                            Default & Comparison: Displays members who have changed status to “FUHFUMInProgress” on filtered month
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            FUHFUM Closed :
                                        </Typography>
                                        <Typography variant="caption">
                                            Default & Comparison: Displays members who have changed status to “FUHFUMClosed” on filtered month
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Disenrolled from HRA :
                                        </Typography>
                                        <Typography variant="caption">
                                            Default & Comparison: Displays members who have changed status to “DisenrolledfromHRA” on filtered month
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </CustomWidthTooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Health Plan</InputLabel>
                            <Select
                                value={selectedPlan}
                                label="Health Plan"
                                name="Health Plan"
                                onChange={(e) => {
                                    setSelectedPlan(e.target.value);
                                    healthPlanChange(e.target.value);
                                }}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {filteredHealthPlans.map((plan) => (
                                    <MenuItem key={plan.Id} value={plan.Id}>
                                        {plan.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Population</InputLabel>
                            <Select
                                value={selectedPopulation}
                                label="Population"
                                name="Population"
                                onChange={(e) => setSelectedPopulation(parseInt(e.target.value as any))}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="0">
                                    All
                                </MenuItem>
                                {populations.map((pop) => (
                                    <MenuItem key={pop.PopulationNumber} value={pop.PopulationNumber}>
                                        {pop.PopulationName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Geography</InputLabel>
                            <Select
                                value={selectedCity}
                                label="Geography"
                                name="Geography"
                                onChange={(e) => setSelectedCity(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {cities.map((city) => (
                                    <MenuItem key={city} value={city}>
                                        {city}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item md={4}>
                            <DesktopDatePicker
                                disabled={true}
                                label="Default Date"
                                value={Boolean(fromDate) ? fromDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => setFromDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <DesktopDatePicker
                                label="Comparison Date"
                                value={Boolean(toDate) ? toDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => setToDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} display={'flex'}>
                        <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={selectedTab} onChange={handleAlertsTabChange}>
                            <Tab value={1} label="Today vs Comparison Date" />
                            <Tab value={2} label="Current Month vs Previous Month" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} marginTop={2} height="40vh" sx={{ position: 'relative' }}>
                        {!isDataShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Members</TableCell>
                                            <TableCell align="center">Default #</TableCell>
                                            <TableCell align="center">Comparison #</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedTab === 1 ? (
                                            <>
                                                <TableRow>
                                                    <TableCell>Active</TableCell>
                                                    <TableCell align="center">{revenueMetrics.ActiveMembersCount}</TableCell>
                                                    <TableCell align="center">
                                                        {!isActiveDataShow ? <CircularProgress size={15} /> : activeComparisonCount}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Offboarding</TableCell>
                                                    <TableCell align="center">{revenueMetrics.OffboardingMembersCount}</TableCell>
                                                    <TableCell align="center">
                                                        {!isOffboardingDataShow ? <CircularProgress size={15} /> : offboardingComparisonCount}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Newly Onboarded</TableCell>
                                                    <TableCell align="center">{revenueMetrics.OnboardedMembersCount}</TableCell>
                                                    <TableCell align="center">{revenueMetrics.ComparedOnboardedMembersCount}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Onboarding Scheduled</TableCell>
                                                    <TableCell align="center">{revenueMetrics.OnboardScheduledCount}</TableCell>
                                                    <TableCell align="center">
                                                        {!isSchedulingDataShow ? <CircularProgress size={15} /> : scheduledComparisonCount}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ) : (
                                            <>
                                                <TableRow>
                                                    <TableCell>Disenrolled from CHP</TableCell>
                                                    <TableCell align="center">{revenueMetrics.DisenrolledFromChpMembersCount}</TableCell>
                                                    <TableCell align="center">{revenueMetrics.ComparedDisenrolledFromChpMembersCount}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Refused to Continue Services</TableCell>
                                                    <TableCell align="center">{revenueMetrics.RefusedToContinueServicesMembersCount}</TableCell>
                                                    <TableCell align="center">{revenueMetrics.ComparedRefusedToContinueServiceMembersCount}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>FUHFUM In Progress</TableCell>
                                                    <TableCell align="center">{revenueMetrics.FuhFumInprogressCount}</TableCell>
                                                    <TableCell align="center">{revenueMetrics.ComparedFuhFumInprogressCount}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>FUHFUM Closed</TableCell>
                                                    <TableCell align="center">{revenueMetrics.FuhFumClosedCount}</TableCell>
                                                    <TableCell align="center">{revenueMetrics.ComparedFuhFumClosedCount}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Disenrolled from HRA</TableCell>
                                                    <TableCell align="center">{revenueMetrics.DisenrolledFromHRACount}</TableCell>
                                                    <TableCell align="center">{revenueMetrics.ComparedDisenrolledFromHRACount}</TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default RevenueMetricsSumamry;
