import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
export const ConfirmationDialog = (props) => {
    const { open, isLoading, title, onClose, onConfirm, message, hideCancelButton, isOverride } = props;
    const maxWidth = title === 'This member currently has an open transition.' ? 'md' : 'sm';
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth} sx={{ p: 2 }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={onClose} variant="outlined" sx={{ minWidth: '120px' }} disabled={isLoading} hidden={hideCancelButton}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} variant="contained" color={isOverride ? 'error' : 'primary'} sx={{ minWidth: '120px' }} disabled={isLoading}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};
