import RichTextEditor from '@mantine/rte';
import { AddCircleOutline, ExpandMore, ExpandLess } from '@mui/icons-material';
import {
    Button,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { Children, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { RootState } from '../../../reducers';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { getRequestZCode, saveNewComment } from '../../../store/requests.slice';
import { summaryNoteType } from '../../../utils/assessments';
import { currencyFormatter, ProviderId } from '../../../utils/constants';
import SignOffWrapper from '../../SignOffWrapper/SignOffWrapper';
import AddResourceItemModal from '../RequestResolutionModal/AddResourceItemModal';
import ResourceItemRow from '../RequestResolutionModal/ResourceItemRow';

const DEFAULT_CPT_CODE = 'T1016' as const;
export interface IAddCommentModalProps {
    open: boolean;
    handleClose: () => void;
    handleSave: () => void;
    isAssessment?: boolean;
}

const AddCommentModal: React.FC<IAddCommentModalProps> = ({ open, handleClose, handleSave, isAssessment }) => {
    const dispatch = useDispatch();
    const {
        selectedRequest: { RequestFor, Id, Zcode, ResourceServiceItems },
        selectedRequest: request,
    } = useSelector((state: RootState) => state.requests);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const handleSignOffClick = (notes: string): void => {
        dispatch(saveNewComment(notes, Id));
        handleSave();
    };
    const [summaryNote, setSummaryNote] = useState<string>('');
    const [addResourceModalOpen, setAddResourceModalOpen] = useState<boolean>(false);
    const [collapsedIn, setCollapsedIn] = useState<boolean>(true);

    const addCommentNote = isAssessment ? (
        <>
            <Typography style={{ textAlign: 'center' }}>Assessment/Request Summary Note</Typography>
            <RichTextEditor value={summaryNote} onChange={(value) => setSummaryNote(value)} />
        </>
    ) : (
        <SignOffWrapper memberId={RequestFor.Id} requestId={Id} zcode={Zcode} finalizeSignOff={handleSignOffClick} />
    );

    const totalResourceCost = useMemo(
        () => ResourceServiceItems.reduce((a, b) => a + b.PayerList.reduce((a, b) => a + b.Amount, 0), 0),
        [ResourceServiceItems]
    );

    const handleSaveNote = async (): Promise<void> => {
        const _zcode = Zcode ?? (await getRequestZCode(Id));
        if (summaryNote.replace(/<[^>]*>?/gm, '').trim().length !== 0) {
            let newEngagementDraft = { ...engagementDraft };
            if (!newEngagementDraft.SummaryNotes.find((note) => note.Zcode === _zcode)) {
                newEngagementDraft.SummaryNotes = [
                    ...newEngagementDraft.SummaryNotes,
                    {
                        Id: String(summaryNoteType.Request.value),
                        Note: summaryNote,
                        Zcode: _zcode,
                        Type: SummaryNoteTypeEnum.Request,
                        IsConfirm: true,
                        CptCode: DEFAULT_CPT_CODE,
                        CreatedOn: new Date(),
                        UpdatedOn: new Date(),
                        ReferenceIds: [Id],
                        CreatedBy: ProviderId,
                        UpdatedBy: ProviderId,
                    } as any,
                ];
            } else {
                newEngagementDraft.SummaryNotes = newEngagementDraft.SummaryNotes.map((note) => {
                    if (note.Zcode === _zcode) {
                        return {
                            ...note,
                            Note: `${note.Note} \n Additional Request Note Added ${new Date().toLocaleString()}\n${summaryNote}`,
                        };
                    } else {
                        return note;
                    }
                });
            }
            dispatch(saveEngagementDraft(newEngagementDraft));
            dispatch(saveNewComment(summaryNote, Id));
            setSummaryNote('');
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={false}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Add Comment</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ paddingBottom: 5 }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {Id?.length > 0 ? (
                            <AddResourceItemModal open={addResourceModalOpen} onClose={() => setAddResourceModalOpen(false)} request={request} />
                        ) : null}
                        <Divider>
                            <Tooltip title="Click to add resources to the list" arrow>
                                <Chip
                                    label="Resources List"
                                    clickable
                                    color="primary"
                                    icon={<AddCircleOutline />}
                                    onClick={() => setAddResourceModalOpen(true)}
                                />
                            </Tooltip>
                        </Divider>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Paper variant="outlined" style={{ padding: 5, marginTop: 10 }}>
                                    {ResourceServiceItems?.length > 0 ? (
                                        <List dense={true}>
                                            <ListItem divider={collapsedIn}>
                                                {collapsedIn ? (
                                                    <IconButton onClick={() => setCollapsedIn(!collapsedIn)}>
                                                        <ExpandMore />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => setCollapsedIn(!collapsedIn)}>
                                                        <ExpandLess />
                                                    </IconButton>
                                                )}
                                                <Grid container justifyContent={'space-evenly'} alignItems="center">
                                                    <Grid item lg md sm xs>
                                                        <Typography variant="subtitle2">{`Total Contributions Toward Request: ${currencyFormatter.format(
                                                            totalResourceCost
                                                        )}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <Collapse in={collapsedIn}>
                                                <List component={'div'} disablePadding>
                                                    {Children.toArray(
                                                        ResourceServiceItems.map((resource, index) => (
                                                            <ResourceItemRow {...resource} divider={index !== ResourceServiceItems.length - 1} />
                                                        ))
                                                    )}
                                                </List>
                                            </Collapse>
                                        </List>
                                    ) : (
                                        <Typography variant="h6" style={{ textAlign: 'center' }}>
                                            No Resources Added
                                        </Typography>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {addCommentNote}
            </DialogContent>
            <DialogActions>
                {!isAssessment || summaryNote.replace(/<[^>]*>?/gm, '').trim().length !== 0 ? null : (
                    <Typography variant="caption" color="error" style={{ paddingRight: 20 }}>
                        A note is required before saving.
                    </Typography>
                )}
                {!isAssessment ? null : (
                    <Button variant="contained" onClick={handleSaveNote} color="success">
                        Save Summary Note
                    </Button>
                )}
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCommentModal;
