import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    Checkbox,
    IconButton,
    TextField,
    Button,
    Stack,
    Grid,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    Autocomplete,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import classes from '../CarePlans/Styles';
import {
    addIntervention,
    updateInterventions,
    getInterventionsByKeyword,
    getInterventionsByMember,
    removeIntervention,
    setMemberInterventions,
    setNewInterventionName,
    toggleShowInterventionDeleteAlert,
    toggleShowInterventionEditMode,
} from '../../../store/carePlan.slice';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { copyObject } from '../../../utils/common';
import { Intervention } from '../../../Models/CarePlans/Intervention.model';
import { gotoPreviousMenu } from '../../../store/assessment.slice';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { SummaryNote } from '../../../Models/DocumentEngagements/SummaryNote.model';
import { assessmentStatusEnum } from '../../../utils/assessments';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';

const Interventions: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [selectedInterventionId, setSelectedInterventionId] = useState<string>('');
    const [editedInterventionIds, setEditedInterventionIds] = useState<string[]>([]);
    const dispatch = useDispatch();
    const {
        memberInterventions,
        interventions,
        newInterventionName,
        showInterventionDeleteAlert,
        isSavingIntervention,
        showInterventionEditMode,
        isFetching,
        isFetchingInterventions,
        isUpdatingIntervention,
    } = useSelector((state: RootState) => state.carePlan);
    const { engagementDraft, isSaving } = useSelector((state: RootState) => state.documentEngagement);
    const isUpdate = memberInterventions.some((s) => s.IsSelected) || false;

    useEffect(() => {
        dispatch(getInterventionsByMember(memberId));
    }, [dispatch, memberId]);

    const handleOnEditIntervention = (id: string, val: any, index: number) => {
        setEditedInterventionIds([...editedInterventionIds, id]);
        let data = copyObject(memberInterventions);
        data[index].Name = val;
        dispatch(setMemberInterventions(data));
    };

    const handleOnToggleSelectIntervention = (isSelected: boolean, index: number) => {
        let data = copyObject(memberInterventions);
        data[index].IsSelected = isSelected;
        dispatch(setMemberInterventions(data));
    };

    const handleOnClickAddNew = () => {
        if (Boolean(newInterventionName)) {
            dispatch(addIntervention(memberId, newInterventionName));
        }
    };

    const handleOnClickSave = () => {
        if (showInterventionEditMode) {
            if (Boolean(editedInterventionIds.length)) {
                dispatch(updateInterventions(memberInterventions.filter((p) => editedInterventionIds.some((e) => e === p.Id))));
            } else {
                dispatch(toggleShowInterventionEditMode(false));
            }
        } else {
            dispatch(toggleShowInterventionEditMode(true));
            setEditedInterventionIds([]);
        }
    };

    const handleOnClickDelete = (intervention: Intervention) => {
        setSelectedInterventionId(intervention.Id);
        dispatch(toggleShowInterventionDeleteAlert(true));
    };

    const toggleOnNewIntervention = () => {
        dispatch(setNewInterventionName(''));
        setOpenNew(!openNew);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        let data = copyObject(engagementDraft);
        data.SummaryNotes = data.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.Intervention) || [];
        memberInterventions
            .filter((g) => g.IsSelected)
            .forEach((intervention) => {
                const summaryNote = {
                    Id: intervention.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: intervention.Name,
                    Type: SummaryNoteTypeEnum.Intervention,
                    IsConfirm: false,
                    ReferenceIds: [],
                    OrderBy: 21,
                    DisplayName: 'Intervention',
                    CategoryGroupId: '',
                } as SummaryNote;
                data.SummaryNotes.push(summaryNote);
            });

        if (isWithoutComplete) {
            data.InterventionStatus = assessmentStatusEnum.COMPLETED;
        }
        dispatch(saveEngagementDraft(data, true));
    };

    const renderIntervention = (intervention: Intervention, index: number) => {
        return (
            <TableRow key={intervention.Id}>
                {showInterventionEditMode ? (
                    <TableCell scope="row">
                        <div className="d-flex-row">
                            <span className="count">{index + 1}</span>
                            <TextField
                                variant="outlined"
                                size="small"
                                sx={classes.textInput}
                                fullWidth
                                name="Name"
                                value={intervention.Name}
                                onChange={(e) => handleOnEditIntervention(intervention.Id, e.target.value, index)}
                            />
                        </div>
                    </TableCell>
                ) : (
                    <TableCell scope="row">
                        <div className="d-flex-row">
                            <span className="count">{index + 1}</span> {intervention.Name}
                        </div>
                    </TableCell>
                )}
                {showInterventionEditMode ? null : (
                    <TableCell width="110px">
                        <Checkbox
                            checked={intervention.IsSelected}
                            name="IsSelected"
                            size="small"
                            onChange={(e) => handleOnToggleSelectIntervention(e.target.checked, index)}
                        />
                    </TableCell>
                )}
                {showInterventionEditMode ? (
                    <TableCell width="80px">
                        <IconButton onClick={() => handleOnClickDelete(intervention)}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    </TableCell>
                ) : (
                    <TableCell width="140px"></TableCell>
                )}
            </TableRow>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Grid item className="content-form">
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item className="content-form">
                        <TableContainer sx={classes.tableContainer}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div className="d-flex-row">
                                                <Typography variant="h4" sx={classes.planTitle}>
                                                    Interventions
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell width="120px">Select Intervention</TableCell>
                                        <TableCell width="140px" align="right">
                                            <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                                <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={handleOnClickSave}>
                                                    {isUpdatingIntervention ? (
                                                        <CircularProgress size={18} />
                                                    ) : showInterventionEditMode ? (
                                                        <DoneIcon />
                                                    ) : (
                                                        <EditIcon />
                                                    )}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={classes.iconMainButton}
                                                    onClick={toggleOnNewIntervention}
                                                >
                                                    {openNew ? <KeyboardArrowUpIcon /> : <AddIcon />}
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            {memberInterventions?.length ? (
                                <Table size="small">
                                    <TableBody>{memberInterventions?.map(renderIntervention)}</TableBody>
                                </Table>
                            ) : !openNew ? (
                                <div className="no-data-container">No interventions to display</div>
                            ) : null}
                        </TableContainer>
                        <Collapse in={openNew} timeout="auto" unmountOnExit>
                            <TableContainer sx={classes.tableContainer}>
                                <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell scope="row">
                                                <Autocomplete
                                                    isOptionEqualToValue={(option, value) => option === value}
                                                    getOptionLabel={(option) => option}
                                                    value={newInterventionName as any}
                                                    options={interventions?.map((i) => i.Name) || []}
                                                    size="small"
                                                    freeSolo
                                                    onChange={(e, value: any) => dispatch(setNewInterventionName(value))}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className="hide-textinput-label"
                                                            variant="outlined"
                                                            placeholder="Enter new intervention here"
                                                            value={newInterventionName}
                                                            onChange={(e) => dispatch(getInterventionsByKeyword(e.target.value))}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {isFetchingInterventions ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell width="140px">
                                                <Box sx={classes.actionButtons}>
                                                    <IconButton onClick={handleOnClickAddNew} disabled={!Boolean(newInterventionName)}>
                                                        {isSavingIntervention ? <CircularProgress size={18} /> : <SaveIcon color="primary" />}
                                                    </IconButton>
                                                    <IconButton onClick={toggleOnNewIntervention}>
                                                        <HighlightOffSharpIcon color="error" />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                        <AlertDialog
                            open={showInterventionDeleteAlert}
                            isLoading={isSavingIntervention}
                            autoHideOnConfirmed={false}
                            okText="Delete"
                            onConfirm={() => dispatch(removeIntervention(selectedInterventionId))}
                            onClose={() => dispatch(toggleShowInterventionDeleteAlert(false))}
                            message="Are you sure want to delete this intervention?"
                        />
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className="mr-3"
                            disabled={isSaving}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSaving}
                            endIcon={isSaving ? <CircularProgress size={18} /> : <ArrowForwardIosIcon />}
                            onClick={() => setShowSaveAlert(true)}
                        >
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {isUpdate ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Intervention"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    showBottomCenter={true}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Intervention is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Intervention"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    showBottomCenter={true}
                    message="Are you sure to proceed without completing Intervention?"
                />
            )}
        </Grid>
    );
};

export default Interventions;
