import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, Divider, TableContainer, Table, TableRow, TableCell, TableBody, Typography, Paper, Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { getMemberBudget, saveMemberBudget, getMemberLastBudget, saveMemberBudgetForOtherAssessment } from '../../../store/memberBudget.slice';
import IncomeForm from './IncomeForm';
import ExpensesForm from './ExpensesForm';
import moment from 'moment';

const BudgetForm: React.FC<{ memberId: string; isOtherAssessment?: boolean }> = ({ memberId, isOtherAssessment }) => {
    const params = useParams();
    memberId = params.memberId;
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { memberAssessment } = useSelector((state: RootState) => state.assessment);
    const { memberBudget, isFetching, isSaving: isSavingMemberBudget, isChanged } = useSelector((state: RootState) => state.memberBudget);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingEngagementDraft || isSavingMemberBudget;

    useEffect(() => {
        if (isOtherAssessment) dispatch(getMemberLastBudget(memberId));
        else dispatch(getMemberBudget(engagementDraft.BudgetFormId, memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isChanged || isWithoutComplete) {
            dispatch(saveMemberBudget({ ...memberBudget, MemberId: memberId }, isWithoutComplete));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnSave = () => {
        dispatch(saveMemberBudgetForOtherAssessment({ ...memberBudget, MemberId: memberId }));
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" padding={isOtherAssessment ? 4 : null}>
            {isFetching ? (
                <Grid item className="content-form">
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item className="content-form">
                        <Typography variant="h6" component="label">
                            Monthly Budget Form
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        <IncomeForm />
                        <ExpensesForm />
                        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                            <Table>
                                <TableBody>
                                    <TableRow hover>
                                        <TableCell scope="row">
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <strong>
                                                        Total Income &nbsp;
                                                        <Typography variant="subtitle1" component="label" color="green">
                                                            <NumericFormat
                                                                displayType="text"
                                                                value={memberBudget.TotalIncome}
                                                                prefix={'$'}
                                                                thousandSeparator=","
                                                            />
                                                        </Typography>
                                                    </strong>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <strong>
                                                        Total Expenses &nbsp;
                                                        <Typography variant="subtitle1" component="label" color="darkred">
                                                            <NumericFormat
                                                                displayType="text"
                                                                value={memberBudget.TotalExpenses}
                                                                prefix={'$'}
                                                                thousandSeparator=","
                                                            />
                                                        </Typography>
                                                    </strong>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <strong>
                                                        Total Money &nbsp;
                                                        <Typography variant="subtitle1" component="label" color="green">
                                                            <NumericFormat
                                                                displayType="text"
                                                                value={memberBudget.SpendingMoney}
                                                                prefix={'$'}
                                                                thousandSeparator=","
                                                            />
                                                        </Typography>
                                                    </strong>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {isOtherAssessment ? (
                        <Grid item className="content-footer">
                            <Stack columnGap={2} flexDirection="row" justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSaving}
                                    endIcon={isSaving ? <CircularProgress size={18} /> : <SaveIcon />}
                                    onClick={handleOnSave}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    ) : (
                        <Grid item justifyContent="flex-end" className="content-footer">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ marginRight: 2 }}
                                disabled={isSaving}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSaving}
                                endIcon={isSaving ? <CircularProgress size={18} /> : <ArrowForwardIosIcon />}
                                onClick={handleOnClickSave}
                            >
                                Next
                            </Button>
                        </Grid>
                    )}
                </>
            )}
            {Boolean(engagementDraft.BudgetFormId) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Budget Form"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    showBottomCenter={true}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Budget Form is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Budget Form"
                    buttonsDirection="column-reverse"
                    showBottomCenter={true}
                    cancelText="Go Back"
                    skipText="Budget Form Not Reviewed"
                    okText="Agree With Budget Form"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message={`Last Budget Form completed on ${moment(memberAssessment.AssessmentDate).format('MM/DD/yyyy')}.`}
                />
            )}
        </Grid>
    );
};

export default BudgetForm;
