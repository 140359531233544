import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    TextField,
    Grid,
    FormLabel,
    FormControlLabel,
    Stack,
    Checkbox,
    Typography,
} from '@mui/material';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { AssessmentCategory, Question } from '../../../Models/Assessment/AssessmentCategory.model';
import classes from '../CategoryQuestions/Styles';
import _ from 'lodash';
import { copyObject, preventNumberInputMaxValue } from '../../../utils/common';

const VitalSignsGeneralQuestions: React.FC<{ categories: AssessmentCategory[]; onChange: Function }> = ({ categories, onChange }) => {
    const handleOnUpdateAnswer = (category, isSelected, questionIndex, optionIndex) => {
        let data = copyObject(category);
        data.Questions[questionIndex].Options.forEach((option, index) => {
            if (index === optionIndex) {
                option.IsSelected = isSelected;
            } else {
                option.IsSelected = false;
            }
        });
        data.Questions[questionIndex].Options[optionIndex].IsSelected = isSelected;
        onChange(data);
    };

    const handleOnUpdateComments = (category: AssessmentCategory, comments: string) => {
        if (Boolean(category)) {
            let data = copyObject(category);
            data.Comments = comments;
            onChange(data);
        }
    };

    const handleOnChangeAnswerText = (category: AssessmentCategory, answerText: string, questionIndex: number) => {
        let data = copyObject(category);
        data.Questions[questionIndex].AnswerText = answerText;
        onChange(data);
    };

    const prepareVitalSignsList = () => {
        let questions = copyObject(categories[0]?.Questions.filter((q) => q.Description !== 'BP-Standing') || []) as Question[];
        questions.forEach((q) => {
            q.Description = q.Description.replace('BP-Sitting', 'Blood Pressure-Diastolic')
                .replace('BP-Lying', 'Blood Pressure-Systolic')
                .replace('Resp-Rate', 'Respiration-Rate');
        });

        return questions;
    };

    const getFilteredVitalSignsList = () => {
        return _.chunk(prepareVitalSignsList(), 3);
    };

    const renderSignInput = (question: Question) => {
        const isHeight = question.Description.includes('Height');
        let feet = '';
        let inches = '';
        if (isHeight) {
            const heightValues = (question.AnswerText || '').split('.');
            feet = heightValues.length ? heightValues[0] : '';
            inches = heightValues.length > 1 ? heightValues[1] : '';
        }
        const splitedDescription = copyObject(question.Description?.split('-'));
        const isBloodGlucose = splitedDescription[0] === 'Blood Glucose';
        const firstCategoryQuestions = prepareVitalSignsList() || [];
        const index = firstCategoryQuestions.indexOf(firstCategoryQuestions.find((q) => q.Description === question.Description));

        return (
            <Box sx={classes.formControlGrp}>
                <FormLabel className={`vital-form-label ${isBloodGlucose && 'flex-none'}`}>{splitedDescription[0]}</FormLabel>
                {isHeight ? (
                    <Stack direction="row" spacing={2}>
                        <TextField
                            label="Ft"
                            variant="outlined"
                            fullWidth
                            defaultValue={feet}
                            size="small"
                            onBlur={(e) => handleOnChangeAnswerText(categories[0], `${e.currentTarget.value}${Boolean(inches) ? '.' : ''}${inches}`, index)}
                            type="number"
                            inputProps={{ min: 0, max: 9 }}
                            onKeyPress={(e) => preventNumberInputMaxValue(e, 9)}
                        />
                        <TextField
                            label="In"
                            variant="outlined"
                            defaultValue={inches}
                            size="small"
                            onBlur={(e) => handleOnChangeAnswerText(categories[0], `${feet}.${e.currentTarget.value}`, index)}
                            type="number"
                            fullWidth
                            inputProps={{ min: 0, max: 11 }}
                            onKeyPress={(e) => preventNumberInputMaxValue(e, 11)}
                        />
                    </Stack>
                ) : (
                    <TextField
                        label={splitedDescription[1]}
                        variant="outlined"
                        defaultValue={question.AnswerText}
                        fullWidth={true}
                        size="small"
                        onBlur={(e) => handleOnChangeAnswerText(categories[0], e.currentTarget.value, index)}
                    />
                )}
            </Box>
        );
    };

    return (
        <>
            <TableContainer component={Paper} sx={classes.customTable}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{categories[0].Name}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getFilteredVitalSignsList().map((qGroup, index) => (
                            <TableRow key={`group_questtion_row_${index}`}>
                                <TableCell component="th" scope="row">
                                    <Grid container spacing={2}>
                                        {qGroup.map((question, qIndex) => (
                                            <Grid key={`input-container-${qIndex}`} item xs={12 / qGroup.length}>
                                                {renderSignInput(question)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>{categories[1]?.Name}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories[1]?.Questions?.map((question, qIndex) => (
                            <TableRow key={`${question.Id}_${qIndex}`}>
                                {question.Description && (
                                    <TableCell component="th" scope="row">
                                        {question.Description}
                                    </TableCell>
                                )}
                                {question.Options?.map((option, oIndex) => (
                                    <TableCell key={`body_${option.Name}_${oIndex}`}>
                                        <FormControlLabel
                                            value={String(option.IsSelected)}
                                            sx={classes.smLabelRadioBtn}
                                            control={
                                                <Checkbox
                                                    checked={option.IsSelected}
                                                    size="small"
                                                    radioGroup={question.Description}
                                                    onChange={(e) => handleOnUpdateAnswer(categories[1], e.currentTarget.checked, qIndex, oIndex)}
                                                    icon={<PanoramaFishEyeIcon />}
                                                    checkedIcon={<RadioButtonCheckedIcon />}
                                                />
                                            }
                                            label={option.Name}
                                        />
                                    </TableCell>
                                ))}
                                {!question.Description && <TableCell scope="row"></TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography sx={classes.commentHeader}>Comments</Typography>
            <Box sx={classes.commentBox}>
                <TextField
                    placeholder="Comments..."
                    defaultValue={categories[1]?.Comments}
                    sx={classes.commentTextarea}
                    multiline
                    onBlur={(e) => handleOnUpdateComments(categories[1], e.currentTarget.value)}
                    rows={6}
                    fullWidth
                />
            </Box>
        </>
    );
};

export default VitalSignsGeneralQuestions;
