import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { AdlAssessment } from '../Models/HRA/AdlAssessment.model';
import { saveEngagementDraft } from './documentEngagement.slice';
import { setAssessmentError } from './assessment.slice';
import { SexualHealth } from '../Models/HRA/SexualHealth.model';
import { VisionAndHearing } from '../Models/HRA/VisionAndHearing.model';
import { FallRisk } from '../Models/HRA/FallRisk.model';
import { TobaccoAndDrugAssessment } from '../Models/HRA/TobaccoAndDrugAssessment.model';
import { PreventativeHealthAdvice } from '../Models/HRA/PreventativeHealthAdvice.model';
import { HraEducation } from '../Models/HRA/HraEducation.model';
import { AdditionalCHPCriteria } from '../Models/HRA/AdditionalCHPCriteria.model';

const hraSlice = createSlice({
    name: 'hra',
    initialState: {
        adlAssessment: new AdlAssessment(),
        sexualHealth: new SexualHealth(),
        fallRisk: new FallRisk(),
        visionAndHearing: new VisionAndHearing(),
        tobaccoAndDrug: new TobaccoAndDrugAssessment(),
        preventativeHealthAdvice: new PreventativeHealthAdvice(),
        additionalCHPCriteria: new AdditionalCHPCriteria(),
        education: new HraEducation(),
        isFetching: false,
        isSaving: false,
        isCloning: false,
    },
    reducers: {
        _setAdlAssessment(state, action) {
            state.adlAssessment = action.payload || new AdlAssessment();
        },
        _setSexualHealth(state, action) {
            state.sexualHealth = action.payload || new SexualHealth();
        },
        _setFallRisk(state, action) {
            state.fallRisk = action.payload || new FallRisk();
        },
        _setVisionAndHearing(state, action) {
            state.visionAndHearing = action.payload || new VisionAndHearing();
        },
        _setTobaccoAndDrugAssessment(state, action) {
            state.tobaccoAndDrug = action.payload || new TobaccoAndDrugAssessment();
        },
        _setPreventativeHealthAdvice(state, action) {
            state.preventativeHealthAdvice = action.payload || new PreventativeHealthAdvice();
        },
        _setAdditionalCHPCriteria(state, action) {
            state.additionalCHPCriteria = action.payload || new AdditionalCHPCriteria();
        },
        _setEducation(state, action) {
            state.education = action.payload || new HraEducation();
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsCloning(state, action) {
            state.isCloning = action.payload;
        },
        _resetHraAssessment(state, action) {
            state.adlAssessment = new AdlAssessment();
            state.sexualHealth = new SexualHealth();
            state.fallRisk = new FallRisk();
            state.visionAndHearing = new VisionAndHearing();
            state.tobaccoAndDrug = new TobaccoAndDrugAssessment();
            state.preventativeHealthAdvice = new PreventativeHealthAdvice();
            state.additionalCHPCriteria = new AdditionalCHPCriteria();
            state.education = new HraEducation();
            state.isFetching = false;
            state.isSaving = false;
            state.isCloning = false;
        },
    },
});

const {
    _setAdlAssessment,
    _setSexualHealth,
    _setFallRisk,
    _setVisionAndHearing,
    _setTobaccoAndDrugAssessment,
    _setPreventativeHealthAdvice,
    _setAdditionalCHPCriteria,
    _setEducation,
    _toggleIsFetching,
    _toggleIsSaving,
    _toggleIsCloning,
    _resetHraAssessment,
} = hraSlice.actions;

export const resetHraAssessment = (): AppThunk => (dispatch, getState) => {
    dispatch(_resetHraAssessment(''));
};

export const fetchAdlAssessmentByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetAdlAssessmentByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setAdlAssessment(response.data || new AdlAssessment()));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchAdlAssessmentById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetAdlAssessmentById/${id}`)
            .then((response) => {
                dispatch(_setAdlAssessment(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveAdlAssessment =
    (adlAssessment: AdlAssessment, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setAdlAssessment(adlAssessment));
        (Boolean(adlAssessment.Id)
            ? axios.put(`${API_URL}/hra/UpdateAdlAssessment`, adlAssessment)
            : axios.post(`${API_URL}/hra/CreateAdlAssessment`, adlAssessment)
        )
            .then((response) => {
                dispatch(_setAdlAssessment(response.data));
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft({ ...engagementDraft, AdlAssessmentId: response.data.Id }, gotoNext));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const fetchSexualHealthByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetSexualHealthByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setSexualHealth(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchSexualHealthById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetSexualHealthById/${id}`)
            .then((response) => {
                dispatch(_setSexualHealth(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveSexualHealth =
    (sexualHealth: SexualHealth, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setSexualHealth(sexualHealth));
        (Boolean(sexualHealth.Id)
            ? axios.put(`${API_URL}/hra/UpdateSexualHealth`, sexualHealth)
            : axios.post(`${API_URL}/hra/CreateSexualHealth`, sexualHealth)
        )
            .then((response) => {
                dispatch(_setSexualHealth(response.data));
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft({ ...engagementDraft, SexualHealthId: response.data.Id }, gotoNext));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const fetchFallRiskByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetFallRiskByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setFallRisk(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchFallRiskById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetFallRiskById/${id}`)
            .then((response) => {
                dispatch(_setFallRisk(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveFallRisk =
    (fallRisk: FallRisk, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setFallRisk(fallRisk));
        (Boolean(fallRisk.Id) ? axios.put(`${API_URL}/hra/UpdateFallRisk`, fallRisk) : axios.post(`${API_URL}/hra/CreateFallRisk`, fallRisk))
            .then((response) => {
                dispatch(_setFallRisk(response.data));
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft({ ...engagementDraft, FallRiskId: response.data.Id }, gotoNext));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const fetchVisionAndHearingByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetVisionAndHearingByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setVisionAndHearing(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchVisionAndHearingById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetVisionAndHearingById/${id}`)
            .then((response) => {
                dispatch(_setVisionAndHearing(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveVisionAndHearing =
    (visionAndHearing: VisionAndHearing, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setVisionAndHearing(visionAndHearing));
        (Boolean(visionAndHearing.Id)
            ? axios.put(`${API_URL}/hra/UpdateVisionAndHearing`, visionAndHearing)
            : axios.post(`${API_URL}/hra/CreateVisionAndHearing`, visionAndHearing)
        )
            .then((response) => {
                dispatch(_setVisionAndHearing(response.data));
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft({ ...engagementDraft, VisionAndHearingId: response.data.Id }, gotoNext));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const fetchTobaccoAndDrugAssessmentByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetTobaccoAndDrugAssessmentByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setTobaccoAndDrugAssessment(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchTobaccoAndDrugAssessmentById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetTobaccoAndDrugAssessmentById/${id}`)
            .then((response) => {
                dispatch(_setTobaccoAndDrugAssessment(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveTobaccoAndDrugAssessment =
    (tobaccoAndDrugAssessment: TobaccoAndDrugAssessment, gotoNext = false, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext || Boolean(callBack)));
        dispatch(_setTobaccoAndDrugAssessment(tobaccoAndDrugAssessment));
        (Boolean(tobaccoAndDrugAssessment.Id)
            ? axios.put(`${API_URL}/hra/UpdateTobaccoAndDrugAssessment`, tobaccoAndDrugAssessment)
            : axios.post(`${API_URL}/hra/CreateTobaccoAndDrugAssessment`, tobaccoAndDrugAssessment)
        )
            .then((response) => {
                dispatch(_setTobaccoAndDrugAssessment(response.data));
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft({ ...engagementDraft, TobaccoAndDrugAssessmentId: response.data.Id, IgnoreSaveSummaryNotes: true }, gotoNext));

                if (Boolean(callBack)) {
                    callBack(response.data);
                }
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const fetchPreventativeHealthAdviceByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetPreventativeHealthAdviceByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setPreventativeHealthAdvice(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchPreventativeHealthAdviceById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetPreventativeHealthAdviceById/${id}`)
            .then((response) => {
                dispatch(_setPreventativeHealthAdvice(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const savePreventativeHealthAdvice =
    (preventativeHealthAdvice: PreventativeHealthAdvice, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setPreventativeHealthAdvice(preventativeHealthAdvice));
        (Boolean(preventativeHealthAdvice.Id)
            ? axios.put(`${API_URL}/hra/UpdatePreventativeHealthAdvice`, preventativeHealthAdvice)
            : axios.post(`${API_URL}/hra/CreatePreventativeHealthAdvice`, preventativeHealthAdvice)
        )
            .then((response) => {
                dispatch(_setPreventativeHealthAdvice(response.data));
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft({ ...engagementDraft, PreventativeHealthAdviceId: response.data.Id }, gotoNext));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const fetchAdditionalCHPCriteriaByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetAdditionalCHPCriteriaByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setAdditionalCHPCriteria(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchAdditionalCHPCriteriaById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetAdditionalCHPCriteriaById/${id}`)
            .then((response) => {
                dispatch(_setAdditionalCHPCriteria(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveAdditionalCHPCriteria =
    (additionalCHPCriteria: AdditionalCHPCriteria, gotoNext = false, saveOnlyLocal = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setAdditionalCHPCriteria(additionalCHPCriteria));
        if (!saveOnlyLocal) {
            (Boolean(additionalCHPCriteria.Id)
                ? axios.put(`${API_URL}/hra/UpdateAdditionalCHPCriteria`, additionalCHPCriteria)
                : axios.post(`${API_URL}/hra/CreateAdditionalCHPCriteria`, additionalCHPCriteria)
            )
                .then((response) => {
                    dispatch(_setAdditionalCHPCriteria(response.data));
                    const engagementDraft = getState().documentEngagement.engagementDraft;
                    dispatch(saveEngagementDraft({ ...engagementDraft, AdditionalCHPCriteriaId: response.data.Id }, gotoNext));
                })
                .catch((err) => {
                    dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
                })
                .finally(() => {
                    dispatch(_toggleIsSaving(false));
                });
        }
    };

export const fetchHraEducation =
    (engagementDraftId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/hra/GetEducation`, { params: { engagementDraftId } })
            .then((response) => {
                dispatch(_setEducation(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveHraEducation =
    (education: HraEducation, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setEducation(education));
        (Boolean(education.Id) ? axios.put(`${API_URL}/hra/UpdateEducation`, education) : axios.post(`${API_URL}/hra/CreateEducation`, education))
            .then((response) => {
                dispatch(_setEducation(response.data));
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft({ ...engagementDraft, HraEducationId: response.data.Id, IgnoreSaveSummaryNotes: true }, gotoNext));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const cloneMember =
    (memberId: string, callBack: Function = null): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsCloning(true));
        axios
            .put(`${API_URL}/hra/CloneMember/${memberId}`)
            .then((response) => {
                if (callBack) {
                    callBack(response.data);
                }
            })
            .finally(() => {
                dispatch(_toggleIsCloning(false));
            });
    };

export default hraSlice.reducer;
