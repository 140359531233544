import { Box, Button, Divider, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import TelemonitoringDeviceRow from './TelemonitoringDeviceRow';
import { fetchDevices, saveDevices } from '../../../../store/memberDetail.slice';

const TelemonitoringDevices = () => {
    const dispatch = useDispatch();
    const { devices, selectedMember } = useSelector((state: RootState) => state.memberDetail);

    const save = () => {
        dispatch(saveDevices(devices));
    };

    const cancel = () => {
        dispatch(fetchDevices(selectedMember.Id));
    };

    return (
        <Box>
            <Divider style={{ marginBottom: '16px' }}></Divider>
            <TelemonitoringDeviceRow name="BP Cuff" device={devices.BpCuff}></TelemonitoringDeviceRow>
            <TelemonitoringDeviceRow name="Glucometer" device={devices.Glucometer}></TelemonitoringDeviceRow>
            <TelemonitoringDeviceRow name="Pulse Ox" device={devices.PulseOX}></TelemonitoringDeviceRow>
            <TelemonitoringDeviceRow name="Scale" device={devices.Scale}></TelemonitoringDeviceRow>
            <TelemonitoringDeviceRow name="Strips" device={devices.Strips}></TelemonitoringDeviceRow>
            <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                <Button className="button-120" variant="contained" onClick={save}>
                    Save Devices
                </Button>
                <Button className="button-120" variant="outlined" onClick={cancel}>
                    Cancel
                </Button>
            </Stack>
        </Box>
    );
};

export default TelemonitoringDevices;
