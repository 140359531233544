import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IPreventativeHealthAdvice extends BaseEntity {
    MemberId: string;
    BreastCancerScreering?: boolean;
    CervicalCancerScreening?: boolean;
    ColorectalCancerScreening?: boolean;
    Hypertension?: boolean;
    LungCancerScreening?: boolean;
    OsteoporosisScreening?: boolean;
    Status: StatusEnum;
}

export class PreventativeHealthAdvice implements IPreventativeHealthAdvice {
    Id: string;
    MemberId: string;
    BreastCancerScreering?: boolean;
    CervicalCancerScreening?: boolean;
    ColorectalCancerScreening?: boolean;
    Hypertension?: boolean;
    LungCancerScreening?: boolean;
    OsteoporosisScreening?: boolean;
    DrinkingRisk?: boolean;
    Status: StatusEnum;

    constructor() {
        this.MemberId = '';
    }
}
