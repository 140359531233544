import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import phoneFormat from '../../../../utils/phoneFormat';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { parentUrl } from '../../../../utils/constants';
import { apiString as API_URL } from '../../../../utils/constants';
import { localDateFormat } from '../../../../utils/timeFormat';
import WidgetLoading from '../../Shared/WidgetLoading';
import { isDefaultDate } from '../../../../utils/common';
import { ConsentDocumentTypeEnum } from '../../../../Enum/ConsentDocumentTypeEnum';
import Authorization from '../../../../utils/Authorization';

const consentTypeList = [
    { Id: ConsentDocumentTypeEnum.PartOne, Value: ConsentDocumentTypeEnum.PartOneDescription },
    { Id: ConsentDocumentTypeEnum.PartTwo, Value: ConsentDocumentTypeEnum.PartTwoDescription },
];

const MembersWithNoConsentForm = () => {
    const auth = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [teamId, setTeamId] = useState(Boolean(auth.TeamId) ? auth.TeamId : '-1');
    const [documentType, setDocumentType] = useState(1);
    const [membersWithNoConsent, setMembersWithNoConsent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const ref = useRef(null);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/documents`, '_blank');
    };

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_URL}/document/getmemberwithnoconsentform?teamId=${
                        teamId === '-1' || teamId === undefined ? '' : teamId
                    }&documentType=${documentType}&includeInActiveTeams=${showInActiveTeams}`
                );
                setMembersWithNoConsent(response.data);
                setIsLoading(false);
            } catch (error) {
                setMembersWithNoConsent([]);
                setIsLoading(false);
            }
        };
        changeFilter();
    }, [teamId, documentType, showInActiveTeams]);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setTeamId('-1');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                            Members With No Consent Form
                        </Typography>
                        <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                            &#40;{membersWithNoConsent.length}&#41;
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Active members who do not have a Consent for Care uploaded within the last year
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>

                    <Stack direction={'row'} marginTop={2} alignItems="center" spacing={2}>
                        <Grid item>
                            <FormControl className="form-control-200">
                                <InputLabel id="demo-simple-select-label">Teams</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={teams.find((el) => el.Id === teamId)?.Id || '-1'}
                                    label="Teams"
                                    size="small"
                                    disabled={isLoading ? true : false}
                                    onChange={(e) => {
                                        setTeamId(e.target.value);
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem value="-1">All</MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <FormControl className="form-control-200">
                                <InputLabel id="demo-select-label">Consent Type</InputLabel>
                                <Select
                                    labelId="demo-select-label"
                                    id="demo-select"
                                    value={documentType}
                                    label="Consent Type"
                                    size="small"
                                    disabled={isLoading ? true : false}
                                    onChange={(e) => setDocumentType(parseInt(e.target.value as any))}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    {consentTypeList.map((type) => {
                                        return (
                                            <MenuItem key={type.Id} value={type.Id}>
                                                {type.Value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Stack>

                    <Grid container spacing={2} marginTop={2} height="52vh" sx={{ overflowY: 'scroll', position: 'relative' }}>
                        {isLoading && <WidgetLoading />}
                        {membersWithNoConsent.length > 0 ? (
                            membersWithNoConsent.map((member) => {
                                return (
                                    <Grid key={member.Id} item xs={6}>
                                        <Card
                                            sx={{
                                                ':hover': {
                                                    cursor: 'pointer',
                                                    boxShadow: 10,
                                                    border: 0,
                                                },
                                                boxShadow: 0,
                                            }}
                                            onClick={() => openDocumentHandler(member.Id)}
                                            variant="outlined"
                                        >
                                            <CardContent
                                                sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-between' }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Member
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {member.FirstName} {member.LastName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Phone Number
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {phoneFormat(member.MobilePhone)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Health Plan
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {member.HealthPlan}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Date Last Seen
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {isDefaultDate(member.LastSeen) ? '-' : localDateFormat(new Date(member.LastSeen))}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Address
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {member.Address}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h6">No Matching Records</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default MembersWithNoConsentForm;
