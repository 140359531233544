import { AddCircleOutline } from '@mui/icons-material';
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid } from '@mui/material';
import axios from 'axios';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateRequest } from '../../../store/requests.slice';
import { apiString, ProviderId } from '../../../utils/constants';
import FormikTextField from '../../FormikFields/FormikTextField';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import { IResource } from '../../Resources/interfaces/IResource';
import { IRequest } from '../interfaces';
import { IResourceServiceItem } from '../interfaces/IResourceServiceItem';
import PayerRow from './PayerRow';

const EditResourceItemModal: React.FC<IBasicModalProps & { resourceItem: IResourceServiceItem; request: IRequest }> = (props) => {
    const dispatch = useDispatch();
    const { open, onClose, resourceItem, request } = props;
    const [resourceOptions, setResourceOptions] = useState<{ value: string; label: string }[]>([]);

    const fetchResourceOptions = async () => {
        const requestURL = `${apiString}/resource/all`;

        try {
            const response = await axios.get(requestURL);
            if (response?.data) {
                const resourceOptions: {
                    value: string;
                    label: string;
                }[] = (response.data as IResource[]).map(({ Id, Name }) => {
                    return {
                        value: Id,
                        label: Name,
                    };
                });
                setResourceOptions(resourceOptions.sort((a, b) => (a.label > b.label ? 1 : -1)));
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message ?? error?.message;
            window.alert('Error fetching resource options: ' + errorMessage);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchResourceOptions();
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={resourceItem}
            onSubmit={(values: IResourceServiceItem) => {
                const oldResource = request.ResourceServiceItems.find((r) => r.ResourceId === values.ResourceId);
                if (!oldResource) {
                    window.alert('Error: Could not find resource in request');
                    return;
                }
                const newRequest = {
                    ...request,
                    ResourceServiceItems: request.ResourceServiceItems.map((r) => {
                        if (r.ResourceId === values.ResourceId) {
                            return values;
                        }
                        return r;
                    }),
                };
                dispatch(UpdateRequest(newRequest));
                onClose();
            }}
        >
            {({ submitForm, values, resetForm, handleChange }: FormikProps<IResourceServiceItem>) => (
                <Form>
                    <Dialog
                        open={open}
                        maxWidth={false}
                        PaperProps={{
                            sx: { alignSelf: 'flex-start', minWidth: 600 },
                        }}
                    >
                        <DialogTitle>{`Editing ${resourceItem.ResourceName}'s Usage Information`}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={values.ResourceDidProvideService} />}
                                        label="Was the resource used to help fulfill the request?"
                                        name="ResourceDidProvideService"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormikTextField name="Note" label="Quick Notes" multiline rows={3} />
                                </Grid>
                                <FieldArray name="PayerList">
                                    {({ push, remove }) => (
                                        <>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Divider>
                                                    <Chip
                                                        label="Add Contributors"
                                                        clickable
                                                        color="primary"
                                                        icon={<AddCircleOutline />}
                                                        onClick={() =>
                                                            push({
                                                                WhoPaid: '',
                                                                Amount: 0,
                                                                CreatedAt: new Date(),
                                                                UpdatedAt: new Date(),
                                                                CreatedBy: ProviderId,
                                                                UpdatedBy: ProviderId,
                                                            })
                                                        }
                                                    />
                                                </Divider>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                {React.Children.toArray(
                                                    values.PayerList.map((payer, index) => (
                                                        <PayerRow payer={payer} index={index} remove={remove} options={resourceOptions} />
                                                    ))
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                </FieldArray>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={submitForm}>
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
};

export default EditResourceItemModal;
