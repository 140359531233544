import BaseEntity from '../BaseEntity.model';
import { FormDocument } from './Document.model';

export default interface IAdvanceDirective extends BaseEntity {
    MemberId: string;
    HasAdvanceDirective?: boolean;
    Document?: FormDocument;
    IsDiscussedWithMember?: boolean;
    IsRefusedToDiscuss?: boolean;
    OtherReason: string;
    HasMPOA?: boolean;
    HasBHPOA?: boolean;
    IsKnownBySupportSystem?: boolean;
    File?: File;
}

export class AdvanceDirective implements IAdvanceDirective {
    Id: string;
    MemberId: string;
    HasAdvanceDirective?: boolean;
    Document?: FormDocument;
    IsDiscussedWithMember?: boolean;
    IsRefusedToDiscuss?: boolean;
    OtherReason: string;
    HasMPOA?: boolean;
    HasBHPOA?: boolean;
    IsKnownBySupportSystem?: boolean;
    File?: File;

    constructor() {
        this.HasAdvanceDirective = null;
    }
}
