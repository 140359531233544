const useStyles = {
    container: {
        padding: '20px',
    },
    mainDivider: {
        '&.MuiDivider-root': {
            width: '95%',
            position: 'absolute',
            top: '16px',
            zIndex: '-3',
        },
    },
    dateChip: {
        '&.MuiChip-root': {
            height: 'auto',
            minHeight: '32px',
            position: 'sticky',
            top: '5px',
            zIndex: 99,

            '&.MuiChip-outlined': {
                backgroundColor: 'white',
            },
        },
    },
    timelineItemHeader: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    scrollTopButton: {
        position: 'fixed !important' as any,
        right: '2%',
        bottom: '3%',
    },
    timelineCard: {
        boxShadow: 'rgb(145 158 171 / 20%) 0px 6px 12px',
        margin: '10px -5px 0',
    },
    timelineDetail: {
        padding: '15px',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid #dddddd80',
    },
};

export default useStyles;
