import { StatusEnum } from '../../Enum/StatusEnum';
import { Assessment } from '../Assessment/Assessment.model';

export default interface IMemberAssessment {
    Id: string;
    Comments: string;
    Name: string;
    Status: StatusEnum;
}

export class MemberAssessment {
    Id: string;
    Assessment: Assessment;
    MemberId: string;
    Status: StatusEnum;
    AssessmentDate: Date;
    NeedsReview: boolean;
    PHQ9EncounterCoded: boolean;
    PHQ9Score: number;
    Percentage: number;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.Status = StatusEnum.New;
    }
}
