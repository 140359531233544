import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Slide, DialogContent, DialogTitle, Dialog, DialogActions, Stack, Link, Box, Tabs, Tab, Divider } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { RootState } from '../../../reducers';
import classes from '../Styles';
import moment from 'moment';
import {
    generateAssessmentWorkflowPDF,
    getAssessmentWorkflowHistory,
    getEngagementDraftPreview,
    resetDownloadVerificationModal,
} from '../../../store/documentEngagement.slice';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { PartTwoDownloadEnum } from '../../../Enum/PartTwoDownloadEnum';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`history-tabpanel-${index}`} aria-labelledby={`history-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

enum AssessmentHistoryMenu {
    History = 1,
    Draft = 2,
}

const AssessmentHistory: React.FC<{ memberId: string; open: boolean; onSelectDraft: Function; onClose: Function; canShowDrafts: boolean }> = ({
    memberId,
    open,
    onSelectDraft,
    onClose,
    canShowDrafts,
}) => {
    const [tabValue, setTabValue] = useState(AssessmentHistoryMenu.History);
    const [selectedHistoryId, setSelectedHistoryId] = useState<string>('');
    const dispatch = useDispatch();
    const {
        engagementDraftPreviews,
        assessmentHistoryPreviews,
        downloadVerificationModal,
        generatingPdfEngagementIds,
        isFetchingPreviews,
        isFetchingHistory,
        isGeneratingPDF,
    } = useSelector((state: RootState) => state.documentEngagement);

    useEffect(() => {
        if (open) {
            setTabValue(AssessmentHistoryMenu.History);
            getAssessmentHistory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const getEngagementDrafts = () => {
        dispatch(getEngagementDraftPreview(memberId));
    };

    const getAssessmentHistory = () => {
        dispatch(getAssessmentWorkflowHistory(memberId));
    };

    const handleOnSelectHistory = (engagementId: string) => {
        setSelectedHistoryId(engagementId);
        dispatch(generateAssessmentWorkflowPDF(engagementId));
    };

    const handleOnMedicationModal = () => onClose();

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        switch (newValue) {
            case AssessmentHistoryMenu.History:
                getAssessmentHistory();
                break;
            case AssessmentHistoryMenu.Draft:
                getEngagementDrafts();
                break;
        }
    };

    return (
        <Dialog
            disableScrollLock
            disableRestoreFocus
            disableAutoFocus
            disableEnforceFocus
            open={open}
            maxWidth="xl"
            sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleOnMedicationModal}
        >
            <DialogTitle>History</DialogTitle>
            <DialogContent dividers sx={{ width: '600px', padding: 0 }}>
                <Stack direction="row">
                    <Tabs orientation="vertical" value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                        <Tab label="History" value={AssessmentHistoryMenu.History} sx={classes.historyTabMenu} />
                        {canShowDrafts && <Tab label="Draft" value={AssessmentHistoryMenu.Draft} sx={classes.historyTabMenu} />}
                    </Tabs>
                    <Divider orientation="vertical" flexItem />
                    <Box sx={classes.historyList}>
                        <TabPanel value={tabValue} index={AssessmentHistoryMenu.History}>
                            {isFetchingHistory ? (
                                <Box display="flex" justifyContent="center" sx={classes.loadingContainer}>
                                    <CircularProgress />
                                </Box>
                            ) : Boolean(assessmentHistoryPreviews.length) ? (
                                assessmentHistoryPreviews.map((history, index) => (
                                    <Stack key={`saved-draft${history.Id}`} direction="row" spacing={0} alignItems="center" sx={classes.historyItem}>
                                        <PictureAsPdfIcon color="primary" sx={{ pr: 1 }} />
                                        <Link underline="none" onClick={() => handleOnSelectHistory(history.Id)}>
                                            {`${moment(history?.CreatedDate).format('MMMM DD, yyyy hh:mm A')} - ${history.Provider}`}
                                        </Link>
                                        {selectedHistoryId === history.Id && isGeneratingPDF ? <CircularProgress size={16} sx={{ ml: 2 }} /> : null}
                                    </Stack>
                                ))
                            ) : (
                                <div className="no-data-container">No History found</div>
                            )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={AssessmentHistoryMenu.Draft}>
                            {isFetchingPreviews ? (
                                <Box display="flex" justifyContent="center" sx={classes.loadingContainer}>
                                    <CircularProgress />
                                </Box>
                            ) : Boolean(engagementDraftPreviews.length) ? (
                                engagementDraftPreviews.map((draft, index) => (
                                    <Stack key={`saved-draft${draft.Id}`} direction="row" spacing={0} alignItems="center" sx={classes.historyItem}>
                                        <Link underline="none" onClick={() => onSelectDraft(draft.Id)}>
                                            {moment(draft?.CreatedDate).format('MMMM DD, yyyy hh:mm A')}
                                        </Link>
                                    </Stack>
                                ))
                            ) : (
                                <div className="no-data-container">No Draft found</div>
                            )}
                        </TabPanel>
                    </Box>
                    <AlertDialog
                        open={downloadVerificationModal?.Show}
                        title="Part 2 consent verification"
                        showTop
                        isLoading={generatingPdfEngagementIds.some((id) => id === downloadVerificationModal.EngagementId)}
                        okText={String(PartTwoDownloadEnum.WithPartTwoDataDescription)}
                        skipText={String(PartTwoDownloadEnum.WithoutPartTwoDataDescription)}
                        cancelText="Cancel"
                        buttonsDirection="column-reverse"
                        skipButtonVariant="contained"
                        onConfirm={() => dispatch(resetDownloadVerificationModal({ ...downloadVerificationModal, Show: false, ShowVerificationOptions: true }))}
                        onSkip={() => dispatch(generateAssessmentWorkflowPDF(downloadVerificationModal.EngagementId, PartTwoDownloadEnum.WithoutPartTwoData))}
                        onClose={() => dispatch(resetDownloadVerificationModal())}
                        message={'This assessment contains Part 2 data, which cannot be shared without appropriate Part 2 consent. Do you want to'}
                    />
                    <AlertDialog
                        open={downloadVerificationModal?.ShowVerificationOptions}
                        title="Part 2 consent verification"
                        showTop
                        isLoading={generatingPdfEngagementIds.some((id) => id === downloadVerificationModal.EngagementId)}
                        okText={String(PartTwoDownloadEnum.VerifiedDescription)}
                        skipText={String(PartTwoDownloadEnum.InternalUseDescription)}
                        cancelText={String(PartTwoDownloadEnum.NoneDescription)}
                        buttonsDirection="column-reverse"
                        skipButtonVariant="contained"
                        onConfirm={() => dispatch(generateAssessmentWorkflowPDF(downloadVerificationModal.EngagementId, PartTwoDownloadEnum.Verified))}
                        onSkip={() => dispatch(generateAssessmentWorkflowPDF(downloadVerificationModal.EngagementId, PartTwoDownloadEnum.InternalUse))}
                        onClose={() => dispatch(resetDownloadVerificationModal())}
                        message={downloadVerificationModal?.Message}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button size="small" type="button" onClick={handleOnMedicationModal} variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssessmentHistory;
