import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../hooks/populationContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { configureStackedBarChartWithoutLegend } from '../../ChartConfiguration/ChartConfiguration';
import { getDepressionScreeningSummary } from '../HedisMetricService';
import DepressionScreeningSummary from '../../../Models/HedisMetrics/DepressionScreeningSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import FilterOptions from '../FilterOptions';
import '../../Widgets/Widgets.css';

const DepressionScreeningSummaries = () => {
    const history = useNavigate();
    const { populations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [depressionScreeningSummary, setDepressionScreeningSummary] = useState<DepressionScreeningSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const {
        depressionScreeningPopulation,
        setDepressionScreeningPopulation,
        depressionScreeningTeam,
        setDepressionScreeningTeam,
        depressionScreeningDateFilter,
        setDepressionScreeningDateFilter,
        depressionScreeningFromDate,
        setDepressionScreeningFromDate,
        depressionScreeningToDate,
        setDepressionScreeningToDate,
        depressionScreeningIncludePastOpenStatus,
        setDepressionScreeningIncludePastOpenStatus,
        depressionScreeningShowInActiveTeam,
        setDepressionScreeningShowInActiveTeam,
        setHedisType,
        setPop,
        setTeam,
        setFilter,
        setFDate,
        setTDate,
        setIncludePastOpenStatus,
        setShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartWithoutLegend('Depression Screening', 'Screened vs Not Screened', 'Population', 'Count', [{ name: '', data: [0] }], [0])
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (
            isPopulationFetched &&
            (depressionScreeningDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(depressionScreeningFromDate) && Boolean(depressionScreeningToDate)))
        ) {
            const changeFilter = async () => {
                let start = depressionScreeningFromDate !== null ? new Date(depressionScreeningFromDate).toDateString() : null;
                let end = depressionScreeningToDate !== null ? new Date(depressionScreeningToDate).toDateString() : null;
                const data = await getDepressionScreeningSummary(
                    depressionScreeningPopulation,
                    depressionScreeningTeam,
                    depressionScreeningDateFilter,
                    start,
                    end,
                    depressionScreeningIncludePastOpenStatus,
                    depressionScreeningShowInActiveTeam
                );
                setDepressionScreeningSummary(data);

                setIsPrepared(true);
            };
            if ((depressionScreeningFromDate !== null || depressionScreeningToDate !== null) && depressionScreeningToDate < depressionScreeningFromDate) {
            } else {
                setChartOptions(
                    configureStackedBarChartWithoutLegend(
                        'Depression Screening',
                        'Screened vs Not Screened',
                        'Population',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        depressionScreeningPopulation,
        depressionScreeningTeam,
        depressionScreeningDateFilter,
        depressionScreeningFromDate,
        depressionScreeningToDate,
        isPopulationFetched,
        depressionScreeningIncludePastOpenStatus,
        depressionScreeningShowInActiveTeam,
    ]);

    useEffect(() => {
        if (depressionScreeningSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            let yVal;

            yValues = [];
            yVal = {
                y: depressionScreeningSummary.Screened,
                name: 'Screened',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Screened', data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });

            yValues = [];
            yVal = {
                y: depressionScreeningSummary.NotScreened,
                name: 'Not Screened',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Screened', data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });

            if (depressionScreeningPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = populations.find((p) => p.value === depressionScreeningPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartWithoutLegend('Depression Screening', '', '', 'Count', yAxisValues, xAxisValues, navigateToMembers));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depressionScreeningSummary, depressionScreeningPopulation, populations, isPrepared]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToMembers = () => {
        setPop(depressionScreeningPopulation);
        setTeam(depressionScreeningTeam);
        setFilter(depressionScreeningDateFilter);
        setFDate(depressionScreeningFromDate);
        setTDate(depressionScreeningToDate);
        setIncludePastOpenStatus(depressionScreeningIncludePastOpenStatus);
        setShowInActiveTeam(depressionScreeningShowInActiveTeam);
        setHedisType(HedisMetricsTypeEnum.DepressionScreening);
        history(`/provider/metricsummary/hedismemberdetail/DepressionScreening`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            Depression Screening
                        </Typography>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">For members aged greater than 12, in the rolling 6 month time frame</Typography>
                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Screened :
                                    </Typography>
                                    <Typography variant="caption">CPT code G8510 OR G8431</Typography>
                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Screened :
                                    </Typography>
                                    <Typography variant="caption">No CPT codes recorded</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FilterOptions
                        selectedTeam={depressionScreeningTeam}
                        setSelectedTeam={setDepressionScreeningTeam}
                        dateFilter={depressionScreeningDateFilter}
                        setDateFilter={setDepressionScreeningDateFilter}
                        fromDate={depressionScreeningFromDate}
                        setFromDate={setDepressionScreeningFromDate}
                        toDate={depressionScreeningToDate}
                        setToDate={setDepressionScreeningToDate}
                        population={depressionScreeningPopulation}
                        setPopulation={setDepressionScreeningPopulation}
                        includePastOpenStatusMembers={depressionScreeningIncludePastOpenStatus}
                        setIncludePastOpenStatusMembers={setDepressionScreeningIncludePastOpenStatus}
                        hedisType={HedisMetricsTypeEnum.DepressionScreening}
                        showInActiveTeams={depressionScreeningShowInActiveTeam}
                        setShowInActiveTeams={setDepressionScreeningShowInActiveTeam}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default DepressionScreeningSummaries;
