import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    IconButton,
    TextField,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    Typography,
    Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { RootState } from '../../reducers';
import classes from './Styles';
import {
    addStrength,
    editStrengths,
    removeStrength,
    setIntegratedCarePlan,
    setNewStrengthName,
    toggleShowStrengthDeleteAlert,
    toggleShowStrengthEditMode,
    addStrengthProgress,
} from '../../store/carePlan.slice';
import AlertDialog from '../AlertDialog/AlertDialog';
import { copyObject } from '../../utils/common';
import { Strength } from '../../Models/CarePlans/Strength.model';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: '13px',
    },
}));

const Strengths: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [selectedStrengthId, setSelectedStrengthId] = useState<string>('');
    const [editedStrengthIds, setEditedStrengthIds] = useState<string[]>([]);
    const dispatch = useDispatch();
    const { integratedCarePlan, newStrengthName, showStrengthDeleteAlert, isSavingStrength, showStrengthEditMode, isSavingStrengthProgress } = useSelector(
        (state: RootState) => state.carePlan
    );

    const handleOnEditStrength = (id: string, val: any, index: number) => {
        setEditedStrengthIds([...editedStrengthIds, id]);
        let data = copyObject(integratedCarePlan);
        data.Strengths[index].Name = val;
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnClickAddNew = () => {
        if (Boolean(newStrengthName)) {
            dispatch(addStrength(memberId, newStrengthName));
        }
    };

    const handleOnClickSave = () => {
        if (showStrengthEditMode) {
            if (Boolean(editedStrengthIds.length)) {
                dispatch(
                    editStrengths(
                        memberId,
                        integratedCarePlan.Strengths.filter((p) => editedStrengthIds.some((e) => e === p.Id))
                    )
                );
            } else {
                dispatch(toggleShowStrengthEditMode(false));
            }
        } else {
            dispatch(toggleShowStrengthEditMode(true));
            setEditedStrengthIds([]);
        }
    };

    const handleOnClickDelete = (strength: Strength) => {
        setSelectedStrengthId(strength.Id);
        dispatch(toggleShowStrengthDeleteAlert(true));
    };

    const toggleOnNewStrength = () => {
        dispatch(setNewStrengthName(''));
        setOpenNew(!openNew);
    };

    const handleOnOpenProgress = (id: string) => {
        let data = copyObject(integratedCarePlan);
        data.Strengths.forEach((strength) => {
            if (strength.Id === id) {
                strength.IsOpenProgress = !strength.IsOpenProgress;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnChangeNewProgress = (id: string, note: string) => {
        let data = copyObject(integratedCarePlan);
        data.Strengths.forEach((strength) => {
            if (strength.Id === id) {
                strength.NewProgressNote = note;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const addNewProgress = (id: string) => {
        const selectedStrength = integratedCarePlan.Strengths.find((g) => g.Id === id);
        dispatch(addStrengthProgress(memberId, selectedStrength.Id, selectedStrength.NewProgressNote));
    };

    const renderStrength = (strength: Strength, index: number) => {
        return (
            <React.Fragment key={index}>
                <TableRow key={strength.Id} className="pointer">
                    {showStrengthEditMode ? (
                        <TableCell scope="row">
                            <div className="d-flex-row">
                                <span className="count">{index + 1}</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    sx={classes.textInput}
                                    fullWidth
                                    name="Name"
                                    value={strength.Name}
                                    onChange={(e) => handleOnEditStrength(strength.Id, e.target.value, index)}
                                />
                            </div>
                        </TableCell>
                    ) : (
                        <TableCell scope="row" onClick={() => handleOnOpenProgress(strength.Id)}>
                            <div className="d-flex-row">
                                <span className="count">{index + 1}</span> {strength.Name}
                            </div>
                        </TableCell>
                    )}
                    {showStrengthEditMode ? (
                        <TableCell width="80px">
                            <IconButton onClick={() => handleOnClickDelete(strength)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </TableCell>
                    ) : (
                        <TableCell width="140px"></TableCell>
                    )}
                </TableRow>
                <TableRow style={{ backgroundColor: 'transparent' }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                        <Collapse in={strength.IsOpenProgress} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1, p: 0 }}>
                                <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                                    <TableBody>
                                        {strength.Progress?.length
                                            ? strength.Progress.map((progress) => (
                                                  <TableRow key={progress.Id}>
                                                      <TableCell>
                                                          <IconButton>
                                                              <ThumbUpOffAltIcon />
                                                          </IconButton>
                                                          {progress.Name}
                                                      </TableCell>
                                                  </TableRow>
                                              ))
                                            : null}
                                        <TableRow>
                                            <TableCell scope="row">
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    sx={classes.textInput}
                                                    fullWidth
                                                    name="Name"
                                                    placeholder="Add Progress Entry"
                                                    value={strength.NewProgressNote}
                                                    autoFocus
                                                    onChange={(e) => handleOnChangeNewProgress(strength.Id, e.target.value)}
                                                    onKeyUp={(e) => e.key === 'Enter' && addNewProgress(strength.Id)}
                                                    InputProps={{
                                                        endAdornment: isSavingStrengthProgress ? <CircularProgress size={15} /> : null,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    return (
        <>
            <TableContainer sx={classes.tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack direction="row">
                                    <Typography sx={classes.planTitle}>Strengths</Typography>
                                    <BootstrapTooltip title="Consider hobbies/interests/supports" placement="top">
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Stack>
                            </TableCell>
                            <TableCell width="140px" align="right">
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={handleOnClickSave}>
                                        {isSavingStrength ? <CircularProgress size={18} /> : showStrengthEditMode ? <DoneIcon /> : <EditIcon />}
                                    </Button>
                                    <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={toggleOnNewStrength}>
                                        {openNew ? <KeyboardArrowUpIcon /> : <AddIcon />}
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                {integratedCarePlan.Strengths?.length ? (
                    <Table size="small">
                        <TableBody>{integratedCarePlan.Strengths?.map(renderStrength)}</TableBody>
                    </Table>
                ) : !openNew ? (
                    <div className="no-data-container">No strengths to display</div>
                ) : null}
            </TableContainer>
            <Collapse in={openNew} timeout="auto" unmountOnExit>
                <TableContainer sx={classes.tableContainer}>
                    <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        sx={classes.textInput}
                                        fullWidth
                                        name="Name"
                                        placeholder="Enter new strength here"
                                        value={newStrengthName}
                                        autoFocus
                                        onChange={(e) => dispatch(setNewStrengthName(e.target.value))}
                                    />
                                </TableCell>
                                <TableCell width="140px">
                                    <Box sx={classes.actionButtons}>
                                        <IconButton onClick={handleOnClickAddNew} disabled={!Boolean(newStrengthName)}>
                                            {isSavingStrength ? <CircularProgress size={18} /> : <SaveIcon color="primary" />}
                                        </IconButton>
                                        <IconButton onClick={toggleOnNewStrength}>
                                            <HighlightOffSharpIcon color="error" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
            <AlertDialog
                open={showStrengthDeleteAlert}
                isLoading={isSavingStrength}
                autoHideOnConfirmed={false}
                okText="Delete"
                onConfirm={() => dispatch(removeStrength(memberId, selectedStrengthId))}
                onClose={() => dispatch(toggleShowStrengthDeleteAlert(false))}
                message="Are you sure want to delete this strength?"
            />
        </>
    );
};

export default Strengths;
