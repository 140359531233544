import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

import { Diagnosis } from '../Models/DocumentEngagements/Diagnosis.model';
import { ICDcode } from '../Models/Shared/ICDcode.model';
import { Symptoms } from '../Models/Shared/Symptoms.model';

const clinicalDataSlice = createSlice({
    name: 'clinicalData',
    initialState: {
        diagnosises: [] as Diagnosis[],
        diagnosisesByKeyword: [] as ICDcode[],
        deletingDiagnosisIds: [] as string[],
        isDiagnosisFetching: false,
        isDiagnosisByKeywordFetching: false,
        isDiagnosisSaving: false,
        symptoms: [] as Symptoms[],
        deletingSymptomIds: [] as string[],
        isSymptomsFetching: false,
        isSymptomSaving: false,
    },
    reducers: {
        _setDiagnosises(state, action) {
            state.diagnosises = action.payload || [];
        },
        _setDiagnosisesByKeyword(state, action) {
            state.diagnosisesByKeyword = action.payload || [];
        },
        _toggleDeletingDiagnosisIds(state, action) {
            state.deletingDiagnosisIds = state.deletingDiagnosisIds.some((d) => d === action.payload)
                ? state.deletingDiagnosisIds.filter((d) => d !== action.payload)
                : [...state.deletingDiagnosisIds, action.payload];
        },
        _toggleIsDiagnosisFetching(state, action) {
            state.isDiagnosisFetching = action.payload;
        },
        _toggleIsDiagnosisByKeywordFetching(state, action) {
            state.isDiagnosisByKeywordFetching = action.payload;
        },
        _toggleIsDiagnosisSaving(state, action) {
            state.isDiagnosisSaving = action.payload;
        },
        _setSymptoms(state, action) {
            state.symptoms = action.payload || [];
        },
        _toggleIsSymptomsFetching(state, action) {
            state.isSymptomsFetching = action.payload;
        },
        _toggleDeletingSymptomIds(state, action) {
            state.deletingSymptomIds = state.deletingSymptomIds.some((d) => d === action.payload)
                ? state.deletingSymptomIds.filter((d) => d !== action.payload)
                : [...state.deletingSymptomIds, action.payload];
        },
        _toggleIsSymptomSaving(state, action) {
            state.isSymptomSaving = action.payload;
        },
    },
});

const {
    _setDiagnosises,
    _setDiagnosisesByKeyword,
    _toggleDeletingDiagnosisIds,
    _toggleIsDiagnosisFetching,
    _toggleIsDiagnosisByKeywordFetching,
    _toggleIsDiagnosisSaving,
    _setSymptoms,
    _toggleIsSymptomsFetching,
    _toggleDeletingSymptomIds,
    _toggleIsSymptomSaving,
} = clinicalDataSlice.actions;

export const getDiagnosisByMember =
    (memberId: string, assessmentRoleId = ''): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsDiagnosisFetching(true));
        axios
            .get(`${API_URL}/member/getdiagnosis`, { params: { memberId, assessmentRoleId } })
            .then((response) => {
                dispatch(_setDiagnosises(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsDiagnosisFetching(false));
            });
    };

export const getDiagnosisByKeyword =
    (keyword: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsDiagnosisByKeywordFetching(true));
        axios
            .get(`${API_URL}/member/geticd10diagnosisbykeyword`, { params: { keyword } })
            .then((response) => {
                dispatch(_setDiagnosisesByKeyword(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsDiagnosisByKeywordFetching(false));
            });
    };

export const addDiagnosis =
    (memberId: string, diagnosis: Diagnosis, onSuccess?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsDiagnosisSaving(true));
        axios
            .put(`${API_URL}/member/adddiagnosis`, diagnosis, { params: { memberId } })
            .then((response) => {
                if (Boolean(onSuccess)) {
                    onSuccess(response.data);
                }
                const diagnosises = getState().clinicalData.diagnosises || [];
                dispatch(_setDiagnosises([...diagnosises, diagnosis].sort((a, b) => a.Code.localeCompare(b.Code))));
            })
            .finally(() => {
                dispatch(_toggleIsDiagnosisSaving(false));
            });
    };

export const deleteDiagnosis =
    (memberId: string, diagnosisId: string, onSuccess?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleDeletingDiagnosisIds(diagnosisId));
        axios
            .delete(`${API_URL}/member/deletediagnosis`, { params: { memberId, diagnosisId } })
            .then((response) => {
                if (Boolean(onSuccess)) {
                    onSuccess(response.data);
                }
                const diagnosises = getState().clinicalData.diagnosises || [];
                dispatch(_setDiagnosises(diagnosises.filter((d) => d.Id !== diagnosisId)));
            })
            .finally(() => {
                dispatch(_toggleDeletingDiagnosisIds(diagnosisId));
            });
    };

export const getSymptomsByMember =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSymptomsFetching(true));
        axios
            .get(`${API_URL}/member/getsymptoms`, { params: { memberId } })
            .then((response) => {
                dispatch(_setSymptoms(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsSymptomsFetching(false));
            });
    };

export const addSymptom =
    (memberId: string, symptom: Symptoms, onSuccess?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSymptomSaving(true));
        axios
            .put(`${API_URL}/member/addsymptom`, symptom, { params: { memberId } })
            .then((response) => {
                if (Boolean(onSuccess)) {
                    onSuccess(response.data);
                }
                const symptoms = getState().clinicalData.symptoms || [];
                dispatch(_setSymptoms([...symptoms, symptom]));
            })
            .finally(() => {
                dispatch(_toggleIsSymptomSaving(false));
            });
    };

export const deleteSymptom =
    (memberId: string, symptomId: string, onSuccess?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleDeletingSymptomIds(symptomId));
        axios
            .delete(`${API_URL}/member/deletesymptom`, { params: { memberId, symptomId } })
            .then((response) => {
                if (Boolean(onSuccess)) {
                    onSuccess(response.data);
                }
                const symptoms = getState().clinicalData.symptoms || [];
                dispatch(_setSymptoms(symptoms.filter((d) => d.Id !== symptomId)));
            })
            .finally(() => {
                dispatch(_toggleDeletingSymptomIds(symptomId));
            });
    };

export default clinicalDataSlice.reducer;
