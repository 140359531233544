import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { PlaceDetail } from '../Models/GooglePlace/PlaceDetail.model';
import Address from '../Models/Shared/Address.model';

const googlePlaceSlice = createSlice({
    name: 'googlePlace',
    initialState: {
        placeList: [],
        placeDetail: new Address(),
        isFetching: false,
        isSaving: false,
    },
    reducers: {
        _setPlaceList(state, action) {
            state.placeList = action.payload;
        },
        _setPlaceDetail(state, action) {
            state.placeDetail = action.payload || new PlaceDetail();
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
    },
});

const { _setPlaceList, _setPlaceDetail, _toggleIsFetching } = googlePlaceSlice.actions;

export const fetchPlacePredictions =
    (input: string, callback?: Function): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/googleplace/getplacepredictions`, { params: { input } })
            .then((response) => {
                dispatch(_setPlaceList(response.data));
                if (Boolean(callback)) {
                    callback(response.data);
                }
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchPlaceDetail =
    (placeId: string, callback?: Function): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/googleplace/getplacedetail`, { params: { placeId } })
            .then((response) => {
                dispatch(_setPlaceDetail(response.data));
                if (Boolean(callback)) {
                    callback(response.data);
                }
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export default googlePlaceSlice.reducer;
