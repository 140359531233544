/* eslint-disable react-hooks/exhaustive-deps */
import RichTextEditor from '@mantine/rte';
import {
    Alert,
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from '../../../Models/Provider/Provider.model';
import IProviderSelectOption from '../../../Models/Provider/ProviderSelectOption.model';
import { RootState } from '../../../reducers';
import { REQUEST_API, saveNewComment, setRequest, setShowForwardingModal } from '../../../store/requests.slice';
import { toTitleCase } from '../../../utils/common';
import { ProviderId } from '../../../utils/constants';
import { findRequestType } from '../../../utils/mappings';
import { IForwardingModalProps } from '../interfaces';

const ForwardingModal: React.FC<IForwardingModalProps> = () => {
    const dispatch = useDispatch();
    const { showForwardingModal, selectedRequest } = useSelector((state: RootState) => state.requests);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const providerRef = React.useRef<HTMLDivElement>(null);
    const [fullProviderOptionsList, setFullProviderOptionsList] = useState<IProviderSelectOption[]>([]);
    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const [selectedProviderOption, setSelectedProviderOption] = useState<IProviderSelectOption | null>(null);
    const [isForwarded, setIsForwarded] = useState<boolean>(false);
    const [forwarding, setForwarding] = useState<boolean>(false);
    const [forwardError, setForwardError] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const handleCancelClick = () => {
        dispatch(setShowForwardingModal(false));
        setProviderInputValue('');
        setSelectedProviderOption(null);
        setIsForwarded(false);
        setForwardError('');
        setMessage(selectedRequest?.Message ?? '');
        setComment('');
    };

    const handleForwardClick = async () => {
        try {
            setForwarding(true);
            if (message !== selectedRequest?.Message) {
                const response = await axios.put(`${REQUEST_API}`, {
                    ...selectedRequest,
                    Message: message,
                });
                if (response.status === 200) {
                    dispatch(setRequest(response.data));
                }
            }
            const forwardResponse = await axios.put(`${REQUEST_API}/forwardrequest?requestId=${selectedRequest?.Id}`, {
                Id: selectedProviderOption?.ProviderId,
                Name: selectedProviderOption?.ProviderName,
                Role: '',
            });
            if (forwardResponse.status === 200 || forwardResponse.status === 204) {
                dispatch(saveNewComment(comment, selectedRequest?.Id));
                setIsForwarded(true);
                dispatch(setRequest(forwardResponse.data));
            }
        } catch (err: unknown) {
            const error = err as Error;
            const errorMessage = `${error.name}: ${error.message}`;
            setForwardError(errorMessage);
        }
        handleCancelClick();
        setForwarding(false);
    };

    const requestTypeText = useMemo(() => {
        return findRequestType(selectedRequest?.Type)?.text ?? 'Unknown';
    }, [selectedRequest?.Type]);

    useEffect(() => {
        if (selectedRequest?.Id?.length > 0 && fullProviderOptionsList.length === 0) {
            const providers = activeProviders.filter((el) => el.Role.RoleName !== 'CEO');
            const options = (providers as Provider[])
                .map((row) => {
                    console.log({ row });
                    return {
                        ProviderId: row.Id,
                        ProviderName: `${row.LastName}, ${row.FirstName}`,
                        FirstName: row.FirstName,
                        LastName: row.LastName,
                    };
                })
                .filter((row) => row.ProviderId !== selectedRequest.RequestTo.Id)
                .sort((a, b) => {
                    let lastNameComparison = a.LastName.localeCompare(b.LastName);
                    if (lastNameComparison !== 0) {
                        return lastNameComparison;
                    }

                    return a.FirstName.localeCompare(b.FirstName);
                });
            console.log({ options, fullProviderOptionsList, providerOptions });
            setFullProviderOptionsList(options);
            setProviderOptions(options);
        }
    }, [selectedRequest?.Id]);

    useEffect(() => {
        if (fullProviderOptionsList.length > 0) {
            if (providerInputValue.length === 0) {
                setProviderOptions(fullProviderOptionsList);
            } else {
                setProviderOptions(
                    fullProviderOptionsList.filter((row) => {
                        return row.ProviderName.toLowerCase().includes(providerInputValue.toLowerCase());
                    })
                );
            }
        }
    }, [providerInputValue]);

    useEffect(() => {
        if (selectedRequest?.Id?.length > 0) {
            setMessage(selectedRequest.Message);
        }
    }, [selectedRequest?.Id]);

    let modalDisplay = (
        <Grid container spacing={2}>
            {selectedRequest?.RequestTo?.Id === ProviderId ? null : (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Alert severity="info">Note: You are forwarding a request that is not currently assigned to you.</Alert>
                </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                    ref={providerRef}
                    style={{ paddingTop: 10 }}
                    fullWidth
                    id="request-provider-selection"
                    options={providerOptions}
                    isOptionEqualToValue={(option, value: any) => {
                        return option.ProviderId === value.ProviderId;
                    }}
                    renderInput={(params) => <TextField {...params} label="Forward To" required />}
                    value={selectedProviderOption as any}
                    onChange={(event: any, newValue: any) => {
                        if (newValue?.value) {
                            setSelectedProviderOption(newValue.value);
                        } else {
                            setSelectedProviderOption(newValue);
                        }
                    }}
                    inputValue={providerInputValue}
                    onInputChange={(event, value) => {
                        setProviderInputValue(value);
                    }}
                    getOptionLabel={(option: any) => option.ProviderName}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: 10 }}>
                <TextField label="Comment" required fullWidth multiline minRows={5} value={comment} onChange={(event) => setComment(event?.target?.value)} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider variant="fullWidth" style={{ paddingTop: 30 }}>
                    <Typography>Request Message (Edits the Old Message)</Typography>
                </Divider>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <RichTextEditor
                    value={message}
                    onChange={(value) => {
                        setMessage(value);
                    }}
                />
            </Grid>
        </Grid>
    );
    if (forwarding) {
        modalDisplay = (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        );
    }
    if (isForwarded) {
        modalDisplay = <Alert severity="success">Request has been forwarded to {selectedProviderOption?.ProviderName}</Alert>;
    }

    if (forwardError) {
        modalDisplay = <Alert severity="error">{forwardError}</Alert>;
    }

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
            open={showForwardingModal}
        >
            <DialogTitle>{`Forward ${toTitleCase(selectedRequest?.RequestFor?.Name)}'s "${
                selectedRequest?.RequestTypeLabel ?? requestTypeText
            }" Request`}</DialogTitle>
            <DialogContent>{modalDisplay}</DialogContent>
            <DialogActions>
                {isForwarded ? null : (
                    <Button
                        color="success"
                        variant="contained"
                        disabled={!selectedProviderOption?.ProviderName || forwarding || forwardError?.length > 0 || comment.trim()?.length === 0}
                        onClick={handleForwardClick}
                    >
                        Forward
                    </Button>
                )}
                <Button variant="contained" onClick={handleCancelClick} color={isForwarded ? 'success' : undefined}>
                    {!isForwarded ? 'Cancel' : 'Close'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ForwardingModal;
