import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

const performanceDashboard = createSlice({
    name: 'performanceDashboard',
    initialState: {
        memberClaims: { MemberClaimCost: [], MemberClaimVisit: [] },
        memberEngagements: [],
        last10Engagements: [],
        last10Visits: [],
        claimGeneratedCostLast10Visits: [],
        filterBy: 'ALL',
    },
    reducers: {
        _setFilterBy(state, action) {
            state.filterBy = action.payload;
        },
        _setMemberClaims(state, action) {
            state.memberClaims = action.payload;
        },
        _setMemberEngagements(state, action) {
            state.memberEngagements = action.payload;
        },
        _setLast10Engagements(state, action) {
            state.last10Engagements = action.payload;
        },
        _setLast10Visits(state, action) {
            state.last10Visits = action.payload;
        },
        _setClaimGeneratedCostLast10Visits(state, action) {
            state.claimGeneratedCostLast10Visits = action.payload;
        },
    },
});

const { _setFilterBy, _setClaimGeneratedCostLast10Visits, _setLast10Engagements, _setLast10Visits, _setMemberClaims, _setMemberEngagements } =
    performanceDashboard.actions;

export const getLast10Engagements =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/documentengagement/getlast10Engagements?memberId=${memberId}`);
            dispatch(_setLast10Engagements(response.data));
        } catch (e) {
            console.log(e);
        }
    };

export const getMemberClaims =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/claimanalytic/getmemberclaims?memberId=${memberId}`);
            dispatch(_setMemberClaims(response.data));
        } catch (e) {
            console.log(e);
        }
    };

export const getMemberEngagements =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/documentengagement/getmemberEngagements?memberId=${memberId}`);
            dispatch(_setMemberEngagements(response.data));
        } catch (e) {
            console.log(e);
        }
    };

export const getMemberLast10Visits =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/claimanalytic/getmemberlastclaimvisits?memberId=${memberId}`);
            dispatch(_setLast10Visits(response.data));
        } catch (e) {
            console.log(e);
        }
    };

export const getClaimGeneratedCostLast10Visits =
    (memberId: string, filterBy: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/claimanalytic/getmemberclaimcostvisits?memberId=${memberId}&type=${filterBy}`);
            dispatch(_setClaimGeneratedCostLast10Visits(response.data));
        } catch (e) {
            console.log(e);
        }
    };

export const getVisitType = (type: number) => {
    let claimType = visitTypes.find((el) => el.id === type)?.name;
    return claimType;
};

export const setFilterBy =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setFilterBy(value));
    };

export const visitTypes = [
    { id: 0, name: 'All', value: 'ALL' },
    { id: 1, name: 'ED Visit Cost', value: 'ED' },
    { id: 2, name: 'AC Cost', value: 'AC' },
    { id: 3, name: 'Pharmacy Cost', value: 'PH' },
    { id: 4, name: 'Other Cost', value: 'Other' },
];

export const getStatusColor = (currentValue: number, previousValue: number) => {
    if (currentValue === previousValue) {
        return 'black';
    }
    if (currentValue > previousValue) {
        return 'red';
    }
    if (currentValue < previousValue) {
        return 'green';
    }
};

export const getVisitTypeName = (visitType: number) => {
    let engagementName = '';
    switch (visitType) {
        case 1:
            engagementName = 'In Person';
            break;
        case 2:
            engagementName = 'Phone';
            break;
        case 3:
            engagementName = 'Telemedicine';
            break;
        case 4:
            engagementName = 'Email';
            break;
        case 5:
            engagementName = 'Office Visit';
            break;
        case 8:
            engagementName = 'Text Message';
            break;
        default:
            engagementName = visitType.toString();
            break;
    }
    return engagementName;
};

export default performanceDashboard.reducer;
