import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, List, ListItem, Stack, TextField, Typography } from '@mui/material';

import moment from 'moment';
import { useEffect, useState } from 'react';

import Signature from '../../Shared/Signature';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { setConsentSuccessful, setDigitalSignatureForm, setOpenSignConsentTwo, validateConsentTwo } from '../../../../store/consentSlice.slice';
import { getConsentFormDocumentByMember } from '../../../../store/assessment.slice';

const ConsentPartTwoEnglish = (props: any) => {
    const dispatch = useDispatch();
    const { consentSuccessful, digitalSignatureForm, isSaving, openSignConsentTwo } = useSelector((state: RootState) => state.consentSlice);
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);

    const [openMemberSignatureOne, setOpenMemberSignatureOne] = useState(false);
    const [openGuardian, setOpenGuardian] = useState(false);
    const [openDecisionMaker, setOpenDecisionMaker] = useState(false);
    const [openVerbalConsent, setOpenVerbalConsent] = useState(false);

    const handleSubmitConsentPartTwo = () => {
        dispatch(validateConsentTwo(digitalSignatureForm, false));
    };

    useEffect(() => {
        if (consentSuccessful) {
            dispatch(setConsentSuccessful(false));
            dispatch(getConsentFormDocumentByMember(memberBasicInfo.Id));
            dispatch(setOpenSignConsentTwo(false));
        }
    }, [consentSuccessful, memberBasicInfo.Id, dispatch]);

    return (
        <Dialog open={openSignConsentTwo} onClose={() => dispatch(setOpenSignConsentTwo(false))} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Stack spacing={1} textAlign={'center'}>
                    <Typography variant="h5" fontWeight={'bold'} mt={5}>
                        CONSENT TO RELEASE BEHAVIORAL HEALTH & SUBSTANCE ABUSE INFORMATION
                    </Typography>
                    <Typography variant="h6">(FOR TREATING PROVIDERS)</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form>
                    <Box p={2} textAlign={'left'} pb={5}>
                        <Grid container spacing={2} mb={5}>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    className="initials-div"
                                    variant="standard"
                                    label="Patient Name"
                                    id="member-name"
                                    value={digitalSignatureForm.MemberName}
                                    error={digitalSignatureForm.MemberName.length === 0}
                                    onChange={(e) =>
                                        dispatch(
                                            setDigitalSignatureForm({
                                                ...digitalSignatureForm,
                                                MemberName: e.target.value,
                                            })
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth variant="standard" label="Date of Birth" value={moment(memberBasicInfo?.DOB).format('MM/DD/YYYY')} />
                            </Grid>
                        </Grid>
                        <Typography variant="body1">
                            By signing this form, I permit all of my past, present and future healthcare providers where I have received behavioral health
                            treatment, including any treatment for substance use disorders, to release my information to Health Current, the statewide health
                            information exchange (HIE), and to organization listed here:
                        </Typography>
                        <Grid container spacing={2} my={2}>
                            <Grid item xs={8}>
                                <TextField fullWidth variant="standard" label="Organization Name" value={'Catalytic Health Partners, Inc'} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth variant="standard" label="Phone Number" value={'602-802-8388'} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth variant="standard" label="Address" value={'3050 W Agua Fria Fwy, Suite 150'} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth variant="standard" label="City" value={'Phoenix'} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth variant="standard" label="State" value={'AZ'} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth variant="standard" label="Zip" value={'85027'} />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" pt={2}>
                            I am receiving (or will receive) treatment from this organization. The purpose of this disclosure is for:
                        </Typography>

                        <List sx={{ listStyleType: 'disc', px: 2 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1">My treatment;</Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1">Payment for my treatment (for example, billing insurance companies); and</Typography>
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                <Typography variant="body1">
                                    Healthcare operations activities (for example, improving the quality of care for patients, managing the care of patients,
                                    patient safety activities, and other activities necessary to run a health care organization).
                                </Typography>
                            </ListItem>
                        </List>
                        <Stack spacing={2}>
                            <Typography variant="body1">
                                I authorize the disclosure of all my medical information for these purposes, including behavioral health information and
                                substance use disorder information (e.g., drugs and alcohol treatment), my medical history, diagnosis, hospital records, clinic
                                and doctor visit information, medications, allergies, lab test results, radiology reports, sexual and reproductive health,
                                communicable disease-related information, and HIV/AIDS-related information.
                            </Typography>
                            <Typography variant="body1">
                                I understand that the organization listed above will obtain this information about me through Health Current, the statewide HIE.
                                I understand that if I previously opted out of having my health information shared through the HIE, this form will change that
                                descision. I understand that if I sign this form, I agree to have my health information shared through the HIE. I understand
                                that I can change this decision at any time.
                            </Typography>
                            <Typography variant="body1">
                                I understand that I may take back or cancel this consent to share my information at any time, except where someone already
                                relied on my consent to release information. If I want to cancel my consent or if I have questions, I will contact the
                                organization at the contact information listed above.{' '}
                                <b>
                                    Unless I cancel this consent earlier, it will automatically terminate <u>one year</u> from the date of my signature
                                </b>{' '}
                                I understand that my substance use disorder treatment information will continue to be protected by federal law after it is
                                released.
                            </Typography>
                        </Stack>
                        <div id="consent-two-signature" className="initials-div"></div>
                        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'flex-end'} my={5}>
                            <Typography variant="body1" color={'error'}>
                                * Please sign one below
                            </Typography>
                            <Stack direction={'row'} alignItems={'flex-end'} spacing={1}>
                                <Typography variant="h6">Date:</Typography>
                                <Typography variant="h6">{moment().format('MM/DD/YYYY')}</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} spacing={1} alignItems={'flex-start'} justifyContent={'space-between'}>
                            {/* Member Signature */}
                            <Box>
                                <Signature
                                    isSpanish={false}
                                    buttonLabel={'Signature'}
                                    isOpen={openMemberSignatureOne}
                                    setIsOpen={setOpenMemberSignatureOne}
                                    signature={digitalSignatureForm.MemberSignatureOne}
                                    setSignature={(signature: string) =>
                                        dispatch(
                                            setDigitalSignatureForm({
                                                ...digitalSignatureForm,
                                                MemberSignatureOne: signature,
                                            })
                                        )
                                    }
                                    notRequired={true}
                                />
                            </Box>
                            {/* Guardian Signature */}
                            <Stack>
                                <Signature
                                    isSpanish={false}
                                    buttonLabel={
                                        <Box display="flex" flexDirection="column" alignItems="center">
                                            <Typography variant="button">Signature of Parent/Guardian</Typography>
                                            <Typography variant="caption" display="block">
                                                (If Patient is a child under the age of 18)
                                            </Typography>
                                        </Box>
                                    }
                                    isOpen={openGuardian}
                                    setIsOpen={setOpenGuardian}
                                    signature={digitalSignatureForm.GuardianOrCaregiverSignatureOne}
                                    setSignature={(signature: string) =>
                                        dispatch(
                                            setDigitalSignatureForm({
                                                ...digitalSignatureForm,
                                                GuardianOrCaregiverSignatureOne: signature,
                                            })
                                        )
                                    }
                                    notRequired={true}
                                />
                                <Typography variant="body1" fontStyle={'italic'} textAlign={'center'} sx={{ mt: 2 }}>
                                    * Both the child and parent/guardian must consent to disclosure of the child's substance use disorder information, unless
                                    the child is married, homeless, or emancipated.
                                </Typography>
                            </Stack>
                            {/* Decision Maker */}
                            <Stack>
                                <Signature
                                    isSpanish={false}
                                    buttonLabel={"Signature of Patient's Health Care Decision Maker"}
                                    isOpen={openDecisionMaker}
                                    setIsOpen={setOpenDecisionMaker}
                                    signature={digitalSignatureForm.MemberSignatureThree}
                                    setSignature={(signature: string) =>
                                        dispatch(
                                            setDigitalSignatureForm({
                                                ...digitalSignatureForm,
                                                MemberSignatureThree: signature,
                                            })
                                        )
                                    }
                                    notRequired={true}
                                />
                                <Typography variant="body1" fontStyle={'italic'} textAlign={'center'} sx={{ mt: 2 }}>
                                    (If Patient has been declared incompetent by a court or is deceased)
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={{ mt: 5 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.VerbalConsent}
                                        onChange={() =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsent: !digitalSignatureForm.VerbalConsent,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography>
                                        Verbal consent is required if member or legal representative is unable to physically sign at this time and yet consent
                                        for care has been explicitly agreed to.
                                        <b> Verbal consent must be witnessed by two people telephonically or in person.</b>
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.ReviewedOne}
                                        onChange={() =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    ReviewedOne: !digitalSignatureForm.ReviewedOne,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography>
                                        <b> As the Provider, I have reviewed this page of the consent form with the member</b>
                                    </Typography>
                                }
                            />
                            {digitalSignatureForm.VerbalConsent && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            helperText="Witness Digital Signature"
                                            sx={{ mt: 5 }}
                                            fullWidth
                                            label={'Witness One'}
                                            value={digitalSignatureForm.VerbalConsentWitnessOne}
                                            onChange={(e) =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        VerbalConsentWitnessOne: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            helperText="Witness Digital Signature"
                                            sx={{ mt: 5 }}
                                            fullWidth
                                            label={'Witness Two'}
                                            value={digitalSignatureForm.VerbalConsentWitnessTwo}
                                            onChange={(e) =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        VerbalConsentWitnessTwo: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Signature
                                            isSpanish={false}
                                            buttonLabel={'Witness Signature'}
                                            isOpen={openVerbalConsent}
                                            setIsOpen={setOpenVerbalConsent}
                                            signature={digitalSignatureForm.VerbalConsentSignature}
                                            setSignature={(signature: string) =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        VerbalConsentSignature: signature,
                                                    })
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Stack>
                        <Typography variant="body1" fontWeight={'bold'} textAlign={'center'} mt={5}>
                            Notice to Recipient of Substance Use Disorder Information: 42 CFR part 2 prohibits unauthorized disclosure of these records.
                        </Typography>
                        <Box textAlign={'center'} mt={5}>
                            <Button variant="outlined" onClick={() => dispatch(setOpenSignConsentTwo(false))} sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button disabled={isSaving} variant="contained" onClick={handleSubmitConsentPartTwo}>
                                Save and Finish
                            </Button>
                        </Box>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ConsentPartTwoEnglish;
