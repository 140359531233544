import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, Chip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { getAssessmentByType, gotoNextMenu, gotoPreviousMenu, saveAssessmentWorkflow, updateMemberAssessmentModel } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import CategoryQuestions from '../CategoryQuestions/CategoryQuestions';
import { AssessmentTypeEnum } from '../../../Enum/AssessmentTypeEnum';
import { AssessmentCategory } from '../../../Models/Assessment/AssessmentCategory.model';
import VitalSignsGeneralQuestions from './VitalSignsGeneralQuestions';
import moment from 'moment';
import AlertDialog from '../../AlertDialog/AlertDialog';

const PhysicalExam: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const { isSaving, memberAssessment, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const type = AssessmentTypeEnum.PhysicalExam;

    useEffect(() => {
        dispatch(getAssessmentByType(type, memberId, engagementDraft.Id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, type, memberId]);

    useEffect(() => {
        if (isFetching && !isPageLoaded) {
            setIsPageLoaded(true);
        }
    }, [isFetching, isPageLoaded]);

    const handleOnChangeCategory = (updatedCategory: AssessmentCategory) => {
        setIsChanged(true);
        dispatch(updateMemberAssessmentModel(updatedCategory));
    };

    const getFilteredCategories = () => {
        return memberAssessment?.Assessment?.Categories.slice(2, memberAssessment?.Assessment?.Categories.length) || [];
    };

    const getVitalSignsCategories = () => {
        return memberAssessment?.Assessment?.Categories.slice(0, 2) || [];
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveAssessmentWorkflow(memberAssessment.Assessment?.Categories[0], isWithoutComplete, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching || !isPageLoaded ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item lg={12} md={12} sm={12} sx={{ mb: 2 }}>
                        <label>
                            <strong>Last Completed : </strong>{' '}
                            {Boolean(memberAssessment.AssessmentDate) ? (
                                <Chip size="small" label={moment(memberAssessment.AssessmentDate).format('MM/DD/yyyy')} />
                            ) : (
                                '-'
                            )}
                        </label>
                    </Grid>
                    <Grid item className="content-form">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {<VitalSignsGeneralQuestions categories={getVitalSignsCategories()} onChange={(c) => handleOnChangeCategory(c)} />}
                            </Grid>
                            {getFilteredCategories().map((category, index) => (
                                <Grid item xs={6} key={`category_${category.Name}_${index}`}>
                                    <CategoryQuestions assessmentType={type} category={category} onChange={(c) => handleOnChangeCategory(c)} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.PhysicalExamId) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    showBottomCenter={true}
                    title="Physical Exam Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Physical Exam assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    showBottomCenter={true}
                    title="Physical Exam Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Physical Exam assessment?"
                />
            )}
        </Grid>
    );
};

export default PhysicalExam;
