import { MbscCalendarEvent } from '@mobiscroll/react';
import FileCopy from '@mui/icons-material/FileCopy';
import { Button } from '@mui/material';
import { useState } from 'react';
import ExportScheduleModal from './ExportScheduleModal';

export interface IExportCalendarButtonProps {
    getEventsForExport: (startDate: Date, endDate: Date) => MbscCalendarEvent[];
    initialStartDate?: Date;
    initialEndDate?: Date;
}

const ExportCalendarButton = (props: IExportCalendarButtonProps) => {
    const { initialStartDate, initialEndDate, getEventsForExport } = props;
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    return (
        <>
            <ExportScheduleModal
                getEventsForExport={getEventsForExport}
                open={isExportModalOpen}
                onClose={() => setIsExportModalOpen(false)}
                onSuccess={() => setIsExportModalOpen(false)}
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
            />
            <Button fullWidth onClick={() => setIsExportModalOpen(true)} variant="contained" startIcon={<FileCopy />}>
                Export Schedule
            </Button>
        </>
    );
};

export default ExportCalendarButton;
