import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { useWidgetContext } from '../../../hooks/widgetContext';
import { fetchDocumentTrackingSummary, MenuProps, exportDocumentTrackingSummary } from '../../Widgets/WidgetService';
import DocumentTrackingSummary from '../../../Models/Widget/DocumentTrackingSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { parentUrl } from '../../../utils/constants';
import { getDateFilter } from '../../HedisMetrics/HedisMetricService';
import { dateFormatter } from '../../../utils/timeFormat';
import { generateRandomId } from '../../../utils/common';
import '../Widgets.css';

const DocumentTrackingSummaryDetail = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const {
        appointmentSelectedProvider,
        setAppointmentSelectedProvider,
        appointmentSelectedTeam,
        setAppointmentSelectedTeam,
        appointmentDateFilter,
        setAppointmentDateFilter,
        appointmentFromDate,
        setAppointmentFromDate,
        appointmentToDate,
        setAppointmentToDate,
        appointmentShowInActiveProviders,
        setAppointmentShowInActiveProviders,
        appointmentShowInActiveTeams,
        setAppointmentShowInActiveTeams,
    } = useWidgetContext();
    const [members, setMembers] = useState<DocumentTrackingSummary>();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const [loadedPage, setLoadedPage] = useState(false);
    const filteredTeams = (appointmentShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (appointmentShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filterList = getDateFilter().filter((d) => d.Value !== 'Custom' && d.Value !== 'Current Month');

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        setIsFetched(true);
    }, [isFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (activeProviders?.length && teams?.length) {
            setLoadedPage(true);
        }
    }, [teams, activeProviders]);

    useEffect(() => {
        if (loadedPage && (appointmentDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(appointmentFromDate) && Boolean(appointmentToDate)))) {
            const changeFilter = async () => {
                setIsMembersLoading(false);
                const data = await fetchDocumentTrackingSummary(
                    appointmentSelectedProvider,
                    appointmentSelectedTeam,
                    appointmentDateFilter,
                    Boolean(appointmentFromDate) && appointmentDateFilter === TimePeriodFilterEnum.Custom ? new Date(appointmentFromDate).toISOString() : '',
                    Boolean(appointmentToDate) && appointmentDateFilter === TimePeriodFilterEnum.Custom ? new Date(appointmentToDate).toISOString() : '',
                    appointmentShowInActiveTeams,
                    appointmentShowInActiveProviders,
                    true
                );
                setMembers(data);
                setIsMembersLoading(true);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (appointmentDateFilter !== TimePeriodFilterEnum.Custom && Boolean(appointmentFromDate) && Boolean(appointmentToDate)) {
            setAppointmentFromDate(null);
            setAppointmentToDate(null);
        }
    }, [
        loadedPage,
        appointmentSelectedTeam,
        appointmentDateFilter,
        appointmentFromDate,
        appointmentToDate,
        setAppointmentFromDate,
        setAppointmentToDate,
        setIsFetched,
        appointmentSelectedProvider,
        appointmentShowInActiveTeams,
        appointmentShowInActiveProviders,
    ]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const changeFromDate = (date: any) => {
        setAppointmentFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(appointmentToDate) || new Date(appointmentToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setAppointmentToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setAppointmentToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(appointmentFromDate) || new Date(appointmentFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setAppointmentFromDate(startDate);
        }
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportDocumentTrackingSummary(
            appointmentSelectedProvider,
            appointmentSelectedTeam,
            appointmentDateFilter,
            Boolean(appointmentFromDate) && appointmentDateFilter === TimePeriodFilterEnum.Custom ? new Date(appointmentFromDate).toISOString() : '',
            Boolean(appointmentToDate) && appointmentDateFilter === TimePeriodFilterEnum.Custom ? new Date(appointmentToDate).toISOString() : '',
            appointmentShowInActiveTeams,
            appointmentShowInActiveProviders
        );

        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `DocumentTracking - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setAppointmentShowInActiveTeams(isChecked);
        setAppointmentSelectedTeam('All');
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setAppointmentShowInActiveProviders(isChecked);
        setAppointmentSelectedProvider('All');
    };

    const columns: GridColDef[] = [
        {
            field: 'Index',
            headerName: '#',
            width: 60,
            sortable: false,
            renderCell: (params) => {
                return `${params.api.getRowIndex(params.id) + 1}`;
            },
        },
        {
            field: 'Name',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DateInfo',
            headerName: 'Appointment Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.DateInfo) ? dateFormatter(new Date(params.row.DateInfo)) : '-'}`,
        },
        {
            field: 'VisitedDate',
            headerName: 'Documented Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.VisitedDate) ? dateFormatter(new Date(params.row.VisitedDate)) : '-'}`,
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Document Tracking Member Summary
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={appointmentSelectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setAppointmentSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={appointmentShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select
                                value={appointmentSelectedTeam}
                                label="Team"
                                name="Team"
                                onChange={(e) => setAppointmentSelectedTeam(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={appointmentShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={appointmentDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setAppointmentDateFilter(parseInt(e.target.value))}
                            >
                                {filterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {parseInt(appointmentDateFilter) === TimePeriodFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item xs={2}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(appointmentFromDate) ? appointmentFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(appointmentToDate) ? appointmentToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={parseInt(appointmentDateFilter) === TimePeriodFilterEnum.Custom ? 1 : 5}></Grid>
                    <Grid item xs={1}>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => exportDetail()}
                            variant="contained"
                            color="success"
                            disabled={members?.DocumentedAppointmentsMembers?.length === 0 && members?.NotDocumentedAppointmentsMembers?.length === 0}
                        >
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isMembersLoading ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Documented ({members.DocumentedAppointmentsMembers.length})
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                        <DataGrid
                                            columns={columns}
                                            rows={members.DocumentedAppointmentsMembers}
                                            getRowId={(row) => `${row.ItemId}-${row.DateInfo}-${generateRandomId()}`}
                                            paginationMode="server"
                                            hideFooterPagination={true}
                                            hideFooter={true}
                                            onCellClick={(params: any) => {
                                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/scheduling`, '_blank');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Not Documented ({members.NotDocumentedAppointmentsMembers.length})
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                        <DataGrid
                                            columns={columns}
                                            rows={members.NotDocumentedAppointmentsMembers}
                                            getRowId={(row) => `${row.ItemId}-${row.DateInfo}-${generateRandomId()}`}
                                            paginationMode="server"
                                            hideFooterPagination={true}
                                            hideFooter={true}
                                            onCellClick={(params: any) => {
                                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/scheduling`, '_blank');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default DocumentTrackingSummaryDetail;
