import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, CircularProgress, Grid, Stack, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { EngagementDraft } from '../../../Models/DocumentEngagements/EngagementDraft.model';
import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { copyObject } from '../../../utils/common';
import AlertDialog from '../../AlertDialog/AlertDialog';
import MemberChartScheduler from '../../Calendar/MemberChartCalendar/MemberChartScheduler';
import Requests from '../../Requests/Requests';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

const enum FollowUpTabEnum {
    Schedule = 1,
    Request = 2,
}

const FollowUp: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [tabValue, setTabValue] = React.useState(FollowUpTabEnum.Schedule);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const dispatch = useDispatch();

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleOnSubmit = () => {
        setShowSaveAlert(true);
    };

    const handleOnAppointmentSaved = useCallback(
        (e: any) => {
            const appointmentId = e?.detail?.appointmentId as string;
            if (Boolean(appointmentId)) {
                let data = copyObject(engagementDraft) as EngagementDraft;
                data.AppointmentIds = (data.AppointmentIds || []).filter((a) => a !== appointmentId);
                data.AppointmentIds.push(appointmentId);

                dispatch(saveEngagementDraft(data));
            }
        },
        [dispatch, engagementDraft]
    );

    const handleOnAppointmentRemoved = useCallback(
        (e: any) => {
            const appointmentId = e?.detail?.appointmentId;
            if (Boolean(appointmentId)) {
                let data = copyObject(engagementDraft) as EngagementDraft;
                data.AppointmentIds = (data.AppointmentIds || []).filter((a) => a !== appointmentId);

                dispatch(saveEngagementDraft(data));
            }
        },
        [dispatch, engagementDraft]
    );

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveEngagementDraft({ ...engagementDraft, FollowUpStatus: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    useEffect(() => {
        document.body.addEventListener('onAppointmentSaved', handleOnAppointmentSaved);
        document.body.addEventListener('onAppointmentRemoved', handleOnAppointmentRemoved);

        return () => {
            document.body.removeEventListener('onAppointmentSaved', handleOnAppointmentSaved);
            document.body.removeEventListener('onAppointmentRemoved', handleOnAppointmentRemoved);
        };
    }, [engagementDraft, handleOnAppointmentSaved, handleOnAppointmentRemoved]);

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Grid item className="content-form">
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                </Grid>
            ) : (
                <Grid item className="content-form">
                    <Box sx={{ pl: 0, pr: 1 }}>
                        <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                            <Tab label="Followup Schedule" value={FollowUpTabEnum.Schedule} />
                            <Tab label="Followup Request" value={FollowUpTabEnum.Request} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={FollowUpTabEnum.Schedule}>
                        <MemberChartScheduler memberId={memberId} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={FollowUpTabEnum.Request}>
                        <Requests memberId={memberId} />
                    </TabPanel>
                </Grid>
            )}
            <Stack direction="row" justifyContent="flex-end" className="content-footer">
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mr: 3 }}
                    disabled={isSavingEngagementDraft}
                    onClick={() => dispatch(gotoPreviousMenu())}
                    startIcon={<ArrowBackIosIcon />}
                >
                    Previous
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleOnSubmit}
                    disabled={isSavingEngagementDraft}
                    endIcon={isSavingEngagementDraft ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                >
                    Next
                </Button>
            </Stack>
            <AlertDialog
                open={showSaveAlert}
                title="Follow Up"
                cancelText="Go Back"
                okText="Confirm"
                onConfirm={() => handleOnConfirmToNext(true)}
                onSkip={() => handleOnConfirmToNext()}
                onClose={() => setShowSaveAlert(false)}
                isLoading={isSavingEngagementDraft}
                message="I confirm and acknowledge that Follow Up is completed."
            />
        </Grid>
    );
};

export default FollowUp;
