import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { fetchAllProviders, handleErrorMessage, setIsLoading } from '../store/shared.slice';
import { GridCellParams } from '@mui/x-data-grid';
import { Provider } from '../Models/Provider/Provider.model';
import { getSSOEndPoint, setSamlResponse } from './medication.slice';
import { dictionaryToArray } from '../utils/mappings';

const adminProvider = createSlice({
    name: 'adminProvider',
    initialState: {
        alertMessage: '',
        confirmVisibility: false,
        formIsValid: true,
        loadingVeradigm: false,
        openAlert: false,
        isOpen: false,
        preferredContactOptions: [],
        providerData: new Provider(),
        providerRoleOptions: [],
        rowData: [],
        severity: 'success',
        showVeradigmModal: false,
        title: '',
    },
    reducers: {
        _setRowData(state, action) {
            state.rowData = action.payload;
        },
        _setAlertMessage(state, action) {
            state.alertMessage = action.payload;
        },
        _setLoadingVeradigm(state, action) {
            state.loadingVeradigm = action.payload;
        },
        _setOpenAlert(state, action) {
            state.openAlert = action.payload;
        },
        _setProviderData(state, action) {
            state.providerData = action.payload;
        },
        _setSeverity(state, action) {
            state.severity = action.payload;
        },
        _setFormIsValid(state, action) {
            state.formIsValid = action.payload;
        },
        _setIsOpen(state, action) {
            state.isOpen = action.payload;
        },
        _setConfirmVisibility(state, action) {
            state.confirmVisibility = action.payload;
        },
        _setPreferredContactOptions(state, action) {
            state.preferredContactOptions = action.payload;
        },
        _setProviderRoleOptions(state, action) {
            state.providerRoleOptions = action.payload;
        },
        _setShowVeradigmModal(state, action) {
            state.showVeradigmModal = action.payload;
        },
        _setTitle(state, action) {
            state.title = action.payload;
        },
    },
});

const {
    _setRowData,
    _setAlertMessage,
    _setLoadingVeradigm,
    _setOpenAlert,
    _setProviderData,
    _setSeverity,
    _setFormIsValid,
    _setIsOpen,
    _setConfirmVisibility,
    _setPreferredContactOptions,
    _setProviderRoleOptions,
    _setShowVeradigmModal,
    _setTitle,
} = adminProvider.actions;

export const SUCCESS = '#4caf50';
export const ERROR = '#f44336';

export const hasProviderPrescribingRole =
    (provider: any): AppThunk =>
    () => {
        return provider.Role?.RoleName === 'Nurse Practitioner' || provider.Role?.RoleName === 'MD';
    };

export const setRowData =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRowData(value));
    };

export const setProviderData =
    (value: Provider): AppThunk =>
    (dispatch) => {
        dispatch(_setProviderData(value));
    };

export const setAlertMessage =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setAlertMessage(value));
    };

export const setOpenAlert =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenAlert(value));
    };

export const setSeverity =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setSeverity(value));
    };

export const setFormIsValid =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setFormIsValid(value));
    };

export const setIsOpen =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setIsOpen(value));
    };

export const setConfirmVisibility =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmVisibility(value));
    };

export const setPreferredContactOptions =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setPreferredContactOptions(value));
    };

export const setProviderRoleOptions =
    (value: []): AppThunk =>
    (dispatch) => {
        dispatch(_setProviderRoleOptions(value));
    };

export const setShowVeradigmModal =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowVeradigmModal(value));
    };

export const setTitle =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setTitle(value));
    };

// Contact provider by phone/video
export const callHandler =
    (email: string): AppThunk =>
    (dispatch) => {
        let windowTeams = window.open(`https://teams.microsoft.com/l/call/0/0?users=${email}`);
        setTimeout(() => {
            windowTeams.close();
        }, 1000);
    };

export const handleClickAlert = (): AppThunk => (dispatch) => {
    dispatch(setOpenAlert(true));
};

export const getPreferredContactOptions = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/common/getpreferredcontact`);
    const preferredContactArray = dictionaryToArray(response.data);
    dispatch(setPreferredContactOptions(preferredContactArray));
};

export const getProviderRoles = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/role/roles`);
    dispatch(setProviderRoleOptions(response.data));
};

export const handleClickOpen = (): AppThunk => async (dispatch) => {
    dispatch(setProviderData(new Provider()));
    dispatch(setFormIsValid(true));
    dispatch(setIsOpen(true));
};

export const handleClose = (): AppThunk => async (dispatch) => {
    dispatch(setIsOpen(false));
    dispatch(setFormIsValid(true));
};

export const openConfirm =
    (params: GridCellParams): AppThunk =>
    (dispatch) => {
        dispatch(setProviderData(params.row));
        dispatch(setConfirmVisibility(true));
    };

export const openEdit =
    (params: GridCellParams): AppThunk =>
    (dispatch) => {
        dispatch(setProviderData(params.row));
        dispatch(setTitle('Edit Provider'));
        dispatch(setFormIsValid(true));
        dispatch(setIsOpen(true));
    };

export const insertProvider =
    (formIsValid: boolean, providerData: object): AppThunk =>
    async (dispatch) => {
        if (formIsValid) {
            try {
                dispatch(setIsLoading(true));
                await axios.post(`${API_URL}/provider/insertprovider`, providerData);
                dispatch(handleClose());
                dispatch(fetchAllProviders());
                dispatch(setIsLoading(false));
            } catch (error) {
                dispatch(setIsLoading(false));
                if (error.response.data.error) {
                    dispatch(setAlertMessage(error.response.data.error));
                } else {
                    dispatch(setAlertMessage(error.response.data));
                }
                dispatch(setSeverity('error'));
                dispatch(handleClickAlert());
            }
        } else {
            dispatch(setFormIsValid(false));
            return;
        }
    };

export const editProvider =
    (formIsValid: boolean, editProviderData: object): AppThunk =>
    async (dispatch) => {
        if (formIsValid) {
            try {
                await axios.put(`${API_URL}/provider/updateprovider`, editProviderData);
                dispatch(fetchAllProviders());
                dispatch(handleClose());
            } catch (error) {
                console.log(error);
            }
        } else {
            dispatch(setFormIsValid(false));
        }
    };

export const deleteProvider =
    (providerID: string): AppThunk =>
    async (dispatch) => {
        await axios.delete(`${API_URL}/provider/deleteprovider?id=${providerID}`);
        dispatch(setConfirmVisibility(false));
        dispatch(setSeverity('success'));
        dispatch(fetchAllProviders());
        dispatch(setAlertMessage('Provider successfully deleted'));
        dispatch(handleClickAlert());
    };

export const resetPasswordHandler =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        try {
            await axios.get(`${API_URL}/provider/resetpassword?providerId=${providerId}`);
            dispatch(setSeverity('success'));
            dispatch(setAlertMessage('Password reset information has been sent to your email.'));
            dispatch(handleClickAlert());
        } catch (error) {
            dispatch(setAlertMessage(error));
            dispatch(handleClickAlert());
        }
    };

export const getVeradigmGuid =
    (provider: Provider): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(_setLoadingVeradigm(true));
            const providerData = getState().adminProvider.providerData;
            const response = await axios.post(`${API_URL}/provider/createveradigmprovider`, provider);
            dispatch(setProviderData({ ...providerData, VeradigmUserGuid: response.data }));
            dispatch(_setLoadingVeradigm(false));
        } catch (error) {
            console.log('error: ', error);

            dispatch(handleErrorMessage(error));
            dispatch(_setLoadingVeradigm(false));
        }
    };

export const registerWithVeradigm =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(_setLoadingVeradigm(true));
            // Connect E-prescribe
            dispatch(getSSOEndPoint());
            const response = await axios.get(`${API_URL}/account/getprovidersamlstring?providerId=${providerId}`);
            if (response.data) {
                dispatch(setSamlResponse(response.data));
                setTimeout(() => {
                    document.getElementById('submitVeradigmRegistration').click();
                }, 200);
            }
            dispatch(_setLoadingVeradigm(false));
        } catch (error) {
            console.log('error: ', error);
            dispatch(handleErrorMessage(error));
            dispatch(_setLoadingVeradigm(false));
        }
    };

export default adminProvider.reducer;
