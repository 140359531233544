export enum ClaimSourceEnum {
    FormSqlMigrationDescription = 'SQL Migration',
    FormSqlMigration = 1,
    ExcelImportDescription = 'Excel Import',
    ExcelImport = 2,
    ChpClaimsDescription = 'CHP',
    ChpClaims = 3,
    ExternalDescription = 'External',
    External = 4,
    HealthPlanDescription = 'Health Plan',
    HealthPlan = 5,
}
