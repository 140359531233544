import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import 'date-fns';
import { isValid, setHours, setMinutes, setSeconds } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import { setShowMessage } from '../../../store/shared.slice';
import { apiString } from '../../../utils/constants';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import { MbscCalendarEvent } from '@mobiscroll/react';

interface IScheduleExportModalProps extends IBasicModalProps {
    getEventsForExport: (startDate: Date, endDate: Date) => MbscCalendarEvent[];
    initialStartDate?: Date;
    initialEndDate?: Date;
}

const getTrueStartOfDay = (date: Date) => {
    return setHours(setMinutes(setSeconds(date, 0), 0), 0);
};

const getTrueEndOfDay = (date: Date) => {
    return setSeconds(setMinutes(setHours(date, 23), 59), 59);
};

const ScheduleExportModal: React.FC<IScheduleExportModalProps> = (props: IScheduleExportModalProps) => {
    const dispatch = useDispatch();
    const { open, onClose, onSuccess, getEventsForExport, initialStartDate = new Date(), initialEndDate = new Date() } = props;
    const [startDate, setStartDate] = useState<Date>(getTrueStartOfDay(initialStartDate));
    const [endDate, setEndDate] = useState<Date>(getTrueEndOfDay(initialEndDate));
    const [exportableEvents, setExportableEvents] = useState<Appointment[]>([]);
    const [isExporting, setIsExporting] = useState<boolean>(false);

    const handleClose = useCallback(() => {
        setStartDate(getTrueStartOfDay(initialStartDate));
        setEndDate(getTrueEndOfDay(initialEndDate));
        onClose();
    }, [initialEndDate, initialStartDate, onClose]);

    const handleExportClick = useCallback(async () => {
        try {
            // There were a lot of hurdles regarding the process of sourcing the events. We have to think about the recurrence appointments, what type of schedule,
            // provider names, members, external providers, etc. It was easier/safer to provide a callback to the parent component to get the events for export.
            // This lets the implementation for the source of the events be left up to the parent component, and we just worry about providing the date range.
            // This way the event source could be the local calendar memory or a remote API call with curated parameters.
            setIsExporting(true);
            const response = await axios.post(`${apiString}/scheduling/GenerateScheduleXLS`, JSON.stringify(exportableEvents), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                const { data } = response;
                const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                const randomNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
                const fileName =
                    'Schedule_' +
                    (startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : startDate.getMonth() + 1) +
                    '.' +
                    startDate.getDate() +
                    '.' +
                    startDate.getFullYear() +
                    '-' +
                    (endDate.getMonth() + 1 < 10 ? '0' + (endDate.getMonth() + 1) : endDate.getMonth() + 1) +
                    '.' +
                    endDate.getDate() +
                    '.' +
                    endDate.getFullYear() +
                    '_' +
                    randomNumber;
                document.body.appendChild(a);
                a.href = blobUrl;
                a.download = fileName + '.xlsx';
                a.click();
                window.URL.revokeObjectURL(blobUrl);
            }
            setIsExporting(false);
            onSuccess?.();
        } catch (error) {
            setIsExporting(false);
            dispatch(setShowMessage(true, `${error.message}`, 'error'));
            console.error('Error exporting schedule', error);
        }
    }, [dispatch, exportableEvents, onSuccess, startDate, endDate]);

    useEffect(() => {
        setStartDate(getTrueStartOfDay(initialStartDate));
        setEndDate(getTrueEndOfDay(initialEndDate));
    }, [initialStartDate, initialEndDate]);

    useEffect(() => {
        const events = getEventsForExport(startDate, endDate);
        // Ensure we map the event instances start and end as the startDate and endDate for Json serializiation.
        const instancedDateTimeEvents = events.map((event) => {
            return {
                ...event,
                startDate: event.start,
                endDate: event.end,
            } as unknown as Appointment;
        });
        setExportableEvents(instancedDateTimeEvents);
    }, [endDate, getEventsForExport, startDate]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Export Calendar Appointments to Excel</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                        <Typography variant="caption">*Events for export are based on currently loaded data. Dates are inclusive.</Typography>
                        <Typography variant="caption">*Some events may start or end before the range, but the timespan falls within the range.</Typography>
                        <Stack direction="row" spacing={2} style={{ marginTop: 10 }}>
                            <DesktopDatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(date) => {
                                    if (isValid(date)) {
                                        setStartDate(getTrueStartOfDay(date));

                                        if (date > endDate) {
                                            setEndDate(getTrueEndOfDay(date));
                                        }
                                    }
                                }}
                                OpenPickerButtonProps={{
                                    'aria-label': 'change start date',
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(date) => {
                                    if (isValid(date)) {
                                        setEndDate(getTrueEndOfDay(date));

                                        if (date < startDate) {
                                            setStartDate(getTrueStartOfDay(date));
                                        }
                                    }
                                }}
                                OpenPickerButtonProps={{
                                    'aria-label': 'change end date',
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                        <Typography variant="caption">{`There are ${exportableEvents.length} event(s) that will be exported by this date range.`}</Typography>
                    </Stack>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleExportClick} disabled={!exportableEvents.length || isExporting}>
                    {isExporting ? <CircularProgress size={24} color="inherit" /> : 'Export'}
                </Button>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduleExportModal;
