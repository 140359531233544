import { Delete } from '@mui/icons-material';
import { Card, CardContent, Grid, IconButton, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import FormikTextField from '../FormikFields/FormikTextField';
import { IResource } from './interfaces/IResource';
import { IResourceSocialMediaProps } from './interfaces/IResourceSocialMediaProps';

const ResourceSocialMedia: React.FC<IResourceSocialMediaProps> = (props) => {
    const { remove, index, disabled } = props;

    const formik = useFormikContext<IResource>();
    return (
        <Card style={{ marginBottom: 10 }} variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <FormikTextField name={`SocialMedia[${index}].Name`} label="Name" required size="small" disabled={disabled} />
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                        <FormikTextField name={`SocialMedia[${index}].Url`} label="Link" required size="small" disabled={disabled} />
                    </Grid>
                    <Grid item lg={1} md={1} sm={12} xs={12} hidden={disabled}>
                        <Tooltip title="Delete Social Media">
                            <IconButton
                                onClick={() => {
                                    if (formik.values.SocialMedia[index].Id) {
                                        formik.setFieldValue(`SocialMedia[${index}].IsDeleted`, true);
                                    } else {
                                        // If the social media is new, just remove it from the array without persisting in the db.
                                        remove(index);
                                    }
                                }}
                            >
                                <Delete color="error" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ResourceSocialMedia;
