import BaseEntity from '../BaseEntity.model';

export default interface IIncomeItem extends BaseEntity {
    Name: string;
    Amount: number;
    IsDeleted: boolean;
}

export class IncomeItem implements IIncomeItem {
    Id: string;
    Name: string;
    Amount: number;
    IsDeleted: boolean;

    constructor() {
        this.Name = '';
        this.Amount = 0;
        this.IsDeleted = false;
    }
}
