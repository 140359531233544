import { Box, Button, Dialog, DialogActions, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CallItemInfo from '../../../OnboardingWorkflow/MemberDemographicsInfo/CallItemInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { setCallItemDialogOpen } from '../../../../store/adminOnboarding.slice';
import { CallListTabs } from '../../../../store/onboardingWorkflow.slice';
import { isDefaultDate } from '../../../../utils/common';
import moment from 'moment';

const CallHistoryCallItemDialog = () => {
    const dispatch = useDispatch();
    const { callItemDialogOpen, callItem } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const columns: GridColDef[] = [
        {
            field: 'NoteType',
            headerName: 'Activity Type',
            minWidth: 200,
        },
        {
            field: 'Note',
            headerName: 'Note',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'UserName',
            headerName: 'Provider',
            minWidth: 200,
        },
        {
            field: 'CreatedDate',
            headerName: 'Created Date',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CreatedDate) ? '-' : moment(params.row.CreatedDate).format('MM/DD/YYYY hh:mm A')}`,
        },
    ];
    const handleCloseCallItemDialog = () => {
        dispatch(setCallItemDialogOpen(false));
    };
    return (
        <Dialog open={callItemDialogOpen} onClose={handleCloseCallItemDialog} maxWidth="lg" fullWidth>
            <Box sx={{ p: 2 }}>
                <Stack spacing={2}>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginY={1} alignSelf={'center'}>
                        <Typography variant="body2" marginRight={'auto'}>
                            Last Contact:
                        </Typography>
                        <Typography fontWeight={700} variant="body2" textAlign={'right'}>
                            {isDefaultDate(callItem.LastContact) ? '-' : moment(callItem.LastContact).format('MM/DD/YYYY hh:mm A')}
                        </Typography>
                    </Stack>
                    <CallItemInfo callItem={callItem} confirmationCallItem={callItem} selectedCallListTab={CallListTabs.CallList} />

                    <div style={{ width: '100%', height: '47vh' }}>
                        <DataGrid
                            rows={callItem.ActivityNotes || []}
                            columns={columns}
                            // pageSize={pageSize}
                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowHeight={() => 'auto'}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            // getRowClassName={(params) => getClassName(params.row)}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                [`& .IsReached`]: {
                                    color: '#1976d2',
                                },
                                [`& .IsSkipped`]: {
                                    color: '#d32f2f',
                                },
                            }}
                        />
                    </div>
                </Stack>
            </Box>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseCallItemDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default CallHistoryCallItemDialog;
