import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridColumnVisibilityModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { localDateFormat } from '../../../utils/timeFormat';
import { isDefaultDate } from '../../../utils/common';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { setStartDate, setEndDate, fetchSDOHReports, setReportType, exportSDOHReports } from '../../../store/sdohAssessmentsRequests.slice';

const SDOHReports = () => {
    const dispatch = useDispatch();
    const { startDate, endDate, reportType, rowData } = useSelector((state: RootState) => state.sdohAssessmentsRequests);
    const { downLoading } = useSelector((state: RootState) => state.shared);

    const reportTypes = [
        { value: 'reportsdohcomplete?days=30', label: 'SDOH assessments completed within 30 days of onboarding date' },
        { value: 'reportsdohnotcomplete?days=15', label: 'SDOH assessments not completed within 15 days of onboarding date' },
        { value: 'requestssdohcomplete?days=45', label: 'SDOH requests completed within 45 days of the SDOH assessment' },
        { value: 'requestssdohnotcomplete?days=30', label: 'SDOH requests not completed within 30 days of the request date' },
    ];

    const assessmentsModel = {
        DateOfOnboard: true,
        AssessmentCreatedBy: true,
        RequestCompletedDate: false,
        RequestProvider: false,
        AssessmentProvider: false,
        Message: false,
    };
    const requestsModel = {
        DateOfOnboard: false,
        AssessmentCreatedBy: false,
        RequestCompletedDate: true,
        RequestProvider: true,
        AssessmentProvider: true,
        Message: true,
    };

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(assessmentsModel);

    const columns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 110,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'DateOfOnboard',
            headerName: 'Date Of Onboard',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DateOfOnboard) ? '-' : localDateFormat(new Date(params.row.DateOfOnboard))}`,
        },
        {
            field: 'AssessmentDate',
            headerName: 'Assessment Date',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${
                    localDateFormat(new Date(params.row.AssessmentDate)) === 'Invalid Date'
                        ? params.row.AssessmentDate
                        : localDateFormat(new Date(params.row.AssessmentDate))
                }`,
        },
        {
            field: 'AssessmentCreatedBy',
            headerName: 'Assessment Created By',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'RequestCompletedDate',
            headerName: 'Request Completed Date',
            minWidth: 180,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.RequestCompletedDate === 'N/A' ? params.row.RequestCompletedDate : localDateFormat(new Date(params.row.RequestCompletedDate))}`,
        },
        {
            field: 'RequestProvider',
            headerName: 'Request Provider',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'AssessmentProvider',
            headerName: 'Assessment Provider',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'Message',
            headerName: 'Message',
            minWidth: 200,
            flex: 1,
        },
    ];

    const changeHeaders = () => {
        if (reportType === 'requestssdohcomplete?days=45' || reportType === 'requestssdohnotcomplete?days=30') {
            setColumnVisibilityModel(requestsModel);
        } else {
            setColumnVisibilityModel(assessmentsModel);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
                <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                    <Typography fontWeight="fontWeightMedium" component="h3">
                        SDOH Assessments &amp; Requests Report
                    </Typography>
                </Box>
                {<Loading message={'Loading...'} />}
                <Grid item xs={12}>
                    <Stack spacing={2} marginY={2}>
                        <Stack width="100%" direction="row" alignItems="center" spacing={3}>
                            <Button
                                style={{ minWidth: '120px', whiteSpace: 'nowrap', marginLeft: 'auto' }}
                                onClick={() => {
                                    dispatch(fetchSDOHReports(reportType, startDate, endDate));
                                    changeHeaders();
                                }}
                                variant="contained"
                                disabled={reportType.length < 1}
                                color="primary"
                            >
                                Generate Report
                            </Button>
                            <Button
                                style={{ minWidth: '120px', marginLeft: '16px' }}
                                onClick={() => dispatch(exportSDOHReports(reportType, startDate, endDate))}
                                variant="contained"
                                color="success"
                            >
                                {downLoading ? 'Downloading..' : 'Export'}
                            </Button>
                        </Stack>
                        <Stack width="100%" direction="row" alignItems="center" spacing={3}>
                            <FormControl className="form-control-200">
                                <DesktopDatePicker
                                    label="Start Date:"
                                    value={startDate}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        dispatch(setStartDate(newValue));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                            <FormControl className="form-control-200">
                                <DesktopDatePicker
                                    label="End Date:"
                                    value={endDate}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        dispatch(setEndDate(newValue));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '350px' }}>
                                <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={reportType}
                                    value={reportType}
                                    label="Report Type"
                                    onChange={(e) => {
                                        dispatch(setReportType(e.target.value));
                                    }}
                                >
                                    {reportTypes.map((report, index) => (
                                        <MenuItem value={report.value} key={index}>
                                            {report.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item style={{ height: '82vh', width: '100%' }}>
                    <DataGrid
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                        rows={rowData}
                        columns={columns}
                        pageSize={11}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => Math.random()}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default SDOHReports;
