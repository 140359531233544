const useStyles = {
    title: {
        color: '#0063a7',
        marginTop: '10px',
    },
    tableContainer: {
        '& table': {
            tableLayout: 'fixed',

            '& tbody': {
                '& tr': {
                    backgroundColor: '#ededed',

                    '& th, & td': {
                        borderBottom: '7px solid white',
                        verticalAlign: 'center',

                        '& .MuiInputBase-root': {
                            backgroundColor: 'white',
                        },

                        '& .count': {
                            backgroundColor: '#bfd674',
                            width: '35px',
                            height: '35px',
                            display: 'inline-flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 10px 0 -12px',
                            border: '4px solid white',
                        },

                        '& .d-flex-row': {
                            alignItems: 'center',
                        },
                    },
                    '& td.hover-visble': {
                        opacity: 0,
                    },
                    '&:hover td.hover-visble': {
                        opacity: 1,
                    },
                },
            },
        },
    },
    editTableContainer: {
        '& tr': {
            '& th, & td': {
                padding: '0 5px 0 2px',
                borderBottom: '0 !important',
            },
        },
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    textInput: {
        backgroundColor: 'white',
        '& fieldset': {
            top: 0,

            '& legend': {
                display: 'none',
            },
        },
    },
    descriptionText: {
        color: '#666',
        textAlign: 'center',
        flex: 1,
    },
    modalPopupForm: {
        top: '15vh',
        left: '50%',
        width: '350px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',

        '& .mui-modal-header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',

            '& h6': {
                fontSize: '16px',
            },
        },

        '& .mui-modal-body': {
            padding: '15px 15px 25px',
        },

        '& .mui-modal-footer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',

            '& button': {
                marginLeft: '15px',
            },
        },
    },
    iconMainButton: {
        '&.MuiButtonBase-root': {
            minWidth: 'inherit',
            paddingLeft: '5px',
            paddingRight: '5px',
        },
    },
    planTitle: {
        color: '#0063a7',
        fontSize: '22px !important',
        fontWeight: 400,
    },
    newItemContainer: {
        backgroundColor: '#ededed',
        marginTop: '10px',
        padding: '10px',
        paddingRight: 0,

        '& .MuiInputBase-root': {
            backgroundColor: 'white',
        },
    },
    achievedText: {
        cursor: 'pointer',
        '& td': {
            color: 'green',
            fontWeight: 'bold',
        },
    },
    notMetText: {
        cursor: 'pointer',
        '& td': {
            color: '#cf2020',
            fontWeight: 'bold',
        },
    },
    deletedText: {
        cursor: 'pointer',
        '& td': {
            color: '#ed6c02',
            fontWeight: 'bold',
        },
    },
    pointer: {
        cursor: 'pointer',
    },
};

export default useStyles;
