import InfoIcon from '@mui/icons-material/Info';
import { Card, CardContent, CardHeader, Checkbox, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { differenceInWeeks, formatDistanceToNow } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { toggleEventCheckbox, viewEvent } from '../../../store/providerRotation.slice';
import { appointmentTypeEnums } from '../../../utils/constants';
import { CalendarEventData } from '../../Calendar/types/CalendarEventData';

export interface ISwapSelectionCheckboxProps {
    appointment: CalendarEventData;
}

const CSHARP_MIN_VALUE_DATE = new Date('0001-01-01T00:00:00.000Z');

const SwapSelectionCheckbox = (props: ISwapSelectionCheckboxProps) => {
    const {
        appointment: { Id, title, startDate, endDate, appointmentType },
    } = props;
    const dispatch = useDispatch();
    const { providerOneSwapSelectionList, providerTwoSwapSelectionList } = useSelector((state: RootState) => state.providerRotationSlice);

    const isChecked = useMemo(() => {
        return providerOneSwapSelectionList.includes(Id) || providerTwoSwapSelectionList.includes(Id);
    }, [providerOneSwapSelectionList, providerTwoSwapSelectionList, Id]);

    const appointmentTypeMetadata = useMemo(() => {
        return appointmentTypeEnums.find((type) => type.value === appointmentType);
    }, [appointmentType]);

    const handleToggle = useCallback(() => {
        console.log(`toggle event checkbox to ${!isChecked}: ` + Id);
        dispatch(toggleEventCheckbox(Id));
    }, [Id, dispatch, isChecked]);

    const lastSwapDate = useMemo(() => new Date(props.appointment.LastIntegratedCareSwap), [props.appointment.LastIntegratedCareSwap]);

    const isMinValue = useMemo(() => {
        return (
            lastSwapDate.getUTCFullYear() === CSHARP_MIN_VALUE_DATE.getUTCFullYear() &&
            lastSwapDate.getUTCMonth() === CSHARP_MIN_VALUE_DATE.getUTCMonth() &&
            lastSwapDate.getUTCDate() === CSHARP_MIN_VALUE_DATE.getUTCDate()
        );
    }, [lastSwapDate]);

    return (
        <Card
            variant="outlined"
            style={{
                margin: 5,
                padding: '5px',
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                overflow: 'hidden',
                minWidth: 0,
                maxWidth: '300px',
            }}
        >
            <CardHeader
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Open Event Viewer">
                            <IconButton
                                onClick={() => {
                                    dispatch(viewEvent(props.appointment));
                                }}
                                size="small"
                                style={{ marginRight: '8px' }}
                            >
                                <InfoIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Typography noWrap style={{ fontSize: '0.875rem' }}>
                            {title}
                        </Typography>
                    </div>
                }
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5px',
                    width: '100%',
                    minWidth: 0,
                    flexShrink: 1,
                }}
            >
                <Checkbox edge="start" checked={isChecked} tabIndex={-1} disableRipple onClick={handleToggle} />
                <Divider orientation="vertical" flexItem style={{ marginLeft: 10, marginRight: 10 }} />
                <CardContent
                    style={{
                        padding: 0,
                        flex: 1,
                        minWidth: 0,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <Typography
                        variant="body1"
                        style={{
                            fontSize: '0.75rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {`Type: ${appointmentTypeMetadata !== null ? appointmentTypeMetadata?.text : 'N/A'}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            fontSize: '0.75rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {`${new Date(startDate).toLocaleDateString('en-US', { weekday: 'long' })} ${new Date(startDate).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                        })} - ${new Date(endDate).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            fontSize: '0.75rem',
                            fontWeight: differenceInWeeks(new Date(), lastSwapDate) <= 1 && !isMinValue ? 'bold' : 'normal',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {`Last swap: ${props.appointment.LastIntegratedCareSwap && !isMinValue ? formatDistanceToNow(lastSwapDate) + ' ago' : 'Never'}`}
                    </Typography>
                </CardContent>
            </div>
        </Card>
    );
};

export default SwapSelectionCheckbox;
