import { SdohSingleAnswer } from './SdohSingleAnswer.model';

export default interface ISdohMultiAnswer {
    Answer: SdohSingleAnswer[] | null;
    OtherAnswer: string;
    NoAnswerToQuestion: boolean;
}

export class SdohMultiAnswer implements ISdohMultiAnswer {
    Answer: SdohSingleAnswer[] | null;
    OtherAnswer: string;
    NoAnswerToQuestion: boolean;

    constructor() {
        this.Answer = null;
        this.OtherAnswer = '';
        this.NoAnswerToQuestion = false;
    }
}
