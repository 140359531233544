import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { isValidZip } from '../utils/validation';
import b64toBlob from 'b64-to-blob';

const facilities = createSlice({
    name: 'facilities',
    initialState: {
        rowData: [],
        showNewFacilityInputs: false,
        newFacilityState: 'select',
        errorText: '',
        isExporting: false,
        selectedFacility: {
            Name: '',
            ZipCode: '',
            Address: '',
            City: '',
            State: '',
        },
        facilityAction: 'edit',
        newFacilitiesData: {
            Name: '',
            ZipCode: '',
            Address: '',
            City: '',
            State: '',
        },
        dialogVisibilty: false,
        confirmVisibility: false,
    },
    reducers: {
        _setRowData(state, action) {
            state.rowData = action.payload;
        },
        _setShowNewFacilityInputs(state, action) {
            state.showNewFacilityInputs = action.payload;
        },
        _setNewFacilityState(state, action) {
            state.newFacilityState = action.payload;
        },
        _setErrorText(state, action) {
            state.errorText = action.payload;
        },
        _setSelectedFacility(state, action) {
            state.selectedFacility = action.payload;
        },
        _setFacilityAction(state, action) {
            state.facilityAction = action.payload;
        },
        _setIsExporting(state, action) {
            state.isExporting = action.payload;
        },
        _setNewFacilitiesData(state, action) {
            state.newFacilitiesData = action.payload;
        },
        _setDialogVisibility(state, action) {
            state.dialogVisibilty = action.payload;
        },
        _setConfirmVisibility(state, action) {
            state.confirmVisibility = action.payload;
        },
    },
});

const {
    _setRowData,
    _setShowNewFacilityInputs,
    _setNewFacilityState,
    _setErrorText,
    _setSelectedFacility,
    _setFacilityAction,
    _setNewFacilitiesData,
    _setDialogVisibility,
    _setConfirmVisibility,
    _setIsExporting,
} = facilities.actions;

export const setRowData =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setRowData(params));
    };

export const setIsExporting =
    (isExporting: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsExporting(isExporting));
    };

export const setShowNewFacilityInputs =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setShowNewFacilityInputs(params));
    };

export const setNewFacilityState =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setNewFacilityState(params));
    };

export const setErrorText =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setErrorText(params));
    };

export const setSelectedFacility =
    (params: Object): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedFacility(params));
    };

export const setFacilityAction =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setFacilityAction(params));
    };

export const setNewFacilitiesData =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setNewFacilitiesData(params));
    };

export const setDialogVisibility =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setDialogVisibility(params));
    };

export const setConfirmVisibility =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setConfirmVisibility(params));
    };

export const clearSelections = (): AppThunk => async (dispatch) => {
    dispatch(
        setSelectedFacility({
            Name: '',
            ZipCode: '',
            Address: '',
            City: '',
            State: '',
        })
    );
    dispatch(
        setNewFacilitiesData({
            Name: '',
            ZipCode: '',
            Address: '',
            City: '',
            State: '',
        })
    );
    dispatch(setNewFacilityState('select'));
};

export const openConfirm = (): AppThunk => async (dispatch) => {
    dispatch(setConfirmVisibility(true));
};
export const closeConfirm = (): AppThunk => async (dispatch) => {
    dispatch(clearSelections());
    dispatch(setConfirmVisibility(false));
};

export const openDialog = (): AppThunk => async (dispatch) => {
    dispatch(setDialogVisibility(true));
};
export const closeDialog = (): AppThunk => async (dispatch) => {
    dispatch(clearSelections());
    dispatch(setDialogVisibility(false));
};

export const addHandler = (): AppThunk => async (dispatch) => {
    dispatch(setFacilityAction('new'));
    dispatch(setShowNewFacilityInputs(true));
    dispatch(openDialog());
};

export const editHandler = (): AppThunk => async (dispatch) => {
    dispatch(setFacilityAction('edit'));
    dispatch(setShowNewFacilityInputs(false));
    dispatch(openDialog());
};

export const deleteHandler = (): AppThunk => async (dispatch) => {
    dispatch(openConfirm());
};

// Fetches Facility Data for DataGrid
export const getNewFacilitiesData = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/facility`);
    dispatch(setRowData(response.data));
    dispatch(setIsLoading(false));
};

export const putFacilitiesData =
    (selectedFacility): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.put(`${API_URL}/facility`, selectedFacility);
            dispatch(setDialogVisibility(false));
            dispatch(clearSelections());
            dispatch(getNewFacilitiesData());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export const postFacilitiesData =
    (newFacilitiesData: object): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.post(`${API_URL}/facility`, newFacilitiesData);
            dispatch(setDialogVisibility(false));
            dispatch(clearSelections());
            dispatch(getNewFacilitiesData());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export const deleteFacility =
    (selectedFacility: object): AppThunk =>
    async (dispatch) => {
        dispatch(closeConfirm());
        dispatch(setIsLoading(true));
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.delete(`${API_URL}/facility/delete?facilityId=${selectedFacility['Id']}`);
            dispatch(clearSelections());
            dispatch(getNewFacilitiesData());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

// UPLOADS CSV FILE
export const putCSVFile =
    (csvFile: File): AppThunk =>
    async (dispatch) => {
        if (!csvFile) {
            return;
        }
        const fd = new FormData();
        fd.append('File', csvFile, csvFile.name);
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios({ method: 'put', url: `${API_URL}/facility/uploadfile`, data: fd });
            dispatch(clearSelections());
            dispatch(getNewFacilitiesData());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(clearSelections());
            dispatch(getNewFacilitiesData());
            dispatch(setIsLoading(false));
        }
    };

export const handleInputChange =
    (e, newFacilitiesData: object): AppThunk =>
    async (dispatch) => {
        const { name, value } = e.target;
        dispatch(setNewFacilitiesData({ ...newFacilitiesData, [name]: value }));
        if (name === 'ZipCode' && isValidZip(value) === false) {
            dispatch(setErrorText('Invalid ZipCode'));
        } else {
            dispatch(setErrorText(''));
        }
    };

export const handleFacilityEdit =
    (e, selectedFacility: object): AppThunk =>
    async (dispatch) => {
        const { name, value } = e.target;
        dispatch(setSelectedFacility({ ...selectedFacility, [name]: value }));
        if (name === 'ZipCode' && isValidZip(value) === false) {
            dispatch(setErrorText('Invalid ZipCode'));
        } else {
            dispatch(setErrorText(''));
        }
    };

export const fetchFacilitiesExcel = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsExporting(true));
    const response = await axios.get(`${API_URL}/facility/getfacilitiesexcel`);
    if (response.status === 200) {
        const data = await response.data;
        const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        const today = new Date();
        const randomNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
        const fileName =
            (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
            '.' +
            today.getDate() +
            '.' +
            today.getFullYear() +
            '-' +
            randomNumber;
        document.body.appendChild(a);
        a.href = blobUrl;
        a.download = fileName + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        dispatch(setIsExporting(false));
    }
};

export default facilities.reducer;
