import moment from 'moment';
import { HealthPlansEnum } from '../../Enum/HealthPlansEnum';
import { ConsentSignatureTypeEnum } from '../../Enum/ConsentSignatureTypeEnum';

export default interface IDigitalSignatureForm {
    AppointmentConsent: string;
    CareCoordinationMentalHealth: boolean;
    CareCoordinationRelatedIssues: boolean;
    CareCoordinationSubstanceDisorder: boolean;
    CCGProcedure: string;
    ConsentExpiration: Date;
    ConsentTreatment: string;
    GuardianOrCaregiverAddress: string;
    GuardianOrCaregiverName: string;
    GuardianOrCaregiverRelationship: string;
    GuardianOrCaregiverTelephone: string;
    GuardianOrCareGiverType: string;
    GuardianOrCaregiverSignatureOne: string;
    GuardianOrCaregiverSignatureDocumentAcknowledge: string;
    GuardianOrCaregiverSignatureHealthInfo: string;
    HealthPlans: HealthPlansEnum[];
    HealthPlanOther: string;
    InfoRestrictions: string;
    MemberFees: string;
    MemberId: string;
    MemberName: string;
    MemberRights: string;
    MemberSignatureOne: string;
    MemberSignatureTwo: string;
    MemberSignatureThree: string;
    PrivacyPractices: string;
    ProtectedInfo: string;
    ProviderName: string;
    ReviewedOne: boolean;
    ReviewedTwo: boolean;
    ReviewedThree: boolean;
    SectionOneInitials: string;
    SectionTwoInitials: string;
    SectionThreeInitials: string;
    SectionFourInitials: string;
    SectionFiveInitials: string;
    SignatureType: ConsentSignatureTypeEnum;
    VerbalConsent: boolean;
    VerbalConsentSignature: string;
    VerbalConsentWitnessOne: string;
    VerbalConsentWitnessTwo: string;
    WitnessDocumentAcknowledge: string;
    WitnessHealthInfo: string;
    WitnessOne: string;
}

export class DigitalSignatureForm implements IDigitalSignatureForm {
    AppointmentConsent: string;
    CareCoordinationMentalHealth: boolean;
    CareCoordinationRelatedIssues: boolean;
    CareCoordinationSubstanceDisorder: boolean;
    CCGProcedure: string;
    ConsentExpiration: Date;
    ConsentTreatment: string;
    GuardianOrCaregiverAddress: string;
    GuardianOrCaregiverName: string;
    GuardianOrCaregiverRelationship: string;
    GuardianOrCaregiverTelephone: string;
    GuardianOrCareGiverType: string;
    GuardianOrCaregiverSignatureOne: string;
    GuardianOrCaregiverSignatureDocumentAcknowledge: string;
    GuardianOrCaregiverSignatureHealthInfo: string;
    HealthPlans: HealthPlansEnum[];
    HealthPlanOther: string;
    InfoRestrictions: string;
    MemberFees: string;
    MemberId: string;
    MemberName: string;
    MemberRights: string;
    MemberSignatureOne: string;
    MemberSignatureTwo: string;
    MemberSignatureThree: string;
    PrivacyPractices: string;
    ProtectedInfo: string;
    ProviderName: string;
    ReviewedOne: boolean;
    ReviewedTwo: boolean;
    ReviewedThree: boolean;
    SectionOneInitials: string;
    SectionTwoInitials: string;
    SectionThreeInitials: string;
    SectionFourInitials: string;
    SectionFiveInitials: string;
    SignatureType: ConsentSignatureTypeEnum;
    VerbalConsent: boolean;
    VerbalConsentSignature: string;
    VerbalConsentWitnessOne: string;
    VerbalConsentWitnessTwo: string;
    WitnessDocumentAcknowledge: string;
    WitnessHealthInfo: string;
    WitnessOne: string;

    constructor() {
        this.AppointmentConsent = '';
        this.CareCoordinationMentalHealth = false;
        this.CareCoordinationRelatedIssues = false;
        this.CareCoordinationSubstanceDisorder = false;
        this.CCGProcedure = '';
        this.ConsentExpiration = moment().add(1, 'years').toDate();
        this.ConsentTreatment = '';
        this.GuardianOrCaregiverAddress = '';
        this.GuardianOrCaregiverName = '';
        this.GuardianOrCaregiverRelationship = '';
        this.GuardianOrCaregiverTelephone = '';
        this.GuardianOrCareGiverType = '';
        this.GuardianOrCaregiverSignatureOne = '';
        this.GuardianOrCaregiverSignatureDocumentAcknowledge = '';
        this.GuardianOrCaregiverSignatureHealthInfo = '';
        this.HealthPlans = [];
        this.HealthPlanOther = '';
        this.InfoRestrictions = '';
        this.MemberFees = '';
        this.MemberId = '';
        this.MemberName = '';
        this.MemberRights = '';
        this.MemberSignatureOne = '';
        this.MemberSignatureTwo = '';
        this.MemberSignatureThree = '';
        this.PrivacyPractices = '';
        this.ProtectedInfo = '';
        this.ProviderName = '';
        this.ReviewedOne = false;
        this.ReviewedTwo = false;
        this.ReviewedThree = false;
        this.SectionOneInitials = '';
        this.SectionTwoInitials = '';
        this.SectionThreeInitials = '';
        this.SectionFourInitials = '';
        this.SectionFiveInitials = '';
        this.SignatureType = ConsentSignatureTypeEnum.Patient;
        this.VerbalConsent = false;
        this.VerbalConsentSignature = '';
        this.VerbalConsentWitnessOne = '';
        this.VerbalConsentWitnessTwo = '';
        this.WitnessDocumentAcknowledge = '';
        this.WitnessHealthInfo = '';
        this.WitnessOne = '';
    }
}
