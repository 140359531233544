import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    Stack,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ProviderTypeEnum } from '../../../Enum/ProviderTypeEnum';
import { Provider } from '../../../Models/Provider/Provider.model';
import IProviderSelectOption from '../../../Models/Provider/ProviderSelectOption.model';
import { RescheduledNonRecurrenceDto } from '../../../Models/Scheduling/RescheduledNonRecurrenceDto.model';
import { RescheduledRecurrenceDto } from '../../../Models/Scheduling/RescheduledRecurrenceDto.model';
import { RootState } from '../../../reducers';
import { getActiveProviders } from '../../../store/provider.slice';
import { getHumanizedTimeSpan, getTimeSpan } from '../../../utils/common';
import { apiString, appointmentStatusEnums, appointmentTypeEnums, meansOfEngagementEnums, providerTypes } from '../../../utils/constants';
import FormikAutocompleteControlled from '../../FormikFields/FormikAutocompleteControlled';
import FormikDesktopDateTimePicker from '../../FormikFields/FormikDesktopDateTimePicker';
import FormikTextField from '../../FormikFields/FormikTextField';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import HelpDocumentIconButton from '../HelpDocumentIconButton';
import { getEventDuration, rescheduleNonRecurringEvent, rescheduleRecurringEvent } from '../Services/CommonCalendarServices';
import { CalendarEventData } from '../types/CalendarEventData';

// TODO: network calls should trigger a backdrop which prevents further
// user action until the network action is completed to prevent data crashes and/or race conditions.

// TODO: Make it so that when the user changes date it validates the time ranges so that the ending date time
// cannot be before the starting date time and vice versa.

const validationSchema = Yup.object({
    appointmentType: Yup.number().required('Appointment type is required.'),
    meansOfEngagement: Yup.number().required('Means of engagement is required.'),
    status: Yup.number().required('Appointment status is required.'),
    ProviderType: Yup.number().required('Provider type is required.'),
    ProviderName: Yup.string().when('ProviderType', {
        is: (val) => val === ProviderTypeEnum.External,
        then: Yup.string().required('A provider name is required.'),
    }),
    ProviderIds: Yup.array().when('ProviderType', {
        is: (val) => val === ProviderTypeEnum.Internal,
        then: Yup.array().min(1, 'At least one provider is required.').required('A provider is required.'),
    }),
    startDate: Yup.date()
        .required('A starting date is required.')
        .test('is-before-end-date', 'Start date must be before end date', function (value) {
            const { endDate } = this.parent;
            return !value || !endDate || value < endDate;
        }),
    endDate: Yup.date()
        .required('An ending date is required.')
        .test('is-after-start-date', 'End date must be after start date', function (value) {
            const { startDate } = this.parent;
            return !value || !startDate || value > startDate;
        }),
});

type ErrorAlertType = {
    errorTitle: string;
    errorMessage: string;
};

const combineWithCurrentTime = (baseDate: Date): Date => {
    const now = new Date();
    // Set the time from current local time to the baseDate
    baseDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    return baseDate;
};

const getClosestHalfHour = (baseDate: Date): Date => {
    baseDate = combineWithCurrentTime(baseDate);

    const minutes = baseDate.getMinutes();

    // If the minutes are between 0 and 30, we set the minutes to 30. Otherwise, we roll to the next hour.
    if (minutes <= 30) {
        baseDate.setMinutes(30);
    } else {
        baseDate.setHours(baseDate.getHours() + 1);
        baseDate.setMinutes(0);
    }
    baseDate.setSeconds(0);
    baseDate.setMilliseconds(0);

    return baseDate;
};

const getClosestHalfHourPlusThirty = (baseDate: Date): Date => {
    baseDate = getClosestHalfHour(baseDate);
    baseDate.setMinutes(baseDate.getMinutes() + 30); // Add 30 minutes
    return baseDate;
};

export interface ICalendarEventEditModalProps extends IBasicModalProps {
    eventData: CalendarEventData;
    onRescheduleSuccess: (rescheduledAppointment: RescheduledNonRecurrenceDto) => void;
    onRescheduleRecurrenceSuccess: (rescheduledRecurrence: RescheduledRecurrenceDto) => void;
    setLoadingOverlay?: (isLoading: boolean) => void;
}

const RescheduleEventModal = (props: ICalendarEventEditModalProps) => {
    const dispatch = useDispatch();
    const { open, onClose, onRescheduleSuccess, onRescheduleRecurrenceSuccess, eventData } = props;
    const { start } = eventData;
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [selectedProviderOptions, setSelectedProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [errorAlert, setErrorAlert] = useState<ErrorAlertType | null>(null);

    const [defaultAddress, setDefaultAddress] = useState<string>('');

    useEffect(() => {
        const fetchDefaultAddress = async () => {
            if (eventData?.MemberObjectId?.length > 0) {
                const response = await axios.get(`${apiString}/Scheduling/getappointmentdefaultaddress`, { params: { memberId: eventData.MemberObjectId } });
                if (response.data) {
                    setDefaultAddress(response.data.fullAddress);
                }
            }
        };

        fetchDefaultAddress();
    }, [eventData?.MemberObjectId]);

    const appointmentTypeOptions = useMemo(() => {
        return Object.values(appointmentTypeEnums).map((type) => ({ value: type.value, label: type.text }));
    }, []);

    const meansOfEngagementOptions = useMemo(() => {
        return Object.values(meansOfEngagementEnums).map((type) => ({ value: type.value, label: type.text }));
    }, []);

    const appointmentStatusOptions = useMemo(() => {
        return Object.values(appointmentStatusEnums).map((type) => ({ value: type.value, label: type.text, color: type.eventColor }));
    }, []);

    const providerTypeOptions = useMemo(() => {
        return Object.values(providerTypes).map((type) => ({ value: type.value, label: type.text }));
    }, []);

    useEffect(() => {
        dispatch(getActiveProviders(false));
    }, [dispatch]);

    useEffect(() => {
        if (activeProviders.length > 0) {
            const newProviderOptions = activeProviders.map((provider: Provider) => ({
                ProviderId: provider.Id,
                ProviderName: `${provider.LastName}, ${provider.FirstName}`,
                Role: provider.Role.RoleName,
            }));
            setProviderOptions(newProviderOptions);
        }
    }, [activeProviders]);

    useEffect(() => {
        if (eventData?.ProviderIds?.length === 0 || eventData?.ProviderIds === undefined) {
            setSelectedProviderOptions([]);
        } else if (providerOptions?.length > 0 && eventData?.ProviderIds?.length > 0) {
            const selectedProviders = providerOptions.filter((provider) => eventData.ProviderIds.includes(provider.ProviderId));

            setSelectedProviderOptions(selectedProviders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerOptions, eventData.ProviderIds, open]);

    const handleCancel = useCallback(() => {
        setSelectedProviderOptions([]);
        setErrorAlert(null);
        onClose();
    }, [setSelectedProviderOptions, onClose]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize={true}
                initialValues={
                    {
                        ...eventData,
                        ProviderType: eventData.ProviderType ?? providerTypeOptions.find((x) => x.value === ProviderTypeEnum.Internal)?.value,
                        ProviderIds: eventData.ProviderIds ?? [],
                        ProviderName: eventData.ProviderName ?? '',
                        address: eventData.address ?? defaultAddress ?? '',
                        status: eventData.status ?? 0,
                        startDate: eventData.startDate ? (structuredClone(eventData.start) as Date) : getClosestHalfHour(new Date(start)),
                        endDate: eventData.endDate ? (structuredClone(eventData.end) as Date) : getClosestHalfHourPlusThirty(new Date(start)),
                    } as CalendarEventData
                }
                onSubmit={async (values: CalendarEventData) => {
                    props.setLoadingOverlay?.(true);
                    try {
                        if (values.recurring) {
                            const response = await rescheduleRecurringEvent(values, new Date(values.start), new Date(values.startDate));
                            onRescheduleRecurrenceSuccess(response);
                        } else {
                            const response = await rescheduleNonRecurringEvent(values);
                            onRescheduleSuccess(response.data);
                        }
                    } catch (error) {
                        console.error(error);
                        setErrorAlert({ errorTitle: 'Error', errorMessage: error?.message ?? 'Rescheduling the appointment has failed.' });
                    }
                    props.setLoadingOverlay?.(false);
                }}
            >
                {({ submitForm, values, setFieldValue, isValid, resetForm, errors, handleChange, validateForm }: FormikProps<CalendarEventData>) => (
                    <Form>
                        <Dialog
                            open={open}
                            disableEscapeKeyDown
                            onClose={(event, reason) => {
                                if (reason && reason === 'backdropClick') return;
                                onClose();
                            }}
                        >
                            <DialogTitle>
                                <Grid container spacing={1} justifyContent="flex-end">
                                    <Grid container alignItems="center" item lg={12} md={12} sm={12} xs={12} style={{ justifyContent: 'space-between' }}>
                                        Reschedule Single Event From Series
                                        <div style={{ float: 'right' }}>
                                            <HelpDocumentIconButton />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        hidden={
                                            errorAlert !== null ||
                                            values.startDate === null ||
                                            values.endDate === null ||
                                            getTimeSpan(values.startDate, values.endDate).asHours() <= 1
                                        }
                                    >
                                        <Alert variant="filled" severity="info">
                                            This event will last about {getHumanizedTimeSpan(values.startDate, values.endDate)}
                                        </Alert>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} hidden={errorAlert === null}>
                                        <Alert variant="filled" severity="error" onClose={() => setErrorAlert(null)}>
                                            <AlertTitle>{errorAlert?.errorTitle ?? 'Error'}</AlertTitle>
                                            {errorAlert?.errorMessage ?? 'An error occurred. Please try again.'}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikAutocompleteControlled
                                            multiple={false}
                                            name="appointmentType"
                                            label="Type of Appointment"
                                            disableClearable
                                            required
                                            options={appointmentTypeOptions}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={appointmentTypeOptions.find((option) => option.value === values.appointmentType) ?? null}
                                            onChange={(e, value) => {
                                                if (value?.value) {
                                                    let duration = getEventDuration(
                                                        value.value,
                                                        selectedProviderOptions.map((provider) => provider.Role)
                                                    );
                                                    setFieldValue('appointmentType', value.value);

                                                    if (!values.Id || values.Id === '_mbsc') {
                                                        const newEndDate = new Date(values.startDate);
                                                        newEndDate.setMinutes(newEndDate.getMinutes() + duration);
                                                        setFieldValue('endDate', newEndDate);
                                                        validateForm();
                                                    }
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikAutocompleteControlled
                                            multiple={false}
                                            name="meansOfEngagement"
                                            label="Means of Engagement"
                                            required
                                            disableClearable
                                            options={meansOfEngagementOptions}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={meansOfEngagementOptions.find((option) => option.value === values.meansOfEngagement) ?? null}
                                            onChange={(e, value) => {
                                                setFieldValue('meansOfEngagement', value.value);
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormikAutocompleteControlled
                                            multiple={false}
                                            name="status"
                                            label="Appointment Status"
                                            required
                                            disableClearable
                                            options={appointmentStatusOptions}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            width: 14,
                                                            height: 14,
                                                            marginRight: 1,
                                                            backgroundColor: option.color,
                                                            display: 'inline-block',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                    {option.label}
                                                </Box>
                                            )}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={appointmentStatusOptions.find((option) => option.value === values.status) ?? null}
                                            onChange={(e, value) => {
                                                if (value?.value !== undefined && value?.value !== null) {
                                                    setFieldValue('status', value.value);
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid container item>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.IsCustomAddress}
                                                        onChange={(e, value) => {
                                                            setFieldValue('IsCustomAddress', value);
                                                        }}
                                                        name="IsCustomAddress"
                                                    />
                                                }
                                                label="Use a custom address for this appointment"
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} hidden={values.IsCustomAddress}>
                                            <FormikTextField readOnly={true} name="address" label="Default Address" />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} hidden={!values.IsCustomAddress}>
                                            <FormikTextField readOnly={!values.IsCustomAddress} name="CustomAddress" label="Custom Address" />
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormikTextField name="reason" label="Appointment Note" multiline minRows={3} maxRows={10} />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormikAutocompleteControlled
                                            multiple={false}
                                            name="ProviderType"
                                            label="Internal/External Provider"
                                            required
                                            disableClearable
                                            options={providerTypeOptions}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={providerTypeOptions.find((option) => option.value === values.ProviderType) ?? null}
                                            onChange={(e, value) => {
                                                setFieldValue('ProviderType', value.value);
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} hidden={values.ProviderType === ProviderTypeEnum.Internal}>
                                        <FormikTextField name="ProviderName" label="External Provider Name" required />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} hidden={values.ProviderType === ProviderTypeEnum.External}>
                                        <FormikAutocompleteControlled
                                            multiple={true}
                                            fullWidth
                                            id="calendar-edit-provider-selection"
                                            label="Internal Providers"
                                            required
                                            options={providerOptions as IProviderSelectOption[]}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.ProviderId === value.ProviderId;
                                            }}
                                            value={selectedProviderOptions}
                                            onChange={(event, value) => {
                                                const providerValue = value as IProviderSelectOption[];
                                                if (value) {
                                                    setSelectedProviderOptions(providerValue);
                                                    setFieldValue(
                                                        'ProviderIds',
                                                        providerValue.map((item) => item.ProviderId)
                                                    );
                                                } else {
                                                    setSelectedProviderOptions(providerValue);
                                                }

                                                let duration = getEventDuration(
                                                    values.appointmentType,
                                                    providerValue.map((provider) => provider.Role)
                                                );
                                                if (!values.Id || values.Id === '_mbsc') {
                                                    const newEndDate = new Date(values.startDate);
                                                    newEndDate.setMinutes(newEndDate.getMinutes() + duration);
                                                    setFieldValue('endDate', newEndDate);
                                                    validateForm();
                                                }
                                            }}
                                            inputValue={providerInputValue}
                                            onInputChange={(event, value) => {
                                                setProviderInputValue(value);
                                            }}
                                            getOptionLabel={(option: any) => option.ProviderName}
                                            name={'ProviderIds'}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikDesktopDateTimePicker
                                            name="startDate"
                                            label="Starting Date and Time"
                                            onChange={(value) => {
                                                setFieldValue('startDate', value);
                                                const { appointmentType } = values;
                                                let duration = getEventDuration(
                                                    appointmentType,
                                                    selectedProviderOptions.map((provider) => provider.Role)
                                                );
                                                const startDate = new Date(value);
                                                const endDate = new Date(startDate.getTime() + duration * 60000);
                                                setFieldValue('endDate', endDate);
                                                validateForm();
                                            }}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikDesktopDateTimePicker
                                            name="endDate"
                                            label="Ending Date and Time"
                                            onChange={(value) => {
                                                if (value < values.startDate) {
                                                    const { appointmentType } = values;
                                                    let duration = getEventDuration(
                                                        appointmentType,
                                                        selectedProviderOptions.map((provider) => provider.Role)
                                                    );
                                                    const startDate = new Date(value);
                                                    startDate.setMinutes(startDate.getMinutes() - duration);
                                                    setFieldValue('startDate', startDate);
                                                    validateForm();
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {!isValid && (
                                    <Box sx={{ marginTop: '16px' }}>
                                        <Typography variant="caption" color="error">
                                            Please correctly fill in all fields before saving.
                                        </Typography>
                                        <List>
                                            {Object.keys(errors).map((key) => (
                                                <ListItem sx={{ padding: '0px', margin: '0px' }} key={key}>
                                                    <Typography variant="caption" color="error">
                                                        {`• ${String(errors[key])}`}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                )}
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="right">
                                    <Button variant="contained" color="success" onClick={submitForm}>
                                        Reschedule
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            resetForm();
                                            handleCancel();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )}
            </Formik>
        </LocalizationProvider>
    );
};

export default RescheduleEventModal;
