import WidgetMemberInfo  from '../Widget/WidgetMemberInfo.model';

export default interface ISchedulingSummary {
    TotalAppointments: number;
    ConfirmedAppointments: number;
    CancelledAppointments: number;
    NoCallOrNoShowAppointments: number;
    RescheduledAppointments: number;
    CompletedAppointments: number;
    ProviderLeaveAppointments: number;
    NoStatusAppointments: number;
    ConfirmedAppointmentMembers: WidgetMemberInfo[] | null;
    CancelledAppointmentMembers: WidgetMemberInfo[] | null;
    NoCallOrNoShowAppointmentMembers: WidgetMemberInfo[] | null;
    RescheduledAppointmentMembers: WidgetMemberInfo[] | null;
    CompletedAppointmentMembers: WidgetMemberInfo[] | null;
    ProviderLeaveAppointmentMembers: WidgetMemberInfo[] | null;
    NoStatusAppointmentMembers: WidgetMemberInfo[] | null;
}

export default class SchedulingSummary implements ISchedulingSummary {
    TotalAppointments: number;
    ConfirmedAppointments: number;
    CancelledAppointments: number;
    NoCallOrNoShowAppointments: number;
    RescheduledAppointments: number;
    CompletedAppointments: number;
    ProviderLeaveAppointments: number;
    NoStatusAppointments: number;
    ConfirmedAppointmentMembers: WidgetMemberInfo[] | null;
    CancelledAppointmentMembers: WidgetMemberInfo[] | null;
    NoCallOrNoShowAppointmentMembers: WidgetMemberInfo[] | null;
    RescheduledAppointmentMembers: WidgetMemberInfo[] | null;
    CompletedAppointmentMembers: WidgetMemberInfo[] | null;
    ProviderLeaveAppointmentMembers: WidgetMemberInfo[] | null;
    NoStatusAppointmentMembers: WidgetMemberInfo[] | null;

    constructor() {
        this.TotalAppointments = 0;
        this.ConfirmedAppointments = 0;
        this.CancelledAppointments = 0;
        this.NoCallOrNoShowAppointments = 0;
        this.RescheduledAppointments = 0;
        this.CompletedAppointments = 0;
        this.ProviderLeaveAppointments = 0;
        this.NoStatusAppointments = 0;
        this.ConfirmedAppointmentMembers = null;
        this.CancelledAppointmentMembers = null;
        this.NoCallOrNoShowAppointmentMembers = null;
        this.RescheduledAppointmentMembers = null;
        this.CompletedAppointmentMembers = null;
        this.ProviderLeaveAppointmentMembers = null;
        this.NoStatusAppointmentMembers = null;
    }
}