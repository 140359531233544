import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';

import { RootState } from '../../../reducers';
import { setMemberBudget } from '../../../store/memberBudget.slice';
import classes from '../Styles';
import { copyObject } from '../../../utils/common';
import CurrencyInput from '../../Shared/CurrencyInput';

const UtilitiesExpenses = () => {
    const dispatch = useDispatch();
    const { memberBudget } = useSelector((state: RootState) => state.memberBudget);

    const handleOnChangeUtilitySource = (val: any, propName: string, index: number) => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.UtilityExpenses[index][propName] = val;
        dispatch(setMemberBudget(memberBudgetData));
    };

    return (
        <TableContainer component={Paper} sx={classes.budgetFormTable}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Utilities</TableCell>
                        <TableCell>Highest Month's Bills</TableCell>
                        <TableCell>Lowest Month's Bills</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>Billing Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberBudget.UtilityExpenses?.map((utility, index) => (
                        <TableRow key={`utility-expenses-${index}`} hover>
                            <TableCell scope="row">{utility.Name}</TableCell>
                            <TableCell scope="row">
                                <CurrencyInput
                                    size="small"
                                    fullWidth
                                    value={utility.HighestMonthBill}
                                    name="HighestMonthBill"
                                    onChange={(e) => handleOnChangeUtilitySource(e.target.value, e.target.name, index)}
                                />
                            </TableCell>
                            <TableCell scope="row">
                                <CurrencyInput
                                    size="small"
                                    fullWidth
                                    value={utility.LowestMonthBill}
                                    name="LowestMonthBill"
                                    onChange={(e) => handleOnChangeUtilitySource(e.target.value, e.target.name, index)}
                                />
                            </TableCell>
                            <TableCell scope="row">
                                <TextField
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    className="hide-textinput-label"
                                    value={utility.CompanyName}
                                    name="CompanyName"
                                    onChange={(e) => handleOnChangeUtilitySource(e.target.value, e.target.name, index)}
                                />
                            </TableCell>
                            <TableCell scope="row">
                                <TextField
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    className="hide-textinput-label"
                                    value={utility.AccountNumber}
                                    name="AccountNumber"
                                    onChange={(e) => handleOnChangeUtilitySource(e.target.value, e.target.name, index)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    className="hide-textinput-label"
                                    value={utility.BillingName}
                                    name="BillingName"
                                    onChange={(e) => handleOnChangeUtilitySource(e.target.value, e.target.name, index)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UtilitiesExpenses;
