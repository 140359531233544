import { Box, Button, Grid, Stack, Tab, Tabs } from '@mui/material';
import Loading from '../Shared/Loading';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import EducationSessions from './EducationSessions/EducationSessions';
import AssignEducationMaterials from './AssignEducationMaterials/AssignEducationMaterials';
import RemoteMonitoring from './RemoteMonitoring/RemoteMonitoring';
import Authorization from '../../utils/Authorization';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';
import { gotoNextMenu, gotoPreviousMenu } from '../../store/assessment.slice';
import { RootState } from '../../reducers';
import AlertDialog from '../AlertDialog/AlertDialog';
import { StatusEnum } from '../../Enum/StatusEnum';
import { useParams } from 'react-router-dom';

const MemberEducation = (props: any) => {
    const dispatch = useDispatch();
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const isAssessment = Boolean(props.isAssessment);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);

    const auth = new Authorization();

    const [tab, setTab] = useState<number>(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveEngagementDraft({ ...engagementDraft, EducationStatus: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    let content = undefined;
    switch (tab) {
        case 0: {
            content = <EducationSessions memberId={memberId} isAssessment={isAssessment} />;
            break;
        }
        case 1: {
            content = <AssignEducationMaterials memberId={memberId} isAssessment={isAssessment} />;
            break;
        }
        case 2: {
            content = <RemoteMonitoring auth={auth} memberId={memberId} />;
            break;
        }
        default: {
            content = <EducationSessions memberId={memberId} />;
        }
    }

    return (
        <Box className={isAssessment ? 'content-panel' : ''} style={isAssessment ? { padding: '10px', margin: '-20px 0 -20px -20px' } : null}>
            {<Loading message={'Loading...'} />}
            <Grid container justifyContent={'flex-end'} spacing={2} p={2}>
                <Grid item xs={12}>
                    <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" sx={{ marginBottom: 2 }}>
                        <Tab label="Education Sessions" value={0} />
                        <Tab label="Assign Education Materials" value={1} />
                        <Tab label="Remote Monitoring" value={2} />
                    </Tabs>
                </Grid>
                <Grid item xs={12} p={2}>
                    {content}
                </Grid>
            </Grid>
            {isAssessment ? (
                <Stack direction="row" justifyContent="flex-end" className="content-footer" style={{ margin: '20px -10px -10px' }}>
                    <Button variant="contained" color="secondary" sx={{ mr: 3 }} onClick={() => dispatch(gotoPreviousMenu())} startIcon={<ArrowBackIosIcon />}>
                        Previous
                    </Button>
                    <Button variant="contained" onClick={() => setShowSaveAlert(true)} endIcon={<ArrowForwardIosIcon />}>
                        Next
                    </Button>
                </Stack>
            ) : null}
            {Boolean(engagementDraft.EducationStatus) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Education"
                    cancelText="Go Back"
                    skipText="No"
                    okText="Yes"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    message="Were education materials or supplies provided?"
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Education"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSavingEngagementDraft}
                    message="Are you sure to proceed without completing education session?"
                />
            )}
        </Box>
    );
};

export default MemberEducation;
