import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material';
import { useRef } from 'react';

import ReactSignatureCanvas from 'react-signature-canvas';

interface InitialsInputModalProps {
    isOpen: boolean;
    onClose: () => void;
    signature: string;
    setSignature: (signature: string) => void;
    isSpanish?: boolean;
}

export default function Signature(props: any) {
    const { buttonLabel, isOpen, setIsOpen, signature, setSignature, notRequired } = props;
    const sigCanvasRef = useRef<any>(null);
    const dialogContentRef = useRef<HTMLDivElement>(null);
    const canvasSize = { width: 825, height: 180 };
    const SignatureInput = (props: InitialsInputModalProps) => {
        const handleClose = () => {
            setIsOpen(false);
            sigCanvasRef.current?.clear();
        };
        const handleAccept = () => {
            if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
                const signatureDataUrl = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
                setSignature(signatureDataUrl);
            }
            handleClose();
        };
        return (
            <Dialog
                open={props.isOpen || false}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{props.isSpanish ? 'Firma' : 'Signature'}</DialogTitle>
                <DialogContent ref={dialogContentRef} sx={{ border: 1, m: 2, p: 0 }}>
                    <ReactSignatureCanvas
                        ref={sigCanvasRef}
                        penColor="blue"
                        canvasProps={{
                            width: canvasSize.width,
                            height: canvasSize.height,
                            className: 'sigCanvas',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        {props.isSpanish ? 'Cancelar' : 'Cancel'}
                    </Button>
                    <Button variant="contained" onClick={handleAccept}>
                        {props.isSpanish ? 'Aceptar' : 'Accept'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const SignatureDisplay = (props: any) => {
        return (
            <Grid container spacing={0} alignItems={'center'} my={5} py={2}>
                <Grid item xs={12}>
                    <Stack direction={'row'} spacing={2} alignItems="flex-end">
                        <Box sx={{ position: 'relative', flexGrow: 1 }}>
                            <TextField
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        borderBottom: '1px solid black',
                                    },
                                }}
                                sx={{ width: '100%' }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -80%)',
                                    display: 'flex',
                                }}
                            >
                                {props.signature.length > 0 ? (
                                    <Box
                                        component="img"
                                        sx={{
                                            height: '50%',
                                            width: '50%',
                                            mx: 'auto',
                                            // maxHeight: { xs: 63 },
                                            // maxWidth: { xs: 210 },
                                            mb: '-10px',
                                        }}
                                        alt="Member initials."
                                        src={props.signature}
                                    />
                                ) : null}
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={'bold'} align="center" pb={2}>
                        {props.label}
                    </Typography>
                </Grid>
                {props.signature.length > 0 ? (
                    <Grid item xs={12} textAlign={'center'}>
                        <Button variant="outlined" sx={{ minWidth: '120px' }} onClick={() => props.setSignature('')}>
                            {props.isSpanish ? 'Limpiar' : 'Clear'}
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        );
    };

    return (
        <>
            <SignatureInput isSpanish={props.isSpanish} isOpen={isOpen} onClose={() => setIsOpen(false)} signature={signature} setSignature={setSignature} />
            {signature.length > 0 ? (
                <SignatureDisplay isSpanish={props.isSpanish} signature={signature} setSignature={setSignature} label={buttonLabel} />
            ) : (
                <Stack spacing={1} alignItems={'center'} mt={5}>
                    <Button variant="contained" onClick={() => setIsOpen(true)} sx={{ width: '300px' }}>
                        {buttonLabel}
                    </Button>
                    {!notRequired ? (
                        <Typography variant="body1" color={'error'} textAlign={'center'}>
                            * {props.isSpanish ? 'Requerido' : 'Required'}
                        </Typography>
                    ) : null}
                </Stack>
            )}
        </>
    );
}
