import { FormControl, InputLabel, Grid, MenuItem, Paper, Select, Typography, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { apiString as API_URL, parentUrl } from '../../../../utils/constants';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';
import { dateFormatter } from '../../../../utils/timeFormat';
import { isDefaultDate } from '../../../../utils/common';

const MembersMissingGender = () => {
    const authData = new Authorization();
    const [memberMissingGender, setMemberMissingGender] = useState([]);
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState('Active');

    const selectedStatusOnChangeHandler = (e) => {
        setSelectedStatus(e.target.value);
        const copy = [...memberMissingGender];
        if (e.target.value === 'All') {
            setRows(copy);
        } else {
            const filtered = copy.filter((el) => el.Status === e.target.value);
            setRows(filtered);
        }
    };
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        setIsFetched(false);
        const changeFilter = async () => {
            setIsFetched(false);
            const response = await axios.get(`${API_URL}/member/getmembersmissinggender`);
            setMemberMissingGender(response.data);

            const filtered = response.data.filter((el) => el.Status === 'Active');
            setRows(filtered);
            setIsFetched(true);
        };
        changeFilter();
    }, [authData.UserId, setMemberMissingGender, setIsFetched]);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'Gender',
            headerName: 'Gender',
            flex: 1,
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
        },
    ];

    const activeStatuses = [
        'Undetermined',
        'Active',
        'OffBoarding',
        'Scheduled',
        'HRA in Review',
        'Uncompensated Care',
        'Suspended',
        'FUHFUM In-Progress',
        'FUH-Und',
    ];

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 90) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                        Members Missing Gender
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="caption">Open members who have a Gender of "Non-Declared"</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Filter by</InputLabel>
                        <Select
                            value={selectedStatus}
                            label="Filter by"
                            name="filter"
                            onChange={selectedStatusOnChangeHandler}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                        >
                            <MenuItem key="All" value="All">
                                All
                            </MenuItem>
                            {activeStatuses.map((state, index) => {
                                return (
                                    <MenuItem key={index} value={state}>
                                        {state}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <div style={{ width: '100%', height: 'Calc(70vh - 135px)', position: 'relative' }}>
                        {!isFetched && <WidgetLoading />}
                        <DataGrid
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default MembersMissingGender;
