import { MedicationTypeEnum } from '../../utils/assessments';
import BaseEntity from '../BaseEntity.model';
import { ProviderPreview } from '../CarePlans/SchedulePlan.model';
import CreatedBy from '../Shared/LastUpdatedBy.model';
import { SlidingScale } from './SlidingScale.model';
import { Taper } from './Taper.model';

export default interface IMedications extends BaseEntity {
    Medicine: string;
    Dosage: string;
    Frequency: string;
    Refills: number;
    Reminder: string;
    LastTaken: string;
    StartDate: string;
    EndDate: string;
    PrescribedFor: string;
    PrescribedBy: CreatedBy;
    OrderingProvider: ProviderPreview;
    CCRDataObjectID: string;
    Source: string;
    OriginalDosage: string;
    MedicationType: string;
    ReconcileDate: string;
    Status: string;
    SlidingScales: SlidingScale[];
    SlidingCeiling: number;
    Tapers: Taper[];
    MedicationSchedule: string[];
    HiddenFromMember: boolean;
    DisplayOnReportPdf: boolean;
}

export class Medications implements IMedications {
    Id: string;
    Medicine: string;
    Dosage: string;
    Frequency: string;
    Refills: number;
    Reminder: string;
    LastTaken: string;
    StartDate: string;
    EndDate: string;
    PrescribedFor: string;
    PrescribedBy: CreatedBy;
    OrderingProvider: ProviderPreview;
    CCRDataObjectID: string;
    Source: string;
    OriginalDosage: string;
    MedicationType: string;
    ReconcileDate: string;
    Status: string;
    DCReason: string;
    SlidingScales: SlidingScale[];
    SlidingCeiling: number;
    Tapers: Taper[];
    MedicationSchedule: string[];
    HiddenFromMember: boolean;
    IsSelected: boolean;
    DisplayOnReportPdf: boolean;

    constructor() {
        this.Id = '';
        this.Medicine = '';
        this.Dosage = '';
        this.Frequency = '';
        this.IsSelected = false;
        this.MedicationType = MedicationTypeEnum.Medication;
        this.SlidingScales = [] as SlidingScale[];
        this.Tapers = [];
        this.DisplayOnReportPdf = false;
    }
}