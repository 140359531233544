import { Button, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { closeEditHandler, insertRole, setAssignedWidgets, setSelectAllWidgets, updateRole } from '../../store/adminRoles.slice';

const Widgets = (props) => {
    const dispatch = useDispatch();

    const {
        assignedWidgets,
        assignedFacilities,
        openNew,
        selectedRole,
        selectAllWidgets,
        smartdashboardWidgets,
        hedisMetricsWidgets,
        analyticsWidgets,
        roleName,
        rolePermissions,
    } = useSelector((state: RootState) => state.adminRoles);

    const selectAllHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            dispatch(setSelectAllWidgets(true));
            dispatch(setAssignedWidgets([...smartdashboardWidgets, ...hedisMetricsWidgets, ...analyticsWidgets]));
        } else {
            dispatch(setSelectAllWidgets(false));
            dispatch(setAssignedWidgets([]));
        }
    };

    const selectAllCategoryHandler = (e: React.ChangeEvent<HTMLInputElement>, widgetList) => {
        // const filteredArray = assignedWidgets.filter((widget) => smartdashboardWidgets.includes(widget) !== true);
        const filteredArray = assignedWidgets.filter((widgetA) => {
            return !widgetList.find((widgetB) => {
                return widgetA.Id === widgetB.Id;
            });
        });
        if (e.target.checked) {
            dispatch(setAssignedWidgets([...widgetList, ...filteredArray]));
        } else {
            dispatch(setAssignedWidgets([...filteredArray]));
        }
    };

    const widgetsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = assignedWidgets?.findIndex((widget) => widget.Name === e.target.value);

        if (index === -1) {
            dispatch(setAssignedWidgets([...assignedWidgets, { Name: e.target.value, Id: e.target.id }]));
        } else {
            dispatch(setAssignedWidgets(assignedWidgets?.filter((widget) => widget.Id !== e.target.id)));
        }
    };

    const checkSelectAll = (widgetList) => {
        const assigned = assignedWidgets.map((el) => el.Id);
        const list = widgetList.map((el) => el.Id);
        const found = list.every((r) => assigned.indexOf(r) >= 0);
        return found;
    };

    useEffect(() => {
        if (assignedWidgets?.length === smartdashboardWidgets.length + analyticsWidgets.length + hedisMetricsWidgets.length) {
            dispatch(setSelectAllWidgets(true));
        } else {
            dispatch(setSelectAllWidgets(false));
        }
    }, [assignedWidgets, smartdashboardWidgets, analyticsWidgets, hedisMetricsWidgets.length, dispatch]);

    return (
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12}>
                    <FormControlLabel
                        disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                        label="Select All Widgets"
                        control={<Switch checked={selectAllWidgets} onChange={selectAllHandler} />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color={selectedRole.Name.length === 0 && !openNew ? 'rgba(155,155,155,.6)' : 'black'} marginY={2}>
                        Smart Dashboard Widgets
                    </Typography>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                            label="Select All Smart Dashboard Widgets"
                            control={
                                <Switch checked={checkSelectAll(smartdashboardWidgets)} onChange={(e) => selectAllCategoryHandler(e, smartdashboardWidgets)} />
                            }
                        />
                    </Grid>
                    <FormGroup sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {[...smartdashboardWidgets]
                            .sort((a, b) => a.Name.localeCompare(b.Name))
                            .map((el) => {
                                return (
                                    <Grid item xs={4} key={el.Id}>
                                        <FormControlLabel
                                            label={el.Name}
                                            control={
                                                <Switch
                                                    id={el.Id}
                                                    checked={assignedWidgets?.map((i) => i.Id).includes(el.Id)}
                                                    disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                                                    value={el.Name}
                                                    onChange={widgetsHandler}
                                                />
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color={selectedRole.Name.length === 0 && !openNew ? 'rgba(155,155,155,.6)' : 'black'} marginY={2}>
                        Hedis Metrics Widgets
                    </Typography>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                            label="Select All Hedis Metrics Widgets"
                            control={
                                <Switch checked={checkSelectAll(hedisMetricsWidgets)} onChange={(e) => selectAllCategoryHandler(e, hedisMetricsWidgets)} />
                            }
                        />
                    </Grid>
                    <FormGroup sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {[...hedisMetricsWidgets]
                            .sort((a, b) => a.Name.localeCompare(b.Name))
                            .map((el) => {
                                return (
                                    <Grid item xs={4} key={el.Id}>
                                        <FormControlLabel
                                            label={el.Name}
                                            control={
                                                <Switch
                                                    id={el.Id}
                                                    checked={assignedWidgets?.map((i) => i.Id).includes(el.Id)}
                                                    disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                                                    value={el.Name}
                                                    onChange={widgetsHandler}
                                                />
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color={selectedRole.Name.length === 0 && !openNew ? 'rgba(155,155,155,.6)' : 'black'} marginY={2}>
                        Analytics Widgets
                    </Typography>
                    <Grid item xs={12}>
                        <FormControlLabel
                            disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                            label="Select All Analytics Widgets"
                            control={<Switch checked={checkSelectAll(analyticsWidgets)} onChange={(e) => selectAllCategoryHandler(e, analyticsWidgets)} />}
                        />
                    </Grid>
                    <FormGroup sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {[...analyticsWidgets]
                            .sort((a, b) => a.Name.localeCompare(b.Name))
                            .map((el) => {
                                return (
                                    <Grid item xs={4} key={el.Id}>
                                        <FormControlLabel
                                            label={el.Name}
                                            control={
                                                <Switch
                                                    id={el.Id}
                                                    checked={assignedWidgets?.map((i) => i.Id).includes(el.Id)}
                                                    disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                                                    value={el.Name}
                                                    onChange={widgetsHandler}
                                                />
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                    </FormGroup>
                </Grid>

                <Grid item xs={12} display="flex" justifyContent={'flex-end'}>
                    <Button
                        className="button-120"
                        sx={{ marginRight: 2 }}
                        disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                        variant="contained"
                        onClick={
                            openNew
                                ? () =>
                                      dispatch(
                                          insertRole({
                                              Id: '',
                                              Name: roleName,
                                              Permissions: rolePermissions,
                                              Widgets: assignedWidgets,
                                              ProviderFacilities: assignedFacilities,
                                          })
                                      )
                                : () =>
                                      dispatch(
                                          updateRole({
                                              Id: selectedRole.Id,
                                              Name: roleName,
                                              Permissions: rolePermissions,
                                              Widgets: assignedWidgets,
                                              ProviderFacilities: assignedFacilities,
                                          })
                                      )
                        }
                    >
                        Save
                    </Button>
                    <Button className="button-120" variant="contained" onClick={() => dispatch(closeEditHandler())}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Widgets;
