export enum HedisMetricsTypeEnum {
    BP = 1,
    BpDescription = 'BP',
    ProviderVisits = 2,
    ProviderVisitsDescription = 'AdultHealth',
    BMI = 3,
    BmiDescription = 'BMI',
    BreastCancerScreening = 4,
    BreastCancerDescription = 'BreastCancerScreening',
    HgbA1c = 5,
    HgbA1cDescription = 'HgbA1c',
    DiabeticEyeExam = 6,
    DiabeticEyeExamDescription = 'DiabeticEyeExam',
    MedicationReconciliation = 7,
    MedicationReconciliationDescription = 'MedicationReconciliation',
    DepressionScreening = 8,
    DepressionScreeningDescription = 'DepressionScreening',
    DepressionFollowUp = 9,
    DepressionFollowUpDescription = 'DepressionFollowUp',
    LipidForBhDx = 10,
    LipidForBhDxDescription = 'LipidForBhDx',
    HgbA1cForBhDx = 11,
    HgbA1cForBhDxDescription = 'HgbA1cForBhDx',
}
