import { Alert, FormControl, FormControlLabel, FormLabel, Grid, Link, Radio, RadioGroup, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { updateQuestionAnswer } from '../../../store/requests.slice';
import { IRequestQuestionAnswer } from '../interfaces';

const RequestsQuestionCard: React.FC<
    Partial<IRequestQuestionAnswer> & {
        index: number;
        requestId?: string;
        setAnswer?: (answer: Partial<IRequestQuestionAnswer>) => void;
        textAnswer?: string;
        isNewQuestion?: boolean;
        disabled?: boolean;
        debounced?: boolean;
    }
> = ({ QuestionId, QuestionText, AnswerType, textAnswer, Choices, Hyperlink, Choice, AnswerText, setAnswer, disabled, debounced, requestId, index }) => {
    const dispatch = useDispatch();
    const [answerText, setAnswerText] = useState<string>(AnswerText ?? textAnswer ?? '');
    const { selectedRequest } = useSelector((state: RootState) => state.requests);
    const [choice, setChoice] = useState<string>(Choice ?? '');

    useEffect(() => {
        if (AnswerText !== undefined) {
            setAnswerText(AnswerText);
        }
    }, [AnswerText]);

    useEffect(() => {
        if (Choice !== undefined) {
            setChoice(Choice);
        }
    }, [Choice]);

    const handleRadioButtonClick = (_event: any, choice: string) => {
        setChoice(choice);
    };

    const radioButtons = useMemo(() => {
        if (Choices?.length > 0) {
            return Choices.map((choice) => <FormControlLabel value={choice} control={<Radio />} label={choice} />);
        }
    }, [Choices]);

    let questionComponent = undefined;
    switch (AnswerType) {
        case 'Text': {
            questionComponent = (
                <TextField
                    sx={{ flexGrow: 1 }}
                    disabled={disabled}
                    value={answerText}
                    onChange={(event) => {
                        setAnswerText(event.target?.value ?? '');
                    }}
                    variant="outlined"
                    label={QuestionText}
                    fullWidth={true}
                    minRows={5}
                    multiline
                />
            );
            break;
        }
        case 'Select': {
            questionComponent = (
                <FormControl disabled={disabled} fullWidth sx={{ flexGrow: 1 }}>
                    <FormLabel id={`${QuestionId}-label`}>{QuestionText}</FormLabel>
                    <RadioGroup aria-labelledby={`${QuestionId}-label`} name="controlled-radio-buttons-group" value={choice} onChange={handleRadioButtonClick}>
                        {radioButtons}
                    </RadioGroup>
                </FormControl>
            );
            break;
        }
        case 'Note': {
            questionComponent = <Alert severity="info">{QuestionText}</Alert>;
            break;
        }
        default: {
            questionComponent = <Alert severity="error">Error: unexpected question answer mode {`"${AnswerType}"`}</Alert>;
        }
    }

    const debouncedSetAnswer = useRef(
        debounce((answer: Partial<IRequestQuestionAnswer>) => {
            dispatch(updateQuestionAnswer(answer as IRequestQuestionAnswer));
        }, 500)
    ).current;

    useEffect(() => {
        if (answerText !== AnswerText || choice !== Choice) {
            if (debounced === false && setAnswer !== undefined) {
                setAnswer({ AnswerText: answerText, Choice: choice });
            } else if (selectedRequest?.Id?.length > 0) {
                debouncedSetAnswer({
                    ...selectedRequest.RequestQuestionAnswers[index],
                    AnswerText: answerText,
                    Choice: choice,
                });
            }
        }
        return () => debouncedSetAnswer.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answerText, choice, debouncedSetAnswer]);

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            {Hyperlink?.length > 0 && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Link rel="noopener" target="_blank" href={Hyperlink}>
                        {Hyperlink}
                    </Link>
                </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {questionComponent}
            </Grid>
        </Grid>
    );
};

export default RequestsQuestionCard;
