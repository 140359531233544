import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSignatureCanvas from 'react-signature-canvas';
import { saveMemberSignature, saveProviderSignature, setShowSignatureModal, setSignatureType, setVerbalConsentName } from '../../../store/carePlan.slice';

import { CarePlanSignatureTypeEnum } from '../../../Enum/CarePlanSignatureTypeEnum';
import { RootState } from '../../../reducers';

interface SignatureInputModalProps {
    isOpen: boolean;
}

const SignatureModal = (props: SignatureInputModalProps) => {
    const dispatch = useDispatch();
    const sigCanvasRef = useRef<any>(null);
    const { signatureType, verbalConsentName } = useSelector((state: RootState) => state.carePlan);

    const dialogContentRef = useRef<HTMLDivElement>(null);

    const handleWindowResize = () => {
        if (dialogContentRef.current) {
            setCanvasSize({
                width: dialogContentRef.current.clientWidth,
                height: dialogContentRef.current.clientHeight,
            });
        }
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        return () => {
            window.removeEventListener('resize', handleWindowResize, false);
        };
    }, []);

    const dialogContent = document.getElementById('dialog-content');

    const [canvasSize, setCanvasSize] = useState({ width: dialogContent?.offsetWidth || 800, height: 180 });

    const handleClose = () => {
        dispatch(setShowSignatureModal(false));
        sigCanvasRef.current?.clear();
    };
    const handleAccept = () => {
        if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
            const signatureDataUrl = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
            if (signatureType === CarePlanSignatureTypeEnum.Provider) {
                dispatch(saveProviderSignature(signatureDataUrl));
            } else {
                dispatch(saveMemberSignature(signatureDataUrl, signatureType));
            }
        }
        if (signatureType === CarePlanSignatureTypeEnum.VerbalConsent) {
            dispatch(saveMemberSignature(verbalConsentName, signatureType));
        }
        handleClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSignatureType(parseInt(event.target.value)));
    };

    return (
        <Dialog
            open={props.isOpen || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Signature</DialogTitle>
            <DialogContent id="dialog-content">
                <FormControl sx={{ mb: 2 }} fullWidth>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={signatureType}
                        onChange={handleChange}
                        name="radio-buttons-group"
                        sx={{ width: '100%' }}
                    >
                        <FormControlLabel value={CarePlanSignatureTypeEnum.Provider} control={<Radio />} label="Provider" />
                        <FormControlLabel sx={{ ml: 'auto' }} value={CarePlanSignatureTypeEnum.Member} control={<Radio />} label="Member" />
                        <FormControlLabel value={CarePlanSignatureTypeEnum.GuardianOrCareGiver} control={<Radio />} label="Guardian/Caregiver" />
                        <FormControlLabel value={CarePlanSignatureTypeEnum.HealthCareDecisionMaker} control={<Radio />} label="Decision Maker" />
                        <FormControlLabel value={CarePlanSignatureTypeEnum.VerbalConsent} control={<Radio />} label="Verbal Consent" />
                    </RadioGroup>
                </FormControl>
                {signatureType !== CarePlanSignatureTypeEnum.VerbalConsent ? (
                    <Box ref={dialogContentRef} border={1}>
                        <ReactSignatureCanvas
                            ref={sigCanvasRef}
                            penColor="blue"
                            canvasProps={{
                                width: canvasSize.width,
                                height: canvasSize.height,
                                className: 'sigCanvas',
                            }}
                        />
                    </Box>
                ) : null}
                {signatureType === CarePlanSignatureTypeEnum.VerbalConsent ? (
                    <TextField fullWidth label="Name" value={verbalConsentName} onChange={(e) => dispatch(setVerbalConsentName(e.target.value))} />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
                <Button disabled={sigCanvasRef.current === ''} variant="contained" onClick={handleAccept}>
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SignatureModal;
