import { SdohSingleAnswerAdvanced } from './SdohSingleAnswerAdvanced.model';

export default interface IPersonalCharacteristics {
    LanguageAreMostComfortableSpeaking: SdohSingleAnswerAdvanced | null;
    HaveProblemsInReadingHealthLiterature: SdohSingleAnswerAdvanced | null;
}

export class PersonalCharacteristics implements IPersonalCharacteristics {
    LanguageAreMostComfortableSpeaking: SdohSingleAnswerAdvanced | null;
    HaveProblemsInReadingHealthLiterature: SdohSingleAnswerAdvanced | null;

    constructor() {
        this.LanguageAreMostComfortableSpeaking = new SdohSingleAnswerAdvanced();
        this.HaveProblemsInReadingHealthLiterature = new SdohSingleAnswerAdvanced();
    }
}
