import axios from 'axios';
import { apiString as api } from '../../../utils/constants';

export const hedisMetricSummary = async (teamId: string, includeInActiveTeams: boolean) => {
    const response = await axios.get(
        `${api}/metricsummary/gethedismetricsummary?teamId=${teamId}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const qaMetricSummary = async (providerId: string, includeInActiveProviders: boolean) => {
    const response = await axios.get(
        `${api}/metricsummary/getqametricsummary?providerId=${providerId}&includeInActiveProviders=${includeInActiveProviders}`
    );
    return response.data;
};