import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Tooltip, Typography, TextField, FormControl, Grid } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { RootState } from '../../reducers';
import { addKeyContact, toggleIsShowKeyContactsForm } from '../../store/transitionalCare.slice';
import { copyObject } from '../../utils/common';
import { KeyContact } from '../../Models/TransitionalCare/KeyContact.model';
import AlertDialog from '../AlertDialog/AlertDialog';
import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(000) 000-0000"
            inputRef={ref as any}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const keyContactsSchema = Yup.object().shape({
    Name: Yup.string().nullable().required('Name is required.'),
    Email: Yup.string().nullable().email('Invalid Email'),
});

const KeyContacts: React.FC<{ transitionStep: TransitionStep; isAssessment: boolean }> = ({ transitionStep, isAssessment }) => {
    const [showNote, setShowNote] = useState('');
    const [initialData, setInitialData] = useState(new KeyContact());
    const dispatch = useDispatch();
    const { isShowKeyContactsForm, memberTransitionalCare } = useSelector((state: RootState) => state.transitionalCare);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const handleOnSubmit = (values) => {
        if (isAssessment) {
            let data = copyObject(engagementDraft);
            data['AddKeyContact'] = true;
            dispatch(saveEngagementDraft(data));
        }
        dispatch(addKeyContact(memberTransitionalCare.Id, transitionStep?.Id, values));
    };

    useEffect(() => {
        if (!isShowKeyContactsForm) {
            setInitialData(new KeyContact());
        }
    }, [isShowKeyContactsForm]);

    const editContactHandler = (keyContact: KeyContact) => {
        setInitialData(keyContact);
        dispatch(toggleIsShowKeyContactsForm(true));
    };

    const renderContactRow = (keyContact: KeyContact) => {
        return (
            <Stack key={keyContact.Id} sx={{ width: '100%', p: 2 }} direction="row">
                <Grid item xs={3}>
                    <Typography variant="body1" fontWeight={500}>
                        {keyContact.Name}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body1" fontWeight={500}>
                        {keyContact.PhoneNumber}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body1" color={'text.secondary'}>
                        {keyContact.Email}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title={keyContact.Note} placement="left">
                            <StickyNote2Icon
                                color="primary"
                                style={{
                                    marginLeft: '24px',
                                    cursor: 'pointer',
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Edit" placement="right">
                            <EditIcon
                                color="primary"
                                style={{
                                    marginLeft: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    editContactHandler(keyContact);
                                }}
                            />
                        </Tooltip>
                    </Stack>
                </Grid>
            </Stack>
        );
    };

    const handleOpen = () => {
        dispatch(toggleIsShowKeyContactsForm(true));
    };
    const handleClose = () => dispatch(toggleIsShowKeyContactsForm(false));

    const renderDialogForm = (props) => {
        const { open, handleClose } = props;

        return (
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
                <DialogTitle>Add Contact</DialogTitle>
                <DialogContent>
                    <Formik initialValues={initialData} onSubmit={handleOnSubmit} validationSchema={keyContactsSchema} enableReinitialize={true}>
                        {({ values, setFieldValue, errors, handleChange, handleBlur, submitCount }: FormikProps<KeyContact>) => (
                            <Form id="contact-form">
                                <Grid container spacing={2} marginTop="0">
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            label="Contact Name"
                                            fullWidth
                                            value={values.Name}
                                            name="Name"
                                            onChange={handleChange}
                                            error={Boolean(submitCount) && Boolean(errors?.Name)}
                                            helperText={Boolean(submitCount) && Boolean(errors?.Name) ? errors.Name : 'Example: John H William'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Primary Phone"
                                            fullWidth
                                            value={values.PhoneNumber}
                                            name="PhoneNumber"
                                            onChange={handleChange}
                                            InputProps={{
                                                inputComponent: TextMaskCustom as any,
                                            }}
                                            helperText="Example: (123) 456-7890"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email"
                                            fullWidth
                                            value={values.Email}
                                            name="Email"
                                            onChange={handleChange}
                                            error={Boolean(submitCount) && Boolean(errors?.Email)}
                                            helperText={Boolean(submitCount) && Boolean(errors?.Email) ? errors.Email : 'Example: sample@email.com'}
                                        />
                                    </Grid>
                                </Grid>
                                <FormControl fullWidth={true} sx={{ marginTop: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Note"
                                        multiline
                                        variant="outlined"
                                        rows={4}
                                        value={values.Note}
                                        name="Note"
                                        onChange={handleChange}
                                        helperText="Add any relevant detail"
                                    />
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button form="contact-form" type="submit">
                        Save & Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            <Button variant="outlined" color="primary" fullWidth sx={{ p: 2, marginBottom: 2 }} onClick={() => handleOpen()}>
                Add Contact
            </Button>

            {transitionStep?.KeyContacts && (
                <Grid container spacing={2} width={'100%'} marginTop="0">
                    {transitionStep.KeyContacts.map((contact) => {
                        return renderContactRow(contact);
                    })}
                </Grid>
            )}

            {renderDialogForm({ open: isShowKeyContactsForm, handleClose: handleClose })}
            <AlertDialog
                open={Boolean(showNote)}
                hideCancelButton
                title="Key Contact Note"
                okText="Ok"
                onConfirm={() => setShowNote('')}
                onClose={() => setShowNote('')}
                message={showNote}
            />
        </>
    );
};

export default KeyContacts;
