import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IHgbA1CAssessment extends BaseEntity {
    MemberId: string;
    MemberName: string;
    Age: number;
    DOB: string;
    TestingMachine: string;
    LotNumTestCartridge: string;
    HgbA1CResult: number;
    HgbA1CRange: string;
    TestDate: string;
    TimeOfTest: string;
    CreatedDate: string;
    CreatedBy: string;
    NeedsReview: boolean;
    Status: StatusEnum;
}

export class HgbA1CAssessment implements IHgbA1CAssessment {
    Id: string;
    MemberId: string;
    MemberName: string;
    Age: number;
    DOB: string;
    TestingMachine: string;
    LotNumTestCartridge: string;
    HgbA1CResult: number;
    HgbA1CRange: string;
    TestDate: string;
    TimeOfTest: string;
    CreatedDate: string;
    CreatedBy: string;
    NeedsReview: boolean;
    Status: StatusEnum;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.MemberName = '';
        this.TestDate = new Date().toISOString();
    }
}
