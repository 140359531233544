import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, Checkbox, Paper, OutlinedInput, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import RedCircleImage from '../../../assets/images/assessments/redcircle.png';
import RedCircleMinusImage from '../../../assets/images/assessments/redcircleminus.png';
import RedCirclePlusImage from '../../../assets/images/assessments/redcircleplus.png';
import { getLastFootDiabetesAssessment, gotoNextMenu, gotoPreviousMenu, saveFootDiabetesAssessment } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import classes from './Styles';
import { DiabetesFootAssessment, SkinCondition } from '../../../Models/Assessment/DiabetesFootAssessment.model';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { copyObject } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';

const DiabetesFoot: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState(true);
    const { isSaving, diabetesFootAssessment, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const skinConditionContainerId = 'skin_conditions_sensation';

    useEffect(() => {
        dispatch(getLastFootDiabetesAssessment(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFetching && !isPageLoaded) {
            setIsPageLoaded(true);
        }
    }, [isFetching, isPageLoaded]);

    const saveFootDiabetes = (diabetesFootAssessment: DiabetesFootAssessment, saveOnlyLocal = false, canGotoNextMenu = false) => {
        setIsChanged(true);
        diabetesFootAssessment.MemberId = memberId;
        diabetesFootAssessment.Status = Boolean(diabetesFootAssessment.Status) ? diabetesFootAssessment.Status : StatusEnum.InProgress;
        dispatch(saveFootDiabetesAssessment(diabetesFootAssessment, saveOnlyLocal, canGotoNextMenu));
    };

    const handleOnChangeFoot = (questionIndex: number, isChecked: boolean, isLeft: boolean) => {
        let data = copyObject(diabetesFootAssessment);
        if (isLeft) {
            data.Questions[questionIndex].LeftFoot = isChecked;
        } else {
            data.Questions[questionIndex].RightFoot = isChecked;
        }

        saveFootDiabetes(data);
    };

    const prepareSensationImage = (sensation, isSkin = false) => {
        if (sensation.FeelFilament === false && !isSkin) {
            return RedCircleMinusImage;
        } else if (sensation.FeelFilament === true && !isSkin) {
            return RedCirclePlusImage;
        } else {
            return RedCircleImage;
        }
    };

    const handleOnClickFootSensationImage = (sensation) => {
        const sensactionIndex = diabetesFootAssessment.FootSensations.indexOf(sensation);
        let sensationCopy = copyObject(sensation);

        if (!sensationCopy.footSensationIndex) {
            sensationCopy.footSensationIndex = 1;
        }
        sensationCopy.footSensationIndex++;

        if (sensationCopy.footSensationIndex % 3 === 1) {
            sensationCopy.FeelFilament = null;
        } else if (sensationCopy.footSensationIndex % 3 === 2) {
            sensationCopy.FeelFilament = true;
        } else {
            sensationCopy.FeelFilament = false;
        }

        let data = copyObject(diabetesFootAssessment);
        data.FootSensations[sensactionIndex] = sensationCopy;
        saveFootDiabetes(data, true);
    };

    const handleOnClickSkinSensationImage = (skinCondition: SkinCondition) => {
        const sensactionIndex = diabetesFootAssessment.SkinConditions.indexOf(skinCondition);
        let skinSensationCopy = copyObject(skinCondition);
        let data = copyObject(diabetesFootAssessment);
        let isRemove = false;

        if (!skinSensationCopy.ItemClick) {
            skinSensationCopy.ItemClick = 1;
            skinSensationCopy.Mark = null;
        }

        skinSensationCopy.ItemClick++;
        if (skinSensationCopy.ItemClick % 6 === 2) {
            skinSensationCopy.Mark = 'R';
            skinSensationCopy.Note = skinSensationCopy.Mark;
        } else if (skinSensationCopy.ItemClick % 6 === 3) {
            skinSensationCopy.Mark = 'M';
            skinSensationCopy.Note = skinSensationCopy.Mark;
        } else if (skinSensationCopy.ItemClick % 6 === 4) {
            skinSensationCopy.Mark = 'D';
            skinSensationCopy.Note = skinSensationCopy.Mark;
        } else if (skinSensationCopy.ItemClick % 6 === 5) {
            skinSensationCopy.Mark = 'T';
            skinSensationCopy.Note = skinSensationCopy.Mark;
        } else {
            data.SkinConditions = data.SkinConditions.filter((s) => s.ImageId !== skinCondition.ImageId);

            let index = 1;
            data.SkinConditions.forEach((skinConditionItem) => {
                skinConditionItem.ImageId = String(index);
                index++;
            });
            isRemove = true;
        }

        if (!isRemove) {
            data.SkinConditions[sensactionIndex] = skinSensationCopy;
        }
        saveFootDiabetes(data, true);
    };

    const handleOnClickFootSensationNote = (val: string, index: number, isSkin = false) => {
        let data = copyObject(diabetesFootAssessment);
        if (isSkin) {
            data.SkinConditions[index].Note = val;
        } else {
            data.FootSensations[index].Note = val;
        }
        saveFootDiabetes(data, true);
    };

    const handleOnClickNewSkin = (event) => {
        if (event.target.id !== event.currentTarget.id) {
            return;
        }

        const newSkinCondition = {
            LocationX: event.nativeEvent.offsetX - 18,
            LocationY: event.nativeEvent.offsetY - 19,
            ImageId: String(diabetesFootAssessment.SkinConditions.length + 1),
            ItemClick: 1,
            Note: '',
        } as SkinCondition;

        let data = copyObject(diabetesFootAssessment);
        data.SkinConditions.push(newSkinCondition);
        saveFootDiabetes(data, true);
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            saveFootDiabetes({ ...diabetesFootAssessment, Status: StatusEnum.Completed }, false, true);
        } else {
            dispatch(gotoNextMenu());
        }
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching || !isPageLoaded ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item lg={12} md={12} sm={12}>
                        {/* <h6>Last Completed :-</h6> */}
                    </Grid>
                    <Grid item className="content-form">
                        <div>
                            <Typography sx={classes.headerTitle}>Foot Questions</Typography>
                            <Grid container spacing={4} sx={classes.questionRow}>
                                <Grid item xs={4} md={3.5} textAlign="right">
                                    <h6>Question</h6>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <h6>Left Foot</h6>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <h6>Right Foot</h6>
                                </Grid>
                            </Grid>
                            {diabetesFootAssessment &&
                                diabetesFootAssessment.Questions.map((question, index) => (
                                    <Grid container spacing={4} key={`foot_question_${question.Id}_${index}`} sx={classes.answerRow}>
                                        <Grid item xs={4} md={3.5} textAlign="right">
                                            <label>{question.Description}</label>
                                        </Grid>
                                        <Grid item xs={2} textAlign="center">
                                            <Checkbox
                                                checked={question.LeftFoot}
                                                size="small"
                                                radioGroup={question.Description}
                                                onChange={(e) => handleOnChangeFoot(index, e.currentTarget.checked, true)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} textAlign="center">
                                            <Checkbox
                                                checked={question.RightFoot}
                                                size="small"
                                                radioGroup={question.Description}
                                                onChange={(e) => handleOnChangeFoot(index, e.currentTarget.checked, false)}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            <Grid container spacing={2} className="mt-15">
                                <Grid item xs={7.3}>
                                    <Typography sx={classes.headerTitle}>Foot Sensation</Typography>
                                </Grid>
                                <Grid item xs={4.7}>
                                    <Typography sx={classes.headerTitle}>Foot Sensation Notes</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <Typography sx={classes.description}>Note the level of sensation in the circles</Typography>
                                    <Typography sx={classes.description}>(Click 1 time on an area) + = Can feel the 5.07 filament</Typography>
                                    <Typography sx={classes.description}>(Click 2 times on an area) - = Can not feel the 5.07 filament</Typography>
                                    <Typography sx={classes.description}>(Click 3 times on an area) resets the area</Typography>
                                    <Paper elevation={0} sx={classes.paperContainer}>
                                        {diabetesFootAssessment.FootSensations.map((sensation, index) => (
                                            <Box
                                                key={`foot_sensation_${index}`}
                                                sx={classes.sensationItem}
                                                style={{ left: `${sensation.LocationX}px`, top: `${sensation.LocationY}px` }}
                                            >
                                                <span>{sensation.ImageId}</span>
                                                <img
                                                    src={prepareSensationImage(sensation)}
                                                    style={classes.sensationImage}
                                                    onClick={() => handleOnClickFootSensationImage(sensation)}
                                                    alt="foot-img"
                                                />
                                            </Box>
                                        ))}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    {diabetesFootAssessment.FootSensations.map((sensation, index) => (
                                        <Box key={`foot_sensation_notes_${index}`} sx={classes.sensationNoteContent}>
                                            <span>{sensation.ImageId}</span>
                                            <OutlinedInput
                                                className="x-small-text-input hide-textinput-label"
                                                classes={{ root: '' }}
                                                value={sensation.Note}
                                                fullWidth={true}
                                                size="small"
                                                onBlur={(e) => handleOnClickFootSensationNote(e.currentTarget.value, index)}
                                            />
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="mt-15">
                                <Grid item xs={7.3}>
                                    <Typography sx={classes.headerTitle}>Skin Conditions on the Foot or between Toes</Typography>
                                </Grid>
                                <Grid item xs={4.7}>
                                    <Typography sx={classes.headerTitle}>Skin Condition Notes</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <Typography sx={classes.description}>Mark on the foot: Callous, Pre-ulcer, Ulcer (note length and width in cm)</Typography>
                                    <Typography sx={classes.description}>(Click 1 time on an area) marks an area on the foot to add a note</Typography>
                                    <Typography sx={classes.description}>(Click 2 times on an area) marks an area R - redness</Typography>
                                    <Typography sx={classes.description}>(Click 3 times on an area) marks an area M - maceration</Typography>
                                    <Typography sx={classes.description}>(Click 4 times on an area) marks an area D - dryness</Typography>
                                    <Typography sx={classes.description}>(Click 5 times on an area) marks an area T - Tinea</Typography>
                                    <Typography sx={classes.description}>(Click 6 times on an area) removes the area on the foot</Typography>
                                    <Paper elevation={0} sx={classes.paperContainer} onClick={handleOnClickNewSkin} id={skinConditionContainerId}>
                                        {diabetesFootAssessment.SkinConditions.map((sensation, index) => (
                                            <Box
                                                key={`skin_sensation_${index}`}
                                                sx={classes.sensationItem}
                                                style={{ left: `${sensation.LocationX}px`, top: `${sensation.LocationY}px` }}
                                            >
                                                <span>{sensation.ImageId}</span>
                                                <img
                                                    src={prepareSensationImage(sensation, true)}
                                                    style={classes.sensationImage}
                                                    onClick={() => handleOnClickSkinSensationImage(sensation)}
                                                    alt="foot-sensation-img"
                                                />
                                                <span className="pointer-events-none">{sensation.Mark}</span>
                                            </Box>
                                        ))}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    {diabetesFootAssessment.SkinConditions.map((sensation, index) => (
                                        <Box sx={classes.sensationNoteContent} key={`skin_sensation_notes_${index}`}>
                                            <span>{sensation.ImageId}</span>
                                            <OutlinedInput
                                                className="x-small-text-input hide-textinput-label"
                                                classes={{ root: '' }}
                                                defaultValue={sensation.Note}
                                                fullWidth={true}
                                                size="small"
                                                onBlur={(e) => handleOnClickFootSensationNote(e.currentTarget.value, index, true)}
                                            />
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 2 }}
                            disabled={isSaving}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleOnClickSave}
                            disabled={isSaving}
                            endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                        >
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.DiabetesFootAssessmentId) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Diabetes Foot Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    showBottomCenter={true}
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Diabetes Foot assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    showBottomCenter={true}
                    title="Diabetes Foot Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Diabetes Foot assessment?"
                />
            )}
        </Grid>
    );
};

export default DiabetesFoot;
