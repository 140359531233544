import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import combineProvider from './hooks/combineProvider';
import { ChpProvider } from './hooks/chpContext';
import { HealthPlanProvider } from './hooks/healthPlanContext';
import { DataFormProvider } from './hooks/dataFormContext';
import { PopulationProvider } from './hooks/populationContext';
import { ProviderProvider } from './hooks/providerContext';
import { HedisMetricsProvider } from './hooks/hedisMetricsContext';
import { WidgetProvider } from './hooks/widgetContext';
import { AnalyticProvider } from './hooks/analyticContext';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import configureStore from './store/configureStore';

const AllProvider = combineProvider([ChpProvider, HealthPlanProvider, DataFormProvider, PopulationProvider, ProviderProvider, HedisMetricsProvider, WidgetProvider, AnalyticProvider]);

const store = configureStore(rootReducer);

ReactDOM.render(
    <Provider store={store}>
        <AllProvider>
            <App />
        </AllProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
