import { Box, Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import Loading from '../../Shared/Loading';
import { localDateFormat } from '../../../utils/timeFormat';
import { isDefaultDate } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
    exportOnboardingMemberReport,
    fetchOnboardingMemberReport,
    setOnboardingStartDate,
    setOnboardingEndDate,
} from '../../../store/onboardingMemberReport.slice';
import { RootState } from '../../../reducers';
import { fetchMemberStatuses } from '../../../store/members.slice';

const OnboardingMemberReport = () => {
    const { onboardingReportStartDate, onboardingReportEndDate, onboardingRowData } = useSelector((state: RootState) => state.onboardingMemberReport);
    const { downLoading } = useSelector((state: RootState) => state.shared);
    const { memberStatuses } = useSelector((state: RootState) => state.members);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchMemberStatuses());
    }, [dispatch]);
    console.log('memberStatuses: ', memberStatuses);

    const columns: GridColDef[] = [
        {
            field: 'Mem_AHCCCS_ID',
            headerName: 'AHCCCS Id',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 240,
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 180,
            valueGetter: (params: GridValueGetterParams) => {
                let found = memberStatuses.find((x) => parseInt(x.value) === params.row.Status);
                return found ? found.label : params.row.Status;
            },
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            width: 150,
        },
        {
            field: 'PopulationName',
            headerName: 'Population Name',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${params.row.PopulationName}`,
        },
        {
            field: 'DateOnboarded',
            headerName: 'Date of OnBoard',
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DateOnboarded) ? '-' : localDateFormat(new Date(params.row.DateOnboarded))}`,
        },
        {
            field: 'TabletId',
            headerName: 'Tablet ID#',
            width: 150,
        },
        {
            field: 'TelemonitoringDevices',
            headerName: 'Telemonitoring Devices',
            width: 150,
        },
        {
            field: 'Address1',
            headerName: 'Address',
            minWidth: 250,
        },
        {
            field: 'Address2',
            headerName: 'Address 2',
            width: 200,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 150,
        },
        {
            field: 'State',
            headerName: 'State',
            width: 150,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            width: 150,
        },
        {
            field: 'NPTeam',
            headerName: 'NPTeam',
            width: 180,
        },
        {
            field: 'CCTeam',
            headerName: 'CCTeam',
            width: 180,
        },
        {
            field: 'BHTeam',
            headerName: 'BHTeam',
            width: 180,
        },
        {
            field: 'CLTeam',
            headerName: 'CLTeam',
            width: 180,
        },
        {
            field: 'CHWTeam',
            headerName: 'CHWTeam',
            width: 180,
        },
        {
            field: 'WhoOnboared',
            headerName: 'Who Onboarded',
            width: 180,
        },
    ];

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 72 : 74) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
                <Box width={'100%'} borderBottom={1} paddingBottom={1}>
                    <Typography fontWeight="fontWeightMedium" component="h3">
                        Onboarding Member Report
                    </Typography>
                </Box>
                {<Loading message={'Loading...'} />}
                <Grid item xs={12}>
                    <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="Start Date:"
                                value={onboardingReportStartDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setOnboardingStartDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="End Date:"
                                value={onboardingReportEndDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setOnboardingEndDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <Button
                            onClick={() => dispatch(fetchOnboardingMemberReport(onboardingReportStartDate, onboardingReportEndDate))}
                            style={{ minWidth: '120px' }}
                            variant="contained"
                        >
                            Search
                        </Button>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => dispatch(exportOnboardingMemberReport(onboardingReportStartDate, onboardingReportEndDate))}
                            variant="contained"
                            color="success"
                        >
                            {downLoading ? 'Downloading..' : 'Export'}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item style={{ height: '84vh', width: '100%' }}>
                    <DataGrid
                        rows={onboardingRowData}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        getRowId={(row) => row.Id}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 2,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default OnboardingMemberReport;
