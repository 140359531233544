import BaseEntity from '../BaseEntity.model';
import CallItem from './CallItem.model';
import OnboarderScheduleCount from './OnboarderScheduleCount.model';
import OnboarderCallCount from './OnboarderCallCount.model';
import CallerPopRule from './CallerPopRule.model';
import OpenPopulationDto from '../AdminOnboarding/OpenPopulationDto.model';

export default interface IOnboarderStatus extends BaseEntity {
    UserId: string;
    Username: string;
    CallList: CallItem[];
    NextCallList: CallItem[];
    LastListAssignmentTime: Date;
    LastStatusUpdateTime: Date;
    FiveDayMembersScheduled: OnboarderScheduleCount[];
    FiveDayCallCounts: OnboarderCallCount[];
    ChpLastStatusUpdateString: string;
    Rules: CallerPopRule[];
    CallListLength?: number | null;
    CallsGoal?: number | null;
    CallListLimits?: OpenPopulationDto[];
}

export class OnboarderStatus implements IOnboarderStatus {
    Id: string;
    UserId: string;
    Username: string;
    CallList: CallItem[];
    NextCallList: CallItem[];
    LastListAssignmentTime: Date;
    LastStatusUpdateTime: Date;
    FiveDayMembersScheduled: OnboarderScheduleCount[];
    FiveDayCallCounts: OnboarderCallCount[];
    ChpLastStatusUpdateString: string;
    Rules: CallerPopRule[];
    CallListLength?: number | null;
    CallsGoal?: number | null;
    CallListLimits?: OpenPopulationDto[];

    constructor() {
        this.Id = '';
        this.UserId = '';
        this.Username = '';
        this.CallList = [];
        this.NextCallList = [];
        this.LastListAssignmentTime = new Date();
        this.LastStatusUpdateTime = new Date();
        this.FiveDayMembersScheduled = [];
        this.FiveDayCallCounts = [];
        this.ChpLastStatusUpdateString = '';
        this.Rules = [];
    }
}
