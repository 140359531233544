import { Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import IOnboarderStatus from '../../../../Models/OfficeOnboarding/OnboarderStatus.model';
import CallingPerformance from '../../../OfficeOnboardingWidgets/CallingPerformance/CallingPerformance';
import CurrentCallList from '../../../OfficeOnboardingWidgets/CurrentCallList/CurrentCallList';
import CallerListDistribution from '../CallerListDistribution/CallerListDistribution';
import CallerHistory from '../CallerHistory/CallerHistory';
import MessageDisplay from '../../../Shared/MessageDisplay';

interface OnboarderPanelProps {
    status: IOnboarderStatus;
    currentListLength: number;
}

const OnboarderPanel = (props: OnboarderPanelProps) => {
    const [callTabs, setCallTabs] = useState('calling-performance');
    const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
        setCallTabs(newValue);
    };
    return props.status?.Id ? (
        <>
            <MessageDisplay />
            <Grid container direction="row" alignItems="flex-start" justifyContent="space-around" spacing={1}>
                <Grid item lg={12} md={12}>
                    <Tabs variant="fullWidth" value={callTabs} onChange={handleTabsChange} sx={{ mb: 4 }}>
                        <Tab value={'calling-performance'} label="Calling Performance" />
                        <Tab value={'call-history'} label="Call History" />
                    </Tabs>
                    {callTabs === 'calling-performance' ? (
                        <CallingPerformance
                            onboarderId={props.status.Id}
                            onboarderName={props.status.Username}
                            onboarderCallGoal={props.status.CallsGoal}
                            isAdmin
                        />
                    ) : callTabs === 'call-history' ? (
                        <CallerHistory selectedOnboarder={props.status} />
                    ) : null}
                </Grid>
                <Grid item lg={12} md={12}>
                    <CallerListDistribution status={props.status} currentListLength={props.currentListLength} />
                </Grid>
                <Grid item lg={12} md={12}>
                    <CurrentCallList callList={props.status.CallList} />
                </Grid>
            </Grid>
        </>
    ) : (
        <Grid direction="row" alignItems="center" justifyContent="space-evenly" container spacing={1}>
            <Grid item xs={12} marginTop={2}>
                <Typography variant="h4">No Onboarder Selected</Typography>
            </Grid>
        </Grid>
    );
};

export default OnboarderPanel;
