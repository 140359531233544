export enum LevelOfTeachbackEnum {
    GoodDescription = 'Good - Skill retention, able to do on own',
    Good = 1,
    FairDescription = 'Fair - Return demonstration indicates a need for further teaching or additional assistance with follow through on next steps',
    Fair = 2,
    PoorDescription = 'Poor - Unable to repeat skill or return demonstration',
    Poor = 3,
    DeclinedDescription = 'Declined - Refused to complete member education',
    Declined = 4,
}
