import OnboardingReport from './OnboardingReport';
import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import UnreachedOnboardingMembersReport from './UnreachedOnboardingMembersReport';
import { PopulationGoals } from './PopulationGoals';

const GoalsAndReports = () => {
    const [tabValue, setTabValue] = useState<number>(1);

    const tabChangeHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} alignItems="flex-start" justifyContent="space-evenly" marginY={5}>
                <PopulationGoals />
            </Grid>
            <Grid item xs={12} alignItems="flex-start" justifyContent="space-evenly" sx={{ mt: 5 }}>
                <Tabs variant="fullWidth" value={tabValue} onChange={tabChangeHandler}>
                    <Tab value={1} label="Population Onboarding Report" />
                    <Tab value={2} label="Onboarding Calls Report" />
                </Tabs>
                {tabValue === 1 && <OnboardingReport />}
                {tabValue === 2 && <UnreachedOnboardingMembersReport />}
            </Grid>
        </Grid>
    );
};

export default GoalsAndReports;
