import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { useWidgetContext } from '../../../hooks/widgetContext';
import { fetchTeamLevelTransitionalCareSummary, exportTeamLevelTransitionalCareSummary, MenuProps } from '../../Widgets/WidgetService';
import TeamLevelTcSummary from '../../../Models/Widget/TeamLevelTcSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { parentUrl } from '../../../utils/constants';
import { FacilityTypeEnum } from '../../../Enum/FacilityTypeEnum';
import { generateRandomId } from '../../../utils/common';
import { dateFormatter } from '../../../utils/timeFormat';
import '../Widgets.css';

const TeamLevelTcSummaryDetail = () => {
    const history = useNavigate();
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const {
        tcTeam,
        setTcTeam,
        tcFacility,
        setTcFacility,
        tcDateFilter,
        setTcDateFilter,
        tcFromDate,
        setTcFromDate,
        tcToDate,
        setTcToDate,
        tcShowInActiveTeams,
        setTcShowInActiveTeams,
    } = useWidgetContext();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [members, setMembers] = useState<TeamLevelTcSummary>();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const filteredTeams = (tcShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const dateFilterList = [
        { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
        { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
        { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
        { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
    ];

    const faclityList = [
        { Id: FacilityTypeEnum.PhysicalHealth, Value: FacilityTypeEnum.PhysicalHealthDescription },
        { Id: FacilityTypeEnum.BehavioralHealthLevel1, Value: FacilityTypeEnum.BehavioralHealthLevel1Description },
        { Id: FacilityTypeEnum.BehavioralHealthLevel2, Value: FacilityTypeEnum.BehavioralHealthLevel2Description },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
    }, [isFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (tcDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(tcFromDate) && Boolean(tcToDate))) {
            setIsMembersLoading(false);
            const changeFilter = async () => {
                let start = tcFromDate !== null ? new Date(tcFromDate).toDateString() : null;
                let end = tcToDate !== null ? new Date(tcToDate).toDateString() : null;

                const data = await fetchTeamLevelTransitionalCareSummary(tcTeam, tcFacility, tcDateFilter, start, end, tcShowInActiveTeams, true);
                setMembers(data);
                setIsMembersLoading(true);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (tcDateFilter !== TimePeriodFilterEnum.Custom && Boolean(tcFromDate) && Boolean(tcToDate)) {
            setTcFromDate(null);
            setTcToDate(null);
        }
    }, [tcTeam, tcFacility, tcDateFilter, tcFromDate, tcToDate, tcShowInActiveTeams, setTcFromDate, setTcToDate]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const changeFromDate = (date: any) => {
        setTcFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(tcToDate) || new Date(tcToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setTcToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setTcToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(tcFromDate) || new Date(tcFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setTcFromDate(startDate);
        }
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let start = tcFromDate !== null ? new Date(tcFromDate).toDateString() : null;
        let end = tcToDate !== null ? new Date(tcToDate).toDateString() : null;
        const data = await exportTeamLevelTransitionalCareSummary(tcTeam, tcFacility, tcDateFilter, start, end, tcShowInActiveTeams);
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `TeamLevelTcSummary - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setTcShowInActiveTeams(isChecked);
        setTcTeam('All');
    };

    const columns: GridColDef[] = [
        {
            field: 'Index',
            headerName: '#',
            width: 60,
            sortable: false,
            renderCell: (params) => {
                return `${params.api.getRowIndex(params.id) + 1}`;
            },
        },
        {
            field: 'Name',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DateInfo',
            headerName: 'Discharged Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.DateInfo) ? dateFormatter(new Date(params.row.DateInfo)) : '-'}`,
        },
        {
            field: 'VisitedDate',
            headerName: 'Visited Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.VisitedDate) ? dateFormatter(new Date(params.row.VisitedDate)) : '-'}`,
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Team Level Transitional Care Visits
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Facility</InputLabel>
                            <Select value={tcFacility} label="Facility" name="Facility" onChange={(e) => setTcFacility(e.target.value)} MenuProps={MenuProps}>
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {faclityList.map((facility) => (
                                    <MenuItem key={facility.Id} value={facility.Id}>
                                        {facility.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select value={tcTeam} label="Team" name="Team" onChange={(e) => setTcTeam(e.target.value)} MenuProps={MenuProps}>
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={tcShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select value={tcDateFilter} label="Date Filter" name="Date" onChange={(e) => setTcDateFilter(parseInt(e.target.value as any))}>
                                {dateFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {tcDateFilter === TimePeriodFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(tcFromDate) ? tcFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(tcToDate) ? tcToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={tcDateFilter === TimePeriodFilterEnum.Custom ? 3.5 : 6.5}></Grid>
                    <Grid item xs={1}>
                        <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isMembersLoading ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={6} textAlign="center" paddingTop={2}>
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Visits WithIn 7 Days Of Discharge ({members.VisitsWithIn7DaysOfDischargeMembers.length})
                                        </Typography>
                                        <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                            <DataGrid
                                                columns={columns}
                                                rows={members.VisitsWithIn7DaysOfDischargeMembers}
                                                getRowId={(row) => `${row.ItemId}-${row.DateInfo}-${row.VisitedDate}-${generateRandomId()}`}
                                                paginationMode="server"
                                                hideFooterPagination={true}
                                                hideFooter={true}
                                                onCellClick={(params: any) => {
                                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} textAlign="center" paddingTop={2}>
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Visits WithIn 30 Days Of Discharge ({members.VisitsWithIn30DaysOfDischargeMembers.length})
                                        </Typography>
                                        <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                            <DataGrid
                                                columns={columns}
                                                rows={members.VisitsWithIn30DaysOfDischargeMembers}
                                                getRowId={(row) => `${row.ItemId}-${row.DateInfo}-${row.VisitedDate}-${generateRandomId()}`}
                                                paginationMode="server"
                                                hideFooterPagination={true}
                                                hideFooter={true}
                                                onCellClick={(params: any) => {
                                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} textAlign="center" paddingTop={2}>
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            No Visit Members ({members.NoVisitMembers.length})
                                        </Typography>
                                        <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                            <DataGrid
                                                columns={columns}
                                                rows={members.NoVisitMembers}
                                                getRowId={(row) => `${row.ItemId}-${row.DateInfo}-${row.VisitedDate}-${generateRandomId()}`}
                                                paginationMode="server"
                                                hideFooterPagination={true}
                                                hideFooter={true}
                                                onCellClick={(params: any) => {
                                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} textAlign="center" paddingTop={2}>
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Total Discharged Members ({members.TotalDischargedMembers.length})
                                        </Typography>
                                        <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                            <DataGrid
                                                columns={columns}
                                                rows={members.TotalDischargedMembers}
                                                getRowId={(row) => `${row.ItemId}-${generateRandomId()}`}
                                                paginationMode="server"
                                                hideFooterPagination={true}
                                                hideFooter={true}
                                                onCellClick={(params: any) => {
                                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default TeamLevelTcSummaryDetail;
