import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmDelete from '../../../components/Shared/ConfirmDelete';
import BasicContactInfoDialog from '../BasicContactInfoDialog';
import Loading from '../../Shared/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    closeDeleteContactHandler,
    deleteContactHandler,
    openDeleteContactHandler,
    saveContactInfoHandler,
    setContactInfoTitle,
    setCurrentContact,
    setCurrentContactList,
    setOpenContactInfo,
} from '../../../store/adminHealthPlan.slice';

const Contact = (props) => {
    const { contactInfoTitle, contactToDelete, currentContact, currentContactList, healthPlanForm, openContactInfo, openDeleteContact } = useSelector(
        (state: RootState) => state.adminHealthPlanSlice
    );

    const dispatch = useDispatch();

    const getDefaultContact = () => {
        return {
            Address: '',
            Email: '',
            Id: '',
            Name: '',
            PhoneNumber: '',
        };
    };

    const inputOnChange = (e) => {
        dispatch(setCurrentContact({ ...currentContact, [e.target.name]: e.target.value }));
    };

    const addContactHandler = (contactInfoTitle, currentContactList) => {
        dispatch(setContactInfoTitle(contactInfoTitle));
        dispatch(setCurrentContact(getDefaultContact()));
        dispatch(setCurrentContactList(currentContactList));
        dispatch(setOpenContactInfo(true));
    };

    const editContactInfoHandler = (params) => {
        dispatch(setCurrentContact(params.row));
        dispatch(setContactInfoTitle(params.row.Name));
        dispatch(setOpenContactInfo(true));
    };

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 1,
        },
        {
            field: 'PhoneNumber',
            headerName: 'Phone Number',
            flex: 1,
            // valueGetter: (params: GridValueGetterParams) => `${phoneFormat(params.row.PhoneNumber)}`,
        },
        {
            field: 'Email',
            headerName: 'Email',
            flex: 1.5,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit Contact" placement="bottom">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => editContactInfoHandler(params)} />
                        </Tooltip>
                        <Tooltip title="Delete Contact" placement="top">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(openDeleteContactHandler(params.row))}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {<Loading message={'Loading...'} />}

            <ConfirmDelete
                open={openDeleteContact}
                onClose={() => dispatch(closeDeleteContactHandler())}
                name={contactToDelete.Name}
                delete={() => dispatch(deleteContactHandler(healthPlanForm, contactToDelete))}
                cancel={() => dispatch(closeDeleteContactHandler())}
            />

            <BasicContactInfoDialog
                open={openContactInfo}
                onClose={() => dispatch(setOpenContactInfo(false))}
                healthPlanForm={healthPlanForm}
                currentContact={currentContact}
                contactInfoTitle={contactInfoTitle}
                inputOnChange={inputOnChange}
                onSave={() => dispatch(saveContactInfoHandler(healthPlanForm, currentContact, currentContactList))}
            />

            <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                <Typography display={'inline-flex'} variant="h5">
                    AP Contacts
                </Typography>
                <Button variant="contained" onClick={() => addContactHandler('AP Contact', 'APContacts')}>
                    Add AP Contact
                </Button>
            </Grid>

            <Grid item xs={12}>
                {healthPlanForm.APContacts?.length > 0 && (
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={healthPlanForm.APContacts || []}
                            columns={columns}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            autoHeight
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                )}
            </Grid>

            <Grid item xs={12} marginTop={5} display="flex" alignItems="center" justifyContent="space-between">
                <Typography display={'inline-flex'} variant="h5">
                    Contracting Contacts
                </Typography>
                <Button variant="contained" onClick={() => addContactHandler('Contracting Contact', 'ContractingContacts')}>
                    Add Contracting Contact
                </Button>
            </Grid>
            <Grid item xs={12}>
                {healthPlanForm.ContractingContacts?.length > 0 && (
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={healthPlanForm.ContractingContacts || []}
                            columns={columns}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            autoHeight
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                )}
            </Grid>
        </>
    );
};

export default Contact;
