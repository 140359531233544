import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { gotoPreviousMenu } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import { fetchMemberDemoGraphicInfo } from '../../../store/memberDetail.slice';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { copyObject, isValidFirstName, isValidName } from '../../../utils/common';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import MemberDetailEditPage from '../../Members/MemberDetail/MemberDetailEditPage';
import Authorization from '../../../utils/Authorization';

const MemberAssessmentDemographics: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const auth = new Authorization();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isFetching, setisFetching] = useState<boolean>(true);
    const [saveClickEvent, setSaveClickEvent] = useState(null);
    const { isSaving, selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const [memberCopy] = useState(selectedMember);

    useEffect(() => {
        dispatch(fetchMemberDemoGraphicInfo(memberId, () => setisFetching(false)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const valid = () => {
        if (
            !selectedMember.FirstName ||
            !selectedMember.LastName ||
            !selectedMember.DOB ||
            (auth.Role !== 'CEO' && memberCopy.FirstName !== selectedMember.FirstName && !isValidFirstName(selectedMember.FirstName)) ||
            (auth.Role === 'CEO' && !isValidName(selectedMember.FirstName)) ||
            !isValidName(selectedMember.LastName) ||
            !isValidName(selectedMember.MiddleName)
        ) {
            return false;
        } else {
            return true;
        }
    };

    const handleOnSubmit = () => {
        if (valid()) {
            setShowSaveAlert(true);
        } else {
            if (saveClickEvent) {
                saveClickEvent();
            }
        }
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (saveClickEvent) {
            saveClickEvent();
        }

        if (isWithoutComplete) {
            let data = copyObject(engagementDraft);
            data.SummaryNotes = data.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.DemographicInfo) || [];
            data.SummaryNotes.push({
                Id: memberId,
                CptCode: '',
                Zcode: '',
                Note: 'Completed Demographic Information',
                Type: SummaryNoteTypeEnum.DemographicInfo,
                IsConfirm: false,
                OrderBy: 28,
                DisplayName: 'Demographic Info',
            });

            dispatch(saveEngagementDraft({ ...data, DemographicStatus: StatusEnum.Completed }));
        }
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item className="content-form">
                        <MemberDetailEditPage memberId={memberId} isAssessment={true} setSaveClickEvent={setSaveClickEvent} />
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            className="mr-3"
                            disabled={isSaving}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleOnSubmit}
                            disabled={isSaving}
                            endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                        >
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            <AlertDialog
                open={showSaveAlert}
                title="Demographics"
                cancelText="Go Back"
                okText="Confirm"
                onConfirm={() => handleOnConfirmToNext(true)}
                onSkip={() => handleOnConfirmToNext()}
                onClose={() => setShowSaveAlert(false)}
                isLoading={isSaving}
                message="I confirm and acknowledge that Demographics is completed."
            />
        </Grid>
    );
};

export default MemberAssessmentDemographics;
