import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Box,
    Modal,
    Typography,
    TextField,
    FormControl,
    CircularProgress,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Collapse,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { RichTextEditor } from '@mantine/rte';

import { RootState } from '../../reducers';
import classes from './Styles';
import moment from 'moment';
import { MeansOfEngagementEnum } from '../../Enum/MeansOfEngagementEnum';
import { phoneOptions } from '../../utils/assessments';
import { saveDocumentEngagement, toggleShowDocEngagementModal } from '../../store/documentEngagement.slice';
import { DocumentEngagement } from '../../Models/DocumentEngagements/DocumentEngagement.model';
import Authorization from '../../utils/Authorization';
import { copyObject } from '../../utils/common';

const docEngagementFormSchema = Yup.object().shape({
    MeansOfEngagement: Yup.string().nullable().required('Select means of engagement.'),
    ReasonForVisit: Yup.object()
        .nullable()
        .shape({
            Id: Yup.string().nullable().required('Select reason.'),
            Name: Yup.string(),
        })
        .required('Select reason.'),
    VisitedTime: Yup.string()
        .nullable()
        .required('Select visited Date and time.')
        .test('VisitedTime', 'Select visited time.', (value) => {
            if (Boolean(value)) {
                const visitedTime = moment(value).format('HH:mm');
                return !(visitedTime === '00:00');
            }
            return true;
        }),
    Contacted: Yup.boolean().nullable().required('Select contacted.'),
});

const AddOrUpdateEngagementDetail: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const { meansOfEngagements, reasonForVisits, documentEngagement, showDocEngagementModal, isSaving, isFetching } = useSelector(
        (state: RootState) => state.documentEngagement
    );
    const [reasonForVisitList, setReasonForVisitList] = useState([]);

    useEffect(() => {
        if (showDocEngagementModal) {
            let data = copyObject(reasonForVisits);
            if (!Boolean(documentEngagement.Id) || (Boolean(documentEngagement.Id) && documentEngagement.ReasonForVisit?.Id !== '56695e83ad78ee0e48b04a63')) {
                data = data.filter((r) => r.Id !== '56695e83ad78ee0e48b04a63');
            }
            setReasonForVisitList(data);
        }
    }, [showDocEngagementModal, documentEngagement, reasonForVisits]);

    const handleClose = () => dispatch(toggleShowDocEngagementModal(false));

    const handleSubmit = (values: DocumentEngagement) => {
        if (!Boolean(values.EngagedProvider?.Id)) {
            const authData = new Authorization();
            values.EngagedProvider = {
                Id: authData.UserId,
                Name: authData.UserName,
                Role: authData.Role,
            };
        }
        dispatch(saveDocumentEngagement({ ...values, MemberId: memberId }));
    };

    const isERVisitType = (reasonForVisitId) => {
        if (Boolean(reasonForVisitId) && (String(reasonForVisitId) === '56695e83ad78ee0e48b04a66' || String(reasonForVisitId) === '56695e83ad78ee0e48b04a64')) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Modal open={showDocEngagementModal} onClose={handleClose}>
            <Box sx={[{ ...classes.modalPopupForm }, { ...classes.form }]}>
                <div className="mui-modal-header">
                    <Typography variant="h6" component="h6">
                        Document Engagement
                    </Typography>
                </div>
                <Formik initialValues={documentEngagement} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={docEngagementFormSchema}>
                    {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<DocumentEngagement>) => (
                        <Form>
                            <div className="mui-modal-body">
                                <Grid container direction="column" spacing={2} className="content-panel">
                                    <Grid item className="content-form">
                                        {isFetching ? (
                                            <Box display="flex" justifyContent="center" className="loader-container">
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        fullWidth
                                                        error={
                                                            (Boolean(errors?.MeansOfEngagement) || String(values.MeansOfEngagement) === '0') &&
                                                            Boolean(submitCount)
                                                        }
                                                    >
                                                        <InputLabel id="means-of-enga-type-select" required>
                                                            Means of Engagement
                                                        </InputLabel>
                                                        <Select
                                                            labelId="means-of-enga-type-select"
                                                            id="means-of-enga-type-select"
                                                            value={Boolean(values.MeansOfEngagement) ? values.MeansOfEngagement : null}
                                                            label="Means of Engagement*"
                                                            name="MeansOfEngagement"
                                                            onChange={handleChange}
                                                        >
                                                            {meansOfEngagements.map((type) => (
                                                                <MenuItem key={type.Id} value={String(type.Id)}>
                                                                    {type.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {Boolean(submitCount) && (
                                                            <FormHelperText>
                                                                {String(values.MeansOfEngagement) === '0'
                                                                    ? 'Select means of engagement.'
                                                                    : errors?.MeansOfEngagement}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        fullWidth
                                                        error={(Boolean(errors?.ReasonForVisit) || Boolean(errors?.ReasonForVisit?.Id)) && Boolean(submitCount)}
                                                    >
                                                        <InputLabel id="reason-type-select" required>
                                                            Reason for Engagement
                                                        </InputLabel>
                                                        <Select
                                                            labelId="reason-type-select"
                                                            id="reason-type-select"
                                                            value={Boolean(values.ReasonForVisit?.Id) ? values.ReasonForVisit.Id : null}
                                                            label="Reason for Engagement*"
                                                            name="ReasonForVisit.Id"
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    'ReasonForVisit',
                                                                    reasonForVisitList.find((r) => r.Id === e.target.value)
                                                                )
                                                            }
                                                        >
                                                            {reasonForVisitList.map((type) => (
                                                                <MenuItem key={type.Id} value={String(type.Id)}>
                                                                    {type.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {Boolean(submitCount) && (
                                                            <FormHelperText>
                                                                {(Boolean(errors?.ReasonForVisit) || Boolean(errors?.ReasonForVisit?.Id)) &&
                                                                Boolean(submitCount)
                                                                    ? 'Select reason.'
                                                                    : ''}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DateTimePicker
                                                        label="Engagement Date"
                                                        value={values.VisitedTime ? new Date(values.VisitedTime) : null}
                                                        minDate={new Date('2017-01-01')}
                                                        onChange={(date) => {
                                                            if (moment(date).isValid()) {
                                                                setFieldValue('VisitedTime', date.toISOString());
                                                            } else {
                                                                setFieldValue('VisitedTime', '');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                {...params}
                                                                error={Boolean(errors?.VisitedTime) && Boolean(submitCount)}
                                                                helperText={Boolean(submitCount) ? errors?.VisitedTime : ''}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth error={Boolean(errors?.Contacted) && Boolean(submitCount)}>
                                                        <InputLabel id="contact-type-select" required>
                                                            Contacted
                                                        </InputLabel>
                                                        <Select
                                                            labelId="contact-type-select"
                                                            id="contact-type-select"
                                                            value={values.Contacted || values.Contacted === false ? String(values.Contacted) : null}
                                                            label="Contacted*"
                                                            name="Contacted"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setFieldValue('ContactResult', phoneOptions[3].Name);
                                                                if (e.target.value === String(true)) {
                                                                    setFieldValue('ContactResult', phoneOptions[3].Name);
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem value={String(true)}>Yes</MenuItem>
                                                            <MenuItem value={String(false)}>No</MenuItem>
                                                        </Select>
                                                        {Boolean(submitCount) && <FormHelperText>{errors?.Contacted}</FormHelperText>}
                                                    </FormControl>
                                                    {String(values.MeansOfEngagement) === String(MeansOfEngagementEnum.Phone) ? (
                                                        <FormControl fullWidth sx={{ mt: 3 }}>
                                                            <InputLabel id="contact-result-type-select">Contact Result</InputLabel>
                                                            <Select
                                                                labelId="contact-result-type-select"
                                                                id="contact-result-type-select"
                                                                value={Boolean(values.ContactResult) ? values.ContactResult : ''}
                                                                label="Contact Result"
                                                                name="ContactResult"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="">Select Contact Result</MenuItem>
                                                                {phoneOptions.map((option) => (
                                                                    <MenuItem key={option.Name} value={option.Name}>
                                                                        {option.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}
                                                    {isERVisitType(values.ReasonForVisit?.Id) ? (
                                                        <FormControl fullWidth sx={{ mt: 3 }}>
                                                            <InputLabel id="avoided-er-type-select">Avoided ER</InputLabel>
                                                            <Select
                                                                labelId="avoided-er-type-select"
                                                                displayEmpty
                                                                id="avoided-er-type-select"
                                                                value={String(values.AvoidedER)}
                                                                label="Avoided ER"
                                                                name="AvoidedER"
                                                                onChange={(e) =>
                                                                    setFieldValue(
                                                                        e.target.name,
                                                                        Boolean(e.target.value) ? e.target.value === 'true' : e.target.value
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem value={String(true)}>Yes</MenuItem>
                                                                <MenuItem value={String(false)}>No</MenuItem>
                                                                <MenuItem value="">Not applicable</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Collapse in={String(values.Contacted) === String(true)} timeout="auto" unmountOnExit>
                                        <Grid item textAlign="center" marginTop={2}>
                                            <Typography variant="subtitle1" component="label" color="red">
                                                Documenting an appointment engagement must not be performed here!
                                            </Typography>
                                            <Typography variant="subtitle1" component="label" color="red">
                                                Use the <em>"Assessment"</em> tab to document an encounter for an appointment with a member.
                                            </Typography>
                                        </Grid>
                                    </Collapse>
                                    <Grid item>
                                        <RichTextEditor
                                            id="summary-note-input"
                                            sx={classes.richTextBox}
                                            value={values.Notes}
                                            onChange={(val) => setFieldValue('Notes', val)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mui-modal-footer">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSaving}
                                    endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                                >
                                    {Boolean(values.Id) ? 'Update' : 'Save'}{' '}
                                    {String(values.Contacted) === String(true) ? '' : 'without documenting appointment'}
                                </Button>
                                <Button type="button" onClick={handleClose} variant="outlined" disabled={isSaving}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default AddOrUpdateEngagementDetail;
