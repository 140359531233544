import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Step, StepLabel, Stepper } from '@mui/material';

import { useEffect } from 'react';

import ConsentPartOneEnglish from './ConsentPartOneEnglish';
import ReleaseHealthInfo from './ReleaseHealthInfo';
import DocumentAcknowledgement from './DocumentAcknowledgement';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';

import { checkValidation, consentSteps, setActiveStep, setConsentSuccessful, setOpenSignConsentOne } from '../../../../store/consentSlice.slice';
import { getConsentFormDocumentByMember } from '../../../../store/assessment.slice';

const ConsentOneBox = () => {
    const dispatch = useDispatch();
    const { activeStep, consentSuccessful, digitalSignatureForm, isSaving, openSignConsentOne } = useSelector((state: RootState) => state.consentSlice);
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);

    const handleNextStep = () => {
        dispatch(checkValidation(activeStep, digitalSignatureForm, false));
    };

    useEffect(() => {
        if (consentSuccessful) {
            dispatch(setConsentSuccessful(false));
            dispatch(getConsentFormDocumentByMember(memberBasicInfo.Id));
            dispatch(setOpenSignConsentOne(false));
        }
    }, [consentSuccessful, memberBasicInfo.Id, dispatch]);

    return (
        <Dialog open={openSignConsentOne} onClose={() => dispatch(setOpenSignConsentOne(false))} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {consentSteps.map((step) => {
                        return (
                            <Step key={step.value}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </DialogTitle>
            <DialogContent>
                {activeStep === 0 && <ConsentPartOneEnglish />}
                {activeStep === 1 && <ReleaseHealthInfo />}
                {activeStep === 2 && <DocumentAcknowledgement />}
            </DialogContent>
            <DialogActions>
                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} p={2} sx={{ width: '100%' }}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={() => {
                            dispatch(setActiveStep(activeStep - 1));
                        }}
                        sx={{ minWidth: '120px' }}
                        variant="contained"
                    >
                        Previous
                    </Button>
                    <Button variant="outlined" sx={{ minWidth: '120px' }} onClick={() => dispatch(setOpenSignConsentOne(false))}>
                        Cancel
                    </Button>
                    <Button onClick={handleNextStep} sx={{ minWidth: '120px' }} variant="contained" disabled={isSaving}>
                        {activeStep < 2 ? 'Next' : 'Save & Finish'}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ConsentOneBox;
