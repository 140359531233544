import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const SSNMaskCustom = forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref: any) {
    const { onChange, ...other } = props;
    return <IMaskInput {...other} mask="000-00-0000" inputRef={ref} onAccept={(value: any) => onChange({ target: { name: props.name, value } })} overwrite />;
});

export default SSNMaskCustom;
