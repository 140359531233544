import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { fetchTeamInPersonVisitsSummaryChart, MenuProps, exportTeamInPersonVisitMembers } from '../WidgetService';
import { fetchPopulations } from '../../Population/PopulationService';
import EngagementMemberSummary from '../../../Models/Widget/EngagementMemberSummary.model';
import Population from '../../../Models/Population/Population.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { parentUrl } from '../../../utils/constants';
import { getDateFilter } from '../../HedisMetrics/HedisMetricService';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../../utils/timeFormat';
import { generateRandomId } from '../../../utils/common';
import '../Widgets.css';

const TeamInPersonVisitsSummaryDetail = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const { healthPlans } = useHealthPlanContext();
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const {
        engagementDateFilter,
        setEngagementDateFilter,
        engagementFromDate,
        setEngagementFromDate,
        engagementToDate,
        setEngagementToDate,
        engagementSelectedPlan,
        setEngagementSelectedPlan,
        engagementSelectedPopulation,
        setEngagementSelectedPopulation,
        engagementSelectedProvider,
        setEngagementSelectedProvider,
        engagementSelectedTeam,
        setEngagementSelectedTeam,
        engagementShowInActiveProviders,
        setEngagementShowInActiveProviders,
        engagementShowInActiveTeams,
        setEngagementShowInActiveTeams,
    } = useWidgetContext();
    const [populations, setPopulations] = useState<Population[]>([]);
    const [members, setMembers] = useState<EngagementMemberSummary>();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const [loadedPage, setLoadedPage] = useState(false);
    const filteredTeams = (engagementShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (engagementShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const healthPlanChange = useCallback(
        async (healthPlanId: string, canResetPopulation = true) => {
            setPopulations([]);
            if (canResetPopulation) {
                setEngagementSelectedPopulation(0);
            }
            if (healthPlanId && healthPlanId !== 'All') {
                const popList = await fetchPopulations(healthPlanId, true);
                setPopulations(popList);
            }
        },
        [setEngagementSelectedPopulation]
    );

    const dateFilterList = getDateFilter().filter((d) => d.Value !== 'Custom');

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        if (engagementSelectedPlan !== 'All') healthPlanChange(engagementSelectedPlan, false);
    }, [isFetched, engagementSelectedPlan, healthPlanChange]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (activeProviders?.length && teams?.length) {
            setLoadedPage(true);
        }
    }, [teams, activeProviders]);

    useEffect(() => {
        if (loadedPage && (engagementDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(engagementFromDate) && Boolean(engagementToDate)))) {
            const changeFilter = async () => {
                setIsMembersLoading(false);
                const data = await fetchTeamInPersonVisitsSummaryChart(
                    engagementSelectedPlan,
                    engagementSelectedPopulation,
                    engagementSelectedProvider,
                    engagementSelectedTeam,
                    engagementDateFilter,
                    Boolean(engagementFromDate) && engagementDateFilter === TimePeriodFilterEnum.Custom ? new Date(engagementFromDate).toISOString() : '',
                    Boolean(engagementToDate) && engagementDateFilter === TimePeriodFilterEnum.Custom ? new Date(engagementToDate).toISOString() : '',
                    engagementShowInActiveTeams,
                    engagementShowInActiveProviders,
                    true
                );
                setMembers(data);
                setIsMembersLoading(true);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (engagementDateFilter !== TimePeriodFilterEnum.Custom && Boolean(engagementFromDate) && Boolean(engagementToDate)) {
            setEngagementFromDate(null);
            setEngagementToDate(null);
        }
    }, [
        engagementSelectedPlan,
        engagementSelectedPopulation,
        engagementSelectedProvider,
        engagementSelectedTeam,
        engagementDateFilter,
        engagementFromDate,
        engagementToDate,
        setEngagementFromDate,
        setEngagementToDate,
        setIsFetched,
        loadedPage,
        engagementShowInActiveTeams,
        engagementShowInActiveProviders,
    ]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const changeFromDate = (date: any) => {
        setEngagementFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(engagementToDate) || new Date(engagementToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setEngagementToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setEngagementToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(engagementFromDate) || new Date(engagementFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setEngagementFromDate(startDate);
        }
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportTeamInPersonVisitMembers(
            engagementSelectedPlan,
            engagementSelectedPopulation,
            engagementSelectedProvider,
            engagementSelectedTeam,
            engagementDateFilter,
            Boolean(engagementFromDate) && engagementDateFilter === TimePeriodFilterEnum.Custom ? new Date(engagementFromDate).toISOString() : '',
            Boolean(engagementToDate) && engagementDateFilter === TimePeriodFilterEnum.Custom ? new Date(engagementToDate).toISOString() : '',
            engagementShowInActiveTeams,
            engagementShowInActiveProviders
        );

        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `EngagementMembers - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setEngagementShowInActiveTeams(isChecked);
        setEngagementSelectedTeam('All');
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setEngagementShowInActiveProviders(isChecked);
        setEngagementSelectedProvider('All');
    };

    const columns: GridColDef[] = [
        {
            field: 'Index',
            headerName: '#',
            width: 60,
            sortable: false,
            renderCell: (params) => {
                return `${params.api.getRowIndex(params.id) + 1}`;
            },
        },
        {
            field: 'Name',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DateInfo',
            headerName: 'Visited Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.DateInfo) ? dateFormatter(new Date(params.row.DateInfo)) : '-'}`,
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Member Visits By Team
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Health Plan</InputLabel>
                            <Select
                                label="Health Plan"
                                name="Health Plan"
                                onChange={(e) => {
                                    setEngagementSelectedPlan(e.target.value);
                                    healthPlanChange(e.target.value);
                                }}
                                value={engagementSelectedPlan}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {filteredHealthPlans.map((plan) => (
                                    <MenuItem key={plan.Id} value={plan.Id}>
                                        {plan.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Population</InputLabel>
                            <Select
                                value={engagementSelectedPopulation}
                                label="Population"
                                name="Population"
                                onChange={(e) => setEngagementSelectedPopulation(parseInt(e.target.value))}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="0">
                                    All
                                </MenuItem>
                                {populations.map((tm) => (
                                    <MenuItem key={tm.PopulationNumber} value={tm.PopulationNumber}>
                                        {tm.PopulationName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={engagementSelectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setEngagementSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={engagementShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select
                                label="Team"
                                name="Team"
                                onChange={(e) => setEngagementSelectedTeam(e.target.value)}
                                value={engagementSelectedTeam}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={engagementShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={engagementDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setEngagementDateFilter(parseInt(e.target.value))}
                            >
                                {dateFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {parseInt(engagementDateFilter) === TimePeriodFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(engagementFromDate) ? engagementFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(engagementToDate) ? engagementToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={parseInt(engagementDateFilter) === TimePeriodFilterEnum.Custom ? 0.5 : 3.5}></Grid>
                    <Grid item xs={1}>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => exportDetail()}
                            variant="contained"
                            color="success"
                            disabled={members?.EngagementMemberDetails?.length === 0}
                        >
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isMembersLoading ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container columnSpacing={3}>
                                {members.EngagementMemberDetails.map((detail, index) => (
                                    <>
                                        <Grid item xs={12} textAlign="center" paddingTop={index === 0 ? 0 : 2}>
                                            <Typography display={'inline-block'} variant="h5" color={'default'} marginTop={1}>
                                                {detail.Month}
                                            </Typography>
                                            <hr></hr>
                                        </Grid>
                                        <Grid item xs={6} textAlign="center">
                                            <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                Visited ({detail.VisitedMembers.length})
                                            </Typography>
                                            <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                <DataGrid
                                                    columns={columns}
                                                    rows={detail.VisitedMembers}
                                                    getRowId={(row) => `${row.ItemId}-${row.DateInfo}-${generateRandomId()}`}
                                                    paginationMode="server"
                                                    hideFooterPagination={true}
                                                    hideFooter={true}
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} textAlign="center">
                                            <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                Not Visited ({detail.NotVisitedMembers.length})
                                            </Typography>
                                            <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                <DataGrid
                                                    columns={columns}
                                                    rows={detail.NotVisitedMembers}
                                                    getRowId={(row) => `${row.ItemId}-${generateRandomId()}`}
                                                    paginationMode="server"
                                                    hideFooterPagination={true}
                                                    hideFooter={true}
                                                    onCellClick={(params: any) => {
                                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default TeamInPersonVisitsSummaryDetail;
