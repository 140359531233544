export default interface ILoginSetup {
    MemberId: string;
    Email: string;
    Password: string;
    TabletId: string;
    Sim: string;
    UserId: string;
    DateOfDelivery: string;
}

export class LoginSetup implements ILoginSetup {
    MemberId: string;
    Email: string;
    Password: string;
    TabletId: string;
    Sim: string;
    UserId: string;
    DateOfDelivery: string;

    constructor() {
        this.MemberId = '';
        this.Email = '';
        this.DateOfDelivery = '';
        this.Password = '';
        this.Sim = '';
        this.TabletId = '';
        this.UserId = '';
    }
}
