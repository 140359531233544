import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography, SelectChangeEvent, Tooltip, IconButton } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { apiString as API_URL } from '../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import WidgetLoading from '../../Shared/WidgetLoading';
import { fetchRoles } from '../../../../store/shared.slice';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';

import InfoIcon from '@mui/icons-material/Info';

const WidgetUsage = (props) => {
    const dispatch = useDispatch();
    const { roles } = useSelector((state: RootState) => state.shared);

    const [widgetUsage, setWidgetUsage] = useState([]);
    const [roleId, setRoleId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        const changeFilter = async (roleId: string) => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_URL}/provider/getwidgetusagebyrole?roleId=${roleId}`);
                setWidgetUsage(response.data);
                setIsLoading(false);
            } catch (error) {
                setWidgetUsage([]);
                setIsLoading(false);
                console.log(error);
            }
        };
        if (roleId.length > 0) {
            changeFilter(roleId);
        }
    }, [roleId]);

    const columns: GridColDef[] = [
        {
            field: 'WidgetName',
            headerName: 'Widget Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'NumberOfTimesUsed',
            headerName: '# of Times Used',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                const numberOfProviders = params.row.NumberOfProviders;
                const percentage = (params.row.NumberOfTimesUsed / numberOfProviders) * 100;
                if (isNaN(percentage)) return <Typography>N/A</Typography>;
                const color = percentage >= 50 ? '#4caf50' : 'error';
                return (
                    <Typography color={color}>
                        {params.row.NumberOfTimesUsed} - {percentage.toFixed(1)}%
                    </Typography>
                );
            },
        },
        {
            field: 'NumberOfTimesNotUsed',
            headerName: '# of Times Not Used',
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                const numberOfProviders = params.row.NumberOfProviders;
                const percentage = (params.row.NumberOfTimesNotUsed / numberOfProviders) * 100;
                if (isNaN(percentage)) return <Typography>N/A</Typography>;
                const color = percentage < 50 ? '#4caf50' : 'error';
                return (
                    <Typography color={color}>
                        {params.row.NumberOfTimesNotUsed} - {percentage.toFixed(1)}%
                    </Typography>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(fetchRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    console.log('widgetUsage: ', widgetUsage);
    const handleChange = (event: SelectChangeEvent) => {
        setRoleId(event.target.value as string);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" mb={2} marginRight={1} color={'primary'}>
                            Widget Usage by Role
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Displays number of times an available widget was set to hidden or visible on a custom Dashboard by Role
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        <Grid item xs={12} textAlign="center">
                            <Stack direction="row" spacing={4} alignItems={'center'}>
                                <FormControl sx={{ width: '200px' }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={roleId} label="Role" onChange={handleChange}>
                                        <MenuItem value={'-1'}>All</MenuItem>
                                        {roles.map((role) => (
                                            <MenuItem key={role.Id} value={role.Id}>
                                                {role.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography display={'inline-block'} variant="body1">
                                        Number of Dashboards:
                                    </Typography>
                                    <Typography display={'inline-block'} variant="h6" fontWeight={'bold'} marginRight={1}>
                                        {widgetUsage.length > 0 ? widgetUsage[0].NumberOfProviders : 0}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        {isLoading && <WidgetLoading />}
                        <Grid item xs={12} marginTop={2} position="relative">
                            <div style={{ width: '100%', height: '52vh' }}>
                                <DataGrid
                                    rows={widgetUsage}
                                    columns={columns}
                                    pageSize={8}
                                    rowsPerPageOptions={[10]}
                                    getRowId={(params) => params.WidgetId}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default WidgetUsage;
