import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import packageJson from '../../package.json';
import { UserDto } from '../Models/User/UserDto.model';

export interface IAuthenticationSlice {
    apiVersion: string;
    version: string;
    authenticatedUser: UserDto | null;
    isInactive: boolean;
}

const initialAuthenticationState: IAuthenticationSlice = {
    version: packageJson.version,
    apiVersion: '',
    authenticatedUser: null,
    isInactive: false,
};

const authentication = createSlice({
    name: 'authentication',
    initialState: initialAuthenticationState,
    reducers: {
        _setAuthenticatedUser(state, action: PayloadAction<UserDto>) {
            state.authenticatedUser = action.payload;
        },
        _setApiVersion(state, action: PayloadAction<string>) {
            state.apiVersion = action.payload;
        },
        _setIsInactive(state, action: PayloadAction<boolean>) {
            state.isInactive = action.payload;
        },
    },
});

const { _setAuthenticatedUser, _setApiVersion, _setIsInactive } = authentication.actions;

export const setIsInactive =
    (isInactive: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsInactive(isInactive));
    };

export const setAuthenticatedUser =
    (user: UserDto): AppThunk =>
    async (dispatch) => {
        dispatch(_setAuthenticatedUser(user));
    };

export const getApiVersion = (): AppThunk => async (dispatch) => {
    try {
        const response = await axios.get<string>(`${API_URL}/account/version`);
        dispatch(_setApiVersion(response.data));
    } catch (e) {
        dispatch(_setApiVersion('API not available'));
        console.log(e);
    }
};

export const logout = (): AppThunk => async (dispatch) => {
    localStorage.removeItem('auth');
    sessionStorage.removeItem('auth');
    dispatch(_setAuthenticatedUser(null));
    localStorage.setItem('logout-event', Date.now().toString());
};

export default authentication.reducer;
