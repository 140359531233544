import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import moment from 'moment';

import { setIsLoading } from './shared.slice';
import { MedicationManagement } from '../Models/Medications/MedicationManagement.model';
import { MedicationFrequencyTimes } from '../Models/Medications/MedicationFrequencyTimes.model';
import { Medications } from '../Models/Medications/Medications.model';
import { setAssessmentError } from './assessment.slice';
import { PeriodsHours, PeriodsMinutes, TimeMeridiems, MedicationStatusEnum } from '../utils/assessments';
import { IMedicationPeriod, MedicationAssignPeriods } from '../Models/Medications/MedicationAssignPeriods.model';
import { copyObject } from '../utils/common';
import { SummaryNoteTypeEnum } from '../Enum/SummaryNoteTypeEnum';
import { saveEngagementDraft } from './documentEngagement.slice';
import { StatusEnum } from '../Enum/StatusEnum';
import Authorization from '../utils/Authorization';

const medicationSlice = createSlice({
    name: 'medication',
    initialState: {
        medicationManagement: new MedicationManagement(),
        medicationFrequencyTimes: [] as MedicationFrequencyTimes[],
        selectedMedication: new Medications(),
        selectedAssignPeriods: new MedicationAssignPeriods(),
        mergeMedication: new Medications(),
        deletingMedicationIds: [] as string[],
        showMedicationModal: false,
        showMedicationPriodModal: false,
        showDiscontinueModal: false,
        showMergeMedicationModal: false,
        samlResponse: '',
        ssoEndpoint: '',
        isMedicationNotification: false,
        isFetching: false,
        isSaving: false,
        isGeneratingPDF: false,
    },
    reducers: {
        _setMedicationManagement(state, action) {
            state.medicationManagement = action.payload;
        },
        _setMedicationFrequencyTimes(state, action) {
            state.medicationFrequencyTimes = action.payload;
        },
        _setIsMedicationNotification(state, action) {
            state.isMedicationNotification = action.payload;
        },
        _setSelectedMedication(state, action) {
            state.selectedMedication = action.payload;
        },
        _setSelectedAssignPeriods(state, action) {
            state.selectedAssignPeriods = action.payload;
        },
        _setMergeMedication(state, action) {
            state.mergeMedication = action.payload;
        },
        _toggleShowMedicationModal(state, action) {
            state.showMedicationModal = action.payload;
        },
        _toggleShowMedicationPriodModal(state, action) {
            state.showMedicationPriodModal = action.payload;
        },
        _toggleShowDiscontinueModal(state, action) {
            state.showDiscontinueModal = action.payload;
        },
        _setDeletingMedicationIds(state, action) {
            state.deletingMedicationIds = action.payload;
        },
        _setSamlResponse(state, action) {
            state.samlResponse = action.payload;
        },
        _setSSOEndpoint(state, action) {
            state.ssoEndpoint = action.payload;
        },
        _toggleShowMergeMedicationModal(state, action) {
            state.showMergeMedicationModal = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsGeneratingPDF(state, action) {
            state.isGeneratingPDF = action.payload;
        },
    },
});

const {
    _setMedicationManagement,
    _setMedicationFrequencyTimes,
    _setIsMedicationNotification,
    _setDeletingMedicationIds,
    _setSelectedMedication,
    _setSelectedAssignPeriods,
    _setMergeMedication,
    _toggleShowMedicationModal,
    _toggleShowMedicationPriodModal,
    _toggleShowDiscontinueModal,
    _toggleShowMergeMedicationModal,
    _setSamlResponse,
    _setSSOEndpoint,
    _toggleIsFetching,
    _toggleIsSaving,
    _toggleIsGeneratingPDF,
} = medicationSlice.actions;

export const setSamlResponse =
    (samlResponse: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setSamlResponse(samlResponse));
    };

export const getMedications =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/medication/getmedications`, { params: { memberId } });
        dispatch(_setMedicationManagement(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const saveMedication =
    (memberId: string, medicationData: Medications, updatedEngagement = true): AppThunk =>
    async (dispatch, getState) => {
        let medication = copyObject(medicationData);
        const medicationManagement = getState().medication.medicationManagement;
        dispatch(_toggleIsSaving(true));
        const isUpdate = Boolean(medication.Id);
        if (!isUpdate) {
            medication.Status = MedicationStatusEnum.Active;
        }

        const request = isUpdate
            ? axios.put(`${API_URL}/medication/updatemedication`, medication, { params: { memberId } })
            : axios.post(`${API_URL}/medication/insertmedication`, medication, { params: { memberId } });

        request
            .then((response) => {
                if (isUpdate) {
                    dispatch(
                        _setMedicationManagement({
                            ...medicationManagement,
                            Medications: [
                                ...medicationManagement.Medications.map((m) => {
                                    return m.Id === medication.Id ? medication : m;
                                }),
                            ],
                        } as MedicationManagement)
                    );
                } else {
                    dispatch(
                        _setMedicationManagement({
                            ...medicationManagement,
                            Medications: [...medicationManagement.Medications, response.data],
                        })
                    );
                }
                dispatch(_toggleShowMedicationModal(false));
                if (updatedEngagement) {
                    axios.put(`${API_URL}/medication/sendveradigmmed`, response.data || medication, { params: { memberId } });
                } else {
                    dispatch(prepareMedicaionSummaryNotes());
                }
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data?.error }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const prepareMedicaionSummaryNotes =
    (isCompleted = false): AppThunk =>
    (dispatch, getState) => {
        const authData = new Authorization();
        const member = getState().memberDetail.memberBasicInfo;
        let engagementDraft = copyObject(getState().documentEngagement.engagementDraft || {});
        let summaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.Medications);
        let medications = (getState().medication?.medicationManagement?.Medications || []).filter((m) => m.DisplayOnReportPdf);

        medications.forEach((medication) => {
            if (member?.IsInTransition && authData.IsLicensedProvider) {
                summaryNotes.push({
                    Id: medication.Id,
                    CptCode: '1111F',
                    Zcode: '',
                    Note: `Medication - ${medication.Medicine}`,
                    Type: SummaryNoteTypeEnum.Medications,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Medications',
                });
            } else {
                summaryNotes.push({
                    Id: medication.Id,
                    CptCode: '1159F',
                    Zcode: '',
                    Note: `Medication - ${medication.Medicine}`,
                    Type: SummaryNoteTypeEnum.Medications,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Medications',
                });
            }
        });

        engagementDraft.SummaryNotes = summaryNotes;
        engagementDraft.MedicationReconciliationStatus = StatusEnum.Completed;
        dispatch(saveEngagementDraft(engagementDraft, isCompleted));
    };

export const getMedicationFrequencyTimes = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/medication/getmedicationfrequencytimes`);
    dispatch(_setMedicationFrequencyTimes(response.data));
    dispatch(setIsLoading(false));
};

export const getMedicationNotifications =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/medication/getmedicationnotifications`, { params: { memberId } });
        dispatch(_setIsMedicationNotification(response.data));
        dispatch(setIsLoading(false));
    };

export const updateMedications =
    (memberId: string, medications: Medications[], sendVeradigm = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        await axios.put(`${API_URL}/medication/updateMedications`, medications, { params: { memberId } });
        if (sendVeradigm) {
            dispatch(sendVeradigmMedications(memberId, medications));
        } else {
            dispatch(getMedications(memberId));
            dispatch(_toggleShowDiscontinueModal(false));
            dispatch(_toggleIsSaving(false));
            dispatch(toggleShowMedicationPriodModal(false));
        }
    };

export const sendVeradigmMedications =
    (memberId: string, medications: Medications[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        await axios.put(`${API_URL}/medication/sendveradigmmeds`, medications, { params: { memberId } });
        dispatch(getMedications(memberId));
        dispatch(_toggleIsSaving(false));
        dispatch(toggleShowMergeMedicationModal(false));
    };

export const updateMedicationNotifications =
    (memberId: string, value: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setIsMedicationNotification(value));
        await axios.put(`${API_URL}/medication/updatemedicationnotifications`, null, { params: { memberId, value } });
    };

export const setMedicationManagement =
    (medicationManagement: MedicationManagement): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setMedicationManagement(medicationManagement));
    };

export const toggleShowMedicationModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        if (!show) {
            dispatch(_setSelectedMedication(new Medications()));
        }

        dispatch(_toggleShowMedicationModal(show));
    };

export const toggleShowMedicationPriodModal =
    (show: boolean, medicationNames = [], frequency = ''): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowMedicationPriodModal(show));
        if (show && Boolean(frequency)) {
            const { medicationFrequencyTimes } = getState().medication;
            let index = 0;
            const periods = [] as IMedicationPeriod[];
            const isHrs = frequency.includes('HRS');
            const timesGiven = medicationFrequencyTimes.find((x) => x.Frequency === frequency)?.TimesGiven || 0;

            if (isHrs) {
                periods.push({ Hour: PeriodsHours[index], Minute: PeriodsMinutes[0], Meridiem: TimeMeridiems[0] });
            } else {
                while (index < timesGiven) {
                    index++;
                    periods.push({ Hour: PeriodsHours[index], Minute: PeriodsMinutes[0], Meridiem: TimeMeridiems[0] });
                }
            }

            const assignPeriods = {
                MedicationNames: medicationNames,
                Frequency: frequency,
                IsHrs: isHrs,
                Periods: periods,
                TimesGiven: timesGiven,
                Schedules: preparePeriodSchedules(frequency, periods),
            } as MedicationAssignPeriods;
            dispatch(_setSelectedAssignPeriods(assignPeriods));
        } else {
            dispatch(_setSelectedAssignPeriods(new MedicationAssignPeriods()));
        }
    };

export const preparePeriodSchedules = (selectedFrequency: string, periods: IMedicationPeriod[]) => {
    const schedules = [];
    if (selectedFrequency.includes('HRS')) {
        const hrs = parseInt(selectedFrequency.replace('HRS', '').replace('Q', ''));
        const numberOfPeriods = 24 / hrs;
        const period = periods[0];
        let startSchedule = period.Hour + ':' + period.Minute + ' ' + period.Meridiem;
        let startHour = parseInt(period.Hour);
        let startAMPM = period.Meridiem;

        schedules.push(startSchedule);

        let index = 0;
        let newHour = startHour;
        while (index < numberOfPeriods - 1) {
            index++;
            newHour = newHour + hrs;

            if (newHour > 12) {
                newHour = newHour - 12;
                startAMPM = startAMPM === 'AM' ? 'PM' : 'AM';
            }

            schedules.push(newHour + ':' + period.Minute + ' ' + startAMPM);
        }
    } else {
        periods.forEach((period) => {
            const value = period.Hour + ':' + period.Minute + ' ' + period.Meridiem;
            schedules.push(value);
        });
    }

    return schedules;
};

export const setSelectedAssignPeriods =
    (periods: MedicationAssignPeriods): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setSelectedAssignPeriods(periods));
    };

export const toggleShowMergeMedicationModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowMergeMedicationModal(show));
        dispatch(_setMergeMedication(new Medications()));
    };

export const toggleShowDiscontinueModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowDiscontinueModal(show));
    };

export const setSelectedMedication =
    (medication: Medications): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setSelectedMedication(medication));
    };

export const deletingMedicationById =
    (medicationId: string, memberId: string): AppThunk =>
    async (dispatch, getState) => {
        const { medicationManagement, deletingMedicationIds } = getState().medication;
        dispatch(_setDeletingMedicationIds([...deletingMedicationIds, medicationId]));
        await axios.delete(`${API_URL}/medication/deletemedication`, { params: { medicationId, memberId } });
        dispatch(
            _setMedicationManagement({
                ...medicationManagement,
                Medications: medicationManagement.Medications.filter((m) => m.Id !== medicationId),
            })
        );
        dispatch(_setDeletingMedicationIds(deletingMedicationIds.filter((d) => d !== medicationId)));
        dispatch(_toggleIsSaving(false));
    };

export const generateMedicationAllergyPDF =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsGeneratingPDF(true));
        const response = await axios.get(`${API_URL}/medication/generateMedicationAllergyPDF`, { params: { memberId } });
        var blob = b64toBlob(response.data.base64String, 'application/pdf');
        var blobUrl = URL.createObjectURL(blob);
        var a = document.createElement('a');
        var randamNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
        var fileName = `Medication-Allergy-${moment(new Date()).format('MM-DD-yyyy')}-${randamNumber}`;
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = fileName + '.pdf';
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        dispatch(_toggleIsGeneratingPDF(false));
    };

export const getSSOEndPoint = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/account/ssoendpoint`);
    dispatch(_setSSOEndpoint(response.data));
};

export const connectEPrescribe =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/account/samlstring?memberId=${memberId}`);
        dispatch(_setSamlResponse(response.data));
        setTimeout(() => {
            document.getElementById('submitEprescribe').click();
        }, 200);
    };

export default medicationSlice.reducer;
