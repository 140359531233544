import { Box, Button, FormGroup, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MessageDisplay from '../../../Shared/MessageDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { setChangePassword, setChangePasswordIsValid } from '../../../../store/providerNavbar.slice';
import ChangePasswordModel from '../../../../Models/Shared/ChangePasswordModel.model';
import { RootState } from '../../../../reducers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Authorization from '../../../../utils/Authorization';
import { setShowMessage } from '../../../../store/shared.slice';
import { logout } from '../../../../store/authentication.slice';
import axios from 'axios';
import { apiString as API_URL } from '../../../../utils/constants';

const ChangePassword = (props) => {
    const authData = new Authorization();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { changePassword, changePasswordIsValid } = useSelector((state: RootState) => state.providerNavbar);

    const [showOld, setShowOld] = useState<boolean>(false);

    const [showNew, setShowNew] = useState<boolean>(false);

    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const submitChangePasswordHandler = async () => {
        if (
            changePassword.OldPassword?.length === 0 ||
            changePassword.NewPassword?.length === 0 ||
            changePassword.ConfirmPassword?.length === 0 ||
            changePassword.NewPassword !== changePassword.ConfirmPassword
        ) {
            dispatch(setChangePasswordIsValid(false));
            return;
        } else {
            const response = await axios.put(`${API_URL}/account/changepassword`, changePassword);
            if (response.data === false) {
                dispatch(setShowMessage(true, 'Old password is incorrect', 'error'));
            } else {
                dispatch(setShowMessage(true, 'Password successfully changed', 'success'));
                dispatch(setChangePassword(new ChangePasswordModel()));
                dispatch(setChangePasswordIsValid(true));
                if (authData.IsTempPassword) {
                    dispatch(logout());
                    navigate('/login');
                }
            }
        }
    };

    const navigateHandler = () => {
        if (authData.IsTempPassword) {
            dispatch(logout());
            navigate('/login');
        } else navigate(-1);
    };

    return (
        <Box p={2}>
            <MessageDisplay />
            <Stack direction="row" alignItems={'center'} spacing={2}>
                <IconButton sx={{ cursor: 'pointer' }} onClick={navigateHandler}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" fontWeight="bold">
                    Change Password
                </Typography>
            </Stack>

            <Grid container height={'60vh'} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ padding: '16px' }}>
                        <FormGroup>
                            <Stack spacing={1}>
                                <TextField
                                    label="Old Password"
                                    variant="outlined"
                                    type={showOld ? 'text' : 'password'}
                                    value={changePassword.OldPassword}
                                    onChange={(e) => dispatch(setChangePassword({ ...changePassword, OldPassword: e.target.value }))}
                                    required
                                    error={!changePasswordIsValid && changePassword.OldPassword.length === 0}
                                    helperText={!changePasswordIsValid && changePassword.OldPassword.length === 0 ? 'Old password is required' : ' '}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowOld(!showOld)}
                                                    onMouseDown={() => setShowOld(!showOld)}
                                                >
                                                    {showOld ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    label="New Password"
                                    variant="outlined"
                                    type={showNew ? 'text' : 'password'}
                                    value={changePassword.NewPassword}
                                    onChange={(e) => dispatch(setChangePassword({ ...changePassword, NewPassword: e.target.value }))}
                                    required
                                    error={
                                        !changePasswordIsValid &&
                                        (changePassword.NewPassword.length === 0 || changePassword.NewPassword !== changePassword.ConfirmPassword)
                                    }
                                    helperText={
                                        !changePasswordIsValid && changePassword.NewPassword.length === 0
                                            ? 'New password is required'
                                            : !changePasswordIsValid && changePassword.NewPassword !== changePassword.ConfirmPassword
                                            ? 'Passwords do not match'
                                            : ' '
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowNew(!showNew)}
                                                    onMouseDown={() => setShowNew(!showNew)}
                                                >
                                                    {showNew ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    label="Confirm New Password"
                                    variant="outlined"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={changePassword.ConfirmPassword}
                                    onChange={(e) => dispatch(setChangePassword({ ...changePassword, ConfirmPassword: e.target.value }))}
                                    required
                                    error={
                                        !changePasswordIsValid &&
                                        (changePassword.ConfirmPassword.length === 0 || changePassword.NewPassword !== changePassword.ConfirmPassword)
                                    }
                                    helperText={
                                        !changePasswordIsValid && changePassword.ConfirmPassword.length === 0
                                            ? 'Confirm password is required'
                                            : !changePasswordIsValid && changePassword.NewPassword !== changePassword.ConfirmPassword
                                            ? 'Passwords do not match'
                                            : ' '
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                                                >
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                            <Stack spacing={2} direction="row" justifyContent="flex-end" marginTop={5}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{ minWidth: '120px' }}
                                    onClick={() => {
                                        dispatch(setChangePassword(new ChangePasswordModel()));
                                        dispatch(setChangePasswordIsValid(true));
                                    }}
                                >
                                    Clear
                                </Button>
                                <Button variant="contained" color="primary" onClick={submitChangePasswordHandler}>
                                    Change Password
                                </Button>
                            </Stack>
                        </FormGroup>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChangePassword;
