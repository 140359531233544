import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Input,
    InputLabel,
    Paper,
    TextField,
    Stack,
    Box,
    Divider,
    Modal,
    Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import MemberAvatar from './MemberAvatar';
import TextMaskCustom from '../../Shared/PhoneMask';
import { modalStyle } from '../../../utils/constants';

const MemberPhone = (props) => {
    const { selectedMember, showPhone, setShowPhone, formPhone, setFormPhone, showDelete, setShowDelete, deletePhone, addPhoneHandler, saveLocalPhone } = props;

    const columns: GridColDef[] = [
        {
            field: 'Number',
            headerName: 'Phone Number',
            minWidth: 200,
            renderCell: (params) => {
                return <Input readOnly value={params.row.Number} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
        },
        {
            field: 'IsPrimary',
            headerName: 'Primary',
            minWidth: 200,
            renderCell: (params) => {
                return params.row.IsPrimary ? <CheckIcon color="success" /> : <div></div>;
            },
        },
        {
            field: 'Textable',
            headerName: 'Textable',
            minWidth: 200,
            renderCell: (params) => {
                return params.row.Textable ? <CheckIcon color="success" /> : <div></div>;
            },
        },
        {
            field: 'Note',
            headerName: 'Notes',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Edit
                            className="icon-left"
                            onClick={() => {
                                addPhoneHandler(params.row);
                                setShowPhone(true);
                            }}
                        />
                        <Delete
                            className="icon-right"
                            onClick={() => {
                                setFormPhone(params.row);
                                setShowDelete(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let update = { ...formPhone };
        update.Number = event.target.value;

        setFormPhone(update);
    };

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} style={{ marginBottom: '16px' }}>
                    <FormGroup>
                        <FormControl variant="standard" style={{ marginRight: '16px' }}>
                            <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
                            <Input
                                value={formPhone.Number || ''}
                                onChange={handlePhoneChange}
                                name="textmask"
                                id="formatted-text-mask-input"
                                inputComponent={TextMaskCustom as any}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={formPhone.Textable} />}
                            onChange={(e: any) => {
                                setFormPhone({ ...formPhone, Textable: e.target.checked });
                            }}
                            label="Textable"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={formPhone.IsPrimary} />}
                            onChange={(e: any) => {
                                setFormPhone({ ...formPhone, IsPrimary: e.target.checked });
                            }}
                            label="Primary Phone"
                        />
                    </FormGroup>
                </Stack>
                <TextField
                    onChange={(e: any) => {
                        setFormPhone({ ...formPhone, Note: e.target.value });
                    }}
                    style={{ width: '100%' }}
                    id="outlined-multiline-static"
                    label="Note"
                    multiline
                    rows={3}
                    value={formPhone.Note || ''}
                />
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={saveLocalPhone}>
                            Save
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowPhone(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    const renderDeleteBody = () => {
        return (
            <Stack spacing={2}>
                <div className="row">
                    <Typography sx={{ ml: 3 }} variant="h6">
                        Are you sure do you want to delete: {formPhone.Note}?
                    </Typography>
                </div>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" color="error" onClick={() => deletePhone()}>
                            Delete
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowDelete(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <div>
            <Paper style={props.phoneStyles}>
                <Stack spacing={2}>
                    {!props.members && (
                        <div>
                            <MemberAvatar />
                            <Button style={{ marginBottom: '16px' }} variant="contained" onClick={addPhoneHandler}>
                                Add Phone
                            </Button>
                        </div>
                    )}
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.Id}
                        rows={selectedMember.Phones}
                        columns={columns}
                        experimentalFeatures={{ newEditingApi: true }}
                        hideFooter
                    />
                </Stack>
            </Paper>

            <Modal
                open={showPhone}
                onClose={() => {
                    setShowPhone(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Phone</Typography>
                        <Divider />
                        {renderBody()}
                    </Stack>
                </Box>
            </Modal>
            <Modal
                open={showDelete}
                onClose={() => {
                    setShowDelete(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Delete Phone</Typography>
                        <Divider />
                        {renderDeleteBody()}
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default MemberPhone;
