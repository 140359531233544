export enum FuhFumVisitTypeEnum {
    InpatientAdmitDescription = 'Inpatient Admit',
    InpatientAdmit = 1,
    EDAdmitDescription = 'ED Admit',
    EDAdmit = 2,
    EDDischargeDescription = 'ED Discharge',
    EDDischarge = 3,
    PostDischargeDescription = 'Post Discharge',
    PostDischarge = 4,
}
