import { SummaryNoteTypeEnum } from '../../Enum/SummaryNoteTypeEnum';
import { EngagementDraft } from '../../Models/DocumentEngagements/EngagementDraft.model';
import { ProviderId } from '../../utils/constants';
import { IOption } from '../interfaces/IOption';

export interface IBasicEngagementProperties {
    memberId: string;
    meansOfEngagementValue: string;
    assessedRoleId: string;
    assessedRoleName: string;
    reasonForEngagementOption: IOption;
    notes: string;
    zcode: string;
    summaryNoteType: number;
}

const DEFAULT_CPT_CODE = 'T1016' as const;

const generateBasicEngagementDraft = (basicEngagement: IBasicEngagementProperties): EngagementDraft => {
    const { summaryNoteType, zcode, memberId, meansOfEngagementValue, assessedRoleName, assessedRoleId, reasonForEngagementOption, notes } = basicEngagement;
    let summaryNotes = [];
    if (summaryNoteType) {
        if (summaryNoteType === SummaryNoteTypeEnum.Request) {
            summaryNotes = [
                {
                    Id: '',
                    Note: '',
                    Zcode: zcode ?? 'Z59.1',
                    Type: SummaryNoteTypeEnum.Request,
                    IsConfirm: true,
                    CptCode: DEFAULT_CPT_CODE,
                    CreatedOn: new Date(),
                    UpdatedOn: new Date(),
                    CreatedBy: ProviderId,
                    UpdatedBy: ProviderId,
                },
            ];
        }
    }

    return {
        MemberId: memberId,
        VisitedTime: new Date().toUTCString(),
        MeansOfEngagement: Number(meansOfEngagementValue),
        AssessedRole: {
            Id: assessedRoleId,
            RoleName: assessedRoleName,
        },
        ReasonForVisit: {
            Name: reasonForEngagementOption.label,
            Id: reasonForEngagementOption.value,
            MemberId: memberId,
        },
        Notes: notes,
        SummaryNotes: summaryNotes,
        IgnoreTimelineInsert: true,
    } as EngagementDraft;
};

export default generateBasicEngagementDraft;
