import { useEffect, useState } from 'react';

import Authorization from '../../utils/Authorization';

import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteNotificationHandler,
    getAllNotifications,
    markAsReadHandler,
    setNotificationTab,
    setNotificationType,
} from '../../store/notificationsPage.slice';
import { RootState } from '../../reducers';
import Loading from '../Shared/Loading';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import { isDefaultDate } from '../../utils/common';
import { localDateAndTimeFormat } from '../../utils/timeFormat';
import CancelIcon from '@mui/icons-material/Cancel';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { NotificationTypeEnum } from '../../Enum/NotificationTypeEnum';
import { getAllScheduleNotifications } from '../../store/providerNavbar.slice';

const NotificationsPage = () => {
    const authData = new Authorization();
    const dispatch = useDispatch();
    const { allNotifications, notificationTab, notificationType } = useSelector((state: RootState) => state.notificationsPage);
    const { allScheduleNotifications } = useSelector((state: RootState) => state.providerNavbar);

    const [rows, setRows] = useState([]);

    const columns: GridColDef[] = [
        {
            field: 'markAsRead',
            headerName: 'Mark As Read',
            sortable: false,
            flex: 0.25,
            renderCell: (params) => {
                return (
                    <MarkEmailReadIcon
                        color={params.row.IsRead ? 'disabled' : 'primary'}
                        style={{
                            marginLeft: '24px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (!params.row.IsRead) dispatch(markAsReadHandler(params.row.Id));
                        }}
                    />
                );
            },
        },
        {
            field: 'Message',
            headerName: 'Message',
            flex: 1,
        },
        {
            field: 'CreatedTime',
            headerName: 'Created',
            flex: 0.25,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CreatedTime) ? '-' : localDateAndTimeFormat(new Date(params.row.CreatedTime))}`,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            flex: 0.25,
            renderCell: (params) => {
                return (
                    <CancelIcon
                        color="error"
                        style={{
                            marginLeft: '24px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            dispatch(deleteNotificationHandler(params.row.Id));
                        }}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(getAllNotifications(authData.UserId));
        dispatch(getAllScheduleNotifications(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (notificationTab === 0) {
            setRows(allNotifications.filter((notification) => notificationType === 0 || notification.NotificationType === notificationType));
        } else if (notificationTab === 1) {
            setRows(allScheduleNotifications);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allNotifications, allScheduleNotifications, notificationTab, notificationType]);

    return (
        <Box p={2}>
            <Loading message="Loading.." />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Notifications</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={notificationTab}
                        variant="scrollable"
                        onChange={(e, newValue) => dispatch(setNotificationTab(newValue))}
                        scrollButtons="auto"
                        textColor="inherit"
                        indicatorColor="primary"
                    >
                        <Tab value={0} label="Site Notifications" />
                        <Tab value={1} label="Scheduling Notifications" />
                    </Tabs>
                </Grid>
                {notificationTab === 0 && (
                    <Grid item xs={12} marginY={2}>
                        <FormControl sx={{ width: '250px' }}>
                            <InputLabel>Notification Type</InputLabel>
                            <Select
                                value={notificationType}
                                label="Notification Type"
                                name="Notification Type"
                                onChange={(e) => {
                                    console.log('e.target.value: ', e.target.value);
                                    console.log(typeof e.target.value);
                                    dispatch(setNotificationType(parseInt(e.target.value as string)));
                                }}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                <MenuItem value={NotificationTypeEnum.Schedule}>Schedule</MenuItem>
                                <MenuItem value={NotificationTypeEnum.Request}>Request</MenuItem>
                                <MenuItem value={NotificationTypeEnum.Hospitalization}>Hospitalization</MenuItem>
                                <MenuItem value={NotificationTypeEnum.IntegratedSchedule}>Integrated Schedule</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item style={{ minHeight: '60vh', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={15}
                        rowsPerPageOptions={[5]}
                        autoHeight
                        getRowId={(row) => row.Id}
                        getRowHeight={() => 'auto'}
                        getRowClassName={(params) => (params.row.IsRead ? 'read' : '')}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                            [`& .read`]: {
                                background: 'rgba(200,200,200,0.4)',
                                fontStyle: 'italic',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default NotificationsPage;
