import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    IconButton,
    Tooltip,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { parentUrl } from '../../../../utils/constants';
import { localDateAndTimeFormat } from '../../../../utils/timeFormat';
import { apiString as API_URL } from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import Authorization from '../../../../utils/Authorization';
import WidgetLoading from '../../Shared/WidgetLoading';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePeriodFilterEnum } from '../../../../Enum/TimePeriodFilterEnum';
import { VitalSignEnum } from '../../../../Enum/VitalSignEnum';

const TelemonitoringOutOfLimitsByUser = () => {
    const authData = new Authorization();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { teams } = useSelector((state: RootState) => state.teams);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [outOfLimits, setOutOfLimits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(VitalSignEnum.All);
    const [dateFilter, setDateFilter] = useState(TimePeriodFilterEnum.Last1Year);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [show, setShow] = useState(false);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const ref = useRef(null);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const dateFilterList = [
        { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
        { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
        { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
        { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
    ];

    const vitalSignsTypeList = [
        { Id: VitalSignEnum.All, Value: VitalSignEnum.AllDescription },
        { Id: VitalSignEnum.Glucometer, Value: VitalSignEnum.GlucometerDescription },
        { Id: VitalSignEnum.BloodPressure, Value: VitalSignEnum.BloodPressureDescription },
        { Id: VitalSignEnum.Oximeter, Value: VitalSignEnum.OximeterDescription },
        { Id: VitalSignEnum.Scale, Value: VitalSignEnum.ScaleDescription },
        { Id: VitalSignEnum.HeartRate, Value: VitalSignEnum.HeartRateDescription },
    ];

    const handleClick = (event) => {
        setShow(!show);
    };

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/performance`, '_blank');
    };

    let icon;
    const getDeviceType = (member) => {
        if (member.Weight !== null) {
            icon = member.Weight + ' lbs';
            return 'Scale';
        }
        if (member.PulseOxSpO2Last !== null) {
            icon = member.PulseOxSpO2Last + ' %';
            return 'Oximeter';
        }
        if (member.BpSystolic !== null) {
            icon = member.BpSystolic + ' / ' + member.BpDiastolic;
            return 'BP';
        }
        if (member.GlucoseMgDl !== null) {
            icon = member.GlucoseMgDl + ' mg/dL';
            return 'Glucometer';
        }
    };

    useEffect(() => {
        if (dateFilter !== TimePeriodFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate))) {
            const changeFilter = async () => {
                let start = fromDate !== null ? new Date(fromDate).toDateString() : null;
                let end = toDate !== null ? new Date(toDate).toDateString() : null;
                const response = await axios.get(
                    `${API_URL}/vitalsigns/getvitalsignswithoutoflimit?teamId=${selectedTeam}&type=${type}&dateFilter=${dateFilter}&dateFrom=${start}&dateTo=${end}&includeInActiveTeams=${showInActiveTeams}`
                );
                setOutOfLimits(response.data);
                setIsLoading(false);
            };
            setIsLoading(true);
            if ((fromDate !== null || toDate !== null) && toDate < fromDate) {
            } else {
                changeFilter();
            }
        }
    }, [selectedTeam, type, dateFilter, fromDate, toDate, showInActiveTeams]);

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setFromDate(startDate);
        }
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                                Telemonitoring Out of Limits by User
                            </Typography>
                            <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                                &#40;{outOfLimits.length}&#41;
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">
                                                Active members whose vital signs are not within their minimum and maximum limit
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        {showProviders && (
                            <Grid item xs={4}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Team</InputLabel>
                                    <Select
                                        value={selectedTeam}
                                        label="Team"
                                        name="Team"
                                        onChange={(e) => setSelectedTeam(e.target.value)}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                            label="Show Inactive"
                                        />
                                        {filteredTeams.map((team) => (
                                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                                {team.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Filter By</InputLabel>
                                <Select value={type} label="Filter By" name="Filter By" onChange={(e) => setType(parseInt(e.target.value as any))}>
                                    {vitalSignsTypeList.map((ty) => (
                                        <MenuItem key={ty.Id} value={ty.Id}>
                                            {ty.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select value={dateFilter} label="Date Filter" name="Date" onChange={(e) => setDateFilter(parseInt(e.target.value as any))}>
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {dateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item xs={3}>
                                    <DesktopDatePicker
                                        className="form-control-200"
                                        label="From Date"
                                        value={Boolean(fromDate) ? fromDate : null}
                                        minDate={new Date('2019-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DesktopDatePicker
                                        className="form-control-200"
                                        label="To Date"
                                        value={Boolean(toDate) ? toDate : null}
                                        minDate={new Date('2019-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                    <Grid container spacing={2} marginTop={2} height={showProviders ? '50vh' : '57vh'} sx={{ overflowY: 'scroll', position: 'relative' }}>
                        {isLoading && <WidgetLoading />}
                        {outOfLimits.length > 0 ? (
                            outOfLimits.map((member) => {
                                return (
                                    <Grid key={member.Id} item xs={6}>
                                        <Card
                                            sx={{
                                                ':hover': {
                                                    cursor: 'pointer',
                                                    boxShadow: 10,
                                                    border: 0,
                                                },
                                                boxShadow: 0,
                                            }}
                                            onClick={() => openDocumentHandler(member.MemberId)}
                                            variant="outlined"
                                        >
                                            <CardContent
                                                sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-between' }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Time
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {localDateAndTimeFormat(new Date(member.RecordingDate))}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Device
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {getDeviceType(member)}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Member
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {member.MemberName}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Box sx={{ background: '#d32f2f', color: 'white' }} p={1} width="100px" textAlign="center">
                                                            <Typography variant="body2" fontWeight="bold">
                                                                {icon}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h6">No Matching Records</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default TelemonitoringOutOfLimitsByUser;
