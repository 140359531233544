import BaseEntity from '../BaseEntity.model';
import CreatedBy from './../Shared/LastUpdatedBy.model';

export default interface ITransitionalCareCheckList extends BaseEntity {
    MedicationReviewed: string;
    DischargeOrdersReviewed: string;
    AppointmentPostDCScheduled: string;
    MedicalEquipmentOrHHOrders: string;
    FeelingBetterScale: string;
    Notes: string;
    CreatedDate: Date | null;
    CreatedBy: CreatedBy | null;
}

export class TransitionalCareCheckList implements ITransitionalCareCheckList {
    Id: string;
    MedicationReviewed: string;
    DischargeOrdersReviewed: string;
    AppointmentPostDCScheduled: string;
    MedicalEquipmentOrHHOrders: string;
    FeelingBetterScale: string;
    Notes: string;
    CreatedDate: Date | null;
    CreatedBy: CreatedBy | null;

    constructor() {
        this.Id = '';
        this.MedicationReviewed = '';
        this.DischargeOrdersReviewed = '';
        this.AppointmentPostDCScheduled = '';
        this.MedicalEquipmentOrHHOrders = '';
        this.FeelingBetterScale = '';
        this.Notes = '';
        this.CreatedDate = null;
        this.CreatedBy = null;
    }
}
