import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { setCurrentContact } from '../../store/adminHealthPlan.slice';
import TextMaskCustom from '../Shared/PhoneMask';

const BasicContactInfoDialog = (props) => {
    // props are: currentContact={} open={} onClose={} onSave={}
    const { contactInfoTitle } = useSelector((state: RootState) => state.adminHealthPlanSlice);
    const { currentContact } = props;
    const dispatch = useDispatch();

    return (
        <Dialog open={props.open} onClose={props.onClose} disableEnforceFocus>
            <DialogContent>
                <FormGroup>
                    <Grid container spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                        <Grid item xs={12}>
                            <Typography marginBottom={2} variant="h6">
                                {currentContact?.Id.length > 0 ? 'Edit ' : 'Add '}
                                {contactInfoTitle}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    value={currentContact.Name || ''}
                                    helperText="Required"
                                    label="Name"
                                    name="Name"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => dispatch(setCurrentContact({ ...currentContact, Name: e.target.value }))}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel required htmlFor="formatted-text-mask-input">
                                    Phone Number
                                </InputLabel>
                                <OutlinedInput
                                    value={currentContact.PhoneNumber || ''}
                                    onChange={(e) => dispatch(setCurrentContact({ ...currentContact, PhoneNumber: e.target.value }))}
                                    name="PhoneNumber"
                                    required
                                    label="Phone Number"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                                <FormHelperText>Required</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    value={currentContact.Address || ''}
                                    label="Address"
                                    name="Address"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => dispatch(setCurrentContact({ ...currentContact, Address: e.target.value }))}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    value={currentContact.Email || ''}
                                    helperText="Required"
                                    label="Email"
                                    name="Email"
                                    type="email "
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => dispatch(setCurrentContact({ ...currentContact, Email: e.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={currentContact.Name?.length === 0 || currentContact.PhoneNumber?.length === 0 || currentContact.Email?.length === 0}
                    className="button-120"
                    variant="contained"
                    onClick={() => props.onSave()}
                >
                    Save
                </Button>
                <Button className="button-120" variant="outlined" onClick={props.onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BasicContactInfoDialog;
