import BaseEntity from '../BaseEntity.model';

export default interface IGoal extends BaseEntity {
    Name: string;
}

export class Progress implements IGoal {
    Id: string | null;
    Name: string;

    constructor() {
        this.Id = null;
        this.Name = '';
    }
}
