import { FactorRiskAssessmentLookupTypeEnum } from '../../Enum/FactorRiskAssessmentLookupTypeEnum';

export default interface IFactorAndRiskAssessmentItem {
    MemberReports: string;
    ProviderAssessed: string;
    MemberOption: FactorRiskAssessmentLookupTypeEnum;
    ProviderOption: FactorRiskAssessmentLookupTypeEnum;
    Name: string;
    IsNA: boolean;
    MemberReportsIncludedInCarePlan: boolean;
    ProviderAssessedIncludedInCarePlan: boolean;
}

export class FactorAndRiskAssessmentItem implements IFactorAndRiskAssessmentItem {
    MemberReports: string;
    ProviderAssessed: string;
    MemberOption: FactorRiskAssessmentLookupTypeEnum;
    ProviderOption: FactorRiskAssessmentLookupTypeEnum;
    Name: string;
    IsNA: boolean;
    MemberReportsIncludedInCarePlan: boolean;
    ProviderAssessedIncludedInCarePlan: boolean;

    constructor() {
        this.MemberReports = '';
        this.ProviderAssessed = '';
        this.Name = '';
        this.IsNA = false;
        this.MemberReportsIncludedInCarePlan = false;
        this.ProviderAssessedIncludedInCarePlan = false;
    }
}
