import { Badge, Box, Button, CircularProgress, Divider, IconButton, Paper, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { parentUrl } from '../../../../utils/constants';
import { useEffect, useState } from 'react';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from 'react-redux';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { RootState } from '../../../../reducers';
import Authorization from '../../../../utils/Authorization';

import moment from 'moment';
import {
    getAllScheduleNotifications,
    getNotificationsByType,
    getNotificationsByTypeCount,
    getNotificationsCount,
    getSiteNotifications,
    getUnreadCount,
    getVitalSigns,
    getVitalSignsCount,
    markAllNotificationsAsRead,
    setNotificationTitle,
    setNotifications,
} from '../../../../store/providerNavbar.slice';
import { markNotificationAsRead } from '../../../../store/notificationsPage.slice';
import { NotificationTypeEnum } from '../../../../Enum/NotificationTypeEnum';

const StandardNotificationTemplate = (notification, navigateToPage, markAsReadHandler) => {
    return (
        <Paper elevation={3} key={notification.Id} sx={{ p: 2, m: 1, paddingBottom: 0 }}>
            <Typography onClick={() => navigateToPage(notification)} variant="body2">
                {notification.Message}
            </Typography>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="caption" fontStyle={'italic'} fontWeight={'bold'}>
                    {moment(notification.CreatedTime).fromNow()}
                </Typography>
                <Tooltip title="Mark as read">
                    <IconButton sx={{ marginLeft: 'auto' }} onClick={() => markAsReadHandler(notification.Id)}>
                        <MarkEmailReadIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Paper>
    );
};

const NotificationsComponents = () => {
    const [path, setPath] = useState<string>('');
    const [authData, setAuthData] = useState(new Authorization());

    const dispatch = useDispatch();
    const { isFetching, notifications, notificationsByTypeCount, notificationsCount, notificationTitle, unreadCount, vitalSignsCount } = useSelector(
        (state: RootState) => state.providerNavbar
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const requestsOpen = Boolean(anchorEl);
    const requestId = requestsOpen ? 'simple-popover' : undefined;

    const handleNotificationsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleNotificationsClose = () => {
        setAnchorEl(null);
    };

    const viewNotificationsHandler = (e, value) => {
        handleNotificationsClick(e);
        switch (value) {
            case 'getnotificationsbytype':
                dispatch(getNotificationsByType(authData?.UserId, 2));
                setPath('requests');
                dispatch(setNotificationTitle('Request Notifications'));
                break;
            case 'getprovidernotifications':
                dispatch(getSiteNotifications(authData?.UserId));
                setPath('myschedule');
                dispatch(setNotificationTitle('Site Notifications'));
                break;
            case 'getallvitalsigns':
                dispatch(getVitalSigns());
                setPath('vital-signs');
                dispatch(setNotificationTitle('Telemonitoring (Last 24 hours)'));
                break;
            case 'getall':
                dispatch(getAllScheduleNotifications(false));
                dispatch(setNotificationTitle('Schedule Notifications'));
                break;
            default:
                break;
        }
    };

    const navigateToNotifications = () => {
        window.open(`${parentUrl}provider/notifications`, '_blank');
        handleNotificationsClose();
    };

    const navigateToPage = (notification) => {
        let url = ``;
        if (notification.NotificationType !== NotificationTypeEnum.Schedule && notification.NotificationType !== NotificationTypeEnum.Request) {
            url = `${parentUrl}provider/members-list/member-chart/${notification.MemberId}/vital-signs`;
        } else {
            url = `${parentUrl}provider/${path}`;
        }
        window.open(url, '_blank');
    };

    const VitalSignsResultsTemplate = (notification) => {
        let reading = null;
        let device = '';
        if (notification.BpSystolic) {
            reading = (
                <>
                    <Typography variant="subtitle2">{notification.BpSystolic}</Typography>
                    <Divider sx={{ border: '1px solid #222', width: '80%' }} />
                    <Typography variant="subtitle2">{notification.BpDiastolic}</Typography>
                </>
            );
            device = 'Blood Pressure';
        }

        if (notification.BpPulse) {
            reading = <Typography variant="subtitle2">{notification.BpPulse}</Typography>;
            device = 'Heart Rate';
        }
        if (notification.Weight) {
            reading = <Typography variant="subtitle2">{notification.Weight ? notification.Weight + ' lbs' : 'N/A'}</Typography>;
            device = 'Weight';
        }
        if (notification.GlucoseMgDl) {
            reading = <Typography variant="subtitle2">{notification.GlucoseMgDl} mg/dL</Typography>;
            device = 'Glucometer';
        }
        if (notification.PulseOxSpO2Last) {
            reading = <Typography variant="subtitle2">{notification.PulseOxSpO2Last}%</Typography>;
            device = 'Oximeter';
        }
        return (
            <Paper elevation={3} key={notification.Id} sx={{ p: 2, m: 1, display: 'flex', alignItems: 'center' }} onClick={() => navigateToPage(notification)}>
                <Box p={2} marginX={2} color={notification.Status === 1 ? 'success.main' : 'error.main'}>
                    {reading}
                </Box>
                <Stack width={'100%'}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Name
                        </Typography>
                        <Typography variant="caption" fontWeight={'bold'}>
                            {notification.MemberName}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Device
                        </Typography>
                        <Typography variant="caption" fontWeight={'bold'}>
                            {device}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Date/Time
                        </Typography>
                        <Typography variant="caption" fontWeight={'bold'}>
                            {moment(notification.UpdatedDate).format('MM/DD/YYYY @ h:mm a')}
                        </Typography>
                    </Stack>
                </Stack>
            </Paper>
        );
    };

    const markAsReadHandler = (id) => {
        const filteredNotifications = notifications.filter((notification) => notification.Id !== id);
        dispatch(setNotifications(filteredNotifications));
        dispatch(markNotificationAsRead(id));
    };

    const markAllNotificationsAsReadHandler = () => {
        dispatch(markAllNotificationsAsRead(authData.UserId));
    };

    useEffect(() => {
        if (authData.UserId) {
            dispatch(getNotificationsByTypeCount(authData.UserId, 2));
            dispatch(getNotificationsCount(authData.UserId));
            dispatch(getVitalSignsCount());
            dispatch(getUnreadCount());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData]);

    useEffect(() => {
        setAuthData(new Authorization());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack direction="row" alignItems={'center'} spacing={1} sx={{ '.MuiIconButton-root': { borderRadius: 0 }, '.MuiSvgIcon-root': { width: '20px' } }}>
            <Typography variant="body2" textAlign={'center'}>
                {authData.UserName}
            </Typography>

            <Tooltip title="Request Notifications">
                <IconButton sx={{ minWidth: '18px' }} onClick={(e) => viewNotificationsHandler(e, 'getnotificationsbytype')}>
                    <Badge max={10000} badgeContent={notificationsByTypeCount} color="error">
                        <EmailIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                id={requestId}
                open={requestsOpen}
                anchorEl={anchorEl}
                onClose={handleNotificationsClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px', maxHeight: '500px' }}>
                    {!isFetching && (
                        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2} p={1} sx={{ background: 'white' }}>
                            <Typography variant="body2" fontWeight={'bold'}>
                                {notificationTitle}
                            </Typography>
                            {notificationTitle === 'Site Notifications' && notifications.length > 0 && (
                                <Button size="small" style={{ fontSize: '12px' }} onClick={markAllNotificationsAsReadHandler}>
                                    Mark all as read
                                </Button>
                            )}
                        </Stack>
                    )}
                    {isFetching && (
                        <Box sx={{ display: 'flex', p: 5, marginX: 'auto' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {!isFetching && notifications.length > 0 && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '400px', overflowY: 'auto' }}>
                            {notifications.map((notification) => {
                                return notificationTitle === 'Telemonitoring (Last 24 hours)'
                                    ? VitalSignsResultsTemplate(notification)
                                    : StandardNotificationTemplate(notification, navigateToPage, markAsReadHandler);
                            })}
                        </Box>
                    )}
                    {!isFetching && notifications.length === 0 && (
                        <Box sx={{ display: 'flex', p: 2, marginX: 'auto' }}>
                            <Typography variant="body2">No Unread Notifications</Typography>
                        </Box>
                    )}

                    {!isFetching && notificationTitle === 'Site Notifications' && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                            <Button size="small" style={{ fontSize: '12px' }} onClick={navigateToNotifications}>
                                See All Notifications
                            </Button>
                        </Box>
                    )}
                </Box>
            </Popover>

            <Tooltip title="Site Notifications">
                <IconButton sx={{ minWidth: '24px' }} size="small" onClick={(e) => viewNotificationsHandler(e, 'getprovidernotifications')}>
                    <Badge max={10000} badgeContent={notificationsCount} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>

            {authData?.isInPermission('Manage Telemonitoring') && (
                <Tooltip title="Telemonitoring (Last 24 hours)">
                    <IconButton sx={{ minWidth: '24px' }} size="small" onClick={(e) => viewNotificationsHandler(e, 'getallvitalsigns')}>
                        <Badge max={10000} badgeContent={vitalSignsCount} color="error">
                            <MonitorHeartIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
            )}

            {(authData?.isInPermission('View Integrated Care Plan') || authData?.isInPermission('Add / Edit Integrated Care Plan')) && (
                <Tooltip title="Schedule Notifications">
                    <IconButton sx={{ minWidth: '24px' }} size="small" onClick={(e) => viewNotificationsHandler(e, 'getall')}>
                        <Badge max={10000} badgeContent={unreadCount} color="error">
                            <CalendarMonthIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
};

export default NotificationsComponents;
