import { Grid, Typography } from '@mui/material';
import { DataGrid, GridCellParams, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { monthNames } from '../../../../utils/constants';
import { useEffect } from 'react';
import { getMemberClaims, getMemberLast10Visits, getStatusColor, getVisitType } from '../../../../store/performanceDashboard.slice';

import { localDateFormat } from '../../../../utils/timeFormat';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { isDefaultDate } from '../../../../utils/common';

const NumberOfVisits = (props) => {
    const dispatch = useDispatch();
    const { memberClaims, last10Visits } = useSelector((state: RootState) => state.performanceDashboard);

    const last10VisitsColumns: GridColDef[] = [
        {
            field: 'ClaimDate',
            headerName: 'Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.ClaimDate) ? '-' : localDateFormat(new Date(params.row.ClaimDate))}`,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${getVisitType(params.row.VisitType)}`,
        },
        {
            field: 'Facility',
            headerName: 'Facility',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.Facility || '-'}`,
        },
    ];

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: '',
            flex: 1,
        },
        {
            field: 'ThisMonth',
            headerName: `${monthNames[new Date().getMonth()]} (TY)`,
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row.ThisMonth, params.row.ThisMonthLastYear),
        },
        {
            field: 'ThisMonthLastYear',
            headerName: `${monthNames[new Date().getMonth()]} (LY)`,
            flex: 1,
        },
        {
            field: 'ThisMonthCHPMedian',
            headerName: `${monthNames[new Date().getMonth()]} (CHP Median)`,
            flex: 1,
        },
        {
            field: 'ThisQuarter',
            headerName: 'This Quarter (TY)',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row.ThisQuarter, params.row.ThisQuarterLastYear),
        },
        {
            field: 'ThisQuarterLastYear',
            headerName: 'This Quarter (LY)',
            flex: 1,
        },
        {
            field: 'ThisQuarterCHPMedian',
            headerName: 'This Quarter (CHP Median)',
            flex: 1,
        },
        {
            field: 'PerformYear',
            headerName: 'Perform Year',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row.PerformYear, params.row.BaselineYear),
        },
        {
            field: 'BaselineYear',
            headerName: 'Baseline Year',
            flex: 1,
        },
        {
            field: 'PerformCHPMedian',
            headerName: 'Perform (CHP Median)',
            flex: 1,
        },
    ];

    useEffect(() => {
        if (props.memberId) {
            dispatch(getMemberClaims(props.memberId));
            dispatch(getMemberLast10Visits(props.memberId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.memberId]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography display={'inline-block'} variant="h6" marginBottom={1} color={'primary'}>
                    Number of Visits
                </Typography>
            </Grid>

            <Grid item style={{ width: '100%' }} marginBottom={2}>
                <DataGrid
                    rows={memberClaims.MemberClaimVisit}
                    columns={columns}
                    autoHeight
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => Math.random()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                        [`& .green`]: {
                            color: 'white',
                            background: 'green',
                        },
                        [`& .red`]: {
                            color: 'white',
                            background: 'red',
                        },
                        [`& .black`]: {
                            color: 'rgba(0,0,0,0.87)',
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography display={'inline-block'} variant="h6" marginBottom={1} color={'primary'}>
                    Last 10 Visits
                </Typography>
            </Grid>

            <Grid item style={{ width: '100%' }}>
                <DataGrid
                    rows={last10Visits}
                    columns={last10VisitsColumns}
                    autoHeight
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => Math.random()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default NumberOfVisits;
