import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Button,
    Box,
    CircularProgress,
    Stack,
    Typography,
    Checkbox,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { copyObject } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import classes from '../Styles';
import { fetchPreventativeHealthAdviceById, fetchTobaccoAndDrugAssessmentById, savePreventativeHealthAdvice } from '../../../store/hra.slice';
import { GenderEnum } from '../../../Enum/GenderEnum';

const PreventativeHealthAdviceAssessment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const { preventativeHealthAdvice, tobaccoAndDrug, isFetching, isSaving: isSavingHra } = useSelector((state: RootState) => state.hra);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingHra || isSavingEngagementDraft;

    const memberAge = Boolean(memberBasicInfo.DOB) ? moment().diff(memberBasicInfo.DOB, 'years', false) : 0;

    useEffect(() => {
        if (Boolean(engagementDraft.PreventativeHealthAdviceId)) {
            dispatch(fetchPreventativeHealthAdviceById(engagementDraft.PreventativeHealthAdviceId));
        }
        if (Boolean(engagementDraft.TobaccoAndDrugAssessmentId) && !Boolean(tobaccoAndDrug?.Id)) {
            dispatch(fetchTobaccoAndDrugAssessmentById(engagementDraft.TobaccoAndDrugAssessmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(savePreventativeHealthAdvice({ ...preventativeHealthAdvice, Status: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeAnswer = (answer: any, propName: string) => {
        let data = copyObject(preventativeHealthAdvice);
        data[propName] = answer;
        data.MemberId = memberId;

        dispatch(savePreventativeHealthAdvice(data));
    };

    const prepareHealthTopics = () => {
        let topics = [];
        if (memberAge >= 50 && memberAge <= 74 && memberBasicInfo.Gender === GenderEnum.FemaleDescription) {
            topics.push({
                topic: 'Breast Cancer Screening',
                recommendation: 'The USPSTF recommends biennial screening mammography for women aged 50 to 74 years.',
                checked: preventativeHealthAdvice.BreastCancerScreering,
                name: 'BreastCancerScreering',
            });
        }

        if (memberAge >= 21 && memberAge <= 65 && memberBasicInfo.Gender === GenderEnum.FemaleDescription) {
            topics.push({
                topic: 'Cervical Cancer Screening',
                recommendation:
                    'The USPSTF recommends screening for cervical cancer every 3 years with cervical cytology alone in women aged 21 to 29 years. For women agend 30 to 65 years, the USPSTF recommends screening every 3 years with cervical cytology alone, every 5 years with high-risk human papillomavirus (hrHPV) testing alone, or every 5 years with hrHPV testing in combination with cytology (cotesting).',
                checked: preventativeHealthAdvice.CervicalCancerScreening,
                name: 'CervicalCancerScreening',
            });
        }

        if (memberAge >= 45 && memberAge <= 75) {
            topics.push({
                topic: 'Colorectal Cancer Screening',
                recommendation: 'The USPSTF recommends screening for colorectal cancer in all adults aged 45 to 75 years.',
                checked: preventativeHealthAdvice.ColorectalCancerScreening,
                name: 'ColorectalCancerScreening',
            });
        }

        if (memberAge >= 18) {
            topics.push({
                topic: 'Hypertension',
                recommendation: 'The USPSTF recommends screening for hypertension in adults 18 years or older with office blood pressure measurement (OBPM).',
                checked: preventativeHealthAdvice.Hypertension,
                name: 'Hypertension',
            });
        }

        if (memberAge >= 50 && memberAge <= 80 && tobaccoAndDrug?.TobaccoAssessment?.TobaccoUseInYears >= 20) {
            topics.push({
                topic: 'Lung Cancer Screening',
                recommendation:
                    'The USPSTF recommends annual screening for lung cancer with low-dose computed tomography (LDCT) in adults aged 50 to 80 years who have a 20 pack-year smoking history and currently smoke or have quit within the past 15 years.',
                checked: preventativeHealthAdvice.LungCancerScreening,
                name: 'LungCancerScreening',
            });
        }

        if (memberAge >= 65) {
            topics.push({
                topic: 'Osteoporosis Screening',
                recommendation:
                    'The USPSTF recommends screening for osteoporosis with bone measurement testing to prevent osteoporotic fractures in women 65 years',
                checked: preventativeHealthAdvice.OsteoporosisScreening,
                name: 'OsteoporosisScreening',
            });
        }

        if (Boolean(tobaccoAndDrug?.AuditAssessment?.Score) && tobaccoAndDrug.AuditAssessment.Score <= 7) {
            topics.push({
                topic: 'Alcohol Use',
                recommendation: 'Education on risks of drinking with a harm reduction approach',
                checked: preventativeHealthAdvice.DrinkingRisk,
                name: 'DrinkingRisk',
            });
        }

        return topics;
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" sx={{ pt: 4 }}>
            {!Boolean(memberAge) ? (
                <Stack alignItems="center" sx={{ pb: 4 }}>
                    <Typography variant="body1" component="label" color="red">
                        Please set DOB for this member before taking this assessment
                    </Typography>
                </Stack>
            ) : isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form" sx={{ pt: 2 }}>
                        <Typography variant="subtitle1" component="label" sx={{ pb: 3 }}>
                            Please select the recommended screenings for this member
                        </Typography>
                        <TableContainer component={Paper} sx={classes.budgetFormTable}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} align="center" width="40%">
                                            <Typography variant="subtitle1" component="label">
                                                <strong>Health Topic</strong>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" width="60%">
                                            <Typography variant="subtitle1" component="label">
                                                <strong>Recommendation</strong>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prepareHealthTopics().map((item, index) => (
                                        <TableRow key={`health-topic-${index}`} hover>
                                            <TableCell align="center">
                                                <Checkbox
                                                    name={item.name}
                                                    checked={item.checked}
                                                    onChange={(e) => handleOnChangeAnswer(e.target.checked, e.target.name)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1" component="label">
                                                    <strong>{item.topic}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1" component="label">
                                                    {item.recommendation}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {Boolean(engagementDraft.PreventativeHealthAdviceId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Preventative Health Advice"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Preventative health advice is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Preventative Health Advice"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Preventative health advice?"
                />
            )}
        </Grid>
    );
};

export default PreventativeHealthAdviceAssessment;
