import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Shared/Loading';
import { RootState } from '../../reducers';
import {
    openActivityNoteHandler,
    addToCallListHandler,
    getOnboarderStatus,
    setCallItem,
    setSelectedPhone,
    setOnboarderNote,
    activityOptions,
    getOnboarderSettings,
    getOnboardingConfirmations,
    setSelectedCallListTab,
    CallListTabs,
    setConfirmationCallItem,
    returnSelectedPhone,
    setNumberOfDays,
    setShowAppointmentIsConfirmed,
    setSelectedNoteTab,
    editNoteHandler,
    checkAppointmentStatus,
    setCallItemConfirmations,
    fetchOnboarderStatuses,
} from '../../store/onboardingWorkflow.slice';
import Authorization from '../../utils/Authorization';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import { isDefaultDate } from '../../utils/common';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import AddToListModal from './Modals/AddToListModal';
import AddActivityNoteModal from './Modals/AddActivityNoteModal';
import OnboarderNote from '../../Models/OnboardingWorkflow/OnboarderNote.model';
import ChangeStatusModal from './Modals/ChangeStatusModal';
import CallItemInfo from './MemberDemographicsInfo/CallItemInfo';
import CallItemActions from './CallItemActions/CallItemActions';
import { OnboardingMeansOfEngagements } from '../../utils/mappings';
import DemographicsRequest from './DemographicsRequest/DemographicsRequest';
import { getActiveProviders } from '../../store/provider.slice';
import ScheduleAppointment from './Modals/ScheduleAppointment';
import CallItemConfirmationInfo from './MemberDemographicsInfo/CallItemConfirmationInfo';
import CallItem from '../../Models/OnboardingWorkflow/CallItem.model';
import OtherMemberChartsModal from './Modals/OtherMemberChartsModal';
import AddPhoneModal from './Modals/AddPhoneModal';
import ConfirmSkipCallModal from './Modals/ConfirmSkipCallModal';
import { fetchPopulationList } from '../../store/adminMembers.slice';
import InfoIcon from '@mui/icons-material/Info';
import HelpPage from '../../assets/documents/Onboarding_Call_Queue.pdf';

const OnboardingWorkflow = (props) => {
    const dispatch = useDispatch();
    const auth = new Authorization();
    const {
        callItem,
        callItemConfirmations,
        confirmationCallItem,
        gettingStatus,
        numberOfDays,
        onboarderSettings,
        onboarderStatus,
        scheduledCount,
        selectedCallListTab,
        selectedNoteTab,
        showAppointmentIsConfirmed,
        todaysCallCount,
    } = useSelector((state: RootState) => state.onboardingWorkflowSlice);

    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    const item = isConfirmCall ? confirmationCallItem : callItem;

    const handleNoteTabChange = (event: React.SyntheticEvent, newValue: string) => {
        dispatch(setSelectedNoteTab(newValue));
    };

    const handleCallListTabChange = (event: React.SyntheticEvent, newValue: CallListTabs) => {
        dispatch(setSelectedCallListTab(newValue));
        if (newValue === CallListTabs.Confirmations) {
            dispatch(getOnboardingConfirmations(numberOfDays));
        }
        const item = newValue === CallListTabs.Confirmations ? confirmationCallItem : callItem;
        const selectedPhone = returnSelectedPhone(item?.Phones || []);
        dispatch(setSelectedPhone(selectedPhone));
    };

    const currentRows = item?.ActivityNotes?.map((note) => {
        return {
            Id: note.Id,
            CreatedDate: note.CreatedDate,
            NoteType: note.NoteType,
            Note: note.Note,
            UserName: note.UserName,
            Editable: note.Editable,
        };
    });

    const allNotesRows = item?.DocumentOnboardingNotes?.map((note) => {
        return {
            Id: note.Id,
            CreationTime: note.CreationTime,
            UpdationTime: note.UpdationTime,
            MeansOfEngagement: note.MeansOfEngagement,
            EngagedProvider: note.EngagedProvider,
            Notes: note.Notes,
        };
    });

    const callGoals = onboarderStatus?.CallsGoal > 0 ? onboarderStatus?.CallsGoal : onboarderSettings?.DefaultCallsGoal;

    const progress = (todaysCallCount / callGoals) * 100;

    const changeCallItemHandler = (call: CallItem) => {
        dispatch(setCallItem(call));
        dispatch(setSelectedPhone(returnSelectedPhone(call.Phones)));
    };

    const changeConfirmationCallItemHandler = (call: CallItem) => {
        /// to check if an appointment is confirmed already or not
        dispatch(checkAppointmentStatus(call.AppointmentId));
        dispatch(setConfirmationCallItem(call));
        dispatch(setSelectedPhone(returnSelectedPhone(call.Phones)));
    };

    const handleCloseAppointmentIsConfirmed = () => {
        const newCallItemConfirmations = callItemConfirmations.filter((item) => item.Id !== confirmationCallItem.Id);
        dispatch(setConfirmationCallItem(null));
        dispatch(setCallItemConfirmations(newCallItemConfirmations));
        dispatch(setShowAppointmentIsConfirmed(false));
    };

    const appointmentIsConfirmedBody = () => {
        return (
            <Stack spacing={2}>
                <Typography variant="body1" textAlign={'center'} marginBottom={2}>
                    This appointment has been confirmed by a provider. This item will be removed from the Confirmation call list.
                </Typography>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={handleCloseAppointmentIsConfirmed}>
                            Ok
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    const columns: GridColDef[] = [
        {
            field: 'NoteType',
            headerName: 'Activity Type',
            minWidth: 200,
        },
        {
            field: 'Note',
            headerName: 'Note',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'UserName',
            headerName: 'Provider',
            minWidth: 200,
        },
        {
            field: 'CreatedDate',
            headerName: 'Created Date',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CreatedDate) ? '-' : moment(params.row.CreatedDate).format('MM/DD/YYYY hh:mm A')}`,
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 160,
            renderCell: (params) => {
                return params.row.Editable && params.row.NoteType !== activityOptions.SYSTEM_SCHEDULER ? (
                    <Tooltip title="Edit" placement="left">
                        <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => dispatch(editNoteHandler(params.row))} />
                    </Tooltip>
                ) : null;
            },
        },
    ];

    const allNotesColumns: GridColDef[] = [
        {
            field: 'MeansOfEngagement',
            headerName: 'Means Of Engagement',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${OnboardingMeansOfEngagements.find((m) => m.value === params.row.MeansOfEngagement)?.label || ''}`,
        },
        {
            field: 'EngagedProvider',
            headerName: 'Engaged Provider',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => `${params.row.EngagedProvider?.Name || ''}`,
        },
        {
            field: 'UpdationTime',
            headerName: 'Engagement Date',
            minWidth: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.UpdationTime) ? '-' : moment(params.row.UpdationTime).format('MM/DD/YYYY hh:mm A')}`,
        },
        {
            field: 'CreationTime',
            headerName: 'Created Date',
            minWidth: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CreationTime) ? '-' : moment(params.row.CreationTime).format('MM/DD/YYYY hh:mm A')}`,
        },
        {
            field: 'Notes',
            headerName: 'Notes',
            minWidth: 300,
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, any, any>) => <div style={{ whiteSpace: 'break-spaces' }}>{params.value}</div>,
        },
    ];

    const returnNameColor = (call) => {
        const activityNotesWithMemberReached = call.ActivityNotes.filter((note) => note.NoteType === activityOptions.ATTEMPTED_CALL_MEMBER_REACHED);
        const color = activityNotesWithMemberReached.length > 0 ? '#1976d2' : '';
        return color;
    };

    const getClassName = (params) => {
        return params.NoteType === activityOptions.ATTEMPTED_CALL_MEMBER_REACHED
            ? 'IsReached'
            : params.NoteType === activityOptions.SKIPPED_CALL
            ? 'IsSkipped'
            : '';
    };

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 70 : 80) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getActiveProviders(false));
        dispatch(getOnboarderSettings());
        dispatch(fetchPopulationList());
        dispatch(fetchOnboarderStatuses());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getOnboarderStatus(auth.UserId));
    }, [dispatch, auth.UserId]);

    return (
        <Box>
            {<Loading message={'Loading...'} />}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={gettingStatus}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={showAppointmentIsConfirmed}
                onClose={handleCloseAppointmentIsConfirmed}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="sm"
            >
                <DialogTitle id="modal-modal-title">Appointment already confirmed.</DialogTitle>
                <DialogContent>{appointmentIsConfirmedBody()}</DialogContent>
            </Dialog>
            <AddToListModal />
            <AddActivityNoteModal />
            <AddPhoneModal />
            <ChangeStatusModal />
            <ConfirmSkipCallModal />
            <DemographicsRequest lockedMemberOption={{ MemberId: item?.MemberId, MemberName: `${item?.FirstName} ${item?.LastName}` }} />
            <OtherMemberChartsModal />
            <ScheduleAppointment />

            <Grid container justifyContent={'flex-end'} spacing={2} style={{ padding: '16px 16px 16px 16px' }}>
                {onboarderStatus.Id && (
                    <Grid item xs={12} p={2} paddingBottom={0}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress sx={{ height: '20px' }} variant="determinate" value={Math.round(progress)} />
                            </Box>
                            <Box sx={{ minWidth: 70 }}>
                                <Typography variant="body1" fontWeight="bold" color="text.secondary">{`${todaysCallCount} / ${callGoals}`}</Typography>
                            </Box>
                        </Box>
                        <Typography variant="body1">Call Progress</Typography>
                    </Grid>
                )}
                {onboarderStatus.Id && (
                    <Grid item xs={12} lg={3} xl={2}>
                        <Tabs value={selectedCallListTab} onChange={handleCallListTabChange} variant="fullWidth">
                            <Tab label="Call List" value={CallListTabs.CallList} />
                            <Tab label="Confirmation" value={CallListTabs.Confirmations} />
                        </Tabs>
                        {selectedCallListTab === CallListTabs.CallList && (
                            <Paper elevation={3} style={{ padding: '16px 16px 16px 16px' }}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1}>
                                    <Typography variant="body1" fontWeight={700}>
                                        Call List:
                                    </Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body1">
                                        {onboarderStatus?.CallList?.length}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1}>
                                    <Typography variant="body1" fontWeight={700}>
                                        Scheduled:
                                    </Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body1">
                                        {scheduledCount || 0}
                                    </Typography>
                                </Stack>
                                <Button variant="contained" color="primary" fullWidth sx={{ marginY: 2 }} onClick={() => dispatch(addToCallListHandler())}>
                                    Add to Call List
                                </Button>
                                <Divider />
                                {onboarderStatus?.CallList?.length > 0 ? (
                                    <List style={{ overflow: 'auto', maxHeight: '65vh' }}>
                                        {onboarderStatus?.CallList?.map((call, index) => {
                                            const color = returnNameColor(call);
                                            return (
                                                <ListItemButton key={index} onClick={() => changeCallItemHandler(call)} selected={callItem?.Id === call.Id}>
                                                    <ListItemText primary={`${call.LastName}, ${call.FirstName}`} sx={{ color: color }} />
                                                </ListItemButton>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    <>
                                        <Typography variant="body1" textAlign={'center'} component="h6" marginTop={2}>
                                            Either a population has been completely worked for the <em>day</em>, or a population isn't in an open time range.
                                        </Typography>
                                        <Typography variant="body1" textAlign="center">
                                            Please contact your administrator to be sure.
                                        </Typography>
                                    </>
                                )}
                            </Paper>
                        )}
                        {selectedCallListTab === CallListTabs.Confirmations && (
                            <Paper elevation={3} style={{ padding: '16px 16px 16px 16px' }}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginY={2}>
                                    <Typography variant="body1" fontWeight={700}>
                                        # of Appointments:
                                    </Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body1">
                                        {callItemConfirmations?.length}
                                    </Typography>
                                </Stack>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <InputLabel id="demo-simple-select-label">Timeframe</InputLabel>
                                    <Select
                                        size="small"
                                        label="Timeframe"
                                        value={numberOfDays}
                                        onChange={(e) => {
                                            dispatch(setNumberOfDays(e.target.value as number));
                                            dispatch(getOnboardingConfirmations(e.target.value as number));
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem value={2}>Next 2 Days</MenuItem>
                                        <MenuItem value={5}>Next 5 Days</MenuItem>
                                        <MenuItem value={7}>Next 7 Days</MenuItem>
                                    </Select>
                                </FormControl>
                                <Divider />
                                {callItemConfirmations?.length > 0 ? (
                                    <List style={{ overflow: 'auto', maxHeight: '65vh' }}>
                                        {callItemConfirmations?.map((item, index) => {
                                            return (
                                                <ListItemButton
                                                    key={index}
                                                    onClick={() => changeConfirmationCallItemHandler(item)}
                                                    selected={confirmationCallItem?.Id === item.Id}
                                                >
                                                    <ListItemText primary={`${item.LastName}, ${item.FirstName}`} />
                                                </ListItemButton>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    <Typography variant="body1" textAlign={'center'} component="h6" marginTop={2}>
                                        No appointments found.
                                    </Typography>
                                )}
                            </Paper>
                        )}
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    lg={9}
                    xl={10}
                    sx={{
                        '.MuiInputBase-input .IsDifferent': {
                            WebkitTextFillColor: '#007bff',
                            color: '#007bff',
                        },
                    }}
                >
                    {item?.Id?.length > 0 && (
                        <>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} marginBottom={2}>
                                <Typography variant="h6" textAlign={'center'} component="h6">
                                    Selected {selectedCallListTab === CallListTabs.Confirmations ? 'Confirm Appointment' : 'Onboarding Call'} Item
                                </Typography>
                                <Tooltip title="Onboarding Workflow Help Document">
                                    <InfoIcon
                                        sx={{ marginLeft: 1 }}
                                        color="primary"
                                        fontSize="small"
                                        cursor="pointer"
                                        onClick={() => window.open(`${HelpPage}`, '_blank')}
                                    />
                                </Tooltip>
                            </Stack>
                            <Paper elevation={3} style={{ padding: '16px 16px 16px 16px' }}>
                                {selectedCallListTab === CallListTabs.CallList && (
                                    <CallItemInfo callItem={callItem} confirmationCallItem={confirmationCallItem} selectedCallListTab={selectedCallListTab} />
                                )}
                                {selectedCallListTab === CallListTabs.Confirmations && <CallItemConfirmationInfo />}
                                <CallItemActions />

                                <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Tabs value={selectedNoteTab} onChange={handleNoteTabChange}>
                                        <Tab label="Activity Notes" value="current" />
                                        <Tab label="Recent Onboarding Notes" value="all" />
                                    </Tabs>
                                    <Button
                                        disabled={!item?.MemberId}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            dispatch(openActivityNoteHandler());
                                            dispatch(setOnboarderNote(new OnboarderNote()));
                                        }}
                                    >
                                        Add Activity Note
                                    </Button>
                                </Stack>

                                <div style={{ width: '100%', height: '47vh' }}>
                                    <DataGrid
                                        rows={
                                            selectedNoteTab === 'current'
                                                ? currentRows?.sort((a, b) => (a.CreatedDate > b.CreatedDate ? -1 : 1)) || []
                                                : allNotesRows?.sort((a, b) => (a.CreationTime > b.CreationTime ? -1 : 1)) || []
                                        }
                                        columns={selectedNoteTab === 'current' ? columns : allNotesColumns}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        getRowHeight={() => 'auto'}
                                        rowsPerPageOptions={[5]}
                                        getRowId={(row) => row.Id}
                                        getRowClassName={(params) => getClassName(params.row)}
                                        sx={{
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1,
                                            },
                                            [`& .IsReached`]: {
                                                color: '#1976d2',
                                            },
                                            [`& .IsSkipped`]: {
                                                color: '#d32f2f',
                                            },
                                        }}
                                    />
                                </div>
                            </Paper>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default OnboardingWorkflow;
