import { Box, Chip, FormControl, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { localDateFormat } from '../../utils/timeFormat';
import { isDefaultDate } from '../../utils/common';
import {
    approveMemberStatusChange,
    cancelMemberStatusChange,
    fetchMemberStatusChanges,
    fetchMemberStatuses,
    fetchStatusAwaitingApprovalCount,
    setMemberSearch,
    setShowMemberStatusChangeReadOnly,
} from '../../store/members.slice';
import { Fab, Paper, TextField, Tooltip } from '@mui/material';
import Loading from '../Shared/Loading';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import MemberStatusChange from './MemberStatusChange';

const tablesStyle = {
    height: '84vh',
    width: '100%',
};

const MembersStatusPage = () => {
    const dispatch = useDispatch();
    const [selectedStatusChange, setSelectedStatusChange] = useState({} as any);

    const approveHandler = (row) => {
        dispatch(approveMemberStatusChange(row));
    };

    const viewHandler = (row) => {
        setSelectedStatusChange(row);
        dispatch(setShowMemberStatusChangeReadOnly(row, true));
    };

    const cancelHandler = (row) => {
        dispatch(cancelMemberStatusChange(row));
    };

    const statusChangCell = (params: any) => {
        let currentFound = memberStatuses.find((x) => parseInt(x.value) === params.row.CurrentStatusDTO);
        currentFound = currentFound ? currentFound.label : params.row.CurrentStatusDTO;

        let futureFound = memberStatuses.find((x) => parseInt(x.value) === params.row.RequestedStatus);
        futureFound = futureFound ? futureFound.label : params.row.RequestedStatus;

        return (
            <Grid container spacing={2} alignItems="center" justifyItems={'center'}>
                <Grid item>
                    <Chip variant="outlined" size="small" color="primary" label={currentFound} />
                </Grid>
                <Grid item>
                    <ArrowForwardRounded />
                </Grid>
                <Grid item>
                    <Chip variant="outlined" size="small" color="primary" label={futureFound} />
                </Grid>
            </Grid>
        );
    };

    const awaitingColumns: GridColDef[] = [
        {
            field: 'EffectiveDate',
            headerName: 'Effective Date',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.EffectiveDate) ? '-' : localDateFormat(new Date(params.row.EffectiveDate))}`,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 300,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 200,
        },
        {
            field: 'CurrentStatusDTO',
            headerName: 'Status Change',
            minWidth: 300,
            flex: 1,
            renderCell: statusChangCell,
        },
        {
            field: 'Note',
            headerName: 'Note',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Approve" placement="left">
                            <CheckCircleIcon
                                color="success"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    approveHandler(params.row);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="View" placement="top">
                            <VisibilityIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    viewHandler(params.row);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Cancel" placement="right">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    cancelHandler(params.row);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const futureStatusColumns: GridColDef[] = [
        {
            field: 'EffectiveDate',
            headerName: 'Effective Date',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.EffectiveDate) ? '-' : localDateFormat(new Date(params.row.EffectiveDate))}`,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 300,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 300,
        },
        {
            field: 'CurrentStatusDTO',
            headerName: 'Status Change',
            minWidth: 300,
            flex: 1,
            renderCell: statusChangCell,
        },
        {
            field: 'Note',
            headerName: 'Note',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="View" placement="left">
                            <VisibilityIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    viewHandler(params.row);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Cancel" placement="right">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    cancelHandler(params.row);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const recentStatusColumns: GridColDef[] = [
        {
            field: 'EffectiveDate',
            headerName: 'Effective Date',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.EffectiveDate) ? '-' : localDateFormat(new Date(params.row.EffectiveDate))}`,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 200,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 200,
        },
        {
            field: 'ChangeDescription',
            headerName: 'Change',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'RequestedBy',
            headerName: 'Requested By',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.RequestedBy?.LastName + ', ' + params.row.RequestedBy?.FirstName,
        },
        {
            field: 'ApprovedBy',
            headerName: 'Approved By',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.ApprovedBy ? params.row.ApprovedBy?.LastName + ', ' + params.row.ApprovedBy?.FirstName : 'CHP Admin',
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="View" placement="top">
                            <VisibilityIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    viewHandler(params.row);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const [pageSize, setPageSize] = useState(8);
    const [localMemberStatusChangesAwaiting, setLocalMemberStatusChangesAwaiting] = useState([]);
    const [localMemberStatusChangesFuture, setLocalMemberStatusChangesFuture] = useState([]);
    const [localMemberStatusChangesRecent, setLocalMemberStatusChangesRecent] = useState([]);

    const [selectedStatusTable, setSelectedStatusTable] = useState(0);

    const { memberStatusChangesAwaiting, memberStatusChangesFuture, memberStatusChangesRecent, memberSearch, memberStatuses } = useSelector(
        (state: RootState) => state.members
    );

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 73 : 84) + ''));
    };

    const filterItems = (statusChangeList: any[]): any[] => {
        var tmp = [];

        for (const memberStatusChange of statusChangeList) {
            if (memberSearch) {
                for (const prop in memberStatusChange) {
                    if (memberStatusChange[prop] && memberStatusChange[prop].toString().toLowerCase().indexOf(memberSearch.toLowerCase()) > -1) {
                        tmp.push(memberStatusChange);
                        break;
                    }
                }
            } else {
                tmp.push(memberStatusChange);
            }
        }

        return tmp;
    };

    const executeSearch = () => {
        var tmpAwait = filterItems(memberStatusChangesAwaiting);
        setLocalMemberStatusChangesAwaiting(tmpAwait);

        var tmpFuture = filterItems(memberStatusChangesFuture);
        setLocalMemberStatusChangesFuture(tmpFuture);

        var tmpRecent = filterItems(memberStatusChangesRecent);
        setLocalMemberStatusChangesRecent(tmpRecent);
    };

    const changeSearch = (event: any) => {
        if (event.key === 'Enter') {
            executeSearch();
        }
    };

    useEffect(() => {
        executeSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberStatusChangesAwaiting, memberStatusChangesFuture, memberStatusChangesRecent]);

    useEffect(() => {
        dispatch(fetchMemberStatuses());
        dispatch(fetchStatusAwaitingApprovalCount());
        dispatch(fetchMemberStatusChanges());
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {<Loading message={'Loading...'} />}
            <MemberStatusChange preview={true} statusChange={selectedStatusChange} />
            <Grid container spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={12} style={{ paddingBottom: '0px' }}>
                    <FormControl style={{ minWidth: '300px', margin: '0px', float: 'left' }}>
                        <TextField
                            style={{ width: '100%', marginBottom: '0px' }}
                            label="Search"
                            type="text"
                            variant="outlined"
                            value={memberSearch}
                            onChange={(e) => {
                                dispatch(setMemberSearch(e.target.value as string));
                            }}
                            onKeyUp={(event: any) => {
                                changeSearch(event);
                            }}
                        />
                    </FormControl>
                    <Fab
                        variant="extended"
                        color={selectedStatusTable === 2 ? 'primary' : 'default'}
                        className="fab-right"
                        onClick={() => {
                            setSelectedStatusTable(2);
                        }}
                    >
                        Recent Changes
                    </Fab>
                    <Fab
                        variant="extended"
                        color={selectedStatusTable === 1 ? 'primary' : 'default'}
                        className="fab-right"
                        onClick={() => {
                            setSelectedStatusTable(1);
                        }}
                    >
                        Future Status Changes
                    </Fab>
                    <Fab
                        variant="extended"
                        color={selectedStatusTable === 0 ? 'primary' : 'default'}
                        className="fab-right"
                        onClick={() => {
                            setSelectedStatusTable(0);
                        }}
                    >
                        Awaiting Approval
                    </Fab>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ padding: '0px 16px 16px 16px' }}>
                <Grid item xs={12} style={tablesStyle}>
                    {selectedStatusTable === 0 ? (
                        <Paper elevation={3} style={{ height: '100%', padding: '8px' }}>
                            <h4>Statuses Awating Approval</h4>
                            <DataGrid
                                style={{ height: 'calc(100% - 35px)' }}
                                getRowId={(row) => row.Id}
                                rows={localMemberStatusChangesAwaiting}
                                columns={awaitingColumns}
                                pageSize={pageSize}
                                getRowClassName={(params) => `color${params.row.IsInTransition}`}
                                rowsPerPageOptions={[5]}
                            />
                        </Paper>
                    ) : null}
                    {selectedStatusTable === 1 ? (
                        <Paper elevation={3} style={{ height: '100%', padding: '8px' }}>
                            <h4>Future Status Changes</h4>
                            <DataGrid
                                style={{ height: 'calc(100% - 35px)' }}
                                getRowId={(row) => row.Id}
                                rows={localMemberStatusChangesFuture}
                                columns={futureStatusColumns}
                                pageSize={pageSize}
                                getRowClassName={(params) => `color${params.row.IsInTransition}`}
                                rowsPerPageOptions={[5]}
                            />
                        </Paper>
                    ) : null}
                    {selectedStatusTable === 2 ? (
                        <Paper elevation={3} style={{ height: '100%', padding: '8px' }}>
                            <h4>Recent Status Changes</h4>
                            <DataGrid
                                style={{ height: 'calc(100% - 35px)' }}
                                getRowId={(row) => row.Id}
                                rows={localMemberStatusChangesRecent}
                                columns={recentStatusColumns}
                                pageSize={pageSize}
                                getRowClassName={(params) => `color${params.row.IsInTransition}`}
                                rowsPerPageOptions={[5]}
                            />
                        </Paper>
                    ) : null}
                </Grid>
            </Grid>
        </Box>
    );
};

export default MembersStatusPage;
