import {
    Avatar,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Paper,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { fetchInsuranceTypes, fetchMemberDemoGraphicInfo } from '../../../store/memberDetail.slice';
import { fetchAllLanguages, fetchGenders, fetchMaritalStatuses } from '../../../store/shared.slice';
import { useEffect, useState } from 'react';
import MessageDisplay from '../../Shared/MessageDisplay';
import { fetchMemberStatuses, fetchRiskLevels } from '../../../store/members.slice';
import { getTCCRoleProviders } from '../../../store/provider.slice';
import MemberStatusChange from '../MemberStatusChange';
import SSNMaskCustom from '../../Shared/SSNMask';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import LoginSetup from './LoginSetup';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { setShowEditMemberDemographics } from '../../../store/memberDemographicsDetails.slice';
import MemberDemographic from './MemberDemographic';
import { GenderEnum } from '../../../Enum/GenderEnum';
import moment from 'moment';
import { PreferredPronounsEnum } from '../../../Enum/PreferredPronounsEnum';

const ViewMemberDemographic: React.FC<{ isAssessment?: boolean; setSaveClickEvent?: Function }> = ({ isAssessment = false, setSaveClickEvent = null }) => {
    const dispatch = useDispatch();
    const { insuranceTypes, selectedMember, teams } = useSelector((state: RootState) => state.memberDetail);
    const { ethnicities, preferredPronouns, races, showEditMemberDemographics } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const { maritalStatuses } = useSelector((state: RootState) => state.shared);
    const { riskLevels } = useSelector((state: RootState) => state.members);
    const { tccProviders } = useSelector((state: RootState) => state.provider);
    const [showSSN, setShowSSN] = useState(!selectedMember?.SSN);

    const tccProvider = tccProviders?.find((el) => el.Id === selectedMember?.TCCProvider?.Id?.toString());

    const openEditHandler = () => {
        dispatch(setShowEditMemberDemographics(true));
    };

    const closeEditHandler = () => {
        dispatch(setShowEditMemberDemographics(false));
    };

    useEffect(() => {
        dispatch(fetchMemberDemoGraphicInfo(selectedMember.Id));
        dispatch(fetchInsuranceTypes());
        dispatch(fetchRiskLevels());
        dispatch(fetchGenders());
        dispatch(fetchMaritalStatuses());
        dispatch(fetchAllLanguages());
        dispatch(fetchMemberStatuses());
        dispatch(getTCCRoleProviders(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const copyAddressDetail = (addressType: string) => {
        let memberName = `${selectedMember.FirstName} - ${selectedMember.LastName}`;
        let fullAddress = '';
        let lat = 0;
        let lon = 0;
        if (addressType === 'Main') {
            let addressParts = [selectedMember.Address1, selectedMember.Address2, selectedMember.City, selectedMember.State, selectedMember.ZipCode];
            fullAddress = addressParts.filter((part) => part).join(', ');
            lat = selectedMember.Lat;
            lon = selectedMember.Lon;
        } else if (addressType === 'Meeting' && Boolean(selectedMember.MeetingAddress)) {
            let addressParts = [
                selectedMember.MeetingAddress.Address1,
                selectedMember.MeetingAddress.Address2,
                selectedMember.MeetingAddress.City,
                selectedMember.MeetingAddress.State,
                selectedMember.MeetingAddress.ZipCode,
            ];
            fullAddress = addressParts.filter((part) => part).join(', ');
            lat = selectedMember.MeetingAddress.Lat;
            lon = selectedMember.MeetingAddress.Lon;
        } else if (addressType === 'Discharge' && Boolean(selectedMember.DischargingAddress)) {
            let addressParts = [
                selectedMember.DischargingAddress.Address1,
                selectedMember.DischargingAddress.Address2,
                selectedMember.DischargingAddress.City,
                selectedMember.DischargingAddress.State,
                selectedMember.DischargingAddress.ZipCode,
            ];
            fullAddress = addressParts.filter((part) => part).join(', ');
            lat = selectedMember.DischargingAddress.Lat;
            lon = selectedMember.DischargingAddress.Lon;
        }
        let memberDetail = `${memberName} - ${fullAddress}`;
        copyDetail(memberDetail, lat, lon);
    };

    const copyDetail = (memberDetail: string, lat: any, lon: any) => {
        let memberInfo = memberDetail;
        if (Boolean(lat) && Boolean(lon)) {
            const url = `https://www.google.com/maps?q=${lat},${lon}`;
            memberInfo = `${memberDetail} - ${url}`;
        }
        navigator.clipboard
            .writeText(memberInfo)
            .then(() => {})
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <Grid
            container
            spacing={2}
            sx={{
                '.MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000',
                    color: '#000',
                },
                '& .MuiFormLabel-root': {
                    color: '#000',
                },
            }}
        >
            <MessageDisplay />
            <MemberStatusChange />
            <LoginSetup />
            <Grid item xs>
                <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                    <Divider style={{ marginBottom: '16px' }}>
                        <Chip label="Identifying Information" />
                    </Divider>
                    <Stack direction="row" spacing={2}>
                        <Stack spacing={2}>
                            <Avatar
                                alt={selectedMember?.FirstName + ' ' + selectedMember?.LastName}
                                src={selectedMember?.ProfileImageUrl}
                                sx={{ width: 144, height: 144 }}
                                className="profile-image"
                            />
                        </Stack>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField fullWidth label="First Name" variant="outlined" value={selectedMember?.FirstName || ''} disabled />
                                    <TextField
                                        fullWidth
                                        label="Middle Name"
                                        variant="outlined"
                                        value={selectedMember?.MiddleName ? selectedMember?.MiddleName : ''}
                                        disabled
                                    />
                                    <TextField fullWidth label="Last Name" variant="outlined" value={selectedMember?.LastName || ''} disabled />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Preferred Name"
                                        variant="outlined"
                                        value={selectedMember?.PreferredName ? selectedMember?.PreferredName : ''}
                                        disabled
                                    />
                                    <TextField
                                        fullWidth
                                        label="DOB"
                                        variant="outlined"
                                        value={selectedMember.DOB ? moment(selectedMember.DOB).format('MM/DD/yyyy') : ''}
                                        disabled
                                    />
                                    <TextField
                                        fullWidth
                                        label="Primary Language"
                                        variant="outlined"
                                        value={selectedMember?.PrimaryLanguage?.Name || ''}
                                        disabled
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField fullWidth label="Gender" variant="outlined" value={GenderEnum[selectedMember?.Gender]} disabled />

                                    {showSSN ? (
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel htmlFor="formatted-text-mask-input">SSN</InputLabel>
                                            <Input
                                                value={selectedMember.SSN || ''}
                                                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                //     ssnChange(e);
                                                // }}
                                                inputComponent={SSNMaskCustom as any}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                setShowSSN(false);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <Visibility />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    ) : (
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel htmlFor="formatted-text-mask-input">SSN</InputLabel>
                                            <Input
                                                disabled={true}
                                                value={selectedMember?.SSN ? 'XXX-XX-' + selectedMember?.SSN.substring(selectedMember.SSN.length - 4) : ''}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => {
                                                                setShowSSN(true);
                                                            }}
                                                            edge="end"
                                                        >
                                                            <VisibilityOff />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    )}
                                    <TextField
                                        fullWidth
                                        label="Marital Status"
                                        variant="outlined"
                                        value={maritalStatuses?.find((el) => el.value === selectedMember?.MaritalStatus?.toString())?.label || ''}
                                        disabled
                                    />
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} spacing={2} marginY={2}>
                        <TextField
                            fullWidth
                            label="Preferred Pronouns"
                            variant="outlined"
                            value={preferredPronouns?.find((p) => p.value === selectedMember?.PreferredPronouns?.toString())?.label || ''}
                            disabled
                        />
                        {selectedMember?.PreferredPronouns === PreferredPronounsEnum.Other && (
                            <TextField
                                fullWidth
                                label="Preferred Pronouns Other"
                                variant="outlined"
                                value={selectedMember?.PreferredPronounsOther || ''}
                                disabled
                            />
                        )}
                        <TextField
                            fullWidth
                            label="Ethnicity"
                            variant="outlined"
                            value={ethnicities?.find((p) => p.value === selectedMember?.Ethnicity?.toString())?.label || ''}
                            disabled
                        />
                        <TextField
                            fullWidth
                            label="Race"
                            variant="outlined"
                            value={races?.find((p) => p.value === selectedMember?.Race?.toString())?.label || ''}
                            disabled
                        />
                    </Stack>
                </Paper>
                <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                    <Divider style={{ marginBottom: '16px' }}>
                        <Chip label="Address Information" />
                    </Divider>
                    <Stack style={{ width: '100%' }} spacing={2}>
                        <FormGroup>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                <FormControlLabel control={<Checkbox checked={selectedMember.IsHomeless} />} disabled label="Is Currently Homeless" />
                                <Tooltip title="Copy Map Location" placement="left">
                                    <ContentCopyRoundedIcon
                                        color="primary"
                                        style={{
                                            marginLeft: '50px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            copyAddressDetail('Main');
                                        }}
                                    />
                                </Tooltip>
                            </Stack>
                        </FormGroup>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <TextField fullWidth label="Address 1" variant="outlined" value={selectedMember?.Address1 || ''} disabled />
                                <TextField fullWidth label="Address 2" variant="outlined" value={selectedMember?.Address2 || ''} disabled />
                                <TextField fullWidth label="City" variant="outlined" value={selectedMember?.City || ''} disabled />
                            </Stack>
                        </FormGroup>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <TextField fullWidth label="State" variant="outlined" value={selectedMember?.State || ''} disabled />
                                <TextField fullWidth label="Country" variant="outlined" value={selectedMember?.Country || ''} disabled />
                                <TextField fullWidth label="Zip Code" variant="outlined" disabled value={selectedMember?.ZipCode} />
                            </Stack>
                        </FormGroup>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <TextField fullWidth label="Email" variant="outlined" value={selectedMember?.Email ? selectedMember?.Email : ''} disabled />
                            </Stack>
                        </FormGroup>
                    </Stack>
                    <Stack style={{ width: '100%' }} spacing={2}>
                        <FormGroup>
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                <FormControlLabel
                                    control={<Checkbox checked={selectedMember.IsDifferMeetingAddress} disabled />}
                                    label="Different Meeting Address"
                                />
                                {selectedMember.IsDifferMeetingAddress && selectedMember.MeetingAddress ? (
                                    <Tooltip title="Copy Map Location" placement="left">
                                        <ContentCopyRoundedIcon
                                            color="primary"
                                            style={{
                                                marginLeft: '50px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                copyAddressDetail('Meeting');
                                            }}
                                        />
                                    </Tooltip>
                                ) : null}
                            </Stack>
                        </FormGroup>
                        {selectedMember.IsDifferMeetingAddress ? (
                            <>
                                <FormGroup>
                                    <Divider style={{ marginBottom: '16px' }}>
                                        <Chip label="Different Meeting Address Information" />
                                    </Divider>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Address 1"
                                            variant="outlined"
                                            value={selectedMember.MeetingAddress?.Address1 ? selectedMember.MeetingAddress?.Address1 : ''}
                                            disabled
                                        />
                                        <TextField
                                            fullWidth
                                            label="Address 2"
                                            variant="outlined"
                                            value={selectedMember.MeetingAddress?.Address2 ? selectedMember.MeetingAddress?.Address2 : ''}
                                            disabled
                                        />
                                        <TextField
                                            fullWidth
                                            label="City"
                                            variant="outlined"
                                            value={selectedMember.MeetingAddress?.City ? selectedMember.MeetingAddress?.City : ''}
                                            disabled
                                        />
                                    </Stack>
                                </FormGroup>
                                <FormGroup>
                                    <Stack direction="row" spacing={2} paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            label="State"
                                            variant="outlined"
                                            value={selectedMember.MeetingAddress?.State ? selectedMember.MeetingAddress?.State : ''}
                                            disabled
                                        />
                                        <TextField
                                            fullWidth
                                            label="Country"
                                            variant="outlined"
                                            value={selectedMember.MeetingAddress?.Country ? selectedMember.MeetingAddress?.Country : ''}
                                            disabled
                                        />
                                        <TextField
                                            fullWidth
                                            label="Zip Code"
                                            variant="outlined"
                                            type={'number'}
                                            disabled
                                            value={selectedMember.MeetingAddress?.ZipCode ? selectedMember.MeetingAddress?.ZipCode : ''}
                                        />
                                    </Stack>
                                </FormGroup>
                            </>
                        ) : null}
                    </Stack>
                    {selectedMember.IsInFuhFum ? (
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedMember.IsDifferDischargingAdress} disabled />}
                                        label="Different Discharging Address"
                                    />
                                    {selectedMember.IsDifferDischargingAdress === true && Boolean(selectedMember.DischargingAddress) ? (
                                        <Tooltip title="Copy Map Location" placement="left">
                                            <ContentCopyRoundedIcon
                                                color="primary"
                                                style={{
                                                    marginLeft: '50px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    copyAddressDetail('Discharge');
                                                }}
                                            />
                                        </Tooltip>
                                    ) : null}
                                </Stack>
                            </FormGroup>
                            {selectedMember.IsDifferDischargingAdress ? (
                                <>
                                    <FormGroup>
                                        <Stack direction="row" spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Address 1"
                                                variant="outlined"
                                                value={selectedMember.DischargingAddress?.Address1 ? selectedMember.DischargingAddress?.Address1 : ''}
                                                disabled
                                            />
                                            <TextField
                                                fullWidth
                                                label="Address 2"
                                                variant="outlined"
                                                value={selectedMember.DischargingAddress?.Address2 ? selectedMember.DischargingAddress?.Address2 : ''}
                                                disabled
                                            />
                                            <TextField
                                                fullWidth
                                                label="City"
                                                variant="outlined"
                                                value={selectedMember.DischargingAddress?.City ? selectedMember.DischargingAddress?.City : ''}
                                                disabled
                                            />
                                        </Stack>
                                    </FormGroup>
                                    <FormGroup>
                                        <Stack direction="row" spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="State"
                                                variant="outlined"
                                                value={selectedMember.DischargingAddress?.State ? selectedMember.DischargingAddress?.State : ''}
                                                disabled
                                            />
                                            <TextField
                                                fullWidth
                                                label="Country"
                                                variant="outlined"
                                                value={selectedMember.DischargingAddress?.Country ? selectedMember.DischargingAddress?.Country : ''}
                                                disabled
                                            />
                                            <TextField
                                                fullWidth
                                                label="Zip Code"
                                                variant="outlined"
                                                type={'number'}
                                                disabled
                                                value={selectedMember.DischargingAddress?.ZipCode ? selectedMember.DischargingAddress?.ZipCode : ''}
                                            />
                                        </Stack>
                                    </FormGroup>
                                </>
                            ) : null}
                        </Stack>
                    ) : null}
                </Paper>
                <Paper elevation={3} style={{ padding: '8px', margin: '16px', marginBottom: '50px', textAlign: 'left' }}>
                    <Divider style={{ marginBottom: '16px' }}>
                        <Chip label="Other Information" />
                    </Divider>
                    <Stack style={{ width: '100%' }} spacing={2}>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Risk Level"
                                    variant="outlined"
                                    value={riskLevels?.find((el) => el.value === selectedMember?.RiskLevel?.toString())?.label || ''}
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    label="Risk Score"
                                    variant="outlined"
                                    value={selectedMember?.RiskScore ? selectedMember?.RiskScore : ''}
                                    disabled
                                />
                                <FormControl fullWidth>
                                    <FormControlLabel control={<Checkbox checked={selectedMember.TwoStaff} disabled />} label="Two Staff" />
                                </FormControl>
                            </Stack>
                        </FormGroup>
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <TextField fullWidth label="Career" variant="outlined" value={selectedMember?.Career ? selectedMember?.Career : ''} disabled />
                                <TextField
                                    fullWidth
                                    label="Hobbies"
                                    variant="outlined"
                                    value={selectedMember?.Hobbies ? selectedMember?.Hobbies : ''}
                                    disabled
                                />
                                <TextField
                                    fullWidth
                                    label="My Favorites"
                                    variant="outlined"
                                    value={selectedMember?.MyFavorites ? selectedMember?.MyFavorites : ''}
                                    disabled
                                />
                            </Stack>
                        </FormGroup>
                    </Stack>
                </Paper>
            </Grid>
            {isAssessment ? null : (
                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Dates & Insurance" />
                        </Divider>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Insurance Type"
                                        variant="outlined"
                                        value={insuranceTypes?.find((el) => el.value === selectedMember?.InsuranceType?.toString())?.label || ''}
                                        disabled
                                    />
                                    <TextField fullWidth label="Member Status" variant="outlined" value={MemberStatusEnum[selectedMember.Status]} disabled />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Date of Onboard"
                                        variant="outlined"
                                        value={selectedMember.DateOfOnboard ? moment(selectedMember.DateOfOnboard).format('MM/DD/yyyy') : ''}
                                        disabled
                                    />
                                    <TextField
                                        fullWidth
                                        label="End of Date of Service"
                                        variant="outlined"
                                        value={selectedMember.EndOfDateOfService ? moment(selectedMember.EndOfDateOfService).format('MM/DD/yyyy') : ''}
                                        disabled
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Last PCP Visit"
                                        variant="outlined"
                                        value={selectedMember.LastPCPVisit ? moment(selectedMember.LastPCPVisit).format('MM/DD/yyyy') : ''}
                                        disabled
                                    />
                                    <TextField
                                        fullWidth
                                        label="Primary Team"
                                        variant="outlined"
                                        // value={selectedMember.Team?.Id}
                                        value={teams?.find((el) => el.Id === selectedMember?.Team?.Id?.toString())?.Name || ''}
                                        disabled
                                    />
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </Paper>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Tablet Information" />
                        </Divider>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Tablet ID"
                                        variant="outlined"
                                        value={selectedMember?.TabletId ? selectedMember?.TabletId : ''}
                                        disabled
                                    />
                                    <TextField fullWidth label="SIM" variant="outlined" value={selectedMember?.Sim ? selectedMember?.Sim : ''} disabled />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Date of Delivery"
                                        variant="outlined"
                                        value={selectedMember.DateOfDelivery ? moment(selectedMember.DateOfDelivery).format('MM/DD/yyyy') : ''}
                                        disabled
                                    />
                                    <TextField
                                        fullWidth
                                        label="Internet Connectivity"
                                        variant="outlined"
                                        value={selectedMember?.InternetConnectivity ? selectedMember?.InternetConnectivity : ''}
                                        disabled
                                    />
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </Paper>
                    {selectedMember.IsInFuhFum ? (
                        <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                            <Divider style={{ marginBottom: '16px' }}>
                                <Chip label="FUHFUM Information" />
                            </Divider>
                            <Stack style={{ width: '100%' }} spacing={2}>
                                <FormGroup>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="TCC Provider"
                                            variant="outlined"
                                            value={tccProvider ? tccProvider.LastName + ', ' + tccProvider?.FirstName : ''}
                                            disabled
                                        />
                                    </Stack>
                                </FormGroup>
                            </Stack>
                        </Paper>
                    ) : null}
                    <Stack direction="row" spacing={2} style={{ float: 'right', margin: '16px' }}>
                        <Button className="button-120" variant="contained" onClick={openEditHandler}>
                            Edit
                        </Button>
                    </Stack>
                </Grid>
            )}
            <Dialog open={showEditMemberDemographics} onClose={closeEditHandler} fullWidth maxWidth={'xl'}>
                <DialogTitle>Edit Member Demographics</DialogTitle>
                <DialogContent>
                    <MemberDemographic />
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </Grid>
    );
};

export default ViewMemberDemographic;
