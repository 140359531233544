import { Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { ConsentSignatureTypeEnum } from '../../../Enum/ConsentSignatureTypeEnum';
import { setDigitalSignatureForm } from '../../../store/consentSlice.slice';

const SignatureTypeTabs = () => {
    const dispatch = useDispatch();
    const { digitalSignatureForm } = useSelector((state: RootState) => state.consentSlice);

    const handleChangeSignatureType = (event: React.SyntheticEvent, newValue: ConsentSignatureTypeEnum) => {
        dispatch(
            setDigitalSignatureForm({
                ...digitalSignatureForm,
                SignatureType: newValue,
            })
        );
    };
    return (
        <Tabs value={digitalSignatureForm.SignatureType} onChange={handleChangeSignatureType} variant="fullWidth" sx={{ px: 2 }}>
            <Tab label="Patient Signature" value={0} />
            <Tab label="Legal Guardian or Caregiver" value={1} />
            <Tab label="Verbal Consent" value={2} />
        </Tabs>
    );
};
export default SignatureTypeTabs;
