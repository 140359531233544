import { MbscCalendarEventData } from '@mobiscroll/react';
import { Repeat } from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

export interface IEventLabelProps {
    event: MbscCalendarEventData & { IsOffboarding?: boolean };
}

const EventContent: React.FC<IEventLabelProps> = (props) => {
    const { event } = props;
    let recurrenceIcon = null;
    if (event.original.recurring) {
        recurrenceIcon = (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 18, // Adjust this value as needed
                    height: 15, // Adjust this value as needed
                    borderRadius: '4px',
                    color: 'white',
                }}
            >
                <Repeat
                    sx={{ transform: 'scale(0.6)' }} // Adjust this value as needed
                />
            </Box>
        );
    }

    let offboardIcon = null;
    if (event.original.IsOffboarding) {
        offboardIcon = <Chip sx={{ maxHeight: 14, fontSize: 8 }} size="small" label="Offboarding" color="warning" />;
    }
    return (
        <>
            <Stack direction={'row'} spacing={1}>
                {event.original.HasWarningFlag ? <WarningIcon sx={{ fontSize: 'inherit', color: 'red' }} /> : null}
                {recurrenceIcon}
                {offboardIcon}
            </Stack>

            <Stack spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                <Typography variant="body2">{event.original.MemberName ?? event.title}</Typography>
            </Stack>
        </>
    );
};

export default EventContent;
