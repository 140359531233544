import { Button, DialogActions, DialogContent, FormControl, Grid, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { downloadSample, setActiveStep, setImportSuccessful } from '../../store/fuhfum.slice';

const SampleData = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const nextStepHandler = () => {
        dispatch(setImportSuccessful(false));
        dispatch(setActiveStep(1));
    };

    const navigateToHie = () => {
        history(`/provider/hie`);
    };

    return (
        <>
            <DialogContent>
                <FormControl fullWidth>
                    <Grid spacing={2} container p={2} sx={{ height: '65vh' }}>
                        <Grid item xs={12}>
                            <Stack>
                                <Typography variant="h4" marginBottom={3}>
                                    Prepare your data for import
                                </Typography>
                                <Typography variant="body1">You can import up to 100 MB size file. The file should be in .xlsx or .xls format.</Typography>
                                <br />
                                <Typography variant="body1">
                                    The column name and order should match with sample file. Dowload the sample and explore the columns to prepare your file.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Button className="button-120" variant="contained" color="success" onClick={() => dispatch(downloadSample())}>
                                Download Sample
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button className="button-120" variant="contained" onClick={navigateToHie}>
                    Cancel
                </Button>
                <Button
                    className="button-120"
                    variant="contained"
                    onClick={() => {
                        nextStepHandler();
                    }}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default SampleData;
