import { Button, Dialog, DialogActions, DialogContent, Grid, Stack, Tooltip, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeAddPopulationHandler,
    closeEditPopulationHandler,
    openEditPopulationHandler,
    setCurrentPopulation,
    setNewPopulation,
    setOpenAddPopulation,
    setPopulationActiveStep,
    setPopulationSteps,
    setUpdatingPopulation,
} from '../../store/adminHealthPlan.slice';
import { RootState } from '../../reducers';
import AddPopulationDialog from './PopulationComponents/AddPopulationDialog';
import EditPopulationDialog from './PopulationComponents/EditPopulationDialog';

const PopulationDialog = (props) => {
    const dispatch = useDispatch();
    const { activeStep, currentHealthPlan, openAddPopulation, populationByHealthPlan } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const { editPopulationVisibility, openAddPopulationHandler } = props;
    const updatePopulationSteps = [
        { label: 'Sample Data', value: 0 },
        { label: 'Choose Data', value: 1 },
        { label: 'Import Data', value: 2 },
    ];

    const updatePopulationHandler = (params) => {
        dispatch(setNewPopulation(false));
        dispatch(setCurrentPopulation(params.row));
        dispatch(setPopulationSteps(updatePopulationSteps));
        dispatch(setUpdatingPopulation(true));
        dispatch(setPopulationActiveStep(0));
        dispatch(setOpenAddPopulation(true));
    };

    const columns: GridColDef[] = [
        {
            field: 'PopulationName',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'Population',
            headerName: 'Type',
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction={'row'} spacing={2}>
                        <Typography>{params.row.IsHra ? 'HRA' : params.row.IsFuhFum ? 'FUHFUM' : params.row.IsSUD ? 'SUD' : 'Full Program'}</Typography>
                    </Stack>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit Population" placement="left">
                            <EditIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(openEditPopulationHandler(params.row))}
                            />
                        </Tooltip>
                        <Tooltip title="Upload Population" placement="right">
                            <PersonAddIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => updatePopulationHandler(params)} />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <EditPopulationDialog
                open={editPopulationVisibility}
                onClose={() => dispatch(closeEditPopulationHandler())}
                openContactInfo={props.openContactInfo}
                setOpenContactInfo={props.setOpenContactInfo}
            />

            <AddPopulationDialog open={openAddPopulation} onClose={() => dispatch(closeAddPopulationHandler(currentHealthPlan))} activeStep={activeStep} />

            <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
                <DialogContent>
                    <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                            <Typography display={'inline-flex'} variant="h5">
                                {currentHealthPlan.Name}
                            </Typography>
                            <Button variant="contained" className="button-120" onClick={openAddPopulationHandler}>
                                New Population
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ height: '65vh', width: '100%' }}>
                                <DataGrid
                                    rows={populationByHealthPlan}
                                    columns={columns}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className="button-120" variant="outlined" onClick={props.onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PopulationDialog;
