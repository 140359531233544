import BaseEntity from '../BaseEntity.model';

export default interface IOnboarderNote extends BaseEntity {
    // CreatedDate: Date;
    CreatedDate: string | null;
    Editable: boolean;
    MemberId: string;
    Note: string;
    NoteType: string;
    UserId: string;
    UserName: string;
}

export default class OnboarderNote implements IOnboarderNote {
    // CreatedDate: Date | null;
    CreatedDate: string | null;
    Editable: boolean;
    MemberId: string;
    Note: string;
    NoteType: string;
    UserId: string;
    UserName: string;

    constructor() {
        this.CreatedDate = null;
        this.Editable = false;
        this.MemberId = '';
        this.Note = '';
        this.NoteType = '';
        this.UserId = '';
        this.UserName = '';
    }
}
