import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import ConfirmDelete from '../../Shared/ConfirmDelete';
import { putApplicableWidgets, setSelectedTabModal, setShowWidgetsMenu } from '../../../store/smartDashboard.slice';
import Authorization from '../../../utils/Authorization';
import { setSelectedTab } from '../../../store/adminRoles.slice';
// import { allWidgetsList } from '../Shared/AllWidgetsList';

interface ISmartDashboardWidgetTabs {
    tabName: string;
    tabValue: number;
    visibleWidgets: any[];
}

const SetVisibleWidgetsDialog = (props) => {
    const dispatch = useDispatch();
    const auth = new Authorization();
    const { customTabsList, selectedTab, selectedTabModal, showWidgetsMenu, startingRoleWidgets } = useSelector((state: RootState) => state.smartDashboard);

    const createNewTab = (tabValue) => {
        const newTab: ISmartDashboardWidgetTabs = {
            tabName: 'New Tab',
            tabValue: tabValue,
            visibleWidgets: [],
        };
        return newTab;
    };

    const [customTabsListCopy, setCustomTabsListCopy] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);

    // const getStartingWidgetsForRender = (widgetNamesList: string[]) => {
    //     return allWidgetsList.filter((widget) => widgetNamesList.includes(widget.name) === true);
    // };

    const updateWidgetSettingsHandler = (newCustomTabsList) => {
        if (selectedTab > newCustomTabsList.length - 1) {
            dispatch(setSelectedTab(newCustomTabsList.length - 1));
        }

        const payload = {
            ProviderID: auth.UserId,
            RoleName: auth.Role,
            CustomDashboardWidgets: newCustomTabsList,
        };
        dispatch(putApplicableWidgets(payload));
        dispatch(setShowWidgetsMenu(false));
    };

    const submitHandler = () => {
        dispatch(setSelectedTabModal(0));
        updateWidgetSettingsHandler(customTabsListCopy);
    };

    const widgetOnChangeHandler = (e) => {
        let copy = [...customTabsListCopy];
        let currentTabTmp = copy.find((tab) => tab.tabValue === selectedTabModal);
        let currentTabTmpVisibleWidgets = [...currentTabTmp.visibleWidgets];
        const tabIndex = copy.indexOf(currentTabTmp);
        if (!e.target.checked) {
            currentTabTmpVisibleWidgets = currentTabTmpVisibleWidgets.filter((widget) => widget !== e.target.name);
        } else {
            currentTabTmpVisibleWidgets.push(e.target.name);
        }
        currentTabTmp = { ...currentTabTmp, visibleWidgets: currentTabTmpVisibleWidgets };
        copy[tabIndex] = currentTabTmp;
        setCustomTabsListCopy(copy);
    };

    const cancelHandler = () => {
        setCustomTabsListCopy([...customTabsList]);
        dispatch(setSelectedTabModal(0));
        dispatch(setShowWidgetsMenu(false));
    };

    const changeTabNameHandler = (e) => {
        let tmp = [...customTabsListCopy];
        let currentTabTmp = tmp.find((tab) => tab.tabValue === selectedTabModal);
        const index = tmp.indexOf(currentTabTmp);
        currentTabTmp = { ...currentTabTmp, tabName: e.target.value };
        tmp[index] = currentTabTmp;
        setCustomTabsListCopy(tmp);
    };

    const addNewTabHandler = () => {
        setCustomTabsListCopy([...customTabsListCopy, createNewTab(customTabsListCopy.length)]);
    };

    const removeTabHandler = () => {
        const current = customTabsListCopy.find((el) => el.tabValue === selectedTabModal);
        const filteredList = customTabsListCopy.filter((tab) => tab.tabValue !== current.tabValue);
        const newTabsList = filteredList.map((tab, index) => ({ ...tab, tabValue: index }));
        dispatch(setSelectedTabModal(newTabsList.length - 1));
        setCustomTabsListCopy(newTabsList);
        setOpenDelete(false);
    };

    const tabChangeHandler = (e, newValue) => {
        dispatch(setSelectedTabModal(newValue));
    };

    const selectAllHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let tmp = [...customTabsListCopy];
        let currentTabTmp = tmp.find((tab) => tab.tabValue === selectedTabModal);
        const index = tmp.indexOf(currentTabTmp);
        if (e.target.checked === false) {
            currentTabTmp = { ...currentTabTmp, visibleWidgets: [] };
        } else {
            currentTabTmp = { ...currentTabTmp, visibleWidgets: [...startingRoleWidgets] };
        }

        tmp[index] = currentTabTmp;
        setCustomTabsListCopy(tmp);
    };

    const nameIsValid = () => {
        let emptyNames = 0;
        for (let i = 0; i < customTabsListCopy.length; i++) {
            let name = customTabsListCopy[i].tabName.trim();
            if (name.length === 0) {
                emptyNames++;
            }
        }

        return emptyNames > 0 ? false : true;
    };

    useEffect(() => {
        if (customTabsList?.length > 0) {
            setCustomTabsListCopy(customTabsList);
        }
    }, [customTabsList]);

    return (
        <>
            <ConfirmDelete
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                name={customTabsListCopy.find((tab) => tab.tabValue === selectedTabModal)?.tabName.toUpperCase()}
                delete={removeTabHandler}
                cancel={() => setOpenDelete(false)}
            />
            <Dialog
                open={showWidgetsMenu}
                onClose={() => dispatch(setShowWidgetsMenu(false))}
                fullWidth
                disableEnforceFocus
                maxWidth="xl"
                sx={{ zIndex: 1202 }}
            >
                <DialogTitle>Customize Dashboard</DialogTitle>

                <DialogContent>
                    <Tabs value={selectedTabModal} onChange={tabChangeHandler}>
                        {customTabsListCopy.map((tab, index) => {
                            return <Tab key={index} value={tab.tabValue} label={tab.tabName} />;
                        })}
                        <Tab disabled={customTabsListCopy.length === 6 || !nameIsValid()} icon={<AddIcon />} onClick={() => addNewTabHandler()} />
                    </Tabs>

                    <Card sx={{ marginTop: 2, boxShadow: 5 }}>
                        <CardContent>
                            <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginY={2}>
                                <TextField
                                    value={customTabsListCopy.find((tab) => tab.tabValue === selectedTabModal)?.tabName.toUpperCase()}
                                    id="tab-name"
                                    label="Tab Name"
                                    name="TabName"
                                    sx={{ width: '300px' }}
                                    variant="outlined"
                                    onChange={(e) => changeTabNameHandler(e)}
                                />
                                <Button
                                    disabled={customTabsListCopy.length <= 1}
                                    onClick={() => setOpenDelete(true)}
                                    sx={{ minWidth: '120px', marginRight: 0 }}
                                    variant="outlined"
                                >
                                    Remove Tab
                                </Button>
                            </Stack>

                            <Typography marginTop={5} marginBottom={2} variant="h6">
                                Set visible widgets for {customTabsListCopy.find((tab) => tab.tabValue === selectedTabModal)?.tabName.toUpperCase()}
                            </Typography>

                            <FormControlLabel
                                label="Select All"
                                sx={{ width: '100%' }}
                                control={
                                    <Switch
                                        checked={
                                            customTabsListCopy.find((tab) => tab.tabValue === selectedTabModal)?.visibleWidgets.length ===
                                            startingRoleWidgets.length
                                        }
                                        onChange={selectAllHandler}
                                    />
                                }
                            />

                            {[...startingRoleWidgets]
                                .sort((a, b) => a.localeCompare(b))
                                .map((widget, index) => (
                                    <Checkbox
                                        key={index}
                                        name={widget}
                                        onChange={(e) => widgetOnChangeHandler(e)}
                                        checked={customTabsListCopy.find((tab) => tab.tabValue === selectedTabModal)?.visibleWidgets.includes(widget)}
                                        icon={<Chip variant="outlined" label={widget} />}
                                        checkedIcon={<Chip color="primary" label={widget} />}
                                    />
                                ))}
                        </CardContent>
                    </Card>
                </DialogContent>

                <DialogActions>
                    <Button disabled={!nameIsValid()} sx={{ marginTop: 2 }} className="button-120" variant="contained" onClick={submitHandler}>
                        Save
                    </Button>
                    <Button sx={{ marginTop: 2 }} className="button-120" variant="contained" onClick={cancelHandler}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SetVisibleWidgetsDialog;
