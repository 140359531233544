import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { GroupedTimeline } from '../Models/Timelines/GroupedTimeline';
import { TimelineTypeEnum } from '../Enum/TimelineTypeEnum';
import { copyObject } from '../utils/common';
import { DocumentEngagement } from '../Models/DocumentEngagements/DocumentEngagement.model';

const timelineSlice = createSlice({
    name: 'timeline',
    initialState: {
        groupedTimelines: [] as GroupedTimeline[],
        isSaving: false,
        isFetching: false,
    },
    reducers: {
        _setGroupedTimeline(state, action) {
            state.groupedTimelines = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
    },
});

const { _setGroupedTimeline, _toggleIsSaving, _toggleIsFetching } = timelineSlice.actions;

export const getTimelineByType =
    (memberId: string, typeId: TimelineTypeEnum): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/timeline/gettimelinebytype`, { params: { memberId, typeId } })
            .then((response) => {
                dispatch(_setGroupedTimeline(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const getDocumentEngagementById =
    (id: string, timelineIndex?: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setTimelineEngagementDetail(id, timelineIndex));

        axios
            .get(`${API_URL}/documentengagement/getdocumentengagementbyid`, { params: { id } })
            .then((response) => {
                if (response.data.StaffReferral == null && Boolean(response.data.PatientOutreachOrInitiatedType)) {
                    response.data.IsShowStaffReferral = false;
                    response.data.IsShowPatientOutReach = true;
                } else if (response.data.StaffReferral != null && !Boolean(response.data.PatientOutreachOrInitiatedType)) {
                    response.data.IsShowStaffReferral = true;
                    response.data.IsShowPatientOutReach = false;
                } else {
                    response.data.IsShowStaffReferral = false;
                    response.data.IsShowPatientOutReach = false;
                }
                dispatch(setTimelineEngagementDetail(id, timelineIndex, response.data));
            })
            .catch(() => {
                dispatch(setTimelineEngagementDetail(id, timelineIndex));
            });
    };

export const setTimelineEngagementDetail =
    (id: string, timelineIndex?: number, documentEngagement: DocumentEngagement = null): AppThunk =>
    async (dispatch, getState) => {
        let groupedTimelines = copyObject(getState().timeline.groupedTimelines || []) as GroupedTimeline[];
        groupedTimelines.forEach((gt) => {
            gt.Values.forEach((timeline, index) => {
                if (timeline.DetailId === id && timelineIndex === index) {
                    timeline.DocumentEngagement = documentEngagement || timeline.DocumentEngagement;
                    timeline.IsFetchingEngagementDetail = !timeline.IsFetchingEngagementDetail;
                }
            });
        });
        dispatch(_setGroupedTimeline(groupedTimelines));
    };

export const insertTimeline =
    (memberId: string, message: string, type: TimelineTypeEnum): AppThunk =>
    async (dispatch, getState) => {
        axios.post(`${API_URL}/timeline/inserttimeline`, null, { params: { memberId, message, type, appendAssignedProvider: false } }).finally(() => {
            dispatch(_toggleIsSaving(false));
        });
    };

export default timelineSlice.reducer;
