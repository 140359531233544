import { SdohSingleAnswerAdvanced } from './SdohSingleAnswerAdvanced.model';

export default interface IOptionalAdditionalQuestions {
    DoYouFeelPhysicallyAndEmotionallySafe: SdohSingleAnswerAdvanced | null;
    HaveYouBeenAfraidOfYourPartner: SdohSingleAnswerAdvanced | null;
}

export class OptionalAdditionalQuestions implements IOptionalAdditionalQuestions {
    DoYouFeelPhysicallyAndEmotionallySafe: SdohSingleAnswerAdvanced | null;
    HaveYouBeenAfraidOfYourPartner: SdohSingleAnswerAdvanced | null;

    constructor() {
        this.DoYouFeelPhysicallyAndEmotionallySafe = new SdohSingleAnswerAdvanced();
        this.HaveYouBeenAfraidOfYourPartner = new SdohSingleAnswerAdvanced();
    }
}
