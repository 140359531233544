import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../../hooks/populationContext';
import { useAnalyticContext } from '../../../../hooks/analyticContext';
import { configureStackedBarChartForCost } from '../../../ChartConfiguration/ChartConfiguration';
import ClaimsPerformance from '../../../../Models/Claims/ClaimsPreview.model';
import FilterOptions from '../FilterOptions';
import { getFfCostGraph } from '../AnalyticService';

const FFCost = () => {
    const history = useNavigate();
    const { analyticPopulations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [graphSummary, setGraphSummary] = useState<ClaimsPerformance>();
    const [chartOptions, setChartOptions] = useState({});

    const {
        ffPopulation,
        setFfPopulation,
        ffMemberStatus,
        setFfMemberStatus,
        ffTimePeriod,
        setFfTimePeriod,
        ffPart,
        setFfPart,
        ffPrimaryProvider,
        setFfPrimaryProvider,
        ffSecondaryProvider,
        setFfSecondaryProvider,
        showFfInActivePrimaryTeams,
        setShowFfInActivePrimaryTeams,
        showFfInActiveSecondaryTeams,
        setShowFfInActiveSecondaryTeams,
    } = useAnalyticContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [0] }],
                    [0],
                    'FfCost',
                    history
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                const data = await getFfCostGraph(
                    ffTimePeriod,
                    ffPopulation,
                    ffPrimaryProvider,
                    ffPart,
                    ffMemberStatus,
                    ffSecondaryProvider,
                    showFfInActivePrimaryTeams,
                    showFfInActiveSecondaryTeams
                );
                setGraphSummary(data);
                setIsPrepared(true);
            };
            setChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'Ffost',
                    history
                )
            );
            setIsGraphShow(false);
            changeFilter();
        }
    }, [
        ffTimePeriod,
        ffPopulation,
        ffPrimaryProvider,
        ffPart,
        ffMemberStatus,
        showFfInActivePrimaryTeams,
        showFfInActiveSecondaryTeams,
        ffSecondaryProvider,
        isPopulationFetched,
        history,
    ]);

    useEffect(() => {
        if (graphSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];

            if (graphSummary.Baseline !== null) {
                yValues = [];
                let costName = 'BL Yr';
                let d = {
                    y: graphSummary.Baseline,
                    name: costName,
                };
                yValues.push(d);
                yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });
            }

            if (graphSummary.PerformanceOne !== null) {
                yValues = [];
                let costName = 'Perf Yr';
                let d = {
                    y: graphSummary.PerformanceOne,
                    name: costName,
                };
                yValues.push(d);
                yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });
            }

            if (ffPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = analyticPopulations.find((p) => p.value === ffPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartForCost('Acute BH Cost', '', '', 'Cost', yAxisValues, xAxisValues, 'FfCost', history));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
    }, [graphSummary, ffPopulation, analyticPopulations, isPrepared, history]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/ffcostdetail`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            Acute BH Costs
                        </Typography>
                    </Grid>
                    <FilterOptions
                        population={ffPopulation}
                        setPopulation={setFfPopulation}
                        memberStatus={ffMemberStatus}
                        setMemberStatus={setFfMemberStatus}
                        timePeriod={ffTimePeriod}
                        setTimePeriod={setFfTimePeriod}
                        part={ffPart}
                        setPart={setFfPart}
                        primaryProvider={ffPrimaryProvider}
                        setPrimaryProvider={setFfPrimaryProvider}
                        secondaryProvider={ffSecondaryProvider}
                        setSecondaryProvider={setFfSecondaryProvider}
                        showInActivePrimaryTeams={showFfInActivePrimaryTeams}
                        setShowInActivePrimaryTeams={setShowFfInActivePrimaryTeams}
                        showInActiveSecondaryTeams={showFfInActiveSecondaryTeams}
                        setShowInActiveSecondaryTeams={setShowFfInActiveSecondaryTeams}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FFCost;
