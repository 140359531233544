import { useState, useCallback } from 'react';
import { Autocomplete, Button, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { closeEditHandler, insertRole, setAssignedFacilities, setSelectAllFacilities, updateRole } from '../../store/adminRoles.slice';
import { Provider } from '../../Models/Provider/Provider.model';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const Facility = () => {
    const dispatch = useDispatch();
    const { assignedWidgets, openNew, selectedRole, selectAllFacilities, roleName, rolePermissions, assignedFacilities, roleFacilities, zipCodes } =
        useSelector((state: RootState) => state.adminRoles);
    const { tccProviders } = useSelector((state: RootState) => state.provider);
    const [filteredFacilities, setFilteredFacilities] = useState(roleFacilities.filter((f) => f.ZipCode === zipCodes[0]));
    const [filteredZipCodes, setFilteredZipCodes] = useState([zipCodes[0]]);
    const [selectedTab, setSelectedTab] = useState('');
    const [assignedProvider, setAssignedProvider] = useState([{ Provider: new Provider(), Facilities: [] }]);
    const [providerZipCodes, setProviderZipCodes] = useState([{ ProviderId: '', ZipCodes: [] }]);

    const initialPage = useCallback(() => {
        let providerZipcodes = tccProviders.map((provider) => {
            return {
                ProviderId: provider.Id,
                ZipCodes: [zipCodes[0]],
            };
        });
        if (Boolean(assignedFacilities.length)) {
            const providerInfo = assignedFacilities[0];
            setAssignedProvider([...assignedFacilities]);
            setSelectedTab(providerInfo.Provider.Id);

            assignedFacilities.forEach((providerfacility) => {
                let providerZipcode = providerZipcodes.find((p) => p.ProviderId === providerfacility.Provider.Id);
                let assignZipCodes = providerfacility.Facilities.map((f) => f.ZipCode);
                if (Boolean(providerZipcode)) {
                    providerZipcode.ZipCodes =
                        assignZipCodes.length > 0
                            ? assignZipCodes.filter(function (itm, i, a) {
                                  return i === a.indexOf(itm);
                              })
                            : [zipCodes[0]];
                }
            });
        } else {
            const defaultProvider = Boolean(tccProviders.length) ? tccProviders[0] : null;
            setSelectedTab(Boolean(defaultProvider) ? defaultProvider.Id : '');
            setAssignedProvider([{ Provider: defaultProvider, Facilities: [] }]);
        }
        setProviderZipCodes(providerZipcodes);
    }, [assignedFacilities, tccProviders, zipCodes]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (Boolean(selectedTab)) {
            let selectedProvider = assignedProvider.find((p) => p.Provider.Id === selectedTab);
            if (Boolean(selectedProvider)) {
                let assignZipCodes = providerZipCodes.find((f) => f.ProviderId === selectedTab)?.ZipCodes;
                setFilteredZipCodes(assignZipCodes);
                let facilities = roleFacilities.filter((f) => assignZipCodes.includes(f.ZipCode));
                facilities = facilities.sort((a, b) => a.ZipCode.localeCompare(b.ZipCode));
                setFilteredFacilities(facilities);
                if (facilities.length > 0 && selectedProvider.Facilities.length === facilities.length) {
                    dispatch(setSelectAllFacilities(true));
                } else {
                    dispatch(setSelectAllFacilities(false));
                }
            } else {
                let currentProvider = tccProviders.find((p) => p.Id === selectedTab);
                let facilities = roleFacilities.filter((f) => f.ZipCode === zipCodes[0]);
                facilities = facilities.sort((a, b) => a.ZipCode.localeCompare(b.ZipCode));
                const providerInfo = { Provider: currentProvider, Facilities: [] };
                setAssignedProvider([...assignedProvider, providerInfo]);
                setFilteredZipCodes([zipCodes[0]]);
                setFilteredFacilities(facilities);
                dispatch(setSelectAllFacilities(false));
            }
        }
        // eslint-disable-next-line
    }, [selectedTab]);

    const selectAllHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { providers, selectedProvider, providerIndex } = getProviderCopy();
        if (Boolean(selectedProvider)) {
            if (e.target.checked) {
                dispatch(setSelectAllFacilities(true));
                providers[providerIndex] = { ...selectedProvider, Facilities: filteredFacilities };
            } else {
                dispatch(setSelectAllFacilities(false));
                providers[providerIndex] = { ...selectedProvider, Facilities: [] };
            }
            setAssignedProvider(providers);
        }
    };

    const onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { providers, selectedProvider, providerIndex } = getProviderCopy();
        if (Boolean(selectedProvider)) {
            const index = selectedProvider.Facilities.findIndex((perm) => perm.Id === e.target.id);
            if (index === -1) {
                let fFacility = filteredFacilities.find((f) => f.Id === e.target.id);
                providers[providerIndex] = { ...selectedProvider, Facilities: [...selectedProvider.Facilities, fFacility] };
            } else {
                providers[providerIndex] = { ...selectedProvider, Facilities: selectedProvider.Facilities.filter((perm) => perm.Id !== e.target.id) };
            }
            setAssignedProvider(providers);

            let assignZipCodes = providerZipCodes.find((f) => f.ProviderId === selectedTab)?.ZipCodes;
            const facilities = roleFacilities.filter((f) => assignZipCodes.includes(f.ZipCode));
            if (facilities.length > 0 && providers[providerIndex].Facilities.length === facilities.length) {
                dispatch(setSelectAllFacilities(true));
            } else {
                dispatch(setSelectAllFacilities(false));
            }
        }
    };

    const getProviderCopy = () => {
        let providers = [...assignedProvider];
        let selectedProvider = providers.find((p) => p.Provider.Id === selectedTab);
        let providerIndex = providers.indexOf(selectedProvider);
        return { providers, selectedProvider, providerIndex };
    };

    const onZipCodeChange = (event, zipcodes) => {
        setFilteredZipCodes(zipcodes);
        let providerZipcode = providerZipCodes.find((f) => f.ProviderId === selectedTab);
        providerZipcode.ZipCodes = zipcodes;

        let facilities = roleFacilities.filter((f) => zipcodes.includes(f.ZipCode));
        facilities = facilities.sort((a, b) => a.ZipCode.localeCompare(b.ZipCode));
        setFilteredFacilities(facilities);
        let { providers, selectedProvider, providerIndex } = getProviderCopy();
        if (Boolean(selectedProvider)) {
            const aFacilities = selectedProvider.Facilities.filter((f) => zipcodes.includes(f.ZipCode));
            providers[providerIndex] = { ...selectedProvider, Facilities: aFacilities };
            setAssignedProvider(providers);

            if (facilities.length > 0 && providers[providerIndex].Facilities.length === facilities.length) {
                dispatch(setSelectAllFacilities(true));
            } else {
                dispatch(setSelectAllFacilities(false));
            }
        }
    };

    const tabChangeHandler = (e, newValue) => {
        setSelectedTab(newValue);
    };

    const handleOnSave = () => {
        const facilities = assignedProvider.filter((f) => f.Facilities.length > 0);
        dispatch(setAssignedFacilities(facilities));
        if (openNew) {
            dispatch(
                insertRole({
                    Id: '',
                    Name: roleName,
                    Permissions: rolePermissions,
                    Widgets: assignedWidgets,
                    ProviderFacilities: facilities,
                })
            );
        } else {
            dispatch(
                updateRole({
                    Id: selectedRole.Id,
                    Name: roleName,
                    Permissions: rolePermissions,
                    Widgets: assignedWidgets,
                    ProviderFacilities: facilities,
                })
            );
        }
    };

    const selectedFailityIds = assignedProvider.find((f) => f.Provider.Id === selectedTab)?.Facilities?.map((i) => i.Id) ?? [];

    return (
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12} justifyContent="center" display="flex">
                    <Box sx={{ maxWidth: { xs: 320, sm: 1000 }, bgcolor: 'background.paper' }}>
                        <Tabs
                            value={selectedTab}
                            onChange={tabChangeHandler}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {(tccProviders || [])?.map((el) => {
                                return (
                                    <Tab key={el.Id} disabled={selectedRole.Name.length === 0 && !openNew ? true : false} value={el.Id} label={el.FullName} />
                                );
                            })}
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                        label="Select All Facilities"
                        control={<Switch checked={selectAllFacilities} onChange={selectAllHandler} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={zipCodes}
                        getOptionLabel={(option) => option}
                        defaultValue={[zipCodes[1]]}
                        filterSelectedOptions
                        value={filteredZipCodes}
                        disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                        renderInput={(params) => <TextField {...params} label="Zip Codes" placeholder="Select" />}
                        onChange={onZipCodeChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup sx={{ padding: 2, display: 'flex', flexDirection: 'row' }}>
                        {filteredFacilities.length
                            ? filteredFacilities?.map((el) => {
                                  return (
                                      <Grid item xs={4} key={el.Id}>
                                          <FormControlLabel
                                              label={
                                                  <Box>
                                                      <Typography variant="body1">{el.Name}</Typography>
                                                      <Typography variant="body2" color="gray">
                                                          {el.Address}
                                                      </Typography>
                                                  </Box>
                                              }
                                              control={
                                                  <Switch
                                                      id={el.Id}
                                                      checked={selectedFailityIds.includes(el.Id)}
                                                      disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                                                      value={el.Name}
                                                      onChange={onToggleChange}
                                                  />
                                              }
                                          />
                                      </Grid>
                                  );
                              })
                            : null}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent={'flex-end'}>
                    <Button
                        className="button-120"
                        sx={{ marginRight: 2 }}
                        disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                        variant="contained"
                        onClick={handleOnSave}
                    >
                        Save
                    </Button>
                    <Button className="button-120" variant="contained" onClick={() => dispatch(closeEditHandler())}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Facility;
