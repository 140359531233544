import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import b64toBlob from 'b64-to-blob';
import axios from 'axios';

import { convertObjectToArray } from '../utils/common';
import { EngagementDraft, ReasonForVisit } from '../Models/DocumentEngagements/EngagementDraft.model';
import { gotoNextMenu, setAssessmentSuccess, updateAssessmentMenu } from './assessment.slice';
import { AssessmentHistoryPreview } from '../Models/Assessment/AssessmentHistoryPreview.model';
import Authorization from '../utils/Authorization';
import { MemberAssessmentHistoryDto } from '../Models/Member/MemberAssessmentHistoryDto.model';
import { assessmentMenuEnum, roleEnum } from '../utils/assessments';
import { PrepareMenuClass } from '../Models/Shared/PrepareMenuClass.model';
import moment from 'moment';
import { Role } from '../Models/Role/Role.model';
import { DocumentEngagement } from '../Models/DocumentEngagements/DocumentEngagement.model';
import { PartTwoDownloadEnum } from '../Enum/PartTwoDownloadEnum';
import { getMemberBasicInfo } from './memberDetail.slice';
import { MemberStatusEnum } from '../Enum/MemberStatusEnum';

const documentEngagementSlice = createSlice({
    name: 'documentEngagement',
    initialState: {
        meansOfEngagements: [],
        reasonForVisits: [],
        engagementDraft: new EngagementDraft(),
        engagementDraftPreviews: [] as AssessmentHistoryPreview[],
        assessmentHistoryPreviews: [] as AssessmentHistoryPreview[],
        memberAssessmentHistory: new MemberAssessmentHistoryDto(),
        documentEngagements: [] as DocumentEngagement[],
        documentEngagement: new DocumentEngagement(),
        generatingPdfEngagementIds: [] as string[],
        appointmentId: '',
        showSummaryNoteModal: false,
        showDocEngagementModal: false,
        isFetchingDocumentEngagements: false,
        isFetchingPreviews: false,
        isFetchingHistory: false,
        isOpenSavedDraftsModel: false,
        isMemberHistoryFetching: false,
        isFetching: false,
        isSaving: false,
        isGeneratingPDF: false,
        downloadVerificationModal: { Show: false, ShowVerificationOptions: false, Message: '', EngagementId: '' },
    },
    reducers: {
        _setMeansOfEngagements(state, action) {
            state.meansOfEngagements = action.payload;
        },
        _setReasonForVisits(state, action) {
            state.reasonForVisits = action.payload;
        },
        _setEngagementDraft(state, action) {
            state.engagementDraft = action.payload;
        },
        _setAppointmentId(state, action) {
            state.appointmentId = action.payload;
        },
        _setEngagementDraftPreviews(state, action) {
            state.engagementDraftPreviews = action.payload;
        },
        _setAssessmentHistoryPreviews(state, action) {
            state.assessmentHistoryPreviews = action.payload;
        },
        _setMemberAssessmentHistory(state, action) {
            state.memberAssessmentHistory = action.payload;
        },
        _setDocumentEngagements(state, action) {
            state.documentEngagements = action.payload;
        },
        _setDocumentEngagement(state, action) {
            state.documentEngagement = action.payload;
        },
        _toggleShowSummaryNoteModal(state, action) {
            state.showSummaryNoteModal = action.payload;
        },
        _toggleShowDocEngagementModal(state, action) {
            state.showDocEngagementModal = action.payload;
        },
        _toggleIsFetchingPreviews(state, action) {
            state.isFetchingPreviews = action.payload;
        },
        _toggleIsFetchingDocumentEngagements(state, action) {
            state.isFetchingDocumentEngagements = action.payload;
        },
        _toggleIsFetchingHistory(state, action) {
            state.isFetchingHistory = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsOpenSavedDraftsModel(state, action) {
            state.isOpenSavedDraftsModel = action.payload;
        },
        _toggleIsMemberHistoryFetching(state, action) {
            state.isMemberHistoryFetching = action.payload;
        },
        _toggleIsGeneratingPDF(state, action) {
            state.isGeneratingPDF = action.payload;
        },
        _toggleGeneratingPdfEngagementIds(state, action) {
            if (state.generatingPdfEngagementIds.some((id) => id === action.payload)) {
                state.generatingPdfEngagementIds = state.generatingPdfEngagementIds.filter((id) => id !== action.payload);
            } else {
                state.generatingPdfEngagementIds = [...state.generatingPdfEngagementIds, action.payload];
            }
        },
        _setDownloadVerificationModal(state, action) {
            state.downloadVerificationModal = action.payload;
        },
        _resetDocumentEngagement(state, action) {
            state.meansOfEngagements = [];
            state.reasonForVisits = [];
            state.engagementDraft = new EngagementDraft();
            state.engagementDraftPreviews = [] as AssessmentHistoryPreview[];
            state.assessmentHistoryPreviews = [] as AssessmentHistoryPreview[];
            state.memberAssessmentHistory = new MemberAssessmentHistoryDto();
            state.documentEngagements = [] as DocumentEngagement[];
            state.documentEngagement = new DocumentEngagement();
            state.generatingPdfEngagementIds = [] as string[];
            state.appointmentId = '';
            state.showSummaryNoteModal = false;
            state.showDocEngagementModal = false;
            state.isFetchingDocumentEngagements = false;
            state.isFetchingPreviews = false;
            state.isFetchingHistory = false;
            state.isOpenSavedDraftsModel = false;
            state.isMemberHistoryFetching = false;
            state.isFetching = false;
            state.isSaving = false;
            state.isGeneratingPDF = false;
            state.downloadVerificationModal = { Show: false, ShowVerificationOptions: false, Message: '', EngagementId: '' };
        },
    },
});

const {
    _setMeansOfEngagements,
    _setReasonForVisits,
    _setEngagementDraft,
    _setAppointmentId,
    _setEngagementDraftPreviews,
    _setAssessmentHistoryPreviews,
    _setMemberAssessmentHistory,
    _setDocumentEngagements,
    _setDocumentEngagement,
    _toggleGeneratingPdfEngagementIds,
    _toggleShowSummaryNoteModal,
    _toggleShowDocEngagementModal,
    _toggleIsFetchingPreviews,
    _toggleIsFetchingDocumentEngagements,
    _toggleIsFetchingHistory,
    _toggleIsFetching,
    _toggleIsSaving,
    _toggleIsOpenSavedDraftsModel,
    _toggleIsMemberHistoryFetching,
    _toggleIsGeneratingPDF,
    _setDownloadVerificationModal,
    _resetDocumentEngagement
} = documentEngagementSlice.actions;

export const resetDocumentEngagement = (): AppThunk => (dispatch, getState) => {
    dispatch(_resetDocumentEngagement(''));
};

export const setDocumentEngagements =
    (state: DocumentEngagement[]): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setDocumentEngagements(state));
    };

export const getDocumentEngagementByMemberId =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetchingDocumentEngagements(true));
        const response = await axios.get(`${API_URL}/documentengagement/getdocumentengagementbymemberid`, { params: { memberId } });
        dispatch(_setDocumentEngagements(response.data));
        dispatch(_toggleIsFetchingDocumentEngagements(false));
    };

export const getEngagementDraftPreview =
    (memberId: string, openDraftModal = false, roleList: Role[] = []): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetchingPreviews(true));
        const response = await axios.get(`${API_URL}/assessment/getengagementdraftpreview`, { params: { memberId } });
        dispatch(_setEngagementDraftPreviews(response.data));
        dispatch(_toggleIsFetchingPreviews(false));
        if (openDraftModal) {
            if (response.data.length) {
                dispatch(_toggleIsOpenSavedDraftsModel(true));
            } else {
                dispatch(getMemberAssessmentHistoryForWorkflow(memberId, null, null, roleList));
            }
        }
    };

export const getAssessmentWorkflowHistory =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetchingHistory(true));
        const response = await axios.get(`${API_URL}/assessment/getassessmentworkflowhistory`, { params: { memberId } });
        dispatch(_setAssessmentHistoryPreviews(response.data));
        dispatch(_toggleIsFetchingHistory(false));
    };

export const getMemberAssessmentHistoryForWorkflow =
    (memberId: string, id: string = null, role: string = null, roleList: Role[] = []): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsMemberHistoryFetching(true));
        const authData = new Authorization();
        const member = getState().memberDetail.memberBasicInfo;
        const authRole = roleList.some((r) => r.Name === authData.Role) ? authData.Role : '';
        if (
            !Boolean(role) &&
            !member?.IsInHra &&
            !member?.IsInFuhFum &&
            (member?.Status === MemberStatusEnum.UndeterminedDescription || member?.Status === MemberStatusEnum.ScheduledDescription)
        ) {
            role = roleEnum.FIELD_ONBOARDING;
        } else {
            const isFuhfumAssessmentShow =
                member?.IsInFuhFum && authData.Role !== roleEnum.NURSE_PRACTITIONER && authData.Role !== roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST;
            role = role || (member?.IsInHra ? roleEnum.HRA : isFuhfumAssessmentShow ? roleEnum.TRANSITIONAL_CARE_COORDINATOR : authRole);
            if (!Boolean(role)) {
                role = roleEnum.CEO;
            }
        }

        if (Boolean(role)) {
            const response = await axios.get(`${API_URL}/assessment/getmemberassessmenthistoryforworkflow`, {
                params: { memberId, id, role },
            });
            dispatch(_setMemberAssessmentHistory(response.data));
            const engagementDraft = response.data?.EngagementDraft as EngagementDraft;
            role = engagementDraft.AssessedRole?.RoleName || role;
            dispatch(
                updateAssessmentMenu({
                    ActiveMenu: assessmentMenuEnum.CONSENT,
                    Role: role,
                    EngagementDraft: engagementDraft,
                    MemberAssessmentHistory: response.data,
                } as PrepareMenuClass)
            );
            dispatch(_setEngagementDraft(engagementDraft));
            dispatch(_toggleIsMemberHistoryFetching(false));
            dispatch(toggleIsOpenSavedDraftsModel(false));

            if (Boolean(response.data?.EngagementDraft?.ConsentFormId)) {
                dispatch(
                    updateAssessmentMenu({
                        ActiveMenu: assessmentMenuEnum.CONSENT,
                        EngagementDraft: engagementDraft,
                        MemberAssessmentHistory: response.data,
                    } as PrepareMenuClass)
                );
            }
        }
    };

export const deleteAssessmentDrafts =
    (ids: string[]): AppThunk =>
    async (dispatch, getState) => {
        const engagementDraftPreviews = getState().documentEngagement.engagementDraftPreviews;
        dispatch(_setEngagementDraftPreviews(engagementDraftPreviews.filter((e) => !ids.some((i) => i === e.Id))));
        const parseIds = ids.toString();
        await axios.delete(`${API_URL}/assessment/deleteassessmentdrafts`, { params: { parseIds } });
        dispatch(setAssessmentSuccess({ Show: true, Message: 'Deleted Successfully!' }));
    };

export const getMeansOfEngagements = (): AppThunk => async (dispatch, getState) => {
    dispatch(_toggleIsFetching(true));
    const response = await axios.get(`${API_URL}/documentengagement/getmeansofengagement`);
    dispatch(_setMeansOfEngagements(convertObjectToArray(response.data)));
    dispatch(_toggleIsFetching(false));
};

export const getReasonForVisit =
    (callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/documentengagement/getreasonforvisit`);
        response.data = response.data.filter(
            (r) =>
                r.Id !== '56695e83ad78ee0e48b04a69' &&
                r.Id !== '56695e83ad78ee0e48b04a62' &&
                r.Id !== '56695e83ad78ee0e48b04a67' &&
                r.Id !== '56695e83ad78ee0e48b04a68'
        );
        dispatch(_setReasonForVisits(response.data));
        dispatch(_toggleIsFetching(false));
        if (Boolean(callback)) {
            callback(response.data);
        }
    };

export const setReasonForVisit =
    (list: ReasonForVisit[]): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setReasonForVisits(list));
    };

export const toggleShowSummaryNoteModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowSummaryNoteModal(show));
    };

export const toggleShowDocEngagementModal =
    (show: boolean, documentEngagement = new DocumentEngagement()): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowDocEngagementModal(show));
        dispatch(_setDocumentEngagement(documentEngagement));
    };

export const saveEngagementDraft =
    (engagementDraft: EngagementDraft, gotoNext: boolean = false, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        dispatch(_setEngagementDraft(engagementDraft));

        try {
            const url = Boolean(engagementDraft.Id)
                ? `${API_URL}/documentengagement/updateengagementdraft`
                : `${API_URL}/documentengagement/insertengagementdraft`;
            const response = await axios.put(url, engagementDraft);
            dispatch(_setEngagementDraft(response.data));
            console.log('response.data', response.data);
            const memberAssessmentHistory = getState().documentEngagement.memberAssessmentHistory;
            dispatch(_setMemberAssessmentHistory({ ...memberAssessmentHistory, ...engagementDraft }));
        } catch (error) {}

        dispatch(_toggleIsSaving(false));
        if (gotoNext) {
            dispatch(gotoNextMenu());
        }
        if (Boolean(callBack)) {
            callBack();
        }
        if (!Boolean(engagementDraft.Id) && Boolean(engagementDraft.MemberId)) {
            dispatch(getMemberBasicInfo(engagementDraft.MemberId));
        }
    };

export const saveDocumentEngagement =
    (documentEngagement: DocumentEngagement, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));

        const request = Boolean(documentEngagement.Id)
            ? axios.put(`${API_URL}/documentengagement/updatedocumentengagement`, documentEngagement)
            : axios.post(`${API_URL}/documentengagement/insertdocumentengagement`, documentEngagement);
        request
            .then((response) => {
                dispatch(toggleShowDocEngagementModal(false));
                if (Boolean(callBack)) {
                    callBack();
                }
                dispatch(getDocumentEngagementByMemberId(documentEngagement.MemberId));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const setEngagementDraftPreviews =
    (engagementDraftPreviews: AssessmentHistoryPreview[]): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setEngagementDraftPreviews(engagementDraftPreviews));
    };

export const setEngagementDraft =
    (engagementDraft: EngagementDraft): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setEngagementDraft(engagementDraft));
    };

export const setAppointmentId =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setAppointmentId(id));
    };

export const updateAppointmentIdListToStatus =
    (appointmentStatus: number, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        const appointmentId = getState().documentEngagement.appointmentId || getState().documentEngagement?.engagementDraft?.AssociatedAppointmentId;
        dispatch(_toggleIsSaving(true));
        axios
            .post(`${API_URL}/scheduling/UpdateAppointmentIdListToStatus/${appointmentStatus}`, [appointmentId])
            .then((response) => {
                dispatch(setAssessmentSuccess({ Show: true, Message: 'Appointment statuses successfully updated!' }));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
                if (callBack) {
                    callBack();
                }
            });
    };

export const toggleIsOpenSavedDraftsModel =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsOpenSavedDraftsModel(show));
    };

export const generateAssessmentWorkflowPDF =
    (engagementId: string, partTwoDownload = PartTwoDownloadEnum.None): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsGeneratingPDF(true));
        dispatch(_toggleGeneratingPdfEngagementIds(engagementId));
        axios
            .get(`${API_URL}/assessment/generateAssessmentWorkflowPDF`, { params: { engagementId, partTwoDownload } })
            .then((response) => {
                var blob = b64toBlob(response.data.base64String, 'application/pdf');
                var blobUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                var randamNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
                var fileName = `History-${moment(new Date()).format('MM-DD-yyyy')}-${randamNumber}`;
                document.body.appendChild(a);
                a.style.display = 'none';
                a.href = blobUrl;
                a.download = fileName + '.pdf';
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                dispatch(resetDownloadVerificationModal());
            })
            .catch((err) => {
                dispatch(
                    _setDownloadVerificationModal({
                        Show: err.response?.data?.RequiredVerification,
                        Message: err.response?.data?.Message,
                        EngagementId: engagementId,
                    })
                );
            })
            .finally(() => {
                dispatch(_toggleGeneratingPdfEngagementIds(engagementId));
                dispatch(_toggleIsGeneratingPDF(false));
            });
    };

export const resetDownloadVerificationModal =
    (data?: any): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setDownloadVerificationModal(data || { Show: false, ShowVerificationOptions: false, Message: '', EngagementId: '' }));
    };

export const checkIsOnboardedAndGetReason =
    (memberId: string, draftId?: string, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/assessment/ismemberonboarded`, { params: { memberId, draftId } });
        dispatch(getReasonForVisit((reasonForVisits) => callBack(reasonForVisits, response.data)));
    };

export default documentEngagementSlice.reducer;
