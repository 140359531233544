import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, Paper, TextField, IconButton, Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchDocumentTrackingSummary, MenuProps } from '../../Widgets/WidgetService';
import DocumentTrackingSummary from '../../../Models/Widget/DocumentTrackingSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import Authorization from '../../../utils/Authorization';
import { getDateFilter } from '../../HedisMetrics/HedisMetricService';
import '../Widgets.css';

const DocumentTrackingSummaries = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const history = useNavigate();
    const {
        appointmentSelectedProvider,
        setAppointmentSelectedProvider,
        appointmentSelectedTeam,
        setAppointmentSelectedTeam,
        appointmentDateFilter,
        setAppointmentDateFilter,
        appointmentFromDate,
        setAppointmentFromDate,
        appointmentToDate,
        setAppointmentToDate,
        appointmentShowInActiveProviders,
        setAppointmentShowInActiveProviders,
        appointmentShowInActiveTeams,
        setAppointmentShowInActiveTeams,
    } = useWidgetContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [documentSummary, setDocumentSummary] = useState<DocumentTrackingSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [loadedPage, setLoadedPage] = useState(false);
    const filteredTeams = (appointmentShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (appointmentShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const filterList = getDateFilter().filter((d) => d.Value !== 'Custom' && d.Value !== 'Current Month');

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        setChartOptions(
            configureStackedBarChart(
                'Document Tracking Summary',
                'Appointment Count',
                'Appointment',
                'Count',
                [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                [0],
                'Appointment',
                history
            )
        );

        if (Boolean(authData.TeamId)) {
            setAppointmentSelectedTeam(authData.TeamId);
        }

        setIsFetched(true);
    }, [isFetched, history, authData.TeamId, setAppointmentSelectedTeam]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (activeProviders?.length && teams?.length) {
            setLoadedPage(true);
        }
    }, [teams, activeProviders, loadedPage, authData.TeamId, setAppointmentSelectedTeam]);

    useEffect(() => {
        if (loadedPage && (appointmentDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(appointmentFromDate) && Boolean(appointmentToDate)))) {
            const changeFilter = async () => {
                setIsGraphShow(false);
                const data = await fetchDocumentTrackingSummary(
                    appointmentSelectedProvider,
                    appointmentSelectedTeam,
                    appointmentDateFilter,
                    Boolean(appointmentFromDate) && appointmentDateFilter === TimePeriodFilterEnum.Custom ? new Date(appointmentFromDate).toISOString() : '',
                    Boolean(appointmentToDate) && appointmentDateFilter === TimePeriodFilterEnum.Custom ? new Date(appointmentToDate).toISOString() : '',
                    appointmentShowInActiveTeams,
                    appointmentShowInActiveProviders,
                    false
                );
                setDocumentSummary(data);
            };
            setChartOptions(
                configureStackedBarChart(
                    'Document Tracking Summary',
                    'Appointment Count',
                    'Appointment',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'Appointment',
                    history
                )
            );
            changeFilter();
        }

        if (appointmentDateFilter !== TimePeriodFilterEnum.Custom && Boolean(appointmentFromDate) && Boolean(appointmentToDate)) {
            setAppointmentFromDate(null);
            setAppointmentToDate(null);
        }
    }, [
        loadedPage,
        appointmentDateFilter,
        appointmentFromDate,
        appointmentToDate,
        setAppointmentFromDate,
        setAppointmentToDate,
        history,
        appointmentSelectedTeam,
        appointmentSelectedProvider,
        appointmentShowInActiveTeams,
        appointmentShowInActiveProviders,
    ]);

    useEffect(() => {
        if (documentSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let visitedValues = [];
            let notVisitedValues = [];

            let visitedPercentage =
                documentSummary.TotalAppointments > 0 ? (documentSummary.DocumentedAppointmentsCount / documentSummary.TotalAppointments) * 100 : 0;
            let d = {
                y: documentSummary.DocumentedAppointmentsCount,
                name: Math.round(visitedPercentage),
            };
            visitedValues.push(d);

            let notVisitedPercentage =
                documentSummary.TotalAppointments > 0 ? (documentSummary.NotDocumentedAppointmentsCount / documentSummary.TotalAppointments) * 100 : 0;
            let d1 = {
                y: documentSummary.NotDocumentedAppointmentsCount,
                name: Math.round(notVisitedPercentage),
            };
            notVisitedValues.push(d1);
            xAxisValues.push('Appointments');

            yAxisValues.push({ name: 'Documented', data: visitedValues, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Not Documented', data: notVisitedValues, color: '#ff7d7d', showInLegend: true });

            if (documentSummary.DocumentedAppointmentsCount === 0 && documentSummary.NotDocumentedAppointmentsCount === 0) {
                setChartOptions(
                    configureStackedBarChart(
                        'Document Tracking Summary',
                        'Appointment Count',
                        'Appointment',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'Appointment',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedBarChart('Appointment Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, 'Appointment', history)
                );
            }

            setIsGraphShow(true);
        }
    }, [documentSummary, history]);

    const changeFromDate = (date: any) => {
        setAppointmentFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(appointmentToDate) || new Date(appointmentToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setAppointmentToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setAppointmentToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(appointmentFromDate) || new Date(appointmentFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setAppointmentFromDate(startDate);
        }
    };

    const navigateToMembers = () => {
        history(`/provider/metricsummary/appointmentmemberdetail`);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setAppointmentShowInActiveTeams(isChecked);
        setAppointmentSelectedTeam('All');
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setAppointmentShowInActiveProviders(isChecked);
        setAppointmentSelectedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    Document Tracking Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Documented :
                                            </Typography>
                                            <Typography variant="caption">Appointments that have been documented within three business days of the appointment date</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Not Documented :
                                            </Typography>
                                            <Typography variant="caption">Doesn't documented within three business days</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={appointmentSelectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setAppointmentSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox checked={appointmentShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={appointmentSelectedTeam}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setAppointmentSelectedTeam(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={appointmentShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={appointmentDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setAppointmentDateFilter(parseInt(e.target.value as any))}
                                >
                                    {filterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {appointmentDateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(appointmentFromDate) ? appointmentFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(appointmentToDate) ? appointmentToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default DocumentTrackingSummaries;
