import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { localDateAndTimeFormat, localDateFormat } from '../../../utils/timeFormat';
import { isDefaultDate } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
    setOnboardingStartDate,
    setOnboardingEndDate,
    fetchOnboardingCallsReport,
    setSelectedHealthPlan,
    setSelectedPopulation,
    setSelectedNoteType,
    setSelectedOnboarder,
    setExporting,
} from '../../../store/onboardingMemberReport.slice';
import { RootState } from '../../../reducers';
import { fetchHealthPlanList } from '../../../store/shared.slice';
import { usePopulationContext } from '../../../hooks/populationContext';
import GetAppIcon from '@mui/icons-material/GetApp';
import { activityOptions, getOnboardingProviders } from '../../../store/onboardingWorkflow.slice';
import b64toBlob from 'b64-to-blob';
import { fetchUnreachedOnboardingCallsExcelReport } from '../AdminOnboardingService';
import { OnboardingCallReport } from '../../../Models/AdminOnboarding/OnboardingCallReport';

const UnreachedOnboardingMembersReport = () => {
    const {
        exporting,
        isTableFetching,
        onboardingReportStartDate,
        onboardingReportEndDate,
        selectedHealthPlan,
        selectedNoteType,
        selectedOnboarder,
        selectedPopulation,
        unreachablePhonesData,
    } = useSelector((state: RootState) => state.onboardingMemberReport);
    const { healthPlans } = useSelector((state: RootState) => state.shared);
    const { onboardingProviders } = useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const { populations, getPopulations } = usePopulationContext();

    const dispatch = useDispatch();

    const activityOptionsList = Object.keys(activityOptions).map((key) => activityOptions[key]);

    const handleChange = (event: SelectChangeEvent<typeof selectedNoteType>) => {
        const {
            target: { value },
        } = event;
        dispatch(setSelectedNoteType(value as string[]));
    };

    useEffect(() => {
        if (!Boolean(populations?.length)) {
            getPopulations();
        }
    }, [dispatch, populations, getPopulations]);

    useEffect(() => {
        dispatch(getOnboardingProviders());
        dispatch(fetchHealthPlanList());
    }, [dispatch]);

    const columns: GridColDef[] = [
        {
            field: 'Mem_AHCCCS_ID',
            headerName: 'AHCCCS Id',
            minWidth: 130,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 130,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'PlanId',
            headerName: 'Health Plan',
            minWidth: 180,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
                let found = healthPlans.find((x) => x.Id === params.row.PlanId);
                return found ? found.OrganizationName : params.row.PlanId;
            },
        },
        {
            field: 'PopulationId',
            headerName: 'Population Name',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
                let found = populations.find((x) => x.value === params.row.PopulationId);
                return found ? found.name : params.row.PopulationId;
            },
        },
        {
            field: 'CreatedDate',
            headerName: 'Created Date',
            minWidth: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CreatedDate) ? '-' : localDateAndTimeFormat(new Date(params.row.CreatedDate))}`,
        },
        {
            field: 'NoteType',
            headerName: 'Note Type',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'Note',
            headerName: 'Note',
            minWidth: 300,
            flex: 1.5,
        },
        {
            field: 'UserName',
            headerName: 'Onboarder Name',
            width: 150,
        },
    ];

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 86) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doExportButtonClick = async () => {
        dispatch(setExporting(true));
        const payload = {
            startDateTime: onboardingReportStartDate,
            endDateTime: onboardingReportEndDate,
            selectedHealthPlan,
            selectedPopulation,
            selectedNoteType,
            selectedOnboarder,
        };

        const response = await fetchUnreachedOnboardingCallsExcelReport(payload);

        if (response.status === 200) {
            const data = await response.data;
            const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            const today = new Date();
            const randomNumber = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
            const fileName =
                (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
                '.' +
                today.getDate() +
                '.' +
                today.getFullYear() +
                '-' +
                randomNumber;
            document.body.appendChild(a);
            a.href = blobUrl;
            a.download = fileName + '.xlsx';
            a.click();
            window.URL.revokeObjectURL(blobUrl);
            dispatch(setExporting(false));
        }
    };

    return (
        <Card variant="outlined" style={{ minWidth: '400px', padding: '16px' }}>
            <CardHeader
                title="Onboarding Calls Report"
                action={
                    <Button variant="contained" color="primary" disabled={exporting || isTableFetching} onClick={doExportButtonClick}>
                        {!exporting ? (
                            <GetAppIcon style={{ marginRight: '8px', color: 'white' }} />
                        ) : (
                            <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />
                        )}
                        <span style={{ color: 'white' }}>Export to Excel</span>
                    </Button>
                }
            />

            <CardContent>
                <Grid container alignItems="center" justifyContent="center" item lg direction="row">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12}>
                            <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                                <FormControl className="form-control-200">
                                    <DesktopDatePicker
                                        label="Start Date:"
                                        value={onboardingReportStartDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            dispatch(setOnboardingStartDate(newValue));
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <DesktopDatePicker
                                        label="End Date:"
                                        value={onboardingReportEndDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            dispatch(setOnboardingEndDate(newValue));
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>

                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Health Plan</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedHealthPlan}
                                        value={selectedHealthPlan}
                                        label="Health Plan"
                                        onChange={(e) => {
                                            dispatch(setSelectedHealthPlan(e.target.value));
                                        }}
                                    >
                                        <MenuItem value={'-1'}>All</MenuItem>
                                        {healthPlans.map((option) => (
                                            <MenuItem key={option.Id} value={option.Id}>
                                                {option.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Population</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedPopulation}
                                        value={selectedPopulation}
                                        label="Population"
                                        onChange={(e) => {
                                            console.log('e.target.value: ', e.target.value);
                                            dispatch(setSelectedPopulation(Number(e.target.value)));
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 300,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={-1}>All</MenuItem>
                                        {populations
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>

                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Note Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedNoteType}
                                        value={selectedNoteType}
                                        label="Note Type"
                                        multiple
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 450,
                                                },
                                            },
                                        }}
                                    >
                                        {activityOptionsList.sort().map((option, index) => (
                                            <MenuItem key={`${option}_${index}`} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Onboarder</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedOnboarder}
                                        value={selectedOnboarder}
                                        label="Onboarder"
                                        onChange={(e) => {
                                            dispatch(setSelectedOnboarder(e.target.value));
                                        }}
                                    >
                                        <MenuItem value={'-1'}>All</MenuItem>
                                        {onboardingProviders.map((option) => (
                                            <MenuItem key={option.UserId} value={option.UserId}>
                                                {option.Username}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Button
                                    onClick={() => {
                                        const onboardingCallReport = new OnboardingCallReport();
                                        onboardingCallReport.startDateTime = onboardingReportStartDate;
                                        onboardingCallReport.endDateTime = onboardingReportEndDate;
                                        onboardingCallReport.selectedHealthPlan = selectedHealthPlan;
                                        onboardingCallReport.selectedPopulation = selectedPopulation;
                                        onboardingCallReport.selectedNoteType = selectedNoteType;
                                        onboardingCallReport.selectedOnboarder = selectedOnboarder;
                                        dispatch(fetchOnboardingCallsReport(onboardingCallReport));
                                    }}
                                    style={{ minWidth: '120px' }}
                                    variant="contained"
                                >
                                    Search
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item style={{ height: '80vh', width: '100%', textAlign: 'left' }}>
                            {isTableFetching ? (
                                <Stack justifyContent={'center'} alignItems={'center'}>
                                    <CircularProgress style={{ marginTop: '50px' }} size={30} color="primary" />
                                </Stack>
                            ) : (
                                <DataGrid
                                    rows={unreachablePhonesData}
                                    columns={columns}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[5]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 2,
                                        },
                                    }}
                                />
                            )}
                        </Grid>
                    </LocalizationProvider>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default UnreachedOnboardingMembersReport;
