import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = '18vw';

const ReportsPage = (props) => {
    const defaultPage = (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
            <Typography textAlign="center" variant="h5">
                Select Report To View
            </Typography>
        </Box>
    );

    const location = useLocation();
    const path = location.pathname.split('/')[3];

    const [isSelected, setIsSelected] = useState(path || '');

    const reportsList = [
        { name: 'Engagements vs Encounters Report', path: 'engagements-vs-encounters' },
        { name: 'Encounter Billing Report History', path: 'encounter-billing-report-history' },
        { name: 'Generate Encounter Billing Report', path: 'generate-encounter-billing-report' },
        { name: 'Member Birthday Report', path: 'member-birthday-report' },
        { name: 'Member Details Report', path: 'member-details-report' },
        { name: 'Member Eligibility Report', path: 'member-eligibility-report' },
        { name: 'Member Encounter Billing Report', path: 'member-encounter-billing-report' },
        { name: 'Member Status Report', path: 'member-status-report' },
        { name: 'Member Vital Signs Report', path: 'member-vital-signs-report' },
        { name: 'Onboarding Member Report', path: 'onboarding-member-report' },
        { name: 'Requests Report', path: 'requests-report' },
        { name: 'SDOH Assessment & Request Report', path: 'sdoh-assessment-request-report' },
        { name: 'Survey Report', path: 'survey-report' },
        { name: 'Team Report', path: 'team-report' },
        { name: 'Transitional Care Report', path: 'transitional-care-report' },
    ];

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        position: 'relative',
                    },
                }}
            >
                <Box component={'div'} overflow={'auto'}>
                    <List>
                        {reportsList
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((item, index) => (
                                <ListItem key={`${item.name}_${index}`} disablePadding>
                                    <ListItemButton
                                        sx={{ paddingY: '12px' }}
                                        selected={isSelected === item.path}
                                        divider
                                        component={Link}
                                        to={item.path}
                                        onClick={() => {
                                            setIsSelected(item.path);
                                        }}
                                    >
                                        <ListItemText sx={{ '& .MuiListItemText-primary': { fontSize: '15px' } }} primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Drawer>
            <Box p={2} width={'100%'}>
                {isSelected ? <Outlet /> : defaultPage}
            </Box>
        </Box>
    );
};

export default ReportsPage;
