import { PlaceLocation } from "./PlaceLocation.model";

export default interface IPlaceDetail {
    FormattedAddress: string;
    Location: PlaceLocation;
}

export class PlaceDetail implements IPlaceDetail {
    FormattedAddress: string;
    Location: PlaceLocation;

    constructor() {
        this.FormattedAddress = '';
        this.Location = null;
    }
}