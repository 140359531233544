import { useCallback, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getActiveProviders } from '../../../store/provider.slice';
import { fetchTeamsName, fetchNpOrBhTeams } from '../../../store/teams.slice';
import { usePopulationContext } from '../../../hooks/populationContext';
import Authorization from '../../../utils/Authorization';
import { getCustomDashboardTabs, setIsWidgetExisting } from '../../../store/smartDashboard.slice';
import HedisMetricSummary from './HedisMetricSummary';
import QAMetricSummary from './QAMetricSummary';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';

const MetricSummary = (props) => {
    const auth = new Authorization();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const { populations, getPopulations } = usePopulationContext();

    const {
        selectedProvider,
        showInActiveProviders,

        setAppointmentSelectedProvider,
        setAppointmentShowInActiveProviders,

        setEngagementSelectedProvider,
        setEngagementShowInActiveProviders,

        setIndividualEngagementSelectedProvider,
        setIndividualEngagementShowInActiveProviders,

        setTcTeam,
        setTcShowInActiveTeams,
    } = useWidgetContext();

    const {
        selectedTeam,
        showInActiveTeams,

        setAdultHealthTeam,
        setAdultHealthIncludePastOpenStatus,
        setAdultHealthShowInActiveTeam,

        setBpTeam,
        setBpIncludePastOpenStatus,
        setBpShowInActiveTeam,

        setBmiTeam,
        setBmiIncludePastOpenStatus,
        setBmiShowInActiveTeam,

        setHgba1cTeam,
        setHgba1cIncludePastOpenStatus,
        setHgba1cShowInActiveTeam,

        setDiabeticEyeExamTeam,
        setDiabeticEyeExamIncludePastOpenStatus,
        setDiabeticEyeExamShowInActiveTeam,

        setMedicationReconciliationTeam,
        setMedicationReconciliationIncludePastOpenStatus,
        setMedicationReconciliationShowInActiveTeam,

        setDepressionScreeningTeam,
        setDepressionScreeningIncludePastOpenStatus,
        setDepressionScreeningShowInActiveTeam,

        setDepressionFollowUpTeam,
        setDepressionFollowUpIncludePastOpenStatus,
        setDepressionFollowUpShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        dispatch(setIsWidgetExisting(true));
        setIsFetched(true);
    }, [isFetched, setIsFetched, dispatch]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        dispatch(getCustomDashboardTabs(auth.UserId, auth.Role));
        dispatch(getActiveProviders(true));
        dispatch(fetchTeamsName());
        dispatch(fetchNpOrBhTeams());
        if (!Boolean(populations?.length)) {
            getPopulations();
        }
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToSmartDashboard = () => {
        setAppointmentSelectedProvider(selectedProvider);
        setAppointmentShowInActiveProviders(showInActiveProviders);

        setEngagementSelectedProvider(selectedProvider);
        setEngagementShowInActiveProviders(showInActiveProviders);

        setIndividualEngagementSelectedProvider(selectedProvider);
        setIndividualEngagementShowInActiveProviders(showInActiveProviders);

        setHgba1cTeam(selectedTeam);
        setHgba1cIncludePastOpenStatus(true);
        setHgba1cShowInActiveTeam(showInActiveTeams);

        setDiabeticEyeExamTeam(selectedTeam);
        setDiabeticEyeExamIncludePastOpenStatus(true);
        setDiabeticEyeExamShowInActiveTeam(showInActiveTeams);

        setBpTeam(selectedTeam);
        setBpIncludePastOpenStatus(true);
        setBpShowInActiveTeam(showInActiveTeams);

        setBmiTeam(selectedTeam);
        setBmiIncludePastOpenStatus(true);
        setBmiShowInActiveTeam(showInActiveTeams);

        setAdultHealthTeam(selectedTeam);
        setAdultHealthIncludePastOpenStatus(true);
        setAdultHealthShowInActiveTeam(showInActiveTeams);

        setDepressionScreeningTeam(selectedTeam);
        setDepressionScreeningIncludePastOpenStatus(true);
        setDepressionScreeningShowInActiveTeam(showInActiveTeams);

        setDepressionFollowUpTeam(selectedTeam);
        setDepressionFollowUpIncludePastOpenStatus(true);
        setDepressionFollowUpShowInActiveTeam(showInActiveTeams);

        setMedicationReconciliationTeam(selectedTeam);
        setMedicationReconciliationIncludePastOpenStatus(true);
        setMedicationReconciliationShowInActiveTeam(showInActiveTeams);

        setTcTeam(selectedTeam);
        setTcShowInActiveTeams(showInActiveTeams);

        history(`/provider/metricsummary/smart-dashboard`);
    };

    return !isFetched ? (
        <Box className="loader-center">
            <CircularProgress />
        </Box>
    ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container rowSpacing={3} columnSpacing={2} paddingTop={2}>
                <Grid item xs={12}>
                    <Button
                        variant="text"
                        sx={{ float: 'right', marginRight: '15px' }}
                        onClick={() => {
                            navigateToSmartDashboard();
                        }}
                    >
                        Smart Dashboard
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <HedisMetricSummary></HedisMetricSummary>
                </Grid>
                <Grid item xs={6}>
                    <QAMetricSummary></QAMetricSummary>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default MetricSummary;
