import { Grid, Paper, Typography, Table, TableBody, TableHead, TableRow, TableContainer, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';

import { useWidgetContext } from '../../../../hooks/widgetContext';
import { fetchFuhFumExceptionSummary, exportFuhFumExceptionSummary } from '../../../Widgets/WidgetService';
import { FuhFumVisitTypeEnum } from '../../../../Enum/FuhFumVisitTypeEnum';
import { parentUrl } from '../../../../utils/constants';
import { isDefaultDate } from '../../../../utils/common';
import '../../Widgets.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const FuhFumExceptionSummaryDetail = () => {
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const { exceptionTab, setExceptionTab } = useWidgetContext();

    const getFuhFumSummary = async () => {
        const response = await fetchFuhFumExceptionSummary();

        setRows(response);
        let filterResponse = response.filter((m) => m.FuhFumVisitType === exceptionTab);
        setFilteredRows(filterResponse);
        setIsFetched(true);
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let filterResponse = [];
        switch (newValue) {
            case 0: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === 0);
                break;
            }
            case 1: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.InpatientAdmit);
                break;
            }
            case 2: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.EDAdmit);
                break;
            }
            case 3: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.PostDischarge);
                break;
            }
            case 4: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.EDDischarge);
                break;
            }
        }
        setFilteredRows(filterResponse);
        setExceptionTab(newValue);
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportFuhFumExceptionSummary();
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `FuhFumExceptionMembers - ${dateInfo}.xlsx`);
        link.click();
        link.remove();
        setDownLoading(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1} alignItems={'center'} display={'flex'}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            FUHFUM Exception Summary
                        </Typography>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isFetched ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={6} display={'flex'}>
                                    <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={exceptionTab} onChange={handleAlertsTabChange}>
                                        <Tab value={1} label="Inpatient admits" />
                                        <Tab value={2} label="ED admits" />
                                        <Tab value={4} label="ED discharges" />
                                        <Tab value={0} label="General" />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        style={{ minWidth: '120px', marginLeft: 'auto', float: 'right' }}
                                        onClick={() => exportDetail()}
                                        variant="contained"
                                        color="success"
                                        disabled={rows.length ? false : true}
                                    >
                                        {downLoading ? 'Exporting..' : 'Export'}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell>Last Name</StyledTableCell>
                                                    <StyledTableCell>First Name</StyledTableCell>
                                                    <StyledTableCell>DOB</StyledTableCell>
                                                    <StyledTableCell>Gender</StyledTableCell>
                                                    <StyledTableCell>Address</StyledTableCell>
                                                    <StyledTableCell>Admission Date</StyledTableCell>
                                                    <StyledTableCell>Facility</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredRows.length ? (
                                                    filteredRows.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.LastName}</StyledTableCell>
                                                            <StyledTableCell>{row.FirstName}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.DOB !== null && !isDefaultDate(row.DOB)
                                                                    ? moment(new Date(row.DOB)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.Gender}</StyledTableCell>
                                                            <StyledTableCell>{row.Address}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.AdmitDate !== null && !isDefaultDate(row.AdmitDate)
                                                                    ? moment(new Date(row.AdmitDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.Facility}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={8}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default FuhFumExceptionSummaryDetail;
