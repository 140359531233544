import { Card, CardContent, Rating, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import React from 'react';
import { IResourceReview } from './interfaces/IResourceReview';

const ResourceReview: React.FC<IResourceReview> = (review) => {
    return (
        <Card>
            <CardContent>
                <Rating name={`read-only-${review.ReviewerProviderId}`} value={review.Value} readOnly />
                <Typography variant="h6">
                    {review.ReviewerName} {new Date(review.UpdatedOn).toLocaleString()}
                </Typography>
                <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(review.Comment),
                    }}
                ></Typography>
            </CardContent>
        </Card>
    );
};

export default ResourceReview;
