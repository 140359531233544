import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Shared/Loading';
import { fetchPermissions } from '../../store/shared.slice';
import { RootState } from '../../reducers';
import {
    fetchRoles,
    openNewHandler,
    setRoleName,
    setSelectedRole,
    setRolePermissions,
    setAssignedWidgets,
    setAssignedFacilities,
    fetchHedisMetricsWidgets,
    fetchSmartDashboardWidgets,
    fetchAnalyticsWidgets,
    fetchFacilities,
    fetchFacilityZipCoeds,
    setSelectedTab,
} from '../../store/adminRoles.slice';
import { getTCCRoleProviders } from '../../store/provider.slice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Permissions from './Permissions';
import Widgets from './Widgets';
import Facility from './Facilities';

const AdminRoles = (props) => {
    const tccRoleName = 'Transitional Care Coordinator';
    const dispatch = useDispatch();
    const { openNew, openEdit, selectedRole, roles, roleName, selectedTab } = useSelector((state: RootState) => state.adminRoles);

    const tabChangeHandler = (e, newValue) => {
        dispatch(setSelectedTab(newValue));
    };
    useEffect(() => {
        dispatch(getTCCRoleProviders(false));
        dispatch(fetchRoles());
        dispatch(fetchPermissions());
        dispatch(fetchAnalyticsWidgets());
        dispatch(fetchSmartDashboardWidgets());
        dispatch(fetchHedisMetricsWidgets());
        dispatch(fetchFacilities());
        dispatch(fetchFacilityZipCoeds());
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {<Loading message={'Loading...'} />}

            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item marginTop={2} xs={12} p={2} display="flex" justifyContent="space-between">
                    <FormControl className="form-control-200">
                        <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={roles.find((role) => role === selectedRole) || '-1'}
                            label="Select Role"
                            disabled={openNew ? true : false}
                            onChange={(e) => {
                                dispatch(setSelectedRole(e.target.value));
                                dispatch(setRoleName(e.target.value.Name));
                                dispatch(setRolePermissions(e.target.value.Permissions));
                                dispatch(setAssignedWidgets(e.target.value.Widgets ? e.target.value.Widgets : []));
                                dispatch(setAssignedFacilities(e.target.value.ProviderFacilities ? e.target.value.ProviderFacilities : []));
                                dispatch(setSelectedTab(0));
                            }}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                        >
                            <MenuItem value={'-1'} disabled>
                                Select Role
                            </MenuItem>
                            {roles.map((role) => {
                                return (
                                    <MenuItem key={role.Id} value={role}>
                                        {`${role.Name}`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button variant="contained" className="button-120" onClick={() => dispatch(openNewHandler())}>
                        Add Role
                    </Button>
                </Grid>
                {(selectedRole.Name.length > 0 || (selectedRole.Name.length === 0 && openNew)) && (
                    <Grid item xs={12}>
                        <TextField
                            autoFocus={openEdit === true ? true : false}
                            sx={{ minWidth: '300px' }}
                            required
                            value={roleName}
                            id="name"
                            label="Edit Role Name"
                            name="Name"
                            placeholder="Add Role Name"
                            variant="outlined"
                            onChange={(e) => dispatch(setRoleName(e.target.value))}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Tabs variant="fullWidth" value={selectedTab} onChange={tabChangeHandler}>
                        <Tab value={0} label="Permissions" />
                        <Tab value={1} label="Smart Dashboard" />
                        {roleName === tccRoleName ? <Tab value={2} label="Facilities" /> : null}
                    </Tabs>
                </Grid>

                {selectedTab === 0 && <Permissions />}
                {selectedTab === 1 && <Widgets />}
                {selectedTab === 2 && <Facility />}
            </Grid>
        </Box>
    );
};

export default AdminRoles;
