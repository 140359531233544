import { Avatar, Grid, Typography } from '@mui/material';
import { isDefaultDate } from '../../../utils/common';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import MessageDisplay from '../../Shared/MessageDisplay';

const MemberAvatar = () => {
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);

    return (
        <Grid container spacing={2} style={{ maxWidth: '400px', float: 'left', textAlign: 'left' }}>
            <MessageDisplay />
            <Grid item xs={3}>
                <Avatar
                    alt={selectedMember.FirstName + ' ' + selectedMember.LastName}
                    src={selectedMember.ProfileImageUrl}
                    sx={{ width: 80, height: 80 }}
                    className="profile-image"
                />
            </Grid>
            <Grid item xs={9}>
                <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                    Name: <span className="profile-text">{selectedMember.FirstName + ' ' + selectedMember.LastName}</span>
                </Typography>
                <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                    AHCCS Id: <span className="profile-text">{selectedMember.Mem_AHCCCS_ID}</span>
                </Typography>
                <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                    DOB: <span className="profile-text">{Boolean(selectedMember.DOB) && !isDefaultDate(selectedMember.DOB) ? new Date(selectedMember.DOB).toLocaleDateString() : ''}</span>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default MemberAvatar;
