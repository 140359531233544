import { OnboardingMeansOfEngagementEnum } from '../../Enum/OnboardingMeansOfEngagementEnum';
import BaseEntity from '../BaseEntity.model';
import { EngagedProvider } from '../DocumentEngagements/EngagedProvider.model';

export default interface IDocumentOnboarding extends BaseEntity {
    MemberId: string;
    MeansOfEngagement: OnboardingMeansOfEngagementEnum;
    Notes: string;
    VisitedTime: string;
    CreationTime: string;
    UpdationTime: string;
    EngagedProvider: EngagedProvider;
    UpdatedProvider: EngagedProvider;
}

export class DocumentOnboarding implements IDocumentOnboarding {
    Id: string;
    MemberId: string;
    MeansOfEngagement: OnboardingMeansOfEngagementEnum;
    Notes: string;
    VisitedTime: string;
    CreationTime: string;
    UpdationTime: string;
    EngagedProvider: EngagedProvider;
    UpdatedProvider: EngagedProvider;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.MeansOfEngagement = OnboardingMeansOfEngagementEnum.OutreachCall;
    }
}
