import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, Paper, Typography } from '@mui/material';

const ConfirmDelete = (props) => {
    // open={} onClose={} name={} delete={} cancel={}

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEnforceFocus
            fullWidth
        >
            <Paper
                sx={{
                    padding: '16px',
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h6" component={'p'} color={'error'}>
                        Are you sure do you want to delete <span style={{ color: '#333', fontWeight: 'bold' }}>{props.name}</span> ?
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={props.delete} autoFocus
                        disabled={props.isDeleting}
                        endIcon={props.isDeleting ? <CircularProgress size={18} color="inherit" /> : null}>
                        Delete
                    </Button>
                    <Button variant="outlined" onClick={props.cancel}
                        disabled={props.isDeleting}>
                        Cancel
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    );
};

export default ConfirmDelete;
