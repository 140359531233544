import { EducationStatusEnum } from '../../Enum/EducationStatusEnum';
import { EducationTimeDurationEnum } from '../../Enum/EducationTimeDurationEnum';
import { LevelOfTeachbackEnum } from '../../Enum/LevelOfTeachbackEnum';
import { LevelOfUnderstandingEnum } from '../../Enum/LevelOfUnderstandingEnum';
import CreatedBy from '../Shared/LastUpdatedBy.model';
import BaseEntity from '../BaseEntity.model';

export default interface IMemberEducation extends BaseEntity {
    MemberId: string;
    PhysicalHealthType: string;
    WellnessType: string;
    Audience: string[];
    LevelOfUnderstanding: LevelOfUnderstandingEnum;
    LevelOfTeachback: LevelOfTeachbackEnum;
    Status: EducationStatusEnum;
    TimeDuration: EducationTimeDurationEnum;
    Notes: string;
    WereMaterialsSupplied: boolean;
    CreatedBy: CreatedBy;
    CreationTime: string;
    UpdatedBy: CreatedBy;
    UpdatedTime: string;
}

export class MemberEducationModel implements IMemberEducation {
    Id: string;
    MemberId: string;
    Type: EducationSessionOption;
    PhysicalHealthType: string;
    WellnessType: string;
    Audience: string[];
    LevelOfUnderstanding: LevelOfUnderstandingEnum;
    LevelOfTeachback: LevelOfTeachbackEnum;
    Status: EducationStatusEnum;
    TimeDuration: EducationTimeDurationEnum;
    Notes: string;
    WereMaterialsSupplied: boolean;
    CreatedBy: CreatedBy;
    CreationTime: string;
    UpdatedBy: CreatedBy;
    UpdatedTime: string;

    constructor() {
        this.MemberId = '';
        this.PhysicalHealthType = '';
        this.Audience = [];
    }
}

export class MemberEducationsByType {
    Key: string;
    Values: MemberEducationModel[];

    constructor() {
        this.Key = '';
        this.Values = [] as MemberEducationModel[];
    }
}

export class EducationSessionOption {
    Id: string;
    Name: string;

    constructor() {
        this.Id = '';
        this.Name = '';
    }
}
