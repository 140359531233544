const useStyles = {
    form: {
        '& .MuiFormControl-root': {
            marginBottom: '15px',
        },
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    tableContainer: {
        '&.MuiPaper-root': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },

        '& thead tr': {
            backgroundColor: '#0063a7',

            '& th': {
                color: 'white',
                padding: '8px 15px',
            },
        },
        '& tbody tr': {
            '& th, td': {
                padding: '10px 15px',
                fontWeight: 500,
            },
        },
    },
    modalPopupForm: {
        top: '15vh',
        left: '50%',
        width: '600px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',

        '& .mui-modal-header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',

            '& h6': {
                fontSize: '16px',
            },
        },

        '& .mui-modal-body': {
            padding: '15px 15px 25px',
            maxHeight: 'calc(100vh - 30vh)',
            overflow: 'auto',

            '& .MuiFormLabel-root:not(.MuiInputLabel-root)': {
                color: 'black',
                fontSize: '14px',
                fontWeight: 700,
            },
        },

        '& .mui-modal-footer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',

            '& button': {
                marginLeft: '15px',
            },
        },
    },
    hr: {
        marginLeft: '-15px',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    numberInputControl: {
        maxWidth: '170px',
    },
    headerSelectInput: {
        '&.MuiFormControl-root': {
            '& .MuiInputBase-root': {
                backgroundColor: 'white',
            },
        },
    },
    topScrollPaper: {
        '&.MuiDialog-container.MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
        },
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    selectInputLoader: {
        position: 'absolute',
        right: '9px',
        top: '10px',
    },
    manuallyEnteredColor: {
        '&:last-child td, &:last-child th': { border: 0 },
        '&.MuiTableRow-root': {
            '& td': {
                color: 'blue',
            },
        },
    },
    ePrescribeColor: {
        '&:last-child td, &:last-child th': { border: 0 },
        '&.MuiTableRow-root': {
            '& td': {
                color: '#c125bc',
            },
        },
    },
};

export default useStyles;
