import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import { CalendarEventData } from '../types/CalendarEventData';
import { useFormikContext } from 'formik';
import { MbscCalendarEventData } from '@mobiscroll/react';
import { RecurrenceUpdateMode } from '../Enums/RecurrenceUpdateModeEnum';
import { RRule } from 'rrule';

export interface IRecurrenceUpdateStrategyModalProps extends IBasicModalProps {
    createEvent: (values: CalendarEventData) => Promise<void>;
    updateEvent: (values: CalendarEventData) => Promise<void>;
    eventList: MbscCalendarEventData[];
}

const RecurrenceUpdateStrategyModal = (props: IRecurrenceUpdateStrategyModalProps) => {
    const formikContext = useFormikContext<CalendarEventData>();
    const { values } = formikContext;
    const [selectedMode, setSelectedMode] = useState<RecurrenceUpdateMode>(RecurrenceUpdateMode.Single);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedMode((event.target as HTMLInputElement).value as RecurrenceUpdateMode);
    };
    const { open, onClose, onSuccess, updateEvent, createEvent, eventList } = props;

    const handleClose = () => {
        setSelectedMode(RecurrenceUpdateMode.Single);
        onClose();
    };

    const handleSuccess = async () => {
        switch (selectedMode) {
            case RecurrenceUpdateMode.Single: {
                const originalEvent = eventList.find((x) => x.id === values.id) as CalendarEventData;

                originalEvent.recurringException = [...((originalEvent?.recurringException as any[]) ?? []), new Date(values.start).toLocaleDateString()];

                // The new event gets its recurring data scrubbed out of caution. If the user wants this to be a recurrence they can set it after.
                const newEvent: CalendarEventData = {
                    ...values,
                    recurring: undefined,
                };

                try {
                    await createEvent(newEvent);
                    await updateEvent(originalEvent);
                } catch (error) {
                    console.error(error);
                }
                
                break;
            }
            case RecurrenceUpdateMode.Future: {
                try {
                    const originalEvent = eventList.find((x) => x.id === values.id) as CalendarEventData;

                    if (new Date(originalEvent.startDate).toDateString() === new Date(values.start).toDateString()) {
                        await updateEvent(values);
                    } else {
                        const recurrenceRule = RRule.fromString(originalEvent.recurring as string);
                        const newRule = new RRule({ ...recurrenceRule.origOptions, until: new Date(values.start), count: undefined });

                        // Make the recurring string end on the new date
                        // and make sure the new date itself is an exception so it doesn't appear from the old rule.
                        originalEvent.recurring = newRule.toString().replace('RRULE:', '');
                        originalEvent.recurringException = [
                            ...((originalEvent?.recurringException as any[]) ?? []),
                            new Date(values.start).toLocaleDateString(),
                        ];
                        await createEvent(values);
                        await updateEvent(originalEvent);
                    }
                } catch (error) {
                    console.error(error);
                }
                break;
            }
            default:
                console.error('Invalid recurrence update mode');
                break;
        }
        setSelectedMode(RecurrenceUpdateMode.Single);
        onSuccess();
    };

    return (
        <Dialog open={open} hideBackdrop>
            <DialogTitle>Update Recurring Appointment</DialogTitle>
            <DialogContent>
                <RadioGroup aria-label="recurrence-update-mode" value={selectedMode} onChange={handleChange}>
                    {Object.keys(RecurrenceUpdateMode).map((mode) => (
                        <FormControlLabel key={mode} value={RecurrenceUpdateMode[mode]} control={<Radio />} label={RecurrenceUpdateMode[mode]} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSuccess}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RecurrenceUpdateStrategyModal;
