import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { usePopulationContext } from '../../../hooks/populationContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { configureStackedBarChartWithoutLegend } from '../../ChartConfiguration/ChartConfiguration';
import { getHgbA1CSummary } from '../HedisMetricService';
import HgbA1CSummary from '../../../Models/HedisMetrics/HgbA1CSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import FilterOptions from '../FilterOptions';
import '../../Widgets/Widgets.css';

const HgbA1CSummaries = () => {
    const history = useNavigate();
    const { populations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [hgbA1CSummary, setHgbA1CSummary] = useState<HgbA1CSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const {
        hgba1cPopulation,
        setHgba1cPopulation,
        hgba1cTeam,
        setHgba1cTeam,
        hgba1cDateFilter,
        setHgba1cDateFilter,
        hgba1cFromDate,
        setHgba1cFromDate,
        hgba1cToDate,
        setHgba1cToDate,
        hgba1cIncludePastOpenStatus,
        setHgba1cIncludePastOpenStatus,
        hgba1cShowInActiveTeam,
        setHgba1cShowInActiveTeam,
        setHedisType,
        setPop,
        setTeam,
        setFilter,
        setFDate,
        setTDate,
        setIncludePastOpenStatus,
        setShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartWithoutLegend('HgbA1C Summary', 'Controlled vs Not Controlled', 'Population', 'Count', [{ name: '', data: [0] }], [0])
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched && (hgba1cDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(hgba1cFromDate) && Boolean(hgba1cToDate)))) {
            const changeFilter = async () => {
                let start = hgba1cFromDate !== null ? new Date(hgba1cFromDate).toDateString() : null;
                let end = hgba1cToDate !== null ? new Date(hgba1cToDate).toDateString() : null;
                const data = await getHgbA1CSummary(
                    hgba1cPopulation,
                    hgba1cTeam,
                    hgba1cDateFilter,
                    start,
                    end,
                    hgba1cIncludePastOpenStatus,
                    hgba1cShowInActiveTeam
                );
                setHgbA1CSummary(data);

                setIsPrepared(true);
            };
            if ((hgba1cFromDate !== null || hgba1cToDate !== null) && hgba1cToDate < hgba1cFromDate) {
            } else {
                setChartOptions(
                    configureStackedBarChartWithoutLegend(
                        'HgbA1C Summary',
                        'Controlled vs Not Controlled',
                        'Population',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hgba1cPopulation,
        hgba1cTeam,
        hgba1cDateFilter,
        hgba1cFromDate,
        hgba1cToDate,
        isPopulationFetched,
        hgba1cIncludePastOpenStatus,
        hgba1cShowInActiveTeam,
    ]);

    useEffect(() => {
        if (hgbA1CSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            let yVal;

            yValues = [];
            yVal = {
                y: hgbA1CSummary.Controlled,
                name: 'Controlled',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Controlled', data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });

            yValues = [];
            yVal = {
                y: hgbA1CSummary.NotControlled,
                name: 'Not Controlled',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Controlled', data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });

            yValues = [];
            yVal = {
                y: hgbA1CSummary.NotDocumented,
                name: 'Not Documented',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Documented', data: yValues, pointPadding: 0, color: '#4682B4', showInLegend: true });

            if (hgba1cPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = populations.find((p) => p.value === hgba1cPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartWithoutLegend('HgbA1C Summary', '', '', 'Count', yAxisValues, xAxisValues, navigateToMembers));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hgbA1CSummary, hgba1cPopulation, populations, isPrepared]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToMembers = () => {
        setPop(hgba1cPopulation);
        setTeam(hgba1cTeam);
        setFilter(hgba1cDateFilter);
        setFDate(hgba1cFromDate);
        setTDate(hgba1cToDate);
        setIncludePastOpenStatus(hgba1cIncludePastOpenStatus);
        setShowInActiveTeam(hgba1cShowInActiveTeam);
        setHedisType(HedisMetricsTypeEnum.HgbA1c);
        history(`/provider/metricsummary/hedismemberdetail/HgbA1C`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            HgbA1C
                        </Typography>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">For members aged 18-75 who have an ICD 10 of E10-E11</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Controlled :
                                    </Typography>
                                    <Typography variant="caption">Any of the CPT codes 3051F or 3044F</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Controlled :
                                    </Typography>
                                    <Typography variant="caption">Any of the CPT codes 3046F or 3052F</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Documented :
                                    </Typography>
                                    <Typography variant="caption">No CPT codes recorded</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FilterOptions
                        selectedTeam={hgba1cTeam}
                        setSelectedTeam={setHgba1cTeam}
                        dateFilter={hgba1cDateFilter}
                        setDateFilter={setHgba1cDateFilter}
                        fromDate={hgba1cFromDate}
                        setFromDate={setHgba1cFromDate}
                        toDate={hgba1cToDate}
                        setToDate={setHgba1cToDate}
                        population={hgba1cPopulation}
                        setPopulation={setHgba1cPopulation}
                        includePastOpenStatusMembers={hgba1cIncludePastOpenStatus}
                        setIncludePastOpenStatusMembers={setHgba1cIncludePastOpenStatus}
                        hedisType={HedisMetricsTypeEnum.HgbA1c}
                        showInActiveTeams={hgba1cShowInActiveTeam}
                        setShowInActiveTeams={setHgba1cShowInActiveTeam}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default HgbA1CSummaries;
