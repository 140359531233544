import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, Divider, FormControl, FormGroup, Input, InputLabel, Modal, Stack, TextField, Typography } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { RootState } from '../../../../reducers';
import { deletePharmacy, savePharmacy } from '../../../../store/memberDetail.slice';
import TextMaskCustom from '../../../Shared/PhoneMask';
import { modalStyle } from '../../../../utils/constants';

const PharmacyTable = () => {
    const getDefault = () => {
        return { Id: null, Name: '', Address: '', PhoneNumber: '', FaxNumber: '' };
    };

    const reset = (row: any) => {
        let update = row ? { ...row } : getDefault();
        setForm(update);
    };

    const addHandler = (row: any = null) => {
        reset(row);
        setShowModal(true);
    };

    const dispatch = useDispatch();
    const { selectedMember, pharmacyList } = useSelector((state: RootState) => state.memberDetail);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [form, setForm] = useState(getDefault());

    const pharmacyColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Pharmacy Name',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'PhoneNumber',
            headerName: 'Phone Number',
            minWidth: 150,
            renderCell: (params) => {
                return <Input readOnly value={params.row.PhoneNumber} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
        },
        {
            field: 'Fax Number',
            headerName: 'Fax Number',
            minWidth: 150,
            renderCell: (params) => {
                return <Input readOnly value={params.row.FaxNumber} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Edit
                            className="icon-left"
                            onClick={() => {
                                addHandler(params.row);
                                setShowModal(true);
                            }}
                        />
                        <Delete
                            className="icon-right"
                            onClick={() => {
                                setForm(params.row);
                                setShowDelete(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const save = () => {
        let update = { ...form };
        update.PhoneNumber = form.PhoneNumber?.replace(/[^\d.]/g, '');
        update.FaxNumber = form.FaxNumber?.replace(/[^\d.]/g, '');
        dispatch(savePharmacy(update));
        setShowModal(false);
    };

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Stack spacing={3}>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Pharmacy Name"
                                variant="outlined"
                                value={form.Name}
                                onChange={(e: any) => {
                                    setForm({ ...form, Name: e.target.value });
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Address"
                                variant="outlined"
                                value={form.Address}
                                onChange={(e: any) => {
                                    setForm({ ...form, Address: e.target.value });
                                }}
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
                                <Input
                                    value={form.PhoneNumber}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handlePhoneChange(e, 'PhoneNumber');
                                    }}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Fax Number</InputLabel>
                                <Input
                                    value={form.FaxNumber}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handlePhoneChange(e, 'FaxNumber');
                                    }}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                        </Stack>
                    </FormGroup>
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={save}>
                            Save
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>, prop) => {
        let update = { ...form };
        update[prop] = event.target.value;

        setForm(update);
    };

    const deleteHandler = () => {
        dispatch(deletePharmacy(selectedMember.Id, form.Id));
        setShowDelete(false);
    };

    const renderDeleteBody = () => {
        return (
            <Stack spacing={2}>
                <Typography sx={{ marginLeft: 3 }} variant="h6">
                    Are you sure do you want to delete: {form.Name}?
                </Typography>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" color="error" onClick={() => deleteHandler()}>
                            Delete
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowDelete(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <>
            <Box style={{ height: '100%', textAlign: 'right' }}>
                <Button
                    className="button-120"
                    variant="contained"
                    onClick={() => {
                        addHandler();
                    }}
                >
                    Add Pharmacy
                </Button>
                <DataGrid
                    autoHeight
                    getRowId={(row) => row.Id}
                    rows={pharmacyList}
                    columns={pharmacyColumns}
                    experimentalFeatures={{ newEditingApi: true }}
                    hideFooter
                />
            </Box>
            <Modal
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Pharmacy</Typography>
                        <Divider />
                        {renderBody()}
                    </Stack>
                </Box>
            </Modal>
            <Modal
                open={showDelete}
                onClose={() => {
                    setShowDelete(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Delete Pharmacy</Typography>
                        <Divider />
                        {renderDeleteBody()}
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default PharmacyTable;
