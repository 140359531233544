import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    FormLabel,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
    TableContainer,
    Table,
    ListItemText,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { getMinimentalExamStatusById, gotoNextMenu, gotoPreviousMenu, saveMiniMentalExamStatus } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import { copyObject } from '../../../utils/common';
import { MiniMentalExamItem } from '../../../Models/MiniMentalExams/MiniMentalExamItem.model';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';

const MiniMentalExamComponent: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { miniMentalExam, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const miniMentalExamsByType = [
        {
            type: 'GeneralObservation',
            name: 'General Observation',
            list: [
                { name: 'Appearance', isMultiSelect: false },
                { name: 'Demeanor', isMultiSelect: true },
                { name: 'EyeContact', isMultiSelect: false },
                { name: 'Behavior', isMultiSelect: true },
                { name: 'Speech', isMultiSelect: true },
            ],
        },
        {
            type: 'Thought',
            name: 'Thought',
            list: [
                { name: 'ThoughtProcess', isMultiSelect: true },
                { name: 'Perceptions', isMultiSelect: false },
                { name: 'DTS', isMultiSelect: false },
                { name: 'DTO', isMultiSelect: false },
            ],
        },
        {
            type: 'Emotion',
            name: 'Emotion',
            list: [
                { name: 'Mood', isMultiSelect: true },
                { name: 'Affect', isMultiSelect: false },
            ],
        },
    ];

    useEffect(() => {
        dispatch(getMinimentalExamStatusById(engagementDraft.MiniMentalExamId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChangeMiniMentalExamItem = (value: any, typePropsName: string, parentPropsName: string, childPropsName: string, saveLocal = false) => {
        let data = copyObject(miniMentalExam);
        data[typePropsName][parentPropsName][childPropsName] = value;
        dispatch(saveMiniMentalExamStatus({ ...data, MemberId: memberId }, saveLocal));
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveMiniMentalExamStatus({ ...miniMentalExam, Status: StatusEnum.Completed }, false, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const renderMiniMentalExamItem = (typePropsName: string, parentPropsName: string, index: number, isMultiSelect = false) => {
        const typeData = miniMentalExam[typePropsName];
        if (!Boolean(typeData)) {
            return null;
        }

        const miniMentalExamItem = typeData[parentPropsName] as MiniMentalExamItem;
        const typeList = miniMentalExamItem?.TypeList || [];

        return (
            Boolean(miniMentalExamItem) && (
                <TableRow key={`general-observ-${parentPropsName}-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell width="15%" style={{ verticalAlign: 'top' }}>
                        <FormLabel>{miniMentalExamItem.Name}</FormLabel>
                    </TableCell>
                    <TableCell width="80%">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} xl={4}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id={`${parentPropsName}-type-select-label`}>Type</InputLabel>
                                    {isMultiSelect ? (
                                        <Select
                                            multiple={isMultiSelect}
                                            labelId={`${parentPropsName}-type-select-label`}
                                            value={Boolean(miniMentalExamItem?.Types?.length) ? miniMentalExamItem.Types.map((t) => t.Id) : []}
                                            label="Type"
                                            renderValue={(selected) =>
                                                typeList
                                                    .filter((t) => selected.some((s) => s === t.Id))
                                                    .map((m) => m.Name)
                                                    .join(', ')
                                            }
                                            onChange={(e) =>
                                                handleOnChangeMiniMentalExamItem(
                                                    typeList.filter((t) => (e.target.value as string[])?.some((v) => v === t.Id)),
                                                    typePropsName,
                                                    parentPropsName,
                                                    'Types'
                                                )
                                            }
                                        >
                                            {typeList.map((type) => (
                                                <MenuItem key={type.Id} value={type.Id}>
                                                    <Checkbox
                                                        checked={Boolean(miniMentalExamItem.Types) && miniMentalExamItem.Types.some((t) => t.Id === type.Id)}
                                                    />
                                                    <ListItemText primary={type.Name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Select
                                            labelId={`${parentPropsName}-type-select-label`}
                                            value={Boolean(miniMentalExamItem?.Type?.Id) ? miniMentalExamItem.Type.Id : null}
                                            label="Type"
                                            onChange={(e) =>
                                                handleOnChangeMiniMentalExamItem(
                                                    miniMentalExamItem.TypeList.find((t) => t.Id === e.target.value),
                                                    typePropsName,
                                                    parentPropsName,
                                                    'Type'
                                                )
                                            }
                                        >
                                            {miniMentalExamItem?.TypeList?.map((type) => (
                                                <MenuItem key={type.Id} value={type.Id}>
                                                    {type.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Comments"
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    size="small"
                                    value={Boolean(miniMentalExamItem?.Description) ? miniMentalExamItem.Description : null}
                                    onChange={(e) => handleOnChangeMiniMentalExamItem(e.target.value, typePropsName, parentPropsName, 'Description', true)}
                                    onBlur={(e) => handleOnChangeMiniMentalExamItem(e.target.value, typePropsName, parentPropsName, 'Description')}
                                />
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item className="content-form" sx={{ mt: 3 }}>
                        {miniMentalExamsByType.map((type, index) => (
                            <Grid container key={`mini-mental-exam-item-${index}`}>
                                <Grid item lg={12} md={12} sm={12}>
                                    <Typography variant="h6" component="label">
                                        {type.name}
                                    </Typography>
                                    <hr />
                                </Grid>
                                <Grid item xs>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {type.list.map((item, itemIndex) =>
                                                    renderMiniMentalExamItem(type.type, item.name, itemIndex, item.isMultiSelect)
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.MiniMentalExamId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Mini Mental Exam Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    showBottomCenter={true}
                    message="I confirm and acknowledge that Mini Mental Exam assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Mini Mental Exam Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    showBottomCenter={true}
                    onClose={() => setShowSaveAlert(false)}
                    message="Are you sure to proceed without completing Mini Mental Exam assessment?"
                />
            )}
        </Grid>
    );
};

export default MiniMentalExamComponent;
