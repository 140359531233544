import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';

const memberBirthdayReport = createSlice({
    name: 'memberBirthdayReport',
    initialState: {
        birthdayRowData: [],
        birthdaySearchStatus: 'active',
        birthdaySearchMonth: new Date().getMonth() + 1,
    },
    reducers: {
        _setBirthdayRowData(state, action) {
            state.birthdayRowData = action.payload;
        },
        _setBirthdaySearchMonth(state, action) {
            state.birthdaySearchMonth = action.payload;
        },
        _setBirthdaySearchStatus(state, action) {
            state.birthdaySearchStatus = action.payload;
        },
    },
});

const { _setBirthdayRowData, _setBirthdaySearchMonth, _setBirthdaySearchStatus } = memberBirthdayReport.actions;

export const fetchMemberBirthdayReport =
    (birthdaySearchStatus, birthdaySearchMonth): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/MemberDetailReport/getmembersforbirthdaycard?status=${birthdaySearchStatus}&month=${birthdaySearchMonth}`);
        dispatch(_setBirthdayRowData(response.data));
        dispatch(setIsLoading(false));
    };

export const setBirthdaySearchStatus =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setBirthdaySearchStatus(params));
    };

export const setBirthdaySearchMonth =
    (params: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setBirthdaySearchMonth(params));
    };

export const exportMemberBirthdayReport =
    (birthdaySearchStatus: string, birthdaySearchMonth: any): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/MemberDetailReport/exportmembersforbirthdaycard?status=${birthdaySearchStatus}&month=${birthdaySearchMonth}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default memberBirthdayReport.reducer;
