import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';

const vitalSignsMemberDetail = createSlice({
    name: 'vitalSignsMemberDetail',
    initialState: {
        memberVitalSigns: [] as any[],
        memberDevices: null,
        timePeriod: 1,
        vitalSign: null,
    },
    reducers: {
        _setMemberVitalSigns(state, action) {
            state.memberVitalSigns = action.payload;
        },
        _setMemberDevices(state, action) {
            state.memberDevices = action.payload;
        },
        _setTimePeriod(state, action) {
            state.timePeriod = action.payload;
        },
        _setVitalSign(state, action) {
            state.vitalSign = action.payload;
        },
    },
});

const { _setMemberVitalSigns, _setMemberDevices, _setTimePeriod, _setVitalSign } = vitalSignsMemberDetail.actions;

export const setMemberVitalSigns =
    (memberVitalSigns: any[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberVitalSigns(memberVitalSigns));
    };

export const setMemberDevices =
    (memberDevices: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberDevices(memberDevices));
    };

export const setTimePeriod =
    (timePeriod: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setTimePeriod(timePeriod));
    };

export const setVitalSign =
    (vitalSign: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setVitalSign(vitalSign));
    };

export default vitalSignsMemberDetail.reducer;
