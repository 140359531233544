import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const ConfirmationModal = (props) => {
    const { alertsList, confirmationModalMessage, handleCancel, handleClose, open, handleAction } = props;

    return (
        <Dialog onClose={handleClose} open={open} sx={{ padding: 5 }} maxWidth="sm" fullWidth>
            <DialogContent sx={{ p: 5 }}>
                <Typography color={confirmationModalMessage.includes('Delete') ? 'red' : ''} variant="h6">
                    {confirmationModalMessage}
                </Typography>
                {alertsList?.map((alert) => (
                    <Typography variant="body1" key={alert.Id}>
                        {alert.Patient} - {alert.DOB}
                    </Typography>
                ))}
            </DialogContent>

            <DialogActions>
                <Button className="button-120" variant="contained" onClick={handleAction}>
                    Confirm
                </Button>
                <Button className="button-120" variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmationModal;
