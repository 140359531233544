import BaseEntity from '../BaseEntity.model';
import CreatedBy from './../Shared/LastUpdatedBy.model';
import { PlannedDischargeLocationEnum } from '../../Enum/PlannedDischargeLocationEnum';

export default interface IDischargePlan extends BaseEntity {
    AnticipatedDate: Date | null;
    PlannedDischargeLocation: PlannedDischargeLocationEnum;
    OtherDischargeLocation: string;
    Note: string;
    CreatedDate: Date | null;
    CreatedBy: CreatedBy | null;
}

export class DischargePlan implements IDischargePlan {
    Id: string;
    AnticipatedDate: Date | null;
    PlannedDischargeLocation: PlannedDischargeLocationEnum;
    OtherDischargeLocation: string;
    Note: string;
    CreatedDate: Date | null;
    CreatedBy: CreatedBy | null;

    constructor() {
        this.Id = '';
        this.AnticipatedDate = null;
        this.PlannedDischargeLocation = 0;
        this.OtherDischargeLocation = '';
        this.Note = '';
        this.CreatedDate = null;
        this.CreatedBy = null;
    }
}
