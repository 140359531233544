import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { parentUrl } from '../../utils/constants';
import HedisMetricsMemberInfo from '../../Models/HedisMetrics/HedisMetricsMemberInfo.model';
import { dateFormatter } from '../../utils/timeFormat';
import { generateRandomId } from '../../utils/common';

type Props = {
    memberList: HedisMetricsMemberInfo[];
    hedisType?: string;
};

const HedisDataGrid = ({ memberList, hedisType }: Props) => {
    const columns: GridColDef[] = [
        {
            field: 'Index',
            headerName: '#',
            width: 60,
            sortable: false,
            renderCell: (params) => {
                return `${params.api.getRowIndex(params.id) + 1}`;
            },
        },
        {
            field: 'Name',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'AdmissionDate',
            headerName: 'Discharge Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.AdmissionDate) ? dateFormatter(new Date(params.row.AdmissionDate)) : '-'}`,
        },
        {
            field: 'AssessmentDate',
            headerName: 'Assessment Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.AssessmentDate) ? dateFormatter(new Date(params.row.AssessmentDate)) : '-'}`,
        },
        {
            field: 'DateOfService',
            headerName: 'Date Of Service',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.DateOfService) ? dateFormatter(new Date(params.row.DateOfService)) : '-'}`,
        },
    ];

    const prepareCloumns = () => {
        let columnsData = columns;
        if (hedisType === 'DepressionFollowUp') {
            columnsData = columnsData.filter((c) => c.field !== 'AdmissionDate');
        } else if (hedisType === 'MedicationReconciliation') {
            columnsData = columnsData.filter((c) => c.field !== 'AssessmentDate');
        } else {
            columnsData = columnsData.filter((c) => c.field !== 'AssessmentDate' && c.field !== 'AdmissionDate');
        }
        return columnsData;
    };

    return (
        <DataGrid
            columns={prepareCloumns()}
            rows={memberList}
            getRowId={(row) => `${row.ItemId}-${row.AdmissionDate}-${generateRandomId()}`}
            paginationMode="server"
            hideFooterPagination={true}
            hideFooter={true}
            onCellClick={(params: any) => {
                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
            }}
        />
    );
};

export default HedisDataGrid;
