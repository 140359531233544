import { Button, DialogActions, DialogContent, FormControl, Grid, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setPopulationActiveStep, setOpenAddPopulation, uploadMember, closeAddPopulationHandler } from '../../../store/adminHealthPlan.slice';
import { localDateFormat } from '../../../utils/timeFormat';
import Loading from '../../Shared/Loading';
import { isDefaultDate } from '../../../utils/common';

const ImportData = (props) => {
    const { currentHealthPlan, currentPopulation, fileProcessedCount, importSuccessful, updatingPopulation, validatedMembers } = useSelector(
        (state: RootState) => state.adminHealthPlanSlice
    );
    const { file, setFile } = props;
    const dispatch = useDispatch();
    const columns: GridColDef[] = [
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'Address1',
            headerName: 'Address1',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'Address2',
            headerName: 'Address2',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 160,
        },
        {
            field: 'State',
            headerName: 'State',
            minWidth: 100,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            minWidth: 100,
        },
        {
            field: 'Gender',
            headerName: 'Gender',
            minWidth: 100,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 160,
        },
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 160,
        },
        {
            field: 'PcpName',
            headerName: 'PCP Name',
            minWidth: 250,
            flex: 1,
        },
    ];
    return (
        <>
            <Loading message={`Importing...${fileProcessedCount.Percentage}%`} />

            {importSuccessful && (
                <DialogContent>
                    <FormControl fullWidth>
                        <Grid spacing={2} container p={2} sx={{ height: '65vh' }}>
                            <Grid item textAlign="center" marginX={'auto'} xs={6}>
                                <Typography variant="h4">Your file was imported successfully.</Typography>
                                {!updatingPopulation && (
                                    <Button
                                        variant="contained"
                                        className="button-120"
                                        sx={{ marginTop: 3 }}
                                        onClick={() => dispatch(setPopulationActiveStep(3))}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {updatingPopulation && (
                                    <Button
                                        variant="contained"
                                        className="button-120"
                                        sx={{ marginTop: 3 }}
                                        onClick={() => {
                                            dispatch(closeAddPopulationHandler(currentHealthPlan));
                                            setFile(null);
                                        }}
                                    >
                                        Finish
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
            )}
            {!importSuccessful && (
                <>
                    <DialogContent>
                        <FormControl fullWidth>
                            <Grid spacing={2} container p={2} sx={{ height: '65vh' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" marginBottom={3}>
                                        Review & import your data
                                    </Typography>
                                    <Typography variant="body1">Displaying first 10 records</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ height: '50vh', width: '100%' }}>
                                        <DataGrid
                                            rows={validatedMembers}
                                            columns={columns}
                                            rowsPerPageOptions={[5]}
                                            getRowId={(row) => row.MemberId}
                                            getRowHeight={() => 'auto'}
                                            sx={{
                                                [`& .${gridClasses.cell}`]: {
                                                    py: 1,
                                                },
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button className="button-120" variant="outlined" onClick={() => dispatch(setOpenAddPopulation(false))}>
                            Close
                        </Button>
                        <Button className="button-120" variant="contained" onClick={() => dispatch(setPopulationActiveStep(1))}>
                            Previous
                        </Button>
                        <Button
                            className="button-120"
                            variant="contained"
                            onClick={() => dispatch(uploadMember(file, currentHealthPlan.Id, currentPopulation.Id))}
                        >
                            Import
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

export default ImportData;
