import BaseEntity from '../BaseEntity.model';

export default interface IMetricGoal extends BaseEntity {
    HgbA1C: number;
    DiabeticEyeExam: number;
    ControlledBloodPressure: number;
    BMI: number;
    AdultHealth: number;
    DepressionScreen: number;
    DepressionFollowUp: number;
    MedicationReconciliation: number;
    FUH: number;
    Documentation: number;
    TeamInPersonVisits: number;
    IndividualInPersonVisits: number;
    ActiveMembersAcrossPopulation: number;
    ActiveMembersPerTeam: number;
}

export default class MetricGoal implements IMetricGoal {
    Id: string;
    HgbA1C: number;
    DiabeticEyeExam: number;
    ControlledBloodPressure: number;
    BMI: number;
    AdultHealth: number;
    DepressionScreen: number;
    DepressionFollowUp: number;
    MedicationReconciliation: number;
    FUH: number;
    Documentation: number;
    TeamInPersonVisits: number;
    IndividualInPersonVisits: number;
    ActiveMembersAcrossPopulation: number;
    ActiveMembersPerTeam: number;

    constructor() {
        this.Id = '';
        this.HgbA1C = 0;
        this.DiabeticEyeExam = 0;
        this.ControlledBloodPressure = 0;
        this.BMI = 0;
        this.AdultHealth = 0;
        this.DepressionScreen = 0;
        this.DepressionFollowUp = 0;
        this.MedicationReconciliation = 0;
        this.FUH = 0;
        this.Documentation = 0;
        this.TeamInPersonVisits = 0;
        this.IndividualInPersonVisits = 0;
        this.ActiveMembersAcrossPopulation = 0;
        this.ActiveMembersPerTeam = 0;
    }
}