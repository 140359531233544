import { Grid, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { localDateFormat } from '../../../../utils/timeFormat';
import { monthNames } from '../../../../utils/constants';
import { useEffect } from 'react';
import { GridCellParams } from '@mui/x-data-grid/models';
import { getLast10Engagements, getMemberEngagements, getStatusColor, getVisitTypeName } from '../../../../store/performanceDashboard.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { isDefaultDate } from '../../../../utils/common';

const EngagementNumbers = (props) => {
    const dispatch = useDispatch();
    const { last10Engagements, memberEngagements } = useSelector((state: RootState) => state.performanceDashboard);
    const last10VisitsColumns: GridColDef[] = [
        {
            field: 'VisitedTime',
            headerName: 'Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.VisitedTime) ? '-' : localDateFormat(new Date(params.row.VisitedTime))}`,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${getVisitTypeName(params.row.MeansOfEngagement)}`,
        },
        {
            field: 'TeamMember',
            headerName: 'Team Member',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.EngagedProvider?.Name}`,
        },
    ];

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: '',
            flex: 1,
        },
        {
            field: 'This Month',
            headerName: `${monthNames[new Date().getMonth()]} (TY)`,
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row['This Month'], params.row['This Month Last Year']),
        },
        {
            field: 'This Month Last Year',
            headerName: `${monthNames[new Date().getMonth()]} (LY)`,
            flex: 1,
        },
        {
            field: 'This Month CHP Median',
            headerName: `${monthNames[new Date().getMonth()]} (CHP Median)`,
            flex: 1,
        },
        {
            field: 'This Quarter',
            headerName: 'This Quarter (TY)',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row['This Quarter'], params.row['This Quarter Last Year']),
        },
        {
            field: 'This Quarter Last Year',
            headerName: 'This Quarter (LY)',
            flex: 1,
        },
        {
            field: 'This Quarter (CHP Median)',
            headerName: 'This Quarter (CHP Median)',
            flex: 1,
        },
        {
            field: 'This Year',
            headerName: '(TY)',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row['This Year'], params.row['Last Year']),
        },
        {
            field: 'This Year (CHP Median)',
            headerName: '(TY) (CHP Median)',
            flex: 1,
        },
        {
            field: 'Last Year',
            headerName: '(LY)',
            flex: 1,
        },
    ];

    useEffect(() => {
        dispatch(getMemberEngagements(props.memberId));
        dispatch(getLast10Engagements(props.memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.memberId]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography display={'inline-block'} variant="h6" marginBottom={1} color={'primary'}>
                    Engagement Numbers
                </Typography>
            </Grid>
            <Grid item marginBottom={2} xs={12}>
                <DataGrid
                    rows={memberEngagements}
                    columns={columns}
                    autoHeight
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => Math.random()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                        [`& .green`]: {
                            color: 'white',
                            background: 'green',
                        },
                        [`& .red`]: {
                            color: 'white',
                            background: 'red',
                        },
                        [`& .black`]: {
                            color: 'rgba(0,0,0,0.87)',
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography display={'inline-block'} variant="h6" marginBottom={1} color={'primary'}>
                    Last 10 Visits
                </Typography>
            </Grid>

            <Grid item style={{ width: '100%' }}>
                <DataGrid
                    rows={last10Engagements}
                    columns={last10VisitsColumns}
                    autoHeight
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => Math.random()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default EngagementNumbers;
