import { Box, CircularProgress, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { addDays } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SchedulingCallList } from '../../Models/Scheduling/SchedulingCallList.model';
import { SchedulingCallItem } from '../../Models/Scheduling/SchedulingCallitem.model';
import { RootState } from '../../reducers';
import { selectCallItem, setSchedulingList } from '../../store/officeScheduler.slice';
import { setIsLoading, setShowMessage } from '../../store/shared.slice';
import { ERROR, apiString } from '../../utils/constants';
import { formatAppointmentTime, formatDateMMDDYYYY } from '../../utils/timeFormat';
import SchedulingDashboard from '../OfficeScheduling/SchedulingDashboard/SchedulingDashboard';
import Loading from '../Shared/Loading';
import MessageDisplay from '../Shared/MessageDisplay';
import CalendarModal from './CalendarModal';
import { OfficeSchedulerModeEnum } from './Enums/OfficeSchedulerModeEnum';
import MemberChartScheduler from './MemberChartCalendar/MemberChartScheduler';

export interface IOfficeConfirmationsProps {
    selectedProviderId?: string;
}

const OfficeConfirmations = (props: IOfficeConfirmationsProps) => {
    const dispatch = useDispatch();
    const { shared, officeSchedulerSlice } = useSelector((state: RootState) => state);
    const { isLoading } = shared;
    const { selectedCallItem, associatedEvent, schedulingList, member } = officeSchedulerSlice;
    const callItems = schedulingList?.CallItems ?? [];
    const { selectedProviderId } = props;
    // today's date is initialized at midnight of the current date
    const today = useMemo(() => {
        return new Date(new Date().setHours(0, 0, 0, 0));
    }, []);

    const startDate = useMemo(() => {
        return addDays(today, 1);
    }, [today]);
    const endDate = useMemo(() => {
        let newDate = addDays(today, 4);
        if (today.getDay() === 3 || today.getDay() === 4 || today.getDay() === 5) {
            newDate = addDays(today, 6);
        }
        return newDate;
    }, [today]);

    // this object stores the necessary state setters and data for the getMemberDataAndApptInfo function

    const [openEditor, setOpenEditor] = useState<boolean>(false);

    let rightHandBoxContent = (
        <Box justifyContent={'center'} alignItems={'center'} display="flex" height="100vh" style={{ position: 'relative' }}>
            <Typography align="center" style={{ position: 'absolute', top: '10%', left: '50%', transform: 'translateX(-50%)' }}>
                No appointments available at this time. Please check back later.
            </Typography>
        </Box>
    );

    const onCloseCalendarModal = () => {
        setOpenEditor(false);
    };

    const getConfirmationsList = useCallback(async () => {
        if (startDate !== undefined && endDate !== undefined) {
            try {
                dispatch(setIsLoading(true));
                const response = await axios.get<SchedulingCallList>(`${apiString}/scheduling/GetConfirmationsList`, {
                    params: { startDate: formatDateMMDDYYYY(startDate), endDate: formatDateMMDDYYYY(endDate), providerId: selectedProviderId },
                });
                dispatch(setIsLoading(false));
                if (response.status === 200) {
                    const { data } = response;
                    let list = structuredClone(data);
                    list.CallItems = list.CallItems.map((item) => ({ ...item, Note: '' }));
                    dispatch(setSchedulingList(list));
                    const callItem = list.CallItems.filter((item) => !item.Completed)[0];
                    dispatch(selectCallItem(callItem));
                }
            } catch (error) {
                let errorMessage = `${error}`;
                if (error?.response?.data?.error) {
                    errorMessage = `${error}. \n\nReason: ${error.response.data?.error}`;
                }
                dispatch(setIsLoading(false));
                console.error(error);
                dispatch(setShowMessage(true, errorMessage, ERROR));
            }
        }
    }, [dispatch, endDate, selectedProviderId, startDate]);

    const handleListItemClick = (callItem: SchedulingCallItem) => {
        dispatch(selectCallItem(callItem));
    };

    const dashboard = useMemo(() => {
        let board = <SchedulingDashboard mode={OfficeSchedulerModeEnum.Confirmations} getLatestListData={getConfirmationsList} setOpenEditor={setOpenEditor} />;
        if (isLoading) {
            board = (
                <Box display="flex" justifyContent="center" alignItems="center" className="loader-container">
                    <CircularProgress />
                </Box>
            );
        }

        return board;
    }, [getConfirmationsList, isLoading]);

    const appointmentList = useMemo(() => {
        let list = null;
        if (callItems?.length > 0) {
            list = callItems
                .filter((item) => !item.Completed)
                .map((item: SchedulingCallItem) => {
                    const dateText = `${formatDateMMDDYYYY(new Date(item.AppointmentDateTime))} @ ${formatAppointmentTime(new Date(item.AppointmentDateTime))}`;

                    return (
                        <ListItem
                            onClick={() => {
                                handleListItemClick(item);
                            }}
                        >
                            <ListItemButton selected={selectedCallItem?.Id === item.Id}>
                                <ListItemText primary={item.MemberName} secondary={dateText} />
                            </ListItemButton>
                        </ListItem>
                    );
                });
        } else if (callItems?.length === 0) {
            list = <Typography style={{ textAlign: 'center' }}>Your confirmations list is empty at the moment. Try again later.</Typography>;
        }
        return list;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callItems, selectedCallItem?.Id]);

    useEffect(() => {
        getConfirmationsList();
    }, [getConfirmationsList]);

    return (
        <Paper>
            <Loading message={'Loading queue...'} />
            <MessageDisplay />
            <div style={{ margin: 5, overflow: 'hidden' }}>
                <Grid container item direction="column" spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start">
                            <Grid item xl={3} lg={3} md={4} sm={12} xs={12} style={{ marginRight: '0px' }}>
                                <Stack>
                                    <Typography style={{ textAlign: 'center' }} variant="body1">{`Unconfirmed appointments from ${formatDateMMDDYYYY(
                                        startDate
                                    )} to ${formatDateMMDDYYYY(endDate)}`}</Typography>
                                    <Typography style={{ textAlign: 'center' }} variant="body2" color="text.secondary">
                                        Select an appointment to view the information.
                                    </Typography>
                                </Stack>
                                <Divider />
                                <List dense>{React.Children.toArray(appointmentList)}</List>
                            </Grid>
                            <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                                {member?.Id?.length > 0 && associatedEvent?.Id?.length > 0 ? (
                                    <>
                                        {dashboard}
                                        <CalendarModal
                                            open={openEditor}
                                            onClose={onCloseCalendarModal}
                                            calendar={<MemberChartScheduler memberId={member?.Id} preSelectedDate={associatedEvent?.AppointmentDateTime} />}
                                        />
                                    </>
                                ) : (
                                    rightHandBoxContent
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
};

export default OfficeConfirmations;
