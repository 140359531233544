import axios from 'axios';
import { apiString as api } from '../../utils/constants';
import { Member } from '../../Models/Member/Member.model';
import { Provider } from '../../Models/Provider/Provider.model';
import { DocumentEngagement } from '../../Models/DocumentEngagements/DocumentEngagement.model';

export const fetchMemberInfo = async (memberId: string) => await axios.get<Member>(`${api}/member/getmemberbyid?memberId=${memberId}`);
export const fetchProviders = async () => await axios.get<Provider[]>(`${api}/provider/getactiveproviders`);
export const fetchMemberDocumentation = async (memberId: string) =>
    await axios.get<DocumentEngagement[]>(`${api}/documentengagement/getdocumentengagementbymemberid?memberId=${memberId}`);
export const updateSchedulingCallItem = async (
    providerId: string,
    apptId: string,
    status: string,
    exclusionDate: string,
    contactTime: string,
    mode: string,
    documentation: string
) =>
    await axios.post(
        `${api}/scheduling/UpdateSchedulingCallItem/${providerId}/${apptId}/${status}/${exclusionDate}/${contactTime}/${mode}`,
        JSON.stringify(documentation),
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );
export const checkCanGetNewList = async (providerId: string, mode: string) =>
    await axios.post(`${api}/scheduling/CheckCanGetNewList/${providerId}/${mode}`, null);
export const fetchNextListTime = async (type: string) => await axios.get(`${api}/scheduling/getnextlisttime?type=${type}`);
