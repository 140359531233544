import { Typography } from '@mui/material';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { useMemo, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../../utils/constants';
import { ISearchableMapProps } from './interfaces/ISearchableMapProps';

const mapStyles = {
    height: 'calc(50vh - 200px)',
    width: '100%',
};

const SearchableMap: React.FC<ISearchableMapProps> = (props) => {
    const { address, longitude, latitude } = props;
    const [showInfoWindow, setShowInfoWindow] = useState(false);

    const mouseOver = (show: boolean, index?: number) => {
        setShowInfoWindow(show);
    };

    const defaultMarker = useMemo(() => {
        if (!longitude || !latitude) {
            return null;
        }
        return (
            // @ts-ignore
            <Marker
                onMouseOver={() => {
                    mouseOver(true);
                }}
                onMouseOut={() => {
                    mouseOver(false);
                }}
                zIndex={1}
                opacity={0.8}
                label={{
                    text: address,
                    color: 'white',
                    fontWeight: 'bold',
                }}
                key={'marker' + address + latitude + longitude}
                position={{ lat: Number(latitude), lng: Number(longitude) }}
            >
                {showInfoWindow && (
                    // @ts-ignore
                    <InfoWindow>
                        <>
                            <Typography>{address}</Typography>
                        </>
                    </InfoWindow>
                )}
            </Marker>
        );
    }, [latitude, longitude, address, showInfoWindow]);

    return (
        // @ts-ignore
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={mapStyles} zoom={11} center={{ lat: Number(latitude), lng: Number(longitude) }}>
                {defaultMarker}
            </GoogleMap>
        </LoadScript>
    );
};

export default SearchableMap;
