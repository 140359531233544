import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

import Authorization from '../../../utils/Authorization';
import { selectedDayFilterList, fetchSchedulerSummary, MenuProps } from '../WidgetService';
import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import SchedulerSummaryModel from '../../../Models/Widget/SchedulerSummary.model';
import '../Widgets.css';

const filterList = selectedDayFilterList();

const SchedulerSummary = () => {
    const schedulingRole = 'Scheduling';
    const authData = new Authorization();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [metrics, setMetrics] = useState<SchedulerSummaryModel>();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [dateFilter, setDateFilter] = useState(WidgetTimeFilterEnum.Last30Days);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isDataShow, setIsDataShow] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const ref = useRef(null);
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const schedulingProviders = filteredProviders.filter((p) => p.Role.RoleName === schedulingRole);

    const handleClick = (event) => {
        setShow(!show);
    };

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (schedulingProviders.length && !selectedProvider) {
            const loggedInProviderId = authData.UserId;
            if (Boolean(loggedInProviderId)) {
                const providerInfo = schedulingProviders.find((t) => t.Id === loggedInProviderId);
                if (Boolean(providerInfo)) {
                    setSelectedProvider(loggedInProviderId);
                } else {
                    setSelectedProvider(schedulingProviders[0].Id);
                }
            }
        }
    }, [schedulingProviders, authData.UserId, selectedProvider]);

    useEffect(() => {
        if (selectedProvider && (dateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate)))) {
            setIsDataShow(false);
            const changeFilter = async () => {
                const data = await fetchSchedulerSummary(
                    selectedProvider,
                    dateFilter,
                    Boolean(fromDate) && dateFilter === WidgetTimeFilterEnum.Custom ? new Date(fromDate).toISOString() : '',
                    Boolean(toDate) && dateFilter === WidgetTimeFilterEnum.Custom ? new Date(toDate).toISOString() : ''
                );
                setMetrics(data);
                setIsDataShow(true);
            };
            changeFilter();
        }

        if (dateFilter !== WidgetTimeFilterEnum.Custom && Boolean(fromDate) && Boolean(toDate)) {
            setFromDate(null);
            setToDate(null);
        }
    }, [selectedProvider, dateFilter, fromDate, toDate]);

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setFromDate(startDate);
        }
    };

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider('');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                Scheduler Summary
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                1 -
                                            </Typography>
                                            <Typography variant="caption">Days from onboard to recurring schedule set</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                2 -
                                            </Typography>
                                            <Typography variant="caption">% of confirmations completed within 3 days</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                3 -
                                            </Typography>
                                            <Typography variant="caption">% of cancels/NCNSs rescheduled within 2 weeks of original appointments</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={selectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {schedulingProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select value={dateFilter} label="Date Filter" name="Date" onChange={(e) => setDateFilter(parseInt(e.target.value as any))}>
                                    {filterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {dateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={6}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(fromDate) ? fromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(toDate) ? toDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={2} height="40vh" sx={{ overflowY: 'scroll', position: 'relative' }}>
                            {!isDataShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Metric</TableCell>
                                                <TableCell align="right">Result</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Days from onboard to recurring schedule set</TableCell>
                                                <TableCell align="right" style={{ paddingLeft: 0, paddingRight: 10 }}>
                                                    <b> Avg : {metrics.OnboardToRecurringSchedule}</b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={2}>
                                                <TableCell>% of confirmations completed within 3 days</TableCell>
                                                <TableCell align="right" style={{ paddingLeft: 0, paddingRight: 10 }}>
                                                    <b>{metrics.ConfirmWithinThreeDays}%</b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={3}>
                                                <TableCell>% of cancels/NCNSs rescheduled within 2 weeks of original appointments</TableCell>
                                                <TableCell align="right" style={{ paddingLeft: 0, paddingRight: 10 }}>
                                                    <b>{metrics.RescheduleWithinTwoWeeks}%</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default SchedulerSummary;
