import { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useNavigate } from 'react-router';
import { hedisMetricSummary } from './MetricSummaryService';
import HedisMetricsView from '../../../Models/MetricSummary/HedisMetricsView.model';
import Authorization from '../../../utils/Authorization';
import { setIsWidgetExisting, setWidgetFromMetricSummary } from '../../../store/smartDashboard.slice';
import { allWidgetsList } from '../Shared/AllWidgetsList';
import { WidgetsEnum } from '../../../utils/constants';
import { copyObject } from '../../../utils/common';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { useWidgetContext } from '../../../hooks/widgetContext';
import InfoIcon from '@mui/icons-material/Info';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { MetricTypeEnum } from '../../../Enum/MetricTypeEnum';
import '../../Widgets/Widgets.css';

const HedisMetricSummary = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const authData = new Authorization();
    const isCEO = authData.Role === 'CEO' ? true : false;
    const { customTabsList } = useSelector((state: RootState) => state.smartDashboard);
    const { teams } = useSelector((state: RootState) => state.teams);

    const {
        selectedTeam,
        setSelectedTeam,
        showInActiveTeams,
        setShowInActiveTeams,

        setAdultHealthPopulation,
        setAdultHealthTeam,
        setAdultHealthDateFilter,
        setAdultHealthIncludePastOpenStatus,
        setAdultHealthShowInActiveTeam,

        setBpPopulation,
        setBpTeam,
        setBpDateFilter,
        setBpIncludePastOpenStatus,
        setBpShowInActiveTeam,

        setBmiPopulation,
        setBmiTeam,
        setBmiDateFilter,
        setBmiIncludePastOpenStatus,
        setBmiShowInActiveTeam,

        setHgba1cPopulation,
        setHgba1cTeam,
        setHgba1cDateFilter,
        setHgba1cIncludePastOpenStatus,
        setHgba1cShowInActiveTeam,

        setDiabeticEyeExamPopulation,
        setDiabeticEyeExamTeam,
        setDiabeticEyeExamDateFilter,
        setDiabeticEyeExamIncludePastOpenStatus,
        setDiabeticEyeExamShowInActiveTeam,

        setMedicationReconciliationPopulation,
        setMedicationReconciliationTeam,
        setMedicationReconciliationDateFilter,
        setMedicationReconciliationIncludePastOpenStatus,
        setMedicationReconciliationShowInActiveTeam,

        setDepressionScreeningPopulation,
        setDepressionScreeningTeam,
        setDepressionScreeningDateFilter,
        setDepressionScreeningIncludePastOpenStatus,
        setDepressionScreeningShowInActiveTeam,

        setDepressionFollowUpPopulation,
        setDepressionFollowUpTeam,
        setDepressionFollowUpDateFilter,
        setDepressionFollowUpIncludePastOpenStatus,
        setDepressionFollowUpShowInActiveTeam,
    } = useHedisMetricsContext();

    useEffect(() => {
        if (!Boolean(selectedTeam)) {
            if (isCEO) {
                setSelectedTeam('All');
            } else {
                setSelectedTeam(authData.TeamId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData.TeamId, isCEO]);

    const {
        selectedProvider,
        showInActiveProviders,

        setAppointmentSelectedProvider,
        setAppointmentShowInActiveProviders,

        setEngagementSelectedProvider,
        setEngagementShowInActiveProviders,
        
        setIndividualEngagementSelectedProvider,
        setIndividualEngagementShowInActiveProviders,

        setClosedSelectedFilter,
        setClosedDateFilter,

        setTcTeam,
        setTcShowInActiveTeams,
    } = useWidgetContext();

    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const [hgbA1CMetric, setHgbA1CMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [diabeticEyeExamMetric, setDiabeticEyeExamMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [bpMetric, setBpMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [bmiMetric, setBmiMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [adultHealthMetric, setAdultHealthMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [depressionScreenMetric, setDepressionScreenMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [depressionFollowUpMetric, setDepressionFollowUpMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [medicationMetric, setMedicationMetric] = useState<HedisMetricsView>(new HedisMetricsView());
    const [fuhMetric, setFuhMetric] = useState<HedisMetricsView>(new HedisMetricsView());

    const [isHedisMetricShow, setIsHedisMetricShow] = useState<boolean>(false);

    const [isHgbA1CTooltipShow, setIsHgbA1CTooltipShow] = useState<boolean>(false);
    const [isDiabeticEyeExamTooltipShow, setIsDiabeticEyeExamTooltipShow] = useState<boolean>(false);
    const [isBpTooltipShow, setIsBpTooltipShow] = useState<boolean>(false);
    const [isBmiTooltipShow, setIsBmiTooltipShow] = useState<boolean>(false);
    const [isAdultHealthTooltipShow, setIsAdultHealthTooltipShow] = useState<boolean>(false);
    const [isDepressionScreenTooltipShow, setIsDepressionScreenTooltipShow] = useState<boolean>(false);
    const [isDepressionFollowUpTooltipShow, setIsDepressionFollowUpTooltipShow] = useState<boolean>(false);
    const [isMedicationTooltipShow, setIsMedicationTooltipShow] = useState<boolean>(false);
    const [isFuhTooltipShow, setIsFuhTooltipShow] = useState<boolean>(false);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 50 * 4.5,
                width: 250,
            },
        },
    };

    useEffect(() => {
        setIsHedisMetricShow(false);
        const changeFilter = async () => {
            const data = await hedisMetricSummary(selectedTeam, showInActiveTeams);
            if (Boolean(data) && data.length) {
                setHgbA1CMetric(data.find((m) => m.MetricType === MetricTypeEnum.HgbA1C));
                setDiabeticEyeExamMetric(data.find((m) => m.MetricType === MetricTypeEnum.DiabeticEyeExam));
                setBpMetric(data.find((m) => m.MetricType === MetricTypeEnum.BloodPressure));
                setBmiMetric(data.find((m) => m.MetricType === MetricTypeEnum.BMI));
                setAdultHealthMetric(data.find((m) => m.MetricType === MetricTypeEnum.AdultHealth));
                setDepressionFollowUpMetric(data.find((m) => m.MetricType === MetricTypeEnum.DepressionFollowUp));
                setDepressionScreenMetric(data.find((m) => m.MetricType === MetricTypeEnum.DepressionScreen));
                setMedicationMetric(data.find((m) => m.MetricType === MetricTypeEnum.MedicationReconciliation));
                setFuhMetric(data.find((m) => m.MetricType === MetricTypeEnum.Fuh));
            }
            setIsHedisMetricShow(true);
        };

        changeFilter();
    }, [selectedTeam, showInActiveTeams, authData.TeamId]);

    const navigateToCustomDashboard = (item: string) => {
        if (customTabsList?.length > 0) {
            let tabList = copyObject(customTabsList);
            let homeTab = tabList.find((t) => t.tabName === 'Home');
            if (Boolean(homeTab)) {
                let component = null;
                let isExistingItem = true;
                switch (item) {
                    case 'HgbA1C':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.HGB_A1C_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Hgb A1C Summary'));
                        break;
                    case 'Diabetic Eye Exam':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.DIABETIC_EYE_EXAM);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Diabetic Eye Exam Summary'));
                        break;
                    case 'Controlled Blood Pressure':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.BP_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('BP Summary'));
                        break;
                    case 'BMI':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.BMI_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('BMI Summary'));
                        break;
                    case 'Adult Health':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.ADULT_HEALTH_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Adult Health Summary'));
                        break;
                    case 'Depression Screen':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.DEPRESSION_SCREENING_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Depression Screening Summary'));
                        break;
                    case 'Depression Follow Up':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.DEPRESSION_FOLLOW_UP_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Depression Follow-Up Summary'));
                        break;
                    case 'Medication Reconciliation':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.MEDICATION_RECONCILIATION_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Medication Reconciliation Summary'));
                        break;
                    case 'FUH':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.TEAM_TC_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Team TC Summary'));
                        break;
                }

                setHgba1cPopulation(0);
                setHgba1cTeam(selectedTeam);
                setHgba1cDateFilter(TimePeriodFilterEnum.Last6Months);
                setHgba1cIncludePastOpenStatus(true);
                setHgba1cShowInActiveTeam(showInActiveTeams);

                setDiabeticEyeExamPopulation(0);
                setDiabeticEyeExamTeam(selectedTeam);
                setDiabeticEyeExamDateFilter(TimePeriodFilterEnum.Last6Months);
                setDiabeticEyeExamIncludePastOpenStatus(true);
                setDiabeticEyeExamShowInActiveTeam(showInActiveTeams);

                setBpPopulation(0);
                setBpTeam(selectedTeam);
                setBpDateFilter(TimePeriodFilterEnum.Last6Months);
                setBpIncludePastOpenStatus(true);
                setBpShowInActiveTeam(showInActiveTeams);

                setBmiPopulation(0);
                setBmiTeam(selectedTeam);
                setBmiDateFilter(TimePeriodFilterEnum.Last6Months);
                setBmiIncludePastOpenStatus(true);
                setBmiShowInActiveTeam(showInActiveTeams);

                setAdultHealthPopulation(0);
                setAdultHealthTeam(selectedTeam);
                setAdultHealthDateFilter(TimePeriodFilterEnum.Last6Months);
                setAdultHealthIncludePastOpenStatus(true);
                setAdultHealthShowInActiveTeam(showInActiveTeams);

                setDepressionScreeningPopulation(0);
                setDepressionScreeningTeam(selectedTeam);
                setDepressionScreeningDateFilter(TimePeriodFilterEnum.Last6Months);
                setDepressionScreeningIncludePastOpenStatus(true);
                setDepressionScreeningShowInActiveTeam(showInActiveTeams);

                setDepressionFollowUpPopulation(0);
                setDepressionFollowUpTeam(selectedTeam);
                setDepressionFollowUpDateFilter(TimePeriodFilterEnum.Last6Months);
                setDepressionFollowUpIncludePastOpenStatus(true);
                setDepressionFollowUpShowInActiveTeam(showInActiveTeams);

                setMedicationReconciliationPopulation(0);
                setMedicationReconciliationTeam(selectedTeam);
                setMedicationReconciliationDateFilter(TimePeriodFilterEnum.Last6Months);
                setMedicationReconciliationIncludePastOpenStatus(true);
                setMedicationReconciliationShowInActiveTeam(showInActiveTeams);

                setTcTeam(selectedTeam);
                setTcShowInActiveTeams(showInActiveTeams);

                setClosedSelectedFilter('0');
                setClosedDateFilter(TimePeriodFilterEnum.Last6Months);

                setAppointmentSelectedProvider(selectedProvider);
                setAppointmentShowInActiveProviders(showInActiveProviders);

                setEngagementSelectedProvider(selectedProvider);
                setEngagementShowInActiveProviders(showInActiveProviders);

                setIndividualEngagementSelectedProvider(selectedProvider);
                setIndividualEngagementShowInActiveProviders(showInActiveProviders);

                dispatch(setIsWidgetExisting(isExistingItem));
                history(`/provider/metricsummary/smart-dashboard`);
            }
        }
    };

    const handleHgba1cClick = () => {
        setIsHgbA1CTooltipShow(!isHgbA1CTooltipShow);
    };

    const handleDiabeticEyeExamClick = () => {
        setIsDiabeticEyeExamTooltipShow(!isDiabeticEyeExamTooltipShow);
    };

    const handleBpClick = () => {
        setIsBpTooltipShow(!isBpTooltipShow);
    };

    const handleBmiClick = () => {
        setIsBmiTooltipShow(!isBmiTooltipShow);
    };

    const handleAdultHealthClick = () => {
        setIsAdultHealthTooltipShow(!isAdultHealthTooltipShow);
    };

    const handleDepressionScreenClick = () => {
        setIsDepressionScreenTooltipShow(!isDepressionScreenTooltipShow);
    };

    const handleDepressionFollowUpClick = () => {
        setIsDepressionFollowUpTooltipShow(!isDepressionFollowUpTooltipShow);
    };

    const handleMedicationClick = () => {
        setIsMedicationTooltipShow(!isMedicationTooltipShow);
    };

    const handleFuhClick = () => {
        setIsFuhTooltipShow(!isFuhTooltipShow);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <Paper className="smartdashboard-widget" elevation={5}>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                <Grid item xs={12} textAlign="center">
                    <Typography display={'inline-block'} variant="h6" color={'primary'} className="pointer">
                        Hedis Metric Summary
                    </Typography>
                </Grid>
                {isCEO && (
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select value={selectedTeam} label="Team" name="Team" onChange={(e) => setSelectedTeam(e.target.value)} MenuProps={MenuProps}>
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} marginTop={0.5} minHeight="56vh" sx={{ position: 'relative' }}>
                {!isHedisMetricShow ? (
                    <Box className="loader-center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography display={'inline-block'} fontWeight={'bold'} variant="body1">
                                HgbA1C
                            </Typography>
                            <Tooltip
                                open={isHgbA1CTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">For members aged 18-75 who have an ICD 10 of E10-E11</Typography>

                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Controlled :
                                        </Typography>
                                        <Typography variant="caption">Any of the CPT codes 3051F or 3044F</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleHgba1cClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('HgbA1C')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: hgbA1CMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: hgbA1CMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(hgbA1CMetric) ? hgbA1CMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {hgbA1CMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(hgbA1CMetric) ? hgbA1CMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                Diabetic Eye Exam
                            </Typography>
                            <Tooltip
                                open={isDiabeticEyeExamTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">
                                            For members who have an ICD 10 of E10-E11 and are between the ages of 18-75 in the rolling 12 month time frame
                                        </Typography>

                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Screened :
                                        </Typography>

                                        <Typography variant="caption">
                                            Any of the CPT codes 67028, 67030, 67031, 67036, 67039, 67040, 67041, 67042, 67043, 67101, 67105, 67107, 67108,
                                            67110, 67113, 67121, 67141, 67145, 68208, 67210, 67218, 67220, 67221, 67227, 67228, 92002, 92004, 92012, 92014,
                                            92018, 92019, 92134, 92225, 92226, 92227, 92228, 92230, 92235, 92240, 92550, 92260,99203, 99204, 99205, 99213,
                                            99214, 99215, 99242, 99243, 99244, 99245
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleDiabeticEyeExamClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('Diabetic Eye Exam')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: diabeticEyeExamMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: diabeticEyeExamMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(diabeticEyeExamMetric) ? diabeticEyeExamMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {diabeticEyeExamMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(diabeticEyeExamMetric) ? diabeticEyeExamMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                Controlled Blood Pressure
                            </Typography>
                            <Tooltip
                                open={isBpTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">Member who has an ICD 10 of I10, within a rolling 12 months</Typography>

                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Controlled BP :
                                        </Typography>

                                        <Typography variant="caption">
                                            CPT Codes 3074F or 3075F Systolic (must have one or the other of these) and 3078F or 3079F Diastolic (must have one
                                            or the other of these)
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleBpClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('Controlled Blood Pressure')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: bpMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: bpMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(bpMetric) ? bpMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {bpMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(bpMetric) ? bpMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                BMI
                            </Typography>
                            <Tooltip
                                open={isBmiTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">For all adults 18-74, within a rolling 12 months</Typography>

                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            BMI Completed :
                                        </Typography>

                                        <Typography variant="caption">
                                            Any of the ICD 10 codes - Z38.20, Z68, Z68.1, Z68.2, Z68.20, Z68.21, Z68.22, Z68.23, Z68.24, Z68.25, Z68.26, Z68.27,
                                            Z68.28, Z68.29, Z68.3, Z68.30, Z68.31, Z68.32, Z68.33, Z68.34, Z68.35, Z68.36, Z68.37, Z68.38, Z68.39, Z68.4,
                                            Z68.41, Z68.42, Z68.43, Z68.44, Z68.45, Z68.5, Z68.51, Z68.52, Z68.53, Z68.54
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleBmiClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('BMI')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: bmiMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: bmiMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(bmiMetric) ? bmiMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {bmiMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(bmiMetric) ? bmiMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                Adult Health
                            </Typography>
                            <Tooltip
                                open={isAdultHealthTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">Member is 20 years of age or older, within a rolling 12 months</Typography>

                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Visits Completed :
                                        </Typography>

                                        <Typography variant="caption">
                                            Any of the CPT codes - 0502F, 20610, 36415, 80048, 80053, 80061, 81001, 84443, 85018, 85025, 92002, 92004, 92012,
                                            92014, 93005, 97597, 98966, 98967, 98968, 98969, 98970, 98971, 98972, 99211, 99212, 99213, 99214, 99215, 99201,
                                            99202, 99203, 99204, 99205, 99223, 99241, 99242, 99243, 99244, 99245, 99281, 99282, 99283, 99284, 99285, 99304,
                                            99305, 99306, 99307, 99308, 99309, 99310, 99334, 99335, 99336, 99337, 99341, 99342, 99343, 99344, 99345, 99347,
                                            99348, 99349, 99350, 99381, 99382, 99383, 99384, 99385, 99386, 99387, 99391, 99392, 99393, 99394, 99395, 99396,
                                            99397, 99401, 99402, 99403, 99404, 99411, 99412, 99421, 99422, 99423, 99429, 99441, 99442, 99443, 99444, 99457,
                                            99483, G0402, G0438, G0439, G0463, T1015, H1000, G0071, G2010, G2012, G2061, G2062, G2063, G2250, G2251, G2252
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleAdultHealthClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('Adult Health')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: adultHealthMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: adultHealthMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(adultHealthMetric) ? adultHealthMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {adultHealthMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(adultHealthMetric) ? adultHealthMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                Depression Screen
                            </Typography>
                            <Tooltip
                                open={isDepressionScreenTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">For members aged greater than 12, in the rolling 6 month time frame</Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Screened :
                                        </Typography>
                                        <Typography variant="caption">CPT code G8510 OR G8431</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleDepressionScreenClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('Depression Screen')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: depressionScreenMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: depressionScreenMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(depressionScreenMetric) ? depressionScreenMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {depressionScreenMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(depressionScreenMetric) ? depressionScreenMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                Depression Follow Up
                            </Typography>
                            <Tooltip
                                open={isDepressionFollowUpTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">For members aged greater than 12 with a PHQ9{'>'}14 in the rolling 6 month time frame</Typography>

                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Documented :
                                        </Typography>

                                        <Typography variant="caption">
                                            Had an encounter with a CPT code of G8431 within 30 days of the documented PHQ9 score
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleDepressionFollowUpClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('Depression Follow Up')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: depressionFollowUpMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: depressionFollowUpMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(depressionFollowUpMetric) ? depressionFollowUpMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {depressionFollowUpMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(depressionFollowUpMetric) ? depressionFollowUpMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                Medication Reconciliation
                            </Typography>
                            <Tooltip
                                open={isMedicationTooltipShow}
                                title={
                                    <div>
                                        <Typography variant="body2">Within 30 days of discharge from a facility</Typography>

                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Documented :
                                        </Typography>

                                        <Typography variant="caption">CPT code 1111F</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleMedicationClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('Medication Reconciliation')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: medicationMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: medicationMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(medicationMetric) ? medicationMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {medicationMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(medicationMetric) ? medicationMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4} textAlign="center" paddingLeft="30px">
                            <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                FUH
                            </Typography>
                            <Tooltip
                                open={isFuhTooltipShow}
                                title={
                                    <div>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Visits within 7 Days :
                                        </Typography>
                                        <Typography variant="caption">Visits completed within 7 days of discharge</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleFuhClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                            <Card
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer',
                                        boxShadow: 10,
                                        border: 0,
                                    },
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={() => navigateToCustomDashboard('FUH')}
                            >
                                <CardContent
                                    sx={{
                                        backgroundColor: fuhMetric?.IsMet ? '#82d782' : '#FD5F61',
                                        color: fuhMetric?.IsMet ? 'black' : 'white',
                                    }}
                                >
                                    <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                        <Typography fontWeight={'bold'} variant="h4">
                                            {Boolean(fuhMetric) ? fuhMetric.Percentage : 0}%
                                        </Typography>
                                        <Stack direction={'row'} spacing={2}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {fuhMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(fuhMetric) ? fuhMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>
        </Paper>
    );
};

export default HedisMetricSummary;
