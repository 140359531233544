import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    TableContainer,
    CircularProgress,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Slide,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    Typography,
    FormControlLabel,
    Radio,
    Collapse,
    Stack,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../../reducers';
import classes from './Styles';
import { MedicationStatusEnum } from '../../../utils/assessments';
import { toggleShowMergeMedicationModal, updateMedications } from '../../../store/medication.slice';
import { Medications } from '../../../Models/Medications/Medications.model';
import { copyObject } from '../../../utils/common';
import { ProviderPreview } from '../../../Models/CarePlans/SchedulePlan.model';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const medicationFormSchema = Yup.object().shape({
    Medicine: Yup.string().nullable().required('Name is required.'),
    Dosage: Yup.string().nullable().required('Dosage is required.'),
    Frequency: Yup.string().nullable().required('Frequency is required.'),
});

const MergeMedications: React.FC<{ medications: Medications[]; memberId: string; onChange: Function }> = ({ medications, memberId, onChange }) => {
    const dispatch = useDispatch();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const { mergeMedication, showMergeMedicationModal, isSaving: isSavingMedication } = useSelector((state: RootState) => state.medication);

    const handleOnMedicationModal = () => dispatch(toggleShowMergeMedicationModal(false));

    const handleOnSubmit = (values: Medications) => {
        let selectedMedications = copyObject(medications) as Medications[];
        let previewMed = selectedMedications[0];
        previewMed.Medicine = values.Medicine;
        previewMed.Dosage = values.Dosage;
        previewMed.Frequency = values.Frequency;
        previewMed.PrescribedFor = values.PrescribedFor;
        previewMed.StartDate = values.StartDate;
        previewMed.EndDate = values.EndDate;
        previewMed.LastTaken = values.LastTaken;
        previewMed.OrderingProvider = values.OrderingProvider;

        selectedMedications.forEach((med) => {
            if (previewMed.Id !== med.Id) {
                med.Status = MedicationStatusEnum.Merged;
            }
        });

        dispatch(updateMedications(memberId, selectedMedications, true));
        if (onChange) {
            onChange();
        }
    };

    return (
        <Dialog
            disableScrollLock
            disableRestoreFocus
            disableAutoFocus
            disableEnforceFocus
            open={showMergeMedicationModal}
            maxWidth="xl"
            sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
            scroll="body"
            TransitionComponent={Transition}
            keepMounted
            onClose={handleOnMedicationModal}
        >
            <DialogTitle>Merge Medication</DialogTitle>
            {showMergeMedicationModal ? (
                <Formik initialValues={mergeMedication} onSubmit={handleOnSubmit} validationSchema={medicationFormSchema} enableReinitialize={true}>
                    {({ values, setFieldValue, errors, handleChange, validateForm, submitCount }: FormikProps<Medications>) => (
                        <Form>
                            <DialogContent dividers style={{ width: '1600px', maxWidth: '100%' }} sx={classes.form}>
                                <Typography variant="subtitle1" component="label" marginTop={1} marginBottom={1}>
                                    <strong>
                                        Medications to become <span style={{ color: 'red' }}>Inactive</span>
                                    </strong>
                                </Typography>
                                <Collapse
                                    in={Boolean(submitCount) && (Boolean(errors?.Medicine) || Boolean(errors?.Dosage) || Boolean(errors?.Frequency))}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <Stack marginBottom={2}>
                                        <Typography variant="subtitle1" component="label" color="red">
                                            {errors?.Medicine}
                                        </Typography>
                                        <Typography variant="subtitle1" component="label" color="red">
                                            {errors?.Dosage}
                                        </Typography>
                                        <Typography variant="subtitle1" component="label" color="red">
                                            {errors?.Frequency}
                                        </Typography>
                                    </Stack>
                                </Collapse>
                                <TableContainer sx={classes.tableContainer}>
                                    <Table size="small" style={{ tableLayout: 'fixed' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="15%">Name</TableCell>
                                                <TableCell width="10%">Dose</TableCell>
                                                <TableCell width="10%">Freq</TableCell>
                                                <TableCell width="10%">Prescribed For</TableCell>
                                                <TableCell width="10%">Ordering Provider</TableCell>
                                                <TableCell width="10%">Last Taken</TableCell>
                                                <TableCell width="10%">Fill Date</TableCell>
                                                <TableCell width="10%">End Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {medications?.map((med, index) => (
                                                <TableRow key={`merge_med_${index}`}>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={med.Medicine}
                                                            name="Medicine"
                                                            onChange={handleChange}
                                                            control={<Radio checked={med.Medicine === values.Medicine} />}
                                                            label={med.Medicine}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={med.Dosage}
                                                            name="Dosage"
                                                            onChange={handleChange}
                                                            control={<Radio checked={med.Dosage === values.Dosage} />}
                                                            label={med.Dosage}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={med.Frequency}
                                                            name="Frequency"
                                                            onChange={handleChange}
                                                            control={<Radio checked={med.Frequency === values.Frequency} />}
                                                            label={med.Frequency}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={med.PrescribedFor}
                                                            name="PrescribedFor"
                                                            onChange={handleChange}
                                                            control={<Radio checked={med.PrescribedFor === values.PrescribedFor} />}
                                                            label={med.PrescribedFor}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={Boolean(med.OrderingProvider?.Id) ? String(med.OrderingProvider.Id) : ''}
                                                            name="OrderingProvider"
                                                            onChange={(e: any) => {
                                                                if (Boolean(e.target.value)) {
                                                                    const selectedProvider = activeProviders.find((p) => p.Id === e.target.value);
                                                                    setFieldValue(e.target.name, {
                                                                        Id: selectedProvider.Id,
                                                                        Name: selectedProvider.FullName,
                                                                        Role: selectedProvider.Role?.RoleName,
                                                                    } as ProviderPreview);
                                                                } else {
                                                                    setFieldValue(e.target.name, null);
                                                                }
                                                            }}
                                                            control={
                                                                <Radio
                                                                    checked={
                                                                        Boolean(med.OrderingProvider?.Id) &&
                                                                        med.OrderingProvider?.Id === values.OrderingProvider?.Id
                                                                    }
                                                                />
                                                            }
                                                            label={med.OrderingProvider?.Name}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={med.LastTaken}
                                                            name="LastTaken"
                                                            onChange={handleChange}
                                                            control={<Radio checked={med.LastTaken === values.LastTaken} />}
                                                            label={moment(med.LastTaken).isValid() ? moment(med.LastTaken).format('MM/DD/yyyy') : ''}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={med.StartDate}
                                                            name="StartDate"
                                                            onChange={handleChange}
                                                            control={<Radio checked={med.StartDate === values.StartDate} />}
                                                            label={moment(med.StartDate).isValid() ? moment(med.StartDate).format('MM/DD/yyyy') : ''}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControlLabel
                                                            value={med.EndDate}
                                                            name="EndDate"
                                                            onChange={handleChange}
                                                            control={<Radio checked={med.EndDate === values.EndDate} />}
                                                            label={moment(med.EndDate).isValid() ? moment(med.EndDate).format('MM/DD/yyyy') : ''}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography variant="subtitle1" component="label" marginTop={4} marginBottom={1}>
                                    <strong>
                                        Preview of Medication <span style={{ color: 'green' }}>Saved</span>
                                    </strong>
                                </Typography>
                                <TableContainer sx={classes.tableContainer}>
                                    <Table size="small" style={{ tableLayout: 'fixed' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="15%">Name</TableCell>
                                                <TableCell width="10%">Dose</TableCell>
                                                <TableCell width="10%">Freq</TableCell>
                                                <TableCell width="10%">Prescribed For</TableCell>
                                                <TableCell width="10%">Ordering Provider</TableCell>
                                                <TableCell width="10%">Last Taken</TableCell>
                                                <TableCell width="10%">Fill Date</TableCell>
                                                <TableCell width="10%">End Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {values.Medicine}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {values.Dosage}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {values.Frequency}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {values.PrescribedFor}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {values.OrderingProvider?.Name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {moment(values.LastTaken).isValid() ? moment(values.LastTaken).format('MM/DD/yyyy') : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {moment(values.StartDate).isValid() ? moment(values.StartDate).format('MM/DD/yyyy') : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {moment(values.EndDate).isValid() ? moment(values.EndDate).format('MM/DD/yyyy') : ''}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="small"
                                    disabled={isSavingMedication}
                                    endIcon={isSavingMedication ? <CircularProgress size={18} color="inherit" /> : null}
                                >
                                    Save
                                </Button>
                                <Button size="small" type="button" onClick={handleOnMedicationModal} variant="outlined" disabled={isSavingMedication}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </Dialog>
    );
};

export default MergeMedications;
