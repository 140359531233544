export enum PlannedDischargeLocationEnum {
    Home = 1,
    Facility = 2,
    Shelter = 3,
    Family = 4,
    Homeless = 5,
    BHRF = 6,
    SNF = 7,
    Deceased = 8,
    Other = 9,
}
