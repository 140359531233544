import BaseEntity from '../BaseEntity.model';

export default interface IKeyContact extends BaseEntity {
    Name: string;
    PhoneNumber: string;
    Email: string;
    Note: string;
}

export class KeyContact implements IKeyContact {
    Id: string;
    Name: string;
    PhoneNumber: string;
    Email: string;
    Note: string;

    constructor() {
        this.Id = '';
        this.Name = '';
        this.PhoneNumber = '';
        this.Note = '';
        this.Email = '';
    }
}
