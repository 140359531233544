import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { apiString as API_URL } from '../../../utils/constants';
import { closePlanTypesHandler, setHealthPlanForm, setPlanTypes } from '../../../store/adminHealthPlan.slice';

const PlanTypesDialog = (props) => {
    const { healthPlanForm, healthPlanTypes, planTypes } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const dispatch = useDispatch();
    const [healthPlanSubTypes, setHealthPlanSubTypes] = useState([]);

    const getHealthPlanSubTypes = async (typeId) => {
        const response = await axios.get(`${API_URL}/managehealthplan/gethealthplansubtypes?id=${typeId}`);
        setHealthPlanSubTypes(response.data);
    };

    const savePlanTypeHandler = () => {
        let currentPlanTypes;
        if (healthPlanForm.PlanTypes) {
            currentPlanTypes = [...healthPlanForm.PlanTypes];
            let currentIndex = currentPlanTypes.findIndex((el) => el.Id === planTypes.Id);
            if (currentIndex !== -1) {
                currentPlanTypes[currentIndex] = planTypes;
            } else {
                currentPlanTypes = [...currentPlanTypes, planTypes];
            }
        } else {
            currentPlanTypes = [planTypes];
        }
        dispatch(setHealthPlanForm({ ...healthPlanForm, PlanTypes: currentPlanTypes }));
        dispatch(closePlanTypesHandler());
    };

    useEffect(() => {
        if (healthPlanForm.HealthPlanType) {
            getHealthPlanSubTypes(healthPlanForm.HealthPlanType.Id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthPlanForm.HealthPlanType]);

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} disableEnforceFocus>
                <DialogContent>
                    <Grid container spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                        <Grid item xs={12} marginBottom={2}>
                            <Typography variant="h6">Plan Types</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel required id="plan-type-label">
                                    Plan Type
                                </InputLabel>
                                <Select
                                    labelId="plan-type-label"
                                    required
                                    value={healthPlanTypes.find((el) => el.Id === planTypes.HealthPlanType?.Id) || ''}
                                    margin="dense"
                                    label="Plan Type"
                                    name="HealthPlanType"
                                    onChange={(e) => {
                                        dispatch(setPlanTypes({ ...planTypes, HealthPlanType: e.target.value, HealthPlanSubType: null }));
                                        getHealthPlanSubTypes(e.target.value?.Id);
                                    }}
                                    renderValue={(value) => value.Type}
                                >
                                    <MenuItem value={null}>Select</MenuItem>
                                    {healthPlanTypes.map((plan) => {
                                        return (
                                            <MenuItem value={plan} key={plan.Id}>
                                                {plan.Type}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {healthPlanSubTypes.length > 0 && (
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel required id="plan-type-label">
                                        {planTypes.HealthPlanType?.Type}
                                    </InputLabel>
                                    <Select
                                        labelId="plan-type-label"
                                        required
                                        id="plan-type"
                                        value={healthPlanSubTypes.find((el) => el.Id === planTypes.HealthPlanSubType?.Id) || ''}
                                        margin="dense"
                                        label={planTypes.HealthPlanType?.Type}
                                        name="HealthPlanSubType"
                                        onChange={(e) => {
                                            dispatch(setPlanTypes({ ...planTypes, HealthPlanSubType: e.target.value }));
                                        }}
                                        renderValue={(value) => value.SubType}
                                    >
                                        {healthPlanSubTypes.map((plan) => {
                                            return (
                                                <MenuItem value={plan} key={plan.Id}>
                                                    {plan.SubType}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={planTypes.OrganizationId || ''}
                                    required
                                    id="name"
                                    label="Organization Id"
                                    name="OrganizationId"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => dispatch(setPlanTypes({ ...planTypes, OrganizationId: e.target.value }))}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={planTypes.OrganizationName || ''}
                                    id="name"
                                    label="Organization Name"
                                    name="OrganizationName"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => dispatch(setPlanTypes({ ...planTypes, OrganizationName: e.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ marginTop: 2 }}>
                    <Button
                        className="button-120"
                        variant="outlined"
                        onClick={() => {
                            props.onClose();
                            setHealthPlanSubTypes([]);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            planTypes.OrganizationId.length === 0 ||
                            planTypes.HealthPlanType === null ||
                            (planTypes.HealthPlanSubType === null && healthPlanSubTypes.length > 0)
                        }
                        className="button-120"
                        variant="contained"
                        onClick={savePlanTypeHandler}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PlanTypesDialog;
