import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, CircularProgress, Dialog, DialogTitle, DialogContent, Stack, Typography, Chip, DialogActions } from '@mui/material';

import { RootState } from '../../../reducers';
import { fetchDiagnosisCodesFromClaims, getPastDiagnosisByMember } from '../../../store/encounter.slice';
import { ICD10 } from '../../../Models/Shared/ICD10.model';

const DiagnosisFromClaims: React.FC<{
    memberId: string;
    show: boolean;
    selectedDiagnosis: string[];
    onSelect: Function;
    onClose: Function;
    isSaving?: boolean;
}> = ({ memberId, show, selectedDiagnosis, onSelect, onClose, isSaving = false }) => {
    const dispatch = useDispatch();
    const { diagnosisFromClaims: claimsDiagnosis, memberPastDiagnosys, isFetching } = useSelector((state: RootState) => state.encounter);

    useEffect(() => {
        dispatch(fetchDiagnosisCodesFromClaims(memberId));
        dispatch(getPastDiagnosisByMember(memberId));
    }, [dispatch, memberId]);

    const renderDiagnosisList = (title: string, diagnosises: ICD10[]) => {
        if (!Boolean(diagnosises?.length)) {
            return null;
        }

        return (
            <Stack spacing={2} marginBottom={2}>
                <Typography variant="subtitle2" component="label">
                    {title}
                </Typography>
                <Box>
                    {diagnosises.map((diagnosis, index) => (
                        <Chip
                            key={`diagnosis-${diagnosis.Id}-${index}`}
                            sx={{ mb: 2, mr: 2 }}
                            disabled={isSaving || selectedDiagnosis.some((s) => s === diagnosis.Code)}
                            onClick={() => onSelect(diagnosis)}
                            label={`${diagnosis.Code} ${diagnosis.Name}`}
                            color="primary"
                            variant="outlined"
                        />
                    ))}
                </Box>
            </Stack>
        );
    };

    return (
        <Dialog
            open={show}
            maxWidth={false}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle display="flex" alignItems="center">
                Select Diagnosis &nbsp; {isSaving && <CircularProgress size={22} />}
            </DialogTitle>
            <DialogContent dividers sx={{ width: '800px', maxWidth: '100%' }} style={{ position: 'relative' }}>
                {isFetching ? (
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                ) : (
                    <React.Fragment>
                        {renderDiagnosisList('Past Diagnosis', memberPastDiagnosys)}
                        {renderDiagnosisList(
                            'Claims',
                            claimsDiagnosis.filter((d) => !(memberPastDiagnosys || []).some((cd) => cd.Code === d.Code))
                        )}
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button size="small" type="button" onClick={() => onClose()} variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DiagnosisFromClaims;
