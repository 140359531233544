import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fab, Grid, Paper, Stack, Button, CircularProgress } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../../reducers';
import MemberAvatar from '../MemberAvatar';
import { fetchCaseManagers, fetchPcpAndSpecialists, fetchPharmacyList, fetchSpecialistTypes } from '../../../../store/memberDetail.slice';
import { fetchPreferredContactOptions } from '../../../../store/shared.slice';
import PcpTable from './PcpTable';
import SpecialistTable from './SpecialistTable';
import PharmacyTable from './PharmacyTable';
import CaseManagerTable from './CaseManagerTable';
import { saveEngagementDraft } from '../../../../store/documentEngagement.slice';
import { StatusEnum } from '../../../../Enum/StatusEnum';
import { gotoNextMenu, gotoPreviousMenu } from '../../../../store/assessment.slice';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import { SummaryNoteTypeEnum } from '../../../../Enum/SummaryNoteTypeEnum';
import { copyObject } from '../../../../utils/common';

const MemberPcpSpecialist: React.FC<{ isAssessment?: boolean }> = ({ isAssessment = false }) => {
    const dispatch = useDispatch();
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [selectedStatusTable, setSelectedStatusTable] = useState(0);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    useEffect(() => {
        dispatch(fetchPreferredContactOptions());
        dispatch(fetchSpecialistTypes());
        dispatch(fetchCaseManagers(selectedMember.Id));
        dispatch(fetchPharmacyList(selectedMember.Id));
        dispatch(fetchPcpAndSpecialists(selectedMember.Id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            let data = copyObject(engagementDraft);
            data.SummaryNotes = data.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.PcpSpecialist) || [];
            data.SummaryNotes.push({
                Id: '',
                CptCode: '',
                Zcode: '',
                Note: 'Completed Pcp & Specialist Information',
                Type: SummaryNoteTypeEnum.PcpSpecialist,
                IsConfirm: false,
                OrderBy: 33,
                DisplayName: 'Pcp, Specialist & Pharmacy',
            });

            dispatch(saveEngagementDraft({ ...data, PcpSpecialistStatus: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    return (
        <div>
            <Paper
                elevation={3}
                className={isAssessment ? 'content-panel' : ''}
                style={isAssessment ? { padding: '10px' } : { padding: '10px', margin: '16px', textAlign: 'right' }}
            >
                <Stack spacing={2}>
                    <div>
                        <MemberAvatar />
                        <Grid container spacing={3} style={{ maxWidth: '700px', float: 'right' }}>
                            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
                                <Fab
                                    variant="extended"
                                    color={selectedStatusTable === 3 ? 'primary' : 'default'}
                                    className="fab-right"
                                    onClick={() => {
                                        setSelectedStatusTable(3);
                                    }}
                                >
                                    Case Manager
                                </Fab>
                                <Fab
                                    variant="extended"
                                    color={selectedStatusTable === 2 ? 'primary' : 'default'}
                                    className="fab-right"
                                    onClick={() => {
                                        setSelectedStatusTable(2);
                                    }}
                                >
                                    Pharmacy
                                </Fab>
                                <Fab
                                    variant="extended"
                                    color={selectedStatusTable === 1 ? 'primary' : 'default'}
                                    className="fab-right"
                                    onClick={() => {
                                        setSelectedStatusTable(1);
                                    }}
                                >
                                    Specialist
                                </Fab>
                                <Fab
                                    variant="extended"
                                    color={selectedStatusTable === 0 ? 'primary' : 'default'}
                                    className="fab-right"
                                    onClick={() => {
                                        setSelectedStatusTable(0);
                                    }}
                                >
                                    PCP
                                </Fab>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={3} style={{ padding: '0px 24px 16px 0px', marginTop: '0px' }}>
                        <Grid item xs={12} style={{ paddingLeft: '0px' }}>
                            {selectedStatusTable === 0 ? <PcpTable /> : null}
                            {selectedStatusTable === 1 ? <SpecialistTable /> : null}
                            {selectedStatusTable === 2 ? <PharmacyTable /> : null}
                            {selectedStatusTable === 3 ? <CaseManagerTable /> : null}
                        </Grid>
                    </Grid>
                </Stack>
                {isAssessment ? (
                    <>
                        <Stack direction="row" justifyContent="flex-end" className="content-footer" style={{ margin: '-10px' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ mr: 3 }}
                                disabled={isSavingEngagementDraft}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={handleOnSubmit}
                                disabled={isSavingEngagementDraft}
                                endIcon={isSavingEngagementDraft ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                            >
                                Next
                            </Button>
                        </Stack>
                        <AlertDialog
                            open={showSaveAlert}
                            title="PCP, Specialists & Pharmacy"
                            cancelText="Go Back"
                            okText="Confirm"
                            onConfirm={() => handleOnConfirmToNext(true)}
                            onSkip={() => handleOnConfirmToNext()}
                            onClose={() => setShowSaveAlert(false)}
                            isLoading={isSavingEngagementDraft}
                            message="I confirm and acknowledged that PCP, Specialists & Pharmacy is completed."
                        />
                    </>
                ) : null}
            </Paper>
        </div>
    );
};

export default MemberPcpSpecialist;
