import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

const teamsSlice = createSlice({
    name: 'teams',
    initialState: {
        teams: [],
        npAndBhTeams: [],
        teamsByProviderId: '',
    },
    reducers: {
        _setTeams(state, action) {
            state.teams = action.payload;
        },
        _setNpOrBhTeams(state, action) {
            state.npAndBhTeams = action.payload;
        },
        _setTeamsByProviderId(state, action) {
            state.teamsByProviderId = action.payload;
        },
    },
});

const { _setTeams, _setNpOrBhTeams, _setTeamsByProviderId } = teamsSlice.actions;

export const fetchTeams = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/team/getallteams`);
    dispatch(_setTeams(response.data));
};

export const fetchTeamsName = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/team/getteamsname`);
    dispatch(_setTeams(response.data));
};

export const fetchTeamsByProviderId =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/team/getteamsbyproviderid?providerId=${providerId}`);
        dispatch(_setTeamsByProviderId(response.data[0]?.Id || ''));
    };

export const fetchNpOrBhTeams = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/team/getbhandnpteam`);
    dispatch(_setNpOrBhTeams(response.data));
};

export default teamsSlice.reducer;
