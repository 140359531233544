export default interface IOnboardingCallReport {
    startDateTime: Date;
    endDateTime: Date;
    selectedHealthPlan: string;
    selectedPopulation: number;
    selectedNoteType: string[];
    selectedOnboarder: string;
}

export class OnboardingCallReport implements IOnboardingCallReport {
    startDateTime: Date;
    endDateTime: Date;
    selectedHealthPlan: string;
    selectedPopulation: number | null;
    selectedNoteType: string[];
    selectedOnboarder: string;

    constructor() {
        this.startDateTime = new Date();
        this.endDateTime = new Date();
        this.selectedHealthPlan = '';
        this.selectedPopulation = null;
        this.selectedNoteType = [];
        this.selectedOnboarder = '';
    }
}
