import { Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { apiString as API_URL } from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import Authorization from '../../../../utils/Authorization';

const CallingPerformance = () => {
    const authData = new Authorization();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [callingPerformance, setCallingPerformance] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('-1');
    const [showInActiveProvider, setShowInActiveProvider] = useState<boolean>(false);
    const filteredProviders = (showInActiveProvider ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const scheduledProviders = filteredProviders.filter((p) => p.Role.RoleName === 'Scheduling');

    const getCallingPerformance = async (id: string) => {
        try {
            const response = await axios.get(`${API_URL}/onboarding/GetOnboarderCallerStats/${id}`);
            setCallingPerformance(response.data);
        } catch (error) {
            console.log(error);
            setCallingPerformance([]);
        }
    };
    const getOnboarderId = async (providerId) => {
        try {
            const response = await axios.get(`${API_URL}/onboarding/GetOnboarderId/${providerId}`);
            const id = await response.data;
            getCallingPerformance(id);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getOnboarderId(authData.UserId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProvider(isChecked);
        setSelectedProvider('-1');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'}>
                        Calling Performance
                    </Typography>
                </Grid>

                {showProviders && (
                    <Grid item marginTop={2} xs={12}>
                        <FormControl className="form-control-200">
                            <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={scheduledProviders.find((provider) => provider.Id === selectedProvider)?.Id || '-1'}
                                size="small"
                                label="Provider"
                                onChange={(e) => {
                                    setSelectedProvider(e.target.value as string);
                                    getOnboarderId(e.target.value as string);
                                }}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                            >
                                <MenuItem value={'-1'} disabled>
                                    Select Provider
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveProvider} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {scheduledProviders.map((option) => {
                                    return (
                                        <MenuItem key={option.Id} value={option.Id} sx={{ color: option.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {`${option.LastName}, ${option.FirstName}`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {/* <Grid container marginTop={2} spacing={2} > */}
                <Grid container spacing={2} marginTop={2} textAlign="center" height="58vh" sx={{ overflowY: 'scroll', position: 'relative' }}>
                    <Grid item xs={6}>
                        <Card
                            elevation={10}
                            sx={{
                                maxHeight: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 3,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="body1" fontWeight={'bold'}>
                                Average Five Day Calls
                            </Typography>
                            <CardContent>
                                <Typography variant="h2" fontWeight="bold" color="#ff6525">
                                    {callingPerformance['averageFiveDayCalls']}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card
                            elevation={10}
                            sx={{
                                maxHeight: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 3,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="body1" fontWeight={'bold'}>
                                Number of Calls Today
                            </Typography>
                            <CardContent>
                                <Typography variant="h2" fontWeight="bold" color="#bb285b">
                                    {callingPerformance['callsToday']}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card
                            elevation={10}
                            sx={{
                                maxHeight: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 3,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="body1" fontWeight={'bold'}>
                                Average Scheduled Appointments (Last 5 Work Days)
                            </Typography>
                            <CardContent>
                                <Typography variant="h2" fontWeight="bold" color="#5427ac">
                                    {callingPerformance['averageScheduledAppointments']}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card
                            elevation={10}
                            sx={{
                                maxHeight: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 3,
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="body1" fontWeight={'bold'}>
                                Number of Appointments Scheduled Today
                            </Typography>
                            <CardContent>
                                <Typography variant="h2" fontWeight="bold" color="#0da2d4">
                                    {callingPerformance['scheduledToday']}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default CallingPerformance;
