import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, FormControl, Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EngagementDraft } from '../../../Models/DocumentEngagements/EngagementDraft.model';
import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu, setAssessmentError, setAssessmentSuccess } from '../../../store/assessment.slice';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { copyObject } from '../../../utils/common';
import AssessmentsCalendar from '../../Calendar/AssessmentsCalendar/AssessmentsCalendar';

const SetAppointment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const handleOnSelectAppointmentDate = (params) => {
        if (!Boolean(params.appointmentId) || params.appointmentId.includes('_')) {
            dispatch(setAssessmentError({ Show: true, Message: `Unable to set Engagement date. Please wait for appointment to save and try again.` }));
        } else {
            let data = copyObject(engagementDraft) as EngagementDraft;
            data.VisitedTime = new Date(params.date).toISOString();
            data.AssociatedAppointmentId = params.appointmentId;
            dispatch(saveEngagementDraft(data));
            dispatch(
                setAssessmentSuccess({
                    Show: true,
                    Message: 'Engagement date and time set. This will not reflect on other calendars until assessment is finished.',
                })
            );
        }
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            <Grid item lg={12} md={12} sm={12} sx={{ ml: 1, mb: 4 }}>
                <FormControl className="form-control-200">
                    <DesktopDatePicker
                        label="Engagement Date"
                        disabled
                        value={Boolean(engagementDraft) && Boolean(engagementDraft.VisitedTime) ? new Date(engagementDraft.VisitedTime) : null}
                        inputFormat="dd-MMM-yyyy"
                        minDate={new Date('2017-01-01')}
                        onChange={() => {}}
                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                    />
                </FormControl>
            </Grid>
            <Grid item className="content-form">
                {<AssessmentsCalendar memberId={memberId} onSelectAppointmentDate={handleOnSelectAppointmentDate} />}
            </Grid>
            <Grid item justifyContent="flex-end" className="content-footer">
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ mr: 3 }}
                    onClick={() => dispatch(gotoPreviousMenu())}
                    startIcon={<ArrowBackIosIcon />}
                >
                    Previous
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIosIcon />}
                    disabled={!Boolean(engagementDraft?.VisitedTime)}
                    onClick={() => dispatch(gotoNextMenu())}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export default SetAppointment;
