import BaseEntity from '../BaseEntity.model';
import { LastUpdatedBy } from '../Shared/LastUpdatedBy.model';
import { Phone } from '../Shared/Phone.model';
import { Team } from './Team.model';

export default interface IMember extends BaseEntity {
    EMRId: string;
    MemberId: string;
    OnboarderAssigned: boolean;
    OnboarderScheduled: boolean;
    Life365Ref: string;
    Population: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Address: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Country: string;
    ZipCode: string;
    HomePhone: string;
    MobilePhone: string;
    PreferredContact: number;
    Email: string;
    Gender: number;
    Age: number;
    DOB: Date;
    SSN: string;
    Height: number;
    Weight: number;
    RiskLevel: number;
    RiskScore: number;
    Photo: string;
    StoryAudio: string;
    MaritalStatus: string;
    Children: string;
    Career: string;
    Hobbies: string;
    MyFavorites: string;
    IsInTransition: boolean;
    PrimaryLanguage: { Id: string; Name: string };
    Phones: Phone[];
}

export class Member implements IMember {
    Id: string;
    EMRId: string;
    MemberId: string;
    OnboarderAssigned: boolean;
    OnboarderScheduled: boolean;
    Life365Ref: string;
    Population: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Address: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Country: string;
    ZipCode: string;
    HomePhone: string;
    MobilePhone: string;
    PreferredContact: number;
    Email: string;
    Gender: number;
    Age: number;
    DOB: Date;
    SSN: string;
    Height: number;
    Weight: number;
    RiskLevel: number;
    RiskScore: number;
    Photo: string;
    StoryAudio: string;
    MaritalStatus: string;
    Children: string;
    Career: string;
    Hobbies: string;
    MyFavorites: string;
    LastUpdatedBy: LastUpdatedBy | null;
    Team: Team;
    IsInTransition: boolean;
    PrimaryLanguage: { Id: string; Name: string };
    Phones: Phone[];

    constructor() {
        this.Id = '';
        this.EMRId = '';
        this.MemberId = '';
        this.FirstName = '';
        this.LastName = '';
        this.MiddleName = '';
    }
}
