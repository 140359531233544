import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Divider, IconButton, Typography, styled } from '@mui/material';
import React from 'react';

export interface IProviderNameExpanderProps {
    providerNames: string[];
}

const RotateButton = styled(({ open, ...other }: { open: boolean; [key: string]: any }) => <IconButton {...other} />)(({ theme, open }) => ({
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ProviderNameExpander = (props: IProviderNameExpanderProps) => {
    const { providerNames } = props;
    const [open, setOpen] = React.useState(true);
    const providerList = providerNames.join(', ');

    return (
        <Box>
            <Collapse in={open} collapsedSize={30}>
                <Typography variant="body2" color="text.secondary" style={{ userSelect: 'text' }}>
                    <span style={{ fontWeight: 'bold' }}>Providers</span>: {providerList}
                </Typography>
            </Collapse>
            <Divider>
                <RotateButton open={open} onClick={() => setOpen(!open)}>
                    <ExpandMoreIcon />
                </RotateButton>
            </Divider>
        </Box>
    );
};

export default ProviderNameExpander;
