export enum LevelOfUnderstandingEnum {
    GoodDescription = 'Good - Verbalizes understanding and desire',
    Good = 1,
    FairDescription = 'Fair - Unsure or undecided about making a decision or ability to change',
    Fair = 2,
    PoorDescription = 'Poor - Refuses to learn or make a decision on needed change',
    Poor = 3,
    DeclinedDescription = 'Declined - Refused to complete member education',
    Declined = 4,
};
