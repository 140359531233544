import { Button, DialogActions, DialogContent, FormControl, Grid, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { setActiveStep, setOpenAddMember, setImportSuccessful, uploadMember } from '../../store/fuhfum.slice';
import { localDateFormat } from '../../utils/timeFormat';
import LinearProgressWithLabel from '../Shared/ProgressBar';
import { isDefaultDate } from '../../utils/common';

const ImportData = (props) => {
    const { fileProcessedCount, importSuccessful, updatingMember, validatedMembers } = useSelector((state: RootState) => state.fuhfum);
    const { file, setFile } = props;
    const newMembers = validatedMembers.filter((m) => !m.IsAlreadyExisting);
    const existingMembers = validatedMembers.filter((m) => m.IsAlreadyExisting);
    const dispatch = useDispatch();
    const newColumns: GridColDef[] = [
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 160,
        },
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 120,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'MiddleName',
            headerName: 'Middle Name',
            minWidth: 160,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 100,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'Gender',
            headerName: 'Gender',
            minWidth: 100,
        },
        {
            field: 'Address1',
            headerName: 'Address1',
            minWidth: 230,
            flex: 1,
        },
        {
            field: 'Address2',
            headerName: 'Address2',
            minWidth: 220,
            flex: 1,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 160,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            minWidth: 100,
        },
        {
            field: 'PcpName',
            headerName: 'PCP Name',
            minWidth: 250,
            flex: 1,
        },
    ];
    const existingColumns: GridColDef[] = [
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 160,
        },
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 120,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'MiddleName',
            headerName: 'Middle Name',
            minWidth: 160,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 100,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 150,
        },
        {
            field: 'Population',
            headerName: 'Population',
            minWidth: 140,
        },
        {
            field: 'Gender',
            headerName: 'Gender',
            minWidth: 100,
        },
        {
            field: 'Address1',
            headerName: 'Address1',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'Address2',
            headerName: 'Address2',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 160,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            minWidth: 100,
        },
        {
            field: 'PcpName',
            headerName: 'PCP Name',
            minWidth: 250,
            flex: 1,
        },
    ];
    return (
        <>
            <LinearProgressWithLabel value={fileProcessedCount.Percentage} />
            {importSuccessful && (
                <DialogContent>
                    <FormControl fullWidth>
                        <Grid spacing={2} container p={2} sx={{ height: '65vh' }}>
                            <Grid item textAlign="center" marginX={'auto'} xs={6}>
                                <Typography variant="h4">Your file was imported successfully.</Typography>
                                {!updatingMember && (
                                    <Button
                                        variant="contained"
                                        className="button-120"
                                        sx={{ marginTop: 3 }}
                                        onClick={() => {
                                            setFile(null);
                                            dispatch(setImportSuccessful(false));
                                            dispatch(setActiveStep(0));
                                        }}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {updatingMember && (
                                    <Button
                                        variant="contained"
                                        className="button-120"
                                        sx={{ marginTop: 3 }}
                                        onClick={() => {
                                            dispatch(setOpenAddMember(false));
                                            setFile(null);
                                        }}
                                    >
                                        Finish
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
            )}
            {!importSuccessful && (
                <>
                    <DialogContent>
                        <FormControl fullWidth>
                            <Grid spacing={2} container p={2} sx={{ height: '70vh' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" marginBottom={3}>
                                        Review & import your data
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="center" variant="h6">
                                        New Members
                                    </Typography>
                                    <div style={{ height: '55vh', width: '100%' }}>
                                        <DataGrid
                                            rows={newMembers}
                                            columns={newColumns}
                                            rowsPerPageOptions={[5]}
                                            getRowId={(row) => row.AhcccsId}
                                            getRowHeight={() => 'auto'}
                                            sx={{
                                                [`& .${gridClasses.cell}`]: {
                                                    py: 1,
                                                },
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="center" variant="h6">
                                        Existing Members
                                    </Typography>
                                    <div style={{ height: '55vh', width: '100%' }}>
                                        <DataGrid
                                            rows={existingMembers}
                                            columns={existingColumns}
                                            rowsPerPageOptions={[5]}
                                            getRowId={(row) => row.AhcccsId}
                                            getRowHeight={() => 'auto'}
                                            sx={{
                                                [`& .${gridClasses.cell}`]: {
                                                    py: 1,
                                                },
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button className="button-120" variant="contained" onClick={() => dispatch(setActiveStep(1))}>
                            Previous
                        </Button>
                        <Button
                            className="button-120"
                            variant="contained"
                            disabled={validatedMembers.length === 0}
                            onClick={() => dispatch(uploadMember(file))}
                        >
                            Import
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

export default ImportData;
