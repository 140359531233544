import { Box, Button, Divider, Slider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { fetchVitalsSigns, saveVitalSigns, setVitalSigns } from '../../../../store/memberDetail.slice';

const TelemonitoringParameters = () => {
    const dispatch = useDispatch();
    const { selectedMember, vitalSigns } = useSelector((state: RootState) => state.memberDetail);

    const handleChange = (event: Event, newValue: number | number[], type: number) => {
        let newVitalSigns = vitalSigns.map((existingO) => {
            let updateO = { ...existingO };
            return updateO;
        });

        let vital = newVitalSigns.find((x) => x.Type === type);
        vital.Values = newValue;
        vital.Min = newValue[0];
        vital.Max = newValue[1];

        dispatch(setVitalSigns(newVitalSigns));
    };

    const save = () => {
        dispatch(saveVitalSigns(vitalSigns));
    };

    const cancel = () => {
        dispatch(fetchVitalsSigns(selectedMember.Id));
    };

    return (
        <Box>
            <Divider style={{ marginBottom: '16px' }}></Divider>
            <Stack spacing={4}>
                <Stack direction="column" spacing={1} style={{ marginBottom: '32px' }}>
                    <Typography style={{ textAlign: 'left' }}>Systolic BP</Typography>
                    <Slider
                        getAriaLabel={() => 'Systolic BP'}
                        value={vitalSigns.find((x) => x.Type === 1)?.Values}
                        onChange={(e: any) => {
                            handleChange(e, e.target.value, 1);
                        }}
                        max={200}
                        valueLabelDisplay="on"
                    />
                </Stack>
                <Stack direction="column" spacing={1} style={{ marginBottom: '32px' }}>
                    <Typography style={{ textAlign: 'left' }}>Diastolic BP</Typography>
                    <Slider
                        getAriaLabel={() => 'Diastolic BP'}
                        value={vitalSigns.find((x) => x.Type === 2)?.Values}
                        onChange={(e: any) => {
                            handleChange(e, e.target.value, 2);
                        }}
                        min={40}
                        max={140}
                        valueLabelDisplay="on"
                    />
                </Stack>
                <Stack direction="column" spacing={1} style={{ marginBottom: '32px' }}>
                    <Typography style={{ textAlign: 'left' }}>Heart Rate</Typography>
                    <Slider
                        getAriaLabel={() => 'Heart Rate'}
                        value={vitalSigns.find((x) => x.Type === 3)?.Values}
                        onChange={(e: any) => {
                            handleChange(e, e.target.value, 3);
                        }}
                        min={10}
                        max={200}
                        valueLabelDisplay="on"
                    />
                </Stack>
                <Stack direction="column" spacing={1} style={{ marginBottom: '32px' }}>
                    <Typography style={{ textAlign: 'left' }}>Oximeter</Typography>
                    <Slider
                        getAriaLabel={() => 'Oximeter'}
                        value={vitalSigns.find((x) => x.Type === 4)?.Values}
                        onChange={(e: any) => {
                            handleChange(e, e.target.value, 4);
                        }}
                        min={0}
                        max={100}
                        valueLabelDisplay="on"
                    />
                </Stack>
                <Stack direction="column" spacing={1} style={{ marginBottom: '32px' }}>
                    <Typography style={{ textAlign: 'left' }}>Scale</Typography>
                    <Slider
                        getAriaLabel={() => 'Scale'}
                        value={vitalSigns.find((x) => x.Type === 5)?.Values}
                        onChange={(e: any) => {
                            handleChange(e, e.target.value, 5);
                        }}
                        min={0}
                        max={10}
                        valueLabelDisplay="on"
                    />
                </Stack>
                <Stack direction="column" spacing={1} style={{ marginBottom: '32px' }}>
                    <Typography style={{ textAlign: 'left' }}>Glucose</Typography>
                    <Slider
                        getAriaLabel={() => 'Glucose'}
                        value={vitalSigns.find((x) => x.Type === 6)?.Values}
                        onChange={(e: any) => {
                            handleChange(e, e.target.value, 6);
                        }}
                        min={50}
                        max={350}
                        valueLabelDisplay="on"
                    />
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={save}>
                            Save Parameters
                        </Button>
                        <Button className="button-120" variant="outlined" onClick={cancel}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default TelemonitoringParameters;
