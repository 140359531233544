import { CarePlanEnum } from '../../Enum/CarePlanEnum';
import BaseEntity from '../BaseEntity.model';
import { Progress } from './Progress.model';
import { DeletedBy } from '../Shared/DeletedBy.model';
import { CreatedBy } from '../Shared/CreatedBy.model';

export default interface IGoal extends BaseEntity {
    Name: string;
    Status: CarePlanEnum;
    Progress: Progress[];
    CreatedAt: string;
    DueDate: string;
    CreatedBy: CreatedBy | null;
    DeletedDate: string;
    ReasonForDelete: string;
    DeletedProviderById: DeletedBy | null;
    IsAddedBySystem: boolean;
    UpdatedAt: string;
}

export class Goal implements IGoal {
    Id: string | null;
    Name: string;
    Status: CarePlanEnum;
    Progress: Progress[];
    CreatedAt: string;
    CreatedBy: CreatedBy | null;
    DueDate: string;
    DeletedDate: string;
    ReasonForDelete: string;
    DeletedProviderById: DeletedBy | null;
    IsAddedBySystem: boolean;
    UpdatedAt: string;

    SelectedForDelete: boolean;
    IsSelected: boolean;
    IsOpenProgress: boolean;
    NewProgressNote: string;

    constructor() {
        this.Id = null;
        this.Name = '';
        this.Status = 0;
        this.Progress = [] as Progress[];
        this.CreatedAt = '';
        this.CreatedBy = null;
        this.DueDate = '';
        this.DeletedDate = '';
        this.ReasonForDelete = '';
        this.DeletedProviderById = null;
        this.IsAddedBySystem = false;
        this.UpdatedAt = '';

        this.SelectedForDelete = false;
        this.IsSelected = false;
        this.IsOpenProgress = false;
        this.NewProgressNote = '';
    }
}
