import { CalendarLoadModeEnum } from '../Enums/CalendarLoadModeEnum';
import { ScheduleOptionMap } from '../types/ScheduleOptionMap';

export const scheduleOptionsMap: ScheduleOptionMap = {
    [CalendarLoadModeEnum.ByAllProviders]: {
        value: CalendarLoadModeEnum.ByAllProviders,
        label: 'All Providers',
    },
    [CalendarLoadModeEnum.ByMemberAndProviders]: {
        value: CalendarLoadModeEnum.ByMemberAndProviders,
        label: 'Member and Provider',
    },
    [CalendarLoadModeEnum.ByExternalProvider]: {
        value: CalendarLoadModeEnum.ByExternalProvider,
        label: 'External Provider',
    },
    [CalendarLoadModeEnum.ByMember]: {
        value: CalendarLoadModeEnum.ByMember,
        label: 'Member',
    },
    [CalendarLoadModeEnum.ByProviders]: {
        value: CalendarLoadModeEnum.ByProviders,
        label: 'Providers',
    },
    [CalendarLoadModeEnum.ByCurrentUser]: {
        value: CalendarLoadModeEnum.ByCurrentUser,
        label: 'Your Schedule',
    },
};

export const scheduleOptionsList = Object.values(scheduleOptionsMap);
