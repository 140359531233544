import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';
import { OnboardingCallReport } from '../Models/AdminOnboarding/OnboardingCallReport';

const onboardingMemberReport = createSlice({
    name: 'onboardingMemberReport',
    initialState: {
        exporting: false,
        isTableFetching: false,
        onboardingReportStartDate: new Date(),
        onboardingReportEndDate: new Date(),
        onboardingRowData: [],
        selectedHealthPlan: '-1',
        selectedNoteType: [],
        selectedOnboarder: '-1',
        selectedPopulation: -1,
        unreachablePhonesData: [],
    },
    reducers: {
        _setExporting(state, action) {
            state.exporting = action.payload;
        },
        _setIsTableFetching(state, action) {
            state.isTableFetching = action.payload;
        },
        _setOnboardingStartDate(state, action) {
            state.onboardingReportStartDate = action.payload;
        },
        _setOnboardingEndDate(state, action) {
            state.onboardingReportEndDate = action.payload;
        },
        _setOnboardingRowData(state, action) {
            state.onboardingRowData = action.payload;
        },
        _setSelectedHealthPlan(state, action) {
            state.selectedHealthPlan = action.payload;
        },
        _setSelectedNoteType(state, action) {
            state.selectedNoteType = action.payload;
        },
        _setSelectedOnboarder(state, action) {
            state.selectedOnboarder = action.payload;
        },
        _setSelectedPopulation(state, action) {
            state.selectedPopulation = action.payload;
        },
        _setUnReachablePhonesData(state, action) {
            state.unreachablePhonesData = action.payload;
        },
    },
});

const {
    _setExporting,
    _setIsTableFetching,
    _setOnboardingStartDate,
    _setOnboardingEndDate,
    _setOnboardingRowData,
    _setSelectedHealthPlan,
    _setSelectedNoteType,
    _setSelectedOnboarder,
    _setSelectedPopulation,

    _setUnReachablePhonesData,
} = onboardingMemberReport.actions;

export const setExporting =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setExporting(params));
    };

export const setOnboardingStartDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setOnboardingStartDate(params));
    };

export const setOnboardingEndDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setOnboardingEndDate(params));
    };

export const setOnboardingRowData =
    (params): AppThunk =>
    async (dispatch) => {
        dispatch(_setOnboardingRowData(params));
    };

export const setSelectedHealthPlan =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedHealthPlan(params));
    };

export const setSelectedNoteType =
    (params: string | string[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedNoteType(params));
    };

export const setSelectedOnboarder =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedOnboarder(params));
    };

export const setSelectedPopulation =
    (params: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedPopulation(params));
    };

export const setUnReachablePhonesData =
    (params): AppThunk =>
    async (dispatch) => {
        dispatch(_setUnReachablePhonesData(params));
    };

export const fetchOnboardingMemberReport =
    (onboardingReportStartDate: Date, onboardingReportEndDate: Date): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/onboardingreport/getonboardingreport?start=${dateFormatter(onboardingReportStartDate)}&end=${dateFormatter(onboardingReportEndDate)}`
        );
        dispatch(_setOnboardingRowData(response.data));
        dispatch(setIsLoading(false));
    };

export const fetchOnboardingCallsReport =
    (onboardingCallReport: OnboardingCallReport): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsTableFetching(true));
        try {
            const response = await axios.post(`${API_URL}/onboardingreport/getonboardingcallsreport`, onboardingCallReport);
            dispatch(_setUnReachablePhonesData(response.data));
        } catch (error) {
            console.log('Error fetching unreachable phones report', error);
        }
        dispatch(_setIsTableFetching(false));
    };

export const exportOnboardingMemberReport =
    (onboardingReportStartDate: Date, onboardingReportEndDate: Date): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/onboardingreport/exportonboardingreport?start=${dateFormatter(onboardingReportStartDate)}&end=${dateFormatter(onboardingReportEndDate)}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default onboardingMemberReport.reducer;
