import { TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { IFormikTextFieldProps } from './interfaces/IFormikTextFieldProps';

const FormikTextField: React.FC<IFormikTextFieldProps> = ({ ...props }) => {
    const [field, meta] = useField(props);
    let helperText = props.helperText;
    if (meta.touched && Boolean(meta.error)) {
        helperText = meta.error;
    }
    return (
        <TextField
            fullWidth
            InputProps={{ readOnly: props.readOnly }}
            {...field}
            {...props}
            variant="outlined"
            error={meta.touched && Boolean(meta.error)}
            helperText={helperText}
        />
    );
};

export default FormikTextField;
