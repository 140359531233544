import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { OnboardingZipCodeGroup } from '../../../../Models/OfficeOnboarding/OnboardingZipCodeGroup';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { deleteZipCodeGroup, setOpenSelectedGroup, setSelectedGroup, setShowConfirmDelete } from '../../../../store/adminOnboarding.slice';
import SelectedGroupModal from './SelectedGroupModal';
import ConfirmDelete from '../../../Shared/ConfirmDelete';

export const ZipCodeGroups = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose, zipCodes, MenuProps } = props;

    const { selectedGroup, showConfirmDelete, zipCodeGroups } = useSelector((state: RootState) => state.adminOnboardingSlice);

    const columns: GridColDef[] = [
        {
            field: 'GroupName',
            headerName: 'Group Name',
            minWidth: 200,
        },
        {
            field: 'SelectedZipCodes',
            headerName: 'Selected Zip Codes',
            minWidth: 200,
            flex: 2,
            renderCell: (params) => {
                return (
                    <Grid container spacing={1}>
                        {params.row.SelectedZipCodes?.map((zipCode) => (
                            <Grid item key={zipCode}>
                                <Chip label={zipCode} />
                            </Grid>
                        ))}
                    </Grid>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Stack direction={'row'} spacing={2} sx={{ cursor: 'pointer' }}>
                        <Tooltip title={`Edit ${params.row.GroupName}`} placement="bottom">
                            <EditIcon color="primary" onClick={() => editZipCodeGroupHandler(params.row.Id)} />
                        </Tooltip>
                        <Tooltip title={`Delete ${params.row.GroupName}`} placement="top">
                            <DeleteIcon color="error" onClick={() => confirmDeleteHandler(params.row)} />
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    const confirmDeleteHandler = (group) => {
        dispatch(setSelectedGroup(group));
        dispatch(setShowConfirmDelete(true));
    };

    const closeConfirmDeleteHandler = () => {
        dispatch(setShowConfirmDelete(false));
    };

    const deleteZipCodeGroupHandler = () => {
        dispatch(deleteZipCodeGroup(selectedGroup.Id));
    };

    const addNewZipCodeGroupHandler = () => {
        dispatch(setOpenSelectedGroup(true));
        dispatch(setSelectedGroup(new OnboardingZipCodeGroup()));
    };

    const editZipCodeGroupHandler = (groupId: string) => {
        const group = zipCodeGroups.find((group) => group.Id === groupId);
        if (group) {
            dispatch(setOpenSelectedGroup(true));
            dispatch(setSelectedGroup(group));
        }
    };

    return (
        <>
            <ConfirmDelete
                open={showConfirmDelete}
                onClose={closeConfirmDeleteHandler}
                name={selectedGroup.GroupName}
                delete={deleteZipCodeGroupHandler}
                cancel={closeConfirmDeleteHandler}
            />
            <SelectedGroupModal MenuProps={MenuProps} zipCodes={zipCodes} />
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth maxWidth="lg">
                <DialogTitle id="alert-dialog-title">Zip Code Groups</DialogTitle>
                <DialogContent>
                    <Grid container justifyContent={'flex-end'} spacing={2}>
                        <Grid item xs={3} textAlign={'right'}>
                            <Button variant="contained" onClick={addNewZipCodeGroupHandler}>
                                Add Group
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    rows={zipCodeGroups}
                                    columns={columns}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    autoHeight
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className="button-120" variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
