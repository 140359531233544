import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { monthNames } from '../../../utils/constants';
import { localDateFormat } from '../../../utils/timeFormat';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchMemberBirthdayReport,
    exportMemberBirthdayReport,
    setBirthdaySearchStatus,
    setBirthdaySearchMonth,
} from '../../../store/memberBirthdayReport.slice';
import Loading from '../../Shared/Loading';
import phoneFormat from '../../../utils/phoneFormat';
import { isDefaultDate } from '../../../utils/common';

const MemberBirthdayReport = () => {
    const dispatch = useDispatch();
    const { birthdayRowData, birthdaySearchStatus, birthdaySearchMonth } = useSelector((state: RootState) => state.memberBirthdayReport);
    const { downLoading } = useSelector((state: RootState) => state.shared);

    useEffect(() => {
        dispatch(fetchMemberBirthdayReport(birthdaySearchStatus, birthdaySearchMonth));
    }, [dispatch, birthdaySearchMonth, birthdaySearchStatus]);

    const columns: GridColDef[] = [
        {
            field: 'NextBirthdayOn',
            headerName: 'Next Birthday On',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.NextBirthdayOn) ? '-' : localDateFormat(new Date(params.row.NextBirthdayOn))}`,
        },
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.LastName}`,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.FirstName}`,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 110,
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 180,
            flex: 1.75,
        },
        {
            field: 'PrimaryPhone',
            headerName: 'Primary Phone',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.PrimaryPhone === null || phoneFormat(params.row.PrimaryPhone) === null ? '' : phoneFormat(params.row.PrimaryPhone)}`,
        },
        {
            field: 'SecondaryPhone',
            headerName: 'SecondaryPhone',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.SecondaryPhone === null || phoneFormat(params.row.SecondaryPhone) === null ? '' : phoneFormat(params.row.SecondaryPhone)}`,
        },
    ];

    const [pageSize, setPageSize] = useState(15);
    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 72 : 74) + ''));
    };
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container>
            <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Member Birthday Report
                </Typography>
            </Box>
            {<Loading message={'Loading...'} />}
            <Grid item xs={12}>
                <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                    <FormControl className="form-control-200">
                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={birthdaySearchMonth}
                            value={birthdaySearchMonth}
                            label="Month"
                            onChange={(e) => {
                                dispatch(setBirthdaySearchMonth(e.target.value));
                            }}
                        >
                            {monthNames.map((month, index) => (
                                <MenuItem key={index} value={index + 1}>
                                    {month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className="form-control-200">
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={birthdaySearchStatus}
                            value={birthdaySearchStatus}
                            label="Status"
                            onChange={(e) => {
                                dispatch(setBirthdaySearchStatus(e.target.value));
                            }}
                        >
                            <MenuItem value={'active'}>Active</MenuItem>
                            <MenuItem value={'activeAndUndetermined'}>Active &amp; Undetermined</MenuItem>
                            <MenuItem value={'fuhUnd'}>FUH-Und</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => dispatch(exportMemberBirthdayReport(birthdaySearchStatus, birthdaySearchMonth))}
                        variant="contained"
                        color="success"
                    >
                        {downLoading ? 'Downloading..' : 'Export'}
                    </Button>
                </Stack>
            </Grid>
            <Grid item style={{ height: '84vh', width: '100%' }}>
                <DataGrid
                    rows={birthdayRowData}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.Id}
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MemberBirthdayReport;
