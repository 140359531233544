import { Eventcalendar, MbscCalendarEvent } from '@mobiscroll/react';
import { Backdrop, Button, CircularProgress, Stack } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import { setShowMessage } from '../../../store/shared.slice';
import { apiString } from '../../../utils/constants';
import { appointmentStatusMap } from '../../../utils/mappings';
import { formatDateMMDDYYYY } from '../../../utils/timeFormat';
import MessageDisplay from '../../Shared/MessageDisplay';
import CalendarStatusLegend from '../Legends/CalendarStatusLegend';
import CalendarEventEditModal from '../Modals/CalendarEventEditModal';
import { confirmRecurringInstanceEvent, getMonthBounds, mapEventData } from '../Services/CommonCalendarServices';
import { CalendarEventData } from '../types/CalendarEventData';
import AssessmentsAgendaCard from './AssessmentsAgendaCard';

const AssessmentsCalendar: React.FC<{ memberId?: string; onSelectAppointmentDate: (params: any) => void; canCreate?: boolean; canEdit?: boolean }> = (
    props
) => {
    const dispatch = useDispatch();
    const { onSelectAppointmentDate, memberId } = props;
    const [myEvents, setMyEvents] = useState<MbscCalendarEvent[]>([]);
    const [mySelectedDate, setSelectedDate] = useState(new Date());
    const [isBackdropOpen, setIsBackdropOpen] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventData | null>(null);
    const [isEventEditorOpen, setIsEventEditorOpen] = useState<boolean>(false);

    const handleSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const getMemberSchedule = useCallback(() => {
        const { firstDate, lastDate } = getMonthBounds(mySelectedDate);
        const startDateMinusOneYear = new Date(firstDate);
        startDateMinusOneYear.setFullYear(startDateMinusOneYear.getFullYear() - 1);
        const lastDatePlusOneMonth = new Date(lastDate);
        lastDatePlusOneMonth.setMonth(lastDatePlusOneMonth.getMonth() + 1);
        setIsBackdropOpen(true);
        axios
            .get(`${apiString}/scheduling/GetMemberChartAppointments`, {
                params: { memberId, start: formatDateMMDDYYYY(startDateMinusOneYear), end: formatDateMMDDYYYY(lastDatePlusOneMonth) },
            })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data.map(mapEventData);
                    setMyEvents(data);
                } else {
                    console.error('Error loading appointment data');
                    setMyEvents([]);
                }
                setIsBackdropOpen(false);
            });
    }, [memberId, mySelectedDate]);

    const handleUpdateEventSuccess = (event: Appointment | CalendarEventData) => {
        setMyEvents((currentEvents) => {
            return currentEvents.map((item) => {
                if (item.Id === event.Id) {
                    // TODO: clean this up so we don't have to map data every time we set the events
                    return mapEventData(event);
                } else {
                    return item;
                }
            });
        });
    };

    const handleCreateEventSuccess = (event: Appointment, rescheduledEvent?: CalendarEventData) => {
        setMyEvents((currentEvents) => {
            let newEvents = [...currentEvents, mapEventData(event)];
            if (rescheduledEvent !== undefined) {
                newEvents = newEvents.map((item) => {
                    if (item.Id === rescheduledEvent.id) {
                        rescheduledEvent.RescheduledAppointmentId = event.Id;
                        if (
                            rescheduledEvent.status !== appointmentStatusMap.Cancelled.value ||
                            rescheduledEvent.status !== appointmentStatusMap.NoCallNoShow.value
                        ) {
                            rescheduledEvent.status = appointmentStatusMap.Cancelled.value;
                        }
                        // TODO: clean this up so we don't have to map data every time we set the events
                        return mapEventData(rescheduledEvent);
                    } else {
                        return item;
                    }
                });
            }
            return newEvents;
        });
    };

    const confirmAndSelectRecurrenceInstance = useCallback(
        async (recurrenceInstance: CalendarEventData) => {
            try {
                console.log('confirmAndSelectRecurrenceInstance', recurrenceInstance);
                setIsBackdropOpen(true);
                const response = await confirmRecurringInstanceEvent(recurrenceInstance.original as Appointment, recurrenceInstance.startDate);
                if (response.status === 200) {
                    const { CreatedInstanceAppointment, OriginalRecurrenceAppointment } = response.data;
                    const mappedNewEvent = mapEventData(CreatedInstanceAppointment);
                    const mappedOriginalEvent = mapEventData(OriginalRecurrenceAppointment);
                    let newEvents = [
                        mappedNewEvent,
                        ...myEvents.map((item) => {
                            if (item.Id === mappedOriginalEvent.Id) {
                                return mappedOriginalEvent;
                            } else {
                                return item;
                            }
                        }),
                    ];
                    setMyEvents(newEvents);
                    onSelectAppointmentDate({ date: CreatedInstanceAppointment.startDate, appointmentId: CreatedInstanceAppointment.Id });
                }
                setIsBackdropOpen(false);
            } catch (error) {
                dispatch(setShowMessage(true, 'Error confirming appointment. Please try again later.', 'error'));
                console.error(error);
                setIsBackdropOpen(false);
            }
        },
        [dispatch, myEvents, onSelectAppointmentDate]
    );

    const renderEventContent = useCallback(
        (eventData: CalendarEventData) => {
            if (eventData.id) {
                return (
                    <AssessmentsAgendaCard
                        event={eventData}
                        onSelectAppointmentDate={onSelectAppointmentDate}
                        confirmAndSelectRecurrenceInstance={confirmAndSelectRecurrenceInstance}
                    />
                );
            } else return null;
        },
        [confirmAndSelectRecurrenceInstance, onSelectAppointmentDate]
    );

    const handleCloseEventEditor = () => {
        setIsEventEditorOpen(false);
    };

    const handleEventCreate = (event: MbscCalendarEvent) => {
        console.log('eventcreate');
        let eventInfo = event.event as CalendarEventData;
        eventInfo = {
            ...eventInfo,
            start: new Date(mySelectedDate).toISOString(),
            end: new Date(mySelectedDate).toISOString(),
        };
        setSelectedEvent(eventInfo);
        setIsEventEditorOpen(true);

        // We will add the event manually, returning false makes mobiscroll not add a placeholder.
        return false;
    };

    return (
        <Stack spacing={1}>
            <MessageDisplay />
            <CalendarStatusLegend />
            <Backdrop open={isBackdropOpen} sx={{ zIndex: 1000 }} onClick={() => console.debug('backdrop clicked')}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Button onClick={handleEventCreate} variant="contained">
                Create New Appointment
            </Button>
            {selectedEvent !== undefined && selectedEvent !== null ? (
                <CalendarEventEditModal
                    open={isEventEditorOpen}
                    onSuccess={() => {
                        setIsEventEditorOpen(false);
                    }}
                    onUpdateEventSuccess={handleUpdateEventSuccess}
                    eventList={myEvents as any}
                    onCreateEventSuccess={handleCreateEventSuccess}
                    onRescheduleEvent={handleUpdateEventSuccess}
                    setLoadingOverlay={(isLoading: boolean) => setIsBackdropOpen(isLoading)}
                    onClose={handleCloseEventEditor}
                    eventData={selectedEvent}
                    memberId={memberId}
                />
            ) : null}
            <Eventcalendar
                data={myEvents}
                clickToCreate={'double'}
                renderEvent={renderEventContent}
                theme="material"
                height={1180}
                themeVariant="light"
                view={{ calendar: { type: 'week' }, agenda: { type: 'day' } }}
                selectedDate={mySelectedDate}
                onPageLoading={getMemberSchedule}
                onSelectedDateChange={handleSelectedDateChange}
                onEventCreate={handleEventCreate}
            />
        </Stack>
    );
};

export default AssessmentsCalendar;
