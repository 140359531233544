import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import ReactSignatureCanvas from 'react-signature-canvas';
import { RootState } from '../../../reducers';

interface InitialsInputModalProps {
    isOpen: boolean;
    onClose: () => void;
    section: string;
    initials: string;
    setInitials: (initials: string) => void;
    isSpanish?: boolean;
}

interface InitialsDisplayComponentProps {
    sectionInitials: string;
    setSectionInitials: (initials: string) => void;
    isSpanish?: boolean;
}

export default function Initials(props: any) {
    const { isOpen, setIsOpen, initials, setInitials } = props;
    const sigCanvasRef = useRef<any>(null);
    const { digitalSignatureForm } = useSelector((state: RootState) => state.consentSlice);

    const handleAcceptInitialsInput = () => {
        if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
            const signatureDataUrl = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
            setInitials(signatureDataUrl);
        }
        handleCloseInitialsInput();
    };

    const handleCloseInitialsInput = () => {
        setIsOpen(false);
        sigCanvasRef.current?.clear();
    };

    const InitialsInput = (props: InitialsInputModalProps) => {
        return (
            <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
                <DialogTitle id="alert-dialog-title">{props.isSpanish ? 'Iniciales' : 'Initial'}</DialogTitle>
                <DialogContent sx={{ border: 1, m: 2, p: 0 }}>
                    <ReactSignatureCanvas
                        ref={sigCanvasRef}
                        penColor="blue"
                        canvasProps={{
                            width: 300,
                            height: 150,
                            className: 'sigCanvas',
                        }}
                    />
                </DialogContent>

                <DialogActions>
                    <Stack spacing={2} width={'100%'}>
                        <Button variant="outlined" onClick={handleCloseInitialsInput}>
                            {props.isSpanish ? 'Cancelar' : 'Cancel'}
                        </Button>
                        <Button variant="contained" onClick={handleAcceptInitialsInput}>
                            {props.isSpanish ? 'Aceptar' : 'Accept'}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        );
    };

    const InitialsDisplay = (props: InitialsDisplayComponentProps) => {
        return (
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={9}>
                    <Stack direction={'row'} spacing={2} alignItems="flex-end" sx={{ py: 2 }}>
                        <Typography variant="h6">{props.isSpanish ? 'Iniciales' : 'Initials'}</Typography>
                        <Box sx={{ position: 'relative', flexGrow: 1 }}>
                            <TextField
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    style: {
                                        borderBottom: '1px solid black',
                                        width: '100%',
                                    },
                                }}
                                sx={{ width: '100%' }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -80%)',
                                }}
                            >
                                {props.sectionInitials.length > 0 ? (
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 60,
                                            width: 100,
                                            maxHeight: { xs: 60 },
                                            maxWidth: { xs: 100 },
                                            mb: '-10px',
                                        }}
                                        alt="Member initials."
                                        src={props.sectionInitials}
                                    />
                                ) : null}
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
                {props.sectionInitials.length > 0 ? (
                    <Grid item xs={3}>
                        <Button variant="outlined" sx={{ minWidth: '120px' }} fullWidth onClick={() => props.setSectionInitials('')}>
                            {props.isSpanish ? 'Limpiar' : 'Clear'}
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        );
    };

    return (
        <>
            <InitialsInput
                isSpanish={props.isSpanish}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                section={'two'}
                initials={initials}
                setInitials={setInitials}
            />
            {initials.length > 0 ? (
                <InitialsDisplay isSpanish={props.isSpanish} sectionInitials={initials} setSectionInitials={setInitials} />
            ) : (
                <Stack sx={{ my: 5 }} spacing={1}>
                    <Button variant="contained" onClick={() => setIsOpen(true)} disabled={digitalSignatureForm.VerbalConsent}>
                        {props.isSpanish ? 'Iniciales' : 'Initial'}
                    </Button>
                    {!digitalSignatureForm.VerbalConsent ? (
                        <Typography variant="body1" color={'error'} textAlign={'center'}>
                            * {props.isSpanish ? 'Requerido' : 'Required'}
                        </Typography>
                    ) : null}
                </Stack>
            )}
        </>
    );
}
