import BaseEntity from '../BaseEntity.model';

export default interface IHealthPlanType extends BaseEntity {
    Type: string;
}

export default class HealthPlanType implements IHealthPlanType {
    Id: string;
    Type: string;

    constructor() {
        this.Id = '';
        this.Type = '';
    }
}
