import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../../hooks/populationContext';
import { useAnalyticContext } from '../../../../hooks/analyticContext';
import { configureStackedBarChartForEdVisit } from '../../../ChartConfiguration/ChartConfiguration';
import ClaimsPerformance from '../../../../Models/Claims/ClaimsPreview.model';
import FilterOptions from '../FilterOptions';
import { getEdVisitGraph } from '../AnalyticService';

const EDVisit = () => {
    const history = useNavigate();
    const { analyticPopulations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [graphSummary, setGraphSummary] = useState<ClaimsPerformance>();
    const [chartOptions, setChartOptions] = useState({});

    const {
        edPopulation,
        setEdPopulation,
        edMemberStatus,
        setEdMemberStatus,
        edTimePeriod,
        setEdTimePeriod,
        edPart,
        setEdPart,
        edPrimaryProvider,
        setEdPrimaryProvider,
        edSecondaryProvider,
        setEdSecondaryProvider,
        showEdInActivePrimaryTeams,
        setShowEdInActivePrimaryTeams,
        showEdInActiveSecondaryTeams,
        setShowEdInActiveSecondaryTeams,
    } = useAnalyticContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartForEdVisit(
                    'ED Visit',
                    'Baseline year vs Performance year',
                    'Population',
                    'Visit',
                    [{ name: '', data: [0] }],
                    [0],
                    'EdVist',
                    history
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                const data = await getEdVisitGraph(
                    edTimePeriod,
                    edPopulation,
                    edPrimaryProvider,
                    edPart,
                    edMemberStatus,
                    edSecondaryProvider,
                    showEdInActivePrimaryTeams,
                    showEdInActiveSecondaryTeams
                );
                setGraphSummary(data);
                setIsPrepared(true);
            };
            setChartOptions(
                configureStackedBarChartForEdVisit(
                    'ED Visit',
                    'Baseline year vs Performance year',
                    'Population',
                    'Visit',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'EdVist',
                    history
                )
            );
            setIsGraphShow(false);
            changeFilter();
        }
    }, [
        edTimePeriod,
        edPopulation,
        edPrimaryProvider,
        edPart,
        edMemberStatus,
        showEdInActivePrimaryTeams,
        showEdInActiveSecondaryTeams,
        edSecondaryProvider,
        isPopulationFetched,
        history,
    ]);

    useEffect(() => {
        if (graphSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            if (graphSummary.Baseline != null) {
                let namePrefix = 'BL Yr';
                let yValues = [];
                let d = {
                    y: graphSummary.Baseline,
                    name: namePrefix,
                    percentage: graphSummary.Baseline,
                };
                yValues.push(d);
                yAxisValues.push({ name: namePrefix, data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });
            }

            if (graphSummary.PerformanceOne != null) {
                let namePrefix = 'Perf Yr';
                let yValues = [];
                let dataInfo = graphSummary.PerformanceOne;
                let blValues = graphSummary.Baseline;
                let total = blValues + dataInfo;
                let blPercentage = (blValues / total) * 100;
                let pfPercentage = (dataInfo / total) * 100;
                let percentageDiffer = pfPercentage - blPercentage;
                let d = {
                    y: dataInfo,
                    name: namePrefix,
                    percentage: dataInfo + ' (' + Math.round(percentageDiffer) + '%)',
                };
                yValues.push(d);
                yAxisValues.push({ name: namePrefix, data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });
            }

            if (edPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = analyticPopulations.find((p) => p.value === edPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartForEdVisit('ED Visit', '', '', 'Visit', yAxisValues, xAxisValues, 'EdVist', history));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
    }, [graphSummary, edPopulation, analyticPopulations, isPrepared, history]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/edvisitdetail`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            Emergency Department Visits
                        </Typography>
                    </Grid>
                    <FilterOptions
                        population={edPopulation}
                        setPopulation={setEdPopulation}
                        memberStatus={edMemberStatus}
                        setMemberStatus={setEdMemberStatus}
                        timePeriod={edTimePeriod}
                        setTimePeriod={setEdTimePeriod}
                        part={edPart}
                        setPart={setEdPart}
                        primaryProvider={edPrimaryProvider}
                        setPrimaryProvider={setEdPrimaryProvider}
                        secondaryProvider={edSecondaryProvider}
                        setSecondaryProvider={setEdSecondaryProvider}
                        showInActivePrimaryTeams={showEdInActivePrimaryTeams}
                        setShowInActivePrimaryTeams={setShowEdInActivePrimaryTeams}
                        showInActiveSecondaryTeams={showEdInActiveSecondaryTeams}
                        setShowInActiveSecondaryTeams={setShowEdInActiveSecondaryTeams}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default EDVisit;
