import axios from 'axios';
import { SchedulingCallItem } from '../../Models/Scheduling/SchedulingCallitem.model';
import { apiString } from '../../utils/constants';
import { checkCanGetNewList, fetchMemberDocumentation, fetchMemberInfo, fetchProviders } from './OfficeSchedulingService';

export const getMemberDataAndApptInfo = async (callItem: SchedulingCallItem, setMemberApptInfo) => {
    const {
        setIsLoading,
        setCurrentMember,
        setCurrentMemberDocs,
        setCurrentApptId,
        setCurrentApptTime,
        setCurrentApptAddress,
        setCurrentApptProviders,
        allProviders,
        setCurrentApptProviderRole,
    } = setMemberApptInfo;
    setIsLoading(true);
    const { ProviderNames } = callItem;
    const docResponse = await fetchMemberDocumentation(callItem.MemberObjectId);
    const response = await fetchMemberInfo(callItem.MemberObjectId);
    if (docResponse.status === 200 && response.status === 200) {
        const docData = docResponse.data;
        const data = response.data;
        setCurrentMember(data);
        setCurrentMemberDocs(docData);
        setCurrentApptTime(new Date(callItem.AppointmentDateTime).toLocaleString('en-US'));
        setCurrentApptAddress(callItem.Address);
        setCurrentApptProviders(ProviderNames);

        let providerSearchName = ProviderNames[0];
        if (providerSearchName && providerSearchName.includes(',')) {
            providerSearchName = ProviderNames[0].split(',')[0];
        }

        const apptProviderInfo = undefined;
        if (allProviders?.length > 0) {
            allProviders.find((p: { name: string | any[] }) => p.name.includes(providerSearchName));
        }

        if (apptProviderInfo !== undefined) {
            setCurrentApptProviderRole(apptProviderInfo.name.split('(')[1].replace(/\W/g, ''));
        } else {
            setCurrentApptProviderRole('');
        }

        setCurrentApptId(callItem.ApptId);
        setIsLoading(false);
    }
};

export const ALL_DONE_MESSAGE = 'You have finished all of your available calls.';

export const checkListStatus = async (providerId, mode) => {
    const response = await checkCanGetNewList(providerId, mode);
    return response;
};

export const fetchProvidersUtil = async () => {
    const response = await fetchProviders();
    return response.data;
};

export const fetchNextListTimeUtil = async (type: string) => {
    const response = await axios.get(`${apiString}/scheduling/getnextlisttime?type=${type}`);
    try {
        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        return null;
    }
};
