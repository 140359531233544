import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const memberEligibilityReport = createSlice({
    name: 'memberEligibilityReport',
    initialState: {
        memberEligibilityReport: [],
        memberDetailFilter: 'all',
    },
    reducers: {
        _setMemberEligibilityReport(state, action) {
            state.memberEligibilityReport = action.payload;
        },
        _setMemberDetailFilter(state, action) {
            state.memberDetailFilter = action.payload;
        },
    },
});

const { _setMemberEligibilityReport, _setMemberDetailFilter } = memberEligibilityReport.actions;

export const fetchMemberEligibilityReport =
    (start: Date, end: Date, planName: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/MemberEligibilityReport/getmembers?start=${dateFormatter(start)}&end=${dateFormatter(end)}&planName=${planName}`
        );
        dispatch(_setMemberEligibilityReport(response.data));
        dispatch(setIsLoading(false));
    };

export const setMemberEligiblityReport =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberEligibilityReport(params));
    };

export const setMemberDetailFilter =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberDetailFilter(params));
    };

export const exportMemberEligibilityReport =
    (start: Date, end: Date, planName: string): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/MemberEligibilityReport/exportmembers?start=${dateFormatter(start)}&end=${dateFormatter(end)}&planName=${planName}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default memberEligibilityReport.reducer;
