import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { localDateFormat } from '../../utils/timeFormat';
import {
    fetchMembersPagination,
    fetchMemberStatuses,
    fetchRiskLevels,
    setMemberSearch,
    setPageNumber,
    setPageSize,
    setSelectedMemberStatus,
    setSelectedRiskLevel,
    setSelectedTeam,
    setSort,
    setIncludeArchivedMembers,
} from '../../store/members.slice';
import { fetchTeamsName } from '../../store/teams.slice';
import { Stack } from '@mui/material';
import Loading from '../Shared/Loading';
import { parentUrl } from '../../utils/constants';
import { Search } from '@mui/icons-material';
import { MemberStatuses } from '../../utils/mappings';
import { isDefaultDate } from '../../utils/common';

const MembersListPage = () => {
    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'RiskScore',
            headerName: 'Risk',
            width: 80,
            sortable: false,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
            sortable: false,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 200,
            flex: 1,
            sortable: false,
        },
        {
            field: 'LastSeen',
            headerName: 'Last Seen',
            width: 120,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.LastSeen) ? '-' : localDateFormat(new Date(params.row.LastSeen))}`,
            sortable: false,
        },
        {
            field: 'Address1',
            headerName: 'Address',
            minWidth: 200,
            flex: 1,
            sortable: false,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 180,
            sortable: false,
        },
        {
            field: 'State',
            headerName: 'State',
            width: 80,
            sortable: false,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            width: 80,
            sortable: false,
        },
        {
            field: 'Status',
            headerName: 'Member Status',
            width: 200,
            sortable: false,
        },
    ];

    // const [localMembers, setLocalMembers] = useState([]);
    const dispatch = useDispatch();
    const {
        members,
        memberSearch,
        memberStatuses,
        pageNumber,
        pageSize,
        pageTotal,
        riskLevels,
        selectedRiskLevel,
        selectedMemberStatus,
        selectedTeam,
        includeArchivedMembers,
    } = useSelector((state: RootState) => state.members);
    const { teams } = useSelector((state: RootState) => state.teams);

    const changeSearch = (event: any) => {
        if (!event.key || event.key === 'Enter') {
            dispatch(fetchMembersPagination());
        }
    };

    const prepareStatuses = () => {
        return [
            {
                Name: 'Open',
                List: memberStatuses
                    .filter((s) => MemberStatuses.some((os) => String(os.Id) === String(s.value) && os.IsOpen))
                    .sort((a, b) => a.label.localeCompare(b.label)),
            },
            {
                Name: 'Closed',
                List: memberStatuses
                    .filter((s) => MemberStatuses.some((os) => String(os.Id) === String(s.value) && !os.IsOpen))
                    .sort((a, b) => a.label.localeCompare(b.label)),
            },
        ];
    };

    useEffect(() => {
        dispatch(fetchRiskLevels());
        dispatch(fetchMemberStatuses());
        dispatch(fetchTeamsName());
        dispatch(fetchMembersPagination());
        window.addEventListener('resize', () => {
            dispatch(setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 72 : 74) + '')));
        });
    }, [dispatch]);

    return (
        <Box>
            {<Loading message={'Loading...'} />}
            <Grid container spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={12} style={{ paddingBottom: '0px' }} justifyItems="space-between">
                    <FormControl style={{ minWidth: '300px', margin: '0px' }}>
                        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            value={memberSearch}
                            onChange={(e) => {
                                dispatch(setMemberSearch(e.target.value as string));
                            }}
                            onKeyUp={(event: any) => {
                                changeSearch(event);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Search" onClick={changeSearch} edge="end">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                    <FormControlLabel
                        style={{ paddingLeft: '20px', paddingTop: '10px' }}
                        label={
                            <Box component="div" fontSize={14}>
                                Include Archived Members
                            </Box>
                        }
                        control={
                            <Checkbox
                                checked={includeArchivedMembers}
                                onChange={() => {
                                    dispatch(setIncludeArchivedMembers(!includeArchivedMembers));
                                    dispatch(fetchMembersPagination());
                                }}
                            />
                        }
                    />
                    <Stack direction="row" className="float-right" spacing={2}>
                        <FormControl className="form-control-200">
                            <InputLabel id="demo-simple-select-label">Risk Level</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Risk Level"
                                value={selectedRiskLevel}
                                onChange={(e) => {
                                    dispatch(setSelectedRiskLevel(e.target.value as []));
                                    dispatch(fetchMembersPagination());
                                }}
                            >
                                <MenuItem value={'-1'}>Show All</MenuItem>
                                {riskLevels.map((risk) => {
                                    return (
                                        <MenuItem key={risk.value} value={risk.value}>
                                            {risk.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control-200">
                            <InputLabel id="demo-simple-select-label">Teams</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Teams"
                                value={selectedTeam}
                                onChange={(e) => {
                                    dispatch(setSelectedTeam(e.target.value as string));
                                    dispatch(fetchMembersPagination());
                                }}
                            >
                                <MenuItem value={'-1'}>Show All</MenuItem>
                                {teams.map((team, index) => {
                                    return (
                                        <MenuItem key={team.Id} value={team.Name}>
                                            {team.Name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: '120px' }}>
                            <InputLabel id="demo-simple-select-label">Member Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Member Status"
                                value={selectedMemberStatus}
                                multiple
                                onChange={(e) => {
                                    dispatch(setSelectedMemberStatus(e.target.value as []));
                                }}
                                onClose={() => {
                                    dispatch(fetchMembersPagination());
                                }}
                            >
                                <MenuItem value={'-1'}>Show All</MenuItem>
                                {prepareStatuses().map((statusByType, index) => [
                                    <ListSubheader key={index}>{statusByType.Name}</ListSubheader>,
                                    statusByType.List.map((status) => {
                                        return (
                                            <MenuItem key={status.value} value={status.value} sx={{ paddingLeft: 4 }}>
                                                {status.label}
                                            </MenuItem>
                                        );
                                    }),
                                ])}
                            </Select>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {/* <div style={{ height: '82vh', width: '100%' }}> */}
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            onSortModelChange={(model) => {
                                dispatch(setSort(model[0]));
                                dispatch(fetchMembersPagination());
                            }}
                            onPageChange={(newPage) => {
                                dispatch(setPageNumber(newPage));
                                dispatch(fetchMembersPagination());
                            }}
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.id}/member-profile`, '_blank');
                            }}
                            getRowId={(row) => row.Id}
                            rows={members}
                            autoHeight
                            rowCount={pageTotal}
                            columns={columns}
                            page={pageNumber}
                            pageSize={pageSize}
                            paginationMode="server"
                            getRowClassName={(params) =>
                                MemberStatuses.some((s) => s.Name === params.row.Status && !s.IsOpen)
                                    ? 'closed-status-color'
                                    : `color${params.row.IsInTransition}`
                            }
                            rowsPerPageOptions={[5]}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MembersListPage;
