import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, FormControl, FormControlLabel, Radio, ImageListItem, ImageList, Stack, TextField, Typography, Checkbox } from '@mui/material';

import { RootState } from '../../reducers';
import classes from './StylesMemberDetail';
import { prepareProgressNote, saveTransitionalCareChecklist, setAdmissionChecklistTextModalIsOpen } from '../../store/transitionalCare.slice';
import { appointmentScheduleList, dischargeOrderReviewList, medicalEquipmentList, medicationReviewList } from '../../utils/constants';
import bad from '../../assets/images/1F61F.svg';
import verygood from '../../assets/images/1F601.svg';
import ok from '../../assets/images/1F610.svg';
import good from '../../assets/images/1F642.svg';
import verybad from '../../assets/images/1F912.svg';
import { copyObject } from '../../utils/common';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useState } from 'react';
import { TransitionalCareCheckList } from '../../Models/TransitionalCare/TransitionalCareCheckList.model';

const emojisList = [
    {
        emoji: verybad,
        title: 'Very Bad',
        label: 'Much worse',
    },
    {
        emoji: bad,
        title: 'Bad',
        label: 'Worse',
    },
    {
        emoji: ok,
        title: 'Ok',
        label: 'No change',
    },
    {
        emoji: good,
        title: 'Good',
        label: 'Better',
    },
    {
        emoji: verygood,
        title: 'Very Good',
        label: 'Much better',
    },
];

const PostDischargeChecklist = (props) => {
    const { isCompleted, isAssessment } = props;
    const dispatch = useDispatch();
    const { transitionStep } = useSelector((state: RootState) => state.transitionalCare);

    const [nextSteps, setNextSteps] = useState({
        ReasonForNotToSeeNPOrBH: transitionStep.ReasonForNotToSeeNPOrBH || '',
        NotApplicable: transitionStep.NotApplicable || false,
        DateToSeeNP: transitionStep.DateToSeeNP || null,
        DateToSeeBH: transitionStep.DateToSeeBH || null,
    });
    const [postDischargeCheckList, setPostDischargeCheckList] = useState(transitionStep.TransitionalCareCheckList || ({} as TransitionalCareCheckList));

    const handleClose = () => {
        dispatch(setAdmissionChecklistTextModalIsOpen(false));
    };

    const handleEdit = () => {
        dispatch(setAdmissionChecklistTextModalIsOpen(true));
    };

    const handleOnChange = (val: any, propName: string) => {
        if (isCompleted) return;
        setPostDischargeCheckList({ ...postDischargeCheckList, [propName]: val });
    };

    const handleOnChangeNotApplicable = (val: any, propName: string) => {
        if (isCompleted) return;
        if (val) {
            setNextSteps({ ...nextSteps, [propName]: val, DateToSeeNP: null, DateToSeeBH: null, ReasonForNotToSeeNPOrBH: '' });
        } else {
            setNextSteps({ ...nextSteps, [propName]: val });
        }
    };

    const handleOnChangeDischarge = (val: any, propName: string, saveLocal: boolean = false) => {
        if (isCompleted) return;
        setNextSteps({ ...nextSteps, [propName]: val });
    };

    const handleOnClickNext = (dischargeStep) => {
        var transitionStepChecklist = transitionStep.TransitionalCareCheckList || ({} as TransitionalCareCheckList);

        if (Boolean(transitionStepChecklist) && Boolean(postDischargeCheckList)) {
            let activities = [];
            if (postDischargeCheckList.MedicationReviewed && transitionStepChecklist?.MedicationReviewed !== postDischargeCheckList.MedicationReviewed) {
                let medicationReviewed = 'Medication Reviewed : ' + postDischargeCheckList.MedicationReviewed;
                if (isAssessment) {
                    dispatch(prepareProgressNote(medicationReviewed));
                }
                activities.push({ Note: medicationReviewed });
            }
            if (
                postDischargeCheckList.DischargeOrdersReviewed &&
                transitionStepChecklist?.DischargeOrdersReviewed !== postDischargeCheckList.DischargeOrdersReviewed
            ) {
                let dischargeOrdersReviewed = 'Discharge Orders Reviewed : ' + postDischargeCheckList.DischargeOrdersReviewed;
                if (isAssessment) {
                    dispatch(prepareProgressNote(dischargeOrdersReviewed));
                }
                activities.push({ Note: dischargeOrdersReviewed });
            }
            if (
                postDischargeCheckList.AppointmentPostDCScheduled &&
                transitionStepChecklist?.AppointmentPostDCScheduled !== postDischargeCheckList.AppointmentPostDCScheduled
            ) {
                let appointmentPostDCScheduled = 'Community Appointment Post DC Scheduled : ' + postDischargeCheckList.AppointmentPostDCScheduled;
                if (isAssessment) {
                    dispatch(prepareProgressNote(appointmentPostDCScheduled));
                }
                activities.push({ Note: appointmentPostDCScheduled });
            }
            if (
                postDischargeCheckList.MedicalEquipmentOrHHOrders &&
                transitionStepChecklist?.MedicalEquipmentOrHHOrders !== postDischargeCheckList.MedicalEquipmentOrHHOrders
            ) {
                let medicalEquipmentOrHHOrders = 'Medical Equipment Or HH Orders : ' + postDischargeCheckList.MedicalEquipmentOrHHOrders;
                if (isAssessment) {
                    dispatch(prepareProgressNote(medicalEquipmentOrHHOrders));
                }
                activities.push({ Note: medicalEquipmentOrHHOrders });
            }
            if (postDischargeCheckList.Notes) {
                let notes = 'Post Discharge Checklist Note : ' + postDischargeCheckList.Notes;
                if (isAssessment) {
                    dispatch(prepareProgressNote(notes));
                }
                activities.push({ Note: notes });
            }
            dispatch(saveTransitionalCareChecklist(dischargeStep, false));
            // dispatch(addTransitionalCareActivityForDischarge(selectedTransitionalCareId, dischargeStep.Id, activities));
        }
    };

    const handleSubmit = () => {
        let data = copyObject(transitionStep);
        data.TransitionalCareCheckList = { ...postDischargeCheckList };
        data.NotApplicable = nextSteps.NotApplicable;
        data.ReasonForNotToSeeNPOrBH = nextSteps.ReasonForNotToSeeNPOrBH;
        data.DateToSeeNP = nextSteps.DateToSeeNP;
        data.DateToSeeBH = nextSteps.DateToSeeBH;
        handleOnClickNext(data);
        // dispatch(saveTransitionalCareChecklist(data, false));
        handleClose();
    };

    return (
        <Grid container columnSpacing={0} rowSpacing={3} p={2}>
            {isCompleted && (
                <Grid item xs={12}>
                    <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                        <Button variant="outlined" sx={{ minWidth: 120 }} color="primary" onClick={handleEdit}>
                            Edit
                        </Button>
                    </Stack>
                </Grid>
            )}
            <Grid item xs={6}>
                <Typography variant="body1" fontWeight={500}>
                    Medication reviewed
                </Typography>

                {medicationReviewList.map((item, index) => (
                    <FormControlLabel
                        key={`review_${item.Name}_${index}`}
                        checked={postDischargeCheckList['MedicationReviewed'] === item.Name}
                        sx={classes.smRadioOption}
                        onChange={() => handleOnChange(item.Name, 'MedicationReviewed')}
                        value={item.Name}
                        control={<Radio size="small" />}
                        label={item.Name}
                    />
                ))}
            </Grid>

            <Grid item xs={6}>
                <Typography variant="body1" fontWeight={500}>
                    Discharge orders reviewed
                </Typography>

                {dischargeOrderReviewList.map((item, index) => (
                    <FormControlLabel
                        key={`review_${item.Name}_${index}`}
                        checked={postDischargeCheckList['DischargeOrdersReviewed'] === item.Name}
                        sx={classes.smRadioOption}
                        onChange={() => handleOnChange(item.Name, 'DischargeOrdersReviewed')}
                        value={item.Name}
                        control={<Radio size="small" />}
                        label={item.Name}
                    />
                ))}
            </Grid>

            <Grid item xs={6}>
                <Typography variant="body1" fontWeight={500}>
                    Community appointments post DC scheduled
                </Typography>

                {appointmentScheduleList.map((item, index) => (
                    <FormControlLabel
                        key={`review_${item.Name}_${index}`}
                        checked={postDischargeCheckList['AppointmentPostDCScheduled'] === item.Name}
                        sx={classes.smRadioOption}
                        onChange={() => handleOnChange(item.Name, 'AppointmentPostDCScheduled')}
                        value={item.Name}
                        control={<Radio size="small" />}
                        label={item.Name}
                    />
                ))}
            </Grid>

            <Grid item xs={6}>
                <Typography variant="body1" fontWeight={500}>
                    Medical equipment / HH orders
                </Typography>

                {medicalEquipmentList.map((item, index) => (
                    <FormControlLabel
                        key={`review_${item.Id}_${index}`}
                        checked={postDischargeCheckList['MedicalEquipmentOrHHOrders'] === item.Name}
                        sx={classes.smRadioOption}
                        onChange={() => handleOnChange(item.Name, 'MedicalEquipmentOrHHOrders')}
                        value={item.Name}
                        control={<Radio size="small" />}
                        label={item.Name}
                    />
                ))}
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" fontWeight={500}>
                    Felling better?
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <ImageList cols={5} rowHeight={90} sx={classes.imageList}>
                    {emojisList.map((item) => (
                        <FormControl key={item.emoji} sx={{ width: '85px' }}>
                            <ImageListItem>
                                <img src={item.emoji} alt={item.title} />
                            </ImageListItem>
                            <FormControlLabel
                                checked={postDischargeCheckList['FeelingBetterScale'] === item.title}
                                sx={[{ textAlign: 'center' }, { ...classes.smRadioOption }]}
                                labelPlacement="bottom"
                                control={<Radio size="small" />}
                                label={item.label}
                                onClick={() => handleOnChange(item.title, 'FeelingBetterScale')}
                            />
                        </FormControl>
                    ))}
                </ImageList>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    value={postDischargeCheckList['Notes'] || ''}
                    placeholder="Enter your description"
                    variant="outlined"
                    label="Notes"
                    disabled={isCompleted}
                    rows={10}
                    onChange={(e) => handleOnChange(e.currentTarget.value, 'Notes')}
                    onBlur={(e) => handleOnChange(e.currentTarget.value, 'Notes')}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" fontWeight={500}>
                    Next Steps
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={2}>
                        <DatePicker
                            value={nextSteps.DateToSeeNP}
                            // inputFormat="MM/dd/yyyy"
                            disabled={isCompleted}
                            minDate={new Date('2017-01-01')}
                            label="Date To See NP"
                            onChange={(date) => {
                                if (moment(date).isValid() || !Boolean(date)) {
                                    handleOnChangeDischarge(date.toISOString(), 'DateToSeeNP');
                                }
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        <DatePicker
                            value={nextSteps.DateToSeeBH}
                            label="Date To See BH"
                            // inputFormat="MM/dd/yyyy"
                            disabled={isCompleted}
                            minDate={new Date('2017-01-01')}
                            onChange={(date) => {
                                if (moment(date).isValid() || !Boolean(date)) {
                                    handleOnChangeDischarge(date.toISOString(), 'DateToSeeBH');
                                }
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    checked={nextSteps.NotApplicable}
                    sx={classes.smCheckboxOption}
                    disabled={isCompleted}
                    control={<Checkbox onChange={(e) => handleOnChangeNotApplicable(e.target.checked, 'NotApplicable')} />}
                    label="Not applicable to see NP/BH"
                />
                {nextSteps.NotApplicable ? (
                    <TextField
                        fullWidth
                        multiline
                        disabled={isCompleted}
                        value={nextSteps.ReasonForNotToSeeNPOrBH}
                        placeholder="Reason for not seeing NP/BH"
                        margin="none"
                        variant="outlined"
                        rows={4}
                        onChange={(e) => handleOnChangeDischarge(e.currentTarget.value, 'ReasonForNotToSeeNPOrBH')}
                        onBlur={(e) => handleOnChangeDischarge(e.currentTarget.value, 'ReasonForNotToSeeNPOrBH')}
                    />
                ) : null}
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                    {!isCompleted && (
                        <Button variant="outlined" sx={{ minWidth: 120 }} color="primary" onClick={handleClose}>
                            Cancel
                        </Button>
                    )}
                    {!isCompleted && (
                        <Button variant="contained" sx={{ minWidth: 120 }} color="primary" onClick={handleSubmit}>
                            Save & Continue
                        </Button>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default PostDischargeChecklist;
