import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';

const transitionalCareReport = createSlice({
    name: 'transitionalCareReport',
    initialState: {
        transitionalCareReport: [],
        transitionalCareFilter: '1',
        transitionalCareRecentActivity: false,
    },
    reducers: {
        _setTransitionalCareReport(state, action) {
            state.transitionalCareReport = action.payload;
        },
        _setTransitionalCareFilter(state, action) {
            state.transitionalCareFilter = action.payload;
        },
        _setTransitionalCareRecentActivity(state, action) {
            state.transitionalCareRecentActivity = action.payload;
        },
    },
});

const { _setTransitionalCareReport, _setTransitionalCareFilter, _setTransitionalCareRecentActivity } = transitionalCareReport.actions;

export const fetchTransitionalCareReport =
    (transitionalCareFilter: string, transitionalCareRecentActivity: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/transitionalcare/getalltransitionalcare?type=${transitionalCareFilter}&noRecentActivity=${transitionalCareRecentActivity}`
        );
        dispatch(_setTransitionalCareReport(response.data));
        dispatch(setIsLoading(false));
    };

export const setTransitionalCareFilter =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setTransitionalCareFilter(params));
    };

export const setTransitionalCareRecentActivity =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setTransitionalCareRecentActivity(params));
    };

export const exportTransitionalCareReport =
    (transitionalCareFilter: string, transitionalCareRecentActivity: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/transitionalcare/exporttransitionalcare?type=${transitionalCareFilter}&noRecentActivity=${transitionalCareRecentActivity}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default transitionalCareReport.reducer;
