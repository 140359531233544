import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setOpenContactInfo, setSftpAddress, setSftpAlert, updateHealthPlan } from '../../../store/adminHealthPlan.slice';

const SFTPDialog = (props) => {
    const { healthPlanForm, sftpAddress, sftpAlert } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const dispatch = useDispatch();

    const ordinal_suffix_of = (i) => {
        let j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + 'st';
        }
        if (j === 2 && k !== 12) {
            return i + 'nd';
        }
        if (j === 3 && k !== 13) {
            return i + 'rd';
        }
        return i + 'th';
    };

    const createArrayOfNumbers = (length) => {
        let list = [];
        for (let i = 1; i < length; i++) {
            list.push(i);
        }
        return list;
    };

    const getDefaultAlert = () => {
        return { DateOfMonth: null, DaysAfter: null };
    };

    const daysAfter = [1, 2, 5, 7, 10, 12, 15].map((day, i) => ({ label: day, value: i + 1 }));

    const [currentAlert, setCurrentAlert] = useState(getDefaultAlert());
    const inputOnChange = (e) => {
        dispatch(setSftpAddress({ ...sftpAddress, [e.target.name]: e.target.value }));
    };

    const addSftpAlertHandler = () => {
        setCurrentAlert(getDefaultAlert());
        dispatch(setSftpAlert(true));
    };

    const addAlertHandler = (alert) => {
        dispatch(setSftpAddress({ ...sftpAddress, AlertFrequency: [...sftpAddress.AlertFrequency, alert] }));
        setCurrentAlert(getDefaultAlert());
        dispatch(setSftpAlert(false));
    };

    const removeAlertHandler = (alert) => {
        dispatch(setSftpAddress({ ...sftpAddress, AlertFrequency: sftpAddress.AlertFrequency.filter((el) => el !== alert) }));
    };

    const saveSftpHandler = () => {
        let SFTPList = [...(healthPlanForm.SftpAddress || [])];
        const SFTPIndex = sftpAddress.Id.length > 0 ? SFTPList.findIndex((el) => el.Id === sftpAddress.Id) : -1;
        if (SFTPIndex > -1) {
            SFTPList[SFTPIndex] = sftpAddress;
            dispatch(updateHealthPlan({ ...healthPlanForm, SftpAddress: SFTPList }));
        }
        if (SFTPIndex === -1) {
            SFTPList[SFTPList.length] = sftpAddress;
            dispatch(updateHealthPlan({ ...healthPlanForm, SftpAddress: SFTPList }));
        }
        dispatch(setOpenContactInfo(false));
    };

    const [contacts, setContacts] = useState(sftpAddress.AlertContacts?.map((el) => el.Id));

    return (
        <Dialog open={props.open} onClose={props.onClose} disableEnforceFocus maxWidth="xl">
            <DialogContent>
                <Grid container spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                    <Grid item xs={12}>
                        <Typography marginBottom={2} variant="h5">
                            {sftpAddress.Id?.length > 0 ? 'Edit ' : 'Add '}SFTP - SSH File Transfer Protocol
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl required fullWidth>
                            <FormLabel id="demo-row-radio-buttons-group-label">File Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="FileType"
                                value={sftpAddress.FileType || 0}
                                onChange={(e) => dispatch(setSftpAddress({ ...sftpAddress, [e.target.name]: Number(e.target.value) }))}
                            >
                                <FormControlLabel value={0} control={<Radio />} label="All" />
                                <FormControlLabel value={1} control={<Radio />} label="Claims" />
                                <FormControlLabel value={2} control={<Radio />} label="Pharma Claims" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl required fullWidth>
                            <FormLabel id="demo-row-radio-buttons-group-label">Transfer Mode</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="TransferMode"
                                value={sftpAddress.TransferMode || 0}
                                onChange={(e) => dispatch(setSftpAddress({ ...sftpAddress, [e.target.name]: Number(e.target.value) }))}
                            >
                                <FormControlLabel value={0} control={<Radio />} label="Default" />
                                <FormControlLabel value={1} control={<Radio />} label="Active" />
                                <FormControlLabel value={2} control={<Radio />} label="Passive" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            required
                            value={sftpAddress.HostName || ''}
                            label="Host Name"
                            name="HostName"
                            fullWidth
                            variant="outlined"
                            onChange={inputOnChange}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            required
                            type="number"
                            value={sftpAddress.PortNumber || 22}
                            label="Port Number"
                            name="PortNumber"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => dispatch(setSftpAddress({ ...sftpAddress, [e.target.name]: Number(e.target.value) }))}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            value={sftpAddress.FilePath || ''}
                            label="File Path"
                            name="FilePath"
                            placeholder="/home/path"
                            fullWidth
                            variant="outlined"
                            onChange={inputOnChange}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            required
                            value={sftpAddress.UserName || ''}
                            label="User Name"
                            name="UserName"
                            fullWidth
                            variant="outlined"
                            onChange={inputOnChange}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            type={'password'}
                            required
                            value={sftpAddress.Password || ''}
                            label="Password"
                            name="Password"
                            fullWidth
                            variant="outlined"
                            onChange={inputOnChange}
                        />
                    </Grid>

                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                        <Typography display={'inline-flex'} marginRight="auto" variant="h5">
                            Alert When File is Not Received
                        </Typography>

                        <Button variant="contained" onClick={addSftpAlertHandler}>
                            Add Alert
                        </Button>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Alert Contact</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={contacts}
                                label="Alert Contact"
                                name="AlertContacts"
                                multiple
                                onChange={(e) => setContacts(e.target.value as [])}
                            >
                                {healthPlanForm.ContactPerson?.map((contact, index) => {
                                    return (
                                        <MenuItem key={index} value={contact.Id}>
                                            {contact.Name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} display="flex" alignItems="center" marginLeft={'auto'} justifyContent="flex-end">
                        <Button variant="contained" onClick={() => props.addCPContactHandler()}>
                            Add Alert Contact
                        </Button>
                    </Grid>

                    {sftpAddress.AlertFrequency?.length > 0 && (
                        <Grid item xs={12}>
                            {sftpAddress.AlertFrequency.map((alert, index) => {
                                return (
                                    <Paper key={index} sx={{ padding: 1, marginY: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography display={'inline-flex'}>
                                            After {daysAfter.find((el) => el.value === alert.DaysAfter).label} days from the{' '}
                                            {ordinal_suffix_of(alert.DateOfMonth)} of every month, notify:{' '}
                                            {contacts.map((contact, index) => (
                                                <span key={index} style={{ fontWeight: 'bold', marginLeft: 3 }}>
                                                    {healthPlanForm.ContactPerson.find((el) => el.Id === contact)?.Name}
                                                </span>
                                            ))}
                                        </Typography>
                                        <CancelIcon color="error" style={{ cursor: 'pointer' }} onClick={() => removeAlertHandler(alert)} />
                                    </Paper>
                                );
                            })}
                        </Grid>
                    )}

                    {sftpAlert && (
                        <>
                            <Grid item xs={12}>
                                <Paper elevation={3} sx={{ padding: 2 }}>
                                    <Stack direction={'row'} alignItems="center">
                                        <Typography variant="body2">After</Typography>

                                        <FormControl sx={{ width: '160px', marginX: 2 }}>
                                            <InputLabel id="demo-simple-select-label"># of days</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={currentAlert.DaysAfter || ''}
                                                label="# of days"
                                                name="DaysAfter"
                                                onChange={(e) => setCurrentAlert({ DateOfMonth: currentAlert.DateOfMonth, DaysAfter: e.target.value })}
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                            >
                                                {daysAfter.map((day) => {
                                                    return (
                                                        <MenuItem key={day.value} value={day.value}>
                                                            {day.label} days{' '}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>

                                        <Typography variant="body2">from the</Typography>

                                        <FormControl sx={{ width: '160px', marginX: 2 }}>
                                            <InputLabel id="demo-simple-select-label">Date of month</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={currentAlert.DateOfMonth || ''}
                                                label="Date of month"
                                                name="DateOfMonth"
                                                onChange={(e) => setCurrentAlert({ DaysAfter: currentAlert.DaysAfter, DateOfMonth: e.target.value })}
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                            >
                                                {createArrayOfNumbers(31).map((day) => {
                                                    return (
                                                        <MenuItem key={day} value={day}>
                                                            {ordinal_suffix_of(day)}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>

                                        <Typography variant="body2">of every month.</Typography>

                                        <Button
                                            disabled={currentAlert.DateOfMonth === null || currentAlert.DaysAfter === null}
                                            variant="contained"
                                            sx={{ minWidth: '120px', marginLeft: 'auto' }}
                                            onClick={() => addAlertHandler(currentAlert)}
                                        >
                                            Add
                                        </Button>
                                    </Stack>
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={
                        sftpAddress.HostName?.length === 0 ||
                        !sftpAddress.PortNumber ||
                        sftpAddress.UserName?.length === 0 ||
                        sftpAddress.Password?.length === 0
                    }
                    className="button-120"
                    variant="contained"
                    onClick={() => {
                        // dispatch(setSftpAddress({ ...sftpAddress, AlertContacts: convertList(contacts) }));
                        dispatch(setSftpAddress({ ...sftpAddress, AlertContacts: contacts }));
                        saveSftpHandler();
                        props.onClose();
                    }}
                >
                    Save
                </Button>
                <Button
                    className="button-120"
                    variant="outlined"
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SFTPDialog;
