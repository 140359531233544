import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, Divider, FormControl, FormGroup, Input, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { RootState } from '../../../../reducers';
import { deletePcpSpecialist, savePcpSpecialist } from '../../../../store/memberDetail.slice';
import TextMaskCustom from '../../../Shared/PhoneMask';
import { modalStyle } from '../../../../utils/constants';

const PcpTable = () => {
    const getDefault = () => {
        return {
            Id: null,
            ClinicName: '',
            Name: '',
            Address: '',
            Email: '',
            OfficePhone: '',
            FaxNumber: '',
            PcpOrSpecialistType: 1,
            SpecialistType: 18,
            PreferredContact: 1,
            MobilePhone: '',
        };
    };

    const reset = (row: any) => {
        let update = row ? { ...row } : getDefault();
        setForm(update);
    };

    const addHandler = (row: any = null) => {
        reset(row);
        setShowModal(true);
    };

    const dispatch = useDispatch();
    const { selectedMember, pcpList } = useSelector((state: RootState) => state.memberDetail);
    const { preferredContactOptions } = useSelector((state: RootState) => state.shared);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [form, setForm] = useState(getDefault());

    const pcpColumns: GridColDef[] = [
        {
            field: 'ClinicName',
            headerName: 'Clinic Name',
            minWidth: 200,
        },
        {
            field: 'Name',
            headerName: 'Provider Name',
            minWidth: 200,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'MobilePhone',
            headerName: 'Mobile Phone',
            minWidth: 150,
            renderCell: (params) => {
                return <Input readOnly value={params.row.MobilePhone} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
        },
        {
            field: 'OfficePhone',
            headerName: 'Office Phone',
            minWidth: 150,
            renderCell: (params) => {
                return <Input readOnly value={params.row.OfficePhone} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
        },
        {
            field: 'Email',
            headerName: 'Email',
            minWidth: 200,
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Edit
                            className="icon-left"
                            onClick={() => {
                                addHandler(params.row);
                                setShowModal(true);
                            }}
                        />
                        <Delete
                            className="icon-right"
                            onClick={() => {
                                setForm(params.row);
                                setShowDelete(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const save = () => {
        let update = { ...form };
        update.MobilePhone = form.MobilePhone?.replace(/[^\d.]/g, '');
        update.OfficePhone = form.OfficePhone?.replace(/[^\d.]/g, '');
        update.FaxNumber = form.FaxNumber?.replace(/[^\d.]/g, '');
        dispatch(savePcpSpecialist(update));
        setShowModal(false);
    };

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Stack spacing={3}>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Provider Name"
                                variant="outlined"
                                value={form.Name}
                                onChange={(e: any) => {
                                    setForm({ ...form, Name: e.target.value });
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Clinic Name"
                                variant="outlined"
                                value={form.ClinicName}
                                onChange={(e: any) => {
                                    setForm({ ...form, ClinicName: e.target.value });
                                }}
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Address"
                                variant="outlined"
                                value={form.Address}
                                onChange={(e: any) => {
                                    setForm({ ...form, Address: e.target.value });
                                }}
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Mobile Phone</InputLabel>
                                <Input
                                    value={form.MobilePhone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handlePhoneChange(e, 'MobilePhone');
                                    }}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Office Phone</InputLabel>
                                <Input
                                    value={form.OfficePhone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handlePhoneChange(e, 'OfficePhone');
                                    }}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Preferred Contact</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={form.PreferredContact}
                                    label="Preferred Contact"
                                    onChange={(e: any) => {
                                        setForm({ ...form, PreferredContact: e.target.value });
                                    }}
                                >
                                    {preferredContactOptions.map(function (option) {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Fax Number</InputLabel>
                                <Input
                                    value={form.FaxNumber}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handlePhoneChange(e, 'FaxNumber');
                                    }}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Email"
                                variant="outlined"
                                value={form.Email}
                                onChange={(e: any) => {
                                    setForm({ ...form, Email: e.target.value });
                                }}
                            />
                        </Stack>
                    </FormGroup>
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={save}>
                            Save
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>, prop) => {
        let update = { ...form };
        update[prop] = event.target.value;

        setForm(update);
    };

    const deleteHandler = () => {
        dispatch(deletePcpSpecialist(selectedMember.Id, form.Id));
        setShowDelete(false);
    };

    const renderDeleteBody = () => {
        return (
            <Stack spacing={2}>
                <Typography sx={{ marginLeft: 3 }} variant="h6">
                    Are you sure do you want to delete: {form.Name}?
                </Typography>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" color="error" onClick={() => deleteHandler()}>
                            Delete
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowDelete(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <>
            <Box style={{ height: '100%', textAlign: 'right' }}>
                <Button
                    className="button-120"
                    variant="contained"
                    onClick={() => {
                        addHandler();
                    }}
                >
                    Add PCP
                </Button>
                <DataGrid autoHeight getRowId={(row) => row.Id} rows={pcpList} columns={pcpColumns} experimentalFeatures={{ newEditingApi: true }} hideFooter />
            </Box>
            <Modal
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Pcp</Typography>
                        <Divider />
                        {renderBody()}
                    </Stack>
                </Box>
            </Modal>
            <Modal
                open={showDelete}
                onClose={() => {
                    setShowDelete(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Delete Pcp</Typography>
                        <Divider />
                        {renderDeleteBody()}
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default PcpTable;
