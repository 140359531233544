import { Grid, TextField, Autocomplete } from '@mui/material';
import React from 'react';
import IOnboarderStatus from '../../../../Models/OfficeOnboarding/OnboarderStatus.model';
import StatusSaveButton from '../../Buttons/StatusSaveButton';

interface OnboarderStatusControlBarProps {
    autoCompleteOnChange: Function;
    autocompleteOptions: IOnboarderStatus[];
    autocompleteInputValue: string;
    autocompleteHelperText: string;
    callsGoalFieldValue: number | null;
    callsGoalFieldLabel: string;
    callsGoalFieldHelperText: string;
    callsGoalFieldOnChange: Function;
    callsListFieldValue: number | null;
    callsListFieldLabel: string;
    callsListFieldHelperText: string;
    callsListFieldOnChange: Function;
    saving: boolean;
    doSaveButtonClick: Function;
    saveButtonLabel: string;
    saveButtonDisabled: boolean;
}

const OnboarderStatusControlBar = (props: OnboarderStatusControlBarProps) => {
    return (
        <Grid container spacing={2} p={5}>
            <Grid item>
                <Autocomplete
                    onChange={(event, value) => {
                        props.autoCompleteOnChange(event, value);
                    }}
                    fullWidth
                    style={{ width: 300 }}
                    options={props.autocompleteOptions}
                    inputValue={props.autocompleteInputValue}
                    isOptionEqualToValue={(option, value) => {
                        return option.Id === value.Id;
                    }}
                    getOptionLabel={(option: any) => option.Username}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Specific Onboarder" variant="outlined" helperText={props.autocompleteHelperText} />
                    )}
                />
            </Grid>
            <Grid item>
                <TextField
                    inputProps={{
                        style: { textAlign: 'center' },
                    }}
                    fullWidth
                    label={props.callsGoalFieldLabel}
                    helperText={props.callsGoalFieldHelperText}
                    type="number"
                    variant="outlined"
                    value={props.callsGoalFieldValue}
                    onChange={(event) => {
                        props.callsGoalFieldOnChange(event);
                    }}
                />
            </Grid>
            <Grid item>
                <TextField
                    inputProps={{
                        style: { textAlign: 'center' },
                    }}
                    fullWidth
                    label={props.callsListFieldLabel}
                    helperText={props.callsListFieldHelperText}
                    type="number"
                    variant="outlined"
                    value={props.callsListFieldValue}
                    onChange={(event) => {
                        props.callsListFieldOnChange(event);
                    }}
                />
            </Grid>
            <Grid item>
                <StatusSaveButton
                    saving={props.saving}
                    onClick={() => {
                        props.doSaveButtonClick();
                    }}
                    label={props.saveButtonLabel}
                    disabled={props.saveButtonDisabled}
                />
            </Grid>
        </Grid>
    );
};

export default OnboarderStatusControlBar;
