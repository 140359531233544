import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getActiveProviders } from '../../store/provider.slice';
import { localDateAndTimeFormat } from '../../utils/timeFormat';
import Loading from '../Shared/Loading';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WarningIcon from '@mui/icons-material/Warning';

import {
    fetchAppointmentOptimizedAppointments,
    setAppointmentDuration,
    setScheduleFrom,
    setScheduleTo,
    setSelectedProviderId,
    setShowModal,
} from '../../store/appointmentScheduler.slice';
import SchedulerMap from './SchedulerMap';
import { fetchMemberDemoGraphicInfo } from '../../store/memberDetail.slice';
import React from 'react';
import { parentUrl } from '../../utils/constants';

const classes = {
    addressRow: {
        borderBottom: '1px solid #ddd',
        marginBottom: '8px',
    },
};

const AppointmentScheduler = () => {
    const dispatch = useDispatch();

    const [noAddressOpen, setNoAddressOpen] = React.useState(false);
    const { appointmentDuration, appointmentMaps, scheduleFrom, scheduleTo, selectedMemberId, selectedProviderId } = useSelector(
        (state: RootState) => state.appointmentScheduler
    );
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const durations = [30, 60, 90, 120];

    const handleScheduleFromChange = (date: Date) => {
        dispatch(setScheduleFrom(date));
    };

    const handleScheduleToChange = (date: Date) => {
        dispatch(setScheduleTo(date));
    };

    useEffect(() => {
        dispatch(fetchMemberDemoGraphicInfo(selectedMemberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMemberId]);

    useEffect(() => {
        dispatch(getActiveProviders(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="mapContainer" className="mt-2">
            {<Loading message={'Loading...'} />}
            <Dialog
                fullWidth
                maxWidth="sm"
                open={noAddressOpen}
                onClose={() => {
                    setNoAddressOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Invalid Addresses</DialogTitle>
                <DialogContent>
                    <Grid style={{ padding: '16px' }} container spacing={1}>
                        <Grid item md={12} container>
                            <Grid item md={12} container>
                                <Grid item md={6}>
                                    <Typography style={{ fontWeight: 'bold' }}>Appt Address</Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography style={{ fontWeight: 'bold' }}>Appt Date</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid style={{ padding: '16px' }} container spacing={1}>
                        <Grid item md={12} container style={{ height: '50px' }}>
                            {appointmentMaps.AppointmentsNoAddress?.map((apptMap, index) => {
                                return (
                                    <Grid key={index} item md={12} container sx={classes.addressRow}>
                                        <Grid item md={6}>
                                            <Typography style={{ fontSize: '.8rem' }} sx={{ color: 'error.main' }}>
                                                {apptMap.CustomAddress ? apptMap.CustomAddress : apptMap.address}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                style={{ fontWeight: 'bold', fontSize: '.8rem', color: 'blue', cursor: 'pointer' }}
                                                onClick={() => {
                                                    window.open(
                                                        `${parentUrl}provider/members-list/member-chart/${apptMap.MemberObjectId}/member-profile`,
                                                        '_blank'
                                                    );
                                                }}
                                            >
                                                {apptMap.MemberName}
                                            </Typography>
                                            <Typography style={{ fontWeight: 'bold', fontSize: '.8rem' }}>
                                                {localDateAndTimeFormat(new Date(apptMap.startDate))}
                                            </Typography>{' '}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-120"
                        variant="outlined"
                        onClick={() => {
                            setNoAddressOpen(false);
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Box style={{ padding: '16px' }} display="inline">
                <Box display="inline" style={{ marginRight: '16px' }}>
                    <FormControl style={{ marginRight: '16px' }}>
                        <Stack>
                            <Typography fontWeight={'bold'}>{`${selectedMember?.LastName}, ${selectedMember?.FirstName}`}</Typography>
                            <Typography fontWeight={'bold'}>{`${selectedMember?.Mem_AHCCCS_ID}`}</Typography>
                        </Stack>
                    </FormControl>
                    <FormControl style={{ width: '300px', marginRight: '16px' }}>
                        <InputLabel id="demo-simple-select-label">Select Provider</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Provider"
                            value={selectedProviderId}
                            onChange={(e) => {
                                dispatch(setSelectedProviderId(e.target.value as string));
                            }}
                        >
                            <MenuItem value="">
                                <em>Select Provider</em>
                            </MenuItem>
                            {activeProviders.map((provider, index) => {
                                return (
                                    <MenuItem key={index} value={provider.Id}>
                                        {provider.LastName + ', ' + provider.FirstName + ' (' + provider.Role?.RoleName + ')'}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box display="inline" style={{ marginRight: '16px' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Schedule From"
                            value={scheduleFrom}
                            minDate={new Date()}
                            onChange={handleScheduleFromChange}
                            OpenPickerButtonProps={{
                                'aria-label': 'change date',
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box display="inline" style={{ marginRight: '16px' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Schedule To"
                            value={scheduleTo}
                            minDate={scheduleFrom}
                            maxDate={new Date(scheduleFrom.getTime() + 14 * 24 * 60 * 60 * 1000)}
                            onChange={handleScheduleToChange}
                            OpenPickerButtonProps={{
                                'aria-label': 'change date',
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box display="inline" style={{ marginRight: '16px' }}>
                    <FormControl style={{ width: '300px', marginRight: '16px' }}>
                        <InputLabel id="demo-simple-select-label">Appt Duration</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Appt Duration"
                            value={appointmentDuration}
                            onChange={(e) => {
                                dispatch(setAppointmentDuration(e.target.value as number));
                            }}
                        >
                            {durations.map((duration, index) => {
                                return (
                                    <MenuItem key={index} value={duration}>
                                        {duration + ' (minutes)'}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box display="inline" style={{ marginRight: '16px', marginTop: '8px', float: 'right' }}>
                    <Button
                        id="search-button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            dispatch(fetchAppointmentOptimizedAppointments());
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        id="close-button"
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '16px' }}
                        onClick={() => {
                            dispatch(setShowModal(false));
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
            {appointmentMaps.AppointmentsNoAddress.length > 0 && (
                <Stack
                    direction="row"
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ background: '#ed6c02', cursor: 'pointer', height: '36px', marginTop: '8px' }}
                    spacing={2}
                    p={1}
                    onClick={() => {
                        setNoAddressOpen(true);
                    }}
                >
                    <WarningIcon sx={{ fontSize: '1.5rem', color: 'white' }} />
                    <Typography variant="body1" color="white">
                        Warning - Invalid Addresses Found - Click to View
                    </Typography>
                </Stack>
            )}
            <Grid style={{ padding: '16px' }} container spacing={1}>
                <Grid item md={6} container>
                    <Grid item md={12} container>
                        <Grid item md={3}>
                            <Typography style={{ fontWeight: 'bold' }}>Member Address</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography style={{ fontWeight: 'bold' }}>Appt Address</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography style={{ fontWeight: 'bold' }}>Appt Date</Typography>
                        </Grid>
                        <Grid item md={1} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Rank</Typography>
                        </Grid>
                        <Grid item md={2} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Travel Time</Typography>
                        </Grid>
                        <Grid item md={1} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Drive Distance</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Grid item md={12} container>
                        <Grid item md={3}></Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={7} style={{ textAlign: 'right' }}></Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: '16px' }} container spacing={1}>
                <Grid item md={6} container style={{ height: '50px' }}>
                    {appointmentMaps.OptimizeRows?.map((apptMap, index) => {
                        return (
                            <Grid key={index} item md={12} container sx={classes.addressRow}>
                                <Grid item md={3}>
                                    <Typography style={{ fontSize: '.8rem' }}>{apptMap.MemberAddress}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography style={{ fontSize: '.8rem' }}>{apptMap.FromAddress}</Typography>
                                </Grid>
                                <Grid item md={2} style={{ textAlign: 'center' }}>
                                    <Typography style={{ fontSize: '.8rem' }}>{apptMap.FromMemberName}</Typography>
                                    <Typography style={{ fontSize: '.8rem' }}>{localDateAndTimeFormat(new Date(apptMap.FromApptStartTime))}</Typography>{' '}
                                    <Typography style={{ fontWeight: 'bold', fontSize: '.8rem' }}>Recommended Time</Typography>
                                    <Typography style={{ fontWeight: 'bold', fontSize: '.8rem' }}>
                                        {localDateAndTimeFormat(new Date(apptMap.RecommendedDateTime))}
                                    </Typography>{' '}
                                </Grid>
                                <Grid item md={1} style={{ textAlign: 'center' }}>
                                    <Typography>{apptMap.Rank}</Typography>
                                </Grid>
                                <Grid item md={2} style={{ textAlign: 'center' }}>
                                    <Typography style={{ fontWeight: 'bold' }}>{apptMap.OptimalTime}</Typography>
                                </Grid>
                                <Grid item md={1} style={{ textAlign: 'center' }}>
                                    <Typography>{apptMap.Distance + ' mi'}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item md={6}>
                    <Grid item>
                        <SchedulerMap />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default AppointmentScheduler;
