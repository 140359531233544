import { EncounterSubmissionStatusEnum } from '../../Enum/EncounterSubmissionStatusEnum';
import { MeansOfEngagementEnum } from '../../Enum/MeansOfEngagementEnum';
import { PatientOutreachOrInitiatedTypeEnum } from '../../Enum/PatientOutreachOrInitiatedTypeEnum';
import BaseEntity from '../BaseEntity.model';
import { Medications } from '../Medications/Medications.model';
import { EngagedProvider } from './EngagedProvider.model';
import { ProviderRole, ReasonForVisit, RequestReviewStatus, StaffReferral, TelemedicineDetails } from './EngagementDraft.model';
import { SummaryNote } from './SummaryNote.model';

export default interface IDocumentEngagement extends BaseEntity {
    MeansOfEngagement: MeansOfEngagementEnum;
    MemberId: string;
    EngagedProvider: EngagedProvider;
    Notes: string;
    CreationTime: string;
    VisitedTime: string;
    ReasonForVisit: ReasonForVisit;
    StaffReferral: StaffReferral;
    PatientOutreachOrInitiatedType: PatientOutreachOrInitiatedTypeEnum;
    UpdationTime: string;
    UpdatedProvider: EngagedProvider;
    EncounterSubmissionStatus: EncounterSubmissionStatusEnum;
    Contacted: boolean;
    ContactResult: string;
    AvoidedER: boolean;
    TelemedicineDetails: TelemedicineDetails;
    SdohAssessmentId: string;
    VitalsignAssessmentId: string;
    PhqAssessmentId: string;
    PastPresentHistoryId: string;
    PastSurgicalHistoryId: string;
    ReviewSystemId: string;
    PhysicalExamId: string;
    HgbA1CAssessmentId: string;
    LipidAssessmentId: string;
    CounselingAssessmentId: string;
    DiabetesFootAssessmentId: string;
    UrinalysisReportId: string;
    MiniMentalExamId: string;
    FactorAndRiskAssessmentId: string;
    SubjectiveAssessmentId: string;
    SummaryNotes: SummaryNote[];
    ProgressNotes: string;
    FollowupRecommendations: string;
    CompletedRequestIds: string[];
    InProgressRequestIds: string[];
    ActiveMedications: Medications[];
    AppointmentIds: string[];
    AssociatedAppointmentId: string;
    FollowUpRequestIds: string[];
    EducationSessionIds: string[];
    AssessedRole: ProviderRole;
    RequestReviewStatus: RequestReviewStatus;
    ConsentFormId: string;
    BudgetFormId: string;
    TransitionalCareId: string;
    AdlAssessmentId: string;
    SexualHealthId: string;
    FallRiskId: string;
    VisionAndHearingId: string;
    AdvancedDirectiveId: string;
    TobaccoAndDrugAssessmentId: string;
    PreventativeHealthAdviceId: string;
    HraEducationId: string;
    MemberName: string;
}

export class DocumentEngagement implements IDocumentEngagement {
    Id: string;
    MeansOfEngagement: MeansOfEngagementEnum;
    MemberId: string;
    EngagedProvider: EngagedProvider;
    Notes: string;
    CreationTime: string;
    VisitedTime: string;
    ReasonForVisit: ReasonForVisit;
    StaffReferral: StaffReferral;
    PatientOutreachOrInitiatedType: PatientOutreachOrInitiatedTypeEnum;
    UpdationTime: string;
    UpdatedProvider: EngagedProvider;
    EncounterSubmissionStatus: EncounterSubmissionStatusEnum;
    Contacted: boolean;
    ContactResult: string;
    AvoidedER: boolean;
    TelemedicineDetails: TelemedicineDetails;
    SdohAssessmentId: string;
    VitalsignAssessmentId: string;
    PhqAssessmentId: string;
    PastPresentHistoryId: string;
    PastSurgicalHistoryId: string;
    ReviewSystemId: string;
    PhysicalExamId: string;
    HgbA1CAssessmentId: string;
    LipidAssessmentId: string;
    CounselingAssessmentId: string;
    DiabetesFootAssessmentId: string;
    UrinalysisReportId: string;
    MiniMentalExamId: string;
    FactorAndRiskAssessmentId: string;
    SubjectiveAssessmentId: string;
    SummaryNotes: SummaryNote[];
    ProgressNotes: string;
    FollowupRecommendations: string;
    CompletedRequestIds: string[];
    InProgressRequestIds: string[];
    ActiveMedications: Medications[];
    AppointmentIds: string[];
    AssociatedAppointmentId: string;
    FollowUpRequestIds: string[];
    EducationSessionIds: string[];
    AssessedRole: ProviderRole;
    RequestReviewStatus: RequestReviewStatus;
    ConsentFormId: string;
    BudgetFormId: string;
    TransitionalCareId: string;
    AdlAssessmentId: string;
    SexualHealthId: string;
    FallRiskId: string;
    VisionAndHearingId: string;
    AdvancedDirectiveId: string;
    TobaccoAndDrugAssessmentId: string;
    PreventativeHealthAdviceId: string;
    HraEducationId: string;
    MemberName: string;
    IsShowStaffReferral: boolean;
    isShowPatientOutReach: boolean;

    constructor() {
        this.Id = '';
        this.MemberId = '';
    }
}
