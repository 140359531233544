import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tabs,
    Tab,
    FormControlLabel,
    Checkbox,
    Chip,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { getAssessmentByType, gotoNextMenu, gotoPreviousMenu, saveAssessmentWorkflow, updateMemberAssessmentModel } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import CategoryQuestions from '../CategoryQuestions/CategoryQuestions';
import { AssessmentTypeEnum } from '../../../Enum/AssessmentTypeEnum';
import { AssessmentCategory } from '../../../Models/Assessment/AssessmentCategory.model';
import classes from '../CategoryQuestions/Styles';
import moment from 'moment';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { copyObject } from '../../../utils/common';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

const ReviewSystem: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();

    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const { isSaving, memberAssessment, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const type = AssessmentTypeEnum.ReviewSystems;
    const title = 'Review of Systems';

    useEffect(() => {
        dispatch(getAssessmentByType(type, memberId));
    }, [dispatch, type, memberId]);

    useEffect(() => {
        if (isFetching && !isPageLoaded) {
            setIsPageLoaded(true);
        }
    }, [isFetching, isPageLoaded]);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleOnChangeCategory = (updatedCategory: AssessmentCategory) => {
        setIsChanged(true);
        dispatch(updateMemberAssessmentModel(updatedCategory));
    };

    const handleOnUpdateAnswer = (category, isSelected, questionIndex, optionIndex) => {
        let data = copyObject(category);
        data.Questions[questionIndex].Options.forEach((option, index) => {
            if (index === optionIndex) {
                option.IsSelected = isSelected;
            } else {
                option.IsSelected = false;
            }
        });
        data.Questions[questionIndex].Options[optionIndex].IsSelected = isSelected;
        handleOnChangeCategory(data);
    };

    const getFilteredCategories = () => {
        return memberAssessment?.Assessment?.Categories.slice(0, 9);
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveAssessmentWorkflow(memberAssessment.Assessment?.Categories[0], isWithoutComplete, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const renderPainAssessmentCategories = () => {
        const selectedCategories = memberAssessment?.Assessment?.Categories.slice(10, 17);
        if (selectedCategories && selectedCategories.length) {
            return (
                <TableContainer component={Paper} sx={classes.customTable}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Pain Assessment</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                        {selectedCategories.map((category, index) => (
                            <Tab label={category.Name} key={`tab_${category.Name}_${index}`} value={index} sx={classes.smTabButton} />
                        ))}
                    </Tabs>
                    {selectedCategories.map((category, index) => (
                        <TabPanel value={selectedTab} index={index} key={`tab_panel_${category.Name}_${index}`}>
                            <Table>
                                <TableBody>
                                    {category.Questions.map((question, qIndex) => (
                                        <TableRow key={`${question.Id}_${qIndex}`}>
                                            <TableCell component="th" scope="row" width="45%">
                                                {question.Description}
                                            </TableCell>
                                            <TableCell scope="row" width="55%">
                                                {category.Questions.length === qIndex + 1 ? (
                                                    <Grid container display="flex" alignItems="center" justifyContent="space-between">
                                                        {question.Options.map((option, oIndex) => (
                                                            <Grid item key={`${option.Id}_${index}_${oIndex}`} display="flex" alignItems="center">
                                                                <Checkbox
                                                                    checked={option.IsSelected}
                                                                    size="small"
                                                                    radioGroup={question.Description}
                                                                    onChange={(e) => handleOnUpdateAnswer(category, e.currentTarget.checked, qIndex, oIndex)}
                                                                    icon={<PanoramaFishEyeIcon />}
                                                                    checkedIcon={<RadioButtonCheckedIcon />}
                                                                />
                                                                {option.Name}
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                ) : (
                                                    <Grid container display="flex" alignItems="center" justifyContent="space-between">
                                                        {question.Options.map((option, oIndex) => (
                                                            <Grid item key={`${option.Id}_${index}_${oIndex}`}>
                                                                <FormControlLabel
                                                                    sx={classes.labelCheckbox}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={option.IsSelected}
                                                                            size="small"
                                                                            radioGroup={question.Description}
                                                                            onChange={(e) =>
                                                                                handleOnUpdateAnswer(category, e.currentTarget.checked, qIndex, oIndex)
                                                                            }
                                                                        />
                                                                    }
                                                                    label={option.Name}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TabPanel>
                    ))}
                </TableContainer>
            );
        } else {
            return null;
        }
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching || !isPageLoaded ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item lg={12} md={12} sm={12} sx={{ mb: 2 }}>
                        <label>
                            <strong>Last Completed : </strong>{' '}
                            {Boolean(memberAssessment.AssessmentDate) ? (
                                <Chip size="small" label={moment(memberAssessment.AssessmentDate).format('MM/DD/yyyy')} />
                            ) : (
                                '-'
                            )}
                        </label>
                    </Grid>
                    <Grid item className="content-form">
                        <Grid container spacing={2}>
                            {getFilteredCategories()?.map((category, index) => (
                                <Grid item xs={6} key={`category_${category.Name}_${index}`}>
                                    <CategoryQuestions assessmentType={type} category={category} onChange={(c) => handleOnChangeCategory(c)} />
                                </Grid>
                            ))}
                            <Grid item xs={6}>
                                {memberAssessment && renderPainAssessmentCategories()}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.ReviewSystemId) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title={`${title} Assessment`}
                    cancelText="Go Back"
                    okText="Confirm"
                    showBottomCenter={true}
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message={`I confirm and acknowledge that ${title} assessment is completed.`}
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title={`${title} Assessment`}
                    buttonsDirection="column-reverse"
                    showBottomCenter={true}
                    cancelText="Go Back"
                    skipText={`${title} Assessment Not Reviewed`}
                    okText={`Agree With ${title} Assessment`}
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message={`Last ${title} assessment completed on ${moment(memberAssessment.AssessmentDate).format('MM/DD/yyyy')}.`}
                />
            )}
        </Grid>
    );
};

export default ReviewSystem;
