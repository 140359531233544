import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    InputAdornment,
    Button,
    Box,
    CircularProgress,
    Stack,
    TextField,
    Collapse,
    Typography,
    Checkbox,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Popover,
    IconButton,
    Modal,
    FormControl,
    FormLabel,
    FormHelperText,
} from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoIcon from '@mui/icons-material/Info';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { copyObject, preventTextInput } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchTobaccoAndDrugAssessmentById, saveTobaccoAndDrugAssessment } from '../../../store/hra.slice';
import { AlcoholProblems, AuditAnswers, DrinkContainingAlcoholFrequencies, NumberOfDrinks, YesNoAnswers } from '../../../utils/assessments';
import { TobaccoAssessment } from '../../../Models/HRA/TobaccoAssessment.model';
import { TobaccoAndDrugAssessment } from '../../../Models/HRA/TobaccoAndDrugAssessment.model';
import { DrugAssessment } from '../../../Models/HRA/DrugAssessment.model';
import { GenderEnum } from '../../../Enum/GenderEnum';
import classes from '../Styles';
import { AuditAssessment, EducationOnDrinking } from '../../../Models/HRA/AuditAssessment.model';
import { AnswerOptionsEnum } from '../../../Enum/AnswerOptionsEnum';

const educationOnDrinkingFormSchema = Yup.object().shape({
    CompletedStatus: Yup.mixed().nullable().required('Required.'),
    ReasonForNotApplicable: Yup.string().when('CompletedStatus', {
        is: String(AnswerOptionsEnum.NotApplicable),
        then: Yup.string().nullable().required('Reason is required.'),
    }),
});

const TobaccoAndDrug: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [anchorInfoEl, setAnchorInfoEl] = React.useState<HTMLButtonElement | null>(null);
    const [showAuditInfo, setShowAuditInfo] = React.useState<boolean>(false);
    const [showDrugInfo, setShowDrugInfo] = React.useState<boolean>(false);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [newEducationOnDrinking, setNewEducationOnDrinking] = useState<EducationOnDrinking>(new EducationOnDrinking());
    const [showEducationOnDrinking, setShowEducationOnDrinking] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const { tobaccoAndDrug, isFetching, isSaving: isSavingHra } = useSelector((state: RootState) => state.hra);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingHra || isSavingEngagementDraft;

    useEffect(() => {
        if (Boolean(engagementDraft.TobaccoAndDrugAssessmentId)) {
            dispatch(fetchTobaccoAndDrugAssessmentById(engagementDraft.TobaccoAndDrugAssessmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    useEffect(() => {
        if (!Boolean(anchorInfoEl)) {
            setTimeout(() => {
                setShowAuditInfo(false);
                setShowDrugInfo(false);
            }, 600);
        }
    }, [anchorInfoEl]);

    const handleOnClickSave = () => {
        if (tobaccoAndDrug?.AuditAssessment?.Score > 0 && tobaccoAndDrug?.AuditAssessment?.Score <= 7) {
            setShowEducationOnDrinking(true);
            setNewEducationOnDrinking(tobaccoAndDrug.AuditAssessment?.EducationOnDrinking || new EducationOnDrinking());
        } else {
            setShowSaveAlert(true);
        }
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveTobaccoAndDrugAssessment({ ...tobaccoAndDrug, Status: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeMainAnswer = (answer: any, propName: string) => {
        let data = copyObject(tobaccoAndDrug);
        data[propName] = answer;
        data.MemberId = memberId;

        dispatch(saveTobaccoAndDrugAssessment(data));
    };

    const handleOnChangeTobaccoAnswer = (answer: any, propName: string) => {
        let data = copyObject(tobaccoAndDrug);
        if (data.TobaccoAssessment === null) {
            data.TobaccoAssessment = new TobaccoAssessment();
        }

        data.TobaccoAssessment[propName] = answer;
        data.MemberId = memberId;

        dispatch(saveTobaccoAndDrugAssessment(data));
    };

    const handleOnChangeDrugAnswer = (answer: any, propName: string) => {
        let data = copyObject(tobaccoAndDrug);
        if (data.DrugAssessment === null) {
            data.DrugAssessment = new DrugAssessment();
        }

        data.DrugAssessment[propName] = answer;
        data.MemberId = memberId;

        dispatch(saveTobaccoAndDrugAssessment(data));
    };

    const handleOnChangeAuditAnswer = (answer: any, propName: string) => {
        let data = copyObject(tobaccoAndDrug) as TobaccoAndDrugAssessment;
        if (data.AuditAssessment === null) {
            data.AuditAssessment = new AuditAssessment();
        }

        data.AuditAssessment[propName] = answer;
        data.MemberId = memberId;
        dispatch(saveTobaccoAndDrugAssessment(data));
    };

    const prepareAuditQuestions = () => {
        return [
            {
                name: 'DrinkContainingAlcoholFrequency',
                question: 'How often do you have a drink containing alcohol?',
                answer: tobaccoAndDrug.AuditAssessment?.DrinkContainingAlcoholFrequency,
                options: DrinkContainingAlcoholFrequencies,
            },
            {
                name: 'DrinkContainingAlcoholPerDayFrequency',
                question: 'During the day, how many drinks containing alcohol do you have?',
                answer: tobaccoAndDrug.AuditAssessment?.DrinkContainingAlcoholPerDayFrequency,
                options: NumberOfDrinks,
            },
            {
                name: 'SixOrMoreDrinksOnOneOccasionFrequency',
                question: 'How often do you have six or more standard drinks on one occasion?',
                answer: tobaccoAndDrug.AuditAssessment?.SixOrMoreDrinksOnOneOccasionFrequency,
                options: AuditAnswers,
            },
            {
                name: 'NotAbleToStopOnceStartedFrequency',
                question: 'During the last year, how often have you found that you were not able to stop drinking once you had started?',
                answer: tobaccoAndDrug.AuditAssessment?.NotAbleToStopOnceStartedFrequency,
                options: AuditAnswers,
            },
            {
                name: 'FailedToDoBecauseOfDrinkingFrequency',
                question: 'Within the last year, how often have you not been able to things you used to because of drinking?',
                answer: tobaccoAndDrug.AuditAssessment?.FailedToDoBecauseOfDrinkingFrequency,
                options: AuditAnswers,
            },
            {
                name: 'LastYearNeededDrinkFirstThingFrequency',
                question: 'Within the last year, how often have you needed a drink first thing in the morning?',
                answer: tobaccoAndDrug.AuditAssessment?.LastYearNeededDrinkFirstThingFrequency,
                options: AuditAnswers,
            },
            {
                name: 'FeltGuiltyOrRemorsefulForDrinkingFrequency',
                question: 'How often, during the last year, have you felt guilty or remorseful for drinking?',
                answer: tobaccoAndDrug.AuditAssessment?.FeltGuiltyOrRemorsefulForDrinkingFrequency,
                options: AuditAnswers,
            },
            {
                name: 'UnableToRememberWhatHappenedFrequency',
                question: 'Within the past year have you been unable to remember what happened due to drinking?',
                answer: tobaccoAndDrug.AuditAssessment?.UnableToRememberWhatHappenedFrequency,
                options: AuditAnswers,
            },
            {
                name: 'InjuredAfterDrinkingFrequency',
                question: 'How you or someone else been injured because of your drinking?',
                answer: tobaccoAndDrug.AuditAssessment?.InjuredAfterDrinkingFrequency,
                options: AuditAnswers,
            },
            {
                name: 'OthersConcernedAboutDrinkingFrequency',
                question: 'Has a relative, friend, doctor, care provider or someone close to you told you they are concerned about your drinking?',
                answer: tobaccoAndDrug.AuditAssessment?.OthersConcernedAboutDrinkingFrequency,
                options: AuditAnswers,
            },
            {
                name: 'EverBeenInTreatmentForAlcoholProblem',
                question: 'Have you ever been in treatment for an alcohol problem?',
                answer: tobaccoAndDrug.AuditAssessment?.EverBeenInTreatmentForAlcoholProblem,
                options: AlcoholProblems,
            },
        ];
    };

    const preparePast12yearsQuestions = () => {
        return [
            {
                question: 'Have you used drugs other than those required for medical reasons?',
                answer: tobaccoAndDrug.DrugAssessment?.HasUsedOtherThanMedicalReason,
                name: 'HasUsedOtherThanMedicalReason',
            },
            {
                question: 'Do you abuse more than one drug at a time?',
                answer: tobaccoAndDrug.DrugAssessment?.HasAbusedMoreThanOne,
                name: 'HasAbusedMoreThanOne',
            },
            {
                question: 'Are you unable to stop abusing drugs when you want to?',
                answer: tobaccoAndDrug.DrugAssessment?.UnableToStop,
                name: 'UnableToStop',
            },
            {
                question: 'Have you ever had blackouts or flashbacks as a result of drug use?',
                answer: tobaccoAndDrug.DrugAssessment?.HasBlackoutsOrFlashback,
                name: 'HasBlackoutsOrFlashback',
            },
            {
                question: 'Do you ever feel bad or guilty about your drug use?',
                answer: tobaccoAndDrug.DrugAssessment?.FeltGuiltyAbout,
                name: 'FeltGuiltyAbout',
            },
            {
                question: 'Does your spouse (or parents) ever complain about your involvement with drugs?',
                answer: tobaccoAndDrug.DrugAssessment?.FamilyComplained,
                name: 'FamilyComplained',
            },
            {
                question: 'Have you neglected your family because of your use of drugs?',
                answer: tobaccoAndDrug.DrugAssessment?.NeglectedFamily,
                name: 'NeglectedFamily',
            },
            {
                question: 'Have you engaged in illegal activities in order to obtain drugs?',
                answer: tobaccoAndDrug.DrugAssessment?.EngagedInIllegalActivityToObtainDrug,
                name: 'EngagedInIllegalActivityToObtainDrug',
            },
            {
                question: 'Have you ever experienced withdrawal symptoms (felt sick) when you stopped taking drugs?',
                answer: tobaccoAndDrug.DrugAssessment?.IsExperiencedWithdrawalSymptoms,
                name: 'IsExperiencedWithdrawalSymptoms',
            },
            {
                question: 'Have you had medical problems as a result of your drug use (e.g. memory loss, hepatitis, convulsions, bleeding)?',
                answer: tobaccoAndDrug.DrugAssessment?.HasMedicalProblemsDueToDrugUsage,
                name: 'HasMedicalProblemsDueToDrugUsage',
            },
        ];
    };

    const handleOnSubmitEducationOnDrink = (values: EducationOnDrinking) => {
        let data = copyObject(tobaccoAndDrug) as TobaccoAndDrugAssessment;
        data.AuditAssessment.EducationOnDrinking = values;
        data.MemberId = memberId;
        dispatch(
            saveTobaccoAndDrugAssessment(data, false, () => {
                setShowSaveAlert(true);
                setShowEducationOnDrinking(false);
            })
        );
    };

    const renderEducationOnDrinking = () => {
        const handleOnCloseModal = () => setShowEducationOnDrinking(false);

        return (
            <Modal open={showEducationOnDrinking} onClose={handleOnCloseModal}>
                <Formik
                    initialValues={newEducationOnDrinking}
                    enableReinitialize={true}
                    onSubmit={handleOnSubmitEducationOnDrink}
                    validationSchema={educationOnDrinkingFormSchema}
                >
                    {({ values, errors, handleChange, submitCount }: FormikProps<EducationOnDrinking>) => (
                        <Form noValidate>
                            <Box sx={[classes.mdModalPopupForm, classes.form]}>
                                <div className="mui-modal-header">
                                    <Typography variant="h6" component="h6">
                                        Education on drinking and harm reduction
                                    </Typography>
                                </div>
                                <div className="mui-modal-body">
                                    <Stack>
                                        <FormControl error={Boolean(submitCount) && Boolean(errors?.CompletedStatus)}>
                                            <FormLabel id="education-radio-buttons-group-label">Education completed</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="education-radio-buttons-group-label"
                                                value={values.CompletedStatus}
                                                name="CompletedStatus"
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value={AnswerOptionsEnum.Yes} control={<Radio />} label={AnswerOptionsEnum.YesDescription} />
                                                <FormControlLabel value={AnswerOptionsEnum.No} control={<Radio />} label={AnswerOptionsEnum.NoDescription} />
                                                <FormControlLabel
                                                    value={AnswerOptionsEnum.NotApplicable}
                                                    control={<Radio />}
                                                    label={AnswerOptionsEnum.NotApplicableDescription}
                                                />
                                            </RadioGroup>
                                            <FormHelperText>
                                                {Boolean(submitCount) && Boolean(errors?.CompletedStatus) ? errors?.CompletedStatus : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <Collapse in={String(values.CompletedStatus) === String(AnswerOptionsEnum.NotApplicable)} timeout="auto" unmountOnExit>
                                            <TextField
                                                fullWidth
                                                label="Reason"
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                rows={5}
                                                value={values.ReasonForNotApplicable}
                                                name="ReasonForNotApplicable"
                                                onChange={handleChange}
                                                error={Boolean(submitCount) && Boolean(errors?.ReasonForNotApplicable)}
                                                helperText={Boolean(submitCount) ? errors?.ReasonForNotApplicable : ''}
                                            />
                                        </Collapse>
                                    </Stack>
                                </div>
                                <div className="mui-modal-footer">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        disabled={isSavingHra}
                                        endIcon={isSavingHra ? <CircularProgress size={18} color="inherit" /> : null}
                                    >
                                        Save
                                    </Button>
                                    <Button size="small" type="button" onClick={handleOnCloseModal} variant="outlined" disabled={isSavingHra}>
                                        Cancel
                                    </Button>
                                </div>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" sx={{ pt: 4 }}>
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form">
                        <div>
                            <Typography variant="h6" component="label">
                                Tobacco Use
                            </Typography>
                            <hr />
                        </div>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item lg={8} md={6} sm={6} display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="label" sx={{ mb: 2 }}>
                                    Does Member currently or formerly use tobacco in any of the following forms:
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <RadioGroup
                                    row
                                    name="HasTobaccoUse"
                                    value={String(tobaccoAndDrug.TobaccoAssessment?.HasTobaccoUse)}
                                    onChange={(e) => handleOnChangeTobaccoAnswer(e.target.value === 'true', e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        {Boolean(tobaccoAndDrug.TobaccoAssessment) ? (
                            <Collapse in={tobaccoAndDrug.TobaccoAssessment?.HasTobaccoUse} timeout="auto" unmountOnExit>
                                <Grid container spacing={2}>
                                    <Grid item xs={0.5} />
                                    <Grid item xs>
                                        <Grid container spacing={2}>
                                            <Grid item lg={3} md={6} sm={6} display="flex" alignItems="center">
                                                <Typography variant="subtitle1" component="label">
                                                    Cigarettes/Cigars:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={5} md={6} sm={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="HasCigrettesUse"
                                                            checked={tobaccoAndDrug.TobaccoAssessment.HasCigrettesUse}
                                                            onChange={(e) => handleOnChangeTobaccoAnswer(e.target.checked, e.target.name)}
                                                        />
                                                    }
                                                    label="Yes"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item lg={3} md={6} sm={6} display="flex" alignItems="center">
                                                <Typography variant="subtitle1" component="label">
                                                    Chewing tobacco/snuff:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={5} md={6} sm={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="IsChewingTobacco"
                                                            checked={tobaccoAndDrug.TobaccoAssessment.IsChewingTobacco}
                                                            onChange={(e) => handleOnChangeTobaccoAnswer(e.target.checked, e.target.name)}
                                                        />
                                                    }
                                                    label="Yes"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item lg={3} md={6} sm={6} display="flex" alignItems="center">
                                                <Typography variant="subtitle1" component="label">
                                                    Vaping:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={5} md={6} sm={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="IsVaping"
                                                            checked={tobaccoAndDrug.TobaccoAssessment.IsVaping}
                                                            onChange={(e) => handleOnChangeTobaccoAnswer(e.target.checked, e.target.name)}
                                                        />
                                                    }
                                                    label="Yes"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Stack direction="row" spacing={2} alignItems="center" marginBottom={4}>
                                            <Typography variant="subtitle1" component="label">
                                                How many years has the member use tobacco?
                                            </Typography>
                                            <TextField
                                                variant="outlined"
                                                name="TobaccoUseInYears"
                                                className="hide-textinput-label"
                                                type="number"
                                                onKeyPress={preventTextInput}
                                                defaultValue={tobaccoAndDrug.TobaccoAssessment?.TobaccoUseInYears}
                                                inputProps={{ min: 0, max: 100 }}
                                                style={{ width: '120px' }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">Years</InputAdornment>,
                                                }}
                                                onBlur={(e) => handleOnChangeTobaccoAnswer(e.target.value, e.target.name)}
                                            />
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems="center" marginBottom={1}>
                                            <Typography variant="subtitle1" component="label">
                                                Average usage per day:
                                            </Typography>
                                            <TextField
                                                variant="outlined"
                                                name="AverageToboccoUsagePerDay"
                                                className="hide-textinput-label"
                                                type="number"
                                                onKeyPress={preventTextInput}
                                                defaultValue={tobaccoAndDrug.TobaccoAssessment?.AverageToboccoUsagePerDay}
                                                placeholder="Enter quantity here"
                                                inputProps={{ min: 0, max: 100 }}
                                                style={{ width: '200px' }}
                                                onBlur={(e) => handleOnChangeTobaccoAnswer(e.target.value, e.target.name)}
                                            />
                                        </Stack>
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} md={6} sm={6}>
                                                <Stack direction="row" spacing={2}>
                                                    <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                                                        <Typography variant="subtitle1" component="label" sx={{ pb: 2 }}>
                                                            Interest in quitting?
                                                        </Typography>
                                                        <RadioGroup
                                                            row
                                                            name="IsInterestedInQuitting"
                                                            value={String(tobaccoAndDrug.TobaccoAssessment.IsInterestedInQuitting)}
                                                            onChange={(e) => handleOnChangeTobaccoAnswer(e.target.value === 'true', e.target.name)}
                                                        >
                                                            {YesNoAnswers.map((type, index) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        key={`is-active-${index}`}
                                                                        value={type.Id}
                                                                        control={<Radio />}
                                                                        label={type.Name}
                                                                    />
                                                                );
                                                            })}
                                                        </RadioGroup>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        ) : null}

                        <div className="mt-4">
                            <Typography variant="h6" component="label">
                                Drinks Use
                            </Typography>
                            <hr />
                        </div>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item lg={8} md={6} sm={6} display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="label" sx={{ mb: 2 }}>
                                    {`How many times in the past year have you had ${
                                        memberBasicInfo.Gender === GenderEnum.MaleDescription ? '5' : '4'
                                    } or more drinks in a day?`}
                                    <IconButton
                                        onClick={(e) => {
                                            setShowAuditInfo(true);
                                            setAnchorInfoEl(e.currentTarget);
                                        }}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <RadioGroup
                                    row
                                    name="HasFiveOrMoreDrinksPerDayInPastYear"
                                    value={String(tobaccoAndDrug.HasFiveOrMoreDrinksPerDayInPastYear)}
                                    onChange={(e) => handleOnChangeMainAnswer(e.target.value === 'true', e.target.name)}
                                >
                                    <FormControlLabel value="false" control={<Radio />} label="None" />
                                    <FormControlLabel value="true" control={<Radio />} label="1 or more" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Collapse in={tobaccoAndDrug.HasFiveOrMoreDrinksPerDayInPastYear} timeout="auto" unmountOnExit>
                            <TableContainer component={Paper} sx={[{ ...classes.budgetFormTable }, { mt: 2 }]}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <Typography variant="subtitle1" component="label">
                                                    <strong>AUDIT Questions</strong>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {prepareAuditQuestions().map((item, index) => (
                                            <TableRow key={`drug-question-${index}`}>
                                                <TableCell align="center" style={{ verticalAlign: 'top' }}>
                                                    <Typography variant="subtitle1" component="label" paddingTop="2px">
                                                        {index + 1}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack>
                                                        <Typography variant="subtitle1" fontWeight="600" component="label">
                                                            {item.question}
                                                        </Typography>
                                                        <Stack direction="row" marginTop={1}>
                                                            {item.options.map((option, index) => (
                                                                <FormControlLabel
                                                                    key={`${option.Id}_${index}`}
                                                                    control={
                                                                        <Checkbox
                                                                            name={item.name}
                                                                            checked={Boolean(item.answer) && item.answer === option.Id}
                                                                            onChange={(e) =>
                                                                                handleOnChangeAuditAnswer(e.target.checked ? option.Id : null, e.target.name)
                                                                            }
                                                                            icon={<PanoramaFishEyeIcon />}
                                                                            checkedIcon={<RadioButtonCheckedIcon />}
                                                                        />
                                                                    }
                                                                    label={option.Name}
                                                                />
                                                            ))}
                                                        </Stack>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Typography variant="subtitle1" component="label">
                                                    <strong>Score: {tobaccoAndDrug.AuditAssessment.Score}</strong>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>

                        <Typography variant="h6" component="label" sx={{ mt: 4 }}>
                            Drug Use
                            <IconButton
                                onClick={(e) => {
                                    setShowDrugInfo(true);
                                    setAnchorInfoEl(e.currentTarget);
                                }}
                            >
                                <InfoIcon />
                            </IconButton>
                        </Typography>
                        <hr />

                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item lg={8} md={6} sm={6} display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="label" sx={{ mb: 2 }}>
                                    How many times in the past year have you used a recreational drug or used a prescription medication for nonmedical reasons?
                                    <IconButton onClick={(e) => setAnchorInfoEl(e.currentTarget)}>
                                        <InfoIcon />
                                    </IconButton>
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <RadioGroup
                                    row
                                    name="HasUsageInPastYear"
                                    value={String(tobaccoAndDrug.DrugAssessment?.HasUsageInPastYear)}
                                    onChange={(e) => handleOnChangeDrugAnswer(e.target.value === 'true', e.target.name)}
                                >
                                    <FormControlLabel value="false" control={<Radio />} label="None" />
                                    <FormControlLabel value="true" control={<Radio />} label="1 or more" />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Collapse in={tobaccoAndDrug.DrugAssessment?.HasUsageInPastYear} timeout="auto" unmountOnExit>
                            <TableContainer component={Paper} sx={[{ ...classes.budgetFormTable }, { mt: 2 }]}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography variant="subtitle1" component="label">
                                                    <strong>In the past 12 months...</strong>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {preparePast12yearsQuestions().map((item, index) => (
                                            <TableRow key={`drug-question-${index}`}>
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" component="label">
                                                        {index + 1}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle1" component="label">
                                                        {item.question}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                name={item.name}
                                                                checked={Boolean(item.answer)}
                                                                onChange={(e) => handleOnChangeDrugAnswer(e.target.checked, e.target.name)}
                                                            />
                                                        }
                                                        label="Yes"
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                name={item.name}
                                                                checked={item.answer === false}
                                                                onChange={(e) => handleOnChangeDrugAnswer(!e.target.checked, e.target.name)}
                                                            />
                                                        }
                                                        label="No"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={4}>
                                                <Typography variant="subtitle1" component="label">
                                                    <strong>Score: {tobaccoAndDrug.DrugAssessment.Score}</strong>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {renderEducationOnDrinking()}
            {Boolean(engagementDraft.TobaccoAndDrugAssessmentId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Tobacco and Drug Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Tobacco and Drug assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Tobacco and Drug Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Tobacco and Drug assessment?"
                />
            )}

            <Popover
                open={Boolean(anchorInfoEl)}
                anchorEl={anchorInfoEl}
                onClose={() => setAnchorInfoEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: { maxWidth: '600px' },
                }}
            >
                {showAuditInfo ? (
                    <Stack direction="column" spacing={1} sx={{ p: 3 }}>
                        <Typography variant="body1" component="label" fontWeight="bold">
                            Drinks
                        </Typography>
                        <Typography variant="subtitle1" component="label">
                            One drink equals a 12 oz beer, 5 oz wine or 1.5 oz liquor (one shot)
                        </Typography>
                    </Stack>
                ) : showDrugInfo ? (
                    <Stack direction="column" spacing={1} sx={{ p: 3 }}>
                        <Typography variant="body1" component="label" fontWeight="bold">
                            Drug Use
                        </Typography>
                        <Typography variant="subtitle1" component="label">
                            Drug abuse refers to use of prescribed or over the counter drugs in excess of directions and/or any non-medical use of drugs
                        </Typography>
                    </Stack>
                ) : (
                    <Stack direction="column" spacing={1} sx={{ p: 3 }}>
                        <Typography variant="body1" component="label" fontWeight="bold">
                            Drugs
                        </Typography>
                        <Typography variant="subtitle1" component="label">
                            Recreational drugs include methamphetamines (speed, crystal), cannabis (marijuana, pot), inhalants (paint thinner, aerosol, glue),
                            tranquilizers (Valium), barbiturates, cocaine, ecstasy, hallucinogens (LSD, mushrooms), or narcotics (heroin).
                        </Typography>
                    </Stack>
                )}
            </Popover>
        </Grid>
    );
};

export default TobaccoAndDrug;
