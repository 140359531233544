import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { ProviderId } from '../../utils/constants';
import { IResourceReviewModalProps } from './interfaces/IResourceReviewModalProps';
import ResourceReview from './ResourceReview';
import ResourceReviewEditModal from './ResourceReviewEditModal';

const ResourceReviewModal: React.FC<IResourceReviewModalProps> = (props) => {
    const { open, onClose, reviews, resourceName } = props;
    const [isEditingReview, setIsEditingReview] = useState<boolean>(false);
    const [userIndex, setUserIndex] = useState<number | undefined>(undefined);

    const closeReviews = () => {
        setUserIndex(undefined);
        onClose();
    };

    const reviewList = useMemo(() => {
        if (reviews?.length === 0) return <Typography style={{ textAlign: 'center' }}>No Reviews</Typography>;
        return React.Children.toArray(
            reviews
                .sort((a, b) => new Date(b.UpdatedOn).getMilliseconds() - new Date(a.UpdatedOn).getMilliseconds())
                .map((review) => <ResourceReview {...review} />)
        );
    }, [reviews]);

    useEffect(() => {
        if (reviews?.length > 0) {
            const foundIndex = reviews.findIndex((review) => review.ReviewerProviderId === ProviderId);
            if (foundIndex !== -1) {
                setUserIndex(foundIndex);
            }
        }
    }, [reviews]);

    return (
        <Dialog
            open={open}
            onClose={closeReviews}
            aria-labelledby="form-dialog-title"
            fullWidth
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={closeReviews} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6">{resourceName} Reviews</Typography>
            </Toolbar>

            <DialogContent>
                <ResourceReviewEditModal open={isEditingReview} onClose={() => setIsEditingReview(false)} usersReviewIndex={userIndex} />
                <Stack spacing={2}>
                    <Button fullWidth variant="contained" onClick={() => setIsEditingReview(true)}>
                        Leave or Edit Your Review
                    </Button>
                    {reviewList}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ResourceReviewModal;
