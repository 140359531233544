import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import { MemberBudget } from '../Models/Budget/MemberBudget.model';
import { saveEngagementDraft } from './documentEngagement.slice';
import { gotoNextMenu } from './assessment.slice';
import { copyObject } from '../utils/common';
import { SummaryNoteTypeEnum } from '../Enum/SummaryNoteTypeEnum';

const memberBudgetSlice = createSlice({
    name: 'memberBudget',
    initialState: {
        memberBudget: new MemberBudget(),
        isFetching: false,
        isSaving: false,
        isChanged: false,
    },
    reducers: {
        _setMemberBudget(state, action) {
            state.memberBudget = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsChanged(state, action) {
            state.isChanged = action.payload;
        },
    },
});

const { _setMemberBudget, _toggleIsFetching, _toggleIsSaving, _toggleIsChanged } = memberBudgetSlice.actions;

export const getMemberBudget =
    (id: string, memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const getBudgetByIdUrl = `${API_URL}/member/getmemberbudgetbyid`;
        const response = await (Boolean(id)
            ? axios.get(getBudgetByIdUrl, { params: { id, memberId } })
            : axios.get(`${API_URL}/member/getbudgetform`, { params: { memberId } }));
        response.data.Id = id;
        dispatch(_setMemberBudget(response.data));
        dispatch(_toggleIsFetching(false));
        dispatch(_toggleIsChanged(false));
    };

export const getMemberLastBudget =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const getBudgetByMemberIdUrl = `${API_URL}/member/getbudgetform`;
        const response = await axios.get(getBudgetByMemberIdUrl, { params: { memberId } });
        dispatch(_setMemberBudget(response.data));
        dispatch(_toggleIsFetching(false));
        dispatch(_toggleIsChanged(false));
    };

export const saveMemberBudget =
    (memberBudget: MemberBudget, isUpdateCreatedDate = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        const isUpdate = Boolean(memberBudget.Id);
        const response = await (isUpdate
            ? axios.put(`${API_URL}/member/updatememberbudget`, memberBudget, { params: { isUpdateCreatedDate } })
            : axios.put(`${API_URL}/member/insertmemberbudget`, memberBudget));

        const memberBudgetId = memberBudget.Id || response.data.Id;
        const engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
        engagementDraft.summaryNotes = engagementDraft.summaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.BudgetForm) || [];
        engagementDraft.summaryNotes.push({
            Id: memberBudgetId,
            CptCode: '',
            Zcode: '',
            Note: 'Completed Budget Form',
            Type: SummaryNoteTypeEnum.BudgetForm,
            IsConfirm: false,
            OrderBy: 31,
            DisplayName: 'Budget Form',
        });
        dispatch(saveEngagementDraft({ ...engagementDraft, BudgetFormId: memberBudgetId }, true));

        if (!isUpdate) {
            dispatch(_setMemberBudget(response.data));
        } else {
            dispatch(gotoNextMenu());
        }
        dispatch(_toggleIsSaving(false));
    };

export const saveMemberBudgetForOtherAssessment =
    (memberBudget: MemberBudget): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        const isUpdate = Boolean(memberBudget.Id);
        const response = await (isUpdate
            ? axios.put(`${API_URL}/member/updatememberbudget`, memberBudget)
            : axios.put(`${API_URL}/member/insertmemberbudget`, memberBudget));
        console.log('response for saveMemberBudgetForOtherAssessment : ', response.data);
        dispatch(_setMemberBudget(response.data));
        dispatch(_toggleIsSaving(false));
    };

export const setMemberBudget =
    (memberBudget: MemberBudget): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setMemberBudget(calculateExpenses(calculateIncome(memberBudget))));
        dispatch(_toggleIsChanged(true));
    };

const calculateIncome = (memberBudget: MemberBudget) => {
    memberBudget.TotalIncome = 0;
    memberBudget.Income.IncomeSources?.forEach((source) => {
        if (isNaN(source.Amount) || !source.Amount) {
            source.Amount = 0;
        }

        memberBudget.TotalIncome += parseInt(`${source.Amount}`);
    });

    memberBudget.Income.OtherSources?.forEach((source) => {
        if (isNaN(source.Amount) || !source.Amount) {
            source.Amount = 0;
        }

        memberBudget.TotalIncome += parseInt(`${source.Amount}`);
    });
    memberBudget.SpendingMoney = memberBudget.TotalIncome - memberBudget.TotalExpenses;
    return memberBudget;
};

const calculateExpenses = (memberBudget: MemberBudget) => {
    memberBudget.TotalExpenses = 0;
    // Rent
    memberBudget.RentExpenses.filter((r) => !r.IgnoreInCalculation)?.forEach((expense) => {
        if (isNaN(expense.Amount) || !expense.Amount) expense.Amount = 0;
        memberBudget.TotalExpenses += parseInt(`${expense.Amount}`);
    });

    // Utilities
    memberBudget.UtilityExpenses.filter((r) => !r.IgnoreInCalculation)?.forEach((expense) => {
        if (isNaN(expense.HighestMonthBill) || !expense.HighestMonthBill) expense.HighestMonthBill = 0;
        if (isNaN(expense.LowestMonthBill) || !expense.LowestMonthBill) expense.LowestMonthBill = 0;
        memberBudget.TotalExpenses += (parseInt(`${expense.HighestMonthBill}`) + parseInt(`${expense.LowestMonthBill}`)) / 2;
    });

    // Food Expense
    memberBudget.FoodExpense.Expenses.filter((r) => !r.IgnoreInCalculation)?.forEach((expense) => {
        if (isNaN(expense.Amount) || !expense.Amount) expense.Amount = 0;
        memberBudget.TotalExpenses += parseInt(`${expense.Amount}`);
    });

    // Medicines
    memberBudget.MedicineExpense.Expenses.filter((r) => !r.IgnoreInCalculation)?.forEach((expense) => {
        if (isNaN(expense.Amount) || !expense.Amount) expense.Amount = 0;
        memberBudget.TotalExpenses += parseInt(`${expense.Amount}`);
    });

    // Family expense
    memberBudget.FamilyExpenses.filter((r) => !r.IgnoreInCalculation)?.forEach((expense) => {
        if (isNaN(expense.Amount) || !expense.Amount) expense.Amount = 0;
        memberBudget.TotalExpenses += parseInt(`${expense.Amount}`);
    });

    // Household Expenses
    memberBudget.HouseHoldExpenses?.forEach((expense) => {
        if (isNaN(expense.Amount) || !expense.Amount) expense.Amount = 0;
        memberBudget.TotalExpenses += parseInt(`${expense.Amount}`);
    });

    if (isNaN(memberBudget.TotalExpenses)) memberBudget.TotalExpenses = 0;
    memberBudget.SpendingMoney = memberBudget.TotalIncome - memberBudget.TotalExpenses;
    return memberBudget;
};

export default memberBudgetSlice.reducer;
