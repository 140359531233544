import BaseEntity from '../BaseEntity.model';

export default interface IOnboardingZipCodeGroup extends BaseEntity {
    Id: string;
    GroupName: string;
    SelectedZipCodes: string[];
}

export class OnboardingZipCodeGroup implements IOnboardingZipCodeGroup {
    Id: string;
    GroupName: string;
    SelectedZipCodes: string[] | null;

    constructor() {
        this.Id = '';
        this.GroupName = '';
        this.SelectedZipCodes = null;
    }
}
