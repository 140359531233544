import { PaymentTypeEnum } from '../../Enum/PaymentTypeEnum';
import { TermOfContractEnum } from '../../Enum/TermOfContractEnum';
import BaseEntity from '../BaseEntity.model';
import Address from '../Shared/Address.model';
import Contact from '../Shared/Contact.model';
import OnboardingPeriod from '../Shared/OnboardingPeriod.model';
import Sftp from '../Shared/Sftp.model';
import HealthPlanSubType from './HealthPlanSubType.model';
import HealthPlanType from './HealthPlanType.model';
import ManagementWindow from './ManagementWindow.model';
import PlanType from './PlanType.model';
import Metrics from './Metrics.model';

export default interface IHealthPlan extends BaseEntity {
    Name: string;
    PlanId: string;
    Address: Address;
    PhoneNumber: string;
    PreAuthorizationNumber: string;
    OrganizationName: string;
    OrganizationId: string;
    IsDeleted: boolean;
    IsEnableAutomatedBillings: boolean;
    APContacts: Contact[] | null;
    ContractingContacts: Contact[] | null;
    CHPAccountRepresentative: string;
    HealthPlanType: HealthPlanType | null;
    HealthPlanSubType: HealthPlanSubType | null;
    PlanTypes: PlanType[] | null;
    DateOfContracts: Date | null;
    TermOfContract: TermOfContractEnum;
    TargetMembersVolume: number | null;
    PayRate: number | null;
    PaymentType: PaymentTypeEnum;
    PaymentDate: number | null;
    SharedSavings: boolean;
    SharedSavingsPercentage: number | null;
    OnboardingPeriod: OnboardingPeriod | null;
    ManagementWindow: ManagementWindow | null;
    ContactPerson: Contact[] | null;
    SftpAddress: Sftp[] | null;
    ConvertionRate: Metrics | null;
    HgbA1CRate: Metrics | null;
    BpSystolic: Metrics | null;
    BpDiastolic: Metrics | null;
    FollowupAfterHospitalDischarge: Metrics | null;
    ReadmissionRateReduction: Metrics | null;
    PhqRate: Metrics | null;
    SdohRate: Metrics | null;
    ReductionInEdVisits: Metrics | null;
    MedicationReconciliationAfterDischarge: Metrics | null;
    HasConvertionRate: boolean;
    HasHgbA1CRate: boolean;
    HasFollowupAfterHospitalDischarge: boolean;
    HasReadmissionRateReduction: boolean;
    HasPhqRate: boolean;
    HasSdohRate: boolean;
    HasReductionInEdVisits: boolean;
    HasMedicationReconciliationAfterDischarge: boolean;
    HasBpDiastolic: boolean;
    HasBpSystolic: boolean;
}

export default class HealthPlan implements IHealthPlan {
    Id: string;
    Name: string;
    PlanId: string;
    Address: Address;
    PhoneNumber: string;
    PreAuthorizationNumber: string;
    OrganizationName: string;
    OrganizationId: string;
    IsDeleted: boolean;
    IsEnableAutomatedBillings: boolean;
    APContacts: Contact[] | null;
    ContractingContacts: Contact[] | null;
    CHPAccountRepresentative: string;
    HealthPlanType: HealthPlanType | null;
    HealthPlanSubType: HealthPlanSubType | null;
    PlanTypes: PlanType[] | null;
    DateOfContract: Date | null;
    TermOfContract: TermOfContractEnum;
    TargetMembersVolume: number | null;
    PayRate: number | null;
    PaymentType: PaymentTypeEnum;
    PaymentDate: number | null;
    SharedSavings: boolean;
    SharedSavingsPercentage: number | null;
    OnboardingPeriod: OnboardingPeriod | null;
    ManagementWindow: ManagementWindow | null;
    ContactPerson: Contact[] | null;
    SftpAddress: Sftp[] | null;
    ConvertionRate: Metrics | null;
    HgbA1CRate: Metrics | null;
    BpSystolic: Metrics | null;
    BpDiastolic: Metrics | null;
    FollowupAfterHospitalDischarge: Metrics | null;
    ReadmissionRateReduction: Metrics | null;
    PhqRate: Metrics | null;
    SdohRate: Metrics | null;
    ReductionInEdVisits: Metrics | null;
    MedicationReconciliationAfterDischarge: Metrics | null;
    HasConvertionRate: boolean;
    HasHgbA1CRate: boolean;
    HasFollowupAfterHospitalDischarge: boolean;
    HasReadmissionRateReduction: boolean;
    HasPhqRate: boolean;
    HasSdohRate: boolean;
    HasReductionInEdVisits: boolean;
    HasMedicationReconciliationAfterDischarge: boolean;
    HasBpDiastolic: boolean;
    HasBpSystolic: boolean;

    constructor() {
        this.Id = '';
        this.Name = '';
        this.PlanId = '';
        this.Address = new Address();
        this.PhoneNumber = '';
        this.PreAuthorizationNumber = '';
        this.OrganizationName = '';
        this.OrganizationId = '';
        this.IsDeleted = false;
        this.IsEnableAutomatedBillings = false;
        this.APContacts = null;
        this.ContractingContacts = null;
        this.CHPAccountRepresentative = '';
        this.HealthPlanType = null;
        this.HealthPlanSubType = null;
        this.PlanTypes = null;
        this.DateOfContract = null;
        this.TermOfContract = 0;
        this.TargetMembersVolume = null;
        this.PayRate = null;
        this.PaymentType = 0;
        this.PaymentDate = null;
        this.SharedSavings = false;
        this.SharedSavingsPercentage = null;
        this.OnboardingPeriod = null;
        this.ManagementWindow = null;
        this.ContactPerson = null;
        this.SftpAddress = null;
        this.ConvertionRate = null;
        this.HgbA1CRate = null;
        this.BpSystolic = null;
        this.BpDiastolic = null;
        this.FollowupAfterHospitalDischarge = null;
        this.ReadmissionRateReduction = null;
        this.PhqRate = null;
        this.SdohRate = null;
        this.ReductionInEdVisits = null;
        this.MedicationReconciliationAfterDischarge = null;
        this.HasConvertionRate = false;
        this.HasHgbA1CRate = false;
        this.HasFollowupAfterHospitalDischarge = false;
        this.HasReadmissionRateReduction = false;
        this.HasPhqRate = false;
        this.HasSdohRate = false;
        this.HasReductionInEdVisits = false;
        this.HasMedicationReconciliationAfterDischarge = false;
        this.HasBpDiastolic = false;
        this.HasBpSystolic = false;
    }
}
