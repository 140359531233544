import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, IconButton, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Slide } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionProps } from '@mui/material/transitions';

import { RootState } from '../../../reducers';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { setMemberBudget } from '../../../store/memberBudget.slice';
import classes from '../Styles';
import { copyObject } from '../../../utils/common';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const MemberList = () => {
    const dispatch = useDispatch();
    const [openMembersModal, setOpenMembersModal] = React.useState(false);
    const [members, setMembers] = useState<string[]>([]);
    const [selectedMember, setSelectedMember] = useState<string>('');
    const [showDeleteMemberAlert, setShowDeleteMemberAlert] = useState<boolean>(false);
    const { memberBudget } = useSelector((state: RootState) => state.memberBudget);

    const handleCloseMembersModal = () => {
        setOpenMembersModal(false);
        setMembers([]);
    };

    const handleOnOpenMembersModal = () => {
        setMembers(memberBudget?.Income?.Members || []);
        setOpenMembersModal(true);
    };

    const handleOnAddNewMember = () => {
        setMembers([...members, `Member ${members.length > 0 ? members.length + 1 : ''}`]);
    };

    const handleOnConfirmDelete = () => {
        setMembers(members.filter((m) => m !== selectedMember));
        setShowDeleteMemberAlert(false);
    };

    const handleOnClickAddMember = () => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.Income.Members = members;
        dispatch(setMemberBudget(memberBudgetData));
        setOpenMembersModal(false);
        handleCloseMembersModal();
    };

    const handleOnChangeMemberName = (name: string, index: number) => {
        let membersData = copyObject(members);
        membersData[index] = name;
        setMembers(membersData);
    };

    return (
        <>
            <IconButton onClick={handleOnOpenMembersModal} sx={{ p: 0 }}>
                <InfoIcon />
            </IconButton>
            <Dialog
                open={openMembersModal}
                sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseMembersModal}
            >
                <DialogTitle>Members</DialogTitle>
                <DialogContent dividers sx={{ width: '350px' }}>
                    <Button variant="text" endIcon={<AddCircleOutlinedIcon />} onClick={handleOnAddNewMember}>
                        Add Member
                    </Button>
                </DialogContent>
                {members?.map((member, index) => (
                    <DialogContent key={`member-${index}`} dividers sx={{ width: '350px' }} style={{ borderTop: 0 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <TextField
                                fullWidth
                                size="small"
                                value={member}
                                label="Name"
                                variant="outlined"
                                onChange={(e) => handleOnChangeMemberName(e.target.value, index)}
                            />
                            <IconButton
                                onClick={() => {
                                    setSelectedMember(member);
                                    setShowDeleteMemberAlert(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    </DialogContent>
                ))}
                <DialogActions>
                    <Button variant="contained" disabled={members.some((m) => !Boolean(m))} onClick={handleOnClickAddMember}>
                        Add
                    </Button>
                    <Button variant="outlined" onClick={handleCloseMembersModal}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                open={showDeleteMemberAlert}
                showTop
                title="Delete Member"
                okText="Delete"
                onConfirm={handleOnConfirmDelete}
                onClose={() => setShowDeleteMemberAlert(false)}
                message="Are you sure want to delete this member?"
            />
        </>
    );
};

export default MemberList;
