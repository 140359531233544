import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { localDateFormat } from '../../../utils/timeFormat';
import { isDefaultDate } from '../../../utils/common';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import {
    setTransitionalCareFilter,
    setTransitionalCareRecentActivity,
    fetchTransitionalCareReport,
    exportTransitionalCareReport,
} from '../../../store/transitionalCareReport.slice';

const TransitionalCareReport = () => {
    const dispatch = useDispatch();

    const { transitionalCareReport, transitionalCareFilter, transitionalCareRecentActivity } = useSelector((state: RootState) => state.transitionalCareReport);
    const { downLoading } = useSelector((state: RootState) => state.shared);

    const columns: GridColDef[] = [
        {
            field: 'AHCCCSId',
            headerName: 'AHCCCS Id',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 110,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'TransitionStage',
            headerName: 'Transition Stage',
            minWidth: 160,
            flex: 1.5,
        },
        {
            field: 'AdmissionDate',
            headerName: 'Admission Date',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.AdmissionDate) ? '-' : localDateFormat(new Date(params.row.AdmissionDate))}`,
        },
        {
            field: 'CurrentFacility',
            headerName: 'Current Facility',
            minWidth: 200,
            flex: 2,
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.DischargeDate === null ? '-' : `${localDateFormat(new Date(params.row.DischargeDate))}`,
        },
        {
            field: 'AnticipatedDischargeDate',
            headerName: 'Anticipated DischargeDate',
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.AnticipatedDischargeDate === null ? '-' : `${localDateFormat(new Date(params.row.AnticipatedDischargeDate))}`,
        },
        {
            field: 'LastActivityNote',
            headerName: 'Last Activity Note',
            width: 350,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Box style={{ maxHeight: '140px', overflowY: 'scroll' }}>
                        <Typography variant="body2">{params.row.LastActivityNote}</Typography>
                    </Box>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(fetchTransitionalCareReport(transitionalCareFilter, transitionalCareRecentActivity));
    }, [dispatch, transitionalCareFilter, transitionalCareRecentActivity]);

    return (
        <Grid container>
            <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Transitional Care Report
                </Typography>
            </Box>
            {<Loading message={'Loading...'} />}
            <Grid item xs={12}>
                <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                    <FormControl sx={{ width: '300px' }}>
                        <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={transitionalCareFilter}
                            value={transitionalCareFilter}
                            label="Filter By"
                            onChange={(e) => {
                                dispatch(setTransitionalCareFilter(e.target.value));
                            }}
                        >
                            <MenuItem value={'1'}>Current Transitional Care</MenuItem>
                            <MenuItem value={'2'}>Past Transitional Care</MenuItem>
                            <MenuItem value={'3'}>Closed Transitional Care Without NP or BH Visit</MenuItem>
                        </Select>
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={() => dispatch(setTransitionalCareRecentActivity(!transitionalCareRecentActivity))} />}
                            label="Cases with no recent activity"
                        />
                    </FormGroup>

                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => dispatch(exportTransitionalCareReport(transitionalCareFilter, transitionalCareRecentActivity))}
                        variant="contained"
                        color="success"
                    >
                        {downLoading ? 'Downloading..' : 'Export'}
                    </Button>
                </Stack>
            </Grid>
            <Grid item style={{ height: '82vh', width: '100%' }}>
                <DataGrid
                    rows={transitionalCareReport}
                    columns={columns}
                    pageSize={11}
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => row.Id}
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TransitionalCareReport;
