import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Stack,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Divider,
    Typography,
    TextField,
    FormControl,
    CircularProgress,
    Grid,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { RootState } from '../../reducers';
import { addDischargePlan, toggleIsShowDischargePlanForm } from '../../store/transitionalCare.slice';
import { copyObject } from '../../utils/common';
import { DischargePlan } from '../../Models/TransitionalCare/DischargePlan.model';
import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';
import { PlannedDischargeLocationEnum } from '../../Enum/PlannedDischargeLocationEnum';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';

const dischargePlanSchema = Yup.object().shape({
    Note: Yup.string().nullable().required('Note is required.'),
});

const DischargePlanComponent: React.FC<{ transitionStep: TransitionStep; isAssessment: boolean }> = ({ transitionStep, isAssessment }) => {
    const [initialData] = useState(new DischargePlan());
    const dispatch = useDispatch();
    const { isShowDischargePlanForm, plannedDischargeLocations } = useSelector((state: RootState) => state.transitionalCare);
    const { engagementDraft, isSaving } = useSelector((state: RootState) => state.documentEngagement);

    const dischargePlans = copyObject(transitionStep?.DischargePlans || []).reverse();

    const handleOnSubmit = (values) => {
        if (isAssessment) {
            let data = copyObject(engagementDraft);
            data['PlanDischargeLocation'] = true;
            dispatch(saveEngagementDraft(data));
        }
        dispatch(addDischargePlan(values));
    };

    const handleOpen = () => dispatch(toggleIsShowDischargePlanForm(true));
    const handleClose = () => dispatch(toggleIsShowDischargePlanForm(false));

    const RenderModalForm = () => {
        return (
            <Dialog open={isShowDischargePlanForm} onClose={handleClose} fullWidth maxWidth={'sm'}>
                <DialogTitle>Add Discharge Plan</DialogTitle>
                <DialogContent>
                    <Formik initialValues={initialData} onSubmit={handleOnSubmit} validationSchema={dischargePlanSchema} enableReinitialize={true}>
                        {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<DischargePlan>) => (
                            <Form id="discharge-plan-form">
                                <Grid container spacing={2} sx={{ marginY: 1 }}>
                                    <Grid item md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                label="Anticipated Discharge Date"
                                                value={values.AnticipatedDate ? new Date(values.AnticipatedDate) : null}
                                                inputFormat="MM/dd/yyyy"
                                                minDate={new Date('2017-01-01')}
                                                onChange={(date) => {
                                                    if (moment(date).isValid() || !Boolean(date)) {
                                                        const newDate = moment(date).format('YYYY-MM-DD') + 'T12:00:00.000Z';
                                                        setFieldValue('AnticipatedDate', newDate);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField fullWidth {...params} error={Boolean(errors?.AnticipatedDate) && Boolean(submitCount)} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="location-type-select">Planned Discharge Location</InputLabel>
                                            <Select
                                                labelId="location-type-select"
                                                id="location-type-select"
                                                value={Boolean(values.PlannedDischargeLocation) ? values.PlannedDischargeLocation : 0}
                                                label="Planed Discharge Location"
                                                name="PlannedDischargeLocation"
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled value={0}>
                                                    Select Location
                                                </MenuItem>
                                                {plannedDischargeLocations.map((type) => (
                                                    <MenuItem key={type.Id} value={String(type.Id)}>
                                                        {type.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <FormControl fullWidth={true} className="mt-10" error={Boolean(errors?.Note) && Boolean(submitCount)}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        required
                                        label="Description"
                                        variant="outlined"
                                        rows={6}
                                        value={values.Note}
                                        name="Note"
                                        onChange={handleChange}
                                        error={Boolean(errors?.Note) && Boolean(submitCount)}
                                        helperText={errors?.Note && submitCount ? errors?.Note : 'Describe your discharge plan.'}
                                    />
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button type="button" onClick={handleClose} disabled={isSaving}>
                        Cancel
                    </Button>
                    <Button
                        form="discharge-plan-form"
                        type="submit"
                        disabled={isSaving}
                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                    >
                        Save & Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            <RenderModalForm />
            <Button disabled={!isAssessment} variant="outlined" color="primary" fullWidth sx={{ p: 2, marginBottom: 2 }} onClick={handleOpen}>
                Add Discharge Plan
            </Button>
            {!isAssessment && (
                <>
                    <Typography textAlign={'center'} variant="body2" color={'red'} sx={{ marginBottom: 2 }}>
                        You must enter Discharge Plans in the Assessment section.
                    </Typography>
                </>
            )}
            {dischargePlans?.map((dischargePlan: DischargePlan, index: number) => {
                const { AnticipatedDate, Note, PlannedDischargeLocation } = dischargePlan;
                const location = PlannedDischargeLocationEnum[PlannedDischargeLocation] || '-';
                const lastItem = dischargePlans.length - 1 === index;
                return (
                    <Stack key={dischargePlan.Id} sx={{ width: '100%', p: 2 }} direction="row">
                        <Grid item xs={4}>
                            <Typography variant="body1" fontWeight={500}>
                                {location}
                            </Typography>
                            <Typography variant="body2" color={'text.secondary'}>
                                {AnticipatedDate ? moment(AnticipatedDate).format('MM/DD/yyyy') : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1" fontWeight={500}>
                                {Note}
                            </Typography>
                            <Typography variant="body2" color={'text.secondary'}>
                                {dischargePlan.CreatedBy?.Name} - {moment(dischargePlan.CreatedDate).format('DD MMM YYYY')}
                            </Typography>
                        </Grid>
                        {!lastItem && <Divider sx={{ marginTop: 3 }} />}
                    </Stack>
                );
            })}
        </>
    );
};

export default DischargePlanComponent;
