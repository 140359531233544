import * as React from 'react';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';

const classes = {
    bottomDialog: {
        '& .MuiDialog-container': {
            alignItems: 'flex-end',

            '& .MuiPaper-root': {
                marginBottom: '25%',
            },
        },
    },
    topScrollPaper: {
        '&.MuiDialog-container.MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
        },
    },
};

export interface IAlertDialogProps {
    open?: boolean;
    onClose?: Function;
    onConfirm?: Function;
    onSkip?: Function;
    title?: any;
    message?: string;
    cancelText?: string;
    okText?: string;
    skipText?: string;
    skipButtonVariant?: 'text' | 'outlined' | 'contained';
    hideCancelButton?: boolean;
    isLoading?: boolean;
    autoHideOnConfirmed?: boolean;
    buttonsDirection?: string;
    showBottomCenter?: boolean;
    showTop?: boolean;
    disabledSubmit?: boolean;
    renderBody?: Function;
    bottomButtonCenter?: boolean;
    okButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const AlertDialog: React.FC<IAlertDialogProps> = ({
    open = false,
    onClose,
    onConfirm,
    onSkip,
    title,
    message,
    cancelText = 'Cancel',
    okText = 'Ok',
    skipText = 'Skip and continue',
    skipButtonVariant = 'outlined',
    hideCancelButton = false,
    isLoading = false,
    autoHideOnConfirmed: autoHideOnCofirmed = false,
    buttonsDirection = 'row',
    showBottomCenter = false,
    showTop = false,
    disabledSubmit = false,
    renderBody = null,
    okButtonColor = 'primary',
    bottomButtonCenter = false,
}) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOnSkip = () => {
        if (onSkip) {
            onSkip();
        }
        handleClose();
    };

    const handleOnConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }

        if (autoHideOnCofirmed) {
            handleClose();
        }
    };

    return (
        <Dialog
            style={{ minWidth: '100px' }}
            open={open}
            fullWidth
            onClose={handleClose}
            sx={[
                { scrollPaper: showTop ? classes.topScrollPaper : null },
                showBottomCenter ? classes.bottomDialog : null,
                { '& .MuiDialog-container': { '& .MuiPaper-root': { width: 'auto' } } },
            ]}
        >
            {title ? (
                <DialogTitle id="alert-dialog-title" alignItems="center" display="flex">
                    {title}{' '}
                    {isLoading ? (
                        <Stack marginLeft={2}>
                            <CircularProgress size={20} />
                        </Stack>
                    ) : null}
                </DialogTitle>
            ) : null}
            <DialogContent>{renderBody ? renderBody() : <DialogContentText id="alert-dialog-description">{message}</DialogContentText>}</DialogContent>
            <DialogActions>
                <div style={{ width: '100%' }}>
                    <Stack direction={buttonsDirection as any} alignItems="center" justifyContent={bottomButtonCenter ? 'center' : 'flex-end'} spacing={2}>
                        {!hideCancelButton && (
                            <Button onClick={handleClose} variant="outlined" className="text-transform-none" disabled={isLoading}>
                                {cancelText}
                            </Button>
                        )}
                        {onSkip && (
                            <Button onClick={handleOnSkip} variant={skipButtonVariant} className="text-transform-none" disabled={isLoading}>
                                {skipText}
                            </Button>
                        )}
                        <Button
                            onClick={handleOnConfirm}
                            variant="contained"
                            color={okButtonColor}
                            className="text-transform-none"
                            disabled={isLoading || disabledSubmit}
                            endIcon={isLoading && !Boolean(title) ? <CircularProgress size={18} /> : null}
                        >
                            {okText}
                        </Button>
                    </Stack>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
