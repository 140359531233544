import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

import { getLastLipidAssessment, getLipidAssessmentById, gotoNextMenu, gotoPreviousMenu, saveLipidAssessment } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import { copyObject } from '../../../utils/common';
import { getActiveProviders } from '../../../store/provider.slice';
import { ProviderPreview } from '../../../Models/CarePlans/SchedulePlan.model';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';

const classes = {
    formControl: {
        marginBottom: '25px',
        padding: '0!important',
    },
};

const LipidAssessmentComponent: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { lipidAssessment, isFetching, isSaving } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { npProviders } = useSelector((state: RootState) => state.provider);

    useEffect(() => {
        if (Boolean(engagementDraft.LipidAssessmentId)) {
            dispatch(getLipidAssessmentById(engagementDraft.LipidAssessmentId));
        } else {
            dispatch(getLastLipidAssessment(memberId));
        }
        dispatch(getActiveProviders(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleOnChangeInput = (value: any, propsName: string, saveLocal = false) => {
        let data = copyObject(lipidAssessment);
        data[propsName] = value;
        dispatch(saveLipidAssessment({ ...data, MemberId: memberId }, saveLocal));
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveLipidAssessment({ ...lipidAssessment, Status: StatusEnum.Completed }, false, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const checkTotalCholestrolOutofRange = () => {
        return Boolean(lipidAssessment.TotalCholestrol) && (Number(lipidAssessment.TotalCholestrol) < 100 || Number(lipidAssessment.TotalCholestrol) > 400);
    };

    const checkHDLCholestrolOutofRange = () => {
        return Boolean(lipidAssessment.HDLCholestrol) && (Number(lipidAssessment.HDLCholestrol) < 20 || Number(lipidAssessment.HDLCholestrol) > 120);
    };

    const checkTriglyceridesOutofRange = () => {
        return Boolean(lipidAssessment.Triglycerides) && (Number(lipidAssessment.Triglycerides) < 50 || Number(lipidAssessment.Triglycerides) > 500);
    };

    const checkEGluOutofRange = () => {
        return Boolean(lipidAssessment.eGlu) && (Number(lipidAssessment.eGlu) < 40 || Number(lipidAssessment.eGlu) > 600);
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching || isLoading ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item lg={12} md={12} sm={12}>
                        {/* <h6>Last Completed :-</h6> */}
                    </Grid>
                    <Grid item className="content-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    Member Information
                                </Typography>
                                <Box sx={classes.formControl}>
                                    <TextField label="Member Name" variant="outlined" size="small" fullWidth disabled value={lipidAssessment.MemberName} />
                                </Box>
                                <Box sx={classes.formControl}>
                                    <DesktopDatePicker
                                        label="DOB"
                                        disabled
                                        value={Boolean(lipidAssessment.DOB) ? new Date(lipidAssessment.DOB) : null}
                                        inputFormat="MM/dd/yyyy"
                                        onChange={(date) => {}}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Box>
                                <Box sx={classes.formControl}>
                                    <TextField label="Age" variant="outlined" size="small" fullWidth disabled value={String(lipidAssessment.Age)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    Test Information
                                </Typography>
                                <Grid container spacing={2} rowGap={1.2}>
                                    <Grid item xs={12}>
                                        <DesktopDatePicker
                                            label="Date of Test"
                                            value={Boolean(lipidAssessment.TestDate) ? moment(lipidAssessment.TestDate).toDate() : null}
                                            inputFormat="MM/dd/yyyy"
                                            onChange={(date) => {
                                                if (moment(date).isValid() || !Boolean(date)) {
                                                    handleOnChangeInput(date.toISOString(), 'TestDate');
                                                }
                                            }}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="provider-type-select">Providers Name</InputLabel>
                                            <Select
                                                labelId="provider-type-select"
                                                id="provider-type-select"
                                                value={Boolean(lipidAssessment.Provider?.Id) ? String(lipidAssessment.Provider.Id) : ''}
                                                label="Providers Name"
                                                name="Provider"
                                                onChange={(e) => {
                                                    const selectedProvider = npProviders.find((p) => p.Id === e.target.value);
                                                    handleOnChangeInput(
                                                        {
                                                            Id: selectedProvider.Id,
                                                            Name: selectedProvider.FullName,
                                                            Role: selectedProvider.Role?.RoleName,
                                                        } as ProviderPreview,
                                                        e.target.name
                                                    );
                                                }}
                                            >
                                                {npProviders.map((provider) => (
                                                    <MenuItem key={provider.Id} value={provider.Id}>
                                                        {provider.FullName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled
                                            label="Collectors Name"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={lipidAssessment.Collector?.Name || ''}
                                            name="LotNumTestCartridge"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm>
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    Result Of Analysis
                                </Typography>

                                <Grid container spacing={2} rowGap={1.2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Total Cholestrol (mg/dL)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    name="TotalCholestrol"
                                                    value={lipidAssessment.TotalCholestrol}
                                                    onChange={(e) => handleOnChangeInput(e.target.value, e.target.name, true)}
                                                    onBlur={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                                                    error={lipidAssessment.IsTotalCholestrolOutOfRange || checkTotalCholestrolOutofRange()}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    sx={{ mr: 0, mb: 0 }}
                                                    control={
                                                        <Checkbox
                                                            checked={lipidAssessment.IsTotalCholestrolOutOfRange}
                                                            name="IsTotalCholestrolOutOfRange"
                                                            onChange={(e) => handleOnChangeInput(e.target.checked, e.target.name)}
                                                        />
                                                    }
                                                    label="Out of range"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} rowGap={0}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="HDL Cholestrol (mg/dL)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    name="HDLCholestrol"
                                                    value={lipidAssessment.HDLCholestrol}
                                                    onChange={(e) => handleOnChangeInput(e.target.value, e.target.name, true)}
                                                    onBlur={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                                                    error={lipidAssessment.IsHDLCholestrolOutOfRange || checkHDLCholestrolOutofRange()}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    sx={{ mr: 0, mb: 0 }}
                                                    control={
                                                        <Checkbox
                                                            checked={lipidAssessment.IsHDLCholestrolOutOfRange}
                                                            name="IsHDLCholestrolOutOfRange"
                                                            onChange={(e) => handleOnChangeInput(e.target.checked, e.target.name)}
                                                        />
                                                    }
                                                    label="Out of range"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Triglycerides (mg/dL)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    name="Triglycerides"
                                                    value={lipidAssessment.Triglycerides}
                                                    onChange={(e) => handleOnChangeInput(e.target.value, e.target.name, true)}
                                                    onBlur={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                                                    error={lipidAssessment.IsTriglyceridesOutOfRange || checkTriglyceridesOutofRange()}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    sx={{ mr: 0, mb: 0 }}
                                                    control={
                                                        <Checkbox
                                                            checked={lipidAssessment.IsTriglyceridesOutOfRange}
                                                            name="IsTriglyceridesOutOfRange"
                                                            onChange={(e) => handleOnChangeInput(e.target.checked, e.target.name)}
                                                        />
                                                    }
                                                    label="Out of range"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="eGLU (mg/dL)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                    name="eGlu"
                                                    value={lipidAssessment.eGlu}
                                                    onChange={(e) => handleOnChangeInput(e.target.value, e.target.name, true)}
                                                    onBlur={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                                                    error={lipidAssessment.IseGluOutOfRange || checkEGluOutofRange()}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    sx={{ mr: 0, mb: 0 }}
                                                    control={
                                                        <Checkbox
                                                            checked={lipidAssessment.IseGluOutOfRange}
                                                            name="IseGluOutOfRange"
                                                            onChange={(e) => handleOnChangeInput(e.target.checked, e.target.name)}
                                                        />
                                                    }
                                                    label="Out of range"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.LipidAssessmentId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Lipid Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Lipid assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Lipid Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={handleOnConfirmToNext}
                    onClose={() => setShowSaveAlert(false)}
                    message="Are you sure to proceed without completing Lipid assessment?"
                />
            )}
        </Grid>
    );
};

export default LipidAssessmentComponent;
