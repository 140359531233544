import BaseEntity from '../BaseEntity.model';

export default interface IConsentFormDocument extends BaseEntity {
    MemberId: string;
    File: File;
    DocumentSignedOn: string;
    Name: string;
    FileName: string;
    DocumentCategory: string;
    IsDocumentPreview: boolean;
    LastUpdationTime: string;
}

export class ConsentFormDocument implements IConsentFormDocument {
    Id: string;
    MemberId: string;
    File: File;
    DocumentSignedOn: string;
    Name: string;
    FileName: string;
    DocumentCategory: string;
    IsDocumentPreview: boolean;
    AzureDocumentUri: string;
    LastUpdationTime: string;
    IsExpired: boolean;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.File = null;
        this.DocumentSignedOn = '';
        this.Name = '';
        this.FileName = '';
        this.DocumentCategory = '';
        this.IsDocumentPreview = false;
        this.AzureDocumentUri = '';
        this.LastUpdationTime = '';
        this.IsExpired = false;
    }
}
