import { useEffect, useState, useRef } from 'react';
import { Paper, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'simplebar/dist/simplebar.min.css';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import Authorization from '../../../utils/Authorization';
import { fetchCarePlanNotReviewedMembersChart } from '../WidgetService';
import MemberPreview from '../../../Models/Member/MemberPreview.model';
import '../Widgets.css';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import WidgetLoading from '../../SmartDashboard/Shared/WidgetLoading';
import { parentUrl } from '../../../utils/constants';
import { MissingCarePlanSignatureTypeEnum } from '../../../Enum/MIssingCarePlanSignatureTypeEnum';

const CarePlanNotReviewedSummary = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [members, setMembers] = useState<MemberPreview[]>([]);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    useEffect(() => {
        setMembers([]);
        setIsFetched(false);
        const changeFilter = async () => {
            setIsFetched(false);
            const membersInfo = await fetchCarePlanNotReviewedMembersChart(selectedTeam, showInActiveTeams);
            setMembers(membersInfo);
            setIsFetched(true);
        };
        changeFilter();
    }, [selectedTeam, showInActiveTeams]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 140,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 140,
        },
        {
            field: 'Population',
            headerName: 'Health Plan',
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'MissingCarePlanSignatureType',
            headerName: 'Missing Signature',
            minWidth: 140,
            renderCell: (params) => {
                return MissingCarePlanSignatureTypeEnum[params.row.MissingCarePlanSignatureType];
            },
        },
    ];
    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                        Care Plan Not Reviewed in 90 Days
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="caption">
                                        Active members whose care plan has not been reviewed with the member in the last 90 days
                                    </Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    {authData.Role === 'CEO' ? (
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select
                                value={selectedTeam}
                                label="Team"
                                name="Team"
                                onChange={(e) => setSelectedTeam(e.target.value)}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <div style={{ width: '100%', height: 'Calc(70vh - 135px)', position: 'relative' }}>
                        {!isFetched && <WidgetLoading />}
                        <DataGrid
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/integrated-care-plan`, '_blank');
                            }}
                            rows={members}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5, 15, 25]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                '& .is-late': {
                                    color: 'red',
                                },
                                '& .is-in-progress': {
                                    color: 'green',
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default CarePlanNotReviewedSummary;
