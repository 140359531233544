import BaseEntity from '../BaseEntity.model';
import HealthPlanPreview from '../HealthPlan/HealthPlanPreview.model';
import Contact from '../Shared/Contact.model';
import { NotificationFrequencyEnum } from '../../Enum/NotificationFrequencyEnum';

export default interface IPopulation extends BaseEntity {
    PopulationNumber: number;
    PopulationName: string;
    OnboardingActive: boolean;
    OnboardedIndividualsGoal?: number;
    OnboardingStartDate?: Date | null;
    OnboardingDeadlineDate?: Date | null;
    HealthPlan: HealthPlanPreview | null;
    FileNotReceivedInDays: NotificationFrequencyEnum;
    AlertContacts: Contact[] | null;
    IsHra: boolean;
    IsFuhFum: boolean;
    IsSUD: boolean;
}

export default class Population implements IPopulation {
    PopulationNumber: number;
    PopulationName: string;
    IsActive: boolean;
    OnboardedIndividualsGoal?: number;
    OnboardingStartDate?: Date | null;
    OnboardingDeadlineDate?: Date | null;
    HealthPlan: HealthPlanPreview | null;
    FileNotReceivedInDays: NotificationFrequencyEnum;
    AlertContacts: Contact[] | null;
    IsHra: boolean;
    IsFuhFum: boolean;
    IsSUD: boolean;

    constructor() {
        this.PopulationNumber = 0;
        this.PopulationName = '';
        this.IsActive = false;
        this.OnboardedIndividualsGoal = 0;
        this.OnboardingStartDate = null;
        this.OnboardingDeadlineDate = null;
        this.HealthPlan = null;
        this.FileNotReceivedInDays = 0;
        this.AlertContacts = null;
        this.IsHra = false;
        this.IsFuhFum = false;
        this.IsSUD = false;
    }
}
