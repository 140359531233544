import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

import { MemberEducationModel, MemberEducationsByType } from '../Models/MemberEducations/MemberEducation.model';
import { copyObject } from '../utils/common';
import { EducationSessionCategory } from '../Models/MemberEducations/EducationSessionCategory.model';

const memberEducation = createSlice({
    name: 'memberEducation',
    initialState: {
        memberEducation: new MemberEducationModel(),
        selectedMemberEducation: new MemberEducationModel(),
        memberEducationsByType: [] as MemberEducationsByType[],
        categories: [] as EducationSessionCategory[],
        memberEducationIdForDelete: '',
        showEducationSessionModal: false,
        showDeleteConfimation: false,
        isDeleting: false,
        isFetching: false,
        isSaving: false,
    },
    reducers: {
        _setMemberEducation(state, action) {
            state.memberEducation = action.payload;
        },
        _setSelectedMemberEducation(state, action) {
            state.selectedMemberEducation = action.payload;
        },
        _setMemberEducationsByType(state, action) {
            state.memberEducationsByType = action.payload;
        },
        _setCategories(state, action) {
            state.categories = action.payload?.filter((c: EducationSessionCategory) => c.Options?.length) || [];
        },
        _setMemberEducationIdForDelete(state, action) {
            state.memberEducationIdForDelete = action.payload;
        },
        _toggleShowEducationSessionModal(state, action) {
            state.showEducationSessionModal = action.payload;
        },
        _toggleShowDeleteConfimation(state, action) {
            state.showDeleteConfimation = action.payload;
        },
        _toggleIsDeleting(state, action) {
            state.isDeleting = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
    },
});

const {
    _setMemberEducation,
    _setSelectedMemberEducation,
    _setMemberEducationsByType,
    _setCategories,
    _setMemberEducationIdForDelete,
    _toggleShowEducationSessionModal,
    _toggleShowDeleteConfimation,
    _toggleIsDeleting,
    _toggleIsFetching,
    _toggleIsSaving,
} = memberEducation.actions;

export const getMemberEducationList =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/membereducation/getmembereducationlist`, { params: { memberId } })
            .then((response) => {
                dispatch(_setMemberEducationsByType(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const getMemberEducationById =
    (id: string): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/membereducation/getmembereducationbyid`, { params: { id } })
            .then((response) => {
                dispatch(_setMemberEducation(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveMemberEducation =
    (memberEducation: MemberEducationModel, callBack?: Function): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleIsSaving(true));
        const request = Boolean(memberEducation.Id)
            ? axios.put(`${API_URL}/membereducation/updatemembereducation`, memberEducation)
            : axios.post(`${API_URL}/membereducation/insertmembereducation`, memberEducation);

        request
            .then((response) => {
                dispatch(getMemberEducationList(memberEducation.MemberId));
                dispatch(_toggleShowEducationSessionModal(false));
                if (Boolean(callBack)) {
                    callBack(response.data);
                }
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const deleteMemberEducation =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsDeleting(true));
        axios
            .get(`${API_URL}/membereducation/deletemembereducation`, { params: { id } })
            .then(() => {
                let memberEducationsByType = copyObject(getState().memberEducation.memberEducationsByType as MemberEducationsByType[])?.map(
                    (listByType: MemberEducationsByType) => {
                        listByType.Values = listByType.Values.filter((v) => v.Id !== id);

                        return listByType;
                    }
                );
                dispatch(_setMemberEducationsByType(memberEducationsByType));
                dispatch(toggleShowEducationDeleteConfimation(false));
            })
            .finally(() => {
                dispatch(_toggleIsDeleting(false));
            });
    };

export const getEducationSessionCategories = (): AppThunk => async (dispatch) => {
    dispatch(_toggleIsFetching(true));
    axios
        .get(`${API_URL}/membereducation/geteducationsessioncategories`)
        .then((response) => {
            dispatch(_setCategories(response.data));
        })
        .finally(() => {
            dispatch(_toggleIsFetching(false));
        });
};

export const updateMterialDetail =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        axios.get(`${API_URL}/membereducation/updatematerialdetail`, { params: { memberId } });
    };

export const setSelectedMemberEducation =
    (memberEducation: MemberEducationModel): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedMemberEducation(memberEducation));
    };

export const toggleShowEducationSessionModal =
    (show: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleShowEducationSessionModal(show));
    };

export const toggleShowEducationDeleteConfimation =
    (show: boolean, memberEducationId = ''): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleShowDeleteConfimation(show));
        dispatch(_setMemberEducationIdForDelete(memberEducationId));
    };

export default memberEducation.reducer;
