import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { localDateFormat } from '../../../utils/timeFormat';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import { parentUrl } from '../../../utils/constants';
import { useEffect, useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';
import {
    setStartDate,
    setEndDate,
    setType,
    setPriority,
    fetchMemberVitalSignsReport,
    setDeviceType,
    exportMemberVitalSignsReport,
} from '../../../store/memberVitalSigns.slice';
import { isDefaultDate } from '../../../utils/common';

const MemberVitalSignsReport = () => {
    const { startDate, endDate, type, rowData, priority, deviceType } = useSelector((state: RootState) => state.memberVitalSigns);
    const { downLoading } = useSelector((state: RootState) => state.shared);
    const dispatch = useDispatch();

    const boxStyles = {
        display: 'flex',
        width: '70px',
        height: '70px',
        border: '2px solid black',
        flexDirection: 'column',
        borderRadius: '12px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const filterAllModel = {
        BpSystolic: true,
        BpPulse: true,
        PulseOxSpO2Last: true,
        Weight: true,
        WeightGain: false,
        GlucoseMgDl: true,
        CollectionType: false,
    };

    const filterGlucometerModel = {
        ...filterAllModel,
        BpSystolic: false,
        BpPulse: false,
        PulseOxSpO2Last: false,
        Weight: false,
        CollectionType: true,
    };

    const filterBPModel = {
        ...filterGlucometerModel,
        BpSystolic: true,
        BpPulse: true,
        GlucoseMgDl: false,
    };
    const filterOximeterModel = {
        ...filterBPModel,
        BpSystolic: false,
        BpPulse: false,
        PulseOxSpO2Last: true,
    };
    const filterWeightModel = {
        ...filterOximeterModel,
        PulseOxSpO2Last: false,
        WeightGain: true,
        Weight: true,
    };

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(filterAllModel);

    const changeHeaders = () => {
        if (type === '0') {
            setColumnVisibilityModel(filterAllModel);
        }
        if (type === '1') {
            setColumnVisibilityModel(filterGlucometerModel);
            dispatch(setDeviceType('Glucometer'));
        }
        if (type === '2' || type === '5') {
            setColumnVisibilityModel(filterBPModel);
            dispatch(setDeviceType('BP'));
        }
        if (type === '3') {
            setColumnVisibilityModel(filterOximeterModel);
            dispatch(setDeviceType('Oximeter'));
        }
        if (type === '4') {
            setColumnVisibilityModel(filterWeightModel);
            dispatch(setDeviceType('Scale'));
        }
    };

    const filterTypes = [
        { value: '0', label: 'All' },
        { value: '1', label: 'Glucometer' },
        { value: '2', label: 'Blood Pressure' },
        { value: '3', label: 'Oximeter' },
        { value: '4', label: 'Scale' },
        { value: '5', label: 'Heart Rate' },
    ];

    const borderColor = (params) => {
        return params.row.Status === 1 ? 'var(--success)!important' : params.row.Status === 2 ? 'var(--danger)!important' : '';
    };

    const columns: GridColDef[] = [
        {
            field: 'BpSystolic',
            headerName: 'Blood Pressure',
            width: 160,
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.row.BpSystolic ? (
                    <Box sx={boxStyles} borderColor={borderColor(params)}>
                        <Typography variant="subtitle2">{params.row.BpSystolic}</Typography>
                        <Divider sx={{ border: '1px solid #222', width: '80%' }} />
                        <Typography variant="subtitle2">{params.row.BpDiastolic}</Typography>
                    </Box>
                ) : (
                    '-'
                );
            },
        },
        {
            field: 'GlucoseMgDl',
            headerName: 'Glucometer',
            width: 160,
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.row.GlucoseMgDl ? (
                    <Box sx={boxStyles} borderColor={borderColor(params)}>
                        <Typography variant="subtitle2">{params.row.GlucoseMgDl}</Typography>
                        <Typography variant="subtitle2">mg/dL</Typography>
                    </Box>
                ) : (
                    '-'
                );
            },
        },
        {
            field: 'PulseOxSpO2Last',
            headerName: 'Oximeter',
            width: 160,
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.row.PulseOxSpO2Last ? (
                    <Box sx={boxStyles} borderColor={borderColor(params)}>
                        <Typography variant="subtitle2">{params.row.PulseOxSpO2Last}%</Typography>
                    </Box>
                ) : (
                    '-'
                );
            },
        },
        {
            field: 'Weight',
            headerName: 'Weight',
            width: 160,
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.row.Weight ? (
                    <Box sx={boxStyles} borderColor={borderColor(params)}>
                        <Typography variant="subtitle2">{params.row.Weight === null ? 'N/A' : params.row.Weight} lbs</Typography>
                    </Box>
                ) : (
                    '-'
                );
            },
        },
        {
            field: 'BpPulse',
            headerName: 'Heart Rate',
            minWidth: 110,
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.row.BpPulse ? (
                    <Typography color={borderColor(params)} variant="subtitle2">
                        {params.row.BpPulse}
                    </Typography>
                ) : (
                    '-'
                );
            },
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            minWidth: 250,
        },
        {
            field: 'WeightGain',
            headerName: 'Weight Gain',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${params.row.WeightGain ? params.row.WeightGain : 'N/A'} lbs`,
        },
        {
            field: 'CollectionType',
            headerName: 'Device',
            minWidth: 160,
            renderCell: (params: GridRenderCellParams<string>) => {
                return <Typography variant="subtitle2">{deviceType}</Typography>;
            },
        },
        {
            field: 'RecordingDate',
            headerName: 'Recording Date',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.RecordingDate) ? '-' : localDateFormat(new Date(params.row.RecordingDate))}`,
        },
    ];

    useEffect(() => {
        dispatch(fetchMemberVitalSignsReport(startDate, endDate, type, priority));
        changeHeaders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, priority, dispatch]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
                <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                    <Typography fontWeight="fontWeightMedium" component="h3">
                        Member Vital Signs Report
                    </Typography>
                </Box>
                {<Loading message={'Loading...'} />}
                <Grid item xs={12} md={6}>
                    <Stack direction="row" alignItems="center" marginY={2} spacing={3}>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="Start Date:"
                                value={startDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setStartDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="End Date:"
                                value={endDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setEndDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <Button
                            style={{ minWidth: '120px', marginRight: 'auto' }}
                            onClick={() => {
                                dispatch(fetchMemberVitalSignsReport(startDate, endDate, type, priority));
                                changeHeaders();
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Search
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction="row" alignItems="center" marginY={2} spacing={3}>
                        <FormControl className="form-control-200">
                            <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={type}
                                value={type}
                                label="Filter By"
                                onChange={(e) => {
                                    dispatch(setType(e.target.value));
                                }}
                            >
                                {filterTypes.map((filter, index) => (
                                    <MenuItem value={filter.value} key={index}>
                                        {filter.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control-200" sx={{ marginX: '24px!important' }}>
                            <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={priority}
                                value={priority}
                                disabled={type === '0'}
                                label="Sort By"
                                onChange={(e) => {
                                    dispatch(setPriority(e.target.value));
                                }}
                            >
                                <MenuItem value={'1'}>Low to High</MenuItem>
                                <MenuItem value={'3'}>High to Low</MenuItem>
                            </Select>
                        </FormControl>

                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => dispatch(exportMemberVitalSignsReport(startDate, endDate, type, priority))}
                            variant="contained"
                            color="success"
                        >
                            {downLoading ? 'Downloading..' : 'Export'}
                        </Button>
                    </Stack>
                </Grid>
                <Grid container style={{ height: '80vh', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                        onCellClick={(params: any) => {
                            window.open(`${parentUrl}provider/members-list/member-chart/${params.row.MemberId}/vitalsigns`, '_blank');
                        }}
                        rows={rowData}
                        columns={columns}
                        pageSize={11}
                        rowsPerPageOptions={[5]}
                        getRowId={() => Math.random()}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default MemberVitalSignsReport;
