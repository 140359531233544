import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { IRequestTimelineEvent } from '../interfaces/IRequestTimelineEvent';
import RequestTimeline from './RequestTimeline';

const RequestTimelineModal: React.FC<{ open: boolean; onClose: () => void; timelineEvents: IRequestTimelineEvent[] }> = (props) => {
    const { open, onClose, timelineEvents } = props;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Request Timeline</DialogTitle>
            <DialogContent>
                <RequestTimeline TimelineEvents={timelineEvents} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => onClose()}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestTimelineModal;
