const useStyles = {
    container: {
        padding: '20px',
    },
    paper: {
        minHeight: '100px',
        padding: '20px',
    },
    tableContainer: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        marginTop: '20px',

        '& tbody tr td': {
            color: '#999',
            paddingTop: '6px',
            paddingBottom: '6px',
            fontSize: '16px',
        },

        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
    scrollTopButton: {
        position: 'fixed !important' as any,
        right: '2%',
        bottom: '3%',
    },
    modalPopupForm: {
        top: '15vh',
        left: '50%',
        width: '400px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',

        '& .mui-modal-header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',
        },

        '& .mui-modal-body': {
            padding: '15px 15px 25px',
            maxHeight: 'calc(100vh - 30vh)',
            overflow: 'auto',

            '& .MuiFormLabel-root:not(.MuiInputLabel-root)': {
                color: 'black',
                fontWeight: 700,
            },
        },

        '& .mui-modal-footer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',

            '& button': {
                marginLeft: '15px',
            },
        },
    },
    noDataFound: {
        '&.MuiTableCell-root.MuiTableCell-body': {
            textAlign: 'center',
            fontSize: '16px',
            color: 'gray',

            '& p': {
                padding: '7px',
                display: 'block',
                margin: 0,
            },
        },
    },
};

export default useStyles;
