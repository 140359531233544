import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Grid,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { dayFilterList, fetchSchedulingSummary, MenuProps, exportSchedulingSummary } from '../../Widgets/WidgetService';
import { fetchPopulations } from '../../Population/PopulationService';
import SchedulingSummary from '../../../Models/Widget/SchedulingSummary.model';
import Population from '../../../Models/Population/Population.model';
import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import { parentUrl } from '../../../utils/constants';
import '../Widgets.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const SchedulingSummaryDetail = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const { healthPlans } = useHealthPlanContext();
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [populations, setPopulations] = useState<Population[]>([]);
    const [members, setMembers] = useState<SchedulingSummary>();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const {
        schedulingDateFilter,
        setSchedulingDateFilter,
        schedulingFromDate,
        setSchedulingFromDate,
        schedulingToDate,
        setSchedulingToDate,
        schedulingSelectedPlan,
        setSchedulingSelectedPlan,
        schedulingSelectedPopulation,
        setSchedulingSelectedPopulation,
        schedulingSelectedProvider,
        setSchedulingSelectedProvider,
        schedulingSelectedStaff,
        setSchedulingSelectedStaff,
        schedulingSelectedTeam,
        setSchedulingSelectedTeam,
        schedulingShowInActiveProviders,
        setSchedulingShowInActiveProviders,
        schedulingShowInActiveTeams,
        setSchedulingShowInActiveTeams,
        schedulingShowInActiveStaffs,
        setSchedulingShowInActiveStaffs,
    } = useWidgetContext();
    const filteredTeams = (schedulingShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (schedulingShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredStaffs = (schedulingShowInActiveStaffs ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const filterList = dayFilterList();

    const healthPlanChange = useCallback(
        async (healthPlanId: string, canResetPopulation = true) => {
            setPopulations([]);
            if (canResetPopulation) {
                setSchedulingSelectedPopulation(0);
            }
            if (healthPlanId && healthPlanId !== 'All') {
                const populations = await fetchPopulations(healthPlanId);
                setPopulations(populations);
            }
        },
        [setSchedulingSelectedPopulation]
    );

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (schedulingSelectedPlan !== 'All') healthPlanChange(schedulingSelectedPlan, false);
    }, [isFetched, schedulingSelectedPlan, healthPlanChange]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (schedulingDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(schedulingFromDate) && Boolean(schedulingToDate))) {
            const changeFilter = async () => {
                setIsMembersLoading(false);
                const data = await fetchSchedulingSummary(
                    schedulingDateFilter,
                    schedulingSelectedPlan,
                    schedulingSelectedPopulation,
                    schedulingSelectedProvider,
                    schedulingSelectedTeam,
                    schedulingSelectedStaff,
                    Boolean(schedulingFromDate) && schedulingDateFilter === WidgetTimeFilterEnum.Custom ? new Date(schedulingFromDate).toISOString() : '',
                    Boolean(schedulingToDate) && schedulingDateFilter === WidgetTimeFilterEnum.Custom ? new Date(schedulingToDate).toISOString() : '',
                    schedulingShowInActiveTeams,
                    schedulingShowInActiveProviders,
                    schedulingShowInActiveStaffs,
                    true
                );
                setMembers(data);
                setIsMembersLoading(true);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (schedulingDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(schedulingFromDate) && Boolean(schedulingToDate)) {
            setSchedulingFromDate(null);
            setSchedulingToDate(null);
        }
    }, [
        schedulingDateFilter,
        schedulingSelectedPlan,
        schedulingSelectedPopulation,
        schedulingSelectedProvider,
        schedulingSelectedStaff,
        schedulingFromDate,
        schedulingToDate,
        setSchedulingFromDate,
        setSchedulingToDate,
        setIsFetched,
        schedulingSelectedTeam,
        schedulingShowInActiveTeams,
        schedulingShowInActiveProviders,
        schedulingShowInActiveStaffs,
    ]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const changeFromDate = (date: any) => {
        setSchedulingFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(schedulingToDate) || new Date(schedulingToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setSchedulingToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setSchedulingToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(schedulingFromDate) || new Date(schedulingFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setSchedulingFromDate(startDate);
        }
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportSchedulingSummary(
            schedulingDateFilter,
            schedulingSelectedPlan,
            schedulingSelectedPopulation,
            schedulingSelectedProvider,
            schedulingSelectedTeam,
            schedulingSelectedStaff,
            Boolean(schedulingFromDate) && schedulingDateFilter === WidgetTimeFilterEnum.Custom ? new Date(schedulingFromDate).toISOString() : '',
            Boolean(schedulingToDate) && schedulingDateFilter === WidgetTimeFilterEnum.Custom ? new Date(schedulingToDate).toISOString() : '',
            schedulingShowInActiveTeams,
            schedulingShowInActiveProviders,
            schedulingShowInActiveStaffs
        );
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `SchedulingSummary - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setSchedulingShowInActiveTeams(isChecked);
        setSchedulingSelectedTeam('All');
        setMembers(new SchedulingSummary());
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setSchedulingShowInActiveProviders(isChecked);
        setSchedulingSelectedProvider('All');
    };

    const toggleStaffHandler = (isChecked: boolean) => {
        setSchedulingShowInActiveStaffs(isChecked);
        setSchedulingSelectedStaff('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Scheduling Member Summary
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Health Plan</InputLabel>
                            <Select
                                label="Health Plan"
                                name="Health Plan"
                                onChange={(e) => {
                                    setSchedulingSelectedPlan(e.target.value);
                                    healthPlanChange(e.target.value);
                                }}
                                value={schedulingSelectedPlan}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {filteredHealthPlans.map((plan) => (
                                    <MenuItem key={plan.Id} value={plan.Id}>
                                        {plan.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Population</InputLabel>
                            <Select
                                value={schedulingSelectedPopulation}
                                label="Population"
                                name="Population"
                                onChange={(e) => setSchedulingSelectedPopulation(parseInt(e.target.value))}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="0">
                                    All
                                </MenuItem>
                                {populations.map((tm) => (
                                    <MenuItem key={tm.PopulationNumber} value={tm.PopulationNumber}>
                                        {tm.PopulationName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={schedulingSelectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setSchedulingSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={schedulingShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select
                                label="Team"
                                name="Team"
                                onChange={(e) => setSchedulingSelectedTeam(e.target.value)}
                                value={schedulingSelectedTeam}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={schedulingShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Scheduling Staff</InputLabel>
                            <Select
                                label="Scheduling Staff"
                                name="Scheduling Staff"
                                onChange={(e) => setSchedulingSelectedStaff(e.target.value)}
                                value={schedulingSelectedStaff}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={schedulingShowInActiveStaffs} onChange={(e) => toggleStaffHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredStaffs.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={schedulingDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setSchedulingDateFilter(parseInt(e.target.value))}
                            >
                                {filterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {parseInt(schedulingDateFilter) === WidgetTimeFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item xs={1}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(schedulingFromDate) ? schedulingFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(schedulingToDate) ? schedulingToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    {parseInt(schedulingDateFilter) !== WidgetTimeFilterEnum.Custom ? <Grid item xs={2}></Grid> : null}
                    <Grid item xs={1}>
                        <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isMembersLoading ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Confirmed ({members.ConfirmedAppointmentMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Appointment Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members.ConfirmedAppointmentMembers.length ? (
                                                    members.ConfirmedAppointmentMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Cancelled ({members.CancelledAppointmentMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Appointment Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members.CancelledAppointmentMembers.length ? (
                                                    members.CancelledAppointmentMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            No Call Or No Show ({members.NoCallOrNoShowAppointmentMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Appointment Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members.NoCallOrNoShowAppointmentMembers.length ? (
                                                    members.NoCallOrNoShowAppointmentMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Completed ({members.CompletedAppointmentMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Appointment Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members.CompletedAppointmentMembers.length ? (
                                                    members.CompletedAppointmentMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            Provider Leave ({members.ProviderLeaveAppointmentMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Appointment Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members.ProviderLeaveAppointmentMembers.length ? (
                                                    members.ProviderLeaveAppointmentMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                            No Status ({members.NoStatusAppointmentMembers.length})
                                        </Typography>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell align="left">Member Name</StyledTableCell>
                                                    <StyledTableCell align="right">Appointment Date</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members.NoStatusAppointmentMembers.length ? (
                                                    members.NoStatusAppointmentMembers.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.DateInfo !== null ? row.DateInfo : '-'}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={3}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default SchedulingSummaryDetail;
