import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Step, StepLabel, Stepper } from '@mui/material';
import BasicInformation from './BasicInformation';
import ContractTerms from './ContractTerms';
import Contact from './Contact';
import DataFeed from './DataFeed';
import OutcomeMetrics from './OutcomeMetrics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { insertHealthPlan, setActiveStep } from '../../../store/adminHealthPlan.slice';

const AddHealthPlanDialog = (props) => {
    const dispatch = useDispatch();

    const { activeStep, newPlan, healthPlanForm } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const { nextStepHandler, prevStepHandler, steps } = props;

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
            <DialogTitle sx={{ m: 2, marginBottom: 5 }}>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {steps.map((step) => {
                        return (
                            <Step
                                onClick={() => {
                                    !newPlan && dispatch(setActiveStep(step.value));
                                }}
                                key={step.value}
                            >
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </DialogTitle>
            <DialogContent sx={{ height: '80vh' }}>
                <FormControl fullWidth>
                    <Grid container spacing={2} p={2} sx={{}}>
                        {activeStep === 0 ? (
                            <BasicInformation inputOnChange={props.inputOnChange} healthPlanForm={healthPlanForm} onSave={props.savePlanTypesHandler} />
                        ) : activeStep === 1 ? (
                            <Contact />
                        ) : activeStep === 2 ? (
                            <ContractTerms healthPlanForm={props.healthPlanForm} inputOnChange={props.inputOnChange} />
                        ) : activeStep === 3 ? (
                            <DataFeed
                                healthPlanForm={props.healthPlanForm}
                                currentContact={props.currentContact}
                                setCurrentContact={props.setCurrentContact}
                                closeContactInfoHandler={props.closeContactInfoHandler}
                            />
                        ) : activeStep === 4 ? (
                            <OutcomeMetrics />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ marginTop: 2 }}>
                <Button className="button-120" variant="outlined" onClick={props.onClose}>
                    Close
                </Button>
                {activeStep > 0 && (
                    <Button className="button-120" variant="contained" onClick={prevStepHandler}>
                        Previous
                    </Button>
                )}
                {!newPlan && activeStep < props.steps.length - 1 && (
                    <Button className="button-120" variant="contained" onClick={nextStepHandler}>
                        Next
                    </Button>
                )}
                {newPlan && activeStep < props.steps.length - 1 && (
                    <Button
                        disabled={healthPlanForm.Name.length === 0}
                        className="button-120"
                        variant="contained"
                        onClick={() => {
                            dispatch(setActiveStep(activeStep + 1));
                            dispatch(insertHealthPlan(healthPlanForm));
                        }}
                    >
                        Create
                    </Button>
                )}
                {activeStep === props.steps.length - 1 && (
                    <Button
                        className="button-120"
                        variant="contained"
                        onClick={() => {
                            nextStepHandler();
                            props.onClose();
                        }}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AddHealthPlanDialog;
