import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../hooks/populationContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { configureStackedBarChartWithoutLegend } from '../../ChartConfiguration/ChartConfiguration';
import { getDiabeticEyeExamSummary } from '../HedisMetricService';
import DiabeticEyeExamSummary from '../../../Models/HedisMetrics/DiabeticEyeExamSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import FilterOptions from '../FilterOptions';
import '../../Widgets/Widgets.css';

const DiabeticEyeExamSummaries = () => {
    const history = useNavigate();
    const { populations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [diabeticEyeExamSummary, setDiabeticEyeExamSummary] = useState<DiabeticEyeExamSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const {
        diabeticEyeExamPopulation,
        setDiabeticEyeExamPopulation,
        diabeticEyeExamTeam,
        setDiabeticEyeExamTeam,
        diabeticEyeExamDateFilter,
        setDiabeticEyeExamDateFilter,
        diabeticEyeExamFromDate,
        setDiabeticEyeExamFromDate,
        diabeticEyeExamToDate,
        setDiabeticEyeExamToDate,
        diabeticEyeExamIncludePastOpenStatus,
        setDiabeticEyeExamIncludePastOpenStatus,
        diabeticEyeExamShowInActiveTeam,
        setDiabeticEyeExamShowInActiveTeam,
        setHedisType,
        setPop,
        setTeam,
        setFilter,
        setFDate,
        setTDate,
        setIncludePastOpenStatus,
        setShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartWithoutLegend('Diabetic Eye Exam', 'Screened vs Not Screened', 'Population', 'Count', [{ name: '', data: [0] }], [0])
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (
            isPopulationFetched &&
            (diabeticEyeExamDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(diabeticEyeExamFromDate) && Boolean(diabeticEyeExamToDate)))
        ) {
            const changeFilter = async () => {
                let start = diabeticEyeExamFromDate !== null ? new Date(diabeticEyeExamFromDate).toDateString() : null;
                let end = diabeticEyeExamToDate !== null ? new Date(diabeticEyeExamToDate).toDateString() : null;
                const data = await getDiabeticEyeExamSummary(
                    diabeticEyeExamPopulation,
                    diabeticEyeExamTeam,
                    diabeticEyeExamDateFilter,
                    start,
                    end,
                    diabeticEyeExamIncludePastOpenStatus,
                    diabeticEyeExamShowInActiveTeam
                );
                setDiabeticEyeExamSummary(data);
                setIsPrepared(true);
            };
            if ((diabeticEyeExamFromDate !== null || diabeticEyeExamToDate !== null) && diabeticEyeExamToDate < diabeticEyeExamFromDate) {
            } else {
                setChartOptions(
                    configureStackedBarChartWithoutLegend(
                        'Diabetic Eye Exam',
                        'Screened vs Not Screened',
                        'Population',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        diabeticEyeExamPopulation,
        diabeticEyeExamTeam,
        diabeticEyeExamDateFilter,
        diabeticEyeExamFromDate,
        diabeticEyeExamToDate,
        isPopulationFetched,
        diabeticEyeExamIncludePastOpenStatus,
        diabeticEyeExamShowInActiveTeam,
    ]);

    useEffect(() => {
        if (diabeticEyeExamSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            let yVal;

            yValues = [];
            yVal = {
                y: diabeticEyeExamSummary.Screened,
                name: 'Screened',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Screened', data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });

            yValues = [];
            yVal = {
                y: diabeticEyeExamSummary.NotScreened,
                name: 'Not Screened',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Screened', data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });

            if (diabeticEyeExamPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = populations.find((p) => p.value === diabeticEyeExamPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartWithoutLegend('Diabetic Eye Exam', '', '', 'Count', yAxisValues, xAxisValues, navigateToMembers));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diabeticEyeExamSummary, diabeticEyeExamPopulation, populations, isPrepared]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToMembers = () => {
        setPop(diabeticEyeExamPopulation);
        setTeam(diabeticEyeExamTeam);
        setFilter(diabeticEyeExamDateFilter);
        setFDate(diabeticEyeExamFromDate);
        setTDate(diabeticEyeExamToDate);
        setIncludePastOpenStatus(diabeticEyeExamIncludePastOpenStatus);
        setShowInActiveTeam(diabeticEyeExamShowInActiveTeam);
        setHedisType(HedisMetricsTypeEnum.DiabeticEyeExam);
        history(`/provider/metricsummary/hedismemberdetail/DiabeticEyeExam`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            Diabetic Eye Exam
                        </Typography>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">
                                        For members who have an ICD 10 of E10-E11 and are between the ages of 18-75 in the rolling 12 month time frame
                                    </Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Screened :
                                    </Typography>

                                    <Typography variant="caption">
                                        Any of the CPT codes 67028, 67030, 67031, 67036, 67039, 67040, 67041, 67042, 67043, 67101, 67105, 67107, 67108, 67110,
                                        67113, 67121, 67141, 67145, 68208, 67210, 67218, 67220, 67221, 67227, 67228, 92002, 92004, 92012, 92014, 92018, 92019,
                                        92134, 92225, 92226, 92227, 92228, 92230, 92235, 92240, 92550, 92260,99203, 99204, 99205, 99213, 99214, 99215, 99242,
                                        99243, 99244, 99245
                                    </Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Screened :
                                    </Typography>
                                    <Typography variant="caption">No CPT codes recorded</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FilterOptions
                        selectedTeam={diabeticEyeExamTeam}
                        setSelectedTeam={setDiabeticEyeExamTeam}
                        dateFilter={diabeticEyeExamDateFilter}
                        setDateFilter={setDiabeticEyeExamDateFilter}
                        fromDate={diabeticEyeExamFromDate}
                        setFromDate={setDiabeticEyeExamFromDate}
                        toDate={diabeticEyeExamToDate}
                        setToDate={setDiabeticEyeExamToDate}
                        population={diabeticEyeExamPopulation}
                        setPopulation={setDiabeticEyeExamPopulation}
                        includePastOpenStatusMembers={diabeticEyeExamIncludePastOpenStatus}
                        setIncludePastOpenStatusMembers={setDiabeticEyeExamIncludePastOpenStatus}
                        hedisType={HedisMetricsTypeEnum.DiabeticEyeExam}
                        showInActiveTeams={diabeticEyeExamShowInActiveTeam}
                        setShowInActiveTeams={setDiabeticEyeExamShowInActiveTeam}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default DiabeticEyeExamSummaries;
