import BaseEntity from '../BaseEntity.model';
import Height from './Height.model';
import { StatusEnum } from '../../Enum/StatusEnum';

export default interface IMembersVitalSigns extends BaseEntity {
    MemberId: string;
    Height: Height;
    Weight: string;
    BmiValuation: string;
    BMI: string | null;
    BpPulse: string;
    BpSystolic: string;
    BpDiastolic: string;
    PulseOxSpO2Last: string;
    GlucoseMgDl: string;
    RespirationRate: string;
    RefusedForAssessment: boolean;
    SpokeWithFamily: string;
    SpokeWithFriends: string;
    HealthEvaluation: string;
    IsAssessmentCompleted: boolean;
    AssessmentStatus: StatusEnum;
}

export class MembersVitalSigns implements IMembersVitalSigns {
    Id: string;
    MemberId: string;
    Height: Height;
    Weight: string;
    BmiValuation: string;
    BMI: string | null;
    BpPulse: string;
    BpSystolic: string;
    BpDiastolic: string;
    PulseOxSpO2Last: string;
    GlucoseMgDl: string;
    RespirationRate: string;
    RefusedForAssessment: boolean;
    SpokeWithFamily: string;
    SpokeWithFriends: string;
    HealthEvaluation: string;
    IsAssessmentCompleted: boolean;
    AssessmentStatus: StatusEnum;
    VitalSignResultStatus: StatusEnum;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.BpSystolic = '';
        this.BpDiastolic = '';
        this.BpPulse = '';
        this.GlucoseMgDl = '';
        this.RespirationRate = '';
        this.Weight = '';
        this.PulseOxSpO2Last = '';
        this.Height = null;
        this.BMI = null;
        this.SpokeWithFamily = '';
        this.SpokeWithFriends = '';
        this.HealthEvaluation = '';
        this.IsAssessmentCompleted = false;
        this.RefusedForAssessment = false;
        this.BmiValuation = '';
        this.AssessmentStatus = 0;
    }
}
