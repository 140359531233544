export default interface IAddress {
    Address1: string;
    Address2: string;
    City: string;
    FullAddress: string;
    State: string;
    ZipCode: string;
    Country: string;
    Lat: number;
    Lon: number;
}

export default class Address implements IAddress {
    Address1: string;
    Address2: string;
    City: string;
    FullAddress: string;
    State: string;
    ZipCode: string;
    Country: string;
    Lat: number;
    Lon: number;

    constructor() {
        this.Address1 = '';
        this.Address2 = '';
        this.City = '';
        this.FullAddress = '';
        this.State = '';
        this.ZipCode = '';
        this.Country = '';
        this.Lat = 0;
        this.Lon = 0;
    }
}
