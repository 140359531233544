import axios from 'axios';

export default class Authorization {
    AccessToken: any;
    APIUrl: string = '';
    UserName: string = '';
    UserId: string = '';
    UserType: string = '';
    Role: string = '';
    AuthObject: any;
    Permissions = [] as string[];
    NPINumber: string = '';
    IsLicensedProvider: boolean = false;
    TeamId: string = '';
    IsAdmin: boolean = false;
    LoggedInAsAdmin: boolean = false;
    IsTempPassword: boolean = false;
    TokenExpires: string = '';

    constructor() {
        var apiBaseUri = 'http://localhost:44362/';
        var env = this.getEnv();
        if (env === 'Production') {
            apiBaseUri = 'https://apistage.chpcarecentral.com/';
        } else if (env === 'QA Testing') {
            apiBaseUri = 'https://chp-qa-api.azurewebsites.net/';
        } else if (env === 'Development') {
            apiBaseUri = 'https://apidev.chpcarecentral.com/';
        }

        this.APIUrl = apiBaseUri + 'api';
        this.AuthObject = this.getAuthFromStorage();

        if (this.AuthObject) {
            this.UserId = this.AuthObject.UserId;
            this.AccessToken = this.AuthObject.AccessToken;
            this.UserType = this.AuthObject.UserType;
            this.UserName = this.AuthObject.UserName;
            this.Role = this.AuthObject.Role;
            this.Permissions = this.AuthObject.Permissions;
            this.NPINumber = this.AuthObject.NPINumber;
            this.IsLicensedProvider = this.AuthObject.IsLicensedProvider;
            this.IsAdmin = this.AuthObject.IsAdmin;
            this.TeamId = this.AuthObject.TeamId;
            this.LoggedInAsAdmin = this.AuthObject.LoggedInAsAdmin || window.location.href.indexOf('admin') > -1;
            this.IsTempPassword = this.AuthObject.IsTempPassword;
            this.TokenExpires = this.AuthObject.TokenExpires;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.AccessToken;
        }
    }

    isInPermission = (permission: string) => {
        if (Boolean(this.Permissions?.length)) {
            return this.Permissions.some((p) => p === permission);
        } else {
            return false;
        }
    };

    getAuthFromStorage() {
        try {
            return localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') + '') : {};
        } catch (e: any) {
            console.log(`Could not get auth data: ${e.message}`);
        }
    }

    getEnv = () => {
        if (window.location.href.indexOf('https://chpcarecentral.com') > -1 || window.location.href.indexOf('https://chp-react-prod.azurewebsites.net') > -1) {
            return 'Production';
        } else if (
            window.location.href.indexOf('https://chp-qa-web.azurewebsites.net') > -1 ||
            window.location.href.indexOf('https://chp-react-qa.azurewebsites.net') > -1
        ) {
            return 'QA Testing';
        } else if (
            window.location.href.indexOf('https://dev.chpcarecentral.com') > -1 ||
            window.location.href.indexOf('https://chp-react-dev.azurewebsites.net') > -1
        ) {
            return 'Development';
        } else {
            return 'Local';
        }
    };
}
