import {
    Grid,
    Paper,
    Typography,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    Checkbox,
    TextField,
    FormControlLabel,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useNavigate } from 'react-router';

import { useWidgetContext } from '../../../../hooks/widgetContext';
import { apiString as API_URL, parentUrl } from '../../../../utils/constants';
import WidgetLoading from '../../../SmartDashboard/Shared/WidgetLoading';
import { dateFormatter } from '../../../../utils/timeFormat';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import { TimePeriodFilterEnum } from '../../../../Enum/TimePeriodFilterEnum';
import { fetchFuhFumClosedSummary } from '../../../Widgets/WidgetService';
import { isDefaultDate } from '../../../../utils/common';

const qualityList = [
    { Id: 1, Name: 'Pending Quality Check' },
    { Id: 2, Name: 'Completed Quality Check' },
];

const dayFilterList = [
    { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
    { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
    { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
    { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
];

const qualityCheckItems = [
    { Name: 'FinalDetermination', DisplayName: 'Final Determination', Checked: false },
    { Name: 'FollowUpAppointmentWithCommunityProvider', DisplayName: 'Follow Up Appointment With Community Provider', Checked: false },
    { Name: 'AllDocumentationCompleted', DisplayName: 'All Documentation Completed', Checked: false },
    { Name: 'AllRequestCompleted', DisplayName: 'All Request Completed', Checked: false },
    { Name: 'AllOpenRequestsMovedToActiveChart', DisplayName: 'All Open Requests Moved To Active Chart', Checked: false },
];

const FuhFumClosedSummary = () => {
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isCheckListPopup, setIsCheckListPopup] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const [selectedMember, setSelectedMember] = useState({ MemberId: '', MemberName: '' });
    const ref = useRef(null);
    const history = useNavigate();
    const [qualityChecks, setQualityChecks] = useState([...qualityCheckItems]);
    const {
        closedSelectedFilter,
        setClosedSelectedFilter,
        closedDateFilter,
        setClosedDateFilter,
        closedFromDate,
        setClosedFromDate,
        closedToDate,
        setClosedToDate,
    } = useWidgetContext();

    const handleClick = (event) => {
        setShow(!show);
    };

    const columns: GridColDef[] = [
        {
            field: 'Action',
            headerName: 'Action',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <>
                        <IconButton
                            onClick={(event) => {
                                event?.stopPropagation();
                                handleSetQualityCheck(params.row.Id);
                            }}
                        >
                            <FactCheckIcon sx={{ width: '20px', height: '20px' }} />
                        </IconButton>
                    </>
                );
            },
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 1,
            renderCell: (params) => {
                let contactDate = isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB));
                return <>{contactDate}</>;
            },
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
        },
        {
            field: 'FuhFumVisitTypeName',
            headerName: 'Admission Type',
            flex: 1,
            renderCell: (params) => {
                let typeInfo = Boolean(params.row.FuhFumVisitTypeName) ? params.row.FuhFumVisitTypeName : '-';
                return <>{typeInfo}</>;
            },
        },
        {
            field: 'Facility',
            headerName: 'Facility',
            flex: 2,
            renderCell: (params) => {
                let facilityInfo = Boolean(params.row.Facility) ? params.row.Facility : '-';
                return <>{facilityInfo}</>;
            },
        },
    ];

    const handleSetQualityCheck = (memberId: string) => {
        let currentMember = rows.find((m) => m.Id === memberId);
        if (Boolean(currentMember)) {
            if (Boolean(currentMember.FuhFumClosedQualityChecks)) {
                let updatedQualityChecks = [...qualityChecks];
                updatedQualityChecks.forEach((item) => {
                    item.Checked = currentMember.FuhFumClosedQualityChecks[item.Name];
                });
                setQualityChecks(updatedQualityChecks);
            } else {
                let updatedQualityChecks = [...qualityCheckItems];
                updatedQualityChecks.forEach((item) => {
                    item.Checked = false;
                });
                setQualityChecks(updatedQualityChecks);
            }
            setSelectedMember({ MemberId: memberId, MemberName: `${currentMember.MemberName} Check List` });
            setIsCheckListPopup(true);
        }
    };

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 90) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFuhFumSummary = async () => {
        if (closedDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(closedFromDate) && Boolean(closedToDate))) {
            setIsFetched(false);
            let dateFrom = Boolean(closedFromDate) && closedDateFilter === TimePeriodFilterEnum.Custom ? new Date(closedFromDate).toISOString() : '';
            let dateTo = Boolean(closedToDate) && closedDateFilter === TimePeriodFilterEnum.Custom ? new Date(closedToDate).toISOString() : '';

            const data = await fetchFuhFumClosedSummary(closedSelectedFilter, closedDateFilter, dateFrom, dateTo);
            setRows(data);
            setIsFetched(true);
        }

        if (closedDateFilter !== TimePeriodFilterEnum.Custom && Boolean(closedFromDate) && Boolean(closedToDate)) {
            setClosedFromDate(null);
            setClosedToDate(null);
        }
    };

    const handleOnSave = async () => {
        try {
            setIsSaving(true);
            let data = {};
            qualityChecks.forEach((item) => {
                data[item.Name] = item.Checked;
            });
            const response = await axios.put(`${API_URL}/fuhfum/updatefuhfumclosedqualitycheck?memberId=${selectedMember.MemberId}`, data);
            if (response.status === 200) {
                setIsSaving(false);
                getFuhFumSummary();
                setIsCheckListPopup(false);
            }
        } catch (error) {
            console.log(error);
            setIsSaving(false);
        }
    };

    const handleOnChangeQualityCheck = (name: string, isChecked: boolean) => {
        let updatedQualityChecks = [...qualityChecks];
        let selectedItem = updatedQualityChecks.find((c) => c.Name === name);
        selectedItem.Checked = isChecked;
        setQualityChecks(updatedQualityChecks);
    };

    const changeFromDate = (date: any) => {
        setClosedFromDate(date);
    };

    const changeToDate = (date: any) => {
        setClosedToDate(date);
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closedSelectedFilter, closedDateFilter, closedFromDate, closedToDate]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/fuhfumclosedmemberdetail`);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    FUHFUM Closed Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">
                                                Select the icon in front of the member’s name to view and work the Completion checklist. Click on the member’s
                                                name to open their record in a new tab.
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="provider-label">Quality Check</InputLabel>
                                <Select
                                    id="demo-provider-select"
                                    value={closedSelectedFilter}
                                    label="Quality Check"
                                    onChange={(e) => {
                                        setClosedSelectedFilter(e.target.value as string);
                                    }}
                                >
                                    <MenuItem key="All" value="0">
                                        All
                                    </MenuItem>
                                    {qualityList.map(function (day) {
                                        return (
                                            <MenuItem key={day.Id} value={day.Id}>
                                                {day.Name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={closedDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setClosedDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {closedDateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={3}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(closedFromDate) ? closedFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(closedToDate) ? closedToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                            {!isFetched ? (
                                <WidgetLoading />
                            ) : (
                                <DataGrid
                                    onCellClick={(params: any) => {
                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                    }}
                                    rows={rows}
                                    columns={columns}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[5]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </Grid>
                </Paper>
                <AlertDialog
                    open={isCheckListPopup}
                    title={selectedMember.MemberName}
                    cancelText="Cancel"
                    okText="Submit"
                    isLoading={isSaving}
                    onConfirm={() => handleOnSave()}
                    onClose={() => {
                        setIsCheckListPopup(false);
                    }}
                    renderBody={() => (
                        <Stack>
                            {qualityChecks.map((item, index) => {
                                return (
                                    <Stack key={selectedMember.MemberId + item.Name} direction="row" spacing={0} alignItems="center">
                                        <FormControlLabel
                                            control={<Checkbox name={item.Name} className="pointer" checked={item.Checked} />}
                                            onChange={(e: any) => {
                                                handleOnChangeQualityCheck(item.Name, e.target.checked);
                                            }}
                                            label={item.DisplayName}
                                        />
                                    </Stack>
                                );
                            })}
                        </Stack>
                    )}
                />
            </Grid>
        </LocalizationProvider>
    );
};

export default FuhFumClosedSummary;
