import BaseEntity from '../BaseEntity.model';
import { DiagnosisDraft } from './DiagnosisDraft.model';

export default interface IMemberDefaultDiagnosis extends BaseEntity {
    MemberId: string;
    DefaultDiagnosis: DiagnosisDraft;
}

export class MemberDefaultDiagnosis implements IMemberDefaultDiagnosis {
    Id: string;
    MemberId: string;
    DefaultDiagnosis: DiagnosisDraft;

    constructor() {
        this.Id = null;
        this.MemberId = '';
        this.DefaultDiagnosis = new DiagnosisDraft();
    }
}
