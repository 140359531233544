import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    FormControlLabel,
    Checkbox,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import { RootState } from '../../../reducers';
import { setMemberBudget } from '../../../store/memberBudget.slice';
import classes from '../Styles';
import { copyObject } from '../../../utils/common';
import MemberList from './MemberList';
import CurrencyInput from '../../Shared/CurrencyInput';
import { IncomeItem } from '../../../Models/Budget/IncomeItem.model';
import AlertDialog from '../../AlertDialog/AlertDialog';

const IncomeForm = () => {
    const dispatch = useDispatch();
    const [showDeleteSourceAlert, setShowDeleteSourceAlert] = useState<boolean>(false);
    const [selectedSourceIndex, setSelectedSourceIndex] = useState(null);
    const { memberBudget } = useSelector((state: RootState) => state.memberBudget);

    const handleOnAddNewSource = () => {
        let memberBudgetData = copyObject(memberBudget);
        if (!Boolean(memberBudgetData.Income.IncomeSources?.length)) {
            memberBudgetData.Income.IncomeSources = [];
        }
        memberBudgetData.Income.IncomeSources.push({ ...new IncomeItem(), Name: `Source ${memberBudgetData.Income.IncomeSources.length + 1}` });
        dispatch(setMemberBudget(memberBudgetData));
    };

    const handleOnChangeIncome = (val: any, propName: string) => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.Income[propName] = val;
        dispatch(setMemberBudget(memberBudgetData));
    };

    const handleOnChangeIncomeSource = (val: any, propName: string, index: number) => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.Income.IncomeSources[index][propName] = val;
        dispatch(setMemberBudget(memberBudgetData));
    };

    const handleOnChangeOtherSource = (val: any, propName: string, index: number) => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.Income.OtherSources[index][propName] = val;
        dispatch(setMemberBudget(memberBudgetData));
    };

    const handleOnConfirmDelete = () => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.Income.IncomeSources = memberBudgetData.Income.IncomeSources.filter((_, index) => index !== selectedSourceIndex);
        dispatch(setMemberBudget(memberBudgetData));
        setShowDeleteSourceAlert(false);
    };

    return (
        <>
            <TableContainer component={Paper} sx={classes.budgetFormTable}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1" component="label">
                                    Income <small>(Money used to pay your bills)</small>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover>
                            <TableCell scope="row">
                                <Stack direction="row">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={memberBudget?.Income?.MemberOnly}
                                                name="MemberOnly"
                                                onChange={(e) => handleOnChangeIncome(e.target.checked, e.target.name)}
                                            />
                                        }
                                        label="Member Only"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={memberBudget?.Income?.Household}
                                                name="Household"
                                                onChange={(e) => handleOnChangeIncome(e.target.checked, e.target.name)}
                                            />
                                        }
                                        label="Household"
                                    />
                                    {Boolean(memberBudget?.Income?.Household) ? <MemberList /> : null}
                                </Stack>
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell scope="row">
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow hover>
                                                        <TableCell scope="row">
                                                            <Button
                                                                variant="text"
                                                                endIcon={<AddCircleOutlinedIcon fontSize="large" />}
                                                                className="text-transform-none"
                                                                onClick={handleOnAddNewSource}
                                                            >
                                                                Add Source
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    {memberBudget.Income?.IncomeSources?.map((source, index) => (
                                                        <TableRow key={`income-source-${index}`} hover>
                                                            <TableCell scope="row">
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            value={source.Name}
                                                                            variant="outlined"
                                                                            name="Name"
                                                                            className="hide-textinput-label"
                                                                            onChange={(e) => handleOnChangeIncomeSource(e.target.value, e.target.name, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CurrencyInput
                                                                            size="small"
                                                                            fullWidth
                                                                            value={source.Amount}
                                                                            name="Amount"
                                                                            onChange={(e) => handleOnChangeIncomeSource(e.target.value, e.target.name, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                setSelectedSourceIndex(index);
                                                                                setShowDeleteSourceAlert(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {memberBudget.Income?.OtherSources?.map((source, index) => (
                                                        <TableRow key={`other-source-${index}`} hover>
                                                            <TableCell scope="row">
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            disabled
                                                                            value={source.Name}
                                                                            variant="outlined"
                                                                            name="Name"
                                                                            className="hide-textinput-label"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CurrencyInput
                                                                            fullWidth
                                                                            size="small"
                                                                            id={`test1`}
                                                                            value={source.Amount}
                                                                            name="Amount"
                                                                            onChange={(e) => handleOnChangeOtherSource(e.target.value, e.target.name, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}></Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow hover>
                            <TableCell scope="row">
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={7}>
                                                <span className="pl-4">Total Income</span>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <strong className="pl-2">${memberBudget.TotalIncome}</strong>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <AlertDialog
                open={showDeleteSourceAlert}
                showTop
                title="Delete Source"
                okText="Delete"
                onConfirm={handleOnConfirmDelete}
                onClose={() => setShowDeleteSourceAlert(false)}
                message="Are you sure want to delete this source?"
            />
        </>
    );
};

export default IncomeForm;
