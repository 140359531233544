import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    FormControl,
    Stack,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    TableRow,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Slide,
    FormControlLabel,
    IconButton,
    Switch,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    Typography,
    Link,
    Checkbox,
    Popover,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import moment from 'moment';
import PrintIcon from '@mui/icons-material/Print';

import { gotoNextMenu, gotoPreviousMenu, setAssessmentError, updateAssessmentMenu } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import classes from './Styles';
import {
    assessmentMenuEnum,
    DiscontinueReasons,
    MedicationActionEnum,
    MedicationActions,
    MedicationStatusEnum,
    MedicationStatuses,
    roleEnum,
} from '../../../utils/assessments';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import {
    connectEPrescribe,
    deletingMedicationById,
    generateMedicationAllergyPDF,
    getMedicationFrequencyTimes,
    getMedicationNotifications,
    getMedications,
    getSSOEndPoint,
    prepareMedicaionSummaryNotes,
    saveMedication,
    setSelectedMedication,
    toggleShowDiscontinueModal,
    toggleShowMedicationModal,
    toggleShowMedicationPriodModal,
    toggleShowMergeMedicationModal,
    updateMedicationNotifications,
    updateMedications,
} from '../../../store/medication.slice';
import { Medications } from '../../../Models/Medications/Medications.model';
import { copyObject } from '../../../utils/common';
import { getActiveProviders } from '../../../store/provider.slice';
import Authorization from '../../../utils/Authorization';
import AddOrUpdateMedication from './AddOrUpdateMedication';
import AssignPeriodsDue from './AssignPeriodsDue';
import MergeMedications from './MergeMedications';
import { getMemberById } from '../../../store/memberDetail.slice';
import { useParams } from 'react-router-dom';
import MemberAllergyComponent from '../MemberAllergy/MemberAllergy';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const MedicationReconciliation: React.FC<{ memberId: string; memberDetail: boolean }> = (props) => {
    const dispatch = useDispatch();
    const authData = new Authorization();
    const memberDetail = props.memberDetail;
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const { member } = useSelector((state: RootState) => state.memberDetail);
    const [selectedStatus, setSelectedStatus] = useState<string>(MedicationStatusEnum.Active);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [selectedAction, setSelectedAction] = useState<string>('');
    const [selectedMeds, setSelectedMeds] = useState<string>('');
    const [selectedDiscontinueReason, setSelectedDiscontinueReason] = useState<string>('');
    const [medications, setMedications] = useState<Medications[]>([]);
    const [selectedPopoverMedication, setSelectedPopoverMedication] = useState<Medications>(null);
    const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const [showEmptyMedicWaring, setShowEmptyMedicWaring] = useState<boolean>(false);
    const [showAllergiesModal, setShowAllergiesModal] = useState<boolean>(false);
    const {
        medicationManagement,
        medicationFrequencyTimes,
        deletingMedicationIds,
        isSaving: isSavingMedication,
        isFetching,
        isMedicationNotification,
        isGeneratingPDF,
        showDiscontinueModal,
        selectedMedication,
        samlResponse,
        ssoEndpoint,
    } = useSelector((state: RootState) => state.medication);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        dispatch(getMedications(memberId));
        dispatch(getMedicationFrequencyTimes());
        dispatch(getMedicationNotifications(memberId));
        dispatch(getActiveProviders(false));
        dispatch(getMemberById(memberId));
        dispatch(getSSOEndPoint());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnCloseModal = () => setShowStatusModal(false);
    const handleOnCloseDiscontinueModal = () => dispatch(toggleShowDiscontinueModal(false));

    const handleOnClickSave = (isMedicConfirm = false) => {
        if (isMedicConfirm) {
            setIsChanged(true);
        }
        if (!isMedicConfirm && medications.length && !medications?.some((m) => m.DisplayOnReportPdf) && authData.Role === roleEnum.NURSE_PRACTITIONER) {
            setShowEmptyMedicWaring(true);
        } else {
            setShowEmptyMedicWaring(false);
            setShowSaveAlert(true);
        }
    };

    const handleViewAllergies = () => {
        setShowAllergiesModal(true);
    };

    const allergiesClass = {
        root: {
            padding: '0px',
        },
    };

    const handleOnCloseAllergiesModal = () => {
        setShowAllergiesModal(false);
    };

    const handleOnClickDelete = (medication) => {
        dispatch(setSelectedMedication(medication));
        setShowDeleteAlert(true);
        setSelectedAction('');
    };

    const handleOnShowModal = (medication = new Medications()) => {
        dispatch(setSelectedMedication(medication));
        dispatch(toggleShowMedicationModal(true));
    };

    const handleOnConfirmedDelete = () => {
        dispatch(deletingMedicationById(selectedMedication.Id, memberId));
        setShowDeleteAlert(false);
    };

    const handleOnCheckMedication = (checked: boolean, index: number) => {
        const medicationsData = copyObject(medications);
        medicationsData[index].IsSelected = checked;

        setMedications(medicationsData);
    };

    const handleOnSelectDisplayPdf = (checked: boolean, index: number) => {
        const medicationsData = copyObject(medications) as Medications[];
        let selectedMedication = medicationsData[index];
        selectedMedication.DisplayOnReportPdf = checked;

        dispatch(saveMedication(memberId, selectedMedication, false));
        setMedications(medicationsData);
        setIsChanged(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(prepareMedicaionSummaryNotes(true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeFilter = (value: string) => {
        setSelectedStatus(value);
        prepareMedications(value);
    };

    const handleOnSelectAction = (event: any) => {
        const value = event.target.value;
        let errorMessage = '';
        switch (value) {
            case MedicationActionEnum.AssignPeriodsDue:
                assignPeriodsDue();
                break;
            case MedicationActionEnum.Discontinue:
                discontinueMeds();
                break;
            case MedicationActionEnum.DownloadPDF:
                dispatch(generateMedicationAllergyPDF(memberId));
                break;
            case MedicationActionEnum.EPrescribe:
                dispatch(connectEPrescribe(memberId));
                break;
            case MedicationActionEnum.MarkReconciled:
                markReconciled();
                break;
            case MedicationActionEnum.MergeSelectedMeds:
                mergeMedicationModal();
                break;
            case MedicationActionEnum.NewMedication:
                handleOnShowModal({ ...new Medications(), PrescribedBy: { Id: authData.UserId, Name: authData.UserName } });
                break;
        }

        if (Boolean(errorMessage)) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please select a medication(s) to assign periods' }));
        }

        if (event.target.blur) {
            event.target.blur();
        }
    };

    const assignPeriodsDue = () => {
        let medicationsData = (copyObject(medications) as Medications[]).filter((med) => med.IsSelected);
        let standardFrequencies = medicationFrequencyTimes.map((m) => m.Frequency);
        let uniqueFrequency = [...Array.from(new Set(medicationsData.map((med) => med.Frequency)))];

        if (medicationsData.length === 0) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please select a medication(s) to assign periods' }));
        } else if (uniqueFrequency.length !== 1) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please select medications with the same frequency to assign periods' }));
        } else if (standardFrequencies.indexOf(uniqueFrequency[0]) < 0) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please select medications or update the medication with a standard frequency' }));
        } else {
            dispatch(
                toggleShowMedicationPriodModal(
                    true,
                    medicationsData.map((m) => m.Medicine),
                    uniqueFrequency[0]
                )
            );
        }
    };

    const discontinueMeds = () => {
        let medicationsData = (copyObject(medications) as Medications[]).filter((med) => med.IsSelected);
        if (medicationsData.length < 1) {
            dispatch(setAssessmentError({ Show: true, Message: 'You must have at least 1 meds selected to discontinue.' }));
            return;
        }
        setSelectedDiscontinueReason('');
        dispatch(toggleShowDiscontinueModal(true));
    };

    const markReconciled = () => {
        let selectedMedications = (copyObject(medications) as Medications[]).filter((med) => med.IsSelected);
        if (selectedMedications.length < 1) {
            dispatch(setAssessmentError({ Show: true, Message: 'You must select at least 1 med to reconcile.' }));
            return;
        }

        selectedMedications.forEach((med) => {
            med.ReconcileDate = new Date().toISOString();
        });

        dispatch(updateMedications(memberId, selectedMedications));
        dispatch(saveEngagementDraft({ ...engagementDraft, MedicationReconciliationStatus: StatusEnum.InProgress }));
    };

    const mergeMedicationModal = () => {
        let medicationsData = (copyObject(medications) as Medications[]).filter((med) => med.IsSelected);
        if (medicationsData.length < 2) {
            dispatch(setAssessmentError({ Show: true, Message: 'You must have at least 2 meds selected to merge.' }));
            return;
        } else if (medicationsData.length > 5) {
            dispatch(
                setAssessmentError({ Show: true, Message: 'You can not merge more than 5 meds, please check your workflow or talk to an administrator.' })
            );
            return;
        } else {
            dispatch(toggleShowMergeMedicationModal(true));
        }
    };

    const prepareMedicationStatusColor = (medication: Medications) => {
        if (!medication.ReconcileDate && medication.Source !== 'Veradigm') {
            return classes.manuallyEnteredColor;
        } else if (!medication.ReconcileDate && medication.Source === 'Veradigm') {
            return classes.ePrescribeColor;
        }
    };

    const handleOnChangeMeds = (value: string) => {
        setSelectedMeds(value);
        let medicationsData = copyObject(medications);
        if (value === 'select' || value === 'all') {
            medicationsData.forEach((m) => {
                m.IsSelected = value === 'all';
            });
        } else {
            medicationsData.forEach((m) => {
                m.IsSelected = value === m.Frequency;
            });
        }

        setMedications(medicationsData);
    };

    const prepareMedications = (status = null) => {
        let medicationsData = medicationManagement.Medications || [];
        status = status || selectedStatus;
        medicationsData = medicationsData.filter((med) => {
            if (med.EndDate && new Date(med.EndDate) < new Date()) {
                return status === MedicationStatusEnum.AllInactive;
            } else if (status === MedicationStatusEnum.Active && (!Boolean(med.Status) || med.Status === status)) {
                return true;
            } else if (status === MedicationStatusEnum.AllInactive && med.Status && med.Status !== MedicationStatusEnum.Active) {
                return true;
            } else if (status === med.Status) {
                return true;
            }

            return false;
        });

        setMedications(medicationsData.sort((a, b) => a.Medicine.localeCompare(b.Medicine)));
    };

    const handleOnChangeData = () => {
        setIsChanged(true);
    };

    useEffect(() => {
        prepareMedications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medicationManagement.Medications]);

    const handleOnConfirmDiscontinue = () => {
        const selectedMedications = medications
            .filter((m) => m.IsSelected)
            .map((m) => {
                m.DCReason = selectedDiscontinueReason;
                m.Status = MedicationStatusEnum.DC;
                return m;
            });

        dispatch(updateMedications(memberId, selectedMedications));
    };

    const renderDiscontinueReasonModal = () => {
        return (
            <Dialog
                disableScrollLock
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus
                open={showDiscontinueModal}
                sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleOnCloseDiscontinueModal}
            >
                <DialogTitle>Discontinue Medications</DialogTitle>
                <DialogContent dividers sx={{ width: '400px' }}>
                    <FormControl sx={{ minWidth: 220 }} size="small">
                        <InputLabel>Reason</InputLabel>
                        <Select value={selectedDiscontinueReason} label="Reason" onChange={(e) => setSelectedDiscontinueReason(e.target.value)}>
                            {DiscontinueReasons.map((status, index) => (
                                <MenuItem key={`${status.Name}-${index}`} value={status.Name}>
                                    {status.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={!Boolean(selectedDiscontinueReason) || isSavingMedication}
                        onClick={handleOnConfirmDiscontinue}
                        endIcon={isSavingMedication ? <CircularProgress size={18} color="inherit" /> : null}
                    >
                        Submit
                    </Button>
                    <Button variant="outlined" onClick={handleOnCloseDiscontinueModal}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderStatusModal = () => {
        return (
            <Dialog
                disableScrollLock
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus
                open={showStatusModal}
                sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleOnCloseModal}
            >
                <DialogTitle>Medication Color Description</DialogTitle>
                <DialogContent dividers sx={{ width: '400px' }}>
                    <Typography variant="subtitle1" component="label">
                        Meds imported from ePrescribe -{' '}
                        <Typography variant="subtitle1" component="label" color="#c125bc">
                            purple text
                        </Typography>
                    </Typography>
                    <Typography variant="subtitle1" component="label">
                        Meds manually entered -{' '}
                        <Typography variant="subtitle1" component="label" color="blue">
                            blue text
                        </Typography>
                    </Typography>
                    <Typography variant="subtitle1" component="label">
                        Meds that are reconciled -{' '}
                        <Typography variant="subtitle1" component="label">
                            black text
                        </Typography>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleOnCloseModal}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleOnClickSchedule = (event: React.MouseEvent<HTMLButtonElement>, medication: Medications) => {
        setAnchorEl(event.currentTarget);
        setSelectedPopoverMedication(medication);
    };

    const handleOnCloseSchedule = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const renderSchedulePopover = (medication: Medications) => {
        return Boolean(medication.MedicationSchedule) && medication.MedicationSchedule.length ? (
            <IconButton aria-describedby={medication.Id} onClick={(e) => handleOnClickSchedule(e, medication)}>
                <AccessTimeIcon color="primary" />
            </IconButton>
        ) : null;
    };

    const renderAllergiesModal = () => {
        return (
            <Dialog
                disableScrollLock
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus
                open={showAllergiesModal}
                sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="lg"
                onClose={handleOnCloseAllergiesModal}
            >
                {/* <DialogTitle>Allergies</DialogTitle> */}
                <DialogContent dividers>
                    <MemberAllergyComponent memberId={memberId} isAssessment={false} />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleOnCloseAllergiesModal}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className={memberDetail ? 'member-detail' : 'content-panel'}>
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <form id="ePrescribeForm" method="post" target="_blank" action={ssoEndpoint}>
                        <input id="samlResponse" type="hidden" name="SAMLResponse" value={samlResponse} />
                        <input type="hidden" name="TARGET" value="" />
                        <button style={{ display: 'none' }} id="submitEprescribe" type="submit" />
                    </form>
                    <Grid className="member-name" style={{ display: 'none' }} item xs={12}>
                        <Typography>
                            {member.LastName}, {member.FirstName}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                        <Stack className="no-print" direction="row" spacing={2} justifyContent="space-between">
                            <Stack direction="row" spacing={2}>
                                {memberDetail && (
                                    <Button onClick={() => window.print()} sx={{ marginRight: 5 }}>
                                        <PrintIcon />
                                    </Button>
                                )}
                                <FormControl sx={{ minWidth: 220 }} size="small">
                                    <InputLabel>Filter by Medication Status</InputLabel>
                                    <Select value={selectedStatus} label="Filter by Medication Status" onChange={(e) => handleOnChangeFilter(e.target.value)}>
                                        {MedicationStatuses.map((status, index) => (
                                            <MenuItem key={`${status.Name}-${index}`} value={status.Name}>
                                                {status.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <IconButton onClick={() => setShowStatusModal(true)}>
                                    <InfoIcon />
                                </IconButton>
                            </Stack>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isMedicationNotification}
                                        onChange={(e) => dispatch(updateMedicationNotifications(memberId, e.target.checked))}
                                        name="MedicationNotifications"
                                    />
                                }
                                labelPlacement="start"
                                label="Medication Notifications"
                            />
                            <Stack className="relative" direction={'row'} alignItems={'center'}>
                                <FormControl sx={{ minWidth: 220 }} size="small">
                                    <InputLabel>Medication Actions</InputLabel>
                                    <Select value={selectedAction} label="Medication Actions" onChange={(e) => handleOnSelectAction(e)}>
                                        {MedicationActions.map((status, index) => (
                                            <MenuItem key={`${status.Name}-${index}`} value={status.Name}>
                                                {status.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {isGeneratingPDF && <CircularProgress size="20px" sx={classes.selectInputLoader} />}
                            </Stack>
                            {
                                <div className="d-flex-center">
                                    {!memberDetail ? (
                                        <Link
                                            onClick={() => dispatch(updateAssessmentMenu({ ActiveMenu: assessmentMenuEnum.ALLERGIES }))}
                                            underline="hover"
                                            sx={{ mr: 3 }}
                                            fontSize="16px"
                                        >
                                            {assessmentMenuEnum.ALLERGIES}
                                        </Link>
                                    ) : (
                                        <Button color="primary" onClick={handleViewAllergies} sx={allergiesClass}>
                                            Allergies
                                        </Button>
                                    )}
                                </div>
                            }
                        </Stack>
                        <hr style={classes.hr} />
                    </Grid>
                    <Grid id="table-container" item className="content-form" sx={{ p: 0, minHeight: '300px' }}>
                        <TableContainer sx={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="no-print">
                                            <FormControl style={{ minWidth: 140 }} size="small" sx={classes.headerSelectInput}>
                                                <Select
                                                    value={selectedMeds}
                                                    displayEmpty
                                                    className="hide-textinput-label"
                                                    onChange={(e) => handleOnChangeMeds(e.target.value)}
                                                >
                                                    <MenuItem value="">Select Meds</MenuItem>
                                                    <MenuItem value="all">All Meds</MenuItem>
                                                    {medicationFrequencyTimes.map((status, index) => (
                                                        <MenuItem key={`${status.Frequency}-${index}`} value={status.Frequency}>
                                                            {status.Frequency}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>Medication Name</TableCell>
                                        <TableCell>Dosage</TableCell>
                                        <TableCell>Frequency</TableCell>
                                        <TableCell>Prescribed For</TableCell>
                                        <TableCell width="105px">Fill Date</TableCell>
                                        <TableCell width="105px">End Date</TableCell>
                                        <TableCell width="105px">Last Reconciled</TableCell>
                                        <TableCell>Ordering Provider</TableCell>
                                        <TableCell className="no-print">Display on PDF</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {medications.length ? (
                                        medications.map((medication, index) => (
                                            <TableRow
                                                key={`summary-note-${medication.Id}-${index}`}
                                                hover
                                                sx={[prepareMedicationStatusColor(medication), { '&:last-child td, &:last-child th': { border: 0 } }]}
                                            >
                                                <TableCell className="no-print">
                                                    <Stack direction="row">
                                                        <Checkbox
                                                            size="small"
                                                            checked={Boolean(medication.IsSelected)}
                                                            onChange={(e) => handleOnCheckMedication(e.currentTarget.checked, index)}
                                                        />
                                                        {renderSchedulePopover(medication)}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>{medication.Medicine}</TableCell>
                                                <TableCell>{medication.Dosage}</TableCell>
                                                <TableCell>{medication.Frequency}</TableCell>
                                                <TableCell>{medication.PrescribedFor}</TableCell>
                                                <TableCell>
                                                    {moment(medication.StartDate).isValid() ? moment(medication.StartDate).format('MM-DD-yyyy') : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(medication.EndDate).isValid() ? moment(medication.EndDate).format('MM-DD-yyyy') : '-'}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(medication.ReconcileDate).isValid() ? moment(medication.ReconcileDate).format('MM-DD-yyyy') : '-'}
                                                </TableCell>
                                                <TableCell>{medication.OrderingProvider?.Name || '-'}</TableCell>

                                                <TableCell className="no-print">
                                                    <Checkbox
                                                        size="small"
                                                        checked={medication.DisplayOnReportPdf}
                                                        onChange={(e) => handleOnSelectDisplayPdf(e.currentTarget.checked, index)}
                                                    />
                                                </TableCell>

                                                <TableCell className="no-print">
                                                    <Stack direction="row">
                                                        <IconButton onClick={() => handleOnShowModal(medication)}>
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => handleOnClickDelete(medication)}
                                                            sx={{ mr: 2 }}
                                                            disabled={deletingMedicationIds.some((d) => d === medication.Id)}
                                                        >
                                                            {deletingMedicationIds.some((d) => d === medication.Id) ? (
                                                                <CircularProgress size={18} />
                                                            ) : (
                                                                <DeleteIcon color="error" fontSize="small" />
                                                            )}
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                                                <Typography variant="subtitle1" component="label">
                                                    No Medications.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {!memberDetail && (
                        <Grid item justifyContent="flex-end" className="content-footer">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ mr: 3 }}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button type="submit" variant="contained" onClick={() => handleOnClickSave()} endIcon={<ArrowForwardIosIcon />}>
                                Next
                            </Button>
                        </Grid>
                    )}
                    {renderStatusModal()}
                    {renderDiscontinueReasonModal()}
                    {renderAllergiesModal()}
                    <AssignPeriodsDue medications={medications} memberId={memberId} onChange={handleOnChangeData} />
                    <MergeMedications medications={medications.filter((m) => m.IsSelected)} memberId={memberId} onChange={handleOnChangeData} />
                    <AddOrUpdateMedication memberId={memberId} onChange={handleOnChangeData} />
                    <AlertDialog
                        open={showDeleteAlert}
                        title="Delete Medication"
                        okText="Confirm"
                        onConfirm={handleOnConfirmedDelete}
                        isLoading={isSavingMedication}
                        onClose={() => setShowDeleteAlert(false)}
                        message="Are you sure want to delete this medication?"
                    />
                </>
            )}
            {Boolean(engagementDraft.MedicationReconciliationStatus === StatusEnum.InProgress) ||
            Boolean(engagementDraft.MedicationReconciliationStatus === StatusEnum.Completed) ||
            isChanged ||
            medications?.some((m) => m.DisplayOnReportPdf) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Medication Reconciliation"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSavingEngagementDraft}
                    message="I confirm and acknowledge that Medication Reconciliation is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Medication Reconciliation"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    message="Are you sure you want to proceed without making changes to Medication Reconciliation?"
                />
            )}

            <AlertDialog
                open={showEmptyMedicWaring}
                title={
                    <Typography variant="h6" component="h6" sx={{ pl: 0 }}>
                        <PriorityHighIcon color="error" fontSize="medium" className="float-left" sx={{ mt: 1 }} />
                        <div className="float-left">Medication Reconciliation</div>
                    </Typography>
                }
                cancelText="No"
                okText="Yes"
                okButtonColor="error"
                onConfirm={() => handleOnClickSave(true)}
                onClose={() => setShowEmptyMedicWaring(false)}
                message="You have not marked any meds to be included in your assessment. Are you sure you want to continue without including medications?"
            />

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleOnCloseSchedule}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Stack sx={{ p: 2 }}>
                    {(selectedPopoverMedication?.MedicationSchedule || []).map((schedule, index) => (
                        <Typography key={`period_time_${index}`} variant="body1" component="label">
                            {schedule}
                        </Typography>
                    ))}
                </Stack>
            </Popover>
        </Grid>
    );
};

export default MedicationReconciliation;
