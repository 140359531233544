import BaseEntity from '../BaseEntity.model';
import { TransitionalCareStatusEnum } from './../../Enum/TransitionalCareStatusEnum';
import { TransitionStep } from './TransitionStep';

export default interface INewMemberTransitionalCare extends BaseEntity {
    MemberId: string;
    TransitionSteps: TransitionStep[];
    Status: TransitionalCareStatusEnum;
    IsClosedDueToDeath: boolean;
    IsDeleted: boolean;
}

export class NewMemberTransitionalCare implements INewMemberTransitionalCare {
    Id: string;
    MemberId: string;
    MemberName: string;
    HealthPlan: string;
    Priority: number;
    TransitionSteps: TransitionStep[];
    Status: TransitionalCareStatusEnum | number;
    IsClosedDueToDeath: boolean;
    IsDeleted: boolean;

    constructor() {
        this.TransitionSteps = [] as TransitionStep[];
        this.Status = 0;
        this.MemberId = '';
        this.IsClosedDueToDeath = false;
        this.IsDeleted = false;
    }
}
