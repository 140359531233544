import { AnswerOptionsEnum } from '../../Enum/AnswerOptionsEnum';
import { HealthEvaluationResponseEnum } from '../../Enum/HealthEvaluationResponseEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IScreeningFuhFum extends BaseEntity {
    MemberId: string;
    HealthEvaluationInLastSixMonths?: HealthEvaluationInLastSixMonths;
    InpatientHospitalizationsLastThreeMonths?: InpatientHospitalizationsLastThreeMonths;
    EDVisitsInLastThreeMonths?: EDVisitsInLastThreeMonths;
    MemberLeftAMA: MemberLeftAMA;
    Status: StatusEnum;
}

export class ScreeningFuhFum implements IScreeningFuhFum {
    Id: string;
    MemberId: string;
    HealthEvaluationInLastSixMonths?: HealthEvaluationInLastSixMonths;
    InpatientHospitalizationsLastThreeMonths?: InpatientHospitalizationsLastThreeMonths;
    EDVisitsInLastThreeMonths?: EDVisitsInLastThreeMonths;
    MemberLeftAMA: MemberLeftAMA;
    Notes: string;
    Status: StatusEnum;

    constructor() {
        this.MemberId = '';
    }
}

export class HealthEvaluationInLastSixMonths {
    Response: HealthEvaluationResponseEnum;
    Reason: string;

    constructor() {
        this.Reason = '';
    }
}

export class EDVisitsInLastThreeMonths {
    Response: AnswerOptionsEnum;
    NumberOfVisits?: number;

    constructor() {
        this.NumberOfVisits = null;
    }
}

export class InpatientHospitalizationsLastThreeMonths {
    Response: AnswerOptionsEnum;
    NumberOfVisits?: number;

    constructor() {
        this.NumberOfVisits = null;
    }
}

export class MemberLeftAMA {
    Response: AnswerOptionsEnum;
    NumberOfVisits?: number;

    constructor() {
        this.NumberOfVisits = null;
    }
}