import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { IntegratedCarePlan } from '../Models/CarePlans/IntegratedCarePlan.model';
import { Goal } from '../Models/CarePlans/Goal.model';
import { convertObjectToArray, copyObject } from '../utils/common';
import { Plan } from '../Models/CarePlans/Plan.model';
import { EngagementDraft } from '../Models/DocumentEngagements/EngagementDraft.model';
import { Role } from '../Models/Role/Role.model';
import { SchedulePlan } from '../Models/CarePlans/SchedulePlan.model';
import { Barrier } from '../Models/CarePlans/Barrier.model';
import { Strength } from '../Models/CarePlans/Strength.model';
import { Metric } from '../Models/CarePlans/Metric.model';
import { NextStep } from '../Models/CarePlans/NextStep.model';
import { Intervention } from '../Models/CarePlans/Intervention.model';
import { Progress } from '../Models/CarePlans/Progress.model';
import { MessageAlert } from '../Models/Shared/MessageAlert.model';
import { CarePlanEnum } from '../Enum/CarePlanEnum';
import { CarePlanSignatureTypeEnum } from '../Enum/CarePlanSignatureTypeEnum';
import { CarePlanSignatureRequest } from '../Models/CarePlans/CarePlanSignatureRequest';
import { ReviewHistory } from '../Models/CarePlans/ReviewHistory.model';
import moment from 'moment';

const carePlanSlice = createSlice({
    name: 'carePlan',
    initialState: {
        integratedCarePlan: new IntegratedCarePlan(),
        selectedGoal: new Goal(),
        selectedPlan: new Plan(),
        selectedSchedulePlan: new SchedulePlan(),
        reasonsForDelete: [],
        roles: [] as Role[],
        signatureType: CarePlanSignatureTypeEnum.Provider,
        interventions: [] as Intervention[],
        memberInterventions: [] as Intervention[],
        memberSignature: '',
        newPlanName: '',
        newBarrierName: '',
        newStrengthName: '',
        newMetricName: '',
        newNextStepName: '',
        newInterventionName: '',
        providerSignature: '',
        showPlanEditMode: false,
        showInterventionEditMode: false,
        showGoalDeleteAlert: false,
        showPlanDeleteAlert: false,
        showInterventionDeleteAlert: false,
        showSchedulePlanDeleteAlert: false,
        showSignatureModal: false,
        isFetching: false,
        isFetchingInterventions: false,
        isSavingGoal: false,
        isSavingGoalProgress: false,
        isSavingPlan: false,
        isSavingPlanProgress: false,
        isSavingMetricProgress: false,
        isSavingNextStepProgress: false,
        isSavingStrengthProgress: false,
        isSavingIntervention: false,
        isUpdatingIntervention: false,
        isSavingSchedulePlan: false,
        isSavingBarrier: false,
        isSavingBarrierProgress: false,
        showBarrierEditMode: false,
        showBarrierDeleteAlert: false,
        isSavingStrength: false,
        showStrengthEditMode: false,
        showStrengthDeleteAlert: false,
        isSavingMetric: false,
        showMetricEditMode: false,
        showMetricDeleteAlert: false,
        isSavingNextStep: false,
        showNextStepEditMode: false,
        showNextStepDeleteAlert: false,
        isSavingReviewHistory: false,
        error: new MessageAlert(),
        verbalConsentName: '',
    },
    reducers: {
        _setIntegratedCarePlan(state, action) {
            state.integratedCarePlan = action.payload;
            state.showGoalDeleteAlert = false;
        },
        _setMemberSignature(state, action) {
            state.memberSignature = action.payload;
        },
        _setProviderSignature(state, action) {
            state.providerSignature = action.payload;
        },
        _setSignatureType(state, action) {
            state.signatureType = action.payload;
        },
        _setSelectedGoal(state, action) {
            state.selectedGoal = action.payload;
        },
        _setSelectedPlan(state, action) {
            state.selectedPlan = action.payload;
        },
        _setSelectedSchedulePlan(state, action) {
            state.selectedSchedulePlan = action.payload;
        },
        _setReasonsForDelete(state, action) {
            state.reasonsForDelete = action.payload;
        },
        _setRoles(state, action) {
            state.roles = action.payload;
        },
        _setMemberInterventions(state, action) {
            state.memberInterventions = action.payload;
        },
        _setInterventions(state, action) {
            state.interventions = action.payload;
        },
        _setNewPlanName(state, action) {
            state.newPlanName = action.payload;
        },
        _setNewInterventionName(state, action) {
            state.newInterventionName = action.payload;
        },
        _setNewBarrierName(state, action) {
            state.newBarrierName = action.payload;
        },
        _setNewStrengthName(state, action) {
            state.newStrengthName = action.payload;
        },
        _setNewMetricName(state, action) {
            state.newMetricName = action.payload;
        },
        _setNewNextStepName(state, action) {
            state.newNextStepName = action.payload;
        },
        _setShowSignatureModal(state, action) {
            state.showSignatureModal = action.payload;
        },
        _toggleShowGoalDeleteAlert(state, action) {
            state.showGoalDeleteAlert = action.payload;
        },
        _toggleShowPlanDeleteAlert(state, action) {
            state.showPlanDeleteAlert = action.payload;
        },
        _toggleShowInterventionDeleteAlert(state, action) {
            state.showInterventionDeleteAlert = action.payload;
        },
        _toggleShowBarrierDeleteAlert(state, action) {
            state.showBarrierDeleteAlert = action.payload;
        },
        _toggleShowStrengthDeleteAlert(state, action) {
            state.showStrengthDeleteAlert = action.payload;
        },
        _toggleShowMetricDeleteAlert(state, action) {
            state.showMetricDeleteAlert = action.payload;
        },
        _toggleShowNextStepDeleteAlert(state, action) {
            state.showNextStepDeleteAlert = action.payload;
        },
        _toggleShowSchedulePlanDeleteAlert(state, action) {
            state.showSchedulePlanDeleteAlert = action.payload;
        },
        _toggleShowPlanEditMode(state, action) {
            state.showPlanEditMode = action.payload;
        },
        _toggleShowInterventionEditMode(state, action) {
            state.showInterventionEditMode = action.payload;
        },
        _toggleShowBarrierEditMode(state, action) {
            state.showBarrierEditMode = action.payload;
        },
        _toggleShowStrengthEditMode(state, action) {
            state.showStrengthEditMode = action.payload;
        },
        _toggleShowMetricEditMode(state, action) {
            state.showMetricEditMode = action.payload;
        },
        _toggleShowNextStepEditMode(state, action) {
            state.showNextStepEditMode = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsFetchingInterventions(state, action) {
            state.isFetchingInterventions = action.payload;
        },
        _toggleIsSavingGoal(state, action) {
            state.isSavingGoal = action.payload;
        },
        _toggleIsSavingGoalProgress(state, action) {
            state.isSavingGoalProgress = action.payload;
        },
        _toggleIsSavingPlan(state, action) {
            state.isSavingPlan = action.payload;
        },
        _toggleIsSavingPlanProgress(state, action) {
            state.isSavingPlanProgress = action.payload;
        },
        _toggleIsSavingIntervention(state, action) {
            state.isSavingIntervention = action.payload;
        },
        _toggleIsUpdatingIntervention(state, action) {
            state.isUpdatingIntervention = action.payload;
        },
        _toggleIsSavingBarrier(state, action) {
            state.isSavingBarrier = action.payload;
        },
        _toggleIsSavingBarrierProgress(state, action) {
            state.isSavingBarrierProgress = action.payload;
        },
        _toggleIsSavingStrength(state, action) {
            state.isSavingStrength = action.payload;
        },
        _toggleIsSavingMetric(state, action) {
            state.isSavingMetric = action.payload;
        },
        _toggleIsSavingNextStep(state, action) {
            state.isSavingNextStep = action.payload;
        },
        _toggleIsSavingSchedulePlan(state, action) {
            state.isSavingSchedulePlan = action.payload;
        },
        _toggleIsSavingMetricProgress(state, action) {
            state.isSavingMetricProgress = action.payload;
        },
        _toggleIsSavingNextStepProgress(state, action) {
            state.isSavingNextStepProgress = action.payload;
        },
        _toggleIsSavingStrengthProgress(state, action) {
            state.isSavingStrengthProgress = action.payload;
        },
        _toggleIsSavingReviewHistory(state, action) {
            state.isSavingReviewHistory = action.payload;
        },
        _setError(state, action) {
            state.error = action.payload;
        },
        _setVerbalConsentName(state, action) {
            state.verbalConsentName = action.payload;
        },
    },
});

const {
    _setIntegratedCarePlan,
    _setSelectedGoal,
    _setSelectedPlan,
    _setSelectedSchedulePlan,
    _setSignatureType,
    _setReasonsForDelete,
    _setRoles,
    _setInterventions,
    _setMemberInterventions,
    _setMemberSignature,
    _setNewPlanName,
    _setNewInterventionName,
    _setNewBarrierName,
    _setNewStrengthName,
    _setNewMetricName,
    _setNewNextStepName,
    _setProviderSignature,
    _setShowSignatureModal,
    _toggleShowGoalDeleteAlert,
    _toggleShowPlanDeleteAlert,
    _toggleShowInterventionDeleteAlert,
    _toggleShowBarrierDeleteAlert,
    _toggleShowStrengthDeleteAlert,
    _toggleShowMetricDeleteAlert,
    _toggleShowNextStepDeleteAlert,
    _toggleShowSchedulePlanDeleteAlert,
    _toggleShowPlanEditMode,
    _toggleShowInterventionEditMode,
    _toggleShowBarrierEditMode,
    _toggleShowStrengthEditMode,
    _toggleShowMetricEditMode,
    _toggleShowNextStepEditMode,
    _toggleIsFetching,
    _toggleIsFetchingInterventions,
    _toggleIsSavingGoal,
    _toggleIsSavingGoalProgress,
    _toggleIsSavingPlan,
    _toggleIsSavingPlanProgress,
    _toggleIsSavingIntervention,
    _toggleIsUpdatingIntervention,
    _toggleIsSavingSchedulePlan,
    _toggleIsSavingBarrier,
    _toggleIsSavingBarrierProgress,
    _toggleIsSavingStrength,
    _toggleIsSavingMetric,
    _toggleIsSavingNextStep,
    _toggleIsSavingMetricProgress,
    _toggleIsSavingNextStepProgress,
    _toggleIsSavingStrengthProgress,
    _toggleIsSavingReviewHistory,
    _setError,
    _setVerbalConsentName,
} = carePlanSlice.actions;

export const getCarePlanByMemberId =
    (memberId: string, fromSave = false, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        if (!fromSave) {
            dispatch(_toggleIsFetching(true));
        }
        const response = await axios.get(`${API_URL}/careplan/getcareplanbymemberid`, { params: { memberId } });
        if (!fromSave) {
            response.data = prepareGoalsAndPlans(response.data, getState().documentEngagement?.engagementDraft);
        }

        dispatch(_setIntegratedCarePlan(response.data));
        dispatch(_toggleIsFetching(false));
        if (callBack) {
            callBack(response.data);
        }
    };

const prepareGoalsAndPlans = (integratedCarePlan: IntegratedCarePlan, engagementDraft: EngagementDraft) => {
    [
        ...(integratedCarePlan.Goals || []),
        ...(integratedCarePlan.SchedulePlans || []),
        ...(integratedCarePlan.Plans || []),
        ...(integratedCarePlan.Barriers || []),
        ...(integratedCarePlan.Strengths || []),
        ...(integratedCarePlan.Metrics || []),
        ...(integratedCarePlan.NextSteps || []),
    ].forEach((plan) => {
        plan.IsSelected = engagementDraft.SummaryNotes?.some((s) => s.Id === plan.Id);
    });

    return integratedCarePlan;
};

export const setIntegratedCarePlan =
    (integratedCarePlan: IntegratedCarePlan): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setIntegratedCarePlan(integratedCarePlan));
    };

export const setSignatureType =
    (signatureType: CarePlanSignatureTypeEnum): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSignatureType(signatureType));
    };

export const updateLastReviewedBy =
    (carePlanSignatureRequest: CarePlanSignatureRequest, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingReviewHistory(true));
        await axios
            .put(`${API_URL}/careplan/updatelastreviewedby`, null, { params: carePlanSignatureRequest })
            .then((response) => {
                dispatch(setIntegratedCarePlan(response.data));
                if (callBack) {
                    callBack(response.data);
                }
            })
            .finally(() => {
                dispatch(_toggleIsSavingReviewHistory(false));
            });
    };

export const addGoal =
    (memberId: string, name: string, dueDate: string): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        dispatch(_toggleIsSavingGoal(true));
        const response = await axios.put(`${API_URL}/careplan/addgoal`, { name, dueDate }, { params: { memberId } });

        const copy = copyObject(response.data);
        const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        copy.UpdatedAt = updatedAtUTC;
        dispatch(_setIntegratedCarePlan({ ...integratedCarePlan, Goals: [copy, ...integratedCarePlan.Goals] }));
        dispatch(_toggleIsSavingGoal(false));
        dispatch(_setSelectedGoal(new Goal()));
    };

export const editGoals =
    (memberId: string, goals: Goal[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingGoal(true));
        await axios
            .put(`${API_URL}/careplan/editgoals`, goals, { params: { memberId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Goals = carePlanData.Goals.map((goal) => {
                    const editedGoal = goals.find((g) => g.Id === goal.Id);
                    let copy = null;
                    if (editedGoal !== undefined) {
                        copy = copyObject(editedGoal);
                        const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        copy.UpdatedAt = now;
                    }
                    return Boolean(copy) ? copy : goal;
                });
                dispatch(setIntegratedCarePlan(carePlanData));
                dispatch(_setSelectedGoal(new Goal()));
            })
            .finally(() => {
                dispatch(_toggleIsSavingGoal(false));
            });
    };

export const addGoalProgress =
    (memberId: string, goalId: string, progressNote: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingGoalProgress(true));
        const progress = new Progress();
        progress.Name = progressNote;
        axios
            .put(`${API_URL}/careplan/addgoalprogress`, progress, { params: { memberId, goalId } })
            .then((response) => {
                let data = copyObject(getState().carePlan.integratedCarePlan);
                data.Goals.forEach((goal) => {
                    if (goal.Id === goalId && Boolean(goal.NewProgressNote)) {
                        goal.Status = goal.Status === CarePlanEnum.Open ? CarePlanEnum.InProgress : goal.Status;
                        goal.Progress = goal.Progress || [];
                        goal.Progress.push(response.data);
                        goal.NewProgressNote = '';
                    }
                });
                dispatch(setIntegratedCarePlan(data));
            })
            .finally(() => {
                dispatch(_toggleIsSavingGoalProgress(false));
            });
    };

export const setMemberSignature =
    (signature: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberSignature(signature));
    };

export const setProviderSignature =
    (signature: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setProviderSignature(signature));
    };

export const setSelectedGoal =
    (goal: Goal): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedGoal(goal));
    };

export const setShowSignatureModal =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowSignatureModal(show));
    };

export const toggleShowGoalDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowGoalDeleteAlert(show));
    };

export const toggleShowPlanDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowPlanDeleteAlert(show));
    };

export const getReasonForDelete = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getresonfordelete`);
    dispatch(_setReasonsForDelete(convertObjectToArray(response.data)));
};

export const handleSendToMember =
    (memberId: string, share: boolean): AppThunk =>
    async (dispatch, getState) => {
        const integratedCarePlan = getState().carePlan.integratedCarePlan;
        dispatch(setIntegratedCarePlan({ ...integratedCarePlan, ShareWithMember: share }));
        await axios.put(`${API_URL}/careplan/sharewithmember?memberId=${memberId}&share=${share}`);
    };

export const setSelectedPlan =
    (plan: Plan): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedPlan(plan));
    };

export const setVerbalConsentName =
    (name: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setVerbalConsentName(name));
    };

export const addPlan =
    (memberId: string, name: string): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        dispatch(_toggleIsSavingPlan(true));
        const response = await axios.put(`${API_URL}/careplan/addplan`, { Name: name }, { params: { memberId } });
        const copy = copyObject(response.data);
        const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        copy.UpdatedAt = updatedAtUTC;
        dispatch(_setIntegratedCarePlan({ ...integratedCarePlan, Plans: [copy, ...integratedCarePlan.Plans] }));
        dispatch(_toggleIsSavingPlan(false));
        dispatch(_setNewPlanName(''));
    };

export const editPlans =
    (memberId: string, plans: Plan[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingPlan(true));
        await axios
            .put(`${API_URL}/careplan/editplans`, plans, { params: { memberId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Plans = carePlanData.Plans.map((plan) => {
                    const editedPlan = plans.find((p) => p.Id === plan.Id);
                    let copy = null;
                    if (editedPlan !== undefined) {
                        copy = copyObject(editedPlan);
                        const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        copy.UpdatedAt = now;
                    }
                    return Boolean(copy) ? copy : plan;
                });
                dispatch(setIntegratedCarePlan(carePlanData));
                dispatch(_setSelectedPlan(new Plan()));
            })
            .finally(() => {
                dispatch(_toggleIsSavingPlan(false));
                dispatch(_toggleShowPlanEditMode(false));
                dispatch(_toggleShowPlanDeleteAlert(false));
            });
    };

export const addPlanProgress =
    (memberId: string, planId: string, progressNote: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingPlanProgress(true));
        const progress = new Progress();
        progress.Name = progressNote;
        axios
            .put(`${API_URL}/careplan/addplanprogress`, progress, { params: { memberId, planId } })
            .then((response) => {
                let data = copyObject(getState().carePlan.integratedCarePlan);
                data.Plans.forEach((plan) => {
                    if (plan.Id === planId && Boolean(plan.NewProgressNote)) {
                        plan.Status = plan.Status === CarePlanEnum.Open ? CarePlanEnum.InProgress : plan.Status;
                        plan.Progress = plan.Progress || [];
                        plan.Progress.push(response.data);
                        plan.NewProgressNote = '';
                    }
                });
                dispatch(setIntegratedCarePlan(data));
            })
            .finally(() => {
                dispatch(_toggleIsSavingPlanProgress(false));
            });
    };

export const removePlan =
    (memberId: string, planId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingPlan(true));
        await axios
            .delete(`${API_URL}/careplan/removeplan`, { params: { memberId, planId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Plans = carePlanData.Plans.filter((p) => p.Id !== planId);
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingPlan(false));
                dispatch(_toggleShowPlanDeleteAlert(false));
            });
    };

export const toggleShowPlanEditMode =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowPlanEditMode(show));
    };

export const setNewPlanName =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNewPlanName(note));
    };

export const getRoles = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/provider/getroles`);
    dispatch(_setRoles(response.data));
};

export const setSelectedSchedulePlan =
    (schedulePlan: SchedulePlan): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedSchedulePlan(schedulePlan));
    };

export const toggleShowSchedulePlanDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowSchedulePlanDeleteAlert(show));
    };

export const addSchedulePlan =
    (memberId: string, schedulePlan: SchedulePlan): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        dispatch(_toggleIsSavingSchedulePlan(true));
        axios
            .put(`${API_URL}/careplan/addscheduleplan`, schedulePlan, { params: { memberId } })
            .then((response) => {
                const copy = copyObject(response.data);
                const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                copy.UpdatedAt = updatedAtUTC;
                dispatch(_setIntegratedCarePlan({ ...integratedCarePlan, SchedulePlans: [copy, ...integratedCarePlan.SchedulePlans] }));
                dispatch(_setSelectedSchedulePlan({}));
            })
            .catch((err) => {
                dispatch(setCarePlanError({ Show: true, Message: err?.response?.data?.error }));
            })
            .finally(() => {
                dispatch(_toggleIsSavingSchedulePlan(false));
            });
    };

export const editSchedulePlan =
    (memberId: string, schedulePlan: SchedulePlan): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        const existingProviderId = integratedCarePlan.SchedulePlans.find((s) => s.Id === schedulePlan.Id)?.Provider?.Id;
        dispatch(_toggleIsSavingSchedulePlan(true));
        const response = await axios.put(`${API_URL}/careplan/editscheduleplan`, schedulePlan, { params: { memberId, existingProviderId } });
        let copy = copyObject(response.data);
        const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        copy.UpdatedAt = updatedAtUTC;
        dispatch(
            _setIntegratedCarePlan({
                ...integratedCarePlan,
                SchedulePlans: [...integratedCarePlan.SchedulePlans.map((s) => (s.Id === schedulePlan.Id ? copy : s))],
            })
        );
        dispatch(_toggleIsSavingSchedulePlan(false));
        dispatch(_setSelectedSchedulePlan(new SchedulePlan()));
    };

export const removeSchedulePlan =
    (memberId: string, providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingSchedulePlan(true));
        await axios
            .delete(`${API_URL}/careplan/removescheduleplan`, { params: { memberId, providerId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.SchedulePlans = carePlanData.SchedulePlans.filter((p) => p.Provider?.Id !== providerId);
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingSchedulePlan(false));
                dispatch(_toggleShowSchedulePlanDeleteAlert(false));
            });
    };

// Barrier start

export const addBarrier =
    (memberId: string, name: string): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        dispatch(_toggleIsSavingBarrier(true));
        const response = await axios.put(`${API_URL}/careplan/addbarrier`, { Name: name }, { params: { memberId } });
        const copy = copyObject(response.data);
        const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        copy.UpdatedAt = updatedAtUTC;
        dispatch(_setIntegratedCarePlan({ ...integratedCarePlan, Barriers: [copy, ...integratedCarePlan.Barriers] }));
        dispatch(_toggleIsSavingBarrier(false));
        dispatch(_setNewBarrierName(''));
    };

export const addBarrierProgress =
    (memberId: string, barrierId: string, progressNote: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingBarrierProgress(true));
        const progress = new Progress();
        progress.Name = progressNote;
        axios
            .put(`${API_URL}/careplan/addbarrierprogress`, progress, { params: { memberId, barrierId } })
            .then((response) => {
                let data = copyObject(getState().carePlan.integratedCarePlan);
                data.Barriers.forEach((barrier) => {
                    if (barrier.Id === barrierId && Boolean(barrier.NewProgressNote)) {
                        barrier.Progress = barrier.Progress || [];
                        barrier.Progress.push(response.data);
                        barrier.NewProgressNote = '';
                    }
                });
                dispatch(setIntegratedCarePlan(data));
            })
            .finally(() => {
                dispatch(_toggleIsSavingBarrierProgress(false));
            });
    };

export const editBarriers =
    (memberId: string, barriers: Barrier[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingBarrier(true));
        await axios
            .put(`${API_URL}/careplan/editbarriers`, barriers, { params: { memberId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Barriers = carePlanData.Barriers.map((barrier) => {
                    const editedBarrier = barriers.find((b) => b.Id === barrier.Id);
                    let copy = null;
                    if (editedBarrier !== undefined) {
                        copy = copyObject(editedBarrier);
                        const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        copy.UpdatedAt = now;
                    }
                    return Boolean(copy) ? copy : barrier;
                });
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingBarrier(false));
                dispatch(_toggleShowBarrierEditMode(false));
            });
    };

export const removeBarrier =
    (memberId: string, barrierId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingBarrier(true));
        await axios
            .delete(`${API_URL}/careplan/removebarrier`, { params: { memberId, barrierId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Barriers = carePlanData.Barriers.filter((p) => p.Id !== barrierId);
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingBarrier(false));
                dispatch(_toggleShowBarrierDeleteAlert(false));
            });
    };

export const toggleShowBarrierEditMode =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowBarrierEditMode(show));
    };

export const setNewBarrierName =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNewBarrierName(note));
    };

export const toggleShowBarrierDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowBarrierDeleteAlert(show));
    };

// Barrier End

// Strength start

export const addStrength =
    (memberId: string, name: string): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        dispatch(_toggleIsSavingStrength(true));
        const response = await axios.put(`${API_URL}/careplan/addstrength`, { Name: name }, { params: { memberId } });
        const copy = copyObject(response.data);
        const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        copy.UpdatedAt = updatedAtUTC;
        dispatch(_setIntegratedCarePlan({ ...integratedCarePlan, Strengths: [copy, ...integratedCarePlan.Strengths] }));
        dispatch(_toggleIsSavingStrength(false));
        dispatch(_setNewStrengthName(''));
    };

export const addStrengthProgress =
    (memberId: string, strengthId: string, progressNote: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingStrengthProgress(true));
        const progress = new Progress();
        progress.Name = progressNote;
        axios
            .put(`${API_URL}/careplan/addstrengthprogress`, progress, { params: { memberId, strengthId } })
            .then((response) => {
                let data = copyObject(getState().carePlan.integratedCarePlan);
                data.Strengths.forEach((strength) => {
                    if (strength.Id === strengthId && Boolean(strength.NewProgressNote)) {
                        strength.Status = strength.Status === CarePlanEnum.Open ? CarePlanEnum.InProgress : strength.Status;
                        strength.Progress = strength.Progress || [];
                        strength.Progress.push(response.data);
                        strength.NewProgressNote = '';
                    }
                });
                dispatch(setIntegratedCarePlan(data));
            })
            .finally(() => {
                dispatch(_toggleIsSavingStrengthProgress(false));
            });
    };

export const editStrengths =
    (memberId: string, strengths: Strength[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingStrength(true));
        await axios
            .put(`${API_URL}/careplan/editstrengths`, strengths, { params: { memberId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Strengths = carePlanData.Strengths.map((strength) => {
                    const editedStrength = strengths.find((s) => s.Id === strength.Id);
                    let copy = null;
                    if (editedStrength !== undefined) {
                        copy = copyObject(editedStrength);
                        const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        copy.UpdatedAt = now;
                    }
                    return Boolean(copy) ? copy : strength;
                });
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingStrength(false));
                dispatch(_toggleShowStrengthEditMode(false));
            });
    };

export const removeStrength =
    (memberId: string, strengthId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingStrength(true));
        await axios
            .delete(`${API_URL}/careplan/removestrength`, { params: { memberId, strengthId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Strengths = carePlanData.Strengths.filter((p) => p.Id !== strengthId);
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingStrength(false));
                dispatch(_toggleShowStrengthDeleteAlert(false));
            });
    };

export const toggleShowStrengthEditMode =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowStrengthEditMode(show));
    };

export const setNewStrengthName =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNewStrengthName(note));
    };

export const toggleShowStrengthDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowStrengthDeleteAlert(show));
    };

// Strength End

// Metric start

export const addMetric =
    (memberId: string, name: string): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        dispatch(_toggleIsSavingMetric(true));
        const response = await axios.put(`${API_URL}/careplan/addmetric`, { Name: name }, { params: { memberId } });
        const copy = copyObject(response.data);
        const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        copy.UpdatedAt = updatedAtUTC;
        dispatch(_setIntegratedCarePlan({ ...integratedCarePlan, Metrics: [copy, ...integratedCarePlan.Metrics] }));
        dispatch(_toggleIsSavingMetric(false));
        dispatch(_setNewMetricName(''));
    };

export const editMetrics =
    (memberId: string, metrics: Metric[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingMetric(true));
        await axios
            .put(`${API_URL}/careplan/editmetrics`, metrics, { params: { memberId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Metrics = carePlanData.Metrics.map((metric) => {
                    const editedMetric = metrics.find((m) => m.Id === metric.Id);
                    let copy = null;
                    if (editedMetric !== undefined) {
                        copy = copyObject(editedMetric);
                        const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        copy.UpdatedAt = now;
                    }
                    return Boolean(copy) ? copy : metric;
                });
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingMetric(false));
                dispatch(_toggleShowMetricEditMode(false));
            });
    };

// Add metric progress
export const addMetricProgress =
    (memberId: string, metricId: string, progressNote: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingMetricProgress(true));
        const progress = new Progress();
        progress.Name = progressNote;
        axios
            .put(`${API_URL}/careplan/addmetricprogress`, progress, { params: { memberId, metricId } })
            .then((response) => {
                let data = copyObject(getState().carePlan.integratedCarePlan);
                data.Metrics.forEach((metric) => {
                    if (metric.Id === metricId && Boolean(metric.NewProgressNote)) {
                        metric.Status = metric.Status === CarePlanEnum.Open ? CarePlanEnum.InProgress : metric.Status;
                        metric.Progress = metric.Progress || [];
                        metric.Progress.push(response.data);
                        metric.NewProgressNote = '';
                    }
                });
                dispatch(setIntegratedCarePlan(data));
            })
            .finally(() => {
                dispatch(_toggleIsSavingMetricProgress(false));
            });
    };

export const removeMetric =
    (memberId: string, metricId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingMetric(true));
        await axios
            .delete(`${API_URL}/careplan/removemetric`, { params: { memberId, metricId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.Metrics = carePlanData.Metrics.filter((p) => p.Id !== metricId);
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingMetric(false));
                dispatch(_toggleShowMetricDeleteAlert(false));
            });
    };

export const toggleShowMetricEditMode =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowMetricEditMode(show));
    };

export const setNewMetricName =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNewMetricName(note));
    };

export const toggleShowMetricDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowMetricDeleteAlert(show));
    };

// Metric End

// Next Step start

export const addNextStep =
    (memberId: string, name: string): AppThunk =>
    async (dispatch, getState) => {
        const { integratedCarePlan } = getState().carePlan;
        dispatch(_toggleIsSavingNextStep(true));
        const response = await axios.put(`${API_URL}/careplan/addnextstep`, { Name: name }, { params: { memberId } });
        const copy = copyObject(response.data);
        const updatedAtUTC = moment.utc(copy.UpdatedAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        copy.UpdatedAt = updatedAtUTC;
        dispatch(_setIntegratedCarePlan({ ...integratedCarePlan, NextSteps: [copy, ...integratedCarePlan.NextSteps] }));
        dispatch(_toggleIsSavingNextStep(false));
        dispatch(_setNewNextStepName(''));
    };

// Add step next progress
export const addNextStepProgress =
    (memberId: string, nextStepId: string, progressNote: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingNextStepProgress(true));
        const progress = new Progress();
        progress.Name = progressNote;
        axios
            .put(`${API_URL}/careplan/addnextstepprogress`, progress, { params: { memberId, nextStepId } })
            .then((response) => {
                let data = copyObject(getState().carePlan.integratedCarePlan);
                data.NextSteps.forEach((nextStep) => {
                    if (nextStep.Id === nextStepId && Boolean(nextStep.NewProgressNote)) {
                        nextStep.Status = nextStep.Status === CarePlanEnum.Open ? CarePlanEnum.InProgress : nextStep.Status;
                        nextStep.Progress = nextStep.Progress || [];
                        nextStep.Progress.push(response.data);
                        nextStep.NewProgressNote = '';
                    }
                });
                dispatch(setIntegratedCarePlan(data));
            })
            .finally(() => {
                dispatch(_toggleIsSavingNextStepProgress(false));
            });
    };

export const editNextSteps =
    (memberId: string, nextSteps: NextStep[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingNextStep(true));
        await axios
            .put(`${API_URL}/careplan/editnextSteps`, nextSteps, { params: { memberId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.NextSteps = carePlanData.NextSteps.map((step) => {
                    const editedStep = nextSteps.find((m) => m.Id === step.Id);
                    let copy = null;
                    if (editedStep !== undefined) {
                        copy = copyObject(editedStep);
                        const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                        copy.UpdatedAt = now;
                    }
                    return Boolean(copy) ? copy : step;
                });
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingNextStep(false));
                dispatch(_toggleShowNextStepEditMode(false));
            });
    };

export const removeNextStep =
    (memberId: string, nextStepId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingNextStep(true));
        await axios
            .delete(`${API_URL}/careplan/removenextstep`, { params: { memberId, nextStepId } })
            .then(() => {
                let carePlanData = copyObject(getState().carePlan.integratedCarePlan) as IntegratedCarePlan;
                carePlanData.NextSteps = carePlanData.NextSteps.filter((p) => p.Id !== nextStepId);
                dispatch(setIntegratedCarePlan(carePlanData));
            })
            .finally(() => {
                dispatch(_toggleIsSavingNextStep(false));
                dispatch(_toggleShowNextStepDeleteAlert(false));
            });
    };

export const toggleShowNextStepEditMode =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowNextStepEditMode(show));
    };

export const setNewNextStepName =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNewNextStepName(note));
    };

export const toggleShowNextStepDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowNextStepDeleteAlert(show));
    };

// Next Step End

// Interventions start

export const getInterventionsByMember =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/careplan/getinterventionsbymember`, { params: { memberId } });
        const engagementDraft = getState().documentEngagement?.engagementDraft;
        (response.data || []).forEach((intervention) => {
            intervention.IsSelected = engagementDraft.SummaryNotes?.some((s) => s.Id === intervention.Id);
        });
        dispatch(_setMemberInterventions(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const getInterventionsByKeyword =
    (keyword: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetchingInterventions(true));
        dispatch(_setNewInterventionName(keyword));
        const response = await axios.get(`${API_URL}/careplan/getinterventionsbykeyword`, { params: { keyword } });
        dispatch(_setInterventions(response.data));
        dispatch(_toggleIsFetchingInterventions(false));
    };

export const addIntervention =
    (memberId: string, name: string): AppThunk =>
    async (dispatch, getState) => {
        const { memberInterventions } = getState().carePlan;
        dispatch(_toggleIsSavingIntervention(true));
        const response = await axios.put(`${API_URL}/careplan/addintervention`, { MemberId: memberId, Name: name });
        dispatch(_setMemberInterventions([...memberInterventions, response.data]));
        dispatch(_toggleIsSavingIntervention(false));
        dispatch(_setNewInterventionName(''));
    };

export const updateInterventions =
    (interventions: Intervention[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsUpdatingIntervention(true));
        await axios.put(`${API_URL}/careplan/updateInterventions`, interventions);
        dispatch(_toggleIsUpdatingIntervention(false));
        dispatch(_toggleShowInterventionEditMode(false));
    };

export const removeIntervention =
    (interventionId: string): AppThunk =>
    async (dispatch, getState) => {
        const { memberInterventions } = getState().carePlan;
        dispatch(_toggleIsSavingIntervention(true));
        await axios.delete(`${API_URL}/careplan/deleteIntervention`, { params: { interventionId } });
        dispatch(_setMemberInterventions(memberInterventions.filter((m) => m.Id !== interventionId)));
        dispatch(_toggleIsSavingIntervention(false));
        dispatch(_toggleShowInterventionDeleteAlert(false));
    };

export const setMemberInterventions =
    (interventions: Intervention[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberInterventions(interventions));
    };

export const toggleShowInterventionEditMode =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowInterventionEditMode(show));
    };

export const toggleShowInterventionDeleteAlert =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleShowInterventionDeleteAlert(show));
    };

export const setNewInterventionName =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNewInterventionName(note));
    };

export const setCarePlanError =
    (error: MessageAlert): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setError(error));
    };

export const resetCarePlanError = (): AppThunk => (dispatch, getState) => {
    dispatch(_setError(new MessageAlert()));
};

export const hasBeenReviewedRecently =
    (planEntry: string, reviewDetail: ReviewHistory): AppThunk =>
    (dispatch, getState) => {
        planEntry = planEntry || null;
        const lastReviewed = reviewDetail?.LastReviewedOn || null;
        const signature = reviewDetail?.Signature || null;

        let status = false;

        if (planEntry !== null && lastReviewed !== null && signature !== null) {
            status = moment(reviewDetail?.LastReviewedOn).isAfter(moment(planEntry));
        }
        return status;
    };

export const saveProviderSignature =
    (signatureDataUrl: string): AppThunk =>
    async (dispatch, getState) => {
        const integratedCarePlan = getState().carePlan.integratedCarePlan;
        dispatch(_toggleIsFetching(true));
        const response = await axios.put(`${API_URL}/careplan/saveprovidersignature`, {
            CarePlanId: integratedCarePlan?.Id,
            Label: CarePlanSignatureTypeEnum.Provider,
            Signature: signatureDataUrl,
        });
        dispatch(setIntegratedCarePlan(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const saveMemberSignature =
    (signatureDataUrl: string, signatureType: CarePlanSignatureTypeEnum): AppThunk =>
    async (dispatch, getState) => {
        console.log('save member signature started');
        const integratedCarePlan = getState().carePlan.integratedCarePlan;
        dispatch(_toggleIsFetching(true));
        const response = await axios.put(`${API_URL}/careplan/savemembersignature`, {
            CarePlanId: integratedCarePlan?.Id,
            Label: signatureType,
            Signature: signatureDataUrl,
        });
        dispatch(setIntegratedCarePlan(response.data));
        dispatch(_toggleIsFetching(false));
    };

// export const createPDF = (): AppThunk => async (dispatch, getState) => {
//     const integratedCarePlan = getState().carePlan.integratedCarePlan;
//     dispatch(_toggleIsFetching(true));
//     const response = await axios.post(`${API_URL}/careplan/addsignaturetocareplanpdf?carePlanId=${integratedCarePlan.Id}`);
//     dispatch(_toggleIsFetching(false));
//     return response.data;
// };

// Interventions end

export default carePlanSlice.reducer;
