import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getActiveProviders } from '../../store/provider.slice';
import { fetchProviderLeaves, setSelectedCalendarDate, setSelectedProvider, setShowCalendarModal } from '../../store/providerLeave.slice';
import { isDefaultDate } from '../../utils/common';
import { SELECTED_PROVIDER_ID } from '../../utils/constants';
import { localDateAndTimeFormat } from '../../utils/timeFormat';
import CalendarModal from '../Calendar/CalendarModal';
import ProviderLeaveCalendar from '../Calendar/ProviderLeaveCalendar/ProviderLeaveCalendar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const ProviderLeave = () => {
    const classes = {
        formControl: {
            margin: useTheme().spacing(1),
            minWidth: 200,
        },
        selectEmpty: {
            marginTop: useTheme().spacing(2),
        },
    };

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            width: 200,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            width: 150,
        },
        {
            field: 'ProviderRole',
            headerName: 'Provider Role',
            width: 250,
        },
        {
            field: 'StartDate',
            headerName: 'Start Date',
            width: 220,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.StartDate) ? '-' : localDateAndTimeFormat(new Date(params.row.StartDate))}`,
        },
        {
            field: 'EndDate',
            headerName: 'End Date',
            width: 220,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.EndDate) ? '-' : localDateAndTimeFormat(new Date(params.row.EndDate))}`,
        },
        {
            field: 'AppointmentType',
            headerName: 'Type',
            width: 150,
        },
        {
            field: 'Notes',
            headerName: 'Notes',
            width: 300,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 50,
            sortable: false,
            renderCell: (cellValues) => (
                <EditIcon
                    sx={{ cursor: 'pointer' }}
                    color="primary"
                    onClick={() => {
                        showCalendar(cellValues.row.ProviderId, cellValues.row.StartDate);
                    }}
                />
            ),
        },
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = React.useState(15);
    const [localProviderLeaves, setLocalProviderLeaves] = React.useState([]);
    const { providerLeaves, selectedProvider, showCalendarModal, selectedCalendarDate } = useSelector((state: RootState) => state.providerLeave);
    const { activeProviders } = useSelector((state: RootState) => state.provider);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 65 : 72) + ''));
    };

    const filterProvider = (providerId) => {
        dispatch(setSelectedProvider(providerId as string));
        if (providerId !== 'default') {
            setLocalProviderLeaves(structuredClone(providerLeaves.filter((x) => x.ProviderId === providerId)));
        } else {
            setLocalProviderLeaves(structuredClone(providerLeaves));
        }
    };

    const showCalendar = (providerId, startDate) => {
        if (providerId) {
            localStorage.setItem(SELECTED_PROVIDER_ID, providerId);
        }
        dispatch(setSelectedProvider(providerId));
        dispatch(setSelectedCalendarDate(startDate ? startDate : selectedCalendarDate));
        dispatch(setShowCalendarModal(true));
    };

    useEffect(() => {
        dispatch(getActiveProviders(false));
        dispatch(fetchProviderLeaves());
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        filterProvider(selectedProvider);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerLeaves]);

    return (
        <Box>
            <CalendarModal
                open={showCalendarModal}
                onClose={() => dispatch(setShowCalendarModal(false))}
                calendar={<ProviderLeaveCalendar preSelectedProviderId={selectedProvider} preSelectedDate={selectedCalendarDate} width={900} />}
            />
            <Grid container spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems={'center'} spacing={2}>
                        <IconButton sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6">Provider Leave</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <FormControl sx={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Select Provider</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Provider"
                            value={selectedProvider}
                            onChange={(e) => filterProvider(e.target.value)}
                        >
                            <MenuItem value={'default'}>Show All</MenuItem>
                            {activeProviders.map((provider, index) => {
                                return (
                                    <MenuItem key={index} value={provider.Id}>
                                        {provider.LastName + ', ' + provider.FirstName + ' (' + provider.Role.RoleName + ')'}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <FormControl sx={classes.formControl}>
                        <Button variant="contained" color="primary" onClick={() => showCalendar('', new Date())}>
                            Add Provider Leave
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: '89vh', width: '100%' }}>
                        <DataGrid getRowId={(row) => row.Id} rows={localProviderLeaves} columns={columns} pageSize={pageSize} rowsPerPageOptions={[5]} />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProviderLeave;
