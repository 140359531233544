import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import phoneFormat from '../../utils/phoneFormat';
import { fetchMemberStatuses } from '../../store/members.slice';
import ProviderFacilityDialog from './ProviderFacilityDialog';
import EditIcon from '@mui/icons-material/Edit';
import Loading from '../Shared/Loading';
import RegionsDialog from './RegionsDialog';
import { getProviderRoles } from '../../store/adminProvider.slice';
import { RootState } from '../../reducers';
import { fetchAllFacilities, fetchAllProviders, fetchRoles } from '../../store/shared.slice';
import {
    addNewRegionHandler,
    addRegion,
    assignProviderHandler,
    closeAddDialogHandler,
    closeDeleteHandler,
    closeEditRegionHandler,
    deleteFromRegionHandler,
    deleteRegionHandler,
    downloadProviderCSV,
    editRegion,
    fetchRegions,
    nameOnChange,
    openAddDialogHandler,
    openDeleteHandler,
    selectOnChange,
    setRegionAction,
    setSelectionModel,
    setCurrentColumns,
    setCurrentRows,
    tabsHandler,
    updateRegionMemberCount,
    uploadProviderCSV,
} from '../../store/adminRegions.slice';
import { Search } from '@mui/icons-material';

const AdminRegions = (props) => {
    const dispatch = useDispatch();

    const { allFacilities, allProviders, roles } = useSelector((state: RootState) => state.shared);
    const {
        regionRowData,
        isNewRegion,
        regionData,
        regionAction,
        helperText,
        selectionModel,
        editRegionModal,
        deleteRegionModal,
        addDialogRows,
        columnVisibilityModel,
        currentColumns,
        currentRows,
        selectValue,
        currentTab,
        addDialog,
    } = useSelector((state: RootState) => state.adminRegions);

    // List of all Providers minus the Providers assigned to the current Region
    const availableProviders = allProviders.filter((item) => !regionData.Providers?.map((item) => item.ProviderId).includes(item.Id));

    // List of all Facilities minus the Facilities assigned to the current Region
    const availableFacilities = allFacilities.filter((item) => !regionData.Facilities?.map((item) => item.Id).includes(item.Id));

    // Creates a new list of all unique facility zip codes.
    const uniqueZipCodes = [
        ...allFacilities
            .reduce((map, obj) => {
                return map.set(obj.ZipCode, obj);
            }, new Map())
            .values(),
    ];

    // Creates a list of Zip Codes to select as a filter for facility locations
    const facilitiesOptions = uniqueZipCodes
        .sort((a, b) => a.ZipCode - b.ZipCode)
        .map((option) => {
            return (
                <MenuItem key={option.Id} value={option.ZipCode}>
                    {option.ZipCode}
                </MenuItem>
            );
        });

    // Creates a list of Roles to select as a filter for providers
    const providerOptions = roles.map((option) => {
        return (
            <MenuItem key={option.Id} value={option.Name}>
                {option.Name}
            </MenuItem>
        );
    });

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Region Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'FacilityCount',
            headerName: 'Facilities',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'ProviderCount',
            headerName: 'Providers',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'MembersCount',
            headerName: 'Members',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit" placement="top">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => dispatch(editRegion(params.id))} />
                        </Tooltip>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon color="error" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => dispatch(openDeleteHandler(params))} />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const providerColumns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'RoleName',
            headerName: 'Role',
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.Role ? params.row.Role.RoleName : params.row.RoleName}`,
        },
        {
            field: 'Email',
            headerName: 'Email',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'MobilePhone',
            headerName: 'MobilePhone',
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.MobilePhone ? phoneFormat(params.row.MobilePhone) : 'n/a'}`,
        },
        {
            field: 'action',
            headerName: 'Remove',
            sortable: false,
            minWidth: 200,

            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(deleteFromRegionHandler(params, regionData, currentTab, allProviders, allFacilities))}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const facilitiesColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'State',
            headerName: 'State',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Remove',
            sortable: false,
            minWidth: 200,

            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => dispatch(deleteFromRegionHandler(params, regionData, currentTab, allProviders, allFacilities))}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const [regionSearchValue, setRegionSearchValue] = useState('');
    const [localRegions, setLocalRegions] = useState([]);
    function executeSearch() {
        var tmp = [];

        const searchValue = regionSearchValue.toLowerCase();
        for (const region of regionRowData) {
            if (region.Name && region.Name.toLowerCase().indexOf(searchValue) > -1) {
                tmp.push(region);
            }
        }

        setLocalRegions(tmp);
    }

    const changeSearch = (event: any) => {
        if (!event.key || event.key === 'Enter') {
            executeSearch();
        }
    };

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 54 : 58) + ''));
    };

    useEffect(() => {
        dispatch(fetchRegions());
        dispatch(fetchAllProviders());
        dispatch(fetchRoles());
        dispatch(fetchAllFacilities());
        dispatch(fetchMemberStatuses());
        handleWindowResize();
        dispatch(getProviderRoles());
        window.addEventListener('resize', handleWindowResize, false);
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        executeSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionRowData]);

    useEffect(() => {
        if (currentTab === 'Provider') {
            dispatch(setCurrentRows(regionData.Providers));
            dispatch(setCurrentColumns(providerColumns));
        }
        if (currentTab === 'Facility') {
            dispatch(setCurrentRows(regionData.Facilities));
            dispatch(setCurrentColumns(facilitiesColumns));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab, regionData]);

    return (
        <Box>
            {<Loading message={'Loading...'} />}

            <RegionsDialog
                open={editRegionModal}
                onClose={() => dispatch(closeEditRegionHandler())}
                regionData={regionData}
                currentTab={currentTab}
                tabsHandler={(e, newValue) => dispatch(tabsHandler(e, newValue))}
                rows={currentRows}
                columns={currentColumns}
                isNewRegion={isNewRegion}
                nameOnChange={(e) => dispatch(nameOnChange(e, regionData))}
                helperText={helperText}
                addRegion={() => dispatch(addRegion(regionData.Name))}
                providerFacilityOpen={() => dispatch(openAddDialogHandler(currentTab, availableFacilities, availableProviders))}
                pageSize={pageSize}
                handleWindowResize={handleWindowResize}
                columnVisibilityModel={columnVisibilityModel}
            />

            <ProviderFacilityDialog
                open={addDialog}
                onClose={() => dispatch(closeAddDialogHandler())}
                selectValue={selectValue}
                selectOnChange={(e) => dispatch(selectOnChange(e, currentTab, availableFacilities, availableProviders))}
                selectTitle={currentTab}
                selectOptions={currentTab === 'Provider' ? providerOptions : facilitiesOptions}
                facilities={allFacilities}
                assignProviderHandler={() => dispatch(assignProviderHandler(currentTab, regionData, selectionModel, allProviders, allFacilities))}
                rows={addDialogRows}
                columns={currentColumns}
                selectLabel={currentTab === 'Provider' ? 'Role' : 'Facility Zip Codes'}
                selectionModel={selectionModel}
                selectionModelHandler={(value) => dispatch(setSelectionModel(value))}
                pageSize={pageSize}
                handleWindowResize={handleWindowResize}
                columnVisibilityModel={columnVisibilityModel}
            />

            <Dialog
                open={deleteRegionModal}
                onClose={() => dispatch(closeDeleteHandler())}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                disableEnforceFocus
                sx={{ padding: '16px' }}
            >
                <Paper
                    sx={{
                        padding: '16px',
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h6" component={'p'} color={'error'}>
                            Are You Sure You Want To Delete Region <span style={{ color: '#333', fontWeight: 'bold' }}>{regionData.Name}</span> ?
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => dispatch(deleteRegionHandler(regionData.Id))} autoFocus>
                            Delete
                        </Button>
                        <Button variant="outlined" onClick={() => dispatch(closeDeleteHandler())}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>

            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={12} textAlign={'right'}>
                    <Stack direction="row" spacing={2} display="flex" alignItems="center">
                        <Grid item xs={6} display="flex" sx={{ marginRight: 'auto', marginLeft: 0 }}>
                            <FormControl style={{ minWidth: '300px', margin: '0px', alignSelf: 'flex-end' }}>
                                <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type="text"
                                    value={regionSearchValue}
                                    onChange={(e) => {
                                        setRegionSearchValue(e.target.value as string);
                                    }}
                                    onKeyUp={(event: any) => {
                                        changeSearch(event);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Search" onClick={changeSearch} edge="end">
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Search"
                                />
                            </FormControl>
                        </Grid>

                        <FormControl className="form-control-200">
                            <InputLabel id="demo-simple-select-label">Select Region Action</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={0}
                                value={regionAction}
                                onChange={(e) => dispatch(setRegionAction(+e.target.value))}
                                sx={{ textAlign: 'left' }}
                                autoWidth
                                label="Select Region Action"
                            >
                                <MenuItem value={0} disabled>
                                    Select
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(downloadProviderCSV())} value={1}>
                                    Download Provider CSV File
                                </MenuItem>
                                <MenuItem onClick={() => dispatch(updateRegionMemberCount())} value={2}>
                                    Update Member Count
                                </MenuItem>
                                <MenuItem onClick={() => document.getElementById('upload-btn').click()} value={3}>
                                    Upload Provider CSV File
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => dispatch(addNewRegionHandler())} variant="contained">
                            Add New Region
                        </Button>
                    </Stack>
                </Grid>
                <form id={'upload-form'}>
                    <Button id="upload-btn" variant="contained" component="label" hidden>
                        Upload File
                        <input
                            id="csv"
                            onChange={(e) => {
                                dispatch(uploadProviderCSV(e.target.files[0]));
                            }}
                            type="file"
                            accept=".csv"
                            hidden
                        />
                    </Button>
                </form>
                <Grid item xs={12}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            rows={localRegions}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminRegions;
