export enum CarePlanEnum {
    OpenDescription = 'Open',
    Open = 1,
    InProgressDescription = 'In-Progress',
    InProgress = 2,
    AchievedDescription = 'Achieved',
    Achieved = 3,
    NotMetDescription = 'Not Met',
    NotMet = 4,
    DeletedDescription = 'Deleted',
    Deleted = 5,
}
