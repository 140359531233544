import { Grid, Paper, Typography, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../reducers';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import WidgetLoading from '../../../SmartDashboard/Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';
import { dateFormatter } from '../../../../utils/timeFormat';
import { OnboardingMeansOfEngagements } from '../../../../utils/mappings';
import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';
import { fetchFuhFumUndeterminedSummary } from '../../../Widgets/WidgetService';
import { isDefaultDate } from '../../../../utils/common';
import { parentUrl } from '../../../../utils/constants';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const FuhFumUndeterminedSummary = () => {
    const authData = new Authorization();
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const { tccProviders } = useSelector((state: RootState) => state.provider);
    const {
        undeterminedProvider,
        setUndeterminedProvider,
        undeterminedMeansOfAttempt,
        setUndeterminedMeansOfAttempt,
        undeterminedDateFilter,
        setUndeterminedDateFilter,
        undeterminedFromDate,
        setUndeterminedFromDate,
        undeterminedToDate,
        setUndeterminedToDate,
        undeterminedShowInActiveProviders,
        setUndeterminedShowInActiveProviders,
    } = useWidgetContext();
    const filteredTccProviders = (undeterminedShowInActiveProviders ? tccProviders : tccProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        if (filteredTccProviders.length) {
            const loggedInProviderId = authData.UserId;
            if (Boolean(loggedInProviderId) && !Boolean(undeterminedProvider)) {
                const providerInfo = filteredTccProviders.find((t) => t.Id === loggedInProviderId);
                if (Boolean(providerInfo)) {
                    setUndeterminedProvider(loggedInProviderId);
                } else {
                    setUndeterminedProvider('All');
                }
            } else if (!Boolean(undeterminedProvider)) {
                setUndeterminedProvider('All');
            }
        } else {
            setUndeterminedProvider('All');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData.UserId, undeterminedProvider, setUndeterminedProvider, undeterminedShowInActiveProviders]);

    const columns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) =>
                params.row.DaysSinceDischarge >= 0 && params.row.DaysSinceDischarge <= 7
                    ? 'green'
                    : params.row.DaysSinceDischarge >= 8 && params.row.DaysSinceDischarge <= 14
                    ? 'yellow'
                    : params.row.DaysSinceDischarge >= 15
                    ? 'red'
                    : '',
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.ReferralDate) ? '-' : dateFormatter(new Date(params.row.ReferralDate))}`,
            cellClassName: (params: GridCellParams<number>) =>
                params.row.DaysSinceDischarge >= 0 && params.row.DaysSinceDischarge <= 7
                    ? 'green'
                    : params.row.DaysSinceDischarge >= 8 && params.row.DaysSinceDischarge <= 14
                    ? 'yellow'
                    : params.row.DaysSinceDischarge >= 15
                    ? 'red'
                    : '',
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeDate) ? '-' : dateFormatter(new Date(params.row.DischargeDate))}`,
            cellClassName: (params: GridCellParams<number>) =>
                params.row.DaysSinceDischarge >= 0 && params.row.DaysSinceDischarge <= 7
                    ? 'green'
                    : params.row.DaysSinceDischarge >= 8 && params.row.DaysSinceDischarge <= 14
                    ? 'yellow'
                    : params.row.DaysSinceDischarge >= 15
                    ? 'red'
                    : '',
        },
        {
            field: 'Facility',
            headerName: 'Facility',
            flex: 2,
            renderCell: (params) => {
                let facilityInfo = Boolean(params.row.Facility) ? params.row.Facility : '-';
                return <>{facilityInfo}</>;
            },
            cellClassName: (params: GridCellParams<number>) =>
                params.row.DaysSinceDischarge >= 0 && params.row.DaysSinceDischarge <= 7
                    ? 'green'
                    : params.row.DaysSinceDischarge >= 8 && params.row.DaysSinceDischarge <= 14
                    ? 'yellow'
                    : params.row.DaysSinceDischarge >= 15
                    ? 'red'
                    : '',
        },
        {
            field: 'NumberOfAttempts',
            headerName: '# Attempts',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) =>
                params.row.DaysSinceDischarge >= 0 && params.row.DaysSinceDischarge <= 7
                    ? 'green'
                    : params.row.DaysSinceDischarge >= 8 && params.row.DaysSinceDischarge <= 14
                    ? 'yellow'
                    : params.row.DaysSinceDischarge >= 15
                    ? 'red'
                    : '',
        },
        {
            field: 'LastAttemptDate',
            headerName: 'Last Attempt',
            flex: 1,
            renderCell: (params) => {
                let attempt = isDefaultDate(params.row.LastAttemptDate)
                    ? '-'
                    : `${params.row.LastAttemptType} - ${dateFormatter(new Date(params.row.LastAttemptDate))}`;
                return <>{attempt}</>;
            },
            cellClassName: (params: GridCellParams<number>) =>
                params.row.DaysSinceDischarge >= 0 && params.row.DaysSinceDischarge <= 7
                    ? 'green'
                    : params.row.DaysSinceDischarge >= 8 && params.row.DaysSinceDischarge <= 14
                    ? 'yellow'
                    : params.row.DaysSinceDischarge >= 15
                    ? 'red'
                    : '',
        },
        {
            field: 'FuhFumVisitTypeName',
            headerName: 'Admission Type',
            flex: 1,
            renderCell: (params) => {
                let typeInfo = Boolean(params.row.FuhFumVisitTypeName) ? params.row.FuhFumVisitTypeName : '-';
                return <>{typeInfo}</>;
            },
            cellClassName: (params: GridCellParams<number>) =>
                params.row.DaysSinceDischarge >= 0 && params.row.DaysSinceDischarge <= 7
                    ? 'green'
                    : params.row.DaysSinceDischarge >= 8 && params.row.DaysSinceDischarge <= 14
                    ? 'yellow'
                    : params.row.DaysSinceDischarge >= 15
                    ? 'red'
                    : '',
        },
    ];

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 90) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFuhFumSummary = async () => {
        if (undeterminedDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(undeterminedFromDate) && Boolean(undeterminedToDate))) {
            setIsFetched(false);
            let dateFrom =
                Boolean(undeterminedFromDate) && undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? new Date(undeterminedFromDate).toISOString() : '';
            let dateTo =
                Boolean(undeterminedToDate) && undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? new Date(undeterminedToDate).toISOString() : '';
            const data = await fetchFuhFumUndeterminedSummary(
                undeterminedProvider,
                undeterminedMeansOfAttempt,
                undeterminedDateFilter,
                dateFrom,
                dateTo,
                undeterminedShowInActiveProviders
            );
            setRows(data);
            setIsFetched(true);
        }

        if (undeterminedDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(undeterminedFromDate) && Boolean(undeterminedToDate)) {
            setUndeterminedFromDate(null);
            setUndeterminedToDate(null);
        }
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [undeterminedProvider, undeterminedMeansOfAttempt, undeterminedDateFilter, undeterminedFromDate, undeterminedToDate, undeterminedShowInActiveProviders]);

    const changeFromDate = (date: any) => {
        setUndeterminedFromDate(date);
    };

    const changeToDate = (date: any) => {
        setUndeterminedToDate(date);
    };

    const navigateToMembers = () => {
        history(`/provider/metricsummary/fuhfumundeterminedmemberdetail`);
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setUndeterminedShowInActiveProviders(isChecked);
        setUndeterminedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    FUHFUM Undetermined Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">Members with FUH-Und status within the date range.</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="provider-label">TCC Provider</InputLabel>
                                <Select
                                    labelId="provider-label"
                                    id="demo-provider-select"
                                    value={undeterminedProvider}
                                    label="TCC Provider"
                                    onChange={(e) => {
                                        setUndeterminedProvider(e.target.value as string);
                                    }}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox checked={undeterminedShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredTccProviders.map(function (Pro) {
                                        return (
                                            <MenuItem key={Pro.Id} value={Pro.Id} sx={{ color: Pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                {Pro.LastName}, {Pro.FirstName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2.4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-means-label">Means of Attempts</InputLabel>
                                <Select
                                    labelId="demo-means-label"
                                    id="demo-meanss-select"
                                    value={undeterminedMeansOfAttempt}
                                    label="Means of Attempts"
                                    onChange={(e) => {
                                        setUndeterminedMeansOfAttempt(e.target.value as string);
                                    }}
                                >
                                    <MenuItem key="0" value="0">
                                        All
                                    </MenuItem>
                                    {OnboardingMeansOfEngagements.map(function (Pro) {
                                        return (
                                            <MenuItem key={Pro.value} value={Pro.value}>
                                                {Pro.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2.4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={undeterminedDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setUndeterminedDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={2.4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(undeterminedFromDate) ? undeterminedFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={2.4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(undeterminedToDate) ? undeterminedToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <div style={{ width: '100%', height: 'Calc(50vh - 65px)', position: 'relative' }}>
                            {!isFetched ? (
                                <WidgetLoading />
                            ) : (
                                <DataGrid
                                    onCellClick={(params: any) => {
                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                    }}
                                    rows={rows}
                                    columns={columns}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[5]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                        [`& .green`]: {
                                            color: 'green',
                                        },
                                        [`& .red`]: {
                                            color: 'red',
                                        },
                                        [`& .yellow`]: {
                                            color: 'gold',
                                        },
                                        [`& .black`]: {
                                            color: 'rgba(0,0,0,0.87)',
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default FuhFumUndeterminedSummary;
