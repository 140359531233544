import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setPopulations, setSelectedPopulation, setShowEditPopulation, updatePopulations } from '../../../store/adminOnboarding.slice';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Population from '../../../Models/Population/Population.model';
import { useState } from 'react';
import moment from 'moment';

const EditPopulationModal = () => {
    const dispatch = useDispatch();
    const { populations, selectedPopulation, showEditPopulation } = useSelector((state: RootState) => state.adminOnboardingSlice);

    const [errorMessage, setErrorMessage] = useState('');

    const checkIfPopulationIsValid = () => {
        if (selectedPopulation.PopulationName === '') {
            setErrorMessage('Population Name is required');
            return false;
        }
        if (selectedPopulation.OnboardingStartDate === null || selectedPopulation.OnboardingDeadlineDate === null) {
            setErrorMessage('Start and End Dates are required');
            return false;
        }
        if (!moment(selectedPopulation.OnboardingStartDate).isValid() || !moment(selectedPopulation.OnboardingDeadlineDate).isValid()) {
            setErrorMessage('Invalid Date');
            return false;
        }
        if (moment(selectedPopulation.OnboardingStartDate) > moment(selectedPopulation.OnboardingDeadlineDate)) {
            setErrorMessage('Start Date must be before End Date');
            return false;
        }
        return true;
    };

    const closeEditPopulationHandler = () => {
        dispatch(setShowEditPopulation(false));
        setErrorMessage('');
    };

    const saveEditPopulationHandler = (population: Population) => {
        if (!checkIfPopulationIsValid()) {
            return;
        }
        const newPops = [...populations];
        const index = newPops.findIndex((pop) => pop.Id === population.Id);
        newPops[index] = population;
        dispatch(setPopulations(newPops));
        dispatch(updatePopulations(newPops));
        dispatch(setShowEditPopulation(false));
        setErrorMessage('');
    };

    return (
        <Dialog
            open={showEditPopulation}
            onClose={closeEditPopulationHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">Edit Population</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <Stack spacing={2} py={2}>
                        <TextField
                            id="populationName"
                            label="Population Name"
                            variant="outlined"
                            value={selectedPopulation.PopulationName}
                            onChange={(e) => {
                                dispatch(setSelectedPopulation({ ...selectedPopulation, PopulationName: e.target.value }));
                            }}
                        />
                        <TextField
                            id="onboardedIndividuals"
                            label="Members Onboarded Goal"
                            variant="outlined"
                            type="number"
                            value={selectedPopulation.OnboardedIndividualsGoal ?? ''}
                            onChange={(e) => {
                                dispatch(setSelectedPopulation({ ...selectedPopulation, OnboardedIndividualsGoal: Number(e.target.value) }));
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Onboarding Start"
                                value={selectedPopulation.OnboardingStartDate}
                                onChange={(date: any) => {
                                    dispatch(setSelectedPopulation({ ...selectedPopulation, OnboardingStartDate: date }));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="MM/dd/yyyy"
                            />
                            <DesktopDatePicker
                                value={selectedPopulation.OnboardingDeadlineDate}
                                label="Onboarding End"
                                onChange={(date: any) => {
                                    dispatch(setSelectedPopulation({ ...selectedPopulation, OnboardingDeadlineDate: date }));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={selectedPopulation.OnboardingStartDate}
                                inputFormat="MM/dd/yyyy"
                            />
                        </LocalizationProvider>
                        {errorMessage.length > 0 ? (
                            <Typography variant="caption" color="error" textAlign={'center'}>
                                {errorMessage}
                            </Typography>
                        ) : null}
                    </Stack>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button className="button-120" variant="outlined" onClick={closeEditPopulationHandler}>
                    Cancel
                </Button>
                <Button className="button-120" variant="contained" onClick={() => saveEditPopulationHandler(selectedPopulation)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPopulationModal;
