import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { localDateAndTimeFormat } from '../../../../utils/timeFormat';
import { isDefaultDate } from '../../../../utils/common';

const MatchedHistoryModal = (props) => {
    const { handleClose, open, matchedHistory } = props;

    const columns: GridColDef[] = [
        {
            field: 'MatchedDate',
            headerName: 'Matched Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.MatchedDate) ? '' : localDateAndTimeFormat(new Date(params.row.MatchedDate))}`,
        },
        {
            field: 'ProviderName',
            headerName: 'Provider',
            flex: 1,
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
        },
    ];
    return (
        <Dialog onClose={handleClose} open={open} sx={{ padding: 5 }} maxWidth="lg" fullWidth>
            <DialogTitle>Alert History</DialogTitle>
            <div style={{ width: '100%', height: '80vh', overflowY: 'scroll' }}>
                <DataGrid
                    rows={matchedHistory}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => Math.random()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </div>
            <DialogActions>
                <Button className="button-120" variant="outlined" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default MatchedHistoryModal;
