import React, { useCallback, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';

import { useWidgetContext } from '../../../hooks/widgetContext';
import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchIndividualInPersonVisitsSummaryChart, MenuProps } from '../WidgetService';
import EngagementSummary from '../../../Models/Widget/EngagementSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { getDateFilter } from '../../HedisMetrics/HedisMetricService';
import '../Widgets.css';

const IndividualInPersonVisitsSummary = () => {
    const history = useNavigate();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const {
        individualEngagementDateFilter,
        setIndividualEngagementDateFilter,
        individualEngagementFromDate,
        setIndividualEngagementFromDate,
        individualEngagementToDate,
        setIndividualEngagementToDate,
        individualEngagementSelectedProvider,
        setIndividualEngagementSelectedProvider,
        individualEngagementShowInActiveProviders,
        setIndividualEngagementShowInActiveProviders,
    } = useWidgetContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [engagementMemberSummary, setEngagementMemberSummary] = useState<EngagementSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [loadedPage, setLoadedPage] = useState(false);
    const filteredProviders =
        (individualEngagementShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const dateFilterList = getDateFilter().filter((d) => d.Value !== 'Custom');

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChart(
                    'Engagement Summary',
                    'Engagement Count',
                    'Visits',
                    'Count',
                    [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                    [0],
                    'IndividualEngagement',
                    history
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (activeProviders?.length) {
            setLoadedPage(true);
        }
    }, [activeProviders]);

    useEffect(() => {
        if (
            loadedPage &&
            (individualEngagementDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(individualEngagementFromDate) && Boolean(individualEngagementToDate)))
        ) {
            const changeFilter = async () => {
                setIsGraphShow(false);
                const data = await fetchIndividualInPersonVisitsSummaryChart(
                    individualEngagementSelectedProvider,
                    individualEngagementDateFilter,
                    Boolean(individualEngagementFromDate) && individualEngagementDateFilter === TimePeriodFilterEnum.Custom
                        ? new Date(individualEngagementFromDate).toISOString()
                        : '',
                    Boolean(individualEngagementToDate) && individualEngagementDateFilter === TimePeriodFilterEnum.Custom
                        ? new Date(individualEngagementToDate).toISOString()
                        : '',
                    individualEngagementShowInActiveProviders,
                    false
                );
                setEngagementMemberSummary(data);
            };
            setChartOptions(
                configureStackedBarChart(
                    'Engagement Summary',
                    'Engagement Count',
                    'Visits',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'IndividualEngagement',
                    history
                )
            );
            changeFilter();
        }

        if (individualEngagementDateFilter !== TimePeriodFilterEnum.Custom && Boolean(individualEngagementFromDate) && Boolean(individualEngagementToDate)) {
            setIndividualEngagementFromDate(null);
            setIndividualEngagementToDate(null);
        }
    }, [
        individualEngagementSelectedProvider,
        individualEngagementDateFilter,
        individualEngagementFromDate,
        individualEngagementToDate,
        setIndividualEngagementFromDate,
        setIndividualEngagementToDate,
        individualEngagementShowInActiveProviders,
        loadedPage,
        history,
    ]);

    useEffect(() => {
        if (engagementMemberSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let inpersonVisitValues = [];
            let totalVisitedValues = [];

            let d = {
                y: engagementMemberSummary.InPersonVisits,
                name: engagementMemberSummary.InPersonVisitPercentage,
            };
            inpersonVisitValues.push(d);

            let d1 = {
                y: engagementMemberSummary.TotalVisits,
                name: 100,
            };
            totalVisitedValues.push(d1);
            xAxisValues.push('Visits');

            yAxisValues.push({ name: 'In-Person Visits', data: inpersonVisitValues, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'All Visits', data: totalVisitedValues, color: '#ff7d7d', showInLegend: true });

            if (engagementMemberSummary.TotalVisits === 0 && engagementMemberSummary.InPersonVisits === 0) {
                setChartOptions(
                    configureStackedBarChart(
                        'Engagement Summary',
                        'Engagement Count',
                        'Visits',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0],
                        'IndividualEngagement',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedBarChart('Engagement Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, 'IndividualEngagement', history)
                );
            }

            setIsGraphShow(true);
        }
    }, [engagementMemberSummary, history]);

    const changeFromDate = (date: any) => {
        setIndividualEngagementFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(individualEngagementToDate) || new Date(individualEngagementToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setIndividualEngagementToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setIndividualEngagementToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(individualEngagementFromDate) || new Date(individualEngagementFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setIndividualEngagementFromDate(startDate);
        }
    };

    const navigateToMembers = () => {
        history(`/provider/metricsummary/individualinpersonvisitsdetail`);
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setIndividualEngagementShowInActiveProviders(isChecked);
        setIndividualEngagementSelectedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    Individual In-Person Member Visits
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                In-Person Visits :
                                            </Typography>
                                            <Typography variant="caption">
                                                Selected provider's rate of in-person visits with reasons of "Initial", "Routine Follow-Up", "Facility Visit"
                                                and "Behavioral Health Services".
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={individualEngagementSelectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setIndividualEngagementSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox
                                                checked={individualEngagementShowInActiveProviders}
                                                onChange={(e) => toggleProviderHandler(e.target.checked)}
                                            />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={individualEngagementDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setIndividualEngagementDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {individualEngagementDateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(individualEngagementFromDate) ? individualEngagementFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(individualEngagementToDate) ? individualEngagementToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default IndividualInPersonVisitsSummary;
