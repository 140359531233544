import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormGroup,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { EncounterStatusEnum } from '../../Enum/EncounterStatusEnum';
import { RootState } from '../../reducers';
import { setClaim, setShowEditResubmitModal } from '../../store/claimResubmission.slice';
import { getTop, setClaimError, updateEncounters } from '../../store/procedureSignOffEncounter.slice';
import { largeModalStyle } from '../../utils/constants';
import ProcedureSignOffEncounterEdit from '../Assessments/ProcedureSignOff/ProcedureSignOffEncounterEdit';
import { useEffect, useState } from 'react';
import { statusValues } from '../../store/claimsManagement.slice';

const ClaimEditResubmission = (props) => {
    const dispatch = useDispatch();
    const { claim, claimNumber, dateOfService, showEditResubmitModal } = useSelector((state: RootState) => state.claimResubmission);
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const { draftEncounterList } = useSelector((state: RootState) => state.procedureSignOffEncounter);
    const [selectedStatus, setSelectedStatus] = useState(props.encounterStatus);
    const [showDxError, setShowDxError] = useState(false);

    const validateCpt = () => {
        if (draftEncounterList?.Encounters.some((e) => !Boolean(e.CptCodeId))) {
            dispatch(setClaimError({ Show: true, Message: 'Please add a primary CPT code to all encounters before submitting.' }));
            return false;
        }

        if (draftEncounterList?.Encounters.some((e) => !Boolean(e.Units))) {
            dispatch(setClaimError({ Show: true, Message: "Please verify you've selected Units." }));
            return false;
        }

        return true;
    };

    const updateEncountersLocal = (isResubmit: boolean) => {
        if (!validateCpt()) {
            return;
        }

        let isDiagnosisMissed = false;
        draftEncounterList.Encounters.forEach((encounter) => {
            if (encounter.DiagnosisList.length === 0) {
                isDiagnosisMissed = true;
            }

            encounter.DiagnosisList.forEach((diagnosis) => {
                if (Boolean(diagnosis.DiagnosisFirstList?.length) && !Boolean(diagnosis.FirstDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisSecondList?.length) && !Boolean(diagnosis.SecondDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisThirdList?.length) && !Boolean(diagnosis.ThirdDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisFourthList?.length) && !Boolean(diagnosis.FourthDiagnosis)) {
                    isDiagnosisMissed = true;
                }
            });
        });

        if (isDiagnosisMissed) {
            dispatch(setClaimError({ Show: true, Message: 'Please select a diagnosis' }));
            setShowDxError(true);
            return;
        }

        dispatch(updateEncounters(draftEncounterList, isResubmit ? EncounterStatusEnum.ToSubmit : selectedStatus, claimNumber, isResubmit));
    };

    useEffect(() => {
        setSelectedStatus(props.encounterStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.encounterStatus]);

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Grid container>
                    <Grid item xs>
                        <Paper elevation={3} style={{ padding: '8px', textAlign: 'left' }}>
                            <Divider style={{ marginBottom: '16px' }}>
                                <Chip label="Member" />
                            </Divider>
                            <Stack direction="row" spacing={2}>
                                <Stack spacing={2}>
                                    <Avatar
                                        alt={selectedMember?.FirstName + ' ' + selectedMember?.LastName}
                                        src={selectedMember?.ProfileImageUrl}
                                        sx={{ width: 144, height: 144 }}
                                        className="profile-image"
                                    />
                                </Stack>
                                <Stack style={{ width: '100%' }} spacing={2}>
                                    <FormGroup>
                                        <Stack direction="row" spacing={2}>
                                            <TextField aria-readonly="true" fullWidth label="First Name" variant="outlined" value={selectedMember?.FirstName} />
                                            <TextField
                                                aria-readonly="true"
                                                fullWidth
                                                label="Middle Name"
                                                variant="outlined"
                                                value={selectedMember?.MiddleName ? selectedMember?.MiddleName : ''}
                                            />
                                            <TextField aria-readonly="true" fullWidth label="Last Name" variant="outlined" value={selectedMember?.LastName} />
                                        </Stack>
                                    </FormGroup>
                                    <FormGroup>
                                        <Stack direction="row" spacing={2}>
                                            <TextField
                                                aria-readonly="true"
                                                fullWidth
                                                label="AHCCCS ID"
                                                variant="outlined"
                                                value={selectedMember?.Mem_AHCCCS_ID}
                                            />
                                            <TextField aria-readonly="true" fullWidth label="DOB" variant="outlined" value={selectedMember?.DOB} />
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="formatted-text-mask-input">SSN</InputLabel>
                                                <Input
                                                    disabled={true}
                                                    value={selectedMember?.SSN ? 'XXX-XX-' + selectedMember?.SSN.substring(selectedMember?.SSN.length - 4) : ''}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </FormGroup>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={3} style={{ padding: '8px', marginLeft: '8px', textAlign: 'left', minHeight: '208px' }}>
                            <Divider style={{ marginBottom: '16px' }}>
                                <Chip label="Claim Information" />
                            </Divider>
                            <Stack direction="row" spacing={2}>
                                <Stack style={{ width: '100%' }} spacing={2}>
                                    <FormGroup>
                                        <Stack spacing={2}>
                                            <TextField aria-readonly="true" fullWidth label="Claim Number" variant="outlined" value={claimNumber} />
                                            <TextField aria-readonly="true" fullWidth label="Date of Service" variant="outlined" value={dateOfService} />
                                        </Stack>
                                    </FormGroup>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                <FormControl>
                    <InputLabel id="plan-type-label">Select Status</InputLabel>
                    <Select
                        labelId="plan-type-label"
                        id="plan-type"
                        value={selectedStatus}
                        margin="dense"
                        label="Select Status"
                        name="HealthPlanType"
                        onChange={(e) => {
                            setSelectedStatus(e.target.value);
                        }}
                    >
                        {statusValues
                            .filter((x) => x.value >= 5 && x.value !== 11)
                            .map((status) => {
                                return (
                                    <MenuItem value={status.value} key={status.value}>
                                        {status.label}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                <Stack direction="row" spacing={2}>
                    <FormControl style={{ minWidth: '200px' }}>
                        <InputLabel id="demo-simple-select-label">Resubmission Code</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Resubmission Code"
                            value={claim?.ResubmissionCode || ''}
                            onChange={(e) => dispatch(setClaim({ ...claim, ResubmissionCode: e.target.value }))}
                        >
                            {[
                                { label: '7 – Replacement of Prior Claim', value: 7 },
                                { label: '8 - Void/Cancel Prior Claim', value: 8 },
                            ].map(function (code) {
                                return (
                                    <MenuItem key={code.value} value={code.value}>
                                        {`${code.label}`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{ minWidth: '200px' }}>
                        <TextField
                            value={claim?.OriginalRefNo || ''}
                            label="Original Ref #"
                            name="Original #"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => dispatch(setClaim({ ...claim, OriginalRefNo: e.target.value }))}
                        />
                    </FormControl>
                    <FormControl style={{ minWidth: '200px' }}>
                        <InputLabel id="demo-simple-select-label">Place of Service</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Place of Service"
                            value={claim?.PlaceOfService || ''}
                            onChange={(e) => dispatch(setClaim({ ...claim, PlaceOfService: e.target.value }))}
                        >
                            {[
                                { label: '2 – Telehealth', value: 2 },
                                { label: '4 – Homeless Shelter', value: 4 },
                                { label: '11 - Office/Email', value: 11 },
                                { label: '12 - Home', value: 12 },
                                { label: '31 - Skilled Nursing Facility', value: 31 },
                                { label: '32 - Nursing Facility', value: 32 },
                            ].map(function (pos) {
                                return (
                                    <MenuItem key={pos.value} value={pos.value}>
                                        {`${pos.label}`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            value={claim?.AdditionalClaimInfo || ''}
                            label="Additional Claim Info"
                            name="Additional Claim Info"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => dispatch(setClaim({ ...claim, AdditionalClaimInfo: e.target.value }))}
                        />
                    </FormControl>
                </Stack>
                <Divider />
                <ProcedureSignOffEncounterEdit dxError={showDxError} encounterStatus={props.encounterStatus} searchClaims={props.searchClaims} />
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button
                            className="button-120"
                            variant="contained"
                            onClick={() => {
                                updateEncountersLocal(true);
                            }}
                        >
                            {'Resubmit to Waystar'}
                        </Button>
                        <Button
                            className="button-120"
                            variant="contained"
                            onClick={() => {
                                updateEncountersLocal(false);
                            }}
                        >
                            {'Save Claim Without Resubmission'}
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                dispatch(setShowEditResubmitModal(false, false));
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <Modal
            open={showEditResubmitModal}
            style={{ overflow: 'scroll' }}
            onClose={() => {
                dispatch(setShowEditResubmitModal(false, true));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={largeModalStyle} style={{ top: getTop(draftEncounterList) }}>
                <Stack spacing={2}>
                    <Typography variant="h5">{'Modify Claim'}</Typography>
                    <Divider />
                    {renderBody()}
                </Stack>
            </Box>
        </Modal>
    );
};

export default ClaimEditResubmission;
