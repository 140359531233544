import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Input,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { deleteCareCircle, fetchCareCircle, saveCareCircle } from '../../../store/memberDetail.slice';
import { useEffect, useState } from 'react';
import MemberAvatar from './MemberAvatar';
import TextMaskCustom from '../../Shared/PhoneMask';
import { fetchAllLanguages, fetchPreferredContactOptions } from '../../../store/shared.slice';
import { modalStyle } from '../../../utils/constants';
import { validEmail } from '../../../utils/validation';

const MemberCareCircle = () => {
    const [error, setError] = useState({ email: false });

    const getDefaultPrimaryLanguage = () => {
        return { Id: '56695e83ad78ee0e48b04a01', Name: 'English' };
    };

    const getDefault = () => {
        return {
            Id: null,
            Address: '',
            Email: '',
            HomePhone: '',
            IsDeleted: false,
            MobilePhone: '',
            Name: '',
            PermissionToReleaseHIPAAInformation: false,
            PreferredContact: -1,
            PrimaryLanguage: getDefaultPrimaryLanguage(),
            ReasonForDeleted: 0,
            Relation: '',
            SignedDocument: '',
            DischargeSupportPerson: false,
            NextOfKin: false,
        };
    };

    const reset = (row: any) => {
        let update = row ? { ...row } : getDefault();
        if (update.PrimaryLanguage === null) {
            update.PrimaryLanguage = getDefaultPrimaryLanguage();
        }

        setError({ ...error, email: false });
        setFormCC(update);
    };

    const addCGHandler = (row: any = null) => {
        reset(row);
        setShowModal(true);
    };

    const dispatch = useDispatch();
    const { careCircle, selectedMember, memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const { allLanguages, preferredContactOptions } = useSelector((state: RootState) => state.shared);
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [formCC, setFormCC] = useState(getDefault());

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'MobilePhone',
            headerName: 'MobilePhone',
            minWidth: 150,
            renderCell: (params) => {
                return <Input readOnly value={params.row.MobilePhone} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
            flex: 1,
        },
        {
            field: 'HomePhone',
            headerName: 'HomePhone',
            minWidth: 150,
            renderCell: (params) => {
                return <Input readOnly value={params.row.HomePhone} name="textmask" id="formatted-text-mask-input" inputComponent={TextMaskCustom as any} />;
            },
            flex: 1,
        },
        {
            field: 'Email',
            headerName: 'Email',
            minWidth: 200,
            flex: 1,
            hide: memberBasicInfo.IsInFuhFum ? true : false,
        },
        {
            field: 'DischargeSupportPersonText',
            headerName: 'Discharge Support Person',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'NextOfKinText',
            headerName: 'Next of Kin',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'Relation',
            headerName: 'Relation',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'Language',
            headerName: 'Language',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return <div className="MuiDataGrid-cellContent">{params.row.PrimaryLanguage ? params.row.PrimaryLanguage.Name : ''}</div>;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Edit
                            className="icon-left"
                            onClick={() => {
                                addCGHandler(params.row);
                                setShowModal(true);
                            }}
                        />
                        <Delete
                            className="icon-right"
                            onClick={() => {
                                setFormCC(params.row);
                                setShowDelete(true);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>, prop) => {
        let update = { ...formCC };
        update[prop] = event.target.value;

        setFormCC(update);
    };

    const deleteHandler = () => {
        dispatch(deleteCareCircle(selectedMember.Id, formCC.Id, -1));
        setShowDelete(false);
    };

    const changePrimaryLanguage = (value) => {
        var update = { ...formCC };
        update.PrimaryLanguage = allLanguages.find((x) => x.Id === value);

        setFormCC(update);
    };

    const saveCC = () => {
        let update = { ...formCC };
        if (formCC.MobilePhone) {
            update.MobilePhone = formCC.MobilePhone.replace(/[^\d.]/g, '');
        }

        if (formCC.HomePhone) {
            update.HomePhone = formCC.HomePhone.replace(/[^\d.]/g, '');
        }

        if (valid()) {
            dispatch(saveCareCircle(update));
            setShowModal(false);
        }
    };

    const valid = () => {
        if (formCC.Email && !validEmail(formCC.Email)) {
            setError({ ...error, email: !validEmail(formCC.Email) });
            return false;
        } else {
            return true;
        }
    };

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Stack spacing={3}>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Name"
                                variant="outlined"
                                value={formCC.Name}
                                onChange={(e: any) => {
                                    setFormCC({ ...formCC, Name: e.target.value });
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Primary Language</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formCC.PrimaryLanguage?.Id}
                                    label="Primary Language"
                                    onChange={(e: any) => {
                                        changePrimaryLanguage(e.target.value);
                                    }}
                                >
                                    {allLanguages.map(function (language) {
                                        return (
                                            <MenuItem key={language.Id} value={language.Id}>
                                                {language.Name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                fullWidth
                                label="Address"
                                variant="outlined"
                                value={formCC.Address}
                                onChange={(e: any) => {
                                    setFormCC({ ...formCC, Address: e.target.value });
                                }}
                            />
                            <TextField
                                error={error.email}
                                helperText={error.email ? 'Invalid email' : ''}
                                fullWidth
                                label="Email"
                                variant="outlined"
                                value={formCC.Email}
                                onChange={(e: any) => {
                                    setFormCC({ ...formCC, Email: e.target.value });
                                }}
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Mobile Phone</InputLabel>
                                <Input
                                    value={formCC.MobilePhone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handlePhoneChange(e, 'MobilePhone');
                                    }}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="formatted-text-mask-input">Home Phone</InputLabel>
                                <Input
                                    value={formCC.HomePhone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handlePhoneChange(e, 'HomePhone');
                                    }}
                                    name="textmask"
                                    id="formatted-text-mask-input"
                                    inputComponent={TextMaskCustom as any}
                                />
                            </FormControl>
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                id="outlined-basic"
                                fullWidth
                                label="Relationship"
                                variant="outlined"
                                value={formCC.Relation}
                                onChange={(e: any) => {
                                    setFormCC({ ...formCC, Relation: e.target.value });
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Preferred Contact</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formCC.PreferredContact}
                                    label="Preferred Contact"
                                    onChange={(e: any) => {
                                        setFormCC({ ...formCC, PreferredContact: e.target.value });
                                    }}
                                >
                                    {preferredContactOptions.map(function (option) {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControlLabel
                                control={<Checkbox checked={formCC.PermissionToReleaseHIPAAInformation} />}
                                onChange={(e: any) => {
                                    setFormCC({ ...formCC, PermissionToReleaseHIPAAInformation: e.target.checked });
                                }}
                                label="Permission To Release HIPAA Information"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={formCC.DischargeSupportPerson} />}
                                onChange={(e: any) => {
                                    setFormCC({ ...formCC, DischargeSupportPerson: e.target.checked });
                                }}
                                label="Discharge Support Person"
                            />
                        </Stack>
                    </FormGroup>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <FormControlLabel
                                control={<Checkbox checked={formCC.NextOfKin} />}
                                onChange={(e: any) => {
                                    setFormCC({ ...formCC, NextOfKin: e.target.checked });
                                }}
                                label="Is this person the member’s Next of Kin?"
                            />
                        </Stack>
                    </FormGroup>
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={saveCC}>
                            Save
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    const renderDeleteBody = () => {
        return (
            <Stack spacing={2}>
                <div className="row">
                    <p className="ml-3">Are you sure do you want to delete: {formCC.Name}?</p>
                </div>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" color="error" onClick={() => deleteHandler()}>
                            Delete
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                setShowDelete(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    useEffect(() => {
        dispatch(fetchAllLanguages());
        dispatch(fetchPreferredContactOptions());
        dispatch(fetchCareCircle(selectedMember.Id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Paper elevation={3} style={{ height: 'calc(100vh - 100px)', padding: '8px', margin: '16px', textAlign: 'right' }}>
                <Stack spacing={2}>
                    <div>
                        <MemberAvatar />
                        <Button
                            style={{ marginBottom: '16px' }}
                            variant="contained"
                            onClick={() => {
                                addCGHandler();
                            }}
                        >
                            Add Care Circle
                        </Button>
                    </div>
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.Id}
                        rows={careCircle}
                        columns={columns}
                        experimentalFeatures={{ newEditingApi: true }}
                        hideFooter
                    />
                </Stack>
            </Paper>
            <Modal
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Care Circle</Typography>
                        <Divider />
                        {renderBody()}
                    </Stack>
                </Box>
            </Modal>
            <Modal
                open={showDelete}
                onClose={() => {
                    setShowDelete(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Stack spacing={2}>
                        <Typography variant="h5">Delete Care Circle</Typography>
                        <Divider />
                        {renderDeleteBody()}
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default MemberCareCircle;
