import {
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { localDateFormat } from '../../../../utils/timeFormat';
import { apiString as API_URL } from '../../../../utils/constants';
import Link from '@mui/material/Link';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';

const RecentMedicalDocuments = () => {
    const auth = new Authorization();
    const showProviders = auth.Role === 'CEO' || auth.Role === 'Central Operation' ? true : false;
    const { teams } = useSelector((state: RootState) => state.teams);
    const [teamId, setTeamId] = useState(Boolean(auth.TeamId) ? auth.TeamId : '-1');
    const [timePeriod, setTimePeriod] = useState(3);
    const [recentMedicalDocuments, setRecentMedicalDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const ref = useRef(null);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            await axios.get(`${API_URL}/document/markasreviewed?documentId=${id}`);
            setRecentMedicalDocuments(recentMedicalDocuments.filter((el) => el.Id !== id));
        } catch (e) {
            console.log(e);
        }
    };

    const timePeriods = [
        { label: '7 Days', value: 1 },
        { label: '15 Days', value: 2 },
        { label: '30 Days', value: 3 },
    ];

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_URL}/document/getrecentmedicaldocuments?teamId=${
                        teamId === '-1' || teamId === undefined ? '' : teamId
                    }&timePeriodFilter=${timePeriod}`
                );
                setRecentMedicalDocuments(response.data);
                setIsLoading(false);
            } catch (error) {
                setRecentMedicalDocuments([]);
                setIsLoading(false);
                console.log(error);
            }
        };
        changeFilter();
    }, [teamId, timePeriod, showInActiveTeams]);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setTeamId('-1');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item xs={12} textAlign="center">
                    <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                        Recent Medical Documents
                    </Typography>
                    <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                        &#40;{recentMedicalDocuments.length}&#41;
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="caption">Recent medical documents uploaded within selected days by team</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Stack direction={'row'} marginTop={2} alignItems="center">
                    {showProviders && (
                        <Grid item>
                            <FormControl className="form-control-200" sx={{ marginRight: 2 }}>
                                <InputLabel id="demo-simple-select-label">Teams</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={'-1'}
                                    value={teams.find((el) => el.Id === teamId)?.Id || '-1'}
                                    size="small"
                                    label="Teams"
                                    onChange={(e) => {
                                        setTeamId(e.target.value);
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem disabled value={'-1'}>
                                        Select Team
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <ToggleButtonGroup
                            value={timePeriod}
                            exclusive
                            disabled={teamId === '-1'}
                            size="small"
                            onChange={(event: React.MouseEvent<HTMLElement>, newPeriod: number | null) => {
                                setTimePeriod(newPeriod);
                            }}
                        >
                            {timePeriods.map((period) => {
                                return (
                                    <ToggleButton color="primary" key={period.value} value={period.value}>
                                        {period.label}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    </Grid>
                </Stack>

                <Grid container spacing={2} marginTop={2} height={'52vh'} sx={{ overflowY: 'scroll', position: 'relative' }}>
                    {isLoading && <WidgetLoading />}
                    {recentMedicalDocuments.length > 0 ? (
                        recentMedicalDocuments.map((member) => {
                            return (
                                <Grid key={member.Id} item xs={6}>
                                    <Card
                                        sx={{
                                            boxShadow: 0,
                                        }}
                                        variant="outlined"
                                    >
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-evenly' }}>
                                            <Grid position={'relative'} container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Member
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {member.FileName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Uploaded On
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {localDateFormat(new Date(member.CreationTime))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Uploaded By
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {member.UploadedBy?.Name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Document Name
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {member.Name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Document Category
                                                    </Typography>
                                                    <Link href={member.AzureDocumentUri} target="_blank">
                                                        Medical Records
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography fontWeight={'bold'} variant="body2" display="inline-flex">
                                                        Mark As Reviewed
                                                    </Typography>
                                                    <Checkbox
                                                        checked={false}
                                                        onChange={(e) => handleChange(e, member.Id)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6">No Matching Records</Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default RecentMedicalDocuments;
