import React, { useState, useCallback } from 'react';
import { apiString as api } from '../utils/constants';
import axios from 'axios';

const ChpContext = React.createContext();

export const ChpProvider = ({ children }) => {
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [citiesByKeyword, setCitiesByKeyword] = useState([]);
    const [currentStep, setCurrentStep] = useState('');

    const getCitiesByKeyword = async (keyword) => {
        const response = await axios.get(`${api}/common/getcitiesbykeyword?keyword=${keyword}`);
        setCitiesByKeyword(response.data);
    };

    const getCities = async () => {
        const response = await axios.get(`${api}/common/getallcity`);
        setCities(response.data);
    };

    const getStates = async () => {
        const response = await axios.get(`${api}/common/getallstate`);
        setStates(response.data);
    };

    const handleNextStep = useCallback(
        (steps) => {
            let stepIndex = steps.indexOf(currentStep);
            stepIndex = stepIndex + 1;
            let stepInfo = steps[stepIndex];
            setCurrentStep(stepInfo);
        },
        [currentStep]
    );

    const handlePreviousStep = useCallback(
        (steps) => {
            let stepIndex = steps.indexOf(currentStep);
            stepIndex = stepIndex - 1;
            let stepInfo = steps[stepIndex];
            setCurrentStep(stepInfo);
        },
        [currentStep]
    );

    const value = {
        states,
        getStates,
        citiesByKeyword,
        getCitiesByKeyword,
        setCitiesByKeyword,
        cities,
        getCities,
        setCities,
        currentStep,
        setCurrentStep,
        handleNextStep,
        handlePreviousStep,
    };

    return <ChpContext.Provider value={value}>{children}</ChpContext.Provider>;
};

export const useChpContext = () => React.useContext(ChpContext);
