import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppiontmentMap from '../Maps/AppointmentMap';
import MySchedulesCalendar from './MySchedulesCalendar/MySchedulesCalendar';
import SchedulesCalendar from './SchedulesCalendar/SchedulesCalendar';

export interface ISchedulesTabsSelectorProps {
    height: string;
    width: number;
    title: string;
}

// TODO: Move this out of the bryntum folder

// mode, setMode
const SchedulesTabsSelector: React.FC<ISchedulesTabsSelectorProps> = (props) => {
    const [tab, setTab] = useState<number>(0);
    const location = useLocation().pathname.split('/')[2];

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (location !== 'schedules') {
        return <MySchedulesCalendar />;
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
        localStorage.setItem('SchedulesTab', newValue.toString());
    };

    let selection = <SchedulesCalendar canCreate canEdit />;
    if (tab === 1) {
        selection = <AppiontmentMap />;
    }

    return (
        <>
            <Tabs
                variant="fullWidth"
                value={tab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                centered
            >
                <Tab label="Appointments" />
                <Tab label="Map" />
            </Tabs>
            {selection}
        </>
    );
};

export default SchedulesTabsSelector;
