import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    FormLabel,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    TableHead,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import {
    getFactorAndRiskAssessmentById,
    gotoNextMenu,
    gotoPreviousMenu,
    saveFactorAndRiskAssessment,
    saveBarrierAndStrengthInCarePlan,
} from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import { copyObject } from '../../../utils/common';
import { FactorAndRiskAssessmentItem } from '../../../Models/FactorAndRisk/FactorAndRiskAssessmentItem.model';
import { FactorRiskAssessmentTypes } from '../../../utils/assessments';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';
import FormControlLabel from '@mui/material/FormControlLabel';

const OtherFactorAndRiskAssessment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { factorAndRiskAssessment, isFetching, isSaving } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const factorAndRiskTypes = ['LifeContext', 'HealthRisk', 'LearningLiteracy', 'StrengthOrAsset'];

    useEffect(() => {
        dispatch(getFactorAndRiskAssessmentById(engagementDraft.FactorAndRiskAssessmentId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChangeMiniMentalExamItem = (value: any, parentPropsName: string, childPropsName: string, saveLocal = false) => {
        let data = copyObject(factorAndRiskAssessment);
        data[parentPropsName][childPropsName] = value;
        dispatch(saveFactorAndRiskAssessment({ ...data, MemberId: memberId }, saveLocal));
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveFactorAndRiskAssessment({ ...factorAndRiskAssessment, Status: StatusEnum.Completed }, false, true));
            dispatch(saveBarrierAndStrengthInCarePlan(factorAndRiskAssessment, memberId));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const renderFactorAndRiskAssessmentItem = (parentPropsName: string, index: number) => {
        const factorAndRiskAssessmentItem = factorAndRiskAssessment[parentPropsName] as FactorAndRiskAssessmentItem;

        return (
            Boolean(factorAndRiskAssessment) && (
                <TableRow key={`other-factor-item-${parentPropsName}-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell width="20%" style={{ verticalAlign: 'top' }}>
                        <FormLabel>{factorAndRiskAssessmentItem.Name}</FormLabel>
                    </TableCell>
                    <TableCell width="35%">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id={`${parentPropsName}-type-select-label`}>Type</InputLabel>
                                    <Select
                                        labelId={`${parentPropsName}-type-select-label`}
                                        value={Boolean(factorAndRiskAssessmentItem?.MemberOption) ? factorAndRiskAssessmentItem.MemberOption : ''}
                                        label="Type"
                                        onChange={(e) => handleOnChangeMiniMentalExamItem(e.target.value, parentPropsName, 'MemberOption')}
                                    >
                                        {FactorRiskAssessmentTypes.map((type) => (
                                            <MenuItem key={type.Id} value={type.Id}>
                                                {type.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Comments"
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    size="small"
                                    value={Boolean(factorAndRiskAssessmentItem?.MemberReports) ? factorAndRiskAssessmentItem.MemberReports : ''}
                                    onChange={(e) => handleOnChangeMiniMentalExamItem(e.target.value, parentPropsName, 'MemberReports', true)}
                                    onBlur={(e) => handleOnChangeMiniMentalExamItem(e.target.value, parentPropsName, 'MemberReports')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={factorAndRiskAssessmentItem.MemberReportsIncludedInCarePlan}
                                            onChange={(e) =>
                                                handleOnChangeMiniMentalExamItem(e.target.checked, parentPropsName, 'MemberReportsIncludedInCarePlan')
                                            }
                                        />
                                    }
                                    label="Include in Care Plan"
                                />
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell width="35%">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id={`${parentPropsName}-type-select-label`}>Type</InputLabel>
                                    <Select
                                        labelId={`${parentPropsName}-type-select-label`}
                                        value={Boolean(factorAndRiskAssessmentItem?.ProviderOption) ? factorAndRiskAssessmentItem.ProviderOption : ''}
                                        label="Type"
                                        onChange={(e) => handleOnChangeMiniMentalExamItem(e.target.value, parentPropsName, 'ProviderOption')}
                                    >
                                        {FactorRiskAssessmentTypes.map((type) => (
                                            <MenuItem key={type.Id} value={type.Id}>
                                                {type.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Comments"
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    size="small"
                                    value={Boolean(factorAndRiskAssessmentItem?.ProviderAssessed) ? factorAndRiskAssessmentItem.ProviderAssessed : ''}
                                    onChange={(e) => handleOnChangeMiniMentalExamItem(e.target.value, parentPropsName, 'ProviderAssessed', true)}
                                    onBlur={(e) => handleOnChangeMiniMentalExamItem(e.target.value, parentPropsName, 'ProviderAssessed')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={factorAndRiskAssessmentItem.ProviderAssessedIncludedInCarePlan}
                                            onChange={(e) =>
                                                handleOnChangeMiniMentalExamItem(e.target.checked, parentPropsName, 'ProviderAssessedIncludedInCarePlan')
                                            }
                                        />
                                    }
                                    label="Include in Care Plan"
                                />
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell width="5%" style={{ verticalAlign: 'top' }} align="center" sx={{ pl: 0 }}>
                        <Checkbox
                            checked={factorAndRiskAssessmentItem.IsNA}
                            onChange={(e) => handleOnChangeMiniMentalExamItem(e.target.checked, parentPropsName, 'IsNA')}
                        />
                    </TableCell>
                </TableRow>
            )
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item className="content-form" sx={{ mt: 3 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3}>Impacting Thoughts / Health / Interventions / Services and Strengths Noted</TableCell>
                                        <TableCell align="center" sx={{ pl: 0 }}>
                                            N/A
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{factorAndRiskTypes.map((type, index) => renderFactorAndRiskAssessmentItem(type, index))}</TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.FactorAndRiskAssessmentId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Factor And Risk Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Factor and Risk assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Factor And Risk Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    showBottomCenter={true}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Factor and Risk assessment?"
                />
            )}
        </Grid>
    );
};

export default OtherFactorAndRiskAssessment;
