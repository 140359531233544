import { IRequest } from '../interfaces';
import { IRequestOrderByOption } from '../interfaces/IRequestOrderByOption';
import { IRequestSortByOption } from '../interfaces/IRequestSortByOption';

export const requestSortByOptionMapping: {
    [key: string]: IRequestSortByOption;
} = {
    Date: {
        value: 10,
        label: 'Date',
        compareRequests: (requestOne: IRequest, requestTwo: IRequest, order: IRequestOrderByOption) =>
            (new Date(requestOne.CreatedOn).getTime() - new Date(requestTwo.CreatedOn).getTime()) * order.value,
    },
    Name: {
        value: 20,
        label: 'Name',
        compareRequests: (requestOne: IRequest, requestTwo: IRequest, order: IRequestOrderByOption) =>
            requestTwo.RequestFor.Name.localeCompare(requestOne.RequestFor.Name) * order.value,
    },
    RequestType: {
        value: 30,
        label: 'Request Type',
        compareRequests: (requestOne: IRequest, requestTwo: IRequest, order: IRequestOrderByOption) => (requestOne.Type - requestTwo.Type) * order.value,
    },
    Status: {
        value: 40,
        label: 'Status',
        compareRequests: (requestOne: IRequest, requestTwo: IRequest, order: IRequestOrderByOption) => (requestOne.Status - requestTwo.Status) * order.value,
    },
    Priority: {
        value: 50,
        label: 'Priority',
        compareRequests: (requestOne: IRequest, requestTwo: IRequest, order: IRequestOrderByOption) =>
            (requestOne.Priority - requestTwo.Priority) * -order.value,
    },
};

export const requestSortByOptions: IRequestSortByOption[] = Object.values(requestSortByOptionMapping);
