import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { SUCCESS, getVeradigmGuid, registerWithVeradigm, setProviderData, setShowVeradigmModal } from '../../store/adminProvider.slice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { setShowMessage } from '../../store/shared.slice';

const RegisterVeradigmModal = (props) => {
    const dispatch = useDispatch();
    const { loadingVeradigm, providerData, showVeradigmModal } = useSelector((state: RootState) => state.adminProvider);
    const { samlResponse, ssoEndpoint } = useSelector((state: RootState) => state.medication);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(providerData.VeradigmUserGuid);
        dispatch(setShowMessage(true, 'Copied to clipboard', 'success'));
    };

    const closeVeradigmModal = () => {
        dispatch(setShowVeradigmModal(false));
    };

    const submitHandler = () => {
        providerData.VeradigmUserGuid ? dispatch(registerWithVeradigm(providerData.Id)) : dispatch(getVeradigmGuid(providerData));
    };

    return (
        <>
            <form id="veradigmRegistration" method="post" target="_blank" action={ssoEndpoint}>
                <input id="samlResponse" type="hidden" name="SAMLResponse" value={samlResponse} />
                <input type="hidden" name="TARGET" value="" />
                <button style={{ display: 'none' }} id="submitVeradigmRegistration" type="submit" />
            </form>
            <Dialog open={showVeradigmModal} onClose={closeVeradigmModal} fullWidth disableEnforceFocus maxWidth="lg">
                <DialogTitle>
                    <Typography variant="h5">
                        Veradigm Registration for{' '}
                        <span style={{ fontWeight: 'bold' }}>
                            {providerData?.FirstName} {providerData?.LastName}
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} py={2}>
                        {loadingVeradigm ? (
                            <Grid item xs={12} textAlign={'center'}>
                                <CircularProgress size={48} style={{ marginRight: '8px' }} />
                            </Grid>
                        ) : (
                            <>
                                <Paper elevation={3} sx={{ p: 2, width: '100%' }}>
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <TextField
                                            label="Veradigm GUID"
                                            fullWidth
                                            value={providerData?.VeradigmUserGuid || 'N/A'}
                                            InputProps={{
                                                readOnly: true,
                                                style: {
                                                    userSelect: 'none',
                                                },
                                                endAdornment: (
                                                    <IconButton onClick={handleCopyClick} edge="end">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                        <FormControlLabel
                                            sx={{ minWidth: 300 }}
                                            control={
                                                <Checkbox
                                                    checked={providerData.VeradigmProviderAdmin}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        dispatch(
                                                            setProviderData({
                                                                ...providerData,
                                                                VeradigmProviderAdmin: event.target.checked,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Veradigm Provider Admin"
                                        />
                                        <Button variant="contained" onClick={submitHandler} color="primary" sx={{ minWidth: 200 }}>
                                            {providerData.VeradigmUserGuid ? 'Open Veradigm' : 'Get GUID'}
                                        </Button>
                                    </Stack>
                                </Paper>

                                <Grid item xs={12} textAlign={'center'} mt={2}>
                                    <Stack spacing={2} mt={2}>
                                        <Typography variant="h6">Steps for adding provider to Veradigm</Typography>

                                        <List sx={{ listStyle: 'decimal', pl: 4 }}>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                <ListItemText
                                                    primary={
                                                        <Stack direction={'row'} alignItems={'flex-end'}>
                                                            <Typography sx={{ textDecoration: providerData.VeradigmUserGuid ? 'line-through' : 'none' }}>
                                                                Press <b>Get GUID</b> to start registration process and recieve Veradigm GUID
                                                            </Typography>
                                                            {providerData.VeradigmUserGuid ? (
                                                                <CheckIcon sx={{ color: SUCCESS, fontSize: '28px', ml: 1 }} />
                                                            ) : null}
                                                        </Stack>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                <ListItemText
                                                    primary={
                                                        <Stack direction={'row'} alignItems={'flex-end'}>
                                                            <Typography>
                                                                Press <b>Open Veradigm</b> to finish Veradigm registration process
                                                            </Typography>
                                                        </Stack>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            <Typography>You will need the following:</Typography>
                                                            <ul>
                                                                <li>CHP Email Address</li>
                                                                <li>Social Security Number</li>
                                                                <li>Driver's License or Passport</li>
                                                            </ul>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                <ListItemText primary={<Typography>Complete ID.me registration process</Typography>} />
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            <Typography>
                                                                <i>If you already have an ID.me account and need to change the email</i>, follow these steps at{' '}
                                                                <a
                                                                    href="https://help.id.me/hc/en-us/articles/20599015408279-Removing-your-work-email-from-a-duplicate-account"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    help.id.me
                                                                </a>
                                                            </Typography>
                                                            <ol>
                                                                <li>Sign in to your ID.me account using your work email address.</li>
                                                                <li>
                                                                    In the top menu of your account, select <b>Sign In & Security</b>, then select{' '}
                                                                    <b>Privacy</b>.
                                                                </li>
                                                                <li>
                                                                    From the "My Data Rights" section, under "Manage my data", select <b>Remove my data</b>.
                                                                </li>
                                                                <li>
                                                                    Select <b>Close my account</b>. Select a reason for closing your account.
                                                                </li>
                                                                <li>
                                                                    Select <b>Yes, close my account</b>.
                                                                </li>
                                                            </ol>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className="button-120" onClick={closeVeradigmModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RegisterVeradigmModal;
