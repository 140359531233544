import {
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import Authorization from '../../../../utils/Authorization';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import OnboardCallPerformance from '../../../../Models/Widget/OnboardCallPerformance.model';
import WidgetLoading from '../../Shared/WidgetLoading';
import InfoIcon from '@mui/icons-material/Info';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import { fetchOnboardCallingPerformance } from '../../../Widgets/WidgetService';
import { TimePeriodFilterEnum } from '../../../../Enum/TimePeriodFilterEnum';

const populationTypeList = [
    { value: '1', text: 'Full Program' },
    { value: '2', text: 'HRA' },
    { value: '3', text: 'FUHFUM' },
];

const OnboardCallPerformances = () => {
    const allText = 'All';
    const history = useNavigate();
    const authData = new Authorization();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { permissionBasedProviders } = useSelector((state: RootState) => state.provider);
    const [callingPerformance, setCallingPerformance] = useState<OnboardCallPerformance>();
    const {
        onboardCallSelectedProvider,
        setOnboardCallSelectedProvider,
        onboardCallSelectedFilter,
        setOnboardCallSelectedFilter,
        onboardCallFromDate,
        setOnboardCallFromDate,
        onboardCallToDate,
        setOnboardCallToDate,
        onboardCallShowInActiveProviders,
        setOnboardCallShowInActiveProviders,
        onboardCallPopulationTypes,
        setOnboardCallPopulationTypes,
    } = useWidgetContext();
    const filteredProviders =
        (onboardCallShowInActiveProviders ? permissionBasedProviders : permissionBasedProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const isSelectAll = onboardCallPopulationTypes?.length === populationTypeList.length;

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [isPopulationUpdated, setIsPopulationUpdated] = useState(false);

    useEffect(() => {
        if (onboardCallPopulationTypes.length === 0) setOnboardCallPopulationTypes(populationTypeList.map((a) => a.text));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeFilter = async () => {
        setIsLoading(true);
        let dateFrom =
            Boolean(onboardCallFromDate) && onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? new Date(onboardCallFromDate).toISOString() : '';
        let dateTo = Boolean(onboardCallToDate) && onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? new Date(onboardCallToDate).toISOString() : '';

        const data = await fetchOnboardCallingPerformance(
            onboardCallSelectedProvider,
            onboardCallPopulationTypes,
            onboardCallSelectedFilter,
            dateFrom,
            dateTo,
            onboardCallShowInActiveProviders
        );
        setCallingPerformance(data);
        setIsLoading(false);
        setIsPopulationUpdated(false);
    };

    useEffect(() => {
        if (onboardCallSelectedFilter !== TimePeriodFilterEnum.Custom || (Boolean(onboardCallFromDate) && Boolean(onboardCallToDate))) {
            changeFilter();
        }

        if (onboardCallSelectedFilter !== TimePeriodFilterEnum.Custom && Boolean(onboardCallFromDate) && Boolean(onboardCallToDate)) {
            setOnboardCallFromDate(null);
            setOnboardCallToDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboardCallSelectedProvider, onboardCallSelectedFilter, onboardCallFromDate, onboardCallToDate, onboardCallShowInActiveProviders]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setOnboardCallShowInActiveProviders(isChecked);
        setOnboardCallSelectedProvider(allText);
    };

    const dayFilterList = [
        { Id: TimePeriodFilterEnum.Last1Month, Value: TimePeriodFilterEnum.Last1MonthDescription },
        { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
        { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
        { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
        { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
    ];

    const changeFromDate = (date: any) => {
        setOnboardCallFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(onboardCallToDate) || new Date(onboardCallToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setOnboardCallToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setOnboardCallToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(onboardCallFromDate) || new Date(onboardCallFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setOnboardCallFromDate(startDate);
        }
    };

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToDetail = () => {
        history(`/provider/metricsummary/onboardcallperformancedetail`);
    };

    const handleOnChange = (e: SelectChangeEvent<unknown>) => {
        let selectedValues = e.target.value as string[];
        if (selectedValues.includes(allText) && !isSelectAll) {
            selectedValues = populationTypeList.map((o) => o.text);
        } else if (selectedValues.includes(allText)) {
            selectedValues = [];
        }

        setOnboardCallPopulationTypes(selectedValues);
        setIsPopulationUpdated(true);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" color={'primary'} className="pointer">
                                <span className="pointer" onClick={navigateToDetail}>
                                    Onboard Calling Performance
                                </span>
                            </Typography>
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Conversion Rate :
                                        </Typography>
                                        <Typography variant="caption">
                                            Calculates the percentage of members scheduled by the chosen Provider whose members’ status changed from “Scheduled”
                                            to “Active” divided by the total amount of members who are “Scheduled” in the given time frame
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Number of Calls:
                                        </Typography>
                                        <Typography variant="caption">Count of un-skipped calls that went through the call queue</Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Number of Successful Onboards:
                                        </Typography>
                                        <Typography variant="caption">
                                            Count of members whose status changed from “Undetermined” or “Scheduled” to Active that were scheduled by the chosen
                                            Provider
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Needs Reschedule:
                                        </Typography>
                                        <Typography variant="caption">
                                            Counts the number of unique “onboard” appointments that have a status of “Cancelled” and “NoCallNoShow”
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Number of Refusals to Enroll:
                                        </Typography>
                                        <Typography variant="caption">
                                            Counts number of unique members who have had a status change to “RefusedToEnroll” within date range
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2} marginTop={1}>
                        {showProviders && (
                            <Grid item xs={3}>
                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={filteredProviders.find((provider) => provider.Id === onboardCallSelectedProvider)?.Id || allText}
                                        size="small"
                                        label="Provider"
                                        onChange={(e) => {
                                            setOnboardCallSelectedProvider(e.target.value as string);
                                        }}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                    >
                                        <MenuItem key={allText} value={allText}>
                                            {allText}
                                        </MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={
                                                <Checkbox
                                                    checked={onboardCallShowInActiveProviders}
                                                    onChange={(e) => toggleInactiveHandler(e.target.checked)}
                                                />
                                            }
                                            label="Show Inactive"
                                        />
                                        {filteredProviders.map((option) => {
                                            return (
                                                <MenuItem
                                                    key={option.Id}
                                                    value={option.Id}
                                                    sx={{ color: option.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                                >
                                                    {`${option.LastName}, ${option.FirstName}`}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={2.4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-label">Population</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Population"
                                    value={onboardCallPopulationTypes}
                                    multiple
                                    onChange={handleOnChange}
                                    onClose={() => (isPopulationUpdated ? changeFilter() : null)}
                                    input={<OutlinedInput label="Population" />}
                                    renderValue={(selected) => (isSelectAll ? allText : selected.join(', '))}
                                >
                                    <MenuItem value={allText} className={isSelectAll ? 'Mui-selected' : ''} sx={{ padding: '0', paddingLeft: '10px' }}>
                                        <Checkbox checked={isSelectAll} indeterminate={Boolean(onboardCallPopulationTypes?.length) && !isSelectAll} />
                                        {allText}
                                    </MenuItem>
                                    {populationTypeList.map((type) => {
                                        return (
                                            <MenuItem key={type.value} value={type.text} sx={{ padding: '0', paddingLeft: '10px' }}>
                                                <Checkbox checked={onboardCallPopulationTypes.indexOf(type.text) > -1} />
                                                <ListItemText primary={type.text} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2.2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={onboardCallSelectedFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setOnboardCallSelectedFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item xs={2.2}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(onboardCallFromDate) ? onboardCallFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={2.2}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(onboardCallToDate) ? onboardCallToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>

                    <Grid container spacing={2} paddingLeft={2} paddingRight={2} marginTop={2} textAlign="center" height="49vh" sx={{ position: 'relative' }}>
                        {isLoading ? (
                            <WidgetLoading />
                        ) : (
                            <>
                                <Grid item xs={6}>
                                    <Card
                                        elevation={10}
                                        sx={{
                                            maxHeight: '200px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 2,
                                            justifyContent: 'space-between',
                                            border: '1px solid #d5d5d5',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <Typography variant="body1" fontWeight={'bold'}>
                                            Conversion Rate
                                        </Typography>
                                        <CardContent sx={{ pb: '0px !important' }}>
                                            <Typography variant="h2" fontWeight="bold" color="#ff6525">
                                                {callingPerformance?.ConversionRate}%
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card
                                        elevation={10}
                                        sx={{
                                            maxHeight: '200px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 2,
                                            justifyContent: 'space-between',
                                            border: '1px solid #d5d5d5',
                                            boxShadow: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={navigateToDetail}
                                    >
                                        <Typography variant="body1" fontWeight={'bold'}>
                                            Number of Calls
                                        </Typography>
                                        <CardContent sx={{ pb: '0px !important' }}>
                                            <Typography variant="h2" fontWeight="bold" color="#bb285b">
                                                {callingPerformance?.NumberOfCalls}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid container spacing={2} paddingLeft={2} paddingRight={2} marginTop={0.5} alignItems="center" justifyContent="center">
                                    <Grid item xs={6}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '200px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 2,
                                                justifyContent: 'space-between',
                                                border: '1px solid #d5d5d5',
                                                boxShadow: 'none',
                                            }}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                Number of Refusals to Enroll
                                            </Typography>
                                            <CardContent sx={{ pb: '0px !important' }}>
                                                <Typography variant="h2" fontWeight="bold" color="#0da2d4">
                                                    {callingPerformance?.NumberOfRefusalsToEnroll}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card
                                        elevation={10}
                                        sx={{
                                            maxHeight: '200px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 2,
                                            justifyContent: 'space-between',
                                            border: '1px solid #d5d5d5',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <Typography variant="body1" fontWeight={'bold'}>
                                            Number of Successful Onboards
                                        </Typography>
                                        <CardContent sx={{ pb: '0px !important' }}>
                                            <Typography variant="h2" fontWeight="bold" color="#0e7c13">
                                                {callingPerformance?.NumberOfSuccessfulOnboards}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card
                                        elevation={10}
                                        sx={{
                                            maxHeight: '200px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 2,
                                            justifyContent: 'space-between',
                                            border: '1px solid #d5d5d5',
                                            boxShadow: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={navigateToDetail}
                                    >
                                        <Typography variant="body1" fontWeight={'bold'}>
                                            Number of Members Needing Reschedule
                                        </Typography>
                                        <CardContent sx={{ pb: '0px !important' }}>
                                            <Typography variant="h2" fontWeight="bold" color="#5427ac">
                                                {callingPerformance?.NumberOfMembersNeedingReschedule}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <Grid container spacing={2} paddingLeft={2} paddingRight={2} marginTop={2} textAlign="center"></Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default OnboardCallPerformances;
