import {
    Grid,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    ListSubheader,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { RootState } from '../../../../reducers';
import React, { useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchFuhFumInProgressSummary, exportFuhFumInProgressSummary } from '../../../Widgets/WidgetService';
import { dateFormatter } from '../../../../utils/timeFormat';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import Authorization from '../../../../utils/Authorization';
import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';
import { parentUrl } from '../../../../utils/constants';
import { isDefaultDate } from '../../../../utils/common';
import '../../Widgets.css';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const daysList = [
    { Id: 1, Name: '0-7 Days' },
    { Id: 2, Name: '8-14 Days' },
    { Id: 3, Name: '15-22 Days' },
    { Id: 4, Name: '23-30 Days' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const FuhFumInProgressSummaryDetail = () => {
    const history = useNavigate();
    const authData = new Authorization();
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const { tccProviders, licensedProviders, isFetchedTCCProvider, isFetchedLicensedProvider } = useSelector((state: RootState) => state.provider);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const [providers, setProviders] = useState([]);
    const {
        inProgressProvider,
        setInProgressProvider,
        inProgressSelectedDay,
        setInProgressSelectedDay,
        inProgressDateFilter,
        setInProgressDateFilter,
        inProgressFromDate,
        setInProgressFromDate,
        inProgressToDate,
        setInProgressToDate,
        inProgressShowInActiveProviders,
        setInProgressShowInActiveProviders,
    } = useWidgetContext();
    const filteredTccProviders = (inProgressShowInActiveProviders ? tccProviders : tccProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredLicensedProviders =
        (inProgressShowInActiveProviders ? licensedProviders : licensedProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const initialPage = useCallback(() => {
        if (isFetchedTCCProvider && isFetchedLicensedProvider) {
            let providersList = [
                {
                    Name: 'TCC Providers',
                    List: filteredTccProviders,
                },
                {
                    Name: 'Licensed Providers',
                    List: filteredLicensedProviders,
                },
            ];
            setProviders(providersList);
            if (providersList.length) {
                const loggedInProviderId = authData.UserId;
                if (Boolean(loggedInProviderId) && !Boolean(inProgressProvider)) {
                    const tccInfo = filteredTccProviders.find((t) => t.Id === loggedInProviderId);
                    const licensdInfo = filteredLicensedProviders.find((t) => t.Id === loggedInProviderId);
                    if (Boolean(tccInfo) || Boolean(licensdInfo)) {
                        setInProgressProvider(loggedInProviderId);
                    } else {
                        setInProgressProvider('All');
                    }
                } else if (!Boolean(inProgressProvider)) {
                    setInProgressProvider('All');
                }
            } else {
                setInProgressProvider('All');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchedLicensedProvider, isFetchedTCCProvider, inProgressProvider, setInProgressProvider, authData.UserId, inProgressShowInActiveProviders]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    const getFuhFumSummary = async () => {
        if (inProgressDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(inProgressFromDate) && Boolean(inProgressToDate))) {
            setIsFetched(false);
            let dateFrom =
                Boolean(inProgressFromDate) && inProgressDateFilter === WidgetTimeFilterEnum.Custom ? new Date(inProgressFromDate).toISOString() : '';
            let dateTo = Boolean(inProgressToDate) && inProgressDateFilter === WidgetTimeFilterEnum.Custom ? new Date(inProgressToDate).toISOString() : '';
            const data = await fetchFuhFumInProgressSummary(
                inProgressProvider,
                inProgressSelectedDay,
                inProgressDateFilter,
                dateFrom,
                dateTo,
                inProgressShowInActiveProviders
            );
            setRows(data);
            setIsFetched(true);
        }

        if (inProgressDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(inProgressFromDate) && Boolean(inProgressToDate)) {
            setInProgressFromDate(null);
            setInProgressToDate(null);
        }
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgressProvider, inProgressSelectedDay, inProgressDateFilter, inProgressFromDate, inProgressToDate, inProgressShowInActiveProviders]);

    const changeFromDate = (date: any) => {
        setInProgressFromDate(date);
    };

    const changeToDate = (date: any) => {
        setInProgressToDate(date);
    };

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setInProgressShowInActiveProviders(isChecked);
        setInProgressProvider('All');
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let dateFrom = Boolean(inProgressFromDate) && inProgressDateFilter === WidgetTimeFilterEnum.Custom ? new Date(inProgressFromDate).toISOString() : '';
        let dateTo = Boolean(inProgressToDate) && inProgressDateFilter === WidgetTimeFilterEnum.Custom ? new Date(inProgressToDate).toISOString() : '';

        const data = await exportFuhFumInProgressSummary(
            inProgressProvider,
            inProgressSelectedDay,
            inProgressDateFilter,
            dateFrom,
            dateTo,
            inProgressShowInActiveProviders
        );
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `FuhFumInProgressMembers - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const prepareLastContact = (row: any) => {
        let contactDate = isDefaultDate(row.LastContactDate) ? '-' : dateFormatter(new Date(row.LastContactDate));
        let contact = Boolean(row.MeansOfContact) ? `${row.MeansOfContact} - ${row.LastContactRole} - ${contactDate}` : '-';
        return contact;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1} alignItems={'center'} display={'flex'}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            FUHFUM In-Progress Summary
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="pro-select-label">Provider</InputLabel>
                            <Select
                                labelId="pro-select-label"
                                id="pro-simple-select"
                                label="Provider"
                                value={inProgressProvider}
                                onChange={(e) => {
                                    setInProgressProvider(e.target.value as string);
                                }}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={inProgressShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {providers.map((provider, index) => [
                                    <ListSubheader key={index}>{provider.Name}</ListSubheader>,
                                    provider.List.map((pro) => {
                                        return (
                                            <MenuItem
                                                key={pro.Id}
                                                value={pro.Id}
                                                sx={{ paddingLeft: 4, color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                            >
                                                {pro.LastName}, {pro.FirstName}
                                            </MenuItem>
                                        );
                                    }),
                                ])}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="provider-label">Days Since Discharge</InputLabel>
                            <Select
                                labelId="provider-label"
                                id="demo-provider-select"
                                value={inProgressSelectedDay}
                                label="Days Since Discharge"
                                onChange={(e) => {
                                    setInProgressSelectedDay(e.target.value as string);
                                }}
                            >
                                <MenuItem key="All" value="0">
                                    All
                                </MenuItem>
                                {daysList.map(function (day) {
                                    return (
                                        <MenuItem key={day.Id} value={day.Id}>
                                            {day.Name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={inProgressDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setInProgressDateFilter(parseInt(e.target.value as any))}
                            >
                                {dayFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {inProgressDateFilter === WidgetTimeFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(inProgressFromDate) ? inProgressFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(inProgressToDate) ? inProgressToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={inProgressDateFilter === WidgetTimeFilterEnum.Custom ? 4.5 : 6.5}></Grid>
                    <Grid item xs={1}>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => exportDetail()}
                            variant="contained"
                            color="success"
                            disabled={rows.length ? false : true}
                        >
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isFetched ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell>Member Name</StyledTableCell>
                                                    <StyledTableCell>DOB</StyledTableCell>
                                                    <StyledTableCell>Referral Date</StyledTableCell>
                                                    <StyledTableCell>Discharge Date</StyledTableCell>
                                                    <StyledTableCell>Facility</StyledTableCell>
                                                    <StyledTableCell>Last Contact Date</StyledTableCell>
                                                    {rows.length && rows[0].ShowDaysSinceLastContactColumn ? (
                                                        <StyledTableCell># Day(s) Since Last Contact</StyledTableCell>
                                                    ) : null}
                                                    <StyledTableCell>Seen By Licensed Staff</StyledTableCell>
                                                    {rows.length && rows[0].ShowHasOpenRequestsColumn ? (
                                                        <StyledTableCell>Has Open Requests</StyledTableCell>
                                                    ) : null}
                                                    {rows.length && rows[0].ShowIsDocumentationCompleted ? (
                                                        <StyledTableCell>Documentation Completed</StyledTableCell>
                                                    ) : null}
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.length ? (
                                                    rows.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.MemberName}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.DOB !== null && !isDefaultDate(row.DOB)
                                                                    ? moment(new Date(row.DOB)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.ReferralDate !== null && !isDefaultDate(row.ReferralDate)
                                                                    ? moment(new Date(row.ReferralDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.DischargeDate !== null && !isDefaultDate(row.DischargeDate)
                                                                    ? moment(new Date(row.DischargeDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.Facility}</StyledTableCell>
                                                            <StyledTableCell>{prepareLastContact(row)}</StyledTableCell>
                                                            {row.ShowDaysSinceLastContactColumn ? (
                                                                <StyledTableCell>
                                                                    {row.DaysSinceDischarge === 1
                                                                        ? `${row.DaysSinceDischarge} Day`
                                                                        : row.DaysSinceDischarge > 1
                                                                        ? `${row.DaysSinceDischarge} Days`
                                                                        : '-'}
                                                                </StyledTableCell>
                                                            ) : null}
                                                            <StyledTableCell>{row.SeenByLicensedStaff === true ? 'Yes' : 'No'}</StyledTableCell>
                                                            {rows[0].ShowHasOpenRequestsColumn ? (
                                                                <StyledTableCell>{row.HasOpenRequests === true ? 'Yes' : 'No'}</StyledTableCell>
                                                            ) : null}
                                                            {rows[0].ShowIsDocumentationCompleted ? (
                                                                <StyledTableCell>{row.IsDocumentationCompleted === true ? 'Yes' : 'No'}</StyledTableCell>
                                                            ) : null}
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={11}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default FuhFumInProgressSummaryDetail;
