import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, FormControl, OutlinedInput, RadioGroup, FormLabel, Radio, InputAdornment, FormControlLabel } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { getCounselingAssessmentById, gotoNextMenu, gotoPreviousMenu, saveCounselingAssessment } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import classes from '../MemberAllergy/Styles';
import { Counseling } from '../../../Models/Assessment/Counseling.model';
import { CounselingTypeEnum } from '../../../Enum/CounselingTypeEnum';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { preventTextInput } from '../../../utils/common';

const counselingFormSchema = Yup.object().shape({
    IsCounselled: Yup.boolean().nullable(),
    TimeSpent: Yup.string().when('IsCounselled', {
        is: true,
        then: Yup.string().nullable().required('Time spent is required.'),
    }),
});

const CounselingComponent: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [selectedCounseling, setSelectedCounseling] = useState<Counseling>(new Counseling());
    const { counseling, isSaving, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    useEffect(() => {
        if (engagementDraft.CounselingAssessmentId) {
            dispatch(getCounselingAssessmentById(engagementDraft.CounselingAssessmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (values: Counseling) => {
        setSelectedCounseling({ ...values, MemberId: memberId });
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(
                saveCounselingAssessment({
                    ...selectedCounseling,
                    Status: StatusEnum.Completed,
                })
            );
        } else {
            dispatch(gotoNextMenu());
        }
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Formik initialValues={counseling} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={counselingFormSchema}>
                        {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<Counseling>) => (
                            <Form noValidate>
                                <Grid item sx={[classes.form, { pt: 4 }]} className="content-form">
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} md={2} sx={classes.titleContainer}>
                                            <FormLabel>Counseling</FormLabel>
                                        </Grid>
                                        <Grid item xs>
                                            <RadioGroup
                                                row
                                                name="IsCounselled"
                                                value={values.IsCounselled === null ? null : values.IsCounselled ? 'Yes' : 'No'}
                                                onChange={(e) => {
                                                    setFieldValue('IsCounselled', e.target.value === 'Yes');
                                                    setIsChanged(true);
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    {values.IsCounselled ? (
                                        <>
                                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                                <Grid item xs={4} md={2} sx={classes.titleContainer}>
                                                    <FormLabel id="type-row-radio-buttons-group-label">Counseling Type</FormLabel>
                                                </Grid>
                                                <Grid item xs>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="type-row-radio-buttons-group-label"
                                                        value={values.CounselingType}
                                                        name="CounselingType"
                                                        onChange={handleChange}
                                                    >
                                                        <FormControlLabel value={CounselingTypeEnum.Individual} control={<Radio />} label="Individual" />
                                                        <FormControlLabel value={CounselingTypeEnum.Family} control={<Radio />} label="Family" />
                                                        <FormControlLabel value={CounselingTypeEnum.Group} control={<Radio />} label="Group" />
                                                    </RadioGroup>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                                <Grid item xs={4} md={2} sx={classes.titleContainer}>
                                                    <FormLabel>Time Spent</FormLabel>
                                                </Grid>
                                                <Grid item xs>
                                                    <FormControl variant="outlined" sx={classes.numberInputControl}>
                                                        <OutlinedInput
                                                            size="small"
                                                            className="hide-textinput-label"
                                                            onChange={handleChange}
                                                            name="TimeSpent"
                                                            type="number"
                                                            value={values.TimeSpent}
                                                            inputProps={{ min: 0 }}
                                                            onKeyPress={preventTextInput}
                                                            endAdornment={<InputAdornment position="end">Minutes</InputAdornment>}
                                                            error={Boolean(submitCount) && Boolean(errors.TimeSpent)}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : null}
                                </Grid>
                                <Grid item justifyContent="flex-end" className="content-footer">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ marginRight: 2 }}
                                        disabled={isSaving}
                                        onClick={() => dispatch(gotoPreviousMenu())}
                                        startIcon={<ArrowBackIosIcon />}
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={isSaving}
                                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            {Boolean(engagementDraft.CounselingAssessmentId) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Counseling Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Counseling assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Counseling Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Counseling assessment?"
                />
            )}
        </Grid>
    );
};

export default CounselingComponent;
