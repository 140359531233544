import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    CircularProgress,
    FormControl,
    Stack,
    InputLabel,
    Select,
    MenuItem,
    Slide,
    FormControlLabel,
    IconButton,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    Typography,
    TextField,
    Checkbox,
    Autocomplete,
    Slider,
    OutlinedInput,
    InputAdornment,
    Divider,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import { RootState } from '../../../reducers';
import classes from './Styles';
import { MedicationTypeEnum, MedicationTypes } from '../../../utils/assessments';
import { saveMedication, toggleShowMedicationModal } from '../../../store/medication.slice';
import { Medications } from '../../../Models/Medications/Medications.model';
import { ProviderPreview } from '../../../Models/CarePlans/SchedulePlan.model';
import { setAssessmentError } from '../../../store/assessment.slice';
import { SlidingScale } from '../../../Models/Medications/SlidingScale.model';
import { SlidingScaleOption } from '../../../Models/Medications/SlidingScaleOption.model';
import { Taper } from '../../../Models/Medications/Taper.model';
import { preventTextInput } from '../../../utils/common';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const medicationFormSchema = Yup.object().shape({
    Medicine: Yup.string().nullable().required('Medicine is required.'),
    Dosage: Yup.string().nullable().required('Dosage is required.'),
    Frequency: Yup.string().nullable().required('Frequency is required.'),
    SlidingScales: Yup.array()
        .nullable()
        .of(
            Yup.object().shape({
                InsulinUnits: Yup.string().nullable().required('Medicine is required.'),
            })
        ),
    Tapers: Yup.array()
        .nullable()
        .of(
            Yup.object().shape({
                StartDate: Yup.string().nullable().required('Start Date is required.'),
                EndDate: Yup.string().nullable().required('End Date is required.'),
                Dose: Yup.string().nullable().required('Dose is required.'),
            })
        ),
});

const AddOrUpdateMedication: React.FC<{ memberId: string; onChange: Function }> = ({ memberId, onChange }) => {
    const dispatch = useDispatch();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const {
        selectedMedication,
        medicationFrequencyTimes,
        showMedicationModal,
        isSaving: isSavingMedication,
    } = useSelector((state: RootState) => state.medication);

    const handleOnMedicationModal = () => dispatch(toggleShowMedicationModal(false));

    const handleSubmitForm = (values: Medications) => {
        dispatch(saveMedication(memberId, values));
        if (onChange) {
            onChange();
        }
    };

    const handleOnAddNewScale = (medication: Medications, setFieldValue: Function) => {
        let previousScale = medication.SlidingScales.length > 0 ? medication.SlidingScales[medication.SlidingScales.length - 1] : null;
        const newSlidingScale = getNewSlidingScale(previousScale, null, medication.SlidingCeiling);
        if (Boolean(newSlidingScale)) {
            setFieldValue('SlidingScales', [...medication.SlidingScales, newSlidingScale]);
        }
    };

    const handleOnRemoveScale = (medication: Medications, selectedIndex: number, setFieldValue: Function) => {
        setFieldValue(
            'SlidingScales',
            medication.SlidingScales.filter((_, index) => index !== selectedIndex)
        );
    };

    const handleOnRemoveTaper = (medication: Medications, selectedIndex: number, setFieldValue: Function) => {
        setFieldValue(
            'Tapers',
            medication.Tapers.filter((_, index) => index !== selectedIndex)
        );
    };

    const getNewSlidingScale = (priorScale?: SlidingScale, units?: number, ceil?: number) => {
        if (priorScale && priorScale.Max >= ceil) {
            dispatch(
                setAssessmentError({
                    Show: true,
                    Message: "Unable to add new scale, you've reached the maximum value already.",
                })
            );
            return null;
        }

        let newScale = {
            Min: 200,
            Max: 250,
            InsulinUnits: units ? units : 0,
            SlidingScaleOption: {
                Floor: 0,
                Ceil: ceil,
                Step: 1,
            } as SlidingScaleOption,
        } as SlidingScale;

        if (priorScale) {
            let newMin = priorScale.Max + 1;
            let newMax = newMin + 49;
            newScale.Min = newMin;
            newScale.Max = newMax > ceil ? ceil : newMax;
        }

        return newScale;
    };

    const handleOnAddNewTaper = (medication: Medications, setFieldValue: Function) => {
        setFieldValue('Tapers', [...medication.Tapers, new Taper()]);
    };

    const handleOnChangeType = (medication: Medications, type: string, setFieldValue: Function) => {
        setFieldValue('MedicationType', type);
        if (type === MedicationTypeEnum.SlidingScale && !Boolean(medication.SlidingScales?.length)) {
            const slidingCeiling = 400;
            let slidingScales = [];

            let scale1 = getNewSlidingScale(null, 2, slidingCeiling);
            let scale2 = getNewSlidingScale(scale1, 4, slidingCeiling);
            let scale3 = getNewSlidingScale(scale2, 6, slidingCeiling);
            let scale4 = getNewSlidingScale(scale3, 8, slidingCeiling);
            if (Boolean(scale1)) {
                slidingScales.push(scale1);
            }
            if (Boolean(scale2)) {
                slidingScales.push(scale2);
            }
            if (Boolean(scale3)) {
                slidingScales.push(scale3);
            }
            if (Boolean(scale4)) {
                slidingScales.push(scale4);
            }

            setFieldValue('SlidingCeiling', slidingCeiling);
            setFieldValue('SlidingScales', slidingScales);
        } else if (type === MedicationTypeEnum.TaperedMedication && !Boolean(medication.Tapers?.length)) {
            setFieldValue('Tapers', [new Taper(), new Taper(), new Taper()]);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog
                disableScrollLock
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus
                open={showMedicationModal}
                maxWidth="xl"
                sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
                scroll="body"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleOnMedicationModal}
            >
                {showMedicationModal ? (
                    <Formik initialValues={selectedMedication} enableReinitialize={true} onSubmit={handleSubmitForm} validationSchema={medicationFormSchema}>
                        {({ values, setFieldValue, errors, handleChange, handleSubmit, validateForm, submitCount }: FormikProps<Medications>) => (
                            <Form noValidate>
                                <DialogTitle>
                                    <Stack direction="row" justifyContent="space-between">
                                        <span>Medication Management</span>
                                        <FormControl size="small" className="form-control-200" sx={{ mb: 0 }}>
                                            <InputLabel>Medication Type</InputLabel>
                                            <Select
                                                value={Boolean(values.MedicationType) ? values.MedicationType : ''}
                                                label="Medication Type"
                                                name="MedicationType"
                                                onChange={(e) => handleOnChangeType(values, e.target.value, setFieldValue)}
                                            >
                                                {MedicationTypes.map((type) => (
                                                    <MenuItem key={type.Id} value={type.Id}>
                                                        {type.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </DialogTitle>
                                <DialogContent dividers sx={[classes.form, { width: '800px' }]}>
                                    <Grid container spacing={2}>
                                        <Grid item md={4}>
                                            <TextField
                                                fullWidth
                                                label="Medicine"
                                                variant="outlined"
                                                size="small"
                                                value={values.Medicine}
                                                name="Medicine"
                                                onChange={handleChange}
                                                error={Boolean(submitCount) && Boolean(errors?.Medicine)}
                                                required
                                                helperText={Boolean(submitCount) ? errors?.Medicine : ''}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <TextField
                                                fullWidth
                                                label="Dosage"
                                                variant="outlined"
                                                size="small"
                                                value={values.Dosage}
                                                name="Dosage"
                                                onChange={handleChange}
                                                error={Boolean(submitCount) && Boolean(errors?.Dosage)}
                                                required
                                                helperText={Boolean(submitCount) ? errors?.Dosage : ''}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <Autocomplete
                                                fullWidth
                                                classes={{ input: 'p-1' }}
                                                getOptionLabel={(option) => option as string}
                                                options={medicationFrequencyTimes.map((m) => m.Frequency)}
                                                freeSolo
                                                size="small"
                                                value={(Boolean(values.Frequency) ? values.Frequency : null) as any}
                                                onChange={(e, value: any) => setFieldValue('Frequency', Boolean(value) ? value : '')}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        label="Frequency"
                                                        variant="outlined"
                                                        name="Frequency"
                                                        onChange={handleChange}
                                                        error={Boolean(errors?.Frequency)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                                                        }}
                                                        helperText={errors?.Frequency}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <TextField
                                                fullWidth
                                                label="Prescribed For"
                                                variant="outlined"
                                                size="small"
                                                value={Boolean(values.PrescribedFor) ? values.PrescribedFor : ''}
                                                name="PrescribedFor"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel>Ordering Provider</InputLabel>
                                                <Select
                                                    value={Boolean(values.OrderingProvider?.Id) ? String(values.OrderingProvider.Id) : ''}
                                                    label="Ordering Provider"
                                                    name="OrderingProvider"
                                                    onChange={(e) => {
                                                        const selectedProvider = activeProviders.find((p) => p.Id === e.target.value);
                                                        setFieldValue(e.target.name, {
                                                            Id: selectedProvider.Id,
                                                            Name: selectedProvider.FullName,
                                                            Role: selectedProvider.Role?.RoleName,
                                                        } as ProviderPreview);
                                                    }}
                                                >
                                                    {activeProviders.map((provider) => (
                                                        <MenuItem key={provider.Id} value={provider.Id}>
                                                            {provider.FullName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="Last Taken"
                                                value={Boolean(values.LastTaken) ? values.LastTaken : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => setFieldValue('LastTaken', newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="Fill Date"
                                                value={Boolean(values.StartDate) ? values.StartDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => setFieldValue('StartDate', newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <DesktopDatePicker
                                                label="End Date"
                                                value={Boolean(values.EndDate) ? values.EndDate : null}
                                                minDate={new Date('2017-01-01')}
                                                onChange={(newValue) => setFieldValue('EndDate', newValue)}
                                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControlLabel
                                                sx={{ ml: 2 }}
                                                control={
                                                    <Checkbox
                                                        checked={values.HiddenFromMember}
                                                        onChange={(e) => setFieldValue('HiddenFromMember', e.target.checked)}
                                                    />
                                                }
                                                label="Medication Hidden from Member"
                                            />
                                        </Grid>
                                    </Grid>
                                    {values.MedicationType === MedicationTypeEnum.SlidingScale ? (
                                        <>
                                            <Grid container spacing={2} sx={{ mlt: 2 }}>
                                                <Grid item md={4} display="flex" alignItems="center">
                                                    <Typography variant="body1" component="label">
                                                        Blood Glucose (mg/dl)
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={4} display="flex" alignItems="center">
                                                    <TextField
                                                        size="small"
                                                        type="number"
                                                        label="Max (mg/dl)"
                                                        variant="outlined"
                                                        value={`${values.SlidingCeiling}`}
                                                        onKeyPress={preventTextInput}
                                                        inputProps={{ min: 0 }}
                                                        sx={{ mb: 0 }}
                                                    />
                                                </Grid>
                                                <Grid item md={4} display="flex" alignItems="center">
                                                    <Typography variant="body1" component="label">
                                                        Insulin Units
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {values.SlidingScales.map((scale, index) => (
                                                <Grid key={`blood-glucose-slider-${index}`} container spacing={2} sx={{ mt: 2 }}>
                                                    <Grid item md={8} display="flex" alignItems="center">
                                                        <Slider
                                                            sx={{ width: '100%', mr: 2 }}
                                                            value={[parseInt(`${scale.Min}`), parseInt(`${scale.Max}`)]}
                                                            valueLabelDisplay="on"
                                                            max={parseInt(`${values.SlidingCeiling}`)}
                                                            onChange={(event: Event, newValue: number | number[]) => {
                                                                const values = newValue as number[];
                                                                setFieldValue(`SlidingScales[${index}].Min`, values[0]);
                                                                setFieldValue(`SlidingScales[${index}].Max`, values[1]);
                                                            }}
                                                            marks={[
                                                                {
                                                                    value: 0,
                                                                    label: '0',
                                                                },
                                                                {
                                                                    value: parseInt(`${values.SlidingCeiling}`),
                                                                    label: `${parseInt(`${values.SlidingCeiling}`)}`,
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item md={4} display="flex" alignItems="center">
                                                        <FormControl variant="outlined" fullWidth>
                                                            <OutlinedInput
                                                                size="small"
                                                                className="hide-textinput-label"
                                                                onChange={handleChange}
                                                                name={`SlidingScales[${index}].InsulinUnits`}
                                                                value={scale.InsulinUnits}
                                                                error={
                                                                    Boolean(errors.SlidingScales) &&
                                                                    Boolean(errors.SlidingScales[index]) &&
                                                                    Boolean(errors.SlidingScales[index]['InsulinUnits'])
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                                        <IconButton
                                                                            edge="end"
                                                                            onClick={() => handleOnRemoveScale(values, index, setFieldValue)}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Button variant="outlined" sx={{ mt: 3, mb: 2 }} onClick={() => handleOnAddNewScale(values, setFieldValue)}>
                                                Add New Scale
                                            </Button>
                                            <div>
                                                <Typography
                                                    variant="subtitle1"
                                                    component="label"
                                                    sx={{ mb: 3 }}
                                                >{`If Blood Glucose > 400; please contact their Physician`}</Typography>
                                            </div>
                                        </>
                                    ) : null}
                                    {values.MedicationType === MedicationTypeEnum.TaperedMedication ? (
                                        <>
                                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                                <Grid item md={8}>
                                                    <Typography variant="body1" component="label">
                                                        Taper Dates
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <Typography variant="body1" component="label">
                                                        Dose
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {values.Tapers.map((taper, index) => (
                                                <Grid key={`taper-date-${index}`} container spacing={2} sx={{ mt: 2 }}>
                                                    <Grid item md={4}>
                                                        <DesktopDatePicker
                                                            label="Start Date"
                                                            value={Boolean(taper.StartDate) ? new Date(taper.StartDate) : null}
                                                            inputFormat="MM/dd/yyyy"
                                                            minDate={new Date('2017-01-01')}
                                                            onChange={(date) => {
                                                                if (moment(date).isValid() || !Boolean(date)) {
                                                                    setFieldValue(`Tapers[${index}].StartDate`, date.toISOString());
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    {...params}
                                                                    error={
                                                                        Boolean(errors.Tapers) &&
                                                                        Boolean(errors.Tapers[index]) &&
                                                                        Boolean(errors.Tapers[index]['StartDate']) &&
                                                                        Boolean(submitCount)
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <DesktopDatePicker
                                                            label="End Date"
                                                            value={Boolean(taper.EndDate) ? new Date(taper.EndDate) : null}
                                                            inputFormat="MM/dd/yyyy"
                                                            minDate={
                                                                taper.StartDate && moment(taper.StartDate).isValid()
                                                                    ? new Date(taper.StartDate)
                                                                    : new Date('2017-01-01')
                                                            }
                                                            onChange={(date) => {
                                                                if (moment(date).isValid() || !Boolean(date)) {
                                                                    setFieldValue(`Tapers[${index}].EndDate`, date.toISOString());
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    {...params}
                                                                    error={
                                                                        Boolean(errors.Tapers) &&
                                                                        Boolean(errors.Tapers[index]) &&
                                                                        Boolean(errors.Tapers[index]['EndDate']) &&
                                                                        Boolean(submitCount)
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <FormControl variant="outlined" fullWidth>
                                                            <OutlinedInput
                                                                size="small"
                                                                className="hide-textinput-label"
                                                                onChange={handleChange}
                                                                name={`Tapers[${index}].Dose`}
                                                                value={taper.Dose}
                                                                error={
                                                                    Boolean(errors.Tapers) &&
                                                                    Boolean(errors.Tapers[index]) &&
                                                                    Boolean(errors.Tapers[index]['Dose'] && Boolean(submitCount))
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                                        <IconButton
                                                                            edge="end"
                                                                            onClick={() => handleOnRemoveTaper(values, index, setFieldValue)}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Button variant="outlined" sx={{ mt: 3, mb: 2 }} onClick={() => handleOnAddNewTaper(values, setFieldValue)}>
                                                Add New Taper
                                            </Button>
                                        </>
                                    ) : null}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        onClick={() => handleSubmit()}
                                        disabled={isSavingMedication}
                                        endIcon={isSavingMedication ? <CircularProgress size={18} color="inherit" /> : null}
                                    >
                                        Save
                                    </Button>
                                    <Button size="small" type="button" onClick={handleOnMedicationModal} variant="outlined" disabled={isSavingMedication}>
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </Dialog>
        </LocalizationProvider>
    );
};

export default AddOrUpdateMedication;
