export enum DrinkContainingAlcoholFrequencyEnum {
    NeverDescription = 'Never',
    Never = 1,
    MonthlyOrLessDescription = 'Monthly or less',
    MonthlyOrLess = 2,
    TwoOrFourXMonthDescription = '2-4x a month',
    TwoOrFourXMonth = 3,
    TwoOrThreeXWeekDescription = '2-3x a week',
    TwoOrThreeXWeek = 4,
    FourPlusTimesWeekDescription = '4+ times a week',
    FourPlusTimesWeek = 5,
}
