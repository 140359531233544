import { CounselingTypeEnum } from '../../Enum/CounselingTypeEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface ICounseling extends BaseEntity {
    IsCounselled?: boolean;
    MemberId: string;
    MemberName: string;
    CounselingType: CounselingTypeEnum;
    TimeSpent: number | null;
    Status: StatusEnum;
}

export class Counseling implements ICounseling {
    Id: string | null;
    IsCounselled?: boolean;
    MemberId: string;
    MemberName: string;
    CounselingType: CounselingTypeEnum;
    TimeSpent: number | null;
    Status: StatusEnum;

    constructor() {
        this.Id = null;
        this.IsCounselled = null;
        this.MemberId = null;
        this.MemberName = '';
        this.CounselingType = CounselingTypeEnum.Individual;
    }
}
