import React, { useEffect, useState } from 'react';
import { fetchCallerPerformance } from '../OfficeOnboardingWidgetService';
import StatBox from './StatBox';
import b64toBlob from 'b64-to-blob';
import { fetchPerformanceExcelReport, fetchDetailedCallHistoryReport } from '../OfficeOnboardingWidgetService';

import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';

interface CallingPerformanceProps {
    isAdmin: boolean;
    onboarderId: string;
    onboarderName: string;
    onboarderCallGoal: number;
}

interface CallerStats {
    callsYesterday: number;
    callsToday: number;
    scheduledYesterday: number;
    scheduledToday: number;
    averageScheduledAppointments: number;
    averageFiveDayCalls: number;
    thirtyDayCalls: number;
    sixtyDayCalls: number;
    ninetyDayCalls: number;
    thirtyDayAppts: number;
    sixtyDayAppts: number;
    ninetyDayAppts: number;
    thirtyDayWorkDays: number;
    sixtyDayWorkDays: number;
    ninetyDayWorkDays: number;
}

const CallingPerformance = (props: CallingPerformanceProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [averageFiveDayCalls, setAverageFiveDayCalls] = useState<number>(0);
    const [callsYesterday, setCallsYesterday] = useState<number>(0);
    const [callsToday, setCallsToday] = useState<number>(0);
    const [averageScheduledAppointments, setAverageScheduledAppointments] = useState<number>(0);
    const [scheduledYesterday, setScheduledYesterday] = useState<number>(0);
    const [scheduledToday, setScheduledToday] = useState<number>(0);
    const [thirtyDayCalls, setThirtyDayCalls] = useState<number>(0);
    const [sixtyDayCalls, setSixtyDayCalls] = useState<number>(0);
    const [ninetyDayCalls, setNinetyDayCalls] = useState<number>(0);
    const [thirtyDayAppts, setThirtyDayAppts] = useState<number>(0);
    const [sixtyDayAppts, setSixtyDayAppts] = useState<number>(0);
    const [ninetyDayAppts, setNinetyDayAppts] = useState<number>(0);
    const [thirtyDayWorkDays, setThirtyDayWorkDays] = useState<number>(0);
    const [sixtyDayWorkDays, setSixtyDayWorkDays] = useState<number>(0);
    const [ninetyDayWorkDays, setNinetyDayWorkDays] = useState<number>(0);
    const [pastCalls, setPastCalls] = useState<number>(0);
    const [pastScheduled, setPastScheduled] = useState<number>(0);
    const [pastDaysWorked, setPastDaysWorked] = useState<number>(0);
    const [exporting, setExporting] = useState<boolean>(false);
    const [reportRange, setReportRange] = useState<string>('30');

    const resetCounts = () => {
        setAverageFiveDayCalls(0);
        setCallsToday(0);
        setCallsYesterday(0);
        setAverageScheduledAppointments(0);
        setScheduledYesterday(0);
        setScheduledToday(0);
        setThirtyDayCalls(0);
        setSixtyDayCalls(0);
        setNinetyDayCalls(0);
        setThirtyDayAppts(0);
        setSixtyDayAppts(0);
        setNinetyDayAppts(0);
        setThirtyDayWorkDays(0);
        setSixtyDayWorkDays(0);
        setNinetyDayWorkDays(0);
    };

    const exportPerformanceReportClick = async () => {
        setExporting(true);
        const response = await fetchPerformanceExcelReport(props.onboarderId, reportRange);

        if (response.status === 200) {
            const data = await response.data;
            const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            const today = new Date();
            const fileName =
                props.onboarderName +
                '-' +
                reportRange +
                '-Day-Report' +
                '_' +
                (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
                '.' +
                today.getDate() +
                '.' +
                today.getFullYear();
            document.body.appendChild(a);
            a.href = blobUrl;
            a.download = fileName + '.xlsx';
            a.click();
            window.URL.revokeObjectURL(blobUrl);
            setExporting(false);
        }
    };

    const exportDetailedReportClick = async () => {
        setExporting(true);
        const response = await fetchDetailedCallHistoryReport(props.onboarderId, reportRange);

        if (response.status === 200) {
            const data = await response.data;
            const blob = b64toBlob(data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            const today = new Date();
            const fileName =
                props.onboarderName +
                '-' +
                reportRange +
                '-Day-Call-Details' +
                '_' +
                (today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
                '.' +
                today.getDate() +
                '.' +
                today.getFullYear();
            document.body.appendChild(a);
            a.href = blobUrl;
            a.download = fileName + '.xlsx';
            a.click();
            window.URL.revokeObjectURL(blobUrl);
            setExporting(false);
        }
    };

    // const getOnboarderStatsForAdmin = useCallback(async () => {
    //   const response = await fetchCallerPerformance(props.onboarderId);
    //   if (response.ok) {
    //     const data: CallerStats = await response.json();
    //     setAverageFiveDayCalls(data.averageFiveDayCalls);
    //     setCallsToday(data.callsToday);
    //     setCallsYesterday(data.callsYesterday);
    //     setAverageScheduledAppointments(data.averageScheduledAppointments);
    //     setScheduledYesterday(data.scheduledYesterday);
    //     setScheduledToday(data.scheduledToday);
    //     setLoading(false);
    //   } else {
    //     resetCounts();
    //     setLoading(false);
    //   }
    // }, [props.onboarderId]);

    useEffect(() => {
        setLoading(true);
        if (props.onboarderId) {
            fetchCallerPerformance(props.onboarderId).then(async (response: any) => {
                if (response.status === 200) {
                    const data: CallerStats = await response.data;
                    setAverageFiveDayCalls(data.averageFiveDayCalls);
                    setCallsToday(data.callsToday);
                    setCallsYesterday(data.callsYesterday);
                    setAverageScheduledAppointments(data.averageScheduledAppointments);
                    setScheduledYesterday(data.scheduledYesterday);
                    setScheduledToday(data.scheduledToday);
                    setThirtyDayCalls(data.thirtyDayCalls);
                    setSixtyDayCalls(data.sixtyDayCalls);
                    setNinetyDayCalls(data.ninetyDayCalls);
                    setThirtyDayAppts(data.thirtyDayAppts);
                    setSixtyDayAppts(data.sixtyDayAppts);
                    setNinetyDayAppts(data.ninetyDayAppts);
                    setThirtyDayWorkDays(data.thirtyDayWorkDays);
                    setSixtyDayWorkDays(data.sixtyDayWorkDays);
                    setNinetyDayWorkDays(data.ninetyDayWorkDays);
                    setLoading(false);
                } else {
                    resetCounts();
                    setLoading(false);
                }
            });
        } else {
            resetCounts();
            setLoading(false);
        }
    }, [props.onboarderId]);

    useEffect(() => {
        switch (reportRange) {
            case '60':
                setPastCalls(sixtyDayCalls);
                setPastScheduled(sixtyDayAppts);
                setPastDaysWorked(sixtyDayWorkDays);
                break;
            case '90':
                setPastCalls(ninetyDayCalls);
                setPastScheduled(ninetyDayAppts);
                setPastDaysWorked(ninetyDayWorkDays);
                break;
            default:
                setPastCalls(thirtyDayCalls);
                setPastScheduled(thirtyDayAppts);
                setPastDaysWorked(thirtyDayWorkDays);
                break;
        }
    }, [
        ninetyDayAppts,
        ninetyDayCalls,
        ninetyDayWorkDays,
        reportRange,
        sixtyDayAppts,
        sixtyDayCalls,
        sixtyDayWorkDays,
        thirtyDayAppts,
        thirtyDayCalls,
        thirtyDayWorkDays,
    ]);

    return (
        <Box>
            <Grid container spacing={2} marginBottom={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <FormLabel>Set Report Range in Days</FormLabel>
                        <RadioGroup row sx={{ marginX: 'auto' }} aria-label="report-range" name="report-range" value={reportRange}>
                            <FormControlLabel
                                value="30"
                                control={<Radio />}
                                label="30"
                                onClick={() => {
                                    setReportRange('30');
                                }}
                                checked={reportRange === '30'}
                            />
                            <FormControlLabel
                                value="60"
                                control={<Radio />}
                                label="60"
                                onClick={() => {
                                    setReportRange('60');
                                }}
                                checked={reportRange === '60'}
                            />
                            <FormControlLabel
                                value="90"
                                control={<Radio />}
                                label="90"
                                onClick={() => {
                                    setReportRange('90');
                                }}
                                checked={reportRange === '90'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <Button variant="contained" color="primary" disabled={exporting || loading} onClick={exportPerformanceReportClick}>
                        {!exporting ? (
                            <GetAppIcon style={{ marginRight: '8px', color: 'white' }} />
                        ) : (
                            <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />
                        )}
                        <span style={{ color: 'white' }}>Export to Excel</span>
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" marginBottom={2}>
                <Grid item xs={2}>
                    <StatBox loading={loading} title="Call Performance (Last 5 Days Worked)" stat={`${averageFiveDayCalls}`} color="#ff6525" />
                </Grid>
                {props.isAdmin ? (
                    <Grid item xs={2}>
                        <StatBox loading={loading} title="Number of Calls Last Day Worked" stat={`${callsYesterday}`} color="#8B0000"></StatBox>
                    </Grid>
                ) : undefined}
                <Grid item xs={2}>
                    <StatBox loading={loading} title="Number of Calls Today" stat={callsToday} color="#bb285b" />
                </Grid>
                <Grid item xs={2}>
                    <StatBox
                        loading={loading}
                        title="Average Scheduled Appointments (Last 5 Days Worked)"
                        stat={averageScheduledAppointments}
                        color="#5427ac"
                    />
                </Grid>
                {props.isAdmin ? (
                    <Grid item xs={2}>
                        <StatBox loading={loading} title="# of Appointments Scheduled Yesterday" stat={scheduledYesterday} color="#191970"></StatBox>
                    </Grid>
                ) : undefined}
                <Grid item xs={2}>
                    <StatBox loading={loading} title="Number of Appointments Scheduled Today" stat={scheduledToday} color="#0da2d4" />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
                <Grid item xs={2}>
                    <StatBox
                        loading={loading}
                        title={`${reportRange} Day Call Avgerage`}
                        stat={`${Math.round(pastCalls / pastDaysWorked) || 0}`}
                        color="#ff6525"
                    />
                </Grid>
                <Grid item xs={2}>
                    <StatBox loading={loading} title={`Calls Last ${reportRange} Days`} stat={pastCalls} color="#8B0000" />
                </Grid>
                <Grid item xs={2} style={{ visibility: 'hidden' }}>
                    <StatBox loading={loading} title={`Days Worked out of ${reportRange} Days`} stat={pastDaysWorked} />
                </Grid>
                <Grid item xs={2}>
                    <StatBox
                        loading={loading}
                        title={`${reportRange} Day Scheduled Avgerage`}
                        stat={Math.round(pastScheduled / pastDaysWorked) || 0}
                        color="#5427ac"
                    />
                </Grid>
                <Grid item xs={2}>
                    <StatBox loading={loading} title={`Scheduled Last ${reportRange} Days`} stat={pastScheduled} color="#191970" />
                </Grid>
                <Grid item xs={2}>
                    <StatBox loading={loading} title={`Days Worked out of ${reportRange} Days`} stat={pastDaysWorked} />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{ paddingBottom: '1em', marginTop: '2em' }}>
                <Button
                    style={{ marginRight: '6em' }}
                    variant="contained"
                    color="secondary"
                    disabled={exporting || loading}
                    onClick={exportDetailedReportClick}
                >
                    {!exporting ? (
                        <GetAppIcon style={{ marginRight: '8px', color: 'white' }} />
                    ) : (
                        <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />
                    )}
                    <span style={{ color: 'white' }}>Detailed Report</span>
                </Button>
            </Grid>
        </Box>
    );
};

export default React.memo(CallingPerformance);
