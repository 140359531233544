export enum MetricTypeEnum {
    HgbA1C = 1,
    DiabeticEyeExam = 2,
    BloodPressure = 3,
    BMI = 4,
    AdultHealth = 5,
    DepressionScreen = 6,
    DepressionFollowUp = 7,
    MedicationReconciliation = 8,
    Fuh = 9,
    Documentation = 10,
    TeamInPersonVisits = 11,
    IndividualInPersonVisits = 12,
    ActiveMembers = 13,
}
