import { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, List, ListItemButton, ListItemText, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import Loading from '../Shared/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllFacilities } from '../../store/shared.slice';
import { RootState } from '../../reducers';
import {
    fetchAllMemberTransitionalCares,
    setCurrentTransitionTab,
    setExpandedList,
    setPatientAdmissionTitle,
    setMemberTransitionalCare,
    setTransitionStep,
    showNewTransitionalCare,
} from '../../store/transitionalCare.slice';
import PatientAdmission from './PatientAdmission';
import { TransitionalCareStatusEnum } from '../../Enum/TransitionalCareStatusEnum';
import { ConfirmationDialog } from './ConfirmationDialog';
import AddDischargeDetail from './AddDischargeDetail';
import TransitionsListMenu from './TransitionsListMenu';
import { getMemberBasicInfo } from '../../store/memberDetail.slice';
import moment from 'moment';
import { TransitionalCareStepTypeEnum } from '../../Enum/TransitionalCareStepTypeEnum';
import { gotoPreviousMenu } from '../../store/assessment.slice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TransitionalCareVisitTypeEnum } from '../../Enum/TransitionalCareVisitTypeEnum';
import { FacilityTypeEnum } from '../../Enum/FacilityTypeEnum';
import { useParams } from 'react-router-dom';
import MessageDisplay from '../Shared/MessageDisplay';
import { NewMemberTransitionalCare } from '../../Models/TransitionalCare/NewMemberTransitionalCareModel';
import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';

const TransitionalCare = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const isAssessment = Boolean(props.isAssessment);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { currentTransitionTab, memberTransitionalCares } = useSelector((state: RootState) => state.transitionalCare);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const [confirmationTitle, setConfirmationTitle] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState();
    const [confirmIsOpen, setConfirmIsOpen] = useState(false);
    const openConfirmDialog = () => setConfirmIsOpen(true);
    const closeConfirmDialog = () => {
        setConfirmIsOpen(false);
        setConfirmationMessage(null);
    };

    const onConfirmNewTCHandler = () => {
        closeConfirmDialog();
        dispatch(showNewTransitionalCare());
    };

    const getReadmissionStatus = (transition: NewMemberTransitionalCare) => {
        const { TransitionSteps } = transition;
        const admission = TransitionSteps.find((el: TransitionStep) => el.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
        const dischargeSteps = TransitionSteps.filter((el: TransitionStep) => el.TransitionStepType === TransitionalCareStepTypeEnum.Discharge);
        const mostRecentDischarge = dischargeSteps[dischargeSteps.length - 1];
        const dischargeDate = moment(mostRecentDischarge?.AdmissionDate);
        const today = moment();
        const diff = today.diff(dischargeDate, 'days');

        return (
            diff <= 30 &&
            admission.VisitType === TransitionalCareVisitTypeEnum.Inpatient &&
            (admission.AdmissionType === FacilityTypeEnum.BehavioralHealthLevel1 || admission.AdmissionType === FacilityTypeEnum.PhysicalHealth)
        );
    };

    const newTransitionalCareHandler = () => {
        const activeTransitionalCares = memberTransitionalCares.filter((el) => el.Status === TransitionalCareStatusEnum.Active);
        const readmitableTransitions = memberTransitionalCares.filter((transition) => !getReadmissionStatus(transition));
        dispatch(setMemberTransitionalCare(new NewMemberTransitionalCare()));
        console.log('readmitableTransitions', readmitableTransitions);
        let message = null;
        const listOfOpenTransitions = () => (
            <List sx={{ marginY: 2 }}>
                {activeTransitionalCares.map((transition, index) => {
                    const admissionStep = transition.TransitionSteps.find((step) => step.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
                    return (
                        <ListItemButton
                            key={index}
                            sx={{
                                textAlign: 'center',
                                border: '2px solid',
                                borderColor: 'grey.300',
                                borderRadius: '5px',
                                marginY: 3,
                                width: '50%',
                                marginX: 'auto',
                                ':hover': { borderColor: 'primary.main' },
                            }}
                            onClick={() => {
                                dispatch(setMemberTransitionalCare(transition));
                                dispatch(setExpandedList([transition.Id]));
                                dispatch(setTransitionStep(admissionStep));
                                closeConfirmDialog();
                            }}
                        >
                            <ListItemText
                                primary={<Typography variant="h6">{admissionStep?.Facility?.Name}</Typography>}
                                secondary={<Typography variant="h6">{moment(admissionStep?.AdmissionDate).format('MM-DD-YYYY')} - open</Typography>}
                            />
                        </ListItemButton>
                    );
                })}
            </List>
        );

        if (activeTransitionalCares.length > 0) {
            setConfirmationTitle('This member currently has an open transition.');
            message = (
                <>
                    <Typography variant="body1" color={'error'}>
                        Are you sure you still want to create a new transitional care? This member currently has an open transitional care(s):
                    </Typography>
                    {listOfOpenTransitions()}
                </>
            );
            setConfirmationMessage(message);
            openConfirmDialog();
        } else {
            dispatch(setPatientAdmissionTitle('New transitional care'));
            dispatch(showNewTransitionalCare());
        }
    };

    useEffect(() => {
        dispatch(fetchAllFacilities());
        dispatch(getMemberBasicInfo(memberId));
        dispatch(fetchAllMemberTransitionalCares(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading && <Loading message={'Loading...'} />}
            <PatientAdmission memberId={memberId} />
            <AddDischargeDetail />
            <ConfirmationDialog
                open={confirmIsOpen}
                title={confirmationTitle}
                onClose={closeConfirmDialog}
                onConfirm={onConfirmNewTCHandler}
                message={confirmationMessage}
            />
            <MessageDisplay />
            <Grid container minHeight={'80vh'} p={0}>
                {memberTransitionalCares.length === 0 ? (
                    <Grid item className={isAssessment ? 'content-form' : ''} xs={3} marginX={'auto'} marginY={'auto'}>
                        <Stack spacing={2} textAlign={'center'}>
                            <Typography variant="h6" fontWeight={700} color={'text.secondary'}>
                                No open transitions
                            </Typography>
                            <Typography variant="body1" color={'text.secondary'}>
                                You can admit the member to a new facility by clicking the button below.
                            </Typography>
                            <Button variant="contained" color="primary" onClick={newTransitionalCareHandler}>
                                NEW TRANSITIONAL CARE
                            </Button>
                        </Stack>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Stack direction={'row'} alignItems={'center'} marginY={2} paddingX={2}>
                            <Tabs
                                value={currentTransitionTab}
                                onChange={(event, newTransition) => dispatch(setCurrentTransitionTab(newTransition))}
                                textColor="inherit"
                                indicatorColor="primary"
                                variant="fullWidth"
                                sx={{
                                    alignSelf: 'flex-start',
                                    '.MuiTab-root': { borderRadius: '90px', marginRight: 2, backgroundColor: 'grey.300', color: 'inherit' },
                                    '.Mui-selected': { color: '#ffffff', backgroundColor: 'primary.main' },
                                }}
                                TabIndicatorProps={{
                                    sx: {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <Tab value="open" label="Open" />
                                <Tab value="closed" label="Closed" />
                            </Tabs>
                            <Button onClick={() => dispatch(setExpandedList([]))}>Collapse All</Button>
                            <Button onClick={() => dispatch(setExpandedList(memberTransitionalCares.map((transition) => transition.Id)))}>Expand All</Button>
                            <Button sx={{ marginLeft: 'auto' }} onClick={newTransitionalCareHandler}>
                                New Transitional Care
                            </Button>
                        </Stack>
                        <Paper elevation={5} sx={{ display: 'flex', minHeight: '800px', marginY: 2 }}>
                            {currentTransitionTab === 'open' && (
                                <TransitionsListMenu
                                    memberId={memberId}
                                    memberTransitionalCares={memberTransitionalCares.filter((el) => el.Status === TransitionalCareStatusEnum.Active)}
                                    isAssessment={isAssessment}
                                />
                            )}

                            {currentTransitionTab === 'closed' && (
                                <TransitionsListMenu
                                    memberId={memberId}
                                    memberTransitionalCares={memberTransitionalCares.filter((el) => el.Status === TransitionalCareStatusEnum.Closed)}
                                    isAssessment={isAssessment}
                                />
                            )}
                        </Paper>
                    </Grid>
                )}
            </Grid>
            {isAssessment && (
                <Grid container spacing={2} direction="column" className="content-panel">
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={() => dispatch(saveEngagementDraft(engagementDraft, isAssessment))}
                            endIcon={isLoading ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default TransitionalCare;
