import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL, activeStatuses, localStorageKeys } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { MemberNote } from '../Models/Member/MemberNote.model';
import { MemberStatusEnum } from '../Enum/MemberStatusEnum';
import { HedisMetricsVisit } from '../Models/HedisMetrics/HedisMetricsVisit.model';
import { dictionaryToArray } from '../utils/mappings';
import { Member } from '../Models/Member/Member.model';
import { HedisMetricsTypeEnum } from '../Enum/HedisMetricsTypeEnum';

const getSelectedMemberDetailPage = () => {
    return window.localStorage.getItem(localStorageKeys.SELECTED_MEMBER_DETAIL_PAGE)
        ? window.localStorage.getItem(localStorageKeys.SELECTED_MEMBER_DETAIL_PAGE)
        : 'Member Profile';
};

export const hedisMetricsList = [
    { name: 'AdultHealthSummary', label: 'Adult Health', type: 2 },
    { name: 'BmiSummary', label: 'BMI', type: 3 },
    { name: 'BpSummary', label: 'BP', type: 1 },
    { name: 'BreastCancerSummary', label: 'Breast Cancer Screening', type: 4 },
    { name: 'DepressionFollowUpSummary', label: 'Depression Follow Up', type: 9 },
    { name: 'DepressionScreeningSummary', label: 'Depression Screening', type: 8 },
    { name: 'DiabeticEyeExamSummary', label: 'Diabetic Eye Exam', type: 6 },
    { name: 'Hgba1cSummary', label: 'HgbA1C', type: 5 },
    { name: 'Hgba1cForBhDxSummary', label: 'HgbA1C for BH DX', type: 11 },
    { name: 'LipidForBhDxSummary', label: 'Lipid for BH DX', type: 10 },
    { name: 'MedicationReconcilationSummary', label: 'Medication Reconciliation', type: 7 },
];

export const completedList = [
    { Id: HedisMetricsTypeEnum.ProviderVisits, Name: HedisMetricsTypeEnum.ProviderVisitsDescription },
    { Id: HedisMetricsTypeEnum.BMI, Name: HedisMetricsTypeEnum.BmiDescription },
    { Id: HedisMetricsTypeEnum.BreastCancerScreening, Name: HedisMetricsTypeEnum.BreastCancerDescription },
    { Id: HedisMetricsTypeEnum.DiabeticEyeExam, Name: HedisMetricsTypeEnum.DiabeticEyeExamDescription },
    { Id: HedisMetricsTypeEnum.MedicationReconciliation, Name: HedisMetricsTypeEnum.MedicationReconciliationDescription },
    { Id: HedisMetricsTypeEnum.DepressionScreening, Name: HedisMetricsTypeEnum.DepressionScreeningDescription },
    { Id: HedisMetricsTypeEnum.DepressionFollowUp, Name: HedisMetricsTypeEnum.DepressionFollowUpDescription },
];

const memberDemographicsDetails = createSlice({
    name: 'memberDemographicsDetails',
    initialState: {
        allMemberNotes: [] as MemberNote[],
        chartDetails: {} as any,
        closedStatusModalIsOpen: false,
        showHedisUpdateModal: false,
        ethnicities: [],
        expiredHedis: [] as any[],
        hasCriticalNotes: false,
        memberAccessMessage: '',
        memberMenuIsOpen: true,
        memberNote: new MemberNote(),
        pageContent: null,
        preferredPronouns: [],
        previousMemberCharts: [],
        races: [],
        selectedMemberDetailPage: getSelectedMemberDetailPage(),
        showDetails: true,
        showMemberAccess: false,
        showNotes: false,
        showEditMemberDemographics: false,
        showPreviousCharts: false,
    },
    reducers: {
        _setAllMemberNotes(state, action) {
            state.allMemberNotes = action.payload;
        },
        _setChartDetails(state, action) {
            state.chartDetails = action.payload;
        },
        _setClosedStatusModalIsOpen(state, action) {
            state.closedStatusModalIsOpen = action.payload;
        },
        _setShowHedisUpdateModal(state, action) {
            state.showHedisUpdateModal = action.payload;
        },
        _setEdxpiredHedis(state, action) {
            state.expiredHedis = action.payload;
        },
        _setEthnicities(state, action) {
            state.ethnicities = action.payload;
        },
        _setHasCriticalNotes(state, action) {
            state.hasCriticalNotes = action.payload;
        },
        _setSelectedMemberDetailPage(state, action) {
            state.selectedMemberDetailPage = action.payload;
        },
        _setMemberAccessMessage(state, action) {
            state.memberAccessMessage = action.payload;
        },
        _setMemberMenuIsOpen(state, action) {
            state.memberMenuIsOpen = action.payload;
        },
        _setMemberNote(state, action) {
            state.memberNote = action.payload;
        },
        _setPreferredPronouns(state, action) {
            state.preferredPronouns = action.payload;
        },
        _setPreviousMemberCharts(state, action) {
            state.previousMemberCharts = action.payload;
        },
        _setRaces(state, action) {
            state.races = action.payload;
        },
        _setShowDetails(state, action) {
            state.showDetails = action.payload;
        },
        _setShowMemberAccess(state, action) {
            state.showMemberAccess = action.payload;
        },
        _setShowNotes(state, action) {
            state.showNotes = action.payload;
        },
        _setShowEditMemberDemographics(state, action) {
            state.showEditMemberDemographics = action.payload;
        },
        _setShowPreviousCharts(state, action) {
            state.showPreviousCharts = action.payload;
        },
        _setPageContent(state, action) {
            state.pageContent = action.payload;
        },
    },
});

const {
    _setAllMemberNotes,
    _setChartDetails,
    _setClosedStatusModalIsOpen,
    _setShowHedisUpdateModal,
    _setEdxpiredHedis,
    _setEthnicities,
    _setHasCriticalNotes,
    _setSelectedMemberDetailPage,
    _setMemberAccessMessage,
    _setMemberMenuIsOpen,
    _setMemberNote,
    _setPreferredPronouns,
    _setPreviousMemberCharts,
    _setRaces,
    _setShowDetails,
    _setShowMemberAccess,
    _setShowNotes,
    _setShowEditMemberDemographics,
    _setShowPreviousCharts,
    _setPageContent,
} = memberDemographicsDetails.actions;

export const setPageContent =
    (page: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setPageContent(page));
    };

export const setAllMemberNotes =
    (memberNotes: MemberNote[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAllMemberNotes(memberNotes));
    };

export const setChartDetails =
    (chartDetails: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setChartDetails(chartDetails));
    };

export const setClosedStatusModalIsOpen =
    (closedStatusModalIsOpen: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setClosedStatusModalIsOpen(closedStatusModalIsOpen));
    };

export const setShowHedisUpdateModal =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowHedisUpdateModal(show));
    };

export const setEdxpiredHedis =
    (expiredHedis: any[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setEdxpiredHedis(expiredHedis));
    };

export const setHasCriticalNotes =
    (hasCriticalNotes: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setHasCriticalNotes(hasCriticalNotes));
    };

export const setSelectedMemberDetailPage =
    (selectedPage: string): AppThunk =>
    async (dispatch, getState) => {
        window.localStorage.setItem(localStorageKeys.SELECTED_MEMBER_DETAIL_PAGE, selectedPage);
        dispatch(_setSelectedMemberDetailPage(selectedPage));
    };

export const setMemberAccessMessage =
    (memberAccessMessage: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberAccessMessage(memberAccessMessage));
    };

export const setMemberMenuIsOpen =
    (memberMenuIsOpen: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberMenuIsOpen(memberMenuIsOpen));
    };

export const setMemberNote =
    (memberNote: MemberNote): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberNote(memberNote));
    };

export const setPreviousMemberCharts =
    (previousMemberCharts: Member[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setPreviousMemberCharts(previousMemberCharts));
    };

export const setShowDetails =
    (showDetails: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowDetails(showDetails));
    };

export const setShowEditMemberDemographics =
    (showEditMemberDemographics: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowEditMemberDemographics(showEditMemberDemographics));
    };

export const setShowMemberAccess =
    (showMemberAccess: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowMemberAccess(showMemberAccess));
    };

export const setShowNotes =
    (showNotes: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowNotes(showNotes));
    };

export const setShowPreviousCharts =
    (showPreviousCharts: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowPreviousCharts(showPreviousCharts));
    };

export const fetchPreferredPronouns = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getpreferredpronouns`);
    const items = dictionaryToArray(response.data);
    dispatch(_setPreferredPronouns(items));
};

export const fetchEthnicities = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getethnicities`);
    const items = dictionaryToArray(response.data);
    dispatch(_setEthnicities(items));
};

export const fetchRaces = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getraces`);
    const items = dictionaryToArray(response.data);
    dispatch(_setRaces(items));
};

export const getMemberByMemberId =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/member/getmemberbyid?memberId=${id}`);
        dispatch(_setChartDetails(response.data));
        const closedStatus =
            !activeStatuses?.includes(response?.data?.Status) ||
            (response?.data?.Status === MemberStatusEnum.FUHFUMInProgress && response?.data?.HasCloned) ||
            (response?.data?.Status === MemberStatusEnum.HRAInReview && response?.data?.HasCloned);
        if (closedStatus) {
            dispatch(setClosedStatusModalIsOpen(true));
        }
    };

export const insertOrUpdateMemberNote =
    (memberId: string, providerId: string, memberNote: MemberNote): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.put(`${API_URL}/member/insertorupdatemembernote?memberId=${memberId}&providerId=${providerId}`, memberNote);
        if (response.data.length > 0) {
            const hasCriticalNotes = response.data.some((x) => x.WarningFlag);
            dispatch(_setHasCriticalNotes(hasCriticalNotes));
        }
        dispatch(setAllMemberNotes(response.data));
        dispatch(setIsLoading(false));
    };

export const deleteMemberNote =
    (memberId: string, memberNoteId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.put(`${API_URL}/member/deletemembernote?memberId=${memberId}&memberNoteId=${memberNoteId}`);
        if (response.data.length > 0) {
            const hasCriticalNotes = response.data.some((x) => x.WarningFlag);
            dispatch(_setHasCriticalNotes(hasCriticalNotes));
        }
        dispatch(setAllMemberNotes(response.data));
        dispatch(setIsLoading(false));
    };

export const submitHedisMetricVisit =
    (memberId: string, hedisMetricVisit: HedisMetricsVisit, file: File, callBack?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        let formData = new FormData();
        formData.append('file', file);
        formData.append('hedisMetricVisit', JSON.stringify(hedisMetricVisit));
        const response = await axios.put(`${API_URL}/member/updatehedismetricsalert?memberId=${memberId}`, formData);
        dispatch(setChartDetails(response.data));
        if (Boolean(callBack)) {
            callBack(response.data);
        }
        dispatch(setIsLoading(false));
        dispatch(setShowHedisUpdateModal(false));
    };

export const fetchPreviousChartsByMemberId =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/member/getpreviouschartsbymemberid?memberId=${memberId}`);
        dispatch(_setPreviousMemberCharts(response.data));
    };

export const fetchMemberNotesByMemberId =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/member/getmembernotesbymemberid?memberId=${memberId}`);
        if (response.data.length > 0) {
            const hasCriticalNotes = response.data.some((x) => x.WarningFlag);
            dispatch(_setHasCriticalNotes(hasCriticalNotes));
            dispatch(setShowNotes(hasCriticalNotes));
        }
        dispatch(setAllMemberNotes(response.data));
    };

export default memberDemographicsDetails.reducer;
