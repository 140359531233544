import { AdlAnswerTypeEnum } from '../../Enum/AdlAnswerTypeEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IFallRisk extends BaseEntity {
    MemberId: string;
    BathBody?: AdlAnswerTypeEnum;
    BathTubOrShower?: AdlAnswerTypeEnum;
    Dressing?: AdlAnswerTypeEnum;
    Toilet?: AdlAnswerTypeEnum;
    TransferringOrAmbulation?: AdlAnswerTypeEnum;
    Feeding?: AdlAnswerTypeEnum;
    Status: StatusEnum;
    Score?: number;
}

export class FallRisk implements IFallRisk {
    Id: string;
    MemberId: string;
    IsFallenInPastYear?: boolean;
    IsAdvisedToUseCaneOrWalker?: boolean;
    IsUnsteadyWhenWalking?: boolean;
    IsSteadyHoldingOntoFurniture?: boolean;
    IsWorriedAboutFalling?: boolean;
    HasWeakLegMuscles?: boolean;
    IsLostFeelingInFeet?: boolean;
    IsTakenMedicineWhenTired?: boolean;
    IsTakenMedicineToSleep?: boolean;
    IsAssessedHomeForFallRisk?: boolean;
    IsEducationCompleted?: boolean;
    Status: StatusEnum;
    Score?: number;

    constructor() {
        this.MemberId = '';
    }
}
