import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

import { getHgba1cAssessmentById, gotoNextMenu, gotoPreviousMenu, saveHgbA1CAssessment } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import { TestingMachines, TestTimes } from '../../../utils/assessments';
import { copyObject, prepareTextFieldValue, preventTextInput } from '../../../utils/common';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';

const classes = {
    formControl: {
        marginBottom: '25px',
        padding: '0!important',
    },
};

const HgbA1C: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { hgbA1CAssessment, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    useEffect(() => {
        if (Boolean(engagementDraft.HgbA1CAssessmentId)) {
            dispatch(getHgba1cAssessmentById(engagementDraft.HgbA1CAssessmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleOnChangeInput = (value: any, propsName: string, saveLocal = false) => {
        let data = copyObject(hgbA1CAssessment);
        data[propsName] = value;

        dispatch(saveHgbA1CAssessment({ ...data, MemberId: memberId }, saveLocal));
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveHgbA1CAssessment({ ...hgbA1CAssessment, Status: StatusEnum.Completed }, false, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    return (
        <>
            <Grid container direction="column" spacing={2} className="content-panel">
                <Grid item lg={12} md={12} sm={12}>
                    {/* <h6>Last Completed :-</h6> */}
                </Grid>
                <Grid item className="content-form">
                    {isFetching ? (
                        <Box display="flex" justifyContent="center" className="loader-container">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    Member Information
                                </Typography>
                                <Box sx={classes.formControl}>
                                    <TextField label="Member Name" variant="outlined" size="small" fullWidth disabled value={hgbA1CAssessment.MemberName} />
                                </Box>
                                <Box sx={classes.formControl}>
                                    <DesktopDatePicker
                                        label="DOB"
                                        disabled
                                        value={Boolean(hgbA1CAssessment.DOB) ? new Date(hgbA1CAssessment.DOB) : null}
                                        inputFormat="MM/dd/yyyy"
                                        onChange={(date) => {}}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Box>
                                <Box sx={classes.formControl}>
                                    <TextField label="Age" variant="outlined" size="small" fullWidth disabled value={String(hgbA1CAssessment.Age)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h5" sx={{ mb: 4 }}>
                                    Test Information
                                </Typography>

                                <Grid container spacing={2} rowGap={1.2}>
                                    <Grid item xs={12} md={6}>
                                        <DesktopDatePicker
                                            label="Date of Test"
                                            value={Boolean(hgbA1CAssessment.TestDate) ? new Date(hgbA1CAssessment.TestDate) : null}
                                            inputFormat="MM/dd/yyyy"
                                            minDate={new Date('2017-01-01')}
                                            onChange={(date) => {
                                                if (moment(date).isValid() || !Boolean(date)) {
                                                    handleOnChangeInput(date.toISOString(), 'TestDate');
                                                }
                                            }}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="testing-machine-select">Testing Machine Utilized</InputLabel>
                                            <Select
                                                labelId="testing-machine-select"
                                                id="testing-machine-select"
                                                disabled
                                                value={Boolean(hgbA1CAssessment.TestingMachine) ? String(hgbA1CAssessment.TestingMachine) : ''}
                                                label="Testing Machine Utilized"
                                                name="TestingMachine"
                                                onChange={() => {}}
                                            >
                                                {TestingMachines.map((machine) => (
                                                    <MenuItem key={machine} value={machine}>
                                                        {machine}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="time-of-test-type-select">Time of Test</InputLabel>
                                            <Select
                                                labelId="time-of-test-type-select"
                                                id="time-of-test-type-select"
                                                value={Boolean(hgbA1CAssessment.TimeOfTest) ? String(hgbA1CAssessment.TimeOfTest) : ''}
                                                label="Time of Test"
                                                name="TimeOfTest"
                                                onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                                            >
                                                <MenuItem value="">Select Test Time</MenuItem>
                                                {TestTimes.map((time) => (
                                                    <MenuItem key={time} value={time}>
                                                        {time}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Lot # of Test Cartridge"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={hgbA1CAssessment.LotNumTestCartridge}
                                            name="LotNumTestCartridge"
                                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Test Result % (Normal Range: 3%-6%)"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            type="number"
                                            value={prepareTextFieldValue(hgbA1CAssessment.HgbA1CResult)}
                                            name="HgbA1CResult"
                                            onKeyPress={preventTextInput}
                                            inputProps={{ min: 0 }}
                                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {isFetching ? null : (
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={() => setShowSaveAlert(true)} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                )}
            </Grid>
            {Boolean(engagementDraft.HgbA1CAssessmentId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="HgbA1C Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    message="I confirm and acknowledged that HgbA1C assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="HgbA1C Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    message="Are you sure to proceed without completing HgbA1C assessment?"
                />
            )}
        </>
    );
};

export default HgbA1C;
