import { SexualOrientationEnum } from '../../Enum/SexualOrientationEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface ISexualHealth extends BaseEntity {
    MemberId: string;
    IsActiveInPastYear?: boolean;
    SexualOrientation: SexualOrientationEnum;
    PartnersInPastTwelveMonths?: number;
    ReferToPcp?: boolean;
    Status: StatusEnum;
}

export class SexualHealth implements ISexualHealth {
    Id: string;
    MemberId: string;
    IsActiveInPastYear?: boolean;
    SexualOrientation: SexualOrientationEnum;
    PartnersInPastTwelveMonths?: number;
    ReferToPcp?: boolean;
    Status: StatusEnum;

    constructor() {
        this.MemberId = '';
    }
}
