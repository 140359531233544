import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, Typography, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { DataGrid, GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { fetchUpcomingAppointments } from '../../store/appointment.slice';
import { Stack } from '@mui/material';
import Loading from '../Shared/Loading';
import { parentUrl } from '../../utils/constants';
import { formatDateWithMonthName, formatAppointmentTime } from '../../utils/timeFormat';
import MapIcon from '@mui/icons-material/Map';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useTheme, useMediaQuery } from '@mui/material';

import Authorization from '../../utils/Authorization';
import AppointmentMobileView from './AppointmentMobileView';
import { getActiveProviders } from '../../store/provider.slice';
import { toPascalCase } from '../../utils/common';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 50 * 4.5,
            width: 250,
        },
    },
};

const UpcomingAppointments = () => {
    const dispatch = useDispatch();
    const authData = new Authorization();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isMobileView, setIsMobileView] = useState(false);
    const { upcomingAppointments, isFetching } = useSelector((state: RootState) => state.appointment);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const isCEO = authData.Role === 'CEO' ? true : false;
    const [selectedProvider, setSelectedProvider] = useState(isCEO ? 'All' : Boolean(authData.UserId) ? authData.UserId : '');
    const filteredProviders = activeProviders.filter((p) => p.Role.RoleName !== 'Inactive');

    const columns: GridColDef[] = [
        {
            field: 'AppointmentDateTime',
            headerName: 'Appointment Date Time',
            width: 350,
            valueGetter: (params: GridValueGetterParams) =>
                `${formatDateWithMonthName(new Date(params.row.AppointmentDateTime))} at ${formatAppointmentTime(
                    new Date(params.row.AppointmentDateTime)
                )} - ${formatAppointmentTime(new Date(params.row.AppointmentEndDateTime))}`,
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            width: 300,
            valueGetter: (params: GridValueGetterParams) => {
                return toPascalCase(params.row.MemberName);
            },
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Link
                        target={'_blank'}
                        underline="hover"
                        href={`${parentUrl}provider/members-list/member-chart/${params.row.MemberId}/member-profile`}
                        color={'#337ab7'}
                    >
                        {params.value}
                    </Link>
                );
            },
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 500,
            sortable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
                let fullAddress = toPascalCase(params.row.Address);
                return fullAddress;
            },
        },
        {
            field: 'StatusString',
            headerName: 'Status',
            width: 250,
            sortable: false,
        },
        {
            field: 'ProviderName',
            headerName: 'Provider Name',
            width: 250,
            sortable: false,
        },
        {
            field: 'action',
            headerName: '',
            sortable: false,
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.Lat && params.row.Lon ? (
                            <>
                                <Tooltip title="View in Google Maps" placement="left">
                                    <MapIcon
                                        color="primary"
                                        style={{
                                            marginLeft: '40px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            openGoogleMaps(params.row.Lat, params.row.Lon);
                                        }}
                                    />
                                </Tooltip>
                            </>
                        ) : (
                            <Typography
                                style={{
                                    marginLeft: '50px',
                                    marginRight: '10px',
                                }}
                            >
                                -
                            </Typography>
                        )}
                        <Tooltip title="Copy Map Location" placement="left">
                            <ContentCopyRoundedIcon
                                color="primary"
                                style={{
                                    marginLeft: '50px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    copyMemberDetail(params.row);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const prepareColumns = () => {
        let columnsData = columns;
        if (selectedProvider !== 'All') {
            columnsData = columnsData.filter((c) => c.field !== 'ProviderName');
        }
        return columnsData;
    };

    const copyMemberDetail = (member: any) => {
        let addressParts = [member.Address, member.City, member.State, member.ZipCode];
        let fullAddress = addressParts.filter((part) => part).join(', ');
        let memberDetail = `${member.MemberName} - ${fullAddress}`;
        if (Boolean(member.Lat) && Boolean(member.Lon)) {
            const url = `https://www.google.com/maps?q=${member.Lat},${member.Lon}`;
            memberDetail = `${memberDetail} - ${url}`;
        }
        navigator.clipboard
            .writeText(memberDetail)
            .then(() => {})
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    const openGoogleMaps = (latitude: any, longitude: any) => {
        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        dispatch(getActiveProviders(true));
        dispatch(fetchUpcomingAppointments(selectedProvider));
    }, [dispatch, selectedProvider]);

    useEffect(() => {
        setIsMobileView(isMobile);
    }, [isMobile]);

    return (
        <Box>
            {<Loading message={'Loading...'} loading={isFetching} />}
            <Grid container spacing={2} style={{ padding: '16px' }}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems={'center'} spacing={2}>
                        <Typography variant="h6">Upcoming Appointments</Typography>
                    </Stack>
                </Grid>
                {isCEO === true ? (
                    <Grid item xs={isMobileView ? 12 : 2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={selectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : null}
            </Grid>
            {isFetching ? null : (
                <>
                    {isMobileView ? (
                        upcomingAppointments.length > 0 ? (
                            upcomingAppointments.map((appointment) => (
                                <AppointmentMobileView
                                    key={appointment.Id}
                                    appointment={appointment}
                                    selectedProvider={selectedProvider}
                                    openGoogleMaps={() => openGoogleMaps(appointment.Lat, appointment.Lon)}
                                    copyMemberDetail={() => copyMemberDetail(appointment)}
                                />
                            ))
                        ) : (
                            <Typography variant="body1" style={{ padding: '0 20px' }}>
                                No data found.
                            </Typography>
                        )
                    ) : (
                        <div style={{ width: '100%' }}>
                            <div style={{ padding: '0 16px 0 16px' }}>
                                <DataGrid
                                    getRowId={(row) => row.Id}
                                    rows={upcomingAppointments}
                                    columns={prepareColumns()}
                                    rowsPerPageOptions={[5]}
                                    pageSize={13}
                                    autoHeight
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </Box>
    );
};

export default UpcomingAppointments;
