import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import EngagementNumbers from './Categories/EngagementNumbers/EngagementNumbers';
import NumberOfVisits from './Categories/NumberOfVisits/NumberOfVisits';
import ClaimGeneratedCosts from './Categories/ClaimGeneratedCosts/ClaimGeneratedCosts';
import { useParams } from 'react-router-dom';

const PerformanceDashboard = (props: any) => {
    const params = useParams();
    const memberId = props.memberId || params.memberId;

    const [isSelected, setIsSelected] = useState(0);
    const categories = [
        { name: 'Number of Visits', content: <NumberOfVisits memberId={memberId} /> },
        { name: 'Claim Generated Costs', content: <ClaimGeneratedCosts memberId={memberId} /> },
        { name: 'Engagement Numbers', content: <EngagementNumbers memberId={memberId} /> },
    ];
    const [pageContent, setPageContent] = useState(<NumberOfVisits memberId={memberId} />);

    return (
        <Box>
            <Tabs value={isSelected} onChange={(e, newValue) => setIsSelected(newValue)} sx={{ marginBottom: 2 }}>
                {categories.map((item, index) => (
                    <Tab
                        value={index}
                        key={index}
                        label={item.name}
                        onClick={() => {
                            setPageContent(item.content);
                        }}
                    />
                ))}
            </Tabs>
            <Box p={2} width={'100%'}>
                {pageContent}
            </Box>
        </Box>
    );
};

export default PerformanceDashboard;
