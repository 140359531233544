export enum EducationTimeDurationEnum {
    LessThan11MinutesDescription = '< 11 minutes',
    LessThan11Minutes = 1,
    From11To19MinutesDescription = '11 to 19 minutes',
    From11To19Minutes = 2,
    From20To30MinutesDescription = '20 to 30 minutes',
    From20To30Minutes = 3,
    GreaterThan301MinutesDescription = '> 30 minutes',
    GreaterThan301Minutes = 4,
}
