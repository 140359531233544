import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    Checkbox,
    IconButton,
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    Select,
    MenuItem,
    Typography,
    Stack,
    Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../../reducers';
import classes from './Styles';
import {
    addSchedulePlan,
    editSchedulePlan,
    removeSchedulePlan,
    setIntegratedCarePlan,
    setSelectedSchedulePlan,
    toggleShowSchedulePlanDeleteAlert,
} from '../../../store/carePlan.slice';
import { copyObject, preventTextInput } from '../../../utils/common';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { ProviderPreview, SchedulePlan } from '../../../Models/CarePlans/SchedulePlan.model';
import { IntervalTypes } from '../../../utils/assessments';
import { TimeIntervalTypeEnum } from '../../../Enum/TimeIntervalTypeEnum';

const schedulePlanFormSchema = Yup.object().shape({
    Provider: Yup.object().shape({
        Id: Yup.string().nullable().required('Provider is required'),
        Role: Yup.string().nullable().required('Role is required'),
    }),
    TimeIntervalType: Yup.string().nullable().required('Time interval is required.'),
    TimeIntervalIndays: Yup.string().nullable().required('Time interval in days is required.'),
});

const SchedulePlanComponent: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [openNew, setOpenNew] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { integratedCarePlan, selectedSchedulePlan, roles, showSchedulePlanDeleteAlert, isSavingSchedulePlan } = useSelector(
        (state: RootState) => state.carePlan
    );
    const { activeProviders } = useSelector((state: RootState) => state.provider);

    const providersByRole = (role: string) => {
        if (Boolean(role)) {
            return activeProviders.filter((p) => p.Role.RoleName === role);
        } else {
            return [];
        }
    };

    const handleOnToggleSelectPlan = (isSelected: boolean, id: string) => {
        let data = copyObject(integratedCarePlan);
        data.SchedulePlans.forEach((plan) => {
            if (plan.Id === id) {
                plan.IsSelected = isSelected;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnClickDelete = (plan: SchedulePlan) => {
        dispatch(setSelectedSchedulePlan({ ...plan, SelectedForDelete: true }));
        dispatch(toggleShowSchedulePlanDeleteAlert(true));
    };

    const handleOnFormSubmit = (values) => {
        if (Boolean(values.Id)) {
            dispatch(editSchedulePlan(memberId, values));
        } else {
            dispatch(addSchedulePlan(memberId, values));
        }
    };

    const toggleOnNewSchedulePlan = () => {
        dispatch(
            setSelectedSchedulePlan({
                ...new SchedulePlan(),
                TimeIntervalType: TimeIntervalTypeEnum.Month,
                IsSelected: !openNew,
            })
        );
        setOpenNew(!openNew);
    };

    const handleOnClickCloseEditable = () => {
        if (Boolean(selectedSchedulePlan.Id)) {
            dispatch(setSelectedSchedulePlan(new SchedulePlan()));
        } else {
            setOpenNew(false);
        }
    };

    const renderEditableSchedulePlan = () => {
        return (
            <Formik initialValues={selectedSchedulePlan} onSubmit={handleOnFormSubmit} validationSchema={schedulePlanFormSchema} enableReinitialize={true}>
                {({ values, errors, handleChange, setFieldValue, submitCount }: FormikProps<SchedulePlan>) => (
                    <Form className="fill">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl fullWidth size="small" error={Boolean(errors?.Provider?.Role) && Boolean(submitCount)}>
                                    <InputLabel id="role-select">Role</InputLabel>
                                    <Select
                                        labelId="role-select"
                                        id="role-select"
                                        value={Boolean(values.Provider?.Role) ? values.Provider.Role : ''}
                                        label="Role"
                                        name="Role"
                                        onChange={(e) =>
                                            setFieldValue('Provider', {
                                                Id: values.Provider?.Id,
                                                Name: values.Provider?.Name,
                                                Role: e.target.value,
                                            } as ProviderPreview)
                                        }
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role.Id} value={role.Name}>
                                                {role.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth size="small" error={Boolean(errors?.Provider?.Id) && Boolean(submitCount)}>
                                    <InputLabel id="time-interval-select">Provider</InputLabel>
                                    <Select
                                        labelId="time-interval-select"
                                        id="time-interval-select"
                                        value={Boolean(values.Provider?.Id) ? values.Provider.Id : ''}
                                        label="Provider"
                                        name="Provider"
                                        onChange={(e) => {
                                            const selectedProvider = providersByRole(values.Provider?.Role).find((p) => p.Id === e.target.value);
                                            setFieldValue('Provider', {
                                                Id: selectedProvider.Id,
                                                Name: `${selectedProvider.LastName}, ${selectedProvider.FirstName}`,
                                                Role: selectedProvider.Role?.RoleName,
                                            } as ProviderPreview);
                                        }}
                                    >
                                        {providersByRole(values.Provider?.Role).map((types) => (
                                            <MenuItem key={types.Id} value={types.Id}>
                                                {types.LastName}, {types.FirstName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth size="small" error={Boolean(errors?.TimeIntervalType) && Boolean(submitCount)}>
                                    <InputLabel id="time-interval-select">Time Interval</InputLabel>
                                    <Select
                                        labelId="time-interval-select"
                                        id="time-interval-select"
                                        value={Boolean(values.TimeIntervalType) ? values.TimeIntervalType : ''}
                                        label="Time Interval"
                                        name="TimeIntervalType"
                                        onChange={handleChange}
                                    >
                                        {IntervalTypes.map((types) => (
                                            <MenuItem key={types.Name} value={types.Id}>
                                                {types.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="d-flex-row">
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        label={`Time Interval In ${
                                            values.TimeIntervalType === TimeIntervalTypeEnum.Week
                                                ? 'weeks'
                                                : values.TimeIntervalType === TimeIntervalTypeEnum.Day
                                                ? 'days'
                                                : 'months'
                                        }`}
                                        name="TimeIntervalIndays"
                                        type="number"
                                        onKeyPress={preventTextInput}
                                        inputProps={{ min: 0 }}
                                        value={Boolean(values.TimeIntervalIndays) ? values.TimeIntervalIndays : ''}
                                        onChange={handleChange}
                                        error={Boolean(errors?.TimeIntervalIndays) && Boolean(submitCount)}
                                    />
                                    <Box sx={classes.actionButtons}>
                                        <IconButton type="submit">
                                            {isSavingSchedulePlan ? <CircularProgress size={18} /> : <SaveIcon color="primary" />}
                                        </IconButton>
                                        <IconButton onClick={handleOnClickCloseEditable}>
                                            <HighlightOffSharpIcon color="error" />
                                        </IconButton>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    };

    const renderSchedulePlan = (plan: SchedulePlan, index: number) => {
        const isEdit = !selectedSchedulePlan.SelectedForDelete && selectedSchedulePlan.Id === plan.Id;

        return isEdit ? (
            <TableRow key={plan.Id}>
                <TableCell colSpan={3}>
                    <div className="d-flex-row">
                        <span className="count">{index + 1}</span>
                        {renderEditableSchedulePlan()}
                    </div>
                </TableCell>
            </TableRow>
        ) : (
            <TableRow key={plan.Id}>
                <TableCell scope="row">
                    <div className="d-flex-row">
                        <span className="count">{index + 1}</span>
                        <div>
                            Appointment with {plan.Provider.Role} <strong>{plan.Provider.Name}</strong> occurs every {plan.TimeIntervalIndays}{' '}
                            {IntervalTypes.find((i) => i.Id === plan.TimeIntervalType)?.Name}
                        </div>
                    </div>
                </TableCell>
                <TableCell width="110px">
                    <Checkbox checked={plan.IsSelected} name="IsSelected" size="small" onChange={(e) => handleOnToggleSelectPlan(e.target.checked, plan.Id)} />
                </TableCell>
                <TableCell width="140px" className="hover-visble">
                    <Box sx={classes.actionButtons}>
                        <IconButton onClick={() => dispatch(setSelectedSchedulePlan(plan))}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleOnClickDelete(plan)}>
                            {false ? <CircularProgress size={18} /> : <DeleteIcon color="error" />}
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <>
            <TableContainer sx={classes.tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack direction="row" columnGap={2}>
                                    <Typography sx={classes.planTitle}>Schedule Plan</Typography>
                                </Stack>
                            </TableCell>
                            <TableCell width="120px">Select Plan</TableCell>
                            <TableCell width="140px" align="right">
                                <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={toggleOnNewSchedulePlan}>
                                    {openNew ? <KeyboardArrowUpIcon /> : <AddIcon />}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                {integratedCarePlan.SchedulePlans?.length ? (
                    <Table size="small">
                        <TableBody>{integratedCarePlan.SchedulePlans?.map(renderSchedulePlan)}</TableBody>
                    </Table>
                ) : !openNew ? (
                    <div className="no-data-container">No schedule plans to display</div>
                ) : null}
                <Collapse in={openNew} timeout="auto" unmountOnExit sx={classes.newItemContainer}>
                    {renderEditableSchedulePlan()}
                </Collapse>
            </TableContainer>
            <AlertDialog
                open={showSchedulePlanDeleteAlert}
                isLoading={isSavingSchedulePlan}
                autoHideOnConfirmed={false}
                okText="Delete"
                onConfirm={() => dispatch(removeSchedulePlan(memberId, selectedSchedulePlan?.Provider?.Id))}
                onClose={() => dispatch(toggleShowSchedulePlanDeleteAlert(false))}
                message="Are you sure want to delete this schedule plan?"
            />
        </>
    );
};

export default SchedulePlanComponent;
