import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Checkbox, Collapse, FormControlLabel, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { UpdateRequest } from '../../../store/requests.slice';
import { currencyFormatter } from '../../../utils/constants';
import { IResourceServiceItem } from '../interfaces/IResourceServiceItem';
import DeleteResourceItemModal from './DeleteResourceItemModal';
import EditResourceItemModal from './EditResourceItemModal';
import CommentIcon from '@mui/icons-material/Comment';

const ResourceItemRow: React.FC<IResourceServiceItem & { divider: boolean }> = (props) => {
    const dispatch = useDispatch();
    const { ResourceName, ResourceId, PayerList, ResourceDidProvideService, divider, Note } = props;
    const [collapsedIn, setCollapsedIn] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [deleteMode, setDeleteMode] = useState<boolean>(false);
    const { selectedRequest: request } = useSelector((state: RootState) => state.requests);

    const handleDelete = () => {
        const newRequest = { ...request, ResourceServiceItems: [...request.ResourceServiceItems.filter((item) => item.ResourceId !== ResourceId)] };
        dispatch(UpdateRequest(newRequest));
        setDeleteMode(false);
    };

    const handleEdit = () => {
        setEditMode(false);
    };

    return (
        <>
            <DeleteResourceItemModal open={deleteMode} onClose={() => setDeleteMode(false)} onSuccess={handleDelete} resourceName={ResourceName} />
            <EditResourceItemModal open={editMode} onClose={() => setEditMode(false)} onSuccess={handleEdit} resourceItem={props} request={request} />
            <ListItem sx={{ pl: 5 }} divider={divider && !collapsedIn}>
                {PayerList?.length > 0 ? (
                    collapsedIn ? (
                        <IconButton onClick={() => setCollapsedIn(!collapsedIn)}>
                            <ExpandMore />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => setCollapsedIn(!collapsedIn)}>
                            <ExpandLess />
                        </IconButton>
                    )
                ) : null}

                <ListItemText primary={ResourceName} secondary={`Cost: ${currencyFormatter.format(PayerList?.reduce((a, b) => a + b.Amount, 0) ?? 0)}`} />
                <FormControlLabel control={<Checkbox checked={ResourceDidProvideService} />} label="Used?" disabled />
                <Tooltip title={`Edit ${ResourceName} payers`} arrow>
                    <IconButton onClick={() => setEditMode(true)}>
                        <EditIcon color="info" />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Delete ${ResourceName} from list`} arrow>
                    <IconButton onClick={() => setDeleteMode(true)}>
                        <DeleteIcon color="error" />
                    </IconButton>
                </Tooltip>
                <Tooltip title={Note ?? 'No Notes'} arrow placement="left">
                    <IconButton>
                        <CommentIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </ListItem>
            <Collapse in={collapsedIn} timeout="auto" unmountOnExit>
                <List component={'div'} disablePadding>
                    {React.Children.toArray(
                        PayerList?.map((payer, index) => (
                            <ListItem sx={{ pl: 12 }} divider={divider && index === PayerList?.length - 1}>
                                <ListItemText
                                    primary={`Contributor: ${payer?.PayerResource?.ResourceName}`}
                                    secondary={`Paid: ${currencyFormatter.format(payer.Amount)}`}
                                />
                            </ListItem>
                        ))
                    )}
                </List>
            </Collapse>
        </>
    );
};

export default ResourceItemRow;
