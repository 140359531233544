import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { activeStatuses, apiString as API_URL, ERROR, localStorageKeys, SUCCESS } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setIsLoading, setShowMessage } from './shared.slice';
import { dictionaryToArray } from '../utils/mappings';
import { fetchMemberDemoGraphicInfo } from './memberDetail.slice';
import { getLocalMemberStatus } from '../utils/localStorageUtils';
import BackgroundTask from '../Models/Shared/BackgroundTask.model';

const getLocalSelectedTab = () => {
    return window.localStorage.getItem(localStorageKeys.SELECTED_TAB) ? parseInt(window.localStorage.getItem(localStorageKeys.SELECTED_TAB)) : 0;
};

const membersSlice = createSlice({
    name: 'members',
    initialState: {
        members: [],
        memberSearch: '',
        memberStatusChangesAwaiting: [],
        memberStatusChangesFuture: [],
        memberStatusChangesRecent: [],
        memberStatuses: [],
        pageSize: 15,
        pageNumber: 0,
        pageTotal: 0,
        sort: { field: 'LastName', sort: 'asc' },
        requestedMemberStatus: -1,
        riskLevels: [],
        selectedRiskLevel: ['-1'],
        selectedMemberStatus: getLocalMemberStatus(),
        selectedTab: getLocalSelectedTab(),
        selectedTeam: '-1',
        showMemberStatusChange: false,
        statusAwaitingApprovalCount: 0,
        fileProcessedCount: { Percentage: 0, ProcessedCount: 0, TotalCount: 0 },
        importSuccessful: false,
        isValidating: false,
        uploadingIcd10: false,
        validFile: true,
        icd10FileUploadBackGroundTask: new BackgroundTask(),
        openAlert: false,
        alertMessage: '',
        includeArchivedMembers: false,
        isFetchingMember: false,
    },
    reducers: {
        _setMembers(state, action) {
            state.members = action.payload;
        },
        _setMemberSearch(state, action) {
            state.memberSearch = action.payload;
        },
        _setMemberStatuses(state, action) {
            state.memberStatuses = action.payload;
        },
        _setMemberStatusChangesAwaiting(state, action) {
            state.memberStatusChangesAwaiting = action.payload;
        },
        _setMemberStatusChangesFuture(state, action) {
            state.memberStatusChangesFuture = action.payload;
        },
        _setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        _setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        _setPageTotal(state, action) {
            state.pageTotal = action.payload;
        },
        _setSort(state, action) {
            state.sort = action.payload;
        },
        _setMemberStatusChangesRecent(state, action) {
            state.memberStatusChangesRecent = action.payload;
        },
        _setRequestedMemberStatus(state, action) {
            state.requestedMemberStatus = action.payload;
        },
        _setRiskLevels(state, action) {
            state.riskLevels = action.payload;
        },
        _setSelectedRiskLevel(state, action) {
            state.selectedRiskLevel = action.payload;
        },
        _setSelectedMemberStatus(state, action) {
            state.selectedMemberStatus = action.payload;
        },
        _setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
        _setSelectedTeam(state, action) {
            state.selectedTeam = action.payload;
        },
        _setStatusAwaitingApprovalCount(state, action) {
            state.statusAwaitingApprovalCount = action.payload;
        },
        _setShowMemberStatusChange(state, action) {
            state.showMemberStatusChange = action.payload;
        },
        _setFileProcessedCount(state, action) {
            state.fileProcessedCount = action.payload;
        },
        _setImportSuccessful(state, action) {
            state.importSuccessful = action.payload;
        },
        _setIsValidating(state, action) {
            state.isValidating = action.payload;
        },
        _setUploadingICD10(state, action) {
            state.uploadingIcd10 = action.payload;
        },
        _setValidFile(state, action) {
            state.validFile = action.payload;
        },
        _setICD10FileUploadBackGroundTask(state, action) {
            state.icd10FileUploadBackGroundTask = action.payload;
        },
        _setOpenAlert(state, action) {
            state.openAlert = action.payload;
        },
        _setAlertMessage(state, action) {
            state.alertMessage = action.payload;
        },
        _setIncludeArchivedMembers(state, action) {
            state.includeArchivedMembers = action.payload;
        },
        _setIsFetchingMember(state, action) {
            state.isFetchingMember = action.payload;
        },
    },
});

const {
    _setStatusAwaitingApprovalCount,
    _setMembers,
    _setMemberSearch,
    _setMemberStatuses,
    _setMemberStatusChangesAwaiting,
    _setMemberStatusChangesFuture,
    _setMemberStatusChangesRecent,
    _setPageNumber,
    _setPageSize,
    _setPageTotal,
    _setRequestedMemberStatus,
    _setRiskLevels,
    _setSelectedRiskLevel,
    _setSelectedMemberStatus,
    _setSelectedTab,
    _setSelectedTeam,
    _setSort,
    _setShowMemberStatusChange,
    _setFileProcessedCount,
    _setImportSuccessful,
    _setIsValidating,
    _setUploadingICD10,
    _setValidFile,
    _setICD10FileUploadBackGroundTask,
    _setOpenAlert,
    _setAlertMessage,
    _setIncludeArchivedMembers,
    _setIsFetchingMember,
} = membersSlice.actions;

export const setMembers =
    (members: []): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMembers(members));
    };

export const approveMemberStatusChange =
    (memberStatusChange: any): AppThunk =>
    async (dispatch) => {
        try {
            await axios.post(`${API_URL}/member/approveMemberStatusChange`, memberStatusChange);
            dispatch(fetchMemberStatusChanges());
            dispatch(fetchStatusAwaitingApprovalCount());
        } catch (e) {
            dispatch(setShowMessage(true, e.response.data, ERROR));
        }
    };

export const cancelMemberStatusChange =
    (memberStatusChange: any): AppThunk =>
    async (dispatch) => {
        try {
            await axios.post(`${API_URL}/member/cancelMemberStatusChange`, memberStatusChange);
            dispatch(fetchMemberStatusChanges());
            dispatch(fetchStatusAwaitingApprovalCount());
        } catch (e) {
            dispatch(setShowMessage(true, e.response.data, ERROR));
        }
    };

export const fetchMembers = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/member/getmembersbystatus?statuses=${getState().members.selectedMemberStatus}`);
    console.log(response.data);
    dispatch(_setMembers(response.data));
    dispatch(setIsLoading(false));
};

export const fetchActiveStatusMembers = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/member/getmembersbystatus?statuses=${activeStatuses}`);
    console.log(response.data);
    dispatch(_setMembers(response.data));
    dispatch(setIsLoading(false));
};

export const fetchMembersByKeyword =
    (keywords: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setIsFetchingMember(true));
        const response = await axios.get(`${API_URL}/member/getactivemembers?keywords=${keywords}`);
        dispatch(_setMembers(response.data));
        dispatch(_setIsFetchingMember(false));
    };

export const fetchMembersPagination = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    let url = `${API_URL}/member/getmembersbystatus?search=${getState().members.memberSearch}&riskLevel=${getState().members.selectedRiskLevel}&team=${
        getState().members.selectedTeam
    }&sortDir=${getState().members.sort.sort}&sortField=${getState().members.sort.field}&pageSize=${getState().members.pageSize}&pageNumber=${
        getState().members.pageNumber
    }&statuses=${getState().members.selectedMemberStatus}&includeArchivedMembers=${getState().members.includeArchivedMembers}`;

    const response = await axios.get(url);
    if (response.data.Data.length < response.data.TotalCount) {
        var lastPage = Math.ceil(response.data.TotalCount / getState().members.pageSize);
        if (lastPage < getState().members.pageNumber) {
            dispatch(_setPageNumber(lastPage));
        }
    }
    dispatch(_setMembers(response.data.Data));
    dispatch(_setPageTotal(response.data.TotalCount));
    dispatch(setIsLoading(false));
};

export const fetchMemberStatuses = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getmemberstatuses`);
    const items = dictionaryToArray(response.data);
    // console.log(items);
    dispatch(_setMemberStatuses(items));
};

export const fetchMemberStatusChanges = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getstatuschanges`);
    dispatch(_setMemberStatusChangesAwaiting(response.data.MemberStatusChangesAwaiting));
    dispatch(_setMemberStatusChangesFuture(response.data.MemberStatusChangesFuture));
    dispatch(_setMemberStatusChangesRecent(response.data.MemberStatusChangesRecent));
};

export const fetchRiskLevels = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getrisklevel`);
    const items = dictionaryToArray(response.data);
    dispatch(_setRiskLevels(items));
};

export const fetchStatusAwaitingApprovalCount = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/member/getstatusawaitingapprovalcount`);
    dispatch(_setStatusAwaitingApprovalCount(response.data));
};

export const saveMemberStatusChange =
    (memberStatusChange: any): AppThunk =>
    async (dispatch) => {
        try {
            await axios.post(`${API_URL}/member/insertmemberstatuschange`, memberStatusChange);
            dispatch(setShowMessage(true, 'Success!', SUCCESS));
            dispatch(setShowMemberStatusChange(-1, false));
            dispatch(fetchStatusAwaitingApprovalCount());
            dispatch(fetchMemberDemoGraphicInfo(memberStatusChange.MemberId));
        } catch (e) {
            dispatch(setShowMessage(true, e.response.data, ERROR));
        }
    };

export const setMemberSearch =
    (search: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberSearch(search));
    };

export const setSelectedRiskLevel =
    (riskLevel: string[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedRiskLevel(riskLevel));
    };

export const setSelectedMemberStatus =
    (statuses: string[]): AppThunk =>
    async (dispatch, getState) => {
        window.localStorage.setItem(localStorageKeys.SELECTED_MEMBER_STATUS, JSON.stringify(statuses));
        dispatch(_setSelectedMemberStatus(statuses));
    };

export const setSelectedTab =
    (tab: number): AppThunk =>
    async (dispatch, getState) => {
        window.localStorage.setItem(localStorageKeys.SELECTED_TAB, tab + '');
        dispatch(_setSelectedTab(tab));
    };

export const setSelectedTeam =
    (team: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedTeam(team));
    };

export const setIncludeArchivedMembers =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIncludeArchivedMembers(value));
    };

export const setShowMemberStatusChangeReadOnly =
    (statusChange: any, show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(
            fetchMemberDemoGraphicInfo(statusChange.MemberId, () => {
                dispatch(_setShowMemberStatusChange(show));
            })
        );
    };

export const setShowMemberStatusChange =
    (newStatus: number, show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setRequestedMemberStatus(newStatus));
        dispatch(_setShowMemberStatusChange(show));
    };

export const setPageNumber =
    (pageNumber: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setPageNumber(pageNumber));
    };

export const setPageSize =
    (pageSize: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setPageSize(pageSize));
    };

export const setSort =
    (sort: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setSort(sort));
    };

export const setFileProcessedCount =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setFileProcessedCount(params));
    };

export const setImportSuccessful =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setImportSuccessful(value));
    };

export const setIsValidating =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsValidating(value));
    };

export const setUploadingICD10 =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setUploadingICD10(params));
    };

export const getFileProcessedCount = (): AppThunk => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/member/getfileprocessedcount`);
        dispatch(setFileProcessedCount(response.data));
    } catch (error) {
        console.log(error);
    }
};

export const setValidFile =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setValidFile(params));
    };

export const setICD10FileUploadBackGroundTask =
    (params: BackgroundTask): AppThunk =>
    async (dispatch) => {
        dispatch(_setICD10FileUploadBackGroundTask(params));
    };

export const setOpenAlert =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAlert(value));
    };

export const setAlertMessage =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setAlertMessage(params));
    };

export const getICD10BackgroundTask = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/backgroundtask/geticd10fileuploadbackgroundtask`);
        dispatch(setICD10FileUploadBackGroundTask(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
    }
};

export const downloadSample = (): AppThunk => async () => {
    const response = await axios.get(`${API_URL}/member/downloadsampleicd10`);
    let blob = b64toBlob(response.data.base64String, 'text/csv');
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `ICD10_Sample.xlsx`);
    link.click();
    link.remove();
};

export const validateFile =
    (file: File): AppThunk =>
    async (dispatch) => {
        dispatch(setIsValidating(true));
        let formData = new FormData();
        formData.append('File', file);
        try {
            const response = await axios.put(`${API_URL}/member/validatefiledata`, formData);
            if (response.data.IsValid) {
                dispatch(setAlertMessage(''));
                dispatch(setOpenAlert(false));
                dispatch(setValidFile(true));
                dispatch(setIsValidating(false));
                dispatch(uploadICD10(file));
            } else {
                dispatch(setOpenAlert(true));
                dispatch(setIsValidating(false));
                dispatch(setAlertMessage(response.data.Message));
            }
        } catch (error) {
            if (error.response.data.error) {
                console.log('error.response.data.error message: ', error.response.data.error);
            } else {
                console.log('error.response.data message: ', error.response.data);
            }
            dispatch(setIsValidating(false));
        }
    };

export const uploadICD10 =
    (file: File): AppThunk =>
    async (dispatch) => {
        var newTask = new BackgroundTask();
        newTask.IsRunning = true;
        dispatch(setICD10FileUploadBackGroundTask(newTask));
        let formData = new FormData();
        formData.append('File', file);
        try {
            await axios.put(`${API_URL}/member/uploadicd10`, formData);
            dispatch(setImportSuccessful(true));
            dispatch(setFileProcessedCount({ Percentage: 0, ProcessedCount: 0, TotalCount: 0 }));
            dispatch(getICD10BackgroundTask());
        } catch (error) {
            dispatch(getICD10BackgroundTask());
            if (error.response.data.error) {
                console.log('error.response.data.error message: ', error.response.data.error);
            } else {
                console.log('error.response.data message: ', error.response.data);
            }
        }
    };

export default membersSlice.reducer;
