import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IRequest, IRequestQuestion, IRequestQuestionAnswer } from '../components/Requests/interfaces';
import { apiString } from '../utils/constants';
import { setRequests } from './requests.slice';
import { AppThunk } from './store';

interface INewRequestSliceState {
    isNewRequestModalOpen: boolean;
    answers: Partial<IRequestQuestionAnswer>[];
    requestQuestionList: IRequestQuestion[];
    isFetchingNewQuestionList: boolean;
    isSavingNewAnswer: boolean;
}

const initialState: INewRequestSliceState = {
    isNewRequestModalOpen: false,
    answers: [],
    requestQuestionList: [],
    isFetchingNewQuestionList: false,
    isSavingNewAnswer: false,
};

const newRequestSlice = createSlice({
    name: 'newRequest',
    initialState,
    reducers: {
        _setIsNewRequestModalOpen(state, action) {
            state.isNewRequestModalOpen = action.payload;
        },
        _setAnswers(state, action) {
            state.answers = action.payload;
        },
        _setRequestQuestionList(state, action) {
            state.requestQuestionList = action.payload;
        },
        _setIsFetchingNewQuestionList(state, action) {
            state.isFetchingNewQuestionList = action.payload;
        },
        _setIsSavingNewAnswer(state, action) {
            state.isSavingNewAnswer = action.payload;
        },
    },
});

export const REQUEST_API = `${apiString}/request` as const;

const { _setIsNewRequestModalOpen, _setAnswers, _setRequestQuestionList, _setIsFetchingNewQuestionList, _setIsSavingNewAnswer } = newRequestSlice.actions;

export const setIsSavingNewAnswer =
    (isSaving: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsSavingNewAnswer(isSaving));
    };

export const setIsNewRequestModalOpen =
    (isOpen: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsNewRequestModalOpen(isOpen));
    };

export const setAnswers =
    (newAnswers: Partial<IRequestQuestionAnswer>[]): AppThunk =>
    async (dispatch) => {
        console.log({ newAnswers });
        dispatch(_setAnswers(newAnswers));
    };

export const writeAnswer =
    (answer: Partial<IRequestQuestionAnswer>): AppThunk =>
    async (dispatch, getState) => {
        const { answers } = getState().newRequest;
        const oldAnswerIndex = answers.findIndex((a) => a.QuestionId === answer.QuestionId);
        const oldAnswer = answers[oldAnswerIndex];
        const newAnswers = [...answers];
        newAnswers[oldAnswerIndex] = { ...oldAnswer, ...answer };
        dispatch(setAnswers(newAnswers));
    };

export const setNewRequestQuestionList =
    (requestQuestionList: IRequestQuestion[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setRequestQuestionList(requestQuestionList));
    };

export const startNewRequest = (): AppThunk => async (dispatch, getState) => {
    dispatch(setAnswers([]));
};

export const setIsFetchingNewQuestionList =
    (isFetching: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsFetchingNewQuestionList(isFetching));
    };

export const setNewQuestionList = (): AppThunk => async (dispatch, getState) => {
    const { selectedRequestTypeId } = getState().requests;
    dispatch(setIsFetchingNewQuestionList(true));
    const response = await axios.get(`${REQUEST_API}/GetRequestTypeQuestionList/${selectedRequestTypeId}`);
    if (response?.data) {
        dispatch(setNewRequestQuestionList(response.data));
    }
    dispatch(setIsFetchingNewQuestionList(false));
};

export const submitNewRequest =
    (newRequest: Partial<IRequest>): AppThunk =>
    async (dispatch, getState) => {
        const request = axios.post(`${REQUEST_API}`, newRequest);
        request.then((response) => {
            const { requests } = getState().requests;
            const newRequests = [...requests];
            newRequests.push(response.data);
            dispatch(setRequests(newRequests));
        });
    };

export default newRequestSlice.reducer;
