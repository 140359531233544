import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const memberStatusReport = createSlice({
    name: 'memberStatusReport',
    initialState: {
        memberStatusReport: [],
        memberDetailFilter: 'all',
    },
    reducers: {
        _setMemberStatusReport(state, action) {
            state.memberStatusReport = action.payload;
        },
        _setMemberDetailFilter(state, action) {
            state.memberDetailFilter = action.payload;
        },
    },
});

const { _setMemberStatusReport, _setMemberDetailFilter } = memberStatusReport.actions;

export const fetchMemberStatusReport =
    (start: Date, end: Date, planName: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/MemberStatusReport/getmembers?start=${dateFormatter(start)}&end=${dateFormatter(end)}&planName=${planName}`
        );
        dispatch(setMemberStatusReport(response.data));
        dispatch(setIsLoading(false));
    };

export const setMemberStatusReport =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberStatusReport(params));
    };

export const setMemberDetailFilter =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberDetailFilter(params));
    };

export const exportMemberStatusReport =
    (start: Date, end: Date, planName: string): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/MemberStatusReport/exportmembers?start=${dateFormatter(start)}&end=${dateFormatter(end)}&planName=${planName}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default memberStatusReport.reducer;
