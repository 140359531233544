import { MemberStatusEnum } from '../Enum/MemberStatusEnum';
import Authorization from './Authorization';
export const chpAzureWebUrl = 'https://chp-react-dev.azurewebsites.net/';

export const apiString = new Authorization().APIUrl;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyD0ZVhNqOlGF37mVlyQX2DZUbnvDaLROus' as const;
// The user id is actually the provider id. This should likely be refactored soon.
export const ProviderId = new Authorization().UserId;

export const activeStatuses = [
    MemberStatusEnum.Active,
    MemberStatusEnum.Scheduled,
    MemberStatusEnum.Undetermined,
    MemberStatusEnum.OffBoarding,
    MemberStatusEnum.UncompensatedCare,
    MemberStatusEnum.HRAInReview,
    MemberStatusEnum.Suspended,
    MemberStatusEnum.FUHFUMInProgress,
    MemberStatusEnum.FUHUnd,
];

export const WidgetsEnum = {
    ADULT_HEALTH_SUMMARY: 'Adult Health Summary',
    AGED_SERVICES: 'Aged Services',
    AGED_REQUESTS: 'Aged Requests',
    APPOINTMENTS_NOT_YET_CONFIRMED: 'Appointments Not Yet Confirmed',
    APPOINTMENTS_VS_ENCOUNTERS: 'Appointments Vs Encounters',
    BMI_SUMMARY: 'BMI Summary',
    BP_SUMMARY: 'BP Summary',
    BREAST_CANCER_SCREENING_SUMMARY: 'Breast Cancer Screening Summary',
    CALLING_PERFORMANCE: 'Calling Performance',
    CANCELLED_AND_NOT_RESCHEDULED: 'Cancelled And Not Rescheduled',
    CARE_PLAN_NOT_REVIEWED_SUMMARY: 'Care Plan Not Reviewed Summary',
    CLEAN_CLAIM_SUMMARY: 'Clean Claim Summary',
    CROSS_DISCIPLINE_CARE_SUMMARY: 'Cross Discipline Care Summary',
    DEPRESSION_FOLLOW_UP_SUMMARY: 'Depression Follow-Up Summary',
    DEPRESSION_SCREENING_SUMMARY: 'Depression Screening Summary',
    DIABETIC_EYE_EXAM: 'Diabetic Eye Exam Summary',
    DOCUMENT_TRACKING_SUMMARY: 'Document Tracking Summary',
    EDUCATION_SESSION_REMINDER: 'Education Session Reminder',
    ENCOUNTERS_SUMMARY: 'Encounters Summary',
    ENGAGEMENTS_SUMMARY: 'Engagements Summary',
    ER_ADMISSIONS: 'ER Admissions',
    HGB_A1C_SUMMARY: 'Hgb A1C Summary',
    HGB_A1C_FOR_BHDX_SUMMARY: 'Hgb A1C For BHDX Summary',
    HIGHEST_COST_MEMBERS: 'Highest Cost Members',
    IN_PERSON_VISITS_SUMMARY: 'In-person Visits Summary',
    LIPID_FOR_BHDX_SUMMARY: 'Lipid For BHDX Summary',
    MEDICATION_RECONCILIATION_SUMMARY: 'Medication Reconciliation Summary',
    MEMBERS_MISSING_GENDER: 'Members Missing Gender',
    MEMBERS_NOT_SCHEDULED_WITH_BH_OR_NP: 'Members Not Scheduled With BH or NP',
    TEAM_IN_PERSON_VISITS_SUMMARY: 'Member Visits By Team',
    INDIVIDUAL_IN_PERSON_VISITS_SUMMARY: 'Individual In-Person Member Visits',
    MEMBERS_WITH_GOALS_NOT_MET: 'Members With Goals Not Met',
    MEMBERS_WITH_NO_CONSENT_FORM: 'Members With No Consent Form',
    MEMBERS_WITH_NO_PRIMARY_DIAGNOSIS: 'Members With No Primary Diagnosis',
    MEMBERS_WITHOUT_ENCOUNTER: 'Members Without Encounter',
    ONBOARDING_PERFORMANCE: 'Onboarding Performance',
    PATIENT_PANEL_ERRORS: 'Patient Panel Errors',
    PHQ9: 'PHQ9',
    RECENT_MEDICAL_DOCUMENTS: 'Recent Medical Documents',
    RESCHEDULING_OPPORTUNITIES: 'Rescheduling Opportunities',
    SCHEDULER_SUMMARY: 'Scheduler Summary',
    REVENUE_METRICS: 'Revenue Metrics',
    SCHEDULINGS_SUMMARY: 'Schedulings Summary',
    SOCIAL_WORKER_REQUEST_SUMMARY: 'Social Worker Request Summary',
    SUBMITTED_CLAIM_SUMMARY: 'Submitted Claim Summary',
    TEAM_TC_SUMMARY: 'Team TC Summary',
    TEAM_MEMBER_SUMMARY: 'Team Member Summary',
    TELEMONITORING_OUT_OF_LIMITS_BY_USER: 'Telemonitoring Out Of Limits By User',
    TRANSITIONAL_CARE: 'Transitional Care',
    TOTAL_COST: 'Total Cost',
    EMERGENCY_DEPARTMENT_VISITS: 'Emergency Department Visits',
    ACUTE_CARE_COSTS: 'Acute Care Costs',
    PHARMACEUTICAL_COSTS: 'Pharmaceutical Costs',
    ACUTE_BH_COSTS: 'Acute BH Costs',
    BH_RTC_RF_COSTS: 'BH RTC/RF Costs',
    FUHFUM_EXCEPTION_SUMMARY: 'FUHFUM Exception Summary',
    FUHFUM_INPROGRESS_SUMMARY: 'FUHFUM InProgress Summary',
    FUHFUM_UNDETERMINED_SUMMARY: 'FUHFUM Undetermined Summary',
    FUHFUM_QUALITY_METRICS: 'FUHFUM Quality Metrics',
    FUHFUM_CLOSED_SUMMARY: 'FUHFUM Closed Summary',
    SURVEY_SUMMARY: 'Survey Summary',
    FUHFUM_FULL_PROGRAM_REFERRAL_SUMMARY: 'FUHFUM Full Program Referral Summary',
    ONBOARD_CALL_PERFORMANCE: 'Onboard Call Performance',
    WIDGET_USAGE: 'Widget Usage',
};

export interface IEnumMetadata {
    value: number;
    text: string;
}

export interface IAppointmentTypeEnumMetadata extends IEnumMetadata {
    abbrev?: string;
    preferredType?: number;
    durationTimes: { default: number; [key: string]: number };
}

export interface IAppointmentStatusEnumMetadata extends IEnumMetadata {
    eventColor: string;
    rescheduledEventColor?: string;
    iconClass: string;
}

export const appointmentTypeEnums: IAppointmentTypeEnumMetadata[] = [
    { value: 31, text: 'ART Staffing', abbrev: 'ART', durationTimes: { default: 60 } },
    { value: 20, text: 'Behavioral Health Appointment', abbrev: 'BHA', durationTimes: { default: 90 } },
    { value: 7, text: 'Care Planning', abbrev: 'CP', durationTimes: { default: 30 } },
    { value: 16, text: 'Check In Call', abbrev: 'P', preferredType: 3, durationTimes: { default: 30 } },
    { value: 3, text: 'Follow Up', abbrev: 'F', durationTimes: { default: 90 } },
    { value: 27, text: 'HRA', abbrev: 'HRA', durationTimes: { default: 120 } },
    { value: 28, text: 'HRA Follow up', abbrev: 'HRAF', durationTimes: { default: 30 } },
    { value: 2, text: 'Initial', abbrev: 'I', durationTimes: { default: 90 } },
    { value: 1, text: 'Onboarding', abbrev: 'O', durationTimes: { default: 120 } },
    { value: 8, text: 'PCP Appointment', abbrev: 'PCP', preferredType: 19, durationTimes: { default: 30 } },
    { value: 4, text: 'Phone Call', abbrev: 'P', preferredType: 3, durationTimes: { default: 30 } },
    { value: 24, text: 'Provider Leave', abbrev: 'PL', durationTimes: { default: 30 } },
    { value: 25, text: 'Rescheduled', abbrev: 'R', durationTimes: { default: 30 } },
    { value: 6, text: 'Social Services', abbrev: 'S', durationTimes: { default: 30 } },
    { value: 26, text: 'Team Meeting', abbrev: 'TM', durationTimes: { default: 30 } },
    { value: 17, text: 'Telemedicine', abbrev: 'T', preferredType: 3, durationTimes: { default: 30 } },
    { value: 22, text: 'Transitional Care', abbrev: 'TC', durationTimes: { default: 30 } },
    { value: 29, text: 'Transportation', abbrev: 'TP', durationTimes: { default: 30 } },
    { value: 23, text: 'Urgent Response', abbrev: 'UR', durationTimes: { default: 30 } },
    { value: 30, text: 'FUHFUM', abbrev: 'FUHFUM', durationTimes: { default: 90, 'Nurse Practitioner': 120, 'Behavioural Health Specialist': 120 } },
    { value: 21, text: 'Outside Health Appointment', abbrev: 'OHA', durationTimes: { default: 30 } },
    { value: 32, text: 'Integrated', abbrev: 'INT', durationTimes: { default: 90 } },
];

export const localStorageKeys = {
    SELECTED_MEMBER_STATUS: 'selectedMemberStatus',
    SELECTED_TAB: 'selectedTab',
    SELECTED_MEMBER_DETAIL_PAGE: 'selectedMemberDetailPage',
};

export const meansOfEngagementEnums = [
    { value: 1, text: 'In-Person/Home', iconCls: 'b-fa b-fa-user' },
    { value: 2, text: 'Phone', iconCls: 'b-fa b-fa-phone' },
    { value: 3, text: 'Telemedicine', iconCls: 'b-fa b-fa-desktop' },
];

export const surveyStatusEnumbers = [
    { value: 1, text: 'Incomplete' },
    { value: 2, text: 'Complete' },
    { value: 3, text: 'Closed' },
];

export const SELECTED_PROVIDER_ID = 'SELECTED_PROVIDER_ID';
export const USE_HOME_ADDRESS = 'USE_HOME_ADDRESS';
export const MAP_DISTANCE_APPT_STATUS = 'MAP_DISTANCE_APPT_STATUS';

export const rescheduledEventColor = '#b495c7';

export const appointmentStatusEnums: IAppointmentStatusEnumMetadata[] = [
    {
        value: 3,
        text: 'Cancelled',
        iconClass: 'fa fa-ban',
        eventColor: '#DC3545',
        rescheduledEventColor,
    },
    {
        value: 4,
        text: 'Completed',
        iconClass: 'fa fa-check-circle',
        eventColor: '#28A745',
    },
    {
        value: 1,
        text: 'Confirmed',
        iconClass: 'fa fa-thumbs-up',
        eventColor: '#17A2B8',
    },
    {
        value: 2,
        text: 'No Call No Show',
        iconClass: 'fa fa-exclamation-circle',
        eventColor: '#6C757D',
        rescheduledEventColor,
    },
    {
        value: 5,
        text: 'Provider Unavailable',
        iconClass: 'fa fa-ban',
        eventColor: '#6C757E',
    },
    {
        value: 0,
        text: 'Unconfirmed',
        iconClass: 'fa fa-question',
        eventColor: '#EB984E',
    },
];

export const providerTypes = [
    { value: 1, text: 'Internal' },
    { value: 2, text: 'External' },
];

export const vitalTypes = [
    { type: 1, name: 'Systolic BP' },
    { type: 2, name: 'Diastolic BP' },
    { type: 3, name: 'Heart Rate' },
    { type: 4, name: 'Oximeter' },
    { type: 5, name: 'Scale' },
    { type: 6, name: 'Glucose' },
];

export const currentTimeNearest15Minutes = () => {
    const date = new Date();
    let minutes = date.getMinutes();
    let hours = date.getHours();
    const m = ((((minutes + 7.5) / 15) | 0) * 15) % 60;
    const h = (((minutes / 105 + 0.5) | 0) + hours) % 24;
    return new Date((hours = h), (minutes = m));
};

// Prepare sdoh question
export const languageList = [
    { Answer: 'English', Diagnosis: null, LoincCode: 'LA16648-0 (English all of the time)' },
    { Answer: 'Language other than English (please write)', Diagnosis: null, LoincCode: 'LA16649-8 (English most of the time)' },
];
export const healthLiteratureList = [
    { Answer: 'Yes', Diagnosis: '587f3d3404a2f81d243b6eea', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const housingSituationList = [
    { Answer: 'I have housing', Diagnosis: null, LoincCode: null },
    {
        Answer: 'I do not have housing (staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car or in a park)',
        Diagnosis: '587f3d3404a2f81d243b6f06',
        LoincCode: 'LA18835-1 (Patient is homeless)',
    },
];
export const losingHouseList = [
    { Answer: 'Yes', Diagnosis: '587f3d3404a2f81d243b6f0e', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const levelOfSchoolList = [
    { Answer: 'Less than high school degree', Diagnosis: '5ece04d74692d6430a74881d', LoincCode: 'LA15616-8 (10th grade)' },
    { Answer: 'Highschool diploma or GED', Diagnosis: '5ece05254692d6430a74883c', LoincCode: 'LA15564-0 (High school graduate)' },
    { Answer: 'More than high school', Diagnosis: null, LoincCode: 'LA15620-0 (Some college, no degree)' },
];
export const workingSituationList = [
    { Answer: 'Unemployed', Diagnosis: '587f3d3404a2f81d243b6eec', LoincCode: 'LA17956-6 (Unemployed)' },
    { Answer: 'Part-time or temporary work', Diagnosis: '5ece09724692d6430a7488c8', LoincCode: 'LA17958-2 (Part-time)' },
    { Answer: 'Full-time work', Diagnosis: '5ece09884692d6430a7488d0', LoincCode: 'LA17957-4 (Full-time)' },
    {
        Answer: 'Otherwise unemployed but not seeking work (student, retired, disabled, unpaid primary care giver) Please write',
        Diagnosis: '5ece09584692d6430a7488c2',
        LoincCode: 'LA15276-1 (student)',
    },
];
export const totalIncomeList = [
    { Answer: 'I have no income', Diagnosis: '587f3d3404a2f81d243b6f0b', LoincCode: null },
    { Answer: 'Less than poverty threshold', Diagnosis: null, LoincCode: '63508-6 (less than poverty threshold)' },
    { Answer: 'Less than $35,000', Diagnosis: null, LoincCode: '63507-8 (less than $35,000)' },
    { Answer: '$35,001 to $50,000', Diagnosis: null, LoincCode: '63506-0 ($35,001 to $50,000)' },
    { Answer: '$50,001 to $75,000', Diagnosis: null, LoincCode: '63510-2 ($50,001 to $75,000' },
    { Answer: '$75,001 to $100,000', Diagnosis: null, LoincCode: '63509-4 ($75,001 to $100,000)' },
];
export const foodList = [
    { Answer: 'Yes', Diagnosis: '587f3d3404a2f81d243b6f0a', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const clothingList = [
    { Answer: 'Yes', Diagnosis: '5ece0d114692d6430a748947', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const utilitiesList = [
    { Answer: 'Yes', Diagnosis: '5ece0caa4692d6430a748931', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const childCareList = [
    { Answer: 'Yes', Diagnosis: '5ece0d264692d6430a74894d', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const phoneList = [
    { Answer: 'Yes', Diagnosis: '5ece0cfa4692d6430a748941', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const healthCareList = [
    { Answer: 'Yes', Diagnosis: '5ece0cd64692d6430a748939', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const otherNeedsList = [
    { Answer: 'Yes', Diagnosis: '5ece0d404692d6430a748959', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
];
export const frequencyOfTalkingPeopleList = [
    { Answer: 'Less than once a week', Diagnosis: '587f3d3404a2f81d243b6f14', LoincCode: null },
    { Answer: '1 or 2 times a week', Diagnosis: null, LoincCode: null },
    { Answer: '3 to 5 times a week', Diagnosis: null, LoincCode: null },
    { Answer: '5 or more times a week', Diagnosis: null, LoincCode: null },
];
export const stressLevelList = [
    { Answer: 'Not at all', Diagnosis: null, LoincCode: 'LA13866-1 (never. Not at all)' },
    { Answer: 'A little bit', Diagnosis: null, LoincCode: 'LA13866-1 (Almost Never. A little bit)' },
    { Answer: 'Somewhat', Diagnosis: null, LoincCode: 'LA10082-8 (Somewhat often)' },
    { Answer: 'Quite a bit', Diagnosis: '587f3d3504a2f81d243b6fbc', LoincCode: 'LA16644-9 (Fairly often. Quite a bit and Very Much)' },
    { Answer: 'Very much', Diagnosis: '5ece11cb4692d6430a748a0a', LoincCode: 'LA16644-9 (Fairly often. Quite a bit and Very Much)' },
];
export const emotionallySafeList = [
    { Answer: 'Yes', Diagnosis: null, LoincCode: null },
    { Answer: 'No', Diagnosis: '5ece11b84692d6430a748a04', LoincCode: null },
    { Answer: 'Unsure', Diagnosis: null, LoincCode: null },
];
export const afraidOfPartnerList = [
    { Answer: 'Yes', Diagnosis: '587f3d3404a2f81d243b6f31', LoincCode: null },
    { Answer: 'No', Diagnosis: null, LoincCode: null },
    { Answer: 'Unsure', Diagnosis: null, LoincCode: null },
    { Answer: 'I have not had a partner in the past year', Diagnosis: null, LoincCode: null },
];

// Poverty guidelines
export const povertyGuidelines = [
    { NoOfPersons: 1, Amount: 12760 },
    { NoOfPersons: 2, Amount: 17240 },
    { NoOfPersons: 3, Amount: 21720 },
    { NoOfPersons: 4, Amount: 26200 },
    { NoOfPersons: 5, Amount: 30680 },
    { NoOfPersons: 6, Amount: 35160 },
    { NoOfPersons: 7, Amount: 39640 },
    { NoOfPersons: 8, Amount: 44120 },
    { NoOfPersons: 9, Amount: 48600 },
    { NoOfPersons: 10, Amount: 53080 },
];

export const medicationReviewList = [
    { Id: 1, Name: 'Yes, with no changes or no meds' },
    { Id: 2, Name: 'yes, new medications' },
    { Id: 3, Name: 'yes, changes in previous medications' },
    { Id: 4, Name: 'Unable to review' },
    { Id: 5, Name: 'Not available' },
];

export const dischargeOrderReviewList = [
    { Id: 1, Name: 'Yes, understands' },
    { Id: 2, Name: 'yes, not understanding education and follow up required' },
    { Id: 3, Name: 'Not available' },
];

export const appointmentScheduleList = [
    { Id: 1, Name: 'Yes, scheduled' },
    { Id: 2, Name: 'No, needs to be scheduled' },
];

export const medicalEquipmentList = [
    { Id: 1, Name: 'Yes, received' },
    { Id: 2, Name: 'yes, not started or received' },
    { Id: 3, Name: 'Not available' },
    { Id: 4, Name: 'Not applicable' },
];

export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';

export const TransitionalCare = 'Transitional Care';

export const states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const getParentUrl = () => {
    return window.location.origin + '/';
};

export const parentUrl = getParentUrl();

export const smallModalStyle = {
    position: 'absolute' as 'absolute',
    top: '400px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #808080',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

export const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '400px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '1px solid #808080',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

export const largeModalStyle = {
    position: 'absolute' as 'absolute',
    top: '400px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    border: '1px solid #808080',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

export const licensures = [
    { Name: 'LPC', Id: '56ba0148b6a2d91c0c972871' },
    { Name: 'LASAC', Id: '56ba0148b6a2d91c0c972874' },
    { Name: 'LCSW', Id: '56ba0148b6a2d91c0c972875' },
    { Name: 'LMSW', Id: '56ba0148b6a2d91c0c972876' },
    { Name: 'BHT', Id: '56ba0148b6a2d91c0c972877' },
    { Name: 'LISAC', Id: '56ba0148b6a2d91c0c972873' },
    { Name: 'LAC', Id: '56ba0148b6a2d91c0c972872' },
    { Name: 'Psychiatrist', Id: '56ba0148b6a2d91c0c972878' },
    { Name: 'Psychologist', Id: '56ba0148b6a2d91c0c972879' },
    { Name: 'NP', Id: '56ba0148b6a2d91c0c972880' },
];

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const healthPlanPriorities = [{ value: 'Primary' }, { value: 'Secondary' }, { value: 'Tertiary' }, { value: 'Other' }];

export const ROLE_NAMES = {
    NURSE_PRACTITIONER: 'Nurse Practitioner',
    BEHAVIOURAL_HEALTH_SPECIALIST: 'Behavioural Health Specialist',
} as const;
