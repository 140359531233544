import { Button, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useState } from 'react';
import {
    setCurrentPopulation,
    downloadSamplePopulationHandler,
    setOpenAddPopulation,
    setPopulationActiveStep,
    addPopulation,
} from '../../../store/adminHealthPlan.slice';
import { copyObject } from '../../../utils/common';

const populationTypes = [
    { label: 'Full Program', value: 1 },
    { label: 'HRA', value: 2 },
    { label: 'FUHFUM', value: 3 },
    { label: 'SUD', value: 4 },
];

const SampleData = (props) => {
    const { currentHealthPlan, currentPopulation, newPopulation, populationByHealthPlan, updatingPopulation } = useSelector(
        (state: RootState) => state.adminHealthPlanSlice
    );
    const [selectedPopulationType, setSelectedPopulationType] = useState<string>(
        currentPopulation.IsHra ? 'HRA' : currentPopulation.IsFuhFum ? 'FUHFUM' : currentPopulation.IsSUD ? 'SUD' : 'Full Program'
    );

    const dispatch = useDispatch();
    const nextStepHandler = () => {
        if (newPopulation) {
            let data = copyObject(currentPopulation);
            if (selectedPopulationType === 'HRA') {
                data.IsHra = true;
            } else if (selectedPopulationType === 'SUD') {
                data.IsSUD = true;
            } else if (selectedPopulationType === 'FUHFUM') {
                data.IsFuhFum = true;
            } else {
                data.IsHra = false;
                data.IsFuhFum = false;
                data.IsSUD = false;
            }

            dispatch(addPopulation(data, currentHealthPlan.Id));
        }
        dispatch(setPopulationActiveStep(1));
    };

    const preparePopulationType = (pop) => {
        setSelectedPopulationType(pop.IsHra ? 'HRA' : pop.IsFuhFum ? 'FUHFUM' : pop.IsSUD ? 'SUD' : 'Full Program');
    };

    return (
        <>
            <DialogContent>
                <FormControl fullWidth>
                    <Grid spacing={2} container p={2} sx={{ height: '65vh' }}>
                        <Grid item xs={12}>
                            <Stack>
                                <Typography variant="h4" marginBottom={3}>
                                    Prepare your data for import
                                </Typography>
                                <Typography variant="body1">You can import up to 100 MB size file. The file should be in .xlsx or .xls format.</Typography>
                                <br />
                                <Typography variant="body1">
                                    The column name and order should match with sample file. Dowload the sample and explore the columns to prepare your file.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField disabled value={currentHealthPlan.Name || ''} id="name" label="Health Plan" fullWidth variant="outlined" />
                        </Grid>

                        <Grid item xs={3}>
                            {!updatingPopulation && (
                                <TextField
                                    required
                                    disabled={!newPopulation}
                                    value={currentPopulation?.PopulationName || ''}
                                    label="Population Name"
                                    name="PopulationName"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => dispatch(setCurrentPopulation({ ...currentPopulation, PopulationName: e.target.value }))}
                                />
                            )}
                            {updatingPopulation && (
                                <FormControl fullWidth>
                                    <InputLabel id="plan-type-label">Population Name</InputLabel>
                                    <Select
                                        labelId="plan-type-label"
                                        id="plan-type"
                                        value={populationByHealthPlan.find((el) => el.PopulationName === currentPopulation.PopulationName) || ''}
                                        margin="dense"
                                        label="Population Name"
                                        name="PopulationName"
                                        onChange={(e) => {
                                            dispatch(setCurrentPopulation(e.target.value));
                                            preparePopulationType(e.target.value);
                                        }}
                                    >
                                        <MenuItem disabled value={0}>
                                            Select
                                        </MenuItem>
                                        {populationByHealthPlan.map((plan, index) => (
                                            <MenuItem key={index} value={plan}>
                                                {plan.PopulationName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Population Type</InputLabel>
                                <Select
                                    required
                                    disabled={!newPopulation}
                                    value={selectedPopulationType}
                                    label="PopulationType"
                                    name="PopulationType"
                                    onChange={(e) => setSelectedPopulationType(e.target.value)}
                                >
                                    {newPopulation
                                        ? populationTypes
                                              .filter((t) => t.label !== 'FUHFUM')
                                              .map((type) => (
                                                  <MenuItem key={type.value} value={type.label}>
                                                      {type.label}
                                                  </MenuItem>
                                              ))
                                        : populationTypes.map((type) => (
                                              <MenuItem key={type.value} value={type.label}>
                                                  {type.label}
                                              </MenuItem>
                                          ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Button className="button-120" variant="contained" color="success" onClick={() => dispatch(downloadSamplePopulationHandler())}>
                                Download Sample
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button className="button-120" variant="outlined" onClick={() => dispatch(setOpenAddPopulation(false))}>
                    Close
                </Button>
                <Button
                    disabled={currentPopulation?.PopulationName.length === 0}
                    className="button-120"
                    variant="contained"
                    onClick={() => {
                        nextStepHandler();
                    }}
                >
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default SampleData;
