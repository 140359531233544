import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { IBasicModalProps } from '../interfaces/IBasicModalProps';

const ConfirmCloseRequestModal: React.FC<IBasicModalProps> = (props) => {
    const { open, onClose, onSuccess } = props;
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Close request with no further actions?</DialogTitle>
            <DialogContent>
                <Typography>
                    This will remove the request from being worked without marking it as complete. It will no longer appear as part of anyone's normal workflow.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onSuccess}>
                    Close Request
                </Button>
                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmCloseRequestModal;
