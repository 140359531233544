import { Box, Modal, Stack, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid rgb(58 70 228)',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

export default function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    const { isLoading } = useSelector((state: RootState) => state.shared);
    return (
        <div>
            <Modal open={isLoading} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Importing...
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" {...props} style={{ flex: 1 }} />
                        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}
