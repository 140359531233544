import { DataGrid, gridClasses, GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../../../../../utils/timeFormat';
import { RootState } from '../../../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelections, getTopThreeMatches, setRowSelectionModel, setSelectedAlert, setUnmatchedId } from '../../../../../../store/hie.slice';
import { isDefaultDate } from '../../../../../../utils/common';

const CovidAlerts = () => {
    const dispatch = useDispatch();

    const { unmatchedId, showArchivedAlerts, unmatchedAlerts } = useSelector((state: RootState) => state.hie);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'CHPDownloadDate',
            headerName: 'Download Date',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CHPDownloadDate) ? '-' : dateFormatter(new Date(params.row.CHPDownloadDate))}`,
        },
        {
            field: 'SendingFacility',
            headerName: 'Sending Facility',
            minWidth: 200,
            flex: 1.5,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.VisitType?.trim().length > 0 ? params.row.VisitType : 'N/A'}`,
        },
        {
            field: 'TestOrdered',
            headerName: 'Test Performed',
            flex: 1.5,
            minWidth: 360,
            renderCell: (params: GridRenderCellParams) => {
                return <>{params.row.TestOrdered}</>;
            },
        },
        {
            field: 'Result',
            headerName: 'Result',
            flex: 1,
        },
        {
            field: 'ObservationLabDate',
            headerName: 'Lab Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.ObservationLabDate) ? '-' : dateFormatter(new Date(params.row.ObservationLabDate))}`,
        },
        {
            field: 'ObservationLabTime',
            headerName: 'Lab Time',
            flex: 1,
        },
    ];
    return (
        <>
            <DataGrid
                onRowClick={(params: any) => {
                    const { DOB, Id, Patient } = params.row;
                    const names = Patient.split(' ');
                    dispatch(clearSelections());
                    dispatch(setUnmatchedId(Id));
                    dispatch(setSelectedAlert(params.row));
                    dispatch(getTopThreeMatches(names[0], names[names.length - 1], DOB));
                }}
                autoHeight
                rows={
                    showArchivedAlerts
                        ? unmatchedAlerts.filter((alert) => alert.AlertType === 'COVID')
                        : unmatchedAlerts.filter((alert) => alert.AlertType === 'COVID').filter((alert) => alert.Archived !== true)
                }
                columns={columns}
                checkboxSelection={unmatchedId.length > 0 ? true : false}
                onSelectionModelChange={(ids) => {
                    dispatch(setRowSelectionModel(ids));
                }}
                pageSize={10}
                getRowHeight={() => 'auto'}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.Id}
                getRowClassName={(params) => (params.row.Archived ? 'archived' : '')}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                    [`& .archived`]: {
                        background: 'rgba(200,200,200,0.4)',
                        fontStyle: 'italic',
                    },
                }}
            />
        </>
    );
};

export default CovidAlerts;
