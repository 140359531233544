export enum MonthEnum {
    JanuaryDescription = 'January',
    January = 1,
    FebruaryDescription = 'February',
    February = 2,
    MarchDescription = 'March',
    March = 3,
    AprilDescription = 'April',
    April = 4,
    MayDescription = 'May',
    May = 5,
    JuneDescription = 'June',
    June = 6,
    JulyDescription = 'July',
    July = 7,
    AugustDescription = 'August',
    August = 8,
    SeptemberDescription = 'September',
    September = 9,
    OctoberDescription = 'October',
    October = 10,
    NovemberDescription = 'November',
    November = 11,
    DecemberDescription = 'December',
    December = 12,
}
