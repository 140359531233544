import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    TableContainer,
    TableRow,
    Table,
    TableBody,
    TableCell,
    Tooltip,
    TableHead,
    FormControlLabel,
    Checkbox,
    Modal,
    Typography,
    FormHelperText,
    TextField,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { RichTextEditor } from '@mantine/rte';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { gotoPreviousMenu } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import {
    getMeansOfEngagements,
    setReasonForVisit,
    saveEngagementDraft,
    setEngagementDraft,
    toggleShowSummaryNoteModal,
    checkIsOnboardedAndGetReason,
    getReasonForVisit,
} from '../../../store/documentEngagement.slice';
import { EngagementDraft, ReasonForVisit } from '../../../Models/DocumentEngagements/EngagementDraft.model';
import classes from '../Styles';
import { phoneOptions, roleEnum, summaryNoteType, summaryNoteTypeList } from '../../../utils/assessments';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { copyObject } from '../../../utils/common';
import { MeansOfEngagementEnum } from '../../../Enum/MeansOfEngagementEnum';
import { StatusEnum } from '../../../Enum/StatusEnum';
import moment from 'moment';
import AlertDialog from '../../AlertDialog/AlertDialog';

const summaryNoteFormSchema = Yup.object().shape({
    MeansOfEngagement: Yup.string()
        .required('Select means of engagement.')
        .test('MeansOfEngagement', 'Select means of engagement.', (value) => {
            if (Boolean(value) && value !== '0' && parseInt(value) !== 0) {
                return Boolean(value);
            } else return false;
        }),
    ReasonForVisit: Yup.object()
        .nullable()
        .shape({
            Id: Yup.string().nullable().required('Select reason.'),
            Name: Yup.string(),
        })
        .required('Select reason.'),
    VisitedTime: Yup.string().nullable().required('Select visited time.'),
    Contacted: Yup.boolean().nullable().required('Select contacted.'),
});

const SummaryNotes = () => {
    const [newNote, onChangeNote] = useState('');
    const dispatch = useDispatch();
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const { selectedRole } = useSelector((state: RootState) => state.assessment);
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const { meansOfEngagements, reasonForVisits, engagementDraft, showSummaryNoteModal, isSaving, isFetching } = useSelector(
        (state: RootState) => state.documentEngagement
    );
    const [isMemberOnboarded, setIsMemberOnboarded] = useState<boolean>(false);
    const [lastReasonForVisit, setLastReasonForVisit] = useState<string>();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const isInHra = selectedRole === roleEnum.HRA;
    const isInFuhfum = memberBasicInfo.IsInFuhFum;
    const onboardedDesc = 'Is onboarded';
    const transitionalCareDesc = 'Transitional Care';
    const facilityVisitDesc = 'Facility Visit';

    useEffect(() => {
        dispatch(getMeansOfEngagements());
        if (!isInFuhfum) {
            dispatch(
                checkIsOnboardedAndGetReason(engagementDraft.MemberId, engagementDraft.Id, (data: ReasonForVisit[], isOnboared: boolean) => {
                    setIsMemberOnboarded(isOnboared);
                    if (isInHra && !Boolean(engagementDraft.ReasonForVisit)) {
                        const hraReason = data.find((d) => d.Name === roleEnum.HRA);
                        if (Boolean(hraReason)) {
                            dispatch(setEngagementDraft({ ...engagementDraft, ReasonForVisit: hraReason }));
                        }
                    } else if (!isOnboared && !Boolean(engagementDraft.ReasonForVisit)) {
                        const reasonForVisit = data.find((d) => d.Name === onboardedDesc);
                        if (Boolean(reasonForVisit)) {
                            dispatch(setEngagementDraft({ ...engagementDraft, ReasonForVisit: reasonForVisit }));
                        }
                    } else if (
                        (isOnboared && !Boolean(engagementDraft.ReasonForVisit)) ||
                        (isOnboared && Boolean(engagementDraft.ReasonForVisit) && engagementDraft.ReasonForVisit.Name !== onboardedDesc)
                    ) {
                        data = data.filter((r) => r.Name !== onboardedDesc);
                        dispatch(setReasonForVisit(data));
                    }
                })
            );
        } else {
            dispatch(
                getReasonForVisit((reasonForVisits) => {
                    if (!Boolean(engagementDraft.ReasonForVisit)) {
                        if (selectedRole === roleEnum.NURSE_PRACTITIONER || selectedRole === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST) {
                            const transitionalCareReason = reasonForVisits.find((d) => d.Name === transitionalCareDesc);
                            dispatch(setEngagementDraft({ ...engagementDraft, ReasonForVisit: transitionalCareReason }));
                        } else {
                            const facilityReason = reasonForVisits.find((d) => d.Name === facilityVisitDesc);
                            dispatch(setEngagementDraft({ ...engagementDraft, ReasonForVisit: facilityReason }));
                        }
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (isFetching && !isPageLoaded) {
            setIsPageLoaded(true);
        }
    }, [isFetching, isPageLoaded]);

    const handleSubmit = (values: EngagementDraft) => {
        let data = copyObject(values);
        data.Notes = '';
        values.SummaryNotes.filter((s) => s.IsConfirm).forEach((note) => {
            if (Boolean(note.Note)) {
                data.Notes += `<p> ${summaryNoteTypeList.find((s) => s.value === note.Type)?.name || '-'} : ${note.Note} </p>`;
            }
        });
        data.DocumentEngagementStatus = StatusEnum.Completed;
        dispatch(saveEngagementDraft(data, true));
    };

    const changeReasonForVisit = (id: string, values: EngagementDraft, setFieldValue: Function) => {
        const lastReasonForVisit = values.ReasonForVisit;
        const reasonForVisit = reasonForVisits.find((d) => d.Id === id);
        if (
            !isMemberOnboarded &&
            Boolean(reasonForVisit) &&
            reasonForVisit.Name !== onboardedDesc &&
            Boolean(lastReasonForVisit) &&
            lastReasonForVisit.Name === onboardedDesc
        ) {
            setShowAlert(true);
            setLastReasonForVisit(id);
        } else {
            if (!isMemberOnboarded && Boolean(reasonForVisit) && reasonForVisit.Name !== onboardedDesc) {
                setFieldValue('IsDefaultReasonForVisitChanged', true);
            } else {
                setFieldValue('IsDefaultReasonForVisitChanged', false);
            }
            setFieldValue('ReasonForVisit', reasonForVisit);
        }
    };

    const handleOnConfirm = (values: EngagementDraft, setFieldValue: Function) => {
        const reasonForVisit = reasonForVisits.find((d) => d.Id === lastReasonForVisit);
        if (Boolean(reasonForVisit)) {
            setFieldValue('ReasonForVisit', reasonForVisit);
            setFieldValue('IsDefaultReasonForVisitChanged', true);
            setShowAlert(false);
        }
    };

    const handleOnAddSummaryNote = (values) => {
        let data = copyObject(values);
        data.SummaryNotes = data.SummaryNotes || [];
        data.SummaryNotes.push({
            Note: newNote,
            Type: SummaryNoteTypeEnum.Custom,
            IsConfirm: true,
            Id: String(summaryNoteType.Custom.value),
            DisplayName: summaryNoteType.Custom.name,
        });

        dispatch(setEngagementDraft(data));
        handleOnCloseModal();
        onChangeNote('');
    };

    const handleOnIsConfirmCheckbox = (checked: boolean, index: number, values: EngagementDraft) => {
        let data = copyObject(values);
        data.SummaryNotes = data.SummaryNotes || [];
        data.SummaryNotes[index].IsConfirm = checked;
        dispatch(setEngagementDraft(data));
    };

    const handleOnToggleAllIsConfirmCheckbox = (checked: boolean, values: EngagementDraft) => {
        let data = copyObject(values);
        data.SummaryNotes = data.SummaryNotes || [];
        data.SummaryNotes.forEach((item) => {
            item.IsConfirm = checked;
        });
        dispatch(setEngagementDraft(data));
    };

    const handleOnCloseModal = () => dispatch(toggleShowSummaryNoteModal(false));

    const handleOnShowModal = () => {
        dispatch(toggleShowSummaryNoteModal(true));
        onChangeNote('');
    };

    const isERVisitType = (reasonForVisitId) => {
        if (Boolean(reasonForVisitId) && (String(reasonForVisitId) === '56695e83ad78ee0e48b04a66' || String(reasonForVisitId) === '56695e83ad78ee0e48b04a64')) {
            return true;
        } else {
            return false;
        }
    };

    const renderAddSummaryNote = (values) => {
        return (
            <Modal open={showSummaryNoteModal} onClose={handleOnCloseModal}>
                <Box sx={classes.modalPopupForm}>
                    <div className="mui-modal-header">
                        <Typography variant="h6" component="label">
                            Summary Notes
                        </Typography>
                    </div>
                    <div className="mui-modal-body">
                        <RichTextEditor id="summary-note-input" sx={classes.richTextBox} value={newNote} onChange={(val) => onChangeNote(val)} />
                    </div>
                    <div className="mui-modal-footer">
                        <Button
                            variant="contained"
                            onClick={() => handleOnAddSummaryNote(values)}
                            disabled={isSaving}
                            endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                        >
                            Save
                        </Button>
                        <Button size="small" type="button" onClick={handleOnCloseModal} variant="outlined" disabled={isSaving}>
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };

    return (
        <Formik sx={classes.form} initialValues={engagementDraft} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={summaryNoteFormSchema}>
            {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<EngagementDraft>) => (
                <Form>
                    <Grid container direction="column" spacing={2} className="content-panel">
                        <Grid item lg={12} md={12} sm={12}>
                            {/* <h6>Last Completed :-</h6> */}
                        </Grid>
                        <Grid item className="content-form">
                            {isFetching || !isPageLoaded ? (
                                <Box display="flex" justifyContent="center" className="loader-container">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                            error={
                                                (Boolean(errors?.MeansOfEngagement) ||
                                                    String(values.MeansOfEngagement) === '0' ||
                                                    parseInt(String(values.MeansOfEngagement)) === 0 ||
                                                    !Boolean(values.MeansOfEngagement) ||
                                                    values.MeansOfEngagement === null) &&
                                                Boolean(submitCount)
                                            }
                                        >
                                            <InputLabel id="means-of-enga-type-select" required>
                                                Means of Engagement
                                            </InputLabel>
                                            <Select
                                                labelId="means-of-enga-type-select"
                                                id="means-of-enga-type-select"
                                                value={
                                                    Boolean(values.MeansOfEngagement) &&
                                                    String(values.MeansOfEngagement) !== '0' &&
                                                    parseInt(String(values.MeansOfEngagement)) !== 0
                                                        ? values.MeansOfEngagement
                                                        : null
                                                }
                                                label="Means of Engagement*"
                                                name="MeansOfEngagement"
                                                onChange={handleChange}
                                            >
                                                {meansOfEngagements.map((type) => (
                                                    <MenuItem key={type.Id} value={String(type.Id)}>
                                                        {type.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {Boolean(submitCount) && (
                                                <FormHelperText>
                                                    {String(values.MeansOfEngagement) === '0' ||
                                                    parseInt(String(values.MeansOfEngagement)) === 0 ||
                                                    !Boolean(values.MeansOfEngagement) ||
                                                    values.MeansOfEngagement === null
                                                        ? 'Select means of engagement.'
                                                        : errors?.MeansOfEngagement}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        {String(values.MeansOfEngagement) === String(MeansOfEngagementEnum.Phone) ? (
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="contact-result-type-select">Contact Result</InputLabel>
                                                <Select
                                                    labelId="contact-result-type-select"
                                                    id="contact-result-type-select"
                                                    value={Boolean(values.ContactResult) ? values.ContactResult : ''}
                                                    label="Contact Result"
                                                    name="ContactResult"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="">Select Contact Result</MenuItem>
                                                    {phoneOptions.map((option) => (
                                                        <MenuItem key={option.Name} value={option.Name}>
                                                            {option.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                            error={(Boolean(errors?.ReasonForVisit) || Boolean(errors?.ReasonForVisit?.Id)) && Boolean(submitCount)}
                                        >
                                            <InputLabel id="reason-type-select" required>
                                                Reason for Engagement
                                            </InputLabel>
                                            <Select
                                                labelId="reason-type-select"
                                                id="reason-type-select"
                                                value={Boolean(values.ReasonForVisit?.Id) ? values.ReasonForVisit.Id : ''}
                                                label="Reason for Engagement*"
                                                name="ReasonForVisit.Id"
                                                onChange={(e) => changeReasonForVisit(e.target.value, values, setFieldValue)}
                                            >
                                                {reasonForVisits.map((type) => (
                                                    <MenuItem key={type.Id} value={String(type.Id)}>
                                                        {type.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {Boolean(submitCount) && (
                                                <FormHelperText>
                                                    {(Boolean(errors?.ReasonForVisit) || Boolean(errors?.ReasonForVisit?.Id)) && Boolean(submitCount)
                                                        ? 'Select reason.'
                                                        : ''}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        {isERVisitType(values.ReasonForVisit?.Id) ? (
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="avoided-er-type-select">Avoided ER</InputLabel>
                                                <Select
                                                    labelId="avoided-er-type-select"
                                                    displayEmpty
                                                    id="avoided-er-type-select"
                                                    value={String(values.AvoidedER)}
                                                    label="Avoided ER"
                                                    name="AvoidedER"
                                                    onChange={(e) =>
                                                        setFieldValue(e.target.name, Boolean(e.target.value) ? e.target.value === 'true' : e.target.value)
                                                    }
                                                >
                                                    <MenuItem value={String(true)}>Yes</MenuItem>
                                                    <MenuItem value={String(false)}>No</MenuItem>
                                                    <MenuItem value="">Not applicable</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip
                                            placement="top"
                                            title={`The engagement date must be set on the previous menu item "Set Appointment Info" by selecting an appointment. If there is no appointment for your assessment, one can be made from the previous tab as well.`}
                                        >
                                            <div>
                                                <DesktopDatePicker
                                                    disabled
                                                    label="Engagement Date"
                                                    value={values.VisitedTime ? new Date(values.VisitedTime) : null}
                                                    inputFormat="MM/dd/yyyy"
                                                    minDate={new Date('2017-01-01')}
                                                    onChange={(date) => {
                                                        if (moment(date).isValid() || !Boolean(date)) {
                                                            setFieldValue('AdmissionDate', date.toISOString());
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            size="small"
                                                            {...params}
                                                            error={Boolean(errors?.VisitedTime) && Boolean(submitCount)}
                                                            helperText={Boolean(submitCount) ? errors?.VisitedTime : ''}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth size="small" error={Boolean(errors?.Contacted) && Boolean(submitCount)}>
                                            <InputLabel id="contact-type-select" required>
                                                Contacted
                                            </InputLabel>
                                            <Select
                                                labelId="contact-type-select"
                                                id="contact-type-select"
                                                value={values.Contacted ? String(values.Contacted) : null}
                                                label="Contacted*"
                                                name="Contacted"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    if (e.target.value === String(true)) {
                                                        setFieldValue('ContactResult', phoneOptions[3].Name);
                                                    }
                                                }}
                                            >
                                                <MenuItem value={String(true)}>Yes</MenuItem>
                                                <MenuItem value={String(false)}>No</MenuItem>
                                            </Select>
                                            {Boolean(submitCount) && <FormHelperText>{errors?.Contacted}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction={'row'} justifyContent="space-between">
                                            <Typography variant="h6" component="label">
                                                Summary Notes
                                            </Typography>
                                            <Button
                                                type="button"
                                                variant="contained"
                                                size="small"
                                                onClick={handleOnShowModal}
                                                style={{ textTransform: 'none' }}
                                            >
                                                Add Summary Note
                                            </Button>
                                        </Stack>
                                        <TableContainer sx={classes.tableContainer}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width="55%">Notes</TableCell>
                                                        <TableCell width="20%">Source</TableCell>
                                                        <TableCell align="center">
                                                            <FormControlLabel
                                                                sx={classes.customCheckbox}
                                                                value="start"
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        onChange={(e) => handleOnToggleAllIsConfirmCheckbox(e.currentTarget.checked, values)}
                                                                        checked={values.SummaryNotes && values.SummaryNotes.every((s) => s.IsConfirm)}
                                                                        indeterminate={
                                                                            values.SummaryNotes &&
                                                                            !values.SummaryNotes.every((s) => s.IsConfirm) &&
                                                                            values.SummaryNotes.some((s) => s.IsConfirm)
                                                                        }
                                                                    />
                                                                }
                                                                label="Add to Document Engagement"
                                                                labelPlacement="start"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {!values.SummaryNotes || !values.SummaryNotes.length ? (
                                                        <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell colSpan={3} sx={{ paddingY: 3 }}>
                                                                <span>No notes to display.</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        values.SummaryNotes.map((note, index) => (
                                                            <TableRow
                                                                key={`summary-note-${note.Id}-${index}`}
                                                                hover
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>
                                                                    {summaryNoteType.ConsentForm.value === note.Type ? (
                                                                        <span>{note.Note}</span>
                                                                    ) : (
                                                                        <span dangerouslySetInnerHTML={{ __html: note.Note }} />
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <span>{summaryNoteTypeList.find((s) => s.value === note.Type)?.name || '-'}</span>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Checkbox
                                                                        size="small"
                                                                        checked={note.IsConfirm}
                                                                        onChange={(e) => handleOnIsConfirmCheckbox(e.currentTarget.checked, index, values)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {renderAddSummaryNote(values)}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {isFetching || !isPageLoaded ? null : (
                            <Grid item justifyContent="flex-end" className="content-footer">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    sx={{ mr: 3 }}
                                    disabled={isSaving}
                                    onClick={() => dispatch(gotoPreviousMenu())}
                                    startIcon={<ArrowBackIosIcon />}
                                >
                                    Previous
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={isSaving}
                                    endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                                >
                                    Next
                                </Button>
                            </Grid>
                        )}
                        <AlertDialog
                            open={showAlert}
                            title="Reason For Visit"
                            cancelText="Go Back"
                            okText="Confirm"
                            onConfirm={() => handleOnConfirm(values, setFieldValue)}
                            onClose={() => setShowAlert(false)}
                            showBottomCenter={true}
                            message="Are you sure you want to change the Reason for Engagement?"
                        />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default SummaryNotes;
