import { SdohSingleAnswerAdvanced } from './SdohSingleAnswerAdvanced.model';

export default interface IFamilyAndHome {
    FamilyMembersCurrentlyLiveWithYou: SdohSingleAnswerAdvanced | null;
    HousingSituationToday: SdohSingleAnswerAdvanced | null;
    WorriedAboutLosingYourHousing: SdohSingleAnswerAdvanced | null;
}

export class FamilyAndHome implements IFamilyAndHome {
    FamilyMembersCurrentlyLiveWithYou: SdohSingleAnswerAdvanced | null;
    HousingSituationToday: SdohSingleAnswerAdvanced | null;
    WorriedAboutLosingYourHousing: SdohSingleAnswerAdvanced | null;

    constructor() {
        this.FamilyMembersCurrentlyLiveWithYou = new SdohSingleAnswerAdvanced();
        this.HousingSituationToday = new SdohSingleAnswerAdvanced();
        this.WorriedAboutLosingYourHousing = new SdohSingleAnswerAdvanced();
    }
}
