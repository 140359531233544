import { SdohSingleAnswerAdvanced } from './SdohSingleAnswerAdvanced.model';
import { SdohMultiAnswer } from './SdohMultiAnswer.model';

export default interface IMoneyAndResources {
    HighestLevelOfSchoolYouFinished: SdohSingleAnswerAdvanced | null;
    CurrentWorkSituation: SdohSingleAnswerAdvanced | null;
    TotalIncome: SdohSingleAnswerAdvanced | null;
    NeededThingsUnableToGetInPastYear: SdohMultiAnswer | null;
    NotNeededThingsUnableToGetInPastYear: SdohMultiAnswer | null;
    Food: SdohSingleAnswerAdvanced | null;
    Clothing: SdohSingleAnswerAdvanced | null;
    Utilities: SdohSingleAnswerAdvanced | null;
    ChildCare: SdohSingleAnswerAdvanced | null;
    Phone: SdohSingleAnswerAdvanced | null;
    Medicine: SdohSingleAnswerAdvanced | null;
    OtherNeed: SdohSingleAnswerAdvanced | null;
}

export class MoneyAndResources implements IMoneyAndResources {
    HighestLevelOfSchoolYouFinished: SdohSingleAnswerAdvanced | null;
    CurrentWorkSituation: SdohSingleAnswerAdvanced | null;
    TotalIncome: SdohSingleAnswerAdvanced | null;
    NeededThingsUnableToGetInPastYear: SdohMultiAnswer | null;
    NotNeededThingsUnableToGetInPastYear: SdohMultiAnswer | null;
    Food: SdohSingleAnswerAdvanced | null;
    Clothing: SdohSingleAnswerAdvanced | null;
    Utilities: SdohSingleAnswerAdvanced | null;
    ChildCare: SdohSingleAnswerAdvanced | null;
    Phone: SdohSingleAnswerAdvanced | null;
    Medicine: SdohSingleAnswerAdvanced | null;
    OtherNeed: SdohSingleAnswerAdvanced | null;

    constructor() {
        this.HighestLevelOfSchoolYouFinished = new SdohSingleAnswerAdvanced();
        this.CurrentWorkSituation = new SdohSingleAnswerAdvanced();
        this.TotalIncome = new SdohSingleAnswerAdvanced();
        this.NeededThingsUnableToGetInPastYear = new SdohMultiAnswer();
        this.NotNeededThingsUnableToGetInPastYear = new SdohMultiAnswer();
        this.Food = new SdohSingleAnswerAdvanced();
        this.Clothing = new SdohSingleAnswerAdvanced();
        this.Utilities = new SdohSingleAnswerAdvanced();
        this.ChildCare = new SdohSingleAnswerAdvanced();
        this.Phone = new SdohSingleAnswerAdvanced();
        this.Medicine = new SdohSingleAnswerAdvanced();
        this.OtherNeed = new SdohSingleAnswerAdvanced();
    }
}
