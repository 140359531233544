import { Eventcalendar, MbscCalendarEvent, MbscPageLoadingEvent, Popup } from '@mobiscroll/react'; /* or import any other component */
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Autocomplete, Backdrop, CircularProgress, Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Provider } from '../../../Models/Provider/Provider.model';
import IProviderSelectOption from '../../../Models/Provider/ProviderSelectOption.model';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import { apiString } from '../../../utils/constants';
import { appointmentStatusMap } from '../../../utils/mappings';
import MessageDisplay from '../../Shared/MessageDisplay';
import EventContent from '../EventContent';
import EventLabel from '../EventLabel';
import ExportCalendarButton from '../Export/ExportCalendarButton';
import CalendarStatusLegend from '../Legends/CalendarStatusLegend';
import CalendarEventEditModal from '../Modals/CalendarEventEditModal';
import { cancelRecurringInstanceEvent, getSelectedProviderSchedules, mapEventData, updateEvent } from '../Services/CommonCalendarServices';
import EventTooltip from '../Tooltips/EventTooltip';
import { CalendarEventData } from '../types/CalendarEventData';
import { setShowMessage } from '../../../store/shared.slice';
import { useDispatch } from 'react-redux';
import { CreatedInstanceDto } from '../../../Models/Scheduling/CreatedInstanceDto.model';

interface IProviderLeaveCalendarProps {
    width?: string | number;
    preSelectedDate?: Date;
    preSelectedProviderId?: string;
}

const ProviderLeaveCalendar: React.FC<IProviderLeaveCalendarProps> = (props) => {
    const { width = 600, preSelectedDate = new Date(), preSelectedProviderId } = props;
    const dispatch = useDispatch();
    const calendarRef = React.useRef<Eventcalendar>(null);
    const [myEvents, setMyEvents] = useState([]);
    const [isEventEditorOpen, setIsEventEditorOpen] = useState<boolean>(false);
    const [mySelectedDate, setSelectedDate] = useState(preSelectedDate);
    const [hoveredEvent, setHoveredEvent] = useState<CalendarEventData | null>(null);
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventData | null>(null);
    const [isBackdropOpen, setIsBackdropOpen] = useState<boolean>(false);
    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [selectedProviderOptions, setSelectedProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const [monthBounds, setMonthBounds] = React.useState<{ firstDate: Date; lastDate: Date } | undefined>({ firstDate: new Date(), lastDate: new Date() });

    const handlePageLoading = (event: MbscPageLoadingEvent) => {
        const { firstDay, lastDay } = event;
        updateBounds(firstDay, lastDay);
        setIsPopupOpen(false);
    };

    const handleDeleteEventSuccess = (eventId: string) => {
        const newEvents = myEvents.filter((item) => item.Id !== eventId);
        setMyEvents(newEvents);
    };

    const handleUpdateEventSuccess = (event: Appointment | CalendarEventData) => {
        setMyEvents((currentEvents) => {
            return currentEvents.map((item) => {
                if (item.Id === event.Id) {
                    // TODO: clean this up so we don't have to map data every time we set the events
                    return mapEventData(event);
                } else {
                    return item;
                }
            });
        });
    };

    const handleCreateEventSuccess = (event: Appointment, rescheduledEvent?: CalendarEventData) => {
        setMyEvents((currentEvents) => {
            let newEvents = [...currentEvents, mapEventData(event)];
            if (rescheduledEvent !== undefined) {
                newEvents = newEvents.map((item) => {
                    if (item.Id === rescheduledEvent.id) {
                        rescheduledEvent.RescheduledAppointmentId = event.Id;
                        if (
                            rescheduledEvent.status !== appointmentStatusMap.Cancelled.value ||
                            rescheduledEvent.status !== appointmentStatusMap.NoCallNoShow.value
                        ) {
                            rescheduledEvent.status = appointmentStatusMap.Cancelled.value;
                        }
                        // TODO: clean this up so we don't have to map data every time we set the events
                        return mapEventData(rescheduledEvent);
                    } else {
                        return item;
                    }
                });
            }
            return newEvents;
        });
    };

    const handleSelectedDateChange = useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const handleCloseEventEditor = () => {
        setIsEventEditorOpen(false);
    };
    const handleEventClick = (args: MbscCalendarEvent) => {
        const eventInfo = args.event as CalendarEventData;
        setSelectedEvent(eventInfo);
        setHoveredEvent(eventInfo);
        setAnchor(args.domEvent.target.parentElement);
        setIsPopupOpen(true);
    };

    const handleEventEdit = (eventData: CalendarEventData) => {
        setSelectedEvent(eventData);
        setIsPopupOpen(false);
        setIsEventEditorOpen(true);
    };

    const handleEventCreate = (event: MbscCalendarEvent) => {
        const eventInfo = event.event as CalendarEventData;
        setSelectedEvent(eventInfo);
        setIsPopupOpen(false);
        setIsEventEditorOpen(true);

        // We will add the event manually, returning false makes mobiscroll not add a placeholder.
        return false;
    };

    const handleCreateRecurrenceInstance = async (createdInstanceDto: CreatedInstanceDto) => {
        const { CreatedInstanceAppointment, OriginalRecurrenceAppointment } = createdInstanceDto;
        const mappedNewEvent = mapEventData(CreatedInstanceAppointment);
        const mappedOriginalEvent = mapEventData(OriginalRecurrenceAppointment);
        let newEvents = [
            mappedNewEvent,
            ...myEvents.map((item) => {
                if (item.Id === mappedOriginalEvent.Id) {
                    return mappedOriginalEvent;
                } else {
                    return item;
                }
            }),
        ];
        setMyEvents(newEvents);
    };
    const handleCancelEventClick = async (eventData: CalendarEventData) => {
        setIsBackdropOpen(true);
        if (eventData.recurring) {
            const response = await cancelRecurringInstanceEvent(eventData.original as Appointment, new Date(eventData.start));
            handleCreateRecurrenceInstance(response.data);
            setIsBackdropOpen(false);
            return;
        }
        eventData.status = appointmentStatusMap.Cancelled.value;
        try {
            const response = await updateEvent(eventData);
            if (response.data) {
                handleUpdateEventSuccess(response.data);
            }
        } catch (error) {
            let errorMessage = 'An error in loading the calendar has occurred. A response was given but data was not received with it.';
            if (error?.response?.data) {
                errorMessage = `${error}. \n\nReason: ${error.response.data}`;
            }
            setMyEvents([]);
            dispatch(setShowMessage(true, errorMessage, 'error'));
        }
        setIsBackdropOpen(false);
        handleUpdateEventSuccess(eventData);
    };

    const handleRescheduleEventClick = (eventData: CalendarEventData) => {
        if (eventData.recurring) {
            console.error('Cannot reschedule recurring events');
            return;
        } else {
            setIsPopupOpen(false);
            if (eventData.status !== appointmentStatusMap.Cancelled.value || eventData.status !== appointmentStatusMap.NoCallNoShow.value) {
                eventData.status = appointmentStatusMap.Cancelled.value;
            }

            const newEvent: Partial<CalendarEventData> & { IsRescheduling: boolean } = {
                ...eventData,
                Id: undefined,
                status: appointmentStatusMap.Unconfirmed.value,
                AppointmentStatus: appointmentStatusMap.Unconfirmed.value,
                OriginalAppointmentId: eventData.Id,
                IsRescheduling: true,
            };

            setSelectedEvent(newEvent as any);
            setIsEventEditorOpen(true);
        }
    };

    const updateBounds = useCallback(
        (firstDay: Date, lastDay: Date) => {
            const newBoundFirstDateTime = firstDay.getTime();
            const newBoundLastDateTime = lastDay.getTime();
            const monthBoundFirstDateTime = monthBounds.firstDate.getTime();
            const monthBoundLastDateTime = monthBounds.lastDate.getTime();
            if (newBoundFirstDateTime !== monthBoundFirstDateTime || newBoundLastDateTime !== monthBoundLastDateTime) {
                setMonthBounds({ firstDate: firstDay, lastDate: lastDay });
            }
        },
        [monthBounds.firstDate, monthBounds.lastDate]
    );

    useEffect(() => {
        axios.get(`${apiString}/Provider/getactiveproviders`).then((response) => {
            if (response.status === 200) {
                const responseOptions = response.data.map((provider: Provider) => ({
                    ProviderId: provider.Id,
                    ProviderName: `${provider.LastName}, ${provider.FirstName}`,
                }));
                setProviderOptions(responseOptions);
            }
        });
    }, []);

    useEffect(() => {
        if (preSelectedProviderId && providerOptions?.length > 0) {
            const selectedProviders = providerOptions.filter((option) => option.ProviderId === preSelectedProviderId);
            setSelectedProviderOptions(selectedProviders);
        }
    }, [preSelectedProviderId, providerOptions]);

    useEffect(() => {
        const fetchEvents = async () => {
            setIsBackdropOpen(true);
            const startDateMinusOneYear = new Date(monthBounds.firstDate);
            startDateMinusOneYear.setFullYear(startDateMinusOneYear.getFullYear() - 1);
            const lastDatePlusOneMonth = new Date(monthBounds.lastDate);
            lastDatePlusOneMonth.setMonth(lastDatePlusOneMonth.getMonth() + 1);
            const response = await getSelectedProviderSchedules(
                startDateMinusOneYear,
                lastDatePlusOneMonth,
                selectedProviderOptions.map((provider) => provider.ProviderId)
            );
            setMyEvents(response.data.map(mapEventData));
            setIsBackdropOpen(false);
        };
        if (selectedProviderOptions.length > 0) {
            fetchEvents();
        }
    }, [selectedProviderOptions, monthBounds]);

    return (
        <Stack spacing={1} direction="row">
            <Stack spacing={2} direction="column" justifyContent="flex-start" style={{ paddingTop: 10, padding: 5, maxWidth: 300 }}>
                <ExportCalendarButton
                    initialStartDate={monthBounds.firstDate}
                    initialEndDate={monthBounds.lastDate}
                    getEventsForExport={(startDate: Date, endDate: Date) => calendarRef.current.getEvents(startDate, endDate) ?? []}
                />
                <MessageDisplay />
                {selectedEvent !== undefined && selectedEvent !== null ? (
                    <CalendarEventEditModal
                        open={isEventEditorOpen}
                        onSuccess={() => {
                            setIsEventEditorOpen(false);
                        }}
                        onUpdateEventSuccess={handleUpdateEventSuccess}
                        onCreateEventSuccess={handleCreateEventSuccess}
                        eventList={myEvents}
                        onRescheduleEvent={null}
                        setLoadingOverlay={(isLoading: boolean) => setIsBackdropOpen(isLoading)}
                        onClose={handleCloseEventEditor}
                        eventData={selectedEvent}
                        memberId={null}
                        canCreate={true}
                        allowAllProviderOption={true}
                    />
                ) : null}
                <Autocomplete
                    fullWidth
                    id="calendar-internal-provider-selection"
                    multiple={true}
                    options={providerOptions}
                    isOptionEqualToValue={(option, value) => {
                        return option.ProviderId === value.ProviderId;
                    }}
                    renderInput={(params) => <TextField {...params} label="Selected Providers" />}
                    value={selectedProviderOptions}
                    onChange={async (event: any, newValue: any) => {
                        setSelectedProviderOptions(newValue);
                    }}
                    inputValue={providerInputValue}
                    onInputChange={(event, value) => {
                        setProviderInputValue(value);
                    }}
                    getOptionLabel={(option: any) => option.ProviderName}
                />
                <CalendarStatusLegend direction="column" />
                <Backdrop open={isBackdropOpen} sx={{ zIndex: 1000 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack>

            <Eventcalendar
                ref={calendarRef}
                data={myEvents}
                clickToCreate={'double'}
                theme="material"
                height={880}
                themeVariant="light"
                width={width ?? '100%'}
                view={{ calendar: { labels: true } }}
                selectedDate={mySelectedDate}
                renderLabelContent={(event) => <EventLabel event={event} />}
                renderEventContent={(event) => <EventContent event={event} />}
                onPageLoading={handlePageLoading}
                onSelectedDateChange={handleSelectedDateChange}
                onEventClick={handleEventClick}
                onEventCreate={handleEventCreate}
            />
            <Popup
                display="anchored"
                isOpen={isPopupOpen}
                anchor={anchor}
                touchUi={false}
                showOverlay={false}
                contentPadding={false}
                closeOnOverlayClick={false}
                cssClass="md-tooltip"
                style={{ zIndex: 99 }}
            >
                <EventTooltip
                    hoveredEvent={hoveredEvent}
                    onMouseEnter={() => {}}
                    onMouseLeave={() => {}}
                    onEventCancelClick={handleCancelEventClick}
                    onDeleteEventSuccess={handleDeleteEventSuccess}
                    closePopup={() => setIsPopupOpen(false)}
                    onEventEditClick={(eventData: CalendarEventData) => {
                        handleEventEdit(eventData);
                        setIsPopupOpen(false);
                    }}
                    onEventRescheduleClick={handleRescheduleEventClick}
                />
            </Popup>
        </Stack>
    );
};

export default ProviderLeaveCalendar;
