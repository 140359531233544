import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Search } from '@mui/icons-material';

const MemberSearch = (props) => {
    // rowData={} allMembers={} setRowData={} memberSearchValue={} setMemberSearchValue={}

    function executeSearch(event: any) {
        if (!event.key || event.key === 'Enter') {
            var tmp = [];
            const searchValue = props.memberSearchValue.toLowerCase();
            for (const member of props.allMembers) {
                var DOB = '';
                if (member.DOB) {
                    const tmpDOB = new Date(member.DOB);
                    DOB = tmpDOB.toLocaleDateString('en-US');
                    DOB = DOB.replace(/\D/g, '');
                }

                let searchValueOnlyDigits = searchValue.replace(/\D/g, '');

                if (
                    (member.LastName && member.LastName.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.FirstName && member.FirstName.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.RiskScore && member.RiskScore.toString().toLowerCase().indexOf(searchValue) > -1) ||
                    (member.DOB && DOB === searchValue.replace(/\D/g, '')) ||
                    (member.HealthPlan && member.HealthPlan.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.LastSeen && member.LastSeen.toString().toLowerCase().indexOf(searchValue) > -1) ||
                    (member.Address1 && member.Address1.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.City && member.City.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.State && member.State.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.ZipCode && member.ZipCode.toString().toLowerCase().indexOf(searchValue) > -1) ||
                    (member.Status && member.Status.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.MobilePhone && searchValueOnlyDigits && member.MobilePhone.replace(/\D/g, '').indexOf(searchValueOnlyDigits) > -1) ||
                    (member.AhcccsId && member.AhcccsId.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.TabletId && member.TabletId.indexOf(searchValue) > -1)
                ) {
                    tmp.push(member);
                }
            }
            props.setRowData(tmp);
        }
    }

    return (
        <FormControl style={{ minWidth: '300px' }}>
            <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                value={props.memberSearchValue}
                onChange={(e) => {
                    props.setMemberSearchValue(e.target.value as string);
                }}
                onKeyUp={(event: any) => {
                    executeSearch(event);
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="Search" onClick={executeSearch} edge="end">
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
                label="Search"
            />
        </FormControl>
    );
};

export default MemberSearch;
