import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { RichTextEditor } from '@mantine/rte';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { getSubjectiveAssessmentById, gotoNextMenu, gotoPreviousMenu, saveSubjectiveAssessment } from '../../../store/assessment.slice';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';

const classes = {
    title: {
        color: '#0063a7',
        marginTop: '10px',
    },
    richTextBox: {
        '& .ql-editor': {
            minHeight: '250px',
        },
    },
};

const SubjectiveAssessmentComponent: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [memberStates, setMemberStates] = useState('');
    const { subjectiveAssessment, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    useEffect(() => {
        if (Boolean(engagementDraft.SubjectiveAssessmentId)) {
            dispatch(getSubjectiveAssessmentById(engagementDraft.SubjectiveAssessmentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setMemberStates(subjectiveAssessment.MemberStates);
    }, [subjectiveAssessment.MemberStates]);

    const handleOnBlur = () => {
        dispatch(saveSubjectiveAssessment({ ...subjectiveAssessment, MemberId: memberId, MemberStates: memberStates }));
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveSubjectiveAssessment({ ...subjectiveAssessment, Status: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Grid item className="content-form">
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item className="content-form">
                        <Typography variant="h6" sx={classes.title}>
                            Member States...
                        </Typography>

                        <RichTextEditor
                            sx={classes.richTextBox}
                            value={memberStates}
                            onChange={(value) => setMemberStates(value)}
                            onBlur={handleOnBlur}
                            formats={['bold', 'italic', 'underline']}
                            controls={[['bold', 'italic', 'underline']]}
                        />
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={handleOnClickSave}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.SubjectiveAssessmentId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Subjective Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    message="I confirm and acknowledge that Subjective assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Subjective Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    message="Are you sure to proceed without completing Subjective assessment?"
                />
            )}
        </Grid>
    );
};

export default SubjectiveAssessmentComponent;
