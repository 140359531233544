import { Grid, Typography } from '@mui/material';
import React from 'react';
import { IResourcesPageProps } from './interfaces/IResourcesPageProps';
import ResourcesTable from './ResourcesTable';

const ResourcesPage: React.FC<IResourcesPageProps> = (props: IResourcesPageProps) => {
    const { showTitle, selection } = props;
    return (
        <Grid container>
            <Grid lg={12} md={12} sm={12} xs={12} hidden={!!!showTitle}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                    Resources
                </Typography>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12}>
                <ResourcesTable selection={selection} />
            </Grid>
        </Grid>
    );
};

export default ResourcesPage;
