import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Grid, Paper, Typography } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import {
    getBpMembers,
    getAdultHealthMembers,
    getBmiMembers,
    getBreastCancerScreeningMembers,
    getHgbA1CMembers,
    getDiabeticEyeExamMembers,
    getMedicationReconciliationMembers,
    getDepressionScreeningMembers,
    getDepressionFollowUpMembers,
    getLipidForBHDXMembers,
    getHgbA1CForBHDXMembers,
} from './HedisMetricService';
import HedisMetricsMembers from '../../Models/HedisMetrics/HedisMetricsMembersDto.model';
import { TimePeriodFilterEnum } from '../../Enum/TimePeriodFilterEnum';
import { useHedisMetricsContext } from '../../hooks/hedisMetricsContext';
import FilterOptions from './FilterOptions';
import HedisDataGrid from './HedisDataGrid';
import '../Widgets/Widgets.css';

const HedisMemberDetail = (props: any) => {
    const history = useNavigate();
    const {
        pop,
        setPop,
        team,
        setTeam,
        filter,
        setFilter,
        fDate,
        setFDate,
        tDate,
        setTDate,
        includePastOpenStatus,
        setIncludePastOpenStatus,
        showInActiveTeam,
        setShowInActiveTeam,

        setAdultHealthPopulation,
        setAdultHealthTeam,
        setAdultHealthDateFilter,
        setAdultHealthFromDate,
        setAdultHealthToDate,
        setAdultHealthIncludePastOpenStatus,
        setAdultHealthShowInActiveTeam,

        setBpPopulation,
        setBpTeam,
        setBpDateFilter,
        setBpFromDate,
        setBpToDate,
        setBpIncludePastOpenStatus,
        setBpShowInActiveTeam,

        setBmiPopulation,
        setBmiTeam,
        setBmiDateFilter,
        setBmiFromDate,
        setBmiToDate,
        setBmiIncludePastOpenStatus,
        setBmiShowInActiveTeam,

        setBreastCancerScreeningPopulation,
        setBreastCancerScreeningTeam,
        setBreastCancerScreeningDateFilter,
        setBreastCancerScreeningFromDate,
        setBreastCancerScreeningToDate,
        setBreastCancerScreeningIncludePastOpenStatus,
        setBreastCancerScreeningShowInActiveTeam,

        setHgba1cPopulation,
        setHgba1cTeam,
        setHgba1cDateFilter,
        setHgba1cFromDate,
        setHgba1cToDate,
        setHgba1cIncludePastOpenStatus,
        setHgba1cShowInActiveTeam,

        setDiabeticEyeExamPopulation,
        setDiabeticEyeExamTeam,
        setDiabeticEyeExamDateFilter,
        setDiabeticEyeExamFromDate,
        setDiabeticEyeExamToDate,
        setDiabeticEyeExamIncludePastOpenStatus,
        setDiabeticEyeExamShowInActiveTeam,

        setMedicationReconciliationPopulation,
        setMedicationReconciliationTeam,
        setMedicationReconciliationDateFilter,
        setMedicationReconciliationFromDate,
        setMedicationReconciliationToDate,
        setMedicationReconciliationIncludePastOpenStatus,
        setMedicationReconciliationShowInActiveTeam,

        setDepressionScreeningPopulation,
        setDepressionScreeningTeam,
        setDepressionScreeningDateFilter,
        setDepressionScreeningFromDate,
        setDepressionScreeningToDate,
        setDepressionScreeningIncludePastOpenStatus,
        setDepressionScreeningShowInActiveTeam,

        setDepressionFollowUpPopulation,
        setDepressionFollowUpTeam,
        setDepressionFollowUpDateFilter,
        setDepressionFollowUpFromDate,
        setDepressionFollowUpToDate,
        setDepressionFollowUpIncludePastOpenStatus,
        setDepressionFollowUpShowInActiveTeam,

        setLipidForBhDxPopulation,
        setLipidForBhDxTeam,
        setLipidForBhDxDateFilter,
        setLipidForBhDxFromDate,
        setLipidForBhDxToDate,
        setLipidForBhDxIncludePastOpenStatus,
        setLipidForBhDxShowInActiveTeam,

        setHgbA1cForBhDxPopulation,
        setHgbA1cForBhDxTeam,
        setHgbA1cForBhDxDateFilter,
        setHgbA1cForBhDxFromDate,
        setHgbA1cForBhDxToDate,
        setHgbA1cForBhDxIncludePastOpenStatus,
        setHgbA1cForBhDxShowInActiveTeam,
    } = useHedisMetricsContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
    const [members, setMembers] = useState<HedisMetricsMembers>();
    const [headerName, setHeaderName] = useState<string>();
    const params = useParams();
    const hedisType = params?.type;

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
    }, [isFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (filter !== TimePeriodFilterEnum.Custom || (Boolean(fDate) && Boolean(tDate))) {
            const changeFilter = async () => {
                setIsMembersLoading(false);
                let data = null;
                let start = fDate !== null ? new Date(fDate).toDateString() : null;
                let end = tDate !== null ? new Date(tDate).toDateString() : null;
                switch (hedisType) {
                    case 'BP':
                        setHeaderName('BP Members');
                        data = await getBpMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setBpPopulation(pop);
                        setBpTeam(team);
                        setBpDateFilter(filter);
                        setBpFromDate(fDate);
                        setBpToDate(tDate);
                        setBpIncludePastOpenStatus(includePastOpenStatus);
                        setBpShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'ProviderVisits':
                        setHeaderName('Adult Health Members');
                        data = await getAdultHealthMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setAdultHealthPopulation(pop);
                        setAdultHealthTeam(team);
                        setAdultHealthDateFilter(filter);
                        setAdultHealthFromDate(fDate);
                        setAdultHealthToDate(tDate);
                        setAdultHealthIncludePastOpenStatus(includePastOpenStatus);
                        setAdultHealthShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'BMI':
                        setHeaderName('BMI Members');
                        data = await getBmiMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setBmiPopulation(pop);
                        setBmiTeam(team);
                        setBmiDateFilter(filter);
                        setBmiFromDate(fDate);
                        setBmiToDate(tDate);
                        setBmiIncludePastOpenStatus(includePastOpenStatus);
                        setBmiShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'BreastCancerScreening':
                        setHeaderName('Breast Cancer Screening Members');
                        data = await getBreastCancerScreeningMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setBreastCancerScreeningPopulation(pop);
                        setBreastCancerScreeningTeam(team);
                        setBreastCancerScreeningDateFilter(filter);
                        setBreastCancerScreeningFromDate(fDate);
                        setBreastCancerScreeningToDate(tDate);
                        setBreastCancerScreeningIncludePastOpenStatus(includePastOpenStatus);
                        setBreastCancerScreeningShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'HgbA1C':
                        setHeaderName('HgbA1C Members');
                        data = await getHgbA1CMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setHgba1cPopulation(pop);
                        setHgba1cTeam(team);
                        setHgba1cDateFilter(filter);
                        setHgba1cFromDate(fDate);
                        setHgba1cToDate(tDate);
                        setHgba1cIncludePastOpenStatus(includePastOpenStatus);
                        setHgba1cShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'DiabeticEyeExam':
                        setHeaderName('Diabetic Eye Exam Members');
                        data = await getDiabeticEyeExamMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setDiabeticEyeExamPopulation(pop);
                        setDiabeticEyeExamTeam(team);
                        setDiabeticEyeExamDateFilter(filter);
                        setDiabeticEyeExamFromDate(fDate);
                        setDiabeticEyeExamToDate(tDate);
                        setDiabeticEyeExamIncludePastOpenStatus(includePastOpenStatus);
                        setDiabeticEyeExamShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'MedicationReconciliation':
                        setHeaderName('Medication Reconciliation Post Discharge Members');
                        data = await getMedicationReconciliationMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setMedicationReconciliationPopulation(pop);
                        setMedicationReconciliationTeam(team);
                        setMedicationReconciliationDateFilter(filter);
                        setMedicationReconciliationFromDate(fDate);
                        setMedicationReconciliationToDate(tDate);
                        setMedicationReconciliationIncludePastOpenStatus(includePastOpenStatus);
                        setMedicationReconciliationShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'DepressionScreening':
                        setHeaderName('Depression Screening Members');
                        data = await getDepressionScreeningMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setDepressionScreeningPopulation(pop);
                        setDepressionScreeningTeam(team);
                        setDepressionScreeningDateFilter(filter);
                        setDepressionScreeningFromDate(fDate);
                        setDepressionScreeningToDate(tDate);
                        setDepressionScreeningIncludePastOpenStatus(includePastOpenStatus);
                        setDepressionScreeningShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'DepressionFollowUp':
                        setHeaderName('Depression Followup Members');
                        data = await getDepressionFollowUpMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setDepressionFollowUpPopulation(pop);
                        setDepressionFollowUpTeam(team);
                        setDepressionFollowUpDateFilter(filter);
                        setDepressionFollowUpFromDate(fDate);
                        setDepressionFollowUpToDate(tDate);
                        setDepressionFollowUpIncludePastOpenStatus(includePastOpenStatus);
                        setDepressionFollowUpShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'LipidForBhDx':
                        setHeaderName('Lipid for BH DX Members');
                        data = await getLipidForBHDXMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setLipidForBhDxPopulation(pop);
                        setLipidForBhDxTeam(team);
                        setLipidForBhDxDateFilter(filter);
                        setLipidForBhDxFromDate(fDate);
                        setLipidForBhDxToDate(tDate);
                        setLipidForBhDxIncludePastOpenStatus(includePastOpenStatus);
                        setLipidForBhDxShowInActiveTeam(showInActiveTeam);
                        break;
                    case 'HgbA1cForBhDx':
                        setHeaderName('HgbA1C for BH DX Members');
                        data = await getHgbA1CForBHDXMembers(pop, team, filter, start, end, includePastOpenStatus, showInActiveTeam);
                        setHgbA1cForBhDxPopulation(pop);
                        setHgbA1cForBhDxTeam(team);
                        setHgbA1cForBhDxDateFilter(filter);
                        setHgbA1cForBhDxFromDate(fDate);
                        setHgbA1cForBhDxToDate(tDate);
                        setHgbA1cForBhDxIncludePastOpenStatus(includePastOpenStatus);
                        setHgbA1cForBhDxShowInActiveTeam(showInActiveTeam);
                        break;
                }
                setMembers(data);
                setIsFetched(true);
                setIsMembersLoading(true);
            };
            if (pop !== null) changeFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pop, hedisType, team, filter, fDate, tDate, includePastOpenStatus]);

    const navigateToGraph = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    return (
        <Paper elevation={5}>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                <Grid item xs={12} paddingBottom={1} display={'flex'} alignItems={'center'}>
                    <span onClick={navigateToGraph}>
                        <ArrowBackIcon className="pointer" style={{ color: 'black', marginRight: 2 }} titleAccess="Back" />
                    </span>
                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                        {headerName}
                    </Typography>
                </Grid>
                <FilterOptions
                    selectedTeam={team}
                    setSelectedTeam={setTeam}
                    dateFilter={filter}
                    setDateFilter={setFilter}
                    fromDate={fDate}
                    setFromDate={setFDate}
                    toDate={tDate}
                    setToDate={setTDate}
                    population={pop}
                    setPopulation={setPop}
                    includePastOpenStatusMembers={includePastOpenStatus}
                    setIncludePastOpenStatusMembers={setIncludePastOpenStatus}
                    isDetailPage={true}
                    hedisType={hedisType}
                    showInActiveTeams={showInActiveTeam}
                    setShowInActiveTeams={setShowInActiveTeam}
                />
            </Grid>
            <hr />
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                    {!isMembersLoading ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {(() => {
                                switch (hedisType) {
                                    case 'BP':
                                        return (
                                            <>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Controlled ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Controlled ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Documented ({members.OtherMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.OtherMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'ProviderVisits':
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Visits Completed ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            No Visits ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'BMI':
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            BMI Completed ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            No BMI Documented ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'BreastCancerScreening':
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Screened ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Screened ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'HgbA1C':
                                        return (
                                            <>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Controlled ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Controlled ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Documented ({members.OtherMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.OtherMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'DiabeticEyeExam':
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Screened ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Screened ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'MedicationReconciliation':
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Documented ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid
                                                            memberList={members.CriteriaMetMembers}
                                                            hedisType="MedicationReconciliation"
                                                        ></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Documented ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid
                                                            memberList={members.CriteriaNotMetMembers}
                                                            hedisType="MedicationReconciliation"
                                                        ></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'DepressionScreening':
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Screened ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Screened ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'DepressionFollowUp':
                                        return (
                                            <>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Documented ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers} hedisType="DepressionFollowUp"></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Documented ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid
                                                            memberList={members.CriteriaNotMetMembers}
                                                            hedisType="DepressionFollowUp"
                                                        ></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'LipidForBhDx':
                                        return (
                                            <>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Controlled ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Controlled ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Documented ({members.OtherMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.OtherMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                    case 'HgbA1cForBhDx':
                                        return (
                                            <>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Controlled ({members.CriteriaMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Controlled ({members.CriteriaNotMetMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.CriteriaNotMetMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12} textAlign="center">
                                                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                                            Not Documented ({members.OtherMembers.length})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                                        <HedisDataGrid memberList={members.OtherMembers}></HedisDataGrid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        );
                                }
                            })()}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default HedisMemberDetail;
