import BaseEntity from '../BaseEntity.model';

export default interface IPhone extends BaseEntity {
    Note: string;
    Number: string;
    Textable: boolean;
    IsPrimary: boolean;
}

export class Phone implements IPhone {
    Id: string;
    Note: string;
    Number: string;
    Textable: boolean;
    IsPrimary: boolean;

    constructor() {
        this.Id = '';
        this.Note = '';
        this.Number = '';
        this.Textable = false;
        this.IsPrimary = false;
    }
}
