import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface ISubjectiveAssessment extends BaseEntity {
    MemberId: string;
    MemberName: string;
    MemberStates: string;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
    Status: StatusEnum;
}

export class SubjectiveAssessment implements ISubjectiveAssessment {
    Id: string | null;
    MemberId: string;
    MemberName: string;
    MemberStates: string;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
    Status: StatusEnum;

    constructor() {
        this.Id = null;
        this.MemberId = '';
        this.MemberName = '';
        this.MemberStates = '';
        this.Status = StatusEnum.InProgress;
    }
}
