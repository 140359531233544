import BaseEntity from '../BaseEntity.model';

export default interface IOnboarderSettings extends BaseEntity {
    DefaultCallsGoal: number | null;
    DefaultListLength: number | null;
    OnboarderId?: string;
}

export class OnboarderSettings implements IOnboarderSettings {
    Id: string;
    DefaultCallsGoal: number | null;
    DefaultListLength: number | null;
    OnboarderId?: string;

    constructor() {
        this.Id = '';
        this.DefaultCallsGoal = 100;
        this.DefaultListLength = 10;
    }
}
