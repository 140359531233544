import { Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';

import Signature from './Signature';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import phoneFormat from '../../../utils/phoneFormat';
import { setDigitalSignatureForm } from '../../../store/consentSlice.slice';

const GuardianOrCaregiverInput = (props: any) => {
    const dispatch = useDispatch();
    const { digitalSignatureForm } = useSelector((state: RootState) => state.consentSlice);
    return (
        <>
            <Stack spacing={2} my={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={digitalSignatureForm.GuardianOrCareGiverType === 'guardian'}
                            onChange={() =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        GuardianOrCareGiverType: 'guardian',
                                    })
                                )
                            }
                        />
                    }
                    label={
                        props.isSpanish ? (
                            <Typography>
                                Consentimiento del Custodio o Tutor Legal, Familiar Próximo, o Abogado del Paciente{' '}
                                <u>en caso de que el paciente no pueda firmar</u>
                            </Typography>
                        ) : (
                            <Typography>
                                Consent of Legal Guardian, Patient Advocate or Nearest Relative <u>if patient is unable to sign</u>
                            </Typography>
                        )
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={digitalSignatureForm.GuardianOrCareGiverType === 'caregiver'}
                            onChange={() =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        GuardianOrCareGiverType: 'caregiver',
                                    })
                                )
                            }
                        />
                    }
                    label={
                        props.isSpanish ? (
                            <Typography>
                                Consentimiento del cuidador <u>en caso de que el paciente no pueda firmar</u>
                            </Typography>
                        ) : (
                            <Typography>
                                Consent of Caregiver <u>if patient is unable to sign</u>
                            </Typography>
                        )
                    }
                />
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        variant="standard"
                        value={digitalSignatureForm.GuardianOrCaregiverName || ''}
                        onChange={(e) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    GuardianOrCaregiverName: e.target.value,
                                })
                            )
                        }
                        label={
                            props.isSpanish
                                ? 'Nombre del Custodio o Tutor Legal, Familiar Próximo, Abogado del Paciente, u Otro'
                                : `Name of Legal Guardian, Patient Advocate, Nearest Relative or Other`
                        }
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        variant="standard"
                        value={digitalSignatureForm.GuardianOrCaregiverRelationship || ''}
                        onChange={(e) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    GuardianOrCaregiverRelationship: e.target.value,
                                })
                            )
                        }
                        label={props.isSpanish ? 'Relación con el paciente' : `Relationship`}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        variant="standard"
                        value={phoneFormat(digitalSignatureForm.GuardianOrCaregiverTelephone) || ''}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    GuardianOrCaregiverTelephone: value,
                                })
                            );
                        }}
                        label={props.isSpanish ? 'Teléfono' : `Telephone`}
                        sx={{ width: '100%' }}
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        variant="standard"
                        value={digitalSignatureForm.GuardianOrCaregiverAddress || ''}
                        onChange={(e) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    GuardianOrCaregiverAddress: e.target.value,
                                })
                            )
                        }
                        label={props.isSpanish ? 'Dirección' : `Address`}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField variant="standard" value={`${moment().format('MM-DD-YYYY')}`} label={props.isSpanish ? 'Fecha' : `Date`} fullWidth />
                </Grid>
            </Grid>
            <Signature
                isSpanish={props.isSpanish}
                buttonLabel={props.isSpanish ? 'Firma del anteriormente mencionado' : 'Signature of the above'}
                isOpen={props.openGuardianOrCaregiver}
                setIsOpen={props.setOpenGuardianOrCaregiver}
                signature={props.guardianOrCareGiver}
                setSignature={(signature: string) => props.setGuardianOrCareGiver(signature)}
            />
            <Signature
                isSpanish={props.isSpanish}
                buttonLabel={props.isSpanish ? 'Firma del Testigo' : 'Signature of Witness'}
                isOpen={props.openGuardianOrCareGiverWitness}
                setIsOpen={props.setOpenGuardianOrCareGiverWitness}
                signature={props.guardianOrCareGiverWitness}
                setSignature={(signature: string) => props.setGuardianOrCareGiverWitness(signature)}
            />
        </>
    );
};

export default GuardianOrCaregiverInput;
