import React from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';

import { ExpenseCategoryEnum } from '../../../Enum/ExpenseCategoryEnum';
import RentExpenses from './RentExpenses';
import UtilitiesExpenses from './UtilitiesExpenses';
import FoodExpenses from './FoodExpenses';
import MedicineExpenses from './MedicineExpenses';
import FamilyExpenses from './FamilyExpenses';
import HouseHoldExpenses from './HouseHoldExpenses';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

const ExpensesForm = () => {
    const [tabValue, setTabValue] = React.useState(ExpenseCategoryEnum.Rent);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Typography variant="h6" marginTop={2}>
                Expenses (Monthly)
            </Typography>
            <Box sx={{ paddingX: 2 }}>
                <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                    <Tab label="Rent" value={ExpenseCategoryEnum.Rent} />
                    <Tab label="Utilities" value={ExpenseCategoryEnum.Utilities} />
                    <Tab label="Food" value={ExpenseCategoryEnum.Food} />
                    <Tab label="Medicines" value={ExpenseCategoryEnum.Medicines} />
                    <Tab label="Family/Children Expenses" value={ExpenseCategoryEnum.FamilyOrChildren} />
                    <Tab label="Household Expenses" value={ExpenseCategoryEnum.Household} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={ExpenseCategoryEnum.Rent}>
                <RentExpenses />
            </TabPanel>
            <TabPanel value={tabValue} index={ExpenseCategoryEnum.Utilities}>
                <UtilitiesExpenses />
            </TabPanel>
            <TabPanel value={tabValue} index={ExpenseCategoryEnum.Food}>
                <FoodExpenses />
            </TabPanel>
            <TabPanel value={tabValue} index={ExpenseCategoryEnum.Medicines}>
                <MedicineExpenses />
            </TabPanel>
            <TabPanel value={tabValue} index={ExpenseCategoryEnum.FamilyOrChildren}>
                <FamilyExpenses />
            </TabPanel>
            <TabPanel value={tabValue} index={ExpenseCategoryEnum.Household}>
                <HouseHoldExpenses />
            </TabPanel>
        </>
    );
};

export default ExpensesForm;
