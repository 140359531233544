import { DateTimePicker } from '@catalytic-health-partners/common-components';
import { useField } from 'formik';
import React from 'react';
import { IFormikTextFieldProps } from './interfaces/IFormikTextFieldProps';

const FormikDesktopDateTimePicker: React.FC<IFormikTextFieldProps> = ({ ...props }) => {
    const [field] = useField(props);


    return (
        <DateTimePicker
            showDaysOutsideCurrentMonth
            closeOnSelect={false}
            disableHighlightToday
            timeSteps={{ minutes: 15 }}
            {...field}
            {...props}
            sx={{ width: '100%' }}
        />
    );
};

export default FormikDesktopDateTimePicker;
