import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { localDateFormat } from '../../../utils/timeFormat';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import phoneFormat from '../../../utils/phoneFormat';
import { setMemberDetailFilter, fetchMemberDetailReport, exportMemberDetailsReport, setPageNumber } from '../../../store/memberDetailsReport.slice';
import { isDefaultDate } from '../../../utils/common';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { fetchPopulationList } from '../../../store/adminMembers.slice';

const MemberDetailsReport = () => {
    const { memberDetailReport, memberDetailFilter, pageNumber, pageTotal } = useSelector((state: RootState) => state.memberDetailsReport);
    const { downLoading } = useSelector((state: RootState) => state.shared);
    const dispatch = useDispatch();

    const memberStatus = [
        { name: 'All', filter: '-1' },
        { name: 'Active & Undetermined', filter: MemberStatusEnum.Active.toString() + ',' + MemberStatusEnum.Undetermined.toString() },
        { name: 'Undetermined', filter: MemberStatusEnum.Undetermined.toString() },
        { name: 'Active', filter: MemberStatusEnum.Active.toString() + ',' + MemberStatusEnum.UncompensatedCare.toString() },
        { name: 'Disenrolled From Health Plan', filter: MemberStatusEnum.DisenrolledFromHealthPlan.toString() },
        { name: 'Refused To Continue Services', filter: MemberStatusEnum.RefusedToContinueServices.toString() },
        { name: 'Unreachable', filter: MemberStatusEnum.Unreachable.toString() },
        { name: 'Deceased', filter: MemberStatusEnum.Deceased.toString() },
        { name: 'Disenrolled From CHP', filter: MemberStatusEnum.DisenrolledFromCHP.toString() },
        { name: 'Refused To Enroll', filter: MemberStatusEnum.RefusedToEnroll.toString() },
        { name: 'FUH-Und', filter: MemberStatusEnum.FUHUnd.toString() },
        { name: 'FUHFUM Close', filter: MemberStatusEnum.FUHFUMClose.toString() },
        { name: 'FUH Closed Billable', filter: MemberStatusEnum.FUHClosedBillable.toString() },
        { name: 'FUH Closed Unbillable', filter: MemberStatusEnum.FUHClosedUnbillable.toString() },
    ];

    const columns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'MemberId',
            headerName: 'MemberId',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 120,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'Population',
            headerName: 'Population',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'Address1',
            headerName: 'Address',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'State',
            headerName: 'State',
            minWidth: 90,
            flex: 1,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'TabletId',
            headerName: 'Tablet #',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'TeamName',
            headerName: 'Primary Provider',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'CCTeam',
            headerName: 'CCTeam',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'CLTeam',
            headerName: 'CLTeam',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'NPTeam',
            headerName: 'NPTeam',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'BHTeam',
            headerName: 'BHTeam',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'CHWTeam',
            headerName: 'CHWTeam',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'PrimaryPhone',
            headerName: 'Primary Phone',
            minWidth: 180,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.PrimaryPhone === null || phoneFormat(params.row.PrimaryPhone) === null ? 'n/a' : phoneFormat(params.row.PrimaryPhone)}`,
        },
        {
            field: 'SecondaryPhone',
            headerName: 'SecondaryPhone',
            minWidth: 180,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.SecondaryPhone === null || phoneFormat(params.row.SecondaryPhone) === null ? 'n/a' : phoneFormat(params.row.SecondaryPhone)}`,
        },
    ];

    const [pageSize, setPageSize] = useState(15);
    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 72 : 74) + ''));
    };
    useEffect(() => {
        handleWindowResize();
        dispatch(fetchPopulationList());
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container>
            <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Member Details Report
                </Typography>
            </Box>
            {<Loading message={'Loading...'} />}
            <Grid item xs={12}>
                <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                    <FormControl className="form-control-200">
                        <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={memberDetailFilter}
                            label="Filter By"
                            onChange={(e) => {
                                dispatch(setMemberDetailFilter(e.target.value));
                                dispatch(fetchMemberDetailReport(e.target.value));
                            }}
                        >
                            <MenuItem disabled value={'-2'}>
                                Select Status
                            </MenuItem>
                            {memberStatus.map((status, index) => {
                                return (
                                    <MenuItem key={index} value={status.filter}>
                                        {status.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => dispatch(exportMemberDetailsReport(memberDetailFilter))}
                        variant="contained"
                        color="success"
                        disabled={downLoading}
                    >
                        {downLoading ? 'Downloading..' : 'Export'}
                    </Button>
                </Stack>
            </Grid>
            <Grid item style={{ width: '100%' }}>
                <DataGrid
                    onPageChange={(newPage) => {
                        dispatch(setPageNumber(newPage));
                        dispatch(fetchMemberDetailReport(memberDetailFilter));
                    }}
                    rowCount={pageTotal}
                    rows={memberDetailReport}
                    columns={columns}
                    autoHeight
                    page={pageNumber}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5]}
                    paginationMode="server"
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.Id}
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MemberDetailsReport;
