import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, Chip, Snackbar, Alert, Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';

import { RootState } from '../../../reducers';
import { gotoPreviousMenu } from '../../../store/assessment.slice';
import {
    getCarePlanByMemberId,
    getReasonForDelete,
    getRoles,
    updateLastReviewedBy,
    resetCarePlanError,
    hasBeenReviewedRecently,
} from '../../../store/carePlan.slice';
import { getActiveProviders } from '../../../store/provider.slice';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import GoalList from './GoalList';
import PlanList from './PlanList';
import { SummaryNote } from '../../../Models/DocumentEngagements/SummaryNote.model';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { copyObject } from '../../../utils/common';
import SchedulePlanComponent from './SchedulePlan';
import BarrierList from './BarrierList';
import StrengthList from './StrengthList';
import MetricList from './MetricList';
import NextStepList from './NextStepList';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { assessmentStatusEnum } from '../../../utils/assessments';
import { CarePlanEnum } from '../../../Enum/CarePlanEnum';
import SignatureModal from './SignatureModal';
import MemberReview from './MemberReview';
import { CarePlanSignatureRequest } from '../../../Models/CarePlans/CarePlanSignatureRequest';

const CarePlans: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);

    const { isFetching, error, integratedCarePlan, showSignatureModal } = useSelector((state: RootState) => state.carePlan);
    const { engagementDraft, isSaving } = useSelector((state: RootState) => state.documentEngagement);
    const [isReviewed, setIsReviewed] = useState<boolean>(engagementDraft.IsCarePlanReviewed);

    const isUpdate =
        [
            ...integratedCarePlan?.Goals,
            ...integratedCarePlan?.Plans,
            ...integratedCarePlan?.Barriers,
            ...integratedCarePlan?.Metrics,
            ...integratedCarePlan?.NextSteps,
            ...integratedCarePlan?.Strengths,
            ...integratedCarePlan?.SchedulePlans,
        ].some((s) => s.IsSelected) || false;

    useEffect(() => {
        dispatch(getCarePlanByMemberId(memberId));
        dispatch(getReasonForDelete());
        dispatch(getRoles());
        dispatch(getActiveProviders(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reviewDetail = Boolean(integratedCarePlan?.ReviewHistory?.length) ? integratedCarePlan?.ReviewHistory[0] : null;
    const reviewDetailMember = Boolean(integratedCarePlan?.ReviewHistoryMember?.length) ? integratedCarePlan?.ReviewHistoryMember[0] : null;

    useEffect(() => {
        const providerReviewed = dispatch(hasBeenReviewedRecently(reviewDetail?.LastReviewedOn, reviewDetail));
        const memberReviewed = dispatch(hasBeenReviewedRecently(reviewDetailMember?.LastReviewedOn, reviewDetail));
        if (providerReviewed && memberReviewed) {
            setIsReviewed(true);
        }
    }, [dispatch, reviewDetail, reviewDetailMember]);
    // console.log('isReviewed: ', isReviewed);
    // console.log('isUpdate: ', isUpdate);

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        let data = copyObject(engagementDraft);
        data.SummaryNotes =
            data.SummaryNotes?.filter(
                (s) =>
                    s.Type !== SummaryNoteTypeEnum.Goal &&
                    s.Type !== SummaryNoteTypeEnum.Plan &&
                    s.Type !== SummaryNoteTypeEnum.Strength &&
                    s.Type !== SummaryNoteTypeEnum.Barrier &&
                    s.Type !== SummaryNoteTypeEnum.Metric &&
                    s.Type !== SummaryNoteTypeEnum.NextStep &&
                    s.Type !== SummaryNoteTypeEnum.SchedulePlan
            ) || [];

        integratedCarePlan.Goals.filter((g) => g.IsSelected).forEach((goal) => {
            const summaryNote = {
                Id: goal.Id,
                CptCode: '',
                Zcode: '',
                Note: goal.Status === CarePlanEnum.Achieved ? `Achieved Goal : ${goal.Name}` : goal.Name,
                Type: SummaryNoteTypeEnum.Goal,
                IsConfirm: false,
                ReferenceIds: [],
                OrderBy: 2,
                DisplayName: 'Goal',
                CategoryGroupId: '',
            } as SummaryNote;
            data.SummaryNotes.push(summaryNote);
        });

        integratedCarePlan.Strengths.filter((g) => g.IsSelected).forEach((strength) => {
            const summaryNote = {
                Id: strength.Id,
                CptCode: '',
                Zcode: '',
                Note: strength.Name,
                Type: SummaryNoteTypeEnum.Strength,
                IsConfirm: false,
                ReferenceIds: [],
                OrderBy: 20,
                DisplayName: 'Strength',
                CategoryGroupId: '',
            } as SummaryNote;
            data.SummaryNotes.push(summaryNote);
        });

        integratedCarePlan.Plans.filter((g) => g.IsSelected).forEach((plan) => {
            const summaryNote = {
                Id: plan.Id,
                CptCode: '',
                Zcode: '',
                Note: plan.Status === CarePlanEnum.Achieved ? `Achieved Plan : ${plan.Name}` : plan.Name,
                Type: SummaryNoteTypeEnum.Plan,
                IsConfirm: false,
                ReferenceIds: [],
                OrderBy: 13,
                DisplayName: 'Plan',
                CategoryGroupId: '',
            } as SummaryNote;
            data.SummaryNotes.push(summaryNote);
        });

        integratedCarePlan.Barriers.filter((g) => g.IsSelected).forEach((barrier) => {
            const summaryNote = {
                Id: barrier.Id,
                CptCode: '',
                Zcode: '',
                Note: barrier.Name,
                Type: SummaryNoteTypeEnum.Barrier,
                IsConfirm: false,
                ReferenceIds: [],
                OrderBy: 14,
                DisplayName: 'Barrier',
                CategoryGroupId: '',
            } as SummaryNote;
            data.SummaryNotes.push(summaryNote);
        });

        integratedCarePlan.Metrics.filter((g) => g.IsSelected).forEach((plan) => {
            const summaryNote = {
                Id: plan.Id,
                CptCode: '',
                Zcode: '',
                Note: plan.Name,
                Type: SummaryNoteTypeEnum.Metric,
                IsConfirm: false,
                ReferenceIds: [],
                OrderBy: 15,
                DisplayName: 'Metric',
                CategoryGroupId: '',
            } as SummaryNote;
            data.SummaryNotes.push(summaryNote);
        });

        integratedCarePlan.NextSteps.filter((g) => g.IsSelected).forEach((nextStep) => {
            const summaryNote = {
                Id: nextStep.Id,
                CptCode: '',
                Zcode: '',
                Note: nextStep.Name,
                Type: SummaryNoteTypeEnum.NextStep,
                IsConfirm: false,
                ReferenceIds: [],
                OrderBy: 16,
                DisplayName: 'NextStep',
                CategoryGroupId: '',
            } as SummaryNote;
            data.SummaryNotes.push(summaryNote);
        });

        integratedCarePlan.SchedulePlans.filter((g) => g.IsSelected).forEach((schedulePlan) => {
            var dayDescription = '';
            if (schedulePlan.TimeIntervalType === 1) {
                if (schedulePlan.TimeIntervalIndays === 1) dayDescription = 'Day';
                if (schedulePlan.TimeIntervalIndays > 1) dayDescription = 'Days';
            } else if (schedulePlan.TimeIntervalType === 2) {
                if (schedulePlan.TimeIntervalIndays === 7) dayDescription = 'Week';
                if (schedulePlan.TimeIntervalIndays > 7) dayDescription = 'Weeks';
            } else if (schedulePlan.TimeIntervalType === 3) {
                if (schedulePlan.TimeIntervalIndays === 1) dayDescription = 'Month';
                if (schedulePlan.TimeIntervalIndays > 1) dayDescription = 'Months';
            }
            const schedulePlanDetail = `Appointment with ${schedulePlan.Provider.Role} ${schedulePlan.Provider.Name} occurs every ${schedulePlan.TimeIntervalIndays} ${dayDescription}`;
            const summaryNote = {
                Id: schedulePlan.Id,
                CptCode: '',
                Zcode: '',
                Note: schedulePlanDetail,
                Type: SummaryNoteTypeEnum.SchedulePlan,
                IsConfirm: false,
                ReferenceIds: [],
                OrderBy: 17,
                DisplayName: 'Schedule Plan',
                CategoryGroupId: '',
            } as SummaryNote;
            data.SummaryNotes.push(summaryNote);
        });

        if (isWithoutComplete) {
            data.CarePlanStatus = assessmentStatusEnum.COMPLETED;
            data.IsCarePlanReviewed = isReviewed;
            if (isReviewed) {
                const request = new CarePlanSignatureRequest();
                request.CarePlanId = integratedCarePlan.Id;
                dispatch(updateLastReviewedBy(request));
            }
        }
        dispatch(saveEngagementDraft(data, true));
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    return (
        <>
            <SignatureModal isOpen={showSignatureModal} />
            <Grid container direction="column" spacing={2} className="content-panel">
                {isFetching ? (
                    <Grid item className="content-form">
                        <Box display="flex" justifyContent="center" className="loader-container">
                            <CircularProgress />
                        </Box>
                    </Grid>
                ) : (
                    <>
                        <Grid item className="content-form">
                            <div>
                                <strong>Last Reviewed By : </strong>
                                {Boolean(reviewDetail) ? (
                                    <Chip
                                        size="small"
                                        label={`${reviewDetail.LastReviewedBy?.Name} - ${moment(reviewDetail.LastReviewedOn).format('MM/DD/yyyy')}`}
                                    />
                                ) : (
                                    ' - '
                                )}
                            </div>
                            <Stack spacing={2} marginTop={2}>
                                <GoalList memberId={memberId} />
                                <SchedulePlanComponent memberId={memberId} />
                                <PlanList memberId={memberId} />
                                <BarrierList memberId={memberId} />
                                <StrengthList memberId={memberId} />
                                <MetricList memberId={memberId} />
                                <NextStepList memberId={memberId} />
                            </Stack>
                        </Grid>
                        <Grid item>
                            <MemberReview memberId={memberId} />
                        </Grid>

                        <Grid item justifyContent="flex-end" className="content-footer">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ mr: 1 }}
                                disabled={isSaving}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSaving}
                                endIcon={isSaving ? <CircularProgress size={18} /> : <ArrowForwardIosIcon />}
                                onClick={handleOnClickSave}
                            >
                                Next
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            {isUpdate ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Integrated Care Plan"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    showBottomCenter={true}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Integrated Care Plan is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Integrated Care Plan"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    showBottomCenter={true}
                    message="Are you sure to proceed without completing Integrated Care Plan?"
                />
            )}
            <Snackbar
                open={error?.Show}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => dispatch(resetCarePlanError())}
            >
                <Alert onClose={() => dispatch(resetCarePlanError())} severity="error" sx={{ width: '100%' }}>
                    {error?.Message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CarePlans;
