import { Box, FormGroup, Grid, Paper, Stack, Typography, Button, Popover, Tooltip, Divider, Modal, Link } from '@mui/material';
import moment from 'moment';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { hedisMetricsList, completedList, setShowPreviousCharts } from '../../../store/memberDemographicsDetails.slice';
import { getDefaultDiagnosis } from '../../../store/encounter.slice';
import { useState, useEffect } from 'react';
import phoneFormat from '../../../utils/phoneFormat';
import MemberPreviousChartsModal from '../MemberPreviousChartsModal/MemberPreviousChartsModal';
import { modalStyle } from '../../../utils/constants';
import { HedisMetricsVisit } from '../../../Models/HedisMetrics/HedisMetricsVisit.model';
import { ClaimSourceEnum } from '../../../Enum/ClaimSourceEnum';
import { HedisMetricStatusEnum } from '../../../Enum/HedisMetricStatusEnum';

const MemberInformation = (props) => {
    const { memberId } = props;
    const dispatch = useDispatch();
    const { chartDetails, previousMemberCharts } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const { memberDefaultDiagnosis } = useSelector((state: RootState) => state.encounter);
    const { memberHealthPlans } = useSelector((state: RootState) => state.memberDetail);
    const [diagnosisAnchorEl, setDiagnosisAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [showHedisDetail, setShowHedisDetail] = useState(false);
    const [hedisDetail, setHedisDetail] = useState<HedisMetricsVisit>(null);
    const [hedisType, setHedisType] = useState<string>('');

    const handleClickDiagnosis = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDiagnosisAnchorEl(event.currentTarget);
    };

    const handleCloseDiagnosis = () => {
        setDiagnosisAnchorEl(null);
    };

    const diagnosisOpen = Boolean(diagnosisAnchorEl);
    const diagnosisId = diagnosisOpen ? 'simple-popover' : undefined;

    const showStar = (team) => {
        if (!team) return false;
        if (team?.Id === chartDetails?.Team?.Id) return true;
        return false;
    };

    const fontColor = (metric) => {
        if (metric?.IsExpired) {
            return 'error.main';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.Completed || metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled) {
            return 'success.main';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
            return 'warning.main';
        } else {
            return 'rgba(0,0,0, 0.4)';
        }
    };

    const prepareHedisStatus = (metric) => {
        if (metric?.IsExpired) {
            return `Expired`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.Completed) {
            return `Completed`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled) {
            return `Completed And Controlled`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
            return `Completed And Not Controlled`;
        }
    };

    const handleViewPreviousCharts = () => {
        dispatch(setShowPreviousCharts(true));
    };

    useEffect(() => {
        dispatch(getDefaultDiagnosis(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prepareHedis = (metric: HedisMetricsVisit, hedisType: string) => {
        setHedisDetail(metric);
        setHedisType(hedisType);
        setShowHedisDetail(true);
    };

    const prepareHedisDetail = () => {
        if (Boolean(chartDetails?.HedisSummary)) {
            var hedisDetailList = { controlledList: [], notControlledList: [], notCompletedList: [] };
            hedisMetricsList.forEach((metric, index) => {
                let metricInfo = chartDetails.HedisSummary.find((h) => h.HedisMetricType === metric.type);
                let metricInfoCopy = {
                    ...metricInfo,
                    Label: metric.label,
                    Type: metric.type,
                    FontColor: fontColor(metricInfo),
                };
                if (
                    metricInfoCopy?.MetricStatus === HedisMetricStatusEnum.Completed ||
                    metricInfoCopy?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled
                ) {
                    hedisDetailList.controlledList.push(metricInfoCopy);
                } else if (metricInfoCopy?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
                    hedisDetailList.notControlledList.push(metricInfoCopy);
                } else {
                    hedisDetailList.notCompletedList.push(metricInfoCopy);
                }
            });
            return [
                {
                    Name: 'Completed And Not Controlled',
                    List: hedisDetailList.notControlledList,
                },
                {
                    Name: 'Not Completed',
                    List: hedisDetailList.notCompletedList,
                },
                {
                    Name: 'Completed And Controlled',
                    List: hedisDetailList.controlledList,
                },
            ].filter((h) => h.List.length);
        }
    };

    const hedisDetails = prepareHedisDetail();
    return (
        <>
            <MemberPreviousChartsModal />
            <Box sx={{ backgroundColor: '#ddd', p: 1 }}>
                <FormGroup sx={{ marginRight: -1 }}>
                    <Grid container spacing={1}>
                        <Paper square elevation={3} sx={{ p: 2, display: 'flex', width: '100%' }}>
                            <Grid item xs={3}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                    <Typography variant="body2">Last Seen:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.LastCHPVisit ? moment(chartDetails?.LastCHPVisit).format('MM/DD/yyyy') : '-'}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Risk Score:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.RiskScore}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Plan:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.PrimaryHealthPlan?.Name}
                                    </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Insurance:</Typography>
                                    <Grid container columnGap={2} justifyContent={'flex-end'}>
                                        {memberHealthPlans.map((plan, index) => {
                                            return (
                                                <Grid item display={'flex'} key={index}>
                                                    <Typography textAlign={'right'} fontStyle={'italic'} variant="body2" marginRight={1}>
                                                        {plan.Priority ? plan.Priority : '-'}
                                                    </Typography>
                                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                        {plan?.Name ? plan?.Name : '-'}
                                                    </Typography>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={3}>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CC:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CCTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CCTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        NP:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.NPTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.NPTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        BH:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.BHTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.BHTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CL:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CLTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CLTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2" marginRight={'auto'}>
                                        CHW:
                                    </Typography>
                                    <Typography fontWeight={700} variant="body2">
                                        {chartDetails?.CHWTeam?.Name || ''}
                                    </Typography>
                                    {showStar(chartDetails?.CHWTeam) && <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 0 }} />}
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={4}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                    <Typography variant="body2">Address:</Typography>
                                    <Stack justifyContent={'flex-end'} spacing={0}>
                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                            {`${chartDetails?.MainAddress?.Address1 ? chartDetails?.MainAddress?.Address1 : '-'}${
                                                chartDetails?.MainAddress?.Address2 ? ', ' + chartDetails?.MainAddress?.Address2 : ''
                                            } `}
                                        </Typography>
                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                            {`${chartDetails?.MainAddress?.City ? chartDetails?.MainAddress?.City : '-'}${
                                                chartDetails?.MainAddress?.State ? ', ' + chartDetails?.MainAddress?.State : ''
                                            } ${chartDetails?.MainAddress?.ZipCode ? chartDetails?.MainAddress?.ZipCode : ''}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {chartDetails.IsDifferMeetingAddress && (
                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                        <Typography variant="body2">Meeting Address:</Typography>
                                        <Stack justifyContent={'flex-end'} spacing={0}>
                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                {`${chartDetails?.MainMeetingAddress?.Address1 ? chartDetails?.MainMeetingAddress?.Address1 : '-'}${
                                                    chartDetails?.MainMeetingAddress?.Address2 ? ', ' + chartDetails?.MainMeetingAddress?.Address2 : ''
                                                } `}
                                            </Typography>
                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                {`${chartDetails?.MainMeetingAddress?.City ? chartDetails?.MainMeetingAddress?.City : '-'}${
                                                    chartDetails?.MainMeetingAddress?.State ? ', ' + chartDetails?.MainMeetingAddress?.State : ''
                                                } ${chartDetails?.MainMeetingAddress?.ZipCode ? chartDetails?.MainMeetingAddress?.ZipCode : ''}`}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                )}
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Phones:</Typography>
                                    <Grid container columnGap={2} justifyContent={'flex-end'}>
                                        {chartDetails?.Phones?.map((phone, index) => {
                                            return (
                                                <Grid key={index} item display={'flex'} color={phone.IsPrimary ? 'primary.main' : 'rgba(0,0,0,0.5)'}>
                                                    <Typography textAlign={'right'} variant="body2" marginRight={1}>
                                                        {phone.Textable ? 'Text -' : 'No Text -'}
                                                    </Typography>
                                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                        {phone.Number ? phoneFormat(phone.Number) : '-'}
                                                    </Typography>
                                                    {phone.IsPrimary && (
                                                        <Tooltip title="Primary Phone">
                                                            <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 1 }} />
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                    <Typography variant="body2">Primary Language:</Typography>
                                    <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                        {chartDetails?.PrimaryLanguage?.Name}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                            <Grid item xs={2}>
                                <Stack spacing={2}>
                                    <Button aria-describedby={diagnosisId} variant="outlined" onClick={handleClickDiagnosis} sx={{ width: '100%' }}>
                                        View Primary Diagnosis
                                    </Button>
                                    <Popover
                                        id={diagnosisId}
                                        open={diagnosisOpen}
                                        anchorEl={diagnosisAnchorEl}
                                        onClose={handleCloseDiagnosis}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{ style: { maxWidth: '400px' } }}
                                    >
                                        <Box sx={{ p: 2 }}>
                                            <Typography variant="body1" fontWeight={700}>
                                                Primary Diagnosis
                                            </Typography>
                                            <Typography variant="body2">
                                                {Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.FourthDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.ThirdDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.SecondDiagnosis?.Name
                                                    : Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis)
                                                    ? memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis?.Code +
                                                      ' - ' +
                                                      memberDefaultDiagnosis?.DefaultDiagnosis?.FirstDiagnosis?.Name
                                                    : '-'}
                                            </Typography>
                                        </Box>
                                    </Popover>
                                    <Button
                                        disabled={previousMemberCharts.length > 0 ? false : true}
                                        aria-describedby={diagnosisId}
                                        variant="outlined"
                                        onClick={handleViewPreviousCharts}
                                        sx={{ width: '100%' }}
                                    >
                                        View Previous Charts ({previousMemberCharts.length})
                                    </Button>
                                </Stack>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Stack direction="row" spacing={1} marginTop={1} justifyContent={'center'}>
                        {hedisDetails?.map((metric: any, index) => (
                            <Stack
                                direction="column"
                                key={`group-${index}`}
                                sx={{
                                    borderRight: index === hedisDetails.length - 1 ? 'none' : '2px solid #9E9E9E',
                                    pr: index === hedisDetails.length - 1 ? '0' : '10px',
                                    pl: index === 0 ? '0' : '10px',
                                }}
                            >
                                <Typography variant="body2" sx={{ whiteSpace: 'nowrap', textAlign: 'center', color: '#7c7c7c' }}>
                                    {metric.Name}
                                </Typography>
                                <Stack direction="row" columnGap={2} p={1} pl={0} flexWrap="wrap" rowGap={2} justifyContent="center">
                                    {metric.List.map((hedis, index) => {
                                        const color = hedis.FontColor;
                                        return Boolean(hedis.IsExpired) ? (
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    position: 'relative',
                                                    padding: '20px',
                                                    textAlign: 'center',
                                                    overflow: 'hidden',
                                                    minWidth: '200px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    (Boolean(hedis?.DateLastMet) || Boolean(hedis?.DateLastCompleted)) && prepareHedis(hedis, hedis.Label);
                                                }}
                                            >
                                                {/* Ribbon */}
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '8px',
                                                        left: '-22px',
                                                        backgroundColor: '#d32f2f',
                                                        color: 'white',
                                                        padding: '3px 20px',
                                                        transform: 'rotate(-45deg)',
                                                        zIndex: 1,
                                                        fontSize: '11px',
                                                        lineHeight: '1',
                                                    }}
                                                >
                                                    Expired
                                                </Typography>
                                                <Typography fontWeight={700} color={color} variant="body1" pb={0.5} sx={{ whiteSpace: 'nowrap' }}>
                                                    {hedis.Label}
                                                </Typography>
                                                <Typography color={color} variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                                    {Boolean(completedList.map((item) => item.Id).includes(hedis?.HedisMetricType)) ? (
                                                        <>
                                                            <Typography color={color} variant="body2">
                                                                Last Completed :{' '}
                                                                {hedis?.DateLastCompleted
                                                                    ? moment(hedis?.DateLastCompleted).format('MM/DD/yyyy')
                                                                    : hedis?.DateLastMet
                                                                    ? moment(hedis?.DateLastMet).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography color={color} variant="body2">
                                                                {hedis?.DateLastMet && !hedis?.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                                                                {hedis?.DateLastMet ? moment(hedis?.DateLastMet).format('MM/DD/yyyy') : '-'}
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Typography>
                                            </Paper>
                                        ) : (
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    textAlign: 'center',
                                                    p: 1.7,
                                                    borderLeftWidth: 4,
                                                    borderLeftColor: color,
                                                    borderLeftStyle: 'solid',
                                                    cursor: Boolean(hedis?.DateLastMet) || Boolean(hedis?.DateLastCompleted) ? 'pointer' : '',
                                                }}
                                                onClick={() => {
                                                    (Boolean(hedis?.DateLastMet) || Boolean(hedis?.DateLastCompleted)) && prepareHedis(hedis, hedis.Label);
                                                }}
                                            >
                                                <Typography fontWeight={700} color={color} variant="body1" pb={0.5} sx={{ whiteSpace: 'nowrap' }}>
                                                    {hedis.Label}
                                                </Typography>
                                                <Typography color={color} variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                                    {Boolean(completedList.map((item) => item.Id).includes(hedis?.HedisMetricType)) ? (
                                                        <>
                                                            <Typography color={color} variant="body2">
                                                                Last Completed :{' '}
                                                                {hedis?.DateLastCompleted
                                                                    ? moment(hedis?.DateLastCompleted).format('MM/DD/yyyy')
                                                                    : hedis?.DateLastMet
                                                                    ? moment(hedis?.DateLastMet).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography color={color} variant="body2">
                                                                {hedis?.DateLastMet && !hedis?.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                                                                {hedis?.DateLastMet ? moment(hedis?.DateLastMet).format('MM/DD/yyyy') : '-'}
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Typography>
                                            </Paper>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                    <Grid item xs={12}>
                        <Stack direction={'row'} spacing={2} sx={{ backgroundColor: 'white' }} justifyContent={'center'}>
                            <Typography variant="body2" fontWeight={'bold'} color={'error.main'}>
                                Expired
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'} color={'success.main'}>
                                Completed and Controlled
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'} color={'warning.main'}>
                                Completed and Not Controlled
                            </Typography>
                        </Stack>
                    </Grid>
                </FormGroup>
            </Box>
            <Modal
                open={showHedisDetail}
                onClose={() => {
                    setShowHedisDetail(false);
                }}
            >
                <Box sx={modalStyle} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            {hedisType}
                            <Typography variant="body1" sx={{ color: fontColor(hedisDetail), fontSize: 14 }}>
                                {prepareHedisStatus(hedisDetail)}
                            </Typography>
                        </Typography>
                        <Divider />
                    </Stack>
                    <Stack spacing={2} mt={2}>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                CPT Code :
                            </Typography>
                            <Typography>{Boolean(hedisDetail?.CPTcode) ? hedisDetail?.CPTcode : '-'}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            {Boolean(completedList.map((item) => item.Id).includes(hedisDetail?.HedisMetricType)) ? (
                                <>
                                    <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                        Last Completed :
                                    </Typography>
                                    <Typography>
                                        {Boolean(hedisDetail?.DateLastCompleted)
                                            ? moment(hedisDetail?.DateLastCompleted).format('MM/DD/yyyy')
                                            : Boolean(hedisDetail?.DateLastMet)
                                            ? moment(hedisDetail?.DateLastMet).format('MM/DD/yyyy')
                                            : '-'}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                        {hedisDetail?.DateLastMet && !hedisDetail.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                                    </Typography>
                                    <Typography>{Boolean(hedisDetail?.DateLastMet) ? moment(hedisDetail?.DateLastMet).format('MM/DD/yyyy') : '-'}</Typography>
                                </>
                            )}
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Claim Number :
                            </Typography>
                            <Typography>{Boolean(hedisDetail?.ClaimNumber) ? hedisDetail?.ClaimNumber : '-'}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Claim Source :
                            </Typography>
                            <Typography>
                                {Boolean(hedisDetail?.ClaimSourceName)
                                    ? hedisDetail.ClaimSource === ClaimSourceEnum.ChpClaims && hedisDetail.ProviderName
                                        ? `${hedisDetail.ClaimSourceName} (${hedisDetail.ProviderName})`
                                        : hedisDetail.ClaimSourceName
                                    : '-'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Notes :
                            </Typography>
                            <Typography flex={1}>{Boolean(hedisDetail?.Note) ? hedisDetail?.Note : '-'}</Typography>
                        </Stack>
                        {hedisDetail?.DocumentUri && (
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                    Document :
                                </Typography>
                                <Link target="_blank" underline="none" href={hedisDetail?.DocumentUri} sx={{ mt: '2px !important' }}>
                                    {hedisDetail?.DocumentName}
                                </Link>
                            </Stack>
                        )}
                        {hedisDetail?.ClaimSource === ClaimSourceEnum.HealthPlan && (
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                    Updated Based On Health Plan :
                                </Typography>
                                <Typography>
                                    {Boolean(hedisDetail?.DateUpdatedByHealthPlan) ? moment(hedisDetail?.DateUpdatedByHealthPlan).format('MM/DD/yyyy') : '-'}
                                </Typography>
                            </Stack>
                        )}
                        <Divider />
                        <Box>
                            <Stack direction="row" spacing={1} style={{ float: 'right' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setShowHedisDetail(false);
                                        setHedisDetail(null);
                                    }}
                                >
                                    Ok
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};
export default MemberInformation;
