import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    CallListTabs,
    activityOptions,
    addActivityNoteHandler,
    cancelAddActivityNoteHandler,
    closeAddActivityNoteHandler,
    setOnboarderNote,
    updateActivityNoteHandler,
} from '../../../store/onboardingWorkflow.slice';
import Authorization from '../../../utils/Authorization';

const AddActivityNoteModal = (props) => {
    const { onboarderNote, addActivityNoteIsOpen, callItem, confirmationCallItem, selectedCallListTab } = useSelector(
        (state: RootState) => state.onboardingWorkflowSlice
    );
    const auth = new Authorization();
    const dispatch = useDispatch();
    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    const item = isConfirmCall ? confirmationCallItem : callItem;

    const activityOptionsList = Object.keys(activityOptions)
        .map((key) => activityOptions[key])
        .filter((option) => option !== 'Skipped Call' && option !== 'Member Status Change' && option !== 'Demographic Research Request');

    const submitHandler = () => {
        let copy = { ...onboarderNote };
        copy.MemberId = item.MemberId;
        copy.UserId = auth.UserId;
        copy.UserName = auth.UserName;
        copy.Editable = true;
        if (onboarderNote.Id) {
            dispatch(updateActivityNoteHandler(item, copy, isConfirmCall));
        } else {
            dispatch(addActivityNoteHandler(item, copy, isConfirmCall));
        }
    };

    return (
        <Dialog open={addActivityNoteIsOpen} fullWidth maxWidth="sm" onClose={() => dispatch(closeAddActivityNoteHandler())}>
            <DialogTitle>Add Activity Note</DialogTitle>
            <DialogContent>
                <Stack spacing={2} paddingY={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Activity Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={activityOptionsList.find((option) => option === onboarderNote.NoteType) || ''}
                            label="Activity Type"
                            onChange={(e) => dispatch(setOnboarderNote({ ...onboarderNote, NoteType: e.target.value }))}
                        >
                            {activityOptionsList
                                .sort((a, b) => a.localeCompare(b))
                                .map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Note"
                        variant="outlined"
                        value={onboarderNote.Note}
                        multiline
                        rows={7}
                        onChange={(e) => dispatch(setOnboarderNote({ ...onboarderNote, Note: e.target.value }))}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" sx={{ minWidth: '120px' }} onClick={() => dispatch(cancelAddActivityNoteHandler())}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{ minWidth: '120px' }} onClick={submitHandler}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddActivityNoteModal;
