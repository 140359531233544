import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Input,
    InputLabel,
    Stack,
    TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    CallListTabs,
    activityOptions,
    addPhone,
    createNote,
    setFormPhone,
    setFormPhoneIsValid,
    setPhoneHelperText,
    setShowAddPhone,
} from '../../../store/onboardingWorkflow.slice';

import phoneFormat from '../../../utils/phoneFormat';
import Authorization from '../../../utils/Authorization';
import TextMaskCustom from '../../Shared/PhoneMask';

const AddPhoneModal = (props) => {
    const { callItem, confirmationCallItem, formPhone, formPhoneIsValid, phoneHelperText, selectedCallListTab, showAddPhone } = useSelector(
        (state: RootState) => state.onboardingWorkflowSlice
    );
    const auth = new Authorization();
    const dispatch = useDispatch();

    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    const item = isConfirmCall ? confirmationCallItem : callItem;

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let update = { ...formPhone };
        update.Number = event.target.value;
        dispatch(setFormPhone(update));
    };

    const saveLocalPhone = () => {
        if (formPhone.Number.length !== 14) {
            dispatch(setPhoneHelperText('Please enter a valid phone number'));
            dispatch(setFormPhoneIsValid(false));
            return;
        } else if (item.Phones?.some((phone) => phone.Number === formPhone.Number)) {
            dispatch(setPhoneHelperText('This phone number already exists'));
            dispatch(setFormPhoneIsValid(false));
            return;
        } else {
            dispatch(setFormPhoneIsValid(true));
        }
        const note = createNote(
            item.MemberId,
            activityOptions.NEW_NUMBER_ADDED,
            `A new number, ${phoneFormat(formPhone.Number)}, was added to the member's profile.`,
            auth
        );
        dispatch(addPhone(formPhone, note, item, isConfirmCall));
        dispatch(setShowAddPhone(false));
    };

    const renderPhoneBody = () => {
        return (
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} style={{ marginBottom: '16px' }}>
                    <FormGroup>
                        <FormControl variant="standard" style={{ marginRight: '16px' }}>
                            <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
                            <Input
                                value={formPhone.Number || ''}
                                onChange={handlePhoneChange}
                                name="textmask"
                                id="formatted-text-mask-input"
                                inputComponent={TextMaskCustom as any}
                                error={!formPhoneIsValid}
                                required
                            />
                            <FormHelperText error={!formPhoneIsValid} id="formatted-text-mask-input-helper-text">
                                {formPhoneIsValid ? '' : phoneHelperText}
                            </FormHelperText>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={formPhone.Textable} />}
                            onChange={(e: any) => {
                                dispatch(setFormPhone({ ...formPhone, Textable: e.target.checked }));
                            }}
                            label="Textable"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={formPhone.IsPrimary} />}
                            onChange={(e: any) => {
                                dispatch(setFormPhone({ ...formPhone, IsPrimary: e.target.checked }));
                            }}
                            label="Primary Phone"
                        />
                    </FormGroup>
                </Stack>
                <TextField
                    onChange={(e: any) => {
                        dispatch(setFormPhone({ ...formPhone, Note: e.target.value }));
                    }}
                    style={{ width: '100%' }}
                    id="outlined-multiline-static"
                    label="Note"
                    multiline
                    rows={3}
                    value={formPhone.Note || ''}
                />
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button className="button-120" variant="contained" onClick={() => saveLocalPhone()}>
                            Save
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                dispatch(setShowAddPhone(false));
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <Dialog
            open={showAddPhone}
            onClose={() => {
                dispatch(setShowAddPhone(false));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="md"
        >
            <DialogTitle id="modal-modal-title">Add Phone Number</DialogTitle>
            <DialogContent>{renderPhoneBody()}</DialogContent>
        </Dialog>
    );
};

export default AddPhoneModal;
