import {
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { parentUrl } from '../../../../utils/constants';
import { localDateFormat } from '../../../../utils/timeFormat';
import { apiString as API_URL } from '../../../../utils/constants';
import WidgetLoading from '../../Shared/WidgetLoading';
import { copyObject } from '../../../../utils/common';
import Authorization from '../../../../utils/Authorization';

const AppointmentsVsEncounters = () => {
    const authData = new Authorization();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const initialState = () => {
        return { Appointments: [], NumOfAppointments: 0, NumOfEncounters: 0 };
    };
    const [appointmentsVsEncounters, setAppointmentsVsEncounters] = useState(initialState());
    const [providerId, setProviderId] = useState(authData.UserId || '');
    const [timePeriod, setTimePeriod] = useState(2);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [showInActiveProvider, setShowInActiveProvider] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState(15);
    const copyProviders = copyObject(activeProviders);
    const filteredProviders = (showInActiveProvider ? copyProviders : copyProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const timePeriods = [
        { label: 'This Week', value: 1 },
        { label: 'Last 30 Days', value: 2 },
        { label: 'Last 60 Days', value: 3 },
    ];

    const columns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'AppointmentDateTime',
            headerName: 'Appointment Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${localDateFormat(new Date(params.row.AppointmentDateTime))}`,
        },
        {
            field: 'AppointmentTypeString',
            headerName: 'Appointment Type',
            flex: 1,
        },
        {
            field: 'StatusString',
            headerName: 'Appointment Status',
            flex: 1,
        },
        {
            field: 'ProviderName',
            headerName: 'Provider Name',
            flex: 1,
        },
    ];

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 100 : 105) + ''));
    };

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_URL}/encounter/getappointmentsvsencounterswidget?timePeriodFilter=${timePeriod}&providerId=${providerId}&includeInActiveProviders=${showInActiveProvider}`
                );
                setAppointmentsVsEncounters(response.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        };
        changeFilter();
    }, [timePeriod, providerId, showInActiveProvider]);

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProvider(isChecked);
        setProviderId(authData.UserId);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                        Appointments <Chip label={appointmentsVsEncounters.NumOfAppointments} color="error" /> vs{' '}
                        <Chip label={appointmentsVsEncounters.NumOfEncounters} color="success" /> Encounters
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">Appointments vs Encounters within selected days</Typography>
                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Green :
                                    </Typography>
                                    <Typography variant="caption">Appointment has corresponding encounter</Typography>
                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Red:
                                    </Typography>
                                    <Typography variant="caption">Appointments doesn't have encounter</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Stack direction={'row'} marginTop={2} alignItems="center">
                    <Grid item>
                        <FormControl className="form-control-200" sx={{ marginRight: 2 }}>
                            <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filteredProviders.find((el) => el.Id === providerId)?.Id || ''}
                                label="Provider"
                                size="small"
                                onChange={(e) => {
                                    setProviderId(e.target.value);
                                }}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                            >
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveProvider} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((provider) => {
                                    return (
                                        <MenuItem
                                            key={provider.Id}
                                            value={provider.Id}
                                            sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                        >
                                            {provider.LastName + ', ' + provider.FirstName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ToggleButtonGroup
                            value={timePeriod}
                            exclusive
                            size="small"
                            onChange={(event: React.MouseEvent<HTMLElement>, newPeriod: number | null) => setTimePeriod(newPeriod)}
                        >
                            {timePeriods.map((period) => {
                                return (
                                    <ToggleButton color="primary" key={period.value} value={period.value}>
                                        {period.label}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    </Grid>
                </Stack>

                <Grid item xs={12} marginTop={2} position="relative">
                    {isLoading && <WidgetLoading />}
                    <div style={{ width: '100%', height: '52vh' }}>
                        <DataGrid
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.MemberId}/scheduling`, '_blank');
                            }}
                            rows={appointmentsVsEncounters.Appointments}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            getRowClassName={(params) => {
                                return params.row.HasEncounter ? 'green' : 'red';
                            }}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                [`& .green`]: {
                                    color: '#1b5e20',
                                },
                                [`& .red`]: {
                                    color: '#b71c1c',
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default AppointmentsVsEncounters;
