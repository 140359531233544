import { Box, Button, Drawer, Grid, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getMemberEducationMaterials, setEducationMaterialIsOpen, setPageContent } from '../../../store/assignEducationMaterials.slice';

import AssignEducationMaterialsModal from './components/AssignEducationMaterialsModal';
import EducationMaterialPageContent from './components/EducationMaterialPageContent';

const AssignEducationMaterials = (props: any) => {
    const drawerWidth = '15vw';
    const isAssessment = Boolean(props.isAssessment);
    const { educationMaterialsAssignedToMember, educationMaterialIsOpen, pageContent } = useSelector((state: RootState) => state.assignEducationMaterials);
    const dispatch = useDispatch();

    const [isSelected, setIsSelected] = useState(null);

    const assignEducationMaterialHandler = () => {
        dispatch(setEducationMaterialIsOpen(true));
    };

    useEffect(() => {
        if (educationMaterialsAssignedToMember) {
            let startingPageContent = educationMaterialsAssignedToMember[0]?.EducationMaterial?.MaterialItems;
            dispatch(setPageContent(<EducationMaterialPageContent materialItems={startingPageContent} />));
            setIsSelected(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [educationMaterialsAssignedToMember]);

    useEffect(() => {
        if (props.memberId) {
            dispatch(getMemberEducationMaterials(props.memberId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.memberId]);
    return (
        <>
            <AssignEducationMaterialsModal
                educationMaterialsAssignedToMember={educationMaterialsAssignedToMember}
                open={educationMaterialIsOpen}
                memberId={props.memberId}
                isAssessment={isAssessment}
                onClose={() => dispatch(setEducationMaterialIsOpen(false))}
            />
            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={12}>
                    <Stack alignItems="flex-end">
                        <Button variant="outlined" onClick={assignEducationMaterialHandler}>
                            Assign Education Materials
                        </Button>
                    </Stack>
                </Grid>
                {educationMaterialsAssignedToMember.length === 0 && (
                    <Grid item xs={12}>
                        <Typography variant="h6">No Materials Assigned To Member</Typography>
                    </Grid>
                )}
                {educationMaterialsAssignedToMember.length > 0 && (
                    <Grid item display="flex" xs={12}>
                        <Drawer
                            variant="permanent"
                            anchor="left"
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,
                                    boxSizing: 'border-box',
                                    position: 'relative',
                                },
                            }}
                        >
                            <Box component={'div'}>
                                <List>
                                    {[...educationMaterialsAssignedToMember].map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                            <ListItemButton
                                                sx={{ paddingY: '12px' }}
                                                selected={isSelected === index}
                                                divider
                                                onClick={() => {
                                                    const array = item.EducationMaterial['MaterialItems'];
                                                    dispatch(setPageContent(<EducationMaterialPageContent materialItems={array} />));
                                                    setIsSelected(index);
                                                }}
                                            >
                                                <ListItemText
                                                    sx={{ '& .MuiListItemText-primary': { fontSize: '15px' } }}
                                                    primary={item.EducationMaterial?.Category}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Drawer>
                        <Box p={2} width={'100%'}>
                            <Grid container spacing={3}>
                                {pageContent}
                            </Grid>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default AssignEducationMaterials;
