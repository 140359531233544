import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../../utils/timeFormat';
import { isDefaultDate } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchOnboardingReportTable,
    fetchOnboardingExcelReport,
    setIsExporting,
    setPopulations,
    updatePopulations,
} from '../../../store/adminOnboarding.slice';
import { RootState } from '../../../reducers';

const OnboardingReport = (props: any) => {
    const dispatch = useDispatch();
    const { isExporting, isTableFetching, populations, reportRows } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const onboardingActivePopulationIds = populations.filter((p) => p.OnboardingActive).map((p) => p.Id);

    const columns: GridColDef[] = [
        {
            field: 'AHCCCSID',
            headerName: 'AHCCCSID',
            minWidth: 160,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) => {
                return `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`;
            },
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 160,
        },
        {
            field: 'PopulationName',
            headerName: 'Population Name',
            minWidth: 160,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 160,
        },
        {
            field: 'CallCount',
            headerName: 'Call Count',
            minWidth: 100,
        },
        {
            field: 'Address1',
            headerName: 'Address1',
            minWidth: 200,
        },
        {
            field: 'Address2',
            headerName: 'Address2',
            minWidth: 200,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 160,
        },
        {
            field: 'State',
            headerName: 'State',
            minWidth: 100,
        },
        {
            field: 'ZipCode',
            headerName: 'ZipCode',
            minWidth: 100,
        },
    ];

    const handleChange = (event: SelectChangeEvent<typeof populations>) => {
        const {
            target: { value },
        } = event;
        const newPopulations = populations.map((p) => {
            if (value.includes(p.Id)) {
                return { ...p, OnboardingActive: true };
            } else {
                return { ...p, OnboardingActive: false };
            }
        });
        dispatch(setPopulations(newPopulations));
        dispatch(updatePopulations(newPopulations));
    };

    useEffect(() => {
        dispatch(fetchOnboardingReportTable(onboardingActivePopulationIds));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, populations]);

    const [pageSize, setPageSize] = useState<number>(15);
    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 60 : 70) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doExportButtonClick = async () => {
        dispatch(setIsExporting(true));
        dispatch(fetchOnboardingExcelReport(onboardingActivePopulationIds));
    };

    return (
        <Card variant="outlined" style={{ minWidth: '400px', padding: '16px' }}>
            <CardHeader
                title="Population Onboarding Report"
                subheader="Quick Viewer"
                action={
                    <Button variant="contained" color="primary" disabled={isExporting || isTableFetching} onClick={doExportButtonClick}>
                        {!isExporting ? (
                            <GetAppIcon style={{ marginRight: '8px', color: 'white' }} />
                        ) : (
                            <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />
                        )}
                        <span style={{ color: 'white' }}>Export to Excel</span>
                    </Button>
                }
            />

            <CardContent>
                <Grid container alignItems="center" direction="row" spacing={2}>
                    <Grid item xs={12} textAlign={'left'}>
                        <FormControl sx={{ minWidth: '300px', display: 'flex' }}>
                            <InputLabel id="demo-simple-select-label">Population Name</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={onboardingActivePopulationIds}
                                onChange={handleChange}
                                input={<OutlinedInput label="Population Name" />}
                                MenuProps={MenuProps}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {(selected as string[]).map((value) => (
                                            <Chip key={value} label={populations.find((p) => p.Id === value)?.PopulationName} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {populations.map((population) => (
                                    <MenuItem key={population.Id} value={population.Id}>
                                        {population.PopulationName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', textAlign: 'left', height: '70vh' }}>
                            {isTableFetching ? (
                                <Stack justifyContent={'center'} alignItems={'center'}>
                                    <CircularProgress style={{ marginTop: '50px' }} size={30} color="primary" />
                                </Stack>
                            ) : (
                                <DataGrid
                                    rows={reportRows}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[15, 25, 100]}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OnboardingReport;
