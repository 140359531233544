import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useNavigate } from 'react-router';
import { Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

import Authorization from '../../../utils/Authorization';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchSocialWorkerRequestSummary, MenuProps } from '../WidgetService';
import SocialWorkerRequestSummary from '../../../Models/Widget/SocialWorkerRequestSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { RequestDayFilterEnum } from '../../../Enum/RequestDayFilterEnum';
import '../Widgets.css';

const dateFilterList = [
    { Id: 1, Value: 'Last 3 Months' },
    { Id: 2, Value: 'Last 6 Months' },
    { Id: 3, Value: 'Last 1 Year' },
    { Id: 4, Value: 'Custom' },
];

const dayFilterList = [
    { Id: 1, Value: RequestDayFilterEnum.Within15DaysDescription },
    { Id: 2, Value: RequestDayFilterEnum.Within30DaysDescription },
];

const SocialWorkerRequestSummaries = () => {
    const socialWorkerRole = 'Social Worker';
    const authData = new Authorization();
    const history = useNavigate();
    const {
        reqSelectedProvider,
        setReqSelectedProvider,
        reqDayFilter,
        setReqDayFilter,
        reqDateFilter,
        setReqDateFilter,
        reqFromDate,
        setReqFromDate,
        reqToDate,
        setReqToDate,
        loadedReqProvider,
        setLoadedReqProvider,
        requestShowInActiveProviders,
        setRequestShowInActiveProviders,
    } = useWidgetContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [requestSummary, setRequestSummary] = useState<SocialWorkerRequestSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const filteredProviders = (requestShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const socialWorkerProviders = filteredProviders.filter((p) => p.Role.RoleName === socialWorkerRole);

    const handleClick = (event) => {
        setShow(!show);
    };

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChart(
                    'Request Summary',
                    'Request Count',
                    'Request',
                    'Count',
                    [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                    [0],
                    'SocialWorkerRequest',
                    history
                )
            );
        }
        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history, setChartOptions]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (socialWorkerProviders.length) {
            const loggedInProviderId = authData.UserId;
            if (Boolean(loggedInProviderId) && !loadedReqProvider) {
                const providerInfo = socialWorkerProviders.find((t) => t.Id === loggedInProviderId);
                if (Boolean(providerInfo)) {
                    setReqSelectedProvider(loggedInProviderId);
                }
            }
            setLoadedReqProvider(true);
        }
    }, [setReqSelectedProvider, socialWorkerProviders, authData.UserId, loadedReqProvider, setLoadedReqProvider]);

    useEffect(() => {
        if (loadedReqProvider && (reqDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(reqFromDate) && Boolean(reqToDate)))) {
            const changeFilter = async () => {
                const data = await fetchSocialWorkerRequestSummary(
                    reqSelectedProvider,
                    reqDayFilter,
                    reqDateFilter,
                    Boolean(reqFromDate) && reqDateFilter === TimePeriodFilterEnum.Custom ? new Date(reqFromDate).toISOString() : '',
                    Boolean(reqToDate) && reqDateFilter === TimePeriodFilterEnum.Custom ? new Date(reqToDate).toISOString() : '',
                    requestShowInActiveProviders,
                    false
                );
                setRequestSummary(data);
            };
            setIsGraphShow(false);
            setChartOptions(
                configureStackedBarChart(
                    'Request Summary',
                    'Request Count',
                    'Request',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'SocialWorkerRequest',
                    history
                )
            );
            changeFilter();
        }

        if (reqDateFilter !== TimePeriodFilterEnum.Custom && Boolean(reqFromDate) && Boolean(reqToDate)) {
            setReqFromDate(null);
            setReqToDate(null);
        }
    }, [
        reqSelectedProvider,
        reqDayFilter,
        reqDateFilter,
        reqFromDate,
        reqToDate,
        setReqFromDate,
        setReqToDate,
        history,
        loadedReqProvider,
        requestShowInActiveProviders,
    ]);

    useEffect(() => {
        if (requestSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let completedValues = [];
            let notCompletedValues = [];

            let completedPercentage = requestSummary.TotalRequests > 0 ? (requestSummary.CompletedCount / requestSummary.TotalRequests) * 100 : 0;
            let d = {
                y: requestSummary.CompletedCount,
                name: Math.round(completedPercentage),
            };
            completedValues.push(d);

            let notCompletedPercentage = requestSummary.TotalRequests > 0 ? (requestSummary.NotCompletedCount / requestSummary.TotalRequests) * 100 : 0;
            let d1 = {
                y: requestSummary.NotCompletedCount,
                name: Math.round(notCompletedPercentage),
            };
            notCompletedValues.push(d1);
            xAxisValues.push('Requests');

            yAxisValues.push({ name: 'Completed', data: completedValues, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Not Completed', data: notCompletedValues, color: '#ff7d7d', showInLegend: true });

            if (requestSummary.CompletedCount === 0 && requestSummary.NotCompletedCount === 0) {
                setChartOptions(
                    configureStackedBarChart(
                        'Request Summary',
                        'Requests Count',
                        'Request',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'SocialWorkerRequest',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedBarChart('Request Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, 'SocialWorkerRequest', history)
                );
            }

            setIsGraphShow(true);
        }
    }, [requestSummary, history]);

    const changeFromDate = (date: any) => {
        setReqFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(reqToDate) || new Date(reqToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setReqToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setReqToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(reqFromDate) || new Date(reqFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setReqFromDate(startDate);
        }
    };

    const navigateToMembers = () => {
        history(`/provider/metricsummary/socialworkerrequestsummarydetail`);
    };

    const toggleInactiveHandler = (isChecked: boolean) => {
        setRequestShowInActiveProviders(isChecked);
        setReqSelectedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    Social Worker Request Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                15 Days :
                                            </Typography>
                                            <Typography variant="caption">Requests completed within 15 days</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                30 Days :
                                            </Typography>
                                            <Typography variant="caption">Requests completed within 30 days</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={reqSelectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setReqSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={requestShowInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {socialWorkerProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Completed Within</InputLabel>
                                <Select
                                    value={reqDayFilter}
                                    label="Completed Within"
                                    name="Completed Within"
                                    onChange={(e) => setReqDayFilter(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    {dayFilterList.map((day) => (
                                        <MenuItem key={day.Id} value={day.Id}>
                                            {day.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={reqDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setReqDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {reqDateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(reqFromDate) ? reqFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(reqToDate) ? reqToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default SocialWorkerRequestSummaries;
