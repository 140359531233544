import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { localDateFormat } from '../../../utils/timeFormat';
import { isDefaultDate } from '../../../utils/common';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import phoneFormat from '../../../utils/phoneFormat';
import { setTeamFilter, fetchTeamReport, exportTeamReport } from '../../../store/teamReport.slice';

const TeamReport = () => {
    const dispatch = useDispatch();
    const { teamData, teamFilter } = useSelector((state: RootState) => state.teamReport);
    const { downLoading } = useSelector((state: RootState) => state.shared);

    const teamFilterValues = [
        { value: '1', label: 'Members Not In Care Coordinator' },
        { value: '2', label: 'Members Not In Nurse Practitioner' },
        { value: '3', label: 'Members Not In Behavioral Health' },
        { value: '4', label: 'Members Not In Clinical Liaison' },
        { value: '5', label: 'Members Not In Community Health Worker' },
    ];

    useEffect(() => {
        dispatch(fetchTeamReport(teamFilter));
    }, [dispatch, teamFilter]);

    const columns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS Id',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 110,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'HealthPlan',
            headerName: 'Health Plan',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'TabletId',
            headerName: 'Tablet',
            minWidth: 180,
            flex: 1,
        },
        {
            field: 'PrimaryPhone',
            headerName: 'Primary Phone',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${phoneFormat(params.row.PrimaryPhone) === null ? 'n/a' : phoneFormat(params.row.PrimaryPhone)}`,
        },
        {
            field: 'SecondaryPhone',
            headerName: 'Secondary Phone',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${phoneFormat(params.row.SecondaryPhone) === null ? '' : phoneFormat(params.row.SecondaryPhone)}`,
        },
    ];

    return (
        <Grid container>
            <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Team Report
                </Typography>
            </Box>
            {<Loading message={'Loading...'} />}
            <Grid item xs={12}>
                <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={teamFilter}
                            value={teamFilter}
                            label="Filter By"
                            onChange={(e) => {
                                dispatch(setTeamFilter(e.target.value));
                            }}
                        >
                            {[...teamFilterValues]
                                .sort((a, b) => a.label.localeCompare(b.label))
                                .map((item, index) => {
                                    return (
                                        <MenuItem value={item.value} key={index}>
                                            {item.label}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>

                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => dispatch(exportTeamReport(teamFilter))}
                        variant="contained"
                        color="success"
                    >
                        {downLoading ? 'Downloading..' : 'Export'}
                    </Button>
                </Stack>
            </Grid>
            <Grid item style={{ height: '82vh', width: '100%' }}>
                <DataGrid
                    rows={teamData}
                    columns={columns}
                    pageSize={11}
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => row.Id}
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TeamReport;
