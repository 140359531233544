import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Fab,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CreateIcon from '@mui/icons-material/Create';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { RootState } from '../../reducers';
import classes from '../DocumentEngagements/Styles';
import { getDataComparator, dataStableSort, Order, copyObject } from '../../utils/common';
import { getMeansOfEngagements } from '../../store/documentEngagement.slice';
import moment from 'moment';
import AddOrUpdateDocumentOnboarding from './AddOrUpdateDocumentOnboarding';
import { getDocumentOnboardingByMemberId, toggleShowDocumentOnboardingModal } from '../../store/documentOnboarding.slice';
import { OnboardingMeansOfEngagements } from '../../utils/mappings';
import { DocumentOnboarding } from '../../Models/DocumentOnboardings/DocumentOnboarding.model';
import { KeyboardArrowUp } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const engagementTableHeadCells = [
    {
        id: 'MeansOfEngagement',
        isDate: false,
        label: 'Means of Engagement',
    },
    {
        id: 'EngagedProvider',
        isDate: false,
        label: 'Engaged Provider',
    },
    {
        id: 'VisitedTime',
        isDate: true,
        label: 'Engagement Date',
    },
    {
        id: 'CreationTime',
        isDate: false,
        label: 'Creation Date',
    },
    {
        id: 'Notes',
        isDate: false,
        label: 'Notes',
    },
];

const DocumentOnboardings = (props: any) => {
    const dispatch = useDispatch();
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const { documentOnboardings, isFetchingList } = useSelector((state: RootState) => state.documentOnboarding);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('name');

    useEffect(() => {
        dispatch(getMeansOfEngagements());
        dispatch(getDocumentOnboardingByMemberId(memberId));
    }, [memberId, dispatch]);

    const handleOnRequestSort = (property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const [showScrollerButton, setShowScrollerButton] = useState(false);
    const scrollToTop = React.useCallback(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const scrolled = document.documentElement.scrollTop;
            setShowScrollerButton(scrolled > 300);
        });
    }, []);

    const prepareDocumentOnboardings = () => {
        let data = (copyObject(documentOnboardings) || []) as DocumentOnboarding[];
        return data.map((item) => {
            return {
                Id: item.Id,
                MeansOfEngagement: OnboardingMeansOfEngagements.find((m) => m.value === item.MeansOfEngagement)?.label,
                EngagedProvider: item.EngagedProvider?.Name,
                Notes: item.Notes,
                CreationTime: new Date(item.CreationTime),
                VisitedTime: new Date(item.VisitedTime),
            };
        });
    };

    const preparedDocOnboardings = prepareDocumentOnboardings();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={classes.container}>
                <Stack direction="row" justifyContent="flex-end">
                    <Button variant="outlined" onClick={() => dispatch(toggleShowDocumentOnboardingModal(true))}>
                        Document Onboarding
                    </Button>
                </Stack>
                <TableContainer component={Paper} elevation={2} variant="elevation" sx={classes.tableContainer}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {engagementTableHeadCells.map((headCell) => (
                                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => handleOnRequestSort(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFetchingList ? (
                                <TableRow>
                                    <TableCell scope="row" colSpan={8} sx={classes.noDataFound}>
                                        <Box display="flex" justifyContent="center" sx={classes.container}>
                                            <CircularProgress />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : Boolean(preparedDocOnboardings.length) ? (
                                dataStableSort(preparedDocOnboardings, getDataComparator(order, orderBy, true)).map((doc, index) => (
                                    <TableRow key={`${doc.Id}_${index}`} hover>
                                        <TableCell>{doc.MeansOfEngagement || '-'}</TableCell>
                                        <TableCell>{doc.EngagedProvider || '-'}</TableCell>
                                        <TableCell>{moment(doc.VisitedTime).isValid() ? moment(doc.VisitedTime).format('MM/DD/yyyy hh:mm a') : '-'}</TableCell>
                                        <TableCell>
                                            {moment(doc.CreationTime).isValid() ? moment(doc.CreationTime).format('MM/DD/yyyy hh:mm a') : '-'}
                                        </TableCell>
                                        <TableCell width="25%">
                                            <div dangerouslySetInnerHTML={{ __html: doc.Notes }} />
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit" placement="left" onClick={() => dispatch(toggleShowDocumentOnboardingModal(true, doc.Id))}>
                                                <IconButton>
                                                    <CreateIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell scope="row" colSpan={8} sx={classes.noDataFound}>
                                        No Document Onboarding
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <AddOrUpdateDocumentOnboarding memberId={memberId} />
            </Box>
            {showScrollerButton && (
                <Fab onClick={scrollToTop} sx={classes.scrollTopButton} color="primary" size="small">
                    <KeyboardArrowUp />
                </Fab>
            )}
        </LocalizationProvider>
    );
};

export default DocumentOnboardings;
