import BaseEntity from '../BaseEntity.model';

export default interface IChangePasswordModel extends BaseEntity {
    ConfirmPassword: string;
    NewPassword: string;
    OldPassword: string;
}

export default class ChangePasswordModel implements IChangePasswordModel {
    ConfirmPassword: string;
    NewPassword: string;
    OldPassword: string;

    constructor() {
        this.ConfirmPassword = '';
        this.NewPassword = '';
        this.OldPassword = '';
    }
}
