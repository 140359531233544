const useStyles = {
    datePickertextField: {
        '&.MuiFormControl-root': {
            '& .MuiInputBase-root': {
                maxWidth: '85px',
                cursor: 'pointer',
                paddingRight: 0,

                '& .MuiInputBase-input': {
                    fontSize: '12px',
                    padding: 0,
                    width: '60px',
                },
                '& .MuiInputAdornment-root': {
                    marginLeft: 0,
                },
                '& ::placeholder': {
                    color: 'white',
                    opacity: 0,
                },
                '& fieldset': {
                    display: 'none',
                },
            },
        },
    },
    customTable: {
        '&.MuiPaper-root': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },

        '& thead tr': {
            backgroundColor: '#0063a7',

            '& th': {
                color: 'white',
                padding: '10px 15px',
            },
        },
        '& tbody tr': {
            '& th, td': {
                padding: '10px 15px',
                fontWeight: 500,
            },
        },
    },
    commentBox: {
        '&.MuiBox-root': {
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            padding: '20px 15px',
        },
    },
    commentHeader: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 500,
        backgroundColor: '#0063a7',
        color: 'white',
        padding: '0 15px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
    commentTextarea: {
        '& .MuiOutlinedInput-root': {
            padding: '2px 12px 12px',
        },

        '& fieldset legend': {
            display: 'none',
        },
    },
    smTabButton: {
        '&.MuiButtonBase-root': {
            fontSize: '14px',
            textTransform: 'none',
            minWidth: 'auto',
            paddingLeft: '6px',
            paddingRight: '6px',
            flex: 1,
        },
    },
    labelCheckbox: {
        '&.MuiFormControlLabel-root': {
            margin: 0,

            '& .MuiCheckbox-root': {
                display: 'none',
            },

            '& .MuiTypography-root': {
                height: '20px',
                width: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
            },

            '& .Mui-checked + .MuiTypography-root': {
                backgroundColor: '#7a5ca5',
                color: 'white',
            },
        },
    },
    rhythmRadioOption: {
        '&.MuiFormControlLabel-root': {
            margin: '8px 18px 5px 0px',

            '& .MuiRadio-root': {
                marginRight: '3px',
            },

            '& .MuiTypography-root': {
                fontSize: '14px',
                color: 'black',
            },
        },
    },
    formControlGrp: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .vital-form-label': {
            margin: '0 5px 0 0',
            fontSize: '14px',
            color: 'black',
            flex: 0.5,
            textAlign: 'right',

            '&.flex-none': {
                flex: 'none',
            },
        },

        '& .MuiFormControl-root': {
            flex: 1,
            maxWidth: '140px',
        },
    },
    smLabelRadioBtn: {
        '&.MuiFormControlLabel-root': {
            marginLeft: 0,

            '& .MuiTypography-root': {
                fontSize: '14px',
                color: 'black',
                marginLeft: '4px',
            },
        },
    },
};

export default useStyles;
