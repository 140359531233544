import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { Provider } from '../Models/Provider/Provider.model';
import { ProviderPreview } from '../Models/CarePlans/SchedulePlan.model';
import ChangePasswordModel from '../Models/Shared/ChangePasswordModel.model';

const providerNavbar = createSlice({
    name: 'providerNavbar',
    initialState: {
        allProviders: [] as Provider[],
        allScheduleNotifications: [] as any[],
        allVitalSigns: [] as any[],
        basicSettings: {} as any,
        basicSettingsMessage: '',
        bhAssessmentSetting: {} as any,
        changePassword: new ChangePasswordModel(),
        changePasswordIsValid: true,
        isFetching: false,
        messageSeverity: 'success',
        notifications: [] as any[],
        notificationTitle: '',
        notificationsByType: [] as any[],
        notificationsByTypeCount: 0,
        notificationsCount: 0,
        providerPreviews: [] as ProviderPreview[],
        selectedBHProvider: [] as string[],
        showBasicSettingsMessage: false,
        siteNotifications: [] as any[],
        unreadCount: 0,
        vitalSignsCount: 0,
    },
    reducers: {
        _setAllProviders(state, action) {
            state.allProviders = action.payload;
        },
        _setAllScheduleNotifications(state, action) {
            state.allScheduleNotifications = action.payload;
        },
        _setAllVitalSigns(state, action) {
            state.allVitalSigns = action.payload;
        },
        _setBasicSettings(state, action) {
            state.basicSettings = action.payload;
        },
        _setBasicSettingsMessage(state, action) {
            state.basicSettingsMessage = action.payload;
        },
        _setBHAssessmentSetting(state, action) {
            state.bhAssessmentSetting = action.payload;
        },
        _setChangePassword(state, action) {
            state.changePassword = action.payload;
        },
        _setChangePasswordIsValid(state, action) {
            state.changePasswordIsValid = action.payload;
        },
        _setIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _setMessageSeverity(state, action) {
            state.messageSeverity = action.payload;
        },
        _setNotifications(state, action) {
            state.notifications = action.payload;
        },
        _setNotificationsByType(state, action) {
            state.notificationsByType = action.payload;
        },
        _setNotificationsByTypeCount(state, action) {
            state.notificationsByTypeCount = action.payload;
        },
        _setNotificationsCount(state, action) {
            state.notificationsCount = action.payload;
        },
        _setNotificationTitle(state, action) {
            state.notificationTitle = action.payload;
        },
        _setProviderPreviews(state, action) {
            state.providerPreviews = action.payload;
        },
        _setSelectedBHProvider(state, action) {
            state.selectedBHProvider = action.payload;
        },
        _setShowBasicSettingsMessage(state, action) {
            state.showBasicSettingsMessage = action.payload;
        },
        _setSiteNotifications(state, action) {
            state.siteNotifications = action.payload;
        },
        _setUnreadCount(state, action) {
            state.unreadCount = action.payload;
        },
        _setVitalSignsCount(state, action) {
            state.vitalSignsCount = action.payload;
        },
    },
});

const {
    _setAllProviders,
    _setAllScheduleNotifications,
    _setAllVitalSigns,
    _setBasicSettings,
    _setBasicSettingsMessage,
    _setBHAssessmentSetting,
    _setChangePassword,
    _setChangePasswordIsValid,
    _setIsFetching,
    _setMessageSeverity,
    _setNotifications,
    _setNotificationsByType,
    _setNotificationsByTypeCount,
    _setNotificationsCount,
    _setNotificationTitle,
    _setProviderPreviews,
    _setSelectedBHProvider,
    _setShowBasicSettingsMessage,
    _setSiteNotifications,
    _setUnreadCount,
    _setVitalSignsCount,
} = providerNavbar.actions;

export const setAllScheduleNotifications =
    (notifications: any[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAllScheduleNotifications(notifications));
    };

export const setAllVitalSigns =
    (vitalSigns: any[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAllVitalSigns(vitalSigns));
    };

export const setBasicSetting =
    (setting: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setBasicSettings(setting));
    };

export const setBasicSettingsMessage =
    (message: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setBasicSettingsMessage(message));
    };

export const setBHAssessmentSetting =
    (setting: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setBHAssessmentSetting(setting));
    };

export const setChangePassword =
    (changePassword: ChangePasswordModel): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setChangePassword(changePassword));
    };

export const setChangePasswordIsValid =
    (isValid: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setChangePasswordIsValid(isValid));
    };

export const setIsFetching =
    (isFetching: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setIsFetching(isFetching));
    };

export const setMessageSeverity =
    (severity: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMessageSeverity(severity));
    };

export const setNotifications =
    (notifications: any[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNotifications(notifications));
    };

export const setNotificationsByType =
    (notifications: any[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNotificationsByType(notifications));
    };

export const setNotificationsByTypeCount =
    (count: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNotificationsByTypeCount(count));
    };

export const setNotificationsCount =
    (count: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNotificationsCount(count));
    };

export const setNotificationTitle =
    (title: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNotificationTitle(title));
    };

export const setSelectedBHProvider =
    (selected: string[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedBHProvider(selected));
    };

export const setShowBasicSettingsMessage =
    (show: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowBasicSettingsMessage(show));
    };

export const setSiteNotifications =
    (notifications: any[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSiteNotifications(notifications));
    };

export const setUnreadCount =
    (count: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setUnreadCount(count));
    };

export const setVitalSignsCount =
    (count: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setVitalSignsCount(count));
    };

export const getActiveProviders = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/provider/getactiveproviders`);
    dispatch(_setAllProviders(response.data));
    dispatch(setIsLoading(false));
};

export const getBasicSettings = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/setting/getsetting`);
    dispatch(_setBasicSettings(response.data));
    dispatch(setIsLoading(false));
};

export const getProviderPreview = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/provider/getproviderpreview`);
    dispatch(_setProviderPreviews(response.data));
    dispatch(setIsLoading(false));
};

export const markAllNotificationsAsRead =
    (providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsFetching(true));
        await axios.put(`${API_URL}/notification/markallasread?providerId=${providerId}`);
        dispatch(setNotificationsCount(0));
        dispatch(setNotificationsByTypeCount(0));
        dispatch(setNotifications([]));
        dispatch(setIsFetching(false));
    };

/// for request notifications icon
export const getNotificationsByType =
    (providerId: string, type: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsFetching(true));
        const response = await axios.get(`${API_URL}/notification/getnotificationsbytype?providerId=${providerId}&type=${type}`);
        // console.log('getNotificationsByType', response.data);
        dispatch(_setNotificationsByType(response.data));
        dispatch(_setNotifications(response.data));
        dispatch(setIsFetching(false));
    };

export const getNotificationsByTypeCount =
    (providerId: string, type: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/notification/getnotificationsbytypecount?providerId=${providerId}&type=${type}`);
        dispatch(_setNotificationsByTypeCount(response.data));
        dispatch(setIsLoading(false));
    };
/// end for request notifications icon

/// for site notifications icon
export const getSiteNotifications =
    (providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsFetching(true));
        const response = await axios.get(`${API_URL}/notification/getprovidernotifications?providerId=${providerId}`);
        dispatch(_setSiteNotifications(response.data));
        dispatch(_setNotifications(response.data));
        dispatch(setIsFetching(false));
    };

export const getNotificationsCount =
    (providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/notification/getnotificationscount?providerId=${providerId}`);
        dispatch(_setNotificationsCount(response.data));
        dispatch(setIsLoading(false));
    };
/// end for site notifications icon

/// for vital signs icon
export const getVitalSigns = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsFetching(true));
    const response = await axios.get(`${API_URL}/vitalsigns/getallvitalsigns`);
    dispatch(_setAllVitalSigns(response.data));
    dispatch(_setNotifications(response.data));
    dispatch(setIsFetching(false));
};

export const getVitalSignsCount = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/vitalsigns/getvitalsignscount`);
    dispatch(_setVitalSignsCount(response.data));
    dispatch(setIsLoading(false));
};
/// end for vital signs icon

export const getUnreadCount = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/SheduleNotification/getunreadcount`);
    dispatch(_setUnreadCount(response.data));
    dispatch(setIsLoading(false));
};

export const getAllScheduleNotifications =
    (isGetAll: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/SheduleNotification/getall?isGetAll=${isGetAll}}`);
        dispatch(setAllScheduleNotifications(response.data));
        dispatch(_setNotifications(response.data));
        dispatch(setIsLoading(false));
    };

/// start basic settings
export const saveCallSettings =
    (coId: string, coName: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/setting/updatecallsetting?coId=${coId}&coName=${coName}`);
            dispatch(setShowBasicSettingsMessage(true));
            if (response.status === 200) {
                dispatch(setMessageSeverity('success'));
                dispatch(setBasicSettingsMessage('Call settings successfully saved'));
            }
        } catch (error) {
            dispatch(setShowBasicSettingsMessage(true));
            dispatch(setMessageSeverity('error'));
            dispatch(setBasicSettingsMessage('Error saving call settings'));
            console.log('error saving call settings: ', error);
        }

        dispatch(setIsLoading(false));
    };

export const saveTabletSettings =
    (email: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/setting/updatetabletsetting?email=${email}`);
            dispatch(setShowBasicSettingsMessage(true));
            if (response.status === 200) {
                dispatch(setMessageSeverity('success'));
                dispatch(setBasicSettingsMessage('Help email has been changed successfully'));
            }
        } catch (error) {
            dispatch(setShowBasicSettingsMessage(true));
            dispatch(setMessageSeverity('error'));
            dispatch(setBasicSettingsMessage('Error saving tablet settings'));
            console.log('error saving tablet settings: ', error);
        }

        dispatch(setIsLoading(false));
    };

export const saveEligibilityLoginSetting = (): AppThunk => async (dispatch, getState) => {
    const basicSettings = getState().providerNavbar.basicSettings;
    dispatch(setIsLoading(true));
    try {
        const response = await axios.put(`${API_URL}/setting/updateeligibilityloginsetting`, { ...basicSettings.EligibilityLoginSetting });
        dispatch(setShowBasicSettingsMessage(true));
        if (response.status === 200) {
            dispatch(setMessageSeverity('success'));
            dispatch(setBasicSettingsMessage('Eligibility login settings successfully saved'));
        }
    } catch (error) {
        dispatch(setShowBasicSettingsMessage(true));
        dispatch(setMessageSeverity('error'));
        dispatch(setBasicSettingsMessage('Error saving eligibility login settings'));
        console.log('error saving eligibility login settings: ', error);
    }
    dispatch(setIsLoading(false));
};

export const resetAssessments = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    try {
        const response = await axios.put(`${API_URL}/setting/resetassessment`);
        dispatch(setShowBasicSettingsMessage(true));
        if (response.status === 200) {
            dispatch(setMessageSeverity('success'));
            dispatch(setBasicSettingsMessage('Assessments successfully reset'));
        }
    } catch (error) {
        dispatch(setShowBasicSettingsMessage(true));
        dispatch(setMessageSeverity('error'));
        dispatch(setBasicSettingsMessage('Error resetting assessments'));
        console.log('error resetting assessments: ', error);
    }
    dispatch(setIsLoading(false));
};

export const getBHAssessmentSettings = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/setting/getbhassessmentsetting`);
    dispatch(setBHAssessmentSetting(response.data));
    dispatch(setSelectedBHProvider(response.data?.Providers.map((p: any) => p.Id)));
    dispatch(setIsLoading(false));
};

export const saveBHAssessmentSettings =
    (providers: ProviderPreview[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/setting/updatebhassessmentsetting`, { Providers: providers });
            dispatch(setShowBasicSettingsMessage(true));
            if (response.status === 200) {
                dispatch(setMessageSeverity('success'));
                dispatch(setBasicSettingsMessage('BH assessment settings successfully saved'));
            }
        } catch (error) {
            dispatch(setShowBasicSettingsMessage(true));
            dispatch(setMessageSeverity('error'));
            dispatch(setBasicSettingsMessage('Error saving BH assessment settings'));
            console.log('error saving BH assessment settings: ', error);
        }
        dispatch(setIsLoading(false));
    };

export const saveBillingSettings = (): AppThunk => async (dispatch, getState) => {
    const basicSettings = getState().providerNavbar.basicSettings;
    dispatch(setIsLoading(true));
    try {
        const response = await axios.put(`${API_URL}/setting/updatebillingsetting`, { ...basicSettings.BillingSetting });
        dispatch(setShowBasicSettingsMessage(true));
        if (response.status === 200) {
            dispatch(setMessageSeverity('success'));
            dispatch(setBasicSettingsMessage('Billing settings successfully saved'));
        }
    } catch (error) {
        dispatch(setShowBasicSettingsMessage(true));
        dispatch(setMessageSeverity('error'));
        dispatch(setBasicSettingsMessage('Error saving billing settings'));
        console.log('error saving billing settings: ', error);
    }
    dispatch(setIsLoading(false));
};

export const updateHraReferralRequestProvider =
    (providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/setting/updatehrareferralrequestprovider?providerId=${providerId}`);
            dispatch(setShowBasicSettingsMessage(true));
            if (response.status === 200) {
                dispatch(setMessageSeverity('success'));
                dispatch(setBasicSettingsMessage('HRA referral request provider has been changed successfully'));
            }
        } catch (error) {
            dispatch(setShowBasicSettingsMessage(true));
            dispatch(setMessageSeverity('error'));
            dispatch(setBasicSettingsMessage('Error saving hra request provider'));
            console.log('error saving hra request provider: ', error);
        }

        dispatch(setIsLoading(false));
    };

export const UpdateHraBhFollowUpRequestProvider =
    (providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/setting/updatehrabhfollowuprequestprovider?providerId=${providerId}`);
            dispatch(setShowBasicSettingsMessage(true));
            if (response.status === 200) {
                dispatch(setMessageSeverity('success'));
                dispatch(setBasicSettingsMessage('HRA BH followup request provider has been changed successfully'));
            }
        } catch (error) {
            dispatch(setShowBasicSettingsMessage(true));
            dispatch(setMessageSeverity('error'));
            dispatch(setBasicSettingsMessage('Error saving hra request provider'));
            console.log('error saving hra request provider: ', error);
        }

        dispatch(setIsLoading(false));
    };

/// end basic settings

export default providerNavbar.reducer;
