import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Shared/Loading';
import { states } from '../../utils/constants';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { RootState } from '../../reducers';
import {
    setNewFacilityState,
    setSelectedFacility,
    setNewFacilitiesData,
    getNewFacilitiesData,
    putFacilitiesData,
    postFacilitiesData,
    handleInputChange,
    handleFacilityEdit,
    deleteFacility,
    closeConfirm,
    putCSVFile,
    closeDialog,
    addHandler,
    editHandler,
    deleteHandler,
    fetchFacilitiesExcel,
} from '../../store/facilities.slice';
import { Search } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp';

const Facilities = (props) => {
    const dispatch = useDispatch();
    const {
        rowData,
        showNewFacilityInputs,
        newFacilityState,
        errorText,
        selectedFacility,
        facilityAction,
        newFacilitiesData,
        dialogVisibilty,
        confirmVisibility,
        isExporting,
    } = useSelector((state: RootState) => state.facilities);

    const uploadHandler = () => {
        document.getElementById('upload-btn').click();
    };

    const [facilitySearchValue, setFacilitySearchValue] = useState('');
    const [localFacilities, setLocalFacilities] = useState([]);
    function executeSearch() {
        var tmp = [];

        const searchValue = facilitySearchValue.toLowerCase();
        for (const facility of rowData) {
            if (
                (facility.Name && facility.Name.toLowerCase().indexOf(searchValue) > -1) ||
                (facility.Address && facility.Address.toLowerCase().indexOf(searchValue) > -1) ||
                (facility.City && facility.City.toLowerCase().indexOf(searchValue) > -1) ||
                (facility.State && facility.State.toLowerCase().indexOf(searchValue) > -1) ||
                (facility.ZipCode && facility.ZipCode.toString().toLowerCase().indexOf(searchValue) > -1)
            ) {
                tmp.push(facility);
            }
        }

        setLocalFacilities(tmp);
    }

    const changeSearch = (event: any) => {
        if (!event.key || event.key === 'Enter') {
            executeSearch();
        }
    };

    const handleExportToExcel = () => {
        dispatch(fetchFacilitiesExcel());
    };

    const [pageSize, setPageSize] = useState(15);

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 54 : 58) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getNewFacilitiesData());
    }, [dispatch]);

    useEffect(() => {
        executeSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData]);

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            minWidth: 250,
            flex: 1.25,
        },
        {
            field: 'ZipCode',
            headerName: 'Zip Code',
            flex: 0.5,
        },
        {
            field: 'Address',
            headerName: 'Address',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'City',
            headerName: 'City',
            flex: 0.75,
        },
        {
            field: 'State',
            headerName: 'State',
            flex: 0.5,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 0.75,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit" placement="top">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => dispatch(editHandler())} />
                        </Tooltip>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon color="error" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => dispatch(deleteHandler())} />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Box>
            {<Loading message={'Loading...'} />}

            <Dialog
                open={confirmVisibility}
                onClose={() => dispatch(closeConfirm())}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                disableEnforceFocus
                sx={{ padding: '16px' }}
            >
                <Paper
                    sx={{
                        padding: '16px',
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="h6" component={'p'} color={'error'}>
                            Are You Sure You Want To Delete <span style={{ color: '#333', fontWeight: 'bold' }}>{selectedFacility.Name}</span> ?
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => dispatch(deleteFacility(selectedFacility))} autoFocus>
                            Delete
                        </Button>
                        <Button variant="outlined" onClick={() => dispatch(closeConfirm())}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>

            <Dialog disableEnforceFocus open={dialogVisibilty}>
                <DialogTitle>
                    <div>{facilityAction === 'new' ? 'New' : 'Edit'} Facility</div>
                </DialogTitle>
                <DialogContent dividers>
                    <FormControl>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Name"
                                    name="Name"
                                    value={facilityAction === 'new' ? newFacilitiesData.Name : selectedFacility.Name}
                                    onChange={(e) =>
                                        facilityAction === 'new'
                                            ? dispatch(handleInputChange(e, newFacilitiesData))
                                            : dispatch(handleFacilityEdit(e, selectedFacility))
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    error={errorText.length === 0 ? false : true}
                                    helperText={errorText}
                                    required
                                    label="Zip Code"
                                    name="ZipCode"
                                    value={facilityAction === 'new' ? newFacilitiesData.ZipCode : selectedFacility.ZipCode}
                                    onChange={(e) => {
                                        facilityAction === 'new'
                                            ? dispatch(handleInputChange(e, newFacilitiesData))
                                            : dispatch(handleFacilityEdit(e, selectedFacility));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Address"
                                    name="Address"
                                    value={facilityAction === 'new' ? newFacilitiesData.Address : selectedFacility.Address}
                                    onChange={(e) =>
                                        facilityAction === 'new'
                                            ? dispatch(handleInputChange(e, newFacilitiesData))
                                            : dispatch(handleFacilityEdit(e, selectedFacility))
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="City"
                                    name="City"
                                    value={facilityAction === 'new' ? newFacilitiesData.City : selectedFacility.City}
                                    onChange={(e) =>
                                        facilityAction === 'new'
                                            ? dispatch(handleInputChange(e, newFacilitiesData))
                                            : dispatch(handleFacilityEdit(e, selectedFacility))
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {!showNewFacilityInputs && (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="State"
                                        name="State"
                                        value={selectedFacility.State}
                                        onChange={(e) => dispatch(handleFacilityEdit(e, selectedFacility))}
                                    />
                                )}
                                {showNewFacilityInputs && (
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={newFacilityState}
                                            defaultValue={newFacilityState}
                                            autoWidth={true}
                                            label="State"
                                            name="state"
                                            onChange={(e) => {
                                                dispatch(setNewFacilityState(e.target.value));
                                                dispatch(setNewFacilitiesData({ ...newFacilitiesData, State: e.target.value }));
                                            }}
                                        >
                                            <MenuItem disabled value={'select'}>
                                                Select a State
                                            </MenuItem>
                                            {states.map((state, index) => {
                                                return (
                                                    <MenuItem key={index} value={state}>
                                                        {state}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <Stack p={2} spacing={2}>
                    {facilityAction === 'new' && (
                        <Typography align="right" variant="body2">
                            * Note: New facilities will automatically be added to regions based on Zip Code.
                        </Typography>
                    )}
                    <DialogActions>
                        {facilityAction === 'new' && (
                            <Button
                                disabled={errorText.length > 1 || newFacilitiesData.ZipCode.length === 0 || newFacilitiesData.Name.length === 0}
                                onClick={() => {
                                    dispatch(postFacilitiesData(newFacilitiesData));
                                }}
                                className="button-120"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        )}
                        {facilityAction !== 'new' && (
                            <Button
                                disabled={errorText.length > 1 || selectedFacility.ZipCode.length === 0 || selectedFacility.Name.length === 0}
                                onClick={() => {
                                    dispatch(putFacilitiesData(selectedFacility));
                                }}
                                className="button-120"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        )}
                        <Button onClick={() => dispatch(closeDialog())} className="button-120" variant="outlined">
                            Cancel
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>

            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={12} textAlign={'right'}>
                    <Stack direction={'row'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid item xs={6} display="flex">
                            <FormControl style={{ minWidth: '300px', margin: '0px', alignSelf: 'flex-end' }}>
                                <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type="text"
                                    value={facilitySearchValue}
                                    onChange={(e) => {
                                        setFacilitySearchValue(e.target.value as string);
                                    }}
                                    onKeyUp={(event: any) => {
                                        changeSearch(event);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Search" onClick={changeSearch} edge="end">
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Search"
                                />
                            </FormControl>
                        </Grid>
                        {/* <ButtonGroup> */}
                        <Stack direction={'row'} spacing={2}>
                            <Button onClick={() => dispatch(addHandler())} sx={{ marginRight: '16px' }} variant="contained">
                                New Facility
                            </Button>
                            <Button onClick={uploadHandler} variant="outlined">
                                Upload Facility CSV File
                            </Button>
                            <Button variant="contained" color="primary" disabled={isExporting} onClick={handleExportToExcel}>
                                {!isExporting ? (
                                    <GetAppIcon style={{ marginRight: '8px', color: 'white' }} />
                                ) : (
                                    <CircularProgress size={24} style={{ marginRight: '8px', color: 'white' }} />
                                )}
                                <span style={{ color: 'white' }}>Export to Excel</span>
                            </Button>
                        </Stack>
                        {/* </ButtonGroup> */}
                    </Stack>
                    <form id={'upload-form'}>
                        <Button id="upload-btn" variant="contained" component="label" hidden>
                            Upload File
                            <input
                                id="csv"
                                onChange={(e) => {
                                    dispatch(putCSVFile(e.target.files[0]));
                                }}
                                type="file"
                                accept=".csv"
                                hidden
                            />
                        </Button>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            onCellClick={(params: any) => {
                                dispatch(setSelectedFacility(params.row));
                            }}
                            rows={localFacilities}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                [`& .MuiDataGrid-row.Mui-selected`]: {
                                    backgroundColor: '#e3fce5',
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Facilities;
