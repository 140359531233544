import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setIsLoading } from './shared.slice';
import { GridColumnVisibilityModel, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';

const defaultRegionData = () => {
    return { Id: '', Name: '', Providers: [], Facilities: [] };
};

const adminRegions = createSlice({
    name: 'adminRegions',
    initialState: {
        addDialog: false,
        addDialogRows: [],
        columnVisibilityModel: { action: true },
        currentColumns: [],
        currentRows: [],
        currentTab: 'Provider',
        deleteRegionModal: false,
        editRegionModal: false,
        helperText: '',
        isNewRegion: false,
        regionAction: 0,
        regionData: defaultRegionData(),
        regionRowData: [],
        selectionModel: [],
        selectValue: '-1',
    },
    reducers: {
        _setAddDialog(state, action) {
            state.addDialog = action.payload;
        },
        _setAddDialogRows(state, action) {
            state.addDialogRows = action.payload;
        },
        _setColumnVisibilityModel(state, action) {
            state.columnVisibilityModel = action.payload;
        },
        _setCurrentColumns(state, action) {
            state.currentColumns = action.payload;
        },
        _setCurrentRows(state, action) {
            state.currentRows = action.payload;
        },
        _setCurrentTab(state, action) {
            state.currentTab = action.payload;
        },
        _setDeleteRegionModal(state, action) {
            state.deleteRegionModal = action.payload;
        },
        _setEditRegionModal(state, action) {
            state.editRegionModal = action.payload;
        },
        _setHelperText(state, action) {
            state.helperText = action.payload;
        },
        _setIsNewRegion(state, action) {
            state.isNewRegion = action.payload;
        },
        _setRegionAction(state, action) {
            state.regionAction = action.payload;
        },
        _setRegionData(state, action) {
            state.regionData = action.payload;
        },
        _setRegionRowData(state, action) {
            state.regionRowData = action.payload;
        },
        _setSelectionModel(state, action) {
            state.selectionModel = action.payload;
        },
        _setSelectValue(state, action) {
            state.selectValue = action.payload;
        },
    },
});

const {
    _setAddDialog,
    _setAddDialogRows,
    _setColumnVisibilityModel,
    _setCurrentColumns,
    _setCurrentRows,
    _setCurrentTab,
    _setDeleteRegionModal,
    _setEditRegionModal,
    _setHelperText,
    _setIsNewRegion,
    _setRegionAction,
    _setRegionData,
    _setRegionRowData,
    _setSelectionModel,
    _setSelectValue,
} = adminRegions.actions;

export const setRegionRowData =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setRegionRowData(params));
    };

export const setIsNewRegion =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsNewRegion(params));
    };

export const setRegionData =
    (params: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setRegionData(params));
    };

export const setRegionAction =
    (params: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setRegionAction(params));
    };

export const setHelperText =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setHelperText(params));
    };

export const setSelectionModel =
    (params: string[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectionModel(params));
    };

export const setEditRegionModal =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setEditRegionModal(params));
    };

export const setDeleteRegionModal =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setDeleteRegionModal(params));
    };

export const setAddDialogRows =
    (params: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setAddDialogRows(params));
    };

export const setCurrentColumns =
    (params: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentColumns(params));
    };

export const setCurrentRows =
    (params: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentRows(params));
    };

export const setSelectValue =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectValue(params));
    };

export const setColumnVisibilityModel =
    (params: GridColumnVisibilityModel): AppThunk =>
    async (dispatch) => {
        dispatch(_setColumnVisibilityModel(params));
    };

export const setCurrentTab =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentTab(params));
    };

export const setAddDialog =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setAddDialog(params));
    };

export const nameOnChange =
    (e, regionData): AppThunk =>
    async (dispatch) => {
        dispatch(setRegionData({ ...regionData, Name: e.target.value }));
    };

export const fetchRegions = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/region`);
    dispatch(setRegionRowData(response.data));
    dispatch(setIsLoading(false));
};

export const updateRegionMemberCount = (): AppThunk => async (dispatch) => {
    console.log('updateRegionMemberCount CLICKED');
    dispatch(setIsLoading(true));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await axios.get(`${API_URL}/region/updateRegionMemberCount`);
    dispatch(setRegionAction(0));
    dispatch(fetchRegions());
    dispatch(setIsLoading(false));
};

// Downloads a CSV file of all providers assigned to regions
export const downloadProviderCSV = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/region/generateRegionDownload`);
    let blob = b64toBlob(response.data.base64String, 'text/csv');
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${Date.now()}.csv`);
    link.click();
    link.remove();
    dispatch(setIsLoading(false));
    dispatch(setRegionAction(0));
};

export const addRegion =
    (name: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.post(`${API_URL}/region`, { Name: name });
            if (response.data.Id !== null) {
                dispatch(fetchRegions());
                dispatch(setHelperText(''));
                dispatch(setIsNewRegion(false));
                dispatch(setRegionData(response.data));
                dispatch(setIsLoading(false));
            } else {
                dispatch(setHelperText('Region Name Already Exists'));
                dispatch(setIsLoading(false));
            }
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export const addNewRegionHandler = (): AppThunk => async (dispatch) => {
    dispatch(setRegionData(defaultRegionData()));
    dispatch(setIsNewRegion(true));
    dispatch(setEditRegionModal(true));
};

export const closeEditRegionHandler = (): AppThunk => async (dispatch) => {
    dispatch(setEditRegionModal(false));
    dispatch(setCurrentTab('Provider'));
    dispatch(setHelperText(''));
    dispatch(setIsNewRegion(false));
};

export const editRegion =
    (params: GridRowId): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/region/${params}`);
        dispatch(setRegionData(response.data));
        dispatch(setEditRegionModal(true));
        dispatch(setIsLoading(false));
    };

export const deleteRegionHandler =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.delete(`${API_URL}/region/delete?regionId=${id}`);
            dispatch(fetchRegions());
            dispatch(closeDeleteHandler());
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            closeDeleteHandler();
        }
    };

export const openDeleteHandler =
    (params: GridRenderCellParams<any, any, any>): AppThunk =>
    async (dispatch) => {
        dispatch(setRegionData(params.row));
        dispatch(setDeleteRegionModal(true));
    };

export const closeDeleteHandler = (): AppThunk => async (dispatch) => {
    dispatch(setDeleteRegionModal(false));
};

export const openAddDialogHandler =
    (currentTab: string, filteredFacilities: any[], filteredProviders: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(setAddDialog(true));
        dispatch(setColumnVisibilityModel({ action: false }));
        if (currentTab === 'Facility') {
            dispatch(setAddDialogRows(filteredFacilities));
        }
        if (currentTab === 'Provider') {
            dispatch(setAddDialogRows(filteredProviders));
        }
    };

export const closeAddDialogHandler = (): AppThunk => async (dispatch) => {
    dispatch(setAddDialog(false));
    dispatch(setSelectValue('-1'));
    dispatch(setColumnVisibilityModel({ action: true }));
    dispatch(setSelectionModel([]));
};

export const uploadProviderCSV =
    (csvFile: File): AppThunk =>
    async (dispatch) => {
        if (!csvFile) {
            return;
        }
        const fd = new FormData();
        fd.append('File', csvFile, csvFile.name);
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios({ method: 'put', url: `${API_URL}/region/uploadfile`, data: fd });
            dispatch(setRegionAction(0));
            dispatch(fetchRegions());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export const selectOnChange =
    (e: any, currentTab: string, filteredFacilities: any[], filteredProviders: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(setSelectValue(e.target.value as string));

        if (currentTab === 'Facility' && e.target.value === '-1') {
            dispatch(setAddDialogRows(filteredFacilities));
        } else if (currentTab === 'Facility') {
            dispatch(setAddDialogRows(filteredFacilities.filter((item) => item.ZipCode === e.target.value)));
        }
        if (currentTab === 'Provider' && e.target.value === '-1') {
            dispatch(setAddDialogRows(filteredProviders));
        } else if (currentTab === 'Provider') {
            dispatch(setAddDialogRows(filteredProviders.filter((item) => item.Role.RoleName === e.target.value)));
        }
    };

export const tabsHandler =
    (event: React.SyntheticEvent, newValue: string): AppThunk =>
    async (dispatch) => {
        dispatch(setCurrentTab(newValue));
    };

export const deleteFromRegionHandler =
    (params: GridRenderCellParams<any, any, any>, regionData, currentTab, allProviders, allFacilities): AppThunk =>
    async (dispatch) => {
        let data = [];
        const currentFacilities = regionData.Facilities.filter((item) => item.Id !== params.id).map((item) => item.Id);
        const currentProviders = regionData.Providers.filter((item) => item.ProviderId !== params.id).map((item) => item.ProviderId);
        if (currentTab === 'Provider') {
            data = allProviders.filter((provider) => currentProviders.includes(provider.Id));
            dispatch(addProviderOrFacilityHandler('Providers', regionData.Id, data));
        }
        if (currentTab === 'Facility') {
            data = allFacilities.filter((facility) => currentFacilities.includes(facility.Id));
            dispatch(addProviderOrFacilityHandler('Facilities', regionData.Id, data));
        }
    };

export const addProviderOrFacilityHandler =
    (type: string, id: string, data: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.put(`${API_URL}/region/add${type}ToRegion?regionId=${id}`, data);
            dispatch(editRegion(id));
            dispatch(fetchRegions());
            dispatch(closeAddDialogHandler());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(closeAddDialogHandler());
            dispatch(setIsLoading(false));
        }
    };

export const assignProviderHandler =
    (currentTab, regionData, selectionModel, allProviders, allFacilities): AppThunk =>
    async (dispatch) => {
        let data = [];
        if (currentTab === 'Provider') {
            let current = regionData.Providers.map((p) => p.ProviderId).concat(selectionModel);
            data = allProviders.filter((item) => current.includes(item.Id));
            dispatch(addProviderOrFacilityHandler('Providers', regionData.Id, data));
        }

        if (currentTab === 'Facility') {
            let current = regionData.Facilities.map((p) => p.Id).concat(selectionModel);
            data = allFacilities.filter((item) => current.includes(item.Id));
            dispatch(addProviderOrFacilityHandler('Facilities', regionData.Id, data));
        }
    };

export default adminRegions.reducer;
