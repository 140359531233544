import { Grid, Paper } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';

const SchedulingEngagementDisplay = () => {
    const { memberDocs } = useSelector((state: RootState) => state.officeSchedulerSlice);
    return (
        <Grid item lg={12}>
            <Paper
                elevation={2}
                style={{
                    width: '100%',
                    padding: '1em',
                    maxHeight: 363,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
                id="docsEl"
            >
                <p style={{ textAlign: 'center' }}>Engagement Notes </p>
                <div>
                    {memberDocs?.length > 0 &&
                        memberDocs.map((doc, i) => (
                            <div key={`${doc.Id}${i}`}>
                                <Grid container justifyContent="space-between">
                                    <Grid item lg={4}>
                                        <span className="documentation-detail">{format(parseISO(doc.VisitedTime), 'MM/dd/yyyy h:mm aa')}</span>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <span className="documentation-detail">{doc.ReasonForVisit?.Name || ''}</span>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <span className="documentation-detail">{doc.EngagedProvider.Name || ''}</span>
                                    </Grid>
                                </Grid>
                                <div dangerouslySetInnerHTML={{ __html: `${doc.Notes || ''}` }}></div>
                                <p className="documentation-note"></p>
                                <hr></hr>
                            </div>
                        ))}
                </div>
            </Paper>
        </Grid>
    );
};

export default SchedulingEngagementDisplay;
