export default interface ITobaccoAssessment {
    HasTobaccoUse?: boolean;
    HasCigrettesUse?: boolean;
    IsChewingTobacco?: boolean;
    IsVaping?: boolean;
    TobaccoUseInYears?: number;
    IsInterestedInQuitting?: boolean;
    AverageToboccoUsagePerDay?: number;
}

export class TobaccoAssessment implements ITobaccoAssessment {
    HasTobaccoUse?: boolean;
    HasCigrettesUse?: boolean;
    IsChewingTobacco?: boolean;
    IsVaping?: boolean;
    TobaccoUseInYears?: number;
    IsInterestedInQuitting?: boolean;
    AverageToboccoUsagePerDay?: number;

    constructor() {
        this.HasTobaccoUse = null;
    }
}
