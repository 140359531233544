import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import b64toBlob from 'b64-to-blob';
import axios from 'axios';
import { apiString as API_URL } from '../utils/constants';
import { saveEngagementDraft } from './documentEngagement.slice';
import { setAssessmentError } from './assessment.slice';
import { ScreeningFuhFum } from '../Models/FuhFum/ScreeningFuhFum.model';
import { setIsLoading } from './shared.slice';

const fuhfumSlice = createSlice({
    name: 'fuhfum',
    initialState: {
        screeningFuhFum: new ScreeningFuhFum(),
        hasFuhfumReferral: false,
        isFetching: false,
        isSaving: false,
        isCloning: false,
        activeStep: 0,
        steps: [],
        validatedMembers: [],
        validFile: true,
        openAlert: false,
        openAddMember: false,
        alertMessage: '',
        fileProcessedCount: { Percentage: 0, ProcessedCount: 0, TotalCount: 0 },
        importSuccessful: false,
        isImporting: false,
        updatingMember: false,
    },
    reducers: {
        _setScreeningFuhFum(state, action) {
            state.screeningFuhFum = action.payload || new ScreeningFuhFum();
        },
        _setHasFuhfumReferral(state, action) {
            state.hasFuhfumReferral = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsCloning(state, action) {
            state.isCloning = action.payload;
        },
        _setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        _setValidFile(state, action) {
            state.validFile = action.payload;
        },
        _setOpenAlert(state, action) {
            state.openAlert = action.payload;
        },
        _setOpenAddMember(state, action) {
            state.openAddMember = action.payload;
        },
        _setAlertMessage(state, action) {
            state.alertMessage = action.payload;
        },
        _setFileProcessedCount(state, action) {
            state.fileProcessedCount = action.payload;
        },
        _setImportSuccessful(state, action) {
            state.importSuccessful = action.payload;
        },
        _setIsImporting(state, action) {
            state.isImporting = action.payload;
        },
        _setUpdatingMember(state, action) {
            state.updatingMember = action.payload;
        },
        _setSteps(state, action) {
            state.steps = action.payload;
        },
        _setValidatedMembers(state, action) {
            state.validatedMembers = action.payload;
        },
        _resetFuhFumAssessment(state, action) {
            state.screeningFuhFum = new ScreeningFuhFum();
            state.hasFuhfumReferral = false;
            state.isFetching = false;
            state.isSaving = false;
            state.isCloning = false;
            state.activeStep = 0;
            state.steps = [];
            state.validatedMembers = [];
            state.validFile = true;
            state.openAlert = false;
            state.openAddMember = false;
            state.alertMessage = '';
            state.fileProcessedCount = { Percentage: 0, ProcessedCount: 0, TotalCount: 0 };
            state.importSuccessful = false;
            state.isImporting = false;
            state.updatingMember = false;
        },
    },
});

const {
    _setScreeningFuhFum,
    _setHasFuhfumReferral,
    _toggleIsFetching,
    _toggleIsSaving,
    _toggleIsCloning,
    _setActiveStep,
    _setValidFile,
    _setOpenAlert,
    _setOpenAddMember,
    _setAlertMessage,
    _setFileProcessedCount,
    _setImportSuccessful,
    _setIsImporting,
    _setUpdatingMember,
    _setSteps,
    _setValidatedMembers,
    _resetFuhFumAssessment,
} = fuhfumSlice.actions;

export const resetFuhFumAssessment = (): AppThunk => (dispatch, getState) => {
    dispatch(_resetFuhFumAssessment(''));
};

export const setValidFile =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setValidFile(params));
    };

export const setActiveStep =
    (value: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setActiveStep(value));
    };

export const setOpenAlert =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAlert(value));
    };

export const setOpenAddMember =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAddMember(value));
    };

export const setAlertMessage =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setAlertMessage(params));
    };

export const setFileProcessedCount =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setFileProcessedCount(params));
    };

export const setImportSuccessful =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setImportSuccessful(value));
    };

export const setIsImporting =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsImporting(value));
    };

export const setUpdatingMember =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setUpdatingMember(params));
    };

export const setSteps =
    (value: { label: string; value: number }[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSteps(value));
    };

export const setValidatedMembers =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setValidatedMembers(params));
    };

export const fetchScreeningFuhFumByMemberId =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/fuhfum/GetScreeningFuhFumByMemberId`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.Status = 0;
                }
                dispatch(_setScreeningFuhFum(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const fetchScreeningFuhFumById =
    (id: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/fuhfum/GetScreeningFuhFumById/${id}`)
            .then((response) => {
                dispatch(_setScreeningFuhFum(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const saveScreeningFuhFum =
    (screeningFuhFum: ScreeningFuhFum, gotoNext = false, saveOnlyLocal = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(gotoNext));
        dispatch(_setScreeningFuhFum(screeningFuhFum));
        if (!saveOnlyLocal) {
            (Boolean(screeningFuhFum.Id)
                ? axios.put(`${API_URL}/fuhfum/UpdateScreeningFuhFum`, screeningFuhFum)
                : axios.post(`${API_URL}/fuhfum/CreateScreeningFuhFum`, screeningFuhFum)
            )
                .then((response) => {
                    dispatch(_setScreeningFuhFum(response.data));
                    const engagementDraft = getState().documentEngagement.engagementDraft;
                    dispatch(saveEngagementDraft({ ...engagementDraft, ScreeningFuhFumId: response.data.Id }, gotoNext));
                })
                .catch((err) => {
                    dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
                })
                .finally(() => {
                    dispatch(_toggleIsSaving(false));
                });
        }
    };

export const fetchHasFuhfumReferral =
    (engagementDraftId: string, callBack: Function = null): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/fuhfum/HasFuhfumReferral`, { params: { engagementDraftId } })
            .then((response) => {
                dispatch(_setHasFuhfumReferral(response.data));
                if (callBack) {
                    callBack(response.data);
                }
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const cloneMember =
    (memberId: string, callBack: Function = null): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsCloning(true));
        axios
            .put(`${API_URL}/fuhfum/CloneMember/${memberId}`)
            .then((response) => {
                if (callBack) {
                    callBack(response.data);
                }
            })
            .finally(() => {
                dispatch(_toggleIsCloning(false));
            });
    };

export const downloadSample = (): AppThunk => async () => {
    const response = await axios.get(`${API_URL}/fuhfum/downloadsamplemember`);
    let blob = b64toBlob(response.data.base64String, 'text/csv');
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Fuhfum_Member_Sample.xlsx`);
    link.click();
    link.remove();
};

export const validateFile =
    (file: File): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        let formData = new FormData();
        formData.append('File', file);
        try {
            const response = await axios.put(`${API_URL}/fuhfum/validatefiledata`, formData);
            if (response.data.IsValid) {
                dispatch(setValidatedMembers(response.data.Data));
                dispatch(setAlertMessage(''));
                dispatch(setOpenAlert(false));
                dispatch(setActiveStep(2));
                dispatch(setValidFile(true));
                dispatch(setIsLoading(false));
            } else {
                dispatch(setOpenAlert(true));
                dispatch(setAlertMessage(response.data.Message));
                dispatch(setIsLoading(false));
            }
        } catch (error) {
            dispatch(setIsLoading(false));
            if (error.response.data.error) {
                console.log('error.response.data.error message: ', error.response.data.error);
            } else {
                console.log('error.response.data message: ', error.response.data);
            }
            dispatch(setValidFile(false));
        }
    };

export const getFileProcessedCount = (): AppThunk => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/fuhfum/getfileprocessedcount`);
        dispatch(setFileProcessedCount(response.data));
    } catch (error) {
        console.log(error);
    }
};

export const uploadMember =
    (file: File): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        let formData = new FormData();
        formData.append('File', file);
        // Start timer to get file progress
        const startTimer = setInterval(async () => {
            dispatch(getFileProcessedCount());
        }, 2000);
        try {
            const response = await axios.put(`${API_URL}/fuhfum/uploadmember`, formData);
            console.log('uploadMember RESPONSE: ', response.data);
            clearInterval(startTimer);
            dispatch(setImportSuccessful(true));
            dispatch(setIsLoading(false));
            dispatch(setFileProcessedCount({ Percentage: 0, ProcessedCount: 0, TotalCount: 0 }));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export default fuhfumSlice.reducer;
