import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { fetchIndividualInPersonVisitsSummaryChart, MenuProps, exportIndividualInPersonVisitMembers } from '../WidgetService';
import EngagementSummary from '../../../Models/Widget/EngagementSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { parentUrl } from '../../../utils/constants';
import { getDateFilter } from '../../HedisMetrics/HedisMetricService';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../../utils/timeFormat';
import { generateRandomId } from '../../../utils/common';
import '../Widgets.css';

const IndividualInPersonVisitsSummaryDetail = () => {
    const history = useNavigate();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const {
        individualEngagementDateFilter,
        setIndividualEngagementDateFilter,
        individualEngagementFromDate,
        setIndividualEngagementFromDate,
        individualEngagementToDate,
        setIndividualEngagementToDate,
        individualEngagementSelectedProvider,
        setIndividualEngagementSelectedProvider,
        individualEngagementShowInActiveProviders,
        setIndividualEngagementShowInActiveProviders,
    } = useWidgetContext();
    const [members, setMembers] = useState<EngagementSummary>();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const [loadedPage, setLoadedPage] = useState(false);
    const filteredProviders =
        (individualEngagementShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const dateFilterList = getDateFilter().filter((d) => d.Value !== 'Custom');

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
    }, [isFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (activeProviders?.length) {
            setLoadedPage(true);
        }
    }, [activeProviders]);

    useEffect(() => {
        if (
            loadedPage &&
            (individualEngagementDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(individualEngagementFromDate) && Boolean(individualEngagementToDate)))
        ) {
            const changeFilter = async () => {
                setIsMembersLoading(false);
                const data = await fetchIndividualInPersonVisitsSummaryChart(
                    individualEngagementSelectedProvider,
                    individualEngagementDateFilter,
                    Boolean(individualEngagementFromDate) && individualEngagementDateFilter === TimePeriodFilterEnum.Custom
                        ? new Date(individualEngagementFromDate).toISOString()
                        : '',
                    Boolean(individualEngagementToDate) && individualEngagementDateFilter === TimePeriodFilterEnum.Custom
                        ? new Date(individualEngagementToDate).toISOString()
                        : '',
                    individualEngagementShowInActiveProviders,
                    true
                );
                setMembers(data);
                setIsMembersLoading(true);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (individualEngagementDateFilter !== TimePeriodFilterEnum.Custom && Boolean(individualEngagementFromDate) && Boolean(individualEngagementToDate)) {
            setIndividualEngagementFromDate(null);
            setIndividualEngagementToDate(null);
        }
    }, [
        individualEngagementSelectedProvider,
        individualEngagementDateFilter,
        individualEngagementFromDate,
        individualEngagementToDate,
        setIndividualEngagementFromDate,
        setIndividualEngagementToDate,
        setIsFetched,
        loadedPage,
        individualEngagementShowInActiveProviders,
    ]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const changeFromDate = (date: any) => {
        setIndividualEngagementFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(individualEngagementToDate) || new Date(individualEngagementToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setIndividualEngagementToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setIndividualEngagementToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(individualEngagementFromDate) || new Date(individualEngagementFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setIndividualEngagementFromDate(startDate);
        }
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportIndividualInPersonVisitMembers(
            individualEngagementSelectedProvider,
            individualEngagementDateFilter,
            Boolean(individualEngagementFromDate) && individualEngagementDateFilter === TimePeriodFilterEnum.Custom
                ? new Date(individualEngagementFromDate).toISOString()
                : '',
            Boolean(individualEngagementToDate) && individualEngagementDateFilter === TimePeriodFilterEnum.Custom
                ? new Date(individualEngagementToDate).toISOString()
                : '',
            individualEngagementShowInActiveProviders
        );

        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `EngagementMembers - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setIndividualEngagementShowInActiveProviders(isChecked);
        setIndividualEngagementSelectedProvider('All');
    };

    const columns: GridColDef[] = [
        {
            field: 'Index',
            headerName: '#',
            width: 60,
            sortable: false,
            renderCell: (params) => {
                return `${params.api.getRowIndex(params.id) + 1}`;
            },
        },
        {
            field: 'Name',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DateInfo',
            headerName: 'Visited Date',
            width: 150,
            valueGetter: (params: GridValueGetterParams) => `${Boolean(params.row.DateInfo) ? dateFormatter(new Date(params.row.DateInfo)) : '-'}`,
        },
        {
            field: 'ReasonForVisit',
            headerName: 'Reason For Visit',
            flex: 1,
        },
        {
            field: 'MeansOfEngagement',
            headerName: 'Means Of Engagement',
            flex: 1,
        },
        {
            field: 'Provider',
            headerName: 'Engaged Provider',
            flex: 1,
        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Individual In-Person Member Visits
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={individualEngagementSelectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setIndividualEngagementSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={
                                        <Checkbox
                                            checked={individualEngagementShowInActiveProviders}
                                            onChange={(e) => toggleProviderHandler(e.target.checked)}
                                        />
                                    }
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={individualEngagementDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setIndividualEngagementDateFilter(parseInt(e.target.value))}
                            >
                                {dateFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {parseInt(individualEngagementDateFilter) === TimePeriodFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(individualEngagementFromDate) ? individualEngagementFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(individualEngagementToDate) ? individualEngagementToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={parseInt(individualEngagementDateFilter) === TimePeriodFilterEnum.Custom ? 5 : 8}></Grid>
                    <Grid item xs={1}>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => exportDetail()}
                            variant="contained"
                            color="success"
                            disabled={members?.TotalVisitMembers?.length === 0}
                        >
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isMembersLoading ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container columnSpacing={3}>
                                <Grid item xs={6} textAlign="center">
                                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                        In-Person Visits ({members.InPersonVisitMembers.length})
                                    </Typography>
                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                        <DataGrid
                                            columns={columns}
                                            rows={members.InPersonVisitMembers}
                                            getRowId={(row) => `${row.ItemId}-${row.DateInfo}-${generateRandomId()}`}
                                            paginationMode="server"
                                            hideFooterPagination={true}
                                            hideFooter={true}
                                            onCellClick={(params: any) => {
                                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} textAlign="center">
                                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                        All Visits ({members.TotalVisitMembers.length})
                                    </Typography>
                                    <Grid item xs={12} style={{ height: '67vh', width: '100%' }}>
                                        <DataGrid
                                            columns={columns}
                                            rows={members.TotalVisitMembers}
                                            getRowId={(row) => `${row.ItemId}-${generateRandomId()}`}
                                            paginationMode="server"
                                            hideFooterPagination={true}
                                            hideFooter={true}
                                            onCellClick={(params: any) => {
                                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default IndividualInPersonVisitsSummaryDetail;
