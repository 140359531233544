export enum HealthPlansEnum {
  AZCompleteHealth = 1,
  AZCompleteHealthDescription = "AZ Complete Health",
  BannerUniversityPlan = 2,
  BannerUniversityPlanDescription = "Banner University Plan",
  Care1st = 3,
  Care1stDescription = "Care 1st",
  HealthChoice = 4,
  HealthChoiceDescription = "Health Choice",
  MolinaHealthcareOfArizona = 5,
  MolinaHealthcareOfArizonaDescription = "Molina Healthcare of Arizona",
  MercyCare = 6,
  MercyCareDescription = "Mercy Care",
  UnitedHealthCare = 7,
  UnitedHealthCareDescription = "United Health Care",
  Other = 8,
  OtherDescription = "Other",
}
