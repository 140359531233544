import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, TextField, Divider, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import classes from '../MemberAllergy/Styles';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchLoginSetup, saveLoginSetup } from '../../../store/memberDetail.slice';
import { LoginSetup } from '../../../Models/Member/LoginSetup.model';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { copyObject } from '../../../utils/common';

const tabletFormSchema = Yup.object().shape({
    MemberId: Yup.string().nullable().required('Member is required.'),
    Email: Yup.string().nullable().required('Email is required.'),
    TabletId: Yup.string().nullable().required('Tablet identifier is required.'),
    Password: Yup.string().nullable().required('Password is required.'),
    Sim: Yup.string().nullable(),
    DateOfDelivery: Yup.string().nullable(),
});

const TabletComponent: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { loginSetup, isSaving: isSavingLoginSetup } = useSelector((state: RootState) => state.memberDetail);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { engagementDraft, isSaving: isSavingDocument } = useSelector((state: RootState) => state.documentEngagement);

    const isSaving = isSavingLoginSetup || isSavingDocument;

    useEffect(() => {
        dispatch(fetchLoginSetup(memberId));
    }, [dispatch, memberId]);

    const handleSubmit = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirm = (values: LoginSetup) => {
        dispatch(
            saveLoginSetup(values, () => {
                let data = copyObject(engagementDraft);
                data.SummaryNotes = data.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.Tablet) || [];
                data.SummaryNotes.push({
                    Id: '',
                    CptCode: '',
                    Zcode: '',
                    Note: 'Completed Tablet Information',
                    Type: SummaryNoteTypeEnum.Tablet,
                    IsConfirm: false,
                    OrderBy: 35,
                    DisplayName: 'Tablet',
                });

                dispatch(saveEngagementDraft({ ...data, TabletStatus: StatusEnum.Completed }, true));
            })
        );
    };

    const handleOnSkip = () => {
        dispatch(gotoNextMenu());
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isLoading ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Formik initialValues={loginSetup} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={tabletFormSchema}>
                        {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<LoginSetup>) => (
                            <Form noValidate>
                                <Grid sx={[classes.form, { pt: 2 }]} item className="content-form">
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="h6" component="label">
                                                Tablet
                                            </Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} md={8} sx={{ mt: 3 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        size="small"
                                                        label="Tablet ID"
                                                        required
                                                        variant="outlined"
                                                        name="TabletId"
                                                        fullWidth
                                                        value={values.TabletId}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.TabletId) && Boolean(submitCount)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        size="small"
                                                        label="SIM"
                                                        variant="outlined"
                                                        name="Sim"
                                                        fullWidth
                                                        value={values.Sim}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <DesktopDatePicker
                                                        label="Date Of Delivery"
                                                        value={Boolean(values.DateOfDelivery) ? new Date(values.DateOfDelivery) : null}
                                                        inputFormat="dd-MMM-yyyy"
                                                        minDate={new Date('2017-01-01')}
                                                        onChange={(val) => setFieldValue('DateOfDelivery', val?.toISOString())}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                {...params}
                                                                error={Boolean(errors.DateOfDelivery) && Boolean(submitCount)}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        size="small"
                                                        label="Email / User Name"
                                                        required
                                                        variant="outlined"
                                                        name="Email"
                                                        fullWidth
                                                        value={values.Email}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.Email) && Boolean(submitCount)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        size="small"
                                                        label="Password"
                                                        required
                                                        variant="outlined"
                                                        name="Password"
                                                        fullWidth
                                                        disabled
                                                        value={values.Password}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.Password) && Boolean(submitCount)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item justifyContent="flex-end" className="content-footer">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="mr-3"
                                        disabled={isSaving}
                                        onClick={() => dispatch(gotoPreviousMenu())}
                                        startIcon={<ArrowBackIosIcon />}
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={isSaving}
                                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                                <AlertDialog
                                    open={showSaveAlert}
                                    title="Tablet"
                                    cancelText="Go Back"
                                    okText="Confirm"
                                    onConfirm={() => handleOnConfirm(values)}
                                    onSkip={handleOnSkip}
                                    onClose={() => setShowSaveAlert(false)}
                                    isLoading={isSaving}
                                    message="I confirm and acknowledge that tablet is completed."
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Grid>
    );
};

export default TabletComponent;
