import React, { useState } from 'react';
import { TimePeriodTypeEnum } from '../Enum/TimePeriodTypeEnum';

const AnalyticContext = React.createContext();

export const AnalyticProvider = ({ children }) => {
    const [tcPopulation, setTcPopulation] = useState(0);
    const [tcMemberStatus, setTcMemberStatus] = useState(2);
    const [tcTimePeriod, setTcTimePeriod] = useState(TimePeriodTypeEnum.YRS);
    const [tcPart, setTcPart] = useState(0);
    const [tcPrimaryProvider, setTcPrimaryProvider] = useState('All');
    const [tcSecondaryProvider, setTcSecondaryProvider] = useState('All');
    const [showTcInActivePrimaryTeams, setShowTcInActivePrimaryTeams] = useState(false);
    const [showTcInActiveSecondaryTeams, setShowTcInActiveSecondaryTeams] = useState(false);

    const [acPopulation, setAcPopulation] = useState(0);
    const [acMemberStatus, setAcMemberStatus] = useState(2);
    const [acTimePeriod, setAcTimePeriod] = useState(TimePeriodTypeEnum.YRS);
    const [acPart, setAcPart] = useState(0);
    const [acPrimaryProvider, setAcPrimaryProvider] = useState('All');
    const [acSecondaryProvider, setAcSecondaryProvider] = useState('All');
    const [showAcInActivePrimaryTeams, setShowAcInActivePrimaryTeams] = useState(false);
    const [showAcInActiveSecondaryTeams, setShowAcInActiveSecondaryTeams] = useState(false);

    const [phPopulation, setPhPopulation] = useState(0);
    const [phMemberStatus, setPhMemberStatus] = useState(2);
    const [phTimePeriod, setPhTimePeriod] = useState(TimePeriodTypeEnum.YRS);
    const [phPart, setPhPart] = useState(0);
    const [phPrimaryProvider, setPhPrimaryProvider] = useState('All');
    const [phSecondaryProvider, setPhSecondaryProvider] = useState('All');
    const [showPhInActivePrimaryTeams, setShowPhInActivePrimaryTeams] = useState(false);
    const [showPhInActiveSecondaryTeams, setShowPhInActiveSecondaryTeams] = useState(false);

    const [ffPopulation, setFfPopulation] = useState(0);
    const [ffMemberStatus, setFfMemberStatus] = useState(2);
    const [ffTimePeriod, setFfTimePeriod] = useState(TimePeriodTypeEnum.YRS);
    const [ffPart, setFfPart] = useState(0);
    const [ffPrimaryProvider, setFfPrimaryProvider] = useState('All');
    const [ffSecondaryProvider, setFfSecondaryProvider] = useState('All');
    const [showFfInActivePrimaryTeams, setShowFfInActivePrimaryTeams] = useState(false);
    const [showFfInActiveSecondaryTeams, setShowFfInActiveSecondaryTeams] = useState(false);

    const [bhRtcPopulation, setBhRtcPopulation] = useState(0);
    const [bhRtcMemberStatus, setBhRtcMemberStatus] = useState(2);
    const [bhRtcTimePeriod, setBhRtcTimePeriod] = useState(TimePeriodTypeEnum.YRS);
    const [bhRtcPart, setBhRtcPart] = useState(0);
    const [bhRtcPrimaryProvider, setBhRtcPrimaryProvider] = useState('All');
    const [bhRtcSecondaryProvider, setBhRtcSecondaryProvider] = useState('All');
    const [showBhRtcInActivePrimaryTeams, setShowBhRtcInActivePrimaryTeams] = useState(false);
    const [showBhRtcInActiveSecondaryTeams, setShowBhRtcInActiveSecondaryTeams] = useState(false);

    const [edPopulation, setEdPopulation] = useState(0);
    const [edMemberStatus, setEdMemberStatus] = useState(2);
    const [edTimePeriod, setEdTimePeriod] = useState(TimePeriodTypeEnum.YRS);
    const [edPart, setEdPart] = useState(0);
    const [edPrimaryProvider, setEdPrimaryProvider] = useState('All');
    const [edSecondaryProvider, setEdSecondaryProvider] = useState('All');
    const [showEdInActivePrimaryTeams, setShowEdInActivePrimaryTeams] = useState(false);
    const [showEdInActiveSecondaryTeams, setShowEdInActiveSecondaryTeams] = useState(false);

    const value = {
        tcPopulation,
        setTcPopulation,
        tcMemberStatus,
        setTcMemberStatus,
        tcTimePeriod,
        setTcTimePeriod,
        tcPart,
        setTcPart,
        tcPrimaryProvider,
        setTcPrimaryProvider,
        tcSecondaryProvider,
        setTcSecondaryProvider,
        showTcInActivePrimaryTeams,
        setShowTcInActivePrimaryTeams,
        showTcInActiveSecondaryTeams,
        setShowTcInActiveSecondaryTeams,

        acPopulation,
        setAcPopulation,
        acMemberStatus,
        setAcMemberStatus,
        acTimePeriod,
        setAcTimePeriod,
        acPart,
        setAcPart,
        acPrimaryProvider,
        setAcPrimaryProvider,
        acSecondaryProvider,
        setAcSecondaryProvider,
        showAcInActivePrimaryTeams,
        setShowAcInActivePrimaryTeams,
        showAcInActiveSecondaryTeams,
        setShowAcInActiveSecondaryTeams,

        phPopulation,
        setPhPopulation,
        phMemberStatus,
        setPhMemberStatus,
        phTimePeriod,
        setPhTimePeriod,
        phPart,
        setPhPart,
        phPrimaryProvider,
        setPhPrimaryProvider,
        phSecondaryProvider,
        setPhSecondaryProvider,
        showPhInActivePrimaryTeams,
        setShowPhInActivePrimaryTeams,
        showPhInActiveSecondaryTeams,
        setShowPhInActiveSecondaryTeams,

        ffPopulation,
        setFfPopulation,
        ffMemberStatus,
        setFfMemberStatus,
        ffTimePeriod,
        setFfTimePeriod,
        ffPart,
        setFfPart,
        ffPrimaryProvider,
        setFfPrimaryProvider,
        ffSecondaryProvider,
        setFfSecondaryProvider,
        showFfInActivePrimaryTeams,
        setShowFfInActivePrimaryTeams,
        showFfInActiveSecondaryTeams,
        setShowFfInActiveSecondaryTeams,

        bhRtcPopulation,
        setBhRtcPopulation,
        bhRtcMemberStatus,
        setBhRtcMemberStatus,
        bhRtcTimePeriod,
        setBhRtcTimePeriod,
        bhRtcPart,
        setBhRtcPart,
        bhRtcPrimaryProvider,
        setBhRtcPrimaryProvider,
        bhRtcSecondaryProvider,
        setBhRtcSecondaryProvider,
        showBhRtcInActivePrimaryTeams,
        setShowBhRtcInActivePrimaryTeams,
        showBhRtcInActiveSecondaryTeams,
        setShowBhRtcInActiveSecondaryTeams,

        edPopulation,
        setEdPopulation,
        edMemberStatus,
        setEdMemberStatus,
        edTimePeriod,
        setEdTimePeriod,
        edPart,
        setEdPart,
        edPrimaryProvider,
        setEdPrimaryProvider,
        edSecondaryProvider,
        setEdSecondaryProvider,
        showEdInActivePrimaryTeams,
        setShowEdInActivePrimaryTeams,
        showEdInActiveSecondaryTeams,
        setShowEdInActiveSecondaryTeams,
    };

    return <AnalyticContext.Provider value={value}>{children}</AnalyticContext.Provider>;
};

export const useAnalyticContext = () => React.useContext(AnalyticContext);
