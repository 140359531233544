import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { mapEventData } from '../components/Calendar/Services/CommonCalendarServices';
import { CalendarEventData } from '../components/Calendar/types/CalendarEventData';
import { apiString } from '../utils/constants';
import { formatDateMMDDYYYY } from '../utils/timeFormat';
import { AppThunk } from './store';

/**
 * The state for the calendar slice.
 * Should provide utilities for fetching calendar events under different scenarios
 * These methods should be calendar system agnostic.
 */

interface ICalendarSliceState {
    memberIdForFetchedDefaultAddress: string;
    defaultAddress: string;
    events: CalendarEventData[];
    isEventEditorOpen: boolean;
    selectedDate: Date;
    monthBounds: { firstDate: Date; lastDate: Date };
    isBackdropOpen: boolean;
    isEventPopupOpen: boolean;
}

const initialState: ICalendarSliceState = {
    memberIdForFetchedDefaultAddress: '',
    defaultAddress: '',
    events: [],
    isEventEditorOpen: false,
    selectedDate: new Date(),
    monthBounds: {
        firstDate: undefined,
        lastDate: undefined,
    },
    isBackdropOpen: false,
    isEventPopupOpen: false,
};

const calendar = createSlice({
    name: 'calendar',
    initialState: initialState,
    reducers: {
        _setMemberIdForFetchedDefaultAddress(state, action: PayloadAction<string>) {
            state.memberIdForFetchedDefaultAddress = action.payload;
        },
        _setDefaultAddress(state, action: PayloadAction<string>) {
            state.defaultAddress = action.payload;
        },
        _setEvents(state, action: PayloadAction<CalendarEventData[]>) {
            state.events = action.payload;
        },
    },
});

const { _setEvents, _setDefaultAddress, _setMemberIdForFetchedDefaultAddress } = calendar.actions;

export const setEvents =
    (appointments: CalendarEventData[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setEvents(appointments));
    };

export const setMemberIdForFetchedDefaultAddress =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberIdForFetchedDefaultAddress(memberId));
    };

export const fetchMemberDefaultAddress =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        axios.get<{ fullAddress: string }>(`${apiString}/scheduling/getappointmentdefaultaddress?memberId=${memberId}`).then((response) => {
            if (response.status === 200) {
                dispatch(_setDefaultAddress(response.data?.fullAddress ?? ''));
            } else {
                console.error('Error loading default address');
            }
        });
    };

export const getExternalProviderAppointmentsByNameAndDateRange =
    (firstDate: Date, lastDate: Date, externalProviderName: string): AppThunk =>
    async (dispatch) => {
        axios
            .get(`${apiString}/scheduling/GetExternalProviderAppointmentsByNameAndDateRange`, {
                params: {
                    providerName: externalProviderName,
                    start: formatDateMMDDYYYY(firstDate),
                    end: formatDateMMDDYYYY(lastDate),
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data.map(mapEventData);
                    dispatch(setEvents(data));
                } else {
                    console.error('Error loading appointment data');
                }
            });
    };

export default calendar.reducer;
