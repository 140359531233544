import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    IconButton,
    TextField,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    Typography,
    Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { styled } from '@mui/material/styles';

import { RootState } from '../../reducers';
import classes from './Styles';
import {
    addNextStep,
    editNextSteps,
    removeNextStep,
    setIntegratedCarePlan,
    setNewNextStepName,
    toggleShowNextStepDeleteAlert,
    toggleShowNextStepEditMode,
    addNextStepProgress,
} from '../../store/carePlan.slice';
import AlertDialog from '../AlertDialog/AlertDialog';
import { copyObject } from '../../utils/common';
import { NextStep } from '../../Models/CarePlans/NextStep.model';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: '13px',
    },
}));

const NextSteps: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [selectedNextStepId, setSelectedNextStepId] = useState<string>('');
    const [editedNextStepIds, setEditedNextStepIds] = useState<string[]>([]);
    const dispatch = useDispatch();
    const { integratedCarePlan, newNextStepName, showNextStepDeleteAlert, isSavingNextStep, showNextStepEditMode, isSavingNextStepProgress } = useSelector(
        (state: RootState) => state.carePlan
    );

    const handleOnEditNextStep = (id: string, val: any, index: number) => {
        setEditedNextStepIds([...editedNextStepIds, id]);
        let data = copyObject(integratedCarePlan);
        data.NextSteps[index].Name = val;
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnClickAddNew = () => {
        if (Boolean(newNextStepName)) {
            dispatch(addNextStep(memberId, newNextStepName));
        }
    };

    const handleOnClickSave = () => {
        if (showNextStepEditMode) {
            if (Boolean(editedNextStepIds.length)) {
                dispatch(
                    editNextSteps(
                        memberId,
                        integratedCarePlan.NextSteps.filter((p) => editedNextStepIds.some((e) => e === p.Id))
                    )
                );
            } else {
                dispatch(toggleShowNextStepEditMode(false));
            }
        } else {
            dispatch(toggleShowNextStepEditMode(true));
            setEditedNextStepIds([]);
        }
    };

    const handleOnClickDelete = (nextStep: NextStep) => {
        setSelectedNextStepId(nextStep.Id);
        dispatch(toggleShowNextStepDeleteAlert(true));
    };

    const toggleOnNewNextStep = () => {
        dispatch(setNewNextStepName(''));
        setOpenNew(!openNew);
    };

    const handleOnOpenProgress = (id: string) => {
        let data = copyObject(integratedCarePlan);
        data.NextSteps.forEach((nextStep) => {
            if (nextStep.Id === id) {
                nextStep.IsOpenProgress = !nextStep.IsOpenProgress;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const handleOnChangeNewProgress = (id: string, note: string) => {
        let data = copyObject(integratedCarePlan);
        data.NextSteps.forEach((nextStep) => {
            if (nextStep.Id === id) {
                nextStep.NewProgressNote = note;
            }
        });
        dispatch(setIntegratedCarePlan(data));
    };

    const addNewProgress = (id: string) => {
        const selectedNextStep = integratedCarePlan.NextSteps.find((g) => g.Id === id);
        dispatch(addNextStepProgress(memberId, selectedNextStep.Id, selectedNextStep.NewProgressNote));
    };

    const renderNextStep = (nextStep: NextStep, index: number) => {
        return (
            <React.Fragment key={index}>
                <TableRow key={nextStep.Id} className="pointer">
                    {showNextStepEditMode ? (
                        <TableCell scope="row">
                            <div className="d-flex-row">
                                <span className="count">{index + 1}</span>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    sx={classes.textInput}
                                    fullWidth
                                    name="Name"
                                    value={nextStep.Name}
                                    onChange={(e) => handleOnEditNextStep(nextStep.Id, e.target.value, index)}
                                />
                            </div>
                        </TableCell>
                    ) : (
                        <TableCell scope="row" onClick={() => handleOnOpenProgress(nextStep.Id)}>
                            <div className="d-flex-row">
                                <span className="count">{index + 1}</span> {nextStep.Name}
                            </div>
                        </TableCell>
                    )}
                    {showNextStepEditMode ? (
                        <TableCell width="80px">
                            <IconButton onClick={() => handleOnClickDelete(nextStep)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </TableCell>
                    ) : (
                        <TableCell width="140px"></TableCell>
                    )}
                </TableRow>
                <TableRow style={{ backgroundColor: 'transparent' }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                        <Collapse in={nextStep.IsOpenProgress} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1, p: 0 }}>
                                <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                                    <TableBody>
                                        {nextStep.Progress?.length
                                            ? nextStep.Progress.map((progress) => (
                                                  <TableRow key={progress.Id}>
                                                      <TableCell>
                                                          <IconButton>
                                                              <ThumbUpOffAltIcon />
                                                          </IconButton>
                                                          {progress.Name}
                                                      </TableCell>
                                                  </TableRow>
                                              ))
                                            : null}
                                        <TableRow>
                                            <TableCell scope="row">
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    sx={classes.textInput}
                                                    fullWidth
                                                    name="Name"
                                                    placeholder="Add Progress Entry"
                                                    value={nextStep.NewProgressNote}
                                                    autoFocus
                                                    onChange={(e) => handleOnChangeNewProgress(nextStep.Id, e.target.value)}
                                                    onKeyUp={(e) => e.key === 'Enter' && addNewProgress(nextStep.Id)}
                                                    InputProps={{
                                                        endAdornment: isSavingNextStepProgress ? <CircularProgress size={15} /> : null,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    return (
        <>
            <TableContainer sx={classes.tableContainer}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack direction="row">
                                    <Typography sx={classes.planTitle}>Next Steps</Typography>
                                    <BootstrapTooltip title="Reminder, these are the member’s next steps, not the staff’s next steps" placement="top">
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </BootstrapTooltip>
                                </Stack>
                            </TableCell>
                            <TableCell width="140px" align="right">
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={handleOnClickSave}>
                                        {isSavingNextStep ? <CircularProgress size={18} /> : showNextStepEditMode ? <DoneIcon /> : <EditIcon />}
                                    </Button>
                                    <Button type="submit" variant="outlined" size="small" sx={classes.iconMainButton} onClick={toggleOnNewNextStep}>
                                        {openNew ? <KeyboardArrowUpIcon /> : <AddIcon />}
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                {integratedCarePlan.NextSteps?.length ? (
                    <Table size="small">
                        <TableBody>{integratedCarePlan.NextSteps?.map(renderNextStep)}</TableBody>
                    </Table>
                ) : !openNew ? (
                    <div className="no-data-container">No next steps to display</div>
                ) : null}
            </TableContainer>
            <Collapse in={openNew} timeout="auto" unmountOnExit>
                <TableContainer sx={classes.tableContainer}>
                    <Table size="small" sx={{ mb: 2, mt: 1.3 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        sx={classes.textInput}
                                        fullWidth
                                        name="Name"
                                        placeholder="Enter new next step here"
                                        value={newNextStepName}
                                        autoFocus
                                        onChange={(e) => dispatch(setNewNextStepName(e.target.value))}
                                    />
                                </TableCell>
                                <TableCell width="140px">
                                    <Box sx={classes.actionButtons}>
                                        <IconButton onClick={handleOnClickAddNew} disabled={!Boolean(newNextStepName)}>
                                            {isSavingNextStep ? <CircularProgress size={18} /> : <SaveIcon color="primary" />}
                                        </IconButton>
                                        <IconButton onClick={toggleOnNewNextStep}>
                                            <HighlightOffSharpIcon color="error" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
            <AlertDialog
                open={showNextStepDeleteAlert}
                isLoading={isSavingNextStep}
                autoHideOnConfirmed={false}
                okText="Delete"
                onConfirm={() => dispatch(removeNextStep(memberId, selectedNextStepId))}
                onClose={() => dispatch(toggleShowNextStepDeleteAlert(false))}
                message="Are you sure want to delete this Next Step?"
            />
        </>
    );
};

export default NextSteps;
