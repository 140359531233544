import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';

const DeleteResourceItemModal: React.FC<IBasicModalProps & { resourceName: string }> = (props) => {
    const { open, onClose, onSuccess, resourceName } = props;
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>{`Delete ${resourceName ?? '!!No Name Listed!!'} from the request's list of resources?`}</DialogTitle>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" color="error" onClick={onSuccess}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteResourceItemModal;
