import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { CallListTabs, closeScheduleModalHandler, setShowScheduleAppointment } from '../../../store/onboardingWorkflow.slice';
import moment from 'moment';
import OnboardingCalendar from '../../Calendar/OnboardingCalendar/OnboardingCalendar';
import ICallItem from '../../../Models/OnboardingWorkflow/CallItem.model';

const ScheduleAppointment = (props) => {
    const dispatch = useDispatch();
    const { callItem, confirmationCallItem, selectedCallListTab, showScheduleAppointment } = useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    // callItem was defined with const, thereby making its properties immutable, requiring the use of structuredClone
    let item: ICallItem = isConfirmCall ? structuredClone(confirmationCallItem) : structuredClone(callItem);

    item.AppointmentDateTime = item.AppointmentId ? item.AppointmentDateTime : new Date();
    const DOB = moment(new Date(item?.DateOfBirth)).format('MM-DD-YYYY');
    const scheduleAppointmentPage = (
        <>
            <Stack direction={'row'} justifyContent={'center'} paddingX={2} spacing={2}>
                <Typography variant="body1">
                    {item?.LastName}, {item?.FirstName}
                </Typography>
                <Typography variant="body1">{DOB}</Typography>
            </Stack>
            <DialogContent>{<OnboardingCalendar callItem={item} />}</DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button variant="contained" sx={{ minWidth: '120px' }} onClick={() => dispatch(closeScheduleModalHandler(item, isConfirmCall))}>
                    Close
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog
            open={showScheduleAppointment}
            fullWidth
            onClose={() => dispatch(setShowScheduleAppointment(false))}
            disableEnforceFocus
            maxWidth={'sm'}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>{isConfirmCall ? 'Confirm' : 'Schedule'} Appointment</DialogTitle>
            {scheduleAppointmentPage}
        </Dialog>
    );
};

export default ScheduleAppointment;
