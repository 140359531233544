import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';
import classes from '../Styles';
import { getChpDiagnosis } from '../../../store/encounter.slice';

const ChpDiagnosis: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const { diagnosisFromCHP, isFetchingDisgnosys } = useSelector((state: RootState) => state.encounter);

    useEffect(() => {
        dispatch(getChpDiagnosis(memberId));
    }, [dispatch, memberId]);

    return (
        <Paper elevation={3} sx={classes.paper}>
            <TableContainer sx={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="100%">
                                <Typography variant="h6">Diagnosis</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {diagnosisFromCHP.length ? (
                            diagnosisFromCHP.map((diagnosis, index) => (
                                <TableRow key={`diagnosis_${index}`} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        <Typography sx={{ py: 1 }}>{`${diagnosis.Code} ${diagnosis.Name}`}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={classes.noDataFound}>
                                    {isFetchingDisgnosys ? <CircularProgress size={30} /> : <p>No Diagnosis found</p>}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ChpDiagnosis;
