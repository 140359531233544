import { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Grid, Stack, Typography, Button, Link, Snackbar } from '@mui/material';
import { setValidFile, validateFile, getICD10BackgroundTask, downloadSample, setOpenAlert } from '../../store/members.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Loading from '../Shared/Loading';
import moment from 'moment';
import Launch from '@mui/icons-material/Launch';

const ICD10 = () => {
    const dispatch = useDispatch();
    const { validFile, alertMessage, openAlert, isValidating } = useSelector((state: RootState) => state.members);
    const [file, setFile] = useState<File>();
    const { icd10FileUploadBackGroundTask } = useSelector((state: RootState) => state.members);

    useEffect(() => {
        dispatch(getICD10BackgroundTask());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setOpenAlert(false));
    };

    return (
        <>
            {<Loading message={'Loading...'} />}
            <Snackbar open={openAlert} onClose={handleCloseAlert} autoHideDuration={8000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseAlert} severity={'error'} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <>
                <Grid container p={4}>
                    <Grid item xs={12}>
                        <Stack paddingTop={2} alignItems="center">
                            <Typography variant="h4" component="h2">
                                Upload ICD10
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} paddingTop={3}>
                        <Stack display="flex" alignItems="center">
                            <Stack alignItems="center" sx={{ width: '500px' }}>
                                <Typography variant="subtitle1">
                                    The ICD-10 file can be downloaded from the{' '}
                                    <Link
                                        href="https://www.cms.gov/medicare/coding-billing/icd-10-codes"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="hover"
                                        sx={{ marginRight: '5px' }}
                                    >
                                        <Launch sx={{ marginBottom: '-5px' }} />
                                        CMS
                                    </Link>
                                    site. On the left side of the page, find the "ICD-10-CM" tab and click on the tab for the year you want to upload, such as
                                    the "2024 ICD-10-CM" tab. At the end of the page, in the Download section, you will find a link named "Code Descriptions in
                                    Tabular Order," which is a zip file. Inside this zip file, there is a file named "icd10cm_order_2024.txt". We need to
                                    convert this text file to an Excel file and upload it here.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack display="flex" alignItems="center" paddingTop={3}>
                            <Button className="button-120" variant="contained" color="success" onClick={() => dispatch(downloadSample())}>
                                Download Sample
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} paddingTop={3}>
                        <Stack display="flex" alignItems="center">
                            <Stack alignItems="center" p={4} sx={{ width: '400px', border: '3px dashed #c5c5c5', borderRadius: '5px' }}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <FileUploadOutlinedIcon color="disabled" sx={{ fontSize: '50px' }} />
                                </Stack>
                                <Stack direction={'row'} spacing={2} alignItems={'center'} paddingTop={2}>
                                    <form id={'upload-form'}>
                                        <Button
                                            className="button-120"
                                            id="upload-btn"
                                            variant="contained"
                                            component="label"
                                            disabled={icd10FileUploadBackGroundTask?.IsRunning}
                                        >
                                            Browse
                                            <input
                                                id="file"
                                                name="File"
                                                onChange={(e) => {
                                                    setFile(e.target.files[0]);
                                                    dispatch(setValidFile(true));
                                                    e.target.value = null;
                                                }}
                                                type="file"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                hidden
                                            />
                                        </Button>
                                    </form>
                                </Stack>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    {validFile && <Typography variant="body1">{file?.name || 'No file selected'}</Typography>}
                                    {!validFile && (
                                        <Typography variant="h6" color="error">
                                            Invalid File
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} paddingTop={5}>
                        <Stack alignItems="center">
                            <Button
                                className="button-120"
                                color="success"
                                variant="contained"
                                disabled={!file || icd10FileUploadBackGroundTask?.IsRunning}
                                onClick={() => dispatch(validateFile(file))}
                            >
                                Upload
                            </Button>
                        </Stack>
                    </Grid>
                    {isValidating && (
                        <Grid item xs={12} paddingTop={3}>
                            <Stack alignItems="center">
                                <Typography variant="h6" color="gray">
                                    Validating...
                                </Typography>
                            </Stack>
                        </Grid>
                    )}
                    {!isValidating &&
                        Boolean(icd10FileUploadBackGroundTask) &&
                        Boolean(icd10FileUploadBackGroundTask.UpdatedOn) &&
                        !icd10FileUploadBackGroundTask.IsRunning && (
                            <Grid item xs={12} paddingTop={2}>
                                <Stack alignItems="center">
                                    <Typography variant="h6">
                                        Last Updated On :&nbsp;
                                        {moment(icd10FileUploadBackGroundTask.UpdatedOn).isValid()
                                            ? moment(icd10FileUploadBackGroundTask.UpdatedOn).format('MM/DD/yyyy hh:mm a')
                                            : '-'}
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                    {!isValidating && icd10FileUploadBackGroundTask?.IsRunning && (
                        <Grid item xs={12} paddingTop={3}>
                            <Stack alignItems="center">
                                <Typography variant="h6" color="red">
                                    The ICD-10 file has started uploading. It will take some time to complete. Please check back later.
                                </Typography>
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </>
        </>
    );
};

export default ICD10;
