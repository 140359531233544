import { MbscCalendarEvent } from '@mobiscroll/react';
import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setIsEventViewerOpen, swapAppointments } from '../../../store/providerRotation.slice';
import { CalendarEventData } from '../../Calendar/types/CalendarEventData';
import SwapList from './SwapList';
import CalendarEventEditModal from '../../Calendar/Modals/CalendarEventEditModal';
import Appointment from '../../../Models/Scheduling/Appointment.model';

export type ProviderAppointmentList = {
    name: string;
    eventList: MbscCalendarEvent[];
} | null;

export interface IAppointmentSwapProps {
    onSuccessfulSwap?: () => void;
    onFailedSwap?: () => void;
}

const AppointmentSwap = (props: IAppointmentSwapProps) => {
    const dispatch = useDispatch();
    const { events, providerOne, providerTwo, selectedEvent, isEventViewerOpen } = useSelector((state: RootState) => state.providerRotationSlice);

    const providerOneList: CalendarEventData[] = useMemo(() => {
        return events.filter((event) => event.resource === providerOne?.ProviderId) as unknown as CalendarEventData[];
    }, [events, providerOne]);
    const providerTwoList: CalendarEventData[] = useMemo(() => {
        return events.filter((event) => event.resource === providerTwo?.ProviderId) as unknown as CalendarEventData[];
    }, [events, providerTwo]);
    if (providerOne === null || providerTwo === null) return null;
    return (
        <Box
            style={{
                padding: 2,
                minWidth: 650,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
            component={Paper}
        >
            {selectedEvent !== null && (
                <CalendarEventEditModal
                    eventData={selectedEvent}
                    canEdit={false}
                    canCreate={false}
                    memberId={selectedEvent.MemberObjectId ?? ''}
                    onUpdateEventSuccess={function (eventDate: Appointment): void {
                        throw new Error('Function not implemented.');
                    }}
                    onCreateEventSuccess={function (eventDate: Appointment, rescheduledEvent?: CalendarEventData): void {
                        throw new Error('Function not implemented.');
                    }}
                    onRescheduleEvent={function (eventDate: CalendarEventData): void {
                        throw new Error('Function not implemented.');
                    }}
                    setLoadingOverlay={function (isLoading: boolean): void {
                        throw new Error('Function not implemented.');
                    }}
                    eventList={[]}
                    open={isEventViewerOpen}
                    onClose={() => {
                        dispatch(setIsEventViewerOpen(false));
                    }}
                />
            )}
            <Stack spacing={1}>
                <Typography variant={'h5'} style={{ textAlign: 'center' }}>
                    Compare Provider Appointments
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    style={{ marginBottom: 5 }}
                    onClick={() => {
                        dispatch(swapAppointments());
                    }}
                >
                    Swap Selected Appointments
                </Button>
            </Stack>

            <Divider />

            <Box
                style={{
                    overflow: 'auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    style={{
                        flexGrow: 1,
                        overflow: 'auto',
                        width: '100%',
                    }}
                >
                    <SwapList providerName={providerOne.ProviderName} eventList={providerOneList} />
                    <Divider orientation="vertical" flexItem />
                    <SwapList providerName={providerTwo.ProviderName} eventList={providerTwoList} />
                </Stack>
            </Box>
        </Box>
    );
};

export default AppointmentSwap;
