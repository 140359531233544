import { Fab, Grid, Tab, Tabs } from '@mui/material';
import classes from './Styles';
import Diagnosises from './Diagnosis/Diagnosis';
import ChpDiagnosis from './Diagnosis/ChpDiagnosis';
import { KeyboardArrowUp } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ClinicalData = (props: any) => {
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const [showScrollerButton, setShowScrollerButton] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const scrolled = document.documentElement.scrollTop;
            setShowScrollerButton(scrolled > 300);
        });
    }, []);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <Grid container spacing={2} sx={classes.container}>
                <Grid item xs={12}>
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={handleTabChange}
                        aria-label="disabled tabs example"
                        centered
                        sx={{ pb: 3 }}
                    >
                        <Tab label="Clinical Diagnosis" />
                        <Tab label="CHP Diagnosis" />
                    </Tabs>
                    {selectedTab === 0 ? <Diagnosises memberId={memberId} /> : null}
                    {selectedTab === 1 ? <ChpDiagnosis memberId={memberId} /> : null}
                </Grid>
                {showScrollerButton && (
                    <Fab onClick={scrollToTop} sx={classes.scrollTopButton} color="primary" size="small">
                        <KeyboardArrowUp />
                    </Fab>
                )}
            </Grid>
        </>
    );
};

export default ClinicalData;
