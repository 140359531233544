export enum RequestTypeEnum {
    All = 0,
    Medications = 1,
    Transportation = 2,
    FollowUpAppointment = 3,
    DME = 4,
    Housing = 5,
    MedicalRecords = 6,
    FoodAssistance = 7,
    Referrals = 8,
    RentalAssistance = 9,
    UtilityAssistance = 10,
    ClothingOrHouseholdItems = 11,
    Dental = 12,
    TeleMonitoringEquipment = 13,
    Others = 14,
    PHQ9 = 15,
    SDOH = 16,
    HraFollowUp = 17,
    PreventativeHealthAdvice = 18,
    FuhFumFollowUp = 19,
    DemographicResearch = 20,
    Hedis = 21,
    HRAReferralToCHPFullProgram = 22,
    HRASchedulePCPVisit = 23,
    HRAScheduleBHFollowUp = 24,
}
