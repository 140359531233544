import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';
import { MemberDevices } from '../Models/VitalSigns/MemberDevices.model';
import { VitalSignParameter } from '../Models/VitalSigns/VitalSignParameter.model';
import { saveEngagementDraft } from './documentEngagement.slice';
import { StatusEnum } from '../Enum/StatusEnum';

const memberVitalSigns = createSlice({
    name: 'memberVitalSigns',
    initialState: {
        startDate: new Date(),
        endDate: new Date(),
        type: '0',
        rowData: [],
        priority: '3',
        deviceType: '',
        memberDevices: new MemberDevices(),
        vitalSignParameters: [] as VitalSignParameter[],
        isSaving: false,
    },
    reducers: {
        _setStartDate(state, action) {
            state.startDate = action.payload;
        },
        _setEndDate(state, action) {
            state.endDate = action.payload;
        },
        _setType(state, action) {
            state.type = action.payload;
        },
        _setRowData(state, action) {
            state.rowData = action.payload;
        },
        _setPriority(state, action) {
            state.priority = action.payload;
        },
        _setDeviceType(state, action) {
            state.deviceType = action.payload;
        },
        _setMemberDevices(state, action) {
            state.memberDevices = action.payload;
        },
        _setVitalSignParameters(state, action) {
            state.vitalSignParameters = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
    },
});

const { _setStartDate, _setEndDate, _setType, _setRowData, _setPriority, _setDeviceType, _setMemberDevices, _setVitalSignParameters, _toggleIsSaving } =
    memberVitalSigns.actions;

export const setStartDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setStartDate(params));
    };

export const setEndDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setEndDate(params));
    };

export const setType =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setType(params));
    };

export const setRowData =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setRowData(params));
    };

export const setPriority =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setPriority(params));
    };

export const setDeviceType =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setDeviceType(params));
    };

export const fetchMemberVitalSignsReport =
    (startDate: Date, endDate: Date, type: string, priority: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/vitalsigns/getvitalsignsbydaterange?start=${dateFormatter(startDate)}&end=${dateFormatter(endDate)}&type=${type}&priority=${priority}`
        );
        dispatch(setRowData(response.data));
        dispatch(setIsLoading(false));
    };

export const exportMemberVitalSignsReport =
    (startDate: Date, endDate: Date, type: string, priority: string): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/vitalsigns/exportvitalsigns?start=${dateFormatter(startDate)}&end=${dateFormatter(endDate)}&type=${type}&priority=${priority}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export const fetchMemberDevices =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/vitalsigns/getmemberdevices`, { params: { memberId } });
        const memberDevices = response.data as MemberDevices;

        if (
            !Boolean(memberDevices.BpCuff?.HasDevice) &&
            !Boolean(memberDevices.PulseOX?.HasDevice) &&
            !Boolean(memberDevices.Scale?.HasDevice) &&
            !Boolean(memberDevices.Glucometer?.HasDevice)
        ) {
            dispatch(_setMemberDevices({ ...memberDevices, NoDevices: true }));
            dispatch(setIsLoading(false));
        } else {
            dispatch(_setMemberDevices(memberDevices));
            dispatch(fetchVitalsignParameters(memberId));
        }
    };

export const fetchVitalsignParameters =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/vitalsigns/getvitalsignparameters?memberId=${memberId}`);
        dispatch(_setVitalSignParameters(response.data?.VitalSignParameters || []));
        dispatch(setIsLoading(false));
    };

export const saveVitalsignparameters =
    (vitalSignParameters: VitalSignParameter[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        await axios.put(`${API_URL}/vitalsigns/insertvitalsignparameters`, { VitalSignParameters: vitalSignParameters });
        const engagementDraft = getState().documentEngagement.engagementDraft;
        dispatch(saveEngagementDraft({ ...engagementDraft, RemoteMonitoringStatus: StatusEnum.Completed }, true));

        dispatch(_toggleIsSaving(false));
    };

export const setVitalSignParameters =
    (vitalSignParameters: VitalSignParameter[]): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setVitalSignParameters(vitalSignParameters));
    };

export default memberVitalSigns.reducer;
