import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slide,
    FormControlLabel,
    IconButton,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    Typography,
    TextField,
    Checkbox,
    ListSubheader,
    ListItemText,
    FormHelperText,
    Popover,
    Stack,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';

import { RootState } from '../../../reducers';
import classes from '../Styles';
import { MemberEducationModel } from '../../../Models/MemberEducations/MemberEducation.model';
import { Audiences, LevelOfTeachbacks, LevelOfUnderstandings, EducationTimeDurations, EducationTypes } from '../../../utils/mappings';
import { saveMemberEducation, toggleShowEducationSessionModal } from '../../../store/memberEducation.slice';
import { EngagementDraft } from '../../../Models/DocumentEngagements/EngagementDraft.model';
import { copyObject } from '../../../utils/common';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { SummaryNote } from '../../../Models/DocumentEngagements/SummaryNote.model';
import { EducationTimeDurationEnum } from '../../../Enum/EducationTimeDurationEnum';
import { AudienceEnum } from '../../../Enum/AudienceEnum';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { StatusEnum } from '../../../Enum/StatusEnum';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { LevelOfUnderstandingEnum } from '../../../Enum/LevelOfUnderstandingEnum';
import { LevelOfTeachbackEnum } from '../../../Enum/LevelOfTeachbackEnum';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const educationSessionFormSchema = Yup.object().shape({
    Type: Yup.mixed().nullable().required('Type of Education is required.'),
    Audience: Yup.array().nullable().min(1, 'Audience is required.'),
    LevelOfUnderstanding: Yup.string().nullable().required('Level of Understanding is required.'),
    LevelOfTeachback: Yup.string().nullable().required('Level of Teachback is required.'),
    TimeDuration: Yup.string().nullable().required('Time Duration is required.'),
    Notes: Yup.string().nullable().required('Notes is required.'),
});

const AddOrUpdateEducationSession: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [anchorInfoEl, setAnchorInfoEl] = React.useState<HTMLButtonElement | null>(null);
    const [infoMessage, setInfoMessage] = React.useState<string>('');
    const [showSuccessAlert, setShowSuccessAlert] = React.useState<boolean>(false);
    const [successMessage, setSuccessMessage] = React.useState<string>('');
    const { categories, showEducationSessionModal, selectedMemberEducation, isSaving } = useSelector((state: RootState) => state.memberEducation);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const handleOnMedicationModal = () => dispatch(toggleShowEducationSessionModal(false));

    const handleSubmitForm = (values: MemberEducationModel) => {
        dispatch(saveMemberEducation({ ...values, MemberId: memberId }, (response) => onSuccess(response, !Boolean(values.Id))));
    };

    const onSuccess = (memberEducation: MemberEducationModel, isNew = false) => {
        if (
            memberEducation.LevelOfUnderstanding === LevelOfUnderstandingEnum.Fair ||
            memberEducation.LevelOfUnderstanding === LevelOfUnderstandingEnum.Poor ||
            memberEducation.LevelOfTeachback === LevelOfTeachbackEnum.Fair ||
            memberEducation.LevelOfTeachback === LevelOfTeachbackEnum.Poor
        ) {
            setSuccessMessage('Schedule within 1 month to have a F/U Education Session');
            setShowSuccessAlert(true);
        } else if (
            memberEducation.LevelOfUnderstanding === LevelOfUnderstandingEnum.Declined ||
            memberEducation.LevelOfTeachback === LevelOfTeachbackEnum.Declined
        ) {
            setSuccessMessage('Schedule within 2 months to have a follow up phone call to assess for changes');
            setShowSuccessAlert(true);
        }

        let data = copyObject(engagementDraft) as EngagementDraft;
        if (isNew) {
            data.EducationSessionIds = data.EducationSessionIds || [];
            data.EducationSessionIds.push(memberEducation.Id);
        }

        if (memberEducation.Notes) {
            data.ProgressNotes = data.ProgressNotes || '';
            data.ProgressNotes += '<p>';
            data.ProgressNotes += memberEducation.Notes;
            data.ProgressNotes += '</p>';
        }

        data.SummaryNotes = data.SummaryNotes.filter((s) => s.Id !== memberEducation.Id);
        if (memberEducation.WereMaterialsSupplied) {
            const summaryNote = {
                Id: memberEducation.Id,
                CptCode: '99071',
                Zcode: '',
                Note: `Education materials provided for ${memberEducation.Type?.Name}`,
                Type: SummaryNoteTypeEnum.Education,
                IsConfirm: false,
                OrderBy: 36,
                DisplayName: 'Education',
            } as SummaryNote;

            data.SummaryNotes.push(summaryNote);
        }

        memberEducation.Audience?.forEach((aud) => {
            const summaryNote = prepareSummaryNotes(memberEducation, aud);
            data.SummaryNotes.push(summaryNote);
        });
        data.EducationStatus = data.EducationStatus || StatusEnum.InProgress;
        dispatch(saveEngagementDraft(data));
    };

    function prepareSummaryNotes(memberEducation: MemberEducationModel, audience: string) {
        let cptCode = '';
        if (
            memberEducation.Type.Id === EducationTypes.AsthmaCOPD.Id ||
            memberEducation.Type.Id === EducationTypes.CHF.Id ||
            memberEducation.Type.Id === EducationTypes.Diabetes.Id ||
            memberEducation.Type.Id === EducationTypes.Hypertension.Id ||
            memberEducation.Type.Id === EducationTypes.Other.Id
        ) {
            cptCode = '98960';
        } else if (memberEducation.Type.Id === EducationTypes.CopingSkills.Id) {
            cptCode = '99071';
        } else if (memberEducation.Type.Id === EducationTypes.HarmReductionSafetyPlan.Id) {
            if (memberEducation.TimeDuration === EducationTimeDurationEnum.LessThan11Minutes) {
                cptCode = '99401';
            } else if (
                memberEducation.TimeDuration === EducationTimeDurationEnum.From11To19Minutes ||
                memberEducation.TimeDuration === EducationTimeDurationEnum.From20To30Minutes
            ) {
                cptCode = '99402';
            } else if (memberEducation.TimeDuration === EducationTimeDurationEnum.GreaterThan301Minutes) {
                cptCode = '99403';
            }
        } else if (memberEducation.Type.Id === EducationTypes.HealthyNutrition.Id) {
            cptCode = '99860';
        } else if (memberEducation.Type.Id === EducationTypes.SmokingCessation.Id) {
            if (memberEducation.TimeDuration === EducationTimeDurationEnum.LessThan11Minutes) {
                cptCode = '99406';
            } else {
                cptCode = '99407';
            }
        } else if (memberEducation.Type.Id === EducationTypes.AdvanceCarePlanning.Id) {
            cptCode = '99497';
        } else if (memberEducation.Type.Id === EducationTypes.InitialEducation.Id) {
            if (audience === AudienceEnum.MemberDescription) cptCode = '99453';
            else cptCode = '99457';
        }

        let timeDetail = '';
        if (memberEducation.TimeDuration === EducationTimeDurationEnum.LessThan11Minutes) {
            timeDetail = EducationTimeDurationEnum.LessThan11MinutesDescription;
        } else if (memberEducation.TimeDuration === EducationTimeDurationEnum.From11To19Minutes) {
            timeDetail = EducationTimeDurationEnum.From11To19MinutesDescription;
        } else if (memberEducation.TimeDuration === EducationTimeDurationEnum.From20To30Minutes) {
            timeDetail = EducationTimeDurationEnum.From20To30MinutesDescription;
        } else if (memberEducation.TimeDuration === EducationTimeDurationEnum.GreaterThan301Minutes) {
            timeDetail = EducationTimeDurationEnum.GreaterThan301MinutesDescription;
        }

        return {
            Id: memberEducation.Id,
            CptCode: cptCode,
            Zcode: '',
            Note: `${timeDetail} ${memberEducation.Type.Name} Education Session with ${audience}`,
            Type: SummaryNoteTypeEnum.Education,
            IsConfirm: false,
            OrderBy: 36,
            DisplayName: 'Education',
        } as SummaryNote;
    }

    return (
        <React.Fragment>
            <Dialog
                disableScrollLock
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus
                open={showEducationSessionModal}
                maxWidth="xl"
                sx={[
                    {
                        scrollPaper: classes.topScrollPaper,
                        paperScrollBody: classes.topPaperScrollBody,
                    },
                    classes.form,
                ]}
                scroll="body"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleOnMedicationModal}
            >
                {showEducationSessionModal ? (
                    <Formik
                        initialValues={selectedMemberEducation}
                        enableReinitialize={true}
                        onSubmit={handleSubmitForm}
                        validationSchema={educationSessionFormSchema}
                    >
                        {({ values, setFieldValue, errors, handleChange, handleSubmit, touched, submitCount }: FormikProps<MemberEducationModel>) => (
                            <Form noValidate>
                                <DialogTitle>
                                    <span>Education Sessions</span>
                                </DialogTitle>
                                <DialogContent dividers sx={{ width: '600px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6}>
                                            <FormControl fullWidth required error={touched.Type && Boolean(errors.Type)}>
                                                <InputLabel>Type of Education</InputLabel>
                                                <Select
                                                    value={Boolean(values.Type?.Id) ? values.Type.Id : ''}
                                                    label="Type of Education"
                                                    name="Type"
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            categories.forEach((category) => {
                                                                const selectedOption = category.Options.find((p) => p.Id === e.target.value);
                                                                if (selectedOption) {
                                                                    setFieldValue(e.target.name, selectedOption);
                                                                }
                                                            });
                                                        } else {
                                                            setFieldValue(e.target.name, null);
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value="">Select Type</MenuItem>
                                                    {categories.map((category, index) => [
                                                        <ListSubheader>{category.Category}</ListSubheader>,
                                                        category.Options.map((option, opIndex) => (
                                                            <MenuItem
                                                                key={`category_${option.Id}_${index}_${opIndex}`}
                                                                value={option.Id}
                                                                style={{ marginLeft: '20px' }}
                                                            >
                                                                {option.Name}
                                                            </MenuItem>
                                                        )),
                                                    ])}
                                                </Select>
                                                <FormHelperText error>{touched.Type && Boolean(errors.Type) && String(errors.Type)}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6}>
                                            <FormControl fullWidth required error={touched.Audience && Boolean(errors.Audience)}>
                                                <InputLabel>Audience</InputLabel>
                                                <Select
                                                    value={Boolean(values.Audience) ? values.Audience : ''}
                                                    label="Audience"
                                                    name="Audience"
                                                    renderValue={(selected) => selected.join(', ')}
                                                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                                                    multiple
                                                >
                                                    {Audiences.map((type) => (
                                                        <MenuItem key={type.Id} value={type.Name}>
                                                            <Checkbox checked={values.Audience?.some((a) => a === type.Name)} />
                                                            <ListItemText primary={type.Name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error>{touched.Audience && errors.Audience}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row" alignItems="flex-start">
                                                <FormControl fullWidth required error={touched.LevelOfUnderstanding && Boolean(errors.LevelOfUnderstanding)}>
                                                    <InputLabel>Level of Understanding</InputLabel>
                                                    <Select
                                                        value={values.LevelOfUnderstanding}
                                                        label="Level of Understanding"
                                                        name="LevelOfUnderstanding"
                                                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                                                    >
                                                        {LevelOfUnderstandings.map((type) => (
                                                            <MenuItem key={type.Id} value={type.Id}>
                                                                {type.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error>{touched.LevelOfUnderstanding && errors.LevelOfUnderstanding}</FormHelperText>
                                                </FormControl>
                                                <IconButton
                                                    onClick={(e) => {
                                                        setAnchorInfoEl(e.currentTarget);
                                                        setInfoMessage('Readiness to self-manage based on education');
                                                    }}
                                                    sx={classes.infoIconButton}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row" alignItems="flex-start">
                                                <FormControl fullWidth required error={touched.LevelOfTeachback && Boolean(errors.LevelOfTeachback)}>
                                                    <InputLabel>Level of Teachback</InputLabel>
                                                    <Select
                                                        value={values.LevelOfTeachback}
                                                        label="Level of Teachback"
                                                        name="LevelOfTeachback"
                                                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                                                    >
                                                        {LevelOfTeachbacks.map((type) => (
                                                            <MenuItem key={type.Id} value={type.Id}>
                                                                {type.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText error>{touched.LevelOfTeachback && errors.LevelOfTeachback}</FormHelperText>
                                                </FormControl>
                                                <IconButton
                                                    onClick={(e) => {
                                                        setAnchorInfoEl(e.currentTarget);
                                                        setInfoMessage('Ability to repeat skills from education');
                                                    }}
                                                    sx={classes.infoIconButton}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth required error={touched.TimeDuration && Boolean(errors.TimeDuration)}>
                                                <InputLabel>Time Duration</InputLabel>
                                                <Select
                                                    value={values.TimeDuration}
                                                    label="Time Duration"
                                                    name="TimeDuration"
                                                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                                                >
                                                    {EducationTimeDurations.map((type) => (
                                                        <MenuItem key={type.Id} value={type.Id}>
                                                            {type.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error>{touched.TimeDuration && errors.TimeDuration}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                style={{ paddingLeft: 10 }}
                                                control={
                                                    <Checkbox
                                                        checked={values.WereMaterialsSupplied}
                                                        name="WereMaterialsSupplied"
                                                        onChange={(e) => setFieldValue(e.target.name, e.target.checked)}
                                                    />
                                                }
                                                label="Were education materials or supplies provided?"
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                label="Notes"
                                                variant="outlined"
                                                size="small"
                                                value={values.Notes}
                                                name="Notes"
                                                onChange={handleChange}
                                                multiline
                                                rows={5}
                                                error={touched.Notes && Boolean(errors.Notes)}
                                                helperText={touched.Notes && errors.Notes}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                        disabled={isSaving}
                                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                                    >
                                        Save
                                    </Button>
                                    <Button type="button" onClick={handleOnMedicationModal} variant="outlined" disabled={isSaving}>
                                        Cancel
                                    </Button>
                                </DialogActions>

                                <Popover
                                    open={Boolean(anchorInfoEl)}
                                    anchorEl={anchorInfoEl}
                                    onClose={() => setAnchorInfoEl(null)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    PaperProps={{
                                        style: { maxWidth: '600px' },
                                    }}
                                >
                                    <Typography sx={{ p: 2 }}>{infoMessage}</Typography>
                                </Popover>
                            </Form>
                        )}
                    </Formik>
                ) : null}
            </Dialog>
            <AlertDialog
                open={showSuccessAlert}
                hideCancelButton
                title="Education"
                okText="Ok"
                onConfirm={() => setShowSuccessAlert(false)}
                onClose={() => setShowSuccessAlert(false)}
                message={successMessage}
            />
        </React.Fragment>
    );
};

export default AddOrUpdateEducationSession;
