import Delete from '@mui/icons-material/Delete';
import { Card, CardContent, CardHeader, IconButton, Stack, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import FormikTextField from '../FormikFields/FormikTextField';
import { IResource } from './interfaces/IResource';
import { IResourceContactCardProps } from './interfaces/IResourceContactCardProps';

const ResourceContactCard: React.FC<IResourceContactCardProps> = (props) => {
    const {
        contact: { Name },
        index,
        remove,
        disabled,
    } = props;
    const formik = useFormikContext<IResource>();
    return (
        <Card>
            <CardHeader
                title={Name || 'Unnamed Contact'}
                action={
                    !disabled ? (
                        <Tooltip title="Delete Contact">
                            <IconButton
                                onClick={() => {
                                    if (formik.values.Contacts[index].Id) {
                                        formik.setFieldValue(`Contacts[${index}].IsDeleted`, true);
                                    } else {
                                        // If the contact is new, just remove it from the array without persisting in the db.
                                        remove(index);
                                    }
                                }}
                            >
                                <Delete color="error" />
                            </IconButton>
                        </Tooltip>
                    ) : null
                }
            />
            <CardContent>
                <Stack spacing={2}>
                    <FormikTextField name={`Contacts[${index}].Name`} label="Name" size="small" disabled={disabled} />
                    <FormikTextField name={`Contacts[${index}].PhoneNumber`} label="Phone Number" size="small" disabled={disabled} />
                    <FormikTextField name={`Contacts[${index}].Email`} label="Email" size="small" disabled={disabled} />
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ResourceContactCard;
