import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, CircularProgress, Divider, Stack, Slider, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { fetchMemberDevices, saveVitalsignparameters, setVitalSignParameters } from '../../../store/memberVitalSigns.slice';
import { VitalSignParameterTypeEnum } from '../../../Enum/VitalSignParameterTypeEnum';
import { VitalSignParameter } from '../../../Models/VitalSigns/VitalSignParameter.model';
import { VitalSignParameterTypes } from '../../../utils/assessments';
import { copyObject } from '../../../utils/common';

const RemoteMonitoring: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { memberDevices, vitalSignParameters, isSaving: isSavingVitalSigns } = useSelector((state: RootState) => state.memberVitalSigns);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingVitalSigns || isSavingEngagementDraft;

    useEffect(() => {
        dispatch(fetchMemberDevices(memberId));
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveVitalsignparameters(vitalSignParameters));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeSlider = (values: number[], index: number) => {
        setIsChanged(true);
        let vitalSignParametersData = copyObject(vitalSignParameters);
        vitalSignParametersData[index].Min = values[0];
        vitalSignParametersData[index].Max = values[1];
        dispatch(setVitalSignParameters(vitalSignParametersData));
    };

    const renderSlider = (vitalSignParameter: VitalSignParameter, index: number) => {
        switch (vitalSignParameter.Type) {
            case VitalSignParameterTypeEnum.SystolicBp:
            case VitalSignParameterTypeEnum.DiastolicBP:
            case VitalSignParameterTypeEnum.HeartRate:
                if (!Boolean(memberDevices.BpCuff?.HasDevice)) {
                    return null;
                }
                break;
            case VitalSignParameterTypeEnum.Oximeter:
                if (!Boolean(memberDevices.PulseOX?.HasDevice)) {
                    return null;
                }
                break;
            case VitalSignParameterTypeEnum.Scale:
                if (!Boolean(memberDevices.Scale?.HasDevice)) {
                    return null;
                }
                break;
            case VitalSignParameterTypeEnum.Glucose:
                if (!Boolean(memberDevices.Glucometer?.HasDevice)) {
                    return null;
                }
                break;
        }

        const parameterDetail = VitalSignParameterTypes.find((v) => v.Id === vitalSignParameter.Type);
        if (!Boolean(parameterDetail)) {
            return null;
        }

        return (
            <Grid container sx={{ mt: 4, pt: 4 }} key={`device-slider-${index}`}>
                <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Typography>{parameterDetail.Name}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={6}>
                    <Slider
                        getAriaLabel={() => parameterDetail.Name}
                        value={[vitalSignParameter.Min, vitalSignParameter.Max]}
                        onChange={(event: Event, newValue: number | number[]) => handleOnChangeSlider(newValue as number[], index)}
                        min={parameterDetail.Min}
                        max={parameterDetail.Max}
                        valueLabelDisplay="on"
                        marks={[
                            {
                                value: parameterDetail.Min,
                                label: `${parameterDetail.Min}`,
                            },
                            {
                                value: parameterDetail.Max,
                                label: `${parameterDetail.Max}`,
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" sx={{ pt: 4 }}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item className="content-form">
                        <Typography variant="h6" component="label">
                            Telemonitoring Devices
                        </Typography>
                        <Divider sx={{ mb: 3 }} />
                        <Stack direction="column" spacing={2} sx={{ mt: 4 }}>
                            {memberDevices.NoDevices ? (
                                <div className="no-data-container">No telemonitoring devices found.</div>
                            ) : (
                                vitalSignParameters.map(renderSlider)
                            )}
                        </Stack>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            disabled={isSaving}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleOnClickSave}
                            disabled={isSaving}
                            endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                        >
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.RemoteMonitoringStatus) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Remote Telemonitoring"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    showTop
                    message="I confirm and acknowledged that Remote Telemonitoring is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Remote Telemonitoring"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    showTop
                    message="Are you sure to proceed without completing Remote Telemonitoring?"
                />
            )}
        </Grid>
    );
};

export default RemoteMonitoring;
