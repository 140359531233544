import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { parentUrl } from '../../../../utils/constants';
import { apiString as API_URL } from '../../../../utils/constants';
import { localDateAndTimeFormat } from '../../../../utils/timeFormat';
import { appointmentTypeEnums } from '../../../../utils/constants';
import { RootState } from '../../../../reducers';
import { useSelector } from 'react-redux';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';

const CancelledAndNotRescheduled = () => {
    const authData = new Authorization();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [membersNotScheduled, setMembersNotScheduled] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showInActiveProvider, setShowInActiveProvider] = useState<boolean>(false);
    const ref = useRef(null);
    const filteredProviders = (showInActiveProvider ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const getMembersNotScheduled = async (providerId) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/scheduling/getmembernotscheduledinnext27days?providerId=${providerId}`);
            setMembersNotScheduled(response.data);

            setIsLoading(false);
        } catch (error) {
            setMembersNotScheduled([]);
            setIsLoading(false);
        }
    };

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/scheduling`, '_blank');
    };

    useEffect(() => {
        getMembersNotScheduled(authData.UserId);
    }, [authData.UserId]);

    useEffect(() => {
        if (activeProviders.length > 0) {
            const provider = activeProviders.find((provider) => provider.Id === authData.UserId);
            setSelectedProvider(provider.Id);
        }
    }, [activeProviders, authData.UserId]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProvider(isChecked);
        setSelectedProvider(null);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                            Cancelled And Not Rescheduled
                        </Typography>
                        <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                            &#40;{membersNotScheduled.length}&#41;
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">Cancelled appointments that are not rescheduled in next 27 days</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>

                    {showProviders && (
                        <Grid item marginTop={2} xs={12}>
                            <FormControl className="form-control-200">
                                <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filteredProviders.find((provider) => provider.Id === selectedProvider)?.Id || ''}
                                    size="small"
                                    label="Provider"
                                    onChange={(e) => {
                                        setSelectedProvider(e.target.value as string);
                                        getMembersNotScheduled(e.target.value as string);
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProvider} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((provider) => {
                                        return (
                                            <MenuItem
                                                key={provider.Id}
                                                value={provider.Id}
                                                sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                            >
                                                {provider.LastName + ', ' + provider.FirstName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid container spacing={2} marginTop={2} height={showProviders ? '52vh' : '58vh'} sx={{ overflowY: 'scroll', position: 'relative' }}>
                        {isLoading && <WidgetLoading />}
                        {membersNotScheduled.length > 0 ? (
                            membersNotScheduled.map((member) => {
                                return (
                                    <Grid key={member.Id} item xs={6}>
                                        <Card
                                            sx={{
                                                ':hover': {
                                                    cursor: 'pointer',
                                                    boxShadow: 10,
                                                    border: 0,
                                                },
                                                boxShadow: 0,
                                            }}
                                            onClick={() => openDocumentHandler(member.MemberId)}
                                            variant="outlined"
                                        >
                                            <CardContent
                                                sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-between' }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Member
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {member.MemberName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Appointment Date Time
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {localDateAndTimeFormat(new Date(member.AppointmentDateTime))}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Provider Name
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {member.ProviderName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            Appointment Type
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {Object.values(appointmentTypeEnums).find((el) => el.value === member.AppointmentType).text}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="text.secondary" variant="body2">
                                                            AppointmentReason
                                                        </Typography>
                                                        <Typography fontWeight={'bold'} variant="body2">
                                                            {member.AppointmentReason}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        ) : (
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h6">No Matching Records</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default CancelledAndNotRescheduled;
