import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const requestReport = createSlice({
    name: 'requestReport',
    initialState: {
        startDate: new Date('2022-01-01'),
        endDate: new Date(),
        providerId: '-1',
        rowData: [],
        priority: ['1', '2', '3'],
        status: ['1', '2', '3'],
        requestType: '',
        requestTypeChoices: {},
        requestProviderType: '1',
    },
    reducers: {
        _setStartDate(state, action) {
            state.startDate = action.payload;
        },
        _setEndDate(state, action) {
            state.endDate = action.payload;
        },
        _setProviderId(state, action) {
            state.providerId = action.payload;
        },
        _setRowData(state, action) {
            state.rowData = action.payload;
        },
        _setPriority(state, action) {
            state.priority = action.payload;
        },
        _setStatus(state, action) {
            state.status = action.payload;
        },
        _setRequestType(state, action) {
            state.requestType = action.payload;
        },
        _setRequestTypeChoices(state, action) {
            state.requestTypeChoices = action.payload;
        },
        _setRequestProviderType(state, action) {
            state.requestProviderType = action.payload;
        },
    },
});

const { _setStartDate, _setEndDate, _setProviderId, _setRowData, _setPriority, _setRequestType, _setStatus, _setRequestTypeChoices, _setRequestProviderType } =
    requestReport.actions;

export const setStartDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setStartDate(params));
    };

export const setEndDate =
    (params: Date): AppThunk =>
    async (dispatch) => {
        dispatch(_setEndDate(params));
    };

export const setProviderId =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setProviderId(params));
    };

export const setRowData =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setRowData(params));
    };

export const setPriority =
    (params: string[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setPriority(params));
    };

export const setStatus =
    (params: string[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setStatus(params));
    };

export const setRequestType =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setRequestType(params));
    };

export const setRequestTypeChoices =
    (params: {}): AppThunk =>
    async (dispatch) => {
        dispatch(_setRequestTypeChoices(params));
    };

export const setRequestProviderType =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setRequestProviderType(params));
    };

export const fetchRequestTypeChoices = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/request/getrequesttype`);
    dispatch(setRequestTypeChoices(response.data));
};

export const fetchRequestsReport =
    (
        providerId: string,
        requestProviderType: string,
        startDate: Date,
        endDate: Date,
        requestTypeId: string,
        priority: string[],
        status: string[],
        showInActive: boolean
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/RequestReport/getRequestsByFilters?providerId=${
                providerId === '-1' ? '' : providerId
            }&requestProviderType=${requestProviderType}&startDate=${dateFormatter(startDate)}&endDate=${dateFormatter(
                endDate
            )}&memberId=&requestTypeId=${requestTypeId}&priorities=${priority}&statuses=${status}&includeInActiveProviders=${showInActive}`
        );
        dispatch(setRowData(response.data));
        dispatch(setIsLoading(false));
    };

export const exportRequestsReport =
    (
        providerId: string,
        requestProviderType: string,
        startDate: Date,
        endDate: Date,
        requestTypeId: string,
        priority: string[],
        status: string[],
        showInActive: boolean
    ): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/RequestReport/exportrequests?providerId=${
                providerId === '-1' ? '' : providerId
            }&requestProviderType=${requestProviderType}&startDate=${dateFormatter(startDate)}&endDate=${dateFormatter(
                endDate
            )}&memberId=&requestTypeId=${requestTypeId}&priorities=${priority}&statuses=${status}&includeInActiveProviders=${showInActive}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default requestReport.reducer;
