import {
    Grid,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { RootState } from '../../../../reducers';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchFuhFumUndeterminedSummary, exportFuhFumUndeterminedSummary } from '../../../Widgets/WidgetService';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import Authorization from '../../../../utils/Authorization';
import { OnboardingMeansOfEngagements } from '../../../../utils/mappings';
import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';
import { parentUrl } from '../../../../utils/constants';
import { isDefaultDate } from '../../../../utils/common';
import '../../Widgets.css';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const FuhFumUndeterminedSummaryDetail = () => {
    const history = useNavigate();
    const authData = new Authorization();
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const { tccProviders } = useSelector((state: RootState) => state.provider);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const {
        undeterminedProvider,
        setUndeterminedProvider,
        undeterminedMeansOfAttempt,
        setUndeterminedMeansOfAttempt,
        undeterminedDateFilter,
        setUndeterminedDateFilter,
        undeterminedFromDate,
        setUndeterminedFromDate,
        undeterminedToDate,
        setUndeterminedToDate,
        undeterminedShowInActiveProviders,
        setUndeterminedShowInActiveProviders,
    } = useWidgetContext();
    const filteredTccProviders = (undeterminedShowInActiveProviders ? tccProviders : tccProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    useEffect(() => {
        if (filteredTccProviders.length) {
            const loggedInProviderId = authData.UserId;
            if (Boolean(loggedInProviderId) && !Boolean(undeterminedProvider)) {
                const providerInfo = filteredTccProviders.find((t) => t.Id === loggedInProviderId);
                if (Boolean(providerInfo)) {
                    setUndeterminedProvider(loggedInProviderId);
                } else {
                    setUndeterminedProvider('All');
                }
            } else if (!Boolean(undeterminedProvider)) {
                setUndeterminedProvider('All');
            }
        } else {
            setUndeterminedProvider('All');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData.UserId, undeterminedProvider, setUndeterminedProvider, undeterminedShowInActiveProviders]);

    const getFuhFumSummary = async () => {
        if (undeterminedDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(undeterminedFromDate) && Boolean(undeterminedToDate))) {
            setIsFetched(false);
            let dateFrom =
                Boolean(undeterminedFromDate) && undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? new Date(undeterminedFromDate).toISOString() : '';
            let dateTo =
                Boolean(undeterminedToDate) && undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? new Date(undeterminedToDate).toISOString() : '';
            const data = await fetchFuhFumUndeterminedSummary(
                undeterminedProvider,
                undeterminedMeansOfAttempt,
                undeterminedDateFilter,
                dateFrom,
                dateTo,
                undeterminedShowInActiveProviders
            );
            setRows(data);
            setIsFetched(true);
        }

        if (undeterminedDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(undeterminedFromDate) && Boolean(undeterminedToDate)) {
            setUndeterminedFromDate(null);
            setUndeterminedToDate(null);
        }
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [undeterminedProvider, undeterminedMeansOfAttempt, undeterminedDateFilter, undeterminedFromDate, undeterminedToDate, undeterminedShowInActiveProviders]);

    const changeFromDate = (date: any) => {
        setUndeterminedFromDate(date);
    };

    const changeToDate = (date: any) => {
        setUndeterminedToDate(date);
    };

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let dateFrom =
            Boolean(undeterminedFromDate) && undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? new Date(undeterminedFromDate).toISOString() : '';
        let dateTo = Boolean(undeterminedToDate) && undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? new Date(undeterminedToDate).toISOString() : '';

        const data = await exportFuhFumUndeterminedSummary(
            undeterminedProvider,
            undeterminedMeansOfAttempt,
            undeterminedDateFilter,
            dateFrom,
            dateTo,
            undeterminedShowInActiveProviders
        );
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `FuhFumUndeterminedMembers - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setUndeterminedShowInActiveProviders(isChecked);
        setUndeterminedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            FUHFUM Undetermined Summary
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="provider-label">TCC Provider</InputLabel>
                            <Select
                                labelId="provider-label"
                                id="demo-provider-select"
                                value={undeterminedProvider}
                                label="TCC Provider"
                                onChange={(e) => {
                                    setUndeterminedProvider(e.target.value as string);
                                }}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={undeterminedShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTccProviders.map(function (Pro) {
                                    return (
                                        <MenuItem key={Pro.Id} value={Pro.Id} sx={{ color: Pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {Pro.LastName}, {Pro.FirstName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-means-label">Means of Attempts</InputLabel>
                            <Select
                                labelId="demo-means-label"
                                id="demo-meanss-select"
                                value={undeterminedMeansOfAttempt}
                                label="Means of Attempts"
                                onChange={(e) => {
                                    setUndeterminedMeansOfAttempt(e.target.value as string);
                                }}
                            >
                                <MenuItem key="0" value="0">
                                    All
                                </MenuItem>
                                {OnboardingMeansOfEngagements.map(function (Pro) {
                                    return (
                                        <MenuItem key={Pro.value} value={Pro.value}>
                                            {Pro.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={undeterminedDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setUndeterminedDateFilter(parseInt(e.target.value as any))}
                            >
                                {dayFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(undeterminedFromDate) ? undeterminedFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(undeterminedToDate) ? undeterminedToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={undeterminedDateFilter === WidgetTimeFilterEnum.Custom ? 3.5 : 6.5}></Grid>
                    <Grid item xs={1}>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => exportDetail()}
                            variant="contained"
                            color="success"
                            disabled={rows.length ? false : true}
                        >
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isFetched ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell>Member Name</StyledTableCell>
                                                    <StyledTableCell>DOB</StyledTableCell>
                                                    <StyledTableCell>Referral Date</StyledTableCell>
                                                    <StyledTableCell>Discharge Date</StyledTableCell>
                                                    <StyledTableCell>Facility</StyledTableCell>
                                                    <StyledTableCell># Attempts</StyledTableCell>
                                                    <StyledTableCell>Last Attempt</StyledTableCell>
                                                    <StyledTableCell>Admission Type</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.length ? (
                                                    rows.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.MemberName}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {!isDefaultDate(row.DOB) ? moment(new Date(row.DOB)).format('MM/DD/yyyy') : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {!isDefaultDate(row.ReferralDate)
                                                                    ? moment(new Date(row.ReferralDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {!isDefaultDate(row.DischargeDate)
                                                                    ? moment(new Date(row.DischargeDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.Facility}</StyledTableCell>
                                                            <StyledTableCell>{row.NumberOfAttempts}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {!isDefaultDate(row.LastAttemptDate)
                                                                    ? moment(new Date(row.LastAttemptDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.FuhFumVisitTypeName}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={9}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default FuhFumUndeterminedSummaryDetail;
