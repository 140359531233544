import { Box, Button, Grid, Link, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import StyleIcon from '@mui/icons-material/Style';
import DescriptionIcon from '@mui/icons-material/Description';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../utils/timeFormat';
import MetaTagDialog from './MetaTagDialog/MetaTagDialog';
import MoveDocumentDialog from './MoveDocumentDialog/MoveDocumentDialog';
import ConfirmDelete from '../Shared/ConfirmDelete';
import AddDocumentDialog from './AddDocumentDialog/AddDocumentDialog';
import { RootState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
    addNewDocumentHandler,
    deleteDocument,
    getDocumentByCategoryId,
    getDocumentCategoryAsTree,
    getDocumentSubMetaTags,
    getMetaTagsHandler,
    setCategoryId,
    setDocumentId,
    setDocumentSubMetaTags,
    setFileName,
    setIsSelected,
    setMetaTags,
    setOpenAddDocument,
    setOpenDeleteDocument,
    setOpenMetaTags,
    setOpenMoveDocuments,
    updateDocumentMetaTags,
} from '../../store/memberDocuments.slice';
import Authorization from '../../utils/Authorization';
import { useState } from 'react';
import { isDefaultDate } from '../../utils/common';
import { useParams } from 'react-router-dom';

const MemberDocuments = (props: any) => {
    const dispatch = useDispatch();
    const params = useParams();
    const memberId = props.memberId || params.memberId;
    const auth = new Authorization();
    const {
        categoryId,
        documentsByCategoryId,
        documentId,
        documentsTree,
        documentSubMetaTags,
        fileName,
        isSelected,
        metaTags,
        metaTagsByCategoryId,
        openAddDocument,
        openDeleteDocument,
        openMetaTags,
        openMoveDocuments,
        subMetaTags,
    } = useSelector((state: RootState) => state.memberDocuments);

    const [documentName, setDocumentName] = useState(documentsTree[0]?.Name);

    useEffect(() => {
        dispatch(getDocumentCategoryAsTree());
        dispatch(getDocumentSubMetaTags());
    }, [dispatch]);

    const MedicalRecord = {
        Name: true,
        MetaTags: true,
        SubMetaTags: false,
        FileName: true,
        CreationTime: true,
        DocumentSignedOn: false,
        UploadedBy: true,
    };

    const ConsentForCare = {
        Name: true,
        MetaTags: true,
        SubMetaTags: false,
        FileName: true,
        CreationTime: true,
        DocumentSignedOn: true,
        UploadedBy: true,
    };

    const allColumns = {
        Name: true,
        MetaTags: true,
        SubMetaTags: true,
        FileName: true,
        CreationTime: true,
        DocumentSignedOn: false,
        UploadedBy: true,
    };

    const medicalRecordsDocumentsIndex = documentsTree.findIndex((item) => item.Name === 'Medical Records');
    const consentForCareDocumentsIndex = documentsTree.findIndex((item) => item.Name === 'Consent For Care');

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'MetaTags',
            headerName: 'Meta Tag',
            flex: 0.75,
            renderCell: (params) => {
                return (
                    <>
                        <Stack>
                            {params.row.MetaTags?.map((tag) => {
                                return <label key={tag.Id}>{tag.Name}</label>;
                            })}
                        </Stack>
                    </>
                );
            },
        },
        {
            field: 'SubMetaTags',
            headerName: 'Sub Meta Tag',
            flex: 0.75,
            renderCell: (params) => {
                return (
                    <>
                        <Stack>
                            {params.row.SubMetaTags?.map((tag) => {
                                return <label key={tag.Id}>{tag.Name}</label>;
                            })}
                        </Stack>
                    </>
                );
            },
        },
        {
            field: 'FileName',
            headerName: 'File Name',
            flex: 1.5,
        },
        {
            field: 'DocumentSignedOn',
            headerName: 'Document Signed On',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) =>
                `${Boolean(params.row.DocumentSignedOn) ? dateFormatter(new Date(params.row.DocumentSignedOn)) : '-'}`,
        },
        {
            field: 'CreationTime',
            headerName: 'Uploaded Date',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CreationTime) ? '-' : dateFormatter(new Date(params.row.CreationTime))}`,
        },
        {
            field: 'UploadedBy',
            headerName: 'Uploaded By',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.UploadedBy ? params.row.UploadedBy.Name : ''}`,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 1.5,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="View Document" placement="left">
                            <Link target="_blank" underline="none" href={params.row.AzureDocumentUri}>
                                <DescriptionIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} />
                            </Link>
                        </Tooltip>
                        <Tooltip title="Meta Tags" placement="bottom">
                            <StyleIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    dispatch(getMetaTagsHandler(params.row.DocumentCategory, params.row.MetaTags || [], params.row.SubMetaTags || []));
                                    dispatch(setDocumentId(params.row.Id));
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Move Document" placement="top">
                            <DriveFileMoveIcon
                                color="primary"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    dispatch(setOpenMoveDocuments(true));
                                    dispatch(setDocumentId(params.row.Id));
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete Document" placement="bottom">
                            <DeleteIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    dispatch(setOpenDeleteDocument(true));
                                    dispatch(setDocumentId(params.row.Id));
                                    dispatch(setFileName(params.row.Name));
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Tabs value={isSelected} variant="scrollable" onChange={(e, newValue) => dispatch(setIsSelected(newValue))}>
                {documentsTree.map((item, index) => (
                    <Tab
                        key={index}
                        label={item.Name}
                        value={index}
                        onClick={(e) => {
                            dispatch(setCategoryId(item.Id));
                            dispatch(getDocumentByCategoryId(item.Id, memberId));
                            setDocumentName(item.Name);
                        }}
                    />
                ))}
            </Tabs>
            <Box p={2} width={'100%'}>
                <Grid container p={2} spacing={2}>
                    <AddDocumentDialog open={openAddDocument} onClose={() => dispatch(setOpenAddDocument(false))} auth={auth} memberId={memberId} />
                    <MetaTagDialog
                        open={openMetaTags}
                        onClose={() => dispatch(setOpenMetaTags(false))}
                        documentSubMetaTags={documentSubMetaTags}
                        setDocumentSubMetaTags={setDocumentSubMetaTags}
                        metaTags={metaTags}
                        setMetaTags={setMetaTags}
                        tags={metaTagsByCategoryId}
                        save={() => dispatch(updateDocumentMetaTags(documentId, categoryId, memberId, metaTags, subMetaTags))}
                    />
                    <MoveDocumentDialog
                        documentsTree={documentsTree}
                        open={openMoveDocuments}
                        onClose={() => dispatch(setOpenMoveDocuments(false))}
                        documentId={documentId}
                        categoryId={categoryId}
                        memberId={memberId}
                    />
                    <ConfirmDelete
                        open={openDeleteDocument}
                        onClose={() => dispatch(setOpenDeleteDocument(false))}
                        name={fileName}
                        delete={() => dispatch(deleteDocument(categoryId, memberId, documentId))}
                        cancel={() => dispatch(setOpenDeleteDocument(false))}
                    />
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                        <Button
                            sx={{ marginLeft: 'auto' }}
                            className="button-120"
                            variant="contained"
                            color="success"
                            onClick={() => dispatch(addNewDocumentHandler())}
                        >
                            Add Document
                        </Button>
                    </Grid>

                    {documentsByCategoryId.length > 0 ? (
                        <Grid item xs={12}>
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    rows={documentsByCategoryId}
                                    columns={columns}
                                    columnVisibilityModel={
                                        isSelected === medicalRecordsDocumentsIndex
                                            ? MedicalRecord
                                            : isSelected === consentForCareDocumentsIndex
                                            ? ConsentForCare
                                            : allColumns
                                    }
                                    autoHeight
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                />
                            </div>
                        </Grid>
                    ) : (
                        <Typography p={5} marginX="auto" variant="h6">
                            No {documentName} Documents Found
                        </Typography>
                    )}
                </Grid>
            </Box>
        </Box>
    );
};
export default MemberDocuments;
