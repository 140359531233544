import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

const assignEducationMaterials = createSlice({
    name: 'assignEducationMaterials',
    initialState: {
        allEducationMaterials: [],
        educationMaterialsAssignedToMember: [],
        educationMaterialIsOpen: false,
        memberInfo: {},
        pageContent: null,
        currentCategories: [],
    },
    reducers: {
        _setAllEducationMaterials(state, action) {
            state.allEducationMaterials = action.payload;
        },
        _setEducationMaterialsAssignedToMember(state, action) {
            state.educationMaterialsAssignedToMember = action.payload;
        },
        _setEducationMaterialIsOpen(state, action) {
            state.educationMaterialIsOpen = action.payload;
        },
        _setMemberInfo(state, action) {
            state.memberInfo = action.payload;
        },
        _setPageContent(state, action) {
            state.pageContent = action.payload;
        },
        _setCurrentCategories(state, action) {
            state.currentCategories = action.payload;
        },
    },
});

const {
    _setAllEducationMaterials,
    _setEducationMaterialsAssignedToMember,
    _setEducationMaterialIsOpen,
    _setMemberInfo,
    _setPageContent,
    _setCurrentCategories,
} = assignEducationMaterials.actions;

export const getAllEducationMaterials = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/education/getalledumaterial`);
    dispatch(_setAllEducationMaterials(response.data));
};

export const setEducationMaterialsAssignedToMember =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setEducationMaterialsAssignedToMember(params));
    };

export const setEducationMaterialIsOpen =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setEducationMaterialIsOpen(params));
    };

export const setMemberInfo =
    (params: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberInfo(params));
    };

export const setPageContent =
    (params: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setPageContent(params));
    };

export const setCurrentCategories =
    (params: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentCategories(params));
    };

export const getMemberBasicInfo =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/member/getmemberbaseinfo?memberId=${memberId}`);
            dispatch(_setMemberInfo(response.data));
        } catch (error) {
            console.log(error);
        }
    };

export const getMemberEducationMaterials =
    (memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/education/getmemberedumaterial?memberId=${memberId}`);
            dispatch(
                _setEducationMaterialsAssignedToMember(
                    response.data?.sort((a, b) => a.EducationMaterial?.Category.localeCompare(b.EducationMaterial?.Category))
                )
            );
            dispatch(setCurrentCategories(response.data.map((category) => category['EducationMaterial'])));
        } catch (error) {
            console.log(error);
        }
    };

export const insertMemberMaterial =
    (payload: {}, memberId: string, callBack?: Function): AppThunk =>
    async (dispatch) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.post(`${API_URL}/education/insertmemberedumaterial`, { MemberEduMaterials: payload });
            // console.log('RESPONSE: ', response.data);
            dispatch(setEducationMaterialsAssignedToMember(response.data?.MemberEduMaterials));
            dispatch(setEducationMaterialIsOpen(false));
            dispatch(getMemberEducationMaterials(memberId));
            if(Boolean(callBack)){
                callBack(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

export default assignEducationMaterials.reducer;
