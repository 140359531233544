import BaseEntity from '../BaseEntity.model';
import { ConsentFormDocument } from './ConsentFormDocument.model';

export default interface IConsentForm extends BaseEntity {
    MemberId: string;
    ConsentToService: boolean;
    PartOneConsent: ConsentFormDocument;
    SignedPartTwoConsent: boolean;
    PartTwoConsent: ConsentFormDocument;

    NewPartOneConsent: ConsentFormDocument;
    NewPartTwoConsent: ConsentFormDocument;
}

export class ConsentForm implements IConsentForm {
    Id: string;
    MemberId: string;
    ConsentToService: boolean;
    PartOneConsent: ConsentFormDocument;
    SignedPartTwoConsent: boolean;
    PartTwoConsent: ConsentFormDocument;

    NewPartOneConsent: ConsentFormDocument;
    NewPartTwoConsent: ConsentFormDocument;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.ConsentToService = null;
        this.SignedPartTwoConsent = false;
        this.PartOneConsent = new ConsentFormDocument();
        this.PartTwoConsent = new ConsentFormDocument();
        
        this.NewPartOneConsent = new ConsentFormDocument();
        this.NewPartTwoConsent = new ConsentFormDocument();
    }
}
