export enum AuditAnswerEnum {
    NeverDescription = 'Never',
    Never = 1,
    LessThanMonthlyDescription = 'Less than monthly',
    LessThanMonthly = 2,
    MonthlyDescription = 'Monthly',
    Monthly = 3,
    WeeklyDescription = 'Weekly',
    Weekly = 4,
    DailyOrAlmostDailyDescription = 'Daily or almost daily',
    DailyOrAlmostDaily = 5,
}
