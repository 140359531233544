import { CarePlanSignatureTypeEnum } from '../../Enum/CarePlanSignatureTypeEnum';

export default interface ICarePlanSignatureRequest {
    CarePlanId: string;
    Label: CarePlanSignatureTypeEnum;
    Signature: string;
}

export class CarePlanSignatureRequest implements ICarePlanSignatureRequest {
    CarePlanId: string;
    Label: CarePlanSignatureTypeEnum;
    Signature: string;

    constructor() {
        this.CarePlanId = '';
        this.Label = CarePlanSignatureTypeEnum.Provider;
        this.Signature = '';
    }
}
