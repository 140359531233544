/* eslint-disable react-hooks/exhaustive-deps */
import { RichTextEditor } from '@mantine/rte';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IMemberSelectOption from '../../../Models/Member/MemberSelectOption.model';
import IProviderSelectOption from '../../../Models/Provider/ProviderSelectOption.model';
import { RootState } from '../../../reducers';
import { setAnswers, setIsNewRequestModalOpen, submitNewRequest } from '../../../store/newRequest.slice';
import { REQUEST_API } from '../../../store/requests.slice';
import { apiString, ProviderId as sessionProviderId } from '../../../utils/constants';
import { IRequest, IRequestQuestion, IRequestQuestionAnswer, IRequestQuestionType } from '../interfaces';
import RequestsQuestionCard from '../RequestsQuestions/RequestsQuestionCard';
import { Provider } from '../../../Models/Provider/Provider.model';

export interface INewRequestModalProps {
    lockedMemberOption?: IMemberSelectOption;
    providerOption?: IProviderSelectOption;
}

type mappedRequestQuestionTypeOption = {
    value: string;
    label: string;
    subRequestTypes?: IRequestQuestionType[];
    isNotEncounterable?: boolean;
    Zcode: string;
};

const NewRequestModal: React.FC<INewRequestModalProps> = ({ lockedMemberOption }) => {
    const dispatch = useDispatch();
    const providerRef = React.useRef<HTMLDivElement>(null);
    const { isNewRequestModalOpen, answers } = useSelector((state: RootState) => state.newRequest);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [isFetchingQuestions, setIsFetchingQuestions] = useState<boolean>(false);
    const [requestQuestionList, setRequestQuestionList] = useState<IRequestQuestion[]>([]);
    const [requestDescription, setRequestDescription] = useState<string>('');
    const [requestTypeInputValue, setRequestTypeInputValue] = useState<string>('');
    const [subRequestTypeInputValue, setSubRequestTypeInputValue] = useState<string>('');
    const [requestTypeOptions, setRequestTypeOptions] = useState<mappedRequestQuestionTypeOption[]>([]);
    const [requestTypeOption, setRequestTypeOption] = useState<mappedRequestQuestionTypeOption>();
    const [subRequestTypeOptions, setSubRequestTypeOptions] = useState<mappedRequestQuestionTypeOption[]>([]);
    const [subRequestTypeOption, setSubRequestTypeOption] = useState<mappedRequestQuestionTypeOption>();
    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [memberOptions, setMemberOptions] = useState<IMemberSelectOption[]>([]);
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const [memberInputValue, setMemberInputValue] = useState<string>('');
    const [selectedMemberOption, setSelectedMemberOption] = useState<IMemberSelectOption | null>(
        lockedMemberOption?.MemberId?.length ? lockedMemberOption : null
    );
    const [selectedProviderOption, setSelectedProviderOption] = useState<IProviderSelectOption | null>(null);
    const [fullProviderOptionsList, setFullProviderOptionsList] = useState<IProviderSelectOption[]>([]);
    const [priorityOption, setPriorityOption] = useState<number>(1);
    const [providerError, setProviderError] = useState<boolean>(false);
    const [memberError, setMemberError] = useState<boolean>(false);

    useEffect(() => {
        if (fullProviderOptionsList.length === 0) {
            const providers = activeProviders.filter((el) => el.Role.RoleName !== 'CEO');
            const options = (providers as Provider[])
                .map((row) => {
                    return {
                        ProviderId: row.Id,
                        ProviderName: `${row.LastName}, ${row.FirstName}`,
                        FirstName: row.FirstName,
                        LastName: row.LastName,
                    };
                })
                .sort((a, b) => {
                    let lastNameComparison = a.LastName.localeCompare(b.LastName);
                    if (lastNameComparison !== 0) {
                        return lastNameComparison;
                    }

                    return a.FirstName.localeCompare(b.FirstName);
                });
            console.log({ options, fullProviderOptionsList, providerOptions });
            setFullProviderOptionsList(options);
            setProviderOptions(options);
        }
    }, [activeProviders]);

    useEffect(() => {
        if (fullProviderOptionsList.length > 0) {
            if (providerInputValue.length === 0) {
                setProviderOptions(fullProviderOptionsList);
            } else {
                setProviderOptions(
                    fullProviderOptionsList.filter((row) => {
                        return row.ProviderName.toLowerCase().includes(providerInputValue.toLowerCase());
                    })
                );
            }
        }
    }, [providerInputValue]);

    const questionCards = useMemo(() => {
        if (requestQuestionList?.length === 0) {
            return (
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography variant="h6">There aren't any questions for this request type.</Typography>
                    </Grid>
                </Grid>
            );
        }
        if (requestQuestionList && answers.length === requestQuestionList.length) {
            return React.Children.toArray(
                requestQuestionList.map((question, index) => {
                    const setAnswer = (answer: Partial<IRequestQuestionAnswer>) => {
                        console.log({ question, index, answer, answers });
                        const newAnswers = [...answers];
                        newAnswers[index] = { ...newAnswers[index], ...answer };
                        dispatch(setAnswers(newAnswers));
                    };
                    return (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <RequestsQuestionCard
                                debounced={false}
                                QuestionId={question.Id}
                                {...question}
                                index={index}
                                setAnswer={setAnswer}
                                isNewQuestion={true}
                            />
                        </Grid>
                    );
                })
            );
        }
    }, [requestQuestionList, answers]);

    const fetchRequestTypeOptions = async () => {
        const deprecatedTypes = ['Others'];
        const response = await axios.get(`${REQUEST_API}/GetRequestTypesList`);
        if (response?.data) {
            const requestTypes: mappedRequestQuestionTypeOption[] = (response.data as IRequestQuestionType[])
                .map(({ Id, RequestTypeLabel, ZCode: Zcode, SubRequestTypes, IsNotEncounterable }) => {
                    return {
                        value: Id,
                        subRequestTypes: SubRequestTypes,
                        label: RequestTypeLabel,
                        isNotEncounterable: IsNotEncounterable,
                        Zcode: Zcode,
                    };
                })
                .filter(({ label }) => !deprecatedTypes.includes(label))
                .sort((a, b) => a.label.localeCompare(b.label));
            setRequestTypeOptions(requestTypes);
            setRequestTypeOption(requestTypes[0]);
        }
    };

    const fetchQuestionList = async (typeId: string) => {
        setIsFetchingQuestions(true);
        setRequestQuestionList([]);
        const request = axios.get(`${REQUEST_API}/GetRequestTypeQuestionList/${typeId}`);
        request
            .then((response) => {
                setRequestQuestionList(response.data);
            })
            .finally(() => {
                setIsFetchingQuestions(false);
            });
    };

    useEffect(() => {
        fetchRequestTypeOptions();
    }, []);

    useEffect(() => {
        dispatch(
            setAnswers(
                requestQuestionList.map((question) => ({
                    ...question,
                    QuestionId: question.Id,
                    AnswerText: '',
                    Choice: '',
                }))
            )
        );
    }, [requestQuestionList]);

    const handleRequestTypeSubTypeListChange = async () => {
        if (requestTypeOption?.value?.length) {
            if (requestTypeOption?.subRequestTypes?.length && subRequestTypeOptions.length === 0) {
                const mappedSubTypes = requestTypeOption.subRequestTypes.map(({ Id, IsNotEncounterable, RequestTypeLabel, SubRequestTypes, ZCode: Zcode }) => ({
                    value: Id,
                    subRequestTypes: SubRequestTypes,
                    label: RequestTypeLabel,
                    IsNotEncounterable: IsNotEncounterable,
                    Zcode: Zcode,
                }));
                console.log({ mappedSubTypes, requestSubTypes: requestTypeOption.subRequestTypes });
                setSubRequestTypeOptions(mappedSubTypes);
                setSubRequestTypeOption(mappedSubTypes[0]);
            } else {
                setSubRequestTypeOption(undefined);
                setSubRequestTypeOptions([]);
            }
            if (!subRequestTypeOption?.value?.length) {
                fetchQuestionList(requestTypeOption.value);
            }
        }
    };

    useEffect(() => {
        handleRequestTypeSubTypeListChange();
    }, [requestTypeOption]);

    useEffect(() => {
        console.log({ subRequestTypeOption });
        if (subRequestTypeOption?.value?.length) {
            fetchQuestionList(subRequestTypeOption.value);
        }
    }, [subRequestTypeOption]);

    const fetchAndSetMemberOptions = useCallback(async (searchString: string) => {
        if (searchString?.length) {
            const response = await axios.get(`${apiString}/member/memberoptions/${searchString.toLowerCase()}`);
            if (response.status === 200) {
                const { data } = response;
                setMemberOptions((data as IMemberSelectOption[]).sort((a, b) => a.MemberName.localeCompare(b.MemberName)));
            }
        }
    }, []);

    useEffect(() => {
        fetchAndSetMemberOptions(memberInputValue);
    }, [memberInputValue]);

    const toggleRequestModal = () => {
        setSelectedMemberOption(lockedMemberOption?.MemberId?.length ? lockedMemberOption : null);
        setSelectedProviderOption(null);
        setPriorityOption(1);
        setRequestDescription('');
        setProviderError(false);
        setMemberError(false);
        // setRequestQuestions([]);
        dispatch(setIsNewRequestModalOpen(!isNewRequestModalOpen));
    };

    const handleSubmitClick = async () => {
        if (selectedProviderOption?.ProviderId?.length && selectedMemberOption?.MemberId.length) {
            const { MemberId: memberId } = selectedMemberOption;
            const { ProviderId: providerId } = selectedProviderOption;
            const newRequest: Partial<IRequest> = {
                Message: requestDescription,
                RequestFor: {
                    Id: memberId,
                    Name: '',
                },
                RequestTo: {
                    Id: providerId,
                    Name: '',
                    Role: '',
                },
                RequestFrom: {
                    Id: sessionProviderId,
                    Name: '',
                    Role: '',
                },
                Priority: priorityOption,
                ForwardBy: [],
                Type: 1,
                RequestQuestionAnswers: answers as IRequestQuestionAnswer[],
                Status: 1,
                Zcode: requestTypeOption.Zcode ?? '',
                CreatedOn: new Date(),
                IsNotEncounterable: requestTypeOption.isNotEncounterable ?? subRequestTypeOption?.isNotEncounterable ?? false,
                RequestTypeObjectId: requestTypeOption?.value,
                SubRequestTypeLabel: subRequestTypeOption?.label,
            };
            if (subRequestTypeOption?.value?.length) {
                newRequest.Zcode = subRequestTypeOption.Zcode;
                newRequest.SubRequestTypeObjectId = subRequestTypeOption?.value;
            }
            dispatch(submitNewRequest(newRequest));
            toggleRequestModal();
        } else {
            setProviderError(!selectedProviderOption?.ProviderId?.length);
            setMemberError(!selectedMemberOption?.MemberId?.length);
        }
    };

    const newRequestInfoPage = (
        <>
            <DialogContent>
                <Grid container justifyContent="center" spacing={3} style={{ paddingTop: 10 }}>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        <Autocomplete
                            ref={providerRef}
                            fullWidth
                            disableClearable
                            id="request-provider-selection"
                            options={providerOptions}
                            isOptionEqualToValue={(option, value: any) => {
                                return option.ProviderId === value.ProviderId;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Assigned Provider"
                                    required
                                    error={providerError}
                                    helperText={providerError && 'A Provider is Required'}
                                />
                            )}
                            value={selectedProviderOption as any}
                            onChange={(event: any, newValue: any) => {
                                if (newValue?.value) {
                                    setSelectedProviderOption(newValue.value);
                                } else {
                                    setSelectedProviderOption(newValue);
                                }
                                if (providerError) {
                                    setProviderError(false);
                                }
                            }}
                            inputValue={providerInputValue}
                            onInputChange={(event, value) => {
                                setProviderInputValue(value);
                            }}
                            getOptionLabel={(option: any) => option.ProviderName}
                        />
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        {!(lockedMemberOption?.MemberId?.length > 0) ? (
                            <Autocomplete
                                fullWidth
                                disableClearable
                                id="request-member-selection"
                                options={memberOptions}
                                isOptionEqualToValue={(option: any, value: any) => {
                                    return option.value === value;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Assigned Member"
                                        required
                                        error={memberError}
                                        helperText={memberError && 'A Member is Required'}
                                    />
                                )}
                                value={selectedMemberOption as any}
                                onChange={(event: any, newValue: any) => {
                                    if (newValue?.value) {
                                        setSelectedMemberOption(newValue);
                                    } else {
                                        setSelectedMemberOption(newValue);
                                    }
                                    if (memberError) {
                                        setMemberError(false);
                                    }
                                }}
                                inputValue={memberInputValue}
                                onInputChange={(event, value) => {
                                    setMemberInputValue(value);
                                }}
                            />
                        ) : (
                            <TextField fullWidth variant="outlined" label="Assigned Member" value={lockedMemberOption.MemberName} disabled />
                        )}
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                        <TextField
                            fullWidth
                            select
                            label="Priority"
                            variant="outlined"
                            value={priorityOption}
                            onChange={(event) => {
                                setPriorityOption(Number(event.target?.value));
                            }}
                        >
                            <MenuItem value={1}>Low</MenuItem>
                            <MenuItem value={2}>Medium</MenuItem>
                            <MenuItem value={3}>High</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography style={{ paddingBottom: 10 }} variant="h5">
                            Message / Description
                        </Typography>
                        <RichTextEditor value={requestDescription} onChange={setRequestDescription} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h5">Request Type and Questionnaire</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {requestTypeOptions && (
                            <Autocomplete
                                sx={{
                                    maxWidth: 300,
                                }}
                                disableClearable
                                id="request-type-selection"
                                options={requestTypeOptions}
                                isOptionEqualToValue={(option, value: any) => {
                                    return option.value === value.value;
                                }}
                                renderInput={(params) => <TextField {...params} label="Request Type" />}
                                value={requestTypeOption as any}
                                onChange={(event: any, newValue: any) => {
                                    console.log({ event, newValue });
                                    if (newValue?.value) {
                                        setRequestTypeOption(newValue);
                                    } else {
                                        setRequestTypeOption(newValue);
                                    }
                                }}
                                getOptionLabel={(option: any) => option.label}
                                inputValue={requestTypeInputValue}
                                onInputChange={(event, value) => {
                                    setRequestTypeInputValue(value);
                                }}
                            />
                        )}
                    </Grid>
                    {subRequestTypeOptions.length === 0 ? null : (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {subRequestTypeOptions.length > 0 && (
                                <Autocomplete
                                    sx={{
                                        maxWidth: 300,
                                    }}
                                    disableClearable
                                    id="sub-request-type-selection"
                                    multiple={false}
                                    options={subRequestTypeOptions as mappedRequestQuestionTypeOption[]}
                                    isOptionEqualToValue={(option, value: any) => {
                                        return option.value === value.value;
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Sub Request Type" />}
                                    value={subRequestTypeOption ?? subRequestTypeOptions[0]}
                                    onChange={(event: any, newValue: any) => {
                                        console.log({ event, newValue });
                                        if (newValue?.value) {
                                            setSubRequestTypeOption(subRequestTypeOptions.find((x) => x.value === newValue.value));
                                        } else {
                                            setSubRequestTypeOption(subRequestTypeOptions.find((x) => x.value === newValue));
                                        }
                                    }}
                                    getOptionLabel={(option: any) => option.label}
                                    inputValue={subRequestTypeInputValue}
                                    onInputChange={(event, value) => {
                                        console.log({ event, value });
                                        setSubRequestTypeInputValue(value);
                                    }}
                                />
                            )}
                        </Grid>
                    )}
                    <Grid container spacing={2} item>
                        {!isFetchingQuestions ? (
                            questionCards
                        ) : (
                            <Grid item container justifyContent={'center'} alignItems="center">
                                <Grid item>
                                    <CircularProgress size={80} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {providerError && (
                    <Typography variant="caption" fontSize={12} color="red" style={{ paddingRight: 20 }}>
                        <sup>*</sup>Assigned Provider is required
                    </Typography>
                )}
                {memberError && (
                    <Typography variant="caption" fontSize={12} color="red" style={{ paddingRight: 20 }}>
                        <sup>*</sup>Assigned Member is required
                    </Typography>
                )}
                <Button variant="contained" onClick={handleSubmitClick} color={memberError || providerError ? 'error' : undefined}>
                    Submit
                </Button>
                <Button variant="contained" onClick={toggleRequestModal}>
                    Cancel
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog
            open={isNewRequestModalOpen}
            fullWidth
            maxWidth={false}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Make New Request</DialogTitle>
            {newRequestInfoPage}
        </Dialog>
    );
};

export default NewRequestModal;
