import axios from 'axios';
import { HedisMetricsTypeEnum } from '../../Enum/HedisMetricsTypeEnum';
import { TimePeriodFilterEnum } from '../../Enum/TimePeriodFilterEnum';
import { apiString as api } from '../../utils/constants';

export const getDateFilter = function () {
    let dateList = [];
    dateList.push({ Id: 6, Value: 'Current Month' });
    dateList.push({ Id: 5, Value: 'Last 1 Month' });
    dateList.push({ Id: 1, Value: 'Last 3 Months' });
    dateList.push({ Id: 2, Value: 'Last 6 Months' });
    dateList.push({ Id: 3, Value: 'Last 1 Year' });
    dateList.push({ Id: 4, Value: 'Custom' });
    return dateList;
};

export const getBpSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getbpsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getBpMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getbpmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getAdultHealthSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getadulthealthsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getAdultHealthMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getadulthealthmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getBmiSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getbmisummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getBmiMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getbmimembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getBreastCancerScreeningSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getbreastcancerscreeningsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getBreastCancerScreeningMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getbreastcancerscreeningmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getHgbA1CSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/gethgba1csummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getHgbA1CMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/gethgba1cmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getDiabeticEyeExamSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getdiabeticeyeexamsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getDiabeticEyeExamMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getdiabeticeyeexammembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getMedicationReconciliationSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getmedicationreconciliationsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getMedicationReconciliationMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getmedicationreconciliationmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getDepressionScreeningSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getdepressionscreeningsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getDepressionScreeningMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getdepressionscreeningmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getDepressionFollowUpSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getdepressionsfollowupsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getDepressionFollowUpMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getdepressionfollowupmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getLipidForBHDXSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getlipidforbhdxsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getLipidForBHDXMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/getlipidforbhdxmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};

export const getHgbA1CForBHDXSummary = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/gethgba1cforbhdxsummary?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const getHgbA1CForBHDXMembers = async (
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/gethgba1cforbhdxmembers?population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
export const exportHedisDetail = async (
    type: HedisMetricsTypeEnum,
    population: number,
    teamId: string,
    dateFilter: TimePeriodFilterEnum,
    dateFrom: string,
    dateTo: string,
    includePastOpenStatusMembers: boolean,
    includeInActiveTeams: boolean
) => {
    const response = await axios.get(
        `${api}/hedismetrics/exporthedismembers?type=${type}&population=${population}&teamId=${teamId}&dateFilter=${dateFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includePastOpenStatusMembers=${includePastOpenStatusMembers}&includeInActiveTeams=${includeInActiveTeams}`
    );
    return response.data;
};
