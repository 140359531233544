import { Grid, Paper, Typography, IconButton, Tooltip, Tabs, Tab } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import { useWidgetContext } from '../../../../hooks/widgetContext';
import WidgetLoading from '../../../SmartDashboard/Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';
import { dateFormatter } from '../../../../utils/timeFormat';
import { FuhFumVisitTypeEnum } from '../../../../Enum/FuhFumVisitTypeEnum';
import { fetchFuhFumExceptionSummary } from '../../../Widgets/WidgetService';
import { isDefaultDate } from '../../../../utils/common';
import { parentUrl } from '../../../../utils/constants';

const FuhFumExceptionSummary = () => {
    const authData = new Authorization();
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const { exceptionTab, setExceptionTab } = useWidgetContext();

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        setIsFetched(false);
        const changeFilter = async () => {
            setIsFetched(false);
            const response = await fetchFuhFumExceptionSummary();
            setRows(response);
            let filterResponse = response.filter((m) => m.FuhFumVisitType === exceptionTab);
            setFilteredRows(filterResponse);
            setIsFetched(true);
        };
        changeFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData.UserId, setIsFetched]);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'Gender',
            headerName: 'Gender',
            flex: 0.5,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 2,
        },
        {
            field: 'AdmitDate',
            headerName: 'Admission Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? '-' : dateFormatter(new Date(params.row.AdmitDate))}`,
        },
        {
            field: 'Facility',
            headerName: 'Facility',
            flex: 2,
            renderCell: (params) => {
                let facilityInfo = Boolean(params.row.Facility) ? params.row.Facility : '-';
                return <>{facilityInfo}</>;
            },
        },
    ];

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 90) + ''));
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let filterResponse = [];
        switch (newValue) {
            case 0: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === 0);
                break;
            }
            case 1: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.InpatientAdmit);
                break;
            }
            case 2: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.EDAdmit);
                break;
            }
            case 3: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.PostDischarge);
                break;
            }
            case 4: {
                filterResponse = rows.filter((m) => m.FuhFumVisitType === FuhFumVisitTypeEnum.EDDischarge);
                break;
            }
        }
        setFilteredRows(filterResponse);
        setExceptionTab(newValue);
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/fuhfumexceptionmemberdetail`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item>
                    <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                        <span className="pointer" onClick={navigateToMembers}>
                            FUHFUM Exception Summary
                        </span>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="caption">Unmatched Members who are not aligned with a TCC.</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ width: '100%', height: 'Calc(70vh - 135px)', position: 'relative' }}>
                        {!isFetched && <WidgetLoading />}
                        <Grid item xs={12} display={'flex'}>
                            <Tabs sx={{ display: 'inline-flex', marginRight: 'auto' }} value={exceptionTab} onChange={handleAlertsTabChange}>
                                <Tab value={1} label="Inpatient admits" />
                                <Tab value={2} label="ED admits" />
                                <Tab value={4} label="ED discharges" />
                                <Tab value={0} label="General" />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} p={2}>
                            <div style={{ width: '100%', height: '50vh' }}>
                                <DataGrid
                                    onCellClick={(params: any) => {
                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                    }}
                                    rows={filteredRows}
                                    columns={columns}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[5]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                        [`& .green`]: {
                                            color: 'green',
                                        },
                                        [`& .red`]: {
                                            color: 'red',
                                        },
                                        [`& .black`]: {
                                            color: 'rgba(0,0,0,0.87)',
                                        },
                                    }}
                                />
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FuhFumExceptionSummary;
