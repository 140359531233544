import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

export const Error = (props) => {
    const navigate = useNavigate();

    const error = useRouteError();

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box justifyContent={'center'} alignItems={'center'}>
            <Stack spacing={2} p={5} width={'100%'}>
                <Typography variant="h5" color={'error.main'}>
                    Error Occurred
                </Typography>
                <Typography>{(error as Error)?.message}</Typography>
                <Button onClick={() => navigate(-1)}>Back</Button>
            </Stack>
        </Box>
    );
};
