import BaseEntity from '../BaseEntity.model';

export default interface IEngagedProvider extends BaseEntity {
    Name: string;
    Role: string;
}

export class EngagedProvider implements IEngagedProvider {
    Id: string;
    Name: string;
    Role: string;

    constructor() {
        this.Id = '';
    }
}
