import { Chip, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { appointmentStatusEnums, rescheduledEventColor } from '../../../utils/constants';
import { getContrastYIQ } from '../Services/CommonCalendarServices';

export interface ICalendarStatusLegendProps {
    direction?: 'row' | 'column';
}

const enumIconTemplate = (eventColor: string, text: string, fullWidth?: boolean) => {
    return (
        <Grid item>
            <Chip sx={{ backgroundColor: eventColor, color: getContrastYIQ(eventColor), width: fullWidth ? '100%' : undefined }} label={text} />
        </Grid>
    );
};

const CalendarStatusLegend = (props: ICalendarStatusLegendProps) => {
    let enumIcons = appointmentStatusEnums
        .sort((a, b) => a.text.localeCompare(b.text))
        .map(({ text, eventColor }) => enumIconTemplate(eventColor, text, props.direction === 'column'));
    // The rescheduled event color isn't actually associated with its own status. It exists simultaneously with
    // no called no show and cancelled events more as a flag than a unique status.
    const rescheduledIcon = enumIconTemplate(rescheduledEventColor, 'Rescheduled NCNS/Cancelled', props.direction === 'column');
    const legendTitle = (
        <Grid item>
            <Typography variant="h6">Appointment Status Legend:</Typography>
        </Grid>
    );
    enumIcons = [legendTitle, ...enumIcons, rescheduledIcon];
    const { direction = 'row' } = props;
    return (
        <>
            <Grid container style={{ marginTop: 10 }} justifyContent={'space-evenly'} direction={direction} spacing={1}>
                {React.Children.toArray(enumIcons)}
            </Grid>
            {direction === 'row' ? <Divider variant="middle" /> : null}
        </>
    );
};

export default React.memo(CalendarStatusLegend);
