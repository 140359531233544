import { MbscCalendarEvent } from '@mobiscroll/react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ProviderSwapRequestDto } from '../Models/Scheduling/ProviderSwapRequestDto.model';
import { getSwappableAppointmentList, mapEventData } from '../components/Calendar/Services/CommonCalendarServices';
import { CalendarEventData } from '../components/Calendar/types/CalendarEventData';
import { IProviderRotationOption } from '../components/ProviderRotation/interfaces/IProviderRotationOption';
import { apiString } from '../utils/constants';
import { setIsLoading, setShowMessage } from './shared.slice';
import { AppThunk } from './store';

export interface IProviderRotationSlice {
    isEventViewerOpen: boolean;
    selectedEvent: CalendarEventData | null;
    events: MbscCalendarEvent[];
    providerOne: IProviderRotationOption | null;
    providerOneSwapSelectionList: string[];
    providerTwo: IProviderRotationOption | null;
    providerTwoSwapSelectionList: string[];
    startDateBoundary: Date;
    endDateBoundary: Date;
}

const initialState: IProviderRotationSlice = {
    isEventViewerOpen: false,
    selectedEvent: null,
    events: [],
    providerOne: null,
    providerOneSwapSelectionList: [],
    providerTwo: null,
    providerTwoSwapSelectionList: [],
    startDateBoundary: new Date(),
    endDateBoundary: new Date(),
};

const providerRotationSlice = createSlice({
    name: 'providerRotation',
    initialState,
    reducers: {
        _setIsEventViewerOpen: (state, action: PayloadAction<boolean>) => {
            state.isEventViewerOpen = action.payload;
        },
        _setSelectedEvent: (state, action: PayloadAction<CalendarEventData | null>) => {
            state.selectedEvent = action.payload;
        },
        _setEvents: (state, action: PayloadAction<MbscCalendarEvent[]>) => {
            state.events = action.payload;
        },
        _setProviderOne: (state, action: PayloadAction<IProviderRotationOption>) => {
            state.providerOne = action.payload;
        },
        _setProviderOneSwapSelectionList: (state, action: PayloadAction<string[]>) => {
            state.providerOneSwapSelectionList = action.payload;
        },
        _setProviderTwo: (state, action: PayloadAction<IProviderRotationOption>) => {
            state.providerTwo = action.payload;
        },
        _setProviderTwoSwapSelectionList: (state, action: PayloadAction<string[]>) => {
            state.providerTwoSwapSelectionList = action.payload;
        },
        _setStartDateBoundary: (state, action: PayloadAction<Date>) => {
            state.startDateBoundary = action.payload;
        },
        _setEndDateBoundary: (state, action: PayloadAction<Date>) => {
            state.endDateBoundary = action.payload;
        },
    },
});

const {
    _setIsEventViewerOpen,
    _setSelectedEvent,
    _setEvents,
    _setProviderOne,
    _setProviderOneSwapSelectionList,
    _setProviderTwo,
    _setProviderTwoSwapSelectionList,
    _setEndDateBoundary,
    _setStartDateBoundary,
} = providerRotationSlice.actions;

export const setIsEventViewerOpen =
    (isOpen: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setIsEventViewerOpen(isOpen));
    };

export const setSelectedEvent =
    (event: CalendarEventData | null): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedEvent(event));
    };

export const setEvents =
    (events: MbscCalendarEvent[]): AppThunk =>
    (dispatch) => {
        dispatch(_setEvents(events));
    };

export const setProviderOne =
    (provider: IProviderRotationOption): AppThunk =>
    (dispatch) => {
        dispatch(_setProviderOne(provider));
    };

export const setProviderOneSwapSelectionList =
    (events: string[]): AppThunk =>
    (dispatch) => {
        dispatch(_setProviderOneSwapSelectionList(events));
    };

export const setProviderTwo =
    (provider: IProviderRotationOption): AppThunk =>
    (dispatch) => {
        dispatch(_setProviderTwo(provider));
    };

export const setProviderTwoSwapSelectionList =
    (events: string[]): AppThunk =>
    (dispatch) => {
        dispatch(_setProviderTwoSwapSelectionList(events));
    };

export const setStartDateBoundary =
    (date: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setStartDateBoundary(date));
    };

export const setEndDateBoundary =
    (date: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setEndDateBoundary(date));
    };

export const providerOneEventToggle =
    (eventId: string): AppThunk =>
    async (dispatch, getState) => {
        const { providerOneSwapSelectionList } = getState().providerRotationSlice;
        const index = providerOneSwapSelectionList.findIndex((id) => id === eventId);
        const newList = structuredClone(providerOneSwapSelectionList);
        // Add
        if (index === -1) {
            newList.push(eventId);
            dispatch(setProviderOneSwapSelectionList(newList));
        } else {
            // Remove
            newList.splice(index, 1);
            dispatch(setProviderOneSwapSelectionList(newList));
        }
    };

export const providerTwoEventToggle =
    (eventId: string): AppThunk =>
    async (dispatch, getState) => {
        const { providerTwoSwapSelectionList } = getState().providerRotationSlice;
        const index = providerTwoSwapSelectionList.findIndex((id) => id === eventId);
        const newList = structuredClone(providerTwoSwapSelectionList);
        // Add
        if (index === -1) {
            newList.push(eventId);
            dispatch(setProviderTwoSwapSelectionList(newList));
        } else {
            // Remove
            newList.splice(index, 1);
            dispatch(setProviderTwoSwapSelectionList(newList));
        }
    };
    
export const toggleAllProviderCheckboxes =
    (providerName: string, toggledOn: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { events, providerOne, providerTwo } = getState().providerRotationSlice;
        const provider = providerName === providerOne?.ProviderName ? providerOne : providerTwo;
        if (provider === null) return;
        const eventsToToggle = events.filter((event) => event.resource === provider.ProviderId).map((event) => event.id as string);
        if (providerName === providerOne?.ProviderName) {
            dispatch(setProviderOneSwapSelectionList(toggledOn ? eventsToToggle : []));
        } else {
            dispatch(setProviderTwoSwapSelectionList(toggledOn ? eventsToToggle : []));
        }
    };

export const toggleEventCheckbox =
    (eventId: string): AppThunk =>
    async (dispatch, getState) => {
        const { events, providerOne, providerTwo } = getState().providerRotationSlice;
        const event = events.find((e) => e.id === eventId) as unknown as CalendarEventData;
        const resource = event.resource;
        if (resource === providerOne.ProviderId) {
            dispatch(providerOneEventToggle(eventId));
        } else if (resource === providerTwo.ProviderId) {
            dispatch(providerTwoEventToggle(eventId));
        } else {
            dispatch(setShowMessage(true, 'Error: event could not be associated with the provider. Please submit a bug ticket.', 'error'));
        }
    };
    
export const viewEvent =
    (event: CalendarEventData): AppThunk =>
    async (dispatch) => {
        dispatch(setSelectedEvent(event));
        dispatch(setIsEventViewerOpen(true));
    };

export const fetchSwappableLists = (): AppThunk => async (dispatch, getState) => {
    const { providerOne, providerTwo, startDateBoundary, endDateBoundary } = getState().providerRotationSlice;
    if (providerOne === null || providerTwo === null) {
        dispatch(setEvents([]));
    } else {
        dispatch(setIsLoading(true));
        const providerOneResponse = await getSwappableAppointmentList(providerOne.ProviderId, startDateBoundary, endDateBoundary);
        const providerTwoResponse = await getSwappableAppointmentList(providerTwo.ProviderId, startDateBoundary, endDateBoundary);

        const providerOneSchedules = providerOneResponse.data.map(mapEventData);
        const providerTwoSchedules = providerTwoResponse.data.map(mapEventData);

        const schedulesNotShared = [
            ...providerOneSchedules.filter((schedule) => !schedule.ProviderIds.includes(providerTwo?.ProviderId)),
            ...providerTwoSchedules.filter((schedule) => !schedule.ProviderIds.includes(providerOne?.ProviderId)),
        ];

        const resourceScheduleMap = [providerOne, providerTwo].map((provider) => {
            const resourceSchedules = schedulesNotShared.filter((schedule) => schedule.ProviderIds.includes(provider.ProviderId));
            return {
                id: provider.ProviderId,
                events: resourceSchedules,
            };
        });

        const events = resourceScheduleMap
            .map((resource) => {
                return resource.events.map((event) => {
                    return {
                        ...event,
                        resource: resource.id,
                        title: event.title,
                    };
                });
            })
            .flat()
            .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

        dispatch(setEvents(events));
    }
    dispatch(setIsLoading(false));
};

export const swapAppointments = (): AppThunk => async (dispatch, getState) => {
    const { providerOneSwapSelectionList, providerTwoSwapSelectionList, events, providerOne, providerTwo } = getState().providerRotationSlice;
    dispatch(setIsLoading(true));
    if (providerOneSwapSelectionList !== null && providerTwoSwapSelectionList !== null) {
        try {
            const swapRequestDto: ProviderSwapRequestDto = {
                ProviderOne: {
                    ProviderId: providerOne.ProviderId,
                    Appointments: providerOneSwapSelectionList.map((item) => {
                        let event = structuredClone(events.find((e) => e.id === item) as unknown as CalendarEventData);
                        return event;
                    }),
                },
                ProviderTwo: {
                    ProviderId: providerTwo.ProviderId,
                    Appointments: providerTwoSwapSelectionList.map((item) => {
                        let event = structuredClone(events.find((e) => e.id === item) as unknown as CalendarEventData);
                        return event;
                    }),
                },
            };
            const response = await axios.post(`${apiString}/scheduling/SwapProviderAppointments`, swapRequestDto);
            if (response.status === 200) {
                // refetch both schedules
                dispatch(setProviderOneSwapSelectionList([]));
                dispatch(setProviderTwoSwapSelectionList([]));
                dispatch(fetchSwappableLists());
            }
        } catch (error) {
            dispatch(setShowMessage(true, error.message, 'error'));
            console.error(error);
            dispatch(setIsLoading(false));
        }
    }
};

export default providerRotationSlice.reducer;
