import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import { Chip, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    addBookmarkToRequest,
    getAgeLabel,
    removeBookmarkFromRequest,
    setSelectedRequestId,
    updateProvidersRecentlyViewedRequests,
} from '../../../store/requests.slice';
import { ProviderId } from '../../../utils/constants';
import { findRequestType } from '../../../utils/mappings';
import { IRequest } from '../interfaces/IRequest';
import { requestStatusMapping } from '../mappings/RequestStatusMapping';
import StatusIcon from '../StatusIcon/StatusIcon';

const RequestListItem: React.FC<IRequest & { index: number }> = ({
    Id,
    RequestFor,
    RequestTo,
    BookmarkedByProviders,
    CreatedOn,
    Status,
    RequestTypeLabel,
    SubRequestTypeLabel,
    Type,
    Priority,
    index,
    isSettingBookmark,
    TimelineEvents,
    RequestFrom,
}) => {
    const dispatch = useDispatch();
    const { selectedRequest } = useSelector((state: RootState) => state.requests);
    const handleOnClick = () => {
        dispatch(setSelectedRequestId(Id));
        dispatch(updateProvidersRecentlyViewedRequests(ProviderId, Id));
    };

    const bookmarked = useMemo(() => {
        return BookmarkedByProviders?.includes(ProviderId) ?? false;
    }, [BookmarkedByProviders]);

    const requestTypeText = useMemo(() => {
        return findRequestType(Type)?.text;
    }, [Type]);

    const handleBookmarkClick = async () => {
        if (BookmarkedByProviders?.includes(ProviderId)) {
            dispatch(removeBookmarkFromRequest(Id, ProviderId));
        } else {
            dispatch(addBookmarkToRequest(Id, ProviderId));
        }
    };

    const requestAgeLabel = useMemo(() => {
        let label = '';
        const age = getAgeLabel(new Date(CreatedOn));
        if (age === '0') {
            label = 'Today';
        } else if (age === '1') {
            label = `Yesterday`;
        } else {
            label = `${age} days ago`;
        }
        return label;
    }, [CreatedOn]);

    const statusChip = useMemo(() => {
        if (!Status) return undefined;
        let { label } = Object.values(requestStatusMapping).find(({ value }) => value === Status);
        if (Status === requestStatusMapping.ClosedWithNoAction.value) {
            label = 'Closed';
        }
        let color = undefined;
        switch (Status) {
            case 1: {
                color = 'info';
                break;
            }
            case 2: {
                color = 'warning';
                break;
            }
            case 3: {
                color = 'success';
                break;
            }
            case 4: {
                break;
            }
        }
        return <Chip sx={{ width: 130 }} size="small" icon={<StatusIcon status={Status} />} label={label} color={color} />;
    }, [Status]);

    const priorityChip = useMemo(() => {
        if (!Priority) return undefined;
        let label = '';
        let color = undefined;
        switch (Priority) {
            case 1: {
                label = 'Low';
                color = 'info';
                break;
            }
            case 2: {
                label = 'Medium';
                color = 'warning';
                break;
            }
            case 3: {
                label = 'High';
                color = 'error';
                break;
            }
        }
        return <Chip size="small" label={label} color={color} />;
    }, [Priority]);

    const label = useMemo(() => {
        if (SubRequestTypeLabel?.length > 0) return SubRequestTypeLabel;
        if (RequestTypeLabel?.length > 0) return RequestTypeLabel;
        return requestTypeText ?? 'Unknown Request Type';
    }, [SubRequestTypeLabel, RequestTypeLabel, requestTypeText]);

    return (
        <Grid
            id={`request-list-item-${Id}`}
            container
            alignItems={'center'}
            justifyContent="space-between"
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                backgroundColor: selectedRequest?.Id === Id ? 'whitesmoke' : undefined,
            }}
            onClick={handleOnClick}
        >
            <Grid item lg={8} md={8} sm={8} xs={8}>
                {statusChip}
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
                {priorityChip}
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="button">{`Request: ${label}`}</Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography component="span" variant="caption">
                    {`Name: ${RequestFor?.Name ?? ''}`}
                </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography component="span" variant="caption">
                    {`Provider: ${RequestTo?.Name ?? ''}`}
                </Typography>
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography component="span" variant="caption">{`Originator: ${
                    TimelineEvents?.[0]?.InitiatorName ?? RequestFrom.Name ?? 'unknown'
                }`}</Typography>
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
                <Typography component="span" variant="caption">{`Requested ${requestAgeLabel}`}</Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} style={{ overflow: 'visible' }}>
                <Tooltip enterDelay={500} arrow={true} title={!bookmarked ? 'Add To Your Bookmarks' : 'Remove From Your Bookmarks'} placement="top">
                    {!isSettingBookmark ? (
                        <IconButton onClick={handleBookmarkClick}>
                            {!bookmarked ? <BookmarkBorderIcon fontSize="small" /> : <BookmarkIcon color="info" fontSize="small" />}
                        </IconButton>
                    ) : (
                        <CircularProgress size={23} />
                    )}
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default RequestListItem;
