import { useEffect, useCallback } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { logout, setIsInactive } from '../../../store/authentication.slice';
import { setShowMessage } from '../../../store/shared.slice';
import { ERROR, WARNING } from '../../../utils/constants';
import moment from 'moment';
import { copyObject } from '../../../utils/common';

export default function AuthRequired() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Retrieve the authenticated user from local storage
    const getLocalAuth = () => {
        const authString = localStorage.getItem('auth');
        const authenticatedUser = authString ? JSON.parse(authString) : null;
        return authenticatedUser;
    };

    // Determine if the user is not authorized
    const notAuthorized = !getLocalAuth() || !getLocalAuth()?.AccessToken;
    const notAdmin = location.pathname.split('/')[1] === 'admin' && !getLocalAuth()?.IsAdmin;

    const handleLogoutInactive = useCallback(() => {
        localStorage.setItem('isInactive', 'true');
        dispatch(logout());
        navigate('/', { replace: true });
        dispatch(setShowMessage(true, 'You have been logged out due to inactivity.', ERROR));
        dispatch(setIsInactive(true));
    }, [dispatch, navigate]);

    const handleLogout = useCallback(() => {
        dispatch(logout());
        navigate('/', { replace: true });
    }, [dispatch, navigate]);

    const checkInactivity = useCallback(() => {
        const localAuth = getLocalAuth();
        if (localAuth) {
            const difference = moment(localAuth?.TokenExpires).diff(moment(), 'seconds') || 0;
            // console.log('Token expires in: ', difference, ' seconds');
            if (difference === 5) {
                dispatch(setShowMessage(true, `Session will expire in ${difference} seconds.`, WARNING));
            }
            if (difference <= 0) {
                handleLogoutInactive();
            }
        } else {
            return;
        }
    }, [dispatch, handleLogoutInactive]);

    const resetTimer = useCallback(() => {
        const localAuth = getLocalAuth();
        if (localAuth) {
            let authCopy = copyObject(localAuth);
            // authCopy.TokenExpires = moment().add(15, 'seconds').toISOString(); // for debugging
            authCopy.TokenExpires = moment().add(15, 'minutes').toISOString();
            localStorage.setItem('auth', JSON.stringify(authCopy));
        } else {
            return;
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(checkInactivity, 1000);

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keypress', resetTimer);

        return () => {
            clearInterval(interval);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keypress', resetTimer);
        };
    }, [resetTimer, checkInactivity]);

    const handleStorageEvent = useCallback(
        (event) => {
            if (event.key === 'isInactive') {
                dispatch(setIsInactive(true));
            }
            if (event.key === 'logout-event') {
                dispatch(logout());
                navigate('/', { replace: true });
            }
        },
        [dispatch, navigate]
    );

    useEffect(() => {
        window.addEventListener('storage', handleStorageEvent);

        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, [handleStorageEvent]);

    // Setup axios interceptor for handling unauthorized responses globally
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (response) => {
                checkInactivity();
                return response;
            },
            (error) => {
                if (error.response?.status === 401) {
                    console.log('Unauthorized, redirecting to login page');
                    dispatch(setShowMessage(true, '401 Unauthorized', ERROR));
                    handleLogout();
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [dispatch, handleLogout, checkInactivity]);

    if (notAuthorized) {
        handleLogout();
        return <Navigate to="/" replace />;
    }

    if (notAdmin) {
        dispatch(setShowMessage(true, 'No Admin Permissions', ERROR));
        handleLogout();
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
}
