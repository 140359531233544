import { StatusEnum } from '../../Enum/StatusEnum';
import { Question } from './AssessmentCategory.model';

export default interface IDiabetesFootAssessment {
    Id: string;
    Comments: string;
    Name: string;
    Status: StatusEnum;
}

export class DiabetesFootAssessment {
    Id: string;
    MemberId: string;
    MemberName: string;
    CreatedDate: string;
    CreatedBy: string;
    LastUpdatedDate: string;
    UpdatedBy: string;
    Deleted: boolean;
    Status: StatusEnum;
    Questions: FootQuestion[];
    FootSensations: FootSensation[];
    SkinConditions: SkinCondition[];

    constructor() {
        this.Id = '';
        this.MemberName = '';
        this.Questions = [] as FootQuestion[];
        this.FootSensations = [] as FootSensation[];
        this.SkinConditions = [] as SkinCondition[];
    }
}

export class FootQuestion extends Question {
    LeftFoot: boolean;
    RightFoot: boolean;
}

export class FootSensation {
    Id: string;
    ImageId: string;
    LocationX: number;
    LocationY: number;
    Note: string;
    FeelFilament: boolean | null;
    CreatedDate: string;
    CreatedBy: string;
    LastUpdatedDate: string;
    UpdatedBy: string;
    Deleted: boolean;
    footSensationIndex: number;
}

export class SkinCondition {
    Id: string;
    ImageId: string;
    LocationX: number;
    LocationY: number;
    Note: string;
    ItemClick: number;
    Mark: string;
    FeelFilament: boolean | null;
    CreatedDate: string;
    CreatedBy: string;
    LastUpdatedDate: string;
    UpdatedBy: string;
    Deleted: boolean;
}
