import React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

const NumberFormatCustom: React.FC<NumericFormatProps> = (props) => {
    return (
        <NumericFormat
            {...props}
            value={props.value}
            onChange={() => {}}
            onValueChange={(values) => {
                props.onChange({
                    target: {
                        name: props.name,
                        value: Boolean(values.value) ? parseFloat(values.value) : values.value,
                    },
                } as any);
            }}
            thousandSeparator=","
        />
    );
};

const CurrencyInput: React.FC<TextFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            className={`${props.label ? '' : 'hide-textinput-label '}${props.className || ''}`}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                startAdornment: <InputAdornment position="start"> $</InputAdornment>,
            }}
        />
    );
};

export default CurrencyInput;
