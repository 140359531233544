import { useCallback, useEffect, useState } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'simplebar/dist/simplebar.min.css';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { fetchPopulations } from '../../Population/PopulationService';
import { fetchTeamMemberSummary, MenuProps } from '../../Widgets/WidgetService';
import Population from '../../../Models/Population/Population.model';
import Authorization from '../../../utils/Authorization';
import TeamMemberSummaryModel from '../../../Models/Widget/TeamMemberSummary.model';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import '../Widgets.css';

const TeamMemberSummary = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const authData = new Authorization();
    const [summary, setSummary] = useState<TeamMemberSummaryModel>();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isDataShow, setIsDataShow] = useState<boolean>(false);
    const { healthPlans } = useHealthPlanContext();
    const [populations, setPopulations] = useState<Population[]>([]);
    const [show, setShow] = useState(false);
    const {
        teamMemberSelectedPlan,
        setTeamMemberSelectedPlan,
        teamMemberSelectedPopulation,
        setTeamMemberSelectedPopulation,
        teamMemberSelectedTeam,
        setTeamMemberSelectedTeam,
        teamShowInActiveTeams,
        setTeamShowInActiveTeams,
        teamMemberFromDate,
        setTeamMemberFromDate,
        teamMemberToDate,
        setTeamMemberToDate,
    } = useWidgetContext();
    const filteredTeams = (teamShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const filteredHealthPlans = healthPlans
        .filter((item) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const healthPlanChange = useCallback(
        async (healthPlanId: string, canResetPopulation = true) => {
            setPopulations([]);
            if (canResetPopulation) {
                setTeamMemberSelectedPopulation(0);
            }
            if (healthPlanId && healthPlanId !== 'All') {
                const populations = await fetchPopulations(healthPlanId, true);
                setPopulations(populations);
            }
        },
        [setTeamMemberSelectedPopulation]
    );

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        if (teamMemberSelectedPlan !== 'All') healthPlanChange(teamMemberSelectedPlan, false);

        if (Boolean(authData.TeamId)) {
            setTeamMemberSelectedTeam(authData.TeamId);
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, healthPlanChange, authData.TeamId, setTeamMemberSelectedTeam, teamMemberSelectedPlan]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        const changeFilter = async () => {
            setIsDataShow(false);
            const data = await fetchTeamMemberSummary(
                teamMemberSelectedPlan,
                teamMemberSelectedPopulation,
                teamMemberSelectedTeam,
                Boolean(teamMemberFromDate) ? new Date(teamMemberFromDate).toISOString() : '',
                Boolean(teamMemberToDate) ? new Date(teamMemberToDate).toISOString() : '',
                teamShowInActiveTeams
            );
            setSummary(data);
            setIsDataShow(true);
        };

        if ((teamMemberFromDate != null && teamMemberToDate != null) || (teamMemberFromDate == null && teamMemberToDate == null)) {
            changeFilter();
        }
    }, [teamMemberSelectedPlan, teamMemberSelectedPopulation, teamMemberSelectedTeam, teamMemberFromDate, teamMemberToDate, teamShowInActiveTeams]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/teammemberdetail`);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setTeamShowInActiveTeams(isChecked);
        setTeamMemberSelectedTeam('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5} sx={{ minHeight: '63vh' }}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    Team Member Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">Number of members in all status assigned to each team</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Health Plan</InputLabel>
                                <Select
                                    value={teamMemberSelectedPlan}
                                    label="Health Plan"
                                    name="Health Plan"
                                    onChange={(e) => {
                                        setTeamMemberSelectedPlan(e.target.value);
                                        healthPlanChange(e.target.value, true);
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {filteredHealthPlans.map((plan) => (
                                        <MenuItem key={plan.Id} value={plan.Id}>
                                            {plan.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Population</InputLabel>
                                <Select
                                    value={teamMemberSelectedPopulation}
                                    label="Population"
                                    name="Population"
                                    onChange={(e) => setTeamMemberSelectedPopulation(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="0">
                                        All
                                    </MenuItem>
                                    {populations.map((pop) => (
                                        <MenuItem key={pop.PopulationNumber} value={pop.PopulationNumber}>
                                            {pop.PopulationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={teamMemberSelectedTeam}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setTeamMemberSelectedTeam(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={teamShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <DesktopDatePicker
                                label="From Date"
                                value={Boolean(teamMemberFromDate) ? teamMemberFromDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => setTeamMemberFromDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <DesktopDatePicker
                                label="To Date"
                                value={Boolean(teamMemberToDate) ? teamMemberToDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => setTeamMemberToDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isDataShow ? (
                                <Box className="loader-center" sx={{ minHeight: '42vh' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <TableContainer component={Paper} sx={{ maxHeight: '42vh' }}>
                                    <Table aria-label="simple table" stickyHeader onClick={navigateToMembers} className="pointer">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell size="small">Status</TableCell>
                                                <TableCell align="right" size="small">
                                                    Count
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {summary.TeamMemberDetail.map((member) => (
                                                <TableRow key={member.Status}>
                                                    <TableCell size="small">{member.Status}</TableCell>
                                                    <TableCell align="right" size="small">
                                                        <b> {member.Count}</b>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default TeamMemberSummary;
