export enum EncounterPlaceEnum {
    Phone = 2,
    Telemedicine = 2,
    Email = 11,
    TextMessage = 11,
    OfficeVisit = 11,
    Home = 12,
    SkilledNursingFacility = 31,
    NursingFacility = 32,
}
