import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    Box,
    CircularProgress,
    Stack,
    Typography,
    Collapse,
    TextField,
    Checkbox,
    FormLabel,
} from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { HealthEvaluationResponses, YesNoAnswers } from '../../../utils/assessments';
import { copyObject, preventTextInput } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchScreeningFuhFumById, saveScreeningFuhFum, fetchHasFuhfumReferral } from '../../../store/fuhfum.slice';
import { ScreeningFuhFum } from '../../../Models/FuhFum/ScreeningFuhFum.model';
import { HealthEvaluationInLastSixMonths } from '../../../Models/FuhFum/ScreeningFuhFum.model';
import { InpatientHospitalizationsLastThreeMonths } from '../../../Models/FuhFum/ScreeningFuhFum.model';
import { EDVisitsInLastThreeMonths } from '../../../Models/FuhFum/ScreeningFuhFum.model';
import { MemberLeftAMA } from '../../../Models/FuhFum/ScreeningFuhFum.model';
import { HealthEvaluationResponseEnum } from '../../../Enum/HealthEvaluationResponseEnum';
import { AnswerOptionsEnum } from '../../../Enum/AnswerOptionsEnum';

const ScreeningFuhFumAssessment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const { screeningFuhFum, isFetching, isCloning, isSaving: isSavingFuhfum } = useSelector((state: RootState) => state.fuhfum);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingFuhfum || isSavingEngagementDraft;
    const [agreesToFollowUp, setAgreesToFollowUp] = useState<boolean>(false);
    const [refuseToEnroll, setRefuseToEnroll] = useState<boolean>(false);
    const [showReferralPopUp, setShowReferralPopUp] = useState<boolean>(false);

    useEffect(() => {
        if (Boolean(engagementDraft.ScreeningFuhFumId)) {
            dispatch(fetchScreeningFuhFumById(engagementDraft.ScreeningFuhFumId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveScreeningFuhFum({ ...screeningFuhFum, Status: StatusEnum.Completed }, false));
            dispatch(
                fetchHasFuhfumReferral(engagementDraft.Id, (hasFuhfumReferral: boolean) => {
                    if (hasFuhfumReferral) {
                        setShowSaveAlert(false);
                        setShowReferralPopUp(true);
                    } else {
                        dispatch(gotoNextMenu());
                    }
                })
            );
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnAgreeToMember = () => {
        if (agreesToFollowUp) {
            dispatch(gotoNextMenu());
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeHealthEvaluation = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(screeningFuhFum) as ScreeningFuhFum;
        data.MemberId = memberId;
        data.HealthEvaluationInLastSixMonths = data.HealthEvaluationInLastSixMonths ?? new HealthEvaluationInLastSixMonths();
        data.HealthEvaluationInLastSixMonths[propName] = val;

        dispatch(saveScreeningFuhFum(data, false, saveOnlyLocal));
    };

    const handleOnChangeEDVisits = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(screeningFuhFum) as ScreeningFuhFum;
        data.MemberId = memberId;
        data.EDVisitsInLastThreeMonths = data.EDVisitsInLastThreeMonths ?? new EDVisitsInLastThreeMonths();
        data.EDVisitsInLastThreeMonths[propName] = val;

        dispatch(saveScreeningFuhFum(data, false, saveOnlyLocal));
    };

    const handleOnChangeHospitalize = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(screeningFuhFum) as ScreeningFuhFum;
        data.MemberId = memberId;
        data.InpatientHospitalizationsLastThreeMonths = data.InpatientHospitalizationsLastThreeMonths ?? new InpatientHospitalizationsLastThreeMonths();
        data.InpatientHospitalizationsLastThreeMonths[propName] = val;

        dispatch(saveScreeningFuhFum(data, false, saveOnlyLocal));
    };

    const handleOnMemberLeftAMA = (val: any, propName: string, saveOnlyLocal = false) => {
        let data = copyObject(screeningFuhFum) as ScreeningFuhFum;
        data.MemberId = memberId;
        data.MemberLeftAMA = data.MemberLeftAMA ?? new MemberLeftAMA();
        data.MemberLeftAMA[propName] = val;

        dispatch(saveScreeningFuhFum(data, false, saveOnlyLocal));
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" sx={{ pt: 4 }}>
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form">
                        <Typography variant="h6" component="label">
                            Screening Questions
                        </Typography>
                        <hr />
                        <Stack paddingY={2}>
                            <Typography variant="subtitle1" component="label" fontWeight={500} paddingBottom={1}>
                                How would you evaluate your health over the last 6 months?
                            </Typography>
                            <Stack paddingLeft={2}>
                                <RadioGroup
                                    row
                                    name="Response"
                                    value={screeningFuhFum.HealthEvaluationInLastSixMonths?.Response}
                                    onChange={(e) => handleOnChangeHealthEvaluation(parseInt(e.target.value), e.target.name)}
                                >
                                    {HealthEvaluationResponses.map((type, index) => (
                                        <FormControlLabel key={`health-evaluation-${index}`} value={type.Id} control={<Radio />} label={type.Name} />
                                    ))}
                                </RadioGroup>
                                <Collapse
                                    in={
                                        screeningFuhFum.HealthEvaluationInLastSixMonths?.Response === HealthEvaluationResponseEnum.Worse ||
                                        screeningFuhFum.HealthEvaluationInLastSixMonths?.Response === HealthEvaluationResponseEnum.SomewhatWorse
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <TextField
                                        fullWidth
                                        sx={{ maxWidth: 450, marginTop: 2 }}
                                        label="Why It Feels Worse"
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={screeningFuhFum.HealthEvaluationInLastSixMonths?.Reason}
                                        name="Reason"
                                        onChange={(e) => handleOnChangeHealthEvaluation(e.target.value, e.target.name, true)}
                                        onBlur={(e) => handleOnChangeHealthEvaluation(e.target.value, e.target.name)}
                                    />
                                </Collapse>
                            </Stack>
                        </Stack>
                        <Stack paddingY={2}>
                            <Typography variant="subtitle1" component="label" fontWeight={500} paddingBottom={1}>
                                3 or more inpatient hospitalizations in the last 3 months?
                            </Typography>
                            <Stack paddingLeft={2}>
                                <RadioGroup
                                    row
                                    name="Response"
                                    value={screeningFuhFum.InpatientHospitalizationsLastThreeMonths?.Response}
                                    onChange={(e) => handleOnChangeHospitalize(parseInt(e.target.value), e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`answer-bath-body-${index}`} value={type.Value} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                                <Collapse
                                    in={screeningFuhFum.InpatientHospitalizationsLastThreeMonths?.Response === AnswerOptionsEnum.Yes}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <TextField
                                        label="How Many Times"
                                        variant="outlined"
                                        name="NumberOfVisits"
                                        type="number"
                                        onKeyPress={preventTextInput}
                                        defaultValue={screeningFuhFum.InpatientHospitalizationsLastThreeMonths?.NumberOfVisits}
                                        placeholder="Enter quantity here"
                                        inputProps={{ min: 0, max: 100 }}
                                        style={{ width: '200px', marginTop: 2 }}
                                        onChange={(e) => handleOnChangeHospitalize(e.target.value, e.target.name, true)}
                                        onBlur={(e) => handleOnChangeHospitalize(e.target.value, e.target.name)}
                                    />
                                </Collapse>
                            </Stack>
                        </Stack>
                        <Stack paddingY={2}>
                            <Typography variant="subtitle1" component="label" fontWeight={500} paddingBottom={1}>
                                6 or more ED visits in the last 3 months?
                            </Typography>
                            <Stack paddingLeft={2}>
                                <RadioGroup
                                    row
                                    name="Response"
                                    value={screeningFuhFum.EDVisitsInLastThreeMonths?.Response}
                                    onChange={(e) => handleOnChangeEDVisits(e.target.value, e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`answer-bath-body-${index}`} value={type.Value} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                                <Collapse in={screeningFuhFum.EDVisitsInLastThreeMonths?.Response === AnswerOptionsEnum.Yes} timeout="auto" unmountOnExit>
                                    <TextField
                                        label="How Many Times"
                                        variant="outlined"
                                        name="NumberOfVisits"
                                        type="number"
                                        onKeyPress={preventTextInput}
                                        defaultValue={screeningFuhFum.EDVisitsInLastThreeMonths?.NumberOfVisits}
                                        placeholder="Enter quantity here"
                                        inputProps={{ min: 0, max: 100 }}
                                        style={{ width: '200px', marginTop: 2 }}
                                        onChange={(e) => handleOnChangeEDVisits(e.target.value, e.target.name, true)}
                                        onBlur={(e) => handleOnChangeEDVisits(e.target.value, e.target.name)}
                                    />
                                </Collapse>
                            </Stack>
                        </Stack>
                        <Stack paddingY={2}>
                            <Typography variant="subtitle1" component="label" fontWeight={500} paddingBottom={1}>
                                Member left AMA during the admission or another admission in the past 90 days
                            </Typography>
                            <Stack paddingLeft={2}>
                                <RadioGroup
                                    row
                                    name="Response"
                                    value={screeningFuhFum.MemberLeftAMA?.Response}
                                    onChange={(e) => handleOnMemberLeftAMA(e.target.value, e.target.name)}
                                >
                                    {YesNoAnswers.map((type, index) => {
                                        return <FormControlLabel key={`answer-bath-body-${index}`} value={type.Value} control={<Radio />} label={type.Name} />;
                                    })}
                                </RadioGroup>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {Boolean(engagementDraft.ScreeningFuhFumId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Screening FUHFUM"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Screening FUHFUM is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Screening FUHFUM"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Screening FUHFUM?"
                />
            )}
            <AlertDialog
                open={showReferralPopUp}
                title="CHP Full Program"
                cancelText="Go Back"
                okText="Confirm"
                isLoading={isCloning}
                onConfirm={() => handleOnAgreeToMember()}
                onClose={() => {
                    setShowReferralPopUp(false);
                }}
                disabledSubmit={(!agreesToFollowUp && !refuseToEnroll) || isCloning}
                renderBody={() => (
                    <Stack>
                        <FormLabel>
                            The member is eligible for the CHP full program. Do you want to proceed with enrolling the member in the CHP full program?
                        </FormLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreesToFollowUp}
                                    onChange={(e) => {
                                        setAgreesToFollowUp(e.target.checked);
                                        setRefuseToEnroll(e.target.checked ? !e.target.checked : false);
                                    }}
                                />
                            }
                            label="Member agrees to follow-up"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={refuseToEnroll}
                                    name="refuseToEnroll"
                                    onChange={(e) => {
                                        setRefuseToEnroll(e.target.checked);
                                        setAgreesToFollowUp(e.target.checked ? !e.target.checked : false);
                                    }}
                                />
                            }
                            label="Member refuses to enroll"
                        />
                    </Stack>
                )}
            />
        </Grid>
    );
};

export default ScreeningFuhFumAssessment;
