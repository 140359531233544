import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '@mui/icons-material';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CreateIcon from '@mui/icons-material/Create';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { KeyboardArrowUp } from '@mui/icons-material';

import { RootState } from '../../reducers';
import classes from './Styles';
import { fetchRoles } from '../../store/shared.slice';
import { getDataComparator, dataStableSort, Order, copyObject } from '../../utils/common';
import {
    generateAssessmentWorkflowPDF,
    getDocumentEngagementByMemberId,
    getMeansOfEngagements,
    getReasonForVisit,
    resetDownloadVerificationModal,
    toggleShowDocEngagementModal,
} from '../../store/documentEngagement.slice';
import { DocumentEngagement } from '../../Models/DocumentEngagements/DocumentEngagement.model';
import moment from 'moment';
import Authorization from '../../utils/Authorization';
import AddOrUpdateEngagementDetail from './AddOrUpdateEngagementDetail';
import AlertDialog from '../AlertDialog/AlertDialog';
import { PartTwoDownloadEnum } from '../../Enum/PartTwoDownloadEnum';
import { useNavigate, useParams } from 'react-router-dom';

const engagementTableHeadCells = [
    {
        id: 'MeansOfEngagement',
        label: 'Means of Engagement',
    },
    {
        id: 'ReasonForVisit.Name',
        label: 'Reason for Engagement',
    },
    {
        id: 'Contacted',
        isDate: false,
        label: 'Contacted',
    },
    {
        id: 'EngagedProvider.Name',
        label: 'Engaged Provider',
    },
    {
        id: 'VisitedTime',
        label: 'Engagement Date',
    },
    {
        id: 'CreationTime',
        label: 'Creation Date',
    },
    {
        id: 'Notes',
        label: 'Notes',
    },
];

const DocumentEngagements = (props: any) => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const [searchKey, setSearchKey] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const memberId = props.memberId || params.memberId;
    const { roles } = useSelector((state: RootState) => state.shared);
    const { documentEngagements, downloadVerificationModal, meansOfEngagements, isFetchingDocumentEngagements, generatingPdfEngagementIds } = useSelector(
        (state: RootState) => state.documentEngagement
    );
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const authData = new Authorization();
    const canAddEditEngagements = authData.isInPermission('Add / Edit engagements');

    const [showScrollerButton, setShowScrollerButton] = useState(false);
    const scrollToTop = React.useCallback(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const scrolled = document.documentElement.scrollTop;
            setShowScrollerButton(scrolled > 300);
        });
    }, []);

    useEffect(() => {
        dispatch(fetchRoles());
        dispatch(getMeansOfEngagements());
        dispatch(getDocumentEngagementByMemberId(memberId));
        dispatch(getReasonForVisit());
    }, [memberId, dispatch]);

    const handleOnChangeRole = (value: any) => {
        setSelectedRole(value);
    };

    const handleOnRequestSort = (property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const generatePDF = (engagementId: string) => {
        dispatch(generateAssessmentWorkflowPDF(engagementId));
    };

    const handleOnClickProcedureSignOff = (engagementId: string) => {
        navigate(`/provider/claims-management`);
    };

    const prepareDocumentEngagements = () => {
        let data = ((copyObject(documentEngagements) || []) as DocumentEngagement[]).filter(
            (d) => Boolean(d.EngagedProvider?.Name) || Boolean(d.ReasonForVisit?.Name)
        );
        if (Boolean(searchKey)) {
            const searchKeyData = searchKey.toLowerCase();
            data = data.filter(
                (d) =>
                    meansOfEngagements
                        .find((m) => m.Id === d.MeansOfEngagement)
                        ?.Name?.toLowerCase()
                        .includes(searchKeyData) ||
                    d.ReasonForVisit?.Name?.toLowerCase().includes(searchKeyData) ||
                    d.EngagedProvider?.Name?.toLowerCase().includes(searchKeyData) ||
                    d.VisitedTime?.toLowerCase().includes(searchKeyData) ||
                    d.CreationTime?.toLowerCase().includes(searchKeyData) ||
                    d.Notes?.toLowerCase().includes(searchKeyData)
            );
        }

        if (Boolean(selectedRole)) {
            data = data.filter((d) => d.AssessedRole?.RoleName === selectedRole);
        }

        return data;
    };

    const preparedDocEngagements = prepareDocumentEngagements();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={classes.container}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" columnGap={2} width="50%" maxWidth="700px">
                        <FormControl style={{ width: '50%' }}>
                            <InputLabel shrink id="role-select-label">
                                Role
                            </InputLabel>
                            <Select
                                labelId="role-select-label"
                                label="Role"
                                displayEmpty
                                value={selectedRole}
                                input={<OutlinedInput notched label="Role" />}
                                onChange={(event) => handleOnChangeRole(event.target.value)}
                            >
                                <MenuItem value={''}>All Roles</MenuItem>
                                {roles
                                    .filter((r) => r.Id)
                                    .map((type, index) => (
                                        <MenuItem key={`${type.Id}-${index}`} value={type.Name}>
                                            {type.Name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ minWidth: '50%' }}>
                            <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type="text"
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value as string)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Search" edge="end">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search"
                            />
                        </FormControl>
                    </Stack>
                    <Button variant="outlined" onClick={() => dispatch(toggleShowDocEngagementModal(true))}>
                        Document Engagement
                    </Button>
                </Stack>

                <TableContainer component={Paper} elevation={2} variant="elevation" sx={classes.tableContainer}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {engagementTableHeadCells.map((headCell) => (
                                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => handleOnRequestSort(headCell.id)}
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFetchingDocumentEngagements ? (
                                <TableRow>
                                    <TableCell scope="row" colSpan={8} sx={classes.noDataFound}>
                                        <Box display="flex" justifyContent="center" sx={classes.container}>
                                            <CircularProgress />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : Boolean(preparedDocEngagements.length) ? (
                                (dataStableSort(preparedDocEngagements, getDataComparator(order, orderBy, true)) as DocumentEngagement[]).map(
                                    (docEngagement, index) => (
                                        <TableRow key={`${docEngagement.Id}_${index}`} hover>
                                            <TableCell>
                                                {Boolean(docEngagement.MeansOfEngagement) && Boolean(meansOfEngagements?.length)
                                                    ? meansOfEngagements.find((m) => m.Id === docEngagement.MeansOfEngagement)?.Name
                                                    : '-'}
                                            </TableCell>
                                            <TableCell>{docEngagement.ReasonForVisit?.Name || '-'}</TableCell>
                                            <TableCell>{docEngagement.Contacted ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{docEngagement.EngagedProvider?.Name || '-'}</TableCell>
                                            <TableCell>
                                                {moment(docEngagement.VisitedTime).isValid()
                                                    ? moment(docEngagement.VisitedTime).format('MM/DD/yyyy hh:mm a')
                                                    : '-'}
                                            </TableCell>
                                            <TableCell>
                                                {moment(docEngagement.CreationTime).isValid()
                                                    ? moment(docEngagement.CreationTime).format('MM/DD/yyyy hh:mm a')
                                                    : '-'}
                                            </TableCell>
                                            <TableCell width="25%">
                                                <div dangerouslySetInnerHTML={{ __html: docEngagement.Notes }} />
                                            </TableCell>
                                            <TableCell>
                                                <Stack>
                                                    {canAddEditEngagements && authData.UserId === docEngagement.EngagedProvider?.Id ? (
                                                        <Tooltip
                                                            title="Edit"
                                                            placement="left"
                                                            onClick={() => dispatch(toggleShowDocEngagementModal(true, docEngagement))}
                                                        >
                                                            <IconButton>
                                                                <CreateIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : null}
                                                    {canAddEditEngagements ? (
                                                        <Tooltip title="Procedures / Sign Off" placement="left">
                                                            <IconButton onClick={() => handleOnClickProcedureSignOff(docEngagement.Id)}>
                                                                <FactCheckOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : null}
                                                    <Tooltip title="Generate PDF" placement="left">
                                                        <IconButton
                                                            onClick={() => generatePDF(docEngagement.Id)}
                                                            disabled={generatingPdfEngagementIds.some((id) => id === docEngagement.Id)}
                                                        >
                                                            {generatingPdfEngagementIds.some((id) => id === docEngagement.Id) ? (
                                                                <CircularProgress size={18} />
                                                            ) : (
                                                                <PictureAsPdfOutlinedIcon />
                                                            )}
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            ) : (
                                <TableRow>
                                    <TableCell scope="row" colSpan={8} sx={classes.noDataFound}>
                                        No Document Engagement {Boolean(searchKey) ? 'found' : ''}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <AddOrUpdateEngagementDetail memberId={memberId} />
            </Box>
            <AlertDialog
                open={downloadVerificationModal?.Show}
                title="Part 2 consent verification"
                isLoading={generatingPdfEngagementIds.some((id) => id === downloadVerificationModal.EngagementId)}
                okText={String(PartTwoDownloadEnum.WithPartTwoDataDescription)}
                skipText={String(PartTwoDownloadEnum.WithoutPartTwoDataDescription)}
                cancelText="Cancel"
                buttonsDirection="column-reverse"
                skipButtonVariant="contained"
                onConfirm={() => dispatch(resetDownloadVerificationModal({ ...downloadVerificationModal, Show: false, ShowVerificationOptions: true }))}
                onSkip={() => dispatch(generateAssessmentWorkflowPDF(downloadVerificationModal.EngagementId, PartTwoDownloadEnum.WithoutPartTwoData))}
                onClose={() => dispatch(resetDownloadVerificationModal())}
                message={'This assessment contains Part 2 data, which cannot be shared without appropriate Part 2 consent. Do you want to'}
            />
            <AlertDialog
                open={downloadVerificationModal?.ShowVerificationOptions}
                title="Part 2 consent verification"
                isLoading={generatingPdfEngagementIds.some((id) => id === downloadVerificationModal.EngagementId)}
                okText={String(PartTwoDownloadEnum.VerifiedDescription)}
                skipText={String(PartTwoDownloadEnum.InternalUseDescription)}
                cancelText={String(PartTwoDownloadEnum.NoneDescription)}
                buttonsDirection="column-reverse"
                skipButtonVariant="contained"
                onConfirm={() => dispatch(generateAssessmentWorkflowPDF(downloadVerificationModal.EngagementId, PartTwoDownloadEnum.Verified))}
                onSkip={() => dispatch(generateAssessmentWorkflowPDF(downloadVerificationModal.EngagementId, PartTwoDownloadEnum.InternalUse))}
                onClose={() => dispatch(resetDownloadVerificationModal())}
                message={downloadVerificationModal?.Message}
            />
            {showScrollerButton && (
                <Fab onClick={scrollToTop} sx={classes.scrollTopButton} color="primary" size="small">
                    <KeyboardArrowUp />
                </Fab>
            )}
        </LocalizationProvider>
    );
};

export default DocumentEngagements;
