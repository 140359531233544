export enum WidgetTimeFilterEnum {
    AllDescription = 'All',
    All = 0,
    Last3DaysDescription = 'Last 3 Days',
    Last3Days = 1,
    Last5DaysDescription = 'Last 5 Days',
    Last5Days = 2,
    Last7DaysDescription = 'Last 7 Days',
    Last7Days = 3,
    Last10DaysDescription = 'Last 10 Days',
    Last10Days = 4,
    Last15DaysDescription = 'Last 15 Days',
    Last15Days = 5,
    Last30DaysDescription = 'Last 30 Days',
    Last30Days = 6,
    Last60DaysDescription = 'Last 60 Days',
    Last60Days = 7,
    CustomDescription = 'Custom',
    Custom = 8,
}
