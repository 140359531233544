export enum AppointmentStatusEnum {
    None = 0,
    NoneDescription = 'None',
    Confirmed = 1,
    ConfirmedDescription = 'Confirmed',
    NoCallOrNoShow = 2,
    NoCallOrNoShowDescription = 'NC/NS (No call/no show)',
    Cancelled = 3,
    CancelledDescription = 'Cancelled',
    Completed = 4,
    CompletedDescription = 'Completed',
    ProviderLeave = 5,
    ProviderLeaveDescription = 'Provider Unavailable',
}
