import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControlLabel,
    Radio,
    TextField,
    Chip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';

import { getAssessmentByType, gotoNextMenu, gotoPreviousMenu, saveAssessmentWorkflow, updateMemberAssessmentModel } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import { AssessmentTypeEnum } from '../../../Enum/AssessmentTypeEnum';
import { AssessmentCategory } from '../../../Models/Assessment/AssessmentCategory.model';
import classes from '../CategoryQuestions/Styles';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { copyObject } from '../../../utils/common';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor: '#f0eeee',
    },
}));

const UrinalysisReport: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const { isSaving, memberAssessment, isFetching } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const type = AssessmentTypeEnum.UrinalysisReport;

    useEffect(() => {
        dispatch(getAssessmentByType(type, memberId));
    }, [dispatch, type, memberId]);

    useEffect(() => {
        if (isFetching && !isPageLoaded) {
            setIsPageLoaded(true);
        }
    }, [isFetching, isPageLoaded]);

    const handleOnChangeCategory = (updatedCategory: AssessmentCategory) => {
        setIsChanged(true);
        dispatch(updateMemberAssessmentModel(updatedCategory));
    };

    const handleOnUpdateAnswer = (category, isSelected, questionIndex, optionIndex) => {
        let data = copyObject(category);
        data.Questions[questionIndex].Options.forEach((option, index) => {
            if (index === optionIndex) {
                option.IsSelected = isSelected;
            } else {
                option.IsSelected = false;
            }
        });
        data.Questions[questionIndex].Options[optionIndex].IsSelected = isSelected;
        handleOnChangeCategory(data);
    };

    const handleOnUpdateComments = (category, comments: string) => {
        let data = copyObject(category);
        data.Comments = comments;
        handleOnChangeCategory(data);
    };

    const handleOnChangeAnswerText = (category, answerText: string, questionIndex) => {
        let data = copyObject(category);
        data.Questions[questionIndex].AnswerText = answerText;
        handleOnChangeCategory(data);
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveAssessmentWorkflow(memberAssessment.Assessment?.Categories[0], isWithoutComplete, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const renderGeneralTable = () => {
        const generalData = memberAssessment?.Assessment?.Categories[0] || ({} as AssessmentCategory);

        return (
            <TableContainer component={Paper} sx={classes.customTable}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="33%">{generalData.Name}</TableCell>
                            <TableCell width="33%"></TableCell>
                            <TableCell width="33%"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {generalData.Questions?.map((question, qIndex) => (
                            <TableRow key={`group_questtion_row_${qIndex}`}>
                                <TableCell component="th" scope="row">
                                    {question.Description}:
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {qIndex < 2 ? (
                                        <TextField
                                            variant="outlined"
                                            className="x-small-text-input hide-textinput-label"
                                            defaultValue={question.AnswerText}
                                            fullWidth={true}
                                            size="small"
                                            onBlur={(e) => handleOnChangeAnswerText(generalData, e.currentTarget.value, qIndex)}
                                        />
                                    ) : qIndex === 2 ? (
                                        <DesktopDatePicker
                                            className="hide-textinput-label"
                                            value={question.AnswerText ? new Date(question.AnswerText) : null}
                                            inputFormat="MM/dd/yyyy"
                                            minDate={new Date('2017-01-01')}
                                            onChange={(date) => {
                                                if (moment(date).isValid() || !Boolean(date)) {
                                                    handleOnChangeAnswerText(generalData, date.toISOString(), qIndex);
                                                }
                                            }}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    ) : (
                                        <TextField
                                            variant="outlined"
                                            className="x-small-text-input hide-textinput-label initials-input"
                                            defaultValue={question.AnswerText}
                                            fullWidth={true}
                                            size="small"
                                            onBlur={(e) => handleOnChangeAnswerText(generalData, e.currentTarget.value, qIndex)}
                                        />
                                    )}
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderPhysicalExaminationTable = () => {
        const physicalExaminationData = memberAssessment?.Assessment?.Categories[1] || ({} as AssessmentCategory);
        const columnsLength = physicalExaminationData?.Questions[0]?.Options.length;

        return (
            <TableContainer component={Paper} sx={classes.customTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="18%">{physicalExaminationData.Name}</TableCell>
                            <TableCell colSpan={columnsLength}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {physicalExaminationData.Questions?.map((question, qIndex) => (
                            <TableRow key={`group_questtion_row_${qIndex}`}>
                                <TableCell component="th" scope="row">
                                    {question.Description}:
                                </TableCell>
                                {question.Options?.map((option, oIndex) => (
                                    <StyledTableCell key={`body_${option.Name}_${oIndex}`}>
                                        <FormControlLabel
                                            value={String(option.IsSelected)}
                                            sx={classes.smLabelRadioBtn}
                                            control={
                                                <Radio
                                                    checked={option.IsSelected}
                                                    size="small"
                                                    radioGroup={question.Description}
                                                    onChange={(e) => handleOnUpdateAnswer(physicalExaminationData, e.currentTarget.checked, qIndex, oIndex)}
                                                />
                                            }
                                            label={option.Name}
                                        />
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderChemicalExaminationTable = () => {
        const physicalExaminationData = memberAssessment?.Assessment?.Categories[2] || ({} as AssessmentCategory);
        const columnsLength = physicalExaminationData?.Questions[0]?.Options.length;

        return (
            <>
                <TableContainer component={Paper} sx={classes.customTable}>
                    <Table sx={classes.customTable} style={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{physicalExaminationData.Name}</TableCell>
                                <TableCell colSpan={columnsLength}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {physicalExaminationData.Questions?.map((question, qIndex) => (
                                <TableRow key={`group_questtion_row_${qIndex}`}>
                                    <TableCell component="th" scope="row">
                                        {question.Description}:
                                    </TableCell>
                                    {question.Options?.map((option, oIndex) => (
                                        <StyledTableCell key={`body_${option.Name}_${oIndex}`}>
                                            <FormControlLabel
                                                value={String(option.IsSelected)}
                                                sx={classes.smLabelRadioBtn}
                                                control={
                                                    <Radio
                                                        checked={option.IsSelected}
                                                        size="small"
                                                        radioGroup={question.Description}
                                                        onChange={(e) => handleOnUpdateAnswer(physicalExaminationData, e.currentTarget.checked, qIndex, oIndex)}
                                                    />
                                                }
                                                label={option.Name}
                                            />
                                        </StyledTableCell>
                                    ))}
                                    {columnsLength - question.Options.length ? (
                                        <StyledTableCell colSpan={columnsLength - question.Options.length}>
                                            {qIndex === 3 ? '(any pink is considered positive)' : ''}
                                        </StyledTableCell>
                                    ) : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography sx={classes.commentHeader}>Comments</Typography>
                <Box sx={classes.commentBox}>
                    <TextField
                        placeholder="Comments..."
                        defaultValue={physicalExaminationData?.Comments}
                        sx={classes.commentTextarea}
                        multiline
                        onBlur={(e) => handleOnUpdateComments(physicalExaminationData, e.currentTarget.value)}
                        rows={6}
                        fullWidth
                    />
                </Box>
            </>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching || !isPageLoaded ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item lg={12} md={12} sm={12} sx={{ mb: 2 }}>
                        <label>
                            <strong>Last Completed : </strong>{' '}
                            {Boolean(memberAssessment.AssessmentDate) ? (
                                <Chip size="small" label={moment(memberAssessment.AssessmentDate).format('MM/DD/yyyy')} />
                            ) : (
                                '-'
                            )}
                        </label>
                    </Grid>
                    <Grid item className="content-form">
                        <div>
                            <Grid container spacing={0}>
                                <Grid item xs={6} md={8}>
                                    {renderGeneralTable()}
                                </Grid>
                                <Grid item xs={12}>
                                    {renderPhysicalExaminationTable()}
                                </Grid>
                                <Grid item xs={12}>
                                    {renderChemicalExaminationTable()}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            {Boolean(engagementDraft.UrinalysisReportId) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Urinalysis Report Assessment"
                    cancelText="Go Back"
                    okText="Confirm"
                    showBottomCenter={true}
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Urinalysis Report assessment is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Urinalysis Report Assessment"
                    cancelText="No"
                    okText="Yes, Proceed"
                    showBottomCenter={true}
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Urinalysis Report assessment?"
                />
            )}
        </Grid>
    );
};

export default UrinalysisReport;
