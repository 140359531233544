import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { monthNames } from '../../../../utils/constants';
import { useEffect } from 'react';
import {
    getClaimGeneratedCostLast10Visits,
    getMemberClaims,
    getStatusColor,
    getVisitType,
    setFilterBy,
    visitTypes,
} from '../../../../store/performanceDashboard.slice';
import { moneyFormatter } from '../../../../utils/moneyFormat';
import { localDateFormat } from '../../../../utils/timeFormat';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { GridCellParams } from '@mui/x-data-grid/models';
import { isDefaultDate } from '../../../../utils/common';

const ClaimGeneratedCosts = (props) => {
    const dispatch = useDispatch();
    const { filterBy, memberClaims, claimGeneratedCostLast10Visits } = useSelector((state: RootState) => state.performanceDashboard);

    const last10VisitsColumns: GridColDef[] = [
        {
            field: 'ClaimDate',
            headerName: 'Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.ClaimDate) ? '-' : localDateFormat(new Date(params.row.ClaimDate))}`,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${getVisitType(params.row.VisitType)}`,
        },
        {
            field: 'Facility',
            headerName: 'Facility',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.Facility || '-'}`,
        },
        {
            field: 'Cost',
            headerName: 'Cost',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.Cost)}`,
        },
    ];

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: '',
            flex: 1,
        },
        {
            field: 'ThisMonth',
            headerName: `${monthNames[new Date().getMonth()]} (TY)`,
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row.ThisMonth, params.row.ThisMonthLastYear),
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.ThisMonth)}`,
        },
        {
            field: 'ThisMonthLastYear',
            headerName: `${monthNames[new Date().getMonth()]} (LY)`,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.ThisMonthLastYear)}`,
        },
        {
            field: 'ThisMonthCHPMedian',
            headerName: `${monthNames[new Date().getMonth()]} (CHP Median)`,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.ThisMonthCHPMedian)}`,
        },
        {
            field: 'ThisQuarter',
            headerName: 'This Quarter (TY)',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row.ThisQuarter, params.row.ThisQuarterLastYear),
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.ThisQuarter)}`,
        },
        {
            field: 'ThisQuarterLastYear',
            headerName: 'This Quarter (LY)',

            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.ThisQuarterLastYear)}`,
        },
        {
            field: 'ThisQuarterCHPMedian',
            headerName: 'This Quarter (CHP Median)',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.ThisQuarterCHPMedian)}`,
        },
        {
            field: 'PerformYear',
            headerName: 'Perform Year',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => getStatusColor(params.row.PerformYear, params.row.BaselineYear),
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.PerformYear)}`,
        },
        {
            field: 'BaselineYear',
            headerName: 'Baseline Year',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.BaselineYear)}`,
        },
        {
            field: 'PerformCHPMedian',
            headerName: 'Perform (CHP Median)',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.PerformCHPMedian)}`,
        },
    ];

    useEffect(() => {
        if (props.memberId) {
            dispatch(getMemberClaims(props.memberId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.memberId]);

    useEffect(() => {
        dispatch(getClaimGeneratedCostLast10Visits(props.memberId, filterBy));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.memberId, filterBy]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography display={'inline-block'} variant="h6" marginBottom={1} color={'primary'}>
                    Claim Generated Costs
                </Typography>
            </Grid>

            <Grid item style={{ width: '100%' }} marginBottom={2}>
                <DataGrid
                    rows={memberClaims.MemberClaimCost}
                    columns={columns}
                    autoHeight
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => Math.random()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                        [`& .green`]: {
                            color: 'white',
                            background: 'green',
                        },
                        [`& .red`]: {
                            color: 'white',
                            background: 'red',
                        },
                        [`& .black`]: {
                            color: 'rgba(0,0,0,0.87)',
                        },
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <Typography paddingTop={3} display={'inline-block'} variant="h6" color={'primary'}>
                    Last 10 Visits
                </Typography>
            </Grid>
            <Grid item xs={6} textAlign={'right'}>
                <FormControl className="form-control-200" sx={{ textAlign: 'left' }}>
                    <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterBy}
                        label="Filter By"
                        onChange={(e) => {
                            dispatch(setFilterBy(e.target.value as string));
                        }}
                    >
                        {visitTypes.map((el) => {
                            return (
                                <MenuItem key={el.id} value={el.value}>
                                    {el.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item style={{ width: '100%' }} marginTop={2}>
                <DataGrid
                    rows={claimGeneratedCostLast10Visits}
                    columns={last10VisitsColumns}
                    autoHeight
                    rowsPerPageOptions={[5]}
                    getRowId={(row) => Math.random()}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default ClaimGeneratedCosts;
