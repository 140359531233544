import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IOnboarderSettings, { OnboarderSettings } from '../../../Models/AdminOnboarding/OnboarderSettings.model';
import IOnboarderStatus, { OnboarderStatus } from '../../../Models/OfficeOnboarding/OnboarderStatus.model';
import { RootState } from '../../../reducers';
import { fetchOnboarderSettings, fetchOnboarderStatuses, saveOnboarderSettings } from '../AdminOnboardingService';

import OnboarderPanel from './OnboarderPanel/OnboarderPanel';
import OnboarderStatusControlBar from './OnboarderPanel/OnboarderStatusControlBar';
import { setSelectedOnboarder } from '../../../store/adminOnboarding.slice';

interface OnboarderStatusesProps {
    viewing: boolean;
}

const OnboarderStatuses = (props: OnboarderStatusesProps) => {
    const { error, selectedOnboarder } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const dispatch = useDispatch();
    const [onboarderStatuses, setOnboarderStatuses] = useState<IOnboarderStatus[]>([]);
    const [defaultOnboarderSettings, setDefaultOnboarderSettings] = useState<IOnboarderSettings>(new OnboarderSettings());
    const [saveButtonLabel, setSaveButtonLabel] = useState<string>('Save Defaults');
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    // const [selectedOnboarder, setSelectedOnboarder] = useState<IOnboarderStatus>(new OnboarderStatus());
    const [callsGoalValue, setCallsGoalValue] = useState<number | null>(0);
    const [callsGoalLabel, setCallsGoalLabel] = useState<string>('Default Calls Goal');
    const [callsGoalHelperText, setCallsGoalHelperText] = useState<string>('');
    const [callsListLengthValue, setCallsListLengthValue] = useState<number | null>(0);
    const [callsListLengthLabel, setCallsListLengthLabel] = useState<string>('Default List Length');
    const [callsListLengthHelperText, setCallsListLengthHelperText] = useState<string>('');

    // onChange Handlers
    const onAutocompleteChange = (event: React.ChangeEvent<{}>, value: IOnboarderStatus) => {
        if (value?.Id) {
            dispatch(setSelectedOnboarder(value));
        } else {
            dispatch(setSelectedOnboarder(new OnboarderStatus()));
        }
    };

    const onCallsGoalChange = (event: any) => setCallsGoalValue(event.target.value);
    const onCallsListLengthChange = (event: any) => setCallsListLengthValue(event.target.value);

    const doSaveButtonClick = useCallback(async () => {
        setSaving(true);

        let newSettings;
        if (selectedOnboarder.Id) {
            newSettings = new OnboarderSettings();
            newSettings.OnboarderId = selectedOnboarder.Id;
            newSettings.DefaultCallsGoal = callsGoalValue;
            newSettings.DefaultListLength = callsListLengthValue;
        } else {
            newSettings = Object.assign({}, defaultOnboarderSettings);
            newSettings.DefaultCallsGoal = callsGoalValue;
            newSettings.DefaultListLength = callsListLengthValue;
            setDefaultOnboarderSettings(newSettings);
        }
        const response = await saveOnboarderSettings(newSettings);
        if (response.status === 200 && selectedOnboarder.Id) {
            let newOnboarders = [...onboarderStatuses];
            let newOnboarder = Object.assign({}, selectedOnboarder);
            const index = newOnboarders.map((status) => status.Id).indexOf(newOnboarder.Id);
            newOnboarder.CallsGoal = newSettings.DefaultCallsGoal ?? defaultOnboarderSettings.DefaultCallsGoal;
            newOnboarder.CallListLength = newSettings.DefaultListLength ?? undefined;
            newOnboarders[index] = newOnboarder;
            dispatch(setSelectedOnboarder(newOnboarder));
            setOnboarderStatuses(newOnboarders);
        }
        setSaving(false);
    }, [callsGoalValue, callsListLengthValue, defaultOnboarderSettings, dispatch, onboarderStatuses, selectedOnboarder]);

    const fetchSettings = useCallback(async () => {
        const response = await fetchOnboarderSettings();
        if (response.status === 200) {
            setDefaultOnboarderSettings(response.data);
        }
    }, []);

    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);

    useEffect(() => {
        if (saving || error) {
            setSaveButtonDisabled(true);
        } else {
            setSaveButtonDisabled(false);
        }
    }, [saving, error]);

    useEffect(() => {
        dispatch(setSelectedOnboarder(new OnboarderStatus()));
    }, [props.viewing, dispatch]);

    useEffect(() => {
        fetchOnboarderStatuses().then((response) => {
            if (response.status === 200) {
                const onboardersList = response.data.sort((a, b) => a.Username.localeCompare(b.Username));
                setOnboarderStatuses(onboardersList);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedOnboarder.Id) {
            setCallsGoalLabel(`${selectedOnboarder.Username}'s Calls Goal`);
            setCallsListLengthLabel(`${selectedOnboarder.Username}'s List Length`);
            setCallsGoalHelperText(selectedOnboarder.CallsGoal ? '' : 'Using Default');
            setCallsListLengthHelperText(selectedOnboarder.CallListLength ? '' : 'Using Default');
            setCallsGoalValue(selectedOnboarder.CallsGoal ?? defaultOnboarderSettings.DefaultCallsGoal);
            setCallsListLengthValue(selectedOnboarder.CallListLength ?? defaultOnboarderSettings.DefaultListLength);
            setSaveButtonLabel(`Save ${selectedOnboarder.Username}'s Goals`);
        } else {
            setCallsGoalLabel('Default Calls Goal');
            setCallsListLengthLabel('Default List Length');
            setCallsGoalHelperText('');
            setCallsListLengthHelperText('');
            setCallsGoalValue(defaultOnboarderSettings.DefaultCallsGoal);
            setCallsListLengthValue(defaultOnboarderSettings.DefaultListLength);
            setSaveButtonLabel(`Save Default Goals`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOnboarder]);

    return (
        <>
            <OnboarderStatusControlBar
                autoCompleteOnChange={onAutocompleteChange}
                autocompleteOptions={onboarderStatuses}
                autocompleteInputValue={selectedOnboarder.Username}
                autocompleteHelperText={''}
                saveButtonLabel={saveButtonLabel}
                callsGoalFieldLabel={callsGoalLabel}
                callsGoalFieldValue={callsGoalValue}
                callsGoalFieldHelperText={callsGoalHelperText}
                callsGoalFieldOnChange={onCallsGoalChange}
                callsListFieldValue={callsListLengthValue}
                callsListFieldLabel={callsListLengthLabel}
                callsListFieldHelperText={callsListLengthHelperText}
                callsListFieldOnChange={onCallsListLengthChange}
                saving={saving}
                doSaveButtonClick={doSaveButtonClick}
                saveButtonDisabled={saveButtonDisabled}
            />
            <OnboarderPanel status={selectedOnboarder} currentListLength={callsListLengthValue ?? defaultOnboarderSettings.DefaultListLength!} />
        </>
    );
};

export default OnboarderStatuses;
