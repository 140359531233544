import { AdlAnswerTypeEnum } from '../../Enum/AdlAnswerTypeEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IAdlAssessment extends BaseEntity {
    MemberId: string;
    BathBody?: AdlAnswerTypeEnum;
    BathTubOrShower?: AdlAnswerTypeEnum;
    Dressing?: AdlAnswerTypeEnum;
    Toilet?: AdlAnswerTypeEnum;
    TransferringOrAmbulation?: AdlAnswerTypeEnum;
    Feeding?: AdlAnswerTypeEnum;
    Status: StatusEnum;
    Score?: number;
}

export class AdlAssessment implements IAdlAssessment {
    Id: string;
    MemberId: string;
    BathBody?: AdlAnswerTypeEnum;
    BathTubOrShower?: AdlAnswerTypeEnum;
    Dressing?: AdlAnswerTypeEnum;
    Toilet?: AdlAnswerTypeEnum;
    TransferringOrAmbulation?: AdlAnswerTypeEnum;
    Feeding?: AdlAnswerTypeEnum;
    Status: StatusEnum;
    Score?: number;

    constructor() {
        this.MemberId = '';
    }
}
