import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { setShowMessage } from '../../store/shared.slice';
import { SUCCESS } from '../../utils/constants';

export default function MessageDisplay() {
    const dispatch = useDispatch();
    const { message, messageType, showMessage } = useSelector((state: RootState) => state.shared);

    return (
        <>
            {showMessage ? (
                <Snackbar
                    style={{ minWidth: '300px', maxWidth: '600px' }}
                    open={showMessage}
                    autoHideDuration={3000}
                    onClose={() => dispatch(setShowMessage(false, '', SUCCESS))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert
                        onClose={() => dispatch(setShowMessage(false, '', SUCCESS))}
                        variant="filled"
                        severity={messageType}
                        sx={{ width: '100%', whiteSpace: 'pre-line' }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            ) : null}
        </>
    );
}
