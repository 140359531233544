import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, FormControlLabel, Button, Box, CircularProgress, Stack, Typography, Checkbox, Divider, FormLabel, TextField, Collapse } from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { copyObject } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchHraEducation, saveHraEducation } from '../../../store/hra.slice';

const HraEducationAssessment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { education, isFetching, isSaving: isSavingHra } = useSelector((state: RootState) => state.hra);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingHra || isSavingEngagementDraft;

    useEffect(() => {
        dispatch(fetchHraEducation(engagementDraft.Id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveHraEducation({ ...education, Status: StatusEnum.Completed, CanCloneMember: true, IsCompleted: true }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeReferral = (isChecked: boolean, propName: string, index: number) => {
        let data = copyObject(education);
        data.Referrals[index][propName] = isChecked;
        data.MemberId = memberId;

        dispatch(saveHraEducation(data));
    };

    const handleOnChange = (value: any, propName: string) => {
        let data = copyObject(education);
        data[propName] = value;
        data.MemberId = memberId;

        dispatch(saveHraEducation(data));
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel pt-4">
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form">
                        <div>
                            <Typography variant="h6" component="label">
                                Education
                            </Typography>
                            <hr />
                        </div>
                        <Stack direction="column">
                            {Boolean(education.Referrals?.length) ? (
                                education.Referrals.map((referral, index) => (
                                    <Stack key={`hra-referral-${index}`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={referral.IsSelected}
                                                    name="IsSelected"
                                                    onChange={(e) => handleOnChangeReferral(e.target.checked, e.target.name, index)}
                                                />
                                            }
                                            label={
                                                <span>
                                                    <strong>{referral.AssessmentSubType}</strong> - {referral.Referral}
                                                </span>
                                            }
                                        />
                                        <Collapse in={referral.IsSelected} timeout="auto" unmountOnExit>
                                            <FormControlLabel
                                                sx={{ ml: 4 }}
                                                control={
                                                    <Checkbox
                                                        checked={referral.AgreesToFollowUp}
                                                        name="AgreesToFollowUp"
                                                        onChange={(e) => handleOnChangeReferral(e.target.checked, e.target.name, index)}
                                                    />
                                                }
                                                label="Member agrees to follow-up"
                                            />
                                        </Collapse>
                                    </Stack>
                                ))
                            ) : (
                                <div className="no-data-container">No Referrals</div>
                            )}
                            <Divider sx={{ marginY: 2 }} />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={education.EducatedTheMember}
                                        name="EducatedTheMember"
                                        onChange={(e) => handleOnChange(e.target.checked, e.target.name)}
                                    />
                                }
                                label="I have educated the member on the details regarding this referral"
                            />
                            <Stack>
                                <FormLabel className="mt-15">Provider Notes to Member:</FormLabel>
                                <TextField
                                    fullWidth
                                    multiline
                                    className="hide-textinput-label"
                                    style={{ maxWidth: '750px' }}
                                    placeholder="Enter here"
                                    margin="none"
                                    variant="outlined"
                                    rows={6}
                                    defaultValue={education.Notes}
                                    name="Notes"
                                    onBlur={(e) => handleOnChange(e.target.value, e.target.name)}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {Boolean(engagementDraft.HraEducationId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Education"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Education is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Education"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Education?"
                />
            )}
        </Grid>
    );
};

export default HraEducationAssessment;
