import { Box, Button, Grid, Tooltip } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridSelectionModel, GridValueGetterParams, GridCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { setIsLoading } from '../../store/shared.slice';
import Loading from '../Shared/Loading';
import EditTeamsDialog from './EditTeamsDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { apiString as API_URL } from '../../utils/constants';
import axios from 'axios';
import ConfirmDelete from '../Shared/ConfirmDelete';
import ViewTeams from './ViewTeams';
import AddMemberDialog from './AddMemberDialog';
import { getMembers } from '../../store/adminMembers.slice';
import { fetchTeams } from '../../store/teams.slice';
import Address from '../../Models/Shared/Address.model';

const AdminTeams = (props) => {
    const { memberStatuses } = useSelector((state: RootState) => state.members);
    const { teams } = useSelector((state: RootState) => state.teams);
    const dispatch = useDispatch();

    const defaultAddress: Address = new Address();

    const getDefault = () => {
        return { Name: '', ProviderId: '', Type: null, Providers: [], TypeName: '', MemberCount: null, Id: '', Address: defaultAddress };
    };

    const [currentTeam, setCurrentTeam] = useState(getDefault());
    const [newTeam, setNewTeam] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAddMember, setOpenAddMember] = useState(false);
    const [status, setStatus] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [allMembers, setAllMembers] = useState([]);
    const [memberSearchValue, setMemberSearchValue] = useState('');
    const [teamTypes, setTeamTypes] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [validProvider, setValidProvider] = useState(true);
    const [openView, setOpenView] = useState(false);
    const [validatedMembers, setValidatedMembers] = useState([]);

    const fetchTeam = async () => {
        dispatch(setIsLoading(true));
        dispatch(fetchTeams());
        dispatch(setIsLoading(false));
    };

    const getTeamMembers = async (id) => {
        const response = await axios.get(`${API_URL}/team/getTeamMembers?teamId=${id}`);
        setTeamMembers(response.data);
        setRowData(response.data);
    };

    const getAllActiveStatusMembers = async () => {
        setRowData([]);
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/member/getallactivestatusmembers`);
        setRowData(response.data.filter(({ Id: id1 }) => !teamMembers.some(({ Id: id2 }) => id2 === id1)));
        setAllMembers(response.data);
        dispatch(setIsLoading(false));
        setOpenAddMember(true);
    };

    const getTeamTypes = async () => {
        const response = await axios.get(`${API_URL}/team/getteamtypes`);
        setTeamTypes(response.data);
    };

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const onSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const openEditHandler = (params) => {
        setCurrentTeam(params.row);
        setOpenEdit(true);
    };

    const closeEditHandler = () => {
        setOpenEdit(false);
        setCurrentTeam(getDefault());
        setValidProvider(true);
        setNewTeam(false);
    };

    const addTeamHandler = () => {
        setNewTeam(true);
        setCurrentTeam(getDefault());
        setOpenEdit(true);
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setCurrentTeam({ ...currentTeam, [name]: value });
    };

    const requestQueueAssignment = async (obj) => {
        const payload = {
            RequestQueueAssignment: {
                Id: obj.ProviderId,
                Name: `${obj.Providers[0].LastName}, ${obj.Providers[0].FirstName}`,
                Role: obj.Providers[0].Role.RoleName,
            },
        };
        await axios.put(`${API_URL}/team/updatesetting?teamId=${obj.Id}`, { payload });
    };

    const addNewTeam = async (data) => {
        if (teams.filter((el) => el.Name === data.Name && data.Id !== el.Id).length > 0) {
            setValidProvider(false);
        } else {
            setValidProvider(true);
            dispatch(setIsLoading(true));
            if (newTeam) {
                console.log('NEW: ', data);
                await axios.post(`${API_URL}/team`, { Name: data.Name, ProviderId: data.ProviderId, Type: data.Type, Address: data.Address });
            } else {
                await axios.put(`${API_URL}/team`, { ...data });
                requestQueueAssignment(data);
            }
            fetchTeam();
            closeEditHandler();
            dispatch(setIsLoading(false));
        }
    };

    const deleteTeam = async (Id) => {
        dispatch(setIsLoading(true));
        await axios.delete(`${API_URL}/team/delete?teamId=${Id}`);
        fetchTeam();
        setOpenDelete(false);
        dispatch(setIsLoading(false));
    };

    const deleteTeamHandler = (params) => {
        setOpenDelete(true);
        setCurrentTeam(params.row);
    };

    const viewTeamHandler = (params) => {
        setRowData([]);
        setOpenView(true);
        setCurrentTeam(params.row);
        getTeamMembers(params.row.Id);
    };

    const statusOnChangeHandler = (e) => {
        setStatus(e.target.value);
    };

    const assignMemberToTeam = async (id, payload) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.put(`${API_URL}/team/assignMemberToTeam?teamId=${id}`, payload);
        fetchTeam();
        getTeamMembers(id);
    };

    const handleValidated = (id, payload) => {
        setValidatedMembers([]);
        assignMemberToTeam(id, payload);
        closeAddMemberHandler();
    };

    const validateMember = async (id, payload) => {
        dispatch(setIsLoading(true));
        const response = await axios.put(`${API_URL}/team/validatemember?teamId=${id}`, payload);
        if (response.data.length > 0) {
            setValidatedMembers(response.data);
            setRowData(response.data);
        } else {
            handleValidated(id, payload);
        }
        dispatch(setIsLoading(false));
    };

    const removeMemberFromTeam = async (memberId, teamId) => {
        dispatch(setIsLoading(true));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.delete(`${API_URL}/team/removeMemberFromTeam?memberId=${memberId}&teamId=${teamId}`);
        getTeamMembers(teamId);
        fetchTeam();
        dispatch(setIsLoading(false));
    };

    const formatToTextCase = (word) => {
        const result = word.replace(/([A-Z])/g, ' $1');
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    };

    const addMemberHandler = () => {
        getAllActiveStatusMembers();
        setMemberSearchValue('');
    };

    const closeAddMemberHandler = () => {
        setOpenAddMember(false);
        setMemberSearchValue('');
        setValidatedMembers([]);
        setRowData(teamMembers);
    };

    const closeViewTeamsHandler = () => {
        setOpenView(false);
        setMemberSearchValue('');
    };

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.Name.toUpperCase()}`,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsActive ? '' : 'grey'),
        },
        {
            field: 'TypeName',
            headerName: 'Type',
            width: 200,
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsActive ? '' : 'grey'),
        },
        {
            field: 'MemberCount',
            headerName: 'Member Count',
            flex: 1,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsActive ? '' : 'grey'),
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 200,
            cellClassName: (params: GridCellParams<number>) => (params.row.IsActive ? '' : 'grey'),
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit Team" placement="left">
                            <EditIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => openEditHandler(params)} />
                        </Tooltip>
                        <Tooltip title="Add Member" placement="bottom">
                            <PersonAddIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => viewTeamHandler(params)} />
                        </Tooltip>
                        <Tooltip title="Delete" placement="right">
                            <CancelIcon
                                color="error"
                                style={{ marginLeft: '24px', cursor: 'pointer' }}
                                onClick={() => {
                                    deleteTeamHandler(params);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const [pageSize, setPageSize] = useState(15);
    const pageSizeHandler = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 54 : 58) + ''));
    };

    const memberSearch = {
        rowData: rowData,
        allMembers: allMembers,
        setRowData: setRowData,
        memberSearchValue: memberSearchValue,
        setMemberSearchValue: setMemberSearchValue,
    };

    useEffect(() => {
        getTeamTypes();
        handleWindowResize();
        fetchTeam();
        window.addEventListener('resize', handleWindowResize, false);
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {<Loading message={'Loading...'} />}

            <EditTeamsDialog
                open={openEdit}
                teamTypes={teamTypes}
                newTeam={newTeam}
                currentTeam={currentTeam}
                setCurrentTeam={setCurrentTeam}
                onChangeHandler={onChangeHandler}
                onClose={closeEditHandler}
                formatToTextCase={formatToTextCase}
                submit={() => addNewTeam(currentTeam)}
                validProvider={validProvider}
            />

            <ConfirmDelete
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                name={currentTeam.Name.toUpperCase()}
                delete={() => deleteTeam(currentTeam.Id)}
                cancel={() => setOpenDelete(false)}
            />

            <ViewTeams
                open={openView}
                onClose={closeViewTeamsHandler}
                memberSearch={memberSearch}
                currentTeam={currentTeam}
                teamMembers={teamMembers}
                removeMemberFromTeam={removeMemberFromTeam}
                addMember={addMemberHandler}
                allMembers={allMembers}
                rowData={rowData}
            />

            <AddMemberDialog
                open={openAddMember}
                onClose={closeAddMemberHandler}
                pageSize={pageSize}
                memberStatuses={memberStatuses}
                status={status}
                memberSearch={memberSearch}
                setStatus={setStatus}
                pageSizeHandler={pageSizeHandler}
                selectionModel={selectionModel}
                onSelectionModelChange={onSelectionModelChange}
                allMembers={allMembers}
                rowData={rowData}
                currentTeam={currentTeam}
                validateMember={validateMember}
                validatedMembers={validatedMembers}
                handleValidated={handleValidated}
                statusOnChangeHandler={statusOnChangeHandler}
                getMembers={getMembers}
                rowDataMembers={rowData}
            />

            <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                <Grid item xs={3} textAlign={'right'}>
                    <Button variant="contained" className="button-120" onClick={addTeamHandler}>
                        Add Team
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            rows={teams}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={pageSizeHandler}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                [`& .grey`]: {
                                    color: 'lightgray',
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminTeams;
