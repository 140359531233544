import { MbscCalendarEventData } from '@mobiscroll/react';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Card, CardContent, CardHeader, Stack, ThemeProvider, Typography, createTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { setShowMessage } from '../../../store/shared.slice';
import { toTitleCase } from '../../../utils/common';
import { appointmentTypeEnums, meansOfEngagementEnums } from '../../../utils/constants';
import { appointmentStatusMap } from '../../../utils/mappings';
import { getContrastYIQ } from '../Services/CommonCalendarServices';
import { CalendarEventData } from '../types/CalendarEventData';

type AssessmentsAgendaCardProps = {
    event: Partial<MbscCalendarEventData>;
    onSelectAppointmentDate: (params: any) => void;
    confirmAndSelectRecurrenceInstance: (recurrenceSeries: CalendarEventData) => void;
};

const AssessmentsAgendaCard: React.FC<AssessmentsAgendaCardProps> = (props) => {
    const { id, color, startDate, endDate, original } = props.event;
    const { MemberName, appointmentType, meansOfEngagement, reason, recurring } = original;
    const dispatch = useDispatch();
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const { AssociatedAppointmentId } = engagementDraft;
    console.log({ AssociatedAppointmentId });
    const cardTitle = useMemo(() => {
        return `${toTitleCase(MemberName)} - ${appointmentTypeEnums.find((x) => x.value === appointmentType)?.text ?? ''} - ${
            meansOfEngagementEnums.find((x) => x.value === meansOfEngagement)?.text ?? ''
        }`;
    }, [MemberName, appointmentType, meansOfEngagement]);
    const backgroundColor = useMemo(() => {
        if (Boolean(AssociatedAppointmentId) && AssociatedAppointmentId === id) return appointmentStatusMap.Completed.eventColor;
        return color;
    }, [AssociatedAppointmentId, color, id]);

    const theme = createTheme({
        components: {
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        paddingTop: 0,
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ height: '50%', marginTop: 0, marginBottom: 0 }} style={{ backgroundColor: backgroundColor, color: getContrastYIQ(backgroundColor) }}>
                <CardHeader
                    sx={{ height: '50%', backgroundColor: backgroundColor, color: getContrastYIQ(backgroundColor) }}
                    title={cardTitle}
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    action={
                        <Button
                            startIcon={<CheckIcon />}
                            variant="contained"
                            size={'small'}
                            disabled={AssociatedAppointmentId === id}
                            onClick={async () => {
                                const checkStartDate = new Date(startDate);
                                if (checkStartDate.getTime() > new Date().getTime()) {
                                    dispatch(setShowMessage(true, 'Cannot set future appointment as engagement.', 'error'));
                                } else {
                                    if ((recurring as string)?.length > 0) {
                                        props.confirmAndSelectRecurrenceInstance(props.event as CalendarEventData);
                                    } else {
                                        props.onSelectAppointmentDate({ date: startDate, appointmentId: id });
                                    }
                                }
                            }}
                        >
                            Set As Engagement
                        </Button>
                    }
                />
                <CardContent>
                    <Stack spacing={0.5}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ fontWeight: 'bold', backgroundColor: backgroundColor, color: getContrastYIQ(backgroundColor) }}
                        >{`${new Date(startDate).toLocaleDateString('en-US')} (${new Date(startDate).toLocaleTimeString('en-US', {
                            timeStyle: 'short',
                        })} - ${new Date(endDate).toLocaleTimeString('en-US', { timeStyle: 'short' })})`}</Typography>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ fontStyle: 'italic', backgroundColor: backgroundColor, color: getContrastYIQ(backgroundColor) }}
                        >
                            {reason}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default React.memo(AssessmentsAgendaCard);
