import { Avatar, FormGroup, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import { isDefaultDate } from '../../../utils/common';

const SelectedMember = (props) => {
    const { selectedMember } = props;
    return (
        <>
            <Grid item xs={7}>
                <Typography variant="body1" marginBottom={2} fontWeight={'bold'}>
                    Selected Member
                </Typography>
                {selectedMember ? (
                    <Paper elevation={3} style={{ padding: '8px', textAlign: 'left' }}>
                        <Stack direction="row" spacing={2}>
                            <Stack spacing={2}>
                                <Avatar
                                    alt={selectedMember?.FirstName + ' ' + selectedMember?.LastName}
                                    src={selectedMember?.Photo}
                                    sx={{ width: 136, height: 136 }}
                                    className="profile-image"
                                />
                            </Stack>
                            <Stack style={{ width: '100%' }} spacing={2}>
                                <FormGroup>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="First Name"
                                            variant="outlined"
                                            value={selectedMember?.FirstName || ''}
                                            InputProps={{ readOnly: true }}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Middle Name"
                                            variant="outlined"
                                            value={selectedMember?.MiddleName || ''}
                                            InputProps={{ readOnly: true }}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Last Name"
                                            variant="outlined"
                                            value={selectedMember?.LastName || ''}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Stack>
                                </FormGroup>
                                <FormGroup>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="DOB"
                                            variant="outlined"
                                            value={!selectedMember?.DOB || isDefaultDate(selectedMember?.DOB)? '' :  new Date(selectedMember?.DOB).toLocaleDateString()}
                                            InputProps={{ readOnly: true }}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Member Id"
                                            variant="outlined"
                                            value={selectedMember?.MemberId || ''}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Stack>
                                </FormGroup>
                                <FormGroup>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="AHCCCS ID"
                                            value={selectedMember?.AhcccsId || ''}
                                            InputProps={{ readOnly: true }}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Status"
                                            variant="outlined"
                                            value={selectedMember?.Status || ''}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Stack>
                                </FormGroup>
                            </Stack>
                        </Stack>
                    </Paper>
                ) : (
                    <Stack p={2}>
                        <Typography variant="body2">No CHP Member Selected for Matching.</Typography>
                    </Stack>
                )}
            </Grid>
        </>
    );
};

export default SelectedMember;
