import { Box, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../../../../../utils/timeFormat';
import { RootState } from '../../../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelections, getTopThreeMatches, setRowSelectionModel, setUnmatchedId } from '../../../../../../store/hie.slice';
import moment from 'moment';
import { isDefaultDate } from '../../../../../../utils/common';

const ADTAlerts = () => {
    const dispatch = useDispatch();

    const { unmatchedId, showArchivedAlerts, unmatchedAlerts } = useSelector((state: RootState) => state.hie);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'CHPDownloadDate',
            headerName: 'Download Date',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CHPDownloadDate) ? '-' : dateFormatter(new Date(params.row.CHPDownloadDate))}`,
        },
        {
            field: 'SendingFacility',
            headerName: 'Sending Facility',
            minWidth: 270,
            flex: 1.5,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'VisitStatus',
            headerName: 'Visit Status',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'AdmitDate',
            headerName: 'Admission Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? '-' : moment.utc(params.row.AdmitDate).format('L')}`,
        },
        {
            field: 'AdmitTime',
            headerName: 'Admission Time',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${new Date(params.row.AdmitTime).toLocaleTimeString()}`,
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeDate) ? '-' : moment.utc(params.row.DischargeDate).format('L')}`,
        },
        {
            field: 'DischargeTime',
            headerName: 'Discharge Time',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${new Date(params.row.DischargeTime).toLocaleTimeString()}`,
        },
        {
            field: 'AdmitReason',
            headerName: 'Admission Reason',
            minWidth: 260,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box>
                        <Typography>{params.row.AdmitReason}</Typography>
                    </Box>
                );
            },
        },
    ];

    return (
        <>
            <DataGrid
                onRowClick={(params: any) => {
                    const { DOB, Id, Patient } = params.row;
                    const names = Patient.split(' ');
                    dispatch(clearSelections());
                    dispatch(setUnmatchedId(Id));
                    dispatch(getTopThreeMatches(names[0], names[names.length - 1], DOB));
                }}
                autoHeight
                rows={
                    showArchivedAlerts
                        ? unmatchedAlerts.filter((alert) => alert.AlertType === 'ADT')
                        : unmatchedAlerts.filter((alert) => alert.AlertType === 'ADT').filter((alert) => alert.Archived !== true)
                }
                columns={columns}
                checkboxSelection={unmatchedId.length > 0 ? true : false}
                onSelectionModelChange={(ids) => {
                    dispatch(setRowSelectionModel(ids));
                }}
                pageSize={10}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.Id}
                getRowHeight={() => 'auto'}
                getRowClassName={(params) => (params.row.Archived ? 'archived' : '')}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                    [`& .archived`]: {
                        background: 'rgba(200,200,200,0.4)',
                        fontStyle: 'italic',
                    },
                }}
            />
        </>
    );
};

export default ADTAlerts;
