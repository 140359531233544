import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const CallBtn = styled(Button)({
    backgroundColor: '#337ab7',

    '&:hover': {
        background: '#005580',
    },
    color: 'white',
});
export const ConfirmBtn = styled(Button)({
    backgroundColor: '#5cb85c',
    '&:hover': {
        background: '#357A38',
    },
    color: 'white',
});
export const RescheduleBtn = styled(Button)({
    backgroundColor: '#b495c7',
    '&:hover': {
        background: '#9077A0',
    },
    color: 'white',
});

export const CancelBtn = styled(Button)({
    backgroundColor: '#DC3545',
    '&:hover': {
        background: '#b42b39',
    },
    color: 'white',
});
export const NotReachedBtn = styled(Button)({
    backgroundColor: '#f0ad4e',
    '&:hover': {
        background: '#c58b3a',
    },
    color: 'white',
});
export const SkipBtn = styled(Button)({
    backgroundColor: '#DC3545',
    '&:hover': {
        background: '#b42b39',
    },
    color: 'white',
});
export const CopyTextMsgBtn = styled(Button)({
    backgroundColor: '#5bc0de',
    '&:hover': {
        background: '#4695ad',
    },
    color: 'white',
});
