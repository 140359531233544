import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import 'simplebar/dist/simplebar.min.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import b64toBlob from 'b64-to-blob';
import { useNavigate } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { exportMembersWithoutEncounter, fetchMembersWithoutEncounter, MenuProps } from '../WidgetService';
import { fetchPopulations } from '../../Population/PopulationService';
import MemberPreview from '../../../Models/Member/MemberPreview.model';
import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import { useWidgetContext } from '../../../hooks/widgetContext';
import '../Widgets.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const MembersWithoutEncounterDetail = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { memberWithoutEncounterFilter, setMemberWithoutEncounterFilter } = useWidgetContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [members, setMembers] = useState<MemberPreview[]>([]);
    const { healthPlans } = useHealthPlanContext();
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const filteredTeams = (memberWithoutEncounterFilter.showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const dayFilterList = [
        { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
        { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
        { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
    }, [isFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        const { fromDate, toDate, dateFilter } = memberWithoutEncounterFilter;
        if (dateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate))) {
            setMembers([]);
            setIsFetched(false);
            const changeFilter = async () => {
                setIsFetched(false);
                const membersInfo = await fetchMembersWithoutEncounter(
                    memberWithoutEncounterFilter.team,
                    memberWithoutEncounterFilter.healthPlan,
                    memberWithoutEncounterFilter.population,
                    memberWithoutEncounterFilter.dateFilter,
                    Boolean(fromDate) && dateFilter === WidgetTimeFilterEnum.Custom ? new Date(fromDate).toISOString() : '',
                    Boolean(toDate) && dateFilter === WidgetTimeFilterEnum.Custom ? new Date(toDate).toISOString() : '',
                    memberWithoutEncounterFilter.showInActiveTeams
                );
                setMembers(membersInfo);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (dateFilter !== WidgetTimeFilterEnum.Custom && Boolean(fromDate) && Boolean(toDate)) {
            setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, fromDate: null, toDate: null });
        }
    }, [memberWithoutEncounterFilter, setMemberWithoutEncounterFilter]);

    const healthPlanChange = async (healthPlanId: string) => {
        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, healthPlan: healthPlanId, population: 0, populations: [] });
        if (healthPlanId && healthPlanId !== 'All') {
            const populations = await fetchPopulations(healthPlanId);
            setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, healthPlan: healthPlanId, population: 0, populations });
        }
    };

    const changeFromDate = (date: any) => {
        const fromDate = date;
        let toDate = memberWithoutEncounterFilter.toDate;
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            toDate = endDate;
        }

        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, fromDate, toDate });
    };

    const changeToDate = (date: any) => {
        const toDate = date;
        let fromDate = memberWithoutEncounterFilter.fromDate;
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            fromDate = startDate;
        }

        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, fromDate, toDate });
    };

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        let filter = memberWithoutEncounterFilter;
        filter.showInActiveTeams = isChecked;
        filter.team = 'All';
        setMemberWithoutEncounterFilter({ ...filter });
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const { team, healthPlan, population, dateFilter, fromDate, toDate } = memberWithoutEncounterFilter;
        let start = fromDate !== null ? new Date(fromDate).toDateString() : null;
        let end = toDate !== null ? new Date(toDate).toDateString() : null;
        const data = await exportMembersWithoutEncounter(team, healthPlan, population, dateFilter, start, end, memberWithoutEncounterFilter.showInActiveTeams);
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `Member Without Encounter - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1} paddingBottom={2}>
                        <Grid item xs={12} paddingBottom={1}>
                            <span onClick={backToWidget}>
                                <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                            </span>
                            <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                Members Without An Encounter
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item sm={1.5} xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.team}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, team: e.target.value })}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox
                                                checked={memberWithoutEncounterFilter.showInActiveTeams}
                                                onChange={(e) => toggleTeamHandler(e.target.checked)}
                                            />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={1.5} xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Health Plan</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.healthPlan}
                                    label="Health Plan"
                                    name="Health Plan"
                                    onChange={(e) => healthPlanChange(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {filteredHealthPlans.map((plan) => (
                                        <MenuItem key={plan.Id} value={plan.Id}>
                                            {plan.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={1.5} xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Population</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.population}
                                    label="Population"
                                    name="Population"
                                    onChange={(e) =>
                                        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, population: parseInt(e.target.value as any) })
                                    }
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="0">
                                        All
                                    </MenuItem>
                                    {memberWithoutEncounterFilter.populations.map((pop) => (
                                        <MenuItem key={pop.PopulationNumber} value={pop.PopulationNumber}>
                                            {pop.PopulationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={1.5} xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.dateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) =>
                                        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, dateFilter: parseInt(e.target.value as any) })
                                    }
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {memberWithoutEncounterFilter.dateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item sm={1.5} xs={6}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(memberWithoutEncounterFilter.fromDate) ? memberWithoutEncounterFilter.fromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item sm={1.5} xs={6}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(memberWithoutEncounterFilter.toDate) ? memberWithoutEncounterFilter.toDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs />
                        <Grid item xs={1}>
                            <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                                {downLoading ? 'Exporting...' : 'Export'}
                            </Button>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                        <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                            {!isFetched ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="customized table" stickyHeader>
                                                <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell>Name</StyledTableCell>
                                                        <StyledTableCell align="left">Health Plan</StyledTableCell>
                                                        <StyledTableCell align="right">Last CHP Visit</StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {members && members.length
                                                        ? members.map((mem) => (
                                                              <StyledTableRow key={mem.Id}>
                                                                  <StyledTableCell component="th" scope="row">
                                                                      {mem.FirstName} {mem.LastName}
                                                                  </StyledTableCell>
                                                                  <StyledTableCell size="small">{mem.HealthPlan}</StyledTableCell>
                                                                  <StyledTableCell align="right" size="small">
                                                                      {mem.LastVisit !== null ? moment(new Date(mem.LastVisit)).format('MM/DD/yyyy') : ''}
                                                                  </StyledTableCell>
                                                              </StyledTableRow>
                                                          ))
                                                        : null}
                                                    {isFetched && members && members.length === 0 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={3}>No Member Found</TableCell>
                                                        </TableRow>
                                                    ) : null}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default MembersWithoutEncounterDetail;
