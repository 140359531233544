import { FormControl, InputLabel, Grid, MenuItem, Paper, Select, Typography, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { parentUrl } from '../../../../utils/constants';
import { apiString as API_URL } from '../../../../utils/constants';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';

const MembersWithNoPrimaryDiagnosis = (props) => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [membersWithNoPrimaryDiagnosis, setMembersWithNoPrimaryDiagnosis] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        const changeFilter = async () => {
            setIsFetched(false);
            const response = await axios.get(`${API_URL}/member/getmemberswithnoprimarydiagnosis?teamId=${selectedTeam}&includeInActiveTeams=${showInActiveTeams}`);
            setMembersWithNoPrimaryDiagnosis(response.data);
            setIsFetched(true);
        };
        changeFilter();
    }, [selectedTeam, showInActiveTeams]);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 140,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 140,
        },
        {
            field: 'Team',
            headerName: 'Team',
            minWidth: 140,
            valueGetter(params) {
                return params.row.Team?.Name;
            },
        },
        {
            field: 'TeamName',
            headerName: 'Providers',
            flex: 1,
        },
    ];

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 100 : 105) + ''));
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                        Members With No Primary Diagnosis
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="caption">Active members who have not been assigned a Primary Diagnosis</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Team</InputLabel>
                        <Select
                            value={selectedTeam}
                            label="Team"
                            name="Team"
                            onChange={(e) => setSelectedTeam(e.target.value)}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                        >
                            <MenuItem key="All" value="All">
                                All
                            </MenuItem>
                            <FormControlLabel
                                sx={{ pl: '12px', mb: '0px' }}
                                control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                label="Show Inactive"
                            />
                            {filteredTeams
                                .filter((team) => team.TypeName === 'Behavioral Health' || team.TypeName === 'Nurse Practitioner')
                                .map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <div style={{ width: '100%', height: 'Calc(70vh - 135px)', position: 'relative' }}>
                        {!isFetched && <WidgetLoading />}
                        <DataGrid
                            onCellClick={(params: any) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                            }}
                            rows={membersWithNoPrimaryDiagnosis}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default MembersWithNoPrimaryDiagnosis;
