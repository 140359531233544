import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, CircularProgress, Stack, Select, MenuItem, Slide, DialogContent, DialogTitle, Dialog, DialogActions, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { RootState } from '../../../reducers';
import classes from './Styles';
import { PeriodsHours, PeriodsMinutes, TimeMeridiems } from '../../../utils/assessments';
import { preparePeriodSchedules, setSelectedAssignPeriods, toggleShowMedicationPriodModal, updateMedications } from '../../../store/medication.slice';
import { Medications } from '../../../Models/Medications/Medications.model';
import { copyObject } from '../../../utils/common';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const AssignPeriodsDue: React.FC<{ medications: Medications[]; memberId: string; onChange: Function }> = ({ medications, memberId, onChange }) => {
    const dispatch = useDispatch();
    const { selectedAssignPeriods, showMedicationPriodModal, isSaving: isSavingMedication } = useSelector((state: RootState) => state.medication);

    const handleOnMedicationModal = () => dispatch(toggleShowMedicationPriodModal(false));

    const handleSubmitForm = () => {
        let selectedMedications = (copyObject(medications) as Medications[]).filter((med) => med.IsSelected);
        selectedMedications.forEach((med) => {
            med.MedicationSchedule = selectedAssignPeriods.Schedules;
        });

        dispatch(updateMedications(memberId, selectedMedications));
        if (onChange) {
            onChange();
        }
    };

    const handleOnChageTime = (val: any, propName: string, index: number) => {
        let periodsData = copyObject(selectedAssignPeriods);
        periodsData.Periods[index][propName] = val;
        periodsData.Schedules = preparePeriodSchedules(periodsData.Frequency, periodsData.Periods);

        dispatch(setSelectedAssignPeriods(periodsData));
    };

    return (
        <Dialog
            disableScrollLock
            disableRestoreFocus
            disableAutoFocus
            disableEnforceFocus
            open={showMedicationPriodModal}
            maxWidth="xl"
            sx={{ scrollPaper: classes.topScrollPaper, paperScrollBody: classes.topPaperScrollBody }}
            scroll="body"
            TransitionComponent={Transition}
            keepMounted
            onClose={handleOnMedicationModal}
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <span>Assign Periods</span>
                    <span>Frequency {selectedAssignPeriods.Frequency}</span>
                </Stack>
            </DialogTitle>
            <DialogContent dividers sx={{ width: '800px' }}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Typography variant="subtitle1" component="label" marginTop={1} marginBottom={1}>
                            <strong>Medications</strong>
                        </Typography>
                        <Stack>
                            {selectedAssignPeriods.MedicationNames.map((name, index) => (
                                <Typography key={`name_${name}_${index}`}>{name}</Typography>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant="subtitle1" component="label" marginTop={1} marginBottom={1}>
                            <strong>{selectedAssignPeriods.IsHrs ? 'Select Start Time' : `Select ${selectedAssignPeriods.TimesGiven} Periods`}</strong>
                        </Typography>

                        {selectedAssignPeriods.Periods?.map((priod, index) => (
                            <Stack direction="row" spacing={2} key={`period_row_${index}`}>
                                <Select
                                    value={priod.Hour}
                                    sx={{ mb: 3 }}
                                    className="hide-textinput-label"
                                    name="Hour"
                                    onChange={(e) => handleOnChageTime(e.target.value, e.target.name, index)}
                                >
                                    {PeriodsHours.map((name) => (
                                        <MenuItem key={`${index}-${name}`} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    value={priod.Minute}
                                    sx={{ mb: 3 }}
                                    className="hide-textinput-label"
                                    name="Minute"
                                    onChange={(e) => handleOnChageTime(e.target.value, e.target.name, index)}
                                >
                                    {PeriodsMinutes.map((name) => (
                                        <MenuItem key={`${index}-${name}`} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    value={priod.Meridiem}
                                    sx={{ mb: 3 }}
                                    className="hide-textinput-label"
                                    name="Meridiem"
                                    onChange={(e) => handleOnChageTime(e.target.value, e.target.name, index)}
                                >
                                    {TimeMeridiems.map((name) => (
                                        <MenuItem key={`${index}-${name}`} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        ))}
                    </Grid>
                    <Grid item md={3} textAlign="center">
                        <Typography variant="subtitle1" component="label" marginTop={1} marginBottom={1}>
                            <strong>Medication Schedule</strong>
                        </Typography>
                        <Stack>
                            {selectedAssignPeriods.Schedules?.map((schedule, index) => (
                                <Typography key={`period_time_${index}`} variant="body1" component="label">
                                    {schedule}
                                </Typography>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    onClick={() => handleSubmitForm()}
                    disabled={isSavingMedication}
                    endIcon={isSavingMedication ? <CircularProgress size={18} color="inherit" /> : null}
                >
                    Save
                </Button>
                <Button size="small" type="button" onClick={handleOnMedicationModal} variant="outlined" disabled={isSavingMedication}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignPeriodsDue;
