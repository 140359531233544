import {
    Box,
    Stack,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Paper,
    Avatar,
    TextField,
    Typography,
    Button,
    CircularProgress,
    Modal,
    Divider,
    FormControlLabel,
    RadioGroup,
    Radio,
    Tooltip,
    Select,
    MenuItem,
    Link,
    ToggleButtonGroup,
    ToggleButton,
    FormLabel,
    FormHelperText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { RootState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Search } from '@mui/icons-material';
import { parentUrl, modalStyle } from '../../utils/constants';
import Loading from '../Shared/Loading';
import moment from 'moment';
import ArticleIcon from '@mui/icons-material/Article';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { localDateFormat } from '../../utils/timeFormat';
import { isDefaultDate } from '../../utils/common';
import { fetchMembersByKeyword, fetchMemberStatuses, setMembers } from '../../store/members.slice';
import { getMemberById } from '../../store/memberDetail.slice';
import { hedisMetricsList, completedList, submitHedisMetricVisit, setShowHedisUpdateModal } from '../../store/memberDemographicsDetails.slice';
import { getMetaTagsByCategoryId } from '../../store/memberDocuments.slice';
import { ClaimSourceEnum } from '../../Enum/ClaimSourceEnum';
import { HedisMetricsVisit } from '../../Models/HedisMetrics/HedisMetricsVisit.model';
import StarIcon from '@mui/icons-material/Star';
import phoneFormat from '../../utils/phoneFormat';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { HedisMetricStatusEnum } from '../../Enum/HedisMetricStatusEnum';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

const MemberHedisMetrics = (props) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { members, memberStatuses, isFetchingMember } = useSelector((state: RootState) => state.members);
    const { showHedisUpdateModal } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const { memberHealthPlans } = useSelector((state: RootState) => state.memberDetail);
    const { metaTagsByCategoryId } = useSelector((state: RootState) => state.memberDocuments);
    const [selectedMember, setSelectedMember] = useState(null);
    const [hedisDetail, setHedisDetail] = useState<HedisMetricsVisit>(null);
    const [memberSearchValue, setMemberSearchValue] = useState('');
    const [hedisType, setHedisType] = useState<string>('');
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [showHedisDetail, setShowHedisDetail] = useState(false);

    const claimsSourceList = [
        {
            Id: ClaimSourceEnum.ChpClaims,
            Name: ClaimSourceEnum.ChpClaimsDescription,
        },
        {
            Id: ClaimSourceEnum.External,
            Name: ClaimSourceEnum.ExternalDescription,
        },
        {
            Id: ClaimSourceEnum.HealthPlan,
            Name: ClaimSourceEnum.HealthPlanDescription,
        },
    ];

    const executeSearch = (event: any) => {
        if (Boolean(event.target.value) && (!event.key || event.key === 'Enter')) {
            dispatch(fetchMembersByKeyword(event.target.value));
            setMemberSearchValue(event.target.value);
            setSelectedMember(null);
        } else if (!Boolean(event.target.value)) {
            dispatch(setMembers([]));
            setSelectedMember(null);
        }
    };

    useEffect(() => {
        dispatch(getMetaTagsByCategoryId('58095a82787ba42fcc404505'));
        dispatch(fetchMemberStatuses());
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const membersColumns: GridColDef[] = [
        {
            field: 'AhcccsId',
            headerName: 'AHCCCS ID',
            flex: 1,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
            sortable: false,
        },
    ];

    const prepareSelectedMember = (memberId: string) => {
        setIsFetching(true);
        dispatch(
            getMemberById(memberId, (data) => {
                setSelectedMember(data);
                setIsFetching(false);
            })
        );
    };

    const fontColor = (metric) => {
        if (metric?.IsExpired) {
            return 'error.main';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.Completed || metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled) {
            return 'success.main';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
            return 'warning.main';
        } else {
            return 'rgba(0,0,0, 0.4)';
        }
    };

    const iconColor = (metric) => {
        if (metric?.IsExpired) {
            return 'error';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.Completed || metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled) {
            return 'success';
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
            return 'warning';
        } else {
            return 'disabled';
        }
    };

    const prepareHedis = (metric: HedisMetricsVisit, hedisType: string) => {
        setHedisDetail(metric);
        setHedisType(hedisType);
        setShowHedisDetail(true);
    };

    const prepareHedisStatus = (metric) => {
        if (metric?.IsExpired) {
            return `Expired`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.Completed) {
            return `Completed`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndControlled) {
            return `Completed And Controlled`;
        } else if (metric?.MetricStatus === HedisMetricStatusEnum.CompletedAndNotControlled) {
            return `Completed And Not Controlled`;
        }
    };

    const handleSubmit = (values: HedisMetricsVisit) => {
        dispatch(
            submitHedisMetricVisit(selectedMember.Id, values, values.File, (data) => {
                setSelectedMember(data);
            })
        );
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const onCheckHandler = (hedisType: string, type: number) => {
        let labReport = metaTagsByCategoryId.find((m) => m.Name === 'Lab Reports').Id;
        let isCompletedList = completedList.map((item) => item.Id).includes(type);
        let metricData = {
            HedisMetricType: type,
            IsMetAtThisVisit: isCompletedList ? false : true,
            DateLastMet: new Date(),
            IsCompletedAtThisVisit: isCompletedList ? true : false,
            DateLastCompleted: new Date(),
            DocumentMetaTag: labReport,
            ClaimSource: 4,
            DateUpdatedByHealthPlan: new Date(),
        } as HedisMetricsVisit;
        setHedisDetail(metricData);
        setHedisType(hedisType);
        dispatch(setShowHedisUpdateModal(true));
    };

    const showStar = (team) => {
        if (!team) return false;
        if (team?.Id === selectedMember?.Team?.Id) return true;
        return false;
    };

    const hedisFormSchema = Yup.object().shape({
        DateLastCompleted: Yup.string().when('HedisMetricType', {
            is: (val: number) => completedList.map((item) => item.Id).includes(val),
            then: Yup.string().nullable().required('Date is required.'),
        }),
        DateLastMet: Yup.string().when('HedisMetricType', {
            is: (val: number) => !completedList.map((item) => item.Id).includes(val),
            then: Yup.string().nullable().required('Date is required.'),
        }),
        DateUpdatedByHealthPlan: Yup.string().when('ClaimSource', {
            is: (val: number) => val === ClaimSourceEnum.HealthPlan,
            then: Yup.string().nullable().required('Date is required.'),
        }),
        Note: Yup.string().nullable().required('Note is required.'),
        File: Yup.mixed().when('ClaimSource', {
            is: (val: number) => val === ClaimSourceEnum.External,
            then: Yup.mixed().nullable().required('File is required.'),
        }),
    });

    const prepareHedisDetail = () => {
        var hedisDetail = [];
        hedisMetricsList.forEach((metric, index) => {
            let metricInfo = selectedMember.HedisSummary.find((h) => h.HedisMetricType === metric.type);
            let metricInfoCopy = { ...metricInfo, Label: metric.label, Type: metric.type, FontColor: fontColor(metricInfo), IconColor: iconColor(metricInfo) };
            hedisDetail.push(metricInfoCopy);
        });
        return hedisDetail;
    };

    return (
        <Box>
            <Grid container paddingBottom={0}>
                <Stack direction="row" width="100%" spacing={4} paddingX={2} paddingTop={2} sx={{ zIndex: 10, background: '#ffffff' }}>
                    <Grid item xs={3}>
                        <FormControl fullWidth style={{ marginTop: '16px', marginBottom: '16px' }}>
                            <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type="text"
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setMemberSearchValue(e.target.value as string);
                                    }
                                }}
                                onKeyUp={(event: any) => {
                                    executeSearch(event);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Search" onClick={executeSearch} edge="end">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search"
                            />
                        </FormControl>
                        <div style={{ width: '100%' }}>
                            <DataGrid
                                loading={isFetchingMember}
                                onCellClick={(params: any) => {
                                    prepareSelectedMember(params.row.Id);
                                }}
                                autoHeight
                                rows={memberSearchValue ? members : []}
                                columns={membersColumns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.Id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                    minHeight: '67vh',
                                }}
                                localeText={{ noRowsLabel: Boolean(memberSearchValue) ? 'No member found' : 'Type and enter to search the members' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        {isFetching ? (
                            <Paper className="loader-container" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '74vh', mt: 2 }}>
                                <CircularProgress size={50} />
                            </Paper>
                        ) : Boolean(selectedMember) ? (
                            <>
                                <Paper elevation={3} style={{ padding: '8px', marginTop: '16px', marginBottom: '16px', textAlign: 'left' }}>
                                    <Stack direction="row" spacing={2}>
                                        <Stack spacing={2}>
                                            <Avatar
                                                alt={selectedMember.FirstName + ' ' + selectedMember.LastName}
                                                src={selectedMember.Photo}
                                                sx={{ width: 136, height: 136 }}
                                                className="profile-image"
                                            />
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    navigateToMemberDashboard(selectedMember.Id);
                                                }}
                                            >
                                                View Chart
                                            </Button>
                                        </Stack>
                                        <Grid container spacing={1}>
                                            <Paper square elevation={3} sx={{ p: 2, display: 'flex', width: '100%' }}>
                                                <Grid item xs={3}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                                        <Typography variant="body2">First Name:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.FirstName}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">Middle Name:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.MiddleName}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">Last Name:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.LastName}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">DOB:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.DOB ? moment(selectedMember?.DOB).format('MM/DD/yyyy') : '-'}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">Gender:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember.Gender === 1
                                                                ? 'Male'
                                                                : selectedMember.Gender === 2
                                                                ? 'Female'
                                                                : 'Non Declared' || ''}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                                                <Grid item xs={3}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                                        <Typography variant="body2">AHCCCS Id:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.Mem_AHCCCS_ID}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">Member Id:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.MemberId}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">Status:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {memberStatuses.find((m) => parseInt(m.value) === selectedMember.Status).label || ''}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">Health Plan:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.PrimaryHealthPlan?.Name}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1.2} borderBottom={1}>
                                                        <Typography variant="body2">Insurance:</Typography>
                                                        <Grid container columnGap={2} justifyContent={'flex-end'}>
                                                            {memberHealthPlans.map((plan, index) => {
                                                                return (
                                                                    <Grid item display={'flex'} key={index}>
                                                                        <Typography textAlign={'right'} fontStyle={'italic'} variant="body2" marginRight={1}>
                                                                            {plan.Priority ? plan.Priority : '-'}
                                                                        </Typography>
                                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                                            {plan?.Name ? plan?.Name : '-'}
                                                                        </Typography>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Stack>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                                                <Grid item xs={3}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} borderBottom={1}>
                                                        <Typography variant="body2">CC:</Typography>
                                                        <Typography fontWeight={700} variant="body2">
                                                            {selectedMember?.CCTeam?.Name || ''}
                                                            {showStar(selectedMember?.CCTeam) && (
                                                                <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: '5px' }} />
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                                                        <Typography variant="body2">NP:</Typography>
                                                        <Typography fontWeight={700} variant="body2">
                                                            {selectedMember?.NPTeam?.Name || ''}
                                                            {showStar(selectedMember?.NPTeam) && (
                                                                <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: '5px' }} />
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                                                        <Typography variant="body2">BH:</Typography>
                                                        <Typography fontWeight={700} variant="body2">
                                                            {selectedMember?.BHTeam?.Name || ''}
                                                            {showStar(selectedMember?.BHTeam) && (
                                                                <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: '5px' }} />
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                                                        <Typography variant="body2">CL:</Typography>
                                                        <Typography fontWeight={700} variant="body2">
                                                            {selectedMember?.CLTeam?.Name || ''}
                                                            {showStar(selectedMember?.CLTeam) && (
                                                                <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: '5px' }} />
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginTop={1} borderBottom={1}>
                                                        <Typography variant="body2">CHW:</Typography>
                                                        <Typography fontWeight={700} variant="body2">
                                                            {selectedMember?.CHWTeam?.Name || ''}
                                                            {showStar(selectedMember?.CHWTeam) && (
                                                                <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: '5px' }} />
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                                                <Grid item xs={3}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} borderBottom={1}>
                                                        <Typography variant="body2">Address:</Typography>
                                                        <Stack justifyContent={'flex-end'} spacing={0}>
                                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                                {`${selectedMember?.MainAddress?.Address1 ? selectedMember?.MainAddress?.Address1 : '-'}${
                                                                    selectedMember?.MainAddress?.Address2 ? ', ' + selectedMember?.MainAddress?.Address2 : ''
                                                                } `}
                                                            </Typography>
                                                            <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                                {`${selectedMember?.MainAddress?.City ? selectedMember?.MainAddress?.City : '-'}${
                                                                    selectedMember?.MainAddress?.State ? ', ' + selectedMember?.MainAddress?.State : ''
                                                                } ${selectedMember?.MainAddress?.ZipCode ? selectedMember?.MainAddress?.ZipCode : ''}`}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                    {selectedMember.IsDifferMeetingAddress && (
                                                        <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                                            <Typography variant="body2">Meeting Address:</Typography>
                                                            <Stack justifyContent={'flex-end'} spacing={0}>
                                                                <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                                    {`${
                                                                        selectedMember?.MainMeetingAddress?.Address1
                                                                            ? selectedMember?.MainMeetingAddress?.Address1
                                                                            : '-'
                                                                    }${
                                                                        selectedMember?.MainMeetingAddress?.Address2
                                                                            ? ', ' + selectedMember?.MainMeetingAddress?.Address2
                                                                            : ''
                                                                    } `}
                                                                </Typography>
                                                                <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                                    {`${
                                                                        selectedMember?.MainMeetingAddress?.City
                                                                            ? selectedMember?.MainMeetingAddress?.City
                                                                            : '-'
                                                                    }${
                                                                        selectedMember?.MainMeetingAddress?.State
                                                                            ? ', ' + selectedMember?.MainMeetingAddress?.State
                                                                            : ''
                                                                    } ${
                                                                        selectedMember?.MainMeetingAddress?.ZipCode
                                                                            ? selectedMember?.MainMeetingAddress?.ZipCode
                                                                            : ''
                                                                    }`}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    )}
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                                        <Typography variant="body2">Phones:</Typography>
                                                        <Grid container columnGap={2} justifyContent={'flex-end'}>
                                                            {selectedMember?.Phones?.map((phone, index) => {
                                                                return (
                                                                    <Grid
                                                                        key={index}
                                                                        item
                                                                        display={'flex'}
                                                                        color={phone.IsPrimary ? 'primary.main' : 'rgba(0,0,0,0.5)'}
                                                                    >
                                                                        <Typography textAlign={'right'} variant="body2" marginRight={1}>
                                                                            {phone.Textable ? 'Text -' : 'No Text -'}
                                                                        </Typography>
                                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                                            {phone.Number ? phoneFormat(phone.Number) : '-'}
                                                                        </Typography>
                                                                        {phone.IsPrimary && (
                                                                            <Tooltip title="Primary Phone">
                                                                                <StarIcon color="primary" sx={{ fontSize: '1rem', marginLeft: 1 }} />
                                                                            </Tooltip>
                                                                        )}
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2} marginTop={1} borderBottom={1}>
                                                        <Typography variant="body2">Primary Language:</Typography>
                                                        <Typography textAlign={'right'} fontWeight={700} variant="body2">
                                                            {selectedMember?.PrimaryLanguage?.Name}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Stack>
                                </Paper>
                                <Paper elevation={3} style={{ padding: '8px', marginTop: '16px', marginBottom: '16px', textAlign: 'left' }}>
                                    <Stack direction={'row'} spacing={2} sx={{ backgroundColor: 'white' }} justifyContent={'center'}>
                                        <Typography variant="body2" fontWeight={'bold'} color={'error.main'}>
                                            Expired
                                        </Typography>
                                        <Typography variant="body2" fontWeight={'bold'} color={'success.main'}>
                                            Completed and Controlled
                                        </Typography>
                                        <Typography variant="body2" fontWeight={'bold'} color={'warning.main'}>
                                            Completed and Not Controlled
                                        </Typography>
                                    </Stack>
                                </Paper>
                                <Paper elevation={3} style={{ padding: '8px', marginTop: '16px', marginBottom: '16px', textAlign: 'left' }}>
                                    <Grid container sx={{ backgroundColor: 'white' }} justifyContent={'center'}>
                                        {prepareHedisDetail().map((metric, index) => {
                                            const fontColor = metric.FontColor;
                                            const iconColor = metric.IconColor;
                                            return (
                                                <Grid item xs={3} p={1.5} textAlign={'center'} key={index}>
                                                    <Paper
                                                        elevation={3}
                                                        sx={{ p: 1, minHeight: 60, borderLeftWidth: 4, borderLeftColor: fontColor, borderLeftStyle: 'solid' }}
                                                    >
                                                        <Typography fontWeight={700} color={fontColor} variant="body1" pb={0.6}>
                                                            {metric.Label}
                                                            <ArticleIcon
                                                                color={iconColor}
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    cursor: Boolean(metric?.DateLastMet) || Boolean(metric?.DateLastCompleted) ? 'pointer' : '',
                                                                    margin: '0 0 -2px 3px',
                                                                    paddingLeft: '5px',
                                                                }}
                                                                onClick={() =>
                                                                    (Boolean(metric?.DateLastMet) || Boolean(metric?.DateLastCompleted)) &&
                                                                    prepareHedis(metric, metric?.Label)
                                                                }
                                                            />
                                                        </Typography>
                                                        {Boolean(completedList.map((item) => item.Id).includes(metric?.HedisMetricType)) ? (
                                                            <>
                                                                <Typography color={fontColor} variant="body2">
                                                                    Last Completed :{' '}
                                                                    {metric?.DateLastCompleted
                                                                        ? moment(metric?.DateLastCompleted).format('MM/DD/yyyy')
                                                                        : metric?.DateLastMet
                                                                        ? moment(metric?.DateLastMet).format('MM/DD/yyyy')
                                                                        : '-'}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography color={fontColor} variant="body2">
                                                                    {metric?.DateLastMet && !metric?.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                                                                    {metric?.DateLastMet ? moment(metric?.DateLastMet).format('MM/DD/yyyy') : '-'}
                                                                </Typography>
                                                            </>
                                                        )}

                                                        <Button
                                                            sx={{ marginTop: '10px', textTransform: 'none', color: fontColor, borderColor: fontColor }}
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={(e) => onCheckHandler(metric.Label, metric.Type)}
                                                        >
                                                            Status at this visit
                                                        </Button>
                                                    </Paper>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Paper>
                            </>
                        ) : (
                            <>
                                <Loading message={'Loading...'} />
                                <Paper elevation={3} style={{ padding: '8px', marginTop: '16px', marginBottom: '16px', textAlign: 'left' }}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="h5" p={0.5}>
                                            Search and select a member to view the HEDIS details.
                                        </Typography>
                                    </Stack>
                                </Paper>
                            </>
                        )}
                    </Grid>
                </Stack>
            </Grid>
            <Modal
                open={showHedisUpdateModal}
                onClose={() => {
                    dispatch(setShowHedisUpdateModal(false));
                    setHedisDetail(null);
                }}
            >
                <Box sx={modalStyle} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Stack spacing={2}>
                        <Typography variant="h5">{hedisType}</Typography>
                        <Divider />
                    </Stack>
                    <Formik
                        initialValues={hedisDetail || ({} as HedisMetricsVisit)}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                        validationSchema={hedisFormSchema}
                    >
                        {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<HedisMetricsVisit>) => (
                            <Form>
                                <div className="mui-modal-body">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid container direction="column" spacing={2} className="content-panel">
                                            <Grid item className="content-form">
                                                <Grid container paddingTop={2} spacing={2}>
                                                    {Boolean(completedList.map((item) => item.Id).includes(values?.HedisMetricType)) ? (
                                                        <>
                                                            <Grid item xs={2}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        row
                                                                        name="IsCompletedAtThisVisit"
                                                                        value={Boolean(values?.IsCompletedAtThisVisit) ? 'Yes' : 'No'}
                                                                        onChange={(e) => {
                                                                            setFieldValue('IsCompletedAtThisVisit', e.target.value === 'Yes');
                                                                        }}
                                                                    >
                                                                        <FormControlLabel
                                                                            sx={{ marginRight: '5px' }}
                                                                            value="Yes"
                                                                            control={<Radio color="success" />}
                                                                            label="Completed"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={3} mr={2}>
                                                                <DatePicker
                                                                    label="Last Completed On"
                                                                    value={values?.DateLastCompleted ? new Date(values?.DateLastCompleted) : null}
                                                                    minDate={new Date('2017-01-01')}
                                                                    onChange={(date) => {
                                                                        if (moment(date).isValid()) {
                                                                            setFieldValue('DateLastCompleted', date.toISOString());
                                                                        } else {
                                                                            setFieldValue('DateLastCompleted', '');
                                                                        }
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            {...params}
                                                                            error={Boolean(errors?.DateLastCompleted) && Boolean(submitCount)}
                                                                            helperText={
                                                                                Boolean(submitCount) && Boolean(errors?.DateLastCompleted)
                                                                                    ? String(errors?.DateLastCompleted)
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid item xs={3}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        row
                                                                        name="IsMetAtThisVisit"
                                                                        value={Boolean(values?.IsMetAtThisVisit) ? 'Yes' : 'No'}
                                                                        onChange={(e) => {
                                                                            setFieldValue('IsMetAtThisVisit', e.target.value === 'Yes');
                                                                        }}
                                                                    >
                                                                        <FormControlLabel
                                                                            sx={{ marginRight: '5px' }}
                                                                            value="Yes"
                                                                            control={<Radio color="success" />}
                                                                            label="Met"
                                                                        />
                                                                        <FormControlLabel
                                                                            sx={{ m: 0 }}
                                                                            value="No"
                                                                            control={<Radio color="error" />}
                                                                            label="Not Met"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2.5} mr={2}>
                                                                <DatePicker
                                                                    label="Date Last Met"
                                                                    value={values?.DateLastMet ? new Date(values.DateLastMet) : null}
                                                                    minDate={new Date('2017-01-01')}
                                                                    onChange={(date) => {
                                                                        if (moment(date).isValid()) {
                                                                            setFieldValue('DateLastMet', date.toISOString());
                                                                        } else {
                                                                            setFieldValue('DateLastMet', '');
                                                                        }
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            {...params}
                                                                            error={Boolean(errors?.DateLastMet) && Boolean(submitCount)}
                                                                            helperText={
                                                                                Boolean(submitCount) && Boolean(errors?.DateLastMet)
                                                                                    ? String(errors?.DateLastMet)
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}
                                                    <Grid item xs={2.6}>
                                                        <FormControl>
                                                            <InputLabel>Claim Source</InputLabel>
                                                            <Select
                                                                style={{ width: 150 }}
                                                                value={values?.ClaimSource}
                                                                label="Claim Source"
                                                                onChange={(e) => {
                                                                    setFieldValue('ClaimSource', parseInt(e.target.value.toString()));
                                                                }}
                                                            >
                                                                {claimsSourceList.map((item, index) => (
                                                                    <MenuItem key={`${item.Id}-${index}`} value={item.Id}>
                                                                        {item.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    {values?.ClaimSource === ClaimSourceEnum.HealthPlan && (
                                                        <Grid item xs={3.4} mr={2}>
                                                            <DatePicker
                                                                label="Health Plan Notification Date"
                                                                value={values?.DateUpdatedByHealthPlan ? new Date(values.DateUpdatedByHealthPlan) : null}
                                                                minDate={new Date('2017-01-01')}
                                                                onChange={(date) => {
                                                                    if (moment(date).isValid()) {
                                                                        setFieldValue('DateUpdatedByHealthPlan', date.toISOString());
                                                                    } else {
                                                                        setFieldValue('DateUpdatedByHealthPlan', '');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        {...params}
                                                                        error={Boolean(errors?.DateUpdatedByHealthPlan) && Boolean(submitCount)}
                                                                        helperText={
                                                                            Boolean(submitCount) && Boolean(errors?.DateUpdatedByHealthPlan)
                                                                                ? String(errors?.DateUpdatedByHealthPlan)
                                                                                : ''
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                <Stack spacing={2} mt={1.5}>
                                                    <TextField
                                                        style={{ width: '100%', marginTop: '10px' }}
                                                        id="outlined-multiline-static"
                                                        label="Note"
                                                        multiline
                                                        rows={6}
                                                        value={values?.Note || ''}
                                                        onChange={(e) => {
                                                            setFieldValue('Note', e.target.value);
                                                        }}
                                                        error={Boolean(errors?.Note) && Boolean(submitCount)}
                                                        helperText={Boolean(submitCount) && Boolean(errors?.Note) ? String(errors?.Note) : ''}
                                                    />
                                                </Stack>
                                                <Grid container paddingTop={1} spacing={2} mb={1}>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth margin="normal">
                                                            <FormLabel sx={{ pb: '5px' }}>Meta Data Tags</FormLabel>
                                                            <ToggleButtonGroup
                                                                sx={{ display: 'flex' }}
                                                                exclusive
                                                                value={values?.DocumentMetaTag}
                                                                color="primary"
                                                                onChange={(e, value) => {
                                                                    setFieldValue('DocumentMetaTag', value);
                                                                }}
                                                            >
                                                                {metaTagsByCategoryId.length > 0 &&
                                                                    metaTagsByCategoryId.map((tag) => {
                                                                        return (
                                                                            <ToggleButton key={tag.Id} value={tag.Id}>
                                                                                {tag.Name}
                                                                            </ToggleButton>
                                                                        );
                                                                    })}
                                                            </ToggleButtonGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container paddingTop={2} spacing={2} mb={3}>
                                                    <Grid item xs={12}>
                                                        <Stack spacing={2}>
                                                            <form id={'upload-form'}>
                                                                <Button
                                                                    variant="contained"
                                                                    id="upload-btn"
                                                                    component="label"
                                                                    sx={{ textAlign: 'center' }}
                                                                    startIcon={<CloudUploadIcon />}
                                                                >
                                                                    Choose Document To Upload
                                                                    <input
                                                                        id="file"
                                                                        name="File"
                                                                        type="file"
                                                                        accept="application/pdf"
                                                                        hidden
                                                                        onChange={(e) => {
                                                                            setFieldValue('File', e.target.files[0]);
                                                                        }}
                                                                    />
                                                                </Button>
                                                                <FormHelperText error>
                                                                    {Boolean(submitCount) && Boolean(errors?.File) ? String(errors?.File) : ''}
                                                                </FormHelperText>
                                                            </form>
                                                            <Typography variant="subtitle1">{values?.File ? values?.File?.name : ''}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                                <Divider />
                                <Stack direction="row" spacing={2} style={{ float: 'right' }} pt={1} mt={1}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={isLoading}
                                        endIcon={isLoading ? <CircularProgress size={18} color="inherit" /> : null}
                                    >
                                        {isLoading ? 'Submiting...' : 'Submit'}
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        disabled={isLoading}
                                        onClick={() => {
                                            dispatch(setShowHedisUpdateModal(false));
                                            setHedisDetail(null);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            <Modal
                open={showHedisDetail}
                onClose={() => {
                    setShowHedisDetail(false);
                }}
            >
                <Box sx={modalStyle} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            {hedisType}
                            <Typography variant="body1" sx={{ color: fontColor(hedisDetail), fontSize: 14 }}>
                                {prepareHedisStatus(hedisDetail)}
                            </Typography>
                        </Typography>
                        <Divider />
                    </Stack>
                    <Stack spacing={2} mt={2}>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                CPT Code :
                            </Typography>
                            <Typography>{Boolean(hedisDetail?.CPTcode) ? hedisDetail?.CPTcode : '-'}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            {Boolean(completedList.map((item) => item.Id).includes(hedisDetail?.HedisMetricType)) ? (
                                <>
                                    <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                        Last Completed :
                                    </Typography>
                                    <Typography>
                                        {Boolean(hedisDetail?.DateLastCompleted)
                                            ? moment(hedisDetail?.DateLastCompleted).format('MM/DD/yyyy')
                                            : Boolean(hedisDetail?.DateLastMet)
                                            ? moment(hedisDetail?.DateLastMet).format('MM/DD/yyyy')
                                            : '-'}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                        {hedisDetail?.DateLastMet && !hedisDetail.IsMetAtThisVisit ? 'Last Not Met : ' : 'Last Met : '}
                                    </Typography>
                                    <Typography>{Boolean(hedisDetail?.DateLastMet) ? moment(hedisDetail?.DateLastMet).format('MM/DD/yyyy') : '-'}</Typography>
                                </>
                            )}
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Claim Number :
                            </Typography>
                            <Typography>{Boolean(hedisDetail?.ClaimNumber) ? hedisDetail?.ClaimNumber : '-'}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Claim Source :
                            </Typography>
                            <Typography>
                                {Boolean(hedisDetail?.ClaimSourceName)
                                    ? hedisDetail.ClaimSource === ClaimSourceEnum.ChpClaims && hedisDetail.ProviderName
                                        ? `${hedisDetail.ClaimSourceName} (${hedisDetail.ProviderName})`
                                        : hedisDetail.ClaimSourceName
                                    : '-'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                Notes :
                            </Typography>
                            <Typography flex={1}>{Boolean(hedisDetail?.Note) ? hedisDetail?.Note : '-'}</Typography>
                        </Stack>
                        {hedisDetail?.DocumentUri && (
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                    Document :
                                </Typography>
                                <Link target="_blank" underline="none" href={hedisDetail?.DocumentUri} sx={{ mt: '4px !important' }}>
                                    {hedisDetail?.DocumentName}
                                </Link>
                            </Stack>
                        )}
                        {hedisDetail?.ClaimSource === ClaimSourceEnum.HealthPlan && (
                            <Stack direction="row" spacing={2}>
                                <Typography fontWeight={700} sx={{ ml: 1, mb: 1.5 }}>
                                    Updated Based On Health Plan :
                                </Typography>
                                <Typography>
                                    {Boolean(hedisDetail?.DateUpdatedByHealthPlan) ? moment(hedisDetail?.DateUpdatedByHealthPlan).format('MM/DD/yyyy') : '-'}
                                </Typography>
                            </Stack>
                        )}
                        <Divider />
                        <Box>
                            <Stack direction="row" spacing={1} style={{ float: 'right' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setShowHedisDetail(false);
                                        setHedisDetail(null);
                                    }}
                                >
                                    Ok
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    );
};

export default MemberHedisMetrics;
