import { Dialog, DialogTitle, Grid, FormControl, InputLabel, MenuItem, Select, Stack, Button } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

const ProviderFacilityDialog = (props) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
            <Grid container p={3} spacing={2}>
                <Grid xs={12} item>
                    <DialogTitle>ADD {props.selectTitle.toUpperCase()}</DialogTitle>
                </Grid>

                <Grid xs={12} item>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button
                            disabled={props.selectionModel.length === 0}
                            sx={{ minWidth: '120px' }}
                            variant="contained"
                            onClick={(e) => {
                                props.assignProviderHandler(e);
                            }}
                        >
                            Assign {props.selectTitle}
                        </Button>
                        <Button
                            sx={{ minWidth: '120px' }}
                            variant="outlined"
                            onClick={(e) => {
                                props.onClose(e);
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Grid>

                <Grid xs={12} item>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{props.selectLabel}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={'-1'}
                            value={props.selectValue}
                            label={props.selectLabel}
                            onChange={props.selectOnChange}
                        >
                            <MenuItem value={'-1'}>All</MenuItem>
                            {props.selectOptions}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} item>
                    <div style={{ height: '60vh', width: '100%' }}>
                        <DataGrid
                            rows={props.rows}
                            columns={props.columns}
                            pageSize={props.pageSize}
                            rowsPerPageOptions={[5]}
                            onPageSizeChange={props.handleWindowResize}
                            getRowId={(row) => row.Id || row.ProviderId}
                            columnVisibilityModel={props.columnVisibilityModel}
                            checkboxSelection
                            onSelectionModelChange={props.selectionModelHandler}
                            selectionModel={props.selectionModel}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                [`& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root`]: {
                                    display: 'none',
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ProviderFacilityDialog;
