import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    CircularProgress,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    Collapse,
    Link,
    IconButton,
    InputLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { fetchAdvancedDirective, saveAdvancedDirective, setAdvancedDirective } from '../../../store/memberDetail.slice';
import MemberAvatar from './MemberAvatar';
import { copyObject } from '../../../utils/common';
import { YesNoAnswers } from '../../../utils/assessments';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';

const MemberAdvancedDirectives: React.FC<{ isAssessment?: boolean }> = ({ isAssessment = false }) => {
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState(false);
    const dispatch = useDispatch();
    const { advancedDirective, selectedMember, isFetching, isSaving: isSavingAd } = useSelector((state: RootState) => state.memberDetail);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingAd || isSavingEngagementDraft;

    const handleOnChange = (value, propName) => {
        let data = copyObject(advancedDirective);
        data.MemberId = selectedMember.Id;
        data.File = advancedDirective.File;
        data[propName] = value;
        setIsChanged(true);
        dispatch(setAdvancedDirective(data));
    };

    const save = () => {
        if (isAssessment) {
            setShowSaveAlert(true);
        } else {
            dispatch(saveAdvancedDirective(advancedDirective));
        }
    };

    const cancel = () => {
        dispatch(fetchAdvancedDirective(selectedMember.Id));
    };

    const handleOnCofirmDeleteDoc = () => {
        handleOnChange(null, 'Document');
        setShowDeleteAlert(false);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(
                saveAdvancedDirective(advancedDirective, (data) => {
                    dispatch(saveEngagementDraft({ ...engagementDraft, AdvancedDirectiveId: data.Id }, true));
                })
            );
        } else {
            dispatch(gotoNextMenu());
        }
    };

    useEffect(() => {
        dispatch(fetchAdvancedDirective(selectedMember.Id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Paper
                elevation={3}
                className={isAssessment ? 'content-panel' : ''}
                style={isAssessment ? { padding: '10px', margin: '-20px 0 -20px -20px' } : { padding: '10px', margin: '16px' }}
            >
                <Stack spacing={2}>
                    <div>
                        <MemberAvatar />
                    </div>
                    {isFetching ? (
                        <Box display="flex" justifyContent="center" className="loader-container">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={6} display="flex" alignItems="center">
                                    <Typography variant="subtitle1" component="label">
                                        Does Member have an Advance Directive?
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <RadioGroup
                                        row
                                        name="HasAdvanceDirective"
                                        value={String(advancedDirective.HasAdvanceDirective)}
                                        onChange={(e) => handleOnChange(e.target.value === 'true', e.target.name)}
                                    >
                                        {YesNoAnswers.map((type, index) => {
                                            return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                        })}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Collapse in={advancedDirective.HasAdvanceDirective} timeout="auto" unmountOnExit>
                                <Grid container className="mt-0">
                                    <Grid item xs={0.5} />
                                    <Grid item xs display="flex" alignItems="center">
                                        <div>
                                            <InputLabel style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Patient's Conversation & Decision document</InputLabel>
                                            {Boolean(advancedDirective?.Document?.AzureDocumentUri) ? (
                                                <React.Fragment>
                                                    <Link
                                                        href={advancedDirective.Document.AzureDocumentUri}
                                                        target="_blank"
                                                        underline="hover"
                                                        className="ml-20"
                                                    >
                                                        {advancedDirective.Document.FileName}
                                                    </Link>
                                                    <IconButton color="error" onClick={() => setShowDeleteAlert(true)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Button variant="contained" component="label" disabled={isSaving}>
                                                        Upload Document
                                                        <input
                                                            accept="application/pdf"
                                                            name="File"
                                                            type="file"
                                                            hidden
                                                            onChange={(e) => handleOnChange((e.currentTarget as HTMLInputElement).files[0], e.target.name)}
                                                        />
                                                    </Button>
                                                    <span className="upload-btn-file-name ml-20">{advancedDirective.File?.name}</span>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Collapse>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={6} display="flex" alignItems="center">
                                    <Typography variant="subtitle1" component="label">
                                        Discussed Advance Directives with Member?
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <RadioGroup
                                        row
                                        name="IsDiscussedWithMember"
                                        value={String(advancedDirective.IsDiscussedWithMember)}
                                        onChange={(e) => handleOnChange(e.target.value === 'true', e.target.name)}
                                    >
                                        {YesNoAnswers.map((type, index) => {
                                            return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                        })}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Collapse in={advancedDirective.IsDiscussedWithMember === false} timeout="auto" unmountOnExit>
                                <Grid container spacing={2}>
                                    <Grid item xs={0.5} />
                                    <Grid item lg={4.65} md={5.65} sm={5.65} display="flex" alignItems="center">
                                        <Typography variant="subtitle1" component="label">
                                            Refused to discuss?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <RadioGroup
                                            row
                                            name="IsRefusedToDiscuss"
                                            value={String(advancedDirective.IsRefusedToDiscuss)}
                                            onChange={(e) => handleOnChange(e.target.value === 'true', e.target.name)}
                                        >
                                            {YesNoAnswers.map((type, index) => {
                                                return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                            })}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Collapse in={advancedDirective.IsRefusedToDiscuss === false} timeout="auto" unmountOnExit>
                                    <Grid container spacing={2}>
                                        <Grid item xs={0.5} />
                                        <Grid item xs lg={9}>
                                            <Stack direction="row" spacing={2}>
                                                <Typography variant="subtitle1" component="label">
                                                    Other:
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    name="OtherReason"
                                                    defaultValue={advancedDirective.OtherReason}
                                                    rows={5}
                                                    label="Reason"
                                                    placeholder="Enter reason here"
                                                    multiline
                                                    onBlur={(e) => handleOnChange(e.target.value, e.target.name)}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Collapse>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={6} display="flex" alignItems="center">
                                    <Typography variant="subtitle1" component="label">
                                        Do thev have a MPOA?
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <RadioGroup
                                        row
                                        name="HasMPOA"
                                        value={String(advancedDirective.HasMPOA)}
                                        onChange={(e) => handleOnChange(e.target.value === 'true', e.target.name)}
                                    >
                                        {YesNoAnswers.map((type, index) => {
                                            return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                        })}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={6} display="flex" alignItems="center">
                                    <Typography variant="subtitle1" component="label">
                                        BHPOA?
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <RadioGroup
                                        row
                                        name="HasBHPOA"
                                        value={String(advancedDirective.HasBHPOA)}
                                        onChange={(e) => handleOnChange(e.target.value === 'true', e.target.name)}
                                    >
                                        {YesNoAnswers.map((type, index) => {
                                            return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                        })}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={5} md={6} sm={6} display="flex" alignItems="center">
                                    <Typography variant="subtitle1" component="label">
                                        Member wishes are known by Support System:
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <RadioGroup
                                        row
                                        name="IsKnownBySupportSystem"
                                        value={String(advancedDirective.IsKnownBySupportSystem)}
                                        onChange={(e) => handleOnChange(e.target.value === 'true', e.target.name)}
                                    >
                                        {YesNoAnswers.map((type, index) => {
                                            return <FormControlLabel key={`is-active-${index}`} value={type.Id} control={<Radio />} label={type.Name} />;
                                        })}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            {isAssessment ? (
                                <Stack direction="row" justifyContent="flex-end" className="content-footer" style={{ margin: '20px -10px -10px' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        className="mr-3"
                                        onClick={() => dispatch(gotoPreviousMenu())}
                                        startIcon={<ArrowBackIosIcon />}
                                    >
                                        Previous
                                    </Button>
                                    <Button type="submit" variant="contained" onClick={save} endIcon={<ArrowForwardIosIcon />}>
                                        Next
                                    </Button>
                                </Stack>
                            ) : (
                                <Box style={{ width: '100%' }}>
                                    <Stack direction="row" spacing={3} style={{ float: 'right' }}>
                                        <Button
                                            className="button-120"
                                            variant="contained"
                                            onClick={save}
                                            disabled={isSaving}
                                            endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                                        >
                                            Save
                                        </Button>
                                        <Button className="button-120" variant="outlined" disabled={isSaving} onClick={cancel}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            )}
                        </React.Fragment>
                    )}
                </Stack>
                {Boolean(engagementDraft.AdvancedDirectiveId) || isChanged ? (
                    <AlertDialog
                        open={showSaveAlert}
                        title="Advanced Directive"
                        cancelText="Go Back"
                        okText="Confirm"
                        onConfirm={() => handleOnConfirmToNext(true)}
                        onSkip={() => handleOnConfirmToNext()}
                        onClose={() => setShowSaveAlert(false)}
                        isLoading={isSaving}
                        message="I confirm and acknowledge that Advanced directive is completed."
                    />
                ) : (
                    <AlertDialog
                        open={showSaveAlert}
                        title="Advanced Directive"
                        cancelText="No"
                        okText="Yes, Proceed"
                        onConfirm={() => handleOnConfirmToNext()}
                        onClose={() => setShowSaveAlert(false)}
                        isLoading={isSaving}
                        message="Are you sure to proceed without completing Advanced directive?"
                    />
                )}
                <AlertDialog
                    open={showDeleteAlert}
                    showTop
                    onConfirm={handleOnCofirmDeleteDoc}
                    onClose={() => setShowDeleteAlert(false)}
                    message="Are you sure want to delete this document?"
                />
            </Paper>
        </div>
    );
};

export default MemberAdvancedDirectives;
