import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const hieSlice = createSlice({
    name: 'hie',
    initialState: {
        unmatchedAlerts: [],
        adtAlerts: [],
        alertsByMatchedDates: [],
        alertHistory: [],
        alertId: '',
        alertsList: [],
        allAlerts: [],
        allLabs: [],
        associatedAlerts: [],
        associatedDocuments: [],
        confirmationModalMessage: '',
        confirmationModalOpen: false,
        confirmationModalTitle: '',
        confirmationModalAction: null,
        covidAlerts: [],
        endDate: null,
        isArchiving: true,
        labsByMember: [],
        mapToMember: [],
        unmatchedMedicaidConsensusAlerts: [],
        memberName: '',
        memberByAHCCCS: [],
        membersByDOB: [],
        mihaAlerts: [],
        openDeleteModal: false,
        patientsAdmittedPast24Hours: [],
        patientHospitalizations: [],
        patientPanel: [],
        rows: [],
        rowSelectionModel: [],
        selectedAlert: null,
        selectedMember: null,
        showArchivedAlerts: false,
        startDate: null,
        teamNotificationModalOpen: false,
        top3Matches: [],
        unmatchedId: '',
        visitTypes: [],
    },
    reducers: {
        _setAlertsList(state, action) {
            state.alertsList = action.payload;
        },
        _setAlertsByMatchedDates(state, action) {
            state.alertsByMatchedDates = action.payload;
        },
        _setAlertHistory(state, action) {
            state.alertHistory = action.payload;
        },
        _setAlertId(state, action) {
            state.alertId = action.payload;
        },
        _setAllAlerts(state, action) {
            state.allAlerts = action.payload;
        },
        _setUnmatchedAlerts(state, action) {
            state.unmatchedAlerts = action.payload;
        },
        _setConfirmationModalAction(state, action) {
            state.confirmationModalAction = action.payload;
        },
        _setShowArchivedAlerts(state, action) {
            state.showArchivedAlerts = action.payload;
        },
        _setAssociatedAlerts(state, action) {
            state.associatedAlerts = action.payload;
        },
        _setAssociatedDocuments(state, action) {
            state.allLabs = action.payload;
        },
        _setConfirmationModalMessage(state, action) {
            state.confirmationModalMessage = action.payload;
        },
        _setConfirmationModalOpen(state, action) {
            state.confirmationModalOpen = action.payload;
        },
        _setConfirmationModalTitle(state, action) {
            state.confirmationModalTitle = action.payload;
        },
        _setEndDate(state, action) {
            state.endDate = action.payload;
        },
        _setIsArchiving(state, action) {
            state.isArchiving = action.payload;
        },
        _setLabsByMember(state, action) {
            state.labsByMember = action.payload;
        },
        _setMapToMember(state, action) {
            state.mapToMember = action.payload;
        },
        _setMemberName(state, action) {
            state.memberName = action.payload;
        },
        _setMemberByAHCCCS(state, action) {
            state.memberByAHCCCS = action.payload;
        },
        _setMedicaidConsensusAlerts(state, action) {
            state.unmatchedMedicaidConsensusAlerts = action.payload;
        },
        _setOpenDeleteModal(state, action) {
            state.openDeleteModal = action.payload;
        },
        _setPatientsAdmittedPast24Hours(state, action) {
            state.patientsAdmittedPast24Hours = action.payload;
        },
        _setPatientHospitalization(state, action) {
            state.patientHospitalizations = action.payload;
        },
        _setPatientPanel(state, action) {
            state.patientPanel = action.payload;
        },
        _setRows(state, action) {
            state.rows = action.payload;
        },
        _setRowSelectionModel(state, action) {
            state.rowSelectionModel = action.payload;
        },
        _setSelectedAlert(state, action) {
            state.selectedAlert = action.payload;
        },
        _setSelectedMember(state, action) {
            state.selectedMember = action.payload;
        },
        _setStartDate(state, action) {
            state.startDate = action.payload;
        },
        _setTeamNotificationModalOpen(state, action) {
            state.teamNotificationModalOpen = action.payload;
        },
        _setTop3Matches(state, action) {
            state.top3Matches = action.payload;
        },
        _setUnmatchedId(state, action) {
            state.unmatchedId = action.payload;
        },
        _setVisitTypes(state, action) {
            state.visitTypes = action.payload;
        },
        _setMembersByDOB(state, action) {
            state.membersByDOB = action.payload;
        },
    },
});

const {
    _setAlertsList,
    _setAlertsByMatchedDates,
    _setAlertHistory,
    _setAlertId,
    _setUnmatchedAlerts,
    _setAllAlerts,
    _setShowArchivedAlerts,
    _setAssociatedAlerts,
    _setConfirmationModalAction,
    _setAssociatedDocuments,
    _setConfirmationModalMessage,
    _setConfirmationModalOpen,
    _setConfirmationModalTitle,
    _setEndDate,
    _setIsArchiving,
    _setLabsByMember,
    _setMapToMember,
    _setMedicaidConsensusAlerts,
    _setMemberName,
    _setMemberByAHCCCS,
    _setOpenDeleteModal,
    _setPatientsAdmittedPast24Hours,
    _setPatientPanel,
    _setRows,
    _setRowSelectionModel,
    _setSelectedAlert,
    _setSelectedMember,
    _setStartDate,
    _setTeamNotificationModalOpen,
    _setTop3Matches,
    _setUnmatchedId,
    _setVisitTypes,
    _setPatientHospitalization,
    _setMembersByDOB,
} = hieSlice.actions;

export const setAlertId =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setAlertId(value));
    };

export const setAlertsList =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setAlertsList(value));
    };

export const setShowArchivedAlerts =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowArchivedAlerts(value));
    };

export const setConfirmationModalAction =
    (value: any): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalAction(value));
    };

export const setConfirmationModalMessage =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalMessage(value));
    };

export const setConfirmationModalOpen =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalOpen(value));
    };

export const setConfirmationModalTitle =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalTitle(value));
    };

export const setUnmatchedAlerts =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setUnmatchedAlerts(value));
    };

export const setMemberByAHCCCS =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setMemberByAHCCCS(value));
    };

export const setEndDate =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setEndDate(value));
    };

export const setIsArchiving =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setIsArchiving(value));
    };

export const setRows =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRows(value));
    };

export const setRowSelectionModel =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRowSelectionModel(value));
    };

export const setSelectedAlert =
    (value: object): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedAlert(value));
    };

export const setSelectedMember =
    (value: object): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedMember(value));
    };

export const setTeamNotificationModalOpen =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setTeamNotificationModalOpen(value));
    };

export const setStartDate =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setStartDate(value));
    };

export const setUnmatchedId =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setUnmatchedId(value));
    };

export const clearSelections = (): AppThunk => (dispatch) => {
    dispatch(_setTop3Matches([]));
    dispatch(_setSelectedMember(null));
    dispatch(_setLabsByMember([]));
    dispatch(_setUnmatchedId(''));
};

export const setTop3Matches =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setTop3Matches(value));
    };

export const setOpenDeleteModal =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenDeleteModal(value));
    };

export const openDeleteAlertHandler =
    (alert: any): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenDeleteModal(true));
        dispatch(_setMemberName(alert.row.Patient));
    };

export const deleteAlertHandler =
    (ids: string[]): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.delete(`${API_URL}/HIE/deletealert`, { data: ids });
        dispatch(getUnmatchedAlerts());
        dispatch(setOpenDeleteModal(false));
        dispatch(setIsLoading(false));
    };

export const archiveAlertHandler =
    (ids: string[]): AppThunk =>
    async (dispatch) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.patch(`${API_URL}/HIE/archivealert`, ids);
        dispatch(getUnmatchedAlerts());
        dispatch(closeConfirmationModalHandler());
    };

export const removeAlertFromArchiveHandler =
    (ids: string[]): AppThunk =>
    async (dispatch) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.patch(`${API_URL}/HIE/unarchivealert`, ids);
        dispatch(getUnmatchedAlerts());
        dispatch(closeConfirmationModalHandler());
    };

export const openConfirmationHandler =
    (memberId: string, providerName: string, confirmationModalTitle: string, alerts: any[], rowSelectionModel: string[]): AppThunk =>
    (dispatch) => {
        dispatch(setAlertsList(alerts));
        const message = `Are you sure you wish to ${confirmationModalTitle} the following Alert(s)?`;
        dispatch(setConfirmationModalMessage(message));
        dispatch(setConfirmationModalTitle(confirmationModalTitle));
        dispatch(setConfirmationModalOpen(true));
        if (confirmationModalTitle === 'Archive') {
            dispatch(
                setConfirmationModalAction(() => {
                    dispatch(archiveAlertHandler(rowSelectionModel));
                    dispatch(closeConfirmationModalHandler());
                })
            );
        }
        if (confirmationModalTitle === 'Delete') {
            dispatch(
                setConfirmationModalAction(() => {
                    dispatch(deleteAlertHandler(rowSelectionModel));
                    dispatch(closeConfirmationModalHandler());
                })
            );
        }
    };

export const closeConfirmationModalHandler = (): AppThunk => (dispatch) => {
    dispatch(clearSelections());
    dispatch(setConfirmationModalOpen(false));
    dispatch(setAlertId(''));
    dispatch(getUnmatchedAlerts());
};

export const getAlertsByMatchedDates =
    (startDate: string, endDate: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getalertsbychpdownloaddate?startDate=${startDate}&endDate=${endDate}`);
            dispatch(_setAlertsByMatchedDates(response.data));
            dispatch(_setRows(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setAlertsByMatchedDates([]));
            dispatch(_setRows([]));
            dispatch(setIsLoading(false));
        }
    };

export const getAllAlerts = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/HIE/getallalerts`);
    dispatch(_setAllAlerts(response.data));
    dispatch(setIsLoading(false));
};

export const fetchMedicaidConsensusAlerts = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/HIE/getunmatchedmedicaidconsensusalerts`);
    dispatch(_setMedicaidConsensusAlerts(response.data));
    dispatch(setIsLoading(false));
};

export const getPatientPanel = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/HIE/getpatientpanel`);
    dispatch(_setPatientPanel(response.data));
};

export const getAlertHistory = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/HIE/getalerthistory`);
    dispatch(_setAlertHistory(response.data));
    dispatch(setIsLoading(false));
};

export const getUnmatchedAlerts = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/HIE/getunmatchedalerts`);
    dispatch(_setUnmatchedAlerts(response.data));
    dispatch(setIsLoading(false));
};

export const getLabsByMember =
    (dob: string, memberName: string, address: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/HIE/getlabsbymember?dob=${dob}&memberName=${memberName}&address=${address}`);
        dispatch(_setLabsByMember(response.data));
    };

export const getMemberByAHCCCS =
    (ahcccsID: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/HIE/getmemberbyahcccsid?ahcccs=${ahcccsID}`);
        dispatch(_setMemberByAHCCCS(response.data));
        dispatch(_setTop3Matches([response.data]));
    };

export const getPatientHospitalizations = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/HIE/getpatienthospitalizations`);
    dispatch(_setPatientHospitalization(response.data));
};

export const getSpecificAlert =
    (alertType: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/HIE/getspecificalert?alertType=${alertType}`);
        // dispatch(_setSpecificAlerts(response.data));
        dispatch(_setUnmatchedAlerts(response.data));
    };

export const getVisitType =
    (visitType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/HIE/getvisittype?visitType=${visitType}`);
            dispatch(_setVisitTypes(response.data));
        } catch (error) {
            console.log(error);
            dispatch(_setVisitTypes([]));
        }
    };

export const getPatientsAdmittedInPast24Hours =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/patientsasmittedinpast24hours?providerId=${providerId}`);
            dispatch(_setPatientsAdmittedPast24Hours(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setPatientsAdmittedPast24Hours([]));
            dispatch(setIsLoading(false));
        }
    };

export const patchMapToMember =
    (memberId: string, providerName: string, ids: string[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.patch(`${API_URL}/HIE/maptomember?memberId=${memberId}&providerName=${providerName}`, [...ids]);
            dispatch(_setMapToMember(response.data));
            dispatch(clearSelections());
            dispatch(getUnmatchedAlerts());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setMapToMember(''));
            dispatch(clearSelections());
            dispatch(getUnmatchedAlerts());
            dispatch(setIsLoading(false));
        }
    };

export const patchUnmatchMember =
    (id: string, providerName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.patch(`${API_URL}/HIE/unmatchmember?Id=${id}&ProviderName=${providerName}`);

            dispatch(clearSelections());
            dispatch(getAlertHistory());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(clearSelections());
            dispatch(getAlertHistory());
            dispatch(setIsLoading(false));
        }
    };

export const getAssociatedAlerts =
    (dob: string, memberName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getassociatedalerts?dob=${dob}&memberName=${memberName}`);
            dispatch(_setAssociatedAlerts(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setAssociatedAlerts([]));
            dispatch(setIsLoading(false));
        }
    };

export const getAssociatedDocuments =
    (dob: string, memberName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getassociateddocuments?dob=${dob}&memberName=${memberName}`);
            dispatch(_setAssociatedDocuments(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setAssociatedDocuments([]));
            dispatch(setIsLoading(false));
        }
    };

export const getMembersByDOB =
    (dob: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getmembersbydob?dob=${dateFormatter(new Date(dob))}`);
            dispatch(_setMembersByDOB(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setMembersByDOB([]));
            dispatch(setIsLoading(false));
        }
    };

export const getTopThreeMatches =
    (firstName: string, lastName: string, dob: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/gettopthreematches?firstName=${firstName}&lastName=${lastName}&dob=${dob}`);
            dispatch(_setTop3Matches(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setTop3Matches([]));
            dispatch(setIsLoading(false));
        }
    };

export default hieSlice.reducer;
