import { Alert, Snackbar, Tab, Tabs, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { setSelectedTab } from '../../store/claimsManagement.slice';
import { fetchGenders, fetchMaritalStatuses, fetchAllLanguages, fetchEncounterClaimProviders } from '../../store/shared.slice';
import { setClaimError } from '../../store/procedureSignOffEncounter.slice';
import Authorization from '../../utils/Authorization';
import ClaimsErrorReport from './ClaimsErrorReport';
import ClaimsManagement from './ClaimsManagement';
import ProviderClaims from './ProviderClaims';
import ClaimsHelp from '../../assets/documents/Claims_Management_Help.pdf';
import InfoIcon from '@mui/icons-material/Info';

const ClaimsManagementPage = (props) => {
    const authData = new Authorization();
    const { error } = useSelector((state: RootState) => state.procedureSignOffEncounter);
    const { selectedTab } = useSelector((state: RootState) => state.claimsManagement);
    const dispatch = useDispatch();

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        dispatch(setSelectedTab(newValue));
    };

    useEffect(() => {
        dispatch(fetchEncounterClaimProviders());
        dispatch(fetchGenders());
        dispatch(fetchMaritalStatuses());
        dispatch(fetchAllLanguages());
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderAlert = () => {
        return (
            <Snackbar
                open={error?.Show}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => dispatch(setClaimError())}
            >
                <Alert onClose={() => dispatch(setClaimError())} severity="error" sx={{ width: '100%' }}>
                    {error?.Message}
                </Alert>
            </Snackbar>
        );
    };

    const getBody = () => {
        if (authData.isInPermission('View Claims Management')) {
            return (
                <>
                    {renderAlert()}
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={handleTabChange}
                        aria-label="disabled tabs example"
                        centered
                    >
                        <Tab
                            label="Claims Management"
                            icon={
                                <Tooltip title="Claims Management Help Document">
                                    <InfoIcon color="primary" cursor="pointer" onClick={() => window.open(`${ClaimsHelp}`, '_blank')} />
                                </Tooltip>
                            }
                            iconPosition="end"
                        />
                        <Tab label="Provider Claims" />
                        <Tab label="Error Report" />
                    </Tabs>
                    {selectedTab === 0 && <ClaimsManagement />}
                    {selectedTab === 1 && <ProviderClaims />}
                    {selectedTab === 2 && <ClaimsErrorReport />}
                </>
            );
        } else {
            return (
                <>
                    {renderAlert()}
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={handleTabChange}
                        aria-label="disabled tabs example"
                        centered
                    >
                        <Tab label="Provider Claims" />
                        <Tab label="Error Report" />
                    </Tabs>
                    {selectedTab === 0 && <ProviderClaims />}
                    {selectedTab === 1 && <ClaimsErrorReport />}
                </>
            );
        }
    };

    return getBody();
};

export default ClaimsManagementPage;
