import { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Paper,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    IconButton,
    Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useNavigate } from 'react-router';
import InfoIcon from '@mui/icons-material/Info';
import { copyObject } from '../../../utils/common';
import { allWidgetsList } from '../Shared/AllWidgetsList';
import { WidgetsEnum } from '../../../utils/constants';
import { qaMetricSummary } from './MetricSummaryService';
import Authorization from '../../../utils/Authorization';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import QaMetricsView from '../../../Models/MetricSummary/QaMetricsView.model';
import { setIsWidgetExisting, setWidgetFromMetricSummary } from '../../../store/smartDashboard.slice';
import { MetricTypeEnum } from '../../../Enum/MetricTypeEnum';
import '../../Widgets/Widgets.css';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 50 * 4.5,
            width: 250,
        },
    },
};

const QaMetricSummary = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const authData = new Authorization();
    const { activeProviders } = useSelector((state: RootState) => state.provider);

    const isCEO = authData.Role === 'CEO' ? true : false;
    const { customTabsList } = useSelector((state: RootState) => state.smartDashboard);

    const [documentMetric, setDocumentMetric] = useState<QaMetricsView>(new QaMetricsView());
    const [teamInPersonExamMetric, setTeamInPersonMetric] = useState<QaMetricsView>(new QaMetricsView());
    const [individualInPersonExamMetric, setIndividualInPersonMetric] = useState<QaMetricsView>(new QaMetricsView());
    const [populationMetric, setPopulationMetric] = useState<QaMetricsView>(new QaMetricsView());

    const [isQaMetricShow, setIsQaMetricShow] = useState<boolean>(false);

    const [isDocumentTooltipShow, setIsDocumentTooltipShow] = useState<boolean>(false);
    const [isTeamInPersonTooltipShow, setIsTeamInPersonTooltipShow] = useState<boolean>(false);
    const [isIndividualInPersonTooltipShow, setIsIndividualInPersonTooltipShow] = useState<boolean>(false);
    const [isPopulationTooltipShow, setIsPopulationTooltipShow] = useState<boolean>(false);

    const {
        selectedProvider,
        setSelectedProvider,
        showInActiveProviders,
        setShowInActiveProviders,

        setAppointmentSelectedProvider,
        setAppointmentDateFilter,
        setAppointmentShowInActiveProviders,

        setEngagementDateFilter,
        setEngagementSelectedProvider,
        setEngagementShowInActiveProviders,

        setIndividualEngagementDateFilter,
        setIndividualEngagementSelectedProvider,
        setIndividualEngagementShowInActiveProviders,

        setTcTeam,
        setTcShowInActiveTeams,
    } = useWidgetContext();

    const {
        selectedTeam,
        showInActiveTeams,

        setAdultHealthTeam,
        setAdultHealthIncludePastOpenStatus,
        setAdultHealthShowInActiveTeam,

        setBpTeam,
        setBpIncludePastOpenStatus,
        setBpShowInActiveTeam,

        setBmiTeam,
        setBmiIncludePastOpenStatus,
        setBmiShowInActiveTeam,

        setHgba1cTeam,
        setHgba1cIncludePastOpenStatus,
        setHgba1cShowInActiveTeam,

        setDiabeticEyeExamTeam,
        setDiabeticEyeExamIncludePastOpenStatus,
        setDiabeticEyeExamShowInActiveTeam,

        setMedicationReconciliationTeam,
        setMedicationReconciliationIncludePastOpenStatus,
        setMedicationReconciliationShowInActiveTeam,

        setDepressionScreeningTeam,
        setDepressionScreeningIncludePastOpenStatus,
        setDepressionScreeningShowInActiveTeam,

        setDepressionFollowUpTeam,
        setDepressionFollowUpIncludePastOpenStatus,
        setDepressionFollowUpShowInActiveTeam,
    } = useHedisMetricsContext();

    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    useEffect(() => {
        if (filteredProviders.length && !Boolean(selectedProvider)) {
            if (isCEO) {
                setSelectedProvider('All');
            } else {
                const loggedInProviderId = authData.UserId;
                if (Boolean(loggedInProviderId)) {
                    const providerInfo = filteredProviders.find((t) => t.Id === loggedInProviderId);
                    if (Boolean(providerInfo)) {
                        setSelectedProvider(loggedInProviderId);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData.UserId, setSelectedProvider, activeProviders]);

    useEffect(() => {
        if (Boolean(filteredProviders.length) && Boolean(selectedProvider)) {
            setIsQaMetricShow(false);
            const changeFilter = async () => {
                const data = await qaMetricSummary(selectedProvider, showInActiveProviders);
                if (Boolean(data) && data.length) {
                    setDocumentMetric(data.find((m) => m.MetricType === MetricTypeEnum.Documentation));
                    setTeamInPersonMetric(data.find((m) => m.MetricType === MetricTypeEnum.TeamInPersonVisits));
                    setPopulationMetric(data.find((m) => m.MetricType === MetricTypeEnum.ActiveMembers));
                    setIndividualInPersonMetric(data.find((m) => m.MetricType === MetricTypeEnum.IndividualInPersonVisits));
                }

                setIsQaMetricShow(true);
            };
            changeFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProvider, showInActiveProviders]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider('All');
    };

    const navigateToCustomDashboard = (item: string) => {
        if (customTabsList?.length > 0) {
            let tabList = copyObject(customTabsList);
            let homeTab = tabList.find((t) => t.tabName === 'Home');
            if (Boolean(homeTab)) {
                let component = null;
                let isExistingItem = true;
                switch (item) {
                    case 'Documentation':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.DOCUMENT_TRACKING_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Document Tracking Summary'));
                        break;
                    case 'Member Visits By Team':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.TEAM_IN_PERSON_VISITS_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Member Visits By Team'));
                        break;
                    case 'Individual In-Person Member Visits':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.INDIVIDUAL_IN_PERSON_VISITS_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Individual In-Person Member Visits'));
                        break;
                    case 'Active Members':
                        component = allWidgetsList.find((w) => w.name === WidgetsEnum.TEAM_MEMBER_SUMMARY);
                        isExistingItem = homeTab.visibleWidgets.some((m) => m === component?.name);
                        dispatch(setWidgetFromMetricSummary('Team Member Summary'));
                        break;
                }
                setAppointmentSelectedProvider(selectedProvider);
                setAppointmentDateFilter(TimePeriodFilterEnum.Last6Months);
                setAppointmentShowInActiveProviders(showInActiveProviders);

                setEngagementSelectedProvider(selectedProvider);
                setEngagementDateFilter(TimePeriodFilterEnum.Last6Months);
                setEngagementShowInActiveProviders(showInActiveProviders);

                setIndividualEngagementSelectedProvider(selectedProvider);
                setIndividualEngagementDateFilter(TimePeriodFilterEnum.Last6Months);
                setIndividualEngagementShowInActiveProviders(showInActiveProviders);

                setHgba1cTeam(selectedTeam);
                setHgba1cIncludePastOpenStatus(true);
                setHgba1cShowInActiveTeam(showInActiveTeams);

                setDiabeticEyeExamTeam(selectedTeam);
                setDiabeticEyeExamIncludePastOpenStatus(true);
                setDiabeticEyeExamShowInActiveTeam(showInActiveTeams);

                setBpTeam(selectedTeam);
                setBpIncludePastOpenStatus(true);
                setBpShowInActiveTeam(showInActiveTeams);

                setBmiTeam(selectedTeam);
                setBmiIncludePastOpenStatus(true);
                setBmiShowInActiveTeam(showInActiveTeams);

                setAdultHealthTeam(selectedTeam);
                setAdultHealthIncludePastOpenStatus(true);
                setAdultHealthShowInActiveTeam(showInActiveTeams);

                setDepressionScreeningTeam(selectedTeam);
                setDepressionScreeningIncludePastOpenStatus(true);
                setDepressionScreeningShowInActiveTeam(showInActiveTeams);

                setDepressionFollowUpTeam(selectedTeam);
                setDepressionFollowUpIncludePastOpenStatus(true);
                setDepressionFollowUpShowInActiveTeam(showInActiveTeams);

                setMedicationReconciliationTeam(selectedTeam);
                setMedicationReconciliationIncludePastOpenStatus(true);
                setMedicationReconciliationShowInActiveTeam(showInActiveTeams);

                setTcTeam(selectedTeam);
                setTcShowInActiveTeams(showInActiveTeams);

                dispatch(setIsWidgetExisting(isExistingItem));
                history(`/provider/metricsummary/smart-dashboard`);
            }
        }
    };

    const handleDocumentClick = () => {
        setIsDocumentTooltipShow(!isDocumentTooltipShow);
    };

    const handleTeamInPersonClick = () => {
        setIsTeamInPersonTooltipShow(!isTeamInPersonTooltipShow);
    };

    const handleIndividualInPersonClick = () => {
        setIsIndividualInPersonTooltipShow(!isIndividualInPersonTooltipShow);
    };

    const handlePopulationClick = () => {
        setIsPopulationTooltipShow(!isPopulationTooltipShow);
    };

    return (
        <>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} className="pointer">
                            QA Metric Summary
                        </Typography>
                    </Grid>
                    {isCEO && (
                        <Grid item xs={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Provider</InputLabel>
                                <Select
                                    value={selectedProvider}
                                    label="Provider"
                                    name="Provider"
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((provider) => {
                                        return (
                                            <MenuItem
                                                key={provider.Id}
                                                value={provider.Id}
                                                sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                            >
                                                {provider.LastName + ', ' + provider.FirstName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} marginTop={0.5} minHeight="56vh" sx={{ position: 'relative' }}>
                    {!isQaMetricShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Grid item xs={4} textAlign="center" paddingLeft="30px">
                                <Typography display={'inline-block'} fontWeight={'bold'} variant="body1">
                                    Documentation
                                </Typography>
                                <Tooltip
                                    open={isDocumentTooltipShow}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Documented :
                                            </Typography>
                                            <Typography variant="caption">Represents the percentage of appointments that have been documented within three business days of the appointment date</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleDocumentClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Card
                                    sx={{
                                        ':hover': {
                                            cursor: 'pointer',
                                            boxShadow: 10,
                                            border: 0,
                                        },
                                        boxShadow: 0,
                                    }}
                                    variant="outlined"
                                    onClick={() => navigateToCustomDashboard('Documentation')}
                                >
                                    <CardContent
                                        sx={{
                                            backgroundColor: documentMetric?.IsMet ? '#82d782' : '#FD5F61',
                                            color: documentMetric?.IsMet ? 'black' : 'white',
                                            paddingBottom: '5px !important' ,
                                        }}
                                    >
                                        <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
                                            <Typography fontWeight={'bold'} variant="h4">
                                                {Boolean(documentMetric) ? documentMetric.Percentage : 0}%
                                            </Typography>
                                        </Stack>
                                        <Stack direction={'row'} spacing={1} justifyContent={'center'} paddingTop={0.8}>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                {documentMetric?.IsMet ? 'Met' : 'Not Met'}
                                            </Typography>
                                            <Typography fontWeight={'bold'} variant="body2">
                                                (Goal: {Boolean(documentMetric) ? documentMetric.Goal : 0}%)
                                            </Typography>
                                        </Stack>
                                        <Stack direction={'row'} spacing={1} justifyContent={'center'} paddingTop={0.9}>
                                            <Typography fontWeight={'bold'} variant="subtitle2">
                                                Average Days: {Boolean(documentMetric) ? documentMetric.AverageDays : 0}
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} textAlign="center" paddingLeft="30px">
                                <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                    Member Visits by Team
                                </Typography>
                                <Tooltip
                                    open={isTeamInPersonTooltipShow}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Visited :
                                            </Typography>
                                            <Typography variant="caption">
                                                Averages selected team's rate of in-person visits with reasons of "Initial", "Routine Follow-Up", and
                                                "Behavioral Health Services" per active member over the selected months. Members will only be counted for months
                                                that they were active
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleTeamInPersonClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Card
                                    sx={{
                                        ':hover': {
                                            cursor: 'pointer',
                                            boxShadow: 10,
                                            border: 0,
                                        },
                                        boxShadow: 0,
                                    }}
                                    variant="outlined"
                                    onClick={() => navigateToCustomDashboard('Member Visits By Team')}
                                >
                                    <CardContent
                                        sx={{
                                            backgroundColor: teamInPersonExamMetric?.IsMet ? '#82d782' : '#FD5F61',
                                            color: teamInPersonExamMetric?.IsMet ? 'black' : 'white',
                                        }}
                                    >
                                        <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                            <Typography fontWeight={'bold'} variant="h4">
                                                {Boolean(teamInPersonExamMetric) ? teamInPersonExamMetric.Percentage : 0}%
                                            </Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Typography fontWeight={'bold'} variant="body2">
                                                    {teamInPersonExamMetric?.IsMet ? 'Met' : 'Not Met'}
                                                </Typography>
                                                <Typography fontWeight={'bold'} variant="body2">
                                                    (Goal: {Boolean(teamInPersonExamMetric) ? teamInPersonExamMetric.Goal : 0}%)
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} textAlign="center" paddingLeft="30px">
                                <Typography fontWeight={'bold'} variant="body1" display={'inline-block'}>
                                    Population Size
                                </Typography>
                                <Tooltip
                                    open={isPopulationTooltipShow}
                                    title={
                                        <div>
                                            <Typography variant="caption">Number of members in active status</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handlePopulationClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Card
                                    sx={{
                                        ':hover': {
                                            cursor: 'pointer',
                                            boxShadow: 10,
                                            border: 0,
                                        },
                                        boxShadow: 0,
                                    }}
                                    variant="outlined"
                                    onClick={() => navigateToCustomDashboard('Active Members')}
                                >
                                    <CardContent
                                        sx={{
                                            backgroundColor: populationMetric?.IsMet ? '#82d782' : '#FD5F61',
                                            color: populationMetric?.IsMet ? 'black' : 'white',
                                        }}
                                    >
                                        <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                            <Typography fontWeight={'bold'} variant="h4">
                                                {Boolean(populationMetric) ? populationMetric.Percentage : 0}
                                            </Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Typography fontWeight={'bold'} variant="body2">
                                                    {populationMetric?.IsMet ? 'Met' : 'Not Met'}
                                                </Typography>
                                                <Typography fontWeight={'bold'} variant="body2">
                                                    (Active Members: {Boolean(populationMetric) ? populationMetric.Goal : 0})
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} textAlign="center" paddingLeft="30px">
                                <Typography fontWeight={'bold'} variant="body2" display={'inline-block'}>
                                    Individual In-Person Members Visits
                                </Typography>
                                <Tooltip
                                    open={isIndividualInPersonTooltipShow}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Visited :
                                            </Typography>
                                            <Typography variant="caption">
                                                Averages selected provider's rate of in-person visits with reasons of "Initial", "Routine Follow-Up", "Facility
                                                Visit" and "Behavioral Health Services"
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleIndividualInPersonClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Card
                                    sx={{
                                        ':hover': {
                                            cursor: 'pointer',
                                            boxShadow: 10,
                                            border: 0,
                                        },
                                        boxShadow: 0,
                                    }}
                                    variant="outlined"
                                    onClick={() => navigateToCustomDashboard('Individual In-Person Member Visits')}
                                >
                                    <CardContent
                                        sx={{
                                            backgroundColor: individualInPersonExamMetric?.IsMet ? '#82d782' : '#FD5F61',
                                            color: individualInPersonExamMetric?.IsMet ? 'black' : 'white',
                                        }}
                                    >
                                        <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
                                            <Typography fontWeight={'bold'} variant="h4">
                                                {Boolean(individualInPersonExamMetric) ? individualInPersonExamMetric.Percentage : 0}%
                                            </Typography>
                                            <Stack direction={'row'} spacing={2}>
                                                <Typography fontWeight={'bold'} variant="body2">
                                                    {individualInPersonExamMetric?.IsMet ? 'Met' : 'Not Met'}
                                                </Typography>
                                                <Typography fontWeight={'bold'} variant="body2">
                                                    (Goal: {Boolean(individualInPersonExamMetric) ? individualInPersonExamMetric.Goal : 0}%)
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* To fix height problem */}
                            <Grid item xs={12} textAlign="center" paddingLeft="30px">
                                <Card sx={{ minHeight: '150px', display: 'inline-block' }}></Card>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Paper>
        </>
    );
};

export default QaMetricSummary;
