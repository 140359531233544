import { Box, Checkbox, Divider, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import moment from 'moment';

import GuardianOrCaregiverInput from '../../Shared/GuardianOrCaregiverInput';
import Initials from '../../Shared/Initials';
import Signature from '../../Shared/Signature';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { setDigitalSignatureForm } from '../../../../store/consentSlice.slice';
import { ConsentSignatureTypeEnum } from '../../../../Enum/ConsentSignatureTypeEnum';
import SignatureTypeTabs from '../../Shared/SignatureTypeTabs';

const DocumentAcknowledgement = () => {
    const { activeStep, digitalSignatureForm } = useSelector((state: RootState) => state.consentSlice);
    const dispatch = useDispatch();
    const [openMemberRights, setOpenMemberRights] = useState(false);
    const [openPrivacyPractices, setOpenPrivacyPractices] = useState(false);
    const [openCCGProcedure, setOpenCCGProcedure] = useState(false);
    const [openProtectedInfo, setOpenProtectedInfo] = useState(false);
    const [openConsentTreatment, setOpenConsentTreatment] = useState(false);
    const [openMemberFees, setOpenMemberFees] = useState(false);
    const [openMemberSignatureDocumentAcknowledgement, setOpenMemberSignatureDocumentAcknowledgement] = useState(false);
    const [openGuardianOrCareGiverDocumentAcknowledge, setOpenGuardianOrCareGiverDocumentAcknowledge] = useState(false);
    const [openGuardianOrCareGiverWitnessDocumentAcknowledge, setOpenGuardianOrCareGiverWitnessDocumentAcknowledge] = useState(false);
    const [openVerbalConsent, setOpenVerbalConsent] = useState(false);

    useEffect(() => {
        const element = document.getElementById('top');
        element.scrollIntoView({ behavior: 'smooth' });
    }, [activeStep]);

    return (
        <Box sx={{ position: 'relative' }}>
            <div id="top" className="initials-div"></div>
            <Typography textAlign={'center'} variant="h5" p={2} fontWeight={'bold'} mt={5}>
                DOCUMENT ACKNOWLEDGEMENT
            </Typography>

            <Typography textAlign={'center'} variant="h6" mb={5}>
                I ACKNOWLEDGE THAT I HAVE RECEIVED A COPY OF:
            </Typography>
            <Grid container spacing={1} rowGap={0} p={2} textAlign={'left'} alignItems={'center'} my={5}>
                {/* Member's Rights */}

                <Grid item xs={12} sx={{ p: '0!important' }}>
                    <div className="initials-div" id="member-rights"></div>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={'bold'}>
                        Member's Rights
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Initials
                        isSpanish={false}
                        isOpen={openMemberRights}
                        setIsOpen={setOpenMemberRights}
                        initials={digitalSignatureForm.MemberRights}
                        setInitials={(initials: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    MemberRights: initials,
                                })
                            )
                        }
                    />
                </Grid>
                {/* Privacy Practices */}
                <Grid item xs={12} sx={{ p: '0!important' }}>
                    <div className="initials-div" id="privacy-practices"></div>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={'bold'}>
                        Notice of Privacy Practices
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Initials
                        isSpanish={false}
                        isOpen={openPrivacyPractices}
                        setIsOpen={setOpenPrivacyPractices}
                        initials={digitalSignatureForm.PrivacyPractices}
                        setInitials={(initials: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    PrivacyPractices: initials,
                                })
                            )
                        }
                    />
                </Grid>
                {/* CCG Procedure */}
                <Grid item xs={12} sx={{ p: '0!important' }}>
                    <div className="initials-div" id="ccg-procedure"></div>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={'bold'}>
                        CHP's Compliments, Complaints and Grievance Procedure
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Initials
                        isSpanish={false}
                        isOpen={openCCGProcedure}
                        setIsOpen={setOpenCCGProcedure}
                        initials={digitalSignatureForm.CCGProcedure}
                        setInitials={(initials: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    CCGProcedure: initials,
                                })
                            )
                        }
                    />
                </Grid>
                {/* Protected Info */}
                <Grid item xs={12} sx={{ p: '0!important' }}>
                    <div className="initials-div" id="protected-info"></div>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={'bold'}>
                        Authorization to Release Protected Information
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Initials
                        isSpanish={false}
                        isOpen={openProtectedInfo}
                        setIsOpen={setOpenProtectedInfo}
                        initials={digitalSignatureForm.ProtectedInfo}
                        setInitials={(initials: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    ProtectedInfo: initials,
                                })
                            )
                        }
                    />
                </Grid>
                {/* Consent Treatment */}
                <Grid item xs={12} sx={{ p: '0!important' }}>
                    <div className="initials-div" id="consent-treatment"></div>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={'bold'}>
                        Consent for Treatment
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Initials
                        isSpanish={false}
                        isOpen={openConsentTreatment}
                        setIsOpen={setOpenConsentTreatment}
                        initials={digitalSignatureForm.ConsentTreatment}
                        setInitials={(initials: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    ConsentTreatment: initials,
                                })
                            )
                        }
                    />
                </Grid>
                {/* Member Fees */}
                <Grid item xs={12} sx={{ p: '0!important' }}>
                    <div className="initials-div" id="member-fees"></div>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={'bold'}>
                        CHP's Policy for Member Fees/Co-Pays
                    </Typography>
                </Grid>
                <div id="document-acknowledge-signature"></div>
                <Grid item xs={6}>
                    <Initials
                        isSpanish={false}
                        isOpen={openMemberFees}
                        setIsOpen={setOpenMemberFees}
                        initials={digitalSignatureForm.MemberFees}
                        setInitials={(initials: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    MemberFees: initials,
                                })
                            )
                        }
                    />
                </Grid>
            </Grid>
            <SignatureTypeTabs />
            <Divider />
            <Grid container spacing={2} p={2}>
                {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Patient && (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                variant="standard"
                                id="member-name"
                                error={digitalSignatureForm.MemberName.length === 0}
                                value={digitalSignatureForm.MemberName}
                                label={`Name`}
                                fullWidth
                                onChange={(e) => {
                                    dispatch(
                                        setDigitalSignatureForm({
                                            ...digitalSignatureForm,
                                            MemberName: e.target.value,
                                        })
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant="standard" value={`${moment().format('MM-DD-YYYY')}`} label={`Date`} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Signature
                                isSpanish={false}
                                buttonLabel={'Signature'}
                                isOpen={openMemberSignatureDocumentAcknowledgement}
                                setIsOpen={setOpenMemberSignatureDocumentAcknowledgement}
                                signature={digitalSignatureForm.MemberSignatureThree}
                                setSignature={(signature: string) =>
                                    dispatch(
                                        setDigitalSignatureForm({
                                            ...digitalSignatureForm,
                                            MemberSignatureThree: signature,
                                        })
                                    )
                                }
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} textAlign={'left'}>
                    {/* Guardian or Caregiver */}
                    {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.GuardianOrCaregiver && (
                        <GuardianOrCaregiverInput
                            isSpanish={false}
                            openGuardianOrCaregiver={openGuardianOrCareGiverDocumentAcknowledge}
                            setOpenGuardianOrCaregiver={setOpenGuardianOrCareGiverDocumentAcknowledge}
                            guardianOrCareGiver={digitalSignatureForm.GuardianOrCaregiverSignatureDocumentAcknowledge}
                            setGuardianOrCareGiver={(signature: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        GuardianOrCaregiverSignatureDocumentAcknowledge: signature,
                                    })
                                )
                            }
                            openGuardianOrCareGiverWitness={openGuardianOrCareGiverWitnessDocumentAcknowledge}
                            setOpenGuardianOrCareGiverWitness={setOpenGuardianOrCareGiverWitnessDocumentAcknowledge}
                            guardianOrCareGiverWitness={digitalSignatureForm.WitnessDocumentAcknowledge}
                            setGuardianOrCareGiverWitness={(signature: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        WitnessDocumentAcknowledge: signature,
                                    })
                                )
                            }
                        />
                    )}
                    {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Verbal && (
                        <>
                            {/* Verbal Consent */}
                            <Stack>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={digitalSignatureForm.VerbalConsent}
                                            onChange={() =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        VerbalConsent: !digitalSignatureForm.VerbalConsent,
                                                    })
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography>
                                            Verbal consent is required if member or legal representative is unable to physically sign at this time and yet
                                            consent for care has been explicitly agreed to.
                                            <b> Verbal consent must be witnessed by two people telephonically or in person.</b>
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={digitalSignatureForm.ReviewedThree}
                                            onChange={() =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        ReviewedThree: !digitalSignatureForm.ReviewedThree,
                                                    })
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography>
                                            <b> As the Provider, I have reviewed this page of the consent form with the member</b>
                                        </Typography>
                                    }
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            helperText="Witness Digital Signature"
                                            sx={{ mt: 5 }}
                                            fullWidth
                                            label={'Witness One'}
                                            value={digitalSignatureForm.VerbalConsentWitnessOne}
                                            onChange={(e) =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        VerbalConsentWitnessOne: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            helperText="Witness Digital Signature"
                                            sx={{ mt: 5 }}
                                            fullWidth
                                            label={'Witness Two'}
                                            value={digitalSignatureForm.VerbalConsentWitnessTwo}
                                            onChange={(e) =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        VerbalConsentWitnessTwo: e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Signature
                                            isSpanish={false}
                                            buttonLabel={'Witness Signature'}
                                            isOpen={openVerbalConsent}
                                            setIsOpen={setOpenVerbalConsent}
                                            signature={digitalSignatureForm.VerbalConsentSignature}
                                            setSignature={(signature: string) =>
                                                dispatch(
                                                    setDigitalSignatureForm({
                                                        ...digitalSignatureForm,
                                                        VerbalConsentSignature: signature,
                                                    })
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default DocumentAcknowledgement;
