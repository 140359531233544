import { FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { appendToCallItemNotes, setIsRescheduleModalOpen, setPropertiesOnSelectedCallItem } from '../../../store/officeScheduler.slice';
import phoneFormat from '../../../utils/phoneFormat';
import { OfficeSchedulerModeEnum } from '../../Calendar/Enums/OfficeSchedulerModeEnum';
import { CallBtn, CancelBtn, ConfirmBtn, CopyTextMsgBtn, NotReachedBtn, RescheduleBtn, SkipBtn } from './schedulingDashboardButtons';
import { format } from 'date-fns';
import { toTitleCase } from '../../../utils/common';

interface SchedulingButtonsPaneProps {
    setOpenEditor: any;
    mode: OfficeSchedulerModeEnum;
}

const SchedulingButtonsPane: React.FC<SchedulingButtonsPaneProps> = ({ setOpenEditor, mode }: SchedulingButtonsPaneProps) => {
    const dispatch = useDispatch();
    const { selectedCallItem, member, associatedEvent } = useSelector((state: RootState) => state.officeSchedulerSlice);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [currentMemberPhones, setCurrentMemberPhones] = useState<any>([]);
    const [callNumber, setCallNumber] = useState<string>('');
    const [textable, setTextable] = useState<boolean>(false);

    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const apptDate = new Date(new Date(selectedCallItem.AppointmentDateTime).setHours(0, 0, 0, 0));
    const differenceInTime = apptDate.getTime() - today.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const providerNames = useMemo(() => {
        return associatedEvent.providerNames.join(', ');
    }, [associatedEvent]);
    const memberName = useMemo(() => toTitleCase(member.FirstName), [member]);
    const eventTime = useMemo(() => format(new Date(associatedEvent?.AppointmentDateTime ?? new Date()), 'MM/dd/yyyy h:mm aa'), [associatedEvent]);

    useEffect(() => {
        if (member?.Phones) {
            const sortedPhones = structuredClone(member.Phones).sort(function (x, y) {
                // true values first
                return x.IsPrimary === y.IsPrimary ? 0 : x.IsPrimary ? -1 : 1;
            });
            const phoneList = sortedPhones.map((p) => {
                let display = phoneFormat(p.Number);
                let number = p.Number.replace(/\D/g, '');
                let textable = p.Textable;
                if (p.IsPrimary) {
                    display = `Primary: ${phoneFormat(p.Number)}`;
                }

                if (p.Textable) {
                    display = display + '  - textable';
                }
                return { display: display, number: number, textable: textable };
            });
            setCurrentMemberPhones(phoneList);
        } else {
            setCurrentMemberPhones([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);

    useEffect(() => {
        if (currentMemberPhones.length > 0) {
            setCallNumber(currentMemberPhones[0].number);
            if (differenceInDays >= 3) {
                setTextable(currentMemberPhones[0].textable);
            } else {
                setTextable(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMemberPhones]);

    function handleNumberChange(event) {
        setCallNumber(event.target.value);
        const currentPhone = currentMemberPhones.find((p) => p.number === event.target.value);

        if (differenceInDays >= 3) {
            setTextable(currentPhone.textable);
        } else {
            setTextable(false);
        }
    }

    const handleCallClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallAttempted: true, LastContact: new Date(), LastCalledNumber: callNumber }));
        dispatch(appendToCallItemNotes(`Attempted to call ${memberName} at ${phoneFormat(callNumber)}`));
    };

    const handleConfirmClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'Confirmed', LastContact: new Date() }));
        dispatch(appendToCallItemNotes(`Confirmed ${memberName}'s ${eventTime} appointment with ${providerNames}.`));
    };
    const handleConfirmedByCalendarClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'ConfirmedByCalendar', LastContact: new Date() }));
        dispatch(appendToCallItemNotes(`Opened member's calendar dialog for direct schedule changes.`));
        setOpenEditor(true);
    };

    const handleCancelledClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'Cancelled' }));
        dispatch(appendToCallItemNotes(`${memberName} cancelled the ${eventTime} appointment with ${providerNames}.`));
    };

    const handleNotReachedClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'NotReached' }));
        dispatch(appendToCallItemNotes(`Unable to reach ${memberName} to confirm the ${eventTime} appointment with ${providerNames}.`));
    };

    const handleSkippedClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'Skipped' }));
        dispatch(
            appendToCallItemNotes(
                `Skipped confirmation of ${memberName}'s ${eventTime} appointment with ${providerNames} skipped due to no phone number provided.`
            )
        );
    };

    const handleRescheduleClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'ConfirmedByCalendar', LastContact: new Date() }));
        dispatch(appendToCallItemNotes(`Rescheduled ${memberName}'s ${eventTime} appointment with ${providerNames}.`));
        dispatch(setIsRescheduleModalOpen(true));
    };

    const handleNotRescheduleClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'Not Rescheduled' }));
        dispatch(appendToCallItemNotes(`Attempted to call ${member?.LastName}, ${member?.FirstName} at ${phoneFormat(callNumber)}`));
    };

    const handleTxtClick = () => {
        dispatch(setPropertiesOnSelectedCallItem({ CallStatus: 'Text Sent', LastContact: new Date() }));
        const provider = activeProviders.find((p) => p.Id === associatedEvent.ProviderIds[0]);
        const providerRole = provider.Role ?? '';
        const providerName = provider.FullName;
        navigator.clipboard
            .writeText(
                `Hello, thank you for being our member at Catalytic Health Partners! ` +
                    `We look forward to assisting you. This is a reminder of your upcoming appointment with your ` +
                    `(${providerRole}) ${providerName}, ` +
                    `on ${associatedEvent.AppointmentDateTime.toLocaleDateString()}. Please confirm this appointment by texting back Yes`
            )
            .then(
                function () {
                    console.log('Async: Copying to clipboard was successful!');
                },
                function (err) {
                    console.error('Async: Could not copy text: ', err);
                }
            );
    };

    return (
        <Grid item lg={12}>
            <Grid container spacing={1} direction={'row'} style={{ marginBottom: '.5em' }}>
                <Grid item lg={12}>
                    {currentMemberPhones.length > 0 && (
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="number-select-label">Select A Number</InputLabel>
                            <Select defaultValue={callNumber} value={callNumber} labelId="number-select-label" id="number-select" onChange={handleNumberChange}>
                                {currentMemberPhones.map((phone, i) => (
                                    <MenuItem value={phone.number} key={phone.number + i} data-textable={phone.textable}>
                                        {phone.display}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={1} direction={'row'} style={{ marginBottom: '.5em' }}>
                <Grid item lg={6}>
                    <a title="Call" href={`tel:+1${callNumber}`} style={{ textDecoration: 'none' }}>
                        <CallBtn className="button-panel-btn" onClick={handleCallClick} disabled={!member?.Phones}>
                            Call
                            <i style={{ fontSize: 21, marginLeft: '1em' }} className="fa fa-phone" aria-hidden="true"></i>
                        </CallBtn>
                    </a>
                </Grid>
                {mode === OfficeSchedulerModeEnum.Confirmations && (
                    <Grid item lg={6}>
                        <ConfirmBtn
                            className="button-panel-btn"
                            disabled={!selectedCallItem.CallAttempted || selectedCallItem.CallStatus !== null}
                            onClick={handleConfirmClick}
                        >
                            Confirm
                        </ConfirmBtn>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={1} direction={'row'} style={{ marginBottom: '.5em' }}>
                <Grid item lg={6}>
                    <NotReachedBtn
                        className="button-panel-btn"
                        disabled={!selectedCallItem.CallAttempted || selectedCallItem.CallStatus !== null}
                        onClick={handleNotReachedClick}
                    >
                        Not Reached
                    </NotReachedBtn>
                </Grid>
                {mode === OfficeSchedulerModeEnum.Confirmations && (
                    <Grid item lg={6}>
                        <CancelBtn
                            className="button-panel-btn"
                            disabled={!selectedCallItem.CallAttempted || selectedCallItem.CallStatus !== null}
                            onClick={handleCancelledClick}
                        >
                            Cancel
                        </CancelBtn>
                    </Grid>
                )}
                {mode === OfficeSchedulerModeEnum.Rescheduling && (
                    <Grid item lg={6}>
                        <CancelBtn
                            className="button-panel-btn"
                            disabled={!selectedCallItem.CallAttempted || selectedCallItem.CallStatus !== null}
                            onClick={handleNotRescheduleClick}
                        >
                            Do Not Reschedule
                        </CancelBtn>
                    </Grid>
                )}
                {(!member?.Phones || member?.Phones?.length === 0) && (
                    <Grid item lg={6}>
                        <SkipBtn className="button-panel-btn" onClick={handleSkippedClick}>
                            Skip
                        </SkipBtn>
                    </Grid>
                )}
                {mode === OfficeSchedulerModeEnum.Confirmations && textable && (
                    <Grid item lg={6}>
                        <CopyTextMsgBtn className="button-panel-btn" onClick={handleTxtClick}>
                            Copy Text
                        </CopyTextMsgBtn>
                    </Grid>
                )}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RescheduleBtn
                        className="button-panel-btn"
                        disabled={!selectedCallItem.CallAttempted || selectedCallItem.CallStatus !== null}
                        onClick={handleRescheduleClick}
                    >
                        Reschedule
                    </RescheduleBtn>
                </Grid>
                {mode === OfficeSchedulerModeEnum.Confirmations && (
                    <>
                        <Grid item lg={12}>
                            <Tooltip
                                title="This will disable confirming or cancelling normally. Changes must be performed through the calendar."
                                placement="top"
                            >
                                <ConfirmBtn disabled={!selectedCallItem.CallAttempted} className="button-panel-btn" onClick={handleConfirmedByCalendarClick}>
                                    Confirm Using Member Calendar
                                </ConfirmBtn>
                            </Tooltip>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default SchedulingButtonsPane;
