import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import Map from './Map';
import {
    setAppointmentMaps,
    setEndDate,
    setStartDate,
    setSelectedProviderId,
    setSelectedAppointmentStatus,
    setUseHomeAddress,
    setHasPermission,
    fetchAppointmentMaps,
} from '../../store/appointmentMap.slice';
import { getActiveProviders } from '../../store/provider.slice';
import { localDateFormat, localTimeFormat } from '../../utils/timeFormat';
import Loading from '../Shared/Loading';
import { SELECTED_PROVIDER_ID, appointmentStatusEnums } from '../../utils/constants';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import _ from 'lodash';
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Authorization from '../../utils/Authorization';

const classes = {
    addressRow: {
        borderBottom: '1px solid #ddd',
        marginBottom: '8px',
    },
};

const AppiontmentMap = () => {
    const dispatch = useDispatch();
    const authData = new Authorization();
    const currentProviderId: string = authData.UserId;
    const { appointmentMaps, endDate, hasPermission, selectedAppointmentStatus, startDate, selectedProviderId, useHomeAddress } = useSelector(
        (state: RootState) => state.appointmentMap
    );
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [currentProviderRole, setCurrentProviderRole] = useState<string>('');

    const handleStartDateChange = (date: Date) => {
        dispatch(setStartDate(date));
    };

    const handleEndDateChange = (date: Date) => {
        dispatch(setEndDate(date));
    };

    const download = () => {
        //set CSV header
        var downloadAppts = [['(In-Person) Appts', 'Leaving From', 'Address', 'Going To', 'Next Address', 'Miles']];
        appointmentMaps.AppointmentMapDownloadDTOs.forEach((m) => {
            let clone = _.cloneDeep(m);
            clone.LeavingFrom = m.LeavingFrom ? m.LeavingFrom.replaceAll(',', ' ').replaceAll('#', '') : '';
            clone.GoingTo = m.GoingTo ? m.GoingTo.replaceAll(',', ' ').replaceAll('#', '') : '';
            clone.Address = m.Address ? m.Address.replaceAll(',', ' ').replaceAll('#', '') : m.Address;
            clone.NextAddress = m.NextAddress ? m.NextAddress.replaceAll(',', ' ').replaceAll('#', '') : m.NextAddress;
            downloadAppts.push([
                localDateFormat(new Date(clone.ApptDateTime)) + ' ' + localTimeFormat(new Date(clone.ApptDateTime)),
                clone.LeavingFrom,
                clone.Address,
                clone.GoingTo,
                clone.NextAddress ? clone.NextAddress : '',
                clone.Miles,
            ]);
        });

        let csvContent = 'data:text/csv;charset=utf-8,' + downloadAppts.map((e) => e.join(',')).join('\n');
        var hiddenElement = document.createElement('a');
        hiddenElement.href = encodeURI(csvContent);
        hiddenElement.target = '_blank';

        for (let provider of activeProviders) {
            if (provider.Id === selectedProviderId) {
                console.log(provider);
                hiddenElement.download = provider.FullName.replaceAll(' ', '') + '.csv';
            }
        }
        hiddenElement.click();
    };

    useEffect(() => {
        dispatch(getActiveProviders(false));

        let storedId = localStorage.getItem(SELECTED_PROVIDER_ID);
        if (window.location.href.indexOf('schedules') > -1 && storedId && storedId.length > 0) {
            dispatch(setSelectedProviderId(localStorage.getItem(SELECTED_PROVIDER_ID)));
        } else {
            dispatch(setSelectedProviderId(currentProviderId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProviderId]);

    useEffect(() => {
        setCurrentProviderRole(authData.Role);
        dispatch(setHasPermission(authData.Permissions.includes('Distance Calculation Options')));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProviderId, activeProviders]);

    return (
        <div id="mapContainer" className="mt-2">
            {<Loading message={'Loading...'} />}
            <Box style={{ padding: '16px' }} display="inline">
                <Box display="inline" style={{ marginRight: '16px' }}>
                    <FormControl style={{ width: '300px' }}>
                        <InputLabel id="demo-simple-select-label">Select Provider</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Provider"
                            value={selectedProviderId}
                            onChange={(e) => {
                                dispatch(setSelectedProviderId(e.target.value as string));
                            }}
                        >
                            <MenuItem value="">
                                <em>Select Provider</em>
                            </MenuItem>
                            {activeProviders.map((provider, index) => {
                                return (
                                    <MenuItem key={index} value={provider.Id}>
                                        {provider.LastName + ', ' + provider.FirstName + ' (' + provider.Role.RoleName + ')'}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box display="inline" style={{ marginRight: '16px' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            OpenPickerButtonProps={{
                                'aria-label': 'change date',
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box display="inline" style={{ marginRight: '16px' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            OpenPickerButtonProps={{
                                'aria-label': 'change date',
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box display="inline" style={{ marginRight: '16px', marginTop: '8px', float: 'right' }}>
                    <Button
                        id="search-button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            dispatch(fetchAppointmentMaps());
                        }}
                    >
                        Search
                    </Button>
                </Box>
            </Box>
            <Grid style={{ padding: '16px' }} container spacing={1}>
                <Grid item md={5} container>
                    <Grid item md={12} container>
                        <Grid item md={2}>
                            <Typography style={{ fontWeight: 'bold' }}>Exclude Appt</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography style={{ fontWeight: 'bold' }}>Member Name</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography style={{ fontWeight: 'bold' }}>(In-Person) Appts</Typography>
                        </Grid>
                        <Grid item md={6} style={{ paddingLeft: '8px' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Address</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={7}>
                    <Grid item md={12} container>
                        <Grid item md={3}>
                            {currentProviderRole === 'CEO' || currentProviderRole === 'Office Manager' || currentProviderRole === 'Scheduling' ? (
                                <div style={{ display: 'inline-block' }}>
                                    <Typography style={{ fontWeight: 'bold', display: 'inline-block' }}>
                                        Approximate Miles ({appointmentMaps.Distance})
                                    </Typography>
                                    <CloudDownloadIcon style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={download} />
                                </div>
                            ) : null}
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={7} style={{ textAlign: 'right' }}>
                            {hasPermission ? (
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={useHomeAddress}
                                                    onChange={(e) => {
                                                        dispatch(setUseHomeAddress(e.target.checked));
                                                    }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Use Home Address"
                                        />
                                    </FormControl>
                                    <FormControl style={{ minWidth: '150px' }}>
                                        <InputLabel>Appointment Status</InputLabel>
                                        <Select
                                            label="Appointment Status"
                                            value={selectedAppointmentStatus}
                                            multiple
                                            onChange={(e) => {
                                                dispatch(setSelectedAppointmentStatus(e.target.value as number[]));
                                            }}
                                            onClose={() => {
                                                //dispatch(fetchMembers());
                                            }}
                                        >
                                            <MenuItem value={'-1'}>Include All</MenuItem>
                                            {appointmentStatusEnums.map((status) => {
                                                return (
                                                    <MenuItem key={status.value} value={status.value}>
                                                        {status.text}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: '16px' }} container spacing={1}>
                <Grid item md={5} container style={{ height: '50px' }}>
                    {appointmentMaps.Appointments?.map((apptMap, index) => {
                        return (
                            <Grid key={index} item md={12} container sx={classes.addressRow}>
                                <Grid item md={2}>
                                    <Checkbox
                                        checked={apptMap.Checked}
                                        onChange={(event) => {
                                            let updatedAppts = _.cloneDeep(appointmentMaps);
                                            let apptMapUpdate = updatedAppts.Appointments?.find((x) => x.Id === apptMap.Id);
                                            apptMapUpdate!.Checked = event.target.checked;
                                            dispatch(setAppointmentMaps(updatedAppts));
                                        }}
                                    ></Checkbox>
                                </Grid>
                                <Grid item md={2}>
                                    <Typography>{'(' + (index + 1) + ') ' + apptMap.MemberName}</Typography>
                                </Grid>
                                <Grid item md={2}>
                                    <Typography>{localDateFormat(new Date(apptMap.SortDate)) + ' '}</Typography>
                                    <Typography style={{ fontWeight: 'bold' }}>{localTimeFormat(new Date(apptMap.startDate))}</Typography>
                                </Grid>
                                <Grid item md={6} style={{ paddingLeft: '8px' }}>
                                    <a rel="noreferrer" target="_blank" href={'https://www.google.com/maps/dir/Current+Location/' + apptMap.address}>
                                        {apptMap.address}
                                    </a>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item md={7}>
                    <Grid item>
                        <Map />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default AppiontmentMap;
