import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import { isDefaultDate } from '../../../utils/common';
import moment from 'moment';
import { parentUrl } from '../../../utils/constants';
import { setShowPreviousCharts } from '../../../store/memberDemographicsDetails.slice';
import { useEffect } from 'react';
import { fetchPopulationList } from '../../../store/adminMembers.slice';

const MemberPreviousChartsModal = () => {
    const { previousMemberCharts, showPreviousCharts } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const dispatch = useDispatch();
    const closePreviousChartsHandler = () => {
        dispatch(setShowPreviousCharts(false));
    };

    const modalSize = previousMemberCharts.length > 0 ? 'lg' : 'sm';

    const columns: GridColDef[] = [
        {
            field: 'Mem_AHCCCS_ID',
            headerName: 'AHCCCS ID',
            minWidth: 140,
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 140,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 140,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : moment(params.row.DOB).format('MM/DD/YYYY')}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => `${MemberStatusEnum[params.row.Status] || ''}`,
        },
        {
            field: 'Population',
            headerName: 'Population',
            minWidth: 160,

            valueGetter: (params: GridValueGetterParams) => `${population.find((el) => el.PopulationNumber === params.row.Population)?.PopulationName || ''}`,
        },
        {
            field: 'PrimaryHealthPlan',
            headerName: 'Primary Health Plan',
            minWidth: 140,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.PrimaryHealthPlan?.Name || ''}`,
        },
    ];

    useEffect(() => {
        dispatch(fetchPopulationList());
    }, [dispatch]);

    return (
        <Dialog open={showPreviousCharts} onClose={closePreviousChartsHandler} fullWidth maxWidth={modalSize}>
            <DialogTitle>Previous Member Charts</DialogTitle>
            <DialogContent>
                {previousMemberCharts.length > 0 ? (
                    <div style={{ width: '100%', height: '47vh' }}>
                        <DataGrid
                            rows={previousMemberCharts || []}
                            columns={columns}
                            onRowClick={(params) => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                            }}
                            getRowHeight={() => 'auto'}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row.Id}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" p={5}>
                        <Typography variant="body1">No previous charts found</Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button sx={{ minWidth: '120px' }} variant="contained" onClick={closePreviousChartsHandler} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MemberPreviousChartsModal;
