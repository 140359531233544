import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { IFormikAutocompleteControlledProps } from './interfaces/IFormikAutocompleteControlledProps';

const FormikAutocompleteControlled: React.FC<IFormikAutocompleteControlledProps> = ({ ...props }) => {
    const [field, meta] = useField(props);
    let helperText = props.helperText;
    if (Boolean(meta.error)) {
        helperText = meta.error;
    } else if (!props.loading && props.helperText) {
        helperText = props.helperText;
    }
    return (
        <Autocomplete
            onChange={(e, value) => field.onChange({ target: { name: field.name, value } })}
            {...field}
            {...props}
            fullWidth
            renderInput={(params) => (
                <TextField
                    label={props.label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <React.Fragment>
                                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.startAdornment}
                            </React.Fragment>
                        ),
                    }}
                    {...params}
                    error={Boolean(meta.error)}
                    helperText={helperText}
                    required={props?.required}
                />
            )}
        />
    );
};

export default FormikAutocompleteControlled;
