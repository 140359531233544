import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { CalendarEventData } from '../../Calendar/types/CalendarEventData';
import SwapSelectionCheckbox from './SwapSelectionCheckbox';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleAllProviderCheckboxes } from '../../../store/providerRotation.slice';

export interface ISwapListProps {
    providerName: string;
    eventList: CalendarEventData[];
}

const SwapList = (props: ISwapListProps) => {
    const dispatch = useDispatch();
    const { providerName, eventList } = props;
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const handleToggleChange = () => {
        setAllChecked(!allChecked);
        dispatch(toggleAllProviderCheckboxes(providerName, !allChecked));
    };
    return (
        <Stack
            justifyContent={'flex-start'}
            alignItems={'center'}
            spacing={1}
            style={{
                flexGrow: 1,
                overflowY: 'auto',
                width: '100%',
                minWidth: '300px',
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Typography variant={'body1'} style={{ textAlign: 'center' }}>
                    {providerName}
                </Typography>
                <FormControlLabel control={<Checkbox checked={allChecked} onChange={handleToggleChange} />} label="Toggle All" style={{ marginLeft: 'auto' }} />
            </Box>
            {eventList.map((data) => (
                <Box key={data.id} style={{ width: '100%' }}>
                    {' '}
                    <SwapSelectionCheckbox appointment={data} />
                </Box>
            ))}
        </Stack>
    );
};

export default SwapList;
