import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InventoryIcon from '@mui/icons-material/Inventory';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PendingIcon from '@mui/icons-material/Pending';
import React from 'react';

export interface IStatusIconProps {
    status: number;
}

const StatusIcon: React.FC<IStatusIconProps> = ({ status }) => {
    let icon = undefined;
    switch (status) {
        case 1: {
            icon = <NewReleasesIcon fontSize="small"></NewReleasesIcon>;
            break;
        }
        case 2: {
            icon = <PendingIcon fontSize="small"></PendingIcon>;
            break;
        }
        case 3: {
            icon = <CheckCircleIcon fontSize="small"></CheckCircleIcon>;
            break;
        }
        case 4: {
            icon = <InventoryIcon fontSize="small"></InventoryIcon>;
        }
    }
    return icon;
};

export default React.memo(StatusIcon);
