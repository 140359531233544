export enum OnboardingMeansOfEngagementEnum {
    OutreachCall = 1,
    OutreachCallDescription = 'Outreach Call',
    Letter = 2,
    LetterDescription = 'Letter',
    Email = 3,
    EmailDescription = 'Email',
    Other = 4,
    OtherDescription = 'Other',
}
