import { Alert, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { IHoursOfOperation } from '../../Resources/interfaces/IHoursOfOperation';
import FormikOperatingDay from './FormikOperatingDay';

const FormikHoursOfOperation: React.FC<IHoursOfOperation & { isEditing: boolean }> = (props) => {
    const { isEditing, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } = props;

    const days = useMemo(() => {
        const usableDays = { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday };
        const alert = (
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Alert severity="info">All times displayed are assumed to be in Arizona Time.</Alert>
            </Grid>
        );
        return (
            <Grid container spacing={2} justifyContent="space-evenly" alignItems="space-evenly">
                {isEditing && alert}
                {React.Children.toArray(
                    Object.entries(usableDays).map(([name, Day]) => {
                        return (
                            <Grid item lg={isEditing ? 3 : 1} md={isEditing ? 3 : 1} sm={6} xs={12}>
                                <FormikOperatingDay DayName={name} {...Day} isEditing={isEditing} />
                            </Grid>
                        );
                    })
                )}
            </Grid>
        );
    }, [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday, isEditing]);

    return days;
};

export default React.memo(FormikHoursOfOperation);
