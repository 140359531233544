import React, { useState } from 'react';
import { apiString as api } from '../utils/constants';
import axios from 'axios';

const PopulationContext = React.createContext();

export const PopulationProvider = ({ children }) => {
    const [population, setPopulation] = useState(0);
    const [populations, setPopulations] = useState([]);
    const [analyticPopulation, setAnalyticPopulation] = useState(0);
    const [analyticPopulations, setAnalyticPopulations] = useState([]);
    const [isPopulationFetched, setIsPopulationFetched] = useState(false);

    const getPopulations = async () => {
        try {
            const response = await axios.get(`${api}/population/getactivepopulation`);
            if (response.status === 200) {
                var popInfo = response.data;
                var popList = [];
                for (var r in popInfo) {
                    if (popInfo.hasOwnProperty(r)) {
                        var pop = { value: parseInt(r), name: popInfo[r] };
                        popList.push(pop);
                    }
                }
                setPopulations(popList);
                setIsPopulationFetched(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getAnalyticPopulations = async () => {
        try {
            const response = await axios.get(`${api}/population/getanalyticpopulation`);
            if (response.status === 200) {
                var popInfo = response.data;
                var popList = [];
                for (var r in popInfo) {
                    if (popInfo.hasOwnProperty(r)) {
                        var pop = { value: parseInt(r), name: popInfo[r] };
                        popList.push(pop);
                    }
                }
                setAnalyticPopulations(popList);
                setIsPopulationFetched(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const value = {
        isPopulationFetched,
        population,
        setPopulation,
        populations,
        getPopulations,
        setPopulations,
        analyticPopulations,
        setAnalyticPopulations,
        getAnalyticPopulations,
        analyticPopulation,
        setAnalyticPopulation,
    };

    return <PopulationContext.Provider value={value}>{children}</PopulationContext.Provider>;
};

export const usePopulationContext = () => React.useContext(PopulationContext);
