import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const generateEncounterBillingReport = createSlice({
    name: 'generateEncounterBillingReport',
    initialState: {
        encounterBillingStart: new Date(),
        encounterBillingEnd: new Date(),
        encounterBillingHealthPlan: 'null',
        encounterBillingProvider: '-1',
        rowData: [],
        modalVisibilty: false,
        encounterServices: [],
        encounterName: '',
    },
    reducers: {
        _setEncounterBillingStart(state, action) {
            state.encounterBillingStart = action.payload;
        },
        _setEncounterBillingEnd(state, action) {
            state.encounterBillingEnd = action.payload;
        },
        _setEncounterBillingHealthPlan(state, action) {
            state.encounterBillingHealthPlan = action.payload;
        },
        _setEncounterBillingProvider(state, action) {
            state.encounterBillingProvider = action.payload;
        },
        _setRowData(state, action) {
            state.rowData = action.payload;
        },
        _setModalVisibility(state, action) {
            state.modalVisibilty = action.payload;
        },
        _setEncounterServices(state, action) {
            state.encounterServices = action.payload;
        },
        _setEncounterName(state, action) {
            state.encounterName = action.payload;
        },
    },
});

const {
    _setEncounterBillingStart,
    _setEncounterBillingEnd,
    _setEncounterBillingHealthPlan,
    _setEncounterBillingProvider,
    _setRowData,
    _setModalVisibility,
    _setEncounterServices,
    _setEncounterName,
} = generateEncounterBillingReport.actions;

export const setEncounterBillingStart =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterBillingStart(value));
    };

export const setEncounterBillingEnd =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterBillingEnd(value));
    };

export const setEncounterBillingHealthPlan =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterBillingHealthPlan(value));
    };

export const setEncounterBillingProvider =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterBillingProvider(value));
    };

export const setRowData =
    (value: []): AppThunk =>
    (dispatch) => {
        dispatch(_setRowData(value));
    };

export const setModalVisibility =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setModalVisibility(value));
    };

export const setEncounterServices =
    (value: []): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterServices(value));
    };

export const setEncounterName =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterName(value));
    };

export const fetchEncounterBillingReport =
    (encounterBillingStart: Date, encounterBillingEnd: Date, healthPlanIdValue: string, providerIdValue: string, showInActive: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/encounterbillingreport/generateencounterbillings?start=${dateFormatter(encounterBillingStart)}&end=${dateFormatter(
                encounterBillingEnd
            )}&healthPlanId=${healthPlanIdValue}&providerId=${providerIdValue !== '-1' ? providerIdValue : ''}&includeInActiveProviders=${showInActive}`
        );
        dispatch(setIsLoading(false));
        dispatch(setRowData(response.data.Encounters));
    };

export const exportEncounterBilling =
    (encounterBillingStart: Date, encounterBillingEnd: Date, healthPlanIdValue: string, providerIdValue: string, showInActive: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/encounterbillingreport/exportencounterbillingstoxlsx?start=${dateFormatter(encounterBillingStart)}&end=${dateFormatter(
                encounterBillingEnd
            )}&healthPlanId=${healthPlanIdValue}&providerId=${providerIdValue !== '-1' ? providerIdValue : ''}&includeInActiveProviders=${showInActive}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

// Not sure if this button actually submits data.. after submitted, a green model saying submission successful pops up?
export const submitToCH =
    (encounterBillingStart: Date, encounterBillingEnd: Date, healthPlanIdValue: string, providerIdValue: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.post(
            `${API_URL}/encounterbillingreport/generate837p?start=${dateFormatter(encounterBillingStart)}&end=${dateFormatter(
                encounterBillingEnd
            )}&healthPlanId=${healthPlanIdValue}&providerId=${providerIdValue !== '-1' ? providerIdValue : ''}`
        );
        console.log('submitToCH response: ', response);
    };

export default generateEncounterBillingReport.reducer;
