import React, { useState } from 'react';

const DataFormContext = React.createContext();

export const DataFormProvider = ({ children }) => {
    const [dataFile, setDataFile] = useState({});
    const [dataDetail, setDataDetail] = useState([]);

    const value = { dataFile, setDataFile, dataDetail, setDataDetail };

    return <DataFormContext.Provider value={value}>{children}</DataFormContext.Provider>;
};

export const useDataFormContext = () => React.useContext(DataFormContext);
