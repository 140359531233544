import { AllFilterTypeEnum } from '../../Enum/AllFilterTypeEnum';
import { MetricTypeEnum } from '../../Enum/MetricTypeEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IHedisMetricsView extends BaseEntity {
    TeamId: string;
    MetricType: MetricTypeEnum;
    Name: string;
    Percentage: number;
    Goal: number;
    IsMet: boolean;
    AllFilterType: AllFilterTypeEnum;
}

export default class HedisMetricsView implements IHedisMetricsView {
    Id: string;
    TeamId: string;
    MetricType: MetricTypeEnum;
    Name: string;
    Percentage: number;
    Goal: number;
    IsMet: boolean;
    AllFilterType: AllFilterTypeEnum;

    constructor() {
        this.Id = '';
        this.TeamId = '';
        this.MetricType = 0;
        this.Name = '';
        this.Percentage = 0;
        this.Goal = 0;
        this.IsMet = false;
        this.AllFilterType = 0;
    }
}
