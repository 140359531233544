import { InfoOutlined } from '@mui/icons-material'
import { Button, IconButton, Tooltip } from '@mui/material'
import CalendarPdf from "../../assets/documents/CalendarSchedulingHelp.pdf"


export interface IHelpDocumentIconButtonProps {
    tooltipPlacement?: "left" | "right" | "top" | "bottom";
    fullButton?: boolean;
}

const HelpDocumentIconButton = (props: IHelpDocumentIconButtonProps) => {
    const { tooltipPlacement = 'bottom', fullButton } = props;
    const handleButtonClick = () => window.open(`${CalendarPdf}`, '_blank');
    let button =
        <Tooltip placement={tooltipPlacement} title={"Open Calendar and Scheduling Help Document"}>
            <IconButton color="primary" onClick={handleButtonClick}>
                <InfoOutlined />
            </IconButton>
        </Tooltip>

    if (fullButton) {
        button = <Button startIcon={<InfoOutlined />} color="primary" variant="outlined" onClick={handleButtonClick} sx={{ width: '100%' }}>
            Help Document
        </Button>
    }
    return button;
    
}

export default HelpDocumentIconButton