import { IRequestOrderByOption } from '../interfaces/IRequestOrderByOption';

export const requestOrderByOptionsMapping: {
    [key: string]: IRequestOrderByOption;
} = {
    Ascending: {
        value: -1,
        label: 'ASC',
    },
    Descending: {
        value: 1,
        label: 'DESC',
    },
};

export const requestOrderByOptions = Object.values(requestOrderByOptionsMapping);
