import React from 'react';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const DeleteAppointmentConfirmationModal = (props: IBasicModalProps) => {
    const { open, onClose, onSuccess } = props;
    return (
        <Dialog open={open} sx={{ zIndex: 99999 }}>
            <DialogTitle>Are you sure you want to delete this appointment?</DialogTitle>
            <DialogActions>
                <Button variant="contained" color="error" onClick={onSuccess}>
                    Delete
                </Button>
                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteAppointmentConfirmationModal;
