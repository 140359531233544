import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { setDevices } from '../../../../store/memberDetail.slice';

const TelemonitoringDeviceRow = (params: any) => {
    const dispatch = useDispatch();
    const { devices } = useSelector((state: RootState) => state.memberDetail);

    const changeHasDevice = (event, checked) => {
        const newItem = { ...devices };
        for (let prop in newItem) {
            if (newItem[prop]?.Name === params.device.Name) {
                let update = { ...newItem[prop] };
                update.HasDevice = checked;

                newItem[prop] = update;
            }
        }
        dispatch(setDevices(newItem));
    };

    const changeSerialNumber = (event, value) => {
        const newItem = { ...devices };
        for (let prop in newItem) {
            if (newItem[prop]?.Name === params.device.Name) {
                let update = { ...newItem[prop] };
                update.SerialNumber = value;

                newItem[prop] = update;
            }
        }
        dispatch(setDevices(newItem));
    };

    const changeDeliveryDate = (newDate) => {
        const newItem = { ...devices };
        for (let prop in newItem) {
            if (newItem[prop]?.Name === params.device.Name) {
                let update = { ...newItem[prop] };
                update.DeliveryDate = newDate;

                newItem[prop] = update;
            }
        }
        dispatch(setDevices(newItem));
    };

    const changeRetrievalDate = (newDate) => {
        const newItem = { ...devices };
        for (let prop in newItem) {
            if (newItem[prop]?.Name === params.device.Name) {
                let update = { ...newItem[prop] };
                update.RetrievalDate = newDate;

                newItem[prop] = update;
            }
        }
        dispatch(setDevices(newItem));
    };

    return (
        <Grid container spacing={2} style={{ textAlign: 'left', marginBottom: '32px' }}>
            <Grid item xs={12 / 5}>
                <Typography>{params.device.Name}</Typography>
            </Grid>
            <Grid item xs={12 / 5}>
                <FormControlLabel
                    control={<Checkbox checked={params.device.HasDevice} />}
                    onChange={(e: any) => changeHasDevice(e, e.target.checked)}
                    label="Has Device"
                />
            </Grid>
            <Grid item xs={12 / 5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Delivery Date"
                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                        onChange={(e: any) => {
                            changeDeliveryDate(e);
                        }}
                        value={params.device.DeliveryDate}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12 / 5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Retrieval Date"
                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                        onChange={(e: any) => {
                            changeRetrievalDate(e);
                        }}
                        value={params.device.RetrievalDate}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12 / 5}>
                <TextField
                    id="outlined-basic"
                    fullWidth
                    label="Serial Number"
                    variant="outlined"
                    value={params.device.SerialNumber}
                    onChange={(e: any) => {
                        changeSerialNumber(e, e.target.value);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TelemonitoringDeviceRow;
