export enum PartTwoDownloadEnum {
    NoneDescription = 'Cancel Download',
    None = 0,
    VerifiedDescription = 'Consent Verified, Download Anyway',
    Verified = 1,
    InternalUseDescription = 'Download for Internal Use Only',
    InternalUse = 2,
    WithoutPartTwoDataDescription = ' Download PDF without Part 2 data',
    WithoutPartTwoData = 3,
    WithPartTwoDataDescription = ' Download PDF with Part 2 data',
    WithPartTwoData = 3,
}
