import { DiagnosisDraft } from '../DocumentEngagements/DiagnosisDraft.model';

export default interface IMemberEncounterDraft {
    Id: string;
    CptCodeId: string;
    CptCode: string;
}

export class MemberEncounterDraft implements IMemberEncounterDraft {
    Id: string;
    CptCodeId: string;
    CptCode: string;
    PrimaryDiagnosis: DiagnosisDraft;
    SecondaryDiagnosis: DiagnosisDraft;
    TertiaryDiagnosis: DiagnosisDraft;
    QuaternaryDiagnosis: DiagnosisDraft;
    AdditionalDiagnosis: DiagnosisDraft[];
    DiagnosisList: DiagnosisDraft[];
    IsSummaryNote: boolean;
    CptCodeOder: number;
    CategoryGroupId: string;
    MemberId: string;
    Units: number;
    tempId: string;
    EncounterPlace: number;
    ServiceDate: string;
    TraceId: string;
    IsNotEncounterable: boolean;

    constructor() {
        this.Id = '';
        this.CptCodeId = '';
        this.CptCode = '';
    }
}
