import RichTextEditor from '@mantine/rte';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Alert,
    Box,
    Button,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Children, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { RootState } from '../../../reducers';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import {
    getAgeLabel,
    getRequestZCode,
    saveNewComment,
    setSelectedRequestId,
    toggleRequestResolutionModal,
    updateRequestStatus,
} from '../../../store/requests.slice';
import { summaryNoteType } from '../../../utils/assessments';
import { currencyFormatter, ProviderId } from '../../../utils/constants';
import SignOffWrapper from '../../SignOffWrapper/SignOffWrapper';
import { IRequestResolutionModalProps } from '../interfaces/IRequestResolutionModalProps';
import { requestStatusMapping } from '../mappings/RequestStatusMapping';
import AddResourceItemModal from './AddResourceItemModal';
import ResourceItemRow from './ResourceItemRow';

const DEFAULT_CPT_CODE = 'T1016' as const;

const RequestResolutionModal: React.FC<IRequestResolutionModalProps> = (props) => {
    const { isAssessment } = props;
    const dispatch = useDispatch();
    const { showRequestResolutionModal: requestResolutionModalOpen, requests, selectedRequest } = useSelector((state: RootState) => state.requests);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const [requestStartDate, setRequestStartDate] = useState<Date>(new Date());
    const [requestResolutionDate, setRequestResolutionDate] = useState<Date>(new Date());
    const [requestCompleted, setRequestCompleted] = useState<boolean>(false);
    const [timeActive, setTimeActive] = useState<string>('');
    const [requestType, setRequestType] = useState<string>('');
    const [summaryNote, setSummaryNote] = useState<string>('');
    const [addResourceModalOpen, setAddResourceModalOpen] = useState<boolean>(false);
    const [collapsedIn, setCollapsedIn] = useState<boolean>(true);

    const toggleRequestModal = () => {
        dispatch(toggleRequestResolutionModal());
    };

    const handleRequestStatusChange = (newStatus: number) => {
        if (newStatus === requestStatusMapping.Completed.value && (selectedRequest.Status ?? 0) !== requestStatusMapping.Completed.value) {
            dispatch(updateRequestStatus(newStatus, selectedRequest.Id));
            dispatch(setSelectedRequestId(null));
            setRequestCompleted(true);
        } else {
            dispatch(updateRequestStatus(newStatus, selectedRequest.Id));
        }
    };

    const handleCompleteSummaryNoteForAssessment = async () => {
        const _zcode = selectedRequest.Zcode ?? (await getRequestZCode(selectedRequest.Id));
        if (summaryNote.replace(/<[^>]*>?/gm, '').trim().length !== 0) {
            let newEngagementDraft = { ...engagementDraft };
            if (!newEngagementDraft.SummaryNotes.find((note) => note.Zcode === _zcode)) {
                newEngagementDraft.SummaryNotes = [
                    ...newEngagementDraft.SummaryNotes,
                    {
                        Id: String(summaryNoteType.Request.value),
                        Note: summaryNote,
                        Zcode: _zcode,
                        Type: SummaryNoteTypeEnum.Request,
                        IsConfirm: true,
                        CptCode: DEFAULT_CPT_CODE,
                        CreatedOn: new Date(),
                        UpdatedOn: new Date(),
                        ReferenceIds: [selectedRequest.Id],
                        CreatedBy: ProviderId,
                        UpdatedBy: ProviderId,
                        IsNotEncounterable: selectedRequest.IsNotEncounterable ?? false,
                    } as any,
                ];
            } else {
                newEngagementDraft.SummaryNotes = newEngagementDraft.SummaryNotes.map((note) => {
                    if (note.Zcode === _zcode) {
                        return {
                            ...note,
                            Note: `${note.Note} \n Additional Request Note Added ${new Date().toLocaleString()}\n${summaryNote}`,
                        };
                    } else {
                        return note;
                    }
                });
            }

            dispatch(saveEngagementDraft(newEngagementDraft));
            dispatch(saveNewComment(summaryNote, selectedRequest.Id, isAssessment));
            handleRequestStatusChange(requestStatusMapping.Completed.value);
            setSummaryNote('');
            toggleRequestModal();
        }
    };

    useEffect(() => {
        if (requests?.length && selectedRequest?.Id) {
            setRequestCompleted(selectedRequest.Status === requestStatusMapping.Completed.value);
            setRequestStartDate(new Date(selectedRequest?.CreatedOn));
            setTimeActive(`${getAgeLabel(new Date(selectedRequest?.CreatedOn))} days`);
            setRequestType(
                selectedRequest.SubRequestTypeLabel
                    ? `${selectedRequest.RequestTypeLabel}: ${selectedRequest.SubRequestTypeLabel}`
                    : selectedRequest.RequestTypeLabel
            );
        }
    }, [requests, selectedRequest]);

    const requestCompletedPanel = (
        <Box>
            <Alert severity="success">Request Completed</Alert>
        </Box>
    );

    const totalResourceCost = useMemo(
        () => selectedRequest?.ResourceServiceItems.reduce((a, b) => a + b.PayerList.reduce((a, b) => a + b.Amount, 0), 0),
        [selectedRequest?.ResourceServiceItems]
    );

    const requestResolution = (
        <>
            <DialogContent>
                <Grid container style={{ paddingTop: 10 }} justifyContent="flex-start" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <DatePicker
                                disabled
                                label="Request Start Date"
                                value={requestStartDate}
                                onChange={(date) => setRequestStartDate(new Date(date))}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <DatePicker
                                disabled
                                label="Request Resolution Date"
                                value={requestResolutionDate}
                                onChange={(date) => setRequestResolutionDate(new Date(date))}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                    </LocalizationProvider>

                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled
                            label="Time Request Spent Active"
                            variant="outlined"
                            value={timeActive}
                            onChange={(event) => {
                                setTimeActive(event.target?.value);
                            }}
                        />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled
                            label="Request Type"
                            variant="outlined"
                            value={requestType}
                            onChange={(event) => {
                                setRequestType(event.target?.value);
                            }}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {selectedRequest?.Id?.length > 0 ? (
                            <AddResourceItemModal open={addResourceModalOpen} onClose={() => setAddResourceModalOpen(false)} request={selectedRequest} />
                        ) : null}
                        <Divider>
                            <Tooltip title="Click to add resources to the list" arrow>
                                <Chip
                                    label="Resources List"
                                    clickable
                                    color="primary"
                                    icon={<AddCircleOutline />}
                                    onClick={() => setAddResourceModalOpen(true)}
                                />
                            </Tooltip>
                        </Divider>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Paper variant="outlined" style={{ padding: 5, marginTop: 10 }}>
                                    {selectedRequest?.ResourceServiceItems?.length > 0 ? (
                                        <List dense={true}>
                                            <ListItem divider={collapsedIn}>
                                                {collapsedIn ? (
                                                    <IconButton onClick={() => setCollapsedIn(!collapsedIn)}>
                                                        <ExpandMore />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => setCollapsedIn(!collapsedIn)}>
                                                        <ExpandLess />
                                                    </IconButton>
                                                )}
                                                <Grid container justifyContent={'space-evenly'} alignItems="center">
                                                    <Grid item lg md sm xs>
                                                        <Typography variant="subtitle2">{`Total Contributions Toward Request: ${currencyFormatter.format(
                                                            totalResourceCost
                                                        )}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <Collapse in={collapsedIn}>
                                                <List component={'div'} disablePadding>
                                                    {Children.toArray(
                                                        selectedRequest.ResourceServiceItems.map((resource, index) => (
                                                            <ResourceItemRow
                                                                {...resource}
                                                                divider={index !== selectedRequest.ResourceServiceItems.length - 1}
                                                            />
                                                        ))
                                                    )}
                                                </List>
                                            </Collapse>
                                        </List>
                                    ) : (
                                        <Typography variant="h6" style={{ textAlign: 'center' }}>
                                            No Resources Added
                                        </Typography>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!requestCompleted ? null : (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {requestCompletedPanel}
                        </Grid>
                    )}
                    {isAssessment ? null : (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {selectedRequest?.RequestFor?.Id?.length > 0 && (
                                <SignOffWrapper
                                    memberId={selectedRequest.RequestFor.Id}
                                    requestId={selectedRequest.Id}
                                    zcode={selectedRequest.Zcode}
                                    finalizeSignOff={() => {
                                        handleRequestStatusChange(requestStatusMapping.Completed.value);
                                    }}
                                />
                            )}
                        </Grid>
                    )}
                    {!isAssessment || requestCompleted ? null : (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography style={{ textAlign: 'center' }}>Assessment/Request Summary Note</Typography>
                            <RichTextEditor value={summaryNote} onChange={(value) => setSummaryNote(value)} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                {!isAssessment || summaryNote.replace(/<[^>]*>?/gm, '').trim().length !== 0 || requestCompleted ? null : (
                    <Typography variant="caption" color="error" style={{ paddingRight: 20 }}>
                        A note is required before saving.
                    </Typography>
                )}
                {!isAssessment || requestCompleted ? null : (
                    <Button variant="contained" onClick={handleCompleteSummaryNoteForAssessment} color="success">
                        Complete Request and Save Note
                    </Button>
                )}
                <Button variant="contained" onClick={toggleRequestModal}>
                    {!requestCompleted ? 'Cancel' : 'Close'}
                </Button>
            </DialogActions>
        </>
    );

    return (
        <Dialog
            open={requestResolutionModalOpen}
            fullWidth
            maxWidth={false}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Request Resolution</DialogTitle>
            {requestResolution}
        </Dialog>
    );
};

export default RequestResolutionModal;
