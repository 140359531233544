import { useCallback, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { configureColumnComparisonChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchSubmittedClaimsChart, MenuProps } from '../../Widgets/WidgetService';
import EncounterSummary from '../../../Models/Widget/EncounterSummary.model';
import Authorization from '../../../utils/Authorization';
import '../Widgets.css';

const SubmittedClaimSummary = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState<string>('All');
    const [selectedPlan, setSelectedPlan] = useState<string>('All');
    const [encounterSummary, setEncounterSummary] = useState<EncounterSummary>();
    const [encounterOptions, setEncounterOptions] = useState({});
    const [isEncounterGraphShow, setIsEncounterGraphShow] = useState<boolean>(false);
    const { healthPlans } = useHealthPlanContext();
    const [selectedTeam, setSelectedTeam] = useState('All');
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [showInActiveTeams, setShowInActiveTeams] = useState<boolean>(false);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isEncounterGraphShow) {
            setEncounterOptions(
                configureColumnComparisonChart(
                    'Submitted Claim Summary',
                    'Claims Count',
                    'Day',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0]
                )
            );
        }

        if (Boolean(authData.TeamId)) {
            setSelectedTeam(authData.TeamId);
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isEncounterGraphShow, authData.TeamId]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        const changeFilter = async () => {
            const data = await fetchSubmittedClaimsChart(selectedProvider, selectedTeam, selectedPlan, showInActiveTeams, showInActiveProviders);
            setEncounterSummary(data);
        };
        setIsEncounterGraphShow(false);
        setEncounterOptions(
            configureColumnComparisonChart(
                'Submitted Claim Summary',
                'Claims Count',
                'Day',
                'Count',
                [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                [0]
            )
        );
        changeFilter();
    }, [selectedProvider, selectedPlan, selectedTeam, showInActiveTeams, showInActiveProviders]);

    useEffect(() => {
        if (encounterSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let totClaimsValues = [];
            let submittedClaimsValues = [];
            for (var data in encounterSummary.Encounters) {
                let dataInfo = encounterSummary.Encounters[data];

                let percentage = (dataInfo.CleanClaimCount / dataInfo.EncounterCount) * 100;

                let d = {
                    y: dataInfo.CleanClaimCount,
                    name: Math.round(percentage),
                };
                submittedClaimsValues.push(d);

                let d1 = {
                    y: dataInfo.EncounterCount,
                    name: '',
                };
                totClaimsValues.push(d1);

                xAxisValues.push(dataInfo.Date);
            }
            yAxisValues.push({ name: 'Total Claims', data: totClaimsValues, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Submitted Claims', data: submittedClaimsValues, color: '#b0dbde', showInLegend: true });

            if (encounterSummary.Encounters.length === 0) {
                setEncounterOptions(
                    configureColumnComparisonChart(
                        'Submitted Claim Summary',
                        'Claims Count',
                        'Day',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setEncounterOptions(configureColumnComparisonChart('Encounter Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues));
            }
            setIsEncounterGraphShow(true);
        }
    }, [encounterSummary]);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider('All');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                            Submitted Claims Within 72 Hours
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">Claims submitted in last 72 hours</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={selectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((option) => {
                                    return (
                                        <MenuItem key={option.Id} value={option.Id} sx={{ color: option.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {`${option.LastName}, ${option.FirstName}`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select value={selectedTeam} label="Team" name="Team" onChange={(e) => setSelectedTeam(e.target.value)} MenuProps={MenuProps}>
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Health Plan</InputLabel>
                            <Select
                                value={selectedPlan}
                                label="Health Plan"
                                name="plan"
                                onChange={(e) => setSelectedPlan(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                {filteredHealthPlans.map((plan) => (
                                    <MenuItem key={plan.Id} value={plan.Id}>
                                        {plan.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isEncounterGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={encounterOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default SubmittedClaimSummary;
