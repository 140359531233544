import { Grid, Paper, Typography, IconButton, Tooltip, TextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { apiString as API_URL } from '../../../../utils/constants';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { parentUrl } from '../../../../utils/constants';
import WidgetLoading from '../../Shared/WidgetLoading';
import { localDateFormat } from '../../../../utils/timeFormat';
import { isDefaultDate } from '../../../../utils/common';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const HIEPatientPanelErrors = (props) => {
    const [patientPanelErrors, setPatientPanelErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const ref = useRef(null);

    const [downloadDate, setDownloadDate] = useState(new Date().toLocaleDateString());

    const handleClick = (event) => {
        setShow(!show);
    };

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'LastName',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'Address1',
            headerName: 'Address1',
            minWidth: 160,
        },
        {
            field: 'City',
            headerName: 'City',
            minWidth: 160,
        },
        {
            field: 'State',
            headerName: 'State',
            minWidth: 100,
        },
        {
            field: 'Zip',
            headerName: 'Zip Code',
            minWidth: 140,
        },
        {
            field: 'DownloadDate',
            headerName: 'Download Date',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => `${localDateFormat(new Date(params.row.DownloadDate))}`,
        },
    ];

    const getPatientPanelErrors = async (date: string) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/HIE/getpatientpanelerrors?date=${date}`);
            setPatientPanelErrors(response.data);

            setIsLoading(false);
        } catch (error) {
            setPatientPanelErrors([]);
            setIsLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        getPatientPanelErrors(downloadDate);
    }, [downloadDate]);

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                            Patient Panel Upload Error Results
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Displays list of members not found by Patient Panel Upload to Contexture. Lists are sorted by date the error list is
                                            download.
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select Download Date"
                            value={downloadDate}
                            onChange={(newValue) => setDownloadDate(new Date(newValue).toLocaleDateString())}
                            renderInput={(params) => <TextField margin="dense" {...params} />}
                        />
                    </LocalizationProvider>
                    <Grid item xs={12} marginTop={2} position="relative">
                        {isLoading && <WidgetLoading />}
                        <div style={{ width: '100%', height: '52vh' }}>
                            <DataGrid
                                onCellClick={(params: any) => {
                                    window.open(`${parentUrl}provider/members-list/member-chart/${params.row.UniquePatientID}/member-profile`, '_blank');
                                }}
                                rows={patientPanelErrors}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => Math.random()}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default HIEPatientPanelErrors;
