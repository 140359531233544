import { Eventcalendar, MbscCalendarEvent, Popup } from '@mobiscroll/react'; /* or import any other component */
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { Backdrop, Button, CircularProgress, Grid, Stack } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import { setShowMessage } from '../../../store/shared.slice';
import { appointmentStatusMap } from '../../../utils/mappings';
import SchedulerNotes from '../../SchedulerNotes/SchedulerNotes';
import MessageDisplay from '../../Shared/MessageDisplay';
import { CalendarLoadModeEnum } from '../Enums/CalendarLoadModeEnum';
import EventContent from '../EventContent';
import EventLabel from '../EventLabel';
import ExportCalendarButton from '../Export/ExportCalendarButton';
import HelpDocumentIconButton from '../HelpDocumentIconButton';
import CalendarStatusLegend from '../Legends/CalendarStatusLegend';
import LoadEventsDropdown from '../LoadByModeDropdown';
import CalendarEventEditModal from '../Modals/CalendarEventEditModal';
import { cancelRecurringInstanceEvent, mapEventData, updateEvent } from '../Services/CommonCalendarServices';
import EventTooltip from '../Tooltips/EventTooltip';
import { scheduleOptionsMap } from '../mappings/ScheduleOptionsMap';
import AppointmentSchedulerButton from '../../Maps/AppointmentSchedulerButton';
import { useParams } from 'react-router';
import { CalendarEventData } from '../types/CalendarEventData';
import RescheduleEventModal from '../Modals/RescheduleEventModal';
import { RescheduledNonRecurrenceDto } from '../../../Models/Scheduling/RescheduledNonRecurrenceDto.model';
import { RescheduledRecurrenceDto } from '../../../Models/Scheduling/RescheduledRecurrenceDto.model';
import { CreatedInstanceDto } from '../../../Models/Scheduling/CreatedInstanceDto.model';

interface IMemberChartSchedulerProps {
    memberId: string;
    canCreate?: boolean;
    canEdit?: boolean;
    preSelectedDate?: Date;
}

const MemberChartScheduler: React.FC<IMemberChartSchedulerProps> = (props) => {
    const dispatch = useDispatch();
    const params = useParams<{ memberId: string }>();
    const memberId = props.memberId ?? params.memberId ?? '';
    const calendarRef = React.useRef<Eventcalendar>(null);
    const [myEvents, setMyEvents] = useState([]);
    const [isEventEditorOpen, setIsEventEditorOpen] = useState<boolean>(false);
    const [mySelectedDate, setSelectedDate] = React.useState(props.preSelectedDate ? new Date(props.preSelectedDate) : new Date());
    const [monthBounds, setMonthBounds] = React.useState<{ firstDate: Date; lastDate: Date } | undefined>({ firstDate: new Date(), lastDate: new Date() });
    const [hoveredEvent, setHoveredEvent] = useState<CalendarEventData | null>(null);
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventData | null>(null);
    const [notesEdit, setNotesEdit] = useState<boolean>(false);
    const [isRecurringRescheduleModalOpen, setIsRecurringRescheduleModalOpen] = useState<boolean>(false);

    const [isBackdropOpen, setIsBackdropOpen] = useState<boolean>(false);

    const handleDeleteEventSuccess = (eventId: string) => {
        const newEvents = myEvents.filter((item) => item.Id !== eventId);
        setMyEvents(newEvents);
    };

    const handleUpdateEventSuccess = (event: Appointment | CalendarEventData) => {
        setMyEvents((currentEvents) => {
            return currentEvents.map((item) => {
                if (item.Id === event.Id) {
                    // TODO: clean this up so we don't have to map data every time we set the events
                    return mapEventData(event);
                } else {
                    return item;
                }
            });
        });
    };

    const handleCreateEventSuccess = (event: Appointment, rescheduledEvent?: CalendarEventData) => {
        setMyEvents((currentEvents) => {
            let newEvents = [...currentEvents, mapEventData(event)];
            if (rescheduledEvent !== undefined) {
                newEvents = newEvents.map((item) => {
                    if (item.Id === rescheduledEvent.id) {
                        rescheduledEvent.RescheduledAppointmentId = event.Id;
                        if (
                            rescheduledEvent.status !== appointmentStatusMap.Cancelled.value ||
                            rescheduledEvent.status !== appointmentStatusMap.NoCallNoShow.value
                        ) {
                            rescheduledEvent.status = appointmentStatusMap.Cancelled.value;
                        }
                        // TODO: clean this up so we don't have to map data every time we set the events
                        return mapEventData(rescheduledEvent);
                    } else {
                        return item;
                    }
                });
            }
            return newEvents;
        });
    };

    const handleRescheduleEventClick = (eventData: CalendarEventData) => {
        if (eventData.recurring) {
            setIsRecurringRescheduleModalOpen(true);
            return;
        }

        if (eventData.status !== appointmentStatusMap.Cancelled.value && eventData.status !== appointmentStatusMap.NoCallNoShow.value) {
            eventData.status = appointmentStatusMap.Cancelled.value;
        }

        const newEvent: Partial<CalendarEventData> & { IsRescheduling: boolean } = {
            ...eventData,
            Id: undefined,
            status: appointmentStatusMap.Unconfirmed.value,
            AppointmentStatus: appointmentStatusMap.Unconfirmed.value,
            OriginalAppointmentId: eventData.Id,
            IsRescheduling: true,
        };

        setSelectedEvent(newEvent as any);
        setIsEventEditorOpen(true);
    };

    const handleCreateRecurrenceInstance = async (createdInstanceDto: CreatedInstanceDto) => {
        const { CreatedInstanceAppointment, OriginalRecurrenceAppointment } = createdInstanceDto;
        const mappedNewEvent = mapEventData(CreatedInstanceAppointment);
        const mappedOriginalEvent = mapEventData(OriginalRecurrenceAppointment);
        let newEvents = [
            mappedNewEvent,
            ...myEvents.map((item) => {
                if (item.Id === mappedOriginalEvent.Id) {
                    return mappedOriginalEvent;
                } else {
                    return item;
                }
            }),
        ];
        setMyEvents(newEvents);
    };
    const handleCancelEventClick = async (eventData: CalendarEventData) => {
        setIsBackdropOpen(true);
        if (eventData.recurring) {
            const response = await cancelRecurringInstanceEvent(eventData.original as Appointment, new Date(eventData.start));
            handleCreateRecurrenceInstance(response.data);
            setIsBackdropOpen(false);
            return;
        }
        eventData.status = appointmentStatusMap.Cancelled.value;
        try {
            const response = await updateEvent(eventData);
            if (response.data) {
                handleUpdateEventSuccess(response.data);
            }
        } catch (error) {
            let errorMessage = 'An error in loading the calendar has occurred. A response was given but data was not received with it.';
            if (error?.response?.data) {
                errorMessage = `${error}. \n\nReason: ${error.response.data}`;
            }
            setMyEvents([]);
            dispatch(setShowMessage(true, errorMessage, 'error'));
        }
        setIsBackdropOpen(false);
        handleUpdateEventSuccess(eventData);
    };

    const handleSelectedDateChange = React.useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const handleCloseEventEditor = () => {
        setIsEventEditorOpen(false);
    };
    const handleEventClick = (args: MbscCalendarEvent) => {
        const eventInfo = args.event as CalendarEventData;
        setSelectedEvent(eventInfo);
        setHoveredEvent(eventInfo);
        setAnchor(args.domEvent.target.parentElement);
        setIsPopupOpen(true);
    };

    const handleEventEdit = (eventData: CalendarEventData) => {
        setSelectedEvent(eventData);
        setIsPopupOpen(false);
        setIsEventEditorOpen(true);
    };

    const handleEventCreate = (event: MbscCalendarEvent) => {
        const eventInfo = event.event as CalendarEventData;
        setSelectedEvent(eventInfo);
        setIsPopupOpen(false);
        setIsEventEditorOpen(true);

        // We will add the event manually, returning false makes mobiscroll not add a placeholder.
        return false;
    };

    const updateBounds = useCallback(
        (firstDay: Date, lastDay: Date) => {
            const newBoundFirstDateTime = firstDay.getTime();
            const newBoundLastDateTime = lastDay.getTime();
            const monthBoundFirstDateTime = monthBounds.firstDate.getTime();
            const monthBoundLastDateTime = monthBounds.lastDate.getTime();
            if (newBoundFirstDateTime !== monthBoundFirstDateTime || newBoundLastDateTime !== monthBoundLastDateTime) {
                setMonthBounds({ firstDate: firstDay, lastDate: lastDay });
            }
        },
        [monthBounds.firstDate, monthBounds.lastDate]
    );
    return (
        <Stack spacing={1}>
            {selectedEvent !== undefined && selectedEvent !== null ? (
                <>
                    <CalendarEventEditModal
                        open={isEventEditorOpen}
                        onSuccess={() => {
                            setIsEventEditorOpen(false);
                        }}
                        onUpdateEventSuccess={handleUpdateEventSuccess}
                        eventList={myEvents as any}
                        onCreateEventSuccess={handleCreateEventSuccess}
                        onRescheduleEvent={handleUpdateEventSuccess}
                        setLoadingOverlay={(isLoading: boolean) => setIsBackdropOpen(isLoading)}
                        onClose={handleCloseEventEditor}
                        eventData={selectedEvent}
                        memberId={memberId}
                    />
                    <RescheduleEventModal
                        eventData={selectedEvent}
                        setLoadingOverlay={(isLoading: boolean) => setIsBackdropOpen(isLoading)}
                        open={isRecurringRescheduleModalOpen}
                        onClose={() => {
                            setIsRecurringRescheduleModalOpen(false);
                        }}
                        onRescheduleSuccess={(rescheduledAppointment: RescheduledNonRecurrenceDto) => {
                            setIsRecurringRescheduleModalOpen(false);
                        }}
                        onRescheduleRecurrenceSuccess={(rescheduledRecurrence: RescheduledRecurrenceDto) => {
                            const { RescheduledAppointment, OriginalInstanceAppointment, OriginalRecurrenceAppointment } = rescheduledRecurrence;
                            const newEvents = myEvents
                                .map((item) => {
                                    if (item.Id === OriginalRecurrenceAppointment.Id) {
                                        return mapEventData(OriginalRecurrenceAppointment);
                                    } else {
                                        return item;
                                    }
                                })
                                .concat([mapEventData(RescheduledAppointment), mapEventData(OriginalInstanceAppointment)]);
                            setMyEvents(newEvents);
                            setIsRecurringRescheduleModalOpen(false);
                        }}
                    />
                </>
            ) : null}
            <CalendarStatusLegend />
            <MessageDisplay />

            <Grid spacing={2} container direction="row">
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Stack spacing={2}>
                        <HelpDocumentIconButton fullButton />
                        <AppointmentSchedulerButton memberId={memberId} />
                        <ExportCalendarButton
                            initialStartDate={monthBounds.firstDate}
                            initialEndDate={monthBounds.lastDate}
                            getEventsForExport={(startDate: Date, endDate: Date) => calendarRef.current.getEvents(startDate, endDate) ?? []}
                        />
                        <LoadEventsDropdown
                            memberId={memberId}
                            defaultScheduleLoadOption={scheduleOptionsMap[CalendarLoadModeEnum.ByMember]}
                            onEventsLoading={() => {
                                setIsBackdropOpen(true);
                            }}
                            onLoadSuccess={(events) => {
                                setMyEvents(events.map(mapEventData));
                                setIsBackdropOpen(false);
                                console.trace('onLoadSuccess', events, isBackdropOpen);
                            }}
                            onLoadFailure={(error) => {
                                let errorMessage = 'An error in loading the calendar has occurred. A response was given but data was not received with it.';
                                if (error?.response?.data) {
                                    errorMessage = `${error}. \n\nReason: ${error.response.data}`;
                                }
                                setMyEvents([]);
                                setIsBackdropOpen(false);
                                dispatch(setShowMessage(true, errorMessage, 'error'));
                            }}
                            startDate={monthBounds.firstDate}
                            endDate={monthBounds.lastDate}
                        />
                        {memberId?.length > 0 && (
                            <>
                                <Button
                                    variant="text"
                                    startIcon={<SpeakerNotesIcon />}
                                    color="primary"
                                    aria-label="toggle notes"
                                    onClick={() => {
                                        setNotesEdit(!notesEdit);
                                    }}
                                >
                                    Edit Notes
                                </Button>
                                <SchedulerNotes memberId={memberId} notesEdit={notesEdit} setNotesEdit={setNotesEdit} />
                            </>
                        )}
                    </Stack>
                </Grid>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Backdrop open={isBackdropOpen} sx={{ zIndex: 1000 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Eventcalendar
                        ref={calendarRef}
                        data={myEvents}
                        clickToCreate={'double'}
                        renderLabelContent={(event) => <EventLabel event={event} />}
                        renderEventContent={(event) => <EventContent event={event} />}
                        theme="material"
                        height={880}
                        width={'100%'}
                        themeVariant="light"
                        view={{ calendar: { labels: true } }}
                        selectedDate={mySelectedDate}
                        onSelectedDateChange={handleSelectedDateChange}
                        onEventClick={handleEventClick}
                        onPageLoading={({ firstDay, lastDay }, inst) => {
                            updateBounds(firstDay, lastDay);
                        }}
                        onEventCreate={handleEventCreate}
                    />
                </Grid>
            </Grid>
            <Popup
                display="anchored"
                isOpen={isPopupOpen}
                anchor={anchor}
                touchUi={false}
                showOverlay={false}
                contentPadding={false}
                closeOnOverlayClick={false}
                cssClass="md-tooltip"
                style={{ zIndex: 99 }}
            >
                <EventTooltip
                    hoveredEvent={hoveredEvent}
                    onEventCancelClick={handleCancelEventClick}
                    onMouseEnter={() => {}}
                    onMouseLeave={() => {}}
                    onDeleteEventSuccess={handleDeleteEventSuccess}
                    closePopup={() => setIsPopupOpen(false)}
                    onEventEditClick={(eventData: CalendarEventData) => {
                        handleEventEdit(eventData);
                        setIsPopupOpen(false);
                    }}
                    onEventRescheduleClick={handleRescheduleEventClick}
                />
            </Popup>
        </Stack>
    );
};

export default MemberChartScheduler;
