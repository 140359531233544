import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Grid,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox,
    OutlinedInput,
    ListItemText,
    SelectChangeEvent,
} from '@mui/material';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useSelector } from 'react-redux';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import { parentUrl } from '../../../../utils/constants';
import { RootState } from '../../../../reducers';
import { TimePeriodFilterEnum } from '../../../../Enum/TimePeriodFilterEnum';
import CallHistoryItem from '../../../../Models/OnboardingWorkflow/CallHistoryItem.model';
import WidgetMemberInfo from '../../../../Models/Widget/WidgetMemberInfo.model';
import Authorization from '../../../../utils/Authorization';
import { fetchProviderCalls, fetchNeedingRescheduleMembers } from '../../../Widgets/WidgetService';
import { isDefaultDate, generateRandomId } from '../../../../utils/common';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { localDateFormat } from '../../../../utils/timeFormat';

const populationTypeList = [
    { value: '1', text: 'Full Program' },
    { value: '2', text: 'HRA' },
    { value: '3', text: 'FUHFUM' },
];

const dayFilterList = [
    { Id: TimePeriodFilterEnum.Last1Month, Value: TimePeriodFilterEnum.Last1MonthDescription },
    { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
    { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
    { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
    { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
];

const OnboardCallPerformancesDetail = () => {
    const allText = 'All';
    const authData = new Authorization();
    const history = useNavigate();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { permissionBasedProviders } = useSelector((state: RootState) => state.provider);
    const [needingRescheduleMembers, setNeedingRescheduleMembers] = useState<WidgetMemberInfo[]>([]);
    const [providerCalls, setProviderCalls] = useState<CallHistoryItem[]>([]);
    const {
        onboardCallSelectedProvider,
        setOnboardCallSelectedProvider,
        onboardCallSelectedFilter,
        setOnboardCallSelectedFilter,
        onboardCallFromDate,
        setOnboardCallFromDate,
        onboardCallToDate,
        setOnboardCallToDate,
        onboardCallShowInActiveProviders,
        setOnboardCallShowInActiveProviders,
        onboardCallPopulationTypes,
        setOnboardCallPopulationTypes,
    } = useWidgetContext();
    const filteredProviders =
        (onboardCallShowInActiveProviders ? permissionBasedProviders : permissionBasedProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const isSelectAll = onboardCallPopulationTypes?.length === populationTypeList.length;
    const [isPopulationUpdated, setIsPopulationUpdated] = useState(false);
    const [isMemberLoading, setIsMemberLoading] = useState(false);
    const [memberPageNumber, setMemberPageNumber] = useState(0);
    const [memberPageTotal, setMemberPageTotal] = useState(0);
    const [isCallLoading, setIsCallLoading] = useState(false);
    const [callPageNumber, setCallPageNumber] = useState(0);
    const [callPageTotal, setCallPageTotal] = useState(0);
    const pageSize = 10;

    const changeMemberFilter = async (pageNo?: number) => {
        setIsMemberLoading(true);
        let pageNumber = pageNo === null ? memberPageNumber : pageNo;
        let dateFrom =
            Boolean(onboardCallFromDate) && onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? new Date(onboardCallFromDate).toISOString() : '';
        let dateTo = Boolean(onboardCallToDate) && onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? new Date(onboardCallToDate).toISOString() : '';
        const response = await fetchNeedingRescheduleMembers(
            onboardCallSelectedProvider,
            onboardCallPopulationTypes,
            onboardCallSelectedFilter,
            dateFrom,
            dateTo,
            onboardCallShowInActiveProviders,
            pageSize,
            pageNumber
        );

        if (response.Data.length < response.TotalCount) {
            var lastPage = Math.ceil(response.TotalCount / pageSize);
            if (lastPage < memberPageNumber) {
                setMemberPageNumber(lastPage);
            }
        }
        setNeedingRescheduleMembers(response.Data);
        setMemberPageTotal(response.TotalCount);
        setIsMemberLoading(false);
        setIsPopulationUpdated(false);
    };

    const changeCallFilter = async (pageNo?: number) => {
        setIsCallLoading(true);
        let pageNumber = pageNo === null ? callPageNumber : pageNo;
        let dateFrom =
            Boolean(onboardCallFromDate) && onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? new Date(onboardCallFromDate).toISOString() : '';
        let dateTo = Boolean(onboardCallToDate) && onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? new Date(onboardCallToDate).toISOString() : '';
        const response = await fetchProviderCalls(
            onboardCallSelectedProvider,
            onboardCallPopulationTypes,
            onboardCallSelectedFilter,
            dateFrom,
            dateTo,
            onboardCallShowInActiveProviders,
            pageSize,
            pageNumber
        );

        if (response.Data.length < response.TotalCount) {
            var lastPage = Math.ceil(response.TotalCount / pageSize);
            if (lastPage < callPageNumber) {
                setCallPageNumber(lastPage);
            }
        }
        setProviderCalls(response.Data);
        setCallPageTotal(response.TotalCount);
        setIsCallLoading(false);
        setIsPopulationUpdated(false);
    };

    useEffect(() => {
        if (onboardCallSelectedFilter !== TimePeriodFilterEnum.Custom || (Boolean(onboardCallFromDate) && Boolean(onboardCallToDate))) {
            setMemberPageTotal(0);
            setCallPageTotal(0);
            setMemberPageNumber(0);
            setCallPageNumber(0);
            changeMemberFilter(0);
            changeCallFilter(0);
        }

        if (onboardCallSelectedFilter !== TimePeriodFilterEnum.Custom && Boolean(onboardCallFromDate) && Boolean(onboardCallToDate)) {
            setOnboardCallFromDate(null);
            setOnboardCallToDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboardCallSelectedProvider, onboardCallSelectedFilter, onboardCallFromDate, onboardCallToDate, onboardCallShowInActiveProviders]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setOnboardCallShowInActiveProviders(isChecked);
        setOnboardCallSelectedProvider(allText);
    };

    const changeFromDate = (date: any) => {
        setOnboardCallFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(onboardCallToDate) || new Date(onboardCallToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setOnboardCallToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setOnboardCallToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(onboardCallFromDate) || new Date(onboardCallFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setOnboardCallFromDate(startDate);
        }
    };

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const handleOnChange = (e: SelectChangeEvent<unknown>) => {
        let selectedValues = e.target.value as string[];
        if (selectedValues.includes(allText) && !isSelectAll) {
            selectedValues = populationTypeList.map((o) => o.text);
        } else if (selectedValues.includes(allText)) {
            selectedValues = [];
        }

        setOnboardCallPopulationTypes(selectedValues);
        setIsPopulationUpdated(true);
    };

    const membersColumns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Member Name',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'DateInfo',
            headerName: 'Last Appointment Date',
            width: 100,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DateInfo) ? '-' : localDateFormat(new Date(params.row.DateInfo))}`,
            sortable: false,
            flex: 1,
        },
    ];

    const callsColumns: GridColDef[] = [
        {
            field: 'FirstName',
            headerName: 'Member Name',
            minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => `${params.row.LastName}, ${params.row.FirstName}`,
            flex: 1,
        },
        {
            field: 'TimeCallPlaced',
            headerName: 'Time On Called',
            width: 100,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.TimeCallPlaced) ? '-' : moment(params.row.TimeCallPlaced).format('MM/DD/YYYY hh:mm A')}`,
            sortable: false,
            flex: 1,
        },
    ];

    const onPopulationChange = () => {
        setMemberPageTotal(0);
        setCallPageTotal(0);
        setMemberPageNumber(0);
        setCallPageNumber(0);
        changeMemberFilter(0);
        changeCallFilter(0);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            Onboard Calling Performance Summary
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} marginTop={1}>
                    {showProviders && (
                        <Grid item xs={1.5}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filteredProviders.find((provider) => provider.Id === onboardCallSelectedProvider)?.Id || allText}
                                    size="small"
                                    label="Provider"
                                    onChange={(e) => {
                                        setOnboardCallSelectedProvider(e.target.value as string);
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem key={allText} value={allText}>
                                        {allText}
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox checked={onboardCallShowInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((option) => {
                                        return (
                                            <MenuItem key={option.Id} value={option.Id} sx={{ color: option.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                                {`${option.LastName}, ${option.FirstName}`}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Population</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Population"
                                value={onboardCallPopulationTypes}
                                multiple
                                onChange={handleOnChange}
                                onClose={() => (isPopulationUpdated ? onPopulationChange() : null)}
                                input={<OutlinedInput label="Population" />}
                                renderValue={(selected) => (isSelectAll ? allText : selected.join(', '))}
                            >
                                <MenuItem value={allText} className={isSelectAll ? 'Mui-selected' : ''} sx={{ padding: '0', paddingLeft: '10px' }}>
                                    <Checkbox checked={isSelectAll} indeterminate={Boolean(onboardCallPopulationTypes?.length) && !isSelectAll} />
                                    {allText}
                                </MenuItem>
                                {populationTypeList.map((type) => {
                                    return (
                                        <MenuItem key={type.value} value={type.text} sx={{ padding: '0', paddingLeft: '10px' }}>
                                            <Checkbox checked={onboardCallPopulationTypes.indexOf(type.text) > -1} />
                                            <ListItemText primary={type.text} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={onboardCallSelectedFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setOnboardCallSelectedFilter(parseInt(e.target.value as any))}
                            >
                                {dayFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {onboardCallSelectedFilter === TimePeriodFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(onboardCallFromDate) ? onboardCallFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item xs={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(onboardCallToDate) ? onboardCallToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        <Grid container spacing={2} paddingTop={2}>
                            <Grid item xs={6}>
                                <Grid item xs={12} textAlign="center">
                                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                        Needing Reschedule Members ({memberPageTotal})
                                    </Typography>
                                </Grid>
                                <div style={{ width: '100%', height: '70vh' }}>
                                    <DataGrid
                                        onPageChange={(newPage) => {
                                            setMemberPageNumber(newPage);
                                            changeMemberFilter(newPage);
                                        }}
                                        onCellClick={(params: any) => {
                                            window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                        }}
                                        getRowId={(row) => `${row.Id}-${row.DateInfo}-${generateRandomId()}`}
                                        rows={needingRescheduleMembers}
                                        autoHeight
                                        rowCount={memberPageTotal}
                                        columns={membersColumns}
                                        page={memberPageNumber}
                                        pageSize={pageSize}
                                        paginationMode="server"
                                        rowsPerPageOptions={[5]}
                                        loading={isMemberLoading}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item xs={12} textAlign="center">
                                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                                        Number of Calls ({callPageTotal})
                                    </Typography>
                                </Grid>
                                <div style={{ width: '100%', height: '70vh' }}>
                                    <DataGrid
                                        onPageChange={(newPage) => {
                                            setCallPageNumber(newPage);
                                            changeCallFilter(newPage);
                                        }}
                                        getRowId={(row) => `${row.Id}-${row.TimeCallPlaced}-${generateRandomId()}`}
                                        rows={providerCalls}
                                        autoHeight
                                        rowCount={callPageTotal}
                                        columns={callsColumns}
                                        page={callPageNumber}
                                        pageSize={pageSize}
                                        paginationMode="server"
                                        rowsPerPageOptions={[5]}
                                        loading={isCallLoading}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default OnboardCallPerformancesDetail;
