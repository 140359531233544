import { Box, Modal, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid rgb(58 70 228)',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
    maxWidth: '70%',
};

export default function Loading(props: any) {
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const isOpen = !!isLoading || !!props.loading;

    return (
        <div>
            <Modal open={isOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {props.message}
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
