import { AllergyTypeEnum } from '../../Enum/AllergyTypeEnum';

export default interface IMemberAllergy {
    Id: string;
    Name: string;
    MemberId: string;
    AllergyType: AllergyTypeEnum;
    Reaction: string;
    CCRDataObjectID: string;
    AllergyClass: string;
    RxNormCode: string;
    Description: string;
    Source: string;
}

export class MemberAllergy implements IMemberAllergy {
    Id: string;
    Name: string;
    MemberId: string;
    AllergyType: AllergyTypeEnum;
    Reaction: string;
    CCRDataObjectID: string;
    AllergyClass: string;
    RxNormCode: string;
    Description: string;
    Source: string;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.AllergyType = AllergyTypeEnum.Medication;
        this.Reaction = '';
    }
}
