import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import FormikAutocompleteControlled from '../../FormikFields/FormikAutocompleteControlled';
import FormikTextField from '../../FormikFields/FormikTextField';
import { IPaidBy } from '../interfaces/IPaidBy';
import { IResourceServiceItem } from '../interfaces/IResourceServiceItem';

const PayerRow: React.FC<{ payer: IPaidBy; index: number; remove: (index: number) => void; options: { value: string; label: string }[] }> = (props) => {
    const { payer, index, remove, options } = props;
    const formik = useFormikContext<IResourceServiceItem>();
    return (
        <Grid container spacing={2} style={{ padding: 3 }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormikAutocompleteControlled
                    options={options}
                    name={`PayerList[${index}].PayerResource`}
                    label="Contributor"
                    getOptionLabel={(option) => option.label ?? option.ResourceName}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value || option.value === value.ResourceId;
                    }}
                    value={payer.PayerResource}
                    onChange={(e, value) => {
                        formik.setFieldValue(`PayerList[${index}].PayerResource`, { ResourceName: value.label, ResourceId: value.value });
                    }}
                    index={index}
                />
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
                <FormikTextField name={`PayerList[${index}].Amount`} label="Amount" value={payer.Amount} type="number" />
            </Grid>
            <Grid item lg={1} md={1} sm={12} xs={12} sx={{ display: 'flex' }} justifyContent="center" alignItems="center">
                <IconButton onClick={() => remove(index)}>
                    <DeleteIcon color="error" />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default PayerRow;
