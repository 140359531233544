import { useDispatch, useSelector } from 'react-redux';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, RadioGroup, Radio, FormControlLabel, Typography } from '@mui/material';

import { RootState } from '../../../reducers';
import { setMemberBudget } from '../../../store/memberBudget.slice';
import classes from '../Styles';
import { copyObject } from '../../../utils/common';
import CurrencyInput from '../../Shared/CurrencyInput';

const FoodExpenses = () => {
    const dispatch = useDispatch();
    const { memberBudget } = useSelector((state: RootState) => state.memberBudget);

    const handleOnChangeFoodSource = (val: any, index: number) => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.FoodExpense.Expenses[index].Amount = val;
        dispatch(setMemberBudget(memberBudgetData));
    };

    const handleOnChangeRadio = (val: string, propName: string) => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.FoodExpense[propName] = val;
        dispatch(setMemberBudget(memberBudgetData));
    };

    return (
        <TableContainer component={Paper} sx={classes.budgetFormTable}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" component="label">
                                Food
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberBudget.FoodExpense?.Expenses?.map((foodExpense, index) => (
                        <TableRow key={`food-expenses-${index}`} hover>
                            <TableCell scope="row">
                                <Grid container spacing={2}>
                                    <Grid item xs={4} display="flex" alignItems="center">
                                        <span>{foodExpense.Name}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CurrencyInput
                                            size="small"
                                            value={foodExpense.Amount}
                                            name="Amount"
                                            onChange={(e) => handleOnChangeFoodSource(e.target.value, index)}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow hover>
                        <TableCell scope="row">
                            <Grid container spacing={2}>
                                <Grid item xs={4} display="flex" alignItems="center">
                                    <span>Do you use coupons?</span>
                                </Grid>
                                <Grid item xs={4}>
                                    <RadioGroup
                                        row
                                        name="DoYouUseCoupons"
                                        value={memberBudget.FoodExpense.DoYouUseCoupons}
                                        onChange={(e) => handleOnChangeRadio(e.target.value, e.target.name)}
                                    >
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell scope="row">
                            <Grid container spacing={2}>
                                <Grid item xs={4} display="flex" alignItems="center">
                                    <span>Are you using a food bank?</span>
                                </Grid>
                                <Grid item xs={4}>
                                    <RadioGroup
                                        row
                                        name="AreYouUsingFoodBank"
                                        value={memberBudget.FoodExpense.AreYouUsingFoodBank}
                                        onChange={(e) => handleOnChangeRadio(e.target.value, e.target.name)}
                                    >
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FoodExpenses;
