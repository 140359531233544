import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';

const getDefault = () => {
    return { Id: '', Name: '', Permissions: [] };
};

const adminRoles = createSlice({
    name: 'adminRoles',
    initialState: {
        assignedWidgets: [],
        openNew: false,
        openEdit: false,
        roles: [],
        selectAllPermissions: false,
        selectAllWidgets: false,
        selectAllFacilities: false,
        selectedRole: getDefault(),
        roleName: '',
        rolePermissions: [],
        smartdashboardWidgets: [],
        analyticsWidgets: [],
        hedisMetricsWidgets: [],
        assignedFacilities: [],
        roleFacilities: [],
        zipCodes: [],
        selectedTab: 0,
    },
    reducers: {
        _setAssignedWidgets(state, action) {
            state.assignedWidgets = action.payload;
        },
        _setOpenNew(state, action) {
            state.openNew = action.payload;
        },
        _setOpenEdit(state, action) {
            state.openEdit = action.payload;
        },
        _setRoles(state, action) {
            state.roles = action.payload;
        },
        _setSelectAllPermissions(state, action) {
            state.selectAllPermissions = action.payload;
        },
        _setSelectAllWidgets(state, action) {
            state.selectAllWidgets = action.payload;
        },
        _setSelectAllFacilities(state, action) {
            state.selectAllFacilities = action.payload;
        },
        _setSelectedRole(state, action) {
            state.selectedRole = action.payload;
        },
        _setRoleName(state, action) {
            state.roleName = action.payload;
        },
        _setRolePermissions(state, action) {
            state.rolePermissions = action.payload;
        },
        _setSmartdashboardWidgets(state, action) {
            state.smartdashboardWidgets = action.payload;
        },
        _setAnalyticsWidgets(state, action) {
            state.analyticsWidgets = action.payload;
        },
        _setHedisMetricsWidgets(state, action) {
            state.hedisMetricsWidgets = action.payload;
        },
        _setRoleFacilities(state, action) {
            state.roleFacilities = action.payload;
        },
        _setAssignedFacilities(state, action) {
            state.assignedFacilities = action.payload;
        },
        _setZipCodes(state, action) {
            state.zipCodes = action.payload;
        },
        _setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
    },
});

const {
    _setAssignedWidgets,
    _setOpenNew,
    _setOpenEdit,
    _setRoles,
    _setSelectAllPermissions,
    _setSelectAllWidgets,
    _setSelectAllFacilities,
    _setSelectedRole,
    _setRoleName,
    _setRolePermissions,
    _setSmartdashboardWidgets,
    _setAnalyticsWidgets,
    _setHedisMetricsWidgets,
    _setRoleFacilities,
    _setAssignedFacilities,
    _setZipCodes,
    _setSelectedTab,
} = adminRoles.actions;

export const setAssignedWidgets =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setAssignedWidgets(value));
    };

export const setOpenNew =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenNew(value));
    };

export const setSelectAllPermissions =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectAllPermissions(value));
    };

export const setSelectAllWidgets =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectAllWidgets(value));
    };

export const setSelectAllFacilities =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectAllFacilities(value));
    };

export const setOpenEdit =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenEdit(value));
    };

export const setRoles =
    (value: []): AppThunk =>
    (dispatch) => {
        dispatch(_setRoles(value));
    };

export const setSelectedRole =
    (value: any): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedRole(value));
    };

export const setRoleName =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setRoleName(value));
    };

export const setRolePermissions =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRolePermissions(value));
    };

export const setRoleFacilities =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRoleFacilities(value));
    };

export const setAssignedFacilities =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setAssignedFacilities(value));
    };

export const setZipCodes =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setZipCodes(value));
    };

export const setSelectedTab =
    (value: any): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedTab(value));
    };

export const closeEditHandler = (): AppThunk => (dispatch) => {
    dispatch(setOpenEdit(false));
    dispatch(setOpenNew(false));
    dispatch(setRolePermissions([]));
    dispatch(setAssignedWidgets([]));
    dispatch(setAssignedFacilities([]));
    dispatch(setRoleName(''));
    dispatch(setSelectedRole(getDefault()));
    dispatch(setSelectedTab(0));
};

export const openNewHandler = (): AppThunk => (dispatch) => {
    dispatch(setOpenNew(true));
    dispatch(setOpenEdit(true));
    dispatch(setSelectedRole(getDefault()));
};

export const fetchRoles = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/role/roles`);
    dispatch(setRoles(response.data));
    dispatch(setIsLoading(false));
};

export const fetchSmartDashboardWidgets = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/role/smartdashboardwidgets`);
    console.log('fetchSmartDashboardWidgets response: ', response.data);
    dispatch(_setSmartdashboardWidgets(response.data));
    dispatch(setIsLoading(false));
};

export const fetchAnalyticsWidgets = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/role/analyticswidgets`);
    console.log('fetchAnalyticsWidgets response: ', response.data);
    dispatch(_setAnalyticsWidgets(response.data));
    dispatch(setIsLoading(false));
};

export const fetchHedisMetricsWidgets = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/role/hedismetricswidgets`);
    console.log('fetchHedisMetricsWidgets response: ', response.data);
    dispatch(_setHedisMetricsWidgets(response.data));
    dispatch(setIsLoading(false));
};

export const fetchFacilities = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/facility/getactivefacilities`);
    dispatch(_setRoleFacilities(response.data));
    dispatch(setIsLoading(false));
};

export const fetchFacilityZipCoeds = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/facility/getfacilityzipcodes`);
    dispatch(_setZipCodes(response.data));
    dispatch(setIsLoading(false));
};

export const insertRole =
    (data: {}): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.post(`${API_URL}/role/insert`, data);
            dispatch(fetchRoles());
            dispatch(closeEditHandler());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export const updateRole =
    (data: {}): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.put(`${API_URL}/role/update`, data);
            dispatch(fetchRoles());
            dispatch(closeEditHandler());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export default adminRoles.reducer;
