import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL, ERROR } from '../utils/constants';
import axios from 'axios';
import { setIsLoading, setShowMessage } from './shared.slice';
import { GridRowId } from '@mui/x-data-grid';
import b64toBlob from 'b64-to-blob';
import { statusValues } from './claimsManagement.slice';
import { EncounterStatusEnum } from '../Enum/EncounterStatusEnum';

const claimsDateFormatter = (date) => {
    return (
        (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
        '/' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        '/' +
        date.getFullYear()
    );
};

const providerClaims = createSlice({
    name: 'providerClaims',
    initialState: {
        claimsData: [],
        claimNumberSearchValue: '',
        encounterStatus: EncounterStatusEnum.ForReview,
        dosStart: new Date(),
        dosEnd: new Date(),
        encounterIdList: [],
        selectedTab: 0,
        selectedProviderId: '',
    },
    reducers: {
        _setClaimsData(state, action) {
            state.claimsData = action.payload;
        },
        _setClaimNumberSearchValue(state, action) {
            state.claimNumberSearchValue = action.payload;
        },
        _setStatusValue(state, action) {
            state.encounterStatus = action.payload;
        },
        _setDosStart(state, action) {
            state.dosStart = action.payload;
        },
        _setDosEnd(state, action) {
            state.dosEnd = action.payload;
        },
        _setEncounterIdList(state, action) {
            state.encounterIdList = action.payload;
        },
        _setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
        _setSelectedProviderId(state, action) {
            state.selectedProviderId = action.payload;
        },
    },
});

const { _setClaimsData, _setClaimNumberSearchValue, _setStatusValue, _setDosStart, _setDosEnd, _setEncounterIdList, _setSelectedTab, _setSelectedProviderId } =
    providerClaims.actions;

export const fetchClaimsData =
    (providerId: string, statusValue: number, dosStart: Date, dosEnd: Date): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        var getURL = `${API_URL}/encounter/getclaims?providerId=${providerId}&encounterStatusValue=${statusValue}&startDate=${claimsDateFormatter(
            dosStart
        )}&endDate=${claimsDateFormatter(dosEnd)}`;

        if (statusValue === 2 || statusValue === 1) {
            getURL = `${API_URL}/encounter/getencounters?providerId=${providerId}&encounterStatusValue=${statusValue}&startDate=${claimsDateFormatter(
                dosStart
            )}&endDate=${claimsDateFormatter(dosEnd)}`;
        }

        const response = await axios.get(getURL);
        dispatch(_setClaimsData(response.data));
        dispatch(setIsLoading(false));
    };

export const fetchClaimsByClaimNumber =
    (providerId: string, claimNumber: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/encounter/getclaimbyclaimnumber?providerId=${providerId}&claimNumber=${claimNumber}`);
        dispatch(_setClaimsData(response.data));

        if (response.data[0] && response.data[0].EncounterStatusValue) {
            const statusValue = statusValues.find((x) => x.label === response.data[0].EncounterStatusValue).value;
            dispatch(_setStatusValue(statusValue));
        }
        dispatch(setIsLoading(false));
    };

export const fetchClaimResponses =
    (callback: Function): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        await axios.post(`${API_URL}/encounter/processclaims`);
        callback();
    };

export const setStatusValue =
    (value: number): AppThunk =>
    (dispatch) => {
        dispatch(_setStatusValue(value));
    };

export const setClaimNumberSearchValue =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setClaimNumberSearchValue(value));
    };

export const setDosStart =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setDosStart(value));
    };

export const setDosEnd =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setDosEnd(value));
    };

export const setEncounterIdList =
    (value: GridRowId[]): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterIdList(value));
    };

export const sendClaim =
    (encounterIdList: GridRowId[], statusValue: number, dosStart: Date, dosEnd: Date): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.post(`${API_URL}/encounter/sendclaims`, encounterIdList);
            console.log(`send claims response: `, response);
            dispatch(setIsLoading(false));
            dispatch(fetchClaimsData(getState().providerClaims.selectedProviderId, statusValue, dosStart, dosEnd));
        } catch (e) {
            dispatch(setShowMessage(true, e.message, ERROR));
            dispatch(setIsLoading(false));
        }
    };

export const downloadClaimsHandler =
    (claimsData): AppThunk =>
    async (dispatch) => {
        const downloadList = claimsData.map((item) => item.EncounterId);
        const response = await axios.post(`${API_URL}/encounter/claimsManagementDownload`, downloadList);
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
    };

export const setSelectedTab =
    (tab: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedTab(tab));
    };

export const setProviderClaimsSelectedProviderId =
    (id: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSelectedProviderId(id));
    };

export default providerClaims.reducer;
