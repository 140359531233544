import React, { useState } from 'react';
import { HedisMetricsTypeEnum } from '../Enum/HedisMetricsTypeEnum';
import { TimePeriodFilterEnum } from '../Enum/TimePeriodFilterEnum';
import Authorization from '../utils/Authorization';

const HedisMetricsContext = React.createContext();

export const HedisMetricsProvider = ({ children }) => {
    const authData = new Authorization();
    const isCEO = authData.Role === 'CEO' ? true : false;
    const [selectedTeam, setSelectedTeam] = useState(isCEO ? 'All' : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);

    const [hedisType, setHedisType] = useState(HedisMetricsTypeEnum.BP);
    const [pop, setPop] = useState(0);
    const [team, setTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [filter, setFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [fDate, setFDate] = useState(null);
    const [tDate, setTDate] = useState(null);
    const [loadedTeam, setLoadedTeam] = useState(false);
    const [includePastOpenStatus, setIncludePastOpenStatus] = useState(false);
    const [showInActiveTeam, setShowInActiveTeam] = useState(false);

    const [adultHealthPopulation, setAdultHealthPopulation] = useState(0);
    const [adultHealthTeam, setAdultHealthTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [adultHealthDateFilter, setAdultHealthDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [adultHealthFromDate, setAdultHealthFromDate] = useState(null);
    const [adultHealthToDate, setAdultHealthToDate] = useState(null);
    const [adultHealthLoadedTeam, setAdultHealthLoadedTeam] = useState(false);
    const [adultHealthIncludePastOpenStatus, setAdultHealthIncludePastOpenStatus] = useState(false);
    const [adultHealthShowInActiveTeam, setAdultHealthShowInActiveTeam] = useState(false);

    const [bmiPopulation, setBmiPopulation] = useState(0);
    const [bmiTeam, setBmiTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [bmiDateFilter, setBmiDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [bmiFromDate, setBmiFromDate] = useState(null);
    const [bmiToDate, setBmiToDate] = useState(null);
    const [bmiLoadedTeam, setBmiLoadedTeam] = useState(false);
    const [bmiIncludePastOpenStatus, setBmiIncludePastOpenStatus] = useState(false);
    const [bmiShowInActiveTeam, setBmiShowInActiveTeam] = useState(false);

    const [bpPopulation, setBpPopulation] = useState(0);
    const [bpTeam, setBpTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [bpDateFilter, setBpDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [bpFromDate, setBpFromDate] = useState(null);
    const [bpToDate, setBpToDate] = useState(null);
    const [bpLoadedTeam, setBpLoadedTeam] = useState(false);
    const [bpIncludePastOpenStatus, setBpIncludePastOpenStatus] = useState(false);
    const [bpShowInActiveTeam, setBpShowInActiveTeam] = useState(false);

    const [breastCancerScreeningPopulation, setBreastCancerScreeningPopulation] = useState(0);
    const [breastCancerScreeningTeam, setBreastCancerScreeningTeam] = useState(
        Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : ''
    );
    const [breastCancerScreeningDateFilter, setBreastCancerScreeningDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [breastCancerScreeningFromDate, setBreastCancerScreeningFromDate] = useState(null);
    const [breastCancerScreeningToDate, setBreastCancerScreeningToDate] = useState(null);
    const [breastCancerScreeningLoadedTeam, setBreastCancerScreeningLoadedTeam] = useState(false);
    const [breastCancerScreeningIncludePastOpenStatus, setBreastCancerScreeningIncludePastOpenStatus] = useState(false);
    const [breastCancerScreeningShowInActiveTeam, setBreastCancerScreeningShowInActiveTeam] = useState(false);

    const [depressionFollowUpPopulation, setDepressionFollowUpPopulation] = useState(0);
    const [depressionFollowUpTeam, setDepressionFollowUpTeam] = useState(
        Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : ''
    );
    const [depressionFollowUpDateFilter, setDepressionFollowUpDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [depressionFollowUpFromDate, setDepressionFollowUpFromDate] = useState(null);
    const [depressionFollowUpToDate, setDepressionFollowUpToDate] = useState(null);
    const [depressionFollowUpLoadedTeam, setDepressionFollowUpLoadedTeam] = useState(false);
    const [depressionFollowUpIncludePastOpenStatus, setDepressionFollowUpIncludePastOpenStatus] = useState(false);
    const [depressionFollowUpShowInActiveTeam, setDepressionFollowUpShowInActiveTeam] = useState(false);

    const [depressionScreeningPopulation, setDepressionScreeningPopulation] = useState(0);
    const [depressionScreeningTeam, setDepressionScreeningTeam] = useState(
        Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : ''
    );
    const [depressionScreeningDateFilter, setDepressionScreeningDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [depressionScreeningFromDate, setDepressionScreeningFromDate] = useState(null);
    const [depressionScreeningToDate, setDepressionScreeningToDate] = useState(null);
    const [depressionScreeningLoadedTeam, setDepressionScreeningLoadedTeam] = useState(false);
    const [depressionScreeningIncludePastOpenStatus, setDepressionScreeningIncludePastOpenStatus] = useState(false);
    const [depressionScreeningShowInActiveTeam, setDepressionScreeningShowInActiveTeam] = useState(false);

    const [diabeticEyeExamPopulation, setDiabeticEyeExamPopulation] = useState(0);
    const [diabeticEyeExamTeam, setDiabeticEyeExamTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [diabeticEyeExamDateFilter, setDiabeticEyeExamDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [diabeticEyeExamFromDate, setDiabeticEyeExamFromDate] = useState(null);
    const [diabeticEyeExamToDate, setDiabeticEyeExamToDate] = useState(null);
    const [diabeticEyeExamLoadedTeam, setDiabeticEyeExamLoadedTeam] = useState(false);
    const [diabeticEyeExamIncludePastOpenStatus, setDiabeticEyeExamIncludePastOpenStatus] = useState(false);
    const [diabeticEyeExamShowInActiveTeam, setDiabeticEyeExamShowInActiveTeam] = useState(false);

    const [hgba1cPopulation, setHgba1cPopulation] = useState(0);
    const [hgba1cTeam, setHgba1cTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [hgba1cDateFilter, setHgba1cDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [hgba1cFromDate, setHgba1cFromDate] = useState(null);
    const [hgba1cToDate, setHgba1cToDate] = useState(null);
    const [hgba1cLoadedTeam, setHgba1cLoadedTeam] = useState(false);
    const [hgba1cIncludePastOpenStatus, setHgba1cIncludePastOpenStatus] = useState(false);
    const [hgba1cShowInActiveTeam, setHgba1cShowInActiveTeam] = useState(false);

    const [hgbA1cForBhDxPopulation, setHgbA1cForBhDxPopulation] = useState(0);
    const [hgbA1cForBhDxTeam, setHgbA1cForBhDxTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [hgbA1cForBhDxDateFilter, setHgbA1cForBhDxDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [hgbA1cForBhDxFromDate, setHgbA1cForBhDxFromDate] = useState(null);
    const [hgbA1cForBhDxToDate, setHgbA1cForBhDxToDate] = useState(null);
    const [hgbA1cForBhDxLoadedTeam, setHgbA1cForBhDxLoadedTeam] = useState(false);
    const [hgbA1cForBhDxIncludePastOpenStatus, setHgbA1cForBhDxIncludePastOpenStatus] = useState(false);
    const [hgbA1cForBhDxShowInActiveTeam, setHgbA1cForBhDxShowInActiveTeam] = useState(false);

    const [lipidForBhDxPopulation, setLipidForBhDxPopulation] = useState(0);
    const [lipidForBhDxTeam, setLipidForBhDxTeam] = useState(Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : '');
    const [lipidForBhDxDateFilter, setLipidForBhDxDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [lipidForBhDxFromDate, setLipidForBhDxFromDate] = useState(null);
    const [lipidForBhDxToDate, setLipidForBhDxToDate] = useState(null);
    const [lipidForBhDxLoadedTeam, setLipidForBhDxLoadedTeam] = useState(false);
    const [lipidForBhDxIncludePastOpenStatus, setLipidForBhDxIncludePastOpenStatus] = useState(false);
    const [lipidForBhDxShowInActiveTeam, setLipidForBhDxShowInActiveTeam] = useState(false);

    const [medicationReconciliationPopulation, setMedicationReconciliationPopulation] = useState(0);
    const [medicationReconciliationTeam, setMedicationReconciliationTeam] = useState(
        Boolean(selectedTeam) ? selectedTeam : Boolean(authData.TeamId) ? authData.TeamId : ''
    );
    const [medicationReconciliationDateFilter, setMedicationReconciliationDateFilter] = useState(TimePeriodFilterEnum.Last6Months);
    const [medicationReconciliationFromDate, setMedicationReconciliationFromDate] = useState(null);
    const [medicationReconciliationToDate, setMedicationReconciliationToDate] = useState(null);
    const [medicationReconciliationLoadedTeam, setMedicationReconciliationLoadedTeam] = useState(false);
    const [medicationReconciliationIncludePastOpenStatus, setMedicationReconciliationIncludePastOpenStatus] = useState(false);
    const [medicationReconciliationShowInActiveTeam, setMedicationReconciliationShowInActiveTeam] = useState(false);

    const value = {
        selectedTeam,
        setSelectedTeam,
        showInActiveTeams,
        setShowInActiveTeams,

        hedisType,
        setHedisType,
        pop,
        setPop,
        team,
        setTeam,
        filter,
        setFilter,
        fDate,
        setFDate,
        tDate,
        setTDate,
        loadedTeam,
        setLoadedTeam,
        includePastOpenStatus,
        setIncludePastOpenStatus,
        showInActiveTeam,
        setShowInActiveTeam,

        adultHealthPopulation,
        setAdultHealthPopulation,
        adultHealthTeam,
        setAdultHealthTeam,
        adultHealthDateFilter,
        setAdultHealthDateFilter,
        adultHealthFromDate,
        setAdultHealthFromDate,
        adultHealthToDate,
        setAdultHealthToDate,
        adultHealthLoadedTeam,
        setAdultHealthLoadedTeam,
        adultHealthIncludePastOpenStatus,
        setAdultHealthIncludePastOpenStatus,
        adultHealthShowInActiveTeam,
        setAdultHealthShowInActiveTeam,

        bmiPopulation,
        setBmiPopulation,
        bmiTeam,
        setBmiTeam,
        bmiDateFilter,
        setBmiDateFilter,
        bmiFromDate,
        setBmiFromDate,
        bmiToDate,
        setBmiToDate,
        bmiLoadedTeam,
        setBmiLoadedTeam,
        bmiIncludePastOpenStatus,
        setBmiIncludePastOpenStatus,
        bmiShowInActiveTeam,
        setBmiShowInActiveTeam,

        bpPopulation,
        setBpPopulation,
        bpTeam,
        setBpTeam,
        bpDateFilter,
        setBpDateFilter,
        bpFromDate,
        setBpFromDate,
        bpToDate,
        setBpToDate,
        bpLoadedTeam,
        setBpLoadedTeam,
        bpIncludePastOpenStatus,
        setBpIncludePastOpenStatus,
        bpShowInActiveTeam,
        setBpShowInActiveTeam,

        breastCancerScreeningPopulation,
        setBreastCancerScreeningPopulation,
        breastCancerScreeningTeam,
        setBreastCancerScreeningTeam,
        breastCancerScreeningDateFilter,
        setBreastCancerScreeningDateFilter,
        breastCancerScreeningFromDate,
        setBreastCancerScreeningFromDate,
        breastCancerScreeningToDate,
        setBreastCancerScreeningToDate,
        breastCancerScreeningLoadedTeam,
        setBreastCancerScreeningLoadedTeam,
        breastCancerScreeningIncludePastOpenStatus,
        setBreastCancerScreeningIncludePastOpenStatus,
        breastCancerScreeningShowInActiveTeam,
        setBreastCancerScreeningShowInActiveTeam,

        depressionFollowUpPopulation,
        setDepressionFollowUpPopulation,
        depressionFollowUpTeam,
        setDepressionFollowUpTeam,
        depressionFollowUpDateFilter,
        setDepressionFollowUpDateFilter,
        depressionFollowUpFromDate,
        setDepressionFollowUpFromDate,
        depressionFollowUpToDate,
        setDepressionFollowUpToDate,
        depressionFollowUpLoadedTeam,
        setDepressionFollowUpLoadedTeam,
        depressionFollowUpIncludePastOpenStatus,
        setDepressionFollowUpIncludePastOpenStatus,
        depressionFollowUpShowInActiveTeam,
        setDepressionFollowUpShowInActiveTeam,

        depressionScreeningPopulation,
        setDepressionScreeningPopulation,
        depressionScreeningTeam,
        setDepressionScreeningTeam,
        depressionScreeningDateFilter,
        setDepressionScreeningDateFilter,
        depressionScreeningFromDate,
        setDepressionScreeningFromDate,
        depressionScreeningToDate,
        setDepressionScreeningToDate,
        depressionScreeningLoadedTeam,
        setDepressionScreeningLoadedTeam,
        depressionScreeningIncludePastOpenStatus,
        setDepressionScreeningIncludePastOpenStatus,
        depressionScreeningShowInActiveTeam,
        setDepressionScreeningShowInActiveTeam,

        diabeticEyeExamPopulation,
        setDiabeticEyeExamPopulation,
        diabeticEyeExamTeam,
        setDiabeticEyeExamTeam,
        diabeticEyeExamDateFilter,
        setDiabeticEyeExamDateFilter,
        diabeticEyeExamFromDate,
        setDiabeticEyeExamFromDate,
        diabeticEyeExamToDate,
        setDiabeticEyeExamToDate,
        diabeticEyeExamLoadedTeam,
        setDiabeticEyeExamLoadedTeam,
        diabeticEyeExamIncludePastOpenStatus,
        setDiabeticEyeExamIncludePastOpenStatus,
        diabeticEyeExamShowInActiveTeam,
        setDiabeticEyeExamShowInActiveTeam,

        hgba1cPopulation,
        setHgba1cPopulation,
        hgba1cTeam,
        setHgba1cTeam,
        hgba1cDateFilter,
        setHgba1cDateFilter,
        hgba1cFromDate,
        setHgba1cFromDate,
        hgba1cToDate,
        setHgba1cToDate,
        hgba1cLoadedTeam,
        setHgba1cLoadedTeam,
        hgba1cIncludePastOpenStatus,
        setHgba1cIncludePastOpenStatus,
        hgba1cShowInActiveTeam,
        setHgba1cShowInActiveTeam,

        hgbA1cForBhDxPopulation,
        setHgbA1cForBhDxPopulation,
        hgbA1cForBhDxTeam,
        setHgbA1cForBhDxTeam,
        hgbA1cForBhDxDateFilter,
        setHgbA1cForBhDxDateFilter,
        hgbA1cForBhDxFromDate,
        setHgbA1cForBhDxFromDate,
        hgbA1cForBhDxToDate,
        setHgbA1cForBhDxToDate,
        hgbA1cForBhDxLoadedTeam,
        setHgbA1cForBhDxLoadedTeam,
        hgbA1cForBhDxIncludePastOpenStatus,
        setHgbA1cForBhDxIncludePastOpenStatus,
        hgbA1cForBhDxShowInActiveTeam,
        setHgbA1cForBhDxShowInActiveTeam,

        lipidForBhDxPopulation,
        setLipidForBhDxPopulation,
        lipidForBhDxTeam,
        setLipidForBhDxTeam,
        lipidForBhDxDateFilter,
        setLipidForBhDxDateFilter,
        lipidForBhDxFromDate,
        setLipidForBhDxFromDate,
        lipidForBhDxToDate,
        setLipidForBhDxToDate,
        lipidForBhDxLoadedTeam,
        setLipidForBhDxLoadedTeam,
        lipidForBhDxIncludePastOpenStatus,
        setLipidForBhDxIncludePastOpenStatus,
        lipidForBhDxShowInActiveTeam,
        setLipidForBhDxShowInActiveTeam,

        medicationReconciliationPopulation,
        setMedicationReconciliationPopulation,
        medicationReconciliationTeam,
        setMedicationReconciliationTeam,
        medicationReconciliationDateFilter,
        setMedicationReconciliationDateFilter,
        medicationReconciliationFromDate,
        setMedicationReconciliationFromDate,
        medicationReconciliationToDate,
        setMedicationReconciliationToDate,
        medicationReconciliationLoadedTeam,
        setMedicationReconciliationLoadedTeam,
        medicationReconciliationIncludePastOpenStatus,
        setMedicationReconciliationIncludePastOpenStatus,
        medicationReconciliationShowInActiveTeam,
        setMedicationReconciliationShowInActiveTeam,
    };

    return <HedisMetricsContext.Provider value={value}>{children}</HedisMetricsContext.Provider>;
};

export const useHedisMetricsContext = () => React.useContext(HedisMetricsContext);
