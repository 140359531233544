import { Alert, Box, Button, Grid, Slider, Snackbar, Typography } from '@mui/material';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    getMemberDevices,
    getVitalSignParameters,
    insertVitalSignParameters,
    setDiastolicBPValue,
    setGlucometerValue,
    setHeartRateValue,
    setOpenAlert,
    setOximeterValue,
    setScaleValue,
    setSystolicBPValue,
} from '../../../store/remoteMonitoring.slice';

const RemoteMonitoring = (props: any) => {
    const memberId = props.memberId;
    const auth = props.auth;
    const dispatch = useDispatch();
    const {
        alertMessage,
        diastolicBPValue,
        glucometerValue,
        heartRateValue,
        memberDevices,
        openAlert,
        oximeterValue,
        scaleValue,
        severity,
        systolicBPValue,
        vitalSignParameters,
    } = useSelector((state: RootState) => state.remoteMonitoring);

    const updateRemoteMonitoringHandler = () => {
        let copy = [];
        vitalSignParameters.forEach((el, index) => {
            if (el.Type === 1) {
                copy = [...copy, { ...copy[index], MemberId: el.MemberId, Type: el.Type, Min: systolicBPValue[0], Max: systolicBPValue[1] }];
            }
            if (el.Type === 2) {
                copy = [...copy, { ...copy[index], MemberId: el.MemberId, Type: el.Type, Min: diastolicBPValue[0], Max: diastolicBPValue[1] }];
            }
            if (el.Type === 3) {
                copy = [...copy, { ...copy[index], MemberId: el.MemberId, Type: el.Type, Min: heartRateValue[0], Max: heartRateValue[1] }];
            }
            if (el.Type === 4) {
                copy = [...copy, { ...copy[index], MemberId: el.MemberId, Type: el.Type, Min: oximeterValue[0], Max: oximeterValue[1] }];
            }
            if (el.Type === 5) {
                copy = [...copy, { ...copy[index], MemberId: el.MemberId, Type: el.Type, Min: scaleValue[0], Max: scaleValue[1] }];
            }
            if (el.Type === 6) {
                copy = [...copy, { ...copy[index], MemberId: el.MemberId, Type: el.Type, Min: glucometerValue[0], Max: glucometerValue[1] }];
            }
        });
        dispatch(insertVitalSignParameters(copy));
    };

    useEffect(() => {
        dispatch(getMemberDevices(memberId));
        dispatch(getVitalSignParameters(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId]);

    return (
        <Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                autoHideDuration={3000}
                onClose={() => dispatch(setOpenAlert(false))}
            >
                <Alert variant="filled" onClose={() => dispatch(setOpenAlert(false))} severity={severity ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {!memberDevices && (
                <Grid item xs={8}>
                    <Typography display={'inline-block'} variant="h6">
                        No Telemonitoring Devices Found
                    </Typography>
                </Grid>
            )}
            <Grid container marginTop={1} spacing={5} style={{ padding: '16px 16px 16px 16px' }}>
                {memberDevices['BpCuff'] && memberDevices['BpCuff']?.HasDevice && (
                    <>
                        <Grid item xs={6}>
                            <Slider
                                min={0}
                                max={200}
                                value={systolicBPValue}
                                valueLabelDisplay="on"
                                marks={[
                                    { value: 0, label: '0' },
                                    { value: 200, label: '200' },
                                ]}
                                onChange={(event: Event, newValue: number | number[]) => {
                                    dispatch(setSystolicBPValue(newValue as number[]));
                                }}
                                disabled={auth.Role !== 'Nurse Practitioner'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" marginRight={5} gutterBottom>
                                Systolic BP
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Slider
                                min={40}
                                max={140}
                                value={diastolicBPValue}
                                valueLabelDisplay="on"
                                marks={[
                                    { value: 40, label: '40' },
                                    { value: 140, label: '140' },
                                ]}
                                onChange={(event: Event, newValue: number | number[]) => {
                                    dispatch(setDiastolicBPValue(newValue as number[]));
                                }}
                                disabled={auth.Role !== 'Nurse Practitioner'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" marginRight={5} gutterBottom>
                                Diastolic BP
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Slider
                                min={10}
                                max={200}
                                value={heartRateValue}
                                valueLabelDisplay="on"
                                marks={[
                                    { value: 10, label: '10' },
                                    { value: 200, label: '200' },
                                ]}
                                onChange={(event: Event, newValue: number | number[]) => {
                                    dispatch(setHeartRateValue(newValue as number[]));
                                }}
                                disabled={auth.Role !== 'Nurse Practitioner'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" marginRight={5} gutterBottom>
                                Heart Rate
                            </Typography>
                        </Grid>
                    </>
                )}
                {memberDevices['PulseOX'] && memberDevices['PulseOX']?.HasDevice && (
                    <>
                        <Grid item xs={6}>
                            <Slider
                                min={0}
                                max={100}
                                value={oximeterValue}
                                valueLabelDisplay="on"
                                marks={[
                                    { value: 0, label: '0%' },
                                    { value: 100, label: '100%' },
                                ]}
                                onChange={(event: Event, newValue: number | number[]) => {
                                    dispatch(setOximeterValue(newValue as number[]));
                                }}
                                disabled={auth.Role !== 'Nurse Practitioner'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" marginRight={5} gutterBottom>
                                Oximeter
                            </Typography>
                        </Grid>
                    </>
                )}
                {memberDevices['Scale'] && memberDevices['Scale']?.HasDevice && (
                    <>
                        <Grid xs={6}>
                            <Slider
                                min={0}
                                max={10}
                                value={scaleValue}
                                valueLabelDisplay="on"
                                marks={[
                                    { value: 0, label: '0' },
                                    { value: 10, label: '10' },
                                ]}
                                onChange={(event: Event, newValue: number | number[]) => {
                                    dispatch(setScaleValue(newValue as number[]));
                                }}
                                disabled={auth.Role !== 'Nurse Practitioner'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" marginRight={5} gutterBottom>
                                Scale
                            </Typography>
                        </Grid>
                    </>
                )}
                {memberDevices['Glucometer'] && memberDevices['Glucometer']?.HasDevice && (
                    <>
                        <Grid item xs={6}>
                            <Slider
                                min={50}
                                max={350}
                                value={glucometerValue}
                                valueLabelDisplay="on"
                                marks={[
                                    { value: 50, label: '50' },
                                    { value: 350, label: '350' },
                                ]}
                                onChange={(event: Event, newValue: number | number[]) => {
                                    dispatch(setGlucometerValue(newValue as number[]));
                                }}
                                disabled={auth.Role !== 'Nurse Practitioner'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontWeight="bold" marginRight={5} gutterBottom>
                                Glucometer
                            </Typography>
                        </Grid>
                    </>
                )}

                {memberDevices && (
                    <Grid item textAlign={'right'} xs={7}>
                        <Button
                            variant="contained"
                            className="button-120"
                            disabled={auth.Role !== 'Nurse Practitioner'}
                            onClick={() => updateRemoteMonitoringHandler()}
                        >
                            Update
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default RemoteMonitoring;
