export enum TeamTypeEnum {
    CareCoordinator = 1,
    CareCoordinatorDescription = 'Care Coordinator',
    NursePractitioner = 2,
    NursePractitionerDescription = 'Nurse Practitioner',
    BehavioralHealth = 3,
    BehavioralHealthDescription = 'Behavioral Health',
    ClinicalLiaison = 4,
    ClinicalLiaisonDescription = 'Clinical Liaison',
    CommunityHealthWorker = 5,
    CommunityHealthWorkerDescription = 'Community Health Worker',
}
