import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { IDeleteResourceModalProps } from './interfaces/IDeleteResourceModalProps';

const DeleteResourceModal: React.FC<IDeleteResourceModalProps> = (props) => {
    const { open, title, onSuccess, onClose } = props;
    return (
        <Dialog open={open}>
            <DialogTitle>Are you sure you want to delete "{title}"?</DialogTitle>
            <DialogActions>
                <Button onClick={onSuccess}>Yes</Button>
                <Button onClick={onClose}>No</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteResourceModal;
