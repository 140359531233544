import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';

const smartDashboard = createSlice({
    name: 'smartDashboard',
    initialState: {
        showWidgetsMenu: false,
        customTabsList: [],
        currentRender: null,
        selectedTab: 0,
        selectedTabModal: 0,
        startingRoleWidgets: [],
        widgetsToRender: [],
        isWidgetExisting: true,
        widgetFromMetricSummary: '',
    },
    reducers: {
        _setCustomTabsList(state, action) {
            state.customTabsList = action.payload;
        },
        _setCurrentRender(state, action) {
            state.currentRender = action.payload;
        },
        _setShowWidgetsMenu(state, action) {
            state.showWidgetsMenu = action.payload;
        },
        _setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
        _setSelectedTabModal(state, action) {
            state.selectedTabModal = action.payload;
        },
        _setStartingRoleWidgets(state, action) {
            state.startingRoleWidgets = action.payload;
        },
        _setWidgetsToRender(state, action) {
            state.widgetsToRender = action.payload;
        },
        _setIsWidgetExisting(state, action) {
            state.isWidgetExisting = action.payload;
        },
        _setWidgetFromMetricSummary(state, action) {
            state.widgetFromMetricSummary = action.payload;
        },
    },
});

const {
    _setCustomTabsList,
    _setSelectedTab,
    _setSelectedTabModal,
    _setShowWidgetsMenu,
    _setCurrentRender,
    _setStartingRoleWidgets,
    _setWidgetsToRender,
    _setIsWidgetExisting,
    _setWidgetFromMetricSummary,
} = smartDashboard.actions;

export const getStartingRoleWidgets =
    (roleName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/role/roles`);
            const role = response.data.filter((r) => r.Name === roleName)[0].Widgets.map((widget) => widget.Name);
            dispatch(_setStartingRoleWidgets(role));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setStartingRoleWidgets([]));
            dispatch(setIsLoading(false));
        }
    };

export const getCustomDashboardTabs =
    (providerId: string, roleName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/provider/getapplicablewidgets?ProviderID=${providerId}&RoleName=${roleName}`);
            dispatch(setCustomTabsList(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export const putApplicableWidgets =
    (payload): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.post(`${API_URL}/provider/setapplicablewidgets`, payload);
            dispatch(_setCustomTabsList(response?.data.CustomDashboardWidgets));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setCustomTabsList([]));
            dispatch(setIsLoading(false));
        }
    };

export const setSelectedTab =
    (value: number): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedTab(value));
    };

export const setSelectedTabModal =
    (value: number): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedTabModal(value));
    };

export const setCurrentRender =
    (value: any): AppThunk =>
    (dispatch) => {
        dispatch(_setCurrentRender(value));
    };

export const setCustomTabsList =
    (value: any): AppThunk =>
    (dispatch) => {
        dispatch(_setCustomTabsList(value));
    };

export const setShowWidgetsMenu =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowWidgetsMenu(value));
    };

export const setWidgetsToRender =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setWidgetsToRender(value));
    };

export const setIsWidgetExisting =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setIsWidgetExisting(value));
    };

export const setWidgetFromMetricSummary =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setWidgetFromMetricSummary(value));
    };

export default smartDashboard.reducer;
