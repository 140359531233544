import BaseEntity from '../BaseEntity.model';
import { Medications } from './Medications.model';

export default interface IMedicationManagement extends BaseEntity {
    Id: string;
    MemberId: string;
    Percentage: number;
    NoMedication: boolean;
    Medications: Medications[];
}

export class MedicationManagement implements IMedicationManagement {
    Id: string;
    MemberId: string;
    Percentage: number;
    NoMedication: boolean;
    Medications: Medications[];

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.NoMedication = false;
        this.Medications = [] as Medications[];
    }
}
