import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { apiString as API_URL } from '../../../utils/constants';
import { dictionaryToArray } from '../../../utils/mappings';
import { monthNames } from '../../../utils/constants';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { setHealthPlanForm } from '../../../store/adminHealthPlan.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { TimePeriodBeginEnum } from '../../../Enum/TimePeriodBeginEnum';

const ContractTerms = (props) => {
    const dispatch = useDispatch();

    const monthOfBeginList = (length) => {
        let list = [];
        for (let i = 0; i < length; i++) {
            list.push(i);
        }
        return list;
    };

    const { healthPlanForm } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const [termOfContract, setTermOfContract] = useState([]);
    const [paymentType, setPaymentType] = useState([]);

    const getTermsOfContract = async () => {
        const response = await axios.get(`${API_URL}/managehealthplan/gettermofcontract`);
        setTermOfContract(response.data);
    };

    const getPaymentType = async () => {
        const response = await axios.get(`${API_URL}/managehealthplan/getpaymenttype`);
        setPaymentType(response.data);
    };
    console.log('healthPlanForm', healthPlanForm);
    useEffect(() => {
        getTermsOfContract();
        getPaymentType();
    }, []);

    return (
        <>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Term of Contract</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={healthPlanForm.TermOfContract || ''}
                        label="Term of Contract"
                        name="TermOfContract"
                        onChange={(e) =>
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    TermOfContract: Number(e.target.value),
                                })
                            )
                        }
                    >
                        {dictionaryToArray(termOfContract).map((term) => {
                            return (
                                <MenuItem key={term.value} value={term.value}>
                                    {term.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date of Contract"
                        value={healthPlanForm.DateOfContract}
                        onChange={(newValue) =>
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    DateOfContract: newValue,
                                })
                            )
                        }
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.SharedSavings ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            SharedSavings: e.target.checked,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Shared Savings"
                    />
                </FormControl>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    value={healthPlanForm.TargetMembersVolume || ''}
                    label="Target Volume Of Members"
                    name="TargetMembersVolume"
                    type="number"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                        dispatch(
                            setHealthPlanForm({
                                ...healthPlanForm,
                                TargetMembersVolume: e.target.value,
                            })
                        )
                    }
                />
            </Grid>

            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Payment Date</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={healthPlanForm.PaymentDate || ''}
                        label="Payment Date"
                        name="PaymentDate"
                        onChange={(e) =>
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    PaymentDate: e.target.value,
                                })
                            )
                        }
                        MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                    >
                        {dictionaryToArray(monthOfBeginList(31)).map((month) => {
                            return (
                                <MenuItem key={month.value} value={month.label + 1}>
                                    {month.label + 1}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={3}>
                <FormControl>
                    <InputLabel htmlFor="pay-rate">Pay Rate</InputLabel>
                    <OutlinedInput
                        id="pay-rate"
                        type="number"
                        name="PayRate"
                        fullWidth
                        value={healthPlanForm.PayRate || ''}
                        onChange={(e) =>
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    PayRate: e.target.value,
                                })
                            )
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton aria-label="Pay Rate" edge="start">
                                    <AttachMoneyIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Pay Rate"
                    />
                </FormControl>
            </Grid>

            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={healthPlanForm.PaymentType || ''}
                        label="Payment Type"
                        name="PaymentType"
                        onChange={(e) =>
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    PaymentType: e.target.value,
                                })
                            )
                        }
                    >
                        {dictionaryToArray(paymentType).map((type) => {
                            return (
                                <MenuItem key={type.value} value={Number(type.value)}>
                                    {type.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    value={healthPlanForm.CHPAccountRepresentative || ''}
                    label="CHP Account Representative"
                    name="CHPAccountRepresentative"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                        dispatch(
                            setHealthPlanForm({
                                ...healthPlanForm,
                                CHPAccountRepresentative: e.target.value,
                            })
                        )
                    }
                />
            </Grid>

            <Grid item xs={12} marginTop={2}>
                <Typography variant="h5">Onboarding Period</Typography>
            </Grid>

            <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={healthPlanForm.OnboardingPeriod?.StartDate || null}
                        onChange={(newValue) => {
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    OnboardingPeriod: {
                                        ...healthPlanForm.OnboardingPeriod,
                                        StartDate: newValue,
                                    },
                                })
                            );
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="End Date"
                        value={healthPlanForm.OnboardingPeriod?.EndDate || null}
                        onChange={(newValue) => {
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    OnboardingPeriod: {
                                        ...healthPlanForm.OnboardingPeriod,
                                        EndDate: newValue,
                                    },
                                })
                            );
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} marginTop={2}>
                <Typography variant="h5">Management Window</Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Time Period Beginning</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={healthPlanForm.ManagementWindow?.TimePeriodBegin || ''}
                        name="TimePeriodBegin"
                        onChange={(e) =>
                            dispatch(
                                setHealthPlanForm({
                                    ...healthPlanForm,
                                    ManagementWindow: {
                                        ...healthPlanForm.ManagementWindow,
                                        TimePeriodBegin: e.target.value,
                                    },
                                })
                            )
                        }
                    >
                        <FormControlLabel value="Begins on a month" control={<Radio />} label="Begins on a month" />
                        <FormControlLabel
                            value="Begins on month following onboarding date"
                            control={<Radio />}
                            label="Begins on month following onboarding date"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                {healthPlanForm.ManagementWindow?.TimePeriodBegin === TimePeriodBeginEnum.Begins_On_Month && (
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Begins on a month</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={healthPlanForm.ManagementWindow.DateOfBegin || ''}
                                label="Begins on a month"
                                name="DateOfBegin"
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            ManagementWindow: {
                                                ...healthPlanForm.ManagementWindow,
                                                DateOfBegin: e.target.value,
                                            },
                                        })
                                    )
                                }
                                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                            >
                                {dictionaryToArray(monthNames).map((month) => {
                                    return (
                                        <MenuItem key={month.value} value={Number(month.value) + 1}>
                                            1st of {month.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {healthPlanForm.ManagementWindow?.TimePeriodBegin === TimePeriodBeginEnum.Begins_On_Onboarding_Date && (
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Months after Onboarding</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={healthPlanForm.ManagementWindow.MonthOfBegin || ''}
                                label="Months after Onboarding"
                                name="MonthOfBegin"
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            ManagementWindow: {
                                                ...healthPlanForm.ManagementWindow,
                                                MonthOfBegin: e.target.value,
                                            },
                                        })
                                    )
                                }
                                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                            >
                                {dictionaryToArray(monthOfBeginList(24)).map((month) => {
                                    return (
                                        <MenuItem key={month.value} value={month.label + 1}>
                                            {month.label + 1}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ContractTerms;
