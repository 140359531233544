import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import moment from 'moment';
import { copyObject } from '../utils/common';
import { setIsLoading } from './shared.slice';
import { GridRowId } from '@mui/x-data-grid';
import { getLocalMemberStatus } from '../utils/localStorageUtils';

const getDefault = () => {
    return {
        Address1: '',
        City: '',
        State: '',
        ZipCode: '',
        Country: '',
        DOB: null,
        DateOfBirth: '',
        FirstName: '',
        Gender: 1,
        Id: '',
        InsuranceType: -1,
        LastName: '',
        MiddleName: '',
        Mem_AHCCCS_ID: '',
        MemberId: '',
        Phones: [],
        Population: -1,
        PrimaryHealthPlan: { Id: null, Name: '' },
        Status: 1,
    };
};

let tmp = getDefault();

const adminMembers = createSlice({
    name: 'adminMembers',
    initialState: {
        rowData: [],
        editing: false,
        filterBy: getLocalMemberStatus(),
        form: tmp,
        formIsValid: true,
        population: [],
        open: false,
        openDelete: false,
        openEdit: false,
        deleteHelper: '',
        includeArchivedMembers: false,
    },
    reducers: {
        _setDeleteHelper(state, action) {
            state.deleteHelper = action.payload;
        },
        _setEditing(state, action) {
            state.editing = action.payload;
        },
        _setFilterBy(state, action) {
            state.filterBy = action.payload;
        },
        _setForm(state, action) {
            state.form = action.payload;
        },
        _setFormIsValid(state, action) {
            state.formIsValid = action.payload;
        },
        _setOpen(state, action) {
            state.open = action.payload;
        },
        _setOpenDelete(state, action) {
            state.openDelete = action.payload;
        },
        _setOpenEdit(state, action) {
            state.openEdit = action.payload;
        },
        _setPopulation(state, action) {
            state.population = action.payload;
        },
        _setRowData(state, action) {
            state.rowData = action.payload;
        },
        _setIncludeArchivedMembers(state, action) {
            state.includeArchivedMembers = action.payload;
        },
    },
});

const {
    _setDeleteHelper,
    _setEditing,
    _setFilterBy,
    _setForm,
    _setFormIsValid,
    _setOpen,
    _setOpenDelete,
    _setOpenEdit,
    _setPopulation,
    _setRowData,
    _setIncludeArchivedMembers,
} = adminMembers.actions;

export const setIncludeArchivedMembers =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIncludeArchivedMembers(value));
    };

export const setRowData =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRowData(value));
    };

export const setFilterBy =
    (statuses: string[]): AppThunk =>
    (dispatch) => {
        dispatch(_setFilterBy(statuses));
    };

export const setForm =
    (value: object): AppThunk =>
    (dispatch) => {
        dispatch(_setForm(value));
    };

export const setFormIsValid =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setFormIsValid(value));
    };

export const setPopulation =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setPopulation(value));
    };

export const setOpen =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpen(value));
    };

export const setOpenDelete =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenDelete(value));
    };

export const setOpenEdit =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenEdit(value));
    };

export const setEditing =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setEditing(value));
    };

export const setDeleteHelper =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setDeleteHelper(value));
    };

export const clearValues = (): AppThunk => (dispatch) => {
    dispatch(setForm(tmp));
    dispatch(setFormIsValid(true));
    dispatch(setDeleteHelper(''));
};

export const closeOpenDelete = (): AppThunk => (dispatch) => {
    dispatch(setOpenDelete(false));
    dispatch(clearValues());
};

export const deleteHandler =
    (params): AppThunk =>
    (dispatch) => {
        dispatch(setForm({ ...tmp, FirstName: params.row.FirstName, LastName: params.row.LastName, Id: params.row.Id }));
        dispatch(setOpenDelete(true));
    };

export const insertMember =
    (formIsValid: boolean, formData: any, filterBy: string): AppThunk =>
    async (dispatch) => {
        if (formIsValid) {
            try {
                dispatch(setIsLoading(true));
                let copyData = copyObject(formData);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                copyData.DateOfBirth = moment(copyData.DOB).format('MM/DD/yyyy');
                await axios.put(`${API_URL}/member/insertmember`, copyData);
                dispatch(setIsLoading(false));
                dispatch(getMembers(filterBy));
                dispatch(handleClose());
            } catch (error) {
                console.log(error);
                dispatch(setIsLoading(false));
            }
        } else {
            dispatch(setFormIsValid(false));
            return;
        }
    };

export const handleEditClose = (): AppThunk => (dispatch) => {
    dispatch(setOpenEdit(false));
    dispatch(clearValues());
};

export const handleClose = (): AppThunk => (dispatch) => {
    dispatch(setOpen(false));
    dispatch(clearValues());
};

export const handleOpen = (): AppThunk => (dispatch) => {
    dispatch(setOpen(true));
    dispatch(setEditing(false));
};

export const getMembers =
    (filterBy: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(
                `${API_URL}/member/getmembersbystatus?statuses=${filterBy}&includeArchivedMembers=${getState().adminMembers.includeArchivedMembers}`
            );
            dispatch(_setRowData(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
        }
    };

export const fetchPopulationList = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/population/GetPopulations`);
    dispatch(setPopulation(response.data));
};

export const fetchMemberDemographicInfo =
    (memberId: GridRowId): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/member/getmemberdemographicinfo?memberId=${memberId}`);
        dispatch(setForm(response.data));
        dispatch(setEditing(true));
        dispatch(setOpenEdit(true));
    };

export const deleteMember =
    (form, passwordRef, filterBy: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.delete(`${API_URL}/member/deletemember?memberId=${form.Id}&password=${passwordRef.current.value}`);
            dispatch(setIsLoading(false));
            dispatch(getMembers(filterBy));
            dispatch(closeOpenDelete());
        } catch (error) {
            console.log(error);
            if (error) {
                dispatch(setDeleteHelper('Invalid password'));
            }
            dispatch(setIsLoading(false));
        }
    };

export const updateMember =
    (formIsValid: boolean, formData: any, filterBy: string): AppThunk =>
    async (dispatch) => {
        if (formIsValid) {
            try {
                dispatch(setIsLoading(true));
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                let copyData = copyObject(formData);
                copyData.DateOfBirth = moment(copyData.DOB).format('MM/DD/yyyy');
                await axios.put(`${API_URL}/member/updatemember`, copyData);
                dispatch(setIsLoading(false));
                dispatch(getMembers(filterBy));
                dispatch(handleEditClose());
            } catch (error) {
                console.log(error);
                dispatch(setIsLoading(false));
            }
        } else {
            dispatch(setFormIsValid(false));
            return;
        }
    };

export default adminMembers.reducer;
