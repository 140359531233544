import {
    Alert,
    Box,
    Stack,
    FormControl,
    Grid,
    InputLabel,
    Typography,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Snackbar,
    Tab,
    Tabs,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { RootState } from '../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveStatusMembers, fetchMemberStatuses } from '../../../../store/members.slice';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import {
    setSelectedMember,
    patchMapToMember,
    clearSelections,
    setShowArchivedAlerts,
    closeConfirmationModalHandler,
    getUnmatchedAlerts,
    openConfirmationHandler,
    setTeamNotificationModalOpen,
    setConfirmationModalOpen,
} from '../../../../store/hie.slice';
import Button from '@mui/material/Button';
import { Search } from '@mui/icons-material';
import Authorization from '../../../../utils/Authorization';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import TeamNotificationModal from '../../Modals/TeamNotificationModal';
import ADTAlerts from './AlertTypes/ADTAlerts/ADTAlerts';
import MIHAAlerts from './AlertTypes/MIHAAlerts/MIHAAlerts';
import CovidAlerts from './AlertTypes/CovidAlerts/CovidAlerts';

import MedicaidCensus from './AlertTypes/MedicaidCensus/MedicaidCensus';
import { apiString as API_URL, parentUrl } from '../../../../utils/constants';
import axios from 'axios';
import { setIsLoading } from '../../../../store/shared.slice';
import SelectedMember from '../../SelectedMember/SelectedMember';
import OutpatientAlerts from './AlertTypes/OutpatientAlerts/OutpatientAlerts';
import LabAlerts from './AlertTypes/LabAlerts/LabAlerts';
import FuhFumOpenAlerts from './AlertTypes/FuhFum/FuhFumOpen';

const Alerts = () => {
    const auth = new Authorization();
    const dispatch = useDispatch();
    const { members } = useSelector((state: RootState) => state.members);
    const history = useNavigate();

    const {
        alertsList,
        confirmationModalAction,
        confirmationModalMessage,
        confirmationModalOpen,
        rowSelectionModel,
        selectedMember,
        showArchivedAlerts,
        teamNotificationModalOpen,
        top3Matches,
        unmatchedAlerts,
    } = useSelector((state: RootState) => state.hie);

    const [memberSearchValue, setMemberSearchValue] = useState('');
    const [allMembers, setAllMembers] = useState([]);
    const [memberSearchTab, setMemberSearchTab] = useState(0);

    const [currentAlertTab, setCurrentAlertTab] = useState('ADT Alerts');

    const [openHelper, setOpenHelper] = useState(false);
    const [helperMessage, setHelperMessage] = useState('');

    const HIETabs = [
        { label: 'ADT Alerts', value: <ADTAlerts /> },
        { label: 'MIHA Alerts', value: <MIHAAlerts /> },
        { label: 'Outpatient Alerts', value: <OutpatientAlerts /> },
        { label: 'COVID Alerts', value: <CovidAlerts /> },
        { label: 'Medicaid Census', value: <MedicaidCensus /> },
        { label: 'Lab Alerts', value: <LabAlerts /> },
        { label: 'FUFHUM Open Status', value: <FuhFumOpenAlerts /> },
    ];

    const handleCloseHelper = () => {
        setOpenHelper(false);
    };

    const handleOpenHelper = (message) => {
        setHelperMessage(message);
        setOpenHelper(true);
    };

    const [fileIsValid, setFileIsValid] = useState(true);
    const uploadMedicaidCensusHandler = async (e) => {
        let formData = new FormData();
        let file = e.target.files[0];

        formData.append('File', file);
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/HIE/addmedicaidfiletodatabase`, formData);
            if (response.status === 200) {
                dispatch(getUnmatchedAlerts());
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log('ERROR: ', error);
            dispatch(setIsLoading(false));
        }
        setFileIsValid(true);
    };

    const closeTeamNotificationModalHandler = () => {
        dispatch(patchMapToMember(selectedMember.Id, auth.UserName, rowSelectionModel));
        const endpoint =
            currentAlertTab === 'Outpatient Alerts' || currentAlertTab === 'Lab Alerts' || currentAlertTab === 'COVID Alerts'
                ? 'documents'
                : 'transitional-care';
        window.open(`${parentUrl}provider/members-list/member-chart/${selectedMember.Id}/${endpoint}`, '_blank');
        dispatch(setTeamNotificationModalOpen(false));
    };

    const handleMemberSearchTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        dispatch(clearSelections());
        setMemberSearchTab(newValue);
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        dispatch(clearSelections());
        setCurrentAlertTab(newValue);
    };

    const matchSelectedMemberHandler = () => {
        if (selectedMember === null && rowSelectionModel.length === 0) {
            handleOpenHelper('No Alert or CHP Member selected for matching. Select an Alert(s) and CHP Member to match.');
        } else if (rowSelectionModel.length === 0) {
            handleOpenHelper('No alerts selected for matching. Click the checkbox next to the alert(s) you wish to match.');
        } else if (selectedMember === null) {
            handleOpenHelper('No CHP Member selected for matching. Click a row to get Top 3 Matches or Search All Members.');
        } else {
            dispatch(setTeamNotificationModalOpen(true));
        }
    };

    const deleteMultipleAlertsHandler = () => {
        const alerts = unmatchedAlerts.filter((el) => rowSelectionModel.includes(el.Id));
        if (rowSelectionModel.length > 0) {
            dispatch(openConfirmationHandler('', '', 'Delete', alerts, rowSelectionModel));
        } else {
            handleOpenHelper('You must select at least one alert to delete.');
        }
    };

    const archiveMultipleAlertsHandler = () => {
        const alerts = unmatchedAlerts.filter((el) => rowSelectionModel.includes(el.Id));
        if (rowSelectionModel.length > 0) {
            dispatch(openConfirmationHandler('', '', 'Archive', alerts, rowSelectionModel));
        } else {
            handleOpenHelper('You must select at least one alert to archive.');
        }
    };

    const executeSearch = (event: any) => {
        if (!event.key || event.key === 'Enter') {
            var tmp = [];
            const searchValue = memberSearchValue.toLowerCase();
            for (const member of members) {
                if (
                    (member.LastName && member.LastName.toLowerCase().indexOf(searchValue) > -1) ||
                    (member.FirstName && member.FirstName.toLowerCase().indexOf(searchValue) > -1)
                ) {
                    tmp.push(member);
                }
            }
            setAllMembers(tmp);
        }
    };

    useEffect(() => {
        dispatch(getUnmatchedAlerts());

        dispatch(fetchActiveStatusMembers());

        dispatch(fetchMemberStatuses());
    }, [dispatch]);

    useEffect(() => {
        if (members.length > 0) {
            setAllMembers(members);
        }
    }, [members]);

    const top3MatchesColumns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',

            flex: 1,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',

            flex: 1,
        },
    ];

    const navigateToFuhFum = () => {
        history(`fuhfum`);
    };

    return (
        <>
            <ConfirmationModal
                confirmationModalMessage={confirmationModalMessage}
                handleClose={() => dispatch(closeConfirmationModalHandler())}
                handleCancel={() => dispatch(setConfirmationModalOpen(false))}
                open={confirmationModalOpen}
                handleAction={confirmationModalAction}
                alertsList={alertsList}
            />
            <TeamNotificationModal
                selectedMember={selectedMember}
                handleMatch={closeTeamNotificationModalHandler}
                handleCancel={() => dispatch(setTeamNotificationModalOpen(false))}
                open={teamNotificationModalOpen}
                alertsList={unmatchedAlerts.filter((alert) => rowSelectionModel.includes(alert.Id))}
            />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openHelper} autoHideDuration={6000} onClose={handleCloseHelper}>
                <Alert sx={{ width: '100%' }} variant="filled" elevation={6} severity="error" onClose={handleCloseHelper}>
                    {helperMessage}
                </Alert>
            </Snackbar>
            <Box>
                <Grid container paddingBottom={0}>
                    <Stack direction={'row'} width="100%" spacing={4} paddingX={2} paddingTop={2} sx={{ zIndex: 10, background: '#ffffff' }}>
                        <Grid item xs={3}>
                            <Tabs variant="fullWidth" value={memberSearchTab} onChange={handleMemberSearchTabChange}>
                                <Tab value={0} label="Top 3 Matches" />
                                <Tab value={1} label="Search All Members" />
                            </Tabs>
                            {memberSearchTab === 0 && (
                                <div style={{ width: '100%', marginTop: '16px' }}>
                                    <DataGrid
                                        onCellClick={(params: any) => {
                                            dispatch(setSelectedMember(params.row));
                                        }}
                                        autoHeight
                                        rows={top3Matches}
                                        columns={top3MatchesColumns}
                                        pageSize={3}
                                        rowsPerPageOptions={[5]}
                                        getRowId={(row) => row.Id}
                                        sx={{
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1,
                                            },
                                        }}
                                    />
                                </div>
                            )}
                            {memberSearchTab === 1 && (
                                <>
                                    <FormControl fullWidth style={{ marginTop: '16px', marginBottom: '16px' }}>
                                        <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type="text"
                                            value={memberSearchValue}
                                            onChange={(e) => {
                                                setMemberSearchValue(e.target.value as string);
                                            }}
                                            onKeyUp={(event: any) => {
                                                executeSearch(event);
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="Search" onClick={executeSearch} edge="end">
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Search"
                                        />
                                    </FormControl>
                                    <div style={{ width: '100%' }}>
                                        <DataGrid
                                            onCellClick={(params: any) => {
                                                dispatch(setSelectedMember(params.row));
                                            }}
                                            autoHeight
                                            rows={allMembers}
                                            columns={top3MatchesColumns}
                                            pageSize={3}
                                            rowsPerPageOptions={[5]}
                                            getRowId={(row) => row.Id}
                                            sx={{
                                                [`& .${gridClasses.cell}`]: {
                                                    py: 1,
                                                },
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            <Button fullWidth className="btn-120" variant="contained" onClick={matchSelectedMemberHandler} sx={{ marginTop: 2 }}>
                                Match Member to Alert&#40;s&#41;
                            </Button>
                        </Grid>

                        <SelectedMember selectedMember={selectedMember} />

                        <Grid item xs={2} textAlign="right">
                            <Stack spacing={2} alignItems={'center'}>
                                <form id={'upload-form'}>
                                    <Button size="small" color="success" variant="contained" id="upload-btn" component="label" sx={{ textAlign: 'center' }}>
                                        Upload Medicaid Census
                                        <input
                                            id="file"
                                            name="File"
                                            onChange={(e) => {
                                                uploadMedicaidCensusHandler(e);
                                            }}
                                            type="file"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            hidden
                                        />
                                    </Button>
                                </form>
                                {!fileIsValid && (
                                    <Typography variant="h6" color="error">
                                        Invalid File
                                    </Typography>
                                )}
                            </Stack>
                            <Stack spacing={2} paddingTop={2} alignItems={'center'}>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    component="label"
                                    sx={{ textAlign: 'center' }}
                                    onClick={navigateToFuhFum}
                                >
                                    UPLOAD FUHFUM
                                </Button>
                            </Stack>
                        </Grid>
                    </Stack>

                    <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} p={2}>
                        <Button className="btn-120" variant="contained" onClick={archiveMultipleAlertsHandler} sx={{ marginRight: 2 }}>
                            Archive Alert&#40;s&#41;
                        </Button>
                        <Button className="btn-120" color="error" variant="contained" onClick={deleteMultipleAlertsHandler} sx={{ marginRight: 2 }}>
                            Delete Alert&#40;s&#41;
                        </Button>
                    </Grid>
                    <Grid item xs={12} display={'flex'}>
                        <Tabs sx={{ marginTop: 2, display: 'inline-flex', marginRight: 'auto' }} value={currentAlertTab} onChange={handleAlertsTabChange}>
                            {HIETabs.map((tab, index) => {
                                return <Tab key={`${tab.label}_${index}`} value={tab.label} label={tab.label} />;
                            })}
                        </Tabs>
                        <FormControlLabel
                            control={<Checkbox checked={showArchivedAlerts} onChange={() => dispatch(setShowArchivedAlerts(!showArchivedAlerts))} />}
                            label="Include Archived Alerts"
                        />
                    </Grid>
                    <Grid item xs={12} p={2}>
                        <div style={{ width: '100%', height: '60vh' }}>
                            {HIETabs.map((tab, index) => {
                                return currentAlertTab === tab.label && <div key={`${tab.label}_${index}`}>{tab.value}</div>;
                            })}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Alerts;
