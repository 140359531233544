import {
    Grid,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableContainer,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchFuhFumClosedSummary, exportFuhFumClosedSummary } from '../../../Widgets/WidgetService';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import { TimePeriodFilterEnum } from '../../../../Enum/TimePeriodFilterEnum';
import { parentUrl } from '../../../../utils/constants';
import { isDefaultDate } from '../../../../utils/common';
import '../../Widgets.css';

const qualityList = [
    { Id: 1, Name: 'Pending Quality Check' },
    { Id: 2, Name: 'Completed Quality Check' },
];

const dayFilterList = [
    { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
    { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
    { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
    { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const FuhFumClosedSummaryDetail = () => {
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const {
        closedSelectedFilter,
        setClosedSelectedFilter,
        closedDateFilter,
        setClosedDateFilter,
        closedFromDate,
        setClosedFromDate,
        closedToDate,
        setClosedToDate,
    } = useWidgetContext();


    const getFuhFumSummary = async () => {
        if (closedDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(closedFromDate) && Boolean(closedToDate))) {
            setIsFetched(false);
            let dateFrom = Boolean(closedFromDate) && closedDateFilter === TimePeriodFilterEnum.Custom ? new Date(closedFromDate).toISOString() : '';
            let dateTo = Boolean(closedToDate) && closedDateFilter === TimePeriodFilterEnum.Custom ? new Date(closedToDate).toISOString() : '';
            const data = await fetchFuhFumClosedSummary(closedSelectedFilter, closedDateFilter, dateFrom, dateTo);
            setRows(data);
            setIsFetched(true);
        }

        if (closedDateFilter !== TimePeriodFilterEnum.Custom && Boolean(closedFromDate) && Boolean(closedToDate)) {
            setClosedFromDate(null);
            setClosedToDate(null);
        }
    };

    const changeFromDate = (date: any) => {
        setClosedFromDate(date);
    };

    const changeToDate = (date: any) => {
        setClosedToDate(date);
    };

    useEffect(() => {
        getFuhFumSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closedSelectedFilter, closedDateFilter, closedFromDate, closedToDate]);

    const backToWidget = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    const navigateToMemberDashboard = (id: any) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${id}/member-profile`, '_blank');
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let dateFrom = Boolean(closedFromDate) && closedDateFilter === TimePeriodFilterEnum.Custom ? new Date(closedFromDate).toISOString() : '';
        let dateTo = Boolean(closedToDate) && closedDateFilter === TimePeriodFilterEnum.Custom ? new Date(closedToDate).toISOString() : '';

        const data = await exportFuhFumClosedSummary(closedSelectedFilter, closedDateFilter, dateFrom, dateTo);
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `FuhFumClosedMembers - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Paper elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                    <Grid item xs={12} paddingBottom={1} alignItems={'center'} display={'flex'}>
                        <span onClick={backToWidget}>
                            <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                        </span>
                        <Typography display={'inline-block'} variant="h6" color={'primary'}>
                            FUHFUM Closed Summary
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container pl={2} mt={0} spacing={2}>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="provider-label">Quality Check</InputLabel>
                            <Select
                                id="demo-provider-select"
                                value={closedSelectedFilter}
                                label="Quality Check"
                                onChange={(e) => {
                                    setClosedSelectedFilter(e.target.value as string);
                                }}
                            >
                                <MenuItem key="All" value="0">
                                    All
                                </MenuItem>
                                {qualityList.map(function (day) {
                                    return (
                                        <MenuItem key={day.Id} value={day.Id}>
                                            {day.Name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Date Filter</InputLabel>
                            <Select
                                value={closedDateFilter}
                                label="Date Filter"
                                name="Date"
                                onChange={(e) => setClosedDateFilter(parseInt(e.target.value as any))}
                            >
                                {dayFilterList.map((date) => (
                                    <MenuItem key={date.Id} value={date.Id}>
                                        {date.Value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {closedDateFilter === TimePeriodFilterEnum.Custom ? (
                        <React.Fragment>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="From Date"
                                    value={Boolean(closedFromDate) ? closedFromDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeFromDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                            <Grid item md={1.5}>
                                <DesktopDatePicker
                                    label="To Date"
                                    value={Boolean(closedToDate) ? closedToDate : null}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => changeToDate(newValue)}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </Grid>
                        </React.Fragment>
                    ) : null}
                    <Grid item xs={closedDateFilter === TimePeriodFilterEnum.Custom ? 3.5 : 6.5}></Grid>
                    <Grid item xs={1}>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() => exportDetail()}
                            variant="contained"
                            color="success"
                            disabled={rows.length ? false : true}
                        >
                            {downLoading ? 'Exporting..' : 'Export'}
                        </Button>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                    <Grid item xs={12} minHeight="60vh" sx={{ position: 'relative' }}>
                        {!isFetched ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper} sx={{ maxHeight: '67vh' }}>
                                        <Table aria-label="customized table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCell>#</StyledTableCell>
                                                    <StyledTableCell>Member Name</StyledTableCell>
                                                    <StyledTableCell>DOB</StyledTableCell>
                                                    <StyledTableCell>Referral Date</StyledTableCell>
                                                    <StyledTableCell>Discharge Date</StyledTableCell>
                                                    <StyledTableCell>Facility</StyledTableCell>
                                                    <StyledTableCell>Admission Type</StyledTableCell>
                                                </StyledTableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.length ? (
                                                    rows.map((row, index) => (
                                                        <StyledTableRow className="pointer" onClick={() => navigateToMemberDashboard(row.Id)}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {index + 1}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.MemberName}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.DOB !== null && !isDefaultDate(row.DOB)
                                                                    ? moment(new Date(row.DOB)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.ReferralDate !== null && !isDefaultDate(row.ReferralDate)
                                                                    ? moment(new Date(row.ReferralDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {row.DischargeDate !== null && !isDefaultDate(row.DischargeDate)
                                                                    ? moment(new Date(row.DischargeDate)).format('MM/DD/yyyy')
                                                                    : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.Facility}</StyledTableCell>
                                                            <StyledTableCell>{row.FuhFumVisitTypeName}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row" colSpan={7}>
                                                            No data.
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </LocalizationProvider>
    );
};

export default FuhFumClosedSummaryDetail;
