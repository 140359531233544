import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';

export const getDefaultDocument = () => {
    return {
        Name: '',
        DocumentCategory: '',
        DocumentSignedOn: null,
        UploadedBy: { Id: '', Name: '' },
        LastUpdatedBy: { Id: '', Name: '' },
        MemberId: '',
        FileName: '',
        MetaTags: [{ Id: '', Name: '', CategoryId: '', IsSelected: false }],
        SubMetaTags: [{ Id: '', Name: '', CategoryId: '', IsSelected: false }],
    };
};

const memberDocuments = createSlice({
    name: 'memberDocuments',
    initialState: {
        documentObj: getDefaultDocument(),
        categoryId: '0',
        documentsByCategoryId: [],
        documentHasErrors: false,
        documentId: '',
        documentSubMetaTags: [],
        documentsTree: [],
        file: null,
        fileIsValid: true,
        fileName: '',
        hasLabs: false,
        hasRadiology: false,
        isSelected: 0,
        metaTag: '',
        metaTags: [],
        metaTagsByCategoryId: [],
        openAddDocument: false,
        openDeleteDocument: false,
        openMetaTags: false,
        openMoveDocuments: false,
        subMetaTags: [],
    },
    reducers: {
        _setCategoryId(state, action) {
            state.categoryId = action.payload;
        },
        _setDocumentObj(state, action) {
            state.documentObj = action.payload;
        },
        _setDocumentsByCategoryId(state, action) {
            state.documentsByCategoryId = action.payload;
        },
        _setDocumentHasErrors(state, action) {
            state.documentHasErrors = action.payload;
        },
        _setDocumentId(state, action) {
            state.documentId = action.payload;
        },
        _setDocumentsTree(state, action) {
            state.documentsTree = action.payload;
        },
        _setDocumentSubMetaTags(state, action) {
            state.documentSubMetaTags = action.payload;
        },
        _setFile(state, action) {
            state.file = action.payload;
        },
        _setFileIsValid(state, action) {
            state.fileIsValid = action.payload;
        },
        _setFileName(state, action) {
            state.fileName = action.payload;
        },
        _setHasLabs(state, action) {
            state.hasLabs = action.payload;
        },
        _setHasRadiology(state, action) {
            state.hasRadiology = action.payload;
        },
        _setIsSelected(state, action) {
            state.isSelected = action.payload;
        },
        _setMetaTags(state, action) {
            state.metaTags = action.payload;
        },
        _setMetaTag(state, action) {
            state.metaTag = action.payload;
        },
        _setMetaTagsByCategoryId(state, action) {
            state.metaTagsByCategoryId = action.payload;
        },
        _setOpenAddDocuments(state, action) {
            state.openAddDocument = action.payload;
        },
        _setOpenDeleteDocuments(state, action) {
            state.openDeleteDocument = action.payload;
        },
        _setOpenMetaTags(state, action) {
            state.openMetaTags = action.payload;
        },
        _setOpenMoveDocuments(state, action) {
            state.openMoveDocuments = action.payload;
        },
        _setSubMetaTags(state, action) {
            state.subMetaTags = action.payload;
        },
    },
});

const {
    _setCategoryId,
    _setDocumentsByCategoryId,
    _setDocumentHasErrors,
    _setDocumentId,
    _setDocumentObj,
    _setDocumentsTree,
    _setDocumentSubMetaTags,
    _setFile,
    _setFileIsValid,
    _setFileName,
    _setHasLabs,
    _setHasRadiology,
    _setIsSelected,
    _setMetaTag,
    _setMetaTags,
    _setMetaTagsByCategoryId,
    _setOpenAddDocuments,
    _setOpenDeleteDocuments,
    _setOpenMetaTags,
    _setOpenMoveDocuments,
    _setSubMetaTags,
} = memberDocuments.actions;

export const setCategoryId =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setCategoryId(value));
    };

export const setDocumentsByCategoryId =
    (value: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setDocumentsByCategoryId(value));
    };

export const setDocumentHasErrors =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setDocumentHasErrors(value));
    };

export const setDocumentId =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setDocumentId(value));
    };

export const setDocumentObj =
    (value: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setDocumentObj(value));
    };

export const setDocumentsTree =
    (value: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setDocumentsTree(value));
    };

export const setDocumentSubMetaTags =
    (value: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setDocumentSubMetaTags(value));
    };

export const setFile =
    (value: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setFile(value));
    };

export const setFileIsValid =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setFileIsValid(value));
    };

export const setFileName =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setFileName(value));
    };

export const setHasLabs =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setHasLabs(value));
    };

export const setHasRadiology =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setHasRadiology(value));
    };

export const setIsSelected =
    (value: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsSelected(value));
    };

export const setMetaTag =
    (value: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMetaTag(value));
    };

export const setMetaTags =
    (value: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setMetaTags(value));
    };

export const setMetaTagsByCategoryId =
    (value: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setMetaTagsByCategoryId(value));
    };

export const setOpenAddDocument =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAddDocuments(value));
    };

export const setOpenDeleteDocument =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenDeleteDocuments(value));
    };

export const setOpenMetaTags =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenMetaTags(value));
    };

export const setOpenMoveDocuments =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenMoveDocuments(value));
    };

export const setSubMetaTags =
    (value: any[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setSubMetaTags(value));
    };

export const changeDocumentCategory =
    (newCategoryId: string, documentId: string, categoryId: string, memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.put(`${API_URL}/document/movedocument?documentId=${documentId}&categoryId=${newCategoryId}`);
        } catch (error) {
            console.log(error);
        }
        dispatch(getDocumentByCategoryId(categoryId, memberId));
        dispatch(setOpenMoveDocuments(false));
    };

export const getDocumentCategoryAsTree = (): AppThunk => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/document/getdocumentcategoryastree`);
        dispatch(setDocumentsTree(response.data));
    } catch (error) {
        dispatch(setDocumentsTree([]));
        console.log(error);
    }
};

export const getDocumentSubMetaTags = (): AppThunk => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/document/getdocumentsubmetatags`);
        dispatch(setDocumentSubMetaTags(response.data));
    } catch (error) {
        dispatch(setDocumentSubMetaTags([]));
        console.log(error);
    }
};

export const getDocumentByCategoryId =
    (categoryId: string, memberId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/document/getdocumentsbycategoryid?categoryId=${categoryId}&memberId=${memberId}`);
            dispatch(setDocumentsByCategoryId(response.data));
        } catch (error) {
            dispatch(setDocumentsByCategoryId([]));
            console.log(error);
        }
        dispatch(setIsLoading(false));
    };

export const getMetaTagsHandler =
    (categoryId: string, metaTags: [], subMetaTags: []): AppThunk =>
    async (dispatch) => {
        let lab = metaTags?.find((el) => el['Name'] === 'Lab Reports');
        let radiology = metaTags?.find((el) => el['Name'] === 'Radiology');
        if (lab !== undefined) {
            dispatch(setHasLabs(true));
        } else {
            dispatch(setHasLabs(false));
        }
        if (radiology !== undefined) {
            dispatch(setHasRadiology(true));
        } else {
            dispatch(setHasRadiology(false));
        }
        dispatch(getMetaTagsByCategoryId(categoryId));
        dispatch(setMetaTags(metaTags));
        dispatch(setSubMetaTags(subMetaTags ?? []));
        dispatch(setOpenMetaTags(true));
    };

export const deleteDocument =
    (categoryId: string, memberId: string, documentId: string): AppThunk =>
    async (dispatch) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.delete(`${API_URL}/document/deletedocument?documentId=${documentId}`);
        } catch (error) {
            console.log(error);
        }
        dispatch(getDocumentByCategoryId(categoryId, memberId));
        dispatch(setOpenDeleteDocument(false));
    };

export const getMetaTagsByCategoryId =
    (categoryId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/document/getmetatagsbycategory?categoryId=${categoryId}`);
            dispatch(setMetaTagsByCategoryId(response.data));
        } catch (error) {
            dispatch(setMetaTagsByCategoryId([]));
            console.log(error);
        }
    };

export const updateDocumentMetaTags =
    (documentId: string, categoryId: string, memberId: string, metaTag, subMetaTags): AppThunk =>
    async (dispatch) => {
        const payload = { metaTag, subMetaTags };
        console.log('payload: ', payload);
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.put(`${API_URL}/document/updatedocumentmetatags?documentId=${documentId}`, payload);
        } catch (error) {
            console.log(error);
        }
        dispatch(getDocumentByCategoryId(categoryId, memberId));
        dispatch(setOpenMetaTags(false));
    };

export const addNewDocumentHandler = (): AppThunk => async (dispatch) => {
    dispatch(setFile(null));
    dispatch(setFileIsValid(true));
    dispatch(setDocumentHasErrors(false));
    dispatch(setDocumentObj(getDefaultDocument()));
    dispatch(setIsSelected(null));
    dispatch(setOpenAddDocument(true));
};

export const uploadDocumentHandler =
    (file, documentObj): AppThunk =>
    async (dispatch, getState) => {
        const categoryId = documentObj.DocumentCategory;
        const memberId = documentObj.MemberId;
        const signedOnDate = new Date(documentObj.DocumentSignedOn).toUTCString();
        let formData = new FormData();
        formData.append('File', file);
        formData.append('document[Name]', documentObj.Name);
        formData.append('document[DocumentCategory]', documentObj.DocumentCategory);
        formData.append('document[DocumentSignedOn]', signedOnDate);
        formData.append('document[UploadedBy][Id]', documentObj.UploadedBy.Id);
        formData.append('document[UploadedBy][Name]', documentObj.UploadedBy.Name);
        formData.append('document[LastUpdatedBy][Id]', documentObj.LastUpdatedBy.Id);
        formData.append('document[LastUpdatedBy][Name]', documentObj.LastUpdatedBy.Name);
        formData.append('document[MemberId]', documentObj.MemberId);
        formData.append('document[FileName]', documentObj.FileName);
        formData.append('document[MetaTags][0][Id]', documentObj?.MetaTags[0]?.Id || '');
        formData.append('document[MetaTags][0][Name]', documentObj?.MetaTags[0]?.Name || '');
        formData.append('document[MetaTags][0][CategoryId]', documentObj?.MetaTags[0]?.CategoryId || '');
        formData.append('document[MetaTags][0][IsSelected]', documentObj?.MetaTags[0]?.IsSelected);

        dispatch(setIsLoading(true));
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.post(`${API_URL}/document/insertdocument`, formData);
            if (response.status === 200) {
                dispatch(getDocumentCategoryAsTree());
                dispatch(getDocumentByCategoryId(categoryId, memberId));
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log('ERROR: ', error);
            dispatch(setIsLoading(false));
        }

        dispatch(setFileIsValid(true));
        dispatch(setOpenAddDocument(false));
    };

export default memberDocuments.reducer;
