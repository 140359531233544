import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Popper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getActiveProviders } from '../../store/provider.slice';
import { states } from '../../utils/constants';
import { isValidZip } from '../../utils/validation';
import Address from '../../Models/Shared/Address.model';
import { fetchPlaceDetail, fetchPlacePredictions } from '../../store/googlePlace.slice';
import React from 'react';

const EditTeamsDialog = (props) => {
    const dispatch = useDispatch();
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [placeList, setPlaceList] = useState([]);
    const { isFetching } = useSelector((state: RootState) => state.googlePlace);

    const getAddressByKeyword = (input: string) => {
        const newAddress = { ...props.currentTeam.Address, Address1: input };
        props.setCurrentTeam({ ...props.currentTeam, Address: newAddress });
        if (input?.length > 4) {
            dispatch(
                fetchPlacePredictions(input, (data: any) => {
                    setPlaceList(data);
                })
            );
        }
    };

    const getAddressDetail = (e: any, option: any) => {
        if (Boolean(option)) {
            dispatch(
                fetchPlaceDetail(option.PlacePrediction.PlaceId, (data: Address) => {
                    const newAddress = { ...props.currentTeam.Address, ...data };
                    props.setCurrentTeam({ ...props.currentTeam, Address: newAddress });
                })
            );
        }
    };

    const teamOptions = Object.entries(props.teamTypes).map((i) => {
        return { value: parseInt(i[0]), label: i[1] };
    });

    const changeProviderHandler = (e) => {
        const selectedProviderId = e.target.value;
        const matchedActiveProvider = activeProviders.find((provider) => provider.Id === selectedProviderId);
        props.setCurrentTeam({
            ...props.currentTeam,
            Name: `${matchedActiveProvider.LastName}, ${matchedActiveProvider.FirstName}`,
            ProviderId: matchedActiveProvider.Id,
            Providers: [matchedActiveProvider],
        });
    };

    // const { currentTeam } = props;
    // console.log('currentTeam: ', currentTeam);

    useEffect(() => {
        dispatch(getActiveProviders(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus>
            <DialogTitle>{props.newTeam ? 'Add' : 'Edit'} Team</DialogTitle>
            <DialogContent sx={{ padding: '16px' }}>
                <FormControl fullWidth>
                    <Grid container spacing={3}>
                        {!props.validProvider && (
                            <Grid item xs={12}>
                                <Paper sx={{ padding: 2, background: 'yellow' }}>
                                    <Typography>This Provider is already assigned to a Care Coordinator Team. Please select a different Provider.</Typography>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled
                                    margin="dense"
                                    id="first-name"
                                    label="Team Name"
                                    name="TeamName"
                                    variant="outlined"
                                    value={props.currentTeam.Name.toUpperCase() || ''}
                                    onChange={(e) => props.setCurrentTeam({ ...props.currentTeam, Name: e.target.value })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel required id="demo-simple-select-label">
                                    Team Type
                                </InputLabel>
                                <Select
                                    autoFocus
                                    disabled={!props.newTeam}
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    margin="dense"
                                    label="Team Type"
                                    name="TeamType"
                                    value={props.currentTeam.Type || ''}
                                    onChange={(e) =>
                                        props.setCurrentTeam({
                                            ...props.currentTeam,
                                            Type: e.target.value,
                                            TypeName: teamOptions.find((el) => el.value === e.target.value).label,
                                        })
                                    }
                                >
                                    {teamOptions.map((i) => {
                                        return (
                                            <MenuItem key={i.value} value={+i.value}>
                                                {props.formatToTextCase(i.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel required id="demo-simple-select-label">
                                    Primary Provider
                                </InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Primary Provider"
                                    name="PrimaryProvider"
                                    value={props.currentTeam.ProviderId || ''}
                                    onChange={changeProviderHandler}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    {activeProviders
                                        .map((i) => {
                                            return (
                                                <MenuItem key={i.Id} value={i.Id}>
                                                    {i.LastName}, {i.FirstName}
                                                </MenuItem>
                                            );
                                        })
                                        .sort()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                            <Typography variant="h6">Team Address</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="address1"
                                    value={props?.currentTeam.Address?.Address1 || ''}
                                    inputValue={props?.currentTeam.Address?.Address1 || ''}
                                    freeSolo
                                    disablePortal
                                    disableClearable
                                    fullWidth
                                    PopperComponent={(inputProps) => <Popper {...inputProps} style={{ width: 500 }} placement="bottom-start" />}
                                    getOptionLabel={(option) => option?.PlacePrediction?.Text?.Text || ''}
                                    options={placeList}
                                    style={{ width: '100%' }}
                                    onChange={(e, option) => {
                                        getAddressDetail(e, option);
                                    }}
                                    onInputChange={(event, newInputValue: any) => {
                                        if (event?.type === 'change') {
                                            getAddressByKeyword(newInputValue);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Address 1"
                                            name="Address1"
                                            variant="outlined"
                                            error={props.currentTeam.Address && !Boolean(props.currentTeam.Address?.Address1)}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled
                                    id="address2"
                                    label="Address2"
                                    name="Address2"
                                    variant="outlined"
                                    value={props.currentTeam.Address?.Address2 || ''}
                                    onChange={(e) => {
                                        const newAddress = { ...props.currentTeam.Address, Address2: e.target.value };
                                        props.setCurrentTeam({ ...props.currentTeam, Address: newAddress });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="address2"
                                    label="City"
                                    name="City"
                                    variant="outlined"
                                    value={props.currentTeam.Address?.City || ''}
                                    onChange={(e) => {
                                        const newAddress = { ...props.currentTeam.Address, City: e.target.value };
                                        props.setCurrentTeam({ ...props.currentTeam, Address: newAddress });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.currentTeam.Address?.State || ''}
                                        name="State"
                                        label="State"
                                        onChange={(e) => {
                                            const newAddress = { ...props.currentTeam.Address, State: e.target.value };
                                            props.setCurrentTeam({ ...props.currentTeam, Address: newAddress });
                                        }}
                                    >
                                        {states.map(function (state) {
                                            return (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="zipCode"
                                    label="ZipCode"
                                    name="ZipCode"
                                    variant="outlined"
                                    error={props.currentTeam.Address?.ZipCode && !isValidZip(props.currentTeam.Address?.ZipCode)}
                                    value={props.currentTeam.Address?.ZipCode || ''}
                                    onChange={(e) => {
                                        const newAddress = { ...props.currentTeam.Address, ZipCode: e.target.value };
                                        props.setCurrentTeam({ ...props.currentTeam, Address: newAddress });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={props.currentTeam.Type === null || props.currentTeam.Providers.length === 0}
                    className="button-120"
                    variant="contained"
                    onClick={props.submit}
                >
                    Save
                </Button>
                <Button className="button-120" variant="outlined" onClick={props.onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditTeamsDialog;
