import { useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    FormControl,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import loginImage from '../../assets/images/catalytic_login.png';
import LockIcon from '@mui/icons-material/Lock';
import { getApiVersion, setAuthenticatedUser, setIsInactive } from '../../store/authentication.slice';
import { apiString as API_URL, ERROR } from '../../utils/constants';
import MessageDisplay from '../Shared/MessageDisplay';
import Authorization from '../../utils/Authorization';
import { useLocation, useNavigate } from 'react-router-dom';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { UserDto } from '../../Models/User/UserDto.model';
import { setShowMessage } from '../../store/shared.slice';

const Login = (props) => {
    const dispatch = useDispatch();

    const { apiVersion, version, isInactive } = useSelector((state: RootState) => state.authentication);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [envText, setEnvText] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        setIsMobileView(isMobile);
    }, [isMobile]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const submit = async (e: any) => {
        e.preventDefault();
        try {
            const response = await axios.post<UserDto>(`${API_URL}/account/login`, { Email: username, Password: password });
            const authenticatedUser = response.data;
            localStorage.removeItem('isInactive');
            localStorage.removeItem('logout-event');
            localStorage.setItem('auth', JSON.stringify(authenticatedUser));
            dispatch(setAuthenticatedUser(authenticatedUser));
            dispatch(setIsInactive(false));
            if (authenticatedUser.IsTempPassword) {
                navigate('/update-password', { replace: true });
                return;
            }
            if (isMobileView === true) {
                navigate(location.state?.from.pathname || '/provider/upcomingappointments', { replace: true });
            } else navigate(location.state?.from.pathname || '/provider/metricsummary', { replace: true });
        } catch (e) {
            console.log(e);
            dispatch(setAuthenticatedUser(null));
            dispatch(setShowMessage(true, e.response.data, ERROR));
        }
    };

    useEffect(() => {
        dispatch(getApiVersion());
    }, [dispatch]);

    useEffect(() => {
        var env = new Authorization().getEnv();
        if (env !== 'Production') {
            setEnvText(env);
        }
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <MessageDisplay />
            <Grid container paddingBottom={0} paddingTop={2}>
                <Grid item xs={12} className="login-page">
                    <Stack spacing={2} alignItems={'center'} paddingY={8}>
                        <Typography className="env-text" align="center">
                            {envText}
                        </Typography>
                        <img src={loginImage} alt="CHP Login" className="login-image" />
                        <form onSubmit={submit}>
                            <FormGroup>
                                <Stack spacing={2} paddingY={2} className="login-panel" alignItems={'center'}>
                                    <Avatar style={{ backgroundColor: '#7560AA' }}>
                                        <LockIcon />
                                    </Avatar>
                                    {isInactive && (
                                        <Typography align="center" color={'error'}>
                                            You have been logged out due to inactivity.
                                        </Typography>
                                    )}
                                    <TextField
                                        fullWidth
                                        label="Username"
                                        variant="outlined"
                                        onChange={(e: any) => {
                                            setUsername(e.target.value);
                                        }}
                                    />
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e: any) => {
                                                setPassword(e.target.value);
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <Button fullWidth variant="contained" type="submit">
                                        Sign In
                                    </Button>
                                    <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
                                        <Typography align="center" className="login-panel-bottom">
                                            Forgot Password?
                                        </Typography>
                                    </Link>
                                    <Typography align="center" className="login-panel-bottom app-version">
                                        Web App Version {version}
                                    </Typography>
                                    <Typography align="center" className="login-panel-bottom">
                                        API Version {apiVersion + ' ' + version}
                                    </Typography>
                                </Stack>
                            </FormGroup>
                        </form>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default Login;
