import BaseEntity from '../BaseEntity.model';

export default interface ITaper extends BaseEntity {
    StartDate: string;
    EndDate: string;
    Dose: string;
}

export class Taper implements ITaper {
    Id: string | null;
    StartDate: string;
    EndDate: string;
    Dose: string;

    constructor() {
        this.Id = null;
    }
}
