import { Button, ButtonGroup, FormControl, InputLabel, Menu, MenuItem, Select, Stack } from '@mui/material';
import { Phone } from '../../../Models/Shared/Phone.model';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { parentUrl } from '../../../utils/constants';

import StarIcon from '@mui/icons-material/Star';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    CallListTabs,
    activityOptions,
    changeTime,
    createNote,
    incrementCallCount,
    openChangeStatusHandler,
    returnDocumentOnboarding,
    saveAndFinish,
    setDemographicsRequestIsOpen,
    setFormPhone,
    setFormPhoneIsValid,
    setSelectedPhone,
    setShowAddPhone,
    setShowScheduleAppointment,
    setShowSkipCall,
    setSkipCallReason,
    showOtherMemberChartsHandler,
} from '../../../store/onboardingWorkflow.slice';
import phoneFormat from '../../../utils/phoneFormat';
import { ContactTimeEnum } from '../../../Enum/ContactTimeEnum';
import Authorization from '../../../utils/Authorization';
import moment from 'moment';
import CallItem from '../../../Models/OnboardingWorkflow/CallItem.model';
import AppointmentSchedulerButton from '../../Maps/AppointmentSchedulerButton';
import { copyObject } from '../../../utils/common';

const CallItemActions = () => {
    const { callItem, confirmationCallItem, selectedCallListTab, selectedPhone } = useSelector((state: RootState) => state.onboardingWorkflowSlice);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const dispatch = useDispatch();
    const auth = new Authorization();
    const contactTimes = Object.keys(ContactTimeEnum)?.filter((key) => isNaN(Number(key)));

    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    const item = isConfirmCall ? confirmationCallItem : callItem;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectPhoneHandler = (phone: Phone, index: number) => {
        dispatch(setSelectedPhone(phone));
        handleClose();
    };

    const changePreferredTimeHandler = (e) => {
        const newValue = { ...item, PreferredTime: parseInt(ContactTimeEnum[e.target.value]) };
        const note = createNote(
            item.MemberId,
            activityOptions.PREFERRED_TIME_CHANGE,
            `The member's preferred contact time has been changed to: ${e.target.value}`,
            auth
        );
        dispatch(changeTime(newValue, note, isConfirmCall));
    };

    const openAddPhoneNumberHandler = () => {
        dispatch(setFormPhone(new Phone()));
        dispatch(setFormPhoneIsValid(true));
        dispatch(setShowAddPhone(true));
    };

    const handleCall = (call: CallItem) => {
        let callItemCopy = copyObject(call);
        callItemCopy.CallAttempted = true;
        callItemCopy.CallCount = callItemCopy.CallCount + 1;
        const note = `A call was logged for, "${phoneFormat(selectedPhone.Number)}."`;
        const newNote = createNote(call.MemberId, activityOptions.ATTEMPTED_CALL, note, auth);
        dispatch(incrementCallCount(callItemCopy, selectedPhone.Number, newNote, isConfirmCall));
    };

    const confirmSkipCall = () => {
        dispatch(setSkipCallReason(''));
        dispatch(setShowSkipCall(true));
    };

    const openDemographicsRequest = () => {
        dispatch(setDemographicsRequestIsOpen(true));
    };

    const openScheduleAppointmentHandler = () => {
        dispatch(setShowScheduleAppointment(true));
    };

    const saveAndFinishHandler = () => {
        const editableNotes = item.ActivityNotes.filter((note) => note.Editable).map((note2) => {
            const date = moment(note2.CreatedDate).format('MM/DD/YYYY hh:mm A');
            return `Date: ${date} \nType: ${note2.NoteType} \n${note2.Note}`;
        });
        let document = returnDocumentOnboarding(item, auth, editableNotes.join('\n\n'));

        dispatch(saveAndFinish(item, document, isConfirmCall));
    };

    const activityNotesWithMemberSkipped = item.ActivityNotes.filter((note) => note.NoteType === activityOptions.SKIPPED_CALL);

    return (
        <Stack direction="row" spacing={2} marginTop={2} marginBottom={5} justifyContent={'space-between'} alignItems={'center'}>
            <Stack spacing={2} alignItems={'center'}>
                <Stack direction="row" spacing={2}>
                    <ButtonGroup>
                        <Button
                            disabled={item?.Phones?.length === 0 || isLoading ? true : false}
                            variant="contained"
                            sx={{ '& a': { color: '#fff', textDecoration: 'none' } }}
                            onClick={(e) => {
                                handleCall(item);
                            }}
                        >
                            {<a href={`tel:+1${selectedPhone?.Number}`}>Make a Call</a>}
                        </Button>
                        <Button
                            disabled={item?.Phones?.length > 0 ? false : true}
                            id="basic-button"
                            variant="contained"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <KeyboardArrowDownIcon />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                [`& .MuiMenuItem-root.Mui-selected`]: {
                                    backgroundColor: 'primary.main',
                                    color: '#F7F7F7',
                                    ':hover': {
                                        backgroundColor: 'primary.main',
                                        color: '#F7F7F7',
                                    },
                                },
                            }}
                        >
                            {item?.Phones?.map((phone: Phone, index: number) => {
                                const iconColor = selectedPhone === phone ? '#fff' : '#1976d2';
                                return (
                                    <MenuItem key={index} selected={selectedPhone === phone} onClick={() => selectPhoneHandler(phone, index)}>
                                        {phoneFormat(phone?.Number)}
                                        {phone.IsPrimary && <StarIcon style={{ color: iconColor }} sx={{ fontSize: '1rem', marginLeft: 1 }} />}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </ButtonGroup>

                    <Button disabled={!item?.MemberId} variant="outlined" color="primary" onClick={openAddPhoneNumberHandler}>
                        Add Phone
                    </Button>

                    <Button variant="contained" color="primary" onClick={openDemographicsRequest}>
                        Demographics Request {item.DemographicsRequestCount > 0 && `(${item.DemographicsRequestCount})`}
                    </Button>

                    {!isConfirmCall && (
                        <Button variant="contained" color="error" onClick={confirmSkipCall}>
                            Skip Call {activityNotesWithMemberSkipped.length > 0 && `(${activityNotesWithMemberSkipped.length})`}
                        </Button>
                    )}
                </Stack>

                <Stack direction="row" spacing={2} alignItems={'center'}>
                    <FormControl sx={{ minWidth: '140px' }}>
                        <InputLabel id="demo-simple-select-label">Preferred Contact Time</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={contactTimes.find((el) => ContactTimeEnum[el] === item?.PreferredTime) || ''}
                            label="Preferred Contact Time"
                            onChange={changePreferredTimeHandler}
                            size="small"
                        >
                            {contactTimes.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button disabled={!item.CallAttempted} onClick={() => dispatch(openChangeStatusHandler(item))} variant="contained" color="secondary">
                        Change Status
                    </Button>

                    <AppointmentSchedulerButton memberId={item.MemberId} />

                    <Button disabled={!item.CallAttempted && isConfirmCall} variant="contained" color="secondary" onClick={openScheduleAppointmentHandler}>
                        {isConfirmCall ? 'Confirm' : 'Schedule'} Appt
                    </Button>

                    <Button disabled={!item.CallAttempted} variant="contained" color="success" onClick={saveAndFinishHandler}>
                        Save and Finish
                    </Button>
                </Stack>
            </Stack>
            <Stack justifyContent={'flex-end'} marginTop={1} spacing={2}>
                {item?.PreviousMemberCharts?.length > 0 && !isConfirmCall && (
                    <Button
                        variant="outlined"
                        sx={{ minWidth: '120px' }}
                        color="primary"
                        onClick={() => dispatch(showOtherMemberChartsHandler(item.PreviousMemberCharts))}
                    >
                        Previous Charts ({item?.PreviousMemberCharts?.length})
                    </Button>
                )}
                <Button
                    variant="contained"
                    sx={{ minWidth: '120px' }}
                    color="primary"
                    disabled={!item?.MemberId}
                    onClick={() => window.open(`${parentUrl}provider/members-list/member-chart/${item?.MemberId}/member-profile`, '_blank')}
                >
                    View Chart
                </Button>
            </Stack>
        </Stack>
    );
};

export default CallItemActions;
