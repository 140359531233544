import { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Link, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    deleteTransitionalCare,
    deleteTransitionalCareStep,
    facilityTypes,
    generateTransitionalCarePDF,
    setAdmissionChecklistTextModalIsOpen,
    setCurrentChecklistItem,
    setDischargeFacility,
    setIsReAdmitting,
    setIsTransferring,
    setPatientAdmissionTitle,
    toggleIsShowDischargeDetails,
    toggleIsShowFacilityDetails,
    transitionalCareNotification,
    visitTypesList,
} from '../../store/transitionalCare.slice';
import InfoIcon from '@mui/icons-material/Info';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import KeyContacts from './KeyContacts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { OutsideFacilityLocationEnum } from '../../Enum/OutsideFacilityLocationEnum';
import PostDischargeChecklist from './PostDischargeChecklist';
import Authorization from '../../utils/Authorization';
import ConfirmDelete from '../Shared/ConfirmDelete';
import { TransitionalCareStepTypeEnum } from '../../Enum/TransitionalCareStepTypeEnum';
import PlansOfCare from './PlansOfCare';
import Medications from './Medications';
import DischargePlanComponent from './DischargePlan';
import EngagementActivity from './EngagementActivity';
import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';
import TCMain from '../../assets/documents/Transitional_Care_Main.pdf';
import MessageDisplay from '../Shared/MessageDisplay';
import { setShowMessage } from '../../store/shared.slice';

const DetailsPage = (props) => {
    const { isAssessment } = props;
    const { admissionChecklistTextModalIsOpen, currentChecklistItem, memberTransitionalCare, transitionStep } = useSelector(
        (state: RootState) => state.transitionalCare
    );
    const auth = new Authorization();

    const dispatch = useDispatch();

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [transitionName, setTransitionName] = useState('');

    const isFromDischarge = transitionStep?.TransitionStepType === TransitionalCareStepTypeEnum.Discharge;
    const isFromTransition = transitionStep?.TransitionStepType === TransitionalCareStepTypeEnum.Transfer;
    const isFromReAdmission = transitionStep?.TransitionStepType === TransitionalCareStepTypeEnum.ReAdmission;

    const handleOpen = () => {
        dispatch(setAdmissionChecklistTextModalIsOpen(true));
    };
    const handleClose = () => {
        dispatch(setAdmissionChecklistTextModalIsOpen(false));
    };

    const editTransitionalCareHandler = (transitionStep: TransitionStep) => {
        if (isFromDischarge) {
            dispatch(toggleIsShowDischargeDetails(true));
            dispatch(setDischargeFacility(transitionStep));
        } else {
            dispatch(setIsTransferring(isFromTransition));
            dispatch(setIsReAdmitting(isFromReAdmission));
            dispatch(setPatientAdmissionTitle(`Edit ${isFromTransition ? 'Transfer' : isFromReAdmission ? 'Re-admission' : 'Admission'}`));
            dispatch(toggleIsShowFacilityDetails(true, transitionStep));
        }
    };

    const hasDeletePermissions = () => {
        if (transitionStep.TransitionStepType === TransitionalCareStepTypeEnum.Admission && auth.Permissions.includes('Delete Transitional Care')) {
            return true;
        } else if (
            transitionStep.TransitionStepType !== TransitionalCareStepTypeEnum.Admission &&
            (auth.Permissions.includes('Delete Transitional Care Step') || auth.Permissions.includes('Delete Transitional Care'))
        ) {
            return true;
        } else {
            return false;
        }
    };
    const openConfirmDeleteDialog = () => {
        const transitionSteps = memberTransitionalCare.TransitionSteps.filter((step) => step.IsDeleted === false);
        const admissionStep = memberTransitionalCare.TransitionSteps.find((step) => step.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
        const reAdmissionSteps = transitionSteps.filter((step) => step.TransitionStepType === TransitionalCareStepTypeEnum.ReAdmission);
        const stepIndex = transitionSteps.findIndex((step) => step.Id === transitionStep.Id);
        if (
            stepIndex !== transitionSteps.length - 1 &&
            reAdmissionSteps.length > 0 &&
            (transitionStep.TransitionStepType === TransitionalCareStepTypeEnum.Discharge ||
                transitionStep.TransitionStepType === TransitionalCareStepTypeEnum.ReAdmission)
        ) {
            dispatch(setShowMessage(true, 'Cannot delete this step. Please delete the following re-admission and/or discharge steps first.', 'error'));
            return;
        }
        const deleteTransitionalCare = `entire Transitional Care for ${admissionStep.Facility?.Name} admitted on ${
            moment(admissionStep?.AdmissionDate).isValid && moment(admissionStep?.AdmissionDate).format('MM.DD.YYYY')
        }`;
        const facilityLocation =
            transitionStep.TransitionStepType === TransitionalCareStepTypeEnum.Discharge
                ? OutsideFacilityLocationEnum[transitionStep?.OutsideFacilityLocation]
                : transitionStep.Facility?.Name;
        const deleteStep = `the following step: ${TransitionalCareStepTypeEnum[transitionStep.TransitionStepType]} to ${facilityLocation} on ${
            moment(transitionStep?.AdmissionDate).isValid && moment(transitionStep?.AdmissionDate).format('MM.DD.YYYY')
        }`;
        const deleteTemplate = transitionStep === admissionStep ? deleteTransitionalCare : deleteStep;
        setTransitionName(deleteTemplate);
        setDialogIsOpen(true);
        return;

        // }
    };

    const closeConfirmDialog = () => {
        setDialogIsOpen(false);
    };

    const deleteTransitionHandler = () => {
        const admissionStep = memberTransitionalCare.TransitionSteps.find((step) => step.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
        transitionStep === admissionStep
            ? dispatch(deleteTransitionalCare(memberTransitionalCare.Id))
            : dispatch(deleteTransitionalCareStep(memberTransitionalCare.Id, transitionStep?.Id));

        setDialogIsOpen(false);
    };

    const PostDischargeChecklistWrapper = (props) => {
        const { isAssessment } = props;
        return (
            <Box>
                <Dialog open={admissionChecklistTextModalIsOpen} onClose={handleClose} fullWidth maxWidth={'md'} disableEnforceFocus>
                    <DialogTitle>Post-discharge checklist</DialogTitle>
                    <DialogContent>
                        <PostDischargeChecklist isAssessment={isAssessment} isCompleted={false} />
                    </DialogContent>
                </Dialog>
                {!transitionStep?.TransitionalCareCheckList && (
                    <Button variant="outlined" color="primary" fullWidth sx={{ p: 2, marginBottom: 2 }} onClick={() => handleOpen()}>
                        Fill out post-discharge checklist
                    </Button>
                )}
                {transitionStep?.TransitionalCareCheckList && (
                    <PostDischargeChecklist patientAdmission={transitionStep} isAssessment={isAssessment} isCompleted={true} />
                )}
            </Box>
        );
    };

    const admissionChecklist = [
        {
            label: 'ENGAGEMENT',
            value: 1,
            name: 'TransitionalCareActivities',
            component: <EngagementActivity transitionStep={transitionStep} isAssessment={isAssessment} />,
        },
        { label: 'PLAN OF CARE', value: 3, name: 'PlansOfCare', component: <PlansOfCare transitionStep={transitionStep} isAssessment={isAssessment} /> },
        { label: 'MEDICATION', value: 4, name: 'Medications', component: <Medications transitionStep={transitionStep} isAssessment={isAssessment} /> },
        {
            label: 'DISCHARGE PLAN',
            value: 5,
            name: 'DischargePlans',
            component: <DischargePlanComponent transitionStep={transitionStep} isAssessment={isAssessment} />,
        },
        {
            label: 'CONTACTS',
            value: 6,
            name: 'KeyContacts',
            component: <KeyContacts transitionStep={transitionStep} isAssessment={isAssessment} />,
        },
    ];

    const dischargeChecklistTabs = [
        {
            label: 'ENGAGEMENT',
            value: 1,
            name: 'TransitionalCareActivities',
            component: <EngagementActivity transitionStep={transitionStep} isAssessment={isAssessment} />,
        },

        {
            label: 'POST-DISCHARGE CHECKLIST',
            value: 2,
            name: 'TransitionalCareCheckList',
            component: <PostDischargeChecklistWrapper isAssessment={isAssessment} />,
        },
        {
            label: 'CONTACTS',
            value: 5,
            name: 'KeyContacts',
            component: <KeyContacts transitionStep={transitionStep} isAssessment={isAssessment} />,
        },
    ];

    const detailsTitle = isFromDischarge ? 'Discharge' : isFromTransition ? 'Transfer' : isFromReAdmission ? 'Re-admission' : 'Admission';

    return (
        <Box width={'100%'}>
            <ConfirmDelete
                open={dialogIsOpen}
                onClose={closeConfirmDialog}
                name={transitionName}
                delete={deleteTransitionHandler}
                cancel={closeConfirmDialog}
            />
            <MessageDisplay />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} marginBottom={4}>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography variant="body1" fontWeight={700} color={'text.secondary'}>
                        {detailsTitle} Details
                        <Tooltip title="Transitional Care Main Help Document">
                            <InfoIcon
                                sx={{ marginLeft: 1, marginBottom: 1 }}
                                color="primary"
                                fontSize="small"
                                cursor="pointer"
                                onClick={() => window.open(`${TCMain}#${detailsTitle}`, '_blank')}
                            />
                        </Tooltip>
                    </Typography>
                </Stack>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Button color="primary" onClick={() => editTransitionalCareHandler(transitionStep)}>
                        <EditIcon />
                    </Button>

                    <Link href="#" underline="hover" onClick={() => dispatch(generateTransitionalCarePDF())}>
                        <PictureAsPdfIcon fontSize="small" />
                    </Link>
                    <Button color="error" disabled={!hasDeletePermissions()} onClick={openConfirmDeleteDialog}>
                        <CancelIcon />
                    </Button>
                </Stack>
            </Stack>
            {!isFromDischarge && <Typography variant="h4">{transitionStep?.Facility?.Name}</Typography>}
            {!isFromDischarge && (
                <Typography variant="body1">
                    {transitionStep?.Facility?.Address} {transitionStep?.Facility?.City}, {transitionStep?.Facility?.State} {transitionStep?.Facility?.ZipCode}
                </Typography>
            )}
            {isFromDischarge && <Typography variant="h4">{OutsideFacilityLocationEnum[transitionStep?.OutsideFacilityLocation]}</Typography>}
            {isFromDischarge && <Typography variant="body1">{transitionStep?.Address}</Typography>}
            <Grid container spacing={2} width={'100%'} marginY={2} height={150}>
                <Grid item xs={6} md={!isFromDischarge ? 2 : 3}>
                    <Stack>
                        <Typography variant="body2">{isFromDischarge ? 'Discharge' : isFromReAdmission ? 'Re-admission' : 'Admission'} Date</Typography>
                        <Typography variant="h6">
                            {moment(transitionStep?.AdmissionDate).isValid && moment(transitionStep?.AdmissionDate).format('MM.DD.YYYY')}
                        </Typography>
                        <Typography variant="body1" fontWeight={500} color={'text.secondary'}>
                            {facilityTypes.find((el) => el.value === transitionStep?.AdmissionType)?.label}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={!isFromDischarge ? 2 : 3}>
                    <Stack>
                        <Typography variant="body2">Notified</Typography>
                        {!isFromDischarge && (
                            <Typography variant="h6">
                                {moment(transitionStep?.NotificationDate).isValid && moment(transitionStep?.NotificationDate).format('MM.DD.YYYY')}
                            </Typography>
                        )}
                        <Typography variant="body1" fontWeight={500} color={'text.secondary'}>
                            {transitionalCareNotification.find((el) => el.value === transitionStep?.NotificationMethod)?.label}
                        </Typography>
                    </Stack>
                </Grid>

                {!isFromDischarge && (
                    <Grid item xs={6} md={2}>
                        <Stack>
                            <Typography variant="body2">Visit Type</Typography>

                            <Typography variant="body1" fontWeight={500} color={'text.secondary'}>
                                {transitionStep?.VisitType ? visitTypesList.find((el) => el.value === transitionStep?.VisitType)?.label : 'N/A'}
                            </Typography>
                        </Stack>
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    <Typography variant="body1" fontWeight={500} color={'text.secondary'} sx={{ overflowY: 'scroll', maxHeight: 150 }}>
                        {transitionStep?.Description ? transitionStep?.Description : 'No description'}
                    </Typography>
                </Grid>
            </Grid>

            {!isFromDischarge && (
                <Tabs value={currentChecklistItem} scrollButtons="auto" onChange={(e, newItem) => dispatch(setCurrentChecklistItem(newItem))}>
                    {admissionChecklist.map((el) => {
                        return (
                            <Tab
                                iconPosition="start"
                                key={el.value}
                                icon={transitionStep[el.name] ? null : <FiberManualRecordIcon fontSize="small" color="primary" />}
                                label={el.label}
                                value={el.value}
                            />
                        );
                    })}
                </Tabs>
            )}
            {!isFromDischarge && (
                <Grid container spacing={2} marginY={2}>
                    {admissionChecklist.map((el) => {
                        return (
                            currentChecklistItem === el.value && (
                                <Grid item xs={12} key={el.value} textAlign={'start'}>
                                    {el.component}
                                </Grid>
                            )
                        );
                    })}
                </Grid>
            )}
            {isFromDischarge && (
                <Tabs value={currentChecklistItem} scrollButtons="auto" onChange={(e, newItem) => dispatch(setCurrentChecklistItem(newItem))}>
                    {dischargeChecklistTabs.map((el) => {
                        return (
                            <Tab
                                iconPosition="start"
                                key={el.value}
                                icon={transitionStep[el.name] ? null : <FiberManualRecordIcon fontSize="small" color="primary" />}
                                label={el.label}
                                value={el.value}
                            />
                        );
                    })}
                </Tabs>
            )}
            {isFromDischarge && (
                <Grid container spacing={2} marginY={2}>
                    {dischargeChecklistTabs.map((el) => {
                        return (
                            currentChecklistItem === el.value && (
                                <Grid item xs={12} key={el.value} textAlign={'start'}>
                                    {el.component}
                                </Grid>
                            )
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
};

export default DetailsPage;
