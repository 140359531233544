import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { IBasicModalProps } from '../interfaces/IBasicModalProps';
import { IResource } from './interfaces/IResource';
import ResourcesPage from './ResourcesPage';

const SelectResourceModal: React.FC<IBasicModalProps & { onSuccessfulSelection: (resource: IResource) => void }> = (props) => {
    const { open, onClose, onSuccessfulSelection } = props;
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={false}
            PaperProps={{
                sx: { alignSelf: 'flex-start' },
            }}
        >
            <DialogTitle>Select a Resource</DialogTitle>
            <DialogContent>
                <ResourcesPage
                    showTitle={false}
                    selection={(resource: IResource) => {
                        onSuccessfulSelection(resource);
                        onClose();
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectResourceModal;
