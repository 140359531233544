import { Box, CircularProgress } from '@mui/material';

const WidgetLoading = () => {
    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                zIndex: 100,
                background: 'rgba(255,255,255, 0.6)',
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default WidgetLoading;
