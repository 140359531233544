import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Dialog, DialogActions, DialogTitle, DialogContent, Divider, FormControl, Button, Typography, TextField, CircularProgress } from '@mui/material';
import moment from 'moment';
import * as Yup from 'yup';
import { RootState } from '../../reducers';
import { addTransitionalCareActivity, setIsShowTransitionalCareActivityForm } from '../../store/transitionalCare.slice';
import { copyObject } from '../../utils/common';
import { TransitionalCareActivity } from '../../Models/TransitionalCare/TransitionalCareActivity.model';

import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';
import { Form, Formik } from 'formik';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';

const plansOfCareSchema = Yup.object().shape({
    Note: Yup.string().nullable().required('Plan is required.'),
});

const EngagementActivity: React.FC<{ transitionStep: TransitionStep; isAssessment: boolean }> = ({ transitionStep, isAssessment }) => {
    const dispatch = useDispatch();
    const { isSavingActivityNote, isShowTransitionalCareActivityForm } = useSelector((state: RootState) => state.transitionalCare);
    const transitionalCareActivities = copyObject(transitionStep.TransitionalCareActivities || []).reverse();
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const handleOpen = () => dispatch(setIsShowTransitionalCareActivityForm(true));
    const handleClose = () => dispatch(setIsShowTransitionalCareActivityForm(false));

    const handleOnClickSave = (values) => {
        if (isAssessment) {
            let data = copyObject(engagementDraft);
            data['EngageWithMember'] = true;
            dispatch(saveEngagementDraft(data));
        }
        dispatch(addTransitionalCareActivity(values.Note));
    };

    const RenderModalForm = () => {
        return (
            <Dialog open={isShowTransitionalCareActivityForm} onClose={handleClose} fullWidth maxWidth={'sm'}>
                <DialogTitle>Add engagement</DialogTitle>
                <DialogContent>
                    <Formik initialValues={{ Note: '' }} onSubmit={handleOnClickSave} validationSchema={plansOfCareSchema}>
                        {({ values, errors, handleChange }) => (
                            <Form id="engagement-form">
                                <FormControl fullWidth={true} error={Boolean(errors?.Note)}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        label="Description"
                                        required
                                        sx={{ marginY: 2 }}
                                        variant="outlined"
                                        rows={6}
                                        value={values.Note}
                                        name="Note"
                                        onChange={handleChange}
                                        helperText={errors?.Note ? errors?.Note : 'Describe member engagement activity.'}
                                        error={Boolean(errors?.Note)}
                                    />
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button type="button" onClick={handleClose} disabled={isSavingActivityNote}>
                        Cancel
                    </Button>
                    <Button
                        form="engagement-form"
                        type="submit"
                        disabled={isSavingActivityNote}
                        endIcon={isSavingActivityNote ? <CircularProgress size={18} color="inherit" /> : null}
                    >
                        Save & Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            <RenderModalForm />
            <Button variant="outlined" color="primary" fullWidth sx={{ p: 2, marginBottom: 2 }} onClick={handleOpen}>
                Add Engagement note
            </Button>
            {transitionalCareActivities?.map((engagement: TransitionalCareActivity, index: number) => {
                const { Note, CreatedBy, CreatedDate } = engagement;
                const lastItem = transitionalCareActivities.length - 1 === index;
                return (
                    <Stack key={index} paddingY={2}>
                        <Typography variant="body1" fontWeight={500}>
                            {Note}
                        </Typography>
                        <Typography variant="body2" color={'text.secondary'}>
                            {CreatedBy?.Name} - {moment(CreatedDate).format('DD MMM YYYY')}
                        </Typography>
                        {!lastItem && <Divider sx={{ marginTop: 3 }} />}
                    </Stack>
                );
            })}
        </>
    );
};

export default EngagementActivity;
