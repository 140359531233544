import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';
import { ProviderPreview } from '../CarePlans/SchedulePlan.model';

export default interface ILipidAssessment extends BaseEntity {
    MemberId: string;
    MemberName: string;
    Age: number;
    DOB: string;
    Provider: ProviderPreview;
    Collector: ProviderPreview;
    TestDate: string;
    TotalCholestrol: number;
    HDLCholestrol: number;
    Triglycerides: number;
    eGlu: number;
    IsTotalCholestrolOutOfRange: boolean;
    IsHDLCholestrolOutOfRange: boolean;
    IsTriglyceridesOutOfRange: boolean;
    IseGluOutOfRange: boolean;
    Percentage: number;
    CreatedDate: string;
    CreatedBy: string;
    NeedsReview: boolean;
    Status: StatusEnum;
}

export class LipidAssessment implements ILipidAssessment {
    Id: string;
    MemberId: string;
    MemberName: string;
    Age: number;
    DOB: string;
    Provider: ProviderPreview;
    Collector: ProviderPreview;
    TestDate: string;
    TotalCholestrol: number;
    HDLCholestrol: number;
    Triglycerides: number;
    eGlu: number;
    IsTotalCholestrolOutOfRange: boolean;
    IsHDLCholestrolOutOfRange: boolean;
    IsTriglyceridesOutOfRange: boolean;
    IseGluOutOfRange: boolean;
    Percentage: number;
    CreatedDate: string;
    CreatedBy: string;
    NeedsReview: boolean;
    Status: StatusEnum;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.MemberName = '';
    }
}
