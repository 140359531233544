import { Grid, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { apiString as API_URL } from '../../../../utils/constants';
import WidgetLoading from '../../Shared/WidgetLoading';

const OnboardingPerformance = () => {
    const [populationGoals, setPopulationGoals] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getPopulationGoals = async () => {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/population/GetPopulationGoals`);
        setPopulationGoals(response.data);
        setIsLoading(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'PopulationName',
            headerName: 'Population',
            flex: 1,
        },
        {
            field: 'PercentToGoal',
            headerName: 'Current / Goal (Percent to Goal)',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.PercentToGoal}%`,
        },
        {
            field: 'MembersScheduled',
            headerName: 'Members Scheduled',
            flex: 1,
        },
        {
            field: 'NumberServiceRefusals',
            headerName: '# of Service Refusals',
            flex: 1,
        },
        {
            field: 'UndeterminedMembers',
            headerName: 'Undetermined Members',
            flex: 1,
        },
    ];

    useEffect(() => {
        getPopulationGoals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid item xs={12} lg={6} position="relative">
            {isLoading && <WidgetLoading />}
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item marginBottom={2}>
                    <Typography color={'primary'} variant="h6" textAlign={'center'}>
                        Onboarding Performance
                    </Typography>
                </Grid>

                <Grid item xs={12} marginTop={1}>
                    <div style={{ width: '100%', height: '58vh' }}>
                        <DataGrid
                            rows={populationGoals}
                            columns={columns}
                            pageSize={15}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => Math.random()}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default OnboardingPerformance;
