import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

import Role from '../Models/Role/Role.model';
import { ConsentForm } from '../Models/Consent/ConsentForm.model';
import { ConsentFormDocument } from '../Models/Consent/ConsentFormDocument.model';
import { MenuDetails } from '../Models/Assessment/AssessmentMenu.model';
import { copyObject, objectToFormData, removeEmptyDate } from '../utils/common';
import {
    assessmentMenuEnum,
    assessmentMenusByRole,
    assessmentMenusForFuhFum,
    assessmentStatusEnum,
    roleEnum,
    TeamTypes,
    TestingMachines,
} from '../utils/assessments';
import { MembersVitalSigns } from '../Models/VitalSigns/MembersVitalSigns.model';
import { AssessmentTypeEnum } from '../Enum/AssessmentTypeEnum';
import { MemberAssessment } from '../Models/Member/MemberAssessment.model';
import { AssessmentCategory } from '../Models/Assessment/AssessmentCategory.model';
import { SdohAssessment } from '../Models/Sdoh/SdohAssessment.model';
import { DiabetesFootAssessment } from '../Models/Assessment/DiabetesFootAssessment.model';
import { saveEngagementDraft } from './documentEngagement.slice';
import { SummaryNoteTypeEnum } from '../Enum/SummaryNoteTypeEnum';
import { MemberAllergy } from '../Models/Member/MemberAllergy.model';
import { Counseling } from '../Models/Assessment/Counseling.model';
import { MiniMentalExam } from '../Models/MiniMentalExams/MiniMentalExam.model';
import { SubjectiveAssessment } from '../Models/Assessment/SubjectiveAssessment.model';
import { FactorAndRiskAssessment } from '../Models/FactorAndRisk/FactorAndRiskAssessment.model';
import { getMemberBasicInfo, getMemberById } from './memberDetail.slice';
import { MemberBasicInfo } from '../Models/Member/MemberBasicInfo.model';
import { HgbA1CAssessment } from '../Models/Assessment/HgbA1CAssessment.model';
import { Member } from '../Models/Member/Member.model';
import { LipidAssessment } from '../Models/Assessment/LipidAssessment.model';
import { MessageAlert } from '../Models/Shared/MessageAlert.model';
import { StatusEnum } from '../Enum/StatusEnum';
import { ProviderRole } from '../Models/DocumentEngagements/EngagementDraft.model';
import { PrepareMenuClass } from '../Models/Shared/PrepareMenuClass.model';
import Authorization from '../utils/Authorization';
import { GenderEnum } from '../Enum/GenderEnum';
import { FactorRiskAssessmentLookupTypeEnum } from '../Enum/FactorRiskAssessmentLookupTypeEnum';
import { IntegratedCarePlan } from '../Models/CarePlans/IntegratedCarePlan.model';
import { ConsentDocumentValid } from '../Models/Consent/ConsentDocumentValid.model';

const initialValue = {
    roles: [] as Role[],
    consentForm: new ConsentForm(),
    consentDocumentValid: [] as ConsentDocumentValid[],
    vitalSign: new MembersVitalSigns(),
    memberAssessment: new MemberAssessment(),
    memberAssessmentsByType: [] as MemberAssessment[],
    sdohAssessment: new SdohAssessment(),
    diabetesFootAssessment: new DiabetesFootAssessment(),
    counseling: new Counseling(),
    subjectiveAssessment: new SubjectiveAssessment(),
    miniMentalExam: new MiniMentalExam(),
    memberAllergies: [] as MemberAllergy[],
    factorAndRiskAssessment: new FactorAndRiskAssessment(),
    hgbA1CAssessment: new HgbA1CAssessment(),
    lipidAssessment: new LipidAssessment(),
    deletingAlergyIds: [],
    fetchingConsentForm: false,
    fetchingVitalSign: false,
    fetchingSdoh: false,
    showDeleteAlert: false,
    assessmentMenuList: [] as MenuDetails[],
    selectedRole: '',
    isSaving: false,
    isFetching: false,
    isDeleting: false,
    showAlleryModal: false,
    error: new MessageAlert(),
    success: new MessageAlert(),
};

const assessmentSlice = createSlice({
    name: 'assessment',
    initialState: initialValue,
    reducers: {
        _setRoles(state, action) {
            state.roles = action.payload;
        },
        _setConsentForm(state, action) {
            state.consentForm = action.payload;
        },
        _setConsentDocumentValid(state, action) {
            state.consentDocumentValid = action.payload;
        },
        _setVitalSign(state, action) {
            state.vitalSign = action.payload;
        },
        _setMemberAssessment(state, action) {
            state.memberAssessment = action.payload;
            state.memberAssessmentsByType = [
                ...state.memberAssessmentsByType.filter((m) => m.Assessment.AssessmentType !== state.memberAssessment.Assessment.AssessmentType),
                state.memberAssessment,
            ];
        },
        _setSdoh(state, action) {
            state.sdohAssessment = action.payload;
        },
        _setDiabetesFootAssessment(state, action) {
            state.diabetesFootAssessment = action.payload;
        },
        _setMemberAllergies(state, action) {
            state.memberAllergies = action.payload;
        },
        _setDeletingAlergyIds(state, action) {
            state.deletingAlergyIds = action.payload;
        },
        _setMiniMentalExam(state, action) {
            state.miniMentalExam = action.payload;
        },
        _setCounceling(state, action) {
            state.counseling = action.payload;
        },
        _setSubjectiveAssessment(state, action) {
            state.subjectiveAssessment = action.payload;
        },
        _setFactorAndRiskAssessment(state, action) {
            state.factorAndRiskAssessment = action.payload;
        },
        _setHgbA1CAssessment(state, action) {
            state.hgbA1CAssessment = action.payload;
        },
        _setLipidAssessment(state, action) {
            state.lipidAssessment = action.payload;
        },
        _setError(state, action) {
            state.error = action.payload;
        },
        _setSuccess(state, action) {
            state.success = action.payload;
        },
        _toggleFetchingConsentForm(state, action) {
            state.fetchingConsentForm = action.payload;
        },
        _toggleFetchingVitalSign(state, action) {
            state.fetchingVitalSign = action.payload;
        },
        _toggleFetchingSdoh(state, action) {
            state.fetchingSdoh = action.payload;
        },
        _toggleShowDeleteAlert(state, action) {
            state.showDeleteAlert = action.payload;
        },
        _updateAssessmentMenuList(state, action) {
            state.assessmentMenuList = action.payload;
        },
        _updateSelectedRole(state, action) {
            state.selectedRole = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsDeleting(state, action) {
            state.isDeleting = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleShowAlleryModal(state, action) {
            state.showAlleryModal = action.payload;
        },
        _resetAssessment(state, action) {
            state.consentForm = new ConsentForm();
            state.consentDocumentValid = [] as ConsentDocumentValid[];
            state.vitalSign = new MembersVitalSigns();
            state.sdohAssessment = new SdohAssessment();
            state.diabetesFootAssessment = new DiabetesFootAssessment();
            state.counseling = new Counseling();
            state.subjectiveAssessment = new SubjectiveAssessment();
            state.miniMentalExam = new MiniMentalExam();
            state.memberAllergies = [] as MemberAllergy[];
            state.factorAndRiskAssessment = new FactorAndRiskAssessment();
            state.hgbA1CAssessment = new HgbA1CAssessment();
            state.lipidAssessment = new LipidAssessment();
            state.error = new MessageAlert();
            state.success = new MessageAlert();
            state.memberAssessment = new MemberAssessment();
            state.memberAssessmentsByType = [] as MemberAssessment[];
            state.assessmentMenuList = [] as MenuDetails[];
            state.deletingAlergyIds = [];
            state.fetchingConsentForm = false;
            state.fetchingVitalSign = false;
            state.fetchingSdoh = false;
            state.showDeleteAlert = false;
            state.isSaving = false;
            state.isFetching = false;
            state.isDeleting = false;
            state.showAlleryModal = false;
            state.error = new MessageAlert();
            state.success = new MessageAlert();
            state.selectedRole = '';
        },
    },
});

const {
    _setRoles,
    _setConsentForm,
    _setConsentDocumentValid,
    _setVitalSign,
    _setMemberAssessment,
    _setSdoh,
    _setDiabetesFootAssessment,
    _setMemberAllergies,
    _setDeletingAlergyIds,
    _setMiniMentalExam,
    _setCounceling,
    _setSubjectiveAssessment,
    _setFactorAndRiskAssessment,
    _setHgbA1CAssessment,
    _setLipidAssessment,
    _setError,
    _setSuccess,
    _toggleFetchingConsentForm,
    _toggleFetchingVitalSign,
    _toggleFetchingSdoh,
    _toggleShowDeleteAlert,
    _toggleIsSaving,
    _toggleIsFetching,
    _toggleIsDeleting,
    _updateAssessmentMenuList,
    _updateSelectedRole,
    _toggleShowAlleryModal,
    _resetAssessment,
} = assessmentSlice.actions;

export const resetAssessment = (): AppThunk => (dispatch, getState) => {
    dispatch(_resetAssessment(''));
};

export const setMemberAssessment =
    (memberAssessment: MemberAssessment): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberAssessment(memberAssessment));
    };

export const fetchRoles = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/role/roles`);
    dispatch(_setRoles(response.data));
};

export const saveMemberConsentForm =
    (data: ConsentForm, extraDraftData = {}): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        let formData = new FormData();

        if (Boolean(data.PartOneConsent) && Boolean(data.PartOneConsent.File)) {
            formData.append('part1Consent', data.PartOneConsent.File, data.PartOneConsent.FileName);
        }

        if (Boolean(data.PartTwoConsent) && Boolean(data.PartTwoConsent.File)) {
            formData.append('part2Consent', data.PartTwoConsent.File, data.PartTwoConsent.FileName);
        }

        let copyData = copyObject(data);
        formData.append('MemberId', copyData.MemberId);

        if (Boolean(copyData.PartOneConsent?.File)) {
            copyData.PartOneConsent.MemberId = copyData.MemberId;
        }
        if (Boolean(copyData.PartTwoConsent?.File)) {
            copyData.PartTwoConsent.MemberId = copyData.MemberId;
        }

        objectToFormData(copyData, formData, 'consentForm');
        const response = await axios.put(`${API_URL}/document/insertOrUpdateMemberConsentForm`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch(_setConsentForm(response.data));
        dispatch(_toggleIsSaving(false));
        //setTimeout(() => dispatch(gotoNextMenu()), 400);

        dispatch(prepareNewConcentEngagementDraft(response.data, data.MemberId, extraDraftData));
        dispatch(getConsentFormDocumentByMember(data.MemberId));

        const menuList = getState().assessment.assessmentMenuList;
        let nextMenuIndex;
        if (data.ConsentToService) {
            nextMenuIndex = menuList.findIndex((m) => m.IsActive) + 1;
        } else {
            nextMenuIndex = menuList.findIndex((m) => m.Name === assessmentMenuEnum.SET_APPOINTMENTS);
        }
        if (menuList.length >= nextMenuIndex) {
            setTimeout(() => {
                dispatch(
                    updateAssessmentMenu({
                        ActiveMenu: menuList[nextMenuIndex].Name,
                    } as PrepareMenuClass)
                );
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 0);
        }
    };

const prepareNewConcentEngagementDraft =
    (consentForm: ConsentForm, memberId: string, extraDraftData = {}): AppThunk =>
    (dispatch, getState) => {
        let engagementDraft = copyObject(getState().documentEngagement.engagementDraft || {});
        let summaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.ConsentForm);
        let summaryNote = {
            Id: consentForm.Id,
            CptCode: '',
            Zcode: '',
            Note: 'Completed Consent Form',
            Type: SummaryNoteTypeEnum.ConsentForm,
            IsConfirm: false,
            OrderBy: 30,
            DisplayName: 'Consent Form',
        };
        if (summaryNote.Id != null) {
            summaryNotes.push(summaryNote);
        }
        if (!Boolean(engagementDraft.MeansOfEngagement)) {
            delete engagementDraft.MeansOfEngagement;
        }

        engagementDraft.MemberId = memberId;
        engagementDraft.ConsentFormId = consentForm.Id;
        engagementDraft.SummaryNotes = summaryNotes;
        engagementDraft = { ...engagementDraft, ...extraDraftData };
        const assessmentSlice = getState().assessment;
        const selectedRole = assessmentSlice.roles.find((r) => r.Name === assessmentSlice.selectedRole);
        engagementDraft.AssessedRole = {
            Id: selectedRole.Id,
            RoleName: selectedRole.Name,
        } as ProviderRole;
        dispatch(saveEngagementDraft(engagementDraft));
    };

export const getMemberConsentForm =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleFetchingConsentForm(true));
        const response = await axios.get(`${API_URL}/document/getmemberconsentform?id=${id}`);
        if (response.data.PartOneConsent && response.data.PartOneConsent.FileName) {
            response.data.PartOneConsent.IsDocumentPreview = true;
        } else {
            response.data.PartOneConsent = new ConsentFormDocument();
        }

        if (response.data.PartTwoConsent && response.data.PartTwoConsent.FileName) {
            response.data.PartTwoConsent.IsDocumentPreview = true;
        } else {
            response.data.PartTwoConsent = new ConsentFormDocument();
        }

        response.data = removeEmptyDate(response.data);
        dispatch(_setConsentForm(response.data));
        dispatch(_toggleFetchingConsentForm(false));
    };

export const getConsentFormDocumentByMember =
    (memberId: string): AppThunk =>
    (dispatch, getState) => {
        axios
            .get(`${API_URL}/document/getconsentformbymemberid`, {
                params: { memberId },
            })
            .then((response) => {
                let consentFormDocs = response.data as ConsentDocumentValid[];
                dispatch(_setConsentDocumentValid(consentFormDocs));
                dispatch(updateAssessmentMenu({} as PrepareMenuClass));
            })
            .catch((err) => {
                dispatch(_setConsentDocumentValid([] as ConsentDocumentValid[]));
            });
    };

export const deleteConsentDocument =
    (documentId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsDeleting(true));
        const response = await axios.delete(`${API_URL}/document/deletedocument?documentId=` + documentId);
        if (response.status === 200) {
            let consentForm = getState().assessment?.consentForm;
            if (consentForm.PartOneConsent.Id === documentId) {
                consentForm = {
                    ...consentForm,
                    PartOneConsent: new ConsentFormDocument(),
                };
            } else {
                consentForm = {
                    ...consentForm,
                    PartTwoConsent: new ConsentFormDocument(),
                };
            }

            dispatch(_setConsentForm(consentForm));
        }
        dispatch(_toggleShowDeleteAlert(false));
        dispatch(_toggleIsDeleting(false));
    };

export const toggleShowDeleteAlert =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowDeleteAlert(show));
    };

export const gotoNextMenu = (): AppThunk => async (dispatch, getState) => {
    const menuList = getState().assessment.assessmentMenuList;
    const nextMenuIndex = menuList.findIndex((m) => m.IsActive) + 1;
    if (menuList.length >= nextMenuIndex + 1) {
        setTimeout(() => {
            dispatch(
                updateAssessmentMenu({
                    ActiveMenu: menuList[nextMenuIndex].Name,
                } as PrepareMenuClass)
            );
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 0);
    }
};

export const gotoPreviousMenu = (): AppThunk => async (dispatch, getState) => {
    const menuList = getState().assessment.assessmentMenuList.filter((a) => !a.IsDisabled);
    const previousMenuIndex = menuList.findIndex((m) => m.IsActive) - 1;
    if (menuList.length >= previousMenuIndex + 1) {
        dispatch(
            updateAssessmentMenu({
                ActiveMenu: menuList[previousMenuIndex].Name,
            } as PrepareMenuClass)
        );
    }
};

export const fetchMemberVitalSign =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleFetchingVitalSign(true));
        const response = await axios.get(`${API_URL}/vitalsigns/getmembervitalsignsbyid?id=${id}`);
        dispatch(_setVitalSign(response.data));
        dispatch(_toggleFetchingVitalSign(false));
    };

export const saveMemberVitalsign =
    (memberVitalSigns: MembersVitalSigns, isWithoutComplete = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        const response = await axios.put(`${API_URL}/vitalsigns/insertmembervitalsigns`, memberVitalSigns);
        dispatch(_setVitalSign(response.data));
        dispatch(gotoNextMenu());
        dispatch(_toggleIsSaving(false));
        dispatch(prepareVitalSignSummaryNotes(response.data, isWithoutComplete));
    };

export const setSelectedRole =
    (role: string): AppThunk =>
    async (dispatch) => {
        dispatch(_updateSelectedRole(role));
    };

export const updateMemberVitalSign =
    (memberVitalSigns: MembersVitalSigns, isWithoutComplete = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        await axios.put(`${API_URL}/vitalsigns/updatemembervitalsigns`, memberVitalSigns);
        dispatch(_setVitalSign(memberVitalSigns));
        dispatch(gotoNextMenu());
        dispatch(_toggleIsSaving(false));
        dispatch(prepareVitalSignSummaryNotes(memberVitalSigns, isWithoutComplete));
    };

const prepareVitalSignSummaryNotes =
    (memberVitalSigns: MembersVitalSigns, isWithoutComplete = false): AppThunk =>
    (dispatch, getState) => {
        let engagementDraft = copyObject(getState().documentEngagement.engagementDraft || {});
        let summaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.VitalSign);
        const bmi = parseFloat(memberVitalSigns.BMI);
        if (bmi > 0) {
            let summaryNote = null;
            if (bmi <= 19.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.1',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 20 && bmi <= 20.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.20',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 21 && bmi <= 21.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.21',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 22 && bmi <= 22.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.22',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 23 && bmi <= 23.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.23',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 24 && bmi <= 24.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.24',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 25 && bmi <= 25.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.25',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 26 && bmi <= 26.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.26',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 27 && bmi <= 27.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.27',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 28 && bmi <= 28.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.28',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 29 && bmi <= 29.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.29',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 30 && bmi <= 30.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.30',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 31 && bmi <= 31.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.31',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 32 && bmi <= 32.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.32',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 33 && bmi <= 33.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.33',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 34 && bmi <= 34.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.34',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 35 && bmi <= 35.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.35',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 36 && bmi <= 36.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.36',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 37 && bmi <= 37.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.37',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 38 && bmi <= 38.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.38',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 39 && bmi <= 39.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.39',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 40 && bmi <= 44.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.41',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 45 && bmi <= 49.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.42',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 50 && bmi <= 59.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.43',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 60 && bmi <= 69.99) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.44',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bmi >= 70) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3008F',
                    Zcode: 'Z68.45',
                    Note: `Body mass index BMI - ${bmi}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            }
            if (Boolean(summaryNote?.Id)) {
                summaryNotes.push(summaryNote);
            }
        }

        // For BP systolic
        const bpSystolic = parseFloat(memberVitalSigns.BpSystolic);
        if (bpSystolic > 0) {
            let summaryNote = {};
            if (bpSystolic < 130) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3074F',
                    Zcode: '',
                    Note: `BP systolic - ${bpSystolic}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bpSystolic >= 130 && bpSystolic <= 139) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3075F',
                    Zcode: '',
                    Note: `BP systolic - ${bpSystolic}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            } else if (bpSystolic >= 140) {
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3077F',
                    Zcode: '',
                    Note: `BP systolic - ${bpSystolic}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            }
            summaryNotes.push(summaryNote);
        }

        // For BP diastolic
        const bpDiastolic = parseFloat(memberVitalSigns.BpDiastolic);
        if (bpDiastolic > 0) {
            let summaryNote = {};
            if (bpDiastolic < 80)
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3078F',
                    Zcode: '',
                    Note: `BP diastolic - ${bpDiastolic}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            else if (bpDiastolic >= 80 && bpDiastolic <= 89)
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3079F',
                    Zcode: '',
                    Note: `BP diastolic - ${bpDiastolic}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            else if (bpDiastolic >= 90)
                summaryNote = {
                    Id: memberVitalSigns.Id,
                    CptCode: '3080F',
                    Zcode: '',
                    Note: `BP diastolic - ${bpDiastolic}`,
                    Type: SummaryNoteTypeEnum.VitalSign,
                    IsConfirm: false,
                    OrderBy: 1,
                    DisplayName: 'Vital Sign',
                };
            summaryNotes.push(summaryNote);
        }

        // For glucose
        const glucoseMgDl = parseFloat(memberVitalSigns.GlucoseMgDl);
        if (glucoseMgDl > 0) {
            let summaryNote = {
                Id: memberVitalSigns.Id,
                CptCode: '82962',
                Zcode: '',
                Note: `Glucose - ${glucoseMgDl}`,
                Type: SummaryNoteTypeEnum.VitalSign,
                IsConfirm: false,
                OrderBy: 1,
                DisplayName: 'Vital Sign',
            };
            summaryNotes.push(summaryNote);
        }

        if (isWithoutComplete) {
            engagementDraft.VitalSignStatus = assessmentStatusEnum.COMPLETED;
        }

        engagementDraft.SummaryNotes = summaryNotes;
        dispatch(saveEngagementDraft({ ...engagementDraft, VitalsignAssessmentId: memberVitalSigns.Id }));
    };

export const getAssessmentByType =
    (type: AssessmentTypeEnum, memberId: string, engagementDraftId = ''): AppThunk =>
    async (dispatch, getState) => {
        const engagementDraft = getState().documentEngagement.engagementDraft;
        let id = null;
        if (engagementDraft) {
            switch (type) {
                case AssessmentTypeEnum.PastPresentHistory:
                    id = engagementDraft.PastPresentHistoryId;
                    break;
                case AssessmentTypeEnum.PastSurgicalHistory:
                    id = engagementDraft.PastSurgicalHistoryId;
                    break;
                case AssessmentTypeEnum.SdohAssessment:
                    id = engagementDraft.SdohAssessmentId;
                    break;
                case AssessmentTypeEnum.ReviewSystems:
                    id = engagementDraft.ReviewSystemId;
                    break;
                case AssessmentTypeEnum.PhysicalExam:
                    id = engagementDraft.PhysicalExamId;
                    break;
                case AssessmentTypeEnum.UrinalysisReport:
                    id = engagementDraft.UrinalysisReportId;
                    break;
                case AssessmentTypeEnum.DiabetesFootAssessment:
                    id = engagementDraft.DiabetesFootAssessmentId;
                    break;
                case AssessmentTypeEnum.PatientHealthQuestionnaire:
                    id = engagementDraft.PhqAssessmentId;
                    break;
            }
        }

        if (Boolean(id)) {
            dispatch(getMemberAssessmentById(id));
        } else {
            dispatch(_toggleIsFetching(true));
            const response = await axios.get(`${API_URL}/assessment/getmemberassessment/${type}?memberId=${memberId}&engagementDraftId=${engagementDraftId}`);
            response.data.Id = null;
            response.data.Status = StatusEnum.New;
            dispatch(_setMemberAssessment(response.data));
            dispatch(_toggleIsFetching(false));
        }
    };

export const getMemberAssessmentById =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/assessment/getmemberassessmentbyid/${id}`)
            .then((response) => {
                dispatch(_setMemberAssessment(response.data));
            })
            .catch((err) => {
                dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const updateMemberAssessmentModel =
    (category: AssessmentCategory): AppThunk =>
    async (dispatch, getState) => {
        let memberAssessment = copyObject(getState().assessment.memberAssessment);
        memberAssessment.Assessment.Categories = memberAssessment.Assessment.Categories.map((cate) => {
            if (category.Id === cate.Id) {
                return category;
            }

            return cate;
        });

        dispatch(_setMemberAssessment(memberAssessment));
    };

export const saveAssessmentWorkflow =
    (category: AssessmentCategory, isWithoutComplete = false, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        if (gotoNext) {
            dispatch(_toggleIsSaving(true));
        }
        let memberAssessment = copyObject(getState().assessment.memberAssessment);
        if (isWithoutComplete) {
            memberAssessment.Status = StatusEnum.Completed;
        }
        memberAssessment.Assessment.Categories = memberAssessment.Assessment.Categories.map((cate) => {
            if (category.Id === cate.Id) {
                return category;
            }

            return cate;
        });

        dispatch(_setMemberAssessment(memberAssessment));
        const response = await axios.put(`${API_URL}/assessment/createorupdateassessmentworkflow`, memberAssessment);
        dispatch(_setMemberAssessment(response.data));
        dispatch(_toggleIsFetching(false));

        let engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
        switch (memberAssessment.Assessment.AssessmentType) {
            case AssessmentTypeEnum.PastPresentHistory:
                engagementDraft.SummaryNotes = engagementDraft.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.PastPresentHistory) || [];
                engagementDraft.SummaryNotes.push({
                    Id: response.data.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: 'Assessed Past Present History',
                    Type: SummaryNoteTypeEnum.PastPresentHistory,
                    IsConfirm: false,
                    OrderBy: 4,
                    DisplayName: 'Past Present History',
                });

                engagementDraft.PastPresentHistoryId = response.data.Id;
                break;
            case AssessmentTypeEnum.PastSurgicalHistory:
                engagementDraft.SummaryNotes = engagementDraft.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.PastSurgicalHistory) || [];
                engagementDraft.SummaryNotes.push({
                    Id: response.data.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: 'Assessed Past Surgical History',
                    Type: SummaryNoteTypeEnum.PastSurgicalHistory,
                    IsConfirm: false,
                    OrderBy: 4,
                    DisplayName: 'Past Surgical History',
                });

                engagementDraft.PastSurgicalHistoryId = response.data.Id;
                break;
            case AssessmentTypeEnum.ReviewSystems:
                engagementDraft.SummaryNotes = engagementDraft.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.ReviewSystems) || [];
                engagementDraft.SummaryNotes.push({
                    Id: response.data.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: 'Assessed Review Systems',
                    Type: SummaryNoteTypeEnum.ReviewSystems,
                    IsConfirm: false,
                    OrderBy: 4,
                    DisplayName: 'Assessed Review Systems',
                });

                engagementDraft.ReviewSystemId = response.data.Id;
                break;
            case AssessmentTypeEnum.PhysicalExam:
                engagementDraft.SummaryNotes = engagementDraft.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.PhysicalExam) || [];
                engagementDraft.SummaryNotes.push({
                    Id: response.data.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: 'Assessed Physical Exam',
                    Type: SummaryNoteTypeEnum.PhysicalExam,
                    IsConfirm: false,
                    OrderBy: 4,
                    DisplayName: 'Physical Exam',
                });

                engagementDraft.PhysicalExamId = response.data.Id;
                break;
            case AssessmentTypeEnum.UrinalysisReport:
                engagementDraft.SummaryNotes = engagementDraft.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.UrinalysisReport) || [];
                engagementDraft.SummaryNotes.push({
                    Id: response.data.Id,
                    CptCode: '81002',
                    Zcode: '',
                    Note: 'Assessed Urinalysis Report',
                    Type: SummaryNoteTypeEnum.UrinalysisReport,
                    IsConfirm: false,
                    OrderBy: 4,
                    DisplayName: 'Assessed Urinalysis Report',
                });

                engagementDraft.UrinalysisReportId = response.data.Id;
                break;
            case AssessmentTypeEnum.DiabetesFootAssessment:
                engagementDraft.DiabetesFootAssessmentId = response.data.Id;
                break;
            case AssessmentTypeEnum.PatientHealthQuestionnaire:
                engagementDraft.SummaryNotes = engagementDraft.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.PHQ9) || [];
                let total = 0;
                const category = memberAssessment?.Assessment?.Categories[0];
                const firstQuestions = category?.Questions?.slice(0, category?.Questions.length - 1);
                firstQuestions.forEach((question) => {
                    total += question?.Options?.find((o) => o.IsSelected)?.Score || 0;
                });

                if (total > 14 && total <= 23) {
                    engagementDraft.SummaryNotes.push({
                        Id: response.data.Id,
                        CptCode: '',
                        Zcode: '',
                        Note: 'PHQ9 score is greater than 14 - Consider Antidepressant',
                        Type: SummaryNoteTypeEnum.PHQ9,
                        IsConfirm: false,
                        OrderBy: 8,
                        DisplayName: 'PHQ9',
                    });
                }
                if (total > 23) {
                    engagementDraft.SummaryNotes.push({
                        Id: response.data.Id,
                        CptCode: '',
                        Zcode: '',
                        Note: 'PHQ9 score is greater than 23 - Alert to BH',
                        Type: SummaryNoteTypeEnum.PHQ9,
                        IsConfirm: false,
                        OrderBy: 8,
                        DisplayName: 'PHQ9',
                    });
                }
                engagementDraft.PhqAssessmentId = response.data.Id;
                break;
        }
        dispatch(saveEngagementDraft(engagementDraft));
        if (gotoNext) {
            dispatch(gotoNextMenu());
            dispatch(_toggleIsSaving(false));
        }
    };

export const getLastSdohAssessment =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleFetchingSdoh(true));
        axios
            .get(`${API_URL}/assessment/getlastsdohassessment`, { params: { memberId } })
            .then((response) => {
                if (Boolean(response.data)) {
                    response.data.Id = null;
                    response.data.IsLast = true;
                    dispatch(_setSdoh(response.data));
                }
            })
            .finally(() => {
                dispatch(_toggleFetchingSdoh(false));
            });
    };

export const fetchSdohAssessment =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleFetchingSdoh(true));
        const response = await axios.get(`${API_URL}/assessment/getsdohassessmentbyid?id=${id}`);
        dispatch(_setSdoh(response.data));
        dispatch(_toggleFetchingSdoh(false));
    };

export const saveSdohAssessment =
    (sdohAssessment: SdohAssessment): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        const response = await axios.put(`${API_URL}/assessment/createsdohassessment`, sdohAssessment);
        dispatch(_setSdoh(response.data));
        dispatch(_toggleIsSaving(false));
        sdohAssessment.Id = response.data.Id;
        dispatch(addSdohSummary(sdohAssessment));
    };

export const updateSdohAssessment =
    (sdohAssessment: SdohAssessment): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        try {
            await axios.put(`${API_URL}/assessment/updatesdohassessment`, sdohAssessment);
            dispatch(_setSdoh(sdohAssessment));
            dispatch(addSdohSummary(sdohAssessment));
        } catch (error) {
            dispatch(_setSdoh(sdohAssessment));
        }
        dispatch(_toggleIsSaving(false));
    };

export const prepareSdohRequest =
    (sdohAssessment: SdohAssessment): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        try {
            await axios.put(`${API_URL}/assessment/preparesdohrequest`, sdohAssessment);
        } catch (error) {}
        dispatch(_toggleIsSaving(false));
    };

export const addSdohSummary =
    (sdohAssessment: SdohAssessment): AppThunk =>
    async (dispatch, getState) => {
        let engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
        let summaryNotes = engagementDraft.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.SDOH) || [];
        if (
            sdohAssessment.FamilyAndHome.HousingSituationToday &&
            sdohAssessment.FamilyAndHome.HousingSituationToday.Answer != null &&
            sdohAssessment.FamilyAndHome.HousingSituationToday.Answer.includes('I do not have housing')
        ) {
            summaryNotes.push({
                Id: sdohAssessment.Id,
                CptCode: 'T1016',
                Zcode: 'Z59.00',
                Note: 'Homelessness unspecified',
                Type: 'SDOH',
                IsConfirm: false,
                OrderBy: 3,
                DisplayName: 'SDOH',
            });
        }
        if (
            sdohAssessment.MoneyAndResources.Food &&
            sdohAssessment.MoneyAndResources.Food.Answer != null &&
            sdohAssessment.MoneyAndResources.Food.Answer === 'Yes'
        ) {
            summaryNotes.push({
                Id: sdohAssessment.Id,
                CptCode: 'T1016',
                Zcode: 'Z59.48',
                Note: 'Other specified lack of adequate food',
                Type: 'SDOH',
                IsConfirm: false,
                OrderBy: 3,
                DisplayName: 'SDOH',
            });
        }
        if (
            sdohAssessment.MoneyAndResources.Utilities &&
            sdohAssessment.MoneyAndResources.Utilities.Answer != null &&
            sdohAssessment.MoneyAndResources.Utilities.Answer === 'Yes'
        ) {
            summaryNotes.push({
                Id: sdohAssessment.Id,
                CptCode: 'T1016',
                Zcode: 'Z59.6',
                Note: 'Low income',
                Type: 'SDOH',
                IsConfirm: false,
                OrderBy: 3,
                DisplayName: 'SDOH',
            });
        }
        if (sdohAssessment.Comment) {
            summaryNotes.push({
                Id: sdohAssessment.Id,
                CptCode: null,
                Zcode: null,
                Note: sdohAssessment.Comment,
                Type: 'SDOH',
                IsConfirm: true,
                OrderBy: 3,
                DisplayName: 'SDOH',
            });
        }

        engagementDraft.SummaryNotes = summaryNotes;
        engagementDraft.SdohAssessmentId = sdohAssessment.Id;
        dispatch(saveEngagementDraft(engagementDraft));
    };

export const getLastFootDiabetesAssessment =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        let response = await axios.get(`${API_URL}/assessment/getlastfootdiabetesassessment?memberId=${memberId}`);
        if (response.status !== 200) {
            response = await axios.get(`${API_URL}/assessment/getnewdiabetesfootassessment`);
        }
        dispatch(_setDiabetesFootAssessment(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const saveFootDiabetesAssessment =
    (diabetesFootAssessment: DiabetesFootAssessment, saveOnlyLocal: boolean = false, canGotoNextMenu: boolean = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        dispatch(_setDiabetesFootAssessment(diabetesFootAssessment));
        if (!saveOnlyLocal) {
            const isUpdate = Boolean(diabetesFootAssessment.Id);
            const url = `${API_URL}/assessment/${isUpdate ? 'updatediabetesfootassessment' : 'creatediabetesfootassessment'}`;

            const response = await axios.put(url, diabetesFootAssessment);
            if (response.data) {
                dispatch(_setDiabetesFootAssessment(response.data));
            }

            const engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
            engagementDraft.SummaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.DiabetesFootAssessment);
            engagementDraft.SummaryNotes.push({
                Id: diabetesFootAssessment.Id || response.data.Id,
                CptCode: '',
                Zcode: '',
                Note: 'Assessed Diabetes Foot Assessment',
                Type: SummaryNoteTypeEnum.DiabetesFootAssessment,
                IsConfirm: false,
                OrderBy: 11,
                DisplayName: 'Diabetes Foot',
            });
            dispatch(saveEngagementDraft({ ...engagementDraft, DiabetesFootAssessmentId: diabetesFootAssessment.Id || response.data.Id }));
        }
        dispatch(_toggleIsSaving(false));
        if (canGotoNextMenu) {
            dispatch(gotoNextMenu());
        }
    };

export const getMemberAllergies =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/allergies/`, {
            params: { memberId },
        });
        dispatch(_setMemberAllergies(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const toggleShowAlleryModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowAlleryModal(show));
    };

export const saveMemberAllergy =
    (memberAllergy: MemberAllergy): AppThunk =>
    async (dispatch, getState) => {
        memberAllergy.Source = '';
        const allergies = getState().assessment.memberAllergies;
        dispatch(_toggleIsSaving(true));
        const isUpdate = Boolean(memberAllergy.Id);
        const response = isUpdate ? await axios.put(`${API_URL}/allergies/`, memberAllergy) : await axios.post(`${API_URL}/allergies/`, memberAllergy);

        if (isUpdate) {
            dispatch(
                _setMemberAllergies(
                    allergies.map((allergy) => {
                        return allergy.Id === memberAllergy.Id ? memberAllergy : allergy;
                    })
                )
            );
        } else {
            dispatch(_setMemberAllergies([...allergies, response.data]));
        }

        try {
            const veradigmResponse = await axios.put(`${API_URL}/allergies/sendveradigmallergy`, response.data || memberAllergy);
            console.log('veradigmResponse: ', veradigmResponse);
            if (isUpdate) {
                dispatch(
                    _setMemberAllergies(
                        allergies.map((allergy) => {
                            return allergy.Id === memberAllergy.Id ? memberAllergy : allergy;
                        })
                    )
                );
            } else {
                dispatch(_setMemberAllergies([...allergies, veradigmResponse.data]));
            }
            dispatch(_toggleShowAlleryModal(false));
            dispatch(_toggleIsSaving(false));
        } catch (error) {
            console.log(error);
            dispatch(_toggleIsSaving(false));
        }
    };

export const deleteMemberAllergy =
    (allergyId: string): AppThunk =>
    async (dispatch, getState) => {
        const { memberAllergies, deletingAlergyIds } = getState().assessment;
        dispatch(_setDeletingAlergyIds([...deletingAlergyIds, allergyId]));
        await axios.delete(`${API_URL}/allergies/delete`, {
            params: { allergyId },
        });
        dispatch(_setMemberAllergies(memberAllergies.filter((m) => m.Id !== allergyId)));
        dispatch(_setDeletingAlergyIds(deletingAlergyIds.filter((d) => d !== allergyId)));
        dispatch(_toggleIsSaving(false));
    };

export const getCounselingAssessmentById =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/assessment/getcounselingassessmentbyid/`, { params: { id } });
        dispatch(_setCounceling(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const saveCounselingAssessment =
    (counseling: Counseling): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        const isUpdate = Boolean(counseling.Id);
        const url = isUpdate ? `${API_URL}/assessment/updatecounselingassessment/` : `${API_URL}/assessment/createcounselingassessment/`;

        const response = await axios.put(url, counseling);
        dispatch(_setCounceling(isUpdate ? counseling : response.data));
        dispatch(_toggleIsSaving(false));
        dispatch(gotoNextMenu());
        if (!isUpdate) {
            const engagementDraft = getState().documentEngagement.engagementDraft;
            dispatch(
                saveEngagementDraft({
                    ...engagementDraft,
                    CounselingAssessmentId: response.data.Id,
                })
            );
        }
    };

export const getMinimentalExamStatusById =
    (id: string = null): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await (Boolean(id)
            ? axios.get(`${API_URL}/assessment/getminimentalexamstatusbyid/`, {
                  params: { id },
              })
            : axios.get(`${API_URL}/assessment/prepareminimentalexamstatus/`));
        dispatch(_setMiniMentalExam(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const saveMiniMentalExamStatus =
    (miniMentalExam: MiniMentalExam, saveLocal = false, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMiniMentalExam(miniMentalExam));
        if (!saveLocal) {
            dispatch(_toggleIsSaving(true));
            const isUpdate = Boolean(miniMentalExam.Id);
            const url = isUpdate ? `${API_URL}/assessment/updateminimentalexamstatus/` : `${API_URL}/assessment/createminimentalexamstatus/`;

            const response = await axios.put(url, miniMentalExam);
            if (Boolean(response.data)) {
                dispatch(_setMiniMentalExam(response.data));
            }

            dispatch(_toggleIsSaving(false));
            dispatch(prepareMiniMentalSummaryNotes(isUpdate ? miniMentalExam : response.data));

            if (gotoNext) {
                dispatch(gotoNextMenu());
            }
        }
    };

const prepareMiniMentalSummaryNotes =
    (miniMentalExam: MiniMentalExam): AppThunk =>
    (dispatch, getState) => {
        if (Boolean(miniMentalExam.Id)) {
            let engagementDraft = copyObject(getState().documentEngagement.engagementDraft || {});
            let summaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.MiniMentalExam);
            if (Boolean(miniMentalExam.GeneralObservation?.Appearance?.Type)) {
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Appearance - ${miniMentalExam.GeneralObservation.Appearance.Type.Name}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.GeneralObservation?.Demeanor?.Types?.length)) {
                let types = [];
                miniMentalExam.GeneralObservation.Demeanor.Types.forEach((type) => {
                    types.push(type.Name);
                });
                let demeanorTypes = types.join(', ');
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Demeanor - ${demeanorTypes}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.GeneralObservation?.EyeContact?.Type)) {
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Eye Contact - ${miniMentalExam.GeneralObservation.EyeContact.Type.Name}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.GeneralObservation?.Behavior?.Types?.length)) {
                let types = [];
                miniMentalExam.GeneralObservation.Behavior.Types.forEach((type) => {
                    types.push(type.Name);
                });
                let behaviorTypes = types.join(', ');
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Behavior - ${behaviorTypes}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.GeneralObservation?.Speech?.Types?.length)) {
                let types = [];
                miniMentalExam.GeneralObservation.Speech.Types.forEach((type) => {
                    types.push(type.Name);
                });
                let speechTypes = types.join(', ');
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Speech - ${speechTypes}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.Thought?.ThoughtProcess?.Types?.length)) {
                let types = [];
                miniMentalExam.Thought.ThoughtProcess.Types.forEach((type) => {
                    types.push(type.Name);
                });
                let thoughtProcessTypes = types.join(', ');
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Thought Process - ${thoughtProcessTypes}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.Thought?.Perceptions?.Type)) {
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Perceptions - ${miniMentalExam.Thought.Perceptions.Type.Name}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.Thought?.DTS?.Type)) {
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `DTS - ${miniMentalExam.Thought.DTS.Type.Name}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.Thought?.DTO?.Type)) {
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `DTO - ${miniMentalExam.Thought.DTO.Type.Name}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.Emotion?.Mood?.Types?.length)) {
                let types = [];
                miniMentalExam.Emotion.Mood.Types.forEach((type) => {
                    types.push(type.Name);
                });
                let moodTypes = types.join(', ');
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Mood - ${moodTypes}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }
            if (Boolean(miniMentalExam.Emotion?.Affect?.Type)) {
                summaryNotes.push({
                    Id: miniMentalExam.Id,
                    CptCode: '',
                    Zcode: '',
                    Note: `Affect - ${miniMentalExam.Emotion.Affect.Type.Name}`,
                    Type: SummaryNoteTypeEnum.MiniMentalExam,
                    IsConfirm: false,
                    OrderBy: 19,
                    DisplayName: 'Mini Mental Exam',
                });
            }

            engagementDraft.SummaryNotes = summaryNotes;
            dispatch(saveEngagementDraft({ ...engagementDraft, MiniMentalExamId: miniMentalExam.Id }));
        }
    };

export const getSubjectiveAssessmentById =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/assessment/getsubjectivesassessmentbyid/`, { params: { id } });
        dispatch(_setSubjectiveAssessment(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const saveSubjectiveAssessment =
    (subjectiveAssessment: SubjectiveAssessment, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        const isUpdate = Boolean(subjectiveAssessment.Id);
        const url = isUpdate ? `${API_URL}/assessment/updatesubjectiveassessment/` : `${API_URL}/assessment/createsubjectiveassessment/`;

        const response = await axios.put(url, subjectiveAssessment);
        dispatch(_setSubjectiveAssessment(isUpdate ? subjectiveAssessment : response.data));
        dispatch(_toggleIsSaving(false));
        if (!isUpdate) {
            const engagementDraft = getState().documentEngagement.engagementDraft;
            dispatch(
                saveEngagementDraft({
                    ...engagementDraft,
                    SubjectiveAssessmentId: response.data.Id,
                })
            );
        }
        if (gotoNext) {
            dispatch(gotoNextMenu());
        }
    };

export const getFactorAndRiskAssessmentById =
    (id: string = null): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await (Boolean(id)
            ? axios.get(`${API_URL}/assessment/getfactorsandrisksassessmentbyid/`, { params: { id } })
            : axios.get(`${API_URL}/assessment/preparefactorsandrisksassessment/`));
        dispatch(_setFactorAndRiskAssessment(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const saveFactorAndRiskAssessment =
    (factorAndRiskAssessment: FactorAndRiskAssessment, saveLocal = false, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setFactorAndRiskAssessment(factorAndRiskAssessment));
        if (!saveLocal) {
            dispatch(_toggleIsSaving(true));
            const isUpdate = Boolean(factorAndRiskAssessment.Id);
            const url = isUpdate ? `${API_URL}/assessment/updatefactorandriskassessment/` : `${API_URL}/assessment/createfactorandriskassessment/`;

            const response = await axios.put(url, factorAndRiskAssessment);
            if (Boolean(response.data)) {
                dispatch(_setFactorAndRiskAssessment(response.data));
            }

            dispatch(_toggleIsSaving(false));
            if (!isUpdate) {
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(
                    saveEngagementDraft({
                        ...engagementDraft,
                        FactorAndRiskAssessmentId: response.data.Id,
                    })
                );
            }
            if (gotoNext) {
                dispatch(gotoNextMenu());
            }
        }
    };

export const saveBarrierAndStrengthInCarePlan =
    (factorAndRiskAssessment: FactorAndRiskAssessment, memberId: string): AppThunk =>
    async (dispatch, getState) => {
        let carePlan = new IntegratedCarePlan();
        let barriers = [];
        if (
            factorAndRiskAssessment.LifeContext.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.LifeContext.MemberOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.LifeContext.MemberReports
        )
            barriers.push({ Name: factorAndRiskAssessment.LifeContext.MemberReports });
        if (
            factorAndRiskAssessment.LifeContext.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.LifeContext.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.LifeContext.ProviderAssessed
        )
            barriers.push({ Name: factorAndRiskAssessment.LifeContext.ProviderAssessed });
        if (
            factorAndRiskAssessment.HealthRisk.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.HealthRisk.MemberOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.HealthRisk.MemberReports
        )
            barriers.push({ Name: factorAndRiskAssessment.HealthRisk.MemberReports });
        if (
            factorAndRiskAssessment.HealthRisk.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.HealthRisk.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.HealthRisk.ProviderAssessed
        )
            barriers.push({ Name: factorAndRiskAssessment.HealthRisk.ProviderAssessed });
        if (
            factorAndRiskAssessment.LearningLiteracy.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.LearningLiteracy.MemberOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.LearningLiteracy.MemberReports
        )
            barriers.push({ Name: factorAndRiskAssessment.LearningLiteracy.MemberReports });
        if (
            factorAndRiskAssessment.LearningLiteracy.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.LearningLiteracy.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.LearningLiteracy.ProviderAssessed
        )
            barriers.push({ Name: factorAndRiskAssessment.LearningLiteracy.ProviderAssessed });
        if (
            factorAndRiskAssessment.StrengthOrAsset.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.StrengthOrAsset.MemberOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.StrengthOrAsset.MemberReports
        )
            barriers.push({ Name: factorAndRiskAssessment.StrengthOrAsset.MemberReports });
        if (
            factorAndRiskAssessment.StrengthOrAsset.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.StrengthOrAsset.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Barrier &&
            factorAndRiskAssessment.StrengthOrAsset.ProviderAssessed
        )
            barriers.push({ Name: factorAndRiskAssessment.StrengthOrAsset.ProviderAssessed });

        let strengths = [];
        if (
            factorAndRiskAssessment.LifeContext.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.LifeContext.MemberOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.LifeContext.MemberReports
        )
            strengths.push({ Name: factorAndRiskAssessment.LifeContext.MemberReports });
        if (
            factorAndRiskAssessment.LifeContext.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.LifeContext.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.LifeContext.ProviderAssessed
        )
            strengths.push({ Name: factorAndRiskAssessment.LifeContext.ProviderAssessed });
        if (
            factorAndRiskAssessment.HealthRisk.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.HealthRisk.MemberOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.HealthRisk.MemberReports
        )
            strengths.push({ Name: factorAndRiskAssessment.HealthRisk.MemberReports });
        if (
            factorAndRiskAssessment.HealthRisk.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.HealthRisk.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.HealthRisk.ProviderAssessed
        )
            strengths.push({ Name: factorAndRiskAssessment.HealthRisk.ProviderAssessed });
        if (
            factorAndRiskAssessment.LearningLiteracy.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.LearningLiteracy.MemberOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.LearningLiteracy.MemberReports
        )
            strengths.push({ Name: factorAndRiskAssessment.LearningLiteracy.MemberReports });
        if (
            factorAndRiskAssessment.LearningLiteracy.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.LearningLiteracy.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.LearningLiteracy.ProviderAssessed
        )
            strengths.push({ Name: factorAndRiskAssessment.LearningLiteracy.ProviderAssessed });
        if (
            factorAndRiskAssessment.StrengthOrAsset.MemberReportsIncludedInCarePlan &&
            factorAndRiskAssessment.StrengthOrAsset.MemberOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.StrengthOrAsset.MemberReports
        )
            strengths.push({ Name: factorAndRiskAssessment.StrengthOrAsset.MemberReports });
        if (
            factorAndRiskAssessment.StrengthOrAsset.ProviderAssessedIncludedInCarePlan &&
            factorAndRiskAssessment.StrengthOrAsset.ProviderOption === FactorRiskAssessmentLookupTypeEnum.Strength &&
            factorAndRiskAssessment.StrengthOrAsset.ProviderAssessed
        )
            strengths.push({ Name: factorAndRiskAssessment.StrengthOrAsset.ProviderAssessed });

        delete carePlan.MemberId;
        carePlan.Barriers = barriers;
        carePlan.Strengths = strengths;

        let factorAndRiskAssessmentId = factorAndRiskAssessment.Id;
        axios
            .put(`${API_URL}/careplan/addcareplanfromriskfactorassessment`, carePlan, { params: { memberId, factorAndRiskAssessmentId } })
            .then((response) => {});
    };

export const getHgba1cAssessmentById =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/assessment/gethgba1assessmentbyid`, { params: { id } });
        dispatch(_setHgbA1CAssessment(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const getLastHgba1cAssessment =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        try {
            const response = await axios.get(`${API_URL}/assessment/getlasthgba1assessment`, { params: { memberId } });
            if (response.status !== 200) {
                dispatch(getMemberBasicInfo(memberId));
            } else {
                dispatch(_setHgbA1CAssessment(response.data));
                dispatch(_toggleIsFetching(false));
            }
        } catch (error) {
            dispatch(getMemberBasicInfo(memberId));
        }
    };

export const setHgba1cByMemberBasicInfo =
    (memberBasicInfo: MemberBasicInfo): AppThunk =>
    async (dispatch, getState) => {
        const hgbA1CAssessment = {
            MemberId: memberBasicInfo.Id,
            MemberName: memberBasicInfo.Name,
            Age: memberBasicInfo.Age,
            DOB: memberBasicInfo.DOB,
            TestDate: new Date().toISOString(),
            TestingMachine: TestingMachines[0],
        } as HgbA1CAssessment;
        dispatch(_setHgbA1CAssessment(hgbA1CAssessment));
        dispatch(_toggleIsFetching(false));
    };

export const saveHgbA1CAssessment =
    (hgbA1CAssessment: HgbA1CAssessment, saveLocal = false, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setHgbA1CAssessment(hgbA1CAssessment));
        if (!saveLocal) {
            dispatch(_toggleIsSaving(true));
            const isUpdate = Boolean(hgbA1CAssessment.Id);
            const url = isUpdate ? `${API_URL}/assessment/updatehgba1cassessment/` : `${API_URL}/assessment/createhgba1cassessment/`;

            axios
                .put(url, hgbA1CAssessment)
                .then((response) => {
                    if (Boolean(response.data)) {
                        dispatch(_setHgbA1CAssessment(response.data));
                    }

                    const hgbA1CAId = isUpdate ? hgbA1CAssessment.Id : response.data.Id;

                    const engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
                    engagementDraft.SummaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.HgbA1CAssessment);
                    if (hgbA1CAssessment.HgbA1CResult > 0) {
                        let summaryNote = {};
                        if (hgbA1CAssessment.HgbA1CResult < 7) {
                            summaryNote = {
                                Id: hgbA1CAssessment.Id,
                                CptCode: '3044F',
                                Zcode: '',
                                Note: 'Assessed HgbA1C Assessment',
                                Type: SummaryNoteTypeEnum.HgbA1CAssessment,
                                IsConfirm: false,
                                OrderBy: 10,
                                DisplayName: 'HgbA1C Assessment',
                            };
                        } else if (hgbA1CAssessment.HgbA1CResult >= 7 && hgbA1CAssessment.HgbA1CResult < 8) {
                            summaryNote = {
                                Id: hgbA1CAssessment.Id,
                                CptCode: '3051F',
                                Zcode: '',
                                Note: 'Assessed HgbA1C Assessment',
                                Type: SummaryNoteTypeEnum.HgbA1CAssessment,
                                IsConfirm: false,
                                OrderBy: 10,
                                DisplayName: 'HgbA1C Assessment',
                            };
                        } else if (hgbA1CAssessment.HgbA1CResult >= 8 && hgbA1CAssessment.HgbA1CResult <= 9) {
                            summaryNote = {
                                Id: hgbA1CAssessment.Id,
                                CptCode: '3052F',
                                Zcode: '',
                                Note: 'Assessed HgbA1C Assessment',
                                Type: SummaryNoteTypeEnum.HgbA1CAssessment,
                                IsConfirm: false,
                                OrderBy: 10,
                                DisplayName: 'HgbA1C Assessment',
                            };
                        } else if (hgbA1CAssessment.HgbA1CResult > 9) {
                            summaryNote = {
                                Id: hgbA1CAssessment.Id,
                                CptCode: '3046F',
                                Zcode: '',
                                Note: 'Assessed HgbA1C Assessment',
                                Type: SummaryNoteTypeEnum.HgbA1CAssessment,
                                IsConfirm: false,
                                OrderBy: 10,
                                DisplayName: 'HgbA1C Assessment',
                            };
                        }

                        engagementDraft.SummaryNotes.push(summaryNote);
                    }
                    dispatch(saveEngagementDraft({ ...engagementDraft, HgbA1CAssessmentId: hgbA1CAId }));

                    if (gotoNext) {
                        dispatch(gotoNextMenu());
                    }
                })
                .catch((err) => {
                    dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
                })
                .finally(() => {
                    dispatch(_toggleIsSaving(false));
                });
        }
    };

export const setAssessmentError =
    (error: MessageAlert): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setError(error));
    };

export const resetAssessmentError = (): AppThunk => (dispatch, getState) => {
    dispatch(_setError(new MessageAlert()));
};

export const setAssessmentSuccess =
    (success: MessageAlert): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setSuccess(success));
    };

export const resetAssessmentSuccess = (): AppThunk => (dispatch, getState) => {
    dispatch(_setSuccess(new MessageAlert()));
};

export const getLipidAssessmentById =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/assessment/getlipidassessmentbyid`, { params: { id } });

        dispatch(_setLipidAssessment(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const getLastLipidAssessment =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        try {
            const response = await axios.get(`${API_URL}/assessment/getlastlipidassessment`, { params: { memberId } });
            if (response.status !== 200) {
                dispatch(getMemberById(memberId));
            } else {
                const authData = new Authorization();
                dispatch(
                    _setLipidAssessment({
                        MemberId: response.data.MemberId,
                        MemberName: response.data.MemberName,
                        DOB: response.data.DOB,
                        Age: response.data.Age,
                        TestDate: response.data.TestDate,
                        Collector: {
                            Id: authData.UserId,
                            Name: authData.UserName,
                            Role: authData.Role,
                        },
                    } as LipidAssessment)
                );
                dispatch(_toggleIsFetching(false));
            }
        } catch (error) {
            dispatch(getMemberBasicInfo(memberId));
        }
    };

export const setLipidAssessmentByMember =
    (member: Member): AppThunk =>
    async (dispatch, getState) => {
        const authData = new Authorization();
        const collector = Boolean(member?.LastUpdatedBy?.Id)
            ? {
                  Id: member.LastUpdatedBy.Id,
                  Name: member.LastUpdatedBy.Name,
              }
            : {
                  Id: authData.UserId,
                  Name: authData.UserName,
                  Role: authData.Role,
              };
        const lipidAssessment = {
            MemberId: member.Id,
            MemberName: `${member.FirstName} ${member.LastName}`,
            Age: member.Age,
            DOB: Boolean(member.DOB) ? new Date(member.DOB).toISOString() : null,
            Collector: collector,
            TestDate: new Date().toISOString(),
            Provider: {
                Id: member?.Team?.Id,
                Name: member?.Team?.Name,
                Role: TeamTypes.find((t) => t.Id === member?.Team?.Type)?.Name,
            },
        } as LipidAssessment;
        dispatch(_setLipidAssessment(lipidAssessment));
        dispatch(_toggleIsFetching(false));
    };

export const saveLipidAssessment =
    (lipidAssessment: LipidAssessment, saveLocal = false, gotoNext = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setLipidAssessment(lipidAssessment));
        if (!saveLocal) {
            dispatch(_toggleIsSaving(true));
            const isUpdate = Boolean(lipidAssessment.Id);
            const url = isUpdate ? `${API_URL}/assessment/updatelipidassessment/` : `${API_URL}/assessment/createlipidassessment/`;

            axios
                .put(url, lipidAssessment)
                .then((response) => {
                    if (Boolean(response.data)) {
                        dispatch(
                            _setLipidAssessment({
                                ...lipidAssessment,
                                Id: response.data.Id,
                            })
                        );
                    }

                    dispatch(prepareLipidSummaryNotes(isUpdate ? lipidAssessment : response.data));
                    if (gotoNext) {
                        dispatch(gotoNextMenu());
                    }
                })
                .catch((err) => {
                    dispatch(setAssessmentError({ Show: true, Message: err?.response?.data }));
                })
                .finally(() => {
                    dispatch(_toggleIsSaving(false));
                });
        }
    };

const prepareLipidSummaryNotes =
    (lipidAssessment: LipidAssessment): AppThunk =>
    (dispatch, getState) => {
        if (Boolean(lipidAssessment.Id)) {
            let engagementDraft = copyObject(getState().documentEngagement.engagementDraft || {});
            let summaryNotes = (engagementDraft.SummaryNotes || []).filter((s) => s.Type !== SummaryNoteTypeEnum.LipidAssessment);
            summaryNotes.push({
                Id: lipidAssessment.Id,
                CptCode: '80061',
                Zcode: 'Z13.220',
                Note: 'Blood test, lipids (cholesterol and triglycerides)',
                Type: SummaryNoteTypeEnum.LipidAssessment,
                IsConfirm: false,
                OrderBy: 21,
                DisplayName: 'Lipid Assessment',
            });

            if (lipidAssessment.TotalCholestrol < 100) {
                summaryNotes.push({
                    Id: lipidAssessment.Id,
                    CptCode: '3048F',
                    Zcode: '',
                    Note: 'Total Cholesterol < 100 mg/dl',
                    Type: SummaryNoteTypeEnum.LipidAssessment,
                    IsConfirm: false,
                    OrderBy: 21,
                    DisplayName: 'Lipid Assessment',
                });
            } else if (lipidAssessment.TotalCholestrol >= 100 && lipidAssessment.TotalCholestrol <= 129) {
                summaryNotes.push({
                    Id: lipidAssessment.Id,
                    CptCode: '3049F',
                    Zcode: '',
                    Note: 'Total Cholesterol 100-129 mg/dl',
                    Type: SummaryNoteTypeEnum.LipidAssessment,
                    IsConfirm: false,
                    OrderBy: 21,
                    DisplayName: 'Lipid Assessment',
                });
            } else if (lipidAssessment.TotalCholestrol >= 130) {
                summaryNotes.push({
                    Id: lipidAssessment.Id,
                    CptCode: '3050F',
                    Zcode: '',
                    Note: 'Total Cholesterol >= 130 mg/dl',
                    Type: SummaryNoteTypeEnum.LipidAssessment,
                    IsConfirm: false,
                    OrderBy: 21,
                    DisplayName: 'Lipid Assessment',
                });
            }

            engagementDraft.SummaryNotes = summaryNotes;
            dispatch(saveEngagementDraft({ ...engagementDraft, LipidAssessmentId: lipidAssessment.Id }));
        }
    };

export const updateAssessmentMenu =
    (prepareMenuClass: PrepareMenuClass): AppThunk =>
    (dispatch, getState) => {
        const assessment = getState().assessment;
        const hra = getState().hra;
        const fuhfum = getState().fuhfum;
        const member = getState().memberDetail.memberBasicInfo;
        const engagementDraft = prepareMenuClass.EngagementDraft ?? getState().documentEngagement.engagementDraft;
        const memberAssessmentHistory = prepareMenuClass.MemberAssessmentHistory ?? getState().documentEngagement.memberAssessmentHistory;
        const role = prepareMenuClass.Role ?? getState().assessment.selectedRole;
        const activeMenu = prepareMenuClass.ActiveMenu ?? getState().assessment.assessmentMenuList.find((a) => a.IsActive)?.Name;
        let status: any;
        let menuList = assessmentMenusByRole.find((a) => a.role === role)?.list || [];
        if (member?.IsInFuhFum && assessmentMenusForFuhFum.some((e) => e.role === role)) {
            menuList = assessmentMenusForFuhFum.find((e) => e.role === role).list;
        }

        const assessmentMenuList = menuList.map((menuName, index) => {
            let isActiveMenu = menuName === assessmentMenuEnum.SET_APPOINTMENTS || menuName === assessmentMenuEnum.SUMMARY_NOTES;
            return {
                Id: index + 1,
                Name: menuName,
                IsActive: activeMenu === menuName,
                IsPending: false,
                IsCompleted: false,
                IsDisabled:
                    Boolean(index) && (!Boolean(engagementDraft.ConsentFormId) || (!getState().assessment?.consentForm?.ConsentToService && !isActiveMenu)),
                IsExpired: false,
                IsActiveIconShow: true,
                IsNeedsReview: false,
            } as MenuDetails;
        });

        assessmentMenuList.forEach((menu) => {
            switch (menu.Name) {
                case assessmentMenuEnum.ADVANCED_DIRECTIVE:
                    menu.IsCompleted = Boolean(engagementDraft.AdvancedDirectiveId);
                    break;
                case assessmentMenuEnum.ALLERGIES:
                    menu.IsPending = engagementDraft.AllergyStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.AllergyStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.ADL:
                    const { adlAssessment } = hra;
                    status = Boolean(adlAssessment?.Status) ? adlAssessment.Status : memberAssessmentHistory?.AdlAssessmentStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.BUDGET_FORM:
                    menu.IsCompleted = Boolean(engagementDraft.BudgetFormId);
                    break;
                case assessmentMenuEnum.CARE_CIRCLE:
                    menu.IsCompleted = engagementDraft.CareCircleStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.CARE_PLANS:
                    menu.IsPending = engagementDraft.CarePlanStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.CarePlanStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.CONSENT:
                    const consentData = assessment?.consentDocumentValid;
                    if (consentData?.length > 0 && consentData?.some((c) => c.IsExpired)) {
                        menu.IsExpired = true;
                        menu.IsCompleted = false;
                    } else if (consentData?.length === 0 && Boolean(memberAssessmentHistory) && memberAssessmentHistory.ShowConsentFormAlert) {
                        menu.IsExpired = true;
                        menu.IsCompleted = false;
                    } else if (Boolean(engagementDraft.ConsentFormId)) {
                        menu.IsCompleted = true;
                    }
                    break;
                case assessmentMenuEnum.COUNSELING:
                    status = Boolean(assessment?.counseling?.Status) ? assessment?.counseling.Status : memberAssessmentHistory?.CounselingStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.DEMOGRAPHICS:
                    menu.IsCompleted = engagementDraft.DemographicStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.DIABETES_FOOT:
                    status = Boolean(assessment?.diabetesFootAssessment?.Status)
                        ? assessment?.diabetesFootAssessment.Status
                        : memberAssessmentHistory?.DiabetesFootStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.EDUCATION:
                    menu.IsCompleted = engagementDraft.EducationStatus === StatusEnum.Completed;
                    menu.IsPending = engagementDraft.EducationStatus === StatusEnum.InProgress;
                    break;
                case assessmentMenuEnum.FALL_RISK:
                    const { fallRisk } = hra;
                    status = Boolean(fallRisk?.Status) ? fallRisk.Status : memberAssessmentHistory?.FallRiskStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.SCHEDULES:
                case assessmentMenuEnum.FOLLOW_UP:
                    menu.IsPending = engagementDraft.FollowUpStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.FollowUpStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.HEALTH_PLAN:
                    menu.IsPending = engagementDraft.HealthPlanStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.HealthPlanStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.HRA_EDUCATION:
                    const { education } = hra;
                    status = Boolean(education?.Status) ? education.Status : memberAssessmentHistory?.HraEducationStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    menu.DisplayName = 'Education';
                    break;
                case assessmentMenuEnum.HGBA1C_ASSESSMENT:
                    status = Boolean(assessment?.hgbA1CAssessment?.Status) ? assessment?.hgbA1CAssessment.Status : memberAssessmentHistory?.HgbA1CStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.INTERVENTIONS:
                    menu.IsPending = engagementDraft.InterventionStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.InterventionStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.LIPID_ASSESSMENT:
                    status = Boolean(assessment?.lipidAssessment?.Status) ? assessment?.lipidAssessment.Status : memberAssessmentHistory?.LipidStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.MEDICATION_RECONCILIATION:
                    menu.IsPending = engagementDraft.MedicationReconciliationStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.MedicationReconciliationStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.MINI_MENTAL_STATUS_EXAM:
                    status = Boolean(assessment?.miniMentalExam?.Status) ? assessment.miniMentalExam.Status : memberAssessmentHistory?.MiniMentalExamStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.OTHER_FACTORS_AND_RISKS:
                    status = Boolean(assessment?.factorAndRiskAssessment?.Status)
                        ? assessment.factorAndRiskAssessment.Status
                        : memberAssessmentHistory?.FactorAndRiskAssessmentStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.PAST_PRESENT_HISTORY:
                    const pastPresentAssessment = assessment.memberAssessmentsByType.find(
                        (m) => m.Assessment.AssessmentType === AssessmentTypeEnum.PastPresentHistory
                    );
                    status = Boolean(pastPresentAssessment) ? pastPresentAssessment.Status : memberAssessmentHistory?.PastPresentHistoryStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.PAST_SURGICAL_HISTORY:
                    const pastSurgicalAssessment = assessment.memberAssessmentsByType.find(
                        (m) => m.Assessment.AssessmentType === AssessmentTypeEnum.PastSurgicalHistory
                    );
                    status = Boolean(pastSurgicalAssessment) ? pastSurgicalAssessment.Status : memberAssessmentHistory?.PastSurgicalHistoryStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.PCP_SPECIALIST:
                    menu.IsPending = engagementDraft.PcpSpecialistStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.PcpSpecialistStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.PHQ9:
                    const phq9Assessment = assessment.memberAssessmentsByType.find(
                        (m) => m.Assessment.AssessmentType === AssessmentTypeEnum.PatientHealthQuestionnaire
                    );
                    status = Boolean(phq9Assessment) ? phq9Assessment.Status : memberAssessmentHistory.PhqStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    menu.IsExpired = status !== StatusEnum.Completed && memberAssessmentHistory.ShowPhqAlert;
                    break;
                case assessmentMenuEnum.PHYSICAL_EXAM:
                    const physicalExamAssessment = assessment.memberAssessmentsByType.find(
                        (m) => m.Assessment.AssessmentType === AssessmentTypeEnum.PhysicalExam
                    );
                    status = Boolean(physicalExamAssessment) ? physicalExamAssessment.Status : memberAssessmentHistory.PhysicalExamStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.PRAPARE_SDOH:
                    status = Boolean(assessment.sdohAssessment?.Status) ? assessment.sdohAssessment.Status : memberAssessmentHistory.SdohStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.PREVENTATION_HEALTH_ADVICE:
                    const { preventativeHealthAdvice } = hra;
                    status = Boolean(preventativeHealthAdvice?.Status)
                        ? preventativeHealthAdvice.Status
                        : memberAssessmentHistory?.PreventativeHealthAdviceStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.ADDITIONAL_CHP_CRITERIA:
                    const { additionalCHPCriteria } = hra;
                    status = Boolean(additionalCHPCriteria?.Status) ? additionalCHPCriteria.Status : memberAssessmentHistory?.AdditionalCHPCriteriaStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.PROCEDURE_SIGN_OFF:
                    menu.IsDisabled = !Boolean(engagementDraft.VisitedTime) || (role !== roleEnum.HRA && !Boolean(engagementDraft.ReasonForVisit?.Id));
                    break;
                case assessmentMenuEnum.PROGRESS_NOTES:
                    menu.IsCompleted = Boolean(engagementDraft.ProgressNoteStatus);
                    break;
                case assessmentMenuEnum.REMOTE_MONITORING:
                    menu.IsCompleted = Boolean(engagementDraft.RemoteMonitoringStatus);
                    break;
                case assessmentMenuEnum.REQUESTS_REFERRALS:
                    menu.IsPending = engagementDraft.RequestStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.RequestStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.REVIEW_SYSTEMS:
                    const reviewSystemAssessment = assessment.memberAssessmentsByType.find(
                        (m) => m.Assessment.AssessmentType === AssessmentTypeEnum.ReviewSystems
                    );
                    status = Boolean(reviewSystemAssessment) ? reviewSystemAssessment.Status : memberAssessmentHistory?.ReviewSystemStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.SET_APPOINTMENTS:
                    menu.IsCompleted = Boolean(engagementDraft.VisitedTime);
                    break;
                case assessmentMenuEnum.SEXUAL_HEALTH:
                    const { sexualHealth } = hra;
                    status = Boolean(sexualHealth?.Status) ? sexualHealth.Status : memberAssessmentHistory?.SexualHealthStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    const memberBasicInfo = getState().memberDetail.memberBasicInfo;
                    menu.DisplayName =
                        memberBasicInfo.Gender !== GenderEnum.MaleDescription && memberBasicInfo.Gender !== GenderEnum.FemaleDescription
                            ? `${GenderEnum.MaleDescription}/${GenderEnum.FemaleDescription} Health`
                            : `${memberBasicInfo.Gender} Health`;
                    break;
                case assessmentMenuEnum.SUBJECTIVE_ASSESSMENT:
                    status = Boolean(assessment?.subjectiveAssessment?.Status)
                        ? assessment.subjectiveAssessment.Status
                        : memberAssessmentHistory?.SubjectiveAssessmentStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.SUMMARY_NOTES:
                    menu.IsPending = engagementDraft.DocumentEngagementStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.DocumentEngagementStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.TABLETS:
                    menu.IsPending = engagementDraft.TabletStatus === StatusEnum.InProgress;
                    menu.IsCompleted = engagementDraft.TabletStatus === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.TRANSITIONAL_CARE:
                    menu.IsExpired = memberAssessmentHistory?.EngagementDraft?.IsInTransition;
                    break;
                case assessmentMenuEnum.TOBACCO_AND_DRUG:
                    const { tobaccoAndDrug } = hra;
                    status = Boolean(tobaccoAndDrug?.Status) ? tobaccoAndDrug.Status : memberAssessmentHistory?.TobaccoAndDrugAssessmentStatus;
                    menu.IsExpired = status === StatusEnum.Expired;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.URINALYSIS_REPORT:
                    const urinalysisAssessment = assessment.memberAssessmentsByType.find(
                        (m) => m.Assessment.AssessmentType === AssessmentTypeEnum.UrinalysisReport
                    );
                    status = Boolean(urinalysisAssessment) ? urinalysisAssessment.Status : memberAssessmentHistory?.UrinalysisReportStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.VISION_AND_HEARING:
                    const { visionAndHearing } = hra;
                    status = Boolean(visionAndHearing?.Status) ? visionAndHearing.Status : memberAssessmentHistory?.VisionAndHearingStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.VITAL_SIGNS:
                    status = Boolean(assessment?.vitalSign?.AssessmentStatus)
                        ? assessment.vitalSign.AssessmentStatus
                        : memberAssessmentHistory?.VitalSignStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
                case assessmentMenuEnum.SCREENING_FUHFUM:
                    const { screeningFuhFum } = fuhfum;
                    status = Boolean(screeningFuhFum?.Status) ? screeningFuhFum.Status : memberAssessmentHistory?.ScreeningFuhfumStatus;
                    menu.IsPending = status === StatusEnum.InProgress;
                    menu.IsCompleted = status === StatusEnum.Completed;
                    break;
            }
        });

        dispatch(_updateSelectedRole(role));
        dispatch(_updateAssessmentMenuList(assessmentMenuList));
    };

// export const fetchDemographicInfo = (memberId: string): AppThunk => async (dispatch, getState) => {
//     const response = await axios.get(`${API_URL}/member/getmemberdemographicinfo?memberId=${memberId}`);
//     dispatch(_setDemographicInfo(response.data));
// };

// export const saveDemographicInfo = (memberDemographicInfo: MemberDemographicInfo): AppThunk => async (dispatch, getState) => {
//     await axios.put(`${API_URL}/member/updatememberbasicinfo`, memberDemographicInfo);
//     dispatch(fetchDemographicInfo(memberDemographicInfo.Id));
// }

export default assessmentSlice.reducer;
