import { useCallback, useEffect, useState } from 'react';
import { Button, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import Loading from '../Shared/Loading';
import { getMetricGoals, updateMetricGoal } from '../MetricGoal/MetricGoalService';
import MetricGoal from '../../Models/MetricGoals/MetricGoal.model';
import { setIsLoading } from '../../store/shared.slice';
import { preventTextInput, preventNumberInputMaxValue, copyObject } from '../../utils/common';

const MetricGoals = (props) => {
    const dispatch = useDispatch();
    const [goalSummary, setGoalSummary] = useState<MetricGoal>(new MetricGoal());

    const changeFilter = async () => {
        dispatch(setIsLoading(true));
        const data = await getMetricGoals();
        setGoalSummary(data.data);
        dispatch(setIsLoading(false));
    };

    const initialPage = useCallback(async () => {
        changeFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    const updateGoal = async () => {
        dispatch(setIsLoading(true));
        const data = await updateMetricGoal(goalSummary);
        if (data.status === 200) {
            changeFilter();
        }
    };

    const handleOnChangeInput = (value: any, propsName: string) => {
        let data = copyObject(goalSummary);
        data[propsName] = parseInt(value);
        setGoalSummary(data);
    };

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            {<Loading message={'Loading...'} />}
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.HgbA1C}
                            label="HgbA1C"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="HgbA1C"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.DiabeticEyeExam}
                            label="Diabetic Eye Exam"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="DiabeticEyeExam"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.ControlledBloodPressure}
                            label="Controlled Blood Pressure"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="ControlledBloodPressure"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.BMI}
                            label="BMI"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="BMI"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.AdultHealth}
                            label="Adult Health"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="AdultHealth"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.DepressionScreen}
                            label="Depression Screen"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="DepressionScreen"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.DepressionFollowUp}
                            label="Depression Follow Up"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="DepressionFollowUp"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.MedicationReconciliation}
                            label="Medication Reconciliation"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="MedicationReconciliation"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.FUH}
                            label="FUH"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="FUH"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.Documentation}
                            label="Documentation"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="Documentation"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.TeamInPersonVisits}
                            label="Team In Person Visits"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="TeamInPersonVisits"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.IndividualInPersonVisits}
                            label="Individual In Person Visits"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 100 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 100);
                            }}
                            name="IndividualInPersonVisits"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.ActiveMembersAcrossPopulation}
                            label="Active Members Across Population"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 10000 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 10000);
                            }}
                            name="ActiveMembersAcrossPopulation"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">#</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            fullWidth
                            value={goalSummary.ActiveMembersPerTeam}
                            label="Active Members Per Team"
                            type="number"
                            variant="outlined"
                            size="small"
                            inputProps={{ min: 1, max: 10000 }}
                            onKeyPress={(e) => {
                                preventTextInput(e);
                                preventNumberInputMaxValue(e, 10000);
                            }}
                            name="ActiveMembersPerTeam"
                            onChange={(e) => handleOnChangeInput(e.target.value, e.target.name)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">#</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={3}>
                    <Grid item xs={2.4}>
                        <Button variant="contained" sx={{ marginRight: '30px', marginLeft: '20px', float: 'right', margin: '0' }} onClick={updateGoal}>
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default MetricGoals;
