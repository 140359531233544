import { Button, DialogActions, DialogContent, FormControl, Grid, Snackbar, Stack, Typography } from '@mui/material';
import { forwardRef } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { setOpenAlert, setActiveStep, setValidFile, validateFile } from '../../store/fuhfum.slice';
import Validating from '../Shared/Validating';

const ChooseData = (props) => {
    const dispatch = useDispatch();
    const { alertMessage, openAlert, validFile } = useSelector((state: RootState) => state.fuhfum);
    const { file, setFile } = props;

    const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setOpenAlert(false));
    };

    return (
        <>
            <Validating message="Validating..." />
            <Snackbar open={openAlert} onClose={handleCloseAlert} autoHideDuration={8000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseAlert} severity={'error'} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <DialogContent>
                <FormControl fullWidth>
                    <Grid spacing={2} container p={2} sx={{ height: '65vh' }}>
                        <Grid item xs={12}>
                            <Stack>
                                <Typography variant="h4" marginBottom={3}>
                                    Choose & validate your data for import
                                </Typography>
                                <Typography variant="body1">Select your file and validate.</Typography>
                                <Typography variant="body1">Format should be same as sample file in previous step.</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <form id={'upload-form'}>
                                    <Button className="button-120" id="upload-btn" variant="contained" component="label">
                                        Upload File
                                        <input
                                            id="file"
                                            name="File"
                                            onChange={(e) => {
                                                setFile(e.target.files[0]);
                                                dispatch(setValidFile(true));
                                                e.target.value = null;
                                            }}
                                            type="file"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            hidden
                                        />
                                    </Button>
                                </form>
                                {validFile && <Typography variant="h6">{file?.name || 'No file selected'}</Typography>}
                                {!validFile && (
                                    <Typography variant="h6" color="error">
                                        Invalid File
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button className="button-120" variant="contained" onClick={() => dispatch(setActiveStep(0))}>
                    Previous
                </Button>
                <Button className="button-120" variant="contained" disabled={!file} onClick={() => dispatch(validateFile(file))}>
                    Validate
                </Button>
            </DialogActions>
        </>
    );
};

export default ChooseData;
