import { Card, CardContent, CardHeader, CircularProgress, Divider, Typography } from '@mui/material';
import React from 'react';

interface StatBoxProps {
    loading?: boolean;
    color?: string;
    title?: string;
    stat?: string | number;
    maxWidth?: number;
    minWidth?: number;
    maxHeight?: number;
    minHeight?: number;
}

const StatBox = (props: StatBoxProps) => {
    const classes = {
        root: {
            maxWidth: props.maxWidth ?? 150,
            minWidth: props.minWidth,
            maxHeight: props.maxHeight,
            minHeight: props.minHeight,
        },
        header: {
            fontSize: '12px',
            fontWeight: 'bold',
            minHeight: '80px',
        },
    };
    return (
        <Card sx={classes.root}>
            <CardHeader sx={classes.header} titleTypographyProps={{ variant: 'inherit' }} title={props.title} />
            <Divider />
            <CardContent>
                {!props.loading ? (
                    <Typography variant="h3" style={{ color: props.color }}>
                        {props.stat ?? 'n/a'}
                    </Typography>
                ) : (
                    <CircularProgress />
                )}
            </CardContent>
        </Card>
    );
};

export default React.memo(StatBox);
