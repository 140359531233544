import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, Paper, Typography, ToggleButtonGroup, ToggleButton, TableContainer, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { usePopulationContext } from '../../../../hooks/populationContext';
import { useAnalyticContext } from '../../../../hooks/analyticContext';
import { configureStackedBarChartForCost } from '../../../ChartConfiguration/ChartConfiguration';
import ClaimsPerformance from '../../../../Models/Claims/ClaimsPreview.model';
import TopTenMembers from '../../../../Models/Claims/TopTenMembers.model';
import { TimePeriodTypeEnum } from '../../../../Enum/TimePeriodTypeEnum';
import FilterOptions from '../DetailFilterOption';
import { getFfCostGraph, getMonthList, getTop10MembersByFfCost, quarter, month } from '../AnalyticService';
import { ClaimPeriodEnum } from '../../../../Enum/ClaimPeriodEnum';
import { ClaimAnalyticEnum } from '../../../../Enum/ClaimAnalyticEnum';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'gray',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const FfCostDetail = () => {
    const history = useNavigate();
    const { analyticPopulations, isPopulationFetched } = usePopulationContext();
    const monthList = getMonthList();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isMemberShow, setIsMemberShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [yearChartOptions, setYearChartOptions] = useState({});
    const [monthChartOptions, setMonthChartOptions] = useState({});
    const [quarterChartOptions, setQuarterChartOptions] = useState({});
    const [yearGraphSummary, setYearGraphSummary] = useState<ClaimsPerformance>();
    const [monthGraphSummary, setMonthGraphSummary] = useState<ClaimsPerformance>();
    const [quarterGraphSummary, setQuarterGraphSummary] = useState<ClaimsPerformance>();
    const [claimPeriod, setClaimPeriod] = useState(ClaimPeriodEnum.BaseLine);
    const [topTenMembers, setTopTenMembers] = useState<TopTenMembers>();

    const {
        ffPopulation,
        setFfPopulation,
        ffMemberStatus,
        setFfMemberStatus,
        ffPrimaryProvider,
        setFfPrimaryProvider,
        ffSecondaryProvider,
        setFfSecondaryProvider,
        showFfInActivePrimaryTeams,
        setShowFfInActivePrimaryTeams,
        showFfInActiveSecondaryTeams,
        setShowFfInActiveSecondaryTeams,
    } = useAnalyticContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setYearChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [0] }],
                    [0],
                    '',
                    history
                )
            );

            setMonthChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [0] }],
                    [0],
                    '',
                    history
                )
            );

            setQuarterChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [0] }],
                    [0],
                    '',
                    history
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                const yearData = await getFfCostGraph(
                    TimePeriodTypeEnum.YRS,
                    ffPopulation,
                    ffPrimaryProvider,
                    0,
                    ffMemberStatus,
                    ffSecondaryProvider,
                    showFfInActivePrimaryTeams,
                    showFfInActiveSecondaryTeams
                );
                setYearGraphSummary(yearData);
                const monthData = await getFfCostGraph(
                    TimePeriodTypeEnum.MTH,
                    ffPopulation,
                    ffPrimaryProvider,
                    month,
                    ffMemberStatus,
                    ffSecondaryProvider,
                    showFfInActivePrimaryTeams,
                    showFfInActiveSecondaryTeams
                );
                setMonthGraphSummary(monthData);
                const quarterData = await getFfCostGraph(
                    TimePeriodTypeEnum.QRT,
                    ffPopulation,
                    ffPrimaryProvider,
                    quarter,
                    ffMemberStatus,
                    ffSecondaryProvider,
                    showFfInActivePrimaryTeams,
                    showFfInActiveSecondaryTeams
                );
                setQuarterGraphSummary(quarterData);
                setIsPrepared(true);
            };
            setYearChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            setMonthChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            setQuarterChartOptions(
                configureStackedBarChartForCost(
                    'Acute BH Cost',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    history
                )
            );
            setIsGraphShow(false);
            changeFilter();
        }
    }, [
        ffPopulation,
        ffPrimaryProvider,
        ffMemberStatus,
        showFfInActivePrimaryTeams,
        showFfInActiveSecondaryTeams,
        ffSecondaryProvider,
        isPopulationFetched,
        history,
    ]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                const members = await getTop10MembersByFfCost(
                    ffPopulation,
                    ffPrimaryProvider,
                    claimPeriod,
                    ffMemberStatus,
                    ffSecondaryProvider,
                    showFfInActivePrimaryTeams,
                    showFfInActiveSecondaryTeams
                );
                setTopTenMembers(members);
            };
            setIsMemberShow(false);
            changeFilter();
        }
    }, [
        ffPopulation,
        ffPrimaryProvider,
        ffMemberStatus,
        showFfInActivePrimaryTeams,
        showFfInActiveSecondaryTeams,
        ffSecondaryProvider,
        isPopulationFetched,
        claimPeriod,
        history,
    ]);

    useEffect(() => {
        if (isPrepared) {
            if (yearGraphSummary) {
                let xAxisValues = [];
                let yAxisValues = [];
                let yValues = [];

                if (yearGraphSummary.Baseline !== null) {
                    yValues = [];
                    let costName = 'BL Yr';
                    let dataInfo = yearGraphSummary.Baseline;
                    let d = {
                        y: dataInfo,
                        name: costName,
                    };
                    yValues.push(d);
                    yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });
                }

                if (yearGraphSummary.PerformanceOne !== null) {
                    yValues = [];
                    let costName = 'Perf Yr';
                    let dataInfo = yearGraphSummary.PerformanceOne;
                    let d = {
                        y: dataInfo,
                        name: costName,
                    };
                    yValues.push(d);
                    yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });
                }

                if (ffPopulation === 0) {
                    xAxisValues.push('All Populations');
                } else {
                    let pop = analyticPopulations.find((p) => p.value === ffPopulation);
                    if (pop) {
                        xAxisValues.push(pop.name);
                    }
                }

                setYearChartOptions(configureStackedBarChartForCost('Acute BH Cost', '', '', 'Cost', yAxisValues, xAxisValues, '', history));
            }

            if (monthGraphSummary) {
                let xAxisValues = [];
                let yAxisValues = [];
                let yValues = [];

                if (monthGraphSummary.Baseline !== null) {
                    yValues = [];
                    let costName = 'BL Yr';
                    let dataInfo = monthGraphSummary.Baseline;
                    let d = {
                        y: dataInfo,
                        name: costName,
                    };
                    yValues.push(d);
                    yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });
                }

                if (monthGraphSummary.PerformanceOne !== null) {
                    yValues = [];
                    let costName = 'Perf Yr';
                    let dataInfo = monthGraphSummary.PerformanceOne;
                    let d = {
                        y: dataInfo,
                        name: costName,
                    };
                    yValues.push(d);
                    yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });
                }

                if (ffPopulation === 0) {
                    xAxisValues.push('All Populations');
                } else {
                    let pop = analyticPopulations.find((p) => p.value === ffPopulation);
                    if (pop) {
                        xAxisValues.push(pop.name);
                    }
                }

                setMonthChartOptions(
                    configureStackedBarChartForCost('AC Cost', '', monthList.find((m) => m.Id === month).Value, 'Cost', yAxisValues, xAxisValues, '', history)
                );
            }

            if (quarterGraphSummary) {
                let xAxisValues = [];
                let yAxisValues = [];
                let yValues = [];

                if (quarterGraphSummary.Baseline !== null) {
                    yValues = [];
                    let costName = 'BL Yr';
                    let dataInfo = quarterGraphSummary.Baseline;
                    let d = {
                        y: dataInfo,
                        name: costName,
                    };
                    yValues.push(d);
                    yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });
                }

                if (quarterGraphSummary.PerformanceOne !== null) {
                    yValues = [];
                    let costName = 'Perf Yr';
                    let dataInfo = quarterGraphSummary.PerformanceOne;
                    let d = {
                        y: dataInfo,
                        name: costName,
                    };
                    yValues.push(d);
                    yAxisValues.push({ name: costName, data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });
                }

                if (ffPopulation === 0) {
                    xAxisValues.push('All Populations');
                } else {
                    let pop = analyticPopulations.find((p) => p.value === ffPopulation);
                    if (pop) {
                        xAxisValues.push(pop.name);
                    }
                }

                setQuarterChartOptions(
                    configureStackedBarChartForCost('Acute BH Cost', '', `Quarter ${quarter}`, 'Cost', yAxisValues, xAxisValues, '', history)
                );
            }

            setIsGraphShow(true);
            setIsMemberShow(true);
            setIsPrepared(false);
        }
    }, [yearGraphSummary, monthGraphSummary, quarterGraphSummary, ffPopulation, analyticPopulations, monthList, isPrepared, history]);

    const handleChange = async (e: any, val: any) => {
        setIsMemberShow(false);
        setClaimPeriod(val);
        const members = await getTop10MembersByFfCost(
            ffPopulation,
            ffPrimaryProvider,
            val,
            ffMemberStatus,
            ffSecondaryProvider,
            showFfInActivePrimaryTeams,
            showFfInActiveSecondaryTeams
        );
        setTopTenMembers(members);
        setIsMemberShow(true);
    };

    const navigateToGraph = () => {
        history('/provider/metricsummary/smart-dashboard');
    };

    return (
        <Paper elevation={5}>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingTop={1}>
                <Grid item xs={12} paddingBottom={1}>
                    <span onClick={navigateToGraph}>
                        <ArrowBackIcon className="pointer mr-2" style={{ color: 'black' }} titleAccess="Back" />
                    </span>
                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                        Acute BH Costs
                    </Typography>
                </Grid>
                <FilterOptions
                    population={ffPopulation}
                    setPopulation={setFfPopulation}
                    memberStatus={ffMemberStatus}
                    setMemberStatus={setFfMemberStatus}
                    primaryProvider={ffPrimaryProvider}
                    setPrimaryProvider={setFfPrimaryProvider}
                    secondaryProvider={ffSecondaryProvider}
                    setSecondaryProvider={setFfSecondaryProvider}
                    claimPeriod={claimPeriod}
                    claimType={ClaimAnalyticEnum.FfCost}
                    showInActivePrimaryTeams={showFfInActivePrimaryTeams}
                    setShowInActivePrimaryTeams={setShowFfInActivePrimaryTeams}
                    showInActiveSecondaryTeams={showFfInActiveSecondaryTeams}
                    setShowInActiveSecondaryTeams={setShowFfInActiveSecondaryTeams}
                />
                <Grid item xs={4} marginTop={1} sx={{ position: 'relative' }}>
                    {!isGraphShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <HighchartsReact highcharts={Highcharts} options={yearChartOptions} />
                </Grid>
                <Grid item xs={4} marginTop={1} sx={{ position: 'relative' }}>
                    {!isGraphShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <HighchartsReact highcharts={Highcharts} options={quarterChartOptions} />
                </Grid>
                <Grid item xs={4} marginTop={1} sx={{ position: 'relative' }}>
                    {!isGraphShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <HighchartsReact highcharts={Highcharts} options={monthChartOptions} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} marginTop={2} style={{ backgroundColor: '#F5F5F5' }} paddingY={1} paddingLeft={2} paddingRight={2}>
                    <Typography display={'inline-block'} variant="h6" color={'primary'}>
                        Top 10 Members
                    </Typography>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={claimPeriod}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        className="float-right"
                    >
                        <ToggleButton style={{ textTransform: 'none' }} value={ClaimPeriodEnum.BaseLine}>
                            Baseline
                        </ToggleButton>
                        <ToggleButton style={{ textTransform: 'none' }} value={ClaimPeriodEnum.PerformanceOne}>
                            CHP Performance
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid container spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                <Grid item xs={12} marginTop={2} minHeight="40vh" sx={{ position: 'relative' }}>
                    {!isGraphShow || !isMemberShow ? (
                        <Box className="loader-center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell align="left">Member Name</StyledTableCell>
                                                <StyledTableCell align="right">Year Cost</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {topTenMembers.ByYear.length ? (
                                                topTenMembers.ByYear.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.MemberName}</StyledTableCell>
                                                        <StyledTableCell align="right">${row.Cost.toFixed(2)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                        No data.
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell align="left">Member Name</StyledTableCell>
                                                <StyledTableCell align="right">Quarter Cost</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {topTenMembers.ByQuarter.length ? (
                                                topTenMembers.ByQuarter.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.MemberName}</StyledTableCell>
                                                        <StyledTableCell align="right">${row.Cost.toFixed(2)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                        No data.
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>#</StyledTableCell>
                                                <StyledTableCell align="left">Member Name</StyledTableCell>
                                                <StyledTableCell align="right">Month Cost</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {topTenMembers.ByMonth.length ? (
                                                topTenMembers.ByMonth.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.MemberName}</StyledTableCell>
                                                        <StyledTableCell align="right">${row.Cost.toFixed(2)}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" scope="row" colSpan={3}>
                                                        No data.
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FfCostDetail;
