import { ProviderPreview } from '../CarePlans/SchedulePlan.model';

export default interface IMemberNote {
    Id: string;
    WarningFlag: boolean;
    Note: string;
    EditedTime: Date;
    Provider: ProviderPreview;
    MemberId: string;
}

export class MemberNote implements IMemberNote {
    Id: null;
    WarningFlag: false;
    Note: string;
    EditedTime: null;
    Provider: null;
    MemberId: null;
    constructor() {
        this.Id = null;
        this.WarningFlag = false;
        this.Note = '';
        this.EditedTime = null;
        this.Provider = null;
        this.MemberId = null;
    }
}
