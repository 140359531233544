import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

import { copyObject } from '../../../utils/common';
import Authorization from '../../../utils/Authorization';
import { fetchCrossDisciplineCare, MenuProps } from '../../Widgets/WidgetService';
import MemberPreview from '../../../Models/Widget/CrossDisciplineCareSummary.model';
import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import '../Widgets.css';

const CrossDisciplineCareSummary = () => {
    const { npAndBhTeams } = useSelector((state: RootState) => state.teams);
    const authData = new Authorization();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [members, setMembers] = useState<MemberPreview[]>([]);
    const [selectedFilter, setSelectedFilter] = useState<WidgetTimeFilterEnum>(WidgetTimeFilterEnum.Last30Days);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [show, setShow] = useState(false);
    const [bhAndNPTeams, setBHAndNPTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const ref = useRef(null);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const filteredTeams = (showInActiveTeams ? bhAndNPTeams : bhAndNPTeams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const dayFilterList = [
        { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
        { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
        { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        let teamList = [];
        for (var data in npAndBhTeams) {
            let teamInfo = copyObject(npAndBhTeams[data]);
            teamInfo.TeamTypeName = teamInfo.TypeName === 'Nurse Practitioner' ? 'NP' : 'BH';
            teamList.push(teamInfo);
        }
        setBHAndNPTeams(teamList);
    }, [isFetched, npAndBhTeams]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (selectedFilter !== WidgetTimeFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate))) {
            setMembers([]);
            setIsFetched(false);
            const changeFilter = async () => {
                setIsFetched(false);

                const membersInfo = await fetchCrossDisciplineCare(
                    selectedTeam,
                    selectedFilter,
                    Boolean(fromDate) && selectedFilter === WidgetTimeFilterEnum.Custom ? new Date(fromDate).toISOString() : '',
                    Boolean(toDate) && selectedFilter === WidgetTimeFilterEnum.Custom ? new Date(toDate).toISOString() : '',
                    showInActiveTeams
                );
                setMembers(membersInfo);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (selectedFilter !== WidgetTimeFilterEnum.Custom && Boolean(fromDate) && Boolean(toDate)) {
            setFromDate(null);
            setToDate(null);
        }
    }, [selectedFilter, fromDate, toDate, selectedTeam, showInActiveTeams]);

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setFromDate(startDate);
        }
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                Cross Discipline Care
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">
                                                Members that were seen In-Person in the date range by both Physical & Behavioral health specialist
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select value={selectedTeam} label="Team" name="Team" onChange={(e) => setSelectedTeam(e.target.value)} MenuProps={MenuProps}>
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name} ({team.TeamTypeName})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={selectedFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setSelectedFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {selectedFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(fromDate) ? fromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(toDate) ? toDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}

                        <Grid item xs={12} marginTop={2} height="49vh" sx={{ position: 'relative' }}>
                            {!isFetched ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <SimpleBar style={{ maxHeight: '430px' }}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width={20} size="small">
                                                        Member Name
                                                    </TableCell>
                                                    <TableCell width={20} size="small">
                                                        Health Plan
                                                    </TableCell>
                                                    <TableCell width={20} size="small">
                                                        Population
                                                    </TableCell>
                                                    <TableCell width={20} size="small">
                                                        NP Team
                                                    </TableCell>
                                                    <TableCell width={20} align="right" size="small">
                                                        BH Team
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members && members.length
                                                    ? members.map((mem) => (
                                                          <TableRow key={mem.Id}>
                                                              <TableCell width={20} size="small">
                                                                  {mem.MemberName}
                                                              </TableCell>
                                                              <TableCell width={20} size="small">
                                                                  {mem.HealthPlan}
                                                              </TableCell>
                                                              <TableCell width={20} size="small">
                                                                  {mem.Population}
                                                              </TableCell>
                                                              <TableCell width={20} size="small">
                                                                  {mem.NPTeam}
                                                              </TableCell>
                                                              <TableCell width={20} align="right" size="small">
                                                                  {mem.BHTeam}
                                                              </TableCell>
                                                          </TableRow>
                                                      ))
                                                    : null}
                                                {isFetched && members && members.length === 0 ? (
                                                    <TableRow>
                                                        <TableCell colSpan={5}>No Member Found</TableCell>
                                                    </TableRow>
                                                ) : null}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </SimpleBar>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default CrossDisciplineCareSummary;
