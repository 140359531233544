export enum AnswerOptionsEnum {
    VeryBadDescription = 'Very Bad',
    VeryBad = 1,
    BadDescription = 'Bad',
    Bad = 2,
    OkDescription = 'Ok',
    Ok = 3,
    GoodDescription = 'Good',
    Good = 4,
    VeryGoodDescription = 'Very Good',
    VeryGood = 5,
    YesDescription = 'Yes',
    Yes = 6,
    NoDescription = 'No',
    No = 7,
    NotApplicableDescription = 'Not Applicable',
    NotApplicable = 8,
    BetterDescription = 'Better',
    Better = 9,
    WorseDescription = 'Worse',
    Worse = 10,
    SameDescription = 'Same',
    Same = 11,
}
