import { Dialog, DialogContent } from '@mui/material';
import React from 'react';

interface CalendarModalProps {
    open: boolean;
    onClose: () => void;
    calendar: JSX.Element;
}

const CalendarModal: React.FC<CalendarModalProps> = ({ calendar, onClose, open }) => {
    return (
        <Dialog open={open} onClose={onClose} hideBackdrop={true} disableEnforceFocus={true} maxWidth="xl">
            <DialogContent>{calendar}</DialogContent>
        </Dialog>
    );
};

export default CalendarModal;
