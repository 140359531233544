import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

const providerLeaveSlice = createSlice({
    name: 'providerLeave',
    initialState: {
        providerLeaves: [],
        selectedProvider: 'default',
        showCalendarModal: false,
        selectedCalendarDate: new Date(),
    },
    reducers: {
        _setProviderLeaves(state, action) {
            state.providerLeaves = action.payload;
        },
        _setSelectedProvider(state, action) {
            state.selectedProvider = action.payload;
        },
        _setShowCalendarModal(state, action) {
            state.showCalendarModal = action.payload;
        },
        _setSelectedCalendarDate(state, action) {
            state.selectedCalendarDate = action.payload;
        },
    },
});

const { _setProviderLeaves, _setSelectedProvider, _setShowCalendarModal, _setSelectedCalendarDate } = providerLeaveSlice.actions;

export const fetchProviderLeaves = (): AppThunk => async (dispatch, getState) => {
    const response = await axios.get(`${API_URL}/scheduling/getproviderleaves`);
    console.log(response.data);
    dispatch(_setProviderLeaves(response.data));
};

export const setSelectedProvider =
    (value: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedProvider(value));
    };

export const setShowCalendarModal =
    (value: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowCalendarModal(value));
    };

export const setSelectedCalendarDate =
    (value: Date): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedCalendarDate(value));
    };

export default providerLeaveSlice.reducer;
