import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import b64toBlob from 'b64-to-blob';
import { GridCellParams } from '@mui/x-data-grid';
import HealthPlan from '../Models/HealthPlan/HealthPlan.model';
import Sftp from '../Models/Shared/Sftp.model';
import PlanType from '../Models/HealthPlan/PlanType.model';
import Population from '../Models/Population/Population.model';

const getDefaultContact = () => {
    return {
        Address: '',
        Email: '',
        Id: '',
        Name: '',
        PhoneNumber: '',
    };
};

const adminHealthPlanSlice = createSlice({
    name: 'adminHealthPlanSlice',
    initialState: {
        activeStep: 0,
        alertContactVisibility: false,
        alertMessage: '',
        contactInfoTitle: '',
        contactToDelete: { Name: '', Id: '' },
        currentContact: getDefaultContact(),
        currentContactList: '',
        currentHealthPlan: { Name: '', Id: '' },
        currentPopulation: new Population(),
        editPopulationVisibility: false,
        fileProcessedCount: { Percentage: 0, ProcessedCount: 0, TotalCount: 0 },
        healthPlans: [],
        healthPlanForm: new HealthPlan(),
        healthPlanTypes: [],
        importSuccessful: false,
        isImporting: false,
        isSaving: false,
        newPlan: false,
        newPopulation: false,
        openAddHealthPlan: false,
        openAddPopulation: false,
        openAlert: false,
        openContactInfo: false,
        openDeleteContact: false,
        openPopulationDialog: false,
        planTypes: new PlanType(),
        planTypesVisibility: false,
        populationActiveStep: 0,
        populationByHealthPlan: [],
        populationSteps: [],
        sftpAddress: new Sftp(),
        sftpAlert: false,
        updatingPopulation: false,
        validatedMembers: [],
        validFile: true,
    },
    reducers: {
        _setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        _setAlertContactVisibility(state, action) {
            state.alertContactVisibility = action.payload;
        },
        _setAlertMessage(state, action) {
            state.alertMessage = action.payload;
        },
        _setContactInfoTitle(state, action) {
            state.contactInfoTitle = action.payload;
        },
        _setContactToDelete(state, action) {
            state.contactToDelete = action.payload;
        },
        _setCurrentContact(state, action) {
            state.currentContact = action.payload;
        },
        _setCurrentContactList(state, action) {
            state.currentContactList = action.payload;
        },
        _setCurrentHealthPlan(state, action) {
            state.currentHealthPlan = action.payload;
        },
        _setCurrentPopulation(state, action) {
            state.currentPopulation = action.payload;
        },
        _setEditPopulationVisibility(state, action) {
            state.editPopulationVisibility = action.payload;
        },
        _setFileProcessedCount(state, action) {
            state.fileProcessedCount = action.payload;
        },
        _setHealthPlans(state, action) {
            state.healthPlans = action.payload;
        },
        _setHealthPlanForm(state, action) {
            state.healthPlanForm = action.payload;
        },
        _setHealthPlanTypes(state, action) {
            state.healthPlanTypes = action.payload;
        },
        _setImportSuccessful(state, action) {
            state.importSuccessful = action.payload;
        },
        _setIsImporting(state, action) {
            state.isImporting = action.payload;
        },
        _setIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _setNewPlan(state, action) {
            state.newPlan = action.payload;
        },
        _setNewPopulation(state, action) {
            state.newPopulation = action.payload;
        },
        _setOpenAddHealthPlan(state, action) {
            state.openAddHealthPlan = action.payload;
        },
        _setOpenAddPopulation(state, action) {
            state.openAddPopulation = action.payload;
        },
        _setOpenAlert(state, action) {
            state.openAlert = action.payload;
        },
        _setOpenContactInfo(state, action) {
            state.openContactInfo = action.payload;
        },
        _setOpenDeleteContact(state, action) {
            state.openDeleteContact = action.payload;
        },
        _setOpenPopulationDialog(state, action) {
            state.openPopulationDialog = action.payload;
        },
        _setPlanTypesVisibility(state, action) {
            state.planTypesVisibility = action.payload;
        },
        _setPopulationActiveStep(state, action) {
            state.populationActiveStep = action.payload;
        },
        _setPopulationSteps(state, action) {
            state.populationSteps = action.payload;
        },
        _setPlanTypes(state, action) {
            state.planTypes = action.payload;
        },
        _setSftpAddress(state, action) {
            state.sftpAddress = action.payload;
        },
        _setSftpAlert(state, action) {
            state.sftpAlert = action.payload;
        },
        _setPopulationByHealthPlan(state, action) {
            state.populationByHealthPlan = action.payload;
        },
        _setUpdatingPopulation(state, action) {
            state.updatingPopulation = action.payload;
        },
        _setValidatedMembers(state, action) {
            state.validatedMembers = action.payload;
        },
        _setValidFile(state, action) {
            state.validFile = action.payload;
        },
    },
});

const {
    _setActiveStep,
    _setAlertMessage,
    _setAlertContactVisibility,
    _setContactInfoTitle,
    _setContactToDelete,
    _setCurrentContact,
    _setCurrentContactList,
    _setCurrentHealthPlan,
    _setCurrentPopulation,
    _setEditPopulationVisibility,
    _setFileProcessedCount,
    _setHealthPlans,
    _setHealthPlanForm,
    _setHealthPlanTypes,
    _setImportSuccessful,
    _setIsImporting,
    _setIsSaving,
    _setNewPopulation,
    _setNewPlan,
    _setOpenAddHealthPlan,
    _setOpenAddPopulation,
    _setOpenAlert,
    _setOpenContactInfo,
    _setOpenDeleteContact,
    _setOpenPopulationDialog,
    _setPlanTypes,
    _setPlanTypesVisibility,
    _setPopulationActiveStep,
    _setPopulationByHealthPlan,
    _setPopulationSteps,
    _setSftpAddress,
    _setSftpAlert,
    _setUpdatingPopulation,
    _setValidatedMembers,
    _setValidFile,
} = adminHealthPlanSlice.actions;

export const addHealthPlanHandler = (): AppThunk => async (dispatch) => {
    dispatch(getHealthPlanTypes());
    dispatch(setNewPlan(true));
    dispatch(setHealthPlanForm(new HealthPlan()));
    dispatch(setActiveStep(0));
    dispatch(setOpenAddHealthPlan(true));
};

export const setActiveStep =
    (value: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setActiveStep(value));
    };

export const closeAddHealthPlanHandler = (): AppThunk => async (dispatch) => {
    dispatch(fetchHealthPlan());
    dispatch(setNewPlan(false));
    dispatch(setActiveStep(0));
    dispatch(setOpenAddHealthPlan(false));
};

export const editHealthPlanHandler =
    (id: string): AppThunk =>
    async (dispatch) => {
        dispatch(setHealthPlanForm(new HealthPlan()));
        dispatch(getHealthPlanTypes());
        dispatch(setNewPlan(false));
        dispatch(getHealthPlanById(id));
        dispatch(setActiveStep(0));
        dispatch(setOpenAddHealthPlan(true));
    };

export const deleteHealthPlanHandler =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            await axios.delete(`${API_URL}/managehealthplan/deletehealthplan?id=${id}`);
            dispatch(fetchHealthPlan());
        } catch (error) {
            console.log(error);
        }
    };

export const closePlanTypesHandler = (): AppThunk => async (dispatch) => {
    dispatch(setPlanTypes(new PlanType()));
    dispatch(setPlanTypesVisibility(false));
};

export const addPlanTypeHandler = (): AppThunk => async (dispatch) => {
    dispatch(setPlanTypes(new PlanType()));
    dispatch(setPlanTypesVisibility(true));
};

export const editPlanTypeHandler =
    (planType: object): AppThunk =>
    async (dispatch) => {
        dispatch(setPlanTypes(planType));
        dispatch(setPlanTypesVisibility(true));
    };

export const openDeleteContactHandler =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(setContactToDelete(params));
        dispatch(setOpenDeleteContact(true));
    };

export const openDeletePlanTypeHandler =
    (contactToDelete: object, plan: GridCellParams): AppThunk =>
    async (dispatch) => {
        dispatch(setContactToDelete(contactToDelete));
        dispatch(setPlanTypes(plan));
        dispatch(setOpenDeleteContact(true));
    };

export const closeDeleteContactHandler = (): AppThunk => (dispatch) => {
    dispatch(setContactToDelete({ Name: '', Id: '' }));
    dispatch(setPlanTypes(new PlanType()));
    dispatch(setOpenDeleteContact(false));
};

export const setContactToDelete =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setContactToDelete(params));
    };

export const deleteContactHandler =
    (healthPlanForm, contactToDelete): AppThunk =>
    (dispatch) => {
        let filteredForm = {};
        if (healthPlanForm.APContacts?.findIndex((el) => el.Id === contactToDelete.Id) > -1) {
            filteredForm = { ...healthPlanForm, APContacts: healthPlanForm.APContacts.filter((el) => el.Id !== contactToDelete.Id) };
        }
        if (healthPlanForm.ContractingContacts?.findIndex((el) => el.Id === contactToDelete.Id) > -1) {
            filteredForm = { ...healthPlanForm, ContractingContacts: healthPlanForm.ContractingContacts.filter((el) => el.Id !== contactToDelete.Id) };
        }
        if (healthPlanForm.ContactPerson?.findIndex((el) => el.Id === contactToDelete.Id) > -1) {
            filteredForm = { ...healthPlanForm, ContactPerson: healthPlanForm.ContactPerson.filter((el) => el.Id !== contactToDelete.Id) };
        }
        if (healthPlanForm.SftpAddress?.findIndex((el) => el.Id === contactToDelete.Id) > -1) {
            filteredForm = { ...healthPlanForm, SftpAddress: healthPlanForm.SftpAddress.filter((el) => el.Id !== contactToDelete.Id) };
        }
        if (healthPlanForm.PlanTypes?.findIndex((el) => el.Id === contactToDelete.Id) > -1) {
            filteredForm = { ...healthPlanForm, PlanTypes: healthPlanForm.PlanTypes.filter((el) => el.Id !== contactToDelete.Id) };
        }

        dispatch(updateHealthPlan(filteredForm));
        dispatch(closeDeleteContactHandler());
    };

export const saveContactInfoHandler =
    (healthPlanForm, currentContact, currentContactList): AppThunk =>
    (dispatch) => {
        let APList = [...(healthPlanForm.APContacts || [])];
        let CCList = [...(healthPlanForm.ContractingContacts || [])];
        let CPList = [...(healthPlanForm.ContactPerson || [])];
        const APIndex = currentContact.Id.length > 0 ? APList.findIndex((el) => el.Id === currentContact.Id) : -1;
        const CCIndex = currentContact.Id.length > 0 ? CCList.findIndex((el) => el.Id === currentContact.Id) : -1;
        const CPIndex = currentContact.Id.length > 0 ? CPList.findIndex((el) => el.Id === currentContact.Id) : -1;

        if (APIndex > -1) {
            APList[APIndex] = currentContact;
            dispatch(setHealthPlanForm({ ...healthPlanForm, APContacts: APList }));
        }
        if (CCIndex > -1) {
            CCList[CCIndex] = currentContact;
            dispatch(setHealthPlanForm({ ...healthPlanForm, ContractingContacts: CCList }));
        }

        if (CPIndex > -1) {
            CPList[CPIndex] = currentContact;
            dispatch(setHealthPlanForm({ ...healthPlanForm, ContactPerson: CPList }));
        }
        if (APIndex === -1 && CCIndex === -1 && CPIndex === -1) {
            if (currentContactList === 'APContacts') {
                APList[APList.length] = currentContact;
                dispatch(updateHealthPlan({ ...healthPlanForm, APContacts: APList }));
            }
            if (currentContactList === 'ContractingContacts') {
                CCList[CCList.length] = currentContact;
                dispatch(updateHealthPlan({ ...healthPlanForm, ContractingContacts: CCList }));
            }
            if (currentContactList === 'ContactPerson') {
                CPList[CPList.length] = currentContact;
                dispatch(updateHealthPlan({ ...healthPlanForm, ContactPerson: CPList }));
            }
        }
        dispatch(setOpenContactInfo(false));
    };

export const savePopulationContactHandler =
    (currentContact, currentPopulation): AppThunk =>
    (dispatch) => {
        let alertsList = [...(currentPopulation.AlertContacts || [])];
        const alertIndex = currentContact.Id.length > 0 ? alertsList.findIndex((el) => el.Id === currentContact.Id) : -1;
        if (alertIndex > -1) {
            alertsList[alertIndex] = currentContact;
            dispatch(setCurrentPopulation({ ...currentPopulation, AlertContacts: alertsList }));
        } else {
            alertsList.push(currentContact);
            dispatch(setCurrentPopulation({ ...currentPopulation, AlertContacts: alertsList }));
        }
        dispatch(updatePopulation({ ...currentPopulation, AlertContacts: alertsList }, currentPopulation.HealthPlan?.Id));

        dispatch(setAlertContactVisibility(false));
    };

export const deletePopulationContactHandler =
    (contactToDelete, currentPopulation, currentHealthPlan): AppThunk =>
    (dispatch) => {
        let filteredForm = {};
        if (currentPopulation.AlertContacts?.findIndex((el) => el.Id === contactToDelete.Id) > -1) {
            filteredForm = { ...currentPopulation, AlertContacts: currentPopulation.AlertContacts.filter((el) => el.Id !== contactToDelete.Id) };
        }
        dispatch(updatePopulation(filteredForm, currentHealthPlan.Id));
        dispatch(setOpenDeleteContact(false));
    };

export const openPopulationDialogHandler =
    (params: GridCellParams): AppThunk =>
    async (dispatch) => {
        dispatch(setCurrentHealthPlan(params.row));
        dispatch(getPopulationsByHealthPlanId(params.row.Id));
        dispatch(setOpenPopulationDialog(true));
    };

export const closeAddPopulationHandler =
    (currentHealthPlan): AppThunk =>
    async (dispatch) => {
        dispatch(setNewPopulation(false));

        dispatch(getPopulationsByHealthPlanId(currentHealthPlan.Id));
        dispatch(setOpenAddPopulation(false));
    };

export const setAlertContactVisibility =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setAlertContactVisibility(params));
    };

export const setAlertMessage =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setAlertMessage(params));
    };

export const setCurrentContact =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentContact(params));
    };

export const setCurrentContactList =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentContactList(params));
    };

export const setContactInfoTitle =
    (title: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setContactInfoTitle(title));
    };

export const setCurrentHealthPlan =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentHealthPlan(params));
    };

export const setCurrentPopulation =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setCurrentPopulation(params));
    };

export const setFileProcessedCount =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setFileProcessedCount(params));
    };

export const setHealthPlans =
    (value: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setHealthPlans(value));
    };

export const setHealthPlanTypes =
    (value: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setHealthPlanTypes(value));
    };

export const setHealthPlanForm =
    (value: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setHealthPlanForm(value));
    };

export const setImportSuccessful =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setImportSuccessful(value));
    };

export const setIsImporting =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsImporting(value));
    };

export const setIsSaving =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsSaving(value));
    };

export const setNewPlan =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setNewPlan(value));
    };

export const setOpenAddHealthPlan =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAddHealthPlan(value));
    };

export const setOpenAddPopulation =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAddPopulation(value));
    };

export const setOpenAlert =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenAlert(value));
    };

export const setOpenContactInfo =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenContactInfo(value));
    };

export const setOpenDeleteContact =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenDeleteContact(value));
    };

export const setOpenPopulationDialog =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenPopulationDialog(value));
    };

export const setPlanTypes =
    (value: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setPlanTypes(value));
    };

export const setPopulationSteps =
    (value: { label: string; value: number }[]): AppThunk =>
    async (dispatch) => {
        dispatch(_setPopulationSteps(value));
    };

export const setEditPopulationVisibility =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setEditPopulationVisibility(value));
    };

export const setPlanTypesVisibility =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setPlanTypesVisibility(value));
    };

export const setNewPopulation =
    (value: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setNewPopulation(value));
    };

export const setPopulationActiveStep =
    (value: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setPopulationActiveStep(value));
    };

export const setPopulationByHealthPlan =
    (value: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setPopulationByHealthPlan(value));
    };

export const setSftpAddress =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(_setSftpAddress(params));
    };

export const setSftpAlert =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setSftpAlert(params));
    };

export const setUpdatingPopulation =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setUpdatingPopulation(params));
    };

export const setValidFile =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setValidFile(params));
    };

export const setValidatedMembers =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setValidatedMembers(params));
    };

export const fetchHealthPlan = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/managehealthplan/gethealthplans`);
    dispatch(
        setHealthPlans(
            response.data.sort((a, b) => (Number(a.LoadedMembers) > Number(b.LoadedMembers) ? -1 : Number(b.LoadedMembers) > Number(a.LoadedMembers) ? 1 : 0))
        )
    );
    dispatch(setIsLoading(false));
};

export const getFileProcessedCount = (): AppThunk => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/population/getfileprocessedcount`);
        dispatch(setFileProcessedCount(response.data));
    } catch (error) {
        console.log(error);
    }
};

export const getHealthPlanById =
    (id: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/managehealthplan/gethealthplanbyid?id=${id}`);
        dispatch(setHealthPlanForm(response.data));
        dispatch(setIsLoading(false));
    };

export const getHealthPlanTypes = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/managehealthplan/gethealthplantypes`);
    dispatch(setHealthPlanTypes(response.data));
};

export const updateHealthPlan =
    (payload: object): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.put(`${API_URL}/managehealthplan/updatehealthplan`, payload);
            dispatch(setHealthPlanForm(response.data));
        } catch (error) {
            console.log(error);
        }
    };

export const getPopulationsByHealthPlanId =
    (planId: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/population/getpopulationsbyhealthplan?healthPlanId=${planId}`);
        dispatch(setPopulationByHealthPlan(response.data));
    };

export const insertHealthPlan =
    (payload: object): AppThunk =>
    async (dispatch) => {
        const response = await axios.post(`${API_URL}/managehealthplan/inserthealthplan`, payload);
        dispatch(setNewPlan(false));
        dispatch(setHealthPlanForm(response.data));
    };

export const addPopulation =
    (payload: object, id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.put(`${API_URL}/population/addpopulation`, payload);
            dispatch(setCurrentPopulation(response.data));
            getPopulationsByHealthPlanId(id);
            dispatch(setNewPopulation(false));
        } catch (error) {
            console.log(error);
        }
    };

export const updatePopulation =
    (payload: object, planId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.put(`${API_URL}/population/updatepopulation`, payload);
            dispatch(setCurrentPopulation(response.data));
            dispatch(getPopulationsByHealthPlanId(planId));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

export const downloadSamplePopulationHandler = (): AppThunk => async () => {
    console.log('downloadSamplePopulationHandler clicked');
    const response = await axios.get(`${API_URL}/population/downloadsamplemember`);
    let blob = b64toBlob(response.data.base64String, 'text/csv');
    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Members_Sample.xlsx`);
    link.click();
    link.remove();
};

export const openEditPopulationHandler =
    (params: object): AppThunk =>
    async (dispatch) => {
        dispatch(setNewPopulation(false));
        dispatch(setCurrentPopulation(params));
        dispatch(setEditPopulationVisibility(true));
    };

export const closeEditPopulationHandler = (): AppThunk => async (dispatch) => {
    dispatch(setOpenAlert(false));
    dispatch(setEditPopulationVisibility(false));
};

export const validatePopulation =
    (file: File, healthPlanId: string, populationId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        let formData = new FormData();
        formData.append('File', file);
        formData.append('HealthPlanId', healthPlanId);
        formData.append('PopulationId', populationId);
        try {
            const response = await axios.put(`${API_URL}/population/validatemembers`, formData);
            if (response.data.IsValid) {
                dispatch(setValidatedMembers(response.data.Data));
                dispatch(setAlertMessage(''));
                dispatch(setOpenAlert(false));
                dispatch(setPopulationActiveStep(2));
                dispatch(setValidFile(true));
                dispatch(setIsLoading(false));
            } else {
                dispatch(setOpenAlert(true));
                dispatch(setAlertMessage(response.data.Message));
                dispatch(setIsLoading(false));
            }
        } catch (error) {
            dispatch(setIsLoading(false));
            if (error.response.data.error) {
                console.log('error.response.data.error message: ', error.response.data.error);
            } else {
                console.log('error.response.data message: ', error.response.data);
            }
            dispatch(setValidFile(false));
        }
    };

export const uploadMember =
    (file: File, healthPlanId: string, populationId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        let formData = new FormData();
        formData.append('File', file);
        formData.append('HealthPlanId', healthPlanId);
        formData.append('PopulationId', populationId);
        // Start timer to get file progress
        const startTimer = setInterval(async () => {
            dispatch(getFileProcessedCount());
        }, 2000);
        try {
            const response = await axios.put(`${API_URL}/population/uploadmember`, formData);
            console.log('uploadMember RESPONSE: ', response.data);
            clearInterval(startTimer);
            dispatch(setImportSuccessful(true));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
        dispatch(fetchHealthPlan());
    };

export default adminHealthPlanSlice.reducer;
