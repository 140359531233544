import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setRequestType } from '../../../../../../store/requestReport.slice';
import { fetchRequestTypeOptions } from '../../../../../../store/requests.slice';

const RequestTypeAutocomplete = () => {
    const dispatch = useDispatch();
    const { isLoading, isError, data, error } = useQuery('reportRequestTypes', fetchRequestTypeOptions, { refetchInterval: 0 });
    const [requestTypeInputValue, setRequestTypeInputValue] = useState('');

    let requestTypeAutocomplete: JSX.Element = null;
    if (isLoading) {
        requestTypeAutocomplete = (
            <Box style={{ textAlign: 'center' }}>
                <CircularProgress />
            </Box>
        );
    } else if (isError) {
        requestTypeAutocomplete = <p>Error: {(error as Error).message}</p>;
    } else {
        requestTypeAutocomplete = (
            <Autocomplete
                fullWidth
                multiple={false}
                id="request-type-selection"
                options={data}
                isOptionEqualToValue={(option, value: any) => {
                    return option.value === value.value;
                }}
                renderInput={(params) => <TextField {...params} label="Request Type" />}
                onChange={(event: any, newValue: any) => {
                    console.log({ event, newValue });
                    if (newValue?.value) {
                        dispatch(setRequestType(newValue.value));
                    } else {
                        dispatch(setRequestType(newValue?.value ?? null));
                    }
                }}
                getOptionLabel={(option: any) => option.label}
                inputValue={requestTypeInputValue}
                onInputChange={(event, value) => {
                    setRequestTypeInputValue(value);
                }}
            />
        );
    }

    return requestTypeAutocomplete;
};

export default React.memo(RequestTypeAutocomplete);
