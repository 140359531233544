import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';

const memberEncounterBillingReport = createSlice({
    name: 'memberEncounterBillingReport',
    initialState: {
        memberData: { MemberName: '', Address: '', DOB: '', Gender: '', InsuranceType: '' },
        searchFilter: '1',
        searchKey: '',
        rows: [],
        searchName: '',
        memberId: '',
        membersListByName: [],
        searchLoading: false,
    },
    reducers: {
        _setMemberData(state, action) {
            state.memberData = action.payload;
        },
        _setSearchFilter(state, action) {
            state.searchFilter = action.payload;
        },
        _setSearchKey(state, action) {
            state.searchKey = action.payload;
        },
        _setRows(state, action) {
            state.rows = action.payload;
        },
        _setSearchName(state, action) {
            state.searchName = action.payload;
        },
        _setMemberId(state, action) {
            state.memberId = action.payload;
        },
        _setMembersListByName(state, action) {
            state.membersListByName = action.payload;
        },
        _setSearchLoading(state, action) {
            state.searchLoading = action.payload;
        },
    },
});

const { _setMemberData, _setSearchFilter, _setSearchKey, _setRows, _setSearchName, _setMemberId, _setMembersListByName, _setSearchLoading } =
    memberEncounterBillingReport.actions;

export const setMemberData =
    (params: any): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberData(params));
    };

export const setSearchFilter =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSearchFilter(params));
    };

export const setSearchKey =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSearchKey(params));
    };

export const setRows =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setRows(params));
    };

export const setSearchName =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setSearchName(params));
    };

export const setMemberId =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setMemberId(params));
    };

export const setMembersListByName =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setMembersListByName(params));
    };

export const setSearchLoading =
    (params: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setSearchLoading(params));
    };

export const fetchMemberByName =
    (searchName: string): AppThunk =>
    async (dispatch) => {
        if (searchName.length > 1) {
            dispatch(setSearchLoading(true));
            const response = await axios.get(`${API_URL}/member/searchmemberbyname?keyword=${searchName}&status=undefined`);
            dispatch(setMembersListByName(response.data));
            dispatch(setSearchLoading(false));
        }
    };

export const fetchMemberEncounterBillingReport =
    (searchKey: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/encounterbillingreport/getencounterbillinghistorybymember?searchKey=${searchKey}`);
            dispatch(setMemberData(response.data));
            dispatch(setMemberId(response.data.Id));
            dispatch(setRows(response.data.Services));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setMemberData({ MemberName: '', Address: '', DOB: '', Gender: '', InsuranceType: '', Id: '' }));
            dispatch(setRows([]));
            dispatch(setIsLoading(false));
        }
    };

export const exportMemberEncounterBillingReport =
    (searchKey: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setDownLoading(true));
            const response = await axios.get(`${API_URL}/encounterbillingreport/exportmemberencountertoxlsx?memberId=${searchKey}`);
            let blob = b64toBlob(response.data.base64String, 'text/csv');
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.xlsx`);
            link.click();
            link.remove();
            dispatch(setDownLoading(false));
        } catch (error) {
            console.log(error);
        }
    };

export default memberEncounterBillingReport.reducer;
