import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';

const surveyReport = createSlice({
    name: 'surveyReport',
    initialState: {
        reportData: [],
        selectedProviderIds: ['All Providers'],
    },
    reducers: {
        _setReportData(state, action) {
            state.reportData = action.payload;
        },
        _setSelectedProviderIds(state, action) {
            state.selectedProviderIds = action.payload;
        },
    },
});

const { _setReportData, _setSelectedProviderIds } = surveyReport.actions;

export const setReportData =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setReportData(params));
    };

export const fetchSurveyReportData =
    (start: Date, end: Date, providerIds: string[], showInActive: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(
            `${API_URL}/SurveyReport/getreport?start=${dateFormatter(start)}&end=${dateFormatter(end)}&providerIds=${providerIds.join(
                ','
            )}&includeInActiveProviders=${showInActive}`
        );
        dispatch(_setReportData(response.data));
        dispatch(setIsLoading(false));
    };

export const exportSurveyReport =
    (start: Date, end: Date, providerIds: string[], showInActive: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(
            `${API_URL}/SurveyReport/export?start=${dateFormatter(start)}&end=${dateFormatter(end)}&providerIds=${providerIds.join(
                ','
            )}&includeInActiveProviders=${showInActive}`
        );
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export const setSelectedProviderIds =
    (selectedIds: string[]): AppThunk =>
    async (dispatch) => {
        selectedIds = selectedIds.length === 0 ? ['All Providers'] : selectedIds.length > 1 ? selectedIds.filter((s) => s !== 'All Providers') : selectedIds;
        dispatch(_setSelectedProviderIds(selectedIds));
    };

export default surveyReport.reducer;
