import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack, Divider, Typography, TextField, FormControl, CircularProgress } from '@mui/material';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { RootState } from '../../reducers';
import { addPlanOfCare, toggleIsShowPlanOfCareForm } from '../../store/transitionalCare.slice';
import { PlanOfCare } from '../../Models/TransitionalCare/PlanOfCare.model';
import { copyObject } from '../../utils/common';
import { TransitionStep } from '../../Models/TransitionalCare/TransitionStep';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';

const plansOfCareSchema = Yup.object().shape({
    PlanOfCareAndTreatment: Yup.string().nullable().required('Plan is required.'),
});

const PlansOfCare: React.FC<{ transitionStep: TransitionStep; isAssessment: boolean }> = ({ transitionStep, isAssessment }) => {
    const dispatch = useDispatch();
    const { isShowPlanOfCareForm } = useSelector((state: RootState) => state.transitionalCare);
    const { engagementDraft, isSaving } = useSelector((state: RootState) => state.documentEngagement);

    const plansOfCares = copyObject(transitionStep?.PlansOfCare || []).reverse();

    const handleOnSubmit = (values) => {
        if (isAssessment) {
            let data = copyObject(engagementDraft);
            data['AddPlanOfCare'] = true;
            dispatch(saveEngagementDraft(data));
        }
        dispatch(addPlanOfCare(values.PlanOfCareAndTreatment));
    };

    const handleOpen = () => dispatch(toggleIsShowPlanOfCareForm(true));
    const handleClose = () => dispatch(toggleIsShowPlanOfCareForm(false));

    const RenderModalForm = () => {
        return (
            <Dialog open={isShowPlanOfCareForm} onClose={handleClose} fullWidth maxWidth={'sm'}>
                <DialogTitle>Add Plan of Care & Treatments</DialogTitle>
                <DialogContent>
                    <Formik initialValues={{ PlanOfCareAndTreatment: '' }} onSubmit={handleOnSubmit} validationSchema={plansOfCareSchema}>
                        {({ values, errors, handleChange }) => (
                            <Form id="plan-of-care-form">
                                <FormControl fullWidth={true} error={Boolean(errors?.PlanOfCareAndTreatment)}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        focused
                                        required
                                        sx={{ marginY: 2 }}
                                        label="Description"
                                        variant="outlined"
                                        rows={6}
                                        value={values.PlanOfCareAndTreatment}
                                        name="PlanOfCareAndTreatment"
                                        onChange={handleChange}
                                        helperText={
                                            errors?.PlanOfCareAndTreatment ? errors?.PlanOfCareAndTreatment : 'Describe your plan of care and treatment.'
                                        }
                                        error={Boolean(errors?.PlanOfCareAndTreatment)}
                                    />
                                </FormControl>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button type="button" onClick={handleClose} disabled={isSaving}>
                        Cancel
                    </Button>
                    <Button
                        form="plan-of-care-form"
                        type="submit"
                        disabled={isSaving}
                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                    >
                        Save & Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <>
            <RenderModalForm />
            <Button disabled={!isAssessment} variant="outlined" color="primary" fullWidth sx={{ p: 2, marginBottom: 2 }} onClick={handleOpen}>
                Add Plan of Care and Treatment
            </Button>
            {!isAssessment && (
                <>
                    <Typography textAlign={'center'} variant="body2" color={'red'} sx={{ marginBottom: 2 }}>
                        You must enter Plan of Care and Treatment in the Assessment section.
                    </Typography>
                </>
            )}
            {plansOfCares?.map((planOfCare: PlanOfCare, index: number) => {
                const { PlanOfCareAndTreatment, CreatedBy, CreatedDate } = planOfCare;
                const lastItem = plansOfCares.length - 1 === index;
                return (
                    <Stack key={index} paddingY={2}>
                        <Typography variant="body1" fontWeight={500}>
                            {PlanOfCareAndTreatment}
                        </Typography>
                        <Typography variant="body2" color={'text.secondary'}>
                            {CreatedBy?.Name} - {moment(CreatedDate).format('DD MMM YYYY')}
                        </Typography>
                        {!lastItem && <Divider sx={{ marginTop: 3 }} />}
                    </Stack>
                );
            })}
        </>
    );
};

export default PlansOfCare;
