const useStyles = {
    container: {
        padding: '20px',
    },
    tableContainer: {
        marginTop: '20px',

        '& tbody td.MuiTableCell-root': {
            verticalAlign: 'baseline',
            fontSize: '15px',
        },
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
    noDataFound: {
        '&.MuiTableCell-root.MuiTableCell-body': {
            textAlign: 'center',
            fontSize: '16px',
            color: 'gray',
        },
    },
    scrollTopButton: {
        position: 'fixed !important' as any,
        right: '2%',
        bottom: '3%',
    },
    modalPopupForm: {
        top: '15vh',
        left: '50%',
        width: '900px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',

        '& .mui-modal-header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',

            '& h6': {
                fontSize: '16px',
            },
        },

        '& .mui-modal-body': {
            padding: '15px 15px 25px',
            maxHeight: 'calc(100vh - 30vh)',
            overflow: 'auto',

            '& .MuiFormLabel-root:not(.MuiInputLabel-root)': {
                color: 'black',
                fontSize: '14px',
                fontWeight: 700,
            },
        },

        '& .mui-modal-footer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            borderTop: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',

            '& button': {
                marginLeft: '15px',
            },
        },
    },
    form: {
        '& .MuiFormControl-root': {
            marginBottom: '15px',
        },
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    richTextBox: {
        '& .ql-editor': {
            minHeight: '250px',
        },
    },
};

export default useStyles;
