import { Card, CardContent, CardMedia, Grid, Link, Typography } from '@mui/material';

const EducationMaterialPageContent = (props) => {
    return props.materialItems?.map((item) => (
        <Grid textAlign="center" key={item.Id} item xs={3}>
            <Link target="_blank" underline="none" href={item.Url}>
                <Card sx={{ '&:hover': { boxShadow: 10, color: 'primary' } }}>
                    <CardContent>
                        <CardMedia
                            sx={{ objectFit: 'cover', objectPosition: '50% 0' }}
                            component="img"
                            width="100"
                            height="120"
                            alt={item.Name}
                            image={item.ThumbnailUrl}
                        />
                    </CardContent>
                    <Typography p={1} variant="body2">
                        {item.Name}
                    </Typography>
                </Card>
            </Link>
        </Grid>
    ));
};

export default EducationMaterialPageContent;
