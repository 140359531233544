import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    Button,
    Paper,
    Typography,
    Stack,
    Menu,
    MenuItem,
    Fab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    TextField,
    CircularProgress,
    Modal,
    Autocomplete,
    Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import classes from '../Styles';
import { addDiagnosis, deleteDiagnosis, getDiagnosisByKeyword, getDiagnosisByMember } from '../../../store/clinicalData.slice';
import DiagnosisFromClaims from './DiagnosisFromClaims';
import { ICD10 } from '../../../Models/Shared/ICD10.model';
import { Diagnosis } from '../../../Models/DocumentEngagements/Diagnosis.model';
import AlertDialog from '../../AlertDialog/AlertDialog';

const Diagnosises: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const [showDiagnosisFromClaims, setShowDiagnosisFromClaims] = useState(false);
    const [showAddDiagnosis, setShowAddDiagnosis] = useState(false);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState<ICD10>(null);
    const [deleteDiagnosisId, setDeleteDiagnosisId] = useState<string>('');
    const { diagnosises, diagnosisesByKeyword, deletingDiagnosisIds, isDiagnosisFetching, isDiagnosisByKeywordFetching, isDiagnosisSaving } = useSelector(
        (state: RootState) => state.clinicalData
    );

    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        dispatch(getDiagnosisByMember(memberId));
    }, [dispatch, memberId]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOnClickShowDiagnosis = (fromClaims = false) => {
        setAnchorEl(null);
        setSelectedDiagnosis(null);
        if (fromClaims) {
            setShowDiagnosisFromClaims(true);
        } else {
            setShowAddDiagnosis(true);
        }
    };

    const handleOnChangeSearch = (value: string) => {
        setSelectedDiagnosis(null);
        dispatch(getDiagnosisByKeyword(value));
    };

    const handleOnSave = () => {
        dispatch(
            addDiagnosis(memberId, convertICD10ToDiagnosis(selectedDiagnosis), () => {
                setShowAddDiagnosis(false);
            })
        );
    };

    const handleOnSelectDiagnosisFromClaims = (diagnosis: ICD10) => {
        dispatch(
            addDiagnosis(memberId, convertICD10ToDiagnosis(diagnosis), () => {
                setShowDiagnosisFromClaims(false);
            })
        );
    };

    const convertICD10ToDiagnosis = (icd10: ICD10) => {
        return {
            Id: icd10.Id,
            Code: icd10.Code,
            Name: icd10.Name,
            ICDcodeVersion: icd10.ICDcodeVersion,
        } as Diagnosis;
    };

    const handleOnClickDelete = (diagnosis: Diagnosis) => {
        setShowDeleteAlert(true);
        setDeleteDiagnosisId(diagnosis.Id);
    };

    const handleOnConfirmedDelete = () => {
        setDeleteDiagnosisId(null);
        setShowDeleteAlert(false);
        dispatch(deleteDiagnosis(memberId, deleteDiagnosisId));
    };

    const renderAddDiagnosis = () => {
        return (
            <Modal open={showAddDiagnosis} onClose={() => setShowAddDiagnosis(false)}>
                <Box sx={classes.modalPopupForm}>
                    <div className="mui-modal-header">
                        <Typography variant="h6" component="h6">
                            Add Diagnosis
                        </Typography>
                    </div>
                    <div className="mui-modal-body">
                        <Autocomplete
                            fullWidth
                            getOptionLabel={(option) => `${option.Code} ${option.Name}`}
                            options={diagnosisesByKeyword.filter((dk) => !diagnosises.some((d) => d.Id === dk.Id))}
                            freeSolo
                            value={selectedDiagnosis as any}
                            onChange={(e, value: any) => setSelectedDiagnosis(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Diagnosis*"
                                    variant="outlined"
                                    name="Diagnosis"
                                    onChange={(e) => handleOnChangeSearch(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isDiagnosisByKeywordFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="mui-modal-footer">
                        <Button
                            variant="contained"
                            type="submit"
                            size="small"
                            onClick={handleOnSave}
                            disabled={!Boolean(selectedDiagnosis) || isDiagnosisSaving}
                            endIcon={isDiagnosisSaving ? <CircularProgress size={18} color="inherit" /> : null}
                        >
                            Save
                        </Button>
                        <Button size="small" type="button" onClick={() => setShowAddDiagnosis(false)} variant="outlined" disabled={isDiagnosisSaving}>
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };

    return (
        <Paper elevation={3} sx={classes.paper}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Diagnosis</Typography>
                <Fab size="small" color="primary" aria-label="add" onClick={handleClick}>
                    <AddIcon />
                </Fab>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => handleOnClickShowDiagnosis()}>Diagnosis</MenuItem>
                    <MenuItem onClick={() => handleOnClickShowDiagnosis(true)}>Diagnosis from claims</MenuItem>
                </Menu>
            </Stack>
            <TableContainer sx={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="100%">Diagnosis</TableCell>
                            <TableCell width="20%">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {diagnosises.length ? (
                            diagnosises.map((diagnosis, index) => (
                                <TableRow key={`diagnosis_${index}`} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{`${diagnosis.Code} ${diagnosis.Name}`}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            sx={{ mr: 2 }}
                                            onClick={() => handleOnClickDelete(diagnosis)}
                                            disabled={deletingDiagnosisIds.some((d) => d === diagnosis.Id)}
                                        >
                                            {deletingDiagnosisIds.some((d) => d === diagnosis.Id) ? (
                                                <CircularProgress size={18} />
                                            ) : (
                                                <DeleteIcon color="error" fontSize="small" />
                                            )}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={8} sx={classes.noDataFound}>
                                    {isDiagnosisFetching ? <CircularProgress size={30} /> : <p>No Diagnosis found</p>}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <DiagnosisFromClaims
                memberId={memberId}
                show={showDiagnosisFromClaims}
                onClose={() => setShowDiagnosisFromClaims(false)}
                onSelect={handleOnSelectDiagnosisFromClaims}
                selectedDiagnosis={diagnosises.map((d) => d.Code)}
                isSaving={isDiagnosisSaving}
            />
            {renderAddDiagnosis()}
            <AlertDialog
                open={showDeleteAlert}
                title="Delete Diagnosis"
                okText="Confirm"
                onConfirm={handleOnConfirmedDelete}
                onClose={() => setShowDeleteAlert(false)}
                message="Are you sure want to delete diagnosis?"
            />
        </Paper>
    );
};

export default Diagnosises;
