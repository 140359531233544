import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { Notification } from '../Models/NotificationMessage/Notification.model';

const notificationsPage = createSlice({
    name: 'notificationsPage',
    initialState: {
        allNotifications: [] as Notification[],
        notificationTab: 0,
        notificationType: 0,
        // notificationType: NotificationTypeEnum,
    },
    reducers: {
        _setAllNotifications(state, action) {
            state.allNotifications = action.payload;
        },
        _setNotificationTab(state, action) {
            state.notificationTab = action.payload;
        },
        _setNotificationType(state, action) {
            state.notificationType = action.payload;
        },
    },
});

const { _setAllNotifications, _setNotificationTab, _setNotificationType } = notificationsPage.actions;

export const setNotificationTab =
    (notificationTab: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setNotificationTab(notificationTab));
    };

export const setNotificationType =
    (notificationType: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setNotificationType(notificationType));
    };

export const getAllNotifications =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/notification/getallnotification?providerId=${providerId}`);
        dispatch(_setAllNotifications(response.data));
        dispatch(setIsLoading(false));
    };

export const markNotificationAsRead =
    (notificationId: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.put(`${API_URL}/notification/markasread?notificationId=${notificationId}`);
        console.log('markNotificationAsRead', response.data);
    };

export const deleteNotification =
    (notificationId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.delete(`${API_URL}/notification/delete?notificationId=${notificationId}`);
        console.log('deleteNotification', response.data);
        dispatch(setIsLoading(false));
    };

export const markAsReadHandler =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        console.log('markAsReadHandler: ', id);
        const notifications = getState().notificationsPage.allNotifications;
        const notification = notifications.find((notification) => notification.Id === id);
        console.log('notification: ', notification);
        const updated = { ...notification, IsRead: true };
        const copyNotifications = [...notifications, updated];
        dispatch(_setAllNotifications(copyNotifications));
        dispatch(markNotificationAsRead(id));
    };

export const deleteNotificationHandler =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        const notifications = getState().notificationsPage.allNotifications;
        const copyNotifications = notifications.filter((notification) => notification.Id !== id);
        dispatch(_setAllNotifications(copyNotifications));
        dispatch(deleteNotification(id));
    };

export default notificationsPage.reducer;
