import { Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { configureStackedSplitBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { fetchTeamLevelTransitionalCareSummary, MenuProps } from '../WidgetService';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import TeamLevelTcSummary from '../../../Models/Widget/TeamLevelTcSummary.model';
import { FacilityTypeEnum } from '../../../Enum/FacilityTypeEnum';
import '../../Widgets/Widgets.css';

const TeamLevelTcSummaries = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [teamLevelTcSummary, setTeamLevelTcSummary] = useState<TeamLevelTcSummary>();
    const {
        tcTeam,
        setTcTeam,
        tcFacility,
        setTcFacility,
        tcDateFilter,
        setTcDateFilter,
        tcFromDate,
        setTcFromDate,
        tcToDate,
        setTcToDate,
        tcShowInActiveTeams,
        setTcShowInActiveTeams,
    } = useWidgetContext();
    const filteredTeams = (tcShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const dateFilterList = [
        { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
        { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
        { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
        { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
    ];

    const faclityList = [
        { Id: FacilityTypeEnum.PhysicalHealth, Value: FacilityTypeEnum.PhysicalHealthDescription },
        { Id: FacilityTypeEnum.BehavioralHealthLevel1, Value: FacilityTypeEnum.BehavioralHealthLevel1Description },
        { Id: FacilityTypeEnum.BehavioralHealthLevel2, Value: FacilityTypeEnum.BehavioralHealthLevel2Description },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedSplitBarChart(
                    'Team Level TC Summary',
                    'Transitional Care',
                    'Visits',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'TeamLevelTcSummary',
                    history,
                    true
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (tcDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(tcFromDate) && Boolean(tcToDate))) {
            const changeFilter = async () => {
                let start = tcFromDate !== null ? new Date(tcFromDate).toDateString() : null;
                let end = tcToDate !== null ? new Date(tcToDate).toDateString() : null;

                const data = await fetchTeamLevelTransitionalCareSummary(tcTeam, tcFacility, tcDateFilter, start, end, tcShowInActiveTeams);
                setTeamLevelTcSummary(data);
            };
            if ((tcFromDate !== null || tcToDate !== null) && tcToDate < tcFromDate) {
            } else {
                setChartOptions(
                    configureStackedSplitBarChart(
                        'Team Level TC Summary',
                        'Transitional Care',
                        'Visits',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0],
                        'TeamLevelTcSummary',
                        history,
                        true
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tcTeam, tcFacility, tcDateFilter, tcFromDate, tcToDate, tcShowInActiveTeams]);

    useEffect(() => {
        if (teamLevelTcSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let totalDischares = [];
            let visitsWithin7Days = [];
            let visitsWithin30Days = [];

            let visitsWithin7DaysPercentage =
                teamLevelTcSummary.TotalDischarges > 0 ? (teamLevelTcSummary.VisitsWithIn7DaysOfDischarge / teamLevelTcSummary.TotalDischarges) * 100 : 0;
            let d = {
                y: teamLevelTcSummary.VisitsWithIn7DaysOfDischarge,
                name: Math.round(visitsWithin7DaysPercentage),
            };
            visitsWithin7Days.push(d);

            let visitsWithIn30DaysPercentage =
                teamLevelTcSummary.TotalDischarges > 0 ? (teamLevelTcSummary.VisitsWithIn30DaysOfDischarge / teamLevelTcSummary.TotalDischarges) * 100 : 0;
            let d1 = {
                y: teamLevelTcSummary.VisitsWithIn30DaysOfDischarge,
                name: Math.round(visitsWithIn30DaysPercentage),
            };
            visitsWithin30Days.push(d1);

            let d3 = {
                y: teamLevelTcSummary.TotalDischarges,
                name: Math.round(100),
            };
            totalDischares.push(d3);

            xAxisValues.push('Visits');

            yAxisValues.push({ name: 'Total Discharges', data: totalDischares, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Visits Within 7 Days', data: visitsWithin7Days, color: '#3676e8', showInLegend: true });
            yAxisValues.push({ name: 'Visits Within 30 Days', data: visitsWithin30Days, color: '#7864ed', showInLegend: true });

            if (teamLevelTcSummary.TotalDischarges === 0) {
                setChartOptions(
                    configureStackedSplitBarChart(
                        'Team Level TC Summary',
                        'Transitional Care',
                        'Visits',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'TeamLevelTcSummary',
                        history,
                        true
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedSplitBarChart(
                        'Team Level TC Summary',
                        subTitle,
                        xAxisTitle,
                        yAxisTitle,
                        yAxisValues,
                        xAxisValues,
                        'TeamLevelTcSummary',
                        history,
                        true
                    )
                );
            }

            setIsGraphShow(true);
        }
    }, [teamLevelTcSummary, history]);

    const changeFromDate = (date: any) => {
        setTcFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(tcToDate) || new Date(tcToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setTcToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setTcToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(tcFromDate) || new Date(tcFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setTcFromDate(startDate);
        }
    };

    const navigateToDetail = () => {
        history(`/provider/metricsummary/teamleveltcsummarydetail`);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setTcShowInActiveTeams(isChecked);
        setTcTeam('All');
    };

    const handleClick = (event) => {
        setShow(!show);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToDetail}>
                                    Team Level TC Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Total Discharges :
                                            </Typography>
                                            <Typography variant="caption">Total discharges in transitional care visits</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Visits within 7 Days :
                                            </Typography>
                                            <Typography variant="caption">Visits completed within 7 days of discharge</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Visits within 30 Days :
                                            </Typography>
                                            <Typography variant="caption">Visits completed within 30 days of discharge</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Facility</InputLabel>
                                <Select
                                    value={tcFacility}
                                    label="Facility"
                                    name="Facility"
                                    onChange={(e) => setTcFacility(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {faclityList.map((facility) => (
                                        <MenuItem key={facility.Id} value={facility.Id}>
                                            {facility.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select value={tcTeam} label="Team" name="Team" onChange={(e) => setTcTeam(e.target.value)} MenuProps={MenuProps}>
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={tcShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select value={tcDateFilter} label="Date Filter" name="Date" onChange={(e) => setTcDateFilter(parseInt(e.target.value as any))}>
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {tcDateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={tcFromDate || null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={tcToDate || null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default TeamLevelTcSummaries;
