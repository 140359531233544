import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import {
    getMetaTagsByCategoryId,
    setDocumentHasErrors,
    setDocumentObj,
    setFile,
    setFileIsValid,
    setMetaTag,
    uploadDocumentHandler,
} from '../../../store/memberDocuments.slice';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';

const AddDocumentDialog = (props) => {
    const { onClose, open, auth, memberId } = props;
    const dispatch = useDispatch();
    const { documentHasErrors, documentObj, documentsTree, file, fileIsValid, metaTag, metaTagsByCategoryId } = useSelector(
        (state: RootState) => state.memberDocuments
    );
    const consent = documentsTree?.find((el) => el.Name === 'Consent For Care');

    const changeDocumentCategoryHandler = (e) => {
        dispatch(setDocumentObj({ ...documentObj, DocumentCategory: e.target.value }));
        dispatch(getMetaTagsByCategoryId(e.target.value));
        e.target.value === consent.Id ? dispatch(setMetaTag('5f6448aae3276120f4020313')) : dispatch(setMetaTag(''));
    };

    const changeMetaTagsHandler = (event: React.MouseEvent<HTMLElement>, metaTag: string | null) => {
        if (metaTag != null) {
            dispatch(setMetaTag(metaTag));
        }
    };

    const addDocumentHandler = (e) => {
        if (e.target.files[0]) {
            if (e.target.files[0].type !== 'application/pdf') {
                dispatch(setFileIsValid(false));
            } else {
                dispatch(setFileIsValid(true));
                dispatch(setFile(e.target.files[0]));
                dispatch(setDocumentObj({ ...documentObj, FileName: e.target.files[0].name }));
            }
        } else {
            dispatch(setFileIsValid(false));
        }
    };

    const createDocumentObjectHandler = () => {
        const documentMetaTag = metaTagsByCategoryId.find((el) => el.Id === metaTag);
        // adds remaining values to document object
        const newDocumentObject = {
            ...documentObj,
            MemberId: memberId,
            UploadedBy: { Id: auth.UserId, Name: auth.UserName },
            LastUpdatedBy: { Id: auth.UserId, Name: auth.UserName },
            MetaTags: [{ ...documentMetaTag, IsSelected: true }],
        };

        if (
            documentObj.Name.length === 0 ||
            documentObj.DocumentCategory.length === 0 ||
            documentObj.MetaTags.length === 0 ||
            documentObj.FileName.length === 0 ||
            (documentObj.DocumentCategory === consent.Id && documentObj.DocumentSignedOn === null) ||
            (documentObj.DocumentCategory === consent.Id && metaTag.length === 0)
        ) {
            dispatch(setDocumentHasErrors(true));
        } else {
            dispatch(setDocumentHasErrors(false));
            dispatch(uploadDocumentHandler(file, newDocumentObject));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEnforceFocus
            maxWidth="md"
            fullWidth
        >
            <Paper
                sx={{
                    padding: '16px',
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h6" component={'p'}>
                        Add Document
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} paddingTop={2}>
                        <FormControl fullWidth sx={{ maxWidth: '400px' }}>
                            <InputLabel id="demo-simple-select-label">Document Name</InputLabel>
                            <OutlinedInput
                                required
                                value={documentObj.Name}
                                id="document-name"
                                label="Document Name"
                                name="document"
                                error={documentHasErrors && documentObj.Name.length === 0}
                                fullWidth
                                onChange={(e) => dispatch(setDocumentObj({ ...documentObj, Name: e.target.value }))}
                            />
                            {documentHasErrors && documentObj.Name.length === 0 && <FormHelperText>Required</FormHelperText>}
                        </FormControl>
                        <FormControl fullWidth sx={{ maxWidth: '400px' }}>
                            <InputLabel id="demo-simple-select-label">Document Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={documentObj.DocumentCategory}
                                error={documentHasErrors && documentObj.DocumentCategory.length === 0}
                                label="Document Category"
                                onChange={changeDocumentCategoryHandler}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                            >
                                <MenuItem disabled value={'0'}>
                                    Select Document Category
                                </MenuItem>
                                {documentsTree.map((doc) => {
                                    return (
                                        <MenuItem key={doc.Id} value={doc.Id}>
                                            {doc.Name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {documentHasErrors && documentObj.DocumentCategory.length === 0 && <FormHelperText>Required</FormHelperText>}
                        </FormControl>

                        {documentObj.DocumentCategory === consent?.Id && (
                            <FormControl fullWidth sx={{ maxWidth: '400px' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Consent Signed-On Date"
                                        renderInput={(params) => (
                                            <TextField
                                                error={documentHasErrors ? true : false}
                                                required
                                                helperText="Required"
                                                color="error"
                                                sx={{ width: '100%' }}
                                                {...params}
                                            />
                                        )}
                                        onChange={(e: any) => {
                                            dispatch(setDocumentObj({ ...documentObj, DocumentSignedOn: e }));
                                        }}
                                        value={documentObj.DocumentSignedOn}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        )}

                        <FormControl fullWidth margin="normal">
                            <FormLabel>Meta Data Tags</FormLabel>
                            <ToggleButtonGroup sx={{ display: 'flex' }} exclusive value={metaTag} color="primary" onChange={changeMetaTagsHandler}>
                                {metaTagsByCategoryId.length > 0 &&
                                    metaTagsByCategoryId.map((tag) => {
                                        return (
                                            <ToggleButton key={tag.Id} value={tag.Id}>
                                                {tag.Name}
                                            </ToggleButton>
                                        );
                                    })}
                            </ToggleButtonGroup>

                            {documentHasErrors && metaTag.length === 0 && documentObj.DocumentCategory === consent?.Id && (
                                <FormHelperText>Required</FormHelperText>
                            )}
                        </FormControl>
                        <Stack spacing={2} marginY={5}>
                            <form id={'upload-form'}>
                                <Button color="success" variant="contained" id="upload-btn" component="label" sx={{ textAlign: 'center' }}>
                                    Choose Document to Upload
                                    <input id="file" name="File" onChange={addDocumentHandler} type="file" accept="application/pdf" hidden />
                                </Button>
                            </form>
                            {!fileIsValid && (
                                <Typography variant="subtitle1" color="error">
                                    Invalid File
                                </Typography>
                            )}
                            {fileIsValid && <Typography variant="subtitle1">{file ? file?.name : 'No file selected'}</Typography>}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={documentObj.DocumentCategory === '0'}
                        variant="contained"
                        className="button-120"
                        onClick={() => createDocumentObjectHandler()}
                        autoFocus
                    >
                        Save
                    </Button>
                    <Button variant="outlined" className="button-120" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    );
};

export default AddDocumentDialog;
