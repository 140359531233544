import { DiagnosisDraft } from '../DocumentEngagements/DiagnosisDraft.model';
import { MemberEncounterDraft } from '../Member/MemberEncounterDraft.model';

export default interface IEncounterList {
    PrimaryDiagnosis: DiagnosisDraft;
    Encounters: MemberEncounterDraft[];
}

export class EncounterList implements IEncounterList {
    PrimaryDiagnosis: DiagnosisDraft;
    Encounters: MemberEncounterDraft[];

    constructor() {
        this.PrimaryDiagnosis = new DiagnosisDraft();
        this.Encounters = [] as MemberEncounterDraft[];
    }
}
