import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import React from 'react';
import { IRequestsCommentProps } from '../interfaces/IRequestsCommentProps';

const RequestsComment: React.FC<IRequestsCommentProps> = ({ name, comment, date }) => {
    // IMPORTANT: Be sure not to set a comment who's HTML has not been first sanitized.
    // We should also sanitize the comments as we send them to the database.
    // This is for security purposes, preventing XSS attacks from insider threats (malicious users).
    return (
        <Card>
            <CardHeader title={name} subheader={new Date(date).toLocaleString()} />
            <CardContent>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(comment),
                    }}
                ></Typography>
            </CardContent>
        </Card>
    );
};

export default RequestsComment;
