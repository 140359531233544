import { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, List, ListItem, Stack, TextField, Typography } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

import GuardianOrCaregiverInput from '../../Shared/GuardianOrCaregiverInput';
import Signature from '../../Shared/Signature';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { HealthPlansEnum } from '../../../../Enum/HealthPlansEnum';
import { setDigitalSignatureForm } from '../../../../store/consentSlice.slice';
import { ConsentSignatureTypeEnum } from '../../../../Enum/ConsentSignatureTypeEnum';
import SignatureTypeTabs from '../../Shared/SignatureTypeTabs';

export default function ReleaseHealthInfo() {
    const { activeStep, digitalSignatureForm } = useSelector((state: RootState) => state.consentSlice);
    const dispatch = useDispatch();
    const [openReleaseHealthInfoSignature, setOpenReleaseHealthInfoSignature] = useState(false);
    const [openGuardianOrCareGiverHealthInfo, setOpenGuardianOrCareGiverHealthInfo] = useState(false);
    const [openGuardianOrCareGiverWitnessHealthInfo, setOpenGuardianOrCareGiverWitnessHealthInfo] = useState(false);
    const [openVerbalConsent, setOpenVerbalConsent] = useState(false);

    const handleHealthPlanChange = (plan: HealthPlansEnum) => {
        const updatedHealthPlans = digitalSignatureForm.HealthPlans.includes(plan)
            ? digitalSignatureForm.HealthPlans.filter((p) => p !== plan)
            : [...digitalSignatureForm.HealthPlans, plan];

        dispatch(
            setDigitalSignatureForm({
                ...digitalSignatureForm,
                HealthPlans: updatedHealthPlans,
            })
        );
    };

    useEffect(() => {
        const element = document.getElementById('top');
        element.scrollIntoView({ behavior: 'smooth' });
    }, [activeStep]);

    return (
        <Box sx={{ position: 'relative' }}>
            <div id="top" className="initials-div"></div>
            <Stack textAlign={'left'} spacing={2} p={2} mt={5}>
                <Typography variant="h5" p={2} fontWeight={'bold'} textAlign={'center'}>
                    AUTHORIZATION TO RELEASE HEALTH INFORMATION
                </Typography>
                <Typography variant="body1" fontWeight={800}>
                    I THE UNDERSIGNED HEREBY EXPRESSLY AUTHORIZE THE RELEASE OF MEMBERS PERSONAL INFORMATION AS WELL AS HEALTH INFORMATION TO THE
                    INDIVIDUAL/ENTITY NAMED BELOW FOR THE PURPOSE SPECIFIED HEREIN:
                </Typography>
                <Typography variant="body1">
                    <b>Name: CATALYTIC HEALTH PARTNERS, INC.,</b> an Arizona corporation
                </Typography>
                <Typography variant="body1">
                    <b>Relationship:</b> non-clinical management services entity for <b>CHP CARE, P.C.,</b> an Arizona professional corporation.
                </Typography>
                <Typography variant="body1">
                    <b>Address:</b> 3050 W Agua Fria Freeway, Suite 150, Phoenix, AZ 85027
                </Typography>
                <Typography variant="body1">
                    <b>Purposes:</b> Coordination of Care
                </Typography>
                <Typography variant="body1">
                    For care coordination between CHP Care and my health plan and other behavioral health and medical providers, including for the purposes
                    listed below:
                </Typography>

                <FormGroup sx={{ px: 2 }}>
                    <List sx={{ p: 2 }}>
                        <ListItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.CareCoordinationSubstanceDisorder}
                                        onChange={(e) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    CareCoordinationSubstanceDisorder: e.target.checked,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography variant="body1">
                                        The release of documentation pertaining to my treatment including substance use disorders
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.CareCoordinationMentalHealth}
                                        onChange={(e) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    CareCoordinationMentalHealth: e.target.checked,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography variant="body1">
                                        The release of documentation related to behavioral health or mental health treatment
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.CareCoordinationRelatedIssues}
                                        onChange={(e) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    CareCoordinationRelatedIssues: e.target.checked,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={<Typography variant="body1">The release of documentation related to other medical issues</Typography>}
                            />
                        </ListItem>
                    </List>
                    <TextField
                        variant="standard"
                        value={digitalSignatureForm.ProviderName}
                        onChange={(e) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    ProviderName: e.target.value,
                                })
                            )
                        }
                        label={`Provider Name`}
                        sx={{ my: 2 }}
                        fullWidth
                    />

                    <Grid container spacing={2} my={2} pl={4}>
                        {Object.keys(HealthPlansEnum)
                            .filter((key) => isNaN(Number(key))) // Filter out numeric keys
                            .map((key) => {
                                const plan = HealthPlansEnum[key as keyof typeof HealthPlansEnum];
                                const description = HealthPlansEnum[`${key}Description` as keyof typeof HealthPlansEnum];
                                if (!description) {
                                    return null;
                                }

                                return (
                                    <Grid item key={plan}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={digitalSignatureForm.HealthPlans.includes(plan)}
                                                    onChange={() => handleHealthPlanChange(plan)}
                                                />
                                            }
                                            label={<Typography>{description}</Typography>}
                                        />
                                    </Grid>
                                );
                            })}
                        {digitalSignatureForm.HealthPlans.includes(HealthPlansEnum.Other) && (
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ width: '100%' }}
                                    label="Plan Name"
                                    variant="standard"
                                    value={digitalSignatureForm.HealthPlanOther}
                                    onChange={(e) => {
                                        dispatch(
                                            setDigitalSignatureForm({
                                                ...digitalSignatureForm,
                                                HealthPlanOther: e.target.value,
                                            })
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormGroup>
                <Typography variant="body1">
                    I understand that any personal health information or other information released to the person or organization identified above may be
                    subject to re-disclosure by such person/organization and may no longer be protected by applicable federal and state privacy laws. This
                    authorization is valid from the date of my/my representative’s signature below and shall expire the earlier of{' '}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl>
                        <DatePicker
                            label="Expiration"
                            renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                            onChange={(date) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        ConsentExpiration: date,
                                    })
                                )
                            }
                            value={digitalSignatureForm.ConsentExpiration}
                        />
                    </FormControl>
                </LocalizationProvider>
                <Typography variant="body1" pt={2}>
                    I understand that I have a right to revoke this authorization by providing written notice. However, this authorization may not be revoked if
                    employees or agents have taken action on this authorization prior to receiving my written notice. I also understand that I have a right to
                    have a copy of this authorization.
                </Typography>
                <Typography variant="body1">
                    I further understand that this authorization is voluntary and that I may refuse to sign this authorization. My refusal to sign will not
                    affect my eligibility for benefits or enrollment or payment for or coverage of services.
                </Typography>
                <Typography variant="h6" fontWeight={'bold'} id="health-info-signature">
                    I HAVE READ OR HAD READ TO ME AND FULLY UNDERSTAND THIS CONSENT; I HAVE HAD THE OPPORTUNITY TO ASK QUESTIONS AND HAD THESE QUESTIONS
                    ADDRESSED.
                </Typography>
            </Stack>
            <SignatureTypeTabs />
            <Divider />
            <Box p={2} textAlign={'left'}>
                {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Patient && (
                    <>
                        <Stack spacing={2} direction={'row'} pb={2}>
                            <TextField
                                id="member-name"
                                error={digitalSignatureForm.MemberName.length === 0}
                                variant="standard"
                                value={digitalSignatureForm.MemberName}
                                label={`Name`}
                                fullWidth
                                onChange={(e) =>
                                    dispatch(
                                        setDigitalSignatureForm({
                                            ...digitalSignatureForm,
                                            MemberName: e.target.value,
                                        })
                                    )
                                }
                            />
                            <TextField variant="standard" value={`${moment().format('MM-DD-YYYY')}`} label={`Date`} fullWidth />
                        </Stack>
                        <Signature
                            isSpanish={false}
                            buttonLabel={'Signature'}
                            isOpen={openReleaseHealthInfoSignature}
                            setIsOpen={setOpenReleaseHealthInfoSignature}
                            signature={digitalSignatureForm.MemberSignatureTwo}
                            setSignature={(signature: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        MemberSignatureTwo: signature,
                                    })
                                )
                            }
                        />
                    </>
                )}
                {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.GuardianOrCaregiver && (
                    <GuardianOrCaregiverInput
                        isSpanish={false}
                        openGuardianOrCaregiver={openGuardianOrCareGiverHealthInfo}
                        setOpenGuardianOrCaregiver={setOpenGuardianOrCareGiverHealthInfo}
                        guardianOrCareGiver={digitalSignatureForm.GuardianOrCaregiverSignatureHealthInfo}
                        setGuardianOrCareGiver={(signature: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    GuardianOrCaregiverSignatureHealthInfo: signature,
                                })
                            )
                        }
                        openGuardianOrCareGiverWitness={openGuardianOrCareGiverWitnessHealthInfo}
                        setOpenGuardianOrCareGiverWitness={setOpenGuardianOrCareGiverWitnessHealthInfo}
                        guardianOrCareGiverWitness={digitalSignatureForm.WitnessHealthInfo}
                        setGuardianOrCareGiverWitness={(signature: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    WitnessHealthInfo: signature,
                                })
                            )
                        }
                    />
                )}
                {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Verbal && (
                    <>
                        {/* Verbal Consent */}
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.VerbalConsent}
                                        onChange={() =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsent: !digitalSignatureForm.VerbalConsent,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography>
                                        Verbal consent is required if member or legal representative is unable to physically sign at this time and yet consent
                                        for care has been explicitly agreed to.
                                        <b> Verbal consent must be witnessed by two people telephonically or in person.</b>
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.ReviewedTwo}
                                        onChange={() =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    ReviewedTwo: !digitalSignatureForm.ReviewedTwo,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography>
                                        <b> As the Provider, I have reviewed this page of the consent form with the member</b>
                                    </Typography>
                                }
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        helperText="Witness Digital Signature"
                                        sx={{ mt: 5 }}
                                        fullWidth
                                        label={'Witness One'}
                                        value={digitalSignatureForm.VerbalConsentWitnessOne}
                                        onChange={(e) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsentWitnessOne: e.target.value,
                                                })
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        helperText="Witness Digital Signature"
                                        sx={{ mt: 5 }}
                                        fullWidth
                                        label={'Witness Two'}
                                        value={digitalSignatureForm.VerbalConsentWitnessTwo}
                                        onChange={(e) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsentWitnessTwo: e.target.value,
                                                })
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Signature
                                        isSpanish={false}
                                        buttonLabel={'Witness Signature'}
                                        isOpen={openVerbalConsent}
                                        setIsOpen={setOpenVerbalConsent}
                                        signature={digitalSignatureForm.VerbalConsentSignature}
                                        setSignature={(signature: string) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsentSignature: signature,
                                                })
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </>
                )}
            </Box>
        </Box>
    );
}
