import EmptyFootImage from '../../../assets/images/assessments/emptyfoot-small.png';

const useStyles = {
    headerTitle: {
        fontSize: '18px',
        fontWeight: 700,
        color: '#666',
        marginTop: '15px',
        marginBottom: '20px',
    },
    questionRow: {
        '&.MuiGrid-container': {
            marginBottom: '15px',

            '& h6': {
                fontSize: '18px',
                color: '#666',
            },
        },
    },
    answerRow: {
        '&.MuiGrid-container': {
            '& label': {
                fontSize: '14px',
                color: '#666',
                fontWeight: 500,
            },
        },
    },
    description: {
        margin: '0 0 8px',
        color: '#666',
        fontSize: '15px',
    },
    paperContainer: {
        width: '530px',
        height: '310px',
        backgroundImage: `url(${EmptyFootImage})`,
        marginTop: '20px',
        position: 'relative',
    },
    sensationItem: {
        position: 'absolute',
        marginLeft: '-12px',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'baseline',
        textAlign: 'right',
        padding: '0!important',
        '& span': {
            width: '15px',
            marginRight: '-2px',
        },
    },
    sensationImage: {
        width: '34px',
        height: '32px',
        zIndex: 99,
        cursor: 'pointer',
    },
    sensationNoteContent: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        padding: '0!important',
        '& span': {
            marginRight: '5px',
            minWidth: '25px',
        },
    },
    skinConditionImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',

        '& span': {
            position: 'absolute',
            width: '100%',
            marginRight: '0',
            marginTop: '1px',
            fontWeight: 'bold',
            color: '#666',
            textAlign: 'center',
        },
    },
};

export default useStyles;
