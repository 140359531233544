import { useDispatch, useSelector } from 'react-redux';
import {
    RadioGroup,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InfoIcon from '@mui/icons-material/Info';
import TCModal from '../../assets/documents/TC_Modals.pdf';
import { RootState } from '../../reducers';
import { addDischargeToTransition, toggleIsShowDischargeDetails } from '../../store/transitionalCare.slice';
import moment from 'moment';
import { PatientDischarge } from '../../Models/TransitionalCare/PatientDischarge.model';
import { YesNoAnswers } from '../../utils/assessments';
import { preventTextInput } from '../../utils/common';
import { OutsideFacilityLocationEnum } from '../../Enum/OutsideFacilityLocationEnum';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TransitionalCareStepTypeEnum } from '../../Enum/TransitionalCareStepTypeEnum';

const dischargeDetailSchema = Yup.object().shape({
    Id: Yup.string().nullable(),
    AdmissionDate: Yup.string().nullable().required('Discharge Date is required'),
    OutsideFacilityLocation: Yup.string().nullable().required('Outside Facility Location is required'),
    NotificationMethod: Yup.string().nullable().required('Notification Method is required'),
});

const AddDischargeDetail = (props) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const { dischargeFacility, memberTransitionalCare, tcNotifications, outsideFacilityLocations, isShowDischargeDetails } = useSelector(
        (state: RootState) => state.transitionalCare
    );
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);

    const handleOnSubmit = (values: any) => {
        let data = {} as any;
        data = {
            Address: values.Address,
            Description: values.Description,
            AdmissionDate: values.AdmissionDate,
            DischargeSummary: values.DischargeSummary,
            NotificationMethod: values.NotificationMethod,
            OutsideFacilityLocation: values.OutsideFacilityLocation,
            ZipCode: values.ZipCode,
            CreatedDate: new Date(),
            UpdatedDate: new Date(),
            TransitionStepType: TransitionalCareStepTypeEnum.Discharge,
        };
        if (Boolean(values.Id)) {
            data = { ...dischargeFacility, ...data, Id: values.Id };
        }
        dispatch(addDischargeToTransition(memberTransitionalCare.Id, data));
        dispatch(toggleIsShowDischargeDetails(false));
    };

    const handleClose = () => {
        dispatch(toggleIsShowDischargeDetails(false));
    };

    const handleOnChangeOutsideFacility = (event: any, setFieldValue: Function) => {
        setFieldValue(event.target.name, event.target.value);
        if (event.target.value === String(OutsideFacilityLocationEnum.Home) && Boolean(memberBasicInfo?.Address)) {
            setFieldValue('Address', `${memberBasicInfo.Address}, ${memberBasicInfo.City}, ${memberBasicInfo.State}`);
            setFieldValue('ZipCode', memberBasicInfo.ZipCode);
        } else {
            setFieldValue('Address', '');
            setFieldValue('ZipCode', '');
        }
    };

    return (
        <Formik initialValues={dischargeFacility} onSubmit={handleOnSubmit} validationSchema={dischargeDetailSchema} enableReinitialize={true}>
            {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount, resetForm }: FormikProps<PatientDischarge>) => (
                <Dialog open={isShowDischargeDetails} onClose={handleClose} fullWidth maxWidth={'md'}>
                    <DialogTitle>
                        Discharge Detail
                        <Tooltip title="Transitional Care Help Document">
                            <InfoIcon
                                color="primary"
                                sx={{ marginLeft: 1, marginBottom: 1 }}
                                fontSize="small"
                                cursor="pointer"
                                onClick={() => window.open(`${TCModal}#Discharge`, '_blank')}
                            />
                        </Tooltip>
                    </DialogTitle>
                    <DialogContent>
                        <Form id="discharge-detail-form">
                            <Grid container spacing={2} marginTop="0">
                                <Grid item md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Discharge Date"
                                            value={values?.AdmissionDate ? new Date(values.AdmissionDate) : null}
                                            inputFormat="MM/dd/yyyy"
                                            minDate={new Date('2017-01-01')}
                                            onChange={(date) => {
                                                if (moment(date).isValid() || !Boolean(date)) {
                                                    const newDate = moment(date).format('YYYY-MM-DD') + 'T12:00:00.000Z';
                                                    setFieldValue('AdmissionDate', newDate);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField fullWidth {...params} required error={Boolean(errors?.AdmissionDate) && Boolean(submitCount)} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl fullWidth required error={Boolean(errors?.OutsideFacilityLocation) && Boolean(submitCount)}>
                                        <InputLabel id="admission-type-select">Outside Facility Location</InputLabel>
                                        <Select
                                            labelId="admission-type-select"
                                            id="admission-type-select"
                                            value={Boolean(values?.OutsideFacilityLocation) ? values?.OutsideFacilityLocation : ''}
                                            label="Outside Facility Location*"
                                            name="OutsideFacilityLocation"
                                            error={Boolean(errors?.OutsideFacilityLocation) && Boolean(submitCount)}
                                            onChange={(e) => handleOnChangeOutsideFacility(e, setFieldValue)}
                                        >
                                            {outsideFacilityLocations.map((type) => (
                                                <MenuItem key={type.Id} value={String(type.Id)}>
                                                    {type.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="mt-10">
                                <Grid item sm={8}>
                                    <TextField
                                        fullWidth
                                        placeholder="#123, City, State"
                                        margin="none"
                                        variant="outlined"
                                        label="Address"
                                        value={values.Address}
                                        name="Address"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        fullWidth
                                        placeholder="12345"
                                        margin="none"
                                        variant="outlined"
                                        onKeyPress={preventTextInput}
                                        label="Zip Code"
                                        inputProps={{ maxLength: 5 }}
                                        value={values.ZipCode}
                                        name="ZipCode"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} marginTop="10px">
                                <Grid item md={6}>
                                    <FormControl fullWidth required error={Boolean(errors?.NotificationMethod) && Boolean(submitCount)}>
                                        <InputLabel id="admission-type-select">Notification Method</InputLabel>
                                        <Select
                                            labelId="admission-type-select"
                                            id="admission-type-select"
                                            value={Boolean(values?.NotificationMethod) ? values?.NotificationMethod : ''}
                                            label="Notification Method*"
                                            name="NotificationMethod"
                                            onChange={handleChange}
                                        >
                                            {tcNotifications.map((type) => (
                                                <MenuItem key={type.Id} value={String(type.Id)}>
                                                    {type.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent={'space-evenly'} marginY={1}>
                                        <Typography variant="subtitle1" component="label">
                                            Discharge Summary?
                                        </Typography>
                                        <RadioGroup
                                            row
                                            name="DischargeSummary"
                                            value={Boolean(String(values.DischargeSummary)) ? String(values.DischargeSummary) : null}
                                            onChange={(e) => setFieldValue(e.target.name, e.target.value === 'true')}
                                        >
                                            {YesNoAnswers.map((type, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={`is-active-${index}`}
                                                        sx={{ marginBottom: 0 }}
                                                        value={type.Id}
                                                        control={<Radio />}
                                                        label={type.Name}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </Stack>
                                </Grid>
                                <Grid item sm={12} marginTop={2}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        label="Description"
                                        rows={6}
                                        value={values.Description || ''}
                                        name="Description"
                                        onChange={handleChange}
                                        helperText={'Describe discharge details.'}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button type="button" onClick={handleClose} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button form="discharge-detail-form" type="submit" variant="contained">
                            Save & Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default AddDischargeDetail;
