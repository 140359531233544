import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useNavigate } from 'react-router';
import { Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchPopulations } from '../../Population/PopulationService';
import { dayFilterList, fetchSchedulingSummary, MenuProps } from '../../Widgets/WidgetService';
import SchedulingSummary from '../../../Models/Widget/SchedulingSummary.model';
import Population from '../../../Models/Population/Population.model';
import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import Authorization from '../../../utils/Authorization';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import '../Widgets.css';

const SchedulingsSummary = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const history = useNavigate();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const { healthPlans } = useHealthPlanContext();
    const [populations, setPopulations] = useState<Population[]>([]);
    const [schedulingSummary, setSchedulingSummary] = useState<SchedulingSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const {
        schedulingDateFilter,
        setSchedulingDateFilter,
        schedulingFromDate,
        setSchedulingFromDate,
        schedulingToDate,
        setSchedulingToDate,
        schedulingSelectedPlan,
        setSchedulingSelectedPlan,
        schedulingSelectedPopulation,
        setSchedulingSelectedPopulation,
        schedulingSelectedProvider,
        setSchedulingSelectedProvider,
        schedulingSelectedStaff,
        setSchedulingSelectedStaff,
        schedulingSelectedTeam,
        setSchedulingSelectedTeam,
        schedulingShowInActiveProviders,
        setSchedulingShowInActiveProviders,
        schedulingShowInActiveTeams,
        setSchedulingShowInActiveTeams,
        schedulingShowInActiveStaffs,
        setSchedulingShowInActiveStaffs,
    } = useWidgetContext();
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const filteredTeams = (schedulingShowInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (schedulingShowInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const filteredStaffs = (schedulingShowInActiveStaffs ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filteredHealthPlans = healthPlans
        .filter((item) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const filterList = dayFilterList();

    const healthPlanChange = useCallback(
        async (healthPlanId: string, canResetPopulation = true) => {
            setPopulations([]);
            if (canResetPopulation) {
                setSchedulingSelectedPopulation(0);
            }
            if (healthPlanId && healthPlanId !== 'All') {
                const populations = await fetchPopulations(healthPlanId);
                setPopulations(populations);
            }
        },
        [setSchedulingSelectedPopulation]
    );

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
        if (schedulingSelectedPlan !== 'All') healthPlanChange(schedulingSelectedPlan, false);
        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChart(
                    'Scheduling Summary',
                    'Appointments Count',
                    'Appointments',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'Scheduling',
                    history
                )
            );
        }

        if (Boolean(authData.TeamId)) {
            setSchedulingSelectedTeam(authData.TeamId);
        }

        setIsFetched(true);
    }, [isFetched, isGraphShow, setIsFetched, setChartOptions, schedulingSelectedPlan, healthPlanChange, history, authData.TeamId, setSchedulingSelectedTeam]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (schedulingDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(schedulingFromDate) && Boolean(schedulingToDate))) {
            const changeFilter = async () => {
                const data = await fetchSchedulingSummary(
                    schedulingDateFilter,
                    schedulingSelectedPlan,
                    schedulingSelectedPopulation,
                    schedulingSelectedProvider,
                    schedulingSelectedTeam,
                    schedulingSelectedStaff,
                    Boolean(schedulingFromDate) && schedulingDateFilter === WidgetTimeFilterEnum.Custom ? new Date(schedulingFromDate).toISOString() : '',
                    Boolean(schedulingToDate) && schedulingDateFilter === WidgetTimeFilterEnum.Custom ? new Date(schedulingToDate).toISOString() : '',
                    schedulingShowInActiveTeams,
                    schedulingShowInActiveProviders,
                    schedulingShowInActiveStaffs,
                    false
                );
                setSchedulingSummary(data);
                setIsGraphShow(true);
            };
            setIsGraphShow(false);
            setChartOptions(
                configureStackedBarChart(
                    'Scheduling Summary',
                    'Appointments Count',
                    'Appointments',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'Scheduling',
                    history
                )
            );
            changeFilter();
        }

        if (schedulingDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(schedulingFromDate) && Boolean(schedulingToDate)) {
            setSchedulingFromDate(null);
            setSchedulingToDate(null);
        }
    }, [
        schedulingDateFilter,
        schedulingSelectedPlan,
        schedulingSelectedPopulation,
        schedulingSelectedProvider,
        schedulingSelectedStaff,
        schedulingFromDate,
        schedulingToDate,
        setSchedulingFromDate,
        setSchedulingToDate,
        history,
        schedulingSelectedTeam,
        schedulingShowInActiveTeams,
        schedulingShowInActiveProviders,
        schedulingShowInActiveStaffs,
    ]);

    useEffect(() => {
        if (schedulingSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let confirmList = [];
            let cancelledList = [];
            let noCallList = [];
            let completedList = [];
            let providerLeavesList = [];
            let noStatuslList = [];
            xAxisValues.push('Appointments');

            let confirmPercentage =
                schedulingSummary.TotalAppointments > 0 ? (schedulingSummary.ConfirmedAppointments / schedulingSummary.TotalAppointments) * 100 : 0;
            let d = {
                y: schedulingSummary.ConfirmedAppointments,
                name: Math.round(confirmPercentage),
            };
            confirmList.push(d);
            yAxisValues.push({ name: 'Confirmed', data: confirmList, color: '#00a652', showInLegend: true });

            if (Boolean(schedulingSummary.CancelledAppointments)) {
                let cancelPercentage =
                    schedulingSummary.TotalAppointments > 0 ? (schedulingSummary.CancelledAppointments / schedulingSummary.TotalAppointments) * 100 : 0;
                let d1 = {
                    y: schedulingSummary.CancelledAppointments,
                    name: Math.round(cancelPercentage),
                };
                cancelledList.push(d1);
                yAxisValues.push({ name: 'Cancelled', data: cancelledList, color: '#ff7d7d', showInLegend: true });
            }

            if (Boolean(schedulingSummary.NoCallOrNoShowAppointments)) {
                let noCallPercentage =
                    schedulingSummary.TotalAppointments > 0 ? (schedulingSummary.NoCallOrNoShowAppointments / schedulingSummary.TotalAppointments) * 100 : 0;
                let d2 = {
                    y: schedulingSummary.NoCallOrNoShowAppointments,
                    name: Math.round(noCallPercentage),
                };
                noCallList.push(d2);
                yAxisValues.push({ name: 'NoCall/NoShow', data: noCallList, color: '#4682B4', showInLegend: true });
            }

            if (Boolean(schedulingSummary.CompletedAppointments)) {
                let completedPercentage =
                    schedulingSummary.TotalAppointments > 0 ? (schedulingSummary.CompletedAppointments / schedulingSummary.TotalAppointments) * 100 : 0;
                let d3 = {
                    y: schedulingSummary.CompletedAppointments,
                    name: Math.round(completedPercentage),
                };
                completedList.push(d3);
                yAxisValues.push({ name: 'Completed', data: completedList, color: '#A569BD', showInLegend: true });
            }

            if (Boolean(schedulingSummary.ProviderLeaveAppointments)) {
                let providerLeavesPercentage =
                    schedulingSummary.TotalAppointments > 0 ? (schedulingSummary.ProviderLeaveAppointments / schedulingSummary.TotalAppointments) * 100 : 0;
                let d4 = {
                    y: schedulingSummary.ProviderLeaveAppointments,
                    name: Math.round(providerLeavesPercentage),
                };
                providerLeavesList.push(d4);
                yAxisValues.push({ name: 'Provider Leave', data: providerLeavesList, color: '#f0ad4e', showInLegend: true });
            }

            if (Boolean(schedulingSummary.NoStatusAppointments)) {
                let noStatusPercentage =
                    schedulingSummary.TotalAppointments > 0 ? (schedulingSummary.NoStatusAppointments / schedulingSummary.TotalAppointments) * 100 : 0;
                let d5 = {
                    y: schedulingSummary.NoStatusAppointments,
                    name: Math.round(noStatusPercentage),
                };
                noStatuslList.push(d5);
                yAxisValues.push({ name: 'No Status', data: noStatuslList, color: '#d9534f', showInLegend: true });
            }

            if (
                !Boolean(schedulingSummary.ConfirmedAppointments) &&
                !Boolean(schedulingSummary.CancelledAppointments) &&
                !Boolean(schedulingSummary.NoCallOrNoShowAppointments) &&
                !Boolean(schedulingSummary.CompletedAppointments) &&
                !Boolean(schedulingSummary.ProviderLeaveAppointments) &&
                !Boolean(schedulingSummary.NoStatusAppointments)
            ) {
                setChartOptions(
                    configureStackedBarChart(
                        'Scheduling Summary',
                        'Appointments Count',
                        'Appointments',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'Scheduling',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedBarChart('Appointment Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, 'Scheduling', history)
                );
            }

            setIsGraphShow(true);
        }
    }, [schedulingSummary, history]);

    const changeFromDate = (date: any) => {
        setSchedulingFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(schedulingToDate) || new Date(schedulingToDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setSchedulingToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setSchedulingToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(schedulingFromDate) || new Date(schedulingFromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setSchedulingFromDate(startDate);
        }
    };

    const navigateToMembers = () => {
        history(`/provider/metricsummary/schedulingsummarydetail`);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setSchedulingShowInActiveTeams(isChecked);
        setSchedulingSelectedTeam('All');
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        setSchedulingShowInActiveProviders(isChecked);
        setSchedulingSelectedProvider('All');
    };

    const toggleStaffHandler = (isChecked: boolean) => {
        setSchedulingShowInActiveStaffs(isChecked);
        setSchedulingSelectedStaff('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToMembers}>
                                    Scheduling Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Confirmed :
                                            </Typography>
                                            <Typography variant="caption">Number of confirmed appointments in given date range</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Cancelled :
                                            </Typography>
                                            <Typography variant="caption">Number of cancelled appointments in given date range</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                NoCall/NoShow :
                                            </Typography>
                                            <Typography variant="caption">Number of NoCall/NoShow appointments in given date range</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Completed :
                                            </Typography>
                                            <Typography variant="caption">Number of completed appointments in given date range</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                No Status :
                                            </Typography>
                                            <Typography variant="caption">Number of appointments without any status in given date range</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={schedulingDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setSchedulingDateFilter(parseInt(e.target.value as any))}
                                >
                                    {filterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {schedulingDateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(schedulingFromDate) ? schedulingFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(schedulingToDate) ? schedulingToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Health Plan</InputLabel>
                                <Select
                                    value={schedulingSelectedPlan}
                                    label="Health Plan"
                                    name="plan"
                                    onChange={(e) => {
                                        setSchedulingSelectedPlan(e.target.value);
                                        healthPlanChange(e.target.value);
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {filteredHealthPlans.map((plan) => (
                                        <MenuItem key={plan.Id} value={plan.Id}>
                                            {plan.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Population</InputLabel>
                                <Select
                                    value={schedulingSelectedPopulation}
                                    label="Population"
                                    name="Population"
                                    onChange={(e) => setSchedulingSelectedPopulation(parseInt(e.target.value as any))}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="0">
                                        All
                                    </MenuItem>
                                    {populations.map((pop) => (
                                        <MenuItem key={pop.PopulationNumber} value={pop.PopulationNumber}>
                                            {pop.PopulationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={schedulingSelectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setSchedulingSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox checked={schedulingShowInActiveProviders} onChange={(e) => toggleProviderHandler(e.target.checked)} />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={schedulingSelectedTeam}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setSchedulingSelectedTeam(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={schedulingShowInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Scheduling Staff</InputLabel>
                                <Select
                                    value={schedulingSelectedStaff}
                                    label="Scheduling Staff"
                                    name="Scheduling Staff"
                                    onChange={(e) => setSchedulingSelectedStaff(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={schedulingShowInActiveStaffs} onChange={(e) => toggleStaffHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredStaffs.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default SchedulingsSummary;
