import {
    Card,
    CardContent,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { parentUrl } from '../../../../utils/constants';
import { localDateFormat } from '../../../../utils/timeFormat';
import { apiString as API_URL } from '../../../../utils/constants';
import { getRequestType } from '../../../../store/request.slice';
import WidgetLoading from '../../Shared/WidgetLoading';
import { copyObject } from '../../../../utils/common';
import Authorization from '../../../../utils/Authorization';
import { Request } from '../../../../Models/Requests/Request.model';
import { IRequestQuestionType } from '../../../Requests/interfaces';
import { REQUEST_API } from '../../../../store/requests.slice';

type mappedRequestQuestionTypeOption = {
    value: string;
    label: string;
    subRequestTypes?: IRequestQuestionType[];
    isNotEncounterable?: boolean;
    Zcode: string;
};

const AgedRequests = () => {
    const dispatch = useDispatch();
    const auth = new Authorization();
    const showProviders = auth.Role === 'CEO' || auth.Role === 'Central Operation' ? true : false;
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [selectedProvider, setSelectedProvider] = useState(auth.UserId || 'All');
    const [requestTypeOptions, setRequestTypeOptions] = useState<mappedRequestQuestionTypeOption[]>([]);
    const [requestType, setRequestType] = useState('0');
    const [filterBy, setFilterBy] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const [agedRequests, setAgedRequests] = useState([]);
    const [showInActiveProvider, setShowInActiveProvider] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const copyProviders = copyObject(activeProviders);
    const filteredProviders = (showInActiveProvider ? copyProviders : copyProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const timePeriods = [
        { label: '0-30 Days', value: '1' },
        { label: '31-60 Days', value: '2' },
        { label: '60+ Days', value: '3' },
    ];

    const handleClick = (event) => {
        setShow(!show);
    };

    const fetchRequestTypeOptions = async () => {
        const deprecatedTypes = ['Others'];
        const response = await axios.get(`${REQUEST_API}/GetRequestTypesList`);
        if (response?.data) {
            const requestTypes: mappedRequestQuestionTypeOption[] = (response.data as IRequestQuestionType[])
                .map(({ Id, RequestTypeLabel, ZCode: Zcode, SubRequestTypes, IsNotEncounterable }) => {
                    return {
                        value: Id,
                        subRequestTypes: SubRequestTypes,
                        label: RequestTypeLabel,
                        isNotEncounterable: IsNotEncounterable,
                        Zcode: Zcode,
                    };
                })
                .filter(({ label }) => !deprecatedTypes.includes(label))
                .sort((a, b) => a.label.localeCompare(b.label));
            setRequestTypeOptions(requestTypes);
        }
    };

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_URL}/request/getagedrequests?requestTypeId=${requestType}&filterBy=${filterBy}&providerId=${selectedProvider}&includeInActiveProviders=${showInActiveProvider}`
                );
                setAgedRequests(response.data);
                setIsLoading(false);
            } catch (error) {
                setAgedRequests([]);
                console.log(error);
                setIsLoading(false);
            }
        };
        changeFilter();
    }, [selectedProvider, requestType, filterBy, showInActiveProvider]);

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/member-requests`, '_blank');
    };

    useEffect(() => {
        dispatch(getRequestType());
    }, [dispatch]);

    useEffect(() => {
        fetchRequestTypeOptions();
    }, []);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProvider(isChecked);
        setSelectedProvider('All');
    };

    const getRequestTypeLabel = (request: Request) => {
        if (request?.SubRequestTypeLabel?.length > 0) return request.SubRequestTypeLabel;
        if (request?.RequestTypeLabel?.length > 0) return request.RequestTypeLabel;
        return Boolean(request?.Type) ? request.Type : 'Unknown Request Type';
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid item xs={12} textAlign="center">
                    <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                        Aged Requests
                    </Typography>
                    <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                        &#40;{agedRequests.length}&#41;
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="caption">Number of request aging for filtered days</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Stack direction={'row'} marginTop={2} alignItems="center" spacing={2}>
                    {showProviders && (
                        <Grid item xs={3}>
                            <FormControl className="width-100p">
                                <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filteredProviders.find((provider) => provider.Id === selectedProvider)?.Id || 'All'}
                                    size="small"
                                    label="Provider"
                                    onChange={(e) => {
                                        setSelectedProvider(e.target.value as string);
                                    }}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProvider} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((provider) => {
                                        return (
                                            <MenuItem
                                                key={provider.Id}
                                                value={provider.Id}
                                                sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                            >
                                                {provider.LastName + ', ' + provider.FirstName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <FormControl className="width-100p">
                            <InputLabel id="demo-simple-select-label">Request Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={requestType || ''}
                                label="Request Type"
                                onChange={(e) => {
                                    setRequestType(e.target.value);
                                }}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                            >
                                <MenuItem value={'0'}>All</MenuItem>

                                {Object.entries(requestTypeOptions).map(([key, value]) => {
                                    return (
                                        <MenuItem key={key} value={value.value}>
                                            {`${value.label}`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ToggleButtonGroup
                            value={filterBy}
                            exclusive
                            size="small"
                            onChange={(event: React.MouseEvent<HTMLElement>, newPeriod: string | null) => setFilterBy(newPeriod)}
                        >
                            {timePeriods.map((period) => {
                                return (
                                    <ToggleButton color="primary" key={period.value} value={period.value}>
                                        {period.label}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    </Grid>
                </Stack>

                <Grid container spacing={2} marginTop={2} height="52vh" sx={{ overflowY: 'scroll', position: 'relative' }}>
                    {isLoading && <WidgetLoading />}
                    {agedRequests.length > 0 ? (
                        agedRequests.map((req) => {
                            return (
                                <Grid key={req.Id} item xs={6}>
                                    <Card
                                        sx={{
                                            ':hover': {
                                                cursor: 'pointer',
                                                boxShadow: 10,
                                                border: 0,
                                            },
                                            boxShadow: 0,
                                        }}
                                        onClick={() => openDocumentHandler(req.RequestFor.Id)}
                                        variant="outlined"
                                    >
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-evenly' }}>
                                            <Grid position={'relative'} container spacing={2}>
                                                <Grid item xs={5}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Member
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {req.RequestFor?.Name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Type
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {getRequestTypeLabel(req)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} textAlign="right">
                                                    <Chip
                                                        size="small"
                                                        variant={'filled'}
                                                        color={req.Status === 1 ? 'primary' : 'warning'}
                                                        label={req.Status === 1 ? 'NEW' : 'IN-PROGRESS'}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Assignee
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {req.RequestFrom?.Name}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Created Date
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {localDateFormat(new Date(req.CreatedOn))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} textAlign="right" paddingTop={'0!important'}>
                                                    <Chip
                                                        size="small"
                                                        variant={'filled'}
                                                        color={req.Priority === 1 ? 'error' : req.Priority === 2 ? 'warning' : 'success'}
                                                        label={req.Priority === 1 ? 'HIGH' : req.Priority === 2 ? 'MEDIUM' : 'LOW'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography color="text.secondary" variant="body2">
                                                        Message
                                                    </Typography>
                                                    <Typography fontWeight={'bold'} variant="body2">
                                                        {req.Message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6">No Matching Records</Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default AgedRequests;
