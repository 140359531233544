import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { fetchMemberDemoGraphicInfo } from './memberDetail.slice';
import { fetchProvider, setIsLoading, setShowMessage } from './shared.slice';
import { EncounterList } from '../Models/Encounters/EncounterList';
import { MemberEncounterDraft } from '../Models/Member/MemberEncounterDraft.model';
import { DiagnosisDraft } from '../Models/DocumentEngagements/DiagnosisDraft.model';
import { convertEncountersToList, getICD10DiagnosisByCode, setDefaultDiagnosis } from './encounter.slice';
import { setShowEditResubmitModal } from './claimResubmission.slice';
import { MemberDefaultDiagnosis } from '../Models/DocumentEngagements/MemberDefaultDiagnosis.model';
import { copyObject, getCPTUserRole } from '../utils/common';
import CPTCodeGroup from '../Models/Shared/CPTCodeGroup';
import moment from 'moment-timezone';
import { MemberBasicInfo } from '../Models/Member/MemberBasicInfo.model';
import { MessageAlert } from '../Models/Shared/MessageAlert.model';

const procedureSignOffEncounterSlice = createSlice({
    name: 'procedureSignOffEncounter',
    initialState: {
        dateOfService: new Date(),
        draftEncounterList: new EncounterList(),
        encounterChange: false,
        encounterDate: '',
        encounterStatus: '',
        showProcedureSignOffModal: false,
        error: new MessageAlert(),
    },
    reducers: {
        _setDateOfService(state, action) {
            state.dateOfService = action.payload;
        },
        _setDraftEncounterList(state, action) {
            state.draftEncounterList = action.payload;
        },
        _setEncounterChange(state, action) {
            state.encounterChange = action.payload;
        },
        _setEncounterDate(state, action) {
            state.encounterDate = action.payload;
        },
        _setEncounterStatus(state, action) {
            state.encounterStatus = action.payload;
        },
        _setShowProcedureSignOffModal(state, action) {
            state.showProcedureSignOffModal = action.payload;
        },
        _setError(state, action) {
            state.error = action.payload;
        },
    },
});

const { _setDateOfService, _setDraftEncounterList, _setEncounterChange, _setEncounterDate, _setEncounterStatus, _setShowProcedureSignOffModal, _setError } =
    procedureSignOffEncounterSlice.actions;

export const setClaimError =
    (error?: MessageAlert): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setError(error));
    };

export const setDateOfService =
    (dateOfService: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setDateOfService(dateOfService));
    };

export const fetchEncountersByDate =
    (encounterStatus: string, memberId: string, encounterDate: string, callback: Function = null): AppThunk =>
    async (dispatch) => {
        if (encounterDate.indexOf(':') > -1) {
            encounterDate = encounterDate.split(' ')[0];
        }

        const response = await axios.get(
            `${API_URL}/encounter/getmemberencountersbydate?memberId=${memberId}&date=${encounterDate}&encounterStatus=${encounterStatus}`
        );
        if (response.data.length > 0) {
            dispatch(fetchProvider(response.data[0].CreatedBy.Id));
            dispatch(setDateOfService(response.data[0].DateOfService));
            let encounterList = await convertEncountersToEncounterList(response.data);
            encounterList = convertEncountersToList(encounterList);
            dispatch(setDraftEncounterList(encounterList));
        }
        dispatch(fetchMemberDemoGraphicInfo(memberId, callback));
    };

export const getICD10DiagnosisByCategoryUpdateList =
    (categoryId: string, encounterIndex: number, diagnosisIndex: number, bindResponsePropName: string, isDefaultDiagnosis = false): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/member/geticd10diagnosisbycategory`, { params: { categoryId } });
        if (isDefaultDiagnosis) {
            let memberDefaultDiagnosis = copyObject(getState().encounter.memberDefaultDiagnosis) as MemberDefaultDiagnosis;
            memberDefaultDiagnosis.DefaultDiagnosis[bindResponsePropName] = response.data;

            dispatch(setDefaultDiagnosis(memberDefaultDiagnosis));
        } else {
            let encounterList = copyObject(getState().procedureSignOffEncounter.draftEncounterList);
            encounterList.Encounters[encounterIndex].DiagnosisList[diagnosisIndex][bindResponsePropName] = response.data;

            dispatch(_setDraftEncounterList(encounterList));
        }
    };

export const setDraftEncounterList =
    (encounterList: EncounterList): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setDraftEncounterList(encounterList));
    };

export const setMemberEncounterDraft = async (draftEncounter: MemberEncounterDraft, encounter: any) => {
    draftEncounter.Id = encounter.Id;
    draftEncounter.Units = encounter.Units;
    draftEncounter.CptCodeId = encounter.CPTcode?.Id;
    draftEncounter.CptCode = encounter.CPTcode?.Code;
    draftEncounter.CategoryGroupId = encounter.CategoryGroupId;
    draftEncounter.MemberId = encounter.MemberId;
    draftEncounter.EncounterPlace = encounter.EncounterPlace;
    draftEncounter.ServiceDate = encounter.DateOfService;
    draftEncounter.TraceId = encounter.TraceId;
};

export const setMemberEncounterDraftDiagnosis = async (
    draftEncounter: MemberEncounterDraft,
    encounter: any,
    diagnosisName: string,
    diagnosisPointerName: string,
    diagnosisList: string
) => {
    if (encounter[diagnosisList] != null && encounter[diagnosisList].length > 0 && encounter[diagnosisList][0] != null) {
        draftEncounter[diagnosisName] = new DiagnosisDraft();
        draftEncounter[diagnosisName].Diagnosis = encounter[diagnosisList][0].Code + ' ' + encounter[diagnosisList][0].Name;
        draftEncounter[diagnosisName].DiagnosisPointer = encounter[diagnosisPointerName];

        if (encounter[diagnosisList].length >= 1) {
            draftEncounter[diagnosisName].FirstDiagnosis = encounter[diagnosisList][1];
            draftEncounter[diagnosisName].DiagnosisFirstList = await getICD10DiagnosisByCategory(encounter[diagnosisList][0].Code);
        }

        if (encounter[diagnosisList].length >= 2) {
            draftEncounter[diagnosisName].SecondDiagnosis = encounter[diagnosisList][2];
            draftEncounter[diagnosisName].DiagnosisSecondList = await getICD10DiagnosisByCategory(encounter[diagnosisList][1].Code);
        }

        if (encounter[diagnosisList].length >= 3) {
            draftEncounter[diagnosisName].ThirdDiagnosis = encounter[diagnosisList][3];
            draftEncounter[diagnosisName].DiagnosisThirdList = await getICD10DiagnosisByCategory(encounter[diagnosisList][2].Code);
        }

        if (encounter[diagnosisList].length >= 4) {
            draftEncounter[diagnosisName].FourthDiagnosis = encounter[diagnosisList][4];
            draftEncounter[diagnosisName].DiagnosisFourthList = await getICD10DiagnosisByCategory(encounter[diagnosisList][3].Code);
        }
    }
};

export const updateList = async (
    encounter: any,
    draft: MemberEncounterDraft,
    diagnosisDraft: DiagnosisDraft,
    diagnosisPointerName: string,
    diagnosisList: string
) => {
    encounter[diagnosisList] = [];
    if (diagnosisDraft && diagnosisDraft.DiagnosisPointer) {
        const diagnosis = await getICD10DiagnosisByCode(diagnosisDraft.Diagnosis.split(' ')[0]);
        encounter[diagnosisList].push(diagnosis);

        if (diagnosisDraft.FirstDiagnosis) {
            encounter[diagnosisList].push(diagnosisDraft.FirstDiagnosis);
        }
        if (diagnosisDraft.SecondDiagnosis) {
            encounter[diagnosisList].push(diagnosisDraft.SecondDiagnosis);
        }
        if (diagnosisDraft.ThirdDiagnosis) {
            encounter[diagnosisList].push(diagnosisDraft.ThirdDiagnosis);
        }
        if (diagnosisDraft.FourthDiagnosis) {
            encounter[diagnosisList].push(diagnosisDraft.FourthDiagnosis);
        }

        encounter[diagnosisPointerName] = diagnosisDraft.DiagnosisPointer;
    }
};

export const convertDraftToEncounters = async (
    encounterList: EncounterList,
    status: number,
    cptCodeCategories: CPTCodeGroup[],
    provider: any,
    selectedMember: any,
    dateOfService: Date,
    memberBasicInfo: MemberBasicInfo,
    selectedRole: string,
    claimNumber: string | null
) => {
    let encounters = [];

    let placeOfService = 0;
    for (let draft of encounterList.Encounters) {
        if (draft.EncounterPlace !== 0) {
            placeOfService = draft.EncounterPlace;
        }
    }

    for (let draft of encounterList.Encounters) {
        let categoryCodes = cptCodeCategories
            .find((c) => c.Id === draft.CategoryGroupId)
            ?.CPTRoleCodes.find((c) => c.Role === getCPTUserRole(provider.Role.RoleName) || (selectedMember?.IsInHra && c.Role === 'HRA'))?.Codes;
        console.log(categoryCodes);

        let checkHRACategoryCode = cptCodeCategories.find((c) => c.Id === draft.CategoryGroupId);
        if (checkHRACategoryCode.GroupName === 'HRA') {
            categoryCodes = checkHRACategoryCode?.CPTRoleCodes.find((c) => c.Role === 'HRA')?.Codes;
        }

        var encounter = {
            Id: draft.Id,
            MemberId: selectedMember.Id,
            EncounterPlace: placeOfService,
            CategoryGroupId: draft.CategoryGroupId,
            Units: draft.Units,
            CPTcode: categoryCodes.find((c) => c.Id === draft.CptCodeId),
            ServiceDate: moment(dateOfService).tz('America/Phoenix').format('MM/DD/yyyy hh:mm'),
            EncounterStatus: status,
            TraceId: claimNumber,
        };

        if (draft.DiagnosisList.length >= 1) {
            await updateList(encounter, draft, draft.DiagnosisList[0], 'PrimaryDiagnosisPointer', 'PrimaryDiagnosisList');
        }
        if (draft.DiagnosisList.length >= 2) {
            await updateList(encounter, draft, draft.DiagnosisList[1], 'SecondaryDiagnosisPointer', 'SecondaryDiagnosisList');
        }
        if (draft.DiagnosisList.length >= 3) {
            await updateList(encounter, draft, draft.DiagnosisList[2], 'TertiaryDiagnosisPointer', 'TertiaryDiagnosisList');
        }
        if (draft.DiagnosisList.length >= 4) {
            await updateList(encounter, draft, draft.DiagnosisList[3], 'QuaternaryDiagnosisPointer', 'QuaternaryDiagnosisList');
        }
        encounters.push(encounter);
    }
    return encounters;
};

export const updateEncounters =
    (encounterList: EncounterList, status: number, claimNumber: string | null, isResubmit: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        let encounters = await convertDraftToEncounters(
            encounterList,
            status,
            getState().encounter.cptCodeCategories,
            getState().shared.currentProvider,
            getState().memberDetail.selectedMember,
            getState().procedureSignOffEncounter.dateOfService,
            getState().memberDetail.memberBasicInfo,
            getState().assessment.selectedRole,
            claimNumber
        );

        try {
            await axios.put(`${API_URL}/encounter/updateencounters`, {
                MemberEncounter: encounters,
                Claim: getState().claimResubmission.claim,
                Resubmit: isResubmit,
            });
            dispatch(setShowProcedureSignOffModal(false));
            dispatch(setShowEditResubmitModal(false, false));
            dispatch(setIsLoading(false));
            dispatch(setShowMessage(true, 'Successfully Updated', 'success'));
            dispatch(setEncounterChange(true));
        } catch (error) {
            dispatch(setShowMessage(true, error.response.data.error, 'error'));
            dispatch(setShowEditResubmitModal(false, false));
            dispatch(setIsLoading(false));
        }
    };

export const convertEncountersToEncounterList = async (encounters: any) => {
    let encounterList = new EncounterList();
    for (let encounter of encounters) {
        var draftEncounter = new MemberEncounterDraft();
        setMemberEncounterDraft(draftEncounter, encounter);

        await setMemberEncounterDraftDiagnosis(draftEncounter, encounter, 'PrimaryDiagnosis', 'PrimaryDiagnosisPointer', 'PrimaryDiagnosisList');
        await setMemberEncounterDraftDiagnosis(draftEncounter, encounter, 'SecondaryDiagnosis', 'SecondaryDiagnosisPointer', 'SecondaryDiagnosisList');
        await setMemberEncounterDraftDiagnosis(draftEncounter, encounter, 'TertiaryDiagnosis', 'TertiaryDiagnosisPointer', 'TertiaryDiagnosisList');
        await setMemberEncounterDraftDiagnosis(draftEncounter, encounter, 'QuaternaryDiagnosis', 'QuaternaryDiagnosisPointer', 'QuaternaryDiagnosisList');

        encounterList.Encounters.push(draftEncounter);
    }
    return encounterList;
};

export const getICD10DiagnosisByCategory = async (categoryId: string) => {
    const response = await axios.get(`${API_URL}/member/geticd10diagnosisbycategory`, { params: { categoryId } });
    return response.data;
};

export const setEncounterDate =
    (encounterDate: string): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterDate(encounterDate));
    };

export const setEncounterStatus =
    (encounterStatus: string): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterStatus(encounterStatus));
    };

export const setShowProcedureSignOffModal =
    (show: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowProcedureSignOffModal(show));
    };

export const setEncounterChange =
    (show: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setEncounterChange(show));
    };

export const getTop = (list: EncounterList) => {
    if (list && list.Encounters.length >= 1) {
        let value = list?.Encounters.length * 335;
        if (list?.Encounters.length > 8) {
            value = list?.Encounters.length * 300;
        }
        return value > 600 ? value : 600;
    } else {
        return 500;
    }
};

export const prepareICD10Hierarchy =
    (code: string, index: number, dxIndex: number): AppThunk =>
    async (dispatch, getState) => {
        axios.get(`${API_URL}/encounter/prepareicd10hierarchy`, { params: { code } }).then((response) => {
            let data = copyObject(getState().procedureSignOffEncounter.draftEncounterList);
            data.Encounters[index].DiagnosisList[dxIndex] = response.data;
            dispatch(setDraftEncounterList(data));
        });
    };

export default procedureSignOffEncounterSlice.reducer;
