export enum NumberOfDrinksEnum {
    ZeroToTwoDescription = '0-2',
    ZeroToTwo = 1,
    ThreeToFourDescription = '3-4',
    ThreeToFour = 2,
    FiveToSixDescription = '5-6',
    FiveToSix = 3,
    SevenToNineDescription = '7-9',
    SevenToNine = 4,
    TenOrMoreDescription = '10+',
    TenOrMore = 5,
}
