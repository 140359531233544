export default function timeFormat(date) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).replace(/^0(?:0:0?)?/, '');
}

export function localDateFormat(date) {
    return date.toLocaleDateString();
}

export function localTimeFormat(date) {
    return date.toLocaleTimeString();
}

export function localDateAndTimeFormat(date) {
    return localDateFormat(date) + ' - ' + localTimeFormat(date);
}

export function dateFormatter(date: Date) {
    return (
        (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
        '/' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        '/' +
        date.getFullYear()
    );
}

// Returns a string in the format of "hh:mm AM/PM"
export const formatAppointmentTime = (date: Date) => {
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    let ampm: string = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr: string = minutes < 10 ? '0' + minutes.toString() : minutes.toString();

    const strTime: string = hours + ':' + minutesStr + ' ' + ampm;
    return strTime;
};

// Returns a string in the format of "mm/dd/yyyy"
export const formatDateMMDDYYYY = (date: Date): string => {
    if (!(date instanceof Date)) {
        throw new Error('Invalid date object');
    }

    let dd: string | number = date.getDate();
    let mm: string | number = date.getMonth() + 1; // January is 0!
    const yyyy: number = date.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    return `${mm}/${dd}/${yyyy}`;
};

export function formatDateWithMonthName(date) {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
}
