import { useState, useEffect } from 'react';

import {
    AppBar,
    Box,
    Button,
    Collapse,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import MemberHeader from './MemberHeader/MemberHeader';
import {
    fetchEthnicities,
    fetchPreferredPronouns,
    fetchRaces,
    getMemberByMemberId,
    setClosedStatusModalIsOpen,
    setMemberAccessMessage,
    setMemberMenuIsOpen,
    setShowDetails,
    setShowMemberAccess,
    fetchPreviousChartsByMemberId,
    fetchMemberNotesByMemberId,
    setShowNotes,
} from '../../store/memberDemographicsDetails.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { fetchHealthPlans, fetchInsuranceTypes, fetchMemberDemoGraphicInfo, fetchMemberHealthPlans } from '../../store/memberDetail.slice';

import WarningIcon from '@mui/icons-material/Warning';
import MemberInformation from './MemberInformation/MemberInformation';

import Authorization from '../../utils/Authorization';

import { MemberStatusEnum } from '../../Enum/MemberStatusEnum';

import { insertTimeline } from '../../store/timeline.slice';
import { TimelineTypeEnum } from '../../Enum/TimelineTypeEnum';
import Loading from '../Shared/Loading';

import { useLocation, useParams, Outlet } from 'react-router';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const MemberAccessDialog = (props) => {
    const { closeMemberAccessHandler, message, messageIsValid, setMessage, showMemberAccess, submitMemberAccessHandler } = props;
    const dispatch = useDispatch();

    return (
        <Dialog open={showMemberAccess} onClose={closeMemberAccessHandler} fullWidth maxWidth="sm">
            <DialogTitle>Member Access</DialogTitle>
            <DialogContent>
                <Typography variant="body1">Please provide your reason for accessing this member record.</Typography>
                <FormControl fullWidth={true}>
                    <TextField
                        fullWidth
                        error={!messageIsValid}
                        multiline
                        required
                        label="Reason"
                        sx={{ marginY: 2 }}
                        helperText={!messageIsValid ? 'Please enter a reason for accessing this member record.' : ''}
                        variant="outlined"
                        rows={6}
                        value={message}
                        name="message"
                        onChange={(e) => dispatch(setMessage(e.target.value))}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button className="button-120" variant="outlined" color="primary" onClick={closeMemberAccessHandler}>
                    Cancel
                </Button>
                <Button className="button-120" variant="contained" color="primary" onClick={submitMemberAccessHandler}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ClosedStatusConfirmationDialog = (props) => {
    const { chartDetails, onClose, open, onConfirm } = props;
    const clonedHRA = chartDetails?.Status === MemberStatusEnum.HRAInReview && chartDetails?.HasCloned;
    const clonedFUHFUMINPROGRESS = chartDetails?.Status === MemberStatusEnum.FUHFUMInProgress && chartDetails?.HasCloned;
    const status = clonedHRA ? 'HRA In Review and an Active record' : clonedFUHFUMINPROGRESS ? 'FUH/FUM In Progress and an Active record' : 'Closed';

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
    };

    const cancelConfirmation = () => {
        window.close();
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: 'error.main' }} color={'white'}>
                Confirmation
            </DialogTitle>
            <DialogContent>
                <Typography id="alert-dialog-description" p={2} marginTop={2}>
                    This record status is <b>{status}</b>. Are you sure you want to continue?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className="button-120" variant="outlined" color="error" onClick={cancelConfirmation}>
                    No
                </Button>
                <Button className="button-120" variant="contained" color="error" onClick={onConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const MemberDemographicsDetails = (props) => {
    //
    const params = useParams<any>();
    const location = useLocation();
    const path = location.pathname.split('/').pop();

    const memberId = props.memberId || params.memberId;

    const providerNavbarHeight = document.getElementById('provider-navbar')?.offsetHeight;
    const memberHeaderHeight = document.getElementById('member-header')?.offsetHeight;
    const top = providerNavbarHeight + memberHeaderHeight;

    const auth = new Authorization();
    const {
        chartDetails,
        closedStatusModalIsOpen,
        hasCriticalNotes,

        memberAccessMessage,
        memberMenuIsOpen,
        showDetails,
        showMemberAccess,
    } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const dispatch = useDispatch();

    const [openOnboarding, setOpenOnboarding] = useState(false);
    const [messageIsValid, setMessageIsValid] = useState(true);

    const openMemberAccessHandler = () => {
        setMessageIsValid(true);
        dispatch(setShowMemberAccess(true));
    };

    const closeMemberAccessHandler = () => {
        dispatch(setMemberAccessMessage(''));
        dispatch(setShowMemberAccess(false));
    };

    const submitMemberAccessHandler = () => {
        if (memberAccessMessage.length === 0) {
            setMessageIsValid(false);
            return;
        } else {
            setMessageIsValid(true);
            dispatch(insertTimeline(memberId, memberAccessMessage, TimelineTypeEnum.ClosedMemberAccessed));
            closeMemberAccessHandler();
            dispatch(setClosedStatusModalIsOpen(false));
        }
    };

    const collapseMenuHandler = () => {
        dispatch(setMemberMenuIsOpen(!memberMenuIsOpen));
    };

    const memberDetailPages = [
        { name: 'Performance', isVisible: true, path: 'performance' },
        {
            name: 'Assessments',
            isVisible: auth.isInPermission('View member assessment') || auth.isInPermission('Edit member assessment data'),
            path: 'assessments',
        },
        { name: 'Integrated Care Plan', isVisible: true, path: 'integrated-care-plan' },
        {
            name: 'Scheduler',
            isVisible: auth.isInPermission('View member appointment') || auth.isInPermission('Add / Edit member appointment'),
            path: 'scheduling',
        },
        {
            name: 'Documents',
            isVisible: auth.isInPermission('View member documents') || auth.isInPermission('Manage member document'),
            path: 'documents',
        },
        { name: 'Timeline', isVisible: auth.isInPermission('View member Timeline'), path: 'timeline' },
        {
            name: 'Engagement',
            isVisible:
                (auth.isInPermission('View engagements') || auth.isInPermission('Add / Edit engagements')) &&
                chartDetails.Status !== MemberStatusEnum.Undetermined &&
                chartDetails.Status !== MemberStatusEnum.Scheduled,
            path: 'document-engagement',
        },
        {
            name: 'Education',
            isVisible: auth.isInPermission('View member education materials') || auth.isInPermission('Assign education materials to member'),
            path: 'education',
        },
        { name: 'Vital Signs', isVisible: auth.isInPermission('View member vital signs'), path: 'vital-signs' },
        { name: 'Requests', isVisible: auth.isInPermission('View the requests'), path: 'member-requests' },
        {
            name: 'Medication Management',
            isVisible: auth.isInPermission('View member assessment') || auth.isInPermission('Edit member assessment data'),
            path: 'medication-management',
        },
        { name: 'Clinical Data', isVisible: auth.isInPermission('Edit member clinical data'), path: 'clinical-data' },
        { name: 'Transitional Care', isVisible: true, path: 'transitional-care' },
        { name: 'HIE', isVisible: true, path: 'hie' },
        { name: 'Budget Form', isVisible: true, path: 'budget-form' },
        {
            name: 'Onboarding',
            isVisible:
                ((auth.isInPermission('View engagements') || auth.isInPermission('Add / Edit engagements')) &&
                    chartDetails.Status === MemberStatusEnum.Undetermined) ||
                chartDetails.Status === MemberStatusEnum.Scheduled,
            path: 'onboarding-engagement',
        },
    ];

    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getMemberByMemberId(memberId));
        dispatch(fetchMemberDemoGraphicInfo(memberId));
        dispatch(fetchHealthPlans());
        dispatch(fetchInsuranceTypes());
        dispatch(fetchPreferredPronouns());
        dispatch(fetchEthnicities());
        dispatch(fetchRaces());
        dispatch(fetchMemberHealthPlans(memberId));
        dispatch(fetchPreviousChartsByMemberId(memberId));
        dispatch(fetchMemberNotesByMemberId(memberId));
    }, [dispatch, memberId]);

    return (
        <>
            <AppBar id="member-header" position="fixed" sx={{ backgroundColor: 'white', color: 'rgba(0,0,0,0.87)', height: '64px', top: providerNavbarHeight }}>
                <Toolbar>
                    <MemberHeader
                        memberId={memberId}
                        chartDetails={chartDetails}
                        showDetails={false}
                        hideDetailsHandler={() => {
                            dispatch(setShowDetails(!showDetails));
                        }}
                    />
                </Toolbar>
                {hasCriticalNotes ? (
                    <Stack
                        direction="row"
                        width={'100%'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ background: '#ed6c02', cursor: 'pointer', height: '36px' }}
                        spacing={2}
                        p={1}
                        onClick={() => dispatch(setShowNotes(true))}
                    >
                        <WarningIcon sx={{ fontSize: '1.5rem', color: 'white' }} />
                        <Typography variant="body1" color="white">
                            Warning - Please read before interacting with Member
                        </Typography>
                    </Stack>
                ) : null}
            </AppBar>

            <Box sx={{ display: 'flex', marginTop: '64px' }}>
                {isLoading && <Loading message="Loading..." />}
                <ClosedStatusConfirmationDialog
                    chartDetails={chartDetails}
                    open={closedStatusModalIsOpen}
                    onClose={() => {
                        dispatch(setClosedStatusModalIsOpen(false));
                    }}
                    onConfirm={openMemberAccessHandler}
                />
                <MemberAccessDialog
                    closeMemberAccessHandler={closeMemberAccessHandler}
                    message={memberAccessMessage}
                    messageIsValid={messageIsValid}
                    setMessage={setMemberAccessMessage}
                    showMemberAccess={showMemberAccess}
                    submitMemberAccessHandler={submitMemberAccessHandler}
                />
                <CssBaseline />

                <Drawer
                    id="member-details-drawer"
                    sx={{
                        width: memberMenuIsOpen ? drawerWidth : 0,
                        '& .MuiDrawer-paper': {
                            width: memberMenuIsOpen ? drawerWidth : 0,
                            boxSizing: 'border-box',
                            top: top,
                            zIndex: 100,
                            height: `calc(100vh - ${top}px)`,
                            overflowY: 'auto',
                            marginTop: hasCriticalNotes ? '28px' : '',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <Divider />
                    <List
                        sx={{
                            [`& .MuiListItemButton-root.Mui-selected`]: {
                                backgroundColor: 'primary.main',
                                color: '#F7F7F7',
                                ':hover': {
                                    backgroundColor: 'primary.main',
                                    color: '#F7F7F7',
                                },
                            },
                        }}
                    >
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton selected={path === 'member-profile'} divider component={Link} to={`member-profile`} sx={{ minHeight: 48, px: 2.5 }}>
                                <ListItemText primary="Member Profile" />
                            </ListItemButton>
                        </ListItem>
                        {memberDetailPages
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((page, index) => {
                                if (page.name === 'Engagement' && page.isVisible) {
                                    return (
                                        <ListItem key={`Engagement_${index}`} disablePadding sx={page.isVisible ? { display: 'block' } : { display: 'none' }}>
                                            <ListItemButton
                                                selected={path === 'document-engagement'}
                                                divider
                                                component={Link}
                                                to={'document-engagement'}
                                                onClick={() => {
                                                    setOpenOnboarding(!openOnboarding);
                                                }}
                                            >
                                                <ListItemText primary={'Engagement'} />
                                                {openOnboarding ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={openOnboarding} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <ListItemButton
                                                        sx={{ pl: 4 }}
                                                        selected={path === 'onboarding-engagement'}
                                                        component={Link}
                                                        to={'onboarding-engagement'}
                                                    >
                                                        <ListItemText primary={'Onboarding'} />
                                                    </ListItemButton>
                                                </List>
                                            </Collapse>
                                        </ListItem>
                                    );
                                } else {
                                    return (
                                        <ListItem key={`${page.name}_${index}`} disablePadding sx={page.isVisible ? { display: 'block' } : { display: 'none' }}>
                                            <ListItemButton selected={path === page.path} divider component={Link} to={`${page.path}`}>
                                                <ListItemText primary={page.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                }
                            })}
                    </List>
                    <Divider />
                </Drawer>

                <Button
                    sx={{
                        p: 0,
                        m: 0,
                        borderRadius: 0,
                        minWidth: '28px',
                        height: `calc(100vh - ${top}px)`,
                        color: 'text.primary',
                        backgroundColor: 'rgb(230,230,230)',
                        position: 'sticky',
                        top: top,
                        '&:hover': { backgroundColor: 'rgb(150, 150, 150)', color: 'rgb(255,255,255)' },
                    }}
                    onClick={collapseMenuHandler}
                >
                    {memberMenuIsOpen ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                </Button>

                <Box component="main" sx={{ flexGrow: 1, paddingTop: 1, marginTop: hasCriticalNotes ? '28px' : '' }}>
                    <Collapse in={showDetails} sx={{ marginX: 0 }}>
                        <MemberInformation chartDetails={chartDetails} memberId={memberId} />
                    </Collapse>
                    {<Outlet />}
                </Box>
            </Box>
        </>
    );
};

export default MemberDemographicsDetails;
