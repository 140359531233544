import { NumberOfDrinksEnum } from '../../Enum/NumberOfDrinksEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';
import { AuditAssessment } from './AuditAssessment.model';
import { DrugAssessment } from './DrugAssessment.model';
import { TobaccoAssessment } from './TobaccoAssessment.model';

export default interface ITobaccoAndDrugAssessment extends BaseEntity {
    MemberId: string;
    TobaccoAssessment?: TobaccoAssessment;
    DrugAssessment?: DrugAssessment;
    HasFiveOrMoreDrinksPerDayInPastYear?: boolean;
    Status: StatusEnum;
    Score?: number;
}

export class TobaccoAndDrugAssessment implements ITobaccoAndDrugAssessment {
    Id: string;
    MemberId: string;
    TobaccoAssessment?: TobaccoAssessment;
    DrugAssessment?: DrugAssessment;
    AuditAssessment?: AuditAssessment;
    HasFiveOrMoreDrinksPerDayInPastYear?: boolean;
    NumberOfFiveOrMoreDrinksInPastYear?: NumberOfDrinksEnum;
    Status: StatusEnum;
    Score?: number;

    constructor() {
        this.MemberId = '';
        this.TobaccoAssessment = new TobaccoAssessment();
        this.DrugAssessment = new DrugAssessment();
        this.AuditAssessment = new AuditAssessment();
    }
}
