import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { changeDocumentCategory } from '../../../store/memberDocuments.slice';
import { useDispatch } from 'react-redux';

const MoveDocumentDialog = (props) => {
    const { categoryId, documentId, documentsTree, memberId, onClose, open } = props;
    const dispatch = useDispatch();

    const [newCategoryId, setNewCategoryId] = useState('0');

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableEnforceFocus fullWidth>
            <Paper
                sx={{
                    padding: '16px',
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h6" component={'p'}>
                        Change Document Category
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ marginY: 2 }}>
                        <InputLabel id="demo-simple-select-label">Move To</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newCategoryId}
                            label="Move To"
                            onChange={(e) => {
                                setNewCategoryId(e.target.value);
                            }}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                        >
                            <MenuItem disabled value={'0'}>
                                Select Document Category
                            </MenuItem>
                            {documentsTree.map((doc) => {
                                return (
                                    <MenuItem key={doc.Id} value={doc.Id}>
                                        {doc.Name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={newCategoryId === '0'}
                        variant="contained"
                        className="button-120"
                        onClick={() => dispatch(changeDocumentCategory(newCategoryId, documentId, categoryId, memberId))}
                        autoFocus
                    >
                        Save
                    </Button>
                    <Button variant="outlined" className="button-120" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    );
};

export default MoveDocumentDialog;
