import RichTextEditor from '@mantine/rte';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Alert, Autocomplete, Button, Grid, Paper, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MeansOfEngagementEnum } from '../../Enum/MeansOfEngagementEnum';
import { SummaryNoteTypeEnum } from '../../Enum/SummaryNoteTypeEnum';
import { EngagementDraft } from '../../Models/DocumentEngagements/EngagementDraft.model';
import IMessageAlert from '../../Models/Shared/MessageAlert.model';
import { RootState } from '../../reducers';
import { resetAssessmentError } from '../../store/assessment.slice';
import { saveEngagementDraft } from '../../store/documentEngagement.slice';
import { getRequestZCode } from '../../store/requests.slice';
import { apiString as API_URL } from '../../utils/constants';
import ProcedureSignOff from '../Assessments/ProcedureSignOff/ProcedureSignOff';
import { IOption } from '../interfaces/IOption';
import generateBasicEngagementDraft from './generateBasicEngagementDraft';

export interface ISignOffWrapperProps {
    meansOfEngagement?: MeansOfEngagementEnum;
    wrapperDraft?: EngagementDraft;
    memberId?: string;
    requestId?: string;
    enableWarnings?: boolean;
    zcode?: string;
    notes?: string;
    finalizeSignOff?: (notes?: string) => void;
}

export interface ISessionProviderRoleData {
    Id: string;
    RoleName: string;
}

const SignOffWrapper: React.FC<ISignOffWrapperProps> = (props) => {
    const { enableWarnings, wrapperDraft, memberId, requestId, zcode, notes, finalizeSignOff } = props;
    const { selectedRequest } = useSelector((state: RootState) => state.requests);
    const [documentation, setDocumentation] = useState<string>('<p><br></p>');

    const [finish, setFinish] = useState<boolean>(false);
    const [sessionProviderRole, setSessionProviderRole] = useState<ISessionProviderRoleData>();
    const [meansOfEngagementOptions, setMeansOfEngagementOptions] = useState<IOption[]>();
    const [meansOfEngagementValue, setMeansOfEngagementValue] = useState<IOption>();
    const [meansOfEngagementInput, setMeansOfEngagementInput] = useState<string>();
    const [reasonForEngagementOptions, setreasonForEngagementOptions] = useState<IOption[]>();
    const [reasonForEngagementValue, setReasonForEngagementValue] = useState<IOption>();
    const [reasonForEngagementInput, setReasonForEngagementInput] = useState<string>();
    const [wrapperError, setWrapperError] = useState<IMessageAlert>({
        Show: false,
        Message: '',
    });

    const dispatch = useDispatch();
    const { error } = useSelector((state: RootState) => state.assessment);
    const handleSaveClick = async () => {
        let usingZcode: string = zcode;
        if (!usingZcode) {
            usingZcode = await getRequestZCode(requestId);
        }
        const draft = generateBasicEngagementDraft({
            meansOfEngagementValue: meansOfEngagementValue?.value,
            reasonForEngagementOption: reasonForEngagementValue,
            memberId,
            notes: notes ?? documentation,
            zcode: usingZcode,
            summaryNoteType: SummaryNoteTypeEnum.Request,
            assessedRoleId: sessionProviderRole?.Id,
            assessedRoleName: sessionProviderRole?.RoleName,
        });
        if (selectedRequest) {
            draft.SummaryNotes[0].ReferenceIds = [selectedRequest.Id];
        }
        dispatch(saveEngagementDraft(wrapperDraft ?? draft));
        setFinish(true);
    };

    useEffect(() => {
        // We're going to copy the assessment error and clear it so we don't have to worry about the assessment slice after this point.
        if (error?.Show) {
            setWrapperError({ ...error });
            dispatch(resetAssessmentError());
        }
    }, [error, dispatch]);

    useEffect(() => {
        setFinish(false);
        const sessionProviderRequest = axios.get(`${API_URL}/provider/GetSessionProvider`);
        sessionProviderRequest.then((response) => {
            if (response.data) {
                setSessionProviderRole({
                    Id: response.data?.ProviderId,
                    RoleName: response.data?.Role,
                });
            }
        });
        const reasonForEngagementRequest = axios.get(`${API_URL}/documentengagement/getreasonforvisit`);
        reasonForEngagementRequest.then((response) => {
            if (response.data) {
                const mappedOptions = response.data.map((x) => ({
                    label: x.Name,
                    value: x.Id,
                }));
                const firstOption = mappedOptions.find((option) => option.label === 'Community Resource') ?? mappedOptions[0];
                setReasonForEngagementValue(firstOption);
                setReasonForEngagementInput(firstOption.label);
                setreasonForEngagementOptions(mappedOptions);
            }
        });

        const meansOfEngagementRequest = axios.get(`${API_URL}/documentengagement/getmeansofengagement`);
        meansOfEngagementRequest.then((response) => {
            if (response.data) {
                const mappedOptions = Object.values(response.data).map((label, index) => ({
                    label: label as string,
                    value: (index + 1).toString(),
                }));
                const firstOption = mappedOptions[0];
                setMeansOfEngagementValue(firstOption);
                setMeansOfEngagementInput(firstOption.label);
                setMeansOfEngagementOptions(mappedOptions);
            }
        });
    }, []);

    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const documentationFields = (
        <>
            <Grid item lg={6} md={6} sm={6} xs={6}>
                {reasonForEngagementOptions && (
                    <Autocomplete
                        fullWidth
                        multiple={false}
                        disableClearable
                        getOptionLabel={(option: any) => option.label}
                        id="reason-of-engagement-autocomplete"
                        options={reasonForEngagementOptions}
                        isOptionEqualToValue={(option, value: any) => {
                            return option.value === value.value;
                        }}
                        renderInput={(params) => <TextField {...params} label="Reason for Engagement" />}
                        value={reasonForEngagementValue}
                        inputValue={reasonForEngagementInput}
                        onInputChange={(event, newInputValue) => {
                            setReasonForEngagementInput(newInputValue);
                        }}
                        onChange={(event: any, newValue: any) => {
                            if (newValue?.value) {
                                setReasonForEngagementValue(newValue);
                            } else {
                                setReasonForEngagementValue(newValue);
                            }
                        }}
                    />
                )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
                {meansOfEngagementOptions && (
                    <Autocomplete
                        fullWidth
                        multiple={false}
                        disableClearable
                        getOptionLabel={(option: any) => option.label}
                        id="means-of-engagement-autocomplete"
                        options={meansOfEngagementOptions}
                        isOptionEqualToValue={(option, value: any) => {
                            return option.value === value.value;
                        }}
                        renderInput={(params) => <TextField {...params} label="Means of Engagement" />}
                        value={meansOfEngagementValue}
                        inputValue={meansOfEngagementInput}
                        onInputChange={(event, newInputValue) => {
                            setMeansOfEngagementInput(newInputValue);
                        }}
                        onChange={(event: any, newValue: any) => {
                            if (newValue?.value) {
                                setMeansOfEngagementValue(newValue);
                            } else {
                                setMeansOfEngagementValue(newValue);
                            }
                        }}
                    />
                )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} hidden={notes !== undefined}>
                <Typography align="center" style={{ width: '100%', alignItems: 'center' }} variant="h5">
                    Documentation
                </Typography>
                <RichTextEditor
                    value={documentation}
                    onChange={setDocumentation}
                    controls={[
                        ['bold', 'italic', 'underline'],
                        ['unorderedList', 'orderedList', 'h1', 'h2', 'h3'],
                        ['sup', 'sub'],
                        ['alignLeft', 'alignCenter', 'alignRight'],
                    ]}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Tooltip title="Be sure you're finished before moving on, you can't come back to the notes on this screen after saving the draft.">
                    <Button
                        color="success"
                        startIcon={<HistoryEduIcon />}
                        variant="contained"
                        disabled={
                            !(
                                sessionProviderRole?.Id &&
                                reasonForEngagementValue?.label &&
                                reasonForEngagementValue?.value &&
                                meansOfEngagementValue?.value &&
                                documentation !== '<p><br></p>'
                            )
                        }
                        onClick={() => {
                            handleSaveClick();
                        }}
                    >
                        Draft Engagement and Sign Off
                    </Button>
                </Tooltip>
            </Grid>
        </>
    );

    const engagementInfoPage = (
        <Paper variant="outlined" style={{ padding: 10 }}>
            <Grid container direction={'row'} spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} hidden={!wrapperError?.Show ?? true}>
                    <Alert onClose={() => setWrapperError({ Show: false, Message: '' })} severity="error">
                        {wrapperError?.Message}
                    </Alert>
                </Grid>
                {!finish && documentationFields}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    {finish && memberId && engagementDraft?.MemberId?.length > 0 && engagementDraft?.Id?.length > 0 && (
                        <ProcedureSignOff
                            noAppointments={true}
                            memberId={memberId}
                            requestId={requestId}
                            disableWarnings={!enableWarnings ?? true}
                            notAssessment={true}
                            finalizeSignOff={finalizeSignOff}
                            isNotEncounterableOnly={true}
                        />
                    )}
                </Grid>
            </Grid>
        </Paper>
    );

    return engagementInfoPage;
};

export default SignOffWrapper;
