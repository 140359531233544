import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IVisionAndHearing extends BaseEntity {
    MemberId: string;
    HasVisionImpairment?: boolean;
    IsGlassesUsed?: boolean;
    HasHearingImpairment?: boolean;
    IsAidsUsed?: boolean;
    Status: StatusEnum;
    Score?: number;
}

export class VisionAndHearing implements IVisionAndHearing {
    Id: string;
    MemberId: string;
    HasVisionImpairment?: boolean;
    IsGlassesUsed?: boolean;
    HasHearingImpairment?: boolean;
    IsAidsUsed?: boolean;
    Status: StatusEnum;
    Score?: number;

    constructor() {
        this.MemberId = '';
    }
}
