import { AlcoholProblemEnum } from '../../Enum/AlcoholProblemEnum';
import { AnswerOptionsEnum } from '../../Enum/AnswerOptionsEnum';
import { AuditAnswerEnum } from '../../Enum/AuditAnswerEnum';
import { DrinkContainingAlcoholFrequencyEnum } from '../../Enum/DrinkContainingAlcoholFrequencyEnum';
import { NumberOfDrinksEnum } from '../../Enum/NumberOfDrinksEnum';

export default interface IAuditAssessment {
    DrinkContainingAlcoholFrequency?: DrinkContainingAlcoholFrequencyEnum;
    DrinkContainingAlcoholPerDayFrequency?: NumberOfDrinksEnum;
    SixOrMoreDrinksOnOneOccasionFrequency?: AuditAnswerEnum;
    NotAbleToStopOnceStartedFrequency?: AuditAnswerEnum;
    FailedToDoBecauseOfDrinkingFrequency?: AuditAnswerEnum;
    LastYearNeededDrinkFirstThingFrequency?: AuditAnswerEnum;
    FeltGuiltyOrRemorsefulForDrinkingFrequency?: AuditAnswerEnum;
    UnableToRememberWhatHappenedFrequency?: AuditAnswerEnum;
    InjuredAfterDrinkingFrequency?: AuditAnswerEnum;
    OthersConcernedAboutDrinkingFrequency?: AuditAnswerEnum;
    EverBeenInTreatmentForAlcoholProblem?: AlcoholProblemEnum;
    Score?: number;
}

export class AuditAssessment implements IAuditAssessment {
    DrinkContainingAlcoholFrequency?: DrinkContainingAlcoholFrequencyEnum;
    DrinkContainingAlcoholPerDayFrequency?: NumberOfDrinksEnum;
    SixOrMoreDrinksOnOneOccasionFrequency?: AuditAnswerEnum;
    NotAbleToStopOnceStartedFrequency?: AuditAnswerEnum;
    FailedToDoBecauseOfDrinkingFrequency?: AuditAnswerEnum;
    LastYearNeededDrinkFirstThingFrequency?: AuditAnswerEnum;
    FeltGuiltyOrRemorsefulForDrinkingFrequency?: AuditAnswerEnum;
    UnableToRememberWhatHappenedFrequency?: AuditAnswerEnum;
    InjuredAfterDrinkingFrequency?: AuditAnswerEnum;
    OthersConcernedAboutDrinkingFrequency?: AuditAnswerEnum;
    EverBeenInTreatmentForAlcoholProblem?: AlcoholProblemEnum;
    EducationOnDrinking: EducationOnDrinking;
    Score?: number;

    constructor() {
        this.DrinkContainingAlcoholFrequency = null;
    }
}

export class EducationOnDrinking {
    CompletedStatus: AnswerOptionsEnum;
    ReasonForNotApplicable: string;

    constructor() {
        this.CompletedStatus = null;
    }
}
