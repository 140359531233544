import { Box, Button, Dialog, DialogContent, DialogTitle, FormGroup, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    CallListTabs,
    activityOptions,
    createNote,
    returnDocumentOnboarding,
    saveAndFinish,
    setShowSkipCall,
    setSkipCallReason,
} from '../../../store/onboardingWorkflow.slice';
import { apiString as API_URL } from '../../../utils/constants';
import Authorization from '../../../utils/Authorization';
import axios from 'axios';
import moment from 'moment';

const ConfirmSkipCallModal = (props) => {
    const { callItem, confirmationCallItem, selectedCallListTab, skipCallReason, showSkipCall } = useSelector(
        (state: RootState) => state.onboardingWorkflowSlice
    );
    const auth = new Authorization();
    const dispatch = useDispatch();

    const isConfirmCall = selectedCallListTab === CallListTabs.Confirmations;
    const item = isConfirmCall ? confirmationCallItem : callItem;

    const skipCallHandler = async () => {
        const note =
            item?.Phones?.length > 0
                ? `A call item was skipped. Reason: ${skipCallReason}.`
                : `A call item was skipped due to no available phone numbers. Reason: ${skipCallReason}.`;

        const newNote = createNote(item.MemberId, activityOptions.SKIPPED_CALL, note, auth);

        const response = await axios.post(`${API_URL}/onboarding/InsertActivityNote`, newNote);

        const updatedCallItem = { ...item, ActivityNotes: [...item.ActivityNotes, response.data] };
        const editableNotes = updatedCallItem.ActivityNotes.filter((note) => note.Editable).map((note2) => {
            const date = moment(note2.CreatedDate).format('MM/DD/YYYY hh:mm A');
            return `Date: ${date} \nType: ${note2.NoteType} \n${note2.Note}`;
        });
        // creates a new onboarding document
        const document = returnDocumentOnboarding(item, auth, editableNotes.join('\n\n'));
        dispatch(setShowSkipCall(false));
        dispatch(saveAndFinish(updatedCallItem, document, isConfirmCall));
    };

    const confirmSkipCall = () => {
        return (
            <Stack spacing={2}>
                <FormGroup>
                    <TextField
                        multiline
                        sx={{ marginY: 1 }}
                        rows={8}
                        required
                        label="Reason"
                        value={skipCallReason}
                        onChange={(e) => dispatch(setSkipCallReason(e.target.value))}
                        helperText="Required"
                    />
                </FormGroup>

                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button
                            disabled={skipCallReason.length === 0 ? true : false}
                            className="button-120"
                            variant="contained"
                            onClick={() => skipCallHandler()}
                        >
                            Skip
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                dispatch(setShowSkipCall(false));
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <Dialog
            open={showSkipCall}
            onClose={() => {
                dispatch(setShowSkipCall(false));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="md"
        >
            <DialogTitle id="modal-modal-title">Are you sure you wish to skip this call item?</DialogTitle>
            <DialogContent>{confirmSkipCall()}</DialogContent>
        </Dialog>
    );
};

export default ConfirmSkipCallModal;
