import { Dialog, DialogTitle, Step, StepLabel, Stepper } from '@mui/material';
import { useSelector } from 'react-redux';
import SampleData from './SampleData';
import ChooseData from './ChooseData';
import { RootState } from '../../../reducers';
import { useState } from 'react';
import ImportData from './ImportData';
import DataValidation from './DataValidation';

const AddPopulationDialog = (props) => {
    const { populationActiveStep, populationSteps } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const [file, setFile] = useState<File>();

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth disableEnforceFocus maxWidth="xl">
            <DialogTitle sx={{ m: 2, marginBottom: 5 }}>
                <Stepper alternativeLabel activeStep={populationActiveStep}>
                    {populationSteps.map((step) => {
                        return (
                            <Step key={step.value}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </DialogTitle>

            {populationActiveStep === 0 ? (
                <SampleData />
            ) : populationActiveStep === 1 ? (
                <ChooseData file={file} setFile={setFile} />
            ) : populationActiveStep === 2 ? (
                <ImportData file={file} setFile={setFile} />
            ) : populationActiveStep === 3 ? (
                <DataValidation setFile={setFile} />
            ) : (
                <></>
            )}
        </Dialog>
    );
};

export default AddPopulationDialog;
