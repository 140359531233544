import { Autocomplete, Chip, TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import { IFormikAutocompleteProps } from './interfaces/IFormikAutocompleteProps';

const FormikAutocomplete: React.FC<IFormikAutocompleteProps> = ({ ...props }) => {
    const [field] = useField(props);
    return (
        <Autocomplete
            {...field}
            {...props}
            fullWidth
            multiple
            freeSolo
            options={[] as string[]}
            onChange={(e, value) => field.onChange({ target: { name: field.name, value } })}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => <Chip variant="filled" label={option} {...getTagProps({ index })} />)
            }
            renderInput={(params) => <TextField label={props.label} {...params} helperText={props.helperText} />}
            disableClearable
        />
    );
};

export default FormikAutocomplete;
