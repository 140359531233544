import BaseEntity from '../BaseEntity.model';
import Facility from './../Regions/Facility.model';
import KeyContact from './KeyContact.model';
import PlanOfCare from './PlanOfCare.model';
import Medication from './Medication.model';
import DischargePlan from './DischargePlan.model';
import TransitionalCareActivity from './TransitionalCareActivity.model';
import { TransitionalCareNotificationEnum } from './../../Enum/TransitionalCareNotificationEnum';
import { FacilityTypeEnum } from './../../Enum/FacilityTypeEnum';

export default interface IPatientAdmission extends BaseEntity {
    AdmissionDate: Date;
    NotificationDate: string;
    NotificationMethod: TransitionalCareNotificationEnum | number;
    OtherNotification: string;
    Facility: Facility | null;
    AdmissionType: FacilityTypeEnum | number;
    Description: string;
    KeyContacts: KeyContact[] | null;
    PlansOfCare: PlanOfCare[] | null;
    Medications: Medication[] | null;
    DischargePlans: DischargePlan[] | null;
    TransitionalCareActivities: TransitionalCareActivity[] | null;
    VisitType: number;
}

export class PatientAdmission implements IPatientAdmission {
    Id: string;
    AdmissionDate: Date | null;
    NotificationDate: string | null;
    NotificationMethod: TransitionalCareNotificationEnum | number;
    OtherNotification: string;
    Facility: Facility | null;
    AdmissionType: FacilityTypeEnum | number;
    Description: string;
    KeyContacts: KeyContact[] | null;
    PlansOfCare: PlanOfCare[] | null;
    Medications: Medication[] | null;
    DischargePlans: DischargePlan[] | null;
    TransitionalCareActivities: TransitionalCareActivity[] | null;
    VisitType: number;

    constructor() {
        this.AdmissionDate = null;
        this.NotificationDate = null;
        this.OtherNotification = '';
        this.Facility = null;
        this.Description = '';
        this.KeyContacts = null;
        this.PlansOfCare = null;
        this.Medications = null;
        this.DischargePlans = null;
        this.TransitionalCareActivities = null;
        this.VisitType = 0;
    }
}
