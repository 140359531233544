import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import moment from 'moment';
import b64toBlob from 'b64-to-blob';

import { DictionaryPreview } from '../Models/Shared/DictionaryPreview.model';
import { convertObjectToArray, copyObject } from '../utils/common';
import { Facility } from '../Models/Regions/Facility.model';
import { KeyContact } from '../Models/TransitionalCare/KeyContact.model';
import { DischargePlan } from '../Models/TransitionalCare/DischargePlan.model';
import { setAssessmentError } from './assessment.slice';
import { saveEngagementDraft } from './documentEngagement.slice';
import { EngagementDraft } from '../Models/DocumentEngagements/EngagementDraft.model';
import { PatientAdmission } from '../Models/TransitionalCare/PatientAdmission.model';
import { TransitionalCareCheckList } from '../Models/TransitionalCare/TransitionalCareCheckList.model';
import { handleErrorMessage, setIsLoading } from './shared.slice';
import { TransitionalCareStatusEnum } from '../Enum/TransitionalCareStatusEnum';
import { NewMemberTransitionalCare } from '../Models/TransitionalCare/NewMemberTransitionalCareModel';
import { TransitionStep } from '../Models/TransitionalCare/TransitionStep';
import { PatientDischarge } from '../Models/TransitionalCare/PatientDischarge.model';
import { TransitionalCareStepTypeEnum } from '../Enum/TransitionalCareStepTypeEnum';
import { getMemberBasicInfo } from './memberDetail.slice';
import { getMemberByMemberId } from './memberDemographicsDetails.slice';
import { insertTimeline } from './timeline.slice';
import { TimelineTypeEnum } from '../Enum/TimelineTypeEnum';
import { OutsideFacilityLocationEnum } from '../Enum/OutsideFacilityLocationEnum';

export const transitionalCareNotification = [
    { value: 1, label: 'Member/Family' },
    { value: 2, label: 'HIE' },
    { value: 3, label: 'Health Plan' },
    { value: 4, label: 'Provider' },
    { value: 5, label: 'Other' },
];

export const facilityTypes = [
    { value: 1, label: 'Physical Health' },
    { value: 2, label: 'Behavioral Health Level 1' },
    { value: 3, label: 'Behavioral Health Level 2' },
];

export const visitTypesList = [
    { value: 1, label: 'Inpatient' },
    { value: 2, label: 'ED' },
];

export const admissionChecklist = () => [
    {
        label: 'ENGAGEMENT',
        value: 1,
        name: 'TransitionalCareActivities',
    },
    { label: 'PLAN OF CARE', value: 2, name: 'PlansOfCare' },
    { label: 'MEDICATION', value: 3, name: 'Medications' },
    {
        label: 'DISCHARGE PLAN',
        value: 4,
        name: 'DischargePlans',
    },
    {
        label: 'CONTACTS',
        value: 5,
        name: 'KeyContacts',
    },
];

export const dischargeChecklist = () => [
    {
        label: 'ENGAGEMENT',
        value: 1,
        name: 'TransitionalCareActivities',
    },
    { label: 'POST-DISCHARGE CHECKLIST', value: 2, name: 'TransitionalCareCheckList' },
    {
        label: 'CONTACTS',
        value: 5,
        name: 'KeyContacts',
    },
];

const transitionalCareSlice = createSlice({
    name: 'transitionalcare',
    initialState: {
        admissionChecklistTextModalIsOpen: false,
        admissionChecklistText: '',
        expandedList: [],
        facilityTypes: [] as DictionaryPreview[],
        tcNotifications: [] as DictionaryPreview[],
        visitTypes: [] as DictionaryPreview[],
        outsideFacilityLocations: [] as DictionaryPreview[],
        plannedDischargeLocations: [] as DictionaryPreview[],
        memberTransitionalCare: new NewMemberTransitionalCare(),
        postDischargeCheckList: new TransitionalCareCheckList(),
        memberTransitionalCares: [] as NewMemberTransitionalCare[],
        facilities: [] as Facility[],
        patientAdmissionFacility: new PatientAdmission(),
        dischargeFacility: new PatientDischarge(),
        newActivityNote: '',
        currentTransitionTab: 'open',
        patientAdmissionTitle: '',
        isSavingActivityNote: false,
        isSavingKeyContacts: false,
        isSavingChecklist: false,
        isGeneratingPDF: false,
        isFetching: false,
        isFetchingFacilities: false,
        isShowHistory: false,
        isShowPlanOfCareForm: false,
        isShowMedicationsForm: false,
        isShowDischargePlanForm: false,
        isShowKeyContactsForm: false,
        isShowFacilityDetails: false,
        isShowDischargeDetails: false,
        isShowTransitionalCareActivityForm: false,
        isClosingTC: false,
        isTransferring: false,
        isReAdmitting: false,
        currentChecklistItem: 1,
        confirmationDialogIsOpen: false,
        transitionStep: {} as TransitionStep,
    },
    reducers: {
        _setAdmissionChecklistTextModalIsOpen(state, action) {
            state.admissionChecklistTextModalIsOpen = action.payload;
        },
        _setAdmissionChecklistText(state, action) {
            state.admissionChecklistText = action.payload;
        },
        _setCurrentTransitionTab(state, action) {
            state.currentTransitionTab = action.payload;
        },
        _setExpandedList(state, action) {
            state.expandedList = action.payload;
        },
        _setFacilityTypes(state, action) {
            state.facilityTypes = action.payload;
        },
        _setTCNotifications(state, action) {
            state.tcNotifications = action.payload;
        },
        _setOutsideFacilityLocations(state, action) {
            state.outsideFacilityLocations = action.payload;
        },
        _setPlannedDischargeLocations(state, action) {
            state.plannedDischargeLocations = action.payload;
        },
        _setTransitionalCare(state, action) {
            state.memberTransitionalCare = action.payload;
        },
        _setPatientAdmissionTitle(state, action) {
            state.patientAdmissionTitle = action.payload;
        },
        _setPostDischargeCheckList(state, action) {
            state.postDischargeCheckList = action.payload;
        },
        _setTransitionalCares(state, action) {
            state.memberTransitionalCares = action.payload;
        },
        _setFacilities(state, action) {
            state.facilities = action.payload;
        },
        _setPatientAdmissionFacility(state, action) {
            state.patientAdmissionFacility = action.payload;
        },
        _setNewActivityNote(state, action) {
            state.newActivityNote = action.payload;
        },
        _setIsSavingActivityNote(state, action) {
            state.isSavingActivityNote = action.payload;
        },
        _setIsSavingKeyContacts(state, action) {
            state.isSavingKeyContacts = action.payload;
        },
        _setIsGeneratingPDF(state, action) {
            state.isGeneratingPDF = action.payload;
        },
        _setIsChecklist(state, action) {
            state.isSavingChecklist = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsFetchingFacilities(state, action) {
            state.isFetchingFacilities = action.payload;
        },
        _toggleIsShowHistory(state, action) {
            state.isShowHistory = action.payload;
        },
        _toggleIsShowPlanOfCareForm(state, action) {
            state.isShowPlanOfCareForm = action.payload;
        },
        _toggleIsShowMedicationsForm(state, action) {
            state.isShowMedicationsForm = action.payload;
        },
        _toggleIsShowKeyContactsForm(state, action) {
            state.isShowKeyContactsForm = action.payload;
        },
        _toggleIsShowDischargePlanForm(state, action) {
            state.isShowDischargePlanForm = action.payload;
        },
        _toggleIsShowFacilityDetails(state, action) {
            state.isShowFacilityDetails = action.payload;
        },
        _toggleIsShowDischargeDetails(state, action) {
            state.isShowDischargeDetails = action.payload;
        },
        _toggleIsClosingTC(state, action) {
            state.isClosingTC = action.payload;
        },
        _setIsTransferring(state, action) {
            state.isTransferring = action.payload;
        },
        _setVisitTypes(state, action) {
            state.visitTypes = action.payload;
        },
        _setCurrentChecklistItem(state, action) {
            state.currentChecklistItem = action.payload;
        },
        _setConfirmationDialogIsOpen(state, action) {
            state.confirmationDialogIsOpen = action.payload;
        },
        _setTransitionStep(state, action) {
            state.transitionStep = action.payload;
        },
        _setDischargeFacility(state, action) {
            state.dischargeFacility = action.payload;
        },
        _setIsShowTransitionalCareActivityForm(state, action) {
            state.isShowTransitionalCareActivityForm = action.payload;
        },
        _setIsReAdmitting(state, action) {
            state.isReAdmitting = action.payload;
        },
    },
});

const {
    _setAdmissionChecklistTextModalIsOpen,
    _setAdmissionChecklistText,
    _setCurrentTransitionTab,
    _setExpandedList,
    _setFacilityTypes,
    _setTCNotifications,
    _setOutsideFacilityLocations,
    _setPlannedDischargeLocations,
    _setTransitionalCare,
    _setTransitionalCares,
    _setPatientAdmissionTitle,
    _setPostDischargeCheckList,
    _setFacilities,
    _setPatientAdmissionFacility,
    _setIsSavingActivityNote,
    _setIsSavingKeyContacts,
    _setIsChecklist,
    _setIsGeneratingPDF,
    _setNewActivityNote,
    _toggleIsFetching,
    _toggleIsFetchingFacilities,
    _toggleIsShowHistory,
    _toggleIsShowPlanOfCareForm,
    _toggleIsShowMedicationsForm,
    _toggleIsShowKeyContactsForm,
    _toggleIsShowDischargePlanForm,
    _toggleIsShowFacilityDetails,
    _toggleIsShowDischargeDetails,
    _toggleIsClosingTC,
    _setIsTransferring,
    _setCurrentChecklistItem,
    _setConfirmationDialogIsOpen,
    _setTransitionStep,
    _setDischargeFacility,
    _setIsShowTransitionalCareActivityForm,
    _setIsReAdmitting,
} = transitionalCareSlice.actions;

export const updateMemberTransitionalCaresAndStep =
    (updatedTransitionalCare: NewMemberTransitionalCare, allMemberTransitionalCares: NewMemberTransitionalCare[]): AppThunk =>
    async (dispatch, getState) => {
        let updatedTransitions = allMemberTransitionalCares.map((item) => {
            return item.Id === updatedTransitionalCare.Id ? updatedTransitionalCare : item;
        });
        dispatch(_setTransitionalCares(updatedTransitions));
        dispatch(_setTransitionalCare(updatedTransitionalCare));
        const activeTransitionSteps = updatedTransitionalCare.TransitionSteps.filter((step) => step.IsDeleted === false);
        const transitionStep = activeTransitionSteps[activeTransitionSteps.length - 1];
        dispatch(setTransitionStep(transitionStep));
    };

export const addNewAddmissionChecklistNoteHandler = (): AppThunk => async (dispatch, getState) => {
    dispatch(setAdmissionChecklistTextModalIsOpen(true));
    dispatch(setAdmissionChecklistText(''));
};

export const closeAddmissionChecklistNoteHandler = (): AppThunk => async (dispatch, getState) => {
    dispatch(setAdmissionChecklistTextModalIsOpen(false));
    dispatch(setAdmissionChecklistText(''));
};

export const setAdmissionChecklistText =
    (admissionCheclistText: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setAdmissionChecklistText(admissionCheclistText));
    };

export const setAdmissionChecklistTextModalIsOpen =
    (admissionChecklistTextModalIsOpen: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setAdmissionChecklistTextModalIsOpen(admissionChecklistTextModalIsOpen));
    };

export const setConfirmationDialogIsOpen =
    (confirmationDialogIsOpen: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setConfirmationDialogIsOpen(confirmationDialogIsOpen));
    };

export const setCurrentChecklistItem =
    (currentChecklistItem: number): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setCurrentChecklistItem(currentChecklistItem));
    };

export const setCurrentTransitionTab =
    (currentTransitionTab: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setCurrentTransitionTab(currentTransitionTab));
    };

export const setDischargeFacility =
    (dischargeFacility: PatientDischarge): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setDischargeFacility(dischargeFacility));
    };

export const setExpandedList =
    (expandedList: any): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setExpandedList(expandedList));
    };

export const setIsShowTransitionalCareActivityForm =
    (isShow: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setIsShowTransitionalCareActivityForm(isShow));
    };

export const setIsReAdmitting =
    (isReAdmitting: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setIsReAdmitting(isReAdmitting));
    };

export const setIsTransferring =
    (isTransferring: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setIsTransferring(isTransferring));
    };

export const setMemberTransitionalCare =
    (memberTransitionalCare: NewMemberTransitionalCare): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setTransitionalCare(memberTransitionalCare));
    };

export const setNewActivityNote =
    (note: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setNewActivityNote(note));
    };

export const setPatientAdmissionTitle =
    (patientAdmissionTitle: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setPatientAdmissionTitle(patientAdmissionTitle));
    };

export const setTransitionStep =
    (transitionStep: TransitionStep): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setTransitionStep(transitionStep));
    };

export const showNewTransitionalCare = (): AppThunk => (dispatch, getState) => {
    dispatch(_setPostDischargeCheckList(new TransitionalCareCheckList()));
    dispatch(_setFacilities([]));
    dispatch(setPatientAdmissionTitle('New Transitional Care'));
    dispatch(toggleIsShowFacilityDetails(true));
};

export const toggleIsShowDischargeDetails =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsShowDischargeDetails(show));
    };

export const toggleIsShowDischargePlanForm =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsShowDischargePlanForm(show));
    };

export const toggleIsShowFacilityDetails =
    (show: boolean, transitionStep = {} as TransitionStep): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsShowFacilityDetails(show));
        dispatch(_setPatientAdmissionFacility(transitionStep));
    };

export const toggleIsShowHistory =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsShowHistory(show));
    };

export const toggleIsShowKeyContactsForm =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsShowKeyContactsForm(show));
    };

export const toggleIsShowMedicationsForm =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsShowMedicationsForm(show));
    };

export const toggleIsShowPlanOfCareForm =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleIsShowPlanOfCareForm(show));
    };

export const fetchFacilityTypes = (): AppThunk => async (dispatch, getState) => {
    dispatch(_toggleIsFetching(true));
    const response = await axios.get(`${API_URL}/transitionalcare/getfacilitytype`);
    dispatch(_setFacilityTypes(convertObjectToArray(response.data)));
    dispatch(_toggleIsFetching(false));
};

export const fetchTransitionalCareNotifications = (): AppThunk => async (dispatch, getState) => {
    dispatch(_toggleIsFetching(true));
    const response = await axios.get(`${API_URL}/transitionalcare/gettransitionalcarenotification`);
    dispatch(_setTCNotifications(convertObjectToArray(response.data)));
    dispatch(_toggleIsFetching(false));
};

export const fetchOutsideFacilityLocations = (): AppThunk => async (dispatch, getState) => {
    dispatch(_toggleIsFetching(true));
    const response = await axios.get(`${API_URL}/transitionalcare/getoutsidefacilitylocation`);
    dispatch(_setOutsideFacilityLocations(convertObjectToArray(response.data)));
    dispatch(_toggleIsFetching(false));
};

export const fetchPlannedDischargeLocations = (): AppThunk => async (dispatch, getState) => {
    dispatch(_toggleIsFetching(true));
    const response = await axios.get(`${API_URL}/transitionalcare/getplanneddischargelocation`);
    dispatch(_setPlannedDischargeLocations(convertObjectToArray(response.data)));
    dispatch(_toggleIsFetching(false));
};

export const fetchTransitionalCareById =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await axios.get(`${API_URL}/transitionalcare/getmembertransitionalcarebyid?id=${id}`);
            const admissionStep = response.data?.TransitionSteps.find((step) => step.TransitionStepType === TransitionalCareStepTypeEnum.Admission);
            const dischargeStep = response.data?.TransitionSteps.find((step) => step.TransitionStepType === TransitionalCareStepTypeEnum.Discharge);
            if (Boolean(admissionStep)) {
                dispatch(_setFacilities([admissionStep?.Facility]));
            }
            dispatch(
                _setPostDischargeCheckList(
                    Boolean(dischargeStep?.TransitionalCareCheckList) ? dischargeStep.TransitionalCareCheckList : new TransitionalCareCheckList()
                )
            );
            dispatch(_setTransitionalCare(response.data));
        } catch (error) {
            dispatch(handleErrorMessage(error));
        }
    };

export const saveNewTransferFacility =
    (transitionStep: TransitionStep): AppThunk =>
    async (dispatch, getState) => {
        const selectedTransitionalCareId = getState().transitionalCare.memberTransitionalCare.Id;
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        const memberTransitionalCare = getState().transitionalCare.memberTransitionalCare;
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/transitionalcare/insertorupdatefacility`, transitionStep, {
                params: { transitionalCareId: selectedTransitionalCareId },
            });
            if (!transitionStep.Id) {
                let message = `TRANSFER Transitional Care to ${transitionStep?.Facility?.Name} - ${moment().format('MM/DD/YYYY')}`;
                dispatch(insertTimeline(memberTransitionalCare.MemberId, message, TimelineTypeEnum.TransitionalCare));
            }
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(_toggleIsShowFacilityDetails(false));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const saveReAdmission =
    (transitionStep: TransitionStep): AppThunk =>
    async (dispatch, getState) => {
        const selectedTransitionalCareId = getState().transitionalCare.memberTransitionalCare.Id;
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/transitionalcare/insertorupdatereadmission`, transitionStep, {
                params: { transitionalCareId: selectedTransitionalCareId },
            });
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(setCurrentTransitionTab('open'));
            dispatch(getMemberByMemberId(response.data.MemberId));
            let message = `Transitional Care Re-Admission to ${transitionStep?.Facility?.Name} - ${moment().format('MM/DD/YYYY')}`;
            dispatch(insertTimeline(response.data.MemberId, message, TimelineTypeEnum.TransitionalCare));
            dispatch(_toggleIsShowFacilityDetails(false));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const getFacilitiesByKeyword =
    (keyword: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetchingFacilities(true));
        const response = await axios.get(`${API_URL}/facility/getfacilitiesbykeyword?keyword=${keyword}`);
        dispatch(_setFacilities(response.data));
        dispatch(_toggleIsFetchingFacilities(false));
    };

export const addTransitionalCareActivity =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        const selectedTransitionalCareId = getState().transitionalCare.memberTransitionalCare.Id;
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        const transitionStepId = getState().transitionalCare.transitionStep.Id;
        dispatch(setIsLoading(true));
        dispatch(_setIsSavingActivityNote(true));
        try {
            const response = await axios.put(
                `${API_URL}/transitionalcare/addtransitionalcareactivity?transitionalCareId=${selectedTransitionalCareId}&transitionStepId=${transitionStepId}`,
                { Note: note }
            );
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(prepareProgressNote(note));
            dispatch(_setNewActivityNote(''));
            dispatch(setIsShowTransitionalCareActivityForm(false));
            dispatch(_setIsSavingActivityNote(false));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const addPlanOfCare =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        const selectedTransitionalCareId = getState().transitionalCare.memberTransitionalCare.Id;
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        const transitionStepId = getState().transitionalCare.transitionStep.Id;
        try {
            const response = await axios.put(
                `${API_URL}/transitionalcare/addplanofcare?transitionalCareId=${selectedTransitionalCareId}&transitionStepId=${transitionStepId}`,
                { PlanOfCareAndTreatment: note }
            );
            dispatch(prepareProgressNote(note));
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(_toggleIsShowPlanOfCareForm(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
        }
    };

export const addMedication =
    (note: string): AppThunk =>
    async (dispatch, getState) => {
        const selectedTransitionalCareId = getState().transitionalCare.memberTransitionalCare.Id;
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        const transitionStepId = getState().transitionalCare.transitionStep.Id;
        try {
            const response = await axios.put(
                `${API_URL}/transitionalcare/addmedication?transitionalCareId=${selectedTransitionalCareId}&transitionStepId=${transitionStepId}`,
                { Medications: note }
            );
            dispatch(prepareProgressNote(note));
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(_toggleIsShowMedicationsForm(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
        }
    };

// updated to use version 2
export const addKeyContact =
    (transitionalCareId: string, transitionStepId: string, data: KeyContact): AppThunk =>
    async (dispatch, getState) => {
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        dispatch(setIsLoading(true));
        try {
            dispatch(_toggleIsShowKeyContactsForm(false));
            dispatch(_setIsSavingKeyContacts(true));
            const url = Boolean(data.Id) ? `${API_URL}/transitionalcare/updatekeycontact` : `${API_URL}/transitionalcare/addkeycontact`;
            const response = await axios.put(url, data, { params: { transitionalCareId, transitionStepId } });
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(_setIsSavingKeyContacts(false));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const addDischargePlan =
    (data: DischargePlan): AppThunk =>
    async (dispatch, getState) => {
        const selectedTransitionalCareId = getState().transitionalCare.memberTransitionalCare.Id;
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        const transitionStepId = getState().transitionalCare.transitionStep.Id;
        try {
            const response = await axios.put(
                `${API_URL}/transitionalcare/adddischargeplan?transitionalCareId=${selectedTransitionalCareId}&transitionStepId=${transitionStepId}`,
                { AnticipatedDate: data.AnticipatedDate, PlannedDischargeLocation: data.PlannedDischargeLocation, Note: data.Note }
            );
            dispatch(prepareProgressNote(data.Note));
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(_toggleIsShowDischargePlanForm(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
        }
    };

export const generateTransitionalCarePDF = (): AppThunk => async (dispatch, getState) => {
    const { memberTransitionalCare } = getState().transitionalCare;
    const admissionStep = memberTransitionalCare.TransitionSteps.find((step) => step.TransitionStepType === 1);
    dispatch(_setIsGeneratingPDF(true));
    const response = await axios.get(`${API_URL}/transitionalcare/generatetransitionalcarePDF`, { params: { transitionalCareId: memberTransitionalCare.Id } });
    var blob = b64toBlob(response.data.base64String, 'application/pdf');
    var blobUrl = URL.createObjectURL(blob);
    var a = document.createElement('a');
    var fileName = `TransitionalCare-Admission-${moment(admissionStep.AdmissionDate).format('MM-DD-yyyy')}`;
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = fileName + '.pdf';
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    dispatch(_setIsGeneratingPDF(false));
};

export const saveTransitionalCareChecklist =
    (data: TransitionStep, saveLocal: boolean = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setIsChecklist(true));
        const selectedTransitionalCareId = getState().transitionalCare.memberTransitionalCare.Id;
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        const transitionStepId = getState().transitionalCare.transitionStep.Id;
        try {
            if (!saveLocal) {
                const response = await axios.put(
                    `${API_URL}/transitionalcare/insertorupdatetransitionalcarechecklist?transitionalCareId=${selectedTransitionalCareId}&transitionStepId=${transitionStepId}`,
                    data
                );
                dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            }
            dispatch(_setIsChecklist(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
        }
    };

export const addTransitionalCareActivityForDischarge =
    (transitionalCareId: string, transitionStepId: string, transitionalCareActivity): AppThunk =>
    async (dispatch, getState) => {
        try {
            await axios.put(`${API_URL}/transitionalcare/addtransitionalcareactivityfordischarge`, transitionalCareActivity, {
                params: { transitionalCareId, transitionStepId },
            });
        } catch (error) {
            dispatch(handleErrorMessage(error));
        }
    };

export const prepareProgressNote =
    (note: string): AppThunk =>
    (dispatch, getState) => {
        if (note) {
            let engagementDraft = copyObject(getState().documentEngagement.engagementDraft || {}) as EngagementDraft;
            let memberTransitionalCare = getState().transitionalCare.memberTransitionalCare;
            if (!Boolean(engagementDraft.ProgressNotes)) {
                engagementDraft.ProgressNotes = '';
            }
            engagementDraft.ProgressNotes += '<p>';
            engagementDraft.ProgressNotes += note;
            engagementDraft.ProgressNotes += '</p>';
            engagementDraft.TransitionalCareId = memberTransitionalCare.Id;
            dispatch(saveEngagementDraft(engagementDraft));
        }
    };

export const fetchAllMemberTransitionalCares =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/transitionalcare/getmembertransitionalcaresbymemberid?memberId=${memberId}`);
            if (response.data && response.data.length) {
                const activeTransitions = response.data.filter((el) => el.Status === TransitionalCareStatusEnum.Active);
                let mostRecent = null;

                if (activeTransitions.length > 0) {
                    mostRecent = activeTransitions[activeTransitions.length - 1];
                    dispatch(setCurrentTransitionTab('open'));
                } else {
                    mostRecent = response.data[response.data.length - 1];
                    dispatch(setCurrentTransitionTab('closed'));
                }
                dispatch(setExpandedList([mostRecent.Id]));
                dispatch(setMemberTransitionalCare(mostRecent));
                const transitionSteps = mostRecent?.TransitionSteps.filter((step) => step.IsDeleted === false);
                dispatch(setTransitionStep(transitionSteps[transitionSteps.length - 1]));
                dispatch(setCurrentChecklistItem(1));
            }
            dispatch(_setTransitionalCares(response.data));
            dispatch(getMemberBasicInfo(memberId));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const insertNewMemberTransitionalCare =
    (data: NewMemberTransitionalCare): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            await axios.post(`${API_URL}/transitionalcare/inserttransitionalcare`, data);
            dispatch(toggleIsShowFacilityDetails(false));
            dispatch(getMemberByMemberId(data.MemberId));
            let message = `ADMIT Transitional Care to ${data.TransitionSteps[0]?.Facility?.Name} - ${moment().format('MM/DD/YYYY')}`;
            dispatch(insertTimeline(data.MemberId, message, TimelineTypeEnum.TransitionalCare));
            dispatch(fetchAllMemberTransitionalCares(data.MemberId));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const updateTransitionalCare =
    (selectedTransitionalCareId: string, transitionalCare: TransitionStep): AppThunk =>
    async (dispatch, getState) => {
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        try {
            dispatch(setIsLoading(true));
            const response = await axios.put(
                `${API_URL}/transitionalcare/updatetransitionalcare?selectedTransitionalCareId=${selectedTransitionalCareId}`,
                transitionalCare
            );
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(toggleIsShowFacilityDetails(false));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const addDischargeToTransition =
    (transitionId: string, step: TransitionStep): AppThunk =>
    async (dispatch, getState) => {
        const memberTransitionalCares = getState().transitionalCare.memberTransitionalCares;
        const memberTransitionalCare = getState().transitionalCare.memberTransitionalCare;
        dispatch(setIsLoading(true));
        try {
            const response = await axios.put(`${API_URL}/transitionalcare/insertorupdatepatientdischarge?transitionId=${transitionId}`, step);
            if (!step.Id) {
                let message = `DISCHARGE Transitional Care to ${OutsideFacilityLocationEnum[step.OutsideFacilityLocation]} - ${moment().format('MM/DD/YYYY')}`;
                dispatch(insertTimeline(memberTransitionalCare.MemberId, message, TimelineTypeEnum.TransitionalCare));
            }
            dispatch(updateMemberTransitionalCaresAndStep(response.data, memberTransitionalCares));
            dispatch(setIsLoading(false));
            dispatch(toggleIsShowDischargeDetails(false));
        } catch (error) {
            console.log(error);
            dispatch(handleErrorMessage(error));
            dispatch(setAssessmentError({ Show: true, Message: error?.response?.data }));
            dispatch(setIsLoading(false));
        }
    };

export const closeTransitionalCare =
    (selectedTransitionalCareId: string, isAssessment: boolean): AppThunk =>
    async (dispatch, getState) => {
        const memberId = getState().transitionalCare.memberTransitionalCare.MemberId;
        dispatch(_toggleIsClosingTC(true));
        try {
            await axios.get(`${API_URL}/transitionalcare/closetransitionalcare?id=${selectedTransitionalCareId}`);
            if (isAssessment) {
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft(engagementDraft, isAssessment));
            }
            dispatch(getMemberByMemberId(memberId));
            dispatch(fetchAllMemberTransitionalCares(memberId));
            dispatch(_toggleIsClosingTC(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(_toggleIsClosingTC(false));
        }
    };

export const closeTransitionalCareDueToDeathById =
    (selectedTransitionalCareId: string, isAssessment: boolean): AppThunk =>
    async (dispatch, getState) => {
        const memberId = getState().transitionalCare.memberTransitionalCare.MemberId;
        dispatch(_toggleIsClosingTC(true));
        try {
            await axios.get(`${API_URL}/transitionalcare/closetransitionalcareduetodeath?id=${selectedTransitionalCareId}`);
            if (isAssessment) {
                const engagementDraft = getState().documentEngagement.engagementDraft;
                dispatch(saveEngagementDraft(engagementDraft, isAssessment));
            }
            dispatch(getMemberByMemberId(memberId));
            dispatch(_toggleIsClosingTC(false));
            dispatch(fetchAllMemberTransitionalCares(memberId));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(_toggleIsClosingTC(false));
        }
    };

export const deleteTransitionalCare =
    (transitionalCareId: string): AppThunk =>
    async (dispatch, getState) => {
        const memberId = getState().transitionalCare.memberTransitionalCare.MemberId;
        const admissionStep = getState().transitionalCare.memberTransitionalCare.TransitionSteps[0];
        dispatch(setIsLoading(true));
        try {
            await axios.delete(`${API_URL}/transitionalcare/deletetransitionalcare?id=${transitionalCareId}`);
            const message = `Transition Deleted - ${TransitionalCareStepTypeEnum[admissionStep.TransitionStepType]} to ${admissionStep.Facility?.Name} on ${
                moment(admissionStep?.AdmissionDate).isValid && moment(admissionStep?.AdmissionDate).format('MM/DD/YYYY')
            }`;
            dispatch(insertTimeline(memberId, message, TimelineTypeEnum.TransitionalCare));
            dispatch(setIsLoading(false));
            dispatch(fetchAllMemberTransitionalCares(memberId));
            dispatch(getMemberByMemberId(memberId));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const deleteTransitionalCareStep =
    (transitionalCareId: string, transitionStepId: string): AppThunk =>
    async (dispatch, getState) => {
        const memberId = getState().transitionalCare.memberTransitionalCare.MemberId;
        const transitionStep = getState().transitionalCare.transitionStep;
        dispatch(setIsLoading(true));
        try {
            await axios.delete(
                `${API_URL}/transitionalcare/deletetransitionalcarestep?transitionalCareId=${transitionalCareId}&transitionStepId=${transitionStepId}`
            );
            const facilityLocation =
                transitionStep.TransitionStepType === TransitionalCareStepTypeEnum.Discharge
                    ? OutsideFacilityLocationEnum[transitionStep?.OutsideFacilityLocation]
                    : transitionStep.Facility?.Name;
            const message = `Deleted ${TransitionalCareStepTypeEnum[transitionStep.TransitionStepType]} to ${facilityLocation} on ${
                moment(transitionStep?.AdmissionDate).isValid && moment(transitionStep?.AdmissionDate).format('MM/DD/YYYY')
            }`;
            dispatch(insertTimeline(memberId, message, TimelineTypeEnum.TransitionalCare));
            dispatch(setIsLoading(false));
            dispatch(fetchAllMemberTransitionalCares(memberId));
            dispatch(getMemberByMemberId(memberId));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export default transitionalCareSlice.reducer;
