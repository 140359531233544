import { useEffect, useState } from 'react';

import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, List, ListItem, Stack, TextField, Typography } from '@mui/material';

import moment from 'moment';

import GuardianOrCaregiverInput from '../../Shared/GuardianOrCaregiverInput';
import Initials from '../../Shared/Initials';
import Signature from '../../Shared/Signature';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { ConsentSignatureTypeEnum } from '../../../../Enum/ConsentSignatureTypeEnum';
import { setDigitalSignatureForm } from '../../../../store/consentSlice.slice';
import SignatureTypeTabs from '../../Shared/SignatureTypeTabs';

const ConsentPartOneEnglish = (props: any) => {
    const { activeStep, digitalSignatureForm } = useSelector((state: RootState) => state.consentSlice);

    const dispatch = useDispatch();

    const updateDigitalSignatureForm = (key: string, value: any) => {
        dispatch(
            setDigitalSignatureForm({
                ...digitalSignatureForm,
                [key]: value,
            })
        );
    };

    const [openGuardianOrCareGiverOne, setOpenGuardianOrCareGiverOne] = useState(false);

    const [openGuardianOrCareGiverWitnessOne, setOpenGuardianOrCareGiverWitnessOne] = useState(false);

    const [openSectionOne, setOpenSectionOne] = useState(false);
    const [openSectionTwo, setOpenSectionTwo] = useState(false);
    const [openSectionThree, setOpenSectionThree] = useState(false);
    const [openSectionFour, setOpenSectionFour] = useState(false);
    const [openSectionFive, setOpenSectionFive] = useState(false);

    const [openMemberSignatureOne, setOpenMemberSignatureOne] = useState(false);
    const [openVerbalConsent, setOpenVerbalConsent] = useState(false);

    const handleConsentChange = (consentType: string) => {
        dispatch(
            setDigitalSignatureForm({
                ...digitalSignatureForm,
                AppointmentConsent: consentType,
            })
        );
    };

    useEffect(() => {
        const element = document.getElementById('top');
        element.scrollIntoView({ behavior: 'smooth' });
    }, [activeStep]);

    return (
        <Box sx={{ position: 'relative' }}>
            <div id="top" className="initials-div"></div>
            <Typography variant="h5" p={2} fontWeight={'bold'} mt={5} textAlign={'center'}>
                CONSENT FOR TREATMENT BY CHP CARE PC AND ACKNOWLEDGEMENT OF RECEIPT OF NOTICE OF PRIVACY PRACTICES
            </Typography>
            <Stack spacing={2} sx={{ padding: 2, textAlign: 'left' }}>
                <section id="consent-for-services">
                    <Typography variant="h6" mb={1} textAlign={'center'}>
                        CONSENT TO CHP CARE PC FOR SERVICES
                    </Typography>
                    <Typography variant="body1">
                        I request and authorize CHP Care PC as my medical care provider, his assistants or designees (collectively called the “providers”) may
                        deem necessary or advisable. This care may include, but is not limited to, routine diagnostics, radiology and laboratory procedures,
                        routine medical, behavioral and healthcare assessment and treatment. I authorize my provider(s) to perform other additional or extended
                        services in emergency situations if it may be necessary or advisable in order to preserve my life or health. I understand that my (the
                        member) care is directed by my provider(s) and that other personnel render care and services to me (the member) according to the
                        provider(s) instructions.
                    </Typography>
                    <List sx={{ listStyleType: 'disc', p: 2 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                I am aware that the practice of medicine is not an exact science and I acknowledge that no guarantees or promises have been made
                                to me with respect to results of such treatment.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                I have received all the information that I need to give informed consent for my proposed treatment. This information shall
                                include the risks and benefits of the treatment.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                I understand that CHP Care PC provides both medical and behavioral services as a treatment team of professionals including
                                individual and family therapy.
                            </Typography>
                        </ListItem>
                    </List>
                    <Typography variant="body1">
                        Your participation is voluntary with the right to participate in the development of and review your treatment plan. You have the right
                        to refuse any recommended treatment, or to withdraw consent to treatment and to be explained the consequences of refusal. You have the
                        right to your records and have been notified of the limitations on confidentiality as confidential information may be disclosed without
                        my consent in accordance with state and federal law. You can notify your clinical team or call CHP Care at 602-802-8388 for a copy of
                        your records at any time.
                    </Typography>
                    <div className="initials-div" id="section-one"></div>
                    <Box my={2} width={'300px'} mx={'auto'}>
                        <Initials
                            isSpanish={false}
                            isOpen={openSectionOne}
                            setIsOpen={setOpenSectionOne}
                            initials={digitalSignatureForm.SectionOneInitials}
                            setInitials={(initials: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        SectionOneInitials: initials,
                                    })
                                )
                            }
                        />
                    </Box>
                </section>
                <section id="privacy-practices">
                    <Typography variant="h6" mb={1} textAlign={'center'}>
                        ACKNOWLEDGEMENT OF PRIVACY PRACTICES
                    </Typography>
                    <Typography variant="body1">
                        The CHP Care PC Notice of Privacy Practices provides information about how protected health information about me (the member) –
                        including information about human immunodeficiency virus (HIV), AIDS-related complex (ATC) and acquired immunodeficiency (AIDS);
                        including substance abuse treatment records protected under the regulation 42 Part 2, in the Code of Federal Regulations (if any); and
                        psychological and social services records, including communication made to me to a social worker or psychologist (if any) may be
                        disclosed. I have been offered an opportunity to review the Notice before signing this consent. Additionally, CHP Care PC participates
                        in the Arizona Health Information Exchange, Health Current, to share my healthcare information electronically to facilitate care with
                        other providers involved in my care. CHP Care PC provided me a copy of the Notice of Health Information Practices, including my rights
                        to Opt Out of my data being shared with the Health Information Exchange. I understand that the terms of the Notice may change, and I may
                        obtain a revised copy by contacting the CHP Care PC office.
                    </Typography>
                    <List sx={{ listStyleType: 'disc', p: 2 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                I understand that I have the right to request restrictions on how my protected health information is used or disclosed for
                                treatment, payment or healthcare operations. CHP Care PC is not required to agree to this restriction (except for my opting out
                                of the Health Information Exchange), but if they agree, will be bound by the agreement.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                By signing this form, I acknowledge that I have been offered and/or received the CHP Care PC Notice of Privacy Practices,
                                including the Notice of Health Information Practices.
                            </Typography>
                        </ListItem>
                    </List>
                    <div className="initials-div" id="section-two"></div>
                    <Box my={2} width={'300px'} mx={'auto'}>
                        <Initials
                            isSpanish={false}
                            isOpen={openSectionTwo}
                            setIsOpen={setOpenSectionTwo}
                            initials={digitalSignatureForm.SectionTwoInitials}
                            setInitials={(initials: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        SectionTwoInitials: initials,
                                    })
                                )
                            }
                        />
                    </Box>
                    <Typography variant="body1">
                        I understand that as part of my healthcare, CHP Care PC, originates, maintains paper and/or electronic records describing my health
                        history, symptoms, examination and test results, diagnoses, treatment, and any plans for future care or treatment. I understand that my
                        medication history and formulary benefits may be downloaded from a secure electronic clearinghouse. I understand that this information
                        serves as:
                    </Typography>
                    <List sx={{ listStyleType: 'disc', p: 2 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">A basis for planning my care and treatment.</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">A means of communication among the many health professionals who contribute to my care.</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                A source of information for applying my diagnosis and surgical information to my medical history.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">A means by which a third-party payer can verify that services were actually provided.</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                A tool for routine healthcare operations such as assessing quality and reviewing the competence of healthcare professionals.
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                If I opt into receiving appointment confirmations through text message and/or electronic mail (e-mail), a source of information
                                to allow for my providers to create and send such text and/or email appointment confirmations.
                            </Typography>
                        </ListItem>
                    </List>
                    <div className="initials-div" id="section-three"></div>
                    <Box my={2} width={'300px'} mx={'auto'}>
                        <Initials
                            isSpanish={false}
                            isOpen={openSectionThree}
                            setIsOpen={setOpenSectionThree}
                            initials={digitalSignatureForm.SectionThreeInitials}
                            setInitials={(initials: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        SectionThreeInitials: initials,
                                    })
                                )
                            }
                        />
                    </Box>
                    <Typography variant="body1" fontWeight={'bold'}>
                        I acknowledge that a copy of Notice of Privacy Practices was provided to me. I understand that I have the following rights and
                        privileges:
                    </Typography>
                    <List sx={{ listStyleType: 'disc', p: 2 }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">The right to review the notice prior to signing this consent.</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">The right to object to the use of my health information for directory purposes.</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant="body1">
                                The right to request restrictions as to how my health information may be used or disclosed to carry out treatment or healthcare
                                operations.
                            </Typography>
                        </ListItem>
                    </List>
                    <Typography variant="body1">
                        I understand that CHP Care PC is not required to agree to the restrictions requested. I understand that I may revoke this consent in
                        writing, except to the extent that the organization has already taken action in reliance thereon. I also understand that by refusing to
                        sign this consent or revoking this consent, this organization may refuse to treat me as permitted by Section 164.506 of the Code of
                        Federal Regulations.
                    </Typography>
                    <Stack spacing={2} my={2}>
                        <Typography variant="body1">
                            I wish to have the following restrictions with regard to the use or disclosure of my health information:
                        </Typography>
                        <TextField
                            fullWidth
                            label="List health information restrictions"
                            multiline
                            variant="outlined"
                            value={digitalSignatureForm.InfoRestrictions}
                            onChange={(e) => updateDigitalSignatureForm('InfoRestrictions', e.target.value)}
                        />
                    </Stack>
                    <Typography variant="body1">
                        I further understand that CHP Care PC reserves the right to change its notice and practices, in accordance with Section 164.520 of the
                        Code of Federal Regulation. Should CHP Care PC change its notice, it will send a copy of any revised notice to the address I have
                        provided (whether U.S. mail or, if I agree, via email).
                    </Typography>
                    <div className="initials-div" id="section-four"></div>
                    <Box my={2} width={'300px'} mx={'auto'}>
                        <Initials
                            isSpanish={false}
                            isOpen={openSectionFour}
                            setIsOpen={setOpenSectionFour}
                            initials={digitalSignatureForm.SectionFourInitials}
                            setInitials={(initials: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        SectionFourInitials: initials,
                                    })
                                )
                            }
                        />
                    </Box>
                </section>
                <section id="text-email-appointment">
                    <div className="initials-div" id="appointment-consent"></div>
                    <Typography variant="h6" mb={1} mt={2} textAlign={'center'}>
                        CONSENT TO TEXT MESSAGE AND/OR E-MAIL APPOINTMENT CONFIRMATION
                    </Typography>

                    <Typography variant="body1">
                        CHP Care PC offers patients the option to receive appointment confirmations by text message and/or email. I understand that CHP Care PC
                        will access my protected health information, specifically my information related to future care and treatment, to send appointment
                        confirmations to me by text message and/or e-mail.
                    </Typography>

                    <FormGroup>
                        <Stack spacing={2} my={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={digitalSignatureForm.AppointmentConsent === 'consent'} onChange={() => handleConsentChange('consent')} />
                                }
                                label={
                                    <Typography>
                                        I <b>consent</b> to CHP Care PC accessing and using my protected health information, so CHP Care PC can send me
                                        appointment confirmations by text message and/or e-mail and have completed the Authorization to Release Health
                                        Information attached to this consent form.
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.AppointmentConsent === 'noconsent'}
                                        onChange={() => handleConsentChange('noconsent')}
                                    />
                                }
                                label={
                                    <Typography>
                                        I <b>do not consent</b> to CHP Care PC accessing and using my protected health information to send appointment
                                        confirmations by text message or e-mail.
                                    </Typography>
                                }
                            />
                        </Stack>
                    </FormGroup>
                </section>
                <section>
                    <div className="initials-div" id="section-five"></div>
                    <Box my={2} width={'300px'} mx={'auto'}>
                        <Initials
                            isSpanish={false}
                            isOpen={openSectionFive}
                            setIsOpen={setOpenSectionFive}
                            initials={digitalSignatureForm.SectionFiveInitials}
                            setInitials={(initials: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        SectionFiveInitials: initials,
                                    })
                                )
                            }
                        />
                    </Box>
                    <Typography id="consent-signature" variant="h6" mb={1} my={'25px'}>
                        I HAVE READ OR HAD READ TO ME AND FULLY UNDERSTAND THIS CONSENT; I HAVE HAD THE OPPORTUNITY TO ASK QUESTIONS AND HAD THESE QUESTIONS
                        ADDRESSED.
                    </Typography>
                </section>
            </Stack>
            <SignatureTypeTabs />
            <Divider />
            <Box px={2} py={4} textAlign={'left'}>
                {/*Member Signature Box */}
                {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Patient && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="member-name"
                                    error={digitalSignatureForm.MemberName.length === 0}
                                    variant="standard"
                                    value={digitalSignatureForm.MemberName}
                                    label={`Name`}
                                    fullWidth
                                    onChange={(e) => {
                                        updateDigitalSignatureForm('MemberName', e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField variant="standard" value={`${moment().format('MM-DD-YYYY')}`} label={`Date`} fullWidth />
                            </Grid>
                        </Grid>
                        <Signature
                            isSpanish={false}
                            buttonLabel={'Signature'}
                            isOpen={openMemberSignatureOne}
                            setIsOpen={setOpenMemberSignatureOne}
                            signature={digitalSignatureForm.MemberSignatureOne}
                            setSignature={(signature: string) =>
                                dispatch(
                                    setDigitalSignatureForm({
                                        ...digitalSignatureForm,
                                        MemberSignatureOne: signature,
                                    })
                                )
                            }
                        />
                    </>
                )}
                {/*Guardian or Caregiver Signature Box */}
                {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.GuardianOrCaregiver && (
                    <GuardianOrCaregiverInput
                        isSpanish={false}
                        openGuardianOrCaregiver={openGuardianOrCareGiverOne}
                        setOpenGuardianOrCaregiver={setOpenGuardianOrCareGiverOne}
                        guardianOrCareGiver={digitalSignatureForm.GuardianOrCaregiverSignatureOne}
                        setGuardianOrCareGiver={(signature: string) =>
                            dispatch(
                                setDigitalSignatureForm({
                                    ...digitalSignatureForm,
                                    GuardianOrCaregiverSignatureOne: signature,
                                })
                            )
                        }
                        openGuardianOrCareGiverWitness={openGuardianOrCareGiverWitnessOne}
                        setOpenGuardianOrCareGiverWitness={setOpenGuardianOrCareGiverWitnessOne}
                        guardianOrCareGiverWitness={digitalSignatureForm.WitnessOne}
                        setGuardianOrCareGiverWitness={(signature: string) => updateDigitalSignatureForm('WitnessOne', signature)}
                    />
                )}
                {/*Verbal consent Signature Box */}
                {digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Verbal && (
                    <>
                        {/* Verbal Consent */}
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.VerbalConsent}
                                        onChange={() =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsent: !digitalSignatureForm.VerbalConsent,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography>
                                        Verbal consent is required if member or legal representative is unable to physically sign at this time and yet consent
                                        for care has been explicitly agreed to.
                                        <b> Verbal consent must be witnessed by two people telephonically or in person.</b>
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={digitalSignatureForm.ReviewedOne}
                                        onChange={() =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    ReviewedOne: !digitalSignatureForm.ReviewedOne,
                                                })
                                            )
                                        }
                                    />
                                }
                                label={
                                    <Typography>
                                        <b> As the Provider, I have reviewed this page of the consent form with the member</b>
                                    </Typography>
                                }
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        helperText="Witness Digital Signature"
                                        sx={{ mt: 5 }}
                                        fullWidth
                                        label={'Witness One'}
                                        value={digitalSignatureForm.VerbalConsentWitnessOne}
                                        onChange={(e) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsentWitnessOne: e.target.value,
                                                })
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        helperText="Witness Digital Signature"
                                        sx={{ mt: 5 }}
                                        fullWidth
                                        label={'Witness Two'}
                                        value={digitalSignatureForm.VerbalConsentWitnessTwo}
                                        onChange={(e) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsentWitnessTwo: e.target.value,
                                                })
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Signature
                                        isSpanish={false}
                                        buttonLabel={'Witness Signature'}
                                        isOpen={openVerbalConsent}
                                        setIsOpen={setOpenVerbalConsent}
                                        signature={digitalSignatureForm.VerbalConsentSignature}
                                        setSignature={(signature: string) =>
                                            dispatch(
                                                setDigitalSignatureForm({
                                                    ...digitalSignatureForm,
                                                    VerbalConsentSignature: signature,
                                                })
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ConsentPartOneEnglish;
