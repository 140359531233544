export enum HealthEvaluationResponseEnum {
    WorseDescription = 'Worse',
    Worse = 1,
    SomewhatWorseDescription = 'Somewhat Worse',
    SomewhatWorse = 2,
    SameDescription = 'Same',
    Same = 3,
    SlightyBetterDescription = 'Slighty Better',
    SlightyBetter = 4,
    BetterDescription = 'Better',
    Better = 5,
}
