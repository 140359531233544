import React, { useEffect, useState, useRef } from 'react';
import { Paper, Card, CardContent, TextField, IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'simplebar/dist/simplebar.min.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router';
import '../../Widgets.css';

import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';
import { useWidgetContext } from '../../../../hooks/widgetContext';
import { fetchFuhFumQualityMetrics } from '../../../Widgets/WidgetService';

const dayFilterList = [
    { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
    { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
    { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
];

const FuhFumQualityMetrics = () => {
    const history = useNavigate();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [qualityMetrics, setQualityMetrics] = useState<any>({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const {
        qualityMetricsDateFilter,
        setQualityMetricsDateFilter,
        qualityMetricsFromDate,
        setQualityMetricsFromDate,
        qualityMetricsToDate,
        setQualityMetricsToDate,
        setQualityMetricsTab,
    } = useWidgetContext();

    const handleClick = (event) => {
        setShow(!show);
    };

    useEffect(() => {
        if (qualityMetricsDateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(qualityMetricsFromDate) && Boolean(qualityMetricsToDate))) {
            setIsFetched(false);
            const changeFilter = async () => {
                let dateFrom =
                    Boolean(qualityMetricsFromDate) && qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(qualityMetricsFromDate).toISOString()
                        : '';
                let dateTo =
                    Boolean(qualityMetricsToDate) && qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom
                        ? new Date(qualityMetricsToDate).toISOString()
                        : '';

                const data = await fetchFuhFumQualityMetrics(qualityMetricsDateFilter, dateFrom, dateTo);
                setQualityMetrics(data);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (qualityMetricsDateFilter !== WidgetTimeFilterEnum.Custom && Boolean(qualityMetricsFromDate) && Boolean(qualityMetricsToDate)) {
            setQualityMetricsFromDate(null);
            setQualityMetricsToDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setQualityMetrics, setIsFetched, qualityMetricsDateFilter, qualityMetricsFromDate, qualityMetricsToDate]);

    const changeFromDate = (date: any) => {
        setQualityMetricsFromDate(date);
    };

    const changeToDate = (date: any) => {
        setQualityMetricsToDate(date);
    };

    const navigateToMembers = (tab: any) => {
        setQualityMetricsTab(tab);
        history(`/provider/metricsummary/fuhfumqualitymetricsdetail`);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={() => navigateToMembers(1)}>
                                    FUHFUM Quality Metrics
                                </span>
                                <Tooltip open={show} title={<div>Quality metrics outcomes</div>} PopperProps={{ style: { zIndex: 1000 } }}>
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={qualityMetricsDateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setQualityMetricsDateFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {qualityMetricsDateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(qualityMetricsFromDate) ? qualityMetricsFromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(qualityMetricsToDate) ? qualityMetricsToDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} minHeight="45vh" sx={{ position: 'relative' }}>
                            {!isFetched ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Grid container spacing={2} marginTop={1} textAlign="center" sx={{ position: 'relative' }}>
                                    <Grid item xs={4}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 2,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(1)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                In-progress vs referred members percentage
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h5" fontWeight="bold" color="#06a106">
                                                    {qualityMetrics.InProgressVsReferredPercentage}%
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 2,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(2)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                Licensed provider visits within 7 days of discharge
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h4" fontWeight="bold" color="#ff8452">
                                                    {qualityMetrics.LicensedVisitsWithin7Days}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 2,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(3)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                Licensed provider visits within 14 days of discharge
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h4" fontWeight="bold" color="#ff0000">
                                                    {qualityMetrics.LicensedVisitsWithin14Days}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 3,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(4)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                Licensed provider visits within 30 days of discharge
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h4" fontWeight="bold" color="#bb285b">
                                                    {qualityMetrics.LicensedVisitsWithin30Days}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 2,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(5)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                Readmissions within 30 days of discharge
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h4" fontWeight="bold" color="#5427ac">
                                                    {qualityMetrics.ReadmissionsWithin30Days}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Card
                                            elevation={10}
                                            sx={{
                                                maxHeight: '150px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: 3,
                                                justifyContent: 'space-between',
                                            }}
                                            className="pointer"
                                            onClick={() => navigateToMembers(6)}
                                        >
                                            <Typography variant="body1" fontWeight={'bold'}>
                                                # Members enrolled in the full program
                                            </Typography>
                                            <CardContent>
                                                <Typography variant="h4" fontWeight="bold" color="#0da2d4">
                                                    {qualityMetrics.EnrolledInFullProgramCount}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default FuhFumQualityMetrics;
