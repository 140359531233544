import BaseEntity from '../BaseEntity.model';
import CreatedBy from '../Shared/LastUpdatedBy.model';
import { ExpenseItem } from './ExpenseItem.model';
import { FoodExpense } from './FoodExpense.model';
import { HouseHoldExpense } from './HouseHoldExpense.model';
import { Income } from './Income.model';
import { MedicineExpense } from './MedicineExpense.model';
import { UtilityExpense } from './UtilityExpense.model';

export default interface IMemberBudget extends BaseEntity {
    MemberId: string;
    Income: Income;
    RentExpenses: ExpenseItem[];
    UtilityExpenses: UtilityExpense[];
    FoodExpense: FoodExpense;
    MedicineExpense: MedicineExpense;
    FamilyExpenses: ExpenseItem[];
    HouseHoldExpenses: HouseHoldExpense[];
    TotalIncome: number;
    TotalExpenses: number;
    SpendingMoney: number;
    CreatedDate: Date;
    CreatedBy: CreatedBy;
    UpdatedDate: Date;
}

export class MemberBudget implements IMemberBudget {
    Id: string | null;
    MemberId: string;
    Income: Income;
    RentExpenses: ExpenseItem[];
    UtilityExpenses: UtilityExpense[];
    FoodExpense: FoodExpense;
    MedicineExpense: MedicineExpense;
    FamilyExpenses: ExpenseItem[];
    HouseHoldExpenses: HouseHoldExpense[];
    TotalIncome: number;
    TotalExpenses: number;
    SpendingMoney: number;
    CreatedDate: Date;
    CreatedBy: CreatedBy;
    UpdatedDate: Date;

    constructor() {
        this.Id = null;
        this.MemberId = '';
    }
}
