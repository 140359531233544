import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../hooks/populationContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { configureStackedBarChartWithoutLegend } from '../../ChartConfiguration/ChartConfiguration';
import { getBmiSummary } from '../HedisMetricService';
import BmiSummary from '../../../Models/HedisMetrics/BmiSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import FilterOptions from '../FilterOptions';
import '../../Widgets/Widgets.css';

const BmiSummaries = () => {
    const history = useNavigate();
    const { populations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [bmiSummary, setBmiSummary] = useState<BmiSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const {
        bmiPopulation,
        setBmiPopulation,
        bmiTeam,
        setBmiTeam,
        bmiDateFilter,
        setBmiDateFilter,
        bmiFromDate,
        setBmiFromDate,
        bmiToDate,
        setBmiToDate,
        bmiIncludePastOpenStatus,
        setBmiIncludePastOpenStatus,
        bmiShowInActiveTeam,
        setBmiShowInActiveTeam,
        setHedisType,
        setPop,
        setTeam,
        setFilter,
        setFDate,
        setTDate,
        setIncludePastOpenStatus,
        setShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartWithoutLegend('BMI Summary', 'Completed vs Not Completed', 'Population', 'Count', [{ name: '', data: [0] }], [0])
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched && (bmiDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(bmiFromDate) && Boolean(bmiToDate)))) {
            const changeFilter = async () => {
                let start = bmiFromDate !== null ? new Date(bmiFromDate).toDateString() : null;
                let end = bmiToDate !== null ? new Date(bmiToDate).toDateString() : null;

                const data = await getBmiSummary(bmiPopulation, bmiTeam, bmiDateFilter, start, end, bmiIncludePastOpenStatus, bmiShowInActiveTeam);
                setBmiSummary(data);

                setIsPrepared(true);
            };
            if ((bmiFromDate !== null || bmiToDate !== null) && bmiToDate < bmiFromDate) {
            } else {
                setChartOptions(
                    configureStackedBarChartWithoutLegend(
                        'BMI Summary',
                        'Completed vs Not Completed',
                        'Population',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bmiPopulation, bmiTeam, bmiDateFilter, bmiFromDate, bmiToDate, isPopulationFetched, bmiIncludePastOpenStatus, bmiShowInActiveTeam]);

    useEffect(() => {
        if (bmiSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            let yVal;

            yValues = [];
            yVal = {
                y: bmiSummary.BMICompleted,
                name: 'BMI Completed',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'BMI Completed', data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });

            yValues = [];
            yVal = {
                y: bmiSummary.NoBMIDocumented,
                name: 'No BMI Documented',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'No BMI Documented', data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });

            if (bmiPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = populations.find((p) => p.value === bmiPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartWithoutLegend('BMI Summary', '', '', 'Count', yAxisValues, xAxisValues, navigateToMembers));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bmiSummary, bmiPopulation, populations, isPrepared]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToMembers = () => {
        setPop(bmiPopulation);
        setTeam(bmiTeam);
        setFilter(bmiDateFilter);
        setFDate(bmiFromDate);
        setTDate(bmiToDate);
        setIncludePastOpenStatus(bmiIncludePastOpenStatus);
        setShowInActiveTeam(bmiShowInActiveTeam);
        setHedisType(HedisMetricsTypeEnum.BMI);
        history(`/provider/metricsummary/hedismemberdetail/BMI`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            BMI
                        </Typography>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">For all adults 18-74, within a rolling 12 months</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        BMI Completed :
                                    </Typography>

                                    <Typography variant="caption">
                                        Any of the ICD 10 codes - Z38.20, Z68, Z68.1, Z68.2, Z68.20, Z68.21, Z68.22, Z68.23, Z68.24, Z68.25, Z68.26, Z68.27,
                                        Z68.28, Z68.29, Z68.3, Z68.30, Z68.31, Z68.32, Z68.33, Z68.34, Z68.35, Z68.36, Z68.37, Z68.38, Z68.39, Z68.4, Z68.41,
                                        Z68.42, Z68.43, Z68.44, Z68.45, Z68.5, Z68.51, Z68.52, Z68.53, Z68.54
                                    </Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        No BMI Documented :
                                    </Typography>
                                    <Typography variant="caption">No CPT codes recorded</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FilterOptions
                        selectedTeam={bmiTeam}
                        setSelectedTeam={setBmiTeam}
                        dateFilter={bmiDateFilter}
                        setDateFilter={setBmiDateFilter}
                        fromDate={bmiFromDate}
                        setFromDate={setBmiFromDate}
                        toDate={bmiToDate}
                        setToDate={setBmiToDate}
                        population={bmiPopulation}
                        setPopulation={setBmiPopulation}
                        includePastOpenStatusMembers={bmiIncludePastOpenStatus}
                        setIncludePastOpenStatusMembers={setBmiIncludePastOpenStatus}
                        hedisType={HedisMetricsTypeEnum.BMI}
                        showInActiveTeams={bmiShowInActiveTeam}
                        setShowInActiveTeams={setBmiShowInActiveTeam}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default BmiSummaries;
