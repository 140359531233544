import BaseEntity from '../BaseEntity.model';

export default interface IAddToCallList extends BaseEntity {
    LastName: string;
    FirstName: string;
    DateOfBirth: Date | null;
    AHCCCSID: string;
}

export default class AddToCallList implements IAddToCallList {
    LastName: string;
    FirstName: string;
    DateOfBirth: Date | null;
    AHCCCSID: string;

    constructor() {
        this.LastName = '';
        this.FirstName = '';
        this.DateOfBirth = null;
        this.AHCCCSID = '';
    }
}
