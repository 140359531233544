import axios from 'axios';
import { apiString as api } from '../../utils/constants';

export const fetchCallerPerformance = async (onboarderId: string) => {
    const response = await axios.get(`${api}/onboarding/GetOnboarderCallerStatsForAdmin/${onboarderId}`);
    return response;
};
export const fetchPerformanceExcelReport = async (onboarderStatusId: string, dayRange: string) => {
    const response = await axios.get(`${api}/onboarding/GetPerformanceReport/${onboarderStatusId}/${dayRange}`);
    return response;
};
export const fetchDetailedCallHistoryReport = async (onboarderStatusId: string, dayRange: string) => {
    const response = await axios.get(`${api}/onboarding/GetDetailedCallHistoryReport/${onboarderStatusId}/${dayRange}`);
    return response;
};
