import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';
import { FactorAndRiskAssessmentItem } from './FactorAndRiskAssessmentItem.model';

export default interface IFactorAndRiskAssessment extends BaseEntity {
    MemberId: string;
    MemberName: string;
    LifeContext: FactorAndRiskAssessmentItem;
    HealthRisk: FactorAndRiskAssessmentItem;
    LearningLiteracy: FactorAndRiskAssessmentItem;
    StrengthOrAsset: FactorAndRiskAssessmentItem;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
    Status: StatusEnum;
}

export class FactorAndRiskAssessment implements IFactorAndRiskAssessment {
    Id: string;
    MemberId: string;
    MemberName: string;
    LifeContext: FactorAndRiskAssessmentItem;
    HealthRisk: FactorAndRiskAssessmentItem;
    LearningLiteracy: FactorAndRiskAssessmentItem;
    StrengthOrAsset: FactorAndRiskAssessmentItem;
    CreatedDate: string;
    CreatedBy: string;
    UpdatedDate: string;
    UpdatedBy: string;
    Status: StatusEnum;

    constructor() {
        this.Id = '';
        this.MemberId = '';
        this.MemberName = '';
        this.LifeContext = new FactorAndRiskAssessmentItem();
        this.HealthRisk = new FactorAndRiskAssessmentItem();
        this.LearningLiteracy = new FactorAndRiskAssessmentItem();
        this.StrengthOrAsset = new FactorAndRiskAssessmentItem();
        this.Status = StatusEnum.InProgress;
    }
}
