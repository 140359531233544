import { Typography, Card, CardContent, IconButton, Stack, Chip } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { formatAppointmentTime, formatDateWithMonthName } from '../../utils/timeFormat';
import { toPascalCase } from '../../utils/common';
import { parentUrl } from '../../utils/constants';

const cardItemStyle = {
    fontSize: '14px',
    paddingTop: '5px',
};

const handleClick = (memberId: string) => {
    let url = `${parentUrl}provider/members-list/member-chart/${memberId}/member-profile`;
    window.location.href = url;
};

const AppointmentMobileView = (props) => {
    return (
        <Card variant="outlined" style={{ margin: '16px' }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-between' }}>
                <Typography variant="body1" sx={cardItemStyle} onClick={() => handleClick(props?.appointment?.MemberId)} style={{ cursor: 'pointer' }}>
                    Appointment with <strong>{toPascalCase(props?.appointment?.MemberName)}</strong> on
                    <strong> {formatDateWithMonthName(new Date(props?.appointment?.AppointmentDateTime))}</strong> at
                    <strong> {formatAppointmentTime(new Date(props?.appointment?.AppointmentDateTime))}</strong> -
                    <strong> {formatAppointmentTime(new Date(props?.appointment?.AppointmentEndDateTime))}</strong>
                    <>{props.selectedProvider === 'All' ? <> ({toPascalCase(props?.appointment?.ProviderName)})</> : null} </>
                </Typography>
                <Typography variant="body1" sx={cardItemStyle} onClick={() => handleClick(props?.appointment?.MemberId)} style={{ cursor: 'pointer' }}>
                    <Stack style={{ float: 'left', width: '65%' }}>
                        <Typography variant="body1" sx={cardItemStyle}>
                            {Boolean(props?.appointment?.Address) ? toPascalCase(props?.appointment?.Address) : props?.appointment?.Address}
                        </Typography>
                    </Stack>
                    <Stack style={{ float: 'right', paddingTop: '10px' }}>
                        <Chip
                            label={props?.appointment?.StatusString}
                            sx={{ backgroundColor: props?.appointment?.StatusString === 'Confirmed' ? '#17A2B8' : '#EB984E ', color: 'white', height: '28px' }}
                        />
                    </Stack>
                </Typography>
                <Typography variant="body1" sx={cardItemStyle}>
                    <>
                        {props?.appointment?.Lat && props?.appointment?.Lon ? (
                            <>
                                <Stack style={{ float: 'left' }}>
                                    <IconButton color="primary" onClick={props.copyMemberDetail} style={{ paddingLeft: '0px' }}>
                                        <ContentCopyRoundedIcon style={{ fontSize: '20px' }} />
                                        <Typography style={{ fontSize: '13px' }}> Copy Member Detail</Typography>
                                    </IconButton>
                                </Stack>
                                <Stack style={{ float: 'right' }}>
                                    <IconButton color="primary" onClick={props.openGoogleMaps} style={{ paddingRight: '0px' }}>
                                        <MapIcon style={{ fontSize: '20px' }} />
                                        <Typography style={{ fontSize: '13px', paddingLeft: '2px' }}> View in Google Maps</Typography>
                                    </IconButton>
                                </Stack>
                            </>
                        ) : (
                            <IconButton color="primary" onClick={props.copyMemberDetail} style={{ paddingLeft: '0px' }}>
                                <ContentCopyRoundedIcon style={{ fontSize: '20px' }} />
                                <Typography style={{ fontSize: '13px' }}> Copy Member Detail</Typography>
                            </IconButton>
                        )}
                    </>
                </Typography>
            </CardContent>
        </Card>
    );
};

export default AppointmentMobileView;
