import React, { useCallback, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { fetchCleanClaimsChart, MenuProps } from '../../Widgets/WidgetService';
import { configureStackedSplitBarChart } from '../../ChartConfiguration/ChartConfiguration';
import EncounterSummary from '../../../Models/Widget/EncounterSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import Authorization from '../../../utils/Authorization';
import '../Widgets.css';

const CleanClaimSummary = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState<string>('All');
    const [selectedPlan, setSelectedPlan] = useState<string>('All');
    const [encounterOptions, setEncounterOptions] = useState({});
    const [isEncounterGraphShow, setIsEncounterGraphShow] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState<number>(2);
    const { healthPlans } = useHealthPlanContext();
    const [encounterSummary, setEncounterSummary] = useState<EncounterSummary>();
    const [selectedTeam, setSelectedTeam] = useState('All');
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const dateFilterList = [
        { Id: 1, Value: 'Last 3 Months' },
        { Id: 2, Value: 'Last 6 Months' },
        { Id: 3, Value: 'Last 1 Year' },
        { Id: 4, Value: 'Custom' },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isEncounterGraphShow) {
            setEncounterOptions(
                configureStackedSplitBarChart(
                    'Clean Claim Summary',
                    'Claims Count',
                    'Month',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    '',
                    false
                )
            );
        }

        if (Boolean(authData.TeamId)) {
            setSelectedTeam(authData.TeamId);
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isEncounterGraphShow, authData.TeamId]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (selectedFilter !== TimePeriodFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate))) {
            const changeFilter = async () => {
                let start = fromDate !== null ? new Date(fromDate).toDateString() : null;
                let end = toDate !== null ? new Date(toDate).toDateString() : null;

                const data = await fetchCleanClaimsChart(
                    selectedProvider,
                    selectedTeam,
                    selectedPlan,
                    selectedFilter,
                    start,
                    end,
                    showInActiveTeams,
                    showInActiveProviders
                );
                setEncounterSummary(data);
            };

            setIsEncounterGraphShow(false);
            setEncounterOptions(
                configureStackedSplitBarChart(
                    'Clean Claim Summary',
                    'Claims Count',
                    'Month',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    '',
                    false
                )
            );
            changeFilter();
        }

        if (selectedFilter !== TimePeriodFilterEnum.Custom && Boolean(fromDate) && Boolean(toDate)) {
            setFromDate(null);
            setToDate(null);
        }
    }, [selectedProvider, selectedPlan, selectedFilter, fromDate, toDate, selectedTeam, showInActiveTeams, showInActiveProviders]);

    useEffect(() => {
        if (encounterSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let totClaimsValues = [];
            let cleanClaimsValues = [];
            for (var data in encounterSummary.Encounters) {
                let dataInfo = encounterSummary.Encounters[data];
                let percentage = dataInfo.EncounterCount > 0 ? (dataInfo.CleanClaimCount / dataInfo.EncounterCount) * 100 : 0;

                let d = {
                    y: dataInfo.CleanClaimCount,
                    name: Math.round(percentage),
                };
                cleanClaimsValues.push(d);

                let d1 = {
                    y: dataInfo.EncounterCount,
                    name: '',
                };
                totClaimsValues.push(d1);

                xAxisValues.push(dataInfo.Date);
            }
            yAxisValues.push({ name: 'Total Claims', data: totClaimsValues, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Clean Claims', data: cleanClaimsValues, color: '#00a652', showInLegend: true });

            if (encounterSummary.Encounters.length === 0) {
                setEncounterOptions(
                    configureStackedSplitBarChart(
                        'Clean Claim Summary',
                        'Claims Count',
                        'Month',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        '',
                        '',
                        false
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setEncounterOptions(
                    configureStackedSplitBarChart('Encounter Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, '', '', false)
                );
            }
            setIsEncounterGraphShow(true);
        }
    }, [encounterSummary]);

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setFromDate(startDate);
        }
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center" ref={ref}>
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                                Clean Claim Summary
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="body2">Total submitted claims vs clean claims</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Clean Claim :
                                            </Typography>
                                            <Typography variant="caption">Claims which is accepted and acknowledged by payer</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={selectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select value={selectedTeam} label="Team" name="Team" onChange={(e) => setSelectedTeam(e.target.value)} MenuProps={MenuProps}>
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Health Plan</InputLabel>
                                <Select
                                    value={selectedPlan}
                                    label="Health Plan"
                                    name="plan"
                                    onChange={(e) => setSelectedPlan(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {filteredHealthPlans.map((plan) => (
                                        <MenuItem key={plan.Id} value={plan.Id}>
                                            {plan.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={selectedFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setSelectedFilter(parseInt(e.target.value as any))}
                                >
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {selectedFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(fromDate) ? fromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(toDate) ? toDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isEncounterGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={encounterOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default CleanClaimSummary;
