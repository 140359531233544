import { getMobiscrollDisplayText } from '@catalytic-health-partners/common-components';
import { MbscCalendarEventData } from '@mobiscroll/react';
import { Repeat } from '@mui/icons-material';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Chip, Stack, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { RRule } from 'rrule';
import { appointmentTypeMap } from '../../utils/mappings';

export interface IEventLabelProps {
    event: MbscCalendarEventData & { IsOffboarding?: boolean; LastIntegratedCareSwap?: Date };
}

const EventLabel: React.FC<IEventLabelProps> = (props) => {
    const { event } = props;

    const { recurring = '' } = event?.original;

    const recurrenceText = useMemo(() => {
        const rrule = RRule.fromString((recurring as string) ?? '');
        return getMobiscrollDisplayText(rrule).toLocaleLowerCase();
    }, [recurring]);

    let recurrenceIcon = null;
    if (event.original.recurring) {
        recurrenceIcon = (
            <Tooltip title={`Happens ${recurrenceText}`} placement="top">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 18, // Adjust this value as needed
                        height: 15, // Adjust this value as needed
                        borderRadius: '4px',
                        color: 'white',
                    }}
                >
                    <Repeat
                        sx={{ transform: 'scale(0.6)' }} // Adjust this value as needed
                    />
                </Box>
            </Tooltip>
        );
    }

    let swappedIcon = null;
    if (event.original.appointmentType === appointmentTypeMap.Integrated.value) {
        swappedIcon = (
            <Tooltip title={`Integrated care appointment`} placement="top">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 18, // Adjust this value as needed
                        height: 15, // Adjust this value as needed
                        borderRadius: '4px',
                        color: 'white',
                    }}
                >
                    <IntegrationInstructionsIcon
                        sx={{ transform: 'scale(0.6)' }} // Adjust this value as needed
                    />
                </Box>
            </Tooltip>
        );
    }

    let offboardIcon = null;
    if (event.original.IsOffboarding) {
        offboardIcon = <Chip sx={{ maxHeight: 14, fontSize: 8 }} size="small" label="Offboarding" color="warning" />;
    }
    return (
        <Stack direction={'row'} spacing={0} alignItems="center" justifyContent={'flex-start'}>
            {event.original.HasWarningFlag ? <WarningIcon sx={{ fontSize: 'inherit', color: 'red' }} /> : null}
            {recurrenceIcon}
            {swappedIcon}
            {offboardIcon}
            <span style={{ paddingLeft: 2 }}>{event.title}</span>
        </Stack>
    );
};

export default EventLabel;
