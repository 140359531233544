import React, { useState } from 'react';
import { Grid, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { usePopulationContext } from '../../hooks/populationContext';
import { getDateFilter } from './HedisMetricService';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePeriodFilterEnum } from '../../Enum/TimePeriodFilterEnum';
import Authorization from '../../utils/Authorization';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import b64toBlob from 'b64-to-blob';

import { exportHedisDetail } from '../../components/HedisMetrics/HedisMetricService';
import { HedisMetricsTypeEnum } from '../../Enum/HedisMetricsTypeEnum';

const FilterOptions = (props) => {
    const {
        selectedTeam,
        setSelectedTeam,
        dateFilter,
        setDateFilter,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        population,
        setPopulation,
        includePastOpenStatusMembers,
        setIncludePastOpenStatusMembers,
        isDetailPage,
        hedisType,
        showInActiveTeams,
        setShowInActiveTeams,
    } = props;
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { analyticPopulations } = usePopulationContext();
    const dateFilterList = getDateFilter().filter((d) => d.Value !== 'Custom' && d.Value !== 'Current Month');
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const showProviders = authData.Role === 'CEO' ? true : false;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 50 * 4.5,
                width: 250,
            },
        },
    };

    const changeDateFilter = (filter: any) => {
        setFromDate(null);
        setToDate(null);
        setDateFilter(parseInt(filter));
    };

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = new Date(dateInfo.setDate(dateInfo.getDate() + 30));
            setToDate(endDate);
        }
        setDateFilter(TimePeriodFilterEnum.Custom);
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = new Date(dateInfo.setDate(dateInfo.getDate() - 30));
            setFromDate(startDate);
        }
        setDateFilter(TimePeriodFilterEnum.Custom);
    };

    const handleOnIncludePastStatus = (isChecked: boolean) => {
        setIncludePastOpenStatusMembers(isChecked);
    };

    const exportDetail = async () => {
        setDownLoading(true);
        let start = fromDate !== null ? new Date(fromDate).toDateString() : null;
        let end = toDate !== null ? new Date(toDate).toDateString() : null;

        const data = await exportHedisDetail(hedisType, population, selectedTeam, dateFilter, start, end, includePastOpenStatusMembers, showInActiveTeams);
        let type = hedisType === 'ProviderVisits' ? HedisMetricsTypeEnum.ProviderVisitsDescription : hedisType;
        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `${type} - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container ml={0} mt={0} spacing={2}>
                <Grid item xs={isDetailPage ? 2 : 3}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Population</InputLabel>
                        <Select
                            value={population}
                            label="Population"
                            name="Population"
                            onChange={(e) => setPopulation(parseInt(e.target.value))}
                            MenuProps={MenuProps}
                        >
                            <MenuItem key="All" value="0">
                                All
                            </MenuItem>
                            {analyticPopulations.map((pop) => (
                                <MenuItem key={pop.value} value={pop.value}>
                                    {pop.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {showProviders && (
                    <Grid item xs={isDetailPage ? 2 : 3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select value={selectedTeam} label="Team" name="Team" onChange={(e) => setSelectedTeam(e.target.value)} MenuProps={MenuProps}>
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={isDetailPage ? 2 : 3}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Date Filter</InputLabel>
                        <Select value={dateFilter} label="Date Filter" name="Date" onChange={(e) => changeDateFilter(e.target.value)}>
                            {dateFilterList.map((date) => (
                                <MenuItem key={date.Id} value={date.Id}>
                                    {date.Value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {parseInt(dateFilter) === TimePeriodFilterEnum.Custom ? (
                    <React.Fragment>
                        <Grid item xs={isDetailPage ? 1.5 : 3}>
                            <DesktopDatePicker
                                label="From Date"
                                value={Boolean(fromDate) ? fromDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => changeFromDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        <Grid item xs={isDetailPage ? 1.5 : 3}>
                            <DesktopDatePicker
                                label="To Date"
                                value={Boolean(toDate) ? toDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => changeToDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        {isDetailPage ? (
                            <Grid item xs={1}>
                                <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                                    {downLoading ? 'Exporting..' : 'Export'}
                                </Button>
                            </Grid>
                        ) : null}
                    </React.Fragment>
                ) : isDetailPage ? (
                    <React.Fragment>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={1}>
                            <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                                {downLoading ? 'Exporting..' : 'Export'}
                            </Button>
                        </Grid>
                    </React.Fragment>
                ) : null}
                <Grid item xs={6} md={6} lg={6} pt={3} style={{ textAlign: 'start' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={includePastOpenStatusMembers}
                                size="small"
                                name="Include Past Open Status Members"
                                onChange={(e) => handleOnIncludePastStatus(e.target.checked)}
                            />
                        }
                        label="Include members who ever been in any open status"
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default FilterOptions;
