import { Box, CircularProgress, Grid, Tooltip, Typography, Button, Stack, IconButton } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import { localDateFormat } from '../../../utils/timeFormat';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import Chip from '@mui/material/Chip';
import ConfirmDelete from '../../Shared/ConfirmDelete';
import {
    deleteMemberEducation,
    getEducationSessionCategories,
    getMemberEducationList,
    setSelectedMemberEducation,
    toggleShowEducationDeleteConfimation,
    toggleShowEducationSessionModal,
} from '../../../store/memberEducation.slice';
import { RootState } from '../../../reducers';
import { EducationStatusEnum } from '../../../Enum/EducationStatusEnum';
import AddOrUpdateEducationSession from './AddOrUpdateEducationSession';
import { MemberEducationModel } from '../../../Models/MemberEducations/MemberEducation.model';
import { EducationStatuses, EducationTimeDurations, LevelOfTeachbacks, LevelOfUnderstandings } from '../../../utils/mappings';
import { copyObject } from '../../../utils/common';
import { isDefaultDate } from '../../../utils/common';

const EducationSessions = (props: any) => {
    const memberId = props.memberId;
    const isAssessment = Boolean(props.isAssessment);
    const dispatch = useDispatch();
    const [memberToDelete, setMemberToDelete] = useState(null);
    const { memberEducationsByType, categories, memberEducationIdForDelete, showDeleteConfimation, isDeleting, isFetching } = useSelector(
        (state: RootState) => state.memberEducation
    );

    const getChipColor = (status: EducationStatusEnum) => {
        let color = '';

        switch (status) {
            case EducationStatusEnum.AccomplishedDescription:
                color = '#2e7d32';
                break;
            case EducationStatusEnum.InProgressDescription:
                color = '#ed6c02';
                break;
            case EducationStatusEnum.DeclinedDescription:
                color = '#d32f2f';
                break;
        }

        return color;
    };

    const columns: GridColDef[] = [
        {
            field: 'TypeDescription',
            headerName: 'Description',
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 170,
            renderCell: (params) => {
                return (
                    <>
                        <Chip sx={{ background: getChipColor(params.row.Status), color: 'white' }} label={params.row.Status} />
                    </>
                );
            },
        },
        {
            field: 'Audience',
            headerName: 'Audience(s)',
            minWidth: 120,
        },
        {
            field: 'LevelOfTeachback',
            headerName: 'Level of Teachback',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'LevelOfUnderstanding',
            headerName: 'Level of Understanding',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'Provider',
            headerName: 'Provider',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${params.row.CreatedBy?.Name}`,
        },
        {
            field: 'Date',
            headerName: 'Date',
            minWidth: 130,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.CreationTime) ? '-' : localDateFormat(new Date(params.row.CreationTime))}`,
        },
        {
            field: 'TimeDuration',
            headerName: 'Time Duration',
            minWidth: 130,
        },
        {
            field: 'Notes',
            headerName: 'Notes',
            minWidth: 50,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            <Box p={1}>
                                <Typography variant="body2">Notes:</Typography>
                                <Typography variant="body1">{params.row.Notes}</Typography>
                                <Typography marginTop={5} variant="body2">
                                    Were education materials or supplies provided?
                                </Typography>
                                <Typography variant="body1">{params.row.WereMaterialsSupplied ? 'Yes' : 'No'}</Typography>
                            </Box>
                        }
                    >
                        <CommentIcon
                            color="info"
                            style={{
                                marginLeft: '24px',
                                cursor: 'pointer',
                            }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            minWidth: 100,
            hide: !isAssessment,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        <Tooltip title="Edit" placement="top">
                            <EditIcon
                                color="primary"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleOnShowEducationSessionModal(params.row)}
                            />
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                            <DeleteIcon
                                color="error"
                                style={{
                                    marginLeft: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    dispatch(toggleShowEducationDeleteConfimation(true, params.row.Id));
                                    setMemberToDelete(params.row.TypeDescription);
                                }}
                            />
                        </Tooltip>
                    </React.Fragment>
                );
            },
        },
    ];

    const handleOnShowEducationSessionModal = (educationSession?: MemberEducationModel, option?: string) => {
        if (Boolean(educationSession)) {
            let educationSessionData = copyObject(educationSession) as MemberEducationModel;
            if (typeof educationSessionData.Audience === 'string') {
                educationSessionData.Audience = String(educationSessionData.Audience).split(',');
            }

            if (typeof educationSessionData.Type === 'string') {
                categories.forEach((category) => {
                    const selectedOption = category.Options.find(
                        (l) => l.Id === String(educationSessionData.Type) || l.Name === String(educationSessionData.Type)
                    );
                    if (selectedOption) {
                        educationSessionData.Type = selectedOption;
                    }
                });
            }

            educationSessionData.LevelOfTeachback = LevelOfTeachbacks.find(
                (l) => l.Id === educationSession.LevelOfTeachback || l.Name === educationSession.LevelOfTeachback
            )?.Id;
            educationSessionData.LevelOfTeachback = LevelOfTeachbacks.find(
                (l) => l.Id === educationSession.LevelOfTeachback || l.Name === educationSession.LevelOfTeachback
            )?.Id;
            educationSessionData.LevelOfUnderstanding = LevelOfUnderstandings.find(
                (l) => l.Id === educationSession.LevelOfUnderstanding || l.Name === educationSession.LevelOfUnderstanding
            )?.Id;
            educationSessionData.Status = EducationStatuses.find((l) => l.Id === educationSession.Status || l.Name === educationSession.Status)?.Id;
            educationSessionData.TimeDuration = EducationTimeDurations.find(
                (l) => l.Id === educationSession.TimeDuration || l.Name === educationSession.TimeDuration
            )?.Id;
            dispatch(setSelectedMemberEducation(educationSessionData));
        } else {
            let newMemberEducation = new MemberEducationModel();
            if (Boolean(option)) {
                categories.forEach((category) => {
                    const selectedOption = category.Options.find((l) => l.Id === option || l.Name === option);
                    if (selectedOption) {
                        newMemberEducation.Type = selectedOption;
                    }
                });
            }

            dispatch(setSelectedMemberEducation(newMemberEducation));
        }

        dispatch(toggleShowEducationSessionModal(true));
    };

    useEffect(() => {
        dispatch(getMemberEducationList(memberId));
        dispatch(getEducationSessionCategories());
    }, [memberId, dispatch]);

    return (
        <Box style={props.isAssessment ? { padding: '0' } : {}}>
            <ConfirmDelete
                open={showDeleteConfimation}
                isDeleting={isDeleting}
                onClose={() => dispatch(toggleShowEducationDeleteConfimation(false))}
                name={memberToDelete}
                delete={() => dispatch(deleteMemberEducation(memberEducationIdForDelete))}
                cancel={() => dispatch(toggleShowEducationDeleteConfimation(false))}
            />
            {props.isAssessment ? (
                <Stack alignItems="flex-end">
                    <Button variant="outlined" onClick={() => handleOnShowEducationSessionModal()}>
                        Add Education Session
                    </Button>
                </Stack>
            ) : null}
            {Boolean(memberEducationsByType?.length) ? (
                memberEducationsByType.map((list, index) => {
                    return (
                        <Grid key={index} container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center">
                                    <Typography display={'inline-block'} marginRight={2} variant="h5" color={'primary'}>
                                        {list['Key']}
                                    </Typography>
                                    {Boolean(list.Values?.length) ? (
                                        <Typography display={'inline-block'} variant="h6" color={getChipColor(list.Values[0]?.Status)}>
                                            ({list.Values[0].Status})
                                        </Typography>
                                    ) : null}
                                    {props.isAssessment ? (
                                        <IconButton onClick={(e) => handleOnShowEducationSessionModal(null, list.Key)}>
                                            <AddCircleOutlineIcon fontSize="large" color="primary" />
                                        </IconButton>
                                    ) : null}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ width: '100%' }}>
                                    <DataGrid
                                        rows={list.Values || []}
                                        columns={columns}
                                        autoHeight
                                        rowsPerPageOptions={[5]}
                                        getRowId={(row) => row.Id}
                                        getRowHeight={() => 'auto'}
                                        sx={{
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1,
                                            },
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    );
                })
            ) : isFetching ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Typography display={'inline-block'} variant="h6">
                    No Education Sessions Found
                </Typography>
            )}
            <AddOrUpdateEducationSession memberId={memberId} />
        </Box>
    );
};

export default EducationSessions;
