import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { dictionaryToArray } from '../../../utils/mappings';
import { setHealthPlanForm } from '../../../store/adminHealthPlan.slice';

const OutcomeMetrics = () => {
    const dispatch = useDispatch();
    const { healthPlanForm } = useSelector((state: RootState) => state.adminHealthPlanSlice);

    const createArrayOfNumbers = (length) => {
        let list = [];
        for (let i = 0; i < length; i++) {
            list.push(i);
        }
        return list;
    };
    const getOMProperties = () => {
        return { Operator: 0, Value: '' };
    };

    const operatorItems = ['Select', 'Equal To (=)', 'Greater Than (>)', 'Less Than (<)', 'Greater Than or Equal To (>=)', 'Less Than or Equal To (<=)'];

    return (
        <>
            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.ConvertionRate ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            ConvertionRate: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Convertion Rate (%)"
                    />
                </FormControl>

                {healthPlanForm.ConvertionRate && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.ConvertionRate?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        ConvertionRate: {
                                            ...healthPlanForm.ConvertionRate,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.ConvertionRate && (
                    <FormControl fullWidth>
                        <TextField
                            value={healthPlanForm.ConvertionRate?.Value || ''}
                            label="Value"
                            name="Value"
                            type="number"
                            fullWidth
                            variant="outlined"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        ConvertionRate: {
                                            ...healthPlanForm.ConvertionRate,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        />
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.HgbA1CRate ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            HgbA1CRate: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="HgbA1C Rate(%)"
                    />
                </FormControl>

                {healthPlanForm.HgbA1CRate && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.HgbA1CRate?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        HgbA1CRate: {
                                            ...healthPlanForm.HgbA1CRate,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.HgbA1CRate && (
                    <FormControl fullWidth>
                        <TextField
                            value={healthPlanForm.HgbA1CRate?.Value || ''}
                            label="Value"
                            name="Value"
                            type="number"
                            fullWidth
                            variant="outlined"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        HgbA1CRate: {
                                            ...healthPlanForm.HgbA1CRate,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        />
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.FollowupAfterHospitalDischarge ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            FollowupAfterHospitalDischarge: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="F/U After Hospital DC Within"
                    />
                </FormControl>

                {healthPlanForm.FollowupAfterHospitalDischarge && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.FollowupAfterHospitalDischarge?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        FollowupAfterHospitalDischarge: {
                                            ...healthPlanForm.FollowupAfterHospitalDischarge,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.FollowupAfterHospitalDischarge && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Value</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            value={healthPlanForm.FollowupAfterHospitalDischarge?.Value || ''}
                            margin="dense"
                            label="Value"
                            name="Value"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        FollowupAfterHospitalDischarge: {
                                            ...healthPlanForm.FollowupAfterHospitalDischarge,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            <MenuItem value="1">7 Days</MenuItem>
                            <MenuItem value="2">30 Days</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.PhqRate ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            PhqRate: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="PHQ9 Greater Than 14 Follow Up (%)"
                    />
                </FormControl>

                {healthPlanForm.PhqRate && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.PhqRate?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        PhqRate: {
                                            ...healthPlanForm.PhqRate,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.PhqRate && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Value</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            value={healthPlanForm.PhqRate?.Value || ''}
                            margin="dense"
                            label="Value"
                            name="Value"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        PhqRate: {
                                            ...healthPlanForm.PhqRate,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            <MenuItem value="1">60</MenuItem>
                            <MenuItem value="2">65</MenuItem>
                            <MenuItem value="3">70</MenuItem>
                            <MenuItem value="4">75</MenuItem>
                            <MenuItem value="5">80</MenuItem>
                            <MenuItem value="6">85</MenuItem>
                            <MenuItem value="7">90</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.SdohRate ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            SdohRate: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="SDOH Assessment Completed (%)"
                    />
                </FormControl>

                {healthPlanForm.SdohRate && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.SdohRate?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        SdohRate: {
                                            ...healthPlanForm.SdohRate,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.SdohRate && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Value</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            value={healthPlanForm.SdohRate?.Value || ''}
                            margin="dense"
                            label="Value"
                            name="Value"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        SdohRate: {
                                            ...healthPlanForm.SdohRate,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            <MenuItem value="1">60</MenuItem>
                            <MenuItem value="2">65</MenuItem>
                            <MenuItem value="3">70</MenuItem>
                            <MenuItem value="4">75</MenuItem>
                            <MenuItem value="5">80</MenuItem>
                            <MenuItem value="6">85</MenuItem>
                            <MenuItem value="7">90</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.ReductionInEdVisits ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            ReductionInEdVisits: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Reduction in ED Visits (%)"
                    />
                </FormControl>

                {healthPlanForm.ReductionInEdVisits && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.ReductionInEdVisits?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        ReductionInEdVisits: {
                                            ...healthPlanForm.ReductionInEdVisits,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.ReductionInEdVisits && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Value</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            value={healthPlanForm.ReductionInEdVisits?.Value || ''}
                            margin="dense"
                            label="Value"
                            name="Value"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        ReductionInEdVisits: {
                                            ...healthPlanForm.ReductionInEdVisits,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                        >
                            {dictionaryToArray(createArrayOfNumbers(21)).map((term) => {
                                return (
                                    <MenuItem key={term.value} value={term.value}>
                                        {term.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.ReadmissionRateReduction ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            ReadmissionRateReduction: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="30 Day Readmission Rate Reduction (%)"
                    />
                </FormControl>

                {healthPlanForm.ReadmissionRateReduction && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.ReadmissionRateReduction?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        ReadmissionRateReduction: {
                                            ...healthPlanForm.ReadmissionRateReduction,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.ReadmissionRateReduction && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Value</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            value={healthPlanForm.ReadmissionRateReduction?.Value || ''}
                            margin="dense"
                            label="Value"
                            name="Value"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        ReadmissionRateReduction: {
                                            ...healthPlanForm.ReadmissionRateReduction,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                        >
                            {dictionaryToArray(createArrayOfNumbers(21)).map((term) => {
                                return (
                                    <MenuItem key={term.value} value={term.value}>
                                        {term.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.MedicationReconciliationAfterDischarge ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            MedicationReconciliationAfterDischarge: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Medication Reconciliation After Discharge (%)"
                    />
                </FormControl>

                {healthPlanForm.MedicationReconciliationAfterDischarge && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.MedicationReconciliationAfterDischarge?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        MedicationReconciliationAfterDischarge: {
                                            ...healthPlanForm.MedicationReconciliationAfterDischarge,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.MedicationReconciliationAfterDischarge && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Value</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            value={healthPlanForm.MedicationReconciliationAfterDischarge?.Value || ''}
                            margin="dense"
                            label="Value"
                            name="Value"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        MedicationReconciliationAfterDischarge: {
                                            ...healthPlanForm.MedicationReconciliationAfterDischarge,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            <MenuItem value="1">80</MenuItem>
                            <MenuItem value="2">85</MenuItem>
                            <MenuItem value="3">90</MenuItem>
                            <MenuItem value="4">95</MenuItem>
                            <MenuItem value="5">100</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.BpSystolic ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            BpSystolic: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="BP Systolic"
                    />
                </FormControl>

                {healthPlanForm.BpSystolic && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.BpSystolic?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        BpSystolic: {
                                            ...healthPlanForm.BpSystolic,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.BpSystolic && (
                    <FormControl fullWidth>
                        <TextField
                            value={healthPlanForm.BpSystolic?.Value || ''}
                            label="Value"
                            name="Value"
                            type="number"
                            fullWidth
                            variant="outlined"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        BpSystolic: {
                                            ...healthPlanForm.BpSystolic,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        />
                    </FormControl>
                )}
            </Stack>

            <Stack p={2} spacing={2} direction={'row'} width="100%">
                <FormControl fullWidth>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={healthPlanForm.BpDiastolic ? true : false}
                                onChange={(e) =>
                                    dispatch(
                                        setHealthPlanForm({
                                            ...healthPlanForm,
                                            BpDiastolic: e.target.checked ? getOMProperties() : null,
                                        })
                                    )
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="BP Diastolic"
                    />
                </FormControl>

                {healthPlanForm.BpDiastolic && (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Measurement</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="provider-roles"
                            defaultValue={0}
                            value={healthPlanForm.BpDiastolic?.Operator || ''}
                            margin="dense"
                            label="Measurement"
                            name="Operator"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        BpDiastolic: {
                                            ...healthPlanForm.BpDiastolic,
                                            Operator: e.target.value,
                                        },
                                    })
                                )
                            }
                        >
                            {operatorItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}

                {healthPlanForm.BpDiastolic && (
                    <FormControl fullWidth>
                        <TextField
                            value={healthPlanForm.BpDiastolic?.Value || ''}
                            label="Value"
                            name="Value"
                            type="number"
                            fullWidth
                            variant="outlined"
                            onChange={(e) =>
                                dispatch(
                                    setHealthPlanForm({
                                        ...healthPlanForm,
                                        BpDiastolic: {
                                            ...healthPlanForm.BpDiastolic,
                                            Value: e.target.value,
                                        },
                                    })
                                )
                            }
                        />
                    </FormControl>
                )}
            </Stack>
        </>
    );
};

export default OutcomeMetrics;
