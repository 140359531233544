const useStyles = {
    form: {
        // padding: '15px',
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    root: {
        '& .MuiTextField-root': {},
    },
    imageList: {},
    descriptionTextarea: {},
    boxPaper: {
        elevation: 0,
    },
    smLabelCheckbox: {
        '&.MuiFormControlLabel-root': {
            marginLeft: '-2px',
            marginBottom: '2px',

            '& .MuiCheckbox-root': {
                padding: '5px 8px 5px 5px',
            },

            '& .MuiFormControlLabel-label': {
                fontSize: '14px',
                color: '#0063a7',
            },
        },
    },
    customeStepper: {
        '& .MuiStepIcon-text': {
            display: 'none',
        },

        '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
            marginTop: '10px',
        },

        '& .MuiStep-root.active .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
            width: '1.2em',
            height: '1.2em',
        },
        '& .Mui-completed .MuiStepLabel-root': {
            cursor: 'pointer',
        },
    },
    btnGroup: {
        '& .MuiButton-root': {
            fontSize: '21px',
            lineHeight: '21px',
        },
    },
    activityLabel: {
        color: '#969696',
        fontSize: '13px',

        '& span': {
            fontSize: '14px',
            color: '#00a1e0',
        },
    },
    activityLabelDetail: {
        color: '#969696',
        fontSize: '13px',

        '& span': {
            fontSize: '14px',
            color: '#000',
        },
    },
    blueTitlt: {
        fontSize: '15px',
        color: '#0063a7',
    },
    tableContainer: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        marginTop: '20px',
    },
    tableContainer2: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    modalPopupForm: {
        top: '15vh',
        left: '50%',
        width: '600px',
        maxWidth: '100%',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        position: 'absolute',
        transform: 'translate(-50%, -10%)',
        borderRadius: '8px',

        '& .mui-modal-header': {
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'space-between',
            // borderBottom: '1px solid #dee2e6',
            // minHeight: '56px',
            padding: '15px',
            // '& h6': {
            //     fontSize: '16px',
            // },
        },

        '& .mui-modal-body': {
            padding: '15px 15px 25px',
            maxHeight: 'calc(100vh - 30vh)',
            overflow: 'auto',

            '& .MuiFormLabel-root:not(.MuiInputLabel-root)': {
                color: 'black',
                fontSize: '14px',
                fontWeight: 700,
            },
        },

        '& .mui-modal-footer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            // borderTop: '1px solid #dee2e6',
            minHeight: '56px',
            padding: '0 15px',
            '& button': {
                marginLeft: '15px',
                minWidth: '120px',
            },
        },
    },
    textWithIcon: {
        '&.MuiSvgIcon-root': {
            fontSize: '16px',
            marginRight: '4px',
        },
    },
    smText: {
        fontSize: '12px',
    },
    downloadBtnContainer: {
        fontSize: '14px',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '5px',

        '& svg.MuiSvgIcon-root': {
            fontSize: '1.2rem',
            marginRight: '4px',
        },
    },
    smRadioOption: {},
    smCheckboxOption: {},
    smTitleLabel: {},
};

export default useStyles;
