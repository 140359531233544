import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router-dom';

import { useHealthPlanContext } from '../../../hooks/healthPlanContext';
import { fetchMembersWithoutEncounter, MenuProps } from '../../Widgets/WidgetService';
import { fetchPopulations } from '../../Population/PopulationService';
import MemberPreview from '../../../Models/Member/MemberPreview.model';
import { WidgetTimeFilterEnum } from '../../../Enum/WidgetTimeFilterEnum';
import { useWidgetContext } from '../../../hooks/widgetContext';
import { isDefaultDate } from '../../../utils/common';
import '../Widgets.css';

const MembersWithoutEncounter = () => {
    const history = useNavigate();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { population } = useSelector((state: RootState) => state.adminMembers);
    const { memberWithoutEncounterFilter, setMemberWithoutEncounterFilter } = useWidgetContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [members, setMembers] = useState<MemberPreview[]>([]);
    const { healthPlans } = useHealthPlanContext();
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const filteredTeams = (memberWithoutEncounterFilter.showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const filteredHealthPlans = healthPlans
        .filter((item: any) => {
            return population.map((item) => item.HealthPlan?.Name).includes(item.Name);
        })
        .sort((a, b) => a.Name.localeCompare(b.Name));

    const handleClick = (event) => {
        setShow(!show);
    };

    const dayFilterList = [
        { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
        { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
        { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }
    }, [isFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        const { fromDate, toDate, dateFilter } = memberWithoutEncounterFilter;
        if (dateFilter !== WidgetTimeFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate))) {
            setMembers([]);
            setIsFetched(false);
            const changeFilter = async () => {
                setIsFetched(false);
                const membersInfo = await fetchMembersWithoutEncounter(
                    memberWithoutEncounterFilter.team,
                    memberWithoutEncounterFilter.healthPlan,
                    memberWithoutEncounterFilter.population,
                    memberWithoutEncounterFilter.dateFilter,
                    Boolean(fromDate) && dateFilter === WidgetTimeFilterEnum.Custom ? new Date(fromDate).toISOString() : '',
                    Boolean(toDate) && dateFilter === WidgetTimeFilterEnum.Custom ? new Date(toDate).toISOString() : '',
                    memberWithoutEncounterFilter.showInActiveTeams
                );
                setMembers(membersInfo);
                setIsFetched(true);
            };
            changeFilter();
        }

        if (dateFilter !== WidgetTimeFilterEnum.Custom && Boolean(fromDate) && Boolean(toDate)) {
            setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, fromDate: null, toDate: null });
        }
    }, [memberWithoutEncounterFilter, setMemberWithoutEncounterFilter]);

    const healthPlanChange = async (healthPlanId: string) => {
        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, healthPlan: healthPlanId, population: 0, populations: [] });
        if (healthPlanId && healthPlanId !== 'All') {
            const populations = await fetchPopulations(healthPlanId);
            setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, healthPlan: healthPlanId, population: 0, populations });
        }
    };

    const changeFromDate = (date: any) => {
        const fromDate = date;
        let toDate = memberWithoutEncounterFilter.toDate;
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            toDate = endDate;
        }

        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, fromDate, toDate });
    };

    const changeToDate = (date: any) => {
        const toDate = date;
        let fromDate = memberWithoutEncounterFilter.fromDate;
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            fromDate = startDate;
        }

        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, fromDate, toDate });
    };

    const navigateToDetail = () => {
        history(`/provider/metricsummary/memberswithoutencounterdetail`);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        let filter = memberWithoutEncounterFilter;
        filter.showInActiveTeams = isChecked;
        filter.team = 'All';
        setMemberWithoutEncounterFilter({ ...filter });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToDetail}>
                                    Members Without An Encounter
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">Active members who don't have an encounter in the given date ranges</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.team}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, team: e.target.value })}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox
                                                checked={memberWithoutEncounterFilter.showInActiveTeams}
                                                onChange={(e) => toggleTeamHandler(e.target.checked)}
                                            />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Health Plan</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.healthPlan}
                                    label="Health Plan"
                                    name="Health Plan"
                                    onChange={(e) => healthPlanChange(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    {filteredHealthPlans.map((plan) => (
                                        <MenuItem key={plan.Id} value={plan.Id}>
                                            {plan.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Population</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.population}
                                    label="Population"
                                    name="Population"
                                    onChange={(e) =>
                                        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, population: parseInt(e.target.value as any) })
                                    }
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="0">
                                        All
                                    </MenuItem>
                                    {memberWithoutEncounterFilter.populations.map((pop) => (
                                        <MenuItem key={pop.PopulationNumber} value={pop.PopulationNumber}>
                                            {pop.PopulationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={memberWithoutEncounterFilter.dateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) =>
                                        setMemberWithoutEncounterFilter({ ...memberWithoutEncounterFilter, dateFilter: parseInt(e.target.value as any) })
                                    }
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {memberWithoutEncounterFilter.dateFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(memberWithoutEncounterFilter.fromDate) ? memberWithoutEncounterFilter.fromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(memberWithoutEncounterFilter.toDate) ? memberWithoutEncounterFilter.toDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}

                        <Grid item xs={12} marginTop={2} height="40vh" sx={{ overflowY: 'scroll', position: 'relative' }}>
                            {!isFetched ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <SimpleBar style={{ maxHeight: '380px' }}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width={40} size="small">
                                                        Name
                                                    </TableCell>
                                                    <TableCell width={40} size="small">
                                                        Health Plan
                                                    </TableCell>
                                                    <TableCell width={20} align="right" size="small">
                                                        Last CHP Visit
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {members && members.length
                                                    ? members.map((mem) => (
                                                          <TableRow key={mem.Id}>
                                                              <TableCell width={40} size="small">
                                                                  {mem.FirstName} {mem.LastName}
                                                              </TableCell>
                                                              <TableCell width={40} size="small">
                                                                  {mem.HealthPlan}
                                                              </TableCell>
                                                              <TableCell width={20} align="right" size="small">
                                                                  {isDefaultDate(mem.LastVisit) ? '-' : moment(new Date(mem.LastVisit)).format('MM/DD/yyyy')}
                                                              </TableCell>
                                                          </TableRow>
                                                      ))
                                                    : null}
                                                {isFetched && members && members.length === 0 ? (
                                                    <TableRow>
                                                        <TableCell colSpan={3}>No Member Found</TableCell>
                                                    </TableRow>
                                                ) : null}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </SimpleBar>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default MembersWithoutEncounter;
