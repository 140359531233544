import { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchInPersonVisitSummary, MenuProps } from '../../Widgets/WidgetService';
import InPersonVisitSummary from '../../../Models/Widget/InPersonVisitSummary.model';
import Authorization from '../../../utils/Authorization';
import '../Widgets.css';

const yearList = [
    { Id: 2021, Name: '2021' },
    { Id: 2022, Name: '2022' },
    { Id: 2023, Name: '2023' },
    { Id: 2024, Name: '2024' },
    { Id: 2025, Name: '2025' },
    { Id: 2026, Name: '2026' },
    { Id: 2027, Name: '2027' },
    { Id: 2028, Name: '2028' },
    { Id: 2029, Name: '2029' },
    { Id: 2030, Name: '2030' },
];

const monthList = [
    { Id: 1, Name: 'January' },
    { Id: 2, Name: 'February' },
    { Id: 3, Name: 'March' },
    { Id: 4, Name: 'April' },
    { Id: 5, Name: 'May' },
    { Id: 6, Name: 'June' },
    { Id: 7, Name: 'July' },
    { Id: 8, Name: 'August' },
    { Id: 9, Name: 'September' },
    { Id: 10, Name: 'October' },
    { Id: 11, Name: 'November' },
    { Id: 12, Name: 'December' },
];

const InpersonVisitsSummary = () => {
    const authData = new Authorization();
    const { teams } = useSelector((state: RootState) => state.teams);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [chartOptions, setChartOptions] = useState({});
    const [inPersonVisitSummary, setInPersonVisitSummary] = useState<InPersonVisitSummary>();
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [selectedYear, setSelectedYear] = useState<any>('2023');
    const [selectedMonth, setSelectedMonth] = useState<any>('1');
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const [selectedProvider, setSelectedProvider] = useState<string>(filteredProviders.length ? filteredProviders[0].Id : '');

    const handleClick = (event) => {
        setShow(!show);
    };

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChart(
                    'In Person Visit Summary',
                    'In Person Visit',
                    'Month',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0]
                )
            );
        }
        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (Boolean(selectedProvider)) {
            const changeFilter = async () => {
                const response = await fetchInPersonVisitSummary(
                    selectedTeam,
                    selectedProvider,
                    selectedYear,
                    selectedMonth,
                    showInActiveTeams,
                    showInActiveProviders
                );
                setInPersonVisitSummary(response);
            };
            setIsGraphShow(false);
            setChartOptions(
                configureStackedBarChart(
                    'In Person Visit Summary',
                    'In Person Visit',
                    'Month',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0]
                )
            );
            changeFilter();
        }
    }, [selectedTeam, selectedProvider, selectedYear, selectedMonth, showInActiveTeams, showInActiveProviders]);

    useEffect(() => {
        if (inPersonVisitSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            for (var data in inPersonVisitSummary.Appointments) {
                let dataInfo = inPersonVisitSummary.Appointments[data];

                yValues.push(dataInfo.InpersonAppointmentCount);
                xAxisValues.push(dataInfo.Date);
            }
            yAxisValues.push({ data: yValues, showInLegend: false, title: { text: null } });
            if (inPersonVisitSummary.Appointments.length === 0) {
                setChartOptions(
                    configureStackedBarChart(
                        'In Person Visit Summary',
                        'In Person Visit',
                        'Month',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedBarChart(
                        'In Person Visit Chart',
                        subTitle,
                        xAxisTitle,
                        yAxisTitle,
                        yAxisValues,
                        xAxisValues,
                        null,
                        null,
                        inPersonVisitSummary?.Percentage
                    )
                );
            }
            setIsGraphShow(true);
        }
    }, [inPersonVisitSummary]);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider('All');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                            In Person Visits Summary
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            Total number of in person visits a provider completed in a given month divided by the number of days they worked
                                            that month
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Team</InputLabel>
                            <Select
                                value={selectedTeam}
                                label="Team"
                                name="Team"
                                onChange={(e) => setSelectedTeam(e.target.value)}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                        {team.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={selectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Year</InputLabel>
                            <Select value={selectedYear} label="Year" name="Year" onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
                                {yearList.map((year) => (
                                    <MenuItem key={year.Id} value={year.Id}>
                                        {year.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Month</InputLabel>
                            <Select value={selectedMonth} label="Month" name="Month" onChange={(e) => setSelectedMonth(parseInt(e.target.value))}>
                                {monthList.map((month) => (
                                    <MenuItem key={month.Id} value={month.Id}>
                                        {month.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <Paper elevation={0} style={{ position: 'relative' }}>
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default InpersonVisitsSummary;
