import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { SdohAssessmentEnum } from '../../../Enum/SdohAssessmentEnum';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { FamilyAndHome } from '../../../Models/Sdoh/FamilyAndHome.model';
import { MoneyAndResources } from '../../../Models/Sdoh/MoneyAndResources.model';
import { OptionalAdditionalQuestions } from '../../../Models/Sdoh/OptionalAdditionalQuestions.model';
import { PersonalCharacteristics } from '../../../Models/Sdoh/PersonalCharacteristics.model';
import { SdohAssessment } from '../../../Models/Sdoh/SdohAssessment.model';
import { SdohMultiAnswer } from '../../../Models/Sdoh/SdohMultiAnswer.model';
import { SdohSingleAnswerAdvanced } from '../../../Models/Sdoh/SdohSingleAnswerAdvanced.model';
import { SocialAndEmotionalHealth } from '../../../Models/Sdoh/SocialAndEmotionalHealth.model';
import { RootState } from '../../../reducers';
import {
    fetchSdohAssessment,
    getLastSdohAssessment,
    gotoNextMenu,
    gotoPreviousMenu,
    saveSdohAssessment,
    updateSdohAssessment,
    prepareSdohRequest,
} from '../../../store/assessment.slice';
import { assessmentStatusEnum } from '../../../utils/assessments';
import { copyObject } from '../../../utils/common';
import {
    afraidOfPartnerList,
    childCareList,
    clothingList,
    emotionallySafeList,
    foodList,
    frequencyOfTalkingPeopleList,
    healthCareList,
    healthLiteratureList,
    housingSituationList,
    languageList,
    levelOfSchoolList,
    losingHouseList,
    otherNeedsList,
    phoneList,
    povertyGuidelines,
    stressLevelList,
    totalIncomeList,
    utilitiesList,
    workingSituationList,
} from '../../../utils/constants';
import SdohNeedsIdentifiedModal from './SdohNeedsIdentifiedModal';

const theme = createTheme();

const classes = {
    form: {
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '75%',
        },
    },
};

const Sdoh: React.FC<{ id?: string; memberId: string }> = ({ id, memberId }) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [showNeedsIdentifiedModal, setShowNeedsIdentifiedModal] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { sdohAssessment, fetchingSdoh, isSaving } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);

    const [initialInfo, setInitialInfo] = useState<SdohAssessment>({
        Id: '',
        MemberId: '',
        MemberName: '',
        PersonalCharacteristics: new PersonalCharacteristics(),
        FamilyAndHome: new FamilyAndHome(),
        MoneyAndResources: new MoneyAndResources(),
        SocialAndEmotionalHealth: new SocialAndEmotionalHealth(),
        OptionalAdditionalQuestions: new OptionalAdditionalQuestions(),
        Status: StatusEnum.None,
        RequestIds: [],
        ResourceOptions: [],
        ResourceRequestIds: [],
        Comment: '',
        IsLast: false,
    });

    const handleSubmit = () => {
        setShowSaveAlert(true);
    };

    const saveSdoh = (sdohInfo: SdohAssessment, isWithoutComplete = false) => {
        let data = prepareSdohInfo(copyObject(sdohInfo));
        data.MemberId = memberId;
        data.Status = sdohInfo.Status;
        if (isWithoutComplete) {
            data.Status = StatusEnum.Completed;
        }
        if (sdohInfo.Id) {
            delete data.Comment;
            data.Id = sdohInfo.Id;
            dispatch(updateSdohAssessment(data));
        } else {
            if (!isWithoutComplete) {
                data.Status = assessmentStatusEnum.IN_PROGRESS;
            }
            dispatch(saveSdohAssessment(data));
        }
        setShowNeedsIdentifiedModal(true);
    };

    const prepareSdohInfo = (sdohInfo: SdohAssessment) => {
        // Prepare personal characteristics
        if (sdohInfo.PersonalCharacteristics.LanguageAreMostComfortableSpeaking && sdohInfo.PersonalCharacteristics.LanguageAreMostComfortableSpeaking.Answer) {
            var language = languageList.find((p) => p.Answer === sdohInfo.PersonalCharacteristics.LanguageAreMostComfortableSpeaking.Answer);
            sdohInfo.PersonalCharacteristics.LanguageAreMostComfortableSpeaking.Diagnosis = language.Diagnosis;
            sdohInfo.PersonalCharacteristics.LanguageAreMostComfortableSpeaking.LoincCode = language.LoincCode;
        }
        if (
            sdohInfo.PersonalCharacteristics.HaveProblemsInReadingHealthLiterature &&
            sdohInfo.PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.Answer
        ) {
            var healthLiterature = healthLiteratureList.find((p) => p.Answer === sdohInfo.PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.Answer);
            sdohInfo.PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.Diagnosis = healthLiterature.Diagnosis;
            sdohInfo.PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.LoincCode = healthLiterature.LoincCode;
        }

        // Prepare family and home
        if (sdohInfo.FamilyAndHome.HousingSituationToday && sdohInfo.FamilyAndHome.HousingSituationToday.Answer) {
            var housingSituation = housingSituationList.find((p) => p.Answer === sdohInfo.FamilyAndHome.HousingSituationToday.Answer);
            sdohInfo.FamilyAndHome.HousingSituationToday.Diagnosis = housingSituation.Diagnosis;
            sdohInfo.FamilyAndHome.HousingSituationToday.LoincCode = housingSituation.LoincCode;
        }
        if (sdohInfo.FamilyAndHome.WorriedAboutLosingYourHousing && sdohInfo.FamilyAndHome.WorriedAboutLosingYourHousing.Answer) {
            var losingHouse = losingHouseList.find((p) => p.Answer === sdohInfo.FamilyAndHome.WorriedAboutLosingYourHousing.Answer);
            sdohInfo.FamilyAndHome.WorriedAboutLosingYourHousing.Diagnosis = losingHouse.Diagnosis;
            sdohInfo.FamilyAndHome.WorriedAboutLosingYourHousing.LoincCode = losingHouse.LoincCode;
        }

        // Prepare money and resources
        if (sdohInfo.MoneyAndResources.HighestLevelOfSchoolYouFinished && sdohInfo.MoneyAndResources.HighestLevelOfSchoolYouFinished.Answer) {
            var levelOfSchool = levelOfSchoolList.find((p) => p.Answer === sdohInfo.MoneyAndResources.HighestLevelOfSchoolYouFinished.Answer);
            sdohInfo.MoneyAndResources.HighestLevelOfSchoolYouFinished.Diagnosis = levelOfSchool.Diagnosis;
            sdohInfo.MoneyAndResources.HighestLevelOfSchoolYouFinished.LoincCode = levelOfSchool.LoincCode;
        }
        if (sdohInfo.MoneyAndResources.CurrentWorkSituation && sdohInfo.MoneyAndResources.CurrentWorkSituation.Answer) {
            var workingSituation = workingSituationList.find((p) => p.Answer === sdohInfo.MoneyAndResources.CurrentWorkSituation.Answer);
            sdohInfo.MoneyAndResources.CurrentWorkSituation.Diagnosis = workingSituation.Diagnosis;
            sdohInfo.MoneyAndResources.CurrentWorkSituation.LoincCode = workingSituation.LoincCode;
        }
        if (sdohInfo.MoneyAndResources.TotalIncome && sdohInfo.MoneyAndResources.TotalIncome.Answer) {
            var totalIncome = totalIncomeList.find((p) => p.Answer === sdohInfo.MoneyAndResources.TotalIncome.Answer);
            sdohInfo.MoneyAndResources.TotalIncome.Diagnosis = totalIncome.Diagnosis;
            sdohInfo.MoneyAndResources.TotalIncome.LoincCode = totalIncome.LoincCode;
            // Prepare poverty level
            if (sdohInfo.FamilyAndHome.FamilyMembersCurrentlyLiveWithYou && sdohInfo.FamilyAndHome.FamilyMembersCurrentlyLiveWithYou.Answer) {
                // Get poverty level
                var povertyGuideline = povertyGuidelines.find(
                    (p) => p.NoOfPersons === parseInt(sdohInfo.FamilyAndHome.FamilyMembersCurrentlyLiveWithYou.Answer)
                );
                var income = 0;
                if (sdohInfo.MoneyAndResources.TotalIncome.Answer === SdohAssessmentEnum.LessThan50000) income = 50000;
                else if (sdohInfo.MoneyAndResources.TotalIncome.Answer === SdohAssessmentEnum.LessThan35000) income = 35000;
                else if (sdohInfo.MoneyAndResources.TotalIncome.Answer === SdohAssessmentEnum.LessThan75000) income = 75000;
                else if (sdohInfo.MoneyAndResources.TotalIncome.Answer === SdohAssessmentEnum.LessThan100000) income = 100000;

                if (povertyGuideline) {
                    var povertyLevel = (income / povertyGuideline.Amount) * 100;
                    if (povertyLevel <= 100)
                        // Extreme poverty
                        sdohInfo.MoneyAndResources.TotalIncome.Diagnosis = '587f3d3404a2f81d243b6f0b';
                    else if (povertyLevel > 100 && povertyLevel <= 200)
                        // Low income
                        sdohInfo.MoneyAndResources.TotalIncome.Diagnosis = '587f3d3404a2f81d243b6f0c';
                }
            }
        }
        sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer = [];
        if (sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear === null)
            sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear = new SdohMultiAnswer();
        sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer = [];
        if (sdohInfo.MoneyAndResources.Food && sdohInfo.MoneyAndResources.Food.Answer) {
            let food = foodList.find((p) => p.Answer === sdohInfo.MoneyAndResources.Food.Answer);
            let foodDetail = {
                Answer: SdohAssessmentEnum.Food,
                Diagnosis: food.Diagnosis,
                LoincCode: food.LoincCode,
                ICD10: null,
            };
            if (sdohInfo.MoneyAndResources.Food && sdohInfo.MoneyAndResources.Food.Answer === SdohAssessmentEnum.Yes)
                sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.push(foodDetail);
            else sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.push(foodDetail);
        }
        if (sdohInfo.MoneyAndResources.Clothing && sdohInfo.MoneyAndResources.Clothing.Answer) {
            let clothing = clothingList.find((p) => p.Answer === sdohInfo.MoneyAndResources.Clothing.Answer);
            let clothingDetail = {
                Answer: SdohAssessmentEnum.Clothing,
                Diagnosis: clothing.Diagnosis,
                LoincCode: clothing.LoincCode,
                ICD10: null,
            };
            if (sdohInfo.MoneyAndResources.Clothing && sdohInfo.MoneyAndResources.Clothing.Answer === SdohAssessmentEnum.Yes)
                sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.push(clothingDetail);
            else sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.push(clothingDetail);
        }
        if (sdohInfo.MoneyAndResources.Utilities && sdohInfo.MoneyAndResources.Utilities.Answer) {
            let utilities = utilitiesList.find((p) => p.Answer === sdohInfo.MoneyAndResources.Utilities.Answer);
            let utilitiesDetail = {
                Answer: SdohAssessmentEnum.Utilities,
                Diagnosis: utilities.Diagnosis,
                LoincCode: utilities.LoincCode,
                ICD10: null,
            };
            if (sdohInfo.MoneyAndResources.Utilities && sdohInfo.MoneyAndResources.Utilities.Answer === SdohAssessmentEnum.Yes)
                sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.push(utilitiesDetail);
            else sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.push(utilitiesDetail);
        }
        if (sdohInfo.MoneyAndResources.ChildCare && sdohInfo.MoneyAndResources.ChildCare.Answer) {
            let childCare = childCareList.find((p) => p.Answer === sdohInfo.MoneyAndResources.ChildCare.Answer);
            let childCareDetail = {
                Answer: SdohAssessmentEnum.ChildCare,
                Diagnosis: childCare.Diagnosis,
                LoincCode: childCare.LoincCode,
                ICD10: null,
            };
            if (sdohInfo.MoneyAndResources.ChildCare && sdohInfo.MoneyAndResources.ChildCare.Answer === SdohAssessmentEnum.Yes)
                sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.push(childCareDetail);
            else sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.push(childCareDetail);
        }
        if (sdohInfo.MoneyAndResources.Phone && sdohInfo.MoneyAndResources.Phone.Answer) {
            let phone = phoneList.find((p) => p.Answer === sdohInfo.MoneyAndResources.Phone.Answer);
            let phoneDetail = {
                Answer: SdohAssessmentEnum.Phone,
                Diagnosis: phone.Diagnosis,
                LoincCode: phone.LoincCode,
                ICD10: null,
            };
            if (sdohInfo.MoneyAndResources.Phone && sdohInfo.MoneyAndResources.Phone.Answer === SdohAssessmentEnum.Yes)
                sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.push(phoneDetail);
            else sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.push(phoneDetail);
        }
        if (sdohInfo.MoneyAndResources.Medicine && sdohInfo.MoneyAndResources.Medicine.Answer) {
            let healthCare = healthCareList.find((p) => p.Answer === sdohInfo.MoneyAndResources.Medicine.Answer);
            let healthCareDetail = {
                Answer: SdohAssessmentEnum.Medicine,
                Diagnosis: healthCare.Diagnosis,
                LoincCode: healthCare.LoincCode,
                ICD10: null,
            };
            if (sdohInfo.MoneyAndResources.Medicine && sdohInfo.MoneyAndResources.Medicine.Answer === SdohAssessmentEnum.Yes)
                sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.push(healthCareDetail);
            else sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.push(healthCareDetail);
        }
        if (sdohInfo.MoneyAndResources.OtherNeed && sdohInfo.MoneyAndResources.OtherNeed.Answer) {
            let otherNeeds = otherNeedsList.find((p) => p.Answer === sdohInfo.MoneyAndResources.OtherNeed.Answer);
            let otherNeedDetail = {
                Answer: SdohAssessmentEnum.OtherNeed,
                Diagnosis: otherNeeds.Diagnosis,
                LoincCode: otherNeeds.LoincCode,
                ICD10: null,
            };
            if (sdohInfo.MoneyAndResources.OtherNeed && sdohInfo.MoneyAndResources.OtherNeed.Answer === SdohAssessmentEnum.Yes)
                sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.push(otherNeedDetail);
            else sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.push(otherNeedDetail);
        }

        // Prepare social and emotional health
        if (sdohInfo.SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople && sdohInfo.SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.Answer) {
            var frequencyOfTalkingPeople = frequencyOfTalkingPeopleList.find(
                (p) => p.Answer === sdohInfo.SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.Answer
            );
            sdohInfo.SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.Diagnosis = frequencyOfTalkingPeople.Diagnosis;
            sdohInfo.SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.LoincCode = frequencyOfTalkingPeople.LoincCode;
        }
        if (sdohInfo.SocialAndEmotionalHealth.HowStressedAreYou && sdohInfo.SocialAndEmotionalHealth.HowStressedAreYou.Answer) {
            var stressLevel = stressLevelList.find((p) => p.Answer === sdohInfo.SocialAndEmotionalHealth.HowStressedAreYou.Answer);
            sdohInfo.SocialAndEmotionalHealth.HowStressedAreYou.Diagnosis = stressLevel.Diagnosis;
            sdohInfo.SocialAndEmotionalHealth.HowStressedAreYou.LoincCode = stressLevel.LoincCode;
        }

        // Prepare optional additional questions
        if (
            sdohInfo.OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe &&
            sdohInfo.OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.Answer
        ) {
            var emotionallySafe = emotionallySafeList.find(
                (p) => p.Answer === sdohInfo.OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.Answer
            );
            sdohInfo.OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.Diagnosis = emotionallySafe.Diagnosis;
            sdohInfo.OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.LoincCode = emotionallySafe.LoincCode;
        }
        if (sdohInfo.OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner && sdohInfo.OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.Answer) {
            var afraidOfPartner = afraidOfPartnerList.find((p) => p.Answer === sdohInfo.OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.Answer);
            sdohInfo.OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.Diagnosis = afraidOfPartner.Diagnosis;
            sdohInfo.OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.LoincCode = afraidOfPartner.LoincCode;
        }
        return sdohInfo;
    };

    const prepareOtherNeeds = useCallback(() => {
        let sdohInfo = copyObject(sdohAssessment);
        sdohInfo.MoneyAndResources.Food = new SdohSingleAnswerAdvanced();
        sdohInfo.MoneyAndResources.Clothing = new SdohSingleAnswerAdvanced();
        sdohInfo.MoneyAndResources.Utilities = new SdohSingleAnswerAdvanced();
        sdohInfo.MoneyAndResources.ChildCare = new SdohSingleAnswerAdvanced();
        sdohInfo.MoneyAndResources.Phone = new SdohSingleAnswerAdvanced();
        sdohInfo.MoneyAndResources.Medicine = new SdohSingleAnswerAdvanced();
        sdohInfo.MoneyAndResources.OtherNeed = new SdohSingleAnswerAdvanced();
        if (Boolean(sdohInfo.MoneyAndResources?.NeededThingsUnableToGetInPastYear?.Answer?.length)) {
            sdohInfo.MoneyAndResources.NeededThingsUnableToGetInPastYear.Answer.forEach((thing) => {
                if (thing.Answer === SdohAssessmentEnum.Food) sdohInfo.MoneyAndResources.Food.Answer = SdohAssessmentEnum.Yes;
                else if (thing.Answer === SdohAssessmentEnum.Clothing) sdohInfo.MoneyAndResources.Clothing.Answer = SdohAssessmentEnum.Yes;
                else if (thing.Answer === SdohAssessmentEnum.Utilities) sdohInfo.MoneyAndResources.Utilities.Answer = SdohAssessmentEnum.Yes;
                else if (thing.Answer === SdohAssessmentEnum.ChildCare) sdohInfo.MoneyAndResources.ChildCare.Answer = SdohAssessmentEnum.Yes;
                else if (thing.Answer === SdohAssessmentEnum.Phone) sdohInfo.MoneyAndResources.Phone.Answer = SdohAssessmentEnum.Yes;
                else if (thing.Answer === SdohAssessmentEnum.Medicine) sdohInfo.MoneyAndResources.Medicine.Answer = SdohAssessmentEnum.Yes;
                else if (thing.Answer === SdohAssessmentEnum.OtherNeed) sdohInfo.MoneyAndResources.OtherNeed.Answer = SdohAssessmentEnum.Yes;
            });
        }
        if (Boolean(sdohInfo.MoneyAndResources?.NotNeededThingsUnableToGetInPastYear?.Answer?.length)) {
            sdohInfo.MoneyAndResources.NotNeededThingsUnableToGetInPastYear.Answer.forEach((thing) => {
                if (thing.Answer === SdohAssessmentEnum.Food) sdohInfo.MoneyAndResources.Food.Answer = SdohAssessmentEnum.No;
                else if (thing.Answer === SdohAssessmentEnum.Clothing) sdohInfo.MoneyAndResources.Clothing.Answer = SdohAssessmentEnum.No;
                else if (thing.Answer === SdohAssessmentEnum.Utilities) sdohInfo.MoneyAndResources.Utilities.Answer = SdohAssessmentEnum.No;
                else if (thing.Answer === SdohAssessmentEnum.ChildCare) sdohInfo.MoneyAndResources.ChildCare.Answer = SdohAssessmentEnum.No;
                else if (thing.Answer === SdohAssessmentEnum.Phone) sdohInfo.MoneyAndResources.Phone.Answer = SdohAssessmentEnum.No;
                else if (thing.Answer === SdohAssessmentEnum.Medicine) sdohInfo.MoneyAndResources.Medicine.Answer = SdohAssessmentEnum.No;
                else if (thing.Answer === SdohAssessmentEnum.OtherNeed) sdohInfo.MoneyAndResources.OtherNeed.Answer = SdohAssessmentEnum.No;
            });
        }
        return sdohInfo;
    }, [sdohAssessment]);

    const initialPage = useCallback(() => {
        if (Boolean(engagementDraft?.SdohAssessmentId)) {
            dispatch(fetchSdohAssessment(engagementDraft.SdohAssessmentId));
        } else {
            dispatch(getLastSdohAssessment(memberId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    useEffect(() => {
        if (sdohAssessment && (sdohAssessment.Id || sdohAssessment.IsLast)) {
            setInitialInfo(prepareOtherNeeds());
        }
    }, [sdohAssessment, prepareOtherNeeds]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    return (
        <Formik initialValues={initialInfo} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ values, setFieldValue, errors, handleChange }: FormikProps<SdohAssessment>) => (
                <Form>
                    <Grid container sx={classes.form} direction="column" spacing={2} className="content-panel">
                        <Grid item className="content-form">
                            {fetchingSdoh ? (
                                <Box display="flex" justifyContent="center" className="loader-container">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} sx={classes.root}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="h6" component="label">
                                                Personal Characteristics
                                            </Typography>
                                            <hr />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>What language are you most comfortable speaking?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="PersonalCharacteristics.LanguageAreMostComfortableSpeaking.Answer"
                                                value={values.PersonalCharacteristics?.LanguageAreMostComfortableSpeaking?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('PersonalCharacteristics.LanguageAreMostComfortableSpeaking.Answer', e.currentTarget.value);
                                                    setFieldValue('PersonalCharacteristics.LanguageAreMostComfortableSpeaking.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {languageList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.PersonalCharacteristics?.LanguageAreMostComfortableSpeaking?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('PersonalCharacteristics.LanguageAreMostComfortableSpeaking.Answer', null);
                                                    setFieldValue('PersonalCharacteristics.LanguageAreMostComfortableSpeaking.OtherAnswer', null);
                                                    setFieldValue(
                                                        'PersonalCharacteristics.LanguageAreMostComfortableSpeaking.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            {values.PersonalCharacteristics?.LanguageAreMostComfortableSpeaking?.Answer ===
                                            SdohAssessmentEnum.LanguageOtherThan ? (
                                                <TextField
                                                    label=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.PersonalCharacteristics.LanguageAreMostComfortableSpeaking.OtherAnswer}
                                                    name="PersonalCharacteristics.LanguageAreMostComfortableSpeaking.OtherAnswer"
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Do you have problems reading or understanding health literature?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.Answer"
                                                value={values.PersonalCharacteristics?.HaveProblemsInReadingHealthLiterature?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.Answer',
                                                        e.currentTarget.value
                                                    );
                                                    setFieldValue('PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {healthLiteratureList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer-lable2-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.PersonalCharacteristics?.HaveProblemsInReadingHealthLiterature?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.Answer', null);
                                                    setFieldValue(
                                                        'PersonalCharacteristics.HaveProblemsInReadingHealthLiterature.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="h6" component="label">
                                                Family and Home
                                            </Typography>
                                            <hr />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>How many family members, including yourself, do you currently live with?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <TextField
                                                label=""
                                                variant="outlined"
                                                size="small"
                                                value={values.FamilyAndHome?.FamilyMembersCurrentlyLiveWithYou?.Answer}
                                                name="FamilyAndHome.FamilyMembersCurrentlyLiveWithYou.Answer"
                                                onChange={(e) => {
                                                    setFieldValue('FamilyAndHome.FamilyMembersCurrentlyLiveWithYou.Answer', e.currentTarget.value);
                                                    setFieldValue('FamilyAndHome.FamilyMembersCurrentlyLiveWithYou.NoAnswerToQuestion', false);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.FamilyAndHome?.FamilyMembersCurrentlyLiveWithYou?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('FamilyAndHome.FamilyMembersCurrentlyLiveWithYou.Answer', '');
                                                    setFieldValue(
                                                        'FamilyAndHome.FamilyMembersCurrentlyLiveWithYou.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>What is your housing situation today?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="FamilyAndHome.HousingSituationToday.Answer"
                                                value={values.FamilyAndHome?.HousingSituationToday?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('FamilyAndHome.HousingSituationToday.Answer', e.currentTarget.value);
                                                    setFieldValue('FamilyAndHome.HousingSituationToday.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {housingSituationList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer2-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.FamilyAndHome?.HousingSituationToday?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('FamilyAndHome.HousingSituationToday.Answer', null);
                                                    setFieldValue('FamilyAndHome.HousingSituationToday.NoAnswerToQuestion', e.currentTarget.checked);
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Are you worried about losing your housing?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="FamilyAndHome.WorriedAboutLosingYourHousing.Answer"
                                                value={values.FamilyAndHome?.WorriedAboutLosingYourHousing?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('FamilyAndHome.WorriedAboutLosingYourHousing.Answer', e.currentTarget.value);
                                                    setFieldValue('FamilyAndHome.WorriedAboutLosingYourHousing.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {losingHouseList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer3-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.FamilyAndHome?.WorriedAboutLosingYourHousing?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('FamilyAndHome.WorriedAboutLosingYourHousing.Answer', null);
                                                    setFieldValue('FamilyAndHome.WorriedAboutLosingYourHousing.NoAnswerToQuestion', e.currentTarget.checked);
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="h6" component="label">
                                                Money and Resources
                                            </Typography>
                                            <hr />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>What is the highest level of school that you have finished?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.HighestLevelOfSchoolYouFinished.Answer"
                                                value={values.MoneyAndResources?.HighestLevelOfSchoolYouFinished?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.HighestLevelOfSchoolYouFinished.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.HighestLevelOfSchoolYouFinished.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {levelOfSchoolList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer4-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.MoneyAndResources?.HighestLevelOfSchoolYouFinished?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.HighestLevelOfSchoolYouFinished.Answer', null);
                                                    setFieldValue(
                                                        'MoneyAndResources.HighestLevelOfSchoolYouFinished.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>What is your current work situation?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.CurrentWorkSituation.Answer"
                                                value={values.MoneyAndResources?.CurrentWorkSituation?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.CurrentWorkSituation.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.CurrentWorkSituation.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {workingSituationList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer6-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.MoneyAndResources?.CurrentWorkSituation?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.CurrentWorkSituation.Answer', null);
                                                    setFieldValue('MoneyAndResources.CurrentWorkSituation.OtherAnswer', null);
                                                    setFieldValue('MoneyAndResources.CurrentWorkSituation.NoAnswerToQuestion', e.currentTarget.checked);
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            {values.MoneyAndResources?.CurrentWorkSituation?.Answer === SdohAssessmentEnum.MoneyAndResources ? (
                                                <TextField
                                                    label=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.MoneyAndResources?.CurrentWorkSituation?.OtherAnswer}
                                                    name="MoneyAndResources.CurrentWorkSituation.OtherAnswer"
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>
                                                During the past year, what was the total combined income for you and the family members you live with? This
                                                information will help us determine if you are eligible for any benefits.
                                            </FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.TotalIncome.Answer"
                                                value={values.MoneyAndResources?.TotalIncome?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.TotalIncome.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.TotalIncome.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {totalIncomeList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer7-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.MoneyAndResources?.TotalIncome?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.TotalIncome.Answer', null);
                                                    setFieldValue('MoneyAndResources.TotalIncome.NoAnswerToQuestion', e.currentTarget.checked);
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <FormLabel>
                                                In the past year, have you or any family members you live with been unable to get any of the following when it
                                                was really needed? Check all that apply.
                                            </FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Food</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.Food.Answer"
                                                value={values.MoneyAndResources?.Food?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.Food.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {foodList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer8-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Clothing</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.Clothing.Answer"
                                                value={values.MoneyAndResources?.Clothing?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.Clothing.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {clothingList.map((type) => {
                                                    return <FormControlLabel value={type.Answer} control={<Radio />} label={type.Answer} />;
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Utilities</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.Utilities.Answer"
                                                value={values.MoneyAndResources?.Utilities?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.Utilities.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {utilitiesList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer9-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Child Care</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.ChildCare.Answer"
                                                value={values.MoneyAndResources?.ChildCare?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.ChildCare.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {childCareList.map((type) => {
                                                    return <FormControlLabel value={type.Answer} control={<Radio />} label={type.Answer} />;
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Phone</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.Phone.Answer"
                                                value={values.MoneyAndResources?.Phone?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.Phone.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {phoneList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer10-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Medicine or any Health Care (Medical, Dental, Mental Health, Vision)</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.Medicine.Answer"
                                                value={values.MoneyAndResources?.Medicine?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.Medicine.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {healthCareList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer11-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Other (Please write)</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="MoneyAndResources.OtherNeed.Answer"
                                                value={values.MoneyAndResources?.OtherNeed?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.OtherNeed.Answer', e.currentTarget.value);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {otherNeedsList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer12-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.MoneyAndResources?.NeededThingsUnableToGetInPastYear?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('MoneyAndResources.Food.Answer', null);
                                                    setFieldValue('MoneyAndResources.Clothing.Answer', null);
                                                    setFieldValue('MoneyAndResources.Utilities.Answer', null);
                                                    setFieldValue('MoneyAndResources.ChildCare.Answer', null);
                                                    setFieldValue('MoneyAndResources.Phone.Answer', null);
                                                    setFieldValue('MoneyAndResources.Medicine.Answer', null);
                                                    setFieldValue('MoneyAndResources.OtherNeed.Answer', null);
                                                    setFieldValue('MoneyAndResources.NeededThingsUnableToGetInPastYear.OtherAnswer', null);
                                                    setFieldValue(
                                                        'MoneyAndResources.NeededThingsUnableToGetInPastYear.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            {values.MoneyAndResources.OtherNeed.Answer === SdohAssessmentEnum.Yes ? (
                                                <TextField
                                                    label=""
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.MoneyAndResources?.NeededThingsUnableToGetInPastYear?.OtherAnswer}
                                                    name="MoneyAndResources.NeededThingsUnableToGetInPastYear.OtherAnswer"
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="h6" component="label">
                                                Social and Emotional Health
                                            </Typography>
                                            <hr />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>
                                                How often do you see or talk to people that that you care about and feel close to? (For example: talking to
                                                friends on the phone, visiting friends or family, going to church or club meetings)
                                            </FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.Answer"
                                                value={values.SocialAndEmotionalHealth?.HowOftenDoYouSeeOrTalkToPeople?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.Answer', e.currentTarget.value);
                                                    setFieldValue('SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {frequencyOfTalkingPeopleList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer12-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.SocialAndEmotionalHealth?.HowOftenDoYouSeeOrTalkToPeople?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.Answer', null);
                                                    setFieldValue(
                                                        'SocialAndEmotionalHealth.HowOftenDoYouSeeOrTalkToPeople.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>
                                                Stress is when someone feels tense, nervous, anxious, or can’t sleep at night because their mind is troubled.
                                                How stressed are you?
                                            </FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="SocialAndEmotionalHealth.HowStressedAreYou.Answer"
                                                value={values.SocialAndEmotionalHealth?.HowStressedAreYou?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('SocialAndEmotionalHealth.HowStressedAreYou.Answer', e.currentTarget.value);
                                                    setFieldValue('SocialAndEmotionalHealth.HowStressedAreYou.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {stressLevelList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer13-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.SocialAndEmotionalHealth?.HowStressedAreYou?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('SocialAndEmotionalHealth.HowStressedAreYou.Answer', null);
                                                    setFieldValue('SocialAndEmotionalHealth.HowStressedAreYou.NoAnswerToQuestion', e.currentTarget.checked);
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="h6" component="label">
                                                Optional Additional Questions
                                            </Typography>
                                            <hr />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>Do you feel physically and emotionally safe where you currently live?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.Answer"
                                                value={values.OptionalAdditionalQuestions?.DoYouFeelPhysicallyAndEmotionallySafe?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.Answer',
                                                        e.currentTarget.value
                                                    );
                                                    setFieldValue(
                                                        'OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.NoAnswerToQuestion',
                                                        false
                                                    );
                                                }}
                                            >
                                                {emotionallySafeList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer14-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.OptionalAdditionalQuestions?.DoYouFeelPhysicallyAndEmotionallySafe?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.Answer', null);
                                                    setFieldValue(
                                                        'OptionalAdditionalQuestions.DoYouFeelPhysicallyAndEmotionallySafe.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <hr className="hr-lite" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <FormLabel>In the past year, have you been afraid of your partner or ex-partner?</FormLabel>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6}>
                                            <RadioGroup
                                                row
                                                name="OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.Answer"
                                                value={values.OptionalAdditionalQuestions?.HaveYouBeenAfraidOfYourPartner?.Answer}
                                                onChange={(e) => {
                                                    setFieldValue('OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.Answer', e.currentTarget.value);
                                                    setFieldValue('OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.NoAnswerToQuestion', false);
                                                }}
                                            >
                                                {afraidOfPartnerList.map((type, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`answer14-label-${index}`}
                                                            value={type.Answer}
                                                            control={<Radio />}
                                                            label={type.Answer}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Checkbox
                                                color="primary"
                                                checked={values.OptionalAdditionalQuestions?.HaveYouBeenAfraidOfYourPartner?.NoAnswerToQuestion}
                                                onChange={(e) => {
                                                    setFieldValue('OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.Answer', null);
                                                    setFieldValue(
                                                        'OptionalAdditionalQuestions.HaveYouBeenAfraidOfYourPartner.NoAnswerToQuestion',
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                            <FormLabel className="no-answer-text">I choose not to answer this question</FormLabel>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item justifyContent="flex-end" className="content-footer">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ mr: 3 }}
                                disabled={isSaving}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSaving}
                                endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                    <SdohNeedsIdentifiedModal
                        isOpen={showNeedsIdentifiedModal}
                        handleClose={() => setShowNeedsIdentifiedModal(false)}
                        handleSubmit={(sdohAssessment: SdohAssessment) => {
                            dispatch(prepareSdohRequest(sdohAssessment));
                            dispatch(gotoNextMenu());
                        }}
                    />
                    <AlertDialog
                        open={showSaveAlert}
                        title="SDOH Assessment"
                        cancelText="Go Back"
                        okText="Confirm"
                        onConfirm={() => {
                            saveSdoh(values, true);
                            setShowNeedsIdentifiedModal(true);
                        }}
                        onSkip={() => saveSdoh(values)}
                        showBottomCenter={true}
                        isLoading={isSaving}
                        onClose={() => setShowSaveAlert(false)}
                        message="I confirm and acknowledge that SDOH assessment is completed."
                    />
                </Form>
            )}
        </Formik>
    );
};

export default Sdoh;
