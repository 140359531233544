import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { modalStyle } from '../../../utils/constants';
import { localDateFormat } from '../../../utils/timeFormat';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RootState } from '../../../reducers';
import { fetchHealthPlanList } from '../../../store/shared.slice';
import {
    exportEncounterBilling,
    setEncounterBillingStart,
    setEncounterBillingEnd,
    setEncounterBillingHealthPlan,
    setEncounterBillingProvider,
    fetchEncounterBillingReport,
    setModalVisibility,
    setEncounterServices,
    setEncounterName,
    submitToCH,
    setRowData,
} from '../../../store/generateEncounterReports.slice';
import { placesOfService } from '../../../utils/mappings';
import { moneyFormatter } from '../../../utils/moneyFormat';
import { isDefaultDate } from '../../../utils/common';
import { copyObject } from '../../../utils/common';
import { getActiveProviders } from '../../../store/provider.slice';

const GenerateEncounterBillingReport = () => {
    const dispatch = useDispatch();
    const {
        encounterBillingStart,
        encounterBillingEnd,
        encounterBillingHealthPlan,
        encounterBillingProvider,
        encounterName,
        rowData,
        modalVisibilty,
        encounterServices,
    } = useSelector((state: RootState) => state.generateEncounterBillingReport);
    const { healthPlans, downLoading } = useSelector((state: RootState) => state.shared);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [showInActive, setShowInActive] = useState<boolean>(false);
    const copyProviders = copyObject(activeProviders);
    const filteredProviders = (showInActive ? copyProviders : copyProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    // Columns for Main Data Grid
    const columns: GridColDef[] = [
        {
            field: 'MemberId',
            headerName: 'Member Id',
            minWidth: 200,
            flex: 0.75,
        },
        {
            field: 'Mem_AHCCCS_ID',
            headerName: 'AHCCCS_ID',
            minWidth: 200,
            flex: 0.75,
        },
        {
            field: 'PatientLastName',
            headerName: 'Last Name',
            flex: 1.5,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${params.row.PatientLastName?.toUpperCase()}`,
        },
        {
            field: 'PatientFirstName',
            headerName: 'First Name',
            flex: 1.5,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${params.row.PatientFirstName?.toUpperCase()}`,
        },
        {
            field: 'DOB',
            headerName: 'Birth Date',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : localDateFormat(new Date(params.row.DOB))}`,
        },
        {
            field: 'PatientAddress',
            headerName: 'Address',
            flex: 1,
            minWidth: 160,
        },
        {
            field: 'PatientCity',
            headerName: 'City',
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'PatientState',
            headerName: 'State',
            flex: 0.75,
            minWidth: 80,
        },
        {
            field: 'PatientPostalCode',
            headerName: 'ZipCode',
            flex: 1,
            minWidth: 110,
        },
        {
            field: 'Gender',
            headerName: 'Gender',
            flex: 0.75,
            minWidth: 110,
        },
        {
            field: 'InsuranceType',
            headerName: 'Insurance Type',
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'PhysicianFirstName',
            headerName: 'Provider',
            flex: 1,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${params.row.PhysicianFirstName} ${params.row.PhysicianLastName}`,
        },
        {
            field: 'Diagnosis',
            headerName: 'Diagnosis',
            flex: 1.5,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${params.row.Diagnosis.map((item) => ' ' + item.Code)}`,
        },
        {
            field: 'TotalAmount',
            headerName: 'Total Amount',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.TotalAmount)}`,
        },
        {
            field: 'Services',
            headerName: 'Encounter Services',
            flex: 1,
            minWidth: 140,
            renderCell: (params: GridRenderCellParams<string>) => {
                return <VisibilityIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => openModal(params)} />;
            },
        },
    ];


    const openModal = (params) => {
        dispatch(setModalVisibility(true));
        dispatch(setEncounterServices(params.row.Services));
        dispatch(setEncounterName(`${params.row.PatientFirstName} ${params.row.PatientLastName}`));
    };
    const closeModal = () => dispatch(setModalVisibility(false));

    // Modal Columns
    const modalColumns: GridColDef[] = [
        {
            field: 'DateOfService',
            headerName: 'Date',
            flex: 0.75,
        },
        {
            field: 'PlaceOfService',
            headerName: 'Place Of Service',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.PlaceOfService) {
                    let found = placesOfService.find((x) => x.value === params.row.PlaceOfService.toString());
                    return found.label;
                } else {
                    return '';
                }
            },
        },
        {
            field: 'CPTdescription',
            headerName: 'CPT',
            flex: 1.75,
        },
        {
            field: 'Charges',
            headerName: 'Charges',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.Charges)}`,
        },
        {
            field: 'Units',
            headerName: 'Units',
            flex: 0.5,
        },
        {
            field: 'PrimaryDiagnosisPointer',
            headerName: 'Diagnosis',
            flex: 1.5,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Stack direction={'column'}>
                        <Typography variant="body2">Primary: {params.row.PrimaryDiagnosisPointer.Code}</Typography>
                        <Typography variant="body2">
                            {params.row.SecondaryDiagnosisPointer !== null ? 'Secondary: ' + params.row.SecondaryDiagnosisPointer.Code : ''}
                        </Typography>
                        <Typography variant="body2">
                            {params.row.TertiaryDiagnosisPointer !== null ? 'Tertiary: ' + params.row.TertiaryDiagnosisPointer.Code : ''}
                        </Typography>
                        <Typography variant="body2">
                            {params.row.QuaternaryDiagnosisPointer !== null ? 'Quaternary: ' + params.row.QuaternaryDiagnosisPointer.Code : ''}
                        </Typography>
                    </Stack>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(getActiveProviders(true));
        dispatch(fetchHealthPlanList());
        dispatch(setEncounterBillingProvider('-1'));
    }, [dispatch]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActive(isChecked);
        dispatch(setEncounterBillingProvider('-1'));
        dispatch(setRowData([]));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Modal open={modalVisibilty} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={modalStyle}
                    style={{
                        height: '76vh',
                        width: '80vw',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '16px 16px 16px 16px',
                    }}
                >
                    <Typography>Encounter Services for {encounterName}</Typography>
                    <DataGrid
                        rows={encounterServices}
                        columns={modalColumns}
                        pageSize={15}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.Id}
                        getRowHeight={() => 'auto'}
                        sx={{
                            marginY: '1rem',
                            [`& .${gridClasses.cell}`]: {
                                py: 2,
                            },
                        }}
                    />
                    <Button variant="outlined" sx={{ width: '120px', alignSelf: 'flex-end' }} onClick={closeModal}>
                        Close
                    </Button>
                </Box>
            </Modal>

            <Box style={{ width: '100%', marginLeft: '8px' }} component={'div'}>
                <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                    <Box width={'100%'} borderBottom={1} paddingBottom={1}>
                        <Typography fontWeight="fontWeightMedium" component="h3">
                            Generate Encounter Billing Report
                        </Typography>
                    </Box>
                    {<Loading message={'Loading...'} />}
                    <Grid alignItems={'center'} container textAlign={'left'} paddingY={1}>
                        <Grid item xs={10} lg={8}>
                            <Stack direction="row" alignItems={'center'} spacing={3}>
                                <FormControl className="form-control-200">
                                    <DesktopDatePicker
                                        label="Start Date:"
                                        value={encounterBillingStart}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            dispatch(setEncounterBillingStart(newValue));
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <DesktopDatePicker
                                        label="End Date:"
                                        value={encounterBillingEnd}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            dispatch(setEncounterBillingEnd(newValue));
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Health Plan</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={encounterBillingHealthPlan}
                                        value={encounterBillingHealthPlan}
                                        label="Health Plan"
                                        onChange={(e) => {
                                            dispatch(setEncounterBillingHealthPlan(e.target.value));
                                        }}
                                    >
                                        <MenuItem value={'null'}>All</MenuItem>
                                        {healthPlans.map((healthPlan) => (
                                            <MenuItem value={healthPlan.Id} key={healthPlan.Id}>
                                                {healthPlan.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl className="form-control-200">
                                    <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={encounterBillingProvider}
                                        value={encounterBillingProvider}
                                        label="Provider"
                                        onChange={(e) => {
                                            dispatch(setEncounterBillingProvider(e.target.value));
                                        }}
                                    >
                                        <MenuItem value={'-1'}>All</MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={<Checkbox checked={showInActive} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                            label="Show Inactive"
                                        />
                                        {[...filteredProviders]
                                            .sort((a, b) => a.LastName.localeCompare(b.LastName))
                                            .map((provider) => (
                                                <MenuItem
                                                    value={provider.Id}
                                                    key={provider.Id}
                                                    sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                                >
                                                    {provider.LastName}, {provider.FirstName}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    onClick={() =>
                                        dispatch(
                                            fetchEncounterBillingReport(
                                                encounterBillingStart,
                                                encounterBillingEnd,
                                                encounterBillingHealthPlan,
                                                encounterBillingProvider,
                                                showInActive
                                            )
                                        )
                                    }
                                    style={{ minWidth: '120px' }}
                                    variant="contained"
                                >
                                    Search
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={10} lg={4} marginY={2} display="flex">
                            <Stack direction="row" spacing={3} sx={{ marginLeft: 'auto!important', marginRight: '0' }}>
                                <Button
                                    disabled={rowData.length < 1 || encounterBillingProvider === '-1'}
                                    style={{ minWidth: '120px' }}
                                    onClick={() =>
                                        dispatch(submitToCH(encounterBillingStart, encounterBillingEnd, encounterBillingHealthPlan, encounterBillingProvider))
                                    }
                                    variant="contained"
                                >
                                    Submit to CH
                                </Button>
                                <Button
                                    style={{ minWidth: '120px' }}
                                    onClick={() =>
                                        dispatch(
                                            exportEncounterBilling(
                                                encounterBillingStart,
                                                encounterBillingEnd,
                                                encounterBillingHealthPlan,
                                                encounterBillingProvider,
                                                showInActive
                                            )
                                        )
                                    }
                                    variant="contained"
                                    color="success"
                                >
                                    {downLoading ? 'Downloading..' : 'Export'}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid width={'100%'}>
                        <div style={{ height: '82vh', width: '100%' }}>
                            <DataGrid
                                rows={rowData}
                                columns={columns}
                                pageSize={15}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.Id}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default GenerateEncounterBillingReport;
