import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material';
import { CarePlanSignatureStatusEnum } from '../../../Enum/CarePlanSignatureStatusEnum';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { handleSendToMember, setShowSignatureModal, setSignatureType } from '../../../store/carePlan.slice';
import classes from './Styles';
import moment from 'moment';
import { ReviewHistory } from '../../../Models/CarePlans/ReviewHistory.model';
import { CarePlanSignatureTypeEnum } from '../../../Enum/CarePlanSignatureTypeEnum';

interface MemberReviewProps {
    memberId: string;
}

const MemberReview = (props: MemberReviewProps) => {
    const { memberId } = props;
    const { integratedCarePlan } = useSelector((state: RootState) => state.carePlan);
    const dispatch = useDispatch();

    const reviewDetail = Boolean(integratedCarePlan?.ReviewHistory?.length) ? integratedCarePlan?.ReviewHistory[0] : null;
    const reviewDetailMember = Boolean(integratedCarePlan?.ReviewHistoryMember?.length) ? integratedCarePlan?.ReviewHistoryMember[0] : null;

    const hasBeenReviewedRecently = (planEntry: string, reviewDetail: ReviewHistory) => {
        planEntry = planEntry || null;
        const lastReviewed = reviewDetail?.LastReviewedOn || null;
        const signature = reviewDetail?.Signature || null;

        let status = false;

        if (planEntry !== null && lastReviewed !== null && signature !== null) {
            status = moment(lastReviewed).isAfter(moment(planEntry));
        }
        return status;
    };
    const allEntries = [
        ...(integratedCarePlan?.Goals?.filter((g) => !g.DeletedDate) || []),
        ...(integratedCarePlan?.Plans?.filter((g) => !g.DeletedDate) || []),
        ...(integratedCarePlan?.SchedulePlans || []),
        ...(integratedCarePlan?.Barriers || []),
        ...(integratedCarePlan?.Strengths || []),
        ...(integratedCarePlan?.Metrics || []),
        ...(integratedCarePlan?.NextSteps || []),
    ];

    const mostRecentEntry = allEntries.reduce((latest, entry) => {
        return entry?.UpdatedAt > latest?.UpdatedAt ? entry : latest;
    }, allEntries[0]);

    const providerStatus = hasBeenReviewedRecently(mostRecentEntry?.UpdatedAt, reviewDetail);
    const memberStatus = hasBeenReviewedRecently(mostRecentEntry?.UpdatedAt, reviewDetailMember);

    const disableSendToTablet = () => {
        let response = false;
        if (allEntries.length === 0) {
            response = true;
        }
        if (providerStatus && memberStatus) {
            response = true;
        }
        return response;
    };
    const handleSign = () => {
        dispatch(setSignatureType(CarePlanSignatureTypeEnum.Provider));
        dispatch(setShowSignatureModal(true));
    };

    return (
        <Box pl={1} mb={4}>
            <Typography sx={classes.planTitle} variant="h6" color={'primary'} mb={2} pl={1}>
                Review With Member
            </Typography>
            <Divider sx={{ mb: 4 }} />
            <Stack direction="row" justifyContent="flex-end" paddingRight={2} alignItems={'flex-start'}>
                <Stack spacing={1} alignItems={'center'} sx={{ mr: 3 }}>
                    <Typography variant="body1">Provider Signature</Typography>
                    <Chip
                        label={providerStatus ? CarePlanSignatureStatusEnum.CompleteDescription : CarePlanSignatureStatusEnum.IncompleteDescription}
                        color={providerStatus ? 'success' : 'error'}
                        size="medium"
                    />
                    {providerStatus ? (
                        <Typography variant="subtitle2" fontStyle={'italic'} color={'text.secondary'}>
                            {moment(reviewDetail.LastReviewedOn).format('MM/DD/YYYY')}
                        </Typography>
                    ) : null}
                </Stack>
                <Stack spacing={1} alignItems={'center'} sx={{ mr: 'auto' }}>
                    <Typography variant="body1">Member Signature</Typography>
                    {!integratedCarePlan.ShareWithMember && (
                        <Chip
                            label={memberStatus ? CarePlanSignatureStatusEnum.CompleteDescription : CarePlanSignatureStatusEnum.IncompleteDescription}
                            color={memberStatus ? 'success' : 'error'}
                            size="medium"
                        />
                    )}
                    {integratedCarePlan.ShareWithMember && <Chip label={CarePlanSignatureStatusEnum.PendingDescription} color={'warning'} size="medium" />}
                    {!integratedCarePlan.ShareWithMember && memberStatus ? (
                        <Typography variant="subtitle2" fontStyle={'italic'} color={'text.secondary'}>
                            {moment(reviewDetailMember.LastReviewedOn).format('MM/DD/YYYY')}
                        </Typography>
                    ) : null}
                </Stack>
                <Stack direction={'row'} spacing={2} alignSelf={'center'}>
                    <Button
                        disabled={disableSendToTablet()}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            dispatch(handleSendToMember(memberId, true));
                        }}
                    >
                        Send to Tablet
                    </Button>
                    <Button disabled={disableSendToTablet()} sx={{ minWidth: '120px' }} variant="contained" color="primary" onClick={handleSign}>
                        Sign
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
};

export default MemberReview;
