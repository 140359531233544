import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowForwardingModal } from '../../../store/requests.slice';
import { IRequestHistoryButtonsProps } from '../interfaces/IRequestHistoryButtonsProps';
import RequestTimelineModal from '../RequestTimeline/RequestTimelineModal';

const RequestHistoryButtons: React.FC<IRequestHistoryButtonsProps> = (props) => {
    const { selectedRequest } = props;
    const [showTimelineModal, setShowTimelineModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    return (
        <Grid container spacing={0} alignItems="center" justifyContent="center">
            <RequestTimelineModal open={showTimelineModal} onClose={() => setShowTimelineModal(false)} timelineEvents={selectedRequest?.TimelineEvents ?? []} />
            <Grid item lg={5} md={5} sm={6} xs={6}>
                <Button
                    fullWidth
                    onClick={() => {
                        dispatch(setShowForwardingModal(true));
                    }}
                    variant="contained"
                    endIcon={<KeyboardDoubleArrowRightIcon fontSize="large" />}
                >
                    Forward
                </Button>
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={3}>
                <Tooltip title="Show Complete Forward History">
                    <IconButton
                        onClick={() => setShowTimelineModal(true)}
                        style={{
                            margin: '15 0 0 0',
                            textAlign: 'center',
                        }}
                    >
                        <WorkHistoryIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            {selectedRequest?.ForwardBy?.length > 0 && (
                <Grid item lg={5} md={5} sm={3} xs={3}>
                    <Typography
                        variant="caption"
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <pre
                            style={{
                                marginTop: 15,
                                fontSize: 10,
                                fontFamily: 'inherit',
                                textAlign: 'left',
                            }}
                        >
                            Previously assigned to
                            {'\n'}
                            {selectedRequest?.ForwardBy[selectedRequest?.ForwardBy?.length - 1]?.Name ?? 'Unknown'}
                        </pre>
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(RequestHistoryButtons);
