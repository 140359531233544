export default interface IHouseHoldExpense {
    Name: string;
    Amount: number;
}

export class HouseHoldExpense implements IHouseHoldExpense {
    Name: string;
    Amount: number;

    constructor() {
        this.Name = '';
    }
}
