import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { EncounterList } from '../Models/Encounters/EncounterList';
import { CPTCodeGroup } from '../Models/Shared/CPTCodeGroup';
import { ICD10 } from '../Models/Shared/ICD10.model';
import { copyObject, getCPTUserRole } from '../utils/common';
import { saveEngagementDraft } from './documentEngagement.slice';
import { setAssessmentError } from './assessment.slice';
import { roleEnum } from '../utils/assessments';
import { DiagnosisDraft } from '../Models/DocumentEngagements/DiagnosisDraft.model';
import { MemberEncounterDraft } from '../Models/Member/MemberEncounterDraft.model';
import { MemberDefaultDiagnosis } from '../Models/DocumentEngagements/MemberDefaultDiagnosis.model';
import Authorization from '../utils/Authorization';

const encounterSlice = createSlice({
    name: 'encounter',
    initialState: {
        cptCodeGroups: [] as CPTCodeGroup[],
        cptCodeCategories: [] as CPTCodeGroup[],
        cptUnits: [],
        diagnosys: [] as ICD10[],
        memberPastDiagnosys: [] as ICD10[],
        encounterList: new EncounterList(),
        diagnosisFromClaims: [] as ICD10[],
        diagnosisFromCHP: [] as ICD10[],
        memberDefaultDiagnosis: new MemberDefaultDiagnosis(),
        isFetching: false,
        isFetchingDisgnosys: false,
        isSaving: false,
        isSavingDefaultDiagnosis: false,
    },
    reducers: {
        _setCptCodeGroups(state, action) {
            state.cptCodeGroups = action.payload;
        },
        _setCptCodeCategories(state, action) {
            state.cptCodeCategories = action.payload;
        },
        _setGetCPTUnits(state, action) {
            state.cptUnits = action.payload;
        },
        _setDisgnosys(state, action) {
            state.diagnosys = action.payload;
        },
        _setEncounterList(state, action) {
            state.encounterList = action.payload;
        },
        _setDiagnosisFromClaims(state, action) {
            state.diagnosisFromClaims = action.payload;
        },
        _setDiagnosisFromCHP(state, action) {
            state.diagnosisFromCHP = action.payload;
        },
        _setMemberDefaultDiagnosis(state, action) {
            state.memberDefaultDiagnosis = action.payload;
        },
        _setMemberPastDiagnosys(state, action) {
            state.memberPastDiagnosys = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleFetchingDisgnosys(state, action) {
            state.isFetchingDisgnosys = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsSavingDefaultDiagnosis(state, action) {
            state.isSavingDefaultDiagnosis = action.payload;
        },
    },
});

const {
    _setCptCodeGroups,
    _setCptCodeCategories,
    _setGetCPTUnits,
    _setDisgnosys,
    _setEncounterList,
    _setDiagnosisFromClaims,
    _setDiagnosisFromCHP,
    _setMemberDefaultDiagnosis,
    _setMemberPastDiagnosys,
    _toggleIsFetching,
    _toggleFetchingDisgnosys,
    _toggleIsSaving,
    _toggleIsSavingDefaultDiagnosis,
} = encounterSlice.actions;

export const deleteEncounters =
    (memberId: string, encounterIds: string[], searchClaims: Function): AppThunk =>
    async () => {
        await axios.delete(`${API_URL}/encounter/deleteencounters?memberId=${memberId}${encounterIds.map((value) => `&encounterIds=${value}`).join('')}`);
        searchClaims();
    };

export const getEncounterFromSummaryNotes =
    (memberId: string, draftId: string = ''): AppThunk =>
    async (dispatch, getState) => {
        draftId = Boolean(draftId) ? draftId : getState().documentEngagement?.engagementDraft?.Id;
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/encounter/getencounterfromsummarynotes`, { params: { memberId, draftId } });
        dispatch(_setEncounterList(convertEncountersToList(response.data)));
        dispatch(_toggleIsFetching(false));
    };

export const setEncounterList =
    (encounterList: EncounterList, isSaveLocal = false): AppThunk =>
    (dispatch, getState) => {
        let engagementDraft = copyObject(getState().documentEngagement.engagementDraft);
        encounterList = convertListToEncounters(encounterList);
        dispatch(_setEncounterList(encounterList));

        if (!isSaveLocal) {
            engagementDraft.Encounters = encounterList.Encounters;
            dispatch(saveEngagementDraft(engagementDraft));
        }
    };

export const fetchCptCodeGroups =
    (isInHra = false, useAll = false): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        let selectedRole = getState().assessment.selectedRole;
        const response = await axios.get(`${API_URL}/encounter/getcptcodegroups`);
        dispatch(_setCptCodeGroups(response.data));
        let cptCodeCategories = [];
        let cptUserRole = getCPTUserRole(selectedRole);
        if (getState().shared.currentProvider) {
            cptUserRole = getCPTUserRole(getState().shared.currentProvider.Role.RoleName);
        }
        let isHraMember = getState().memberDetail?.memberBasicInfo?.IsInHra || false;
        const authData = new Authorization();
        response.data.forEach((category) => {
            let addCategory = false;
            category.CPTRoleCodes.forEach((roleCode) => {
                if (
                    !useAll &&
                    (roleCode.Role === cptUserRole ||
                        (isHraMember &&
                            ((isInHra && (authData.Role === roleEnum.NURSE_PRACTITIONER || authData.Role === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST)) ||
                                selectedRole === roleEnum.NURSE_PRACTITIONER ||
                                selectedRole === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST) &&
                            roleCode.Role === 'HRA'))
                ) {
                    addCategory = true;
                }
            });

            if (useAll || addCategory) {
                cptCodeCategories.push(category);
            }
        });
        dispatch(_setCptCodeCategories(cptCodeCategories));
        dispatch(setIsLoading(false));
    };

export const getCPTUnits = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/encounter/getCPTUnits`);
    dispatch(_setGetCPTUnits(response.data));
    dispatch(setIsLoading(false));
};

export const getICD10DiagnosisByCode = async (code: string) => {
    const response = await axios.get(`${API_URL}/member/geticd10bycode?code=${code}`);
    return response.data;
};

export const getICD10DiagnosisByKeyword =
    (keyword: string, assessmentRoleId = ''): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleFetchingDisgnosys(true));
        axios
            .get(`${API_URL}/member/geticd10diagnosisbykeyword`, {
                params: { keyword, assessmentRoleId },
            })
            .then((response) => {
                dispatch(_setDisgnosys(response.data));
            })
            .catch((err) => {
                dispatch(_setDisgnosys([]));
            })
            .finally(() => {
                dispatch(_toggleFetchingDisgnosys(false));
            });
    };

export const resetDisgnosysList = (): AppThunk => async (dispatch, getState) => {
    dispatch(_setDisgnosys([]));
};

export const getICD10DiagnosisByCategory =
    (categoryId: string, encounterIndex: number, diagnosisIndex: number, bindResponsePropName: string, isDefaultDiagnosis = false): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/member/geticd10diagnosisbycategory`, { params: { categoryId } });
        if (isDefaultDiagnosis) {
            let memberDefaultDiagnosis = copyObject(getState().encounter.memberDefaultDiagnosis) as MemberDefaultDiagnosis;
            memberDefaultDiagnosis.DefaultDiagnosis[bindResponsePropName] = response.data;

            dispatch(_setMemberDefaultDiagnosis(memberDefaultDiagnosis));
        } else {
            let encounterList = copyObject(getState().encounter.encounterList);
            encounterList.Encounters[encounterIndex].DiagnosisList[diagnosisIndex][bindResponsePropName] = response.data;

            dispatch(_setEncounterList(encounterList));
        }
    };

export const insertEncountersNotAsssessment =
    (encounterList: any, notToEncounter: boolean, callback?: (notes?: string) => void): AppThunk =>
    async (dispatch, getState) => {
        const engagementDraftId = getState().documentEngagement.engagementDraft.Id;
        dispatch(_toggleIsSaving(true));
        await axios.post(`${API_URL}/encounter/insertencounters`, encounterList, {
            params: { engagementDraftId, notToEncounter },
        });
        dispatch(_toggleIsSaving(false));
        if (callback) {
            console.trace('callback');
            const notes = getState().documentEngagement.engagementDraft.Notes;
            callback(notes);
        }
    };

export const insertEncounters =
    (encounterList: any, notToEncounter: boolean, callback: Function): AppThunk =>
    async (dispatch, getState) => {
        const engagementDraftId = getState().documentEngagement.engagementDraft.Id;
        dispatch(_toggleIsSaving(true));
        try {
            await axios.post(`${API_URL}/encounter/insertencounters`, encounterList, {
                params: { engagementDraftId, notToEncounter },
            });
            dispatch(_toggleIsSaving(false));
            if (Boolean(callback)) {
                callback();
            }
        } catch (err) {
            dispatch(
                setAssessmentError({
                    Show: true,
                    Message: err?.response?.data?.error,
                })
            );
            dispatch(_toggleIsSaving(false));
        }
    };

export const fetchDiagnosisCodesFromClaims =
    (memberId: string, assessmentRoleId = ''): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/member/GetDiagnosisCodesFromClaims`, { params: { memberId, assessmentRoleId } });
        dispatch(_setDiagnosisFromClaims(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const convertEncountersToList = (encounterList: EncounterList) => {
    let encounterData = copyObject(encounterList);
    encounterData.Encounters.forEach((encounter: MemberEncounterDraft) => {
        encounter.DiagnosisList = [] as DiagnosisDraft[];

        if (Boolean(encounter.PrimaryDiagnosis?.Diagnosis)) {
            encounter.DiagnosisList.push(encounter.PrimaryDiagnosis);
        } else {
            encounter.DiagnosisList.push(new DiagnosisDraft());
        }

        if (Boolean(encounter.SecondaryDiagnosis?.Diagnosis)) {
            encounter.DiagnosisList.push(encounter.SecondaryDiagnosis);
        }

        if (Boolean(encounter.TertiaryDiagnosis?.Diagnosis)) {
            encounter.DiagnosisList.push(encounter.TertiaryDiagnosis);
        }

        if (Boolean(encounter.QuaternaryDiagnosis?.Diagnosis)) {
            encounter.DiagnosisList.push(encounter.QuaternaryDiagnosis);
        }

        if (Boolean(encounter.AdditionalDiagnosis?.length)) {
            encounter.DiagnosisList = encounter.DiagnosisList.concat(encounter.AdditionalDiagnosis);
        }
    });

    return encounterData;
};

const convertListToEncounters = (encounterList: EncounterList) => {
    let encounterData = copyObject(encounterList);
    encounterData.Encounters.forEach((encounter: MemberEncounterDraft) => {
        if (Boolean(encounter.DiagnosisList?.length)) {
            encounter.PrimaryDiagnosis = encounter.DiagnosisList[0];
            if (encounter.DiagnosisList.length > 1) {
                encounter.SecondaryDiagnosis = encounter.DiagnosisList[1];
            } else {
                encounter.SecondaryDiagnosis = new DiagnosisDraft();
            }

            if (encounter.DiagnosisList.length > 2) {
                encounter.TertiaryDiagnosis = encounter.DiagnosisList[2];
            } else {
                encounter.TertiaryDiagnosis = new DiagnosisDraft();
            }

            if (encounter.DiagnosisList.length > 3) {
                encounter.QuaternaryDiagnosis = encounter.DiagnosisList[3];
            } else {
                encounter.QuaternaryDiagnosis = new DiagnosisDraft();
            }

            if (encounter.DiagnosisList.length > 4) {
                encounter.AdditionalDiagnosis = encounter.DiagnosisList.slice(4, encounter.DiagnosisList.length);
            } else {
                encounter.AdditionalDiagnosis = [];
            }
        } else {
            encounter.PrimaryDiagnosis = null;
        }
    });

    return encounterData;
};

export const getDefaultDiagnosis =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        axios
            .get(`${API_URL}/encounter/getDefaultDiagnosis`, { params: { memberId } })
            .then((response) => {
                dispatch(_setMemberDefaultDiagnosis(response.data || new MemberDefaultDiagnosis()));
            })
            .catch(() => {
                dispatch(_setMemberDefaultDiagnosis(new MemberDefaultDiagnosis()));
            });
    };

export const updateDefaultDiagnosis =
    (memberDefaultDiagnosis: MemberDefaultDiagnosis, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSavingDefaultDiagnosis(true));
        axios
            .post(`${API_URL}/encounter/updateDefaultDiagnosis`, memberDefaultDiagnosis)
            .then((response) => {
                dispatch(_setMemberDefaultDiagnosis(response.data || new MemberDefaultDiagnosis()));
                if (Boolean(callback)) {
                    callback(response.data?.DefaultDiagnosis);
                }
            })
            .finally(() => {
                dispatch(_toggleIsSavingDefaultDiagnosis(false));
            });
    };

export const setDefaultDiagnosis =
    (memberDefaultDiagnosis: MemberDefaultDiagnosis): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setMemberDefaultDiagnosis(memberDefaultDiagnosis || new MemberDefaultDiagnosis()));
    };

export const getPastDiagnosisByMember =
    (memberId: string, assessmentRoleId = ''): AppThunk =>
    async (dispatch) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/encounter/getPastDiagnosis`, { params: { memberId, assessmentRoleId } })
            .then((response) => {
                dispatch(_setMemberPastDiagnosys(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const prepareICD10Hierarchy =
    (code: string, index: number, dxIndex: number): AppThunk =>
    async (dispatch, getState) => {
        axios.get(`${API_URL}/encounter/prepareicd10hierarchy`, { params: { code } }).then((response) => {
            let data = copyObject(getState().encounter.encounterList);
            data.Encounters[index].DiagnosisList[dxIndex] = response.data;
            dispatch(setEncounterList(data));
        });
    };

export const getChpDiagnosis =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleFetchingDisgnosys(true));
        axios
            .get(`${API_URL}/encounter/getchpdiagnosis`, { params: { memberId } })
            .then((response) => {
                dispatch(_setDiagnosisFromCHP(response.data));
            })
            .finally(() => {
                dispatch(_toggleFetchingDisgnosys(false));
            });
    };

export default encounterSlice.reducer;
