import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../../hooks/populationContext';
import { useAnalyticContext } from '../../../../hooks/analyticContext';
import { configureBhrtcChart } from '../../../ChartConfiguration/ChartConfiguration';
import FilterOptions from '../FilterOptions';
import { getBhRfCostGraph, getBfCostGraph } from '../AnalyticService';

const BhRtcCost = () => {
    const history = useNavigate();
    const { analyticPopulations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [graphSummary, setGraphSummary] = useState<any>([]);
    const [chartOptions, setChartOptions] = useState({});

    const {
        bhRtcPopulation,
        setBhRtcPopulation,
        bhRtcMemberStatus,
        setBhRtcMemberStatus,
        bhRtcTimePeriod,
        setBhRtcTimePeriod,
        bhRtcPart,
        setBhRtcPart,
        bhRtcPrimaryProvider,
        setBhRtcPrimaryProvider,
        bhRtcSecondaryProvider,
        setBhRtcSecondaryProvider,
        showBhRtcInActivePrimaryTeams,
        setShowBhRtcInActivePrimaryTeams,
        showBhRtcInActiveSecondaryTeams,
        setShowBhRtcInActiveSecondaryTeams,
    } = useAnalyticContext();

    const bhRtcName = 'BH RTC';
    const bhRfName = 'BH RF';

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureBhrtcChart(
                    'BH RTC/RF Costs',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [0] }],
                    [0],
                    'BhRtcCost',
                    history
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (isPopulationFetched) {
            const changeFilter = async () => {
                let response = [];
                let bhrtc, bhrf;
                const bhrfCost = await getBhRfCostGraph(
                    bhRtcTimePeriod,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    bhRtcPart,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                if (bhrfCost.status === 200) {
                    bhrtc = bhrfCost;
                    bhrtc.Name = bhRtcName;
                    response.push(bhrtc);
                }
                const bfCost = await getBfCostGraph(
                    bhRtcTimePeriod,
                    bhRtcPopulation,
                    bhRtcPrimaryProvider,
                    bhRtcPart,
                    bhRtcMemberStatus,
                    bhRtcSecondaryProvider,
                    showBhRtcInActivePrimaryTeams,
                    showBhRtcInActiveSecondaryTeams
                );
                if (bfCost.status === 200) {
                    bhrf = bfCost;
                    bhrf.Name = bhRfName;
                    response.push(bhrf);
                }
                setGraphSummary(response);
                setIsPrepared(true);
            };
            setChartOptions(
                configureBhrtcChart(
                    'BH RTC/RF Costs',
                    'Baseline year vs Performance year',
                    'Population',
                    'Cost',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'BhRtcCost',
                    history
                )
            );
            setIsGraphShow(false);
            changeFilter();
        }
    }, [
        bhRtcTimePeriod,
        bhRtcPopulation,
        bhRtcPrimaryProvider,
        bhRtcPart,
        bhRtcMemberStatus,
        showBhRtcInActivePrimaryTeams,
        showBhRtcInActiveSecondaryTeams,
        bhRtcSecondaryProvider,
        isPopulationFetched,
        history,
    ]);

    useEffect(() => {
        if (graphSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let isData = false;

            graphSummary.forEach((bhrtc) => {
                let yValues = [];
                if (bhrtc.Baseline != null) {
                    let tC = bhrtc.Baseline;
                    if (tC && tC > 0) {
                        isData = true;
                        yValues.push({ y: tC, name: 'BL Yr', title: bhrtc.Name });
                    }
                }
                if (bhrtc.PerformanceOne != null) {
                    let tC = bhrtc.PerformanceOne;
                    if (tC && tC > 0) {
                        isData = true;
                        yValues.push({ y: tC, name: 'Perf Yr', title: bhrtc.Name });
                    }
                }
                yAxisValues.push({
                    name: bhrtc.Name,
                    data: yValues,
                    pointPadding: 0,
                    color: bhrtc.Name === bhRtcName ? '#B0DBDE' : '#917DFF',
                    showInLegend: true,
                });
            });

            if (isData) {
                xAxisValues = ['BL Yr', 'Perf Yr'];
            } else {
                xAxisValues = [];
            }

            setChartOptions(configureBhrtcChart('BH RTC/RF Costs', '', '', 'Cost', yAxisValues, xAxisValues, 'BhRtcCost', history));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
    }, [graphSummary, bhRtcPopulation, analyticPopulations, isPrepared, history]);

    const navigateToMembers = () => {
        history(`/provider/metricsummary/bhrtccostdetail`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            BH RTC/RF Costs
                        </Typography>
                    </Grid>
                    <FilterOptions
                        population={bhRtcPopulation}
                        setPopulation={setBhRtcPopulation}
                        memberStatus={bhRtcMemberStatus}
                        setMemberStatus={setBhRtcMemberStatus}
                        timePeriod={bhRtcTimePeriod}
                        setTimePeriod={setBhRtcTimePeriod}
                        part={bhRtcPart}
                        setPart={setBhRtcPart}
                        primaryProvider={bhRtcPrimaryProvider}
                        setPrimaryProvider={setBhRtcPrimaryProvider}
                        secondaryProvider={bhRtcSecondaryProvider}
                        setSecondaryProvider={setBhRtcSecondaryProvider}
                        showInActivePrimaryTeams={showBhRtcInActivePrimaryTeams}
                        setShowInActivePrimaryTeams={setShowBhRtcInActivePrimaryTeams}
                        showInActiveSecondaryTeams={showBhRtcInActiveSecondaryTeams}
                        setShowInActiveSecondaryTeams={setShowBhRtcInActiveSecondaryTeams}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default BhRtcCost;
