export enum MeansOfEngagementEnum {
    InPerson = 1,
    Phone = 2,
    Telemedicine = 3,
    Email = 4,
    Office = 5,
    CareConference = 6,
    CareCoordination = 7,
    TextMessage = 8,
}
