import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, CircularProgress } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import MemberCareCircle from '../../Members/MemberDetail/MemberCareCircle';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { copyObject } from '../../../utils/common';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';

const MemberAssessmentCareCircle = () => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { engagementDraft, isSaving } = useSelector((state: RootState) => state.documentEngagement);

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            let data = copyObject(engagementDraft);
            data.SummaryNotes = data.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.CareCircle) || [];
            data.SummaryNotes.push({
                Id: '',
                CptCode: '',
                Zcode: '',
                Note: 'Completed Care Circle Information',
                Type: SummaryNoteTypeEnum.CareCircle,
                IsConfirm: false,
                OrderBy: 34,
                DisplayName: 'Care Circle',
            });

            dispatch(saveEngagementDraft({ ...data, CareCircleStatus: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    return (
        <>
            <Grid container direction="column" spacing={2} className="content-panel">
                <Grid item className="content-form">
                    <MemberCareCircle />
                </Grid>
                <Grid item justifyContent="flex-end" className="content-footer">
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginRight: 2 }}
                        disabled={isSaving}
                        onClick={() => dispatch(gotoPreviousMenu())}
                        startIcon={<ArrowBackIosIcon />}
                    >
                        Previous
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={() => setShowSaveAlert(true)}
                        disabled={isSaving}
                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : <ArrowForwardIosIcon />}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
            <AlertDialog
                open={showSaveAlert}
                title="Care Circle"
                cancelText="Go Back"
                okText="Confirm"
                onConfirm={() => handleOnConfirmToNext(true)}
                onSkip={() => handleOnConfirmToNext()}
                onClose={() => setShowSaveAlert(false)}
                isLoading={isSaving}
                message="I confirm and acknowledge that Care Circle is completed."
            />
        </>
    );
};

export default MemberAssessmentCareCircle;
