import React, { useCallback, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import { fetchSurveyChart, MenuProps } from '../../Widgets/WidgetService';
import { configureStackedSplitBarChart } from '../../ChartConfiguration/ChartConfiguration';
import SurveyChartSummary from '../../../Models/Widget/SurveyChartSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import '../Widgets.css';

const SurveySummary = () => {
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState<string>('All');
    const [encounterOptions, setEncounterOptions] = useState({});
    const [isEncounterGraphShow, setIsEncounterGraphShow] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState<number>(2);
    const [surveySummary, setSurveySummary] = useState<SurveyChartSummary>();
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const dateFilterList = [
        { Id: 1, Value: 'Last 3 Months' },
        { Id: 2, Value: 'Last 6 Months' },
        { Id: 3, Value: 'Last 1 Year' },
        { Id: 4, Value: 'Custom' },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isEncounterGraphShow) {
            setEncounterOptions(
                configureStackedSplitBarChart(
                    'Survey Summary',
                    'Survey Count',
                    'Month',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    '',
                    false
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isEncounterGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (selectedFilter !== TimePeriodFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate))) {
            const changeFilter = async () => {
                let start = fromDate !== null ? new Date(fromDate).toDateString() : null;
                let end = toDate !== null ? new Date(toDate).toDateString() : null;

                const data = await fetchSurveyChart(selectedProvider, selectedFilter, start, end);
                setSurveySummary(data);
            };

            setIsEncounterGraphShow(false);
            setEncounterOptions(
                configureStackedSplitBarChart(
                    'Survey Summary',
                    'Survey Count',
                    'Month',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    '',
                    '',
                    false
                )
            );
            changeFilter();
        }

        if (selectedFilter !== TimePeriodFilterEnum.Custom && Boolean(fromDate) && Boolean(toDate)) {
            setFromDate(null);
            setToDate(null);
        }
    }, [selectedProvider, selectedFilter, fromDate, toDate, showInActiveProviders]);

    useEffect(() => {
        if (surveySummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let totAppointmentValues = [];
            let totSurveyValues = [];
            for (var data in surveySummary.SurveyChartData) {
                let dataInfo = surveySummary.SurveyChartData[data];
                let percentage = dataInfo.AppointmentCount > 0 ? (dataInfo.SurveyCount / dataInfo.AppointmentCount) * 100 : 0;

                let d = {
                    y: dataInfo.SurveyCount,
                    name: Math.round(percentage),
                };
                totSurveyValues.push(d);

                let d1 = {
                    y: dataInfo.AppointmentCount,
                    name: '',
                };
                totAppointmentValues.push(d1);

                xAxisValues.push(dataInfo.Date);
            }
            yAxisValues.push({ name: 'Complete Appointments', data: totAppointmentValues, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Complete Appointments Survey Sent', data: totSurveyValues, color: '#00a652', showInLegend: true });

            if (surveySummary.SurveyChartData.length === 0) {
                setEncounterOptions(
                    configureStackedSplitBarChart(
                        'Survey Summary',
                        'Survey Count',
                        'Month',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        '',
                        '',
                        false
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setEncounterOptions(configureStackedSplitBarChart('Survey Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, '', '', false));
            }
            setIsEncounterGraphShow(true);
        }
    }, [surveySummary]);

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setFromDate(startDate);
        }
    };

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center" ref={ref}>
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                                Survey Summary
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="body2">Total complete appointments versus complete appointment surveys sent</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={selectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={selectedFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setSelectedFilter(parseInt(e.target.value as any))}
                                >
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {selectedFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(fromDate) ? fromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(toDate) ? toDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isEncounterGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={encounterOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default SurveySummary;
