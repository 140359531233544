import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import Loading from '../../Shared/Loading';
import { modalStyle } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getEncounterBillingReportHistory,
    setModalVisibility,
    setEncounterServices,
    setEncounterName,
} from '../../../store/encounterBillingHistoryReport.slice';
import { RootState } from '../../../reducers';
import { placesOfService } from '../../../utils/mappings';
import { moneyFormatter } from '../../../utils/moneyFormat';

const EncounterBillingReportHistory = () => {
    const dispatch = useDispatch();
    const { billingHistory, modalVisibilty, encounterServices, encounterName } = useSelector((state: RootState) => state.encounterBillingHistoryReport);

    const columns: GridColDef[] = [
        {
            field: 'PatientLastName',
            headerName: 'Last Name',
            flex: 1.5,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${params.row.EncounterBillings.Encounters[0].PatientLastName?.toUpperCase()}`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'PatientFirstName',
            headerName: 'First Name',
            flex: 1.5,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${params.row.EncounterBillings.Encounters[0].PatientFirstName?.toUpperCase()}`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 0.75,
            minWidth: 110,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${params.row.EncounterBillings.Encounters[0].DOB?.slice(4, 6)}/${params.row.EncounterBillings.Encounters[0].DOB?.slice(
                          6,
                          8
                      )}/${params.row.EncounterBillings.Encounters[0].DOB?.slice(0, 4)}`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'PatientAddress',
            headerName: 'Address',
            flex: 1.5,
            minWidth: 240,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${params.row.EncounterBillings.Encounters[0].PatientAddress}`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'PatientCity',
            headerName: 'City',
            flex: 1,
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0 ? `${params.row.EncounterBillings.Encounters[0].PatientCity}` : 'Missing Encounter';
            },
        },
        {
            field: 'PatientState',
            headerName: 'State',
            flex: 0.75,
            minWidth: 80,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0 ? `${params.row.EncounterBillings.Encounters[0].PatientState}` : 'Missing Encounter';
            },
        },
        {
            field: 'PatientPostalCode',
            headerName: 'ZipCode',
            flex: 1,
            minWidth: 80,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${params.row.EncounterBillings.Encounters[0].PatientPostalCode}`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'TotalAmount',
            headerName: 'Total Amount',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${moneyFormatter.format(params.row.EncounterBillings.Encounters[0].TotalAmount)}`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'PayerName',
            headerName: 'Payer Name',
            flex: 1.5,
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0 ? `${params.row.EncounterBillings.Encounters[0].PayerName}` : 'Missing Encounter';
            },
        },
        {
            field: 'PayerIdentificationCode',
            headerName: 'Payer Id',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${params.row.EncounterBillings.Encounters[0].PayerIdentificationCode}`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'CHStatus',
            headerName: 'CH Status',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${
                          params.row.EncounterBillings.Encounters[0].CHStatus === 1
                              ? 'Open'
                              : params.row.EncounterBillings.Encounters[0].CHStatus === 2
                              ? 'Pending'
                              : params.row.EncounterBillings.Encounters[0].CHStatus === 3
                              ? 'Duplicated'
                              : params.row.EncounterBillings.Encounters[0].CHStatus === 4
                              ? 'Accepted'
                              : params.row.EncounterBillings.Encounters[0].CHStatus === 5
                              ? 'Rejected'
                              : 'N/A'
                      }`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'PayerStatus',
            headerName: 'Payer Status',
            flex: 1,
            minWidth: 110,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.EncounterBillings.Encounters.length > 0
                    ? `${
                          params.row.EncounterBillings.Encounters[0].PayerStatus === 1
                              ? 'Open'
                              : params.row.EncounterBillings.Encounters[0].PayerStatus === 2
                              ? 'Acknowledged'
                              : params.row.EncounterBillings.Encounters[0].PayerStatus === 3
                              ? 'Rejected'
                              : 'null'
                      }`
                    : 'Missing Encounter';
            },
        },
        {
            field: 'Encounters',
            headerName: 'Encounters',
            flex: 1,
            minWidth: 90,
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.row.EncounterBillings.Encounters.length > 0 ? (
                    <VisibilityIcon color="primary" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => openModal(params)} />
                ) : (
                    'Missing Encounter'
                );
            },
        },
    ];

    // Modal for Encounters

    const openModal = (params) => {
        dispatch(setModalVisibility(true));
        dispatch(setEncounterServices(params.row.EncounterBillings.Encounters[0].Services));
        dispatch(
            setEncounterName(`${params.row.EncounterBillings.Encounters[0].PatientFirstName} ${params.row.EncounterBillings.Encounters[0].PatientLastName}`)
        );
    };
    const closeModal = () => dispatch(setModalVisibility(false));

    // Modal Columns
    const modalColumns: GridColDef[] = [
        {
            field: 'DateOfService',
            headerName: 'Date',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => {
                const dateOfService = params.row.DateOfService;
                return `${dateOfService?.slice(4, 6)}/${dateOfService?.slice(6, 8)}/${dateOfService?.slice(0, 4)}`;
            },
        },
        {
            field: 'PlaceOfService',
            headerName: 'Place Of Service',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => {
                if (params.row.PlaceOfService) {
                    let found = placesOfService.find((x) => x.value === params.row.PlaceOfService.toString());
                    return found.label;
                } else {
                    return '';
                }
            },
        },
        {
            field: 'CPTdescription',
            headerName: 'CPT',
            flex: 1.75,
            valueGetter: (params: GridValueGetterParams) => `${params.row.CPTdescription}`,
        },
        {
            field: 'Charges',
            headerName: 'Charges',
            flex: 0.75,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.Charges)}`,
        },
        {
            field: 'Units',
            headerName: 'Units',
            flex: 0.5,
            valueGetter: (params: GridValueGetterParams) => `${params.row.Units}`,
        },
        {
            field: 'PrimaryDiagnosisPointer',
            headerName: 'Diagnosis',
            flex: 1.5,
            valueGetter: (params: GridValueGetterParams) => `Primary: ${params.row.PrimaryDiagnosisPointer.Code}`,
        },
    ];

    // End Modal for Encounters

    useEffect(() => {
        dispatch(getEncounterBillingReportHistory());
    }, [dispatch]);

    return (
        <Box style={{ height: '94vh', width: '100%' }} component={'div'}>
            <Box borderBottom={1} paddingBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Encounter Billing Report History
                </Typography>
            </Box>
            <Modal open={modalVisibilty} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={modalStyle}
                    style={{ height: '76vh', width: '80vw', display: 'flex', flexDirection: 'column', top: '50vh', padding: '16px 16px 16px 16px' }}
                >
                    <Typography>Encounter Services for {encounterName}</Typography>
                    <DataGrid
                        rows={encounterServices}
                        columns={modalColumns}
                        pageSize={15}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.Id}
                        getRowHeight={() => 'auto'}
                        sx={{
                            marginY: '1rem',
                            [`& .${gridClasses.cell}`]: {
                                py: 2,
                            },
                        }}
                    />
                    <Stack direction="row">
                        <Button variant="outlined" sx={{ width: '120px' }} onClick={closeModal}>
                            Close
                        </Button>
                        <Button disabled variant="contained" sx={{ width: '120px', marginLeft: 'auto' }} onClick={() => console.log('regenerate clicked')}>
                            ReGenerate
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            {<Loading message={'Loading...'} />}
            <DataGrid
                rows={billingHistory}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.Id}
                getRowHeight={() => 'auto'}
                sx={{
                    py: 2,
                    [`& .${gridClasses.cell}`]: {
                        py: 2,
                    },
                }}
            />
        </Box>
    );
};

export default EncounterBillingReportHistory;
