import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import { DigitalSignatureForm } from '../Models/Consent/DigitalSignatureForm.model';
import { ConsentSignatureTypeEnum } from '../Enum/ConsentSignatureTypeEnum';
import axios from 'axios';
import { setShowMessage } from './shared.slice';

export const consentSteps = [
    {
        label: 'Consent Form',
        value: 0,
    },
    {
        label: 'Release Health Information',
        value: 1,
    },
    {
        label: 'Document Acknowledgement',
        value: 2,
    },
];

const consentSlice = createSlice({
    name: 'consentSlice',
    initialState: {
        activeStep: 0,

        consentSuccessful: false,
        digitalSignatureForm: new DigitalSignatureForm(),
        isSaving: false,
        openSignConsentOne: false,
        openSignConsentTwo: false,
    },
    reducers: {
        _setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        _setConsentSuccessful(state, action) {
            state.consentSuccessful = action.payload;
        },
        _setDigitalSignatureForm(state, action) {
            state.digitalSignatureForm = action.payload;
        },
        _setIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _setOpenSignConsentOne(state, action) {
            state.openSignConsentOne = action.payload;
        },
        _setOpenSignConsentTwo(state, action) {
            state.openSignConsentTwo = action.payload;
        },
    },
});

const {
    _setActiveStep,

    _setConsentSuccessful,
    _setDigitalSignatureForm,
    _setIsSaving,
    _setOpenSignConsentOne,
    _setOpenSignConsentTwo,
} = consentSlice.actions;

export const setActiveStep =
    (activeStep: number): AppThunk =>
    async (dispatch) => {
        dispatch(_setActiveStep(activeStep));
    };

export const setConsentSuccessful =
    (successful: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setConsentSuccessful(successful));
    };

export const setDigitalSignatureForm =
    (digitalSignatureForm: DigitalSignatureForm): AppThunk =>
    async (dispatch) => {
        dispatch(_setDigitalSignatureForm(digitalSignatureForm));
    };

export const setIsSaving =
    (isSaving: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setIsSaving(isSaving));
    };

export const setOpenSignConsentOne =
    (openSignConsent: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenSignConsentOne(openSignConsent));
    };

export const setOpenSignConsentTwo =
    (openSignConsent: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(_setOpenSignConsentTwo(openSignConsent));
    };

const guardianOrCaregiverSignatureIsComplete = (digitalSignatureForm: DigitalSignatureForm) => {
    return (
        digitalSignatureForm.GuardianOrCareGiverType !== '' &&
        digitalSignatureForm.GuardianOrCaregiverName !== '' &&
        digitalSignatureForm.GuardianOrCaregiverAddress !== '' &&
        digitalSignatureForm.GuardianOrCaregiverRelationship !== '' &&
        digitalSignatureForm.GuardianOrCaregiverTelephone !== ''
    );
};

const signaturesCompletedConsent = (digitalSignatureForm: DigitalSignatureForm) => {
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Patient) {
        return digitalSignatureForm.MemberSignatureOne !== '';
    }
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.GuardianOrCaregiver) {
        return (
            guardianOrCaregiverSignatureIsComplete(digitalSignatureForm) &&
            digitalSignatureForm.GuardianOrCaregiverSignatureOne !== '' &&
            digitalSignatureForm.WitnessOne !== ''
        );
    }
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Verbal) {
        return (
            digitalSignatureForm.VerbalConsent &&
            digitalSignatureForm.ReviewedOne &&
            digitalSignatureForm.VerbalConsentWitnessOne !== '' &&
            digitalSignatureForm.VerbalConsentWitnessTwo !== '' &&
            digitalSignatureForm.VerbalConsentSignature !== ''
        );
    }
};

const signaturesCompletedReleaseHealthInfo = (digitalSignatureForm: DigitalSignatureForm) => {
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Patient) {
        return digitalSignatureForm.MemberSignatureTwo !== '';
    }
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.GuardianOrCaregiver) {
        return (
            guardianOrCaregiverSignatureIsComplete(digitalSignatureForm) &&
            digitalSignatureForm.GuardianOrCaregiverSignatureHealthInfo !== '' &&
            digitalSignatureForm.WitnessHealthInfo !== ''
        );
    }
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Verbal) {
        return (
            digitalSignatureForm.VerbalConsent &&
            digitalSignatureForm.ReviewedTwo &&
            digitalSignatureForm.VerbalConsentWitnessOne !== '' &&
            digitalSignatureForm.VerbalConsentWitnessTwo !== '' &&
            digitalSignatureForm.VerbalConsentSignature !== ''
        );
    }
};

const signaturesCompletedDocumentAcknowledgement = (digitalSignatureForm: DigitalSignatureForm) => {
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Patient) {
        return digitalSignatureForm.MemberSignatureThree !== '';
    }
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.GuardianOrCaregiver) {
        return (
            guardianOrCaregiverSignatureIsComplete(digitalSignatureForm) &&
            digitalSignatureForm.GuardianOrCaregiverSignatureDocumentAcknowledge !== '' &&
            digitalSignatureForm.WitnessDocumentAcknowledge !== ''
        );
    }
    if (digitalSignatureForm.SignatureType === ConsentSignatureTypeEnum.Verbal) {
        return (
            digitalSignatureForm.VerbalConsent &&
            digitalSignatureForm.ReviewedThree &&
            digitalSignatureForm.VerbalConsentWitnessOne !== '' &&
            digitalSignatureForm.VerbalConsentWitnessTwo !== '' &&
            digitalSignatureForm.VerbalConsentSignature !== ''
        );
    }
};

const checkValidationConsentPartOne = (digitalSignatureForm: DigitalSignatureForm) => {
    let invalidSection = '';
    if (digitalSignatureForm.VerbalConsent) {
        if (digitalSignatureForm.AppointmentConsent === '') {
            invalidSection = 'appointment-consent';
        }
        if (!signaturesCompletedConsent(digitalSignatureForm)) {
            invalidSection = 'consent-signature';
        }
    } else {
        if (digitalSignatureForm.SectionOneInitials === '') {
            invalidSection = 'section-one';
        } else if (digitalSignatureForm.SectionTwoInitials === '') {
            invalidSection = 'section-two';
        } else if (digitalSignatureForm.SectionThreeInitials === '') {
            invalidSection = 'section-three';
        } else if (digitalSignatureForm.SectionFourInitials === '') {
            invalidSection = 'section-four';
        } else if (digitalSignatureForm.AppointmentConsent === '') {
            invalidSection = 'appointment-consent';
        } else if (digitalSignatureForm.SectionFiveInitials === '') {
            invalidSection = 'section-five';
        } else if (!signaturesCompletedConsent(digitalSignatureForm)) {
            invalidSection = 'consent-signature';
        } else if (digitalSignatureForm.MemberName === '') {
            invalidSection = 'member-name';
        } else {
            invalidSection = '';
        }
    }

    return invalidSection;
};

const checkValidationReleaseHealthInfo = (digitalSignatureForm: DigitalSignatureForm) => {
    let invalidSection = '';
    if (digitalSignatureForm.VerbalConsent) {
        if (!signaturesCompletedReleaseHealthInfo(digitalSignatureForm)) {
            invalidSection = 'consent-review'; // This should be 'consent-signature'
        }
    } else {
        if (!signaturesCompletedReleaseHealthInfo(digitalSignatureForm)) {
            invalidSection = 'health-info-signature';
        } else if (digitalSignatureForm.MemberName === '') {
            invalidSection = 'member-name';
        } else {
            invalidSection = '';
        }
    }
    return invalidSection;
};

const checkValidationDocumentAcknowledgement = (digitalSignatureForm: DigitalSignatureForm) => {
    let invalidSection = '';
    if (digitalSignatureForm.VerbalConsent) {
        if (!signaturesCompletedDocumentAcknowledgement(digitalSignatureForm)) {
            invalidSection = 'consent-signature';
        }
    } else {
        if (digitalSignatureForm.MemberRights === '') {
            invalidSection = 'member-rights';
        } else if (digitalSignatureForm.PrivacyPractices === '') {
            invalidSection = 'privacy-practices';
        } else if (digitalSignatureForm.CCGProcedure === '') {
            invalidSection = 'ccg-procedure';
        } else if (digitalSignatureForm.ProtectedInfo === '') {
            invalidSection = 'protected-info';
        } else if (digitalSignatureForm.ConsentTreatment === '') {
            invalidSection = 'consent-treatment';
        } else if (digitalSignatureForm.MemberFees === '') {
            invalidSection = 'member-fees';
        } else if (!signaturesCompletedDocumentAcknowledgement(digitalSignatureForm)) {
            invalidSection = 'document-acknowledge-signature';
        } else if (digitalSignatureForm.MemberName === '') {
            invalidSection = 'member-name';
        } else {
            invalidSection = '';
        }
    }
    return invalidSection;
};

export const checkValidation =
    (activeStep: number, digitalSignatureForm: DigitalSignatureForm, isSpanish: boolean): AppThunk =>
    (dispatch, getState) => {
        let invalidSection = '';
        if (activeStep === 0) {
            invalidSection = checkValidationConsentPartOne(digitalSignatureForm);
        }
        if (activeStep === 1) {
            invalidSection = checkValidationReleaseHealthInfo(digitalSignatureForm);
        }
        if (activeStep === 2) {
            invalidSection = checkValidationDocumentAcknowledgement(digitalSignatureForm);
        }
        if (invalidSection.length > 0) {
            const element = document.getElementById(invalidSection);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
        if (invalidSection === '' && activeStep <= 1) {
            dispatch(setActiveStep(activeStep + 1));
        }
        if (invalidSection === '' && activeStep === 2) {
            //   if (isSpanish) {
            //     dispatch(signConsentPartOnePDFSpanish(digitalSignatureForm));
            //   } else {
            //     dispatch(signConsentPartOnePDF(digitalSignatureForm));
            //   }
            dispatch(signConsentPartOnePDF(digitalSignatureForm));
        }
    };

export const validateConsentTwo =
    (digitalSignatureForm: DigitalSignatureForm, isSpanish: boolean): AppThunk =>
    (dispatch, getState) => {
        let invalidSection = '';
        if (
            digitalSignatureForm.MemberSignatureOne.length === 0 &&
            digitalSignatureForm.GuardianOrCaregiverSignatureOne.length === 0 &&
            digitalSignatureForm.MemberSignatureThree.length === 0 &&
            digitalSignatureForm.VerbalConsentSignature.length === 0
        ) {
            invalidSection = 'consent-two-signature';
        } else if (digitalSignatureForm.MemberName === '') {
            invalidSection = 'member-name';
        } else {
            invalidSection = '';
        }
        if (invalidSection) {
            const element = document.getElementById(invalidSection);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            //   if (isSpanish) {
            //     dispatch(signConsentTwoPDFSpanish(digitalSignatureForm));
            //   } else {
            //     dispatch(signConsentTwoPDF(digitalSignatureForm));
            //   }
            dispatch(signConsentTwoPDF(digitalSignatureForm));
        }
    };

export const signConsentPartOnePDF =
    (digitalSignatureForm: DigitalSignatureForm): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setIsSaving(true));
            const { memberBasicInfo } = getState().memberDetail;
            const formCopy: DigitalSignatureForm = { ...digitalSignatureForm };
            formCopy.MemberId = memberBasicInfo.Id;

            await axios.post(`${API_URL}/document/signconsentonepdf`, formCopy);
            dispatch(setConsentSuccessful(true));
            dispatch(setShowMessage(true, 'Consent form signed successfully', 'success'));
            dispatch(setIsSaving(false));
        } catch (e) {
            dispatch(setConsentSuccessful(false));
            console.log('error', e);
            dispatch(setIsSaving(false));
        }
    };

export const signConsentTwoPDF =
    (digitalSignatureForm: DigitalSignatureForm): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setIsSaving(true));
            const { memberBasicInfo } = getState().memberDetail;
            const formCopy: DigitalSignatureForm = { ...digitalSignatureForm };
            formCopy.MemberId = memberBasicInfo.Id;

            await axios.post(`${API_URL}/document/signconsenttwopdf`, formCopy);
            dispatch(setConsentSuccessful(true));
            dispatch(setShowMessage(true, 'Consent form signed successfully', 'success'));
            dispatch(setIsSaving(false));
        } catch (e) {
            dispatch(setConsentSuccessful(false));
            console.log('error', e);
        }
        dispatch(setIsSaving(false));
    };

export default consentSlice.reducer;
