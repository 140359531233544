import moment from 'moment';
import { DictionaryPreview } from '../Models/Shared/DictionaryPreview.model';
import { roleEnum } from './assessments';
import Authorization from './Authorization';
import { dateFormatter } from './timeFormat';

export const objectToFormData = (data: any, formData: FormData, parentKey: string = '') => {
    for (let dataKey in data) {
        if (Boolean(data[dataKey]) || data[dataKey] === false) {
            if (typeof data[dataKey] === 'object') {
                objectToFormData(data[dataKey], formData, parentKey ? `${parentKey}[${dataKey}]` : dataKey);
            } else if (parentKey) {
                formData.append(`${parentKey}[${dataKey}]`, data[dataKey]);
            } else {
                formData.append(dataKey, data[dataKey]);
            }
        }
    }
};

export const removeEmptyDate = (data: any, parentKey: string = '') => {
    const emtyDate = '0001-01-01T00:00:00Z';
    for (let dataKey in data) {
        if (typeof data[dataKey] === 'object') {
            removeEmptyDate(data[dataKey], parentKey ? `${parentKey}[${dataKey}]` : dataKey);
        } else if (data[dataKey] === emtyDate) {
            data[dataKey] = '';
        }
    }

    return data;
};

export const copyObject = (data: any) => {
    return JSON.parse(JSON.stringify(data));
};

export const convertObjectToArray = (data: any) => {
    if (!Boolean(data)) {
        return [];
    }

    let list = [];
    for (var t in data) {
        if (data.hasOwnProperty(t)) {
            var type = { Id: parseInt(t), Name: data[t] };
            list.push(type);
        }
    }

    return list;
};

export const stringAvatar = (name: string) => {
    if (!Boolean(name)) {
        return '';
    }

    const splitedName = name.split(' ');
    return splitedName.length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : splitedName[0][0];
};

export const preventTextInput = (event: any) => {
    if (event.key !== '.' && !new RegExp(/^\d+$/).test(event.key)) {
        event.preventDefault();
    }
};

export const preventNumberInputMaxValue = (event: any, maxValue: any) => {
    const currretValue = event.target.value + event.key;
    if (Boolean(currretValue) && parseInt(currretValue) > maxValue) {
        event.preventDefault();
    }
};

export const toTitleCase = (input: string) => {
    if (!Boolean(input)) return input;
    return input
        .trim()
        .toLowerCase()
        .split(' ')
        .filter(Boolean) // removes empty strings
        .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
};

export const prepareTextFieldValue = (value: any) => {
    return Boolean(value) || value === 0 ? `${value}` : '';
};

export const getCPTUserRole = (roleName: string = '') => {
    const authData = new Authorization();
    var role = roleName ? roleName : authData.Role;
    if (authData.IsLicensedProvider && (authData.Role === roleEnum.NURSE_PRACTITIONER || authData.Role === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST)) {
        role = authData.Role;
    } else if (
        role !== roleEnum.NURSE_PRACTITIONER &&
        role !== roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST &&
        role !== roleEnum.CLINICAL_LIAISON &&
        role !== roleEnum.LPC &&
        role !== roleEnum.CARE_COORDINATOR
    ) {
        role = roleEnum.CARE_COORDINATOR;
    }
    role = role.replace(/\s+/g, '');
    return role;
};

export const dataStableSort = (array: any[], comparator: (a: any, b: any) => number) => {
    if (!Boolean(array)) {
        return [];
    }

    const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export type Order = 'asc' | 'desc';
function descendingComparator<T>(a: T, b: T, orderBy: keyof T, filterNotes = false) {
    if (orderBy.toString().includes('.')) {
        const splitPropName = orderBy.toString().split('.');
        let aValue = a[splitPropName[0]];
        let bValue = b[splitPropName[0]];
        aValue = Boolean(aValue) && typeof aValue === 'object' ? aValue[splitPropName[1]] : 0;
        bValue = Boolean(bValue) && typeof bValue === 'object' ? bValue[splitPropName[1]] : 0;

        if (bValue < aValue) {
            return -1;
        }
        if (bValue > aValue) {
            return 1;
        }
    } else {
        let aValue: any;
        let bValue: any;
        if (orderBy === 'Notes' && filterNotes) {
            aValue = (a || '').toString().replace('<p>', '');
            bValue = (b || '').toString().replace('<p>', '');
        } else {
            aValue = a;
            bValue = b;
        }

        if (bValue[orderBy] < aValue[orderBy]) {
            return -1;
        }
        if (bValue[orderBy] > aValue[orderBy]) {
            return 1;
        }
    }

    return 0;
}

export const getDataComparator = (
    order: Order,
    orderBy: string,
    filterNotes = false
): ((a: { [key in string]: number | string }, b: { [key in string]: number | string }) => number) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy, filterNotes) : (a, b) => -descendingComparator(a, b, orderBy, filterNotes);
};

export const convertDictionaryToList = (data: []): DictionaryPreview[] => {
    return data.map((item) => {
        const map = new Map(Object.entries(item));
        return { Id: map.get('0'), Name: map.get('1') } as DictionaryPreview;
    });
};

export const isDefaultDate = (date: Date) => {
    let dateInfo = dateFormatter(new Date(date));
    let dateList = [
        '01/01/1901',
        '01/01/2001',
        '01/01/0001',
        '01/01/1970',
        '01/01/1',
        '12/31/0',
        '01-01-1901',
        '01-01-2001',
        '01-01-0001',
        '01-01-1970',
        '01-01-1',
        '12-31-0',
        '1901/01/01',
        '2001/01/01',
        '0001/01/01',
        '1970/01/01',
        '1/01/01',
        '0/12/31',
        '1901-01-01',
        '2001-01-01',
        '0001-01-01',
        '1970-01-01',
        '1-01-01',
        '0-12-31',
    ];
    if (!Boolean(date) || dateList.some((d) => d === dateInfo)) {
        return true;
    } else {
        return false;
    }
};

export const getTimeSpan = (startDate: Date, endDate: Date) => {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const duration = moment.duration(endMoment.diff(startMoment));
    return duration;
};

export const getHumanizedTimeSpan = (startDate: Date, endDate: Date): string => {
    return getTimeSpan(startDate, endDate).humanize();
};

export const generateRandomId = (length = 10) => {
    let result = '';

    for (let i = 0; i < length; i++) {
        result += Math.floor(Math.random() * 10);
    }

    return result;
};

export const isValidFirstName = (name: string) => {
    // Regular expression to match one word or a hyphenated word with no spaces
    const regex = /^[A-Za-z]+(-[A-Za-z]+)?$/;
    return regex.test(name);
};

export const isValidName = (name: string) => {
    // Regular expression to match letters and hyphens with spaces, but no leading or trailing spaces
    const regex = /^(?! )[A-Za-z -]+(?<! )$/;
    return regex.test(name);
};

export const toPascalCase = (str: string) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
