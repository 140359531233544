import BaseEntity from '../BaseEntity.model';
import CreatedBy from './../Shared/LastUpdatedBy.model';
import KeyContact from './KeyContact.model';
import TransitionalCareActivity from './TransitionalCareActivity.model';
import TransitionalCareCheckList from './TransitionalCareCheckList.model';
import { TransitionalCareNotificationEnum } from './../../Enum/TransitionalCareNotificationEnum';
import { OutsideFacilityLocationEnum } from './../../Enum/OutsideFacilityLocationEnum';

export default interface IPatientDischarge extends BaseEntity {
    AdmissionDate: Date | null;
    NotificationMethod: TransitionalCareNotificationEnum | number;
    OtherNotification: string;
    OutsideFacilityLocation: OutsideFacilityLocationEnum;
    Address: string;
    ZipCode: string;
    Description: string;
    KeyContacts: KeyContact[] | null;
    DischargeSummary: boolean;
    TransitionalCareCheckList: TransitionalCareCheckList | null;
    TransitionalCareActivities: TransitionalCareActivity[] | null;
    DateToSeeNP: Date | null;
    DateToSeeBH: Date | null;
    NotApplicable: boolean;
    ReasonForNotToSeeNPOrBH: string;
    CreatedDate: Date | null;
    CreatedBy: CreatedBy | null;
    UpdatedDate: Date | null;
    UpdatedBy: CreatedBy | null;
}

export class PatientDischarge implements IPatientDischarge {
    Id: string;
    AdmissionDate: Date | null;
    NotificationMethod: TransitionalCareNotificationEnum | number;
    OtherNotification: string;
    OutsideFacilityLocation: OutsideFacilityLocationEnum | number;
    Address: string;
    ZipCode: string;
    Description: string;
    KeyContacts: KeyContact[] | null;
    DischargeSummary: boolean;
    TransitionalCareCheckList: TransitionalCareCheckList | null;
    TransitionalCareActivities: TransitionalCareActivity[] | null;
    DateToSeeNP: Date | null;
    DateToSeeBH: Date | null;
    NotApplicable: boolean;
    ReasonForNotToSeeNPOrBH: string;
    CreatedDate: Date | null;
    CreatedBy: CreatedBy | null;
    UpdatedDate: Date | null;
    UpdatedBy: CreatedBy | null;

    constructor() {
        this.Id = null;
        this.AdmissionDate = null;
        this.NotificationMethod = 0;
        this.OtherNotification = '';
        this.OutsideFacilityLocation = 0;
        this.Address = '';
        this.ZipCode = '';
        this.Description = '';
        this.KeyContacts = null;
        this.TransitionalCareCheckList = null;
        this.TransitionalCareActivities = null;
        this.DateToSeeNP = null;
        this.DateToSeeBH = null;
        this.NotApplicable = false;
        this.ReasonForNotToSeeNPOrBH = '';
        this.CreatedDate = null;
        this.CreatedBy = null;
        this.UpdatedBy = null;
        this.UpdatedDate = null;
    }
}
