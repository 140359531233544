import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Typography } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import { RootState } from '../../../reducers';
import { setMemberBudget } from '../../../store/memberBudget.slice';
import classes from '../Styles';
import { copyObject } from '../../../utils/common';
import CurrencyInput from '../../Shared/CurrencyInput';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { HouseHoldExpense } from '../../../Models/Budget/HouseHoldExpense.model';

const HouseHoldExpenses = () => {
    const dispatch = useDispatch();
    const [showDeleteExpenseAlert, setShowDeleteExpenseAlert] = useState<boolean>(false);
    const [selectedExpenseIndex, setSelectedExpenseIndex] = useState(null);
    const { memberBudget } = useSelector((state: RootState) => state.memberBudget);

    const handleOnAddNewExpense = () => {
        let memberBudgetData = copyObject(memberBudget);
        if (!Boolean(memberBudgetData.HouseHoldExpenses?.length)) {
            memberBudgetData.HouseHoldExpenses = [];
        }
        memberBudgetData.HouseHoldExpenses.push({ ...new HouseHoldExpense(), Name: `Expense ${memberBudgetData.HouseHoldExpenses.length + 1}` });
        dispatch(setMemberBudget(memberBudgetData));
    };

    const handleOnChangeExpense = (val: any, propName: string, index: number) => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.HouseHoldExpenses[index][propName] = val;
        dispatch(setMemberBudget(memberBudgetData));
    };

    const handleOnConfirmDelete = () => {
        let memberBudgetData = copyObject(memberBudget);
        memberBudgetData.HouseHoldExpenses = memberBudgetData.HouseHoldExpenses.filter((_, index) => index !== selectedExpenseIndex);
        dispatch(setMemberBudget(memberBudgetData));
        setShowDeleteExpenseAlert(false);
    };

    return (
        <>
            <TableContainer component={Paper} sx={classes.budgetFormTable}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1" marginBottom={0} component="label">
                                    Household Expenses
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover>
                            <TableCell scope="row">
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow hover>
                                                        <TableCell scope="row">
                                                            <Button
                                                                variant="text"
                                                                endIcon={<AddCircleOutlinedIcon fontSize="large" />}
                                                                className="text-transform-none"
                                                                onClick={handleOnAddNewExpense}
                                                            >
                                                                Add Expense
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    {memberBudget.HouseHoldExpenses?.map((expense, index) => (
                                                        <TableRow key={`house-hold-expense-${index}`} hover>
                                                            <TableCell scope="row">
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={7}>
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            value={expense.Name}
                                                                            variant="outlined"
                                                                            name="Name"
                                                                            className="hide-textinput-label"
                                                                            onChange={(e) => handleOnChangeExpense(e.target.value, e.target.name, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CurrencyInput
                                                                            size="small"
                                                                            fullWidth
                                                                            value={expense.Amount}
                                                                            name="Amount"
                                                                            onChange={(e) => handleOnChangeExpense(e.target.value, e.target.name, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                setSelectedExpenseIndex(index);
                                                                                setShowDeleteExpenseAlert(true);
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <AlertDialog
                open={showDeleteExpenseAlert}
                showTop
                title="Delete Expense"
                okText="Delete"
                onConfirm={handleOnConfirmDelete}
                onClose={() => setShowDeleteExpenseAlert(false)}
                message="Are you sure want to delete this expense?"
            />
        </>
    );
};

export default HouseHoldExpenses;
