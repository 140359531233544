import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControlLabel,
    Radio,
    Chip,
    Stack,
    FormControl,
    FormLabel,
    TextField,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';

import {
    getAssessmentByType,
    gotoNextMenu,
    gotoPreviousMenu,
    saveAssessmentWorkflow,
    setMemberAssessment,
    updateMemberAssessmentModel,
} from '../../../store/assessment.slice';
import { setEngagementDraft } from '../../../store/documentEngagement.slice';
import { RootState } from '../../../reducers';
import { AssessmentTypeEnum } from '../../../Enum/AssessmentTypeEnum';
import { AssessmentCategory } from '../../../Models/Assessment/AssessmentCategory.model';
import classes from './Styles';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { copyObject } from '../../../utils/common';
import Authorization from '../../../utils/Authorization';

const PatientHealthQuestionnaire: React.FC<{ memberId: string }> = ({ memberId }) => {
    const dispatch = useDispatch();
    const authData = new Authorization();
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const { memberAssessment, isFetching, isSaving } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const type = AssessmentTypeEnum.PatientHealthQuestionnaire;
    const category = memberAssessment?.Assessment?.Categories[0];
    const firstQuestions = category?.Questions?.slice(0, category?.Questions.length - 1);
    const options = category?.Questions[1]?.Options || [];
    const lastQuestion = category?.Questions[category?.Questions.length - 1];
    const lastQuestionNumber = category?.Questions.length;
    const title = 'PHQ9' as const;
    const [showScoreAlert, setShowScoreAlert] = useState<boolean>(false);
    const [showNotAddressedPopup, setShowNotAddressedPopup] = useState<boolean>(false);
    const [elevatedPHQ9Notes, setElevatedPHQ9Notes] = useState<string>(engagementDraft.ElevatedPHQ9Notes || '');
    const [totalPhqScore, setTotalPhqScore] = useState<any>(0);

    useEffect(() => {
        dispatch(getAssessmentByType(type, memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isFetching && !isPageLoaded) {
            setIsPageLoaded(true);
        }
    }, [isFetching, isPageLoaded]);

    const handleOnChangeCategory = (updatedCategory: AssessmentCategory) => {
        setIsChanged(true);
        dispatch(updateMemberAssessmentModel(updatedCategory));
    };

    const handleOnClickSave = () => {
        let total = 0;
        firstQuestions.forEach((question) => {
            total += question?.Options?.find((o) => o.IsSelected)?.Score || 0;
        });
        setTotalPhqScore(total);

        if (Boolean(total > 13) && authData.Permissions.includes('PHQ9 Score Greater Than 14 Alert')) {
            setShowScoreAlert(true);
        } else {
            setShowScoreAlert(false);
            setShowSaveAlert(true);
        }
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            let data = copyObject(memberAssessment);
            data.PHQ9Score = totalPhqScore;
            dispatch(setMemberAssessment(data));
            dispatch(saveAssessmentWorkflow(data.Assessment?.Categories[0], isWithoutComplete, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnScoreAlert = (isAddressed) => {
        let data = copyObject(engagementDraft);
        data.IsElevatedPHQ9Addressed = isAddressed;
        dispatch(setEngagementDraft(data));
        setShowScoreAlert(false);

        if (isAddressed) setShowSaveAlert(true);
        else setShowNotAddressedPopup(true);
    };

    const handleOnSubmitPhq9NotAddressed = () => {
        let data = copyObject(engagementDraft);
        data.ElevatedPHQ9Notes = elevatedPHQ9Notes;
        dispatch(setEngagementDraft(data));
        setShowNotAddressedPopup(false);
        setShowSaveAlert(true);
    };

    const handleOnUpdateAnswer = (isSelected, questionIndex, optionIndex) => {
        let data = copyObject(category);
        data.Questions[questionIndex].Options.forEach((option, index) => {
            if (index === optionIndex) {
                option.IsSelected = isSelected;
            } else {
                option.IsSelected = false;
            }
        });
        data.Questions[questionIndex].Options[optionIndex].IsSelected = isSelected;
        handleOnChangeCategory(data);
    };

    const getPhqAssessmentStatus = () => {
        let depressionStatus = '';
        let total = 0;
        firstQuestions?.forEach((question) => {
            total += question?.Options?.find((o) => o.IsSelected)?.Score || 0;
        });

        if (total === 0) {
            depressionStatus = '';
        } else if (total > 0 && total <= 4) {
            depressionStatus = 'Minimal depression';
        } else if (total >= 5 && total <= 9) {
            depressionStatus = 'Mild depression';
        } else if (total >= 10 && total <= 14) {
            depressionStatus = 'Moderate depression';
        } else if (total >= 15 && total <= 19) {
            depressionStatus = 'Moderately severe depression';
        } else if (total >= 20 && total <= 27) {
            depressionStatus = 'Severe depression';
        }

        return Boolean(total) ? `${total} - ${depressionStatus}` : '';
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching || !isPageLoaded ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item lg={12} md={12} sm={12} sx={{ mb: 2 }}>
                        <label>
                            <strong>Last Completed : </strong>{' '}
                            {Boolean(memberAssessment.AssessmentDate) ? (
                                <Chip size="small" label={moment(memberAssessment.AssessmentDate).format('MM/DD/yyyy')} />
                            ) : (
                                '-'
                            )}
                        </label>
                    </Grid>
                    <Grid item className="content-form">
                        <TableContainer component={Paper} sx={classes.customTable}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" width="40%"></TableCell>
                                        {options.map((option, index) => (
                                            <TableCell component="td" width="15%" key={`header_${option.Name}_${index}`}>
                                                {option.Name}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {firstQuestions?.map((question, qIndex) => (
                                        <TableRow key={`${question.Id}_${qIndex}`}>
                                            <TableCell component="th" scope="row">
                                                <div className="d-flex-row">
                                                    <Box sx={classes.numberLabel}>{qIndex + 1}.</Box>
                                                    <span>{question.Description}</span>
                                                </div>
                                            </TableCell>
                                            {question.Options?.map((option, oIndex) => (
                                                <TableCell key={`body_${option.Name}_${oIndex}`}>
                                                    <FormControlLabel
                                                        value={String(option.IsSelected)}
                                                        sx={classes.smLabelRadioBtn}
                                                        control={
                                                            <Radio
                                                                checked={option.IsSelected}
                                                                size="small"
                                                                radioGroup={question.Description}
                                                                onChange={(e) => handleOnUpdateAnswer(e.currentTarget.checked, qIndex, oIndex)}
                                                            />
                                                        }
                                                        label={String(option.Score)}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell component="th" scope="row" className="no-border"></TableCell>
                                        <TableCell className="no-border" colSpan={4} align="center">
                                            <Box sx={classes.totalText}>Total &nbsp;&nbsp; &nbsp; {getPhqAssessmentStatus()}</Box>
                                        </TableCell>
                                    </TableRow>
                                    {lastQuestion ? (
                                        <TableRow>
                                            <TableCell component="th" scope="row" className="no-border">
                                                <div className="d-flex-row">
                                                    <Box sx={classes.numberLabel}>{lastQuestionNumber}.</Box>
                                                    <span>{lastQuestion.Description}</span>
                                                </div>
                                            </TableCell>
                                            {lastQuestion.Options?.map((option, oIndex) => (
                                                <TableCell key={`body_${option.Name}_${oIndex}`} className="no-border">
                                                    <FormControlLabel
                                                        value={String(option.IsSelected)}
                                                        sx={classes.smLabelRadioBtn}
                                                        control={
                                                            <Radio
                                                                checked={option.IsSelected}
                                                                size="small"
                                                                radioGroup={lastQuestion.Description}
                                                                onChange={(e) => handleOnUpdateAnswer(e.currentTarget.checked, lastQuestionNumber - 1, oIndex)}
                                                            />
                                                        }
                                                        label={String(option.Name)}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 3 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Grid>
                </>
            )}
            <AlertDialog
                open={showScoreAlert}
                title="Elevated PHQ9 assessment"
                cancelText="No"
                okText="Yes"
                onConfirm={() => handleOnScoreAlert(true)}
                onClose={() => handleOnScoreAlert(false)}
                message={`The PHQ9 result has been 14 or higher. Did a licensed provider address and document this issue?`}
            />
            <AlertDialog
                open={showNotAddressedPopup}
                title="Elevated PHQ9 assessment"
                cancelText="Go Back"
                okText="Submit"
                isLoading={isSaving}
                onConfirm={() => handleOnSubmitPhq9NotAddressed()}
                onClose={() => {
                    setElevatedPHQ9Notes('');
                    setShowNotAddressedPopup(false);
                }}
                disabledSubmit={!Boolean(elevatedPHQ9Notes)}
                renderBody={() => (
                    <Stack>
                        <FormControl fullWidth={true}>
                            <FormLabel required>Why it was not addressed?</FormLabel>
                            <TextField
                                variant="outlined"
                                size="small"
                                name="phq9Notes"
                                className="hide-textinput-label"
                                value={elevatedPHQ9Notes}
                                multiline
                                rows={5}
                                onChange={(e) => setElevatedPHQ9Notes(e.target.value)}
                            />
                        </FormControl>
                    </Stack>
                )}
            />
            {Boolean(engagementDraft.PhqAssessmentId) || isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title={`${title} Assessment`}
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message={`I confirm and acknowledge that ${title} assessment is completed.`}
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    buttonsDirection="column-reverse"
                    title={`${title} Assessment`}
                    cancelText="Go Back"
                    skipText={`${title} Assessment Not Reviewed`}
                    okText={`Agree With ${title} Assessment`}
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message={`Last ${title} assessment completed on ${moment(memberAssessment.AssessmentDate).format('MM/DD/yyyy')}.`}
                />
            )}
        </Grid>
    );
};

export default PatientHealthQuestionnaire;
