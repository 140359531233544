import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { deleteMemberNote, insertOrUpdateMemberNote, setMemberNote, setShowNotes } from '../../../store/memberDemographicsDetails.slice';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import WarningIcon from '@mui/icons-material/Warning';
import Authorization from '../../../utils/Authorization';
import { useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { MemberNote } from '../../../Models/Member/MemberNote.model';

const ConfirmDeleteNoteDialog = (props) => {
    const { showConfirmDelete, memberNote, closeConfirmDelete, confirmDeleteNoteHandler } = props;
    return (
        <Dialog open={showConfirmDelete} onClose={closeConfirmDelete} fullWidth maxWidth="sm">
            <DialogTitle color={'error'}>Are you sure you wish to delete this note?</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {memberNote?.Note} - {memberNote?.Provider?.Name}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className="button-120" variant="outlined" color="primary" onClick={closeConfirmDelete}>
                    Cancel
                </Button>
                <Button className="button-120" variant="contained" color="error" onClick={confirmDeleteNoteHandler}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AddNoteDialog = (props) => {
    const { closeAddNoteHandler, memberNote, onChangeHandler, showAddNote, submitNoteHandler } = props;
    const dispatch = useDispatch();
    return (
        <Dialog open={showAddNote} onClose={closeAddNoteHandler} fullWidth maxWidth="sm">
            <DialogTitle>{memberNote.Id?.length > 0 ? 'Edit' : 'Add'} Note</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControl fullWidth={true}>
                        <TextField
                            fullWidth
                            multiline
                            label="Note"
                            sx={{ marginY: 2 }}
                            variant="outlined"
                            rows={6}
                            value={memberNote?.Note || ''}
                            name="Note"
                            onChange={onChangeHandler}
                        />
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={memberNote?.WarningFlag}
                                onChange={(e) => dispatch(setMemberNote({ ...memberNote, WarningFlag: e.target.checked }))}
                            />
                        }
                        label="Add Warning Flag"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button className="button-120" variant="outlined" color="primary" onClick={closeAddNoteHandler}>
                    Cancel
                </Button>
                <Button className="button-120" variant="contained" color="primary" onClick={submitNoteHandler}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const MemberNoteDialog = (props) => {
    const { memberId } = props;
    const { allMemberNotes, memberNote, showNotes } = useSelector((state: RootState) => state.memberDemographicsDetails);

    const dispatch = useDispatch();
    const auth = new Authorization();
    const [showAddNote, setShowAddNote] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const cancelNoteHandler = () => {
        dispatch(setShowNotes(false));
    };

    const confirmDeleteNoteHandler = () => {
        dispatch(deleteMemberNote(memberId, memberNote.Id));
        closeConfirmDelete();
    };

    const openEditNoteHandler = () => {
        setShowAddNote(true);
    };

    const openAddNoteHandler = () => {
        setShowAddNote(true);
        dispatch(setMemberNote(new MemberNote()));
    };

    const closeAddNoteHandler = () => {
        setShowAddNote(false);
    };

    const submitNoteHandler = () => {
        let note = { ...memberNote };
        dispatch(insertOrUpdateMemberNote(memberId, auth.UserId, note));
        closeAddNoteHandler();
    };

    const onChangeHandler = (e) => {
        dispatch(setMemberNote({ ...memberNote, Note: e.target.value }));
    };

    const closeConfirmDelete = () => {
        setShowConfirmDelete(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'Note',
            headerName: 'Note',
            minWidth: 300,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack textAlign={'start'} direction={'row'} alignItems={'center'} spacing={2}>
                        {params.row.WarningFlag ? <WarningIcon /> : null}
                        <Typography variant="body2">{params.row.Note}</Typography>
                    </Stack>
                );
            },
        },
        {
            field: 'EditedTime',
            headerName: 'Created By',
            minWidth: 240,
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Stack textAlign={'start'}>
                        <Typography variant="body2">{params.row.Provider?.Name || ''}</Typography>
                        <Typography variant="body2" fontStyle={'italic'}>
                            {moment(params.row.EditedTime).format('MM/DD/yyyy')}
                        </Typography>
                    </Stack>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 100,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Edit
                            className="icon-left"
                            color="primary"
                            onClick={() => {
                                dispatch(setMemberNote(params.row));
                                openEditNoteHandler();
                            }}
                        />
                        <Delete
                            className="icon-right"
                            color="error"
                            onClick={() => {
                                setShowConfirmDelete(true);
                                dispatch(setMemberNote(params.row));
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <Dialog open={showNotes} onClose={cancelNoteHandler} fullWidth maxWidth="lg">
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ p: 3 }}>
                <Typography variant="h6">Member Notes</Typography>
                <Button variant="contained" sx={{ marginRight: 0, marginLeft: 'auto', minWidth: '120px' }} color="primary" onClick={openAddNoteHandler}>
                    Add Note
                </Button>
            </Stack>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                {allMemberNotes?.length > 0 ? (
                    <DataGrid
                        rows={allMemberNotes || []}
                        columns={columns}
                        autoHeight
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.Id}
                        getRowHeight={() => 'auto'}
                        getRowClassName={(params) => (params.row.WarningFlag ? 'Warning' : '')}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                            [`& .Warning`]: {
                                color: '#d32f2f',
                            },
                        }}
                    />
                ) : (
                    <Typography variant="body1" marginX="auto">
                        No notes found.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ marginTop: 2, marginRight: 2 }}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <Button className="button-120" variant="outlined" color="primary" onClick={cancelNoteHandler}>
                        Close
                    </Button>
                </Stack>
            </DialogActions>
            <AddNoteDialog
                closeAddNoteHandler={closeAddNoteHandler}
                memberNote={memberNote}
                onChangeHandler={onChangeHandler}
                showAddNote={showAddNote}
                setShowAddNote={setShowAddNote}
                submitNoteHandler={submitNoteHandler}
            />
            <ConfirmDeleteNoteDialog
                showConfirmDelete={showConfirmDelete}
                memberNote={memberNote}
                closeConfirmDelete={closeConfirmDelete}
                confirmDeleteNoteHandler={confirmDeleteNoteHandler}
            />
        </Dialog>
    );
};

export default MemberNoteDialog;
