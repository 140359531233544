import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

const appointmentSlice = createSlice({
    name: 'appointment',
    initialState: {
        scheduleNote: {
            MemberId: '',
            CreatedById: '',
            CreatedByName: '',
            CreatedDate: new Date(),
            Note: '',
            Id: null,
        },
        upcomingAppointments: [],
        isFetching: false,
    },
    reducers: {
        _setScheduleNote(state, action) {
            state.scheduleNote = action.payload;
        },
        _setUpcomingAppointments(state, action) {
            state.upcomingAppointments = action.payload;
        },
        _setIsFetching(state, action) {
            state.isFetching = action.payload;
        },
    },
});

const { _setScheduleNote, _setUpcomingAppointments, _setIsFetching } = appointmentSlice.actions;

export const changeRecurringToSingle =
    (appointmentId: string, startDate: Date, endDate: Date): AppThunk =>
    async (dispatch, getState) => {
        const sDate = startDate.toLocaleDateString().replaceAll('/', '-') + ' ' + startDate.toLocaleTimeString();
        const eDate = endDate.toLocaleDateString().replaceAll('/', '-') + ' ' + endDate.toLocaleTimeString();
        await axios.get(`${API_URL}/scheduling/changerecurring/${appointmentId}/${sDate}/${eDate}`);
        window.location.reload();
    };

export const fetchScheduleNotes =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await axios.get(`${API_URL}/MemberScheduleNotes/${memberId}`);
        console.log(response.data);
        dispatch(_setScheduleNote(response.data));
    };

export const saveScheduleNotes =
    (id: string | null, memberId: string, note: string): AppThunk =>
    async (dispatch, getState) => {
        await axios.post(`${API_URL}/MemberScheduleNotes/SaveNote`, { id, memberId, note });
        dispatch(fetchScheduleNotes(memberId));
    };

export const fetchUpcomingAppointments =
    (providerId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setIsFetching(true));
        axios
            .get(`${API_URL}/scheduling/getupcomingappointments?providerId=${providerId}`)
            .then((response) => {
                dispatch(_setUpcomingAppointments(response.data));
            })
            .finally(() => {
                dispatch(_setIsFetching(false));
            });
    };

export default appointmentSlice.reducer;
