import { Button, FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { closeEditHandler, insertRole, setRolePermissions, setSelectAllPermissions, updateRole } from '../../store/adminRoles.slice';

const Permissions = (props) => {
    const dispatch = useDispatch();

    const { permissions } = useSelector((state: RootState) => state.shared);
    const { assignedWidgets, openNew, selectedRole, selectAllPermissions, roleName, rolePermissions, assignedFacilities } = useSelector(
        (state: RootState) => state.adminRoles
    );

    const selectAllHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            dispatch(setSelectAllPermissions(true));
            dispatch(setRolePermissions([...permissions]));
        } else {
            dispatch(setSelectAllPermissions(false));
            dispatch(setRolePermissions([]));
        }
    };

    const permissionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = rolePermissions.findIndex((perm) => perm.Name === e.target.value);
        if (index === -1) {
            dispatch(setRolePermissions([...rolePermissions, { Name: e.target.value, Id: e.target.id }]));
        } else {
            dispatch(setRolePermissions(rolePermissions.filter((perm) => perm.Id !== e.target.id)));
        }
    };

    useEffect(() => {
        if (rolePermissions.length === permissions.length) {
            dispatch(setSelectAllPermissions(true));
        } else {
            dispatch(setSelectAllPermissions(false));
        }
    }, [rolePermissions.length, permissions.length, dispatch]);

    return (
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12}>
                    {/* <Typography component={'h5'}>Permissions</Typography> */}
                    <FormControlLabel
                        disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                        label="Select All Permissions"
                        control={<Switch checked={selectAllPermissions} onChange={selectAllHandler} />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormGroup sx={{ padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {permissions.map((el) => {
                            return (
                                <Grid item xs={4} key={el.Id}>
                                    <FormControlLabel
                                        label={el.Name}
                                        control={
                                            <Switch
                                                id={el.Id}
                                                checked={rolePermissions.map((i) => i.Id).includes(el.Id)}
                                                disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                                                value={el.Name}
                                                onChange={permissionHandler}
                                            />
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent={'flex-end'}>
                    <Button
                        className="button-120"
                        sx={{ marginRight: 2 }}
                        disabled={selectedRole.Name.length === 0 && !openNew ? true : false}
                        variant="contained"
                        onClick={
                            openNew
                                ? () =>
                                      dispatch(
                                          insertRole({
                                              Id: '',
                                              Name: roleName,
                                              Permissions: rolePermissions,
                                              Widgets: assignedWidgets,
                                              ProviderFacilities: assignedFacilities,
                                          })
                                      )
                                : () =>
                                      dispatch(
                                          updateRole({
                                              Id: selectedRole.Id,
                                              Name: roleName,
                                              Permissions: rolePermissions,
                                              Widgets: assignedWidgets,
                                              ProviderFacilities: assignedFacilities,
                                          })
                                      )
                        }
                    >
                        Save
                    </Button>
                    <Button className="button-120" variant="contained" onClick={() => dispatch(closeEditHandler())}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Permissions;
