import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    Box,
    CircularProgress,
    FormControl,
    TextField,
    RadioGroup,
    FormLabel,
    Radio,
    TableContainer,
    TableRow,
    Table,
    TableBody,
    TableCell,
    TableHead,
    FormControlLabel,
    IconButton,
    Modal,
    Typography,
    Stack,
    Checkbox,
    Autocomplete,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    deleteMemberAllergy,
    getMemberAllergies,
    gotoNextMenu,
    gotoPreviousMenu,
    saveMemberAllergy,
    toggleShowAlleryModal,
} from '../../../store/assessment.slice';
import { RootState } from '../../../reducers';
import classes from './Styles';
import { MemberAllergy } from '../../../Models/Member/MemberAllergy.model';
import { AllergyTypeEnum } from '../../../Enum/AllergyTypeEnum';
import { AllergyTypes } from '../../../utils/assessments';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { saveEngagementDraft } from '../../../store/documentEngagement.slice';
import { SummaryNoteTypeEnum } from '../../../Enum/SummaryNoteTypeEnum';
import { copyObject } from '../../../utils/common';
import { updateNoKnownAllergies } from '../../../store/memberDetail.slice';
import { apiString as API_URL } from '../../../utils/constants';
import axios from 'axios';

const memberAllergyFormSchema = Yup.object().shape({
    Name: Yup.string().nullable().required('Allergy Name is required.'),
    Reaction: Yup.string().nullable().required('Reaction is required.'),
});

const MemberAllergyComponent: React.FC<{ memberId: string; isAssessment: boolean }> = ({ memberId, isAssessment }) => {
    const dispatch = useDispatch();
    const [selectedAllergy, setSelectedAllergy] = useState<MemberAllergy>(new MemberAllergy());
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const [medRxNorms, setMedRxNorms] = useState([]);
    const [allergyClasses, setAllergyClasses] = useState([]);
    const [showClasses, setShowClasses] = useState<boolean>(false);
    const {
        memberAllergies,
        showAlleryModal,
        deletingAlergyIds,
        isSaving: isSavingAssessment,
        isFetching,
    } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const isSaving = isSavingAssessment || isSavingEngagementDraft;

    useEffect(() => {
        dispatch(getMemberAllergies(memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        axios.get(`${API_URL}/allergies/GetAllergyClasses`).then((response) => {
            setAllergyClasses(response.data);
        });
        setShowClasses(false);
    }, []);

    useEffect(() => {
        axios.get(`${API_URL}/allergies/GetMedicationRxNorms`).then((response) => {
            setMedRxNorms(response.data);
        });
    }, []);

    const handleSubmit = (values: MemberAllergy) => {
        setIsChanged(true);
        dispatch(saveMemberAllergy(values));
    };

    const handleOnCloseModal = () => dispatch(toggleShowAlleryModal(false));

    const handleOnShowModal = (allergy = new MemberAllergy()) => {
        setSelectedAllergy({ ...allergy, MemberId: memberId });
        dispatch(toggleShowAlleryModal(true));
        setShowClasses(allergy.AllergyType === AllergyTypeEnum.Class);
    };

    const handleOnClickDelete = (allergy: MemberAllergy) => {
        setSelectedAllergy(allergy);
        setShowDeleteAlert(true);
    };

    const handleOnConfirmedDelete = () => {
        dispatch(deleteMemberAllergy(selectedAllergy.Id));
        setShowDeleteAlert(false);
    };

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = () => {
        dispatch(gotoNextMenu());
    };

    const handleOnConfirm = () => {
        let data = copyObject(engagementDraft);
        data.SummaryNotes = data.SummaryNotes?.filter((s) => s.Type !== SummaryNoteTypeEnum.Allergy) || [];
        data.SummaryNotes.push({
            Id: '',
            CptCode: '',
            Zcode: '',
            Note: 'Completed Allergy Information',
            Type: SummaryNoteTypeEnum.Allergy,
            IsConfirm: false,
            OrderBy: 32,
            DisplayName: 'Allergy',
        });
        data.AllergyStatus = StatusEnum.Completed;
        dispatch(saveEngagementDraft(data, true));
    };

    const renderAddOrUpdateAllergy = () => {
        return (
            <Modal open={showAlleryModal} onClose={handleOnCloseModal} disableEnforceFocus>
                <Formik initialValues={selectedAllergy} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={memberAllergyFormSchema}>
                    {({ values, setFieldValue, errors, handleChange, handleBlur, validateForm, submitCount }: FormikProps<MemberAllergy>) => (
                        <Form noValidate>
                            <Box sx={{ ...classes.modalPopupForm, ...classes.form }}>
                                <div className="mui-modal-header">
                                    <Typography variant="h6" component="h6">
                                        Allergy & Reaction
                                    </Typography>
                                </div>
                                <div className="mui-modal-body">
                                    <Grid container spacing={2}>
                                        <Grid item md={6}>
                                            <TextField
                                                fullWidth
                                                label="Name"
                                                variant="outlined"
                                                value={values.Name}
                                                name="Name"
                                                onChange={handleChange}
                                                error={Boolean(submitCount) && Boolean(errors?.Name)}
                                                helperText={Boolean(submitCount) ? errors?.Name : ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
                                                <FormControl>
                                                    <FormLabel id="allergy-type-radio-buttons-group-label">Allergy Type</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="allergy-type-radio-buttons-group-label"
                                                        value={values.AllergyType}
                                                        name="AllergyType"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            if (parseInt(e.target.value) === AllergyTypeEnum.Class) {
                                                                setShowClasses(true);
                                                            } else {
                                                                setShowClasses(false);
                                                            }
                                                        }}
                                                    >
                                                        <FormControlLabel value={AllergyTypeEnum.Medication} control={<Radio />} label="Medication" />
                                                        <FormControlLabel value={AllergyTypeEnum.Class} control={<Radio />} label="Class" />
                                                    </RadioGroup>
                                                </FormControl>
                                                {showClasses ? (
                                                    <Autocomplete
                                                        id="allergy-class-select"
                                                        options={allergyClasses.sort((a, b) => a.ClassName.localeCompare(b.ClassName))}
                                                        getOptionLabel={(option) => option.ClassName}
                                                        style={{ width: 250 }}
                                                        renderInput={(params) => <TextField {...params} label="Allergy Class" variant="outlined" />}
                                                        onChange={(e, value) => {
                                                            setFieldValue('AllergyClass', value?.ClassNumber || '');
                                                        }}
                                                        value={allergyClasses.find((a) => a.ClassNumber === values.AllergyClass) || null}
                                                    />
                                                ) : (
                                                    <Autocomplete
                                                        id="rx-norm-code-select"
                                                        options={medRxNorms.sort((a, b) => a.Name.localeCompare(b.Name))}
                                                        getOptionLabel={(option) => `${option.RxNorm} - ${option.Name} ${option.Strength} ${option.UOM}`}
                                                        style={{ width: 250 }}
                                                        renderInput={(params) => <TextField {...params} label="RxNorm Code" variant="outlined" />}
                                                        onChange={(e, value) => {
                                                            setFieldValue('RxNormCode', value?.RxNorm || '');
                                                        }}
                                                        value={medRxNorms.find((a) => a.RxNorm === values.RxNormCode) || null}
                                                        filterOptions={(options, { inputValue }) => {
                                                            if (inputValue.length < 2) {
                                                                return [];
                                                            } else {
                                                                return options.filter(
                                                                    (option) =>
                                                                        option.Name.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                        option.RxNorm.toLowerCase().includes(inputValue.toLowerCase())
                                                                );
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item md={12}>
                                            <TextField
                                                fullWidth
                                                label="Reaction"
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                rows={5}
                                                value={values.Reaction}
                                                name="Reaction"
                                                onChange={handleChange}
                                                error={Boolean(submitCount) && Boolean(errors?.Reaction)}
                                                helperText={Boolean(submitCount) ? errors?.Reaction : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="mui-modal-footer">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        disabled={isSaving}
                                        endIcon={isSaving ? <CircularProgress size={18} color="inherit" /> : null}
                                    >
                                        Save
                                    </Button>
                                    <Button size="small" type="button" onClick={handleOnCloseModal} variant="outlined" disabled={isSaving}>
                                        Cancel
                                    </Button>
                                </div>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid item lg={12} md={12} sm={12}>
                        <div className="d-row-space-between">
                            <Typography variant="h6" component="label">
                                Allergies / Reactions
                            </Typography>
                            <Stack direction="row" justifyContent="center" spacing={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={memberBasicInfo.NoKnownAllergies}
                                            onChange={(e) => {
                                                dispatch(updateNoKnownAllergies(memberId, e.target.checked));
                                                setIsChanged(true);
                                            }}
                                        />
                                    }
                                    label="NKA"
                                    labelPlacement="start"
                                    sx={classes.nkaCheckbox}
                                />
                                <IconButton onClick={() => handleOnShowModal()} sx={{ p: 0, pr: 3 }}>
                                    <AddBoxRoundedIcon color="primary" fontSize="large" />
                                </IconButton>
                            </Stack>
                        </div>
                        <hr style={classes.hr} />
                    </Grid>

                    <Grid item className="content-form" sx={{ p: 0 }}>
                        {memberAllergies.length ? (
                            <TableContainer sx={classes.tableContainer}>
                                <Typography variant="h6" component="label" sx={{ fontSize: '14px', ml: 1 }}>
                                    <span style={{ color: 'red' }}>* </span>Downloaded from Veradigm
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="20%">Medication</TableCell>
                                            {/* <TableCell width="20%">Description</TableCell> */}
                                            <TableCell width="10%">Type</TableCell>
                                            <TableCell width="50%">Reaction</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {memberAllergies.map((allergy, index) => (
                                            <TableRow
                                                key={`summary-note-${allergy.Id}-${index}`}
                                                hover
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    {allergy.Name}
                                                    {allergy.Source === 'Veradigm' ? <span style={{ color: 'red' }}> *</span> : null}
                                                </TableCell>
                                                {/* <TableCell>{allergy.Description}</TableCell> */}
                                                <TableCell>{AllergyTypes.find((a) => String(a.Id) === String(allergy.AllergyType)).Name}</TableCell>
                                                <TableCell>
                                                    <div className="d-row-space-between">
                                                        <span>{allergy.Reaction}</span>
                                                        <div className="row">
                                                            <IconButton onClick={() => handleOnShowModal(allergy)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleOnClickDelete(allergy)}
                                                                sx={{ mr: 2 }}
                                                                disabled={deletingAlergyIds.some((d) => d === allergy.Id)}
                                                            >
                                                                {deletingAlergyIds.some((d) => d === allergy.Id) ? (
                                                                    <CircularProgress size={18} />
                                                                ) : (
                                                                    <DeleteIcon color="error" fontSize="small" />
                                                                )}
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography sx={{ ml: 3 }} variant="body1" component="label">
                                No allergies for this member.
                            </Typography>
                        )}
                    </Grid>
                    {isAssessment ? (
                        <Grid item justifyContent="flex-end" className="content-footer">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ mr: 3 }}
                                disabled={isSaving}
                                onClick={() => dispatch(gotoPreviousMenu())}
                                startIcon={<ArrowBackIosIcon />}
                            >
                                Previous
                            </Button>
                            <Button type="submit" variant="contained" disabled={isSaving} onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                                Next
                            </Button>
                        </Grid>
                    ) : null}
                    {renderAddOrUpdateAllergy()}

                    <AlertDialog
                        open={showDeleteAlert}
                        title="Delete Allergy"
                        okText="Confirm"
                        onConfirm={handleOnConfirmedDelete}
                        isLoading={isSaving}
                        onClose={() => {
                            setShowDeleteAlert(false);
                        }}
                        message="Are you sure want to delete this allergy?"
                    />
                </>
            )}
            {Boolean(engagementDraft.AllergyStatus === StatusEnum.InProgress) ||
            Boolean(engagementDraft.AllergyStatus === StatusEnum.Completed) ||
            isChanged ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Allergy"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={handleOnConfirm}
                    onSkip={handleOnConfirmToNext}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Allergy is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Allergy"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={handleOnConfirmToNext}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure you want to proceed without making changes to Allergy?"
                />
            )}
        </Grid>
    );
};

export default MemberAllergyComponent;
