import NotificationImportant from '@mui/icons-material/NotificationImportant';
import { Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchStatusAwaitingApprovalCount, setSelectedTab } from '../../store/members.slice';
import { fetchProvider } from '../../store/shared.slice';
import Authorization from '../../utils/Authorization';
import MembersListPage from './MembersListPage';
import MembersStatusPage from './MembersStatusPage';

const MembersList = (props) => {
    const authData = new Authorization();
    const dispatch = useDispatch();

    const { selectedTab, statusAwaitingApprovalCount } = useSelector((state: RootState) => state.members);
    const { currentProvider } = useSelector((state: RootState) => state.shared);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        dispatch(setSelectedTab(newValue));
    };

    useEffect(() => {
        if (authData.isInPermission('Approve Member Status Changes')) {
            dispatch(fetchStatusAwaitingApprovalCount());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProvider]);

    useEffect(() => {
        dispatch(fetchProvider(new Authorization().UserId));
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {authData.isInPermission('Approve Member Status Changes') ? (
                <>
                    <Tabs
                        variant="fullWidth"
                        value={selectedTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={handleTabChange}
                        aria-label="disabled tabs example"
                        centered
                    >
                        <Tab label="Members List" />
                        <Tab
                            icon={statusAwaitingApprovalCount > 0 ? <NotificationImportant color="error" /> : null}
                            iconPosition="end"
                            label="Members Statuses"
                        />
                    </Tabs>
                    {selectedTab === 0 ? <MembersListPage /> : null}
                    {selectedTab === 1 ? <MembersStatusPage /> : null}
                </>
            ) : (
                <MembersListPage />
            )}
        </>
    );
};

export default MembersList;
