import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { apiString as API_URL } from '../../../../utils/constants';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { moneyFormatter } from '../../../../utils/moneyFormat';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';

const HighestCostMembers = () => {
    const authData = new Authorization();
    const showProviders = authData.Role === 'CEO' || authData.Role === 'Central Operation' ? true : false;
    const { teams } = useSelector((state: RootState) => state.teams);
    const [timePeriod, setTimePeriod] = useState(1);
    const [highestCostMembers, setHighestCostMembers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const ref = useRef(null);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const columns: GridColDef[] = [
        {
            field: 'Rank',
            headerName: '#',
            minWidth: 200,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${highestCostMembers.indexOf(params.row) + 1}`,
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'Cost',
            headerName: 'Cost',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${moneyFormatter.format(params.row.Cost)}`,
        },
    ];

    const timePeriods = [
        { label: 'Baseline', value: 1 },
        { label: 'CHP Performance', value: 2 },
    ];

    useEffect(() => {
        const changeFilter = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_URL}/claimanalytic/gethighcostmembers?claimPeriod=${timePeriod}&teamId=${selectedTeam}&includeInActiveTeams=${showInActiveTeams}`
                );
                setHighestCostMembers(response.data);
                setIsLoading(false);
            } catch (error) {
                setHighestCostMembers([]);
                setIsLoading(false);
                console.log(error);
            }
        };
        changeFilter();
    }, [timePeriod, selectedTeam, showInActiveTeams]);

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                            Highest Cost Members
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">Top 10 highest cost active members</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {showProviders && (
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={selectedTeam}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setSelectedTeam(e.target.value)}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl className="form-control-200">
                            <ToggleButtonGroup
                                value={timePeriod}
                                exclusive
                                size="small"
                                onChange={(event: React.MouseEvent<HTMLElement>, newPeriod: number | null) => {
                                    setTimePeriod(newPeriod);
                                }}
                            >
                                {timePeriods.map((period) => {
                                    return (
                                        <ToggleButton color="primary" key={period.value} value={period.value} sx={{ whiteSpace: 'nowrap' }}>
                                            {period.label}
                                        </ToggleButton>
                                    );
                                })}
                            </ToggleButtonGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} marginTop={2} position="relative">
                        {isLoading && <WidgetLoading />}
                        <div style={{ width: '100%', height: '52vh' }}>
                            <DataGrid
                                rows={highestCostMembers}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => Math.random()}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default HighestCostMembers;
