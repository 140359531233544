import {
    Grid,
    Paper,
    Typography,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
import { DataGrid, GridColDef, gridClasses, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState, useRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';

import WidgetLoading from '../../../SmartDashboard/Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';
import { dateFormatter } from '../../../../utils/timeFormat';
import { fetchFuhFumFullProgramReferralSummary, MenuProps } from '../../../Widgets/WidgetService';
import { isDefaultDate } from '../../../../utils/common';
import { parentUrl } from '../../../../utils/constants';
import { Search } from '@mui/icons-material';

const FuhFumFullProgramReferralSummary = () => {
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const authData = new Authorization();
    const history = useNavigate();
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const ref = useRef(null);
    const [tab, setTab] = useState(1);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState<string>(authData.Role === 'CEO' ? 'All' : authData.UserId);
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const [memberSearchValue, setMemberSearchValue] = useState('');

    const handleClick = (event) => {
        setShow(!show);
    };

    const changeFilter = async () => {
        setIsFetched(false);
        const response = await fetchFuhFumFullProgramReferralSummary(memberSearchValue, selectedProvider, showInActiveProviders);
        setRows(response);
        let filterResponse = response.filter((m) => m.IsEnrolled);
        setFilteredRows(filterResponse);
        setIsFetched(true);
    };

    useEffect(() => {
        setIsFetched(false);
        changeFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsFetched, selectedProvider, showInActiveProviders]);

    const columns: GridColDef[] = [
        {
            field: 'MemberName',
            headerName: 'Member Name',
            flex: 1,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 2,
        },
        {
            field: 'ReferralDate',
            headerName: 'Referral Date',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? '-' : dateFormatter(new Date(params.row.AdmitDate))}`,
        },
    ];

    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 80 : 90) + ''));
    };

    const handleAlertsTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        let filterResponse = [];
        switch (newValue) {
            case 1: {
                filterResponse = rows.filter((m) => m.IsEnrolled);
                break;
            }
            case 2: {
                filterResponse = rows.filter((m) => !m.IsEnrolled);
                break;
            }
        }
        setFilteredRows(filterResponse);
        setTab(newValue);
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider('All');
    };

    const navigateToMembers = () => {
        history(`/provider/metricsummary/fuhfumexceptionmemberdetail`);
    };

    const changeSearch = (event: any) => {
        if (!event.key || event.key === 'Enter') {
            changeFilter();
        }
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color={'primary'} variant="h6" textAlign={'center'} ref={ref}>
                            <span className="pointer" onClick={navigateToMembers}>
                                FUHFUM Full Program Referral
                            </span>
                            <Tooltip
                                open={show}
                                title={
                                    <div>
                                        <Typography variant="caption">FUHFUM members who are reffered to the full program</Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Enrolled :
                                        </Typography>
                                        <Typography variant="caption">
                                            Indicates members who are enrolled in full program after the FUHFUM is complete.
                                        </Typography>
                                        <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                            Not Enrolled :
                                        </Typography>
                                        <Typography variant="caption">
                                            Members who are not enrolled in full program after 30 days of FUHFUM referral.
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 1000 } }}
                            >
                                <IconButton onClick={handleClick}>
                                    <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Providers</InputLabel>
                            <Select
                                value={selectedProvider}
                                label="Providers"
                                name="Providers"
                                onChange={(e) => setSelectedProvider(e.target.value)}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key="All" value="All">
                                    All
                                </MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {filteredProviders.map((pro) => (
                                    <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {pro.LastName}, {pro.FirstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl size="small">
                            <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type="text"
                                value={memberSearchValue}
                                onChange={(e) => {
                                    setMemberSearchValue(e.target.value as string);
                                }}
                                onKeyUp={(event: any) => {
                                    changeSearch(event);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Search" onClick={changeSearch} edge="end">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} style={{ paddingTop: '5px' }}>
                        <Tabs value={tab} onChange={handleAlertsTabChange} style={{ float: 'right' }}>
                            <Tab value={1} label="Enrolled" />
                            <Tab value={2} label="Not Enrolled" />
                        </Tabs>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ width: '100%', height: 'Calc(70vh - 245px)', position: 'relative' }}>
                        {!isFetched && <WidgetLoading />}

                        <Grid item xs={12} paddingTop={2}>
                            <div style={{ width: '100%', height: '42vh' }}>
                                <DataGrid
                                    onCellClick={(params: any) => {
                                        window.open(`${parentUrl}provider/members-list/member-chart/${params.row.Id}/member-profile`, '_blank');
                                    }}
                                    rows={filteredRows}
                                    columns={columns}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[5]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    getRowId={(row) => row.Id}
                                    getRowHeight={() => 'auto'}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                        [`& .green`]: {
                                            color: 'green',
                                        },
                                        [`& .red`]: {
                                            color: 'red',
                                        },
                                        [`& .black`]: {
                                            color: 'rgba(0,0,0,0.87)',
                                        },
                                    }}
                                />
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FuhFumFullProgramReferralSummary;
