import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { getActiveProviders } from '../../../../store/provider.slice';
import {
    exportRequestsReport,
    fetchRequestsReport,
    fetchRequestTypeChoices,
    setEndDate,
    setPriority,
    setProviderId,
    setRequestProviderType,
    setStartDate,
    setStatus,
    setRowData,
} from '../../../../store/requestReport.slice';
import { localDateFormat } from '../../../../utils/timeFormat';
import { copyObject } from '../../../../utils/common';
import Loading from '../../../Shared/Loading';
import RequestTypeAutocomplete from './components/Autocompletes/RequestTypeAutocomplete';

const RequestsReport = () => {
    const dispatch = useDispatch();
    const { startDate, endDate, providerId, priority, rowData, status, requestType, requestProviderType } = useSelector(
        (state: RootState) => state.requestReport
    );
    const { downLoading } = useSelector((state: RootState) => state.shared);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [showInActive, setShowInActive] = useState<boolean>(false);
    const copyProviders = copyObject(activeProviders);
    const filteredProviders = (showInActive ? copyProviders : copyProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    useEffect(() => {
        dispatch(getActiveProviders(true));
        dispatch(setProviderId('-1'));
        dispatch(fetchRequestTypeChoices());
    }, [dispatch]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActive(isChecked);
        dispatch(setProviderId('-1'));
        dispatch(setRowData([]));
    };

    const priorityFormatter = (value: number) => {
        if (value === 1) {
            return 'Low';
        }
        if (value === 2) {
            return 'Medium';
        }
        if (value === 3) {
            return 'High';
        }
    };

    const statusFormatter = (value: number) => {
        if (value === 1) {
            return 'New';
        }
        if (value === 2) {
            return 'In-Progress';
        }
        if (value === 3) {
            return 'Completed';
        }
        if (value === 4) {
            return 'Archived';
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'RequestFrom',
            headerName: 'Initiated By',
            minWidth: 160,
            flex: 1,
            // Fixes bug where Initiated By value is left blank
            renderCell: (params: GridRenderCellParams<string>) => {
                return params.row.RequestFrom === null ? (
                    <Typography color={'error'} variant="subtitle2">
                        No Value Entered
                    </Typography>
                ) : (
                    <Typography variant="body2">{params?.row?.RequestFrom?.Name ?? 'Missing Name'}</Typography>
                );
            },
        },
        {
            field: 'RequestTo',
            headerName: 'Assigned To',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params?.row?.RequestTo?.Name ?? 'Missing Name'}`,
        },
        {
            field: 'RequestFor',
            headerName: 'Member',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params?.row?.RequestFor?.Name ?? 'Missing Name'}`,
        },
        {
            field: 'PrimaryPrividerName',
            headerName: 'Primary Provider',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row?.PrimaryPrividerName ?? '-'}`,
        },
        {
            field: 'Priority',
            headerName: 'Priority',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${priorityFormatter(params.row.Priority)}`,
        },
        {
            field: 'Type',
            headerName: 'Type',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.RequestTypeLabel}`,
        },
        {
            field: 'Status',
            headerName: 'Status',
            minWidth: 180,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${statusFormatter(params.row.Status)}`,
        },
        {
            field: 'CreatedOn',
            headerName: 'Creation Date',
            minWidth: 110,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${localDateFormat(new Date(params.row.CreatedOn))}`,
        },
        {
            field: 'CompletedOn',
            headerName: 'Completion Date',
            minWidth: 110,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.CompletedOn ? localDateFormat(new Date(params.row.CompletedOn)) : ''}`,
        },
    ];

    const priorityChoices = [
        { label: 'Low', value: '1' },
        { label: 'Medium', value: '2' },
        { label: 'High', value: '3' },
    ];

    const priorityHandler = (event: React.MouseEvent<HTMLElement>, newPriorites: string[]) => {
        if (newPriorites.length) {
            dispatch(setPriority(newPriorites));
        }
    };

    const statusChoices = [
        { label: 'New', value: '1' },
        { label: 'In-Progress', value: '2' },
        { label: 'Completed', value: '3' },
    ];

    const statusHandler = (event: React.MouseEvent<HTMLElement>, newStatuses: string[]) => {
        dispatch(setStatus(newStatuses));
    };

    const handleProviderType = (event: React.MouseEvent<HTMLElement>, providerType: string | null) => {
        dispatch(setRequestProviderType(providerType));
    };

    useEffect(() => {
        dispatch(fetchRequestsReport(providerId, requestProviderType, startDate, endDate, requestType, priority, status, showInActive));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerId, priority, status, requestProviderType, requestType]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
                <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                    <Typography fontWeight="fontWeightMedium" component="h3">
                        Requests Report
                    </Typography>
                </Box>
                {<Loading message={'Loading...'} />}
                <Grid item xs={12}>
                    <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="Start Date:"
                                value={startDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setStartDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <FormControl className="form-control-200">
                            <DesktopDatePicker
                                label="End Date:"
                                value={endDate}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                    dispatch(setEndDate(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>

                        <FormControl className="form-control-200">
                            <RequestTypeAutocomplete />
                        </FormControl>

                        <Button
                            style={{ minWidth: '120px', marginRight: 'auto' }}
                            onClick={() =>
                                dispatch(fetchRequestsReport(providerId, requestProviderType, startDate, endDate, requestType, priority, status, showInActive))
                            }
                            variant="contained"
                        >
                            Search
                        </Button>
                        <Button
                            style={{ minWidth: '120px', marginLeft: 'auto' }}
                            onClick={() =>
                                dispatch(exportRequestsReport(providerId, requestProviderType, startDate, endDate, requestType, priority, status, showInActive))
                            }
                            variant="contained"
                            color="success"
                        >
                            {downLoading ? 'Downloading..' : 'Export'}
                        </Button>
                    </Stack>

                    <Stack width="100%" direction="row" alignItems="flex-end" marginBottom={2} className="float-right" spacing={3}>
                        <FormControl>
                            <ToggleButtonGroup
                                sx={{
                                    [`& .MuiToggleButtonGroup-grouped.Mui-selected`]: {
                                        backgroundColor: '#1976d2',
                                        color: '#fff',
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                value={requestProviderType}
                                size="small"
                                exclusive
                                onChange={handleProviderType}
                                arial-label="request provider type"
                            >
                                <ToggleButton value={'1'}>Initiated By</ToggleButton>
                                <ToggleButton value={'2'}>Assigned To</ToggleButton>
                            </ToggleButtonGroup>
                        </FormControl>
                        <FormControl className="form-control-200">
                            <InputLabel id="demo-simple-select-label">Provider</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={providerId}
                                value={providerId}
                                label="Provider"
                                onChange={(e) => {
                                    dispatch(setProviderId(e.target.value));
                                }}
                            >
                                <MenuItem value={'-1'}>All</MenuItem>
                                <FormControlLabel
                                    sx={{ pl: '12px', mb: '0px' }}
                                    control={<Checkbox checked={showInActive} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                    label="Show Inactive"
                                />
                                {[...filteredProviders]
                                    .sort((a, b) => a.LastName.localeCompare(b.LastName))
                                    .map((provider) => (
                                        <MenuItem
                                            value={provider.Id}
                                            key={provider.Id}
                                            sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}
                                        >
                                            {provider.LastName}, {provider.FirstName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control-200">
                            <FormLabel component="legend">Priority</FormLabel>
                            <ToggleButtonGroup
                                sx={{
                                    [`& .MuiToggleButtonGroup-grouped.Mui-selected`]: {
                                        backgroundColor: '#1976d2',
                                        color: '#fff',
                                    },
                                }}
                                value={priority}
                                size="small"
                                onChange={priorityHandler}
                                arial-label="priorites"
                            >
                                {priorityChoices.map((item, index) => {
                                    return (
                                        <ToggleButton key={index} value={item.value}>
                                            {item.label}
                                        </ToggleButton>
                                    );
                                })}
                            </ToggleButtonGroup>
                        </FormControl>

                        <FormControl>
                            <FormLabel component="legend">Status</FormLabel>
                            <ToggleButtonGroup
                                sx={{
                                    [`& .MuiToggleButtonGroup-grouped.Mui-selected`]: {
                                        backgroundColor: '#1976d2',
                                        color: '#fff',
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                value={status}
                                size="small"
                                onChange={statusHandler}
                                arial-label="statuses"
                            >
                                {statusChoices.map((item, index) => {
                                    return (
                                        <ToggleButton key={index} value={item.value}>
                                            {item.label}
                                        </ToggleButton>
                                    );
                                })}
                            </ToggleButtonGroup>
                        </FormControl>
                    </Stack>
                </Grid>

                <Grid item style={{ height: '72vh', width: '100%' }}>
                    <DataGrid
                        rows={rowData}
                        columns={columns}
                        pageSize={11}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.Id}
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default RequestsReport;
