import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { usePopulationContext } from '../../../hooks/populationContext';
import { getStatus, MenuProps, exportAnalyticMembers } from './AnalyticService';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';

const DetailFilterOptions = (props) => {
    const {
        population,
        setPopulation,
        memberStatus,
        setMemberStatus,
        primaryProvider,
        setPrimaryProvider,
        secondaryProvider,
        setSecondaryProvider,
        claimType,
        claimPeriod,
        showInActivePrimaryTeams,
        setShowInActivePrimaryTeams,
        showInActiveSecondaryTeams,
        setShowInActiveSecondaryTeams,
    } = props;
    const { teams } = useSelector((state: RootState) => state.teams);
    const { analyticPopulations } = usePopulationContext();
    const statusList = getStatus();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [downLoading, setDownLoading] = useState<boolean>(false);
    const filteredPrimaryTeams = (showInActivePrimaryTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredSecondaryTeams = (showInActiveSecondaryTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    const togglePrimaryTeamHandler = (isChecked: boolean) => {
        setShowInActivePrimaryTeams(isChecked);
        setPrimaryProvider('All');
    };

    const toggleSecondaryTeamHandler = (isChecked: boolean) => {
        setShowInActiveSecondaryTeams(isChecked);
        setSecondaryProvider('All');
    };

    const exportDetail = async () => {
        setDownLoading(true);
        const data = await exportAnalyticMembers(
            claimType,
            population,
            primaryProvider,
            claimPeriod,
            memberStatus,
            secondaryProvider,
            showInActivePrimaryTeams,
            showInActiveSecondaryTeams
        );

        let typeName = '';
        switch (claimType) {
            case 1:
                typeName = 'TotalCost';
                break;
            case 2:
                typeName = 'EmergencyDepartmentVisit';
                break;
            case 3:
                typeName = 'AcuteCareCost';
                break;
            case 4:
                typeName = 'PharmaceuticalCost';
                break;
            case 5:
                typeName = 'AcuteBhCost';
                break;
            case 6:
                typeName = 'BhRtcCost';
                break;
        }

        let blob = b64toBlob(data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        var date = new Date();
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var dateInfo = [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, date.getFullYear()].join('');
        link.setAttribute('download', `${typeName} - ${dateInfo}.xlsx`);
        link.click();
        link.remove();

        setDownLoading(false);
    };

    return (
        <Grid container ml={0} mt={0} spacing={2}>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Population</InputLabel>
                    <Select
                        value={population}
                        label="Population"
                        name="Population"
                        onChange={(e) => setPopulation(parseInt(e.target.value))}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="All" value="0">
                            All
                        </MenuItem>
                        {analyticPopulations.map((pop) => (
                            <MenuItem key={pop.value} value={pop.value}>
                                {pop.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select value={memberStatus} label="Status" name="Status" onChange={(e) => setMemberStatus(parseInt(e.target.value))} MenuProps={MenuProps}>
                        {statusList.map((st) => (
                            <MenuItem key={st.value} value={st.value}>
                                {st.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Primary Provider</InputLabel>
                    <Select
                        value={primaryProvider}
                        label="Primary Provider"
                        name="Primary Provider"
                        onChange={(e) => {
                            setPrimaryProvider(e.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="All" value="All">
                            All
                        </MenuItem>
                        <FormControlLabel
                            sx={{ pl: '12px', mb: '0px' }}
                            control={<Checkbox checked={showInActivePrimaryTeams} onChange={(e) => togglePrimaryTeamHandler(e.target.checked)} />}
                            label="Show Inactive"
                        />
                        {filteredPrimaryTeams.map((team) => (
                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                {team.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Secondary Provider</InputLabel>
                    <Select
                        value={secondaryProvider}
                        label="Secondary Provider"
                        name="Secondary Provider"
                        onChange={(e) => {
                            setSecondaryProvider(e.target.value);
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="All" value="All">
                            All
                        </MenuItem>
                        <FormControlLabel
                            sx={{ pl: '12px', mb: '0px' }}
                            control={<Checkbox checked={showInActiveSecondaryTeams} onChange={(e) => toggleSecondaryTeamHandler(e.target.checked)} />}
                            label="Show Inactive"
                        />
                        {filteredSecondaryTeams.map((team) => (
                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                {team.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={1}>
                <Button style={{ minWidth: '120px', marginLeft: 'auto' }} onClick={() => exportDetail()} variant="contained" color="success">
                    {downLoading ? 'Exporting..' : 'Export'}
                </Button>
            </Grid>
        </Grid>
    );
};

export default DetailFilterOptions;
