import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import b64toBlob from 'b64-to-blob';
import { setDownLoading, setIsLoading } from './shared.slice';

const teamReport = createSlice({
    name: 'teamReport',
    initialState: {
        teamData: [],
        teamFilter: '3',
    },
    reducers: {
        _setTeamData(state, action) {
            state.teamData = action.payload;
        },
        _setTeamFilter(state, action) {
            state.teamFilter = action.payload;
        },
    },
});

const { _setTeamData, _setTeamFilter } = teamReport.actions;

export const setTeamData =
    (params: []): AppThunk =>
    async (dispatch) => {
        dispatch(_setTeamData(params));
    };

export const setTeamFilter =
    (params: string): AppThunk =>
    async (dispatch) => {
        dispatch(_setTeamFilter(params));
    };

export const fetchTeamReport =
    (teamFilter): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/TeamReport/getmembers?type=${teamFilter}`);
        dispatch(setTeamData(response.data));
        dispatch(setIsLoading(false));
    };

export const exportTeamReport =
    (teamFilter): AppThunk =>
    async (dispatch) => {
        dispatch(setDownLoading(true));
        const response = await axios.get(`${API_URL}/TeamReport/exportmembers?type=${teamFilter}`);
        let blob = b64toBlob(response.data.base64String, 'text/csv');
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        link.click();
        link.remove();
        dispatch(setDownLoading(false));
    };

export default teamReport.reducer;
