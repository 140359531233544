import {
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { parentUrl } from '../../../../utils/constants';
import { apiString as API_URL } from '../../../../utils/constants';
import { localDateAndTimeFormat } from '../../../../utils/timeFormat';
import { appointmentTypeEnums } from '../../../../utils/constants';
import WidgetLoading from '../../Shared/WidgetLoading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { MenuProps } from '../../../Widgets/WidgetService';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InfoIcon from '@mui/icons-material/Info';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const appointmentTypeList = [
    { value: '2', text: 'Initial' },
    { value: '3', text: 'Follow Up' },
    { value: '1', text: 'OnBoard' },
    { value: '27', text: 'HRA' },
    { value: '20', text: 'Behavioral Health Apointment' },
    { value: '25', text: 'Rescheduled' },
    { value: '17', text: 'Telemedicine' },
];

const AppointmentsNotYetConfirmed = () => {
    const defaultEndDays = 3;
    const allText = 'All';
    const [show, setShow] = useState(false);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [appointments, setAppointments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isTypeUpdated, setIsTypeUpdated] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(allText);
    const [appointmentTypes, setAppointmentTypes] = useState(appointmentTypeList.map((a) => a.text));
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [showInActiveProviders, setShowInActiveProviders] = useState<boolean>(false);
    const filteredProviders = (showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];
    const isSelectAll = appointmentTypes?.length === appointmentTypeList.length;

    const getBusinessDate = async () => {
        setFromDate(new Date());
        const response = await axios.get(`${API_URL}/scheduling/getbusinessdate?days=${defaultEndDays}`);
        setToDate(response.data);
    };

    useEffect(() => {
        getBusinessDate();
    }, []);

    const changeFilter = async () => {
        setIsLoading(true);
        let start = fromDate !== null ? new Date(fromDate).toDateString() : null;
        let end = toDate !== null ? new Date(toDate).toDateString() : null;
        const response = await axios.get(
            `${API_URL}/scheduling/appointmentsnotyetconfirmedfornext3days?appointmentTypes=${appointmentTypes}&providerId=${selectedProvider}&dateFrom=${start}&dateTo=${end}&includeInActiveProviders=${showInActiveProviders}`
        );
        setAppointments(response.data);
        setIsLoading(false);
        setIsTypeUpdated(false);
    };

    useEffect(() => {
        if (selectedProvider && Boolean(fromDate) && Boolean(toDate)) {
            changeFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProvider, fromDate, toDate, showInActiveProviders]);

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/scheduling`, '_blank');
    };

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActiveProviders(isChecked);
        setSelectedProvider(allText);
    };

    const handleClick = (event) => {
        setShow(!show);
    };

    const handleOnChange = (e: SelectChangeEvent<unknown>) => {
        let selectedValues = e.target.value as string[];
        if (selectedValues.includes(allText) && !isSelectAll) {
            selectedValues = appointmentTypeList.map((o) => o.text);
        } else if (selectedValues.includes(allText)) {
            selectedValues = [];
        }

        setSelectedAppointmentTypeIds(selectedValues);
    };

    const setSelectedAppointmentTypeIds = (selectedIds: string[]) => {
        selectedIds = selectedIds.length > 1 ? selectedIds.filter((s) => s !== allText) : selectedIds;
        setAppointmentTypes(selectedIds);
        setIsTypeUpdated(true);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                                Appointments Not Yet Confirmed
                            </Typography>
                            <Typography display={'inline-block'} variant="body1" color={'primary'}>
                                &#40;{appointments.length}&#41;
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">
                                                Displays all appointments not yet confirmed within the specified time frame
                                            </Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-label">Appointment Types</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Appointment Types"
                                    value={appointmentTypes}
                                    multiple
                                    onChange={handleOnChange}
                                    onClose={() => (isTypeUpdated ? changeFilter() : null)}
                                    input={<OutlinedInput label="Appointment Types" />}
                                    renderValue={(selected) => (isSelectAll ? allText : selected.join(', '))}
                                >
                                    <MenuItem value={allText} className={isSelectAll ? 'Mui-selected' : ''} sx={{ padding: '0', paddingLeft: '10px' }}>
                                        <Checkbox checked={isSelectAll} indeterminate={Boolean(appointmentTypes?.length) && !isSelectAll} />
                                        {allText}
                                    </MenuItem>
                                    {appointmentTypeList.map((type) => {
                                        return (
                                            <MenuItem key={type.value} value={type.text} sx={{ padding: '0', paddingLeft: '10px' }}>
                                                <Checkbox checked={appointmentTypes.indexOf(type.text) > -1} />
                                                <ListItemText primary={type.text} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={selectedProvider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setSelectedProvider(e.target.value)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key={allText} value={allText}>
                                        {allText}
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={<Checkbox checked={showInActiveProviders} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DesktopDatePicker
                                label="From Date"
                                value={Boolean(fromDate) ? fromDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => setFromDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DesktopDatePicker
                                label="To Date"
                                value={Boolean(toDate) ? toDate : null}
                                minDate={new Date('2017-01-01')}
                                onChange={(newValue) => setToDate(newValue)}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12} marginTop={2} height="45vh" sx={{ position: 'relative' }}>
                            {isLoading ? (
                                <WidgetLoading />
                            ) : appointments.length > 0 ? (
                                <SimpleBar style={{ maxHeight: '380px', width: '100%' }} autoHide={false}>
                                    <Grid container spacing={2}>
                                        {appointments.map((member) => {
                                            return (
                                                <Grid key={member.Id} item xs={6}>
                                                    <Card
                                                        sx={{
                                                            ':hover': {
                                                                cursor: 'pointer',
                                                                boxShadow: 10,
                                                                border: 0,
                                                            },
                                                            boxShadow: 0,
                                                        }}
                                                        onClick={() => openDocumentHandler(member.MemberId)}
                                                        variant="outlined"
                                                    >
                                                        <CardContent
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItem: 'flex-start',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Typography color="text.secondary" variant="body2">
                                                                        Member
                                                                    </Typography>
                                                                    <Typography fontWeight={'bold'} variant="body2">
                                                                        {member.MemberName}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography color="text.secondary" variant="body2">
                                                                        Appointment Date Time
                                                                    </Typography>
                                                                    <Typography fontWeight={'bold'} variant="body2">
                                                                        {localDateAndTimeFormat(new Date(member.AppointmentDateTime))}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography color="text.secondary" variant="body2">
                                                                        Provider Name
                                                                    </Typography>
                                                                    <Typography fontWeight={'bold'} variant="body2">
                                                                        {member.ProviderName}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography color="text.secondary" variant="body2">
                                                                        Appointment Type
                                                                    </Typography>
                                                                    <Typography fontWeight={'bold'} variant="body2">
                                                                        {
                                                                            Object.values(appointmentTypeEnums).find(
                                                                                (el) => el.value === member.AppointmentType
                                                                            ).text
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography color="text.secondary" variant="body2">
                                                                        AppointmentReason
                                                                    </Typography>
                                                                    <Typography fontWeight={'bold'} variant="body2">
                                                                        {member.AppointmentReason}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </SimpleBar>
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} height="45vh" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="h6" color="gray">
                                            No appointments to display
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default AppointmentsNotYetConfirmed;
