import BaseEntity from '../BaseEntity.model';
import HealthPlanType from './HealthPlanType.model';
import HealthPlanSubType from './HealthPlanSubType.model';

export default interface IPlanType extends BaseEntity {
    HealthPlanType: HealthPlanType;
    HealthPlanSubType: HealthPlanSubType | null;
    OrganizationName: string;
    OrganizationId: string;
    IsPrimary: boolean;
}

export default class PlanType implements IPlanType {
    Id: string;
    HealthPlanType: HealthPlanType;
    HealthPlanSubType: HealthPlanSubType | null;
    OrganizationName: string;
    OrganizationId: string;
    IsPrimary: boolean;

    constructor() {
        this.Id = '';
        this.HealthPlanType = new HealthPlanType();
        this.HealthPlanSubType = null;
        this.OrganizationName = '';
        this.OrganizationId = '';
        this.IsPrimary = false;
    }
}
