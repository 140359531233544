import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { StatusEnum } from '../Enum/StatusEnum';
import { RequestComment } from '../Models/Requests/Comment.modal';
import { RequestPreview, RequestPreviewsByType } from '../Models/Requests/RequestPreview.model';
import RequestTo from '../Models/Requests/RequestTo.model';
import { convertObjectToArray } from '../utils/common';
import { apiString as API_URL } from '../utils/constants';
import { setAssessmentError } from './assessment.slice';
import { setIsLoading } from './shared.slice';
import { AppThunk } from './store';

const requestSlice = createSlice({
    name: 'provider',
    initialState: {
        requestPreviewsByType: [] as RequestPreviewsByType[],
        requestTypes: [],
        selectedRequest: new RequestPreview(),
        showRequestModal: false,
        showForwardRequestModal: false,
        showDeleteRequestModal: false,
        showAddEngagementNoteModal: false,
        isForwarding: false,
        isRequestDeleting: false,
        isUpdatingStatus: false,
        isSaving: false,
        isFetching: false,
    },
    reducers: {
        _setRequestPreviews(state, action) {
            state.requestPreviewsByType = action.payload;
        },
        _setRequestTypes(state, action) {
            state.requestTypes = action.payload;
        },
        _setSelectedRequest(state, action) {
            state.selectedRequest = action.payload;
        },
        _toggleShowRequestModal(state, action) {
            state.showRequestModal = action.payload;
        },
        _toggleShowForwardRequestModal(state, action) {
            state.showForwardRequestModal = action.payload;
        },
        _toggleShowDeleteRequestModal(state, action) {
            state.showDeleteRequestModal = action.payload;
        },
        _toggleIsForwarding(state, action) {
            state.isForwarding = action.payload;
        },
        _toggleIsUpdatingStatus(state, action) {
            state.isUpdatingStatus = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsRequestDeleting(state, action) {
            state.isRequestDeleting = action.payload;
        },
    },
});

const {
    _setRequestPreviews,
    _setRequestTypes,
    _setSelectedRequest,
    _toggleShowRequestModal,
    _toggleShowForwardRequestModal,
    _toggleIsForwarding,
    _toggleShowDeleteRequestModal,
    _toggleIsRequestDeleting,
    _toggleIsUpdatingStatus,
    _toggleIsSaving,
    _toggleIsFetching,
} = requestSlice.actions;

export const getAllRequests =
    (memberId: string, providerId: string, priority: number, sortBy: number, assignee: number, status: number, type: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/request/all`, {
            params: {
                memberId,
                providerId,
                priority,
                sortBy,
                assignee,
                status,
                type,
            },
        });
        dispatch(_setRequestPreviews(response.data));
        dispatch(setIsLoading(false));
    };

export const saveRequest =
    (request: RequestPreview, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        if (!Boolean(request.Id)) {
            request.Status = StatusEnum.New;
        }
        const axiosRequest = Boolean(request.Id)
            ? axios.put(`${API_URL}/request`, {
                  ...request,
                  LastUpdatedOn: new Date().toISOString(),
              })
            : axios.post(`${API_URL}/request`, request);

        axiosRequest
            .then((response) => {
                dispatch(_toggleShowRequestModal(false));
                if (Boolean(callback)) {
                    callback();
                }
            })
            .catch((err) => {
                dispatch(
                    setAssessmentError({
                        Show: true,
                        Message: Boolean(err?.response?.data?.error) ? err.response.data.error : err?.response?.data,
                    })
                );
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const getRequestType = (): AppThunk => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/request/getrequesttype`);
    dispatch(_setRequestTypes(convertObjectToArray(response.data)));
    dispatch(setIsLoading(false));
};

export const getRequestById =
    (requestId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        const response = await axios.get(`${API_URL}/request`, {
            params: { requestId },
        });
        dispatch(_setSelectedRequest(response.data));
        dispatch(_toggleIsFetching(false));
    };

export const forwardRequest =
    (requestId: string, requestTo: RequestTo, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsForwarding(true));
        await axios.put(`${API_URL}/request/fowardrequest`, requestTo, {
            params: { requestId },
        });
        dispatch(_toggleIsForwarding(false));
        dispatch(_toggleShowForwardRequestModal(false));
        if (Boolean(callback)) {
            callback();
        }
    };

export const deleteRequest =
    (requestId: string, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsRequestDeleting(true));
        await axios.delete(`${API_URL}/request/delete`, {
            params: { requestId },
        });
        dispatch(_toggleIsRequestDeleting(false));
        dispatch(_toggleShowDeleteRequestModal(false));
        if (Boolean(callback)) {
            callback();
        }
    };

export const toggleShowRequestModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowRequestModal(show));
    };

export const toggleShowForwardRequestModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowForwardRequestModal(show));
    };

export const toggleShowDeleteRequestModal =
    (show: boolean): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowDeleteRequestModal(show));
    };

export const updateRequestStatus =
    (requestId: string, status: StatusEnum, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsUpdatingStatus(true));
        await axios
            .put(`${API_URL}/request/updatestatus`, {}, { params: { requestId, status } })
            .then(() => {
                if (Boolean(callback)) {
                    callback();
                }
            })
            .catch((err) => {
                dispatch(
                    setAssessmentError({
                        Show: true,
                        Message: err?.response?.data?.error,
                    })
                );
            })
            .finally(() => {
                dispatch(_toggleIsUpdatingStatus(false));
            });
    };

export const saveRequestComment =
    (requestId: string, comment: RequestComment, callback?: Function): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));
        const isUpdate = Boolean(comment.Id);
        const url = isUpdate ? `${API_URL}/request/updaterequestcomment` : `${API_URL}/request/addrequestcomment`;
        const response = await axios.put(url, comment, {
            params: { requestId },
        });
        if (!isUpdate) {
            dispatch(_setSelectedRequest(response.data));
        }
        dispatch(_toggleIsSaving(false));
        if (Boolean(callback)) {
            callback();
        }
    };

export default requestSlice.reducer;
