const useStyles = {
    form: {
        '& .MuiFormControl-root': {
            marginBottom: '15px',
        },
        '& .MuiCheckbox-root': {
            marginLeft: '-10px',
        },
    },
    topScrollPaper: {
        '&.MuiDialog-container.MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
        },
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    infoIconButton: {
        marginTop: '8px',
    },
};

export default useStyles;
