import {
    Box,
    Collapse,
    Grid,
    IconButton,
    TextField,
    Typography,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    Chip,
    Stack,
    Button,
    Paper,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OpenPopulationDto from '../../../../Models/AdminOnboarding/OpenPopulationDto.model';
import IOnboarderStatus from '../../../../Models/OfficeOnboarding/OnboarderStatus.model';
import { RootState } from '../../../../reducers';
import WidgetBox from '../../../OfficeOnboardingWidgets/WidgetBox';
import { fetchCallListLimits, saveCallListLimits } from '../../AdminOnboardingService';
import { fetchZipCodeGroups, setError } from '../../../../store/adminOnboarding.slice';
import { ZipCodeGroups } from '../ZipCodeGroups/ZipCodeGroups';
import { fetchRegions } from '../../../../store/adminRegions.slice';

interface CallerListDistributionProps {
    status: IOnboarderStatus;
    currentListLength: number;
}

const CallerListDistribution = (props: CallerListDistributionProps) => {
    const dispatch = useDispatch();

    const { error, zipCodeGroups } = useSelector((state: RootState) => state.adminOnboardingSlice);
    const { regionRowData } = useSelector((state: RootState) => state.adminRegions);
    const [openPops, setOpenPops] = useState<OpenPopulationDto[]>([]);
    const [changed, setChanged] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    const [showZipCodeGroups, setShowZipCodeGroups] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchZipCodeGroups());
    }, [dispatch]);

    const zipCodes = regionRowData;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const closeZipCodeGroupsHandler = () => {
        setShowZipCodeGroups(false);
    };

    const handleChange = (event: any, index: number) => {
        const { name, value } = event.target;
        let newPops = [...openPops];
        newPops[index][name] = value;
        setOpenPops(newPops);
        setChanged(changed + 1);
    };

    let columns = undefined;
    if (openPops.length) {
        columns = openPops
            .sort((a, b) => a.PopulationName.localeCompare(b.PopulationName))
            .map((pop, index) => {
                return (
                    <Grid item xs={3}>
                        <Paper sx={{ p: 2 }} elevation={3}>
                            <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="h6">
                                {pop.PopulationName}
                            </Typography>
                            <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="body2">{`${pop.OpenMemberCount} Undetermined`}</Typography>
                            <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="body2">{`${pop.FuhUndCount} FUH-Und`}</Typography>
                            <Typography sx={{ maxWidth: 200, marginX: 'auto' }} variant="body2">{`${pop.ScheduledMemberCount} Scheduled`}</Typography>

                            <Stack spacing={2} marginTop={2} sx={{ textAlign: 'left' }}>
                                <TextField
                                    variant="outlined"
                                    error={error}
                                    helperText={error ? 'Values exceed maximum call list length' : ''}
                                    sx={{ marginY: 1 }}
                                    label={'Limit'}
                                    name="Limit"
                                    type="number"
                                    value={pop.Limit || ''}
                                    onChange={(event: any) => {
                                        handleChange(event, index);
                                    }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="zip-codes-label">Zip Codes</InputLabel>
                                    <Select
                                        labelId="zip-codes-label"
                                        multiple
                                        value={pop.SelectedZipCodes || []}
                                        name="SelectedZipCodes"
                                        onChange={(event: any) => {
                                            handleChange(event, index);
                                        }}
                                        input={<OutlinedInput id="select-multiple-chip" label="Zip Codes" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {pop.PopulationZipCodes?.map((zip, index) => {
                                            return (
                                                <MenuItem key={`${zip}_${index}`} value={zip.ZipCode}>
                                                    {`${zip.ZipCode} (${zip.ZipCodeCount})`}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel>Zip Code Groups</InputLabel>
                                    <Select
                                        value={pop.ZipCodeGroupId || ''}
                                        label="Zip Code Groups"
                                        name="ZipCodeGroupId"
                                        onChange={(event: any) => {
                                            handleChange(event, index);
                                        }}
                                    >
                                        <MenuItem value={null}>None</MenuItem>
                                        {zipCodeGroups.map((group) => (
                                            <MenuItem key={group.Id} value={group.Id}>
                                                {group.GroupName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Paper>
                    </Grid>
                );
            });
    }

    const fetchCallList = useCallback(async () => {
        if (props.status?.Id) {
            setOpenPops([]);
            setOpen(false);
            return fetchCallListLimits(props.status.Id);
        }
    }, [props.status]);

    useEffect(() => {
        const fetchCallListLimitsAsync = async () => {
            const response = await fetchCallList();
            if (response.status === 200) {
                const data = await response.data;
                setOpenPops(data);
            }
        };
        fetchCallListLimitsAsync();
    }, [fetchCallList]);

    useEffect(() => {
        dispatch(fetchRegions());
    }, [dispatch]);

    const openZipCodeGroupsHandler = () => {
        setShowZipCodeGroups(true);
    };

    // const savePops = async () => {
    //   const totals = openPops.map((pop) => pop.Limit);
    //   const totalCount = totals.reduce((a, b) => Number(a!) + Number(b!)) ?? 0;
    //   const exceeds = totalCount > props.currentListLength;
    //   setError(exceeds);
    //   if (!exceeds) {
    //     const response = await saveCallListLimits(props.status.Id!, openPops);
    //     if (response.ok) {
    //       setOpen(true);
    //     }
    //   }
    // };

    useEffect(() => {
        if (changed !== 0 && openPops.length) {
            setOpen(false);
            const openAlertOnTimer = () =>
                setTimeout(async () => {
                    const totals = openPops.map((pop) => pop.Limit);
                    const totalCount = totals.reduce((a, b) => Number(a!) + Number(b!)) ?? 0;
                    const exceeds = totalCount > props.currentListLength;
                    dispatch(setError(exceeds));
                    if (!exceeds) {
                        const response = await saveCallListLimits(props.status.Id!, openPops);
                        if (response.status === 200) {
                            setOpen(true);
                        }
                    }
                }, 2000);
            const timerId = openAlertOnTimer();
            return () => {
                clearTimeout(timerId);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed, props.status, openPops, props.currentListLength, setError]);

    useEffect(() => {
        if (openPops?.length) {
            const totals = openPops.map((pop) => pop.Limit);
            const totalCount = totals.reduce((a, b) => Number(a!) + Number(b!)) ?? 0;
            const exceeds = totalCount > props.currentListLength;
            if (props.status.CallListLimits) {
                dispatch(setError(exceeds));
            } else {
                dispatch(setError(false));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.status, openPops, props.currentListLength, setError]);

    return (
        <>
            <ZipCodeGroups
                open={showZipCodeGroups}
                handleClose={closeZipCodeGroupsHandler}
                zipCodes={zipCodes}
                zipCodeGroups={zipCodeGroups}
                MenuProps={MenuProps}
            />
            <WidgetBox title="Caller List Distribution">
                <div style={{ marginBottom: 15 }}>
                    <Grid container alignItems="center" justifyContent="space-evenly" spacing={3}>
                        <Grid item xs={3}>
                            <Collapse in={open}>
                                <Alert
                                    severity="success"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            <GridCloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    Distribution Updated
                                </Alert>
                            </Collapse>
                        </Grid>
                    </Grid>
                </div>

                <Stack mb={5}>
                    <Button variant="contained" color="primary" sx={{ alignSelf: 'flex-end' }} onClick={openZipCodeGroupsHandler}>
                        Zip Code Groups
                    </Button>
                </Stack>
                <Grid container alignItems="flex-start" justifyContent="space-evenly" spacing={2}>
                    {React.Children.toArray(columns)}
                </Grid>
            </WidgetBox>
        </>
    );
};

export default CallerListDistribution;
