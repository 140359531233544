import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Radio, RadioGroup, FormControlLabel, Button, Box, CircularProgress, Stack, Divider, Typography, Checkbox } from '@mui/material';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { RootState } from '../../../reducers';
import { gotoNextMenu, gotoPreviousMenu } from '../../../store/assessment.slice';
import { YesNoAnswers } from '../../../utils/assessments';
import { copyObject } from '../../../utils/common';
import { StatusEnum } from '../../../Enum/StatusEnum';
import { fetchFallRiskById, saveFallRisk } from '../../../store/hra.slice';
import { YesNoEnum } from '../../../Enum/YesNoEnum';

const FallRiskAssessment: React.FC<{ memberId: string }> = ({ memberId }) => {
    const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { fallRisk, isFetching, isSaving: isSavingHra } = useSelector((state: RootState) => state.hra);
    const { engagementDraft, isSaving: isSavingEngagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const isSaving = isSavingHra || isSavingEngagementDraft;

    useEffect(() => {
        if (Boolean(engagementDraft.FallRiskId)) {
            dispatch(fetchFallRiskById(engagementDraft.FallRiskId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, memberId]);

    const handleOnClickSave = () => {
        setShowSaveAlert(true);
    };

    const handleOnConfirmToNext = (isWithoutComplete = false) => {
        if (isWithoutComplete) {
            dispatch(saveFallRisk({ ...fallRisk, Status: StatusEnum.Completed }, true));
        } else {
            dispatch(gotoNextMenu());
        }
    };

    const handleOnChangeAnswer = (answer: string, propName: string) => {
        let data = copyObject(fallRisk);
        data[propName] = answer;
        data.MemberId = memberId;

        dispatch(saveFallRisk(data));
    };

    const prepareFallRiskQuestions = () => {
        return [
            {
                question: 'I have fallen in the past year. (likely to fall again)',
                answer: fallRisk.IsFallenInPastYear,
                name: 'IsFallenInPastYear',
            },
            {
                question: 'I use or have been advised to use a cane or walker to get around safely (assistive DME)',
                answer: fallRisk.IsAdvisedToUseCaneOrWalker,
                name: 'IsAdvisedToUseCaneOrWalker',
            },
            {
                question: 'Sometimes I feel unsteady when I am walking. (sign of poor balance)',
                answer: fallRisk.IsUnsteadyWhenWalking,
                name: 'IsUnsteadyWhenWalking',
            },
            {
                question: 'I steady myself by holding onto fumiture (as above)',
                answer: fallRisk.IsSteadyHoldingOntoFurniture,
                name: 'IsSteadyHoldingOntoFurniture',
            },
            {
                question: 'I am worried about falling ( more likely to fall)',
                answer: fallRisk.IsWorriedAboutFalling,
                name: 'IsWorriedAboutFalling',
            },
            {
                question: 'I need to push with my hands to stand up from a chair or stepping up on a curb (sign of weak leg muscles)',
                answer: fallRisk.HasWeakLegMuscles,
                name: 'HasWeakLegMuscles',
            },
            {
                question: 'I have lost some feeling in my feet. (cause stumbles and lead to falls)',
                answer: fallRisk.IsLostFeelingInFeet,
                name: 'IsLostFeelingInFeet',
            },
            {
                question:
                    'I take medicine that sometimes makes me feel light-headed or more tired than usual (side effects from medicines can sometimes increase your chance of falling)',
                answer: fallRisk.IsTakenMedicineWhenTired,
                name: 'IsTakenMedicineWhenTired',
            },
            {
                question: 'I take medicine to help me sleep or improve my mood. (medicines can sometimes increase your chance of falling)',
                answer: fallRisk.IsTakenMedicineToSleep,
                name: 'IsTakenMedicineToSleep',
            },
            {
                question: 'Assessed Home for Fall Risk:',
                answer: fallRisk.IsAssessedHomeForFallRisk,
                name: 'IsAssessedHomeForFallRisk',
                isAssessedHome: true,
            },
        ];
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel" sx={{ paddingTop: 4 }}>
            {isFetching ? (
                <Box display="flex" justifyContent="center" className="loader-container">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Grid item className="content-form">
                        <Typography variant="h6" component="label">
                            Balance/Fall risk
                        </Typography>
                        <hr />
                        {prepareFallRiskQuestions().map((question, qIndex) => (
                            <React.Fragment key={`fall-risk-question-${qIndex}`}>
                                <Grid container spacing={2} sx={{ paddingY: 1 }}>
                                    <Grid item lg={8.5} md={8} sm={6} display="flex" alignItems="center">
                                        <Typography variant="subtitle1" component="label">
                                            {question.question}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3.5} md={4} sm={6}>
                                        <RadioGroup
                                            row
                                            name={question.name}
                                            value={question.answer}
                                            onChange={(e) => handleOnChangeAnswer(e.target.value, e.target.name)}
                                        >
                                            {YesNoAnswers.map((type, index) => {
                                                const label = `${type.Name}${type.Name === YesNoEnum.Yes && question.isAssessedHome ? ', have concerns' : ''}`;
                                                return (
                                                    <FormControlLabel
                                                        key={`answer-bath-body-${index}-${qIndex}`}
                                                        value={type.Id}
                                                        control={<Radio />}
                                                        label={label}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </React.Fragment>
                        ))}
                        <Grid container spacing={2} sx={{ paddingY: 1, mb: 2 }}>
                            <Grid item lg={8.5} md={8} sm={6} display="flex" alignItems="center">
                                <Typography variant="subtitle1" component="label">
                                    Education completed
                                </Typography>
                            </Grid>
                            <Grid item lg={3.5} md={4} sm={6}>
                                <RadioGroup
                                    row
                                    name="IsEducationCompleted"
                                    value={fallRisk.IsEducationCompleted}
                                    onChange={(e) => handleOnChangeAnswer(e.target.value, e.target.name)}
                                >
                                    <FormControlLabel value="true" control={<Checkbox />} label="Completed" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Typography variant="h6" component="label">
                            Score: {fallRisk.Score || '0'}
                        </Typography>
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end" className="content-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 2 }}
                            onClick={() => dispatch(gotoPreviousMenu())}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Previous
                        </Button>
                        <Button type="submit" variant="contained" onClick={handleOnClickSave} endIcon={<ArrowForwardIosIcon />}>
                            Next
                        </Button>
                    </Stack>
                </React.Fragment>
            )}
            {Boolean(engagementDraft.FallRiskId) ? (
                <AlertDialog
                    open={showSaveAlert}
                    title="Fall risk"
                    cancelText="Go Back"
                    okText="Confirm"
                    onConfirm={() => handleOnConfirmToNext(true)}
                    onSkip={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="I confirm and acknowledge that Fall risk is completed."
                />
            ) : (
                <AlertDialog
                    open={showSaveAlert}
                    title="Fall risk"
                    cancelText="No"
                    okText="Yes, Proceed"
                    onConfirm={() => handleOnConfirmToNext()}
                    onClose={() => setShowSaveAlert(false)}
                    isLoading={isSaving}
                    message="Are you sure to proceed without completing Fall risk?"
                />
            )}
        </Grid>
    );
};

export default FallRiskAssessment;
