import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    TextField,
    Checkbox,
    OutlinedInput,
    FormControlLabel,
    Collapse,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { AssessmentTypeEnum } from '../../../Enum/AssessmentTypeEnum';
import { AssessmentCategory, Question } from '../../../Models/Assessment/AssessmentCategory.model';
import classes from './Styles';
import { SelectionType } from '../../../Enum/SelectionTypeEnum';
import { copyObject } from '../../../utils/common';

const CategoryQuestions: React.FC<{ assessmentType: AssessmentTypeEnum; category: AssessmentCategory; onChange: Function }> = ({
    assessmentType,
    category,
    onChange,
}) => {
    const options = category?.Questions?.filter((q) => Boolean(q.Options))?.sort((a, b) => b.Options.length - a.Options.length)[0]?.Options || [];
    const showDatePicker = assessmentType === AssessmentTypeEnum.PastSurgicalHistory;

    const handleOnUpdateAnswer = (isSelected, questionIndex, optionIndex) => {
        let data = copyObject(category);
        data.Questions[questionIndex].Options.forEach((option, index) => {
            if (index === optionIndex) {
                option.IsSelected = isSelected;
            } else {
                option.IsSelected = false;
            }
        });
        data.Questions[questionIndex].Options[optionIndex].IsSelected = isSelected;
        onChange(data);
    };

    const handleOnUpdateSubAnswer = (isSelected: boolean, questionIndex: number, optionIndex: number, subOptionIndex: number) => {
        let data = copyObject(category);
        data.Questions[questionIndex].Options.forEach((option, index) => {
            if (index === optionIndex) {
                option.SubOptions.forEach((subOption, sIndex) => {
                    if (sIndex === subOptionIndex) {
                        subOption.IsSelected = isSelected;
                    } else {
                        subOption.IsSelected = false;
                    }
                });
            }
        });

        onChange(data);
    };

    const handleOnUpdateDate = (date, questionIndex) => {
        let data = copyObject(category);
        data.Questions[questionIndex].Date = date;
        onChange(data);
    };

    const handleOnUpdateComments = (comments: string) => {
        let data = copyObject(category);
        data.Comments = comments;
        onChange(data);
    };

    const handleOnChangeAnswerText = (answerText: string, questionIndex: number) => {
        let data = copyObject(category);
        data.Questions[questionIndex].AnswerText = answerText;
        onChange(data);
    };

    const renderSubQuesions = (question: Question, qIndex: number) => {
        const option = Boolean(question?.Options?.length) ? question.Options.find((o) => o.IsSelected && Boolean(o.SubOptions)) : null;

        return (
            <Collapse in={Boolean(option)} timeout="auto" unmountOnExit sx={{ marginTop: 0 }}>
                {Boolean(option) && option.IsSelected && Boolean(option.SubOptions?.length)
                    ? option.SubOptions.map((subOption, sIndex) => (
                          <FormControlLabel
                              key={`body_${subOption.Name}_sub_option_${sIndex}`}
                              sx={classes.rhythmRadioOption}
                              control={
                                  <Checkbox
                                      checked={subOption.IsSelected}
                                      size="small"
                                      radioGroup={question.Description}
                                      onChange={(e) => handleOnUpdateSubAnswer(e.currentTarget.checked, qIndex, 0, sIndex)}
                                      icon={<PanoramaFishEyeIcon />}
                                      checkedIcon={<RadioButtonCheckedIcon />}
                                  />
                              }
                              label={subOption.Name}
                          />
                      ))
                    : null}
            </Collapse>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TableContainer component={Paper} sx={classes.customTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="55%">{category.Name}</TableCell>
                            {options.map((option, index) => (
                                <TableCell key={`header_${option.Name}_${index}`} align="center">
                                    {option.Name}
                                </TableCell>
                            ))}
                            {showDatePicker && (
                                <TableCell align="center" width="24%">
                                    Date
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {category?.Questions?.map((question, qIndex) =>
                            question.Description === 'Rhythm' && category.Name === 'Heart' ? (
                                <TableRow key={`${question.Id}_${qIndex}`}>
                                    <TableCell component="th" scope="row" colSpan={options.length + 1}>
                                        <div>{question.Description}</div>
                                        {question.Options?.map((option, oIndex) => (
                                            <FormControlLabel
                                                key={`body_${option.Name}_${oIndex}`}
                                                sx={classes.rhythmRadioOption}
                                                control={
                                                    <Checkbox
                                                        checked={option.IsSelected}
                                                        size="small"
                                                        radioGroup={question.Description}
                                                        onChange={(e) => handleOnUpdateAnswer(e.currentTarget.checked, qIndex, oIndex)}
                                                        icon={<PanoramaFishEyeIcon />}
                                                        checkedIcon={<RadioButtonCheckedIcon />}
                                                    />
                                                }
                                                label={option.Name}
                                            />
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow key={`${question.Id}_${qIndex}`}>
                                    <TableCell component="th" scope="row">
                                        {question.Description}
                                        {renderSubQuesions(question, qIndex)}
                                    </TableCell>
                                    {question.SelectionType === SelectionType.SingleSelect || question.SelectionType === SelectionType.MultiSelect ? (
                                        question.Options?.map((option, oIndex) => (
                                            <TableCell key={`body_${option.Name}_${oIndex}`} align="center" style={{ verticalAlign: 'top' }}>
                                                <React.Fragment>
                                                    <Checkbox
                                                        checked={option.IsSelected}
                                                        size="small"
                                                        radioGroup={question.Description}
                                                        onChange={(e) => handleOnUpdateAnswer(e.currentTarget.checked, qIndex, oIndex)}
                                                        icon={<PanoramaFishEyeIcon />}
                                                        checkedIcon={<RadioButtonCheckedIcon />}
                                                    />
                                                </React.Fragment>
                                            </TableCell>
                                        ))
                                    ) : question.SelectionType === SelectionType.Text ? (
                                        <TableCell key={`body_${question.Description}_${qIndex}`} align="center" colSpan={3}>
                                            <OutlinedInput
                                                className="x-small-text-input hide-textinput-label"
                                                classes={{ root: '' }}
                                                defaultValue={question.AnswerText}
                                                fullWidth={true}
                                                size="small"
                                                onBlur={(e) => handleOnChangeAnswerText(e.currentTarget.value, qIndex)}
                                            />
                                        </TableCell>
                                    ) : null}
                                    {showDatePicker && (
                                        <TableCell align="right">
                                            <DesktopDatePicker
                                                value={Boolean(question.Date) ? question.Date : null}
                                                toolbarPlaceholder=""
                                                views={['month', 'year']}
                                                inputFormat="MMM yyyy"
                                                onChange={(date) => handleOnUpdateDate(date, qIndex)}
                                                renderInput={(params) => <TextField sx={classes.datePickertextField} placeholder="" size="small" {...params} />}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={classes.commentHeader}>{category.Name} Comments</Box>
            <Box sx={classes.commentBox}>
                <TextField
                    placeholder="Comments..."
                    defaultValue={category?.Comments}
                    sx={classes.commentTextarea}
                    multiline
                    onBlur={(e) => handleOnUpdateComments(e.currentTarget.value)}
                    rows={6}
                    fullWidth
                />
            </Box>
        </LocalizationProvider>
    );
};

export default CategoryQuestions;
