import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fab, Grid, Paper, Stack } from '@mui/material';
import { RootState } from '../../../../reducers';
import MemberAvatar from '../MemberAvatar';
import { fetchDevices, fetchLife365, fetchVitalsSigns } from '../../../../store/memberDetail.slice';
import TelemonitoringLife365 from './TelemonitoringLife365';
import TelemonitoringDevices from './TelemonitoringDevices';
import TelemonitoringParameters from './TelemonitoringParameters';

const MemberTelemonitoring = () => {
    const dispatch = useDispatch();
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const [selectedStatusTable, setSelectedStatusTable] = useState(0);

    useEffect(() => {
        dispatch(fetchDevices(selectedMember.Id));
        dispatch(fetchLife365(selectedMember.Id));
        dispatch(fetchVitalsSigns(selectedMember.Id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Paper elevation={3} style={{ height: 'calc(100vh - 100px)', padding: '8px', margin: '16px', textAlign: 'right' }}>
                <Stack spacing={2}>
                    <div>
                        <MemberAvatar />
                        <Grid container spacing={3} style={{ maxWidth: '700px', float: 'right' }}>
                            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
                                <Fab
                                    variant="extended"
                                    color={selectedStatusTable === 2 ? 'primary' : 'default'}
                                    className="fab-right"
                                    onClick={() => {
                                        setSelectedStatusTable(2);
                                    }}
                                >
                                    Parameters
                                </Fab>
                                <Fab
                                    variant="extended"
                                    color={selectedStatusTable === 1 ? 'primary' : 'default'}
                                    className="fab-right"
                                    onClick={() => {
                                        setSelectedStatusTable(1);
                                    }}
                                >
                                    Devices
                                </Fab>
                                <Fab
                                    variant="extended"
                                    color={selectedStatusTable === 0 ? 'primary' : 'default'}
                                    className="fab-right"
                                    onClick={() => {
                                        setSelectedStatusTable(0);
                                    }}
                                >
                                    Life 365
                                </Fab>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={3} style={{ padding: '0px 24px 16px 0px', marginTop: '0px' }}>
                        <Grid item xs={12} style={{ paddingLeft: '0px' }}>
                            {selectedStatusTable === 0 ? <TelemonitoringLife365 /> : null}
                            {selectedStatusTable === 1 ? <TelemonitoringDevices /> : null}
                            {selectedStatusTable === 2 ? <TelemonitoringParameters /> : null}
                        </Grid>
                    </Grid>
                </Stack>
            </Paper>
        </Box>
    );
};

export default MemberTelemonitoring;
