import React, { useState } from 'react';
import { apiString as api } from '../utils/constants';
import axios from 'axios';

const HealthPlanContext = React.createContext();

export const HealthPlanProvider = ({ children }) => {
    const [healthPlan, setHealthPlan] = useState({});
    const [healthPlans, setHealthPlans] = useState([]);

    const getHealthPlans = async () => {
        try {
            const response = await axios.get(`${api}/managehealthplan/gethealthplans`);
            setHealthPlans(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const value = { healthPlan, setHealthPlan, healthPlans, getHealthPlans, setHealthPlans };

    return <HealthPlanContext.Provider value={value}>{children}</HealthPlanContext.Provider>;
};

export const useHealthPlanContext = () => React.useContext(HealthPlanContext);
