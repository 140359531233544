import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../hooks/populationContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { configureStackedBarChartWithoutLegend } from '../../ChartConfiguration/ChartConfiguration';
import { getDepressionFollowUpSummary } from '../HedisMetricService';
import DepressionFollowUpSummary from '../../../Models/HedisMetrics/DepressionFollowUpSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import FilterOptions from '../FilterOptions';
import '../../Widgets/Widgets.css';

const DepressionFollowUpSummaries = () => {
    const history = useNavigate();
    const { populations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [depressionFollowUpSummary, setDepressionFollowUpSummary] = useState<DepressionFollowUpSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const {
        depressionFollowUpPopulation,
        setDepressionFollowUpPopulation,
        depressionFollowUpTeam,
        setDepressionFollowUpTeam,
        depressionFollowUpDateFilter,
        setDepressionFollowUpDateFilter,
        depressionFollowUpFromDate,
        setDepressionFollowUpFromDate,
        depressionFollowUpToDate,
        setDepressionFollowUpToDate,
        depressionFollowUpIncludePastOpenStatus,
        setDepressionFollowUpIncludePastOpenStatus,
        depressionFollowUpShowInActiveTeam,
        setDepressionFollowUpShowInActiveTeam,
        setHedisType,
        setPop,
        setTeam,
        setFilter,
        setFDate,
        setTDate,
        setIncludePastOpenStatus,
        setShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartWithoutLegend(
                    'Depression Follow Up',
                    'Documented vs Not Documented',
                    'Population',
                    'Count',
                    [{ name: '', data: [0] }],
                    [0]
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (
            isPopulationFetched &&
            (depressionFollowUpDateFilter !== TimePeriodFilterEnum.Custom || (Boolean(depressionFollowUpFromDate) && Boolean(depressionFollowUpToDate)))
        ) {
            const changeFilter = async () => {
                let start = depressionFollowUpFromDate !== null ? new Date(depressionFollowUpFromDate).toDateString() : null;
                let end = depressionFollowUpToDate !== null ? new Date(depressionFollowUpToDate).toDateString() : null;

                const data = await getDepressionFollowUpSummary(
                    depressionFollowUpPopulation,
                    depressionFollowUpTeam,
                    depressionFollowUpDateFilter,
                    start,
                    end,
                    depressionFollowUpIncludePastOpenStatus,
                    depressionFollowUpShowInActiveTeam
                );
                setDepressionFollowUpSummary(data);

                setIsPrepared(true);
            };
            if ((depressionFollowUpFromDate !== null || depressionFollowUpToDate !== null) && depressionFollowUpToDate < depressionFollowUpFromDate) {
            } else {
                setChartOptions(
                    configureStackedBarChartWithoutLegend(
                        'Depression Follow Up',
                        'Documented vs Not Documented',
                        'Population',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        depressionFollowUpPopulation,
        depressionFollowUpTeam,
        depressionFollowUpDateFilter,
        depressionFollowUpFromDate,
        depressionFollowUpToDate,
        isPopulationFetched,
        depressionFollowUpIncludePastOpenStatus,
        depressionFollowUpShowInActiveTeam,
    ]);

    useEffect(() => {
        if (depressionFollowUpSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            let yVal;

            yValues = [];
            yVal = {
                y: depressionFollowUpSummary.Documented,
                name: 'Documented',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Documented', data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });

            yValues = [];
            yVal = {
                y: depressionFollowUpSummary.NotDocumented,
                name: 'Not Documented',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Documented', data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });

            if (depressionFollowUpPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = populations.find((p) => p.value === depressionFollowUpPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(configureStackedBarChartWithoutLegend('Depression Follow Up', '', '', 'Count', yAxisValues, xAxisValues, navigateToMembers));

            setIsGraphShow(true);
            setIsPrepared(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depressionFollowUpSummary, depressionFollowUpPopulation, populations, isPrepared]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToMembers = () => {
        setPop(depressionFollowUpPopulation);
        setTeam(depressionFollowUpTeam);
        setFilter(depressionFollowUpDateFilter);
        setFDate(depressionFollowUpFromDate);
        setTDate(depressionFollowUpToDate);
        setIncludePastOpenStatus(depressionFollowUpIncludePastOpenStatus);
        setShowInActiveTeam(depressionFollowUpShowInActiveTeam);
        setHedisType(HedisMetricsTypeEnum.DepressionFollowUp);
        history(`/provider/metricsummary/hedismemberdetail/DepressionFollowUp`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            Depression Follow Up
                        </Typography>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">For members aged greater than 12 with a PHQ9{'>'}14 in the rolling 6 month time frame</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Documented :
                                    </Typography>

                                    <Typography variant="caption">
                                        Had an encounter with a CPT code of G8431 within 30 days of the documented PHQ9 score
                                    </Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Documented :
                                    </Typography>
                                    <Typography variant="caption">No CPT codes recorded</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FilterOptions
                        selectedTeam={depressionFollowUpTeam}
                        setSelectedTeam={setDepressionFollowUpTeam}
                        dateFilter={depressionFollowUpDateFilter}
                        setDateFilter={setDepressionFollowUpDateFilter}
                        fromDate={depressionFollowUpFromDate}
                        setFromDate={setDepressionFollowUpFromDate}
                        toDate={depressionFollowUpToDate}
                        setToDate={setDepressionFollowUpToDate}
                        population={depressionFollowUpPopulation}
                        setPopulation={setDepressionFollowUpPopulation}
                        includePastOpenStatusMembers={depressionFollowUpIncludePastOpenStatus}
                        setIncludePastOpenStatusMembers={setDepressionFollowUpIncludePastOpenStatus}
                        hedisType={HedisMetricsTypeEnum.DepressionFollowUp}
                        showInActiveTeams={depressionFollowUpShowInActiveTeam}
                        setShowInActiveTeams={setDepressionFollowUpShowInActiveTeam}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default DepressionFollowUpSummaries;
