import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    IconButton,
    Tooltip,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { parentUrl } from '../../../../utils/constants';
import { apiString as API_URL } from '../../../../utils/constants';
import { localDateFormat } from '../../../../utils/timeFormat';
import { RootState } from '../../../../reducers';
import { useSelector } from 'react-redux';
import WidgetLoading from '../../Shared/WidgetLoading';
import Authorization from '../../../../utils/Authorization';
import { WidgetTimeFilterEnum } from '../../../../Enum/WidgetTimeFilterEnum';

const ERAdmissions = (props) => {
    const authData = new Authorization();
    const ref = useRef(null);
    const { teams } = useSelector((state: RootState) => state.teams);
    const [erAdmissions, setERAdmissions] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(Boolean(authData.TeamId) ? authData.TeamId : 'All');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<WidgetTimeFilterEnum>(WidgetTimeFilterEnum.Last15Days);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [show, setShow] = useState(false);
    const [showInActiveTeams, setShowInActiveTeams] = useState(false);
    const filteredTeams = (showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];

    const dayFilterList = [
        { Id: WidgetTimeFilterEnum.Last15Days, Value: WidgetTimeFilterEnum.Last15DaysDescription },
        { Id: WidgetTimeFilterEnum.Last30Days, Value: WidgetTimeFilterEnum.Last30DaysDescription },
        { Id: WidgetTimeFilterEnum.Last60Days, Value: WidgetTimeFilterEnum.Last60DaysDescription },
        { Id: WidgetTimeFilterEnum.Custom, Value: WidgetTimeFilterEnum.CustomDescription },
    ];

    const handleClick = (event) => {
        setShow(!show);
    };

    const openDocumentHandler = (memberId: string) => {
        window.open(`${parentUrl}provider/members-list/member-chart/${memberId}/hie`, '_blank');
    };

    useEffect(() => {
        if (selectedFilter !== WidgetTimeFilterEnum.Custom || (Boolean(fromDate) && Boolean(toDate))) {
            setIsLoading(true);
            const changeFilter = async () => {
                setIsLoading(true);
                let dateFrom = Boolean(fromDate) && selectedFilter === WidgetTimeFilterEnum.Custom ? new Date(fromDate).toISOString() : '';
                let dateTo = Boolean(toDate) && selectedFilter === WidgetTimeFilterEnum.Custom ? new Date(toDate).toISOString() : '';
                const response = await axios.get(
                    `${API_URL}/hie/patientsasmittedinpastdays?teamId=${selectedTeam}&timeFilter=${selectedFilter}&dateFrom=${dateFrom}&dateTo=${dateTo}&includeInActiveTeams=${showInActiveTeams}`
                );
                setERAdmissions(response.data);
                setIsLoading(false);
            };
            changeFilter();
        }

        if (selectedFilter !== WidgetTimeFilterEnum.Custom && Boolean(fromDate) && Boolean(toDate)) {
            setFromDate(null);
            setToDate(null);
        }
    }, [selectedTeam, setERAdmissions, setIsLoading, selectedFilter, fromDate, toDate, showInActiveTeams]);

    const changeFromDate = (date: any) => {
        setFromDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(toDate) || new Date(toDate) <= dateInfo) {
            let endDate = dateInfo.setDate(dateInfo.getDate() + 30);
            setToDate(endDate);
        }
    };

    const changeToDate = (date: any) => {
        setToDate(date);
        let dateInfo = new Date(date);
        if (!Boolean(fromDate) || new Date(fromDate) >= dateInfo) {
            let startDate = dateInfo.setDate(dateInfo.getDate() - 30);
            setFromDate(startDate);
        }
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        setShowInActiveTeams(isChecked);
        setSelectedTeam('All');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'}>
                                ER Admissions
                            </Typography>
                            <Typography display={'inline-block'} variant="body1" color={'primary'} ref={ref}>
                                &#40;{erAdmissions.length}&#41;
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography variant="caption">Members in ER admissions in date range</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        {props.showProviders && (
                            <Grid item marginTop={2} xs={3}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel>Team</InputLabel>
                                    <Select
                                        value={selectedTeam}
                                        label="Team"
                                        name="Team"
                                        onChange={(e) => setSelectedTeam(e.target.value)}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                                    >
                                        <MenuItem key="All" value="All">
                                            All
                                        </MenuItem>
                                        <FormControlLabel
                                            sx={{ pl: '12px', mb: '0px' }}
                                            control={<Checkbox checked={showInActiveTeams} onChange={(e) => toggleTeamHandler(e.target.checked)} />}
                                            label="Show Inactive"
                                        />
                                        {filteredTeams.map((team) => (
                                            <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                                {team.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item marginTop={2} xs={3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={selectedFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setSelectedFilter(parseInt(e.target.value as any))}
                                >
                                    {dayFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {selectedFilter === WidgetTimeFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item marginTop={2} md={3}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={Boolean(fromDate) ? fromDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item marginTop={2} md={3}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={Boolean(toDate) ? toDate : null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid
                            container
                            spacing={2}
                            marginTop={2}
                            marginLeft={0.1}
                            height={props.showProviders ? '50vh' : '56vh'}
                            sx={{ overflowY: 'scroll', position: 'relative' }}
                        >
                            {isLoading && <WidgetLoading />}
                            {erAdmissions.length > 0 ? (
                                erAdmissions.map((member) => {
                                    return (
                                        <Grid key={member.Id} item xs={6}>
                                            <Card
                                                sx={{
                                                    ':hover': {
                                                        cursor: 'pointer',
                                                        boxShadow: 10,
                                                        border: 0,
                                                    },
                                                    boxShadow: 0,
                                                }}
                                                onClick={() => openDocumentHandler(member.MemberId)}
                                                variant="outlined"
                                            >
                                                <CardContent
                                                    sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-start', justifyContent: 'space-between' }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Name
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2">
                                                                {`${member.MemberFirstName} ${member.MemberLastName}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                DOB
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2">
                                                                {localDateFormat(new Date(member.MemberDOB))}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Location
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2">
                                                                {member.Location}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Admission Date
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2">
                                                                {localDateFormat(new Date(member.AdmissionDate))}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color="text.secondary" variant="body2">
                                                                Discharge Date
                                                            </Typography>
                                                            <Typography fontWeight={'bold'} variant="body2">
                                                                {member.DischargeDate ? localDateFormat(new Date(member.DischargeDate)) : '-'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Grid item xs={12} textAlign="center">
                                    <Typography variant="h6">No Matching Records</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default ERAdmissions;
