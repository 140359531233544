import { AnswerOptionsEnum } from '../../Enum/AnswerOptionsEnum';
import { HealthEvaluationResponseEnum } from '../../Enum/HealthEvaluationResponseEnum';
import { StatusEnum } from '../../Enum/StatusEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IAdditionalCHPCriteria extends BaseEntity {
    MemberId: string;
    LastSixMonthHealthEvaluation?: LastSixMonthHealthEvaluation;
    EDVisitsInLastFourMonths?: EDVisitsInLastFourMonths;
    HospitalizedInLastFourMonths?: HospitalizedInLastFourMonths;
    Status: StatusEnum;
}

export class AdditionalCHPCriteria implements IAdditionalCHPCriteria {
    Id: string;
    MemberId: string;
    LastSixMonthHealthEvaluation?: LastSixMonthHealthEvaluation;
    EDVisitsInLastFourMonths?: EDVisitsInLastFourMonths;
    HospitalizedInLastFourMonths?: HospitalizedInLastFourMonths;
    Notes: string;
    Status: StatusEnum;

    constructor() {
        this.MemberId = '';
    }
}

export class LastSixMonthHealthEvaluation {
    Response: HealthEvaluationResponseEnum;
    Reason: string;

    constructor() {
        this.Reason = '';
    }
}

export class EDVisitsInLastFourMonths {
    Response: AnswerOptionsEnum;
    NumberOfVisits?: number;

    constructor() {
        this.NumberOfVisits = null;
    }
}

export class HospitalizedInLastFourMonths {
    Response: AnswerOptionsEnum;
    NumberOfVisits?: number;

    constructor() {
        this.NumberOfVisits = null;
    }
}
