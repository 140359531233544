import { Avatar, Box, Chip, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { isDefaultDate } from '../../../utils/common';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import InfoIcon from '@mui/icons-material/Info';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { hedisMetricsList, setShowNotes } from '../../../store/memberDemographicsDetails.slice';
import MemberNoteDialog from '../MemberNotes/MemberNotes';
import { PopulationEnrollmentTagEnum } from '../../../Enum/PopulationEnrollmentTagEnum';

const MemberHeader = (props) => {
    const { showDetails, hideDetailsHandler, memberId } = props;
    const { allMemberNotes, chartDetails, preferredPronouns } = useSelector((state: RootState) => state.memberDemographicsDetails);
    const dispatch = useDispatch();

    const memberTagsExisting = Boolean(chartDetails?.Tags) && chartDetails?.Tags.length > 0 ? true : false;
    const fullProgramReferralTag = memberTagsExisting
        ? chartDetails?.Tags.find((t) => t.Name === PopulationEnrollmentTagEnum.FullProgramReferralDescription)
        : '';
    const hraReferralTag = memberTagsExisting ? chartDetails?.Tags.find((t) => t.Name === PopulationEnrollmentTagEnum.HRAReferralDescription) : '';

    const hasNote = allMemberNotes.length > 0;
    const hasImportantNote = allMemberNotes.filter((note) => note.WarningFlag).length > 0;

    const getExpiredHedis = () => {
        const expiredHedis = [];
        hedisMetricsList.map((item) => {
            return chartDetails[item.name]?.IsExpired && expiredHedis.push(item);
        });
        return expiredHedis.length;
    };

    const openNoteHandler = () => {
        dispatch(setShowNotes(true));
    };

    const [showFullProgramReferral, setShowFullProgramReferral] = useState(false);
    const [showHraReferral, setShowHraReferral] = useState(false);

    const fullProgramReferralClick = (event) => {
        setShowFullProgramReferral(!showFullProgramReferral);
    };

    const hraReferralClick = (event) => {
        setShowHraReferral(!showHraReferral);
    };

    return (
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
            <MemberNoteDialog memberId={memberId} />
            <Stack direction={'row'} alignItems={'center'}>
                <Avatar
                    alt={chartDetails?.FirstName + ' ' + chartDetails?.LastName}
                    src={chartDetails?.Photo}
                    sx={{ width: 46, height: 46, marginRight: 2 }}
                    className="profile-image"
                />
                {chartDetails?.PreferredName ? (
                    <Stack textAlign={'center'}>
                        <Typography variant="h5" fontWeight={'bold'}>
                            {chartDetails?.PreferredName}
                        </Typography>
                        <Typography variant="body2" fontWeight={'bold'}>
                            {`(${chartDetails?.FirstName || ''} ${chartDetails?.LastName || ''})`}
                        </Typography>
                    </Stack>
                ) : (
                    <Stack textAlign={'center'}>
                        <Typography variant="h6" fontWeight={'bold'} marginLeft={1}>
                            {`${chartDetails?.FirstName || ''} ${chartDetails?.LastName || ''}`}
                        </Typography>
                    </Stack>
                )}
                <Stack textAlign={'center'} marginLeft={2}>
                    <Typography variant="body2" sx={{ color: hasImportantNote ? 'error.main' : 'text.secondary' }}>
                        NOTES:
                    </Typography>
                    <Tooltip title="View notes" placement="bottom">
                        <Stack
                            direction={'row'}
                            sx={{
                                color: hasImportantNote ? 'error.main' : hasNote ? 'primary.main' : 'rgba(0,0,0,0.4)',
                                cursor: 'pointer',
                            }}
                            alignItems={'center'}
                            spacing={1}
                            onClick={openNoteHandler}
                        >
                            {allMemberNotes.length > 0 ? (
                                <Typography variant="body2" fontWeight={'bold'}>
                                    {moment(allMemberNotes[0].EditedTime).format('MM/DD/yyyy')}
                                </Typography>
                            ) : (
                                <Typography variant="body2" textAlign={'center'} fontWeight={'bold'}>
                                    Add Note
                                </Typography>
                            )}
                        </Stack>
                    </Tooltip>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                {chartDetails?.PreferredPronouns !== 0 && (
                    <>
                        <Stack textAlign={'center'}>
                            <Typography variant="body2" color={'text.secondary'}>
                                PRONOUNS:
                            </Typography>
                            <Typography variant="body1" fontWeight={'bold'}>
                                {chartDetails?.PreferredPronouns !== 4
                                    ? preferredPronouns.find((p) => p.value === chartDetails?.PreferredPronouns?.toString())?.label
                                    : chartDetails?.PreferredPronounsOther}
                            </Typography>
                        </Stack>
                        <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                    </>
                )}
                <Stack textAlign={'center'}>
                    <Typography variant="body2" color={'text.secondary'}>
                        DOB:
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                        <Typography variant="body2" fontWeight={'bold'}>
                            {!isDefaultDate(chartDetails?.DOB) ? moment(chartDetails?.DOB).format('MM/DD/yyyy') : ''}
                        </Typography>
                        <Typography variant="body2" fontWeight={'bold'}>
                            &#40;{chartDetails?.Age} yrs&#41;
                        </Typography>
                    </Stack>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                <Stack textAlign={'center'}>
                    <Typography variant="body2" color={'text.secondary'}>
                        AHCCCS ID:
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                        <Typography variant="body2" fontWeight={'bold'}>
                            {chartDetails?.Mem_AHCCCS_ID}
                        </Typography>
                    </Stack>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                <Stack textAlign={'center'}>
                    <Typography variant="body2" color={'text.secondary'}>
                        MEMBER ID:
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                        <Typography variant="body2" fontWeight={'bold'}>
                            {chartDetails?.MemberId}
                        </Typography>
                    </Stack>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                {getExpiredHedis() > 0 && (
                    <>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                            <Typography color={'error.main'} variant="body2" fontWeight={'bold'}>
                                # EXPIRED HEDIS METRICS:
                            </Typography>
                            <Typography color={'error'} variant="h6" fontWeight={'bold'}>
                                {getExpiredHedis()}
                            </Typography>
                        </Stack>
                        <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />
                    </>
                )}
            </Stack>

            <Stack direction={'row'} alignItems={'center'}>
                {(chartDetails.IsInTransition ||
                    chartDetails.IsInFuhFum ||
                    chartDetails.IsInHra ||
                    chartDetails.IsInSUD ||
                    chartDetails.HasCloded ||
                    memberTagsExisting) && (
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} marginLeft={'auto'}>
                        {chartDetails.IsInTransition && <Chip label="Transitional Care" variant="filled" color="error" />}
                        {chartDetails.IsInFuhFum && <Chip label="FUHFUM" variant="filled" color="primary" />}
                        {chartDetails.IsInHra && <Chip label="HRA" variant="filled" color="success" />}
                        {chartDetails.HasCloned && <Chip label="Has Cloned" variant="filled" color="secondary" />}
                        {chartDetails.IsInSUD && <Chip label="SUD" variant="filled" style={{ backgroundColor: '#00877A', color: 'white' }} />}
                        {Boolean(fullProgramReferralTag) && (
                            <Tooltip
                                open={showFullProgramReferral}
                                title={
                                    <div>
                                        <Typography variant="caption">Member was already referred to the full program.</Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 10000, cursor: 'pointer' } }}
                            >
                                <Chip
                                    label="Full Program Referral"
                                    variant="filled"
                                    style={{ backgroundColor: '#d91a5d', color: 'white', cursor: 'pointer' }}
                                    deleteIcon={
                                        <IconButton onClick={fullProgramReferralClick} style={{ padding: 0, cursor: 'pointer' }}>
                                            <InfoIcon sx={{ width: '18px', height: '18px', color: 'white', cursor: 'pointer' }} />
                                        </IconButton>
                                    }
                                    onDelete={fullProgramReferralClick}
                                />
                            </Tooltip>
                        )}
                        {Boolean(hraReferralTag) && (
                            <Tooltip
                                open={showHraReferral}
                                title={
                                    <div>
                                        <Typography variant="caption">
                                            {chartDetails.IsInFuhFum
                                                ? 'FUHFUM member have another HRA referral to be worked on and a chart to be created after the FUHFUM is completed.'
                                                : 'Member have another HRA referral.'}
                                        </Typography>
                                    </div>
                                }
                                PopperProps={{ style: { zIndex: 10000, cursor: 'pointer' } }}
                            >
                                <Chip
                                    label="HRA Referral"
                                    variant="filled"
                                    style={{ backgroundColor: '#00a152', color: 'white', cursor: 'pointer' }}
                                    deleteIcon={
                                        <IconButton onClick={hraReferralClick} style={{ padding: 0, cursor: 'pointer' }}>
                                            <InfoIcon sx={{ width: '18px', height: '18px', color: 'white', cursor: 'pointer' }} />
                                        </IconButton>
                                    }
                                    onDelete={hraReferralClick}
                                />
                            </Tooltip>
                        )}
                    </Stack>
                )}
                <Stack alignItems={'center'} justifyContent={'space-between'} spacing={0} marginLeft={5}>
                    <Typography variant="body2" color={'text.secondary'}>
                        CHP Status:
                    </Typography>
                    <Typography variant="body2" fontWeight={'bold'}>
                        {MemberStatusEnum[chartDetails?.Status]}
                    </Typography>
                </Stack>
                {chartDetails?.Eligibility && (
                    <Stack alignItems={'center'} justifyContent={'space-between'} spacing={0} marginLeft={2}>
                        <Typography variant="body2" color={'text.secondary'}>
                            Eligibility:
                        </Typography>
                        <Typography variant="body2" fontWeight={'bold'}>
                            {chartDetails?.Eligibility}
                        </Typography>
                    </Stack>
                )}
                {showDetails ? (
                    <Tooltip title="Show/Hide" placement="bottom">
                        <KeyboardDoubleArrowDownIcon sx={{ marginLeft: 2, cursor: 'pointer' }} onClick={hideDetailsHandler} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Show/Hide" placement="bottom">
                        <KeyboardDoubleArrowUpIcon sx={{ marginLeft: 2, cursor: 'pointer' }} onClick={hideDetailsHandler} />
                    </Tooltip>
                )}
            </Stack>
        </Box>
    );
};

export default MemberHeader;
