import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';

import { DocumentOnboarding } from '../Models/DocumentOnboardings/DocumentOnboarding.model';

const documentOnboardingSlice = createSlice({
    name: 'documentOnboarding',
    initialState: {
        documentOnboardings: [] as DocumentOnboarding[],
        documentOnboarding: new DocumentOnboarding(),
        showDocumentOnboardingModal: false,
        isFetchingList: false,
        isFetching: false,
        isSaving: false,
    },
    reducers: {
        _setDocumentOnboardings(state, action) {
            state.documentOnboardings = action.payload;
        },
        _setDocumentOnboarding(state, action) {
            state.documentOnboarding = action.payload;
        },
        _toggleShowDocumentOnboardingModal(state, action) {
            state.showDocumentOnboardingModal = action.payload;
        },
        _toggleIsFetchingList(state, action) {
            state.isFetchingList = action.payload;
        },
        _toggleIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        _toggleIsSaving(state, action) {
            state.isSaving = action.payload;
        },
    },
});

const { _setDocumentOnboardings, _setDocumentOnboarding, _toggleShowDocumentOnboardingModal, _toggleIsFetchingList, _toggleIsFetching, _toggleIsSaving } =
    documentOnboardingSlice.actions;

export const getDocumentOnboardingByMemberId =
    (memberId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetchingList(true));
        axios
            .get(`${API_URL}/documentonboarding/getdocumentonboardingbymemberid`, { params: { memberId } })
            .then((response) => {
                dispatch(_setDocumentOnboardings(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsFetchingList(false));
            });
    };

export const saveDocumentOnboarding =
    (documentOnboarding: DocumentOnboarding): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsSaving(true));

        const request = Boolean(documentOnboarding.Id)
            ? axios.put(`${API_URL}/documentonboarding/updatedocumentonboarding`, documentOnboarding)
            : axios.post(`${API_URL}/documentonboarding/insertdocumentonboarding`, documentOnboarding);
        request
            .then((response) => {
                dispatch(_toggleShowDocumentOnboardingModal(false));
                dispatch(getDocumentOnboardingByMemberId(documentOnboarding.MemberId));
            })
            .finally(() => {
                dispatch(_toggleIsSaving(false));
            });
    };

export const getDocumentOnboardingById =
    (id: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_toggleIsFetching(true));
        axios
            .get(`${API_URL}/documentonboarding/getdocumentonboardingbyid`, { params: { id } })
            .then((response) => {
                dispatch(_setDocumentOnboarding(response.data));
            })
            .finally(() => {
                dispatch(_toggleIsFetching(false));
            });
    };

export const toggleShowDocumentOnboardingModal =
    (show: boolean, documentOnboardingId = ''): AppThunk =>
    (dispatch, getState) => {
        dispatch(_toggleShowDocumentOnboardingModal(show));
        if (Boolean(documentOnboardingId)) {
            dispatch(getDocumentOnboardingById(documentOnboardingId));
        } else {
            dispatch(_setDocumentOnboarding(new DocumentOnboarding()));
        }
    };

export default documentOnboardingSlice.reducer;
