import { IMapping, IRequestStatus } from '../interfaces';

export const requestStatusMapping: IMapping<IRequestStatus> = {
    New: {
        value: 1,
        label: 'New',
    },
    InProgress: {
        value: 2,
        label: 'In Progress',
    },
    Completed: {
        value: 3,
        label: 'Completed',
    },
    Archived: {
        value: 4,
        label: 'Archived',
    },
    ClosedWithNoAction: {
        value: 5,
        label: 'Closed With No Action',
    },
};
