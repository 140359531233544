import {
    Alert,
    Button,
    Chip,
    Divider,
    FormControl,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import {
    getActiveProviders,
    getBHAssessmentSettings,
    getBasicSettings,
    getProviderPreview,
    resetAssessments,
    saveBHAssessmentSettings,
    saveBillingSettings,
    saveCallSettings,
    saveEligibilityLoginSetting,
    saveTabletSettings,
    setBasicSetting,
    setSelectedBHProvider,
    setShowBasicSettingsMessage,
    updateHraReferralRequestProvider,
    UpdateHraBhFollowUpRequestProvider,
} from '../../../../store/providerNavbar.slice';
import { useEffect } from 'react';
import { MenuProps } from '../../../Widgets/WidgetService';
import { states } from '../../../../utils/constants';
import TextMaskCustom from '../../../Shared/PhoneMask';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BasicSettings = (props) => {
    const dispatch = useDispatch();
    const { basicSettings, basicSettingsMessage, messageSeverity, providerPreviews, selectedBHProvider, showBasicSettingsMessage } = useSelector(
        (state: RootState) => state.providerNavbar
    );
    const { isLoading } = useSelector((state: RootState) => state.shared);
    const navigate = useNavigate();

    const handleChange = (event: SelectChangeEvent<typeof selectedBHProvider>) => {
        const {
            target: { value },
        } = event;
        dispatch(
            setSelectedBHProvider(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value
            )
        );
    };

    const handleCloseMessage = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setShowBasicSettingsMessage(false));
    };

    const saveCallSettingsHandler = () => {
        dispatch(saveCallSettings(basicSettings.CallSetting.COId, basicSettings.CallSetting.COName));
    };

    const saveTabletSettingsHandler = () => {
        dispatch(saveTabletSettings(basicSettings.TabletSetting.TabletHelpEmail));
    };

    const saveHraReferralRequestHandler = () => {
        dispatch(updateHraReferralRequestProvider(basicSettings.HraReferralRequestProvider));
    };

    const saveHraBhFollowUpRequestHandler = () => {
        dispatch(UpdateHraBhFollowUpRequestProvider(basicSettings.HraBhFollowUpRequestProvider));
    };

    const changeEligibilityLoginHandler = () => {
        dispatch(saveEligibilityLoginSetting());
    };

    const saveBillingSettingsHandler = () => {
        dispatch(saveBillingSettings());
    };

    const saveBHAssessmentHandler = () => {
        const providers = providerPreviews.filter((el) => selectedBHProvider.includes(el.Id));
        dispatch(saveBHAssessmentSettings(providers));
    };

    const resetAssessmentsHandler = () => {
        dispatch(resetAssessments());
    };

    useEffect(() => {
        dispatch(getActiveProviders());
        dispatch(getProviderPreview());
        dispatch(getBasicSettings());
        dispatch(getBHAssessmentSettings());
    }, [dispatch]);

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showBasicSettingsMessage}
                autoHideDuration={3000}
                onClose={handleCloseMessage}
            >
                <Alert
                    sx={{ width: '100%' }}
                    variant="filled"
                    elevation={6}
                    severity={messageSeverity === 'success' ? 'success' : 'error'}
                    onClose={handleCloseMessage}
                >
                    {basicSettingsMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems={'center'} spacing={2}>
                        <IconButton sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6">Basic Settings</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Call Settings" />
                        </Divider>
                        <FormControl fullWidth sx={{ marginTop: '16px' }}>
                            <InputLabel id="incoming-outgoing-caller-label">Incoming/Outgoing Caller</InputLabel>
                            <Select
                                fullWidth
                                disabled={isLoading ? true : false}
                                label={'Incoming/Outgoing Caller'}
                                value={providerPreviews.find((el) => el.Id === basicSettings.CallSetting?.COId)?.Id || ''}
                                onChange={(e) => {
                                    dispatch(
                                        setBasicSetting({
                                            ...basicSettings,
                                            CallSetting: { ...basicSettings.CallSetting, COId: e.target.value },
                                        })
                                    );
                                }}
                                MenuProps={MenuProps}
                            >
                                {providerPreviews.map((provider) => (
                                    <MenuItem key={provider.Id} value={provider.Id}>
                                        {provider.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            disabled={isLoading ? true : false}
                            variant="contained"
                            sx={{ marginTop: 2, minWidth: '120px' }}
                            onClick={saveCallSettingsHandler}
                        >
                            Save
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Tablet Settings" />
                        </Divider>
                        <FormControl fullWidth sx={{ marginTop: '16px' }}>
                            <TextField
                                fullWidth
                                required
                                disabled={isLoading ? true : false}
                                error={basicSettings.TabletSetting?.TabletHelpEmail === ''}
                                helperText={basicSettings.TabletSetting?.TabletHelpEmail === '' ? 'Required' : ''}
                                label="Tablet Help Email"
                                type="email"
                                value={basicSettings.TabletSetting?.TabletHelpEmail || ''}
                                onChange={(e) => {
                                    dispatch(
                                        setBasicSetting({
                                            ...basicSettings,
                                            TabletSetting: { ...basicSettings.TabletSetting, TabletHelpEmail: e.target.value },
                                        })
                                    );
                                }}
                            />
                        </FormControl>
                        <Button
                            disabled={isLoading ? true : false}
                            variant="contained"
                            sx={{ marginTop: 2, minWidth: '120px' }}
                            onClick={saveTabletSettingsHandler}
                        >
                            Save
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={4}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Change HealthCare Eligibility Login" />
                        </Divider>
                        <FormControl fullWidth sx={{ marginTop: '16px' }}>
                            <Stack spacing={2}>
                                <TextField
                                    fullWidth
                                    required
                                    label="User Name"
                                    value={basicSettings.EligibilityLoginSetting?.UserName || ''}
                                    onChange={(e) => {
                                        dispatch(
                                            setBasicSetting({
                                                ...basicSettings,
                                                EligibilityLoginSetting: { ...basicSettings.EligibilityLoginSetting, UserName: e.target.value },
                                            })
                                        );
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    required
                                    label="Password"
                                    value={basicSettings.EligibilityLoginSetting?.Password || ''}
                                    type="password"
                                    onChange={(e) => {
                                        dispatch(
                                            setBasicSetting({
                                                ...basicSettings,
                                                EligibilityLoginSetting: { ...basicSettings.EligibilityLoginSetting, Password: e.target.value },
                                            })
                                        );
                                    }}
                                />
                            </Stack>
                        </FormControl>
                        <Button
                            disabled={isLoading ? true : false}
                            variant="contained"
                            sx={{ marginTop: 2, minWidth: '120px' }}
                            onClick={changeEligibilityLoginHandler}
                        >
                            Save
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={3}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'center' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="HRA Assessment Settings" />
                        </Divider>
                        <Button disabled={isLoading ? true : false} variant="contained" sx={{ marginTop: 2 }} onClick={resetAssessmentsHandler}>
                            Reset Assessments
                        </Button>
                    </Paper>
                </Grid>

                <Grid item>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'center' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="BH Assessment Settings" />
                        </Divider>
                        <Stack spacing={2}>
                            <Typography variant="body1">Providers with Edit Access</Typography>
                            <FormControl sx={{ marginTop: '16px', minWidth: '350px' }}>
                                <InputLabel id="incoming-outgoing-caller-label">Select Provider</InputLabel>
                                <Select fullWidth multiple label={'Select Provider'} value={selectedBHProvider} onChange={handleChange} MenuProps={MenuProps}>
                                    {providerPreviews.map((provider) => (
                                        <MenuItem key={provider.Id} value={provider.Id}>
                                            {provider.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button disabled={isLoading ? true : false} variant="contained" sx={{ width: '120px' }} onClick={saveBHAssessmentHandler}>
                                Save
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>

                <Grid item xs={3}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="HRA Referral To Full CHP Program" />
                        </Divider>
                        <FormControl fullWidth sx={{ marginTop: '16px' }}>
                        <InputLabel id="request-provider-label">Select Provider</InputLabel>
                            <Select
                                fullWidth
                                disabled={isLoading ? true : false}
                                label={'Select Provider'}
                                value={providerPreviews.find((el) => el.Id === basicSettings.HraReferralRequestProvider)?.Id || ''}
                                onChange={(e) => {
                                    dispatch(
                                        setBasicSetting({
                                            ...basicSettings,
                                            HraReferralRequestProvider: e.target.value,
                                        })
                                    );
                                }}
                                MenuProps={MenuProps}
                            >
                                {providerPreviews.map((provider) => (
                                    <MenuItem key={provider.Id} value={provider.Id}>
                                        {provider.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            disabled={isLoading ? true : false}
                            variant="contained"
                            sx={{ marginTop: 2, minWidth: '120px' }}
                            onClick={saveHraReferralRequestHandler}
                        >
                            Save
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={3}>
                    <Paper elevation={3} style={{ padding: '8px', margin: '16px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="HRA BH Follow Up" />
                        </Divider>
                        <FormControl fullWidth sx={{ marginTop: '16px' }}>
                        <InputLabel id="request-provider1-label">Select Provider</InputLabel>
                            <Select
                                fullWidth
                                disabled={isLoading ? true : false}
                                label={'Select Provider'}
                                value={providerPreviews.find((el) => el.Id === basicSettings.HraBhFollowUpRequestProvider)?.Id || ''}
                                onChange={(e) => {
                                    dispatch(
                                        setBasicSetting({
                                            ...basicSettings,
                                            HraBhFollowUpRequestProvider: e.target.value,
                                        })
                                    );
                                }}
                                MenuProps={MenuProps}
                            >
                                {providerPreviews.map((provider) => (
                                    <MenuItem key={provider.Id} value={provider.Id}>
                                        {provider.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            disabled={isLoading ? true : false}
                            variant="contained"
                            sx={{ marginTop: 2, minWidth: '120px' }}
                            onClick={saveHraBhFollowUpRequestHandler}
                        >
                            Save
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} marginBottom={2}>
                    <Typography variant="body1" fontWeight="bold" marginBottom={2}>
                        Encounter Billing Settings
                    </Typography>
                    <Paper elevation={3} style={{ padding: '8px', textAlign: 'left' }}>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="837P Header" />
                        </Divider>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Sender ID"
                                    value={basicSettings.BillingSetting?.HeaderSetting?.SenderId || ''}
                                    onChange={(e) => {
                                        dispatch(
                                            setBasicSetting({
                                                ...basicSettings,
                                                BillingSetting: {
                                                    ...basicSettings.BillingSetting,
                                                    HeaderSetting: { ...basicSettings.BillingSetting?.HeaderSetting, SenderId: e.target.value },
                                                },
                                            })
                                        );
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        <Divider style={{ marginBottom: '16px' }}>
                            <Chip label="Billing Provider" />
                        </Divider>
                        <Stack style={{ width: '100%' }} spacing={2}>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.Name || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            Name: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        label="Address"
                                        variant="outlined"
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.Address || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            Address: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="City"
                                        variant="outlined"
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.City || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            City: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="State"
                                            value={states.find((state) => state === basicSettings.BillingSetting?.BillingProviderSetting?.State) || ''}
                                            onChange={(e) => {
                                                dispatch(
                                                    setBasicSetting({
                                                        ...basicSettings,
                                                        BillingSetting: {
                                                            ...basicSettings.BillingSetting,
                                                            BillingProviderSetting: {
                                                                ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                                State: e.target.value,
                                                            },
                                                        },
                                                    })
                                                );
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {states.map(function (state) {
                                                return (
                                                    <MenuItem key={state} value={state}>
                                                        {state}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Zip Code"
                                        variant="outlined"
                                        type={'number'}
                                        inputProps={{ maxLength: 5 }}
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.PostalCode || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            PostalCode: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="NPI Number"
                                        variant="outlined"
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.NPINumber || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            NPINumber: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        label="Tax Identification Number"
                                        variant="outlined"
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.TaxIdentificationNumber || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            TaxIdentificationNumber: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        label="Contact Person Name"
                                        variant="outlined"
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.ContactPersonName || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            ContactPersonName: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                </Stack>
                            </FormGroup>
                            <FormGroup>
                                <Stack direction="row" spacing={2}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel htmlFor="formatted-text-mask-input" required>
                                            Contact Person Number
                                        </InputLabel>
                                        <OutlinedInput
                                            required
                                            value={basicSettings.BillingSetting?.BillingProviderSetting?.CommunicationNumber || ''}
                                            onChange={(e) => {
                                                dispatch(
                                                    setBasicSetting({
                                                        ...basicSettings,
                                                        BillingSetting: {
                                                            ...basicSettings.BillingSetting,
                                                            BillingProviderSetting: {
                                                                ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                                CommunicationNumber: e.target.value,
                                                            },
                                                        },
                                                    })
                                                );
                                            }}
                                            label="Contact Person Number"
                                            inputComponent={TextMaskCustom as any}
                                        />
                                    </FormControl>

                                    <TextField
                                        required
                                        fullWidth
                                        label="Communication Email"
                                        variant="outlined"
                                        value={basicSettings.BillingSetting?.BillingProviderSetting?.Email || ''}
                                        onChange={(e) => {
                                            dispatch(
                                                setBasicSetting({
                                                    ...basicSettings,
                                                    BillingSetting: {
                                                        ...basicSettings.BillingSetting,
                                                        BillingProviderSetting: {
                                                            ...basicSettings.BillingSetting?.BillingProviderSetting,
                                                            Email: e.target.value,
                                                        },
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                </Stack>
                            </FormGroup>
                        </Stack>
                        <Button
                            variant="contained"
                            disabled={isLoading ? true : false}
                            sx={{ marginTop: 2, minWidth: '120px' }}
                            onClick={saveBillingSettingsHandler}
                        >
                            Save
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default BasicSettings;
