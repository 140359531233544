export enum MemberStatusEnum {
    UndeterminedDescription = 'Undetermined',
    Undetermined = 1,
    ActiveDescription = 'Active',
    Active = 2,
    DisenrolledFromHealthPlanDescription = 'Disenrolled HealthPlan',
    DisenrolledFromHealthPlan = 3,
    RefusedToContinueServicesDescription = 'Refused To Continue Services',
    RefusedToContinueServices = 4,
    UnreachableDescription = 'Unreachable',
    Unreachable = 5,
    DeceasedDescription = 'Deceased',
    Deceased = 6,
    DisenrolledFromCHPDescription = 'Disenrolled From CHP',
    DisenrolledFromCHP = 7,
    RefusedToEnrollDescription = 'Refused To Enroll',
    RefusedToEnroll = 8,
    AssociateDescription = 'Associate',
    Associate = 9,
    OffBoardingDescription = 'OffBoarding',
    OffBoarding = 10,
    ScheduledDescription = 'Scheduled',
    Scheduled = 11,
    DisenrolledFromHRADescription = 'Disenrolled from HRA',
    DisenrolledFromHRA = 12,
    HRAInReviewDescription = 'HRA in Review',
    HRAInReview = 13,
    UncompensatedCareDescription = 'Uncompensated Care',
    UncompensatedCare = 14,
    SuspendedDescription = 'Suspended',
    Suspended = 16,
    FUHFUMInProgressDescription = 'FUHFUM In-Progress',
    FUHFUMInProgress = 17,
    FUHFUMCloseDescription = 'FUHFUM Close',
    FUHFUMClose = 18,
    FUHUndDescription = 'FUH-Und',
    FUHUnd = 19,
    HRAUndDescription = 'HRA-Und',
    HRAUnd = 20,
    FUHClosedBillableDescription = 'FUH Closed Billable',
    FUHClosedBillable = 21,
    FUHClosedUnbillableDescription = 'FUH Closed Unbillable',
    FUHClosedUnbillable = 22,
}