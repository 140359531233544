import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Shared/Loading';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fetchSurveyReportData, exportSurveyReport, setSelectedProviderIds, setReportData } from '../../../store/surveyReport.slice';
import Authorization from '../../../utils/Authorization';
import { getActiveProviders } from '../../../store/provider.slice';
import { localDateFormat } from '../../../utils/timeFormat';
import { copyObject } from '../../../utils/common';

const SurveyReport = () => {
    const dispatch = useDispatch();
    let sDate = new Date();
    const authData = new Authorization();
    const { reportData, selectedProviderIds } = useSelector((state: RootState) => state.surveyReport);
    const { downLoading } = useSelector((state: RootState) => state.shared);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const [startDate, setStartDate] = useState(new Date(sDate.getFullYear(), sDate.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [localProviders, setLocalProviders] = useState([]);
    const [showInActive, setShowInActive] = useState<boolean>(false);
    const copyProviders = copyObject(activeProviders);
    const filteredProviders = (showInActive ? copyProviders : copyProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const getQA = (question: any) => {
        return question ? (
            <div>
                <p>{question.QuestionText}</p>
                <br />
                {!question.IfNotMax && <p style={{ fontWeight: 'bold' }}>{question.Answer ? question.Answer : question.ScaleAnswer}</p>}
                {question.IfNotMax && <p style={{ fontWeight: 'bold' }}>{question.ScaleAnswer}</p>}
                {question.IfNotMax && (
                    <div>
                        <p>{question.IfNotMaxText}</p>
                        <p style={{ fontWeight: 'bold' }}>{question.Answer}</p>
                    </div>
                )}
            </div>
        ) : (
            <p>N/A</p>
        );
    };

    const columns: GridColDef[] = [
        {
            field: 'ApptDate',
            headerName: 'Appt Date',
            minWidth: 110,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${localDateFormat(new Date(params.row.ApptDate))}`,
        },
        {
            field: 'SurveyStatus',
            headerName: 'Survey Status',
            minWidth: 110,
            flex: 1,
            renderCell: (params) => {
                if (params.row.SurveyStatus === 'Complete') {
                    return <Chip label="Complete" color="success" />;
                } else if (params.row.SurveyStatus === 'Incomplete') {
                    return <Chip label="Incomplete" color="warning" />;
                } else if (params.row.SurveyStatus === 'Closed') {
                    return <Chip label="Closed" />;
                }
            },
        },
        {
            field: 'MemberName',
            headerName: 'Member Name',
            minWidth: 220,
            flex: 1,
        },
        {
            field: 'AHCCCSId',
            headerName: 'AHCCCSId',
            minWidth: 220,
            flex: 1,
        },
        {
            field: 'ProviderName',
            headerName: 'Provider Name',
            minWidth: 220,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.ProviderName}`,
        },
        {
            field: 'Question1',
            headerName: 'Question 1',
            minWidth: 220,
            flex: 1,
            renderCell: (params) => {
                return <>{params.row.Question1 ? getQA(params.row.Question1) : <p>N/A</p>}</>;
            },
        },
        {
            field: 'Question2',
            headerName: 'Question 2',
            minWidth: 220,
            flex: 1,
            renderCell: (params) => {
                return <>{params.row.Question2 ? getQA(params.row.Question2) : <p>N/A</p>}</>;
            },
        },
        {
            field: 'Question3',
            headerName: 'Question 3',
            minWidth: 220,
            flex: 1,
            renderCell: (params) => {
                return <>{params.row.Question3 ? getQA(params.row.Question3) : <p>N/A</p>}</>;
            },
        },
        {
            field: 'Question4',
            headerName: 'Question 4',
            minWidth: 220,
            flex: 1,
            renderCell: (params) => {
                return <>{params.row.Question4 ? getQA(params.row.Question4) : <p>N/A</p>}</>;
            },
        },
    ];

    const [pageSize, setPageSize] = useState(15);
    const handleWindowResize = () => {
        setPageSize(parseInt(window.innerHeight / (window.innerHeight > 1000 ? 72 : 74) + ''));
    };
    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize, false);
        dispatch(getActiveProviders(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authData.isInPermission('View Claims Management')) {
            setLocalProviders(filteredProviders);
        } else {
            setLocalProviders(filteredProviders.filter((x) => x.Id === authData.UserId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeProviders]);

    const toggleInactiveHandler = (isChecked: boolean) => {
        setShowInActive(isChecked);
        dispatch(setSelectedProviderIds(['All Providers']));
        dispatch(setReportData([]));
    };

    return (
        <Grid container>
            <Box width={'100%'} borderBottom={1} paddingBottom={1} marginBottom={1}>
                <Typography fontWeight="fontWeightMedium" component="h3">
                    Survey Report
                </Typography>
            </Box>
            {<Loading message={'Loading...'} />}
            <Grid item xs={12}>
                <Stack width="100%" direction="row" alignItems="center" marginY={2} className="float-right" spacing={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['month', 'year']}
                            label="Start Month"
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e: any) => {
                                setStartDate(new Date(e.getFullYear(), e.getMonth(), 1));
                            }}
                            value={startDate}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['month', 'year']}
                            label="End Month"
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e: any) => {
                                setEndDate(e);
                            }}
                            value={endDate}
                        />
                    </LocalizationProvider>
                    <FormControl sx={{ minWidth: '120px' }}>
                        <InputLabel id="demo-simple-select-label">Selected Providers</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Selected Providers"
                            value={selectedProviderIds}
                            multiple
                            onChange={(e) => {
                                dispatch(setSelectedProviderIds(e.target.value as []));
                            }}
                        >
                            {authData.isInPermission('View Claims Management') && <MenuItem value={'All Providers'}>All Providers</MenuItem>}
                            <FormControlLabel
                                sx={{ pl: '12px', mb: '0px' }}
                                control={<Checkbox checked={showInActive} onChange={(e) => toggleInactiveHandler(e.target.checked)} />}
                                label="Show Inactive"
                            />
                            {localProviders.map((provider) => {
                                return (
                                    <MenuItem key={provider.Id} value={provider.Id} sx={{ color: provider.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                        {provider.LastName + ', ' + provider.FirstName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        style={{ minWidth: '120px' }}
                        onClick={() => dispatch(fetchSurveyReportData(startDate, endDate, selectedProviderIds, showInActive))}
                        variant="contained"
                    >
                        Search
                    </Button>
                    <Button
                        style={{ minWidth: '120px', marginLeft: 'auto' }}
                        onClick={() => dispatch(exportSurveyReport(startDate, endDate, selectedProviderIds, showInActive))}
                        variant="contained"
                        color="success"
                    >
                        {downLoading ? 'Downloading..' : 'Export'}
                    </Button>
                </Stack>
            </Grid>
            <Grid item style={{ height: '82vh', width: '100%' }}>
                <DataGrid
                    rows={reportData}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.Id}
                    getRowHeight={() => 'auto'}
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default SurveyReport;
