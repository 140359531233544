import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { RootState } from '../../reducers';
import { fetchClaimsData, setDosStart, setDosEnd, setSelectedProviderId, setErrorMessage } from '../../store/claimsErrorReport.slice';
import Loading from '../Shared/Loading';
import MessageDisplay from '../Shared/MessageDisplay';
import Authorization from '../../utils/Authorization';
import { setSelectedTab } from '../../store/claimsManagement.slice';
import { setClaimNumberSearchValue, setProviderClaimsSelectedProviderId } from '../../store/providerClaims.slice';
import { setEncounterChange } from '../../store/procedureSignOffEncounter.slice';

const ProviderClaims = () => {
    const authData = new Authorization();
    const { claimsData, errorMessage, dosStart, dosEnd, selectedProviderId } = useSelector((state: RootState) => state.claimsErrorReport);
    const { encounterClaimProviders } = useSelector((state: RootState) => state.shared);
    const dispatch = useDispatch();
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [localProviders, setLocalProviders] = useState([]);

    const columns: GridColDef[] = [
        {
            field: 'Count',
            headerName: 'Count',
            flex: 0.75,
        },
        {
            field: 'LastErrorDate',
            headerName: 'Last Error Date',
            flex: 0.75,
        },
        {
            field: 'ErrorMessage',
            headerName: 'Error Message',
            flex: 1.25,
        },
        {
            field: 'ClaimNumbers',
            headerName: 'Claim Numbers',
            flex: 1.25,
            renderCell: (params: GridRenderCellParams<string>) => {
                let splitClaimNumbers = params.row.ClaimNumbers.split(', ');
                return splitClaimNumbers.map((claimNumber) => {
                    return (
                        <label
                            className="claim-number-cell"
                            onClick={() => {
                                if (authData.isInPermission('View Claims Management')) {
                                    dispatch(setProviderClaimsSelectedProviderId(''));
                                }
                                dispatch(setClaimNumberSearchValue(claimNumber));
                                dispatch(setSelectedTab(1));
                                dispatch(setEncounterChange(true));
                            }}
                        >
                            {claimNumber}
                        </label>
                    );
                });
            },
        },
    ];

    const changeProvider = (providerId: string) => {
        dispatch(setSelectedProviderId(providerId));
    };

    const searchClaims = () => {
        setLoadingMessage('Loading...');
        dispatch(fetchClaimsData(selectedProviderId, errorMessage, dosStart, dosEnd));
    };

    useEffect(() => {
        if (authData.isInPermission('View Claims Management')) {
            setLocalProviders(encounterClaimProviders);
        } else {
            setLocalProviders(encounterClaimProviders.filter((x) => x.Id === authData.UserId));
            dispatch(setSelectedProviderId(authData.UserId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encounterClaimProviders]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MessageDisplay />
            <Box>
                {<Loading message={loadingMessage} />}
                {/* <Grid
                    container
                    justifyContent={'flex-end'}
                    spacing={3}
                    style={{ padding: '16px 16px 16px 16px' }}
                > */}
                <Grid container justifyContent={'flex-end'} spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                    <Grid item xs={2} textAlign={'left'}>
                        <Typography variant="h5">Claims</Typography>
                        <Typography variant="h6" className={'colorsuccess'}>
                            Exist in Waystar
                        </Typography>
                        <br />
                        <Stack direction="column" alignItems={'left'} justifyContent={'flex-end'} spacing={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Provider</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Provider"
                                    disabled={!authData.isInPermission('View Claims Management')}
                                    value={selectedProviderId}
                                    onChange={(e) => changeProvider(e.target.value)}
                                >
                                    <MenuItem key={''} value={''}>
                                        {`Select A Provider`}
                                    </MenuItem>
                                    {localProviders.map(function (provider) {
                                        return (
                                            <MenuItem key={provider.Id} value={provider.Id}>
                                                {`${provider.LastName}, ${provider.FirstName}`}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel htmlFor="outlined-adornment-password">Search By Error Message</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type="text"
                                    value={errorMessage}
                                    onChange={(e) => {
                                        dispatch(setErrorMessage(e.target.value as string));
                                    }}
                                    onKeyUp={(event: any) => {}}
                                    label="Search By Error Message"
                                />
                            </FormControl>
                            <FormControl>
                                <DesktopDatePicker
                                    label="DOS Start Date:"
                                    value={dosStart}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        dispatch(setDosStart(newValue));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                            <FormControl>
                                <DesktopDatePicker
                                    label="DOS End Date:"
                                    value={dosEnd}
                                    minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                        dispatch(setDosEnd(newValue));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                            <Button onClick={searchClaims} style={{ minWidth: '120px!important' }} variant="contained">
                                Search
                            </Button>
                            {/* <Button
                                onClick={() => {
                                    dispatch(downloadClaimsHandler(claimsData));
                                }}
                                style={{ minWidth: '120px!important' }}
                                variant="contained"
                            >
                                Download
                            </Button> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={10}>
                        <div style={{ height: '88vh', width: '100%' }}>
                            <DataGrid
                                rows={claimsData}
                                columns={columns}
                                pageSize={15}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.ErrorMessage}
                                getRowHeight={() => 'auto'}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                        display: 'block',
                                    },
                                    '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root': {
                                        // display: 'none',
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default ProviderClaims;
