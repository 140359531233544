import { BackgroundTaskTypeEnum } from '../../Enum/BackgroundTaskTypeEnum';
import BaseEntity from '../BaseEntity.model';

export default interface IBackgroundTask extends BaseEntity {
    Type: BackgroundTaskTypeEnum;
    IsRunning: boolean;
    CreatedOn: Date | null;
    UpdatedOn: Date | null;
}

export default class BackgroundTask implements IBackgroundTask {
    Id: string;
    Type: BackgroundTaskTypeEnum;
    IsRunning: boolean;
    CreatedOn: Date | null;
    UpdatedOn: Date | null;

    constructor() {
        this.Id = '';
        this.Type = null;
        this.IsRunning = false;
        this.CreatedOn = null;
        this.UpdatedOn = null;
    }
}
