import { Box, Button, Divider, FormControl, FormGroup, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchMemberStatuses, saveMemberStatusChange, setShowMemberStatusChange } from '../../store/members.slice';
import MemberAvatar from './MemberDetail/MemberAvatar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { modalStyle } from '../../utils/constants';

const MemberStatusChange = (props: any) => {
    const dispatch = useDispatch();
    const { showMemberStatusChange } = useSelector((state: RootState) => state.members);
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const { memberStatuses, requestedMemberStatus } = useSelector((state: RootState) => state.members);
    const [error, setError] = useState({ EffectiveDate: false, RequestedStatus: false, Note: false });

    const getDefault = () => {
        return {
            RequestedDate: new Date(),
            EffectiveDate: new Date(),
            RequestedStatus: requestedMemberStatus,
            Note: '',
            FirstName: '',
            LastName: '',
            MemberId: '',
            RequestedBy: { FullName: '' },
        };
    };

    let tmp = getDefault();
    const [form, setForm] = useState(tmp);

    const saveCC = () => {
        if (valid()) {
            let msc = { ...form };
            msc.FirstName = selectedMember.FirstName;
            msc.LastName = selectedMember.LastName;
            msc.MemberId = selectedMember.Id;

            dispatch(saveMemberStatusChange(msc));
        }
    };

    const valid = () => {
        if (!form.EffectiveDate || !form.RequestedStatus || !form.Note) {
            setError({ ...error, EffectiveDate: !form.EffectiveDate, RequestedStatus: !form.RequestedStatus, Note: !form.Note });
            return false;
        } else {
            return true;
        }
    };

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Stack spacing={3}>
                    <Stack direction="row" spacing={2}>
                        <MemberAvatar />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Current Member Status</InputLabel>
                            <Select
                                disabled={props.preview}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedMember?.Status}
                                label="Current Member Status"
                            >
                                {memberStatuses.map(function (option) {
                                    return (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                    <FormGroup>
                        <Stack direction="row" spacing={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disabled={props.preview}
                                    label="Effective Date"
                                    renderInput={(params) => (
                                        <TextField
                                            error={error.EffectiveDate}
                                            helperText={error.EffectiveDate ? 'Required' : ''}
                                            sx={{ width: '100%' }}
                                            {...params}
                                        />
                                    )}
                                    onChange={(e: any) => {
                                        setForm({ ...form, EffectiveDate: e });
                                    }}
                                    value={form.EffectiveDate}
                                />
                            </LocalizationProvider>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Requested Member Status</InputLabel>
                                <Select
                                    disabled={props.preview}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={form.RequestedStatus}
                                    label="Requested Member Status"
                                    onChange={(e: any) => {
                                        setForm({ ...form, RequestedStatus: e.target.value });
                                    }}
                                >
                                    {memberStatuses.map(function (option) {
                                        return (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </FormGroup>
                    {props.preview ? (
                        <FormGroup>
                            <Stack direction="row" spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disabled={props.preview}
                                        label="Requested Date"
                                        renderInput={(params) => <TextField sx={{ width: '100%' }} {...params} />}
                                        onChange={() => {}}
                                        value={form.RequestedDate}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    disabled={props.preview}
                                    fullWidth
                                    label="Requested By"
                                    variant="outlined"
                                    value={form.RequestedBy?.FullName}
                                    onChange={() => {}}
                                />
                            </Stack>
                        </FormGroup>
                    ) : null}
                    <FormGroup>
                        <TextField
                            disabled={props.preview}
                            error={error.Note}
                            helperText={error.Note ? 'Required' : ''}
                            fullWidth
                            multiline
                            rows={3}
                            label="Note"
                            variant="outlined"
                            value={form.Note}
                            onChange={(e: any) => {
                                setForm({ ...form, Note: e.target.value });
                            }}
                        />
                    </FormGroup>
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        {props.preview ? null : (
                            <Button className="button-120" variant="contained" onClick={saveCC}>
                                Save
                            </Button>
                        )}
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                dispatch(setShowMemberStatusChange(-1, false));
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    useEffect(() => {
        dispatch(fetchMemberStatuses());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setForm({ ...form, RequestedStatus: requestedMemberStatus });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestedMemberStatus]);

    useEffect(() => {
        if (props.statusChange) {
            setForm(props.statusChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.statusChange]);

    return (
        <Modal
            open={showMemberStatusChange}
            onClose={() => {
                dispatch(setShowMemberStatusChange(-1, false));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Stack spacing={2}>
                    <Typography variant="h5">Member Status Change</Typography>
                    <Divider />
                    {renderBody()}
                </Stack>
            </Box>
        </Modal>
    );
};

export default MemberStatusChange;
