import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
} from '@mui/material';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusEnum } from '../../../../Enum/StatusEnum';
import { SummaryNoteTypeEnum } from '../../../../Enum/SummaryNoteTypeEnum';
import { EngagementDraft } from '../../../../Models/DocumentEngagements/EngagementDraft.model';
import { SummaryNote } from '../../../../Models/DocumentEngagements/SummaryNote.model';
import { RootState } from '../../../../reducers';
import {
    getAllEducationMaterials,
    getMemberBasicInfo,
    getMemberEducationMaterials,
    insertMemberMaterial,
    setCurrentCategories,
} from '../../../../store/assignEducationMaterials.slice';
import { saveEngagementDraft } from '../../../../store/documentEngagement.slice';
import { copyObject } from '../../../../utils/common';
import AssignEducationCheckboxComponent from '../AssignEducationCheckboxModal';

const AssignEducationMaterialsModal = (props) => {
    const dispatch = useDispatch();
    const isAssessment = Boolean(props.isAssessment);

    const { allEducationMaterials, educationMaterialsAssignedToMember, memberInfo, currentCategories } = useSelector(
        (state: RootState) => state.assignEducationMaterials
    );
    const { engagementDraft } = useSelector((state: RootState) => state.documentEngagement);
    const starting = allEducationMaterials.filter((material) => currentCategories.some((material2) => material.Id === material2.Id));
    const [selectedMaterialsList, setSelectedMaterialsList] = useState([]);

    const handleChange = (event: SelectChangeEvent<typeof currentCategories>) => {
        const {
            target: { value },
        } = event;
        dispatch(
            setCurrentCategories(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value
            )
        );
    };

    const selectedMaterialsListOnChangeHandler = (object) => {
        let selectedCategory = selectedMaterialsList.find((category) => category['Id'] === object.categoryId);

        if (selectedCategory === undefined) {
            selectedCategory = currentCategories.map((el) => ({ ...el, MaterialItems: [] })).find((category) => category['Id'] === object.categoryId);
        }
        if (selectedCategory['MaterialItems'].find((material) => material['Id'] === object.item.Id)) {
            selectedCategory = { ...selectedCategory, MaterialItems: selectedCategory['MaterialItems'].filter((item) => item.Id !== object.item.Id) };
        } else {
            selectedCategory = { ...selectedCategory, MaterialItems: [...selectedCategory['MaterialItems'], object.item] };
        }
        let copy = [...selectedMaterialsList];
        let index = selectedMaterialsList.findIndex((category) => category.Id === selectedCategory.Id);
        if (index === -1) {
            copy = [...copy, selectedCategory];
        } else {
            copy[index] = selectedCategory;
        }
        setSelectedMaterialsList(copy);
    };

    const assignMaterialsHandler = () => {
        const emptyMaterialLists = selectedMaterialsList.filter((el) => el.MaterialItems.length === 0);
        let temp = [];

        if (emptyMaterialLists.length === selectedMaterialsList.length) {
            temp = selectedMaterialsList.map((el) => ({ ...el, Id: null }));
        } else {
            temp = selectedMaterialsList.filter((material) => material['MaterialItems'].length !== 0);
        }
        const payload = temp.map((el) => ({
            EducationMaterial: { ...el },
            MemberId: memberInfo['Id'],
            MemberName: memberInfo['Name'],
        }));
        dispatch(insertMemberMaterial(payload, props.memberId, prepareSummaryNotes));
    };

    const prepareSummaryNotes = (response) => {
        if (isAssessment) {
            let data = copyObject(engagementDraft) as EngagementDraft;
            const id = response.MemberEduMaterials[0]?.Id;
            data.SummaryNotes = data.SummaryNotes.filter(s => s.Id !== id);
            if (Boolean(selectedMaterialsList.length)) {
                let materialInfo = selectedMaterialsList.map(s => s.Category).join(", ");
                data.ProgressNotes = data.ProgressNotes || '';
                data.ProgressNotes += "<p>";
                data.ProgressNotes += `Assigned material for ${materialInfo}`;
                data.ProgressNotes += "</p>";
                const summaryNote = {
                    Id: id,
                    CptCode: "99071",
                    Zcode: "", Note: `Education materials provided for ${materialInfo}`,
                    Type: SummaryNoteTypeEnum.Education,
                    IsConfirm: false,
                    OrderBy: 36,
                    DisplayName: "Education"
                } as SummaryNote;
                data.SummaryNotes.push(summaryNote);
                data.EducationStatus = data.EducationStatus || StatusEnum.InProgress;
                dispatch(saveEngagementDraft(data));
            }
        }
    }

    const addAllHandler = (category) => {
        let index = selectedMaterialsList.findIndex((category2) => category2.Id === category.Id);
        let copy = [...selectedMaterialsList];
        let newCategory = { ...category, MaterialItems: [...category['MaterialItems']] };
        if (index === -1) {
            copy = [...copy, newCategory];
        } else {
            copy[index] = newCategory;
        }
        setSelectedMaterialsList(copy);
    };

    const removeAllHandler = (category) => {
        let index = selectedMaterialsList.findIndex((category2) => category2.Id === category.Id);
        let copy = [...selectedMaterialsList];
        let newCategory = { ...category, MaterialItems: [] };
        if (index === -1) {
            copy = [...copy, newCategory];
        } else {
            copy[index] = newCategory;
        }
        setSelectedMaterialsList(copy);
    };

    useEffect(() => {
        if (educationMaterialsAssignedToMember.length > 0) {
            setSelectedMaterialsList(educationMaterialsAssignedToMember.map((el) => el['EducationMaterial']));
        }
    }, [educationMaterialsAssignedToMember]);

    useEffect(() => {
        dispatch(getAllEducationMaterials());
        dispatch(getMemberBasicInfo(props.memberId));
        dispatch(getMemberEducationMaterials(props.memberId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.memberId]);

    return (
        <Dialog open={props.open} onClose={props.onClose} disableEnforceFocus fullWidth maxWidth="xl" PaperProps={{ sx: { height: '90vh' } }}>
            <Grid item p={2}>
                <Stack>
                    <Typography marginBottom={2} variant="h6">
                        Select Education Materials
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">Categories</InputLabel>
                        <Select label="Categories" multiple value={starting} onChange={handleChange} MenuProps={{ sx: { height: 400 } }}>
                            {allEducationMaterials.map((el) => {
                                return (
                                    <MenuItem key={el.Id} value={el}>
                                        {el.Category}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </Grid>
            <DialogContent>
                <FormGroup>
                    <Grid container spacing={3} style={{ padding: '16px 16px 16px 16px' }}>
                        {starting.length === 0 ? (
                            <Grid item xs={12}>
                                <Typography variant="h6">No Materials Assigned To Member</Typography>
                            </Grid>
                        ) : (
                            starting.map((category, index) => {
                                return (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={12}>
                                            <Typography display={'inline-flex'} variant="h6">
                                                {category['Category']}
                                            </Typography>
                                            <Stack direction={'row'} p={2}>
                                                <Button sx={{ marginRight: 1 }} onClick={() => addAllHandler(category)}>
                                                    Add All
                                                </Button>
                                                <Button onClick={() => removeAllHandler(category)}>Remove All</Button>
                                            </Stack>
                                        </Grid>
                                        <AssignEducationCheckboxComponent
                                            selectedMaterialsList={selectedMaterialsList}
                                            selectedMaterialsListOnChangeHandler={selectedMaterialsListOnChangeHandler}
                                            materialItems={category['MaterialItems']}
                                            categoryId={category.Id}
                                        />
                                        <Divider sx={{ height: '2px', width: '100%', marginY: 5 }} />
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button disabled={selectedMaterialsList.length === 0} className="button-120" variant="contained" onClick={assignMaterialsHandler}>
                    Assign
                </Button>
                <Button
                    className="button-120"
                    variant="outlined"
                    onClick={() => {
                        props.onClose();
                        setSelectedMaterialsList(educationMaterialsAssignedToMember.map((el) => el['EducationMaterial']));
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignEducationMaterialsModal;
