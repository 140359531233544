export enum TimeFrameEnum {
    From1To7Days = 1,
    From1To7DaysDescription = '1-7 Days',
    From8To14Days = 2,
    From8To14DaysDescription = '8-14 Days',
    From15To30Days = 3,
    From15To30DaysDescription = '15-30 Days',
    From31To60Days = 4,
    From31To60DaysDescription = '31-60 Days',
}
