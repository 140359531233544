import { Autocomplete, CircularProgress, FormControl, FormGroup, InputLabel, MenuItem, Popper, Select, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch} from 'react-redux';
import { fetchPlaceDetail, fetchPlacePredictions } from '../../store/googlePlace.slice';
import { states } from '../../utils/constants';
import Address from '../../Models/Shared/Address.model';

const AddressDetail = (props) => {
    const dispatch = useDispatch();
    const [placeList, setPlaceList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const getAddressByKeyword = (input: string) => {
        props.onChange({ ...props.address, Address1: input });
        if (input?.length > 4) {
            setIsFetching(true);
            dispatch(
                fetchPlacePredictions(input, (data: any) => {
                    setPlaceList(data);
                    setIsFetching(false);
                })
            );
        }
    };

    const getAddressDetail = (e: any, option: any) => {
        if (Boolean(option)) {
            setIsFetching(true);
            dispatch(
                fetchPlaceDetail(option.PlacePrediction.PlaceId, (data: Address) => {
                    props.onChange({ ...props.address, ...data });
                    setIsFetching(false);
                })
            );
        }
    };

    return (
        <>
            <FormGroup>
                <Stack direction="row" spacing={2}>
                    <Autocomplete
                        id="address1"
                        value={props?.address?.Address1 || ''}
                        inputValue={props?.address?.Address1 || ''}
                        freeSolo
                        disablePortal
                        disableClearable
                        PopperComponent={(inputProps) => <Popper {...inputProps} style={{ width: 500 }} placement="bottom-start" />}
                        getOptionLabel={(option) => option?.PlacePrediction?.Text?.Text || ''}
                        options={placeList}
                        style={{ width: '100%' }}
                        onChange={(e, option) => {
                            getAddressDetail(e, option);
                        }}
                        onInputChange={(event, newInputValue: any) => {
                            if (event?.type === 'change') {
                                getAddressByKeyword(newInputValue);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Address 1"
                                name="address1"
                                required
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <TextField
                        fullWidth
                        label="Address 2"
                        variant="outlined"
                        value={props?.address?.Address2 || ''}
                        onChange={(e: any) => {
                            props.onChange({ ...props.address, Address2: e.target.value });
                        }}
                    />
                    <TextField
                        fullWidth
                        label="City"
                        variant="outlined"
                        value={props?.address?.City || ''}
                        onChange={(e: any) => {
                            props.onChange({ ...props.address, City: e.target.value });
                        }}
                    />
                </Stack>
            </FormGroup>
            <FormGroup>
                <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props?.address?.State || ''}
                            label="State"
                            onChange={(e: any) => {
                                props.onChange({ ...props.address, State: e.target.value });
                            }}
                        >
                            {states.map(function (state) {
                                return (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Country"
                        variant="outlined"
                        value={props?.address?.Country || ''}
                        onChange={(e: any) => {
                            props.onChange({ ...props.address, Country: e.target.value });
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Zip Code"
                        variant="outlined"
                        type={'number'}
                        inputProps={{ maxLength: 5 }}
                        value={props?.address?.ZipCode || ''}
                        onChange={(e: any) => {
                            props.onChange({ ...props.address, ZipCode: e.target.value });
                        }}
                    />
                </Stack>
            </FormGroup>
        </>
    );
};

export default AddressDetail;
