import { Modal, Typography, Divider, Button, Grid, Card } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { fetchClaimHistory, getClaimStatus, setShowClaimHistoryModal } from '../../store/claimsManagement.slice';
import { largeModalStyle } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useEffect } from 'react';
import { localDateAndTimeFormat } from '../../utils/timeFormat';
import ClaimsHistory from '../../Models/Claims/ClaimHistory.model';

const ClaimHistoryModal = (props: any) => {
    const dispatch = useDispatch();
    const { claimHistories, showClaimHistoryModal } = useSelector((state: RootState) => state.claimsManagement);

    const serviceLineUI = (serviceLine: any, index: number) => {
        return (
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} className="claim-history-cell">
                    <Typography variant="subtitle1" className="claim-history-label">
                        CPT Code
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{serviceLine.CPTcode}</span>
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} className="claim-history-cell">
                    <Typography variant="subtitle1" className="claim-history-label">
                        Units
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{serviceLine.Units}</span>
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} className="claim-history-cell">
                    <Typography variant="subtitle1" className="claim-history-label">
                        Primary Dx
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{serviceLine.PrimaryDiagnosisPointer.Code}</span>
                    </Typography>
                </Stack>
                {serviceLine.SecondaryDiagnosisPointer && (
                    <Stack direction="row" spacing={2} className="claim-history-cell">
                        <Typography variant="subtitle1" className="claim-history-label">
                            Secondary Dx
                        </Typography>
                        <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>{serviceLine.SecondaryDiagnosisPointer?.Code}</span>
                        </Typography>
                    </Stack>
                )}
                {serviceLine.TertiaryDiagnosisPointer && (
                    <Stack direction="row" spacing={2} className="claim-history-cell">
                        <Typography variant="subtitle1" className="claim-history-label">
                            Tertiary Dx
                        </Typography>
                        <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>{serviceLine.TertiaryDiagnosisPointer?.Code}</span>
                        </Typography>
                    </Stack>
                )}
                {serviceLine.QuaternaryDiagnosisPointer && (
                    <Stack direction="row" spacing={2} className="claim-history-cell">
                        <Typography variant="subtitle1" className="claim-history-label">
                            Quaternary Dx
                        </Typography>
                        <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>{serviceLine.QuaternaryDiagnosisPointer?.Code}</span>
                        </Typography>
                    </Stack>
                )}
                <Divider />
            </Stack>
        );
    };

    const getClaimRow = (claimHistory: ClaimsHistory, claim: any, type: string) => {
        return (
            <Stack spacing={2} style={{ marginTop: '0px' }}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    {type} Claim
                </Typography>
                <Stack spacing={2} direction="row">
                    <Typography variant="subtitle1" className="claim-history-label">
                        Claim Status
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{getClaimStatus(claim.CHStatus)}</span>
                    </Typography>
                </Stack>
                <Stack spacing={2} direction="row">
                    <Typography variant="subtitle1" className="claim-history-label">
                        Resubmission Code
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{claim.ResubmissionCode}</span>
                    </Typography>
                </Stack>
                <Stack spacing={2} direction="row">
                    <Typography variant="subtitle1" className="claim-history-label">
                        Original Ref #
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{claim.OriginalRefNo}</span>
                    </Typography>
                </Stack>
                <Stack spacing={2} direction="row">
                    <Typography variant="subtitle1" className="claim-history-label">
                        Additional Claim Info
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{claim.AdditionalClaimInfo}</span>
                    </Typography>
                </Stack>
                <Stack spacing={2} direction="row">
                    <Typography variant="subtitle1" className="claim-history-label">
                        Place of Service
                    </Typography>
                    <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>{claim.PlaceOfService}</span>
                    </Typography>
                </Stack>
                {claim.ServiceLines.map((serviceLine: any, index: number) => {
                    return serviceLineUI(serviceLine, index);
                })}
            </Stack>
        );
    };

    const renderBody = () => {
        return (
            <Stack spacing={7}>
                {claimHistories.map((claimHistory: any, index: number) => {
                    return (
                        <Card elevation={5} style={{ padding: '8px' }}>
                            <Stack key={index} spacing={2}>
                                <Typography variant="h6">
                                    Claim Change - <span style={{ fontWeight: 'bold' }}>{localDateAndTimeFormat(new Date(claimHistory.CreatedDate))}</span> -
                                    Created By: <span style={{ fontWeight: 'bold' }}>{claimHistory.CreatedBy.Name}</span>
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    {getClaimRow(claimHistory, claimHistory.PreviousClaim, 'Previous')}
                                    <Divider orientation="vertical" flexItem style={{ padding: '16px', color: 'black' }} />
                                    {getClaimRow(claimHistory, claimHistory.ModifiedClaim, 'Modified')}
                                </Stack>
                            </Stack>
                        </Card>
                    );
                })}
                <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                        <Button
                            style={{ width: '120px' }}
                            variant="contained"
                            onClick={() => {
                                dispatch(setShowClaimHistoryModal(false));
                            }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        );
    };

    useEffect(() => {
        if (props.claim) {
            dispatch(fetchClaimHistory(props.claim.TraceId));
        }
    }, [dispatch, props.claim]);

    return (
        <Modal
            open={showClaimHistoryModal}
            style={{ overflow: 'scroll' }}
            onClose={() => {
                dispatch(setShowClaimHistoryModal(false));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={largeModalStyle} style={{ backgroundColor: 'white', width: '1000px', top: '50%', height: '90VH', overflow: 'scroll' }}>
                <Stack spacing={2}>
                    <Typography variant="h5">
                        Claim History - <span style={{ fontWeight: 'bold' }}>{props.claim?.TraceId + ' ( Member: ' + props.claim?.MemberName + ')'}</span>
                    </Typography>
                    <Divider />
                    {renderBody()}
                </Stack>
            </Box>
        </Modal>
    );
};

export default ClaimHistoryModal;
