import React, { useCallback, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { configureStackedBarChart } from '../../ChartConfiguration/ChartConfiguration';
import { fetchEngagementSummaryChart, MenuProps } from '../../Widgets/WidgetService';
import EngagementSummary from '../../../Models/Widget/EngagementSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { RootState } from '../../../reducers';
import { useWidgetContext } from '../../../hooks/widgetContext';
import '../Widgets.css';

const EngagementsSummary = () => {
    const { teams } = useSelector((state: RootState) => state.teams);
    const { activeProviders } = useSelector((state: RootState) => state.provider);
    const history = useNavigate();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [engagementSummary, setEngagementSummary] = useState<EngagementSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const { engagementSummaryFilter, setEngagementSummaryFilter } = useWidgetContext();
    const filteredTeams = (engagementSummaryFilter.showInActiveTeams ? teams : teams.filter((t) => t.IsActive)) || [];
    const filteredProviders =
        (engagementSummaryFilter.showInActiveProviders ? activeProviders : activeProviders.filter((p) => p.Role.RoleName !== 'Inactive')) || [];

    const handleClick = (event) => {
        setShow(!show);
    };

    const dateFilterList = [
        { Id: TimePeriodFilterEnum.Last3Months, Value: TimePeriodFilterEnum.Last3MonthsDescription },
        { Id: TimePeriodFilterEnum.Last6Months, Value: TimePeriodFilterEnum.Last6MonthsDescription },
        { Id: TimePeriodFilterEnum.Last1Year, Value: TimePeriodFilterEnum.Last1YearDescription },
        { Id: TimePeriodFilterEnum.Custom, Value: TimePeriodFilterEnum.CustomDescription },
    ];

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChart(
                    'Engagement Summary',
                    'Engagements Count',
                    'Visits',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'EngagementSummary',
                    history
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow, history]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (
            engagementSummaryFilter.dateFilter !== TimePeriodFilterEnum.Custom ||
            (Boolean(engagementSummaryFilter.fromDate) && Boolean(engagementSummaryFilter.toDate))
        ) {
            const changeFilter = async () => {
                let start = engagementSummaryFilter.fromDate !== null ? new Date(engagementSummaryFilter.fromDate).toDateString() : null;
                let end = engagementSummaryFilter.toDate !== null ? new Date(engagementSummaryFilter.toDate).toDateString() : null;

                const data = await fetchEngagementSummaryChart(
                    engagementSummaryFilter.provider,
                    engagementSummaryFilter.team,
                    engagementSummaryFilter.dateFilter,
                    start,
                    end,
                    false,
                    engagementSummaryFilter.showInActiveTeams,
                    engagementSummaryFilter.showInActiveProviders
                );
                setEngagementSummary(data);
            };
            setIsGraphShow(false);
            setChartOptions(
                configureStackedBarChart(
                    'Engagement Summary',
                    'Engagements Count',
                    'Visits',
                    'Count',
                    [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                    [0],
                    'EngagementSummary',
                    history
                )
            );
            changeFilter();
        }

        if (
            engagementSummaryFilter.dateFilter !== TimePeriodFilterEnum.Custom &&
            Boolean(engagementSummaryFilter.fromDate) &&
            Boolean(engagementSummaryFilter.toDate)
        ) {
            setEngagementSummaryFilter({ ...engagementSummaryFilter, fromDate: null, toDate: null });
        }
    }, [setEngagementSummaryFilter, engagementSummaryFilter, history]);

    useEffect(() => {
        if (engagementSummary) {
            let xAxisValues = [];
            let yAxisValues = [];
            let inpersonVisits = [];
            let telemedicineVisits = [];
            let telephoneVisits = [];

            let inPersonVisitsPercentage = engagementSummary.TotalVisits > 0 ? (engagementSummary.InPersonVisits / engagementSummary.TotalVisits) * 100 : 0;
            let d = {
                y: engagementSummary.InPersonVisits,
                name: Math.round(inPersonVisitsPercentage),
            };
            inpersonVisits.push(d);

            let telemedicineVisitsPercentage =
                engagementSummary.TotalVisits > 0 ? (engagementSummary.TelemedicineVisits / engagementSummary.TotalVisits) * 100 : 0;
            let d1 = {
                y: engagementSummary.TelemedicineVisits,
                name: Math.round(telemedicineVisitsPercentage),
            };
            telemedicineVisits.push(d1);

            let telephoneVisitsPercentage = engagementSummary.TotalVisits > 0 ? (engagementSummary.TelephonicVisits / engagementSummary.TotalVisits) * 100 : 0;
            let d2 = {
                y: engagementSummary.TelephonicVisits,
                name: Math.round(telephoneVisitsPercentage),
            };
            telephoneVisits.push(d2);

            xAxisValues.push('Visits');

            yAxisValues.push({ name: 'InPerson', data: inpersonVisits, color: '#4682B4', showInLegend: true });
            yAxisValues.push({ name: 'Telemedicine', data: telemedicineVisits, color: '#7864ed', showInLegend: true });
            yAxisValues.push({ name: 'Telephonic', data: telephoneVisits, color: '#3676e8', showInLegend: true });

            if (engagementSummary.InPersonVisits === 0 && engagementSummary.TelemedicineVisits === 0 && engagementSummary.TelephonicVisits === 0) {
                setChartOptions(
                    configureStackedBarChart(
                        'Engagement Summary',
                        'Visits Count',
                        'Visits',
                        'Count',
                        [{ name: '', data: [0], showInLegend: false, title: { text: null } }],
                        [0],
                        'EngagementSummary',
                        history
                    )
                );
            } else {
                let subTitle = '',
                    xAxisTitle = '',
                    yAxisTitle = 'Count';
                setChartOptions(
                    configureStackedBarChart('Engagement Chart', subTitle, xAxisTitle, yAxisTitle, yAxisValues, xAxisValues, 'EngagementSummary', history)
                );
            }

            setIsGraphShow(true);
        }
    }, [engagementSummary, history]);

    const changeFromDate = (date: any) => {
        let filter = engagementSummaryFilter;
        filter.fromDate = date;
        let dateInfo = new Date(date);
        if (!Boolean(engagementSummaryFilter.toDate) || new Date(engagementSummaryFilter.toDate) <= dateInfo) {
            filter.toDate = dateInfo.setDate(dateInfo.getDate() + 30);
        }
        filter.dateFilter = TimePeriodFilterEnum.Custom;
        setEngagementSummaryFilter({ ...filter });
    };

    const changeToDate = (date: any) => {
        let filter = engagementSummaryFilter;
        filter.toDate = date;
        setEngagementSummaryFilter({ ...engagementSummaryFilter, toDate: date });
        let dateInfo = new Date(date);
        if (!Boolean(engagementSummaryFilter.fromDate) || new Date(engagementSummaryFilter.fromDate) >= dateInfo) {
            filter.fromDate = dateInfo.setDate(dateInfo.getDate() - 30);
        }
        filter.dateFilter = TimePeriodFilterEnum.Custom;
        setEngagementSummaryFilter({ ...filter });
    };

    const navigateToDetail = () => {
        history(`/provider/metricsummary/engagementsummarydetail`);
    };

    const toggleTeamHandler = (isChecked: boolean) => {
        let filter = engagementSummaryFilter;
        filter.showInActiveTeams = isChecked;
        filter.team = 'All';
        setEngagementSummaryFilter({ ...filter });
    };

    const toggleProviderHandler = (isChecked: boolean) => {
        let filter = engagementSummaryFilter;
        filter.showInActiveProviders = isChecked;
        filter.provider = 'All';
        setEngagementSummaryFilter({ ...filter });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} lg={6}>
                <Paper className="smartdashboard-widget" elevation={5}>
                    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                        <Grid item xs={12} textAlign="center">
                            <Typography display={'inline-block'} variant="h6" marginRight={1} color={'primary'} ref={ref}>
                                <span className="pointer" onClick={navigateToDetail}>
                                    Engagement Visits Summary
                                </span>
                                <Tooltip
                                    open={show}
                                    title={
                                        <div>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Inperson :
                                            </Typography>
                                            <Typography variant="caption">Number of inperson vists in given date range</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Telemedicine :
                                            </Typography>
                                            <Typography variant="caption">Number of Telemedicine vists in given date range</Typography>
                                            <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                                Telephonic :
                                            </Typography>
                                            <Typography variant="caption">Number of telephonic vists in given date range</Typography>
                                        </div>
                                    }
                                    PopperProps={{ style: { zIndex: 1000 } }}
                                >
                                    <IconButton onClick={handleClick}>
                                        <InfoIcon sx={{ width: '18px', height: '18px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Providers</InputLabel>
                                <Select
                                    value={engagementSummaryFilter.provider}
                                    label="Providers"
                                    name="Providers"
                                    onChange={(e) => setEngagementSummaryFilter({ ...engagementSummaryFilter, provider: e.target.value })}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox
                                                checked={engagementSummaryFilter.showInActiveProviders}
                                                onChange={(e) => toggleProviderHandler(e.target.checked)}
                                            />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredProviders.map((pro) => (
                                        <MenuItem key={pro.Id} value={pro.Id} sx={{ color: pro.Role.RoleName === 'Inactive' ? 'grey' : 'black' }}>
                                            {pro.LastName}, {pro.FirstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Team</InputLabel>
                                <Select
                                    value={engagementSummaryFilter.team}
                                    label="Team"
                                    name="Team"
                                    onChange={(e) => setEngagementSummaryFilter({ ...engagementSummaryFilter, team: e.target.value })}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem key="All" value="All">
                                        All
                                    </MenuItem>
                                    <FormControlLabel
                                        sx={{ pl: '12px', mb: '0px' }}
                                        control={
                                            <Checkbox
                                                checked={engagementSummaryFilter.showInActiveTeams}
                                                onChange={(e) => toggleTeamHandler(e.target.checked)}
                                            />
                                        }
                                        label="Show Inactive"
                                    />
                                    {filteredTeams.map((team) => (
                                        <MenuItem key={team.Id} value={team.Id} sx={{ color: team.IsActive ? 'black' : 'grey' }}>
                                            {team.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel>Date Filter</InputLabel>
                                <Select
                                    value={engagementSummaryFilter.dateFilter}
                                    label="Date Filter"
                                    name="Date"
                                    onChange={(e) => setEngagementSummaryFilter({ ...engagementSummaryFilter, dateFilter: parseInt(e.target.value as any) })}
                                >
                                    {dateFilterList.map((date) => (
                                        <MenuItem key={date.Id} value={date.Id}>
                                            {date.Value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {engagementSummaryFilter.dateFilter === TimePeriodFilterEnum.Custom ? (
                            <React.Fragment>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="From Date"
                                        value={engagementSummaryFilter.fromDate || null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeFromDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <DesktopDatePicker
                                        label="To Date"
                                        value={engagementSummaryFilter.toDate || null}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => changeToDate(newValue)}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                        <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                            {!isGraphShow ? (
                                <Box className="loader-center">
                                    <CircularProgress />
                                </Box>
                            ) : null}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </LocalizationProvider>
    );
};

export default EngagementsSummary;
