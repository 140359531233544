/* eslint-disable react-hooks/exhaustive-deps */
import RichTextEditor from '@mantine/rte';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import {
    Alert,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Rating,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import FormikAutocomplete from '../FormikFields/FormikAutocomplete';
import FormikHoursOfOperation from '../FormikFields/FormikHoursOfOperation/FormikHoursOfOperation';
import FormikTextField from '../FormikFields/FormikTextField';
import SearchableMap from '../Maps/SearchableMap';
import DeleteResourceModal from './DeleteResourceModal';
import { IResource } from './interfaces/IResource';
import { IResourceEditModalProps } from './interfaces/IResourceEditModalProps';
import ResourceContactCard from './ResourceContactCard';
import ResourceReviewModal from './ResourceReviewModal';
import ResourceSocialMedia from './ResourceSocialMedia';

const defaultCenter = {
    lat: 33.302947,
    lng: -111.803276,
} as const;

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const timeRegExp = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AP][M]))/;

const MAX_CONTACTS = 9 as const;
const MAX_SOCIAL_MEDIA = 9 as const;

const validationSchema = Yup.object({
    Name: Yup.string().required('A name is required for all resources.'),
    Contacts: Yup.array()
        .of(
            Yup.object().shape({
                Name: Yup.string().nullable(),
                PhoneNumber: Yup.string()
                    .matches(phoneRegExp, 'Phone number must match common formats: (###) ###-#### or ###-###-#### or ###.###.#### or ### ### #### ')
                    .nullable(),
                Email: Yup.string().email('Please enter a valid email address.').nullable(),
            })
        )
        .max(MAX_CONTACTS, 'Contact limit reached.'),
    SocialMedia: Yup.array()
        .of(
            Yup.object().shape({
                Name: Yup.string().required('An identifying name is required.'),
                Url: Yup.string().required('A link is required.'),
            })
        )
        .max(MAX_SOCIAL_MEDIA, 'Social Media limit reached.'),
    HoursOfOperation: Yup.object().shape({
        Monday: Yup.object().shape({
            OpensAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
            ClosesAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
        }),
        Tuesday: Yup.object().shape({
            OpensAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
            ClosesAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
        }),
        Wednesday: Yup.object().shape({
            OpensAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
            ClosesAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
        }),
        Thursday: Yup.object().shape({
            OpensAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
            ClosesAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
        }),
        Friday: Yup.object().shape({
            OpensAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
            ClosesAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
        }),
        Saturday: Yup.object().shape({
            OpensAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
            ClosesAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
        }),
        Sunday: Yup.object().shape({
            OpensAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
            ClosesAt: Yup.string().matches(timeRegExp, 'Operation time must match ##:## AM/PM format. Uppercase AM/PM.').nullable(),
        }),
    }),
});

const ResourceEditModal: React.FC<IResourceEditModalProps> = (props) => {
    const { selection, isSelectingResource } = props;
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isEditingHours, setIsEditingHours] = useState<boolean>(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
    const { open, resource, onClickClose, onClickSave } = props;
    const { Latitude, Longitude, Address, Website } = resource;

    const doDeleteResource = async () => {
        await onClickSave({ ...resource, IsActive: false });
        setIsDeleteOpen(false);
        onClickClose();
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={resource}
            onSubmit={async (values: IResource) => {
                setIsSaving(true);
                await onClickSave(values);
                setIsSaving(false);
                setIsEditingHours(false);
            }}
            validationSchema={validationSchema}
        >
            {({ submitForm, values, setFieldValue, dirty, isValid, resetForm, errors }: FormikProps<IResource>) => (
                <Form>
                    <Dialog
                        fullWidth
                        maxWidth={false}
                        PaperProps={{
                            sx: { alignSelf: 'flex-start' },
                        }}
                        open={open}
                    >
                        <DialogTitle>
                            <Grid container alignItems="flex-start" justifyContent={'flex-start'}>
                                <Grid item lg md sm xs justifyContent="flex-start" alignItems="flex-start">
                                    {!Boolean(resource.Id) ? 'Create New Resource' : resource?.Name}
                                    {Website?.length > 0 && (
                                        <Chip
                                            style={{ marginLeft: 20, cursor: 'pointer' }}
                                            label="Visit Website"
                                            component="a"
                                            rel="noopener"
                                            target="_blank"
                                            href={Website}
                                            clickable
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                    <Paper
                                        variant="outlined"
                                        onClick={() => {
                                            if (!isSelectingResource) setIsReviewModalOpen(true);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Grid container justifyContent="flex-end" alignItems="flex-items">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant="h6" color="textSecondary" textAlign={'center'}>
                                                    {values.Reviews.length} Reviews
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box display="flex" justifyContent="center">
                                                    {values.Reviews.length > 0 ? (
                                                        <Rating
                                                            name="simple-controlled-average"
                                                            value={values.Reviews.reduce((a, b) => a + b.Value, 0) / values.Reviews.length}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <Typography variant="body2" color="textSecondary" align="center" style={{ cursor: 'pointer' }}>
                                                            Add a Review
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Box style={{ paddingTop: 10 }}>
                                <DeleteResourceModal
                                    open={isDeleteOpen}
                                    onSuccess={doDeleteResource}
                                    onClose={() => setIsDeleteOpen(false)}
                                    title={resource?.Name}
                                />
                                <ResourceReviewModal
                                    open={isReviewModalOpen}
                                    onClose={() => setIsReviewModalOpen(false)}
                                    reviews={values.Reviews}
                                    resourceName={values.Name}
                                />
                                <Grid container spacing={2}>
                                    <Grid item lg={8} md={12} sm={12} xs={12} order={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
                                        <Grid container spacing={2} alignContent="">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                {dirty && !isSelectingResource && (
                                                    <Alert severity="warning">If you've made changes, don't forget to save!</Alert>
                                                )}
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Divider variant="fullWidth">
                                                        <>
                                                            <Typography variant="h6" component="span">
                                                                Hours of Operation
                                                            </Typography>
                                                            <Tooltip
                                                                title={isEditingHours ? 'Stop Editing Hours of Operation' : 'Edit Hours of Operation'}
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    hidden={isSelectingResource}
                                                                    onClick={() => {
                                                                        setIsEditingHours((prev) => !prev);
                                                                    }}
                                                                >
                                                                    {isEditingHours ? <DoneIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    </Divider>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <FormikHoursOfOperation {...values.HoursOfOperation} isEditing={isEditingHours} />
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Divider variant="fullWidth">
                                                    <Typography variant="h6">Description</Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                {!isSelectingResource ? (
                                                    <RichTextEditor value={values.Description} onChange={(value) => setFieldValue('Description', value)} />
                                                ) : (
                                                    <Typography
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(values.Description),
                                                        }}
                                                    ></Typography>
                                                )}
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Divider variant="fullWidth">
                                                    <Typography variant="h6">Details</Typography>
                                                </Divider>
                                            </Grid>

                                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                                <FormikTextField name="Name" label="Name" required disabled={isSelectingResource} />
                                            </Grid>
                                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                                <FormikTextField name="Website" label="Website URL" disabled={isSelectingResource} />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <FormikAutocomplete
                                                    disabled={isSelectingResource}
                                                    name="Categories"
                                                    label="Categories"
                                                    helperText="To add a category, type it in and press enter."
                                                />
                                            </Grid>
                                            <FieldArray name="Contacts">
                                                {({ push, remove }) => (
                                                    <>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Divider variant="fullWidth">
                                                                <Tooltip title="Add Contact" arrow>
                                                                    <Chip
                                                                        clickable={values.Contacts.length < MAX_CONTACTS && !isSelectingResource}
                                                                        color="primary"
                                                                        variant="filled"
                                                                        label="Contacts"
                                                                        icon={
                                                                            values.Contacts.length < MAX_CONTACTS && !isSelectingResource ? (
                                                                                <AddCircleOutlineIcon />
                                                                            ) : null
                                                                        }
                                                                        onClick={() => {
                                                                            if (values.Contacts.length < MAX_CONTACTS && !isSelectingResource)
                                                                                push({
                                                                                    Name: '',
                                                                                    PhoneNumber: '',
                                                                                    Email: '',
                                                                                    IsDeleted: false,
                                                                                });
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </Divider>
                                                        </Grid>
                                                        {React.Children.toArray(
                                                            values.Contacts.filter((contact) => !contact.IsDeleted).map((contact, index) => (
                                                                <Grid item lg={4} md={6} sm={12} xs={12}>
                                                                    <ResourceContactCard
                                                                        contact={contact}
                                                                        index={index}
                                                                        remove={remove}
                                                                        disabled={isSelectingResource}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        )}
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={4} md={12} sm={12} xs={12} order={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Divider variant="fullWidth">
                                                    <Typography variant="h6">Location</Typography>
                                                </Divider>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <SearchableMap
                                                    latitude={Latitude ?? defaultCenter.lat.toString()}
                                                    longitude={Longitude ?? defaultCenter.lng.toString()}
                                                    address={Address}
                                                />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <FormikTextField name="Address" label="Address" disabled={isSelectingResource} />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <FormikTextField name="Latitude" label="Latitude" disabled />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <FormikTextField name="Longitude" label="Longitude" disabled />
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ maxHeight: 400, minHeight: 100, margin: 0, padding: 0 }}>
                                                <FieldArray name="SocialMedia">
                                                    {({ push, remove }) => (
                                                        <>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <Divider variant="fullWidth">
                                                                    <Tooltip title="Add Social Media Links" arrow>
                                                                        <Chip
                                                                            clickable={values.SocialMedia.length < MAX_SOCIAL_MEDIA && !isSelectingResource}
                                                                            color="primary"
                                                                            variant="filled"
                                                                            label="Social Media"
                                                                            icon={
                                                                                values.SocialMedia.length < MAX_SOCIAL_MEDIA && !isSelectingResource ? (
                                                                                    <AddCircleOutlineIcon />
                                                                                ) : null
                                                                            }
                                                                            onClick={() => {
                                                                                if (values.SocialMedia.length < MAX_SOCIAL_MEDIA && !isSelectingResource)
                                                                                    push({
                                                                                        Name: '',
                                                                                        Url: '',
                                                                                        IsDeleted: false,
                                                                                    });
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                </Divider>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                style={{ overflowY: 'auto', maxHeight: 400 }}
                                                            >
                                                                {React.Children.toArray(
                                                                    values.SocialMedia.filter((media) => !media.IsDeleted).map((media, index) => (
                                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                            <ResourceSocialMedia
                                                                                SocialMedia={media}
                                                                                index={index}
                                                                                remove={remove}
                                                                                disabled={isSelectingResource}
                                                                            />
                                                                        </Grid>
                                                                    ))
                                                                )}
                                                            </Grid>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={6} xs={6} alignItems="flex-start">
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="error"
                                        onClick={() => setIsDeleteOpen(true)}
                                        hidden={resource?.Id?.length <= 0 || isSelectingResource}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Stack direction={'row'} spacing={1} alignItems="center" justifyContent="right">
                                        {!isValid && (
                                            <Typography variant="caption" color="error">
                                                Please correctly fill in all fields before saving.
                                            </Typography>
                                        )}
                                        <Button
                                            variant="contained"
                                            hidden={!isSelectingResource}
                                            onClick={() => {
                                                if (selection) {
                                                    selection(resource);
                                                }
                                            }}
                                        >
                                            Select
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            onClick={submitForm}
                                            disabled={!dirty || isSaving}
                                            hidden={isSelectingResource}
                                        >
                                            {isSaving ? <CircularProgress size={24} /> : Boolean(resource.Id) ? 'Save' : 'Save New Resource'}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                if (dirty) {
                                                    resetForm();
                                                }
                                                setIsEditingHours(false);
                                                onClickClose();
                                            }}
                                            color={dirty ? 'error' : 'primary'}
                                        >
                                            {dirty ? 'Close without Saving' : 'Close'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
};

export default ResourceEditModal;
